import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {ProgressSpinnerComponent} from '../progress-spinner/progress-spinner.component';
import {ProgressSpinnerModule as progressSpinnerModule} from '../progress-spinner/progress-spinner.module';
import {SubmitButtonSpinnerComponent} from './submit-button-spinner.component';

@NgModule({
  declarations: [SubmitButtonSpinnerComponent],
  exports: [SubmitButtonSpinnerComponent, ProgressSpinnerComponent],
  imports: [CommonModule, ButtonModule, progressSpinnerModule]
})
export class SubmitButtonSpinnerModule {}
