<pm-basic *ngIf="isViewingPage">
  <pm-title>Account settings</pm-title>

  <pm-card title="Notifications" subtitle="Choose how you'd like to be notified.">
    <ng-container *ngIf="userPreferences">
      <pm-attribute-display-typeahead [attribute]="buyerConfiguration" [(model)]="buyerModel"
                                      (selection)="buyerChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-toggle [attribute]="emailToggleConfiguration" [(model)]="emailToggleModel"
                                   (change)="emailToggleChange($event)">
      </pm-attribute-display-toggle>

      <pm-attribute-display-text-input [attribute]="emailFrequencyConfig" [(model)]="emailFrequencyModel">
      </pm-attribute-display-text-input>
    </ng-container>

    <div class="pb-3 text-right">
      <button *ngIf="changes" pButton type="button" label="Cancel" class="ui-button-link pr-3" (click)="cancel()"></button>
      <button pButton type="button" label="Save changes" class="ui-button-link" (click)="save()"></button>
    </div>
  </pm-card>
</pm-basic>
