import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTypeaheadConfig } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class Vendor implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(candidate: Candidate, overrides?): AttributeTypeaheadConfig {
    let url;
    if (candidate && candidate.dsdSwitch && candidate.warehouseSwitch) {
      url = '/lookup/vendor';
    } else if (candidate && candidate.warehouseSwitch) {
      url = '/lookup/whsvendor';
    } else if (candidate && candidate.dsdSwitch) {
      url = '/lookup/vendordsd';
    } else {
      url = '/lookup/vendor';
    }

    return {
      label: 'Supplier',
      description: 'The supplier of record that H-E-B pays.',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: 'vendorTypeAhead',
      displayRef: 'displayName',
      placeholderText: 'Select or search for a Supplier...',
      searchUrl: url,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(candidate, configOverrides);
    componentInstance.model = candidate.vendor;
    componentInstance.selection.subscribe(x => {
      candidate.vendor = x;
      candidate.lane = null;
      if (configOverrides) {
        configOverrides.saveDisabled = !candidate.vendor;
      }
    });
  }
  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.vendor) {
      componentInstance.model = candidate.vendor;
    }
  }
}
