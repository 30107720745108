import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InnerPackDetailsCardComponent } from './inner-pack-details-card.component';

import { CardModule } from '../../shared/card/card.module';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';

@NgModule({
  declarations: [InnerPackDetailsCardComponent],
  exports: [InnerPackDetailsCardComponent, CardModule, AttributeDisplayModule],
  imports: [CommonModule, CardModule, AttributeDisplayModule]
})
export class InnerPackDetailsCardModule {}
