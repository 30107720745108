import {Component, OnInit} from '@angular/core';
import {AdminPreferences, AdminPreferencesType, Vendor, VendorAndPreferencesPageModel} from 'pm-models';
import {Observable, of} from 'rxjs';
import {LookupService} from '../service/lookup.service';
import {switchMap} from 'rxjs/operators';
import {PreferencesService} from '../service/preferences.service';
import {PageableResult} from 'pm-models/lib/pageableResult';

@Component({
  selector: 'app-volume-upload-settings',
  templateUrl: './volume-upload-settings.component.html',
  styleUrls: ['./volume-upload-settings.component.scss']
})
export class VolumeUploadSettingsComponent implements OnInit {

  readonly pageSize = 20;
  vendorPageAndPreferences: VendorAndPreferencesPageModel[];
  recordCount = 0;
  includeCount = true;
  isViewingSupplierSettingsPanel: boolean;
  vendor: Vendor;
  suppliers: Vendor[] = [];
  supplierFilters: string[];

  vendorHeaderColumns = [{field: 'apNumber', header: 'AP #', sortable: false, width: '15%'},
    {field: 'description', header: 'Supplier', sortable: false, width: '55%'},
    {field: 'channel', header: 'Channel', sortable: false, width: '15%'},
    {field: 'apNumber', header: 'Auto-approve', sortable: false, width: '15%'}];

  constructor(private lookupService: LookupService, private preferencesService: PreferencesService) { }

  ngOnInit() {
    this.lookupService.findAllVendors().subscribe((suppliers) => {
      this.suppliers = suppliers;
    });
  }

  onClickRow(event, panel, target) {

    this.showSupplierSettingsPanel(event, panel, target, event.data.vendor);
  }

  /**
   * finds a page of vendors and returns them wrapped with their preferences.
   * @param pageNumber
   */
  findVendorPageAndPreferences(pageNumber: number): Observable<PageableResult> {

    return this.lookupService.findAllVendorsByPage(pageNumber, this.pageSize, this.includeCount, this.supplierFilters).pipe(
      switchMap((vendorPage) => {
        if (!vendorPage || !vendorPage.data || vendorPage.data.length === 0) {
          return of(vendorPage);
        }
        return this.getVendorAndPreferencesModelFromVendors(vendorPage);
      })
    );
  }

  /**
   * Takes a list of vendors, and finds their preferences, and puts them in a wrapper with their corresponding vendors.
   * @param page containing vendors.
   */
  getVendorAndPreferencesModelFromVendors(page: PageableResult): Observable<PageableResult> {
    const vendors = page.data;
    const apNumbers = vendors.map(function(vendor) { return vendor.apNumber; });

    return this.preferencesService.getAdminPreferencesByIdsAndType(apNumbers, AdminPreferencesType.VENDOR).pipe(
      switchMap((adminPreferencesList) => {

          if (!adminPreferencesList || adminPreferencesList.length === 0) {
            page.data = vendors.map(function(vendor) {
              return {vendor: vendor};
            });
          } else {
            page.data = vendors.map(function(vendor) {
              const adminPreferences = adminPreferencesList.find(preferences => +preferences.id === +vendor.apNumber);
              return { vendor: vendor, adminPreferences: adminPreferences };
            });
          }
          return of(page);
        }
      )
    );
  }

  /**
   * Whether or not the model has preferences.
   * @param vendorPageAndPreference
   */
  hasPreference(vendorPageAndPreference: VendorAndPreferencesPageModel) {
    return vendorPageAndPreference && vendorPageAndPreference.adminPreferences;
  }

  /**
   * Returns '-' if there's no fulfillment channel preferences, else returns a user friendly display.
   * @param vendorPageAndPreference
   */
  getUserFriendlyFulfillmentChannel(vendorPageAndPreference: VendorAndPreferencesPageModel) {
    if (!this.hasPreference(vendorPageAndPreference) || !vendorPageAndPreference.adminPreferences.fulfillmentChannel) {
      return '-';
    }
    return vendorPageAndPreference.adminPreferences.fulfillmentChannel ===
    AdminPreferences.WAREHOUSE_FULFILLMENT_CHANNEL ? 'Warehouse' :  AdminPreferences.DSD_FULFILLMENT_CHANNEL;
  }

  /**
   * If there's no preferences, or the auto approve hasn't been set, return '-', else return yes or no.
   * @param vendorPageAndPreference
   */
  getAutoApprove(vendorPageAndPreference: VendorAndPreferencesPageModel) {
    if (!this.hasPreference(vendorPageAndPreference) || vendorPageAndPreference.adminPreferences.autoApproveActivation === null  ||
      vendorPageAndPreference.adminPreferences.autoApproveActivation === undefined) {
      return '-';
    }
    return vendorPageAndPreference.adminPreferences.autoApproveActivation ? 'Yes' : 'No';
  }

  /**
   * Returns a page of data.
   * @param event
   */
  fetchPage(event?) {
    const pageNumber = event ? event.first / this.pageSize : 0;

    this.findVendorPageAndPreferences(pageNumber).subscribe((page) => {
      this.vendorPageAndPreferences = page.data;
      // only run the count query the first time fetching the page.
      if (this.includeCount) {
        this.includeCount = false;
        this.recordCount = page.recordCount;
      }
    });
  }


  /**
   * Updates the supplier settings on save.
   * @param preferences
   * @param supplierSettingsOverlay
   */
  onSaveSupplierSettings(preferences: AdminPreferences, supplierSettingsOverlay) {

    this.vendorPageAndPreferences.forEach(vendorPageAndPreference => {
      if (+vendorPageAndPreference.vendor.apNumber === +preferences.id) {
        vendorPageAndPreference.adminPreferences = preferences;
      }
    });

    this.hideSupplierSettingsPanel(supplierSettingsOverlay);
  }

  /**
   * Hides the supplier settings panel.
   * @param supplierSettingsOverlay
   */
  hideSupplierSettingsPanel(supplierSettingsOverlay) {
    this.isViewingSupplierSettingsPanel = false;
    supplierSettingsOverlay.hide();
  }


  /**
   * Displays the supplier settings panels.
   *
   * @param event the event.
   * @param panel the panel.
   * @param target the target.
   * @param vendor the vendor.
   */
  showSupplierSettingsPanel(event, panel, target, vendor) {
    this.vendor = vendor;
    this.isViewingSupplierSettingsPanel = true;
    panel.show(event, target);
  }

  /**
   * Updates filter aps and refetched volume uploads with ap filters.
   * @param supplierAps
   */
  onApplySupplierFilter(supplierAps: any) {
    this.supplierFilters = supplierAps ? supplierAps : [];
    this.includeCount = true;
    this.fetchPage();
  }
}
