import {Task} from './task';

/**
 * This represents the object that Workflow API returns, that has a list of tasks, as well as metadata surrounding paging.
 */
export class Tasks {

  // The default size for the number of records is 10. Setting this size will force workflow
  // to return up to that many results. If a user ever has more than 1000
  // records, we may want to use server side pagination instead of client side.
  public static DEFAULT_TOTAL_SIZE = 1000;

  data: Task [];
  order: string;
  size: number;
  sort: string;
  start: number;
  total: number;
}
