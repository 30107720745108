import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseAttributeDisplayComponent} from '../base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-tax-category',
  templateUrl: './attribute-display-tax-category.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-tax-category.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayTaxCategoryComponent extends BaseAttributeDisplayComponent implements OnInit {
  _model;

  searchText: string;
  selectedCategory: any;

  recommendedCategories: [];
  notRecommendedCategories: [];

  @Input()
  get model() {
    return this._model;
  }

  set model(val) {
    if (val) {
      this.selectedCategory = this.findItem(val.id);
    } else {
      this.selectedCategory = null;
    }

    this._model = this.selectedCategory;
    this.modelChange.emit(this._model);
  }

  constructor() {
    super();
  }

  isOptionDisabled(option) {
    return (this.attribute.isReadOnly && this.attribute.isReadOnly()) ||
      (this.attribute.isDisabled && this.attribute.isDisabled()) ||
      (option.disabled && option.disabled());
  }

  ngOnInit() {
    if (this.attribute && this.attribute.collections) {
      this.recommendedCategories = this.attribute.collections.filter((category) => category.defaultCategory === true);
      this.notRecommendedCategories = this.attribute.collections.filter((category) => category.defaultCategory === false);
    }
  }

  onChange(event) {
    this.selectedCategory = this.findItem(event + '');
    this.change.emit(event);
  }

  findItem(id: string) {
    return this.attribute.collections.find(item => item.id.toString() === id.toString());
  }

  getNonDefaultDisplayName(taxCategory) {
    if (!taxCategory) {
      return;
    }
    return taxCategory.id + '-' +  taxCategory.description;
  }
}
