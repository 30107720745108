import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateError, CandidateProductError, CandidateValidatorType} from 'pm-models';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {MatUtilService} from '../../service/mat-util.service';
import {MrtInnerStepperComponent} from '../mrt-inner-stepper/mrt-inner-stepper.component';

@Component({
  selector: 'app-inner-extended-attributes',
  templateUrl: './inner-extended-attributes.component.html',
  styleUrls: ['./inner-extended-attributes.component.scss']
})
export class InnerExtendedAttributesComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private growlService: GrowlService,
              private candidateUtilService: CandidateUtilService, private matUtils: MatUtilService) {
  }

  isLoadingData = true;
  canNavigate = true;
  innerCandidateError: CandidateError;
  innerCandidateProductError: CandidateProductError;

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {

      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {
        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId() ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate) => {
            if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
              this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
              return this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).pipe(
                tap(innerCandidate => {
                  this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
                  this.initializeData();
                }),
                switchMap(() => this.matUtils.updateMatAttributesAndValues(this.supplierMrtService.getInnerCandidate(),
                  this.supplierMrtService.candidateInnerGlobalAttributes, this.supplierMrtService.candidateInnerHierarchyAttributes)),
                finalize(() => this.isLoadingData = false)
              );
            } else {
              this.supplierMrtService.resetService();
              return this.router.navigate(['/tasks']);
            }
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).pipe(
          tap(() => {
            this.initializeData();
          }),
          switchMap(isValid => {
            if (isValid) {
              return this.matUtils.updateMatAttributesAndValues(this.supplierMrtService.getInnerCandidate(),
                this.supplierMrtService.candidateInnerGlobalAttributes, this.supplierMrtService.candidateInnerHierarchyAttributes).pipe(
                tap(() => this.isLoadingData = false)
              );
            } else {
              this.supplierMrtService.resetService();
              return this.router.navigate(['/tasks']);
            }
          })
        ).subscribe();
      }
    });
  }


  initializeData() {
    this.innerCandidateError = this.supplierMrtService.getInnerExtendedAttributesError(this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.innerCandidateError) {
      this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[this.supplierMrtService.getInnerCandidateProduct(0).id];
    }
    this.supplierMrtService.setInnerCandidates().subscribe();
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case MrtInnerStepperComponent.SUPPLIER_HEB_SETUP:
        this.jumpToPage('/supplierAndHebDetails');
        break;
      case MrtInnerStepperComponent.PRODUCT_DETAILS:
        this.jumpToPage('/innerProductDetails');
        break;
      case MrtInnerStepperComponent.CASE_PACK:
        this.onClickBack();
        break;
    }
  }

  onClickNext() {
    this.canNavigate = false;

    let validators: CandidateValidatorType[];
    if (this.supplierMrtService.isInnerCandidateReplenishable()) {
      validators = SupplierMrtService.FULL_SUPPLIER_REPLENISHABLE_VALIDATORS;
    } else {
      validators = SupplierMrtService.FULL_SUPPLIER_NON_REPLENISHABLE_VALIDATORS;
    }

    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), validators).subscribe(data => {
      this.supplierMrtService.setInnerExtendedAttributesError(this.supplierMrtService.getInnerCandidate().candidateId, null);

      if (this.supplierMrtService.validateOtherInnerCandidates()) {
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/mrtCaseDetails',
          {
            queryParams: {
              taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId
            }
          }, false);
      } else {
        // if there's errors on other candidates, navigate to setup inners page.
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/setUpNewMrtItems',
          {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}}, false);
      }
    }, (error) => {
      this.supplierMrtService.scrollToTop();
      // set the errors on the page and save.
      if (error.error?.candidateErrors?.hasErrors) {
        this.innerCandidateError = error.error.candidateErrors;
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
        this.supplierMrtService.updateInnerErrors(this.supplierMrtService.getInnerCandidate().candidateId, this.innerCandidateError);
      }
      this.supplierMrtService.saveInnerCandidate(this.supplierMrtService.getInnerCandidate());
      this.canNavigate = true;
    });
  }

  onClickBack() {
    this.canNavigate = false;
    const url = this.supplierMrtService.isInnerCandidateReplenishable() ? '/innerCaseDetails' : '/addNewMrtCase';
    this.jumpToPage(url);
  }

  jumpToPage(url) {
    this.canNavigate = false;
    this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
      {
        queryParams: {
          taskId: this.supplierMrtService.getTaskIdString(),
          candidateId: this.supplierMrtService.getInnerCandidate().candidateId
        }
      }, false, this.supplierMrtService.hasInnerChanged());
  }
}
