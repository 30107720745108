import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AttributeTypes, ReviewComponent} from 'pm-components';
import {
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  DsdItem,
  Item,
  Product,
  TextInputType,
  Upc
} from 'pm-models';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {CostService} from '../../service/cost.service';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {FileService} from '../../service/file.service';
import {LookupService} from '../../service/lookup.service';
import {ProductService} from '../../service/product.service';
import {WorkflowService} from '../../service/workflow.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {HistoryService} from '../../utils/history.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {LabelInsightService} from '../../service/label-insight.service';
import {CandidateHistoryService} from '../../service/candidate-history.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MatUtilService} from '../../service/mat-util.service';

@Component({
  selector: 'app-supplier-bonus-review',
  templateUrl: './supplier-bonus-review.component.html',
  styleUrls: ['./supplier-bonus-review.component.scss']
})

export class SupplierBonusReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public UPC = 'UPC';
  public isApproveDisabled = false;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public productData: Product;
  public upc: Upc;
  public item: Item;
  public dsdItem: DsdItem;
  public productInfoString: string;
  public displayingExistingCasesDrawer = false;
  public displayingBuyerInfo = false;
  public departmentId = undefined;
  public taskId: any;
  public resubmittedCandidate: Candidate;
  public candidateProductImages = [];
  public labelInsightImages = [];

  public currentCandidateProductIndex = 1;
  public searchedCandidateProductIndex = 0;

  showMatAttributes = false;
  isLoadingMatData = true;

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute, public router: Router,
              public candidateService: CandidateService, public lookupService: LookupService, public growlService: GrowlService,
              public costService: CostService, public productService: ProductService, public fileService: FileService,
              public bonusSizeService: BonusSizeService, public editCandidateModalService: EditCandidateModalService,
              public candidateUtilService: CandidateUtilService, public historyService: HistoryService,
              public permissionService: NgxPermissionsService, public labelInsightService: LabelInsightService,
              public candidateHistoryService: CandidateHistoryService, public matUtilService: MatUtilService) {
  }

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;

  public originalCandidate: any = {};

  private taskSubscription$: any;
  public isViewingPage = true;
  public commodities: any;

  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;

  public productImageUrl: string = null;

  public decimalCount = 1;
  public pennyProfit: any;
  public margin;
  public oldCandidateFound: boolean;
  public currentHistoryResults: any = undefined;
  public showHistoryPanel: boolean = false;
  public isReplacementUpc: boolean = false;
  public canEditAndResubmit = true;

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'Verify the value provided by the supplier or enter a value if empty .',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };

  yearConfiguration: AttributeTextInputConfig = {
    label: 'Year',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };

  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  ngOnInit() {
    this.bonusSizeService.resetService();
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.BONUS_SIZE && candidate.candidateType !== Candidate.REPLACEMENT_UPC) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.candidateHistoryService.setLastSupplierChangedCandidate(candidate);
            this.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
      if (params.has('taskId')) {
        this.taskId = parseInt(params.get('taskId'), 10);
      }
    });
  }

  onClickBackToHome() {
    this.router.navigate(['/tasks']).then();
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']).then();
  }

  private setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.productImageUrl = null;
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    this.guaranteeToStoreDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.reactionDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.inboundSpecConfiguration.isHidden = () => !this.candidate.codeDate;
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
    this.candidateProductImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[1].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[1].labelInsightsImageLinks);
    this.currentHistoryResults = this.historyService.getCandidateHistory(this.candidate.candidateId, true);
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return;
    }
    this.bonusSizeService.resetMatHierarchyFields();
    if (this.candidateUtilService.isInProgressCandidate(this.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatHierarchyFromProduct(this.candidateProduct, this.productData).pipe(
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
          this.bonusSizeService.globalAttributes, this.bonusSizeService.hierarchyAttributes)),
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.bonusSizeService.hierarchyAttributes,
            this.bonusSizeService.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.bonusSizeService.globalAttributes,
            [], this.bonusSizeService.warehouseItemAttributes, this.bonusSizeService.upcAttributes);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.candidateProduct?.globalAttributes, [],
        this.bonusSizeService.warehouseItemAttributes, this.bonusSizeService.upcAttributes);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[this.currentCandidateProductIndex];
  }

  setProductData(): Observable<any> {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc,
        []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
        })
      );
    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode,
        []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
        })
      );
    }
  }

  private setInformationFromProductData(productData: Product) {
    this.candidate.productId = productData.productId;
    this.item = this.productService.getPrimaryItem(this.candidate.candidateProducts[0], productData);
    this.dsdItem = this.productService.getPrimaryDsdItem(this.candidate.candidateProducts[0], productData);
    this.productService.getUpcBySearchedValue(this.candidate.candidateProducts[0], productData).subscribe(upc => {
      this.upc = upc;
      this.setProductRetailInfo();
    });
    this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
    this.departmentId = this.productService.getDepartment(productData);
  }

  setProductRetailInfo() {
    const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
    tempCandidate.retailPrice = this.upc.retailPrice;
    tempCandidate.retailXFor = this.upc.xfor;
    if (!this.productData.priceRequired) {
      tempCandidate.retailType = 'KEY_RETAIL';
    }
    this.pennyProfit = this.costService.getHebPennyProfit(tempCandidate);
    this.margin = this.costService.getHebMargin(tempCandidate);
    if (this.candidate.candidateType === Candidate.REPLACEMENT_UPC) {
      this.productInfoString =
        'Retail size: ' + this.candidate.retailSize +
        ' | Original size: ' + this.upc.size +
        ' | Suggested retail: ' + this.costService.toCurrency(this.upc.retailPrice);
    } else {
      this.productInfoString =
        'Bonus size: ' + this.candidate.retailSize +
        ' | Original size: ' + this.upc.size +
        ' | Suggested retail: ' + this.costService.toCurrency(this.upc.retailPrice);
    }
  }

  showExistingCasePacks() {
    this.displayingExistingCasesDrawer = true;
    this.displayingBuyerInfo = false;
    this.showHistoryPanel = false;
    this.pmReview.openDrawer();
  }

  collapse() {
    this.displayingExistingCasesDrawer = false;
    this.displayingBuyerInfo = false;
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  get attributeType() {
    return AttributeTypes;
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  showEditCandidateProductModal(type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
      }
    });
  }
  onClickCasePack(casePack) {
    casePack.isViewing = !casePack.isViewing;
  }

  print() {
    window.print();
  }

  seasonChange(event) {
    this.candidate.season = event;
  }

  /**
   * Saves candidate.
   */
  save() {
    this.candidate.candidateProducts[this.currentCandidateProductIndex] = this.candidateProduct;
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.candidate.brand.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.candidate.brand.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  getPageTitle() {
    if (!this.candidate) {
      return '';
    }

    if (this.candidate.candidateType === Candidate.REPLACEMENT_UPC) {
      return 'Review Replacement UPC';
    } else {
      return 'Review New Bonus';
    }
  }

  getTitleSubHeading(): String {
    if (!this.candidate) {
      return '';
    }
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.candidate.status);
    if (this.candidate && this.candidate.candidateProducts[this.currentCandidateProductIndex].itemCode) {
      infoString += ' | Item code: ' + this.candidate.candidateProducts[this.currentCandidateProductIndex].itemCode;
    }

    return infoString;
  }

  isRejected() {
    if (!this.candidate) {
      return false;
    }
    return (CandidateUtilService.getUIStatusFromCandidateStatus(this.candidate.status) === 'Rejected');
  }

  resubmitCandidate() {
    this.canEditAndResubmit = false;

    this.resubmittedCandidate = JSON.parse(JSON.stringify(this.candidate));
    this.resubmittedCandidate.candidateId = null;
    this.resubmittedCandidate.status = null;
    this.resubmittedCandidate.vendorComment = null;
    this.resubmittedCandidate.buyerComment = null;
    this.resubmittedCandidate.scaComment = null;

    // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
    this.bonusSizeService.createCandidateAndNavigate(this.resubmittedCandidate, '/setupBonusSize', true);
    this.workflowService.deleteHistoricInstance(this.taskId).subscribe();
  }

  historyPanelOpen() {
    this.permissionService.hasPermission('ROLE_VIEW_CANDIDATE_HISTORY').then((hasPermission) => {
      if (hasPermission) {
        this.historyService.getCandidateHistory(this.candidate.candidateId, true);
        this.currentHistoryResults = this.historyService.sortedAudits;
        this.showHistoryPanel = true;
        this.displayingExistingCasesDrawer = false;
        this.pmReview.openDrawer();
      }
    });
  }

  historyPanelClose() {
    this.showHistoryPanel = false;
    this.displayingExistingCasesDrawer = false;
    this.pmReview.closeDrawer();
  }

  getUpcDetailLabel() {
    if (this.candidate.candidateType === Candidate.REPLACEMENT_UPC) {
      this.isReplacementUpc = true;
      return 'Replacement UPC details';
    } else {
      return 'Bonus UPC Details';
    }
  }
}
