import { BaseAttribute } from './base-attribute';
import {AttributeUPCConfig, CandidateValidatorType, CandidateError, Candidate} from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayUpcComponent, InputState } from '../../shared/attribute-display/attribute-display-upc/attribute-display-upc.component';
import { UPCInputState } from '../../shared/upc-inputs/upc-input/upc-input.component';

export class ProductUPC implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayUpcComponent;
  }

  getAttributeConfig(overrides?): AttributeUPCConfig {
    return {
      label: 'UPC',
      description: `Enter the product's UPC or another unique product identification code`,
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: '',
      // UPCType:
      placeholderText: `Enter UPC`,
      checkDigitPlaceholderText: `Check #`,
      productIndex: 1,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayUpcComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    if (candidate?.candidateType === Candidate.NEW_PRODUCT ||
      candidate?.candidateType === Candidate.NEW_PRODUCT_UPLOAD ||
      candidate.candidateType === Candidate.MRT_INNER) {
      this.setupComponentCandidate(componentInstance, candidate, configOverrides, 0);
    } else {
      this.setupComponentCandidate(componentInstance, candidate, configOverrides, componentInstance.attribute.productIndex);
    }
  }

  setupComponentCandidate(componentInstance: AttributeDisplayUpcComponent, candidate: Candidate, configOverrides: any = {}, index: number) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.candidateProducts[index].upc;
    componentInstance.modelChange.subscribe(x => {
      candidate.candidateProducts[index].upc = x;
    });
    componentInstance.checkDigitModel = candidate.candidateProducts[index].upcCheckDigit;
    componentInstance.checkDigitModelChange.subscribe(x => {
      candidate.candidateProducts[index].upcCheckDigit = x;
    });

    componentInstance.validateUpc.subscribe(s => {
      if (configOverrides.validationService) {
        componentInstance.state = InputState.loading;
        const candidateProductId = candidate.candidateProducts[index].id;

        configOverrides.validationService.validateCandidate(candidate, [CandidateValidatorType.UPC_VALIDATOR]).subscribe(
          () => {
            componentInstance.state = InputState.valid;
            componentInstance.attributeError = undefined;
          },
          error => {
            componentInstance.state =  InputState.invalid;
            // if there's an error, and it's an instance of candidate error model, update the candidate product's
            // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
            // add the whole candidate product error model.
            if (error.error.candidateErrors) {
              const returnedCandidateError: CandidateError = error.error.candidateErrors;
              const returnedCandidateProductError = returnedCandidateError.candidateProductErrors[candidateProductId];
              componentInstance.attributeError = returnedCandidateProductError.upc;
            }
          }
        );
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayUpcComponent, candidate: Candidate) {
    if (candidate.candidateType === 'NEW_PRODUCT') {
      this.updateModelCandidate(componentInstance, candidate, 0);
    } else {
      this.updateModelCandidate(componentInstance, candidate, componentInstance.attribute.productIndex);
    }
  }

  updateModelCandidate(componentInstance: AttributeDisplayUpcComponent, candidate: Candidate, index: number) {
    if (componentInstance.model !== candidate.candidateProducts[index].upc) {
      componentInstance.model = candidate.candidateProducts[index].upc;
    }

    if (componentInstance.checkDigitModel !== candidate.candidateProducts[index].upcCheckDigit) {
      componentInstance.checkDigitModel = candidate.candidateProducts[index].upcCheckDigit;
    }
  }
}
