import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AttributeConfig, AttributeTypeaheadConfig} from 'pm-models';

@Component({
  selector: 'pm-import-factory-search-bar',
  templateUrl: './import-factory-search-bar.component.html',
  styleUrls: ['../attribute-display/attribute-display.component.scss', './import-factory-search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportFactorySearchBarComponent implements OnInit {

  FACTORY_ID = 'FACTORY_ID';
  FACTORY_NAME = 'FACTORY_NAME';
  FACTORY_COUNTRY = 'COUNTRY';
  FACTORY_CITY = 'CITY';
  public typeaheadStyle = {'border-top-right-radius' : '3px', 'border-bottom-right-radius' : '3px',
    'border-right' : '1px solid rgb(184, 184, 184)', 'border-top' : '1px solid rgb(184, 184, 184)',
    'border-bottom' : '1px solid rgb(184, 184, 184)'};
  public errorTypeaheadStyle = {'border-top-right-radius' : '3px', 'border-bottom-right-radius' : '3px',
    'border-right' : '2px solid #e32526', 'border-top' : '2px solid #e32526',
    'border-bottom' : '2px solid #e32526'};

  @Input()
  public attribute?: AttributeConfig;

  @Input()
  factoryModel: any;

  @Input()
  public attributeError?: any;

  @Output() searchTypeChange?: EventEmitter<any> = new EventEmitter<any>();

  @Output() factoryModelChange?: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  searchOptions: {label: string, value: string}[] =
    [{label: 'Factory ID', value: this.FACTORY_ID}, {label: 'Factory name', value: this.FACTORY_NAME},
      {label: 'Country', value: this.FACTORY_COUNTRY}, {label: 'City', value: this.FACTORY_CITY}];

  searchTypeValue = this.FACTORY_ID;

  factoryIdConfiguration: AttributeTypeaheadConfig = {
    description: 'Search for factory...',
    isRequired: false,
    name: 'factoryIdTypeAhead',
    idRef: 'factoryId',
    displayRef: 'displayName',
    placeholderText: 'Search for factory...',
    searchUrl: '/lookup/import/factory/factoryId',
    showAddlInfo: true,
    displaySubRef: 'cityCountryString'
  };

  factoryNameConfiguration: AttributeTypeaheadConfig = {
    description: 'Search for factory...',
    isRequired: false,
    name: 'factoryNameTypeAhead',
    idRef: 'factoryId',
    displayRef: 'displayName',
    placeholderText: 'Search for factory...',
    searchUrl: '/lookup/import/factory/factoryName',
    showAddlInfo: true,
    displaySubRef: 'cityCountryString'
  };

  factoryCountryConfiguration: AttributeTypeaheadConfig = {
    description: 'Search for factory...',
    isRequired: false,
    name: 'factoryCountryTypeAhead',
    idRef: 'factoryId',
    displayRef: 'displayName',
    placeholderText: 'Search for factory...',
    searchUrl: '/lookup/import/factory/countryName',
    showAddlInfo: true,
    displaySubRef: 'cityCountryString'
  };

  factoryCityConfiguration: AttributeTypeaheadConfig = {
    description: 'Search for factory...',
    isRequired: false,
    name: 'factoryCityTypeAhead',
    idRef: 'factoryId',
    displayRef: 'displayName',
    placeholderText: 'Search for factory...',
    searchUrl: '/lookup/import/factory/cityName',
    showAddlInfo: true,
    displaySubRef: 'cityCountryString'
  };

  ngOnInit() {
  }

  get searchType() {
    return this.searchTypeValue;
  }

  set searchType(val) {
    this.searchTypeValue = val;
    this.searchTypeChange.emit(val);
  }

  handleFactoryChange(event) {
    this.factoryModel = event;
    this.factoryModelChange.emit(event);
  }

  onSearchOptionChange(event) {
    this.factoryModel = null;
  }

  factoryInitCallback(event) {
  }

  getTypeaheadStyle() {
    if (this.attributeError) {
      return this.errorTypeaheadStyle;
    } else {
      return this.typeaheadStyle;
    }
  }
}
