import {BaseAttribute} from 'pm-components/lib/attributes/attribute-type/base-attribute';
import {Attribute, AttributeTextInputConfig} from 'pm-models';
import {MatUtilService} from '../../../../../../src/app/2.0.0/service/mat-util.service';
import {
  AttributeDisplayTextinputComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class MatAttributeTextInput implements BaseAttribute {

  constructor() { }


  private attributeTextInputConfig: AttributeTextInputConfig;


  getAttributeConfig(overrides): AttributeTextInputConfig {
    return this.attributeTextInputConfig;
  }

  getComponentType(): any {
    return AttributeDisplayTextinputComponent;
  }

  setupComponent(componentInstance: any, attribute: Attribute, overrides?: any): void {
    this.setAttributeComponent(attribute);
    componentInstance.attribute = this.getAttributeConfig(overrides);
    componentInstance.model = attribute.value;
    componentInstance.modelChange.subscribe(event => {
      attribute.value = event;
    });
  }

  private setAttributeComponent(attribute: Attribute) {
    this.attributeTextInputConfig = MatUtilService.getAttributeTextInputConfigurationFromAttribute(attribute);
  }

}
