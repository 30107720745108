<pm-review #pmReview *ngIf="isViewingPage" [ngClass]="'review-grid-container'" (closeEvent)="onClose()">

  <pm-title>Review new product</pm-title>

  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages.length > 0 ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div *ngIf="!candidateUtilService.isPlu(candidate)" class="upc-header">
            UPC: {{candidate.candidateProducts[0].upc}}-{{candidate.candidateProducts[0].upcCheckDigit}}
          </div>
          <div *ngIf="candidateUtilService.isPlu(candidate)" class="upc-header">
            <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
              PLU: {{candidate.candidateProducts[0].upc | upc}}-{{candidate.candidateProducts[0].upc | upcCheckDigit}}<br>
            </div>
            <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
              UPC: {{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc)}}-{{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc) | upcCheckDigit}}
            </div>
          </div>
          <div class="attribute-description">
            <p>{{candidateProduct.description}}</p>
            <p>{{getProductInfoString()}}</p>
          </div>
          <button pButton type="button"
                  [label]="'View candidate history'"
                  class="ui-button-link" (click)="historyPanelOpen()">
          </button>
        </div>
      </div>

      <div *ngIf="candidateErrorUtils.hasCandidateProductErrorMessages(candidateError)">
        <div class="row ml-0">
          <div class="col col-md-10 same-store-error-container">
            <div class="row ml-1">
              <img class="ban-icon" src="/assets/images/ban-icon.svg">
              <div class="header-row">
                Missing Store Errors
              </div>
            </div>
            <ui class="row error-list-row">
              <li *ngFor="let error of candidateErrorUtils.getCandidateProductErrorMessages(candidateError)">
                {{error}}
              </li>
            </ui>
          </div>
        </div>
      </div>


      <pm-attribute-grid *ngIf="isSellable" [title]="'Retail set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="true"
                          [errorMessage]="candidateError?.prePrice"
                          (editClicked)="editCandidate(attributeType.PrePrice)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="true"
                          [errorMessage]="candidateError?.costLink"
                          (editClicked)="editCandidate(attributeType.CostLinked)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="true"
                          [errorMessage]="candidateError?.costLink"
                          (editClicked)="editCostLink()"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="true"
                          [errorMessages]="[candidateError?.suggestedXFor, candidateError?.suggestedRetailPrice]"
                          (editClicked)="editCandidate(attributeType.SuggestedRetail)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="true"
                          [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]"
                          (editClicked)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: {{costService.getMargin(candidate)}}%
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="true"
                          [errorMessage]="candidateError?.mapRetail"
                          (editClicked)="editCandidate(attributeType.MapRetail)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Retail set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="true"
                          [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]"
                          (editClicked)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.unitCost)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!--Cost set by buyer-->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Costs set by buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="true"
                          [errorMessage]="candidateError?.retailType"
                          (editClicked)="editPricingType()" >
              {{candidate.retailType}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              {{costService.getHebMargin(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="true"
                          [errorMessages]="[candidateError?.retailXFor, candidateError?.retailPrice]"
                          (editClicked)="editCandidate(attributeType.RetailPricing)">
              {{costService.getRetailPricing(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{costService.getHebPennyProfit(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="true"
                          [errorMessage]="candidateError?.masterPack"
                          (editClicked)="costService.editCostRelatedFields(attributeType.TotalRetail, candidate)">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="true"
                          [errorMessages]="[candidateError?.masterLength, candidateError?.masterWidth, candidateError?.masterHeight]"
                          (editClicked)="editCandidate(attributeType.Dimensions)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight, 1)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="true"
                          [errorMessages]="[candidateError?.vendorTie,candidateError?.vendorTier ]"
                          (editClicked)="editCandidate(attributeType.BoxesOnPallet)"
                          (historyClicked)="historyPanelOpen()">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'"
                          [canEdit]="isTiedToCatchOrVariableWeightBuyer()"
                          [errorMessage]="candidateError?.itemWeightType"
                          (editClicked)="editCandidate(attributeType.ItemWeightType)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="true"
                          [errorMessage]="candidateError?.masterWeight"
                          (editClicked)="editCandidate(attributeType.Weight)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="true"
                          [errorMessage]="candidateError?.cubeAdjustedFactor"
                          (editClicked)="editCandidate(attributeType.OrderFactor)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="codeDateEditable()"
                          [errorMessage]="candidateError?.codeDate"
                          (editClicked)="editCandidate(attributeType.CodeDate)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="candidate?.codeDate"
                          [errorMessage]="candidateError?.maxShelfLife"
                          (editClicked)="editCandidate(attributeType.MaxShelfLife)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="candidate?.codeDate"
                          [errorMessage]="candidateError?.inboundSpecDays"
                          (editClicked)="editCandidate(attributeType.InboundSpecDays)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid *ngIf="showCasePackInfo() && !candidate.dsdSwitch" [title]="'Inner packs'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="true"
                          [errorMessage]="candidateError?.innerPackSelected"
                          (editClicked)="costService.editCostRelatedFields(attributeType.InnerPackSelected, candidate)">
              {{candidate.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="true"
                          [errorMessage]="candidateError?.innerPack"
                          (editClicked)="costService.editCostRelatedFields(attributeType.InnerPackQuantity, candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.innerPack }}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="candidate.innerPackSelected"
                          [errorMessages]="[candidateError?.innerLength, candidateError?.innerWidth, candidateError?.innerHeight]"
                          (editClicked)="editCandidate(attributeType.InnerPackDimensions)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getInnerDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight, 1)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="candidate.innerPackSelected"
                          [errorMessage]="candidateError?.innerWeight"
                          (editClicked)="editCandidate(attributeType.InnerPackWeight)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="isSellable && candidate.warehouseSwitch" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="true"
                          [errorMessage]="candidateError?.displayReadyUnit"
                          (editClicked)="editCandidate(attributeType.DisplayReadyUnit)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnit | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.displayReadyUnit">
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="true"
                          [errorMessage]="candidateError?.displayReadyUnitOrientation"
                          (editClicked)="editCandidate(attributeType.Orientation)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="true"
                          [errorMessages]="[candidateError?.displayReadyUnitRowsDeep, candidateError?.displayReadyUnitRowsFacing, candidateError?.displayReadyUnitRowsHigh]"
                          (editClicked)="editCandidate(attributeType.RetailUnitCount)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnit ? candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh : 0}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit type'" [canEdit]="true"
                          [errorMessages]="candidateError?.displayReadyUnitType"
                          (editClicked)="editCandidate(attributeType.DisplayReadyUnitType)">
              {{candidateUtilService.getDruType(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Display ready units, not showing for non sellable -->

      <!-- Product details from buyer -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="true"
                          (editClicked)="editMerchandiseType()"
                          [errorMessage]="candidateError?.merchandiseType"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.merchandiseType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="true"
                          (editClicked)="editCommodity()"
                          [errorMessage]="candidateError?.commodity"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="true"
                          (editClicked)="editSubCommodity()"
                          [errorMessage]="candidateError?.subCommodity"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.subCommodity?.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>

          </pm-grid-column>

          <pm-grid-column>

            <pm-grid-cell [label]="'PSS department'" [canEdit]="pssDepartmentEditable()"
                          (editClicked)="editPssDepartment()"
                          [errorMessage]="candidateError?.pssDepartment"
                          (historyClicked)="historyPanelOpen()">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Like item code'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.LikeItemCode)"
                          [errorMessage]="candidateError?.likeId"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.likeId}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season & Year'" [canEdit]="true"
                          (editClicked)="editSeasonYear()"
                          [errorMessages]="[candidateError?.season, candidateError?.seasonYear]"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>


            <pm-grid-cell [label]="'Estimated store count'" [canEdit]="true"
                          [errorMessage]="candidateError?.numberOfStores"
                          (editClicked)="editCandidate(attributeType.EstimatedStoreCount)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.numberOfStores}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Sold by weight'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.SoldByWeight)"
                          [errorMessage]="candidateError?.weightSwitch"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.weightSwitch | yesNo}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Reaction days'" [canEdit]="candidate?.codeDate"
                          [errorMessage]="candidateError?.warehouseReactionDays"
                          (editClicked)="editCandidate(attributeType.ReactionDays)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="candidate?.codeDate"
                          [errorMessage]="candidateError?.guaranteeToStoreDays" (editClicked)="editCandidate(attributeType.GuaranteeDays)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!-- Product details from buyer -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="true"
                          (editClicked)="editMerchandiseType()"
                          [errorMessage]="candidateError?.merchandiseType"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.merchandiseType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Reaction days'" [canEdit]="candidate?.codeDate"
                          [errorMessage]="candidateError?.warehouseReactionDays"
                          (editClicked)="editCandidate(attributeType.ReactionDays)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'PSS department'" [canEdit]="pssDepartmentEditable()"
                          (editClicked)="editPssDepartment()"
                          [errorMessage]="candidateError?.pssDepartment"
                          (historyClicked)="historyPanelOpen()">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="true"
                          (editClicked)="editCommodity()"
                          [errorMessage]="candidateError?.commodity"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="candidate?.codeDate"
                          [errorMessage]="candidateError?.guaranteeToStoreDays"
                          (editClicked)="editCandidate(attributeType.GuaranteeDays)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="true"
                          (editClicked)="editSubCommodity()"
                          [errorMessage]="candidateError?.subCommodity"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.subCommodity?.subCommodityName}}  [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Warehouses -->
      <pm-attribute-grid *ngIf="showCasePackInfo() && !isDsdOnlyParms(candidate)" [title]="'Warehouses'">

        <!-- Warehouses grid content -->
        <pm-grid-group *ngIf="candidateProduct.warehouses.length > 0">
          <pm-grid-column>
            <pm-grid-cell [label]="'Max ship'" [canEdit]="true"
                          [errorMessage]="candidateError?.maxShip"
                          (editClicked)="editCandidate(attributeType.MaxShip)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.maxShip}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

        <ng-template ngFor let-warehouse [ngForOf]="candidateProduct.warehouses" let-index="index">

          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Warehouse'" [canEdit]="false" class="warehouse-grid-cell">
                {{warehouse.name}}
                <pm-grid-cell-info>
                  ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                </pm-grid-cell-info>
                <button pButton *ngIf="candidateProduct.warehouses.length !== 1" type="button" label="Remove" class="ui-button-link" (click)="removeWarehouse(index)"></button>
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order unit'" [canEdit]="true"
                            [errorMessage]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit"
                            (editClicked)="editWarehouse(warehouse, attributeType.WarehouseOrderUnit)"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderUnit?.description}}
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order restriction'" [canEdit]="true"
                            [errorMessage]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction"
                            (editClicked)="editOrderRestrictions(warehouse)"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderRestriction?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <div *ngIf="index !== candidateProduct.warehouses.length - 1" class="grid-divider"></div>
        </ng-template>
      </pm-attribute-grid>
      <div *ngIf="showCasePackInfo()" class="row">
        <div class="col-6">
          <button pButton type="button" label="View additional warehouse options" class="ui-button-link" (click)="openWarehouseDrawer()"></button>
        </div>
      </div>


      <!-- Authorized stores -->
      <pm-attribute-grid *ngIf="isDsdOnlyParms(candidate)" [title]="'Authorized stores'">
        <pm-grid-group *ngFor="let locationGroupStore of candidate.candidateProducts[0].locationGroupStores">
          <pm-grid-column>
            <pm-grid-cell [label]="'Distributor'" [canEdit]="true" (editClicked)="showStoreSearchPanel($event, authGroupsOverlay, authGroupsDiv)">
              <div>{{getVendor(locationGroupStore.splrLocationNumber)?.displayName}}</div>
              <pm-grid-cell-info>
                <b>Total stores: {{locationGroupStore.stores.length}} of {{getNumberOfStoresForGroup(locationGroupStore.splrLocationGroupId)}} stores</b>
              </pm-grid-cell-info>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Authorization group'">
              <div>{{locationGroupStore.splrLocationGroupId}}</div>
              <pm-grid-cell-info>
                <div *ngIf="locationGroupStore.listCost">${{locationGroupStore.listCost| number : '1.2-2'}}</div>
                <div *ngIf="!locationGroupStore.listCost">${{candidate?.masterListCost| number : '1.2-2'}}</div>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Stores'">
              <div class="mb-2">{{getAuthGroupLabel(locationGroupStore)}}</div>
              <div *ngFor="let store of locationGroupStore.stores">
                <div class="ui-grid-col-2 mr-2 mb-2" style="background: #f2f2f2; text-align: center;">{{store.custLocationNumber}}</div>
              </div>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>

        <pm-grid-cell *ngIf="candidateProductError?.missingStoresError"
                      [errorMessage]="candidateProductError?.missingStoresError">
        </pm-grid-cell>

      </pm-attribute-grid>

      <div *ngIf="candidate.dsdSwitch && !hasStores()" class="addDistributors">
        <button pButton label="Set up Stores" class="ui-button-info mt-2" (click)="showStoreSearchPanel($event, authGroupsOverlay, authGroupsDiv)"></button>
      </div>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="true"
                          [errorMessage]="candidateError?.brand"
                          (editClicked)="editBrandModal()"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="true"
                          [errorMessage]="candidateProductError?.subBrand"
                          (editClicked)="showEditCandidateProductModal(attributeType.SubBrand)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="true"
                          [errorMessage]="candidateError?.costOwner"
                          (editClicked)="editCostOwner()"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="true"
                          [errorMessages]="[candidateError?.contactName, candidateError?.contactEmail,candidateError?.contactNumber]"
                          (editClicked)="editContact()"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false"
                          [errorMessage]="candidateError?.topToTop"
                          (historyClicked)="historyPanelOpen()">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.Vendor)"
                          [errorMessage]="candidateError?.vendor"
                          (historyClicked)="historyPanelOpen()">

              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="true"
                          (editClicked)="editWarehouseSupplier()"
                          [errorMessage]="candidateError?.lane"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="true" (editClicked)="editBuyer()"
                          [errorMessage]="candidateError?.buyer"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Channel'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.Channel)"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">

              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.Sellable)"
                          [errorMessage]="candidateError?.productType"
                          (historyClicked)="historyPanelOpen()">
              {{getSellableString()}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="true"
                          (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.FoodStampEligible)"
                          [errorMessage]="candidateError?.foodStamp"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.Taxable)"
                          [errorMessage]="candidateError?.taxable"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="true" [label]="'FSA/HSA eligible'"
                          (editClicked)="editCandidate(attributeType.FSAEligible)"
                          [errorMessage]="candidateError?.flexibleSpendingAccount"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="true"
                          (editClicked)="editPackageType()"
                          [errorMessage]="candidateError?.packageType"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="true"
                          (editClicked)="editUnitOfMeasure()"
                          [errorMessage]="candidateError?.unitOfMeasure"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.RetailSize)"
                          [errorMessage]="candidateError?.retailSize"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.retailSize}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Total volume'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.TotalVolume)"
                          [errorMessage]="candidateError?.totalVolume"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Show Calories'" [canEdit]="true" (editClicked)="editShowCalories()"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.showCalories | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.ProductDimensions)"
                          [errorMessages]="[candidateError?.productLength, candidateError?.productWidth, candidateError?.productHeight]"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.ProductWeight)"
                          [errorMessage]="candidateError?.productWeight"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Nutrition panel status'" [canEdit]="false">Approved
              <pm-grid-cell-info>
                <button style="font-size: 14px;" pButton type="button"
                        [label]="'View panel'"
                        class="ui-button-link" (click)="onOpenNutritionDrawer()">
                </button>
              </pm-grid-cell-info>
            </pm-grid-cell>

          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Qualifying conditions'" [canEdit]="true" (editClicked)="editQualifyingConditions()">
              {{candidateProduct?.qualifyingCondition?.description}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Tax category'" [canEdit]="true" (editClicked)="editVertexTaxCategory()"
                          [errorMessage]="candidateError?.vertexTaxCategory">
              {{candidate.vertexTaxCategoryModel?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line & Packaging -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product line & Packaging'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="true"
                          (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Package type'" [canEdit]="true"
                          (editClicked)="editPackageType()"
                          [errorMessage]="candidateError?.packageType"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="true"
                          (editClicked)="editUnitOfMeasure()"
                          [errorMessage]="candidateError?.unitOfMeasure"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Qualifying conditions'" [canEdit]="true" (editClicked)="editQualifyingConditions()">
              {{candidateProduct?.qualifyingCondition?.description}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>

            <pm-grid-cell [label]="'Dimensions'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.ProductDimensions)"
                          [errorMessages]="[candidateError?.productLength, candidateError?.productWidth, candidateError?.productHeight]"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.ProductWeight)"
                          [errorMessage]="candidateError?.productWeight"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Show Calories'" [canEdit]="true" (editClicked)="editShowCalories()"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.showCalories | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'Nutrition panel status'">Approved
              <pm-grid-cell-info>
                <button (click)="onOpenNutritionDrawer()" [label]="'View panel'" class="ui-button-link"
                        pButton
                        style="font-size: 14px;" type="button">
                </button>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Total volume'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.TotalVolume)"
                          [errorMessage]="candidateError?.totalVolume"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="true"
                          (editClicked)="editCandidate(attributeType.RetailSize)"
                          [errorMessage]="candidateError?.retailSize"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.retailSize}}
            </pm-grid-cell>
            <pm-grid-cell (editClicked)="editVertexTaxCategory()" [canEdit]="true"
                          [errorMessage]="candidateError?.vertexTaxCategory" [label]="'Tax category'">
              {{candidate.vertexTaxCategoryModel?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>


      <!--Scale information-->
      <pm-attribute-grid *ngIf="candidateUtilService.isScaleProduct(candidate)" [title]="'Scale information'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Ingredient stmt #'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.IngredientStatement)"
                          [errorMessage]="candidateProductError?.scaleInformation?.ingredientStatementNumber">
              {{candidateProduct?.scaleInformation.ingredientStatementNumber}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pre-pack tare'" [canEdit]="true"
                          (editClicked)="editPrePackTareModal()"
                          [errorMessage]="candidateProductError?.scaleInformation?.prePackTare">
              {{candidateProduct?.scaleInformation.prePackTare}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf life'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.ShelfLifeDays)"
                          [errorMessage]="candidateProductError?.scaleInformation?.shelfLifeDays">
              {{getDaysString(candidateProduct?.scaleInformation.shelfLifeDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Graphics code 1'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.GraphicsCodeOne)"
                          [errorMessage]="candidateProductError?.scaleInformation?.graphicsCodeOne">
              {{candidateProduct?.scaleInformation.graphicsCodeOne | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Action code'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.ActionCode)"
                          [errorMessage]="candidateProductError?.scaleInformation?.actionCode">
              {{candidateProduct?.scaleInformation.actionCode}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Nutrition stmt #'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.NutritionStatementNumber)"
                          [errorMessage]="candidateProductError?.scaleInformation?.nutritionStatementNumber">
              {{candidateProduct?.scaleInformation.nutritionStatementNumber | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Service counter tare'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.ServiceCounterTare)"
                          [errorMessage]="candidateProductError?.scaleInformation?.serviceCounterTare">
              {{candidateProduct?.scaleInformation.serviceCounterTare | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Mechanically tend.'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.MechanicallyTenderized)"
                          [errorMessage]="candidateProductError?.scaleInformation?.mechanicallyTenderized">
              {{candidateProduct?.scaleInformation.mechanicallyTenderized | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Graphics code 2'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.GraphicsCodeTwo)"
                          [errorMessage]="candidateProductError?.scaleInformation?.graphicsCodeTwo">
              {{candidateProduct?.scaleInformation.graphicsCodeTwo | emptyToValue: '-'}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Grade #'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.GradeNumber)"
                          [errorMessage]="candidateProductError?.scaleInformation?.gradeNumber">
              {{candidateProduct?.scaleInformation.gradeNumber | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Force tare'" [canEdit]="true"
                          (editClicked)="editForceTareModal()"
                          [errorMessage]="candidateProductError?.scaleInformation?.forceTare">
              {{candidateProduct?.scaleInformation.forceTare | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Net weight'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.NetWeight)"
                          [errorMessage]="candidateProductError?.scaleInformation?.netWeight">
              {{candidateProduct?.scaleInformation.netWeight | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Graphics code 3'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.GraphicsCodeThree)"
                          [errorMessage]="candidateProductError?.scaleInformation?.graphicsCodeThree">
              {{candidateProduct?.scaleInformation.graphicsCodeThree | emptyToValue: '-'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <pm-attribute-grid *ngIf="candidateUtilService.isScaleProduct(candidate)" [title]="'Scale labels'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'English label 1'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.EnglishLabelOne)"
                          [errorMessage]="candidateProductError?.scaleInformation?.englishLabelOne">
              {{candidateProduct?.scaleInformation.englishLabelOne | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'English label 3'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.EnglishLabelThree)"
                          [errorMessage]="candidateProductError?.scaleInformation?.englishLabelThree">
              {{candidateProduct?.scaleInformation.englishLabelThree | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Bilingual label'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.BilingualLabels)">
              {{candidateProduct?.scaleInformation.bilingual | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="candidateProduct?.scaleInformation.bilingual" [label]="'English label 1 (bilingual)'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.EnglishLabelOneBilingual)"
                          [errorMessage]="candidateProductError?.scaleInformation?.englishLabelOneBilingual">
              {{candidateProduct?.scaleInformation.englishLabelOneBilingual | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="candidateProduct?.scaleInformation.bilingual" [label]="'Spanish label 1'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.SpanishLabelOne)"
                          [errorMessage]="candidateProductError?.scaleInformation?.spanishLabelOne">
              {{candidateProduct?.scaleInformation.spanishLabelOne | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Label format 1'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.LabelFormatOne)"
                          [errorMessage]="candidateProductError?.scaleInformation?.labelFormatOne">
              {{candidateProduct?.scaleInformation.labelFormatOne | emptyToValue: '-'}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'English label 2'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.EnglishLabelTwo)"
                          [errorMessage]="candidateProductError?.scaleInformation?.englishLabelTwo">
              {{candidateProduct?.scaleInformation.englishLabelTwo | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'English label 4'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.EnglishLabelFour)"
                          [errorMessage]="candidateProductError?.scaleInformation?.englishLabelFour">
              {{candidateProduct?.scaleInformation.englishLabelFour | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell *ngIf="candidateProduct?.scaleInformation.bilingual" [label]="'English label 2 (bilingual)'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.EnglishLabelTwoBilingual)"
                          [errorMessage]="candidateProductError?.scaleInformation?.englishLabelTwoBilingual">
              {{candidateProduct?.scaleInformation.englishLabelTwoBilingual | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="candidateProduct?.scaleInformation.bilingual" [label]="'Spanish label 2'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.SpanishLabelTwo)"
                          [errorMessage]="candidateProductError?.scaleInformation?.spanishLabelTwo">
              {{candidateProduct?.scaleInformation.spanishLabelTwo | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Label format 2'" [canEdit]="true"
                          (editClicked)="editScaleAttributeModal(attributeType.LabelFormatTwo)"
                          [errorMessage]="candidateProductError?.scaleInformation?.labelFormatTwo">
              {{candidateProduct?.scaleInformation.labelFormatTwo | emptyToValue: '-'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!-- Unit Details -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="true" (editClicked)="editProductUPC()"
                            [errorMessages]="[candidateProductError?.upc, candidateProductError?.upcCheckDigit]"
                            (historyClicked)="historyPanelOpen()">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="true"
                            (editClicked)="editPlu()"
                            [errorMessages]="[candidateProductError?.upc, candidateProductError?.upcCheckDigit]"
                            (historyClicked)="historyPanelOpen()">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="true"
                          [errorMessage]="candidateProductError?.description"
                          (editClicked)="editProductDescription()"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="true"
                          [errorMessage]="candidateProductError?.customerFriendlyDescription1"
                          (editClicked)="editCandidateProduct(attributeType.ShelfTag1)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.customerFriendlyDescription1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="true"
                          [errorMessage]="candidateProductError?.customerFriendlyDescription2"
                          (editClicked)="editCandidateProduct(attributeType.ShelfTag2)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.customerFriendlyDescription2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="true"
                          [errorMessage]="candidateProductError?.romanceCopy"
                          (editClicked)="editCandidateProduct(attributeType.ECommCopy)"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.romanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="true"  [errorMessage]="candidateProductError?.categorySelectionError" (editClicked)="editCategory()">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Images'" [canEdit]="true"
                          (editClicked)="editCandidateProduct(attributeType.ProductImages)"
                          [errorMessage]="candidateProductError?.images">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Unit Details -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="true" (editClicked)="editProductUPC()" [errorMessages]="[candidateProductError?.upc, candidateProductError?.upcCheckDigit]"
                            (historyClicked)="historyPanelOpen()">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="true"
                            (editClicked)="editPlu()"
                            [errorMessages]="[candidateProductError?.upc, candidateProductError?.upcCheckDigit]"
                            (historyClicked)="historyPanelOpen()">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="true"
                          [errorMessage]="candidateProductError?.description"
                          (editClicked)="editProductDescription()"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="true"  [errorMessage]="candidateProductError?.categorySelectionError" (editClicked)="editCategory()">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="true"
                          (editClicked)="editCandidateProduct(attributeType.ProductImages)"
                          [errorMessage]="candidateProductError?.images">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Global Attributes   -->
      <div *ngIf="showMatAttributes">
        <pm-attribute-grid [title]="'Extended attributes: Product'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>

        <pm-attribute-grid [title]="'Extended attributes: UPC'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>

        <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && isLoadingMatGlobalData" [title]="'Extended attributes: Case'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
        </pm-attribute-grid>
        <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && !isLoadingMatGlobalData && !!supplierProductService?.warehouseItemAttributes.length" [title]="'Extended attributes: Case'">
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>
      </div>
      <!--   Mat Hierarchy   -->
      <pm-attribute-grid *ngIf="showMatAttributes && isLoadingMatHierarchyData && matUtilService.getMatHierarchyList(candidate).length > 0" [title]="'Category attributes'">
        <div style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingMatHierarchyData" [strokeWidth]="'2'"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching extended attribute data...</h5>
        </div>
      </pm-attribute-grid>
      <div *ngIf="showMatAttributes && supplierProductService.hierarchyNumberToAttributesMap.size > 0">
        <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
          <pm-attribute-grid *ngIf="supplierProductService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'Comments'" [canEdit]="true"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.scaComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate.piaComment">
                <b>{{piaName}}</b> <br>
                {{candidate.piaComment}}
              </div>
              <br>
              <div *ngIf="!!candidate.obRegComment">
                <b>{{candidate.obRegName}}</b> <br>
                {{candidate.obRegComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="true" (editClicked)="editCandidateProduct(attributeType.Remark1)" [errorMessage]="candidateProductError?.remark1">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="true" (editClicked)="editCandidateProduct(attributeType.Remark2)" [errorMessage]="candidateProductError?.remark2">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <pm-review-drawer-container>
    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="piaComments"
                                           [(model)]="tempCommentHolder">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.piaComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.piaComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-review-drawer-container >
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="historyService.sortedAudits">
            <div *ngIf="historyService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{historyService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-review-drawer-container>
    <ng-container *ngIf="candidate && showWarehousePanel && !openCommentDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link" (click)="save()">
            </button>
          </div>
        </div>
        <h2>Additional warehouses</h2>
        <hr/>
        <ng-template ngFor let-warehouse [ngForOf]="warehouseData">
          <div class="attribute-group">
            <div>
              <p-checkbox id="whsCheckbox"
                          [(ngModel)]="warehouse.checked"
                          (onChange)="selectedWarehouseChange($event, warehouse)"
                          binary="true">
              </p-checkbox>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{warehouse.name}}
                </label>
              </div>
              <p class="attribute-description">
                ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
              </p>
              <p *ngIf="warehouse.checked && warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
                 class="error-message bicep-error">
                {{warehouseCandidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
              </p>
            </div>
          </div>
          <div *ngIf="warehouse.checked">
            <div class="attributes-stacked">
              <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                          [(model)]="warehouse.orderUnitId"
                                          (change)="orderUnitChange($event, warehouse)"
                                          [attributeError]="warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
              </pm-attribute-display-radio>
              <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                              [(model)]="warehouse.orderRestriction"
                                              (selection)="orderRestrictionChange($event, warehouse)"
                                              [attributeError]="warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
              </pm-attribute-display-typeahead>
            </div>
          </div>
          <hr/>
        </ng-template>
        <div class="text-right">
          <p-button class="m-2" label="Cancel" (onClick)="collapse()"></p-button>
          <p-button class="m-2" label="Update" (onClick)="update()" [disabled]="!this.isSelectedWarehouse()"></p-button>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-review-drawer-container>
    <ng-container *ngIf="candidate && openNutritionDrawer && nutritionResults && candidate.showCalories">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapseNutritionDrawer()"></button>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            UPC: {{candidate.candidateProducts[0].upc}}
          </div>
          <div class="row">
            Source: Genesis
          </div>
          <div class="row">
            Serving Size {{servingSize}}
          </div>
          <div class="row">
            Serving Per Container {{servingsPerContainer}}
          </div>
        </div>
        <br>

        <p-table [columns]="nutritionHeaderColumns"
                 [resizableColumns]="true" [autoLayout]="true"
                 [value]="nutritionResults">

          <ng-template pTemplate="header" let-nutritionHeaderColumns>
            <tr>
              <th *ngFor="let col of nutritionHeaderColumns" [pSortableColumn]="col.field"
                  [pSortableColumnDisabled]="!col.sortable" [ngStyle]="{'width' : col.width}">
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
              </th>
            </tr>
          </ng-template>


          <ng-template pTemplate="body" let-nutrition >
            <tr style="height: 16px !important;">

              <td>{{nutrition?.field}}</td>
              <td>{{nutrition?.gramPerServing}}</td>
              <td>{{nutrition?.dailyValue}}</td>

            </tr>
          </ng-template>

        </p-table>

        <br>
        <div class="col-12">
          Ingredients List: {{ingredientsList}}
          <br>
          <br>
          Allergens: {{allergensList}}
        </div>
        <br>
      </div>
    </ng-container>

    <ng-container *ngIf="candidate && openNutritionDrawer && !nutritionResults && candidate.showCalories">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapseNutritionDrawer()"></button>
          </div>
        </div>
        <h5>Pending Genesis Data</h5>
      </div>
    </ng-container>

    <ng-container *ngIf="candidate && openNutritionDrawer && scaleNutritionPanel && !candidate.showCalories">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapseNutritionDrawer()"></button>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            UPC: {{candidate.candidateProducts[0].upc}}
          </div>
          <div class="row">
            Source: Scales
          </div>
          <div class="row">
            Serving Size: {{scaleNutritionPanel.servingSize}}
          </div>
          <div class="row">
            Serving Per Container: {{scaleNutritionPanel.servingsPerContainer}}
          </div>
        </div>
        <br>
        <p-table [columns]="nutritionHeaderColumns" [resizableColumns]="true" [autoLayout]="true" [value]="scaleNutritionPanel.columns[0].detail">
          <ng-template pTemplate="header" let-nutritionHeaderColumns>
            <tr>
              <th *ngFor="let col of nutritionHeaderColumns" [pSortableColumn]="col.field"
                  [pSortableColumnDisabled]="!col.sortable" [ngStyle]="{'width' : col.width}">
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-nutrition >
            <tr style="height: 16px !important;">
              <td>{{nutrition?.description}}</td>
              <td>{{nutrition?.nutrientValue}}</td>
              <td>{{nutrition?.nutrientPercentDailyValue}}</td>
            </tr>
          </ng-template>
        </p-table>
        <br>
        <div class="col-12">
          Ingredients List: {{scaleNutritionPanel?.ingredients}}
          <br>
          <br>
          Allergens: {{scaleNutritionPanel?.allergens}}
        </div>
        <br>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Reject" (onClick)="showPanel($event, rejectOverlay, rejectCandidateDiv)" [disabled]="isRejectDisabled"></p-button>
    <p-button class="m-2" label="{{getActivateButtonTitle()}}" (onClick)="onClickActivate()" [disabled]="isActivateButtonDisabled()"></p-button>
  </pm-footer>
</pm-review>

<div>
  <div #rejectCandidateDiv></div>
  <pm-static-overlay-panel #rejectOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
    <pm-reject-candidate-overlay [rejectMessage]="rejectMessage" (cancelClicked)="rejectOverlay.hide()" (rejectButtonClicked)="reject($event, rejectOverlay, rejectCandidateDiv)"></pm-reject-candidate-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #authGroupsDiv></div>
  <pm-static-overlay-panel [hideTransitionOptions]="'0ms ease-in'" [showTransitionOptions]="'0ms ease-out'" #authGroupsOverlay appendTo="body" [style]="{width: '100%', height: '100%'}">
    <app-set-up-stores-overlay *ngIf="showStoreOverlay" [canClickSave]="canClickSave"
                               [candidateError]="supplierOverlayCandidateError" [candidateProductError]="supplierOverlayCandidateProductError"
                               (cancelButtonClicked)="hideStoreSearchPanel(authGroupsOverlay)" (saveButtonClicked)="saveClicked($event, authGroupsOverlay)">

    </app-set-up-stores-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatConfirmDiv></div>
  <pm-static-overlay-panel #rnaMatConfirmOverlayPanel appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'" [hideTransitionOptions]="'0ms'">
    <pm-request-new-attribute-confirm-overlay *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                              [title]="'Request a new ' + requestNewMatAttributeOverrideWrapper?.requestNewMatAttributeForm?.attributeBusinessFriendlyDescription + ' value'"
                                              [classOverride]="'col-md-5'"
                                              (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatConfirmOverlayPanel, requestNewMatAttributeOverrideWrapper)"
                                              (continueButtonClicked)="matUtilService.showRequestNewAttributeFormPanel($event, rnaMatFormOverlay, rnaMatFormOverlayDiv, rnaMatConfirmOverlayPanel)">
    </pm-request-new-attribute-confirm-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatFormOverlayDiv></div>
  <pm-static-overlay-panel #rnaMatFormOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'">
    <pm-request-new-mat-attribute-form *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                       (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatFormOverlay, requestNewMatAttributeOverrideWrapper)"
                                       [(model)]="requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm"
                                       (sendRequestClicked)="matUtilService.sendRequestAndCloseModal(this.candidate, requestNewMatAttributeOverrideWrapper, rnaMatFormOverlay)">
    </pm-request-new-mat-attribute-form>
  </pm-static-overlay-panel>
</div>
