import {BaseAttribute} from 'pm-components/lib/attributes/attribute-type/base-attribute';
import {Attribute, AttributeConfig} from 'pm-models';
import {MatUtilService} from '../../../../../../src/app/2.0.0/service/mat-util.service';
import {
  AttributeDisplayDatePickerComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-date-picker/attribute-display-date-picker.component';

export class MatAttributeDatePicker implements BaseAttribute {

  constructor() { }

  private attributeDatePickerConfig: AttributeConfig;

  getAttributeConfig(overrides): AttributeConfig {
    return this.attributeDatePickerConfig;
  }

  getComponentType(): any {
    return AttributeDisplayDatePickerComponent;
  }

  setupComponent(componentInstance: any, attribute: Attribute, overrides?: any): void {
    this.setAttributeComponent(attribute);
    componentInstance.attribute = this.getAttributeConfig(overrides);
    componentInstance.model = attribute.value;
    componentInstance.modelChange.subscribe(event => {
      attribute.value = event;
    });
  }

  private setAttributeComponent(attribute: Attribute) {
    this.attributeDatePickerConfig = MatUtilService.getAttributeDisplayDatePickerConfigFromAttribute(attribute);
  }
}
