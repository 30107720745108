<div class="pm-editor-body">
  <pm-drawer-container class="d-flex flex-grow-1 w-100 pm-editor-page">
    <pm-drawer-content class="w-100  bg-surface">

      <div class="container">
        <div class="pm-editor-header px-3 py-4 col">
          <div class="row">
            <div class="col-1">
              <pm-close (click)="closeEvent.emit()"></pm-close>
            </div>
            <div class="col">
              <ng-content select="pm-header"></ng-content>
            </div>
          </div>
        </div>
      </div>

      <div class="header-and-body">
        <div class="container">
          <header class="row pt-5" *ngIf="headerFacet">
            <div class="col offset-2 offset-md-3">
              <h1 class="editor-title">
                <ng-content select="pm-title"></ng-content>
              </h1>
              <h2 class="editor-sub-title">
                <ng-content select="pm-sub-title"></ng-content>
              </h2>
            </div>
          </header>

          <div class="row">
            <div class="col-2 col-md-3">
              <ng-content select="pm-sidebar"></ng-content>
            </div>
            <div class="col-10 col-md-9">
              <ng-content></ng-content>
            </div>
          </div>

        </div>
      </div>
      <div class="container">
        <footer class="row editor-footer" *ngIf="footerFacet">
          <div class="col-6 py-3 offset-3 text-right">
            <ng-content select="pm-footer"></ng-content>
          </div>
        </footer>
      </div>
    </pm-drawer-content>
    <pm-drawer #drawer mode="side" position="end" [(opened)]="opened" class="drawer-wrapper">
      <ng-content select="pm-editor-drawer-container"></ng-content>
    </pm-drawer>
  </pm-drawer-container>
</div>
