import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BaseAttributeDisplayComponent} from '../base-attribute-display/base-attribute-display.component';
import {AttributeTypeaheadConfig} from 'pm-models';

@Component({
  selector: 'pm-attribute-display-typeahead',
  templateUrl: './attribute-display-typeahead.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayTypeaheadComponent extends BaseAttributeDisplayComponent implements OnInit {

  @Input()
  public attribute?: AttributeTypeaheadConfig;

  @Input()
  public isDropdown = true;

  @Input()
  public maxListSize = 5000;

  @Input()
  public showErrorMessage = true;

  @Input()
  public showExtraButton = false;

  @Input()
  public warningMessageText?: String;

  @Input()
  public showWarningMessage: boolean = false;

  @Output()
  public selection?: EventEmitter<any> = new EventEmitter();

  @Output()
  public initCallback?: EventEmitter<any> = new EventEmitter();

  @Output()
  buttonOptionClicked?: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  extraButtonClicked?: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit() {
  }
  handleChange(event) {
    this.model = event;
    this.selection.emit(event);
  }

  isReadOnly() {
    return (this.attribute.isReadOnly &&
      this.attribute.isReadOnly() === true);
  }
  getReadOnlyText() {
    if (this.attribute && this.model && this.model[this.attribute.displayRef]) {
      return this.model[this.attribute.displayRef];
    } else if (this.attribute && typeof this.model === 'string') {
      return this.model;
    }
  }
  getReadOnlyDescription() {
    if (this.attribute && this.attribute.displaySubRef && this.model[this.attribute.displaySubRef]) {
      return this.model[this.attribute.displaySubRef];
    }
  }
  onInitCallback(event) {
    this.initCallback.emit(event);
  }

  onButtonOptionClicked(event) {
    this.buttonOptionClicked.emit(event);
  }

  onExtraButtonClicked(event) {
    this.model = null;
    this.extraButtonClicked.emit(event);
  }
}
