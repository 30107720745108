import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {CardModule} from '../card/card.module';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {EditCandidateModalServiceMock} from '../../cards/regulatory-card/edit-candidate-modal-service-mock.service';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {LookupServiceMock} from '../../cards/regulatory-card/lookup-service-mock.service';
import {UploadSupplierAndHebTableComponent} from './upload-supplier-and-heb-table.component';
import {WorkflowService} from '../../../../../../src/app/2.0.0/service/workflow.service';
import {WorkflowServiceMock} from '../../cards/regulatory-card/workflow-service-mock.service';

@NgModule({
  declarations: [UploadSupplierAndHebTableComponent],
  exports: [UploadSupplierAndHebTableComponent, ButtonModule, CardModule, AttributeDisplayModule],
  imports: [CommonModule, CardModule, AttributeDisplayModule, TableModule, CheckboxModule],
  providers: [
    { provide: EditCandidateModalService, useClass: EditCandidateModalServiceMock },
    { provide: LookupService, useClass: LookupServiceMock },
    { provide: WorkflowService, useClass: WorkflowServiceMock }]
})
export class UploadSupplierAndHebTableModule {}
