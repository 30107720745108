import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayReadyUnitCardComponent } from './display-ready-unit-card.component';
import { CardModule } from '../../shared/card/card.module';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';



@NgModule({
  declarations: [DisplayReadyUnitCardComponent],
  exports: [DisplayReadyUnitCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule,
    AttributeDisplayModule
  ]
})
export class DisplayReadyUnitCardModule { }
