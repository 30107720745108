import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, ContentChild } from '@angular/core';
import { SubTitleComponent, TitleComponent } from '../common';
@Component({
  selector: 'pm-card',
  templateUrl: './card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() title: string;

  @Input() subtitle: string;

  @Input() style: any;

  @Input() styleClass: string;

  @ContentChild(TitleComponent, { static: true }) titleFacet;
  @ContentChild(SubTitleComponent, { static: true }) subTitleFacet;

  constructor() {}
}
