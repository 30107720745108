import {Component, OnInit} from '@angular/core';
import {
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  LocationGroupStores,
  Store,
  TaskDecision,
  TextInputType
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {CostService} from '../../service/cost.service';
import {CandidateService} from '../../service/candidate.service';
import {PiaProductService} from '../../service/pia-product.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {WorkflowService} from '../../service/workflow.service';
import {GrowlService} from '../../growl/growl.service';
import {CandidateErrorUtilService} from '../../service/candidate-error-util.service';
import {PiaNewProductFlowStepperComponent} from '../pia-new-product-flow-stepper/pia-new-product-flow-stepper.component';
import {PiaNewDsdProductFlowStepperComponent} from '../pia-new-dsd-product-flow-stepper/pia-new-dsd-product-flow-stepper.component';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-set-up-stores',
  templateUrl: './pia-set-up-stores.component.html',
  styleUrls: ['./pia-set-up-stores.component.scss']
})
export class PiaSetUpStoresComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public piaProductService: PiaProductService,
              public costService: CostService, private candidateService: CandidateService,
              public candidateUtilService: CandidateUtilService, private growlService: GrowlService,
              private workflowService: WorkflowService, public candidateErrorUtilService: CandidateErrorUtilService,
              private permissionService: NgxPermissionsService) { }

  public isViewingPage = true;
  public currentCandidateProductIndex;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public canClickSubmit = true;
  public distributorName: any;
  public showMatAttributes = false;

  private validators: CandidateValidatorType[] = [CandidateValidatorType.PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR];

  listCostConfiguration: AttributeTextInputConfig = {
    label: 'List cost',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    textInputType: TextInputType.decimal,
    placeholderText: '$',
    numberCount: 4,
    decimalCount: 2
  };

  ngOnInit() {

    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.piaProductService.getTaskId(), this.piaProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.piaProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.candidateError = this.piaProductService.getStoreAuthError();
            this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
            this.currentCandidateProductIndex = this.piaProductService.getCurrentCandidateProductIndex();
            this.initializeData();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.piaProductService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.piaProductService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  initializeData() {
    this.distributorName = this.piaProductService.getCandidate().vendor.description;
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.showMatAttributes = true;
    }
    // if there's no auth selected auth groups, initialize the data.
    if (!this.piaProductService.getSelectedAuthGroups()) {
      // if there's auth groups and no candidate data, set the selected to the auth groups data, else initialize the data
      // prefilled w/ candidate info.
      if (this.piaProductService.getAuthGroups()) {
        if (!this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.piaProductService.setSelectedAuthGroups(this.piaProductService.getAuthGroups());

          this.piaProductService.openAuthGroups();
        } else {
          this.piaProductService.setSelectedAuthGroupsFromCandidate();
        }

        // else Fetch auth groups and set SelectedAuthGroups
      } else if (this.piaProductService.getCandidate().overrideDepartment &&
        this.piaProductService.getCandidate().overrideSubDepartment) {

        this.piaProductService.findAllAuthGroupsByParms(this.piaProductService.getCandidate().vendor.apNumber,
          this.piaProductService.getCandidate().overrideSubDepartment.departmentId,
          this.piaProductService.getCandidate().overrideSubDepartment.subDepartmentId)
          .subscribe((authGroups) => {
            this.piaProductService.setAuthGroups(authGroups);
            // if there's no candidate data, set the selected to the auth groups data, else initialize the data
            // prefilled w/ candidate info.
            if (!this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores ||
              this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
              this.piaProductService.setSelectedAuthGroups(this.piaProductService.getAuthGroups());

              this.piaProductService.openAuthGroups();
            } else {
              this.piaProductService.setSelectedAuthGroupsFromCandidate();
            }
          });
      } else {
        this.piaProductService.findAllAuthGroups().subscribe((authGroups) => {

          this.piaProductService.setAuthGroups(authGroups);

          // if there's no candidate data, set the selected to the auth groups data, else initialize the data
          // prefilled w/ candidate info.
          if (!this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores ||
            this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
            this.piaProductService.setSelectedAuthGroups(this.piaProductService.getAuthGroups());

            this.piaProductService.openAuthGroups();
          } else {
            this.piaProductService.setSelectedAuthGroupsFromCandidate();
          }
        });
      }
    }
  }



  onClickSubmit() {
    // validate the candidate for this page and send errors to components to display, if any
    this.canClickSubmit = false;
    this.applySelectedStoresToCandidate();
    if (this.piaProductService.isScaleProduct(this.piaProductService.getCandidate())) {
      this.validators.push(CandidateValidatorType.SCALE_ENGLISH_LABEL_VALIDATOR);
    }
    this.candidateService.validateCandidate(this.piaProductService.getCandidate(), this.validators).toPromise().then(data => {

      if (this.piaProductService.isScaleProduct(this.piaProductService.getCandidate()) || this.piaProductService.getCandidate().showCalories) {
        this.saveAndCompletePiaTask();
      } else {
        this.candidateService.activateCandidate(this.piaProductService.getCandidate()).subscribe(() => {
          this.completeTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE,
            TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
            'Successfully activated candidate.'
          );
        }, (error) => {
          this.growlService.addError(error);
          this.canClickSubmit = true;
        });
      }
    }, (error) => {
      this.piaProductService.scrollToTop();
      if (error.error?.candidateErrors?.hasErrors) {
        this.piaProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.piaProductService.getStoreAuthError();
        this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canClickSubmit = true;
    });
  }

  onClickBack() {
    this.applySelectedStoresToCandidate();
    this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
  }

  onClickBackToStoreSelection() {
    this.applySelectedStoresToCandidate();
    this.piaProductService.saveCandidateAndNavigate('/piaStoreAuthorization', false);
  }

  onClose() {
    this.isViewingPage = false;
    this.applySelectedStoresToCandidate();
    this.piaProductService.saveCandidateAndClose();
  }

  onClickAuthGroupCaret(authGroup) {
    authGroup.isViewing = !authGroup.isViewing;
  }

  getSelectedStoresCountString(authGroup) {
    if (!authGroup.stores || authGroup.stores.length === 0) {
      return '';
    }
    let count = 0;
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].checked) {
        count++;
      }
    }
    return count + ' of ' + authGroup.stores.length + ' stores selected.';
  }


  onStoreChecked(event, store, authGroup) {
    const checked = event.checked;

    if (!checked) {
      authGroup.checked = checked;
    } else {
      let areAllStoresChecked = true;
      for (let x = 0; x < authGroup.stores.length; x++) {
        if (!authGroup.stores[x].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        authGroup.checked = true;
      }
    }
  }

  onListCostChange(event, authGroup) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].listCost = event;
    }
  }


  listCostFocus(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }


  onClickApplyToAllGroups(listCost, listCostOverlay) {
    for (let x = 0; x < this.piaProductService.getSelectedAuthGroups().length; x++) {
      this.piaProductService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.piaProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.piaProductService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }
    listCostOverlay.hide();
  }

  onClickApplyToAllRemainingGroups(listCost, listCostOverlay) {
    for (let x = 0; x < this.piaProductService.getSelectedAuthGroups().length; x++) {
      if (this.piaProductService.getSelectedAuthGroups()[x].listCost) {
        continue;
      }
      this.piaProductService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.piaProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.piaProductService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }
    listCostOverlay.hide();
  }

  getListCostOverlayStyle(index) {
    if (index === 0) {
      return {margin: '-1.375rem 0 0 16rem'};
    } else {
      return {margin: '-2rem 0 0 16rem'};
    }
  }

  getAllStoreAuthCountsString() {
    if (!this.piaProductService.getSelectedAuthGroups()) {
      return '';
    }
    let totalCount = 0;
    for (let x = 0; x < this.piaProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.piaProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.piaProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          totalCount++;
        }
      }
    }
    return '' + totalCount + ' stores selected';
  }

  getStoreAuthCountsString(authGroup: LocationGroupStores) {
    if (!authGroup) {
      return '';
    }
    let storeCount = 0;
    for (let y = 0; y < authGroup.stores.length; y++) {
      if (authGroup.stores[y].checked) {
        storeCount++;
      }
    }
    return  'Group ' + authGroup.splrLocationGroupId + ' | ' +
      storeCount + ' of ' + authGroup.stores.length + ' selected';
  }

  displayShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].isHidden) {
        return true;
      }
    }
    return false;
  }

  onClickShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].isHidden = false;
    }
  }

  /**
   * Sets the selected stores onto the candidate model.
   */
  applySelectedStoresToCandidate() {
    const authGroups: LocationGroupStores[] = [];
    for (let x = 0; x < this.piaProductService.getSelectedAuthGroups().length; x++) {
      let authGroup: LocationGroupStores = null;
      const stores: Store[] = [];

      for (let y = 0; y < this.piaProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.piaProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          stores.push(JSON.parse(JSON.stringify(this.piaProductService.getSelectedAuthGroups()[x].stores[y])));
        }
      }
      if (stores.length > 0) {
        authGroup =  JSON.parse(JSON.stringify(this.piaProductService.getSelectedAuthGroups()[x]));
        authGroup.stores = stores;
        authGroups.push(authGroup);
      }
    }
    this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores = authGroups;
  }

  hasNoStoresSelected () {
    if (!this.piaProductService.getSelectedAuthGroups() || this.piaProductService.getSelectedAuthGroups().length === 0) {
      return true;
    }

    for (let x = 0; x < this.piaProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.piaProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.piaProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          return false;
        }
      }
    }
    return true;
  }


  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.piaProductService.getCandidate().vendor.apNumber, this.piaProductService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( data => {
          this.canClickSubmit = true;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.canClickSubmit = true;
      });
  }

  private saveAndCompletePiaTask() {
    this.candidateService.saveCandidate(this.piaProductService.getCandidate()).subscribe(savedCandidate => {
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_SCALE_PLU_APPROVE_YES, 'Successfully completed task.');
    });
  }


  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaNewProductFlowStepperComponent.UPC_ITEM_CODE: {
        this.piaProductService.saveCandidateAndNavigate('/piaSetupNewProduct', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.SUPPLIER_HEB_SETUP: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductSupplierDetails', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.PRODUCT_DETAILS: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductDetails', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.CASE_PACK: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
        break;
      }
      case PiaNewDsdProductFlowStepperComponent.STORE_AUTHORIZATION: {
        this.piaProductService.saveCandidateAndNavigate('/piaSetUpStores', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.EXTENDED_ATTRIBUTES: {
        this.onClickNext();
        break;
      }
    }
  }

  jumpTo(urlToNavigate) {
    this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);
  }

  onClickNext() {
    this.canClickSubmit = false;
    this.applySelectedStoresToCandidate();

    this.candidateService.validateCandidate(this.piaProductService.getCandidate(),
      [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR]).subscribe(data => {
      this.piaProductService.setStoreAuthError(new CandidateError());
      this.piaProductService.saveCandidateAndNavigate('/piaNewProductExtendedAttributes', false);
    }, (error) => {
      this.piaProductService.scrollToTop();
      if (error.error.candidateErrors?.hasErrors) {
        this.piaProductService.setStoreAuthError(error.error.candidateErrors);
        this.candidateError = this.piaProductService.getStoreAuthError();
        this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
      }
      this.piaProductService.saveCandidateAndNavigate('/piaNewProductExtendedAttributes', false);
    });
  }
}
