<pm-editor (closeEvent)="onClose()">

  <pm-header>
    <app-pia-mrt-inner-stepper *ngIf="supplierMrtService.innerCandidate"
                               [candidateErrors]="supplierMrtService.getAllPiaCandidateInnerErrorsByCandidateId()"
                               [isReplenishable]="supplierMrtService.isInnerCandidateReplenishable()" [stepIndex]="5"
                               [isSellable]="candidateUtilService.isSellable(supplierMrtService.innerCandidate)"
                               (indexClick)="onClickStepper($event)">
    </app-pia-mrt-inner-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Select warehouses</pm-title>

  <ng-container *ngIf="supplierMrtService.innerCandidate">
    <pm-max-ship-card [(candidateModel)]="supplierMrtService.innerCandidate"
                      [permissions]="maxShipCardPermissions"
                      [candidateErrorModel]="innerCandidateError">
    </pm-max-ship-card>

    <div *ngIf="innerCandidateProductError?.missingWarehouses" class="row ml-0">
      <div class="col-md-10 p-0">
        <div class="row missing-warehouse-container">
          <img class="ban-icon" src="/assets/images/ban-icon.svg">
          <div class="header-row">
            {{innerCandidateProductError?.missingWarehouses}}
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <h2>Warehouses</h2>
      <h4 class="sub-title">Configure one or more of the warehouses below for this product.</h4>
      <hr>
      <ng-template ngFor let-warehouse [ngForOf]="warehouseData" let-index="index">
        <div class="attribute-group">
          <div class="ml-2">
            <p-checkbox id="whsCheckbox"
                        [(ngModel)]="warehouse.checked"
                        (onChange)="selectedWarehouseChange($event, warehouse)"
                        binary="true">
            </p-checkbox>
          </div>
          <div>
            <div class="attribute-label">
              <label>
                {{warehouse.name}}
              </label>
            </div>
            <p class="attribute-description">
              ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
            </p>
            <p *ngIf="warehouse.checked && innerCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
               class="error-message bicep-error">
              {{innerCandidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
            </p>
          </div>
        </div>
        <div *ngIf="warehouse.checked">
          <div class="attributes-stacked">
            <div class="row warehouse-details-row">
              <div class="col-md-4">
                <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                                [(model)]="warehouse.orderRestriction"
                                                (selection)="orderRestrictionChange($event, warehouse)"
                                                [attributeError]="innerCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
                </pm-attribute-display-typeahead>
              </div>
              <div class="col-md-4">
                <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                            [(model)]="warehouse.orderUnitId"
                                            (change)="orderUnitChange($event, warehouse)"
                                            [attributeError]="innerCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
                </pm-attribute-display-radio>
              </div>
            </div>
          </div>
        </div>

        <hr>
      </ng-template>
    </div>
  </ng-container>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!this.isSelectedWarehouse() && !showMatAttributes"></p-button>
  </pm-footer>
</pm-editor>
