import {Component, OnInit} from '@angular/core';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {CostService} from '../../service/cost.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {finalize, switchMap} from 'rxjs/operators';
import {Candidate, CandidateError, CandidateProductError, CandidateValidatorType, TaskDecision} from 'pm-models';
import {SupplierBonusStepperComponent} from '../supplier-bonus-stepper/supplier-bonus-stepper.component';
import {MatUtilService} from '../../service/mat-util.service';

@Component({
  selector: 'app-bonus-extended-attributes',
  templateUrl: './bonus-extended-attributes.component.html',
  styleUrls: ['./bonus-extended-attributes.component.scss']
})
export class BonusExtendedAttributesComponent implements OnInit {

  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public bonusSizeService: BonusSizeService, public costService: CostService,
              private candidateUtilService: CandidateUtilService, private matUtils: MatUtilService) { }

  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;
  candidateProductError: CandidateProductError;

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId,
        this.bonusSizeService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.BONUS_SIZE || candidate.candidateType === Candidate.REPLACEMENT_UPC) {
              this.bonusSizeService.setOriginalAndCurrentCandidate(candidate);
              return this.bonusSizeService.setProductData();
            } else {
              return this.router.navigate(['/tasks']);
            }
          }),
          switchMap(() => this.matUtils.updateMatAttributesAndValues(this.bonusSizeService.getCandidate(),
            this.bonusSizeService.globalAttributes, this.bonusSizeService.hierarchyAttributes)),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.bonusSizeService.extendedAttributesError;
            this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return this.bonusSizeService.setProductData();
            } else {
              this.bonusSizeService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          switchMap(() => this.matUtils.updateMatAttributesAndValues(this.bonusSizeService.getCandidate(),
            this.bonusSizeService.globalAttributes, this.bonusSizeService.hierarchyAttributes)),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.bonusSizeService.extendedAttributesError;
            this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      }
    });
  }

  onClose() {
    this.bonusSizeService.saveCandidateAndNavigate('/tasks', false);
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case SupplierBonusStepperComponent.UPC_ITEM_CODE: {
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getSupplierSetupUrl(), false);
        break;
      }
      case SupplierBonusStepperComponent.REPLACEMENT_DETAILS:
      case SupplierBonusStepperComponent.BONUS_DETAILS: {
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getSupplierUpcDetailsUrl(), false);
        break;
      }
      case SupplierBonusStepperComponent.CASE_DETAILS: {
        this.onClickBack();
        break;
      }
    }
  }

  onClickBack() {
    this.bonusSizeService.saveCandidateAndNavigate('/caseDetails', false);
  }

  onClickSubmit() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.BONUS_SIZE_DETAILS_VALIDATOR,
        CandidateValidatorType.BONUS_CASE_PACK_DETAILS_VALIDATOR,  CandidateValidatorType.SUPPLIER_COMMENT_VALIDATOR,
        CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR]).subscribe(() => {
      this.bonusSizeService.saveAndCompleteTaskAndRouteToUrlWithCandidateId(WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_VENDOR_DATA_DECISION_NO, '/bonusReview', this.bonusSizeService.originalCandidate.candidateId);
    }, (error) => {

      this.bonusSizeService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.bonusSizeService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
      } else {
        this.growlService.addError(error.message);
      }
      this.canNavigate = true;
    });
  }
}
