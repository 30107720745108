import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {File as PamFile, FileHandle} from 'pm-models';
import {DomSanitizer} from '@angular/platform-browser';
import {forkJoin, Observable, throwError} from 'rxjs';
import {UUID} from 'angular2-uuid';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';
import {
  BaseAttributeDisplayComponent
} from '../../shared/attribute-display/base-attribute-display/base-attribute-display.component';
import {catchError} from 'rxjs/operators';


@Component({
  selector: 'pm-label-insights-image-upload',
  templateUrl: './label-insights-image-upload.component.html',
  styleUrls: ['./label-insights-image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LabelInsightsImageUploadComponent extends BaseAttributeDisplayComponent implements OnChanges, OnInit {

  @Input() showProgress: boolean = false;
  @Output() onImageUpload = new EventEmitter<any>();

  uploadedFiles: PamFile[] = [];
  uploadingFiles: PamFile[] = [];
  isInProgress: boolean;
  fileErrorMap: Map<string, string[]> = new Map<string, string[]>();
  fileError: string;
  noImagePng: any = '/assets/images/flat-pack-cereal.png';

  constructor(
    private candidateService: CandidateService,
    private changeDetector: ChangeDetectorRef,
    private growlService: GrowlService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    // SETUP WITH BUCKET TO DOWNLOAD IMAGES
    if (this.model && this.model.length > 0 && this.uploadedFiles && this.uploadedFiles.length === 0) {
      this.retrieveImages();
    }

    if (this.showProgress) {
      this.isInProgress = this.showProgress;
    }
  }

  uploadImages(uploadFiles: File[]) {
    this.isInProgress = true;
    this.changeDetector.detectChanges();
    const fileReaderObservables: Observable<PamFile>[] = [];
    for (const file of uploadFiles) {
      this.getDataFromFile(fileReaderObservables, file);
    }
    forkJoin(fileReaderObservables).subscribe(images => {
      const candCall$: Observable<String>[] = [];
      for (const image of images) {
        candCall$.push(this.candidateService.uploadLabelInsights(image, image.type, true).pipe(
          catchError(errMsg => {
            this.fileError = `<br>${errMsg.message}</br>`;
            return throwError(errMsg);
          })
        ));
      }
      forkJoin(candCall$).subscribe(
        uuids => {
          uuids.forEach(uuid => {
            let x;
            for (x = 0; x < this.uploadingFiles.length; x++) {
              if (this.uploadingFiles[x].uuid === uuid) {
                this.uploadedFiles.push(this.uploadingFiles[x]);
                break;
              }
            }
            this.uploadingFiles.splice(x, 1);
          });
        },
        () => {
          this.isInProgress = false;
        },
        () => {
          this.isInProgress = false;
          this.changeDetector.detectChanges();
          this.onImageUpload.emit();
          this.modelChange.emit(this.uploadedFiles);
        }
      );
    });
  }

  private getDataFromFile(fileReader$: Observable<PamFile>[], file: File) {
    const fileReader = new FileReader();
    const fileReaderObserver$ = new Observable<PamFile>(o => {
      fileReader.onloadend = fr => {
        const image = new PamFile();
        image.data = file;
        image.uuid = UUID.UUID();
        image.name = file.name;
        image.type = file.type;
        image.size = file.size;
        image.category = 'PROD';
        image.status = 'UPLOADED';
        image.salesChannels = [];
        this.getDefaultImageSalesChannels().forEach(imageSalesChannel => {
          image.salesChannels.push(imageSalesChannel);
        });
        this.uploadingFiles.push(image);

        image.fullFile = fileReader.result;

        o.next(image);
        o.complete();
      };
    });
    fileReader$.push(fileReaderObserver$);
    fileReader.readAsDataURL(file);
  }

  filesDropped(files: FileHandle[]): void {
    const filesToSend: File[] = [];
    const filesToDiscard = [];
    this.clearOldErrors();
    for (const file of files) {
      if (this.checkForErrors(file.file, filesToSend.length)) {
        filesToDiscard.push(file.file);
      } else {
        filesToSend.push(file.file);
      }
    }
    this.fileError = this.getFileErrorMessages();
    if (filesToSend.length >= 1) {
      this.uploadImages(filesToSend);
    }
  }

  onFilesAdded(files: FileList) {
    const filesToSend: File[] = [];
    const filesToDiscard = [];
    this.clearOldErrors();
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (this.checkForErrors(file, filesToSend.length)) {
        filesToDiscard.push(file);
      } else {
        filesToSend.push(file);
      }
    }
    this.fileError = this.getFileErrorMessages();
    if (filesToSend.length >= 1) {
      this.uploadImages(filesToSend);
    }
  }

  removeFile(file) {
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
    this.modelChange.emit(this.uploadedFiles);
  }

  checkForErrors(file: File, countOfFilesToSend) {
    let hasError = false;
    if (file.size < 838) {
      hasError = true;
      this.fileErrorMap.get('fileIntegrityError').push(file.name);
    }
    if (countOfFilesToSend + this.uploadedFiles.length >= 12) {
      hasError = true;
      this.fileErrorMap.get('maxFileCountError').push(file.name);
    }
    if (file.size > 40000000) {
      hasError = true;
      this.fileErrorMap.get('fileSizeError').push(file.name);
    }
    if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'application/pdf') {
      // do nothing
    } else {
      hasError = true;
      this.fileErrorMap.get('fileTypeError').push(file.name);
    }
    return hasError;
  }

  clearOldErrors() {
    this.growlService.clearMessages(); // clear out any growl message(s)
    this.fileError = null;
    this.fileErrorMap.clear();
    this.fileErrorMap.set('fileSizeError', []);
    this.fileErrorMap.set('fileTypeError', []);
    this.fileErrorMap.set('fileSystemError', []);
    this.fileErrorMap.set('maxFileCountError', []);
    this.fileErrorMap.set('fileIntegrityError', []);
  }

  getFileErrorMessages() {
    let errorsToReturn = '';

    if (this.fileErrorMap.get('maxFileCountError').length >= 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
          this.fileErrorMap
            .get('maxFileCountError')
            .toString()
            .italics() +
          ' because image upload limit is reached. 12 files are allowed to be uploaded.'
        )
        .concat('</br>');
    }

    if (this.fileErrorMap.get('fileSizeError').length === 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
          this.fileErrorMap
            .get('fileSizeError')
            .toString()
            .italics() +
          ' because it is too big. Files must be smaller than 40 megabytes.'
        )
        .concat('</br>');
    } else if (this.fileErrorMap.get('fileSizeError').length > 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
          this.fileErrorMap
            .get('fileSizeError')
            .toString()
            .italics() +
          ' because they are too large. Files must be smaller than 40 megabytes.'
        )
        .concat('</br>');
    }

    if (this.fileErrorMap.get('fileIntegrityError').length === 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
          this.fileErrorMap
            .get('fileIntegrityError')
            .toString()
            .italics() +
          ' because it is corrupt. Please choose a different file.'
        )
        .concat('</br>');
    } else if (this.fileErrorMap.get('fileIntegrityError').length > 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
          this.fileErrorMap
            .get('fileIntegrityError')
            .toString()
            .italics() +
          ' because they are corrupt. Please choose a different file.'
        )
        .concat('</br>');
    }

    if (this.fileErrorMap.get('fileTypeError').length === 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
          this.fileErrorMap
            .get('fileTypeError')
            .toString()
            .italics() +
          ' because it is the wrong file type. You can upload JPEG, PNG, JPG or PDF files.'
        )
        .concat('</br>');
    } else if (this.fileErrorMap.get('fileTypeError').length > 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
          this.fileErrorMap
            .get('fileTypeError')
            .toString()
            .italics() +
          ' because they are the wrong file type. You can upload JPEG, PNG, JPG or PDF files.'
        )
        .concat('</br>');
    }

    return errorsToReturn;
  }

  private retrieveImages() {
    this.model.forEach(link => {
      this.candidateService.retrieveCandidateImage(link.uuid).subscribe(
        result => {
          const fileReader = new FileReader();
          fileReader.onloadend = e => {
            link.fullFile = fileReader.result.toString().replace('application/json', link.type);
            this.uploadedFiles.push(link);
          };
          fileReader.readAsDataURL(result);
        },
        error => {
          this.growlService.addError(error);
        }
      );
    });
  }

  /**
   * Returns default sales channels.
   */
  private getDefaultImageSalesChannels(): string[] {
    const imageSalesChannels: string[] = [];
    imageSalesChannels.push('01', '03', '04', '06', '08');
    return imageSalesChannels;
  }

  isPdf(file) {
    return file.type === 'application/pdf';
  }

}
