import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  CloseComponent,
  FooterComponent,
  HeaderComponent,
  SidebarComponent,
  SubTitleComponent,
  TitleComponent
} from './common';
import {PipesModule} from './pipes/pipes.module';
import {UpcInputsModule} from './upc-inputs/upc-inputs.module';
import {StaticOverlayPanelModule} from './overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {AttributeDisplayModule} from './attribute-display/attribute-display.module';

const COMPONENTS = [FooterComponent, TitleComponent, SubTitleComponent, CloseComponent, SidebarComponent, HeaderComponent];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, PipesModule, UpcInputsModule],
  imports: [CommonModule, PipesModule, UpcInputsModule, StaticOverlayPanelModule, AttributeDisplayModule]
})
export class SharedModule {}
