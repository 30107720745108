import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeTextInputConfig, Candidate, CostCardErrors, CostCardPermissions, TextInputType} from 'pm-models';

import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper, VisiblePermissionsMapper} from '../attribute-permissions-mapper';
import { CostService } from '../../../../../../src/app/2.0.0/service/cost.service';

@Component({
  selector: 'pm-cost-card',
  templateUrl: './cost-card.component.html',
  styleUrls: ['./cost-card.component.scss']
})
export class CostCardComponent implements OnInit {
  @Input()
  model: Candidate;

  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: CostCardPermissions;

  @Input()
  errorModel: CostCardErrors;

  @Input()
  subTitle: string;

  _masterListCostDescription;
  @Input()
  get masterListCostDescription() {
    return this._masterListCostDescription;
  }

  set masterListCostDescription(e) {
    this._masterListCostDescription = e;
    this.masterCostDescription = this._masterListCostDescription;
  }

  masterCostDefaultDescription = 'How much H-E-B will pay for the entire master case pack.\n ' +
    ' We\'ll calculate values for the fields below based on this value.';
  masterCostDescription: string =
    this.masterListCostDescription !== undefined ? this.masterListCostDescription : this.masterCostDefaultDescription;

  constructor(private costService: CostService) {}

  ngOnInit() {
    // this is being done so masterListCostConfiguration description can be set to the correct value on ngOnInit
    if (this.masterListCostDescription !== undefined) {
      this.masterCostDescription = this.masterListCostDescription;
    }
    this.masterListCostConfiguration.description = this.masterCostDescription;
  }

  getSubTitle () {
    if (this.subTitle !== undefined) {
      return this.subTitle;
    } else {
      return 'Details about case and unit costs and profit margins.';
    }
  }

  masterListCostConfiguration: AttributeTextInputConfig = {
    label: 'Master list cost',
    description: this.masterCostDescription,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.masterListCost),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.masterListCost),
    isRequired: true,
    placeholderText: `List cost`,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    numberCount: 7,
    decimalCount: 4,
    name: 'masterListCostId'
  };
  masterListCostChange(event) {
    if (!this.hasValue(this.model.masterListCost) || !this.hasValue(this.model.masterPack)) {
      this.model.innerListCost = '';
      this.model.unitCost = '';
    }
    if (this.hasValue(this.model.innerPack)) {
      this.model.innerListCost = this.model.masterListCost / (this.model.masterPack / this.model.innerPack);
      this.model.unitCost = this.model.innerListCost / this.model.innerPack;
    } else {
      this.model.innerListCost = this.model.masterListCost;
      this.model.unitCost = this.model.innerListCost / this.model.masterPack;
    }
  }

  masterPackConfiguration: AttributeTextInputConfig = {
    label: 'Total retail units',
    description: 'The total number of units in the master pack.',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.masterPack),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.masterPack),
    isHidden: () => this.isMasterPackHidden(),
    textInputType: TextInputType.integer,
    placeholderText: '# Units',
    inputGroupClass: 'ui-narrow-input',
    name: 'totalRetailUnitsId',
    maxLength: 3
  };

  isMasterPackHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.masterPack) ||
      (!this.model.dsdSwitch || this.model.warehouseSwitch);
  }

  unitCostConfiguration: AttributeTextInputConfig = {
    label: 'Unit cost',
    isDisabled: () => false,
    isReadOnly: () => true,
    isHidden: () => this.isUnitCostHidden(),
    placeholderText: `(total)`,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 4
  };

  isUnitCostHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.unitCost);
  }

  innerCostConfiguration: AttributeTextInputConfig = {
    label: 'Inner Cost',
    isDisabled: () => false,
    isReadOnly: () => true,
    isHidden: () => this.isInnerCostHidden(),
    placeholderText: `(total)`,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 4
  };

  isInnerCostHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.innerCost) ||
      (this.model.dsdSwitch || !this.model.warehouseSwitch) || (this.model.productType === Candidate.NON_SELLABLE && this.model.dsdSwitch);
  }

  pennyProfitConfiguration: AttributeTextInputConfig = {
    label: 'Penny profit',
    isDisabled: () => false,
    isReadOnly: () => true,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.pennyProfit),
    placeholderText: `(total)`,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 2
  };

  marginPercentConfiguration: AttributeTextInputConfig = {
    label: 'Margin %',
    isDisabled: () => false,
    isReadOnly: () => true,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.marginPercent),
    placeholderText: `(total)`,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 4
  };

  calculatePennyProfit(): any {
    if (
      !this.hasValue(this.model.suggestedRetailPrice) ||
      !this.hasValue(this.model.suggestedXFor) ||
      !this.hasValue(this.model.unitCost)
    ) {
      return '';
    }
    return ((this.model.suggestedRetailPrice / this.model.suggestedXFor) - this.model.unitCost).toFixed(2);
  }

  hasValue(value) {
    return value !== undefined && value !== null && value !== '';
  }

  get getMargin(): any {
    return this.costService.getMargin(this.model);
  }

  get isSellable(): boolean {
    return this.model && this.model.productType === 'SELLABLE';
  }
}
