import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Attribute,
  AttributeConfig,
  AttributeTypeaheadConfig,
  BaseCardPermissions,
  Candidate,
  CandidateProduct,
  CandidateProductError,
  MatHierarchy
} from 'pm-models';
import {MatUtilService} from '../../../../../../src/app/2.0.0/service/mat-util.service';
import {EmailService} from '../../../../../../src/app/2.0.0/service/email.service';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';
import {
  RequestNewMatAttributeFormModel
} from 'pm-components/lib/shared/request-new-mat-attribute-form/request-new-mat-attribute-form.component';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';

@Component({
  selector: 'pm-mat-categories-card',
  templateUrl: './mat-categories-card.component.html',
  styleUrls: ['./mat-categories-card.component.scss']
})
export class MatCategoriesCardComponent implements OnInit {

  @Input()
  permissions: BaseCardPermissions;

  @Input()
  candidateModel: Candidate;

  @Input()
  candidateProductModel: CandidateProduct;

  @Input()
  hierarchyAttributes: Attribute[];

  @Input()
  candidateProductErrorModel: CandidateProductError;

  @Output()
  candidateProductModelChange = new EventEmitter<any>();

  hierarchyNumberToConfigurationsMap: Map<number, AttributeConfig[]> = new Map();

  isShowingRequestAttributePanel = false;
  requestNewMatAttributeFormModel: RequestNewMatAttributeFormModel;

  constructor(public matUtilService: MatUtilService, private emailService: EmailService, private growlService: GrowlService) { }

  ngOnInit() {
    // if there's no mat hierarchies, there's no work to be done.
    if (!this.candidateProductModel || !this.candidateProductModel.matHierarchyList ||
      this.candidateProductModel.matHierarchyList.length === 0) {
      return;
    } else if (this.hierarchyAttributes && this.hierarchyAttributes.length > 0) {
      this.setAttributeConfigurations();
    }
  }


  /**
   * Sets the attribute configurations for the mat attributes.
   * @param attributes attributes.
   */
  setAttributeConfigurations() {

    this.hierarchyAttributes.forEach((attribute) => {
      const attributeConfig = MatUtilService.getAttributeConfigurationFromAttribute(attribute);

      if (attributeConfig) {
        if (this.hierarchyNumberToConfigurationsMap.has(attribute.hierarchyDetails.matHierarchyId)) {
          this.hierarchyNumberToConfigurationsMap.get(attribute.hierarchyDetails.matHierarchyId).push(attributeConfig);
        } else {
          this.hierarchyNumberToConfigurationsMap.set(attribute.hierarchyDetails.matHierarchyId, [attributeConfig]);
        }
      }
    });
  }

  /**
   * Whether or not the given mat hierarchy id has attributes.
   *
   * @param matHierarchyId the mat hierarchy id.
   */
  hasAttributes(matHierarchyId): boolean {

    if (!this.hierarchyNumberToConfigurationsMap || this.hierarchyNumberToConfigurationsMap.size === 0) {
      return false;
    } else {
      return this.hierarchyNumberToConfigurationsMap.has(matHierarchyId);
    }
  }

  /**
   * Returns the attribute configurations for a hierarchy id.
   *
   * @param matHierarchyId matHierarchyId.
   */
  getAttributeConfigurations(matHierarchyId): AttributeConfig[] {
    return this.hierarchyNumberToConfigurationsMap.get(matHierarchyId);
  }

  /**
   * Sets the value for an attribute.
   * @param event
   * @param matHierarchy
   * @param attributeConfig
   */
  onAttributeSelection(event, matHierarchy: MatHierarchy, attributeConfig: AttributeConfig) {
    const fieldId = attributeConfig?.matAttributeId;

    let attribute: Attribute;
    // if there's no attributes on the mat hierarchy, find it it the list, add to the mat hierarchy.
    if (!matHierarchy.attributes || matHierarchy.attributes.length === 0) {
      matHierarchy.attributes = [];
      // if there's no value selected, and no current attribute, just return.
      if (this.matUtilService.hasNoValidValueForAttributeConfig(event, attributeConfig)) {
        return;
      }
      attribute = this.hierarchyAttributes.find((attr => attr.identifiers.fieldId === fieldId));
      // Associates can have many candidate products, we don't want the attributes to have the same reference to
      // prevent updates persisting across the different associate UPCs.
      if (Candidate.ASSOCIATE_UPC === this.candidateModel.candidateType) {
        attribute = JSON.parse(JSON.stringify(attribute));
      }
      matHierarchy.attributes.push(attribute);
    } else {
      attribute = matHierarchy.attributes.find((attr => attr.identifiers.fieldId === fieldId));
      // if the attribute isn't on the mat hierarchy, find it it the list, add to the mat hierarchy.
      if (!attribute) {
        // if there's no value selected, and no current attribute, just return.
        if (this.matUtilService.hasNoValidValueForAttributeConfig(event, attributeConfig)) {
          return;
        }
        // Associates can have many candidate products, we don't want the attributes to have the same reference to
        // prevent updates persisting across the different associate UPCs.
        if (Candidate.ASSOCIATE_UPC === this.candidateModel.candidateType) {
          attribute = JSON.parse(JSON.stringify(attribute));
        }
        attribute = this.hierarchyAttributes.find((attr => attr.identifiers.fieldId === fieldId));
        matHierarchy.attributes.push(attribute);
      } else {
        if (this.matUtilService.hasNoValidValueForAttributeConfig(event, attributeConfig)) {
          // if there's no value selected, and remove the current attribute and return.
          for (let x = 0; x < matHierarchy.attributes.length; x++) {
            if (matHierarchy.attributes[x].identifiers.fieldId === fieldId) {
              matHierarchy.attributes.splice(x, 1);
              return;
            }
          }
        }
      }
    }
    // set the selection on the found attribute.
    this.setValue(attribute, event);
  }

  /**
   * Sets the values for a select. If the value to set is an array, simply assigns the value, else it
   * wraps the object in an array and sets the value.
   *
   * @param attribute
   * @param valueToSet
   */
  private setValue(attribute: Attribute, valueToSet) {
    attribute.value = valueToSet;
  }

  /**
   * Returns the value for the mat hierarchy model.
   *
   * @param matHierarchy the mat hierarchy.
   * @param attributeConfig the attribute configuration.
   */
  getMatAttributeModel(matHierarchy: MatHierarchy, attributeConfig: AttributeConfig) {
    const attribute: Attribute = matHierarchy.attributes?.find(attr => attr.identifiers.fieldId === attributeConfig.matAttributeId);
    return attribute ? attribute.value : undefined;
  }

  showRequestNewAttributeConfirmationPanel(attributeTypeaheadConfig: AttributeTypeaheadConfig, matHierarchy: MatHierarchy, event, panel, target) {
    this.requestNewMatAttributeFormModel = new RequestNewMatAttributeFormModel();
    this.requestNewMatAttributeFormModel.attributeFieldId = attributeTypeaheadConfig.matAttributeId;
    this.requestNewMatAttributeFormModel.attributeBusinessFriendlyDescription = attributeTypeaheadConfig.label;
    this.requestNewMatAttributeFormModel.attributeTypeaheadConfig = attributeTypeaheadConfig;
    this.requestNewMatAttributeFormModel.matHierarchy = matHierarchy;
    // remove stub event
    if (attributeTypeaheadConfig.allowMultiple && Array.isArray(event) && !!event.length) {
      event.pop();
      this.requestNewMatAttributeFormModel.currentValue = event;
    }
    this.showPanel(event, panel, target);
  }


  showRequestNewAttributeFormPanel(event, panel, target, rnaMatConfirmOverlay) {
    rnaMatConfirmOverlay.hide();
    this.showPanel(event, panel, target);
  }


  showPanel(event, panel, target) {
    this.isShowingRequestAttributePanel = true;
    panel.show(event, target);
  }

  hidePanel(panel) {
    panel.hide();
    this.isShowingRequestAttributePanel = false;
    this.requestNewMatAttributeFormModel = null;
  }


  sendRequestAndCloseModal(newMatAttributeFormModel: RequestNewMatAttributeFormModel, panel) {
    if (!newMatAttributeFormModel?.newMatAttributeValue) {
      return;
    }

    this.emailService.sendRequestMatAttributeValue(this.candidateModel, newMatAttributeFormModel).subscribe(() => {
        let updateAttributeValue;
        const valueToAdd = {
          id: CandidateUtilService.PENDING_ID_STRING,
          description: 'Pending: ' + newMatAttributeFormModel.newMatAttributeValue,
          pending: true
        };
        if (this.requestNewMatAttributeFormModel.currentValue) {
          updateAttributeValue = this.requestNewMatAttributeFormModel.currentValue.concat(valueToAdd);
        } else {
          updateAttributeValue = valueToAdd;
        }
        this.onAttributeSelection(updateAttributeValue, newMatAttributeFormModel.matHierarchy, newMatAttributeFormModel.attributeTypeaheadConfig);

        this.hidePanel(panel);
      }, (error) => {
        this.growlService.addError(error.error);
      }
    );
  }

}
