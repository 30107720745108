<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4 helper-col">
    <div *ngIf="showInnerPackHelp" class="helper-image">
      <pm-card>
        <div class="row">
          <div class="col-6">
            <img src="assets/images/pam_inner-pack.png" class="img-fluid" />
          </div>
          <div class="col-6">
            <h3>Inner Pack</h3>
            <p>
              Inner packs, sometimes called ship packs, are the boxes inside the master pack that contain the product.
            </p>
            <p>
              This example shows an inner pack containing containing three units.
            </p>
          </div>
        </div>
      </pm-card>
    </div>
  </div>
</div>


<ng-template #card>
  <pm-card title="Inner pack details" subtitle="">
    <pm-sub-title>
Tell us about the inner or ship packs. <button class="btn btn-link p-0 m-0" (click)="toggleInnerPackHelp()">{{showInnerPackHelp ? 'Hide examples' : 'Show examples'}}</button>
    </pm-sub-title>
    <ng-container *ngIf="model">
      <pm-attribute-display-toggle [attribute]="isInnerPackConfiguration" [(model)]="model.innerPackSelected"
                                   [attributeError]="errorModel?.innerPackSelected" (change)="isInnerPackChange($event)">
        <div class="inner-pack-toggle"
          *ngIf="!(isInnerPackConfiguration?.isReadOnly && isInnerPackConfiguration?.isReadOnly())">
        </div>
      </pm-attribute-display-toggle>

      <pm-attribute-display-text-input [attribute]="innerPackQuantityConfiguration" [(model)]="model.innerPack"
        [attributeError]="errorModel?.innerPack" (modelChange)="onInnerPackQuantityChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-volume [attribute]="innerPackDimensionsConfiguration" [(lengthModel)]="model.innerLength"
        [(widthModel)]="model.innerWidth" [(heightModel)]="model.innerHeight" (volumeModelChange)="volumeCalc($event)"
        [attributeWidthError]="errorModel?.innerWidth" [attributeHeightError]="errorModel?.innerHeight"
        [attributeLengthError]="errorModel?.innerLength"
        [divisor]="1728">
      </pm-attribute-display-volume>

      <pm-attribute-display-text-input [attribute]="weightConfiguration" [(model)]="model.innerWeight"
        [attributeError]="errorModel?.innerWeight" (change)="innerWeightChange($event)">
      </pm-attribute-display-text-input>
      <!-- <pm-attribute-display-text-input [attribute]="totalRetailUnitsConfiguration" [(model)]="model.masterPack"
        [attributeError]="errorModel?.masterPack" (change)="totalRetailChange($event)">
      </pm-attribute-display-text-input>  -->
    </ng-container>
  </pm-card>
</ng-template>
