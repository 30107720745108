import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {GrowlService} from '../growl/growl.service';
import {
  Attribute,
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Task,
  TextInputType,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {CandidateService} from '../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../service/workflow.service';
import {LookupService} from '../service/lookup.service';
import {AttributeTypes, ReviewComponent, UpcService} from 'pm-components';
import {FileService} from '../service/file.service';
import {CostService} from '../service/cost.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {AuthService} from '../auth/auth.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {MatUtilService} from '../service/mat-util.service';
import {LabelInsightService} from '../service/label-insight.service';
import {finalize, tap} from 'rxjs/operators';

@Component({
  selector: 'app-sca-product-review',
  templateUrl: './sca-product-review.component.html',
  styleUrls: ['./sca-product-review.component.scss']

})
export class ScaProductReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public WAREHOUSE_TASK_NAME = 'Assign Warehouse';
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';

  constructor(public route: ActivatedRoute, public workflowService: WorkflowService,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public fileService: FileService,
              public costService: CostService, public candidateUtilService: CandidateUtilService,
              public authService: AuthService, public editCandidateModalService: EditCandidateModalService,
              public permissionService: NgxPermissionsService, public upcService: UpcService,
              public matUtilService: MatUtilService, public labelInsightService: LabelInsightService) { }

  public taskSubscription$: any;
  public task: Task;
  public isApproveDisabled = false;
  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  public warehouses: any = undefined;
  public isViewingPage = true;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public orderRestrictions: any[];
  public warehouseData = [];
  public productImages = [];
  public labelInsightImages = [];
  public openCommentDrawer = false;
  public tempCommentHolder: string;
  public decimalCount = 1;
  public warehouseMissingNotesEntered: boolean = false;
  public hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  showMatAttributes = false;
  isLoadingMatGlobalData = true;
  isLoadingMatHierarchyData = true;
  hierarchyAttributes: Attribute[] = [];
  globalAttributes: Attribute[] = [];
  productAttributes: Attribute[] = [];
  warehouseItemAttributes: Attribute[] = [];
  upcAttributes: Attribute[] = [];

  public isPageEditable: boolean = false;

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  scaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'scaCommentId',
    maxLength: 4000
  };

  missingWarehousesCommentsConfiguration: AttributeTextInputConfig = {
    label: 'Notes for Procurement Support',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-half-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: '',
    name: 'missingWarehouseCommentId',
    maxLength: 4000,
    isRequired: true
  };

  private setIsPageEditable() {
    this.isPageEditable = this.authService.isSca();
  }

  ngOnInit() {

    this.setIsPageEditable();

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.WAREHOUSE_TASK_NAME)  {
              this.router.navigate(['/tasks'], {
                queryParams: {
                  growlMessage: 'Candidate is not in ' + this.WAREHOUSE_TASK_NAME + ' status.', growlToUse: GrowlService.SEVERITY_ERROR
                }
              }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {

                this.costService.updateCostLink(candidate).subscribe((updatedCandidate) => {
                  this.costService.updateRetailLink(updatedCandidate).subscribe( (upToDateCandidate) => {
                    this.setInitialValues(upToDateCandidate);
                  });
                });
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: { growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR } }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  setInitialValues(candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.getOrderRestrictions();
    this.getWarehouses(candidate);
    this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].labelInsightsImageLinks);
    this.candidateUtilService.setWarehouseSupplier(this.candidate);
    if (this.candidate.missingWarehousesComment && this.candidate.missingWarehousesComment.length > 0) {
      this.warehouseMissingNotesEntered = true;
    }

    this.setupMatAttributes();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatHierarchyData = false;
      this.isLoadingMatGlobalData = false;
      return;
    }
    this.showMatAttributes = true;
    this.matUtilService.setMatAttributeLists(this.candidate, this.globalAttributes, this.hierarchyAttributes).pipe(
      tap(() => {
        this.matUtilService.setHierarchyNumberToAttributesMap(this.hierarchyAttributes, this.hierarchyNumberToAttributesMap);
        this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.globalAttributes, this.productAttributes, this.warehouseItemAttributes, this.upcAttributes);
      }),
      finalize(() => {
        this.isLoadingMatHierarchyData = false;
        this.isLoadingMatGlobalData = false;
      })
    ).subscribe();
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];

    this.candidate.scaName = this.authService.getUser();
    if (this.candidate.scaComment) {
      this.tempCommentHolder = this.candidate.scaComment;
    }
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    if (this.candidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({},
              this.candidateProduct.warehouses[y], JSON.parse(JSON.stringify(this.candidateProduct.warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.router.navigate(['/tasks']).then();
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']).then();
    }
  }


  onClickNext() {
    this.pmReview.openDrawer();
  }
  collapse() {
    this.pmReview.closeDrawer();
  }

  /**
   * Saves candidate.
   */
  save() {
    this.candidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }
  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }
  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  onClickApprove() {
    this.isApproveDisabled = true;
    this.candidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.SCA_PRODUCT_REVIEW_VALIDATOR]).subscribe(() => {
      this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE, 'Successfully completed assign warehouse task.');
    }, (error) => {
      this.isApproveDisabled = false;
      if (error.error.candidateErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        if (this.candidateProductError.errors.length > 0) {
          let errorString = '';
          for (let x = 0; x < this.candidateError.candidateProductErrors[this.candidateProduct.id].errors.length; x++) {
            errorString += this.candidateError.candidateProductErrors[this.candidateProduct.id].errors[x];
          }
          this.growlService.addError(errorString); // TODO: new way to handle server side errors?
        }
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.workflowService.completeTaskWithAction(this.task, action)
        .subscribe(() => {
          this.router.navigate(['/tasks'], {queryParams: {growlMessage: growlMessage}}).then();
        }, (error) => {
          this.growlService.addError(error);
          this.isApproveDisabled = false;
        });
    });
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }
  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }
  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }
  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  getProductInfoString() {
    let infoString = '';

    infoString += this.candidate.retailSize + ' | ' + 'Inner case cost: ' +
      this.costService.toCurrencyForCost(this.candidate.innerListCost) + ' |  Unit cost: ' +
      this.costService.toCurrencyForCost(this.candidate.unitCost);

    if (this.isSellable) {
      infoString +=
        ' | Suggested Retail: '  +
        this.candidate.suggestedXFor + ' for ' + this.costService.toCurrency(this.candidate.suggestedRetailPrice);
    }

    return infoString;
  }

  getSellableString(): string {
    return this.originalCandidate.productType === 'SELLABLE' ? 'Yes' : 'No';
  }

  isSelectedWarehouse() {
    let result: boolean = false;

    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        result = true;
      }
    }

    return result;
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.scaComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.scaComment = this.tempCommentHolder;
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  editWarehouseSupplier() {
    if (this.candidate.vendor && !this.candidate.vendor.lanes && this.candidate.vendor.apNumber) {
      this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe(() => {
        this.showEditCandidateModal(AttributeTypes.Warehouse, {collections: this.candidate.vendor.lanes});
      });
    } else {
      this.showEditCandidateModal(AttributeTypes.Warehouse,
        {collections: this.candidate.vendor ? this.candidate.vendor.lanes : []});
    }
  }

  get attributeType() {
    return AttributeTypes;
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.resetWarehouseData();
      }
    });
  }

  private resetWarehouseData() {
    if (this.candidate.vendor.lanes && this.candidate.lane.id !== null && this.candidate.lane.id !== undefined) {
      this.lookupService.findWarehousesByVendorApNumberAndLaneId(this.candidate.vendor.apNumber,
        this.candidate.lane.id).subscribe(warehouses => {
        this.warehouses = warehouses;
        this.getWarehouses(this.candidate);
        this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe();
      });
    } else {
      this.warehouses = [];
      this.warehouseData = [];
      this.candidateProduct.warehouses = [];
    }
    this.setWarehouseData();
  }

  getPageTitle() {
    if (this.isSellable) {
      return 'Review new product';
    } else if (this.candidate && !this.isSellable) {
      return 'Review non-sellable product';
    }
  }

  updatedMissingWarehousesComment() {
    this.warehouseMissingNotesEntered = true;
  }

  warehouseMissingCheckboxChange(event) {
    const checked = event.checked;
    if (!checked) {
      this.warehouseMissingNotesEntered = false;
      this.candidate.missingWarehousesComment = null;
      this.candidate.missingWarehousesCommentUser = null;
      this.candidate.missingWarehousesCommentTime = null;
      this.candidateError.missingWarehousesComment = undefined;
    } else {
      this.candidate.missingWarehousesCommentUser = this.authService.getUser();
      this.candidate.missingWarehousesCommentTime = new Date().getTime();
    }
  }
}
