import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from './overlay.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [OverlayComponent],
  imports: [CommonModule, SharedModule],
  exports: [SharedModule, OverlayComponent]
})
export class OverlayModule {}
