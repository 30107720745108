import {BaseAttribute} from './base-attribute';
import {AttributeConfig, Candidate} from 'pm-models';

// tslint:disable-next-line:max-line-length
import {AttributeDisplayRadioComponent} from '../../shared/attribute-display/attribute-display-radio/attribute-display-radio.component';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';
import {MatUtilService} from '../../../../../../src/app/2.0.0/service/mat-util.service';

export class Channel implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayRadioComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Channel',
      description: `Let us know where you'll send this product.`,
      isRequired: true,
      options: [
        {
          value: 'warehouse',
          label: 'Warehouse'
        },
        {
          value: 'dsd',
          label: 'Direct (DSD)'
        }
      ],
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayRadioComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = (candidate.warehouseSwitch === true) ? 'warehouse' : 'dsd';
    componentInstance.modelChange.subscribe(event => {
      candidate.dsdSwitch = event === 'dsd';
      candidate.warehouseSwitch = event === 'warehouse';
      candidate.merchandiseType = null;
      candidate.displayReadyUnit = null;
      candidate.displayReadyUnitOrientation = null;
      candidate.displayReadyUnitRowsDeep = null;
      candidate.displayReadyUnitRowsFacing = null;
      candidate.displayReadyUnitRowsHigh = null;
      candidate.displayReadyUnitType = null;

      if (candidate.dsdSwitch) {
        if (CandidateUtilService.isCostLinkedByItem(candidate)) {
          candidate.costLinkBy = 'LINK';
          candidate.costLink = null;
          candidate.costLinkFromServer = null;
        }
        MatUtilService.removeWarehouseItemAttributes(candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidate)]);
      } else {
        MatUtilService.removeDsdItemAttributes(candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidate)]);
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayRadioComponent, candidate: Candidate) {
    componentInstance.model = (candidate.warehouseSwitch === true) ? 'warehouse' : 'dsd';
  }
}
