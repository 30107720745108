import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerComponent } from './progress-spinner.component';
import { ProgressSpinnerModule as primeNgSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [ProgressSpinnerComponent],
  exports: [ProgressSpinnerComponent, primeNgSpinnerModule],
  imports: [CommonModule, primeNgSpinnerModule]
})
export class ProgressSpinnerModule {}
