import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {StaticOverlayPanelModule} from '../overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {TooltipModule} from 'primeng/tooltip';
import {EditButtonComponent} from './edit-button.component';

@NgModule({
  declarations: [EditButtonComponent],
  exports: [EditButtonComponent],
  imports: [CommonModule, ButtonModule, StaticOverlayPanelModule, TooltipModule]
})
export class EditButtonModule {}
