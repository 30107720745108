import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {CardModule} from '../card/card.module';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {TableModule} from 'primeng/table';
import {UploadCandidateTableComponent} from './upload-candidate-table.component';
import {TabViewModule} from 'primeng/tabview';
import {UploadProductTableModule} from '../upload-product-table/upload-product-table.module';
import {UploadCaseTableModule} from '../upload-case-table/upload-case-table.module';
import {UploadSupplierAndHebTableModule} from '../upload-supplier-and-heb-table/upload-supplier-and-heb-table.module';
import {UploadWarehouseTableModule} from '../upload-warehouse-table/upload-warehouse-table.module';
import {UploadDsdTableModule} from 'pm-components/lib/shared/upload-dsd-table/upload-dsd-table.module';

@NgModule({
  declarations: [UploadCandidateTableComponent],
  exports: [UploadCandidateTableComponent, ButtonModule, CardModule, AttributeDisplayModule],
  imports: [CommonModule, CardModule, AttributeDisplayModule, TableModule, TabViewModule,
    UploadProductTableModule, UploadCaseTableModule, UploadSupplierAndHebTableModule, UploadWarehouseTableModule, UploadDsdTableModule]
})
export class UploadCandidateTableModule {}
