<div class="object-manager-page">

  <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

  <div *ngIf="buyers.length > 0" class="row justify-content-left">
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <pm-buyer-drop-down-filter [hidden]="showSpinner" [buyers]="buyers" [buyerFilters]="buyerFilters"
                                 (dropDownChange)="onApplyBuyerFilter($event)"></pm-buyer-drop-down-filter>
    </div>
  </div>

  <pm-task-viewer-table [hidden]="showSpinner" [tasks]="tasks"
                        [lazyLoadOn]="true"
                        [totalNumRecords]="totalNumRecords"
                        [pageSize]="taskListService.PAGE_SIZE"
                        (taskClicked)="onClickRow($event)"
                        [rushFlag]="!isBuyer"
                        [bulkUpdate]="hasBulkActionPermission"
                        (loadData)="onLoadData($event)"
                        (bulkReview)="onClickBulkEdit($event)"
                        [isSca]="isSca"
                        [isBuyer]="isBuyer">
  </pm-task-viewer-table>

</div>
