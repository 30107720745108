import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {ErrorPanelComponent} from './error-panel.component';

import {TableModule} from 'primeng/table';

@NgModule({
  declarations: [ErrorPanelComponent],
  exports: [ErrorPanelComponent, FormsModule, TableModule, ButtonModule],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    TableModule
  ]
})
export class ErrorPanelModule { }
