<pm-editor (closeEvent)="onClose()">

  <pm-header>
    <app-pia-mrt-inner-stepper *ngIf="supplierMrtService.innerCandidate"
                               [candidateErrors]="supplierMrtService.getAllPiaCandidateInnerErrorsByCandidateId()"
                               [isReplenishable]="supplierMrtService.isInnerCandidateReplenishable()" [stepIndex]="4"
                               [isSellable]="candidateUtilService.isSellable(supplierMrtService.innerCandidate)"
                               (indexClick)="onClickStepper($event)">
    </app-pia-mrt-inner-stepper>
  </pm-header>


  <pm-title>Case pack</pm-title>
  <pm-sub-title *ngIf="supplierMrtService.innerCandidate">UPC: {{supplierMrtService.innerCandidate.candidateProducts[0].upc}}-{{supplierMrtService.innerCandidate.candidateProducts[0].upcCheckDigit}}
  </pm-sub-title>

  <ng-container *ngIf="supplierMrtService.innerCandidate">

    <pm-master-pack-details-card [(model)]="supplierMrtService.innerCandidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="innerCandidateError">
    </pm-master-pack-details-card>


    <pm-inner-pack-details-card [(model)]="supplierMrtService.innerCandidate"
                                [defaultInnerSelectedValue]="false"
                                [permissions]="innerPackDetailsCardPermissions"
                                [errorModel]="innerCandidateError"
                                (innerPackQuantityChange)="innerPackQuantityChange()">
    </pm-inner-pack-details-card>

    <pm-case-details-card *ngIf="additionalCasePackBasicsCardPermissions" [(candidateModel)]="supplierMrtService.innerCandidate"
                          [(candidateProductModel)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="innerCandidateError"
                          [candidateProductErrorModel]="innerCandidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUpc()">
    </pm-case-details-card>

    <pm-imported-product-card [(model)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                              [containerSizes]="containerSizes"
                              [incoTermsList]="incoTermsList"
                              [permissions]="importProductCardPermissions"
                              [errorModel]="innerCandidateProductError">
    </pm-imported-product-card>

    <pm-display-ready-unit-card [(model)]="supplierMrtService.innerCandidate"
                                [permissions]="displayReadyUnitCardPermissions"
                                [errorModel]="innerCandidateError">
    </pm-display-ready-unit-card>

    <pm-cost-link-card [model]="supplierMrtService.innerCandidate"
                       (validateCostLink)="validateCostLink()"
                       [costLinkState]="costLinkState"
                       [errorModel]="innerCandidateError"
                       [permissions]="costLinkCardPermissions">
    </pm-cost-link-card>

    <pm-cost-card [model]="supplierMrtService.innerCandidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="innerCandidateError">
    </pm-cost-card>

    <pm-omi-remarks-card [(model)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                         [permissions]="omiRemarksPermissions"
                         [errorModel]="innerCandidateProductError">
    </pm-omi-remarks-card>
    <pm-attachments-card [(model)]="supplierMrtService.innerCandidate"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="innerCandidateError">
    </pm-attachments-card>
  </ng-container>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
