import {BaseAttribute} from './base-attribute';
import {AttributeTextInputConfig, CandidateProduct} from 'pm-models';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayTextinputComponent} from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class Receipt implements BaseAttribute {


  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Receipt',
      isDisabled: () => true,
      isReadOnly: () => true,
      isRequired: false,
      name: 'receipt',
      maxLength: 25,
      inputGroupClass: 'typeahead-width',
      labelInputGroupClass: 'product-description-label-width',
      ...overrides
    };
  }
  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidateProduct: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = this.getReceipt(candidateProduct.description);
    componentInstance.change.subscribe(x => {
      componentInstance.model = this.getReceipt(candidateProduct.description);
    });
  }

  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidateProduct: CandidateProduct) {
    componentInstance.model = this.getReceipt(candidateProduct.description);

  }

  getReceipt(productDescription): string {
    if (productDescription) {
      if (productDescription.length > 25) {
        return productDescription.substring(0, 25).toUpperCase();
      } else {
        return productDescription.substring(0, productDescription.length).toUpperCase();
      }
    }
  }
}
