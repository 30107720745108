import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeTypes} from 'pm-components/lib/attributes/attribute-types.enum';

import {
  AttributeConfig, AttributeTextInputConfig, AttributeTypeaheadConfig,
  Candidate, CandidateError,
  RegulatoryCardModel, RegulatoryCardPermissions
} from 'pm-models';
import {VertexTaxCategoryDefaults} from 'pm-models/lib/vertexTaxCategoriesDefault';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {
  DisabledPermissionsMapper,
  ReadOnlyPermissionsMapper,
  VisiblePermissionsMapper
} from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-regulatory-card',
  templateUrl: './regulatory-card.component.html',
  styleUrls: ['./regulatory-card.component.scss']
})
export class RegulatoryCardComponent implements OnInit {

  @Input()
  model: RegulatoryCardModel | Candidate;

  @Input()
  permissions: RegulatoryCardPermissions;

  @Input()
  errorModel: CandidateError;

  @Output() modelChange = new EventEmitter<any>();
  @Output() taxableChange = new EventEmitter<any>();

  private defaultTaxCategories: VertexTaxCategoryDefaults;

  constructor(private editCandidateModalService: EditCandidateModalService, private lookupService: LookupService) {}

  ngOnInit() {}

  fsaConfiguration: AttributeConfig = {
    label: 'FSA/HSA eligible',
    description: 'Indicate if customers can use funds from a Flexible Spending Account (FSA) or Health Savings Account (HSA) to purchase this product.',
    isRequired: false,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.flexibleSpendingAccount),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.flexibleSpendingAccount),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.flexibleSpendingAccount) || !this.isSellable(),
    name: 'fsaEligibleId',
    defaultValue: false
  };

  flexSpendingChange(event) {
    this.model.flexibleSpendingAccount = event.checked;
    this.modelChange.emit(this.model);
  }

  taxableConfiguration: AttributeConfig = {
    label: 'Taxable',
    description: 'Indicate if H-E-B should collect sales tax on this product.',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.taxable),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.taxable),
    isHidden: () => VisiblePermissionsMapper(this.permissions,  this.permissions.taxable),
    defaultValue: false,
    name: 'taxableId'
  };

  onTaxableChange(event) {
    this.model.taxable = event.checked;
    this.modelChange.emit(this.model);
    this.taxableChange.emit();
  }

  foodStampsConfiguration: AttributeConfig = {
    label: 'Food stamp eligible',
    description: 'Indicate if a customer can use food stamps to purchase this product.',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.foodStamp),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.foodStamp),
    isHidden: () => VisiblePermissionsMapper(this.permissions,  this.permissions.foodStamp) || !this.isSellable(),
    name: 'foodStampEligibleId',
    defaultValue: false
  };

  foodStampChange(event) {
    this.model.foodStamp = event.checked;
    this.modelChange.emit(this.model);
  }

  editTaxCategory() {
    this.lookupService.findDefaultTaxCategories(this.model.subCommodity.subCommodityId, this.model.taxable).subscribe(
      (defaultTaxCategories) => {
        this.defaultTaxCategories = defaultTaxCategories;
        this.openCategoryModal();
      });
  }

  private openCategoryModal() {
    this.editCandidateModalService.openModal(AttributeTypes.VertexTaxCategory, this.model as Candidate,
      {collections: this.defaultTaxCategories},
      {contentStyle: {'max-height': '550px', overflow: 'none'}}
    ).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.model = response;
        this.modelChange.emit(this.model);
      }
    });
  }

  showTaxCategoryEdit() {
    return this.model && this.model.subCommodity && !VisiblePermissionsMapper(this.permissions, this.permissions.taxCategory);
  }

  showCaloriesConfiguration: AttributeConfig = {
    checkboxLabel: 'Show calories',
    label: 'Include nutrition panel or label',
    // tslint:disable-next-line:max-line-length
    description: 'If checked, we\'ll pull nutritional information for the Own Brand Regulatory team to review. ' +
      'You\'ll be able to activate this candidate after they approve the nutrition panel.',
    isRequired: false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.showCalories),
    name: 'showCaloriesId',
    defaultValue: false
  };

  showCaloriesChange(event) {
    this.modelChange.emit(this.model);
  }

  isSellable() {
    return this.model.productType === Candidate.SELLABLE;
  }

  taxCategoryConfiguration: AttributeTypeaheadConfig = {
    label: 'Tax category',
    searchUrl: '/lookup/vertexTaxCategory',
    placeholderText: 'Select your category...',
    displaySubRef: '',
    idRef: 'id',
    displayRef: 'displayName',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.taxCategory),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.taxCategory),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.taxCategory),
  };

  onTypeaheadChange(event) {
    if (!event) {
      this.model.vertexTaxCategoryModel = null;
    } else {
      this.model.vertexTaxCategoryModel = event;
    }
    this.modelChange.emit(this.model);
  }
}
