import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  CandidateProduct,
  CandidateProductError,
  ImportedProductCardErrors,
  ImportedProductCardModel,
  ImportedProductCardPermissions,
  TextInputType
} from 'pm-models';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper} from '../attribute-permissions-mapper';
import {UUID} from 'angular2-uuid';

@Component({
  selector: 'pm-imported-product-card',
  templateUrl: './imported-product-card.component.html',
  styleUrls: ['./imported-product-card.component.scss']
})
export class ImportedProductCardComponent implements OnInit {

  @Input()
  model: ImportedProductCardModel | CandidateProduct;

  @Input()
  permissions: ImportedProductCardPermissions;

  @Input()
  errorModel: ImportedProductCardErrors | CandidateProductError;

  @Output() modelChange = new EventEmitter<any>();


  _containerSizes;
  @Input()
  get containerSizes() {
    return this._containerSizes;
  }
  set containerSizes(e) {
    this._containerSizes = e;
    this.containerSizeConfiguration.collections = this._containerSizes;
  }

  _incoTermsList;
  @Input()
  get incoTermsList() {
    return this._incoTermsList;
  }
  set incoTermsList(e) {
    this._incoTermsList = e;
    this.incoTermsConfiguration.collections = this._incoTermsList;
  }

  minimumDate: Date;
  maxHtsCodeLength: number = 10;
  constructor() {
  }

  ngOnInit() {
    this.minimumDate = new Date();
    // Set hts codes preceding zeros on page load.
    if (this.model.imported) {
      if (this.model.importInfo?.hts1) {
        this.hts1Change();
      }
      if (this.model.importInfo?.hts2) {
        this.hts2Change();
      }
      if (this.model.importInfo?.hts3) {
        this.hts3Change();
      }
    }
  }

  importedProductConfiguration: AttributeConfig = {
    label: 'H-E-B Import',
    description: 'If H-E-B is the known importer of record for this product, we need to collect additional information',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.imported),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.imported),
    isRequired: false,
    name: 'import',
    defaultValue: false
  };

  isImportedProductFlagChange(e) {
    this.model.imported = e.checked;
    if (this.model.imported === false) {
      this.model.importInfo = null;
    } else {
      this.model.importInfo = { id: UUID.UUID() };
    }
    this.modelChange.emit(this.model);
  }

  containerSizeConfiguration: AttributeTypeaheadConfig = {
    label: 'Container Size',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.containerSize),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.containerSize),
    isHidden: () => !this.model.imported,
    collections: this.containerSizes,
    idRef: 'id',
    name: 'containerSize',
    placeholderText: 'Select a container size...',
    displayRef: 'description'
  };

  containerSizeChange(e) {
    console.log(e);
  }

  incoTermsConfiguration: AttributeTypeaheadConfig = {
    label: 'Inco Terms',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.incoTerms),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.incoTerms),
    isHidden: () => !this.model.imported,
    name: 'incoTerms',
    idRef: 'id',
    collections: this.incoTermsList,
    placeholderText: 'Select inco terms...',
    displayRef: 'description'
  };

  incoTermsChange(e) {
    console.log(e);
  }


  pickupPointConfiguration: AttributeTextInputConfig = {
    label: 'Pickup Point',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.pickupPoint),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.pickupPoint),
    isHidden: () => !this.model.imported,
    name: 'pickupPoint',
    placeholderText: 'Pickup point',
    maxLength: 20
  };

  pickupPointChange(e) {
    console.log(e);
  }

  dutyPercentConfiguration: AttributeTextInputConfig = {
    label: 'Duty %',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.dutyPercent),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.dutyPercent),
    isHidden: () => !this.model.imported,
    isRequired: true,
    decimalCount: 2,
    numberCount: 3,
    textInputType: TextInputType.decimal,
    placeholderText: 'Duty %',
    name: 'dutyPercent'
  };

  dutyPercentChange(event) {
  }

  dutyConfirmedConfiguration: AttributeConfig = {
    label: 'Duty Confirmed',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.dutyConfirmed),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.dutyConfirmed),
    isHidden: () => !this.model.imported,
    placeholderText: 'Select a date...',
    name: 'dutyConfirmed',
    isRequired: true
  };

  dutyConfirmedChange(event) {
  }

  dutyInfoConfiguration: AttributeTextInputConfig = {
    label: 'Duty Info',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.dutyPercent),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.dutyPercent),
    isHidden: () => !this.model.imported,
    name: 'dutyInfo',
    textInputType: TextInputType.text,
    placeholderText: 'Add Additional info',
    maxLength: 20,
    isRequired: false
  };

  dutyInfoChange(event) {
  }

  minimumOrderQtyConfiguration: AttributeTextInputConfig = {
    label: 'Minimum Order',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.minimumOrderQty),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.minimumOrderQty),
    isHidden: () => !this.model.imported,
    isRequired: true,
    inputGroupClass: 'ui-narrow-input',
    name: 'minimumOrderQty',
    textInputType: TextInputType.integer,
    placeholderText: 'Qty',
    maxLength: 7
  };

  minimumOrderQtyChange(event) {
  }

  minimumOrderDescConfiguration: AttributeTextInputConfig = {
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.minimumOrderDesc),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.minimumOrderDesc),
    isHidden: () => !this.model.imported,
    isRequired: true,
    name: 'minimumOrderDesc',
    textInputType: TextInputType.text,
    placeholderText: 'Description',
    maxLength: 20
  };

  minimumOrderDescChange(event) {
  }

  hts1Configuration: AttributeTextInputConfig = {
    label: 'HTS1',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.hts1),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.hts1),
    isHidden: () => !this.model.imported,
    isRequired: true,
    name: 'hts1',
    textInputType: TextInputType.integer,
    placeholderText: 'HTS1',
    maxLength: 10
  };

  hts1Change() {
    if(this.model.importInfo?.hts1) {
      const newHts = this.model.importInfo.hts1 + '';
      this.model.importInfo.hts1 = newHts.padStart(this.maxHtsCodeLength, '0');
    }
  }

  hts2Configuration: AttributeTextInputConfig = {
    label: 'HTS2',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.hts2),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.hts2),
    isHidden: () => !this.model.imported,
    name: 'hts2',
    textInputType: TextInputType.integer,
    placeholderText: 'HTS2',
    maxLength: 10
  };

  hts2Change() {
    if (this.model.importInfo?.hts2) {
      const newHts = this.model.importInfo.hts2 + '';
      this.model.importInfo.hts2 = newHts.padStart(this.maxHtsCodeLength, '0');
    }
  }

  hts3Configuration: AttributeTextInputConfig = {
    label: 'HTS3',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.hts3),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.hts3),
    isHidden: () => !this.model.imported,
    name: 'hts3',
    textInputType: TextInputType.integer,
    placeholderText: 'HTS3',
    maxLength: 10
  };

  hts3Change() {
    if (this.model.importInfo?.hts3) {
      const newHts = this.model.importInfo.hts3 + '';
      this.model.importInfo.hts3 = newHts.padStart(this.maxHtsCodeLength, '0');
    }
  }

  agentPercentConfiguration: AttributeTextInputConfig = {
    label: 'Agent %',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.agentPercent),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.agentPercent),
    isHidden: () => !this.model.imported,
    isRequired: true,
    name: 'agentPercent',
    decimalCount: 2,
    numberCount: 3,
    textInputType: TextInputType.decimal,
    placeholderText: 'Agent %'
  };

  agentPercentChange(event) {
  }

  cartonMarkingConfiguration: AttributeTextInputConfig = {
    label: 'Carton Marking',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.cartonMarking),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.cartonMarking),
    isHidden: () => !this.model.imported,
    isRequired: true,
    name: 'cartonMarking',
    textInputType: TextInputType.text,
    placeholderText: 'Carton marking',
    maxLength: 30
  };

  cartonMarkingChange(event) {
  }

  productColorConfiguration: AttributeTextInputConfig = {
    label: 'Product Color',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.productColor),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.productColor),
    isHidden: () => !this.model.imported,
    isRequired: true,
    name: 'productColor',
    textInputType: TextInputType.text,
    placeholderText: 'Product color',
    maxLength: 50
  };

  productColorChange(event) {
  }

  prorationDateConfiguration: AttributeConfig = {
    label: 'Proration Date',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.prorationDate),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.prorationDate),
    isHidden: () => !this.model.imported,
    placeholderText: 'Select a date...',
    name: 'prorationDate',
    isRequired: false
  };

  prorationDateChange(event) {
  }

  inStoreDateConfiguration: AttributeConfig = {
    label: 'In Store Date',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.inStoreDate),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.inStoreDate),
    isHidden: () => !this.model.imported,
    placeholderText: 'Select a date...',
    name: 'inStoreDate',
    isRequired: false
  };

  inStoreDateChange(event) {
  }

  whsFlushDateConfiguration: AttributeConfig = {
    label: 'WHS Flush Date',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.whsFlushDate),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.whsFlushDate),
    isHidden: () => !this.model.imported,
    placeholderText: 'Select a date...',
    name: 'whsFlushDate',
    isRequired: false
  };
  whsFlushDateChange(event) {
  }

  freightConfirmedDateConfiguration: AttributeConfig = {
    label: 'Freight Confirmed Date',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.freightConfirmedDate),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.freightConfirmedDate),
    isHidden: () => !this.model.imported,
    name: 'freightConfirmedDate',
    placeholderText: 'Select a date...',
    isRequired: true
  };

  freightConfirmedDateChange(event) {
  }

  importFactoryConfiguration: AttributeConfig = {
    label: 'Import factory',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => !this.model.imported
  };
}
