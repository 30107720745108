

<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-new-warehouse-product-stepper *ngIf="supplierProductService.candidate && !supplierProductService.isDsdOnly()"
                                       [candidateErrors]="supplierProductService.getAllWhsCandidateErrors()"
                                       [stepIndex]="2"
                                       [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                       (indexClick)="onClickStepper($event)">
    </app-new-warehouse-product-stepper>
    <app-new-dsd-product-stepper *ngIf="supplierProductService.candidate && supplierProductService.isDsdOnly()"
                                 [candidateErrors]="supplierProductService.getAllDsdCandidateErrors()"
                                 [stepIndex]="2"
                                 [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                 (indexClick)="onClickStepper($event)">
    </app-new-dsd-product-stepper>
  </pm-header>
  <!-- Page Titles -->
  <pm-title>Provide supplier and buyer details.</pm-title>
  <!-- Page Cards -->
  <!--
    We're using  an ng-container to not add additional
    DOM elements and only show the cards when the candidate is loaded
  -->
  <ng-container *ngIf="supplierProductService.candidate">
    <pm-heb-details-card [(model)]="supplierProductService.candidate"
                         [(candidateProductModel)]="supplierProductService.candidate.candidateProducts[0]"
                         (merchandiseTypeChange)="onMerchandiseTypeChange($event)"
                         (buyerChange)="onBuyerChange($event)"
                         (commodityChange)="onCommodityChange($event)"
                         (subCommodityChange)="onSubCommodityChange($event)"
                         [permissions]="hebDetailsCardPermissions"
                         [errorModel]="candidateError"
                         [commodities]="commodities"
                         [isDsd]="supplierProductService.candidate.dsdSwitch"
                         [commodityConfiguration]="getCommodityConfiguration()"
                         [subCommodityConfiguration]="getSubCommodityConfiguration()"
                         [candidateProductErrorModel]="candidateProductError"
                         [merchandiseTypes]="merchandiseTypes">

    </pm-heb-details-card>
    <pm-supplier-details-card [(candidateModel)]="supplierProductService.candidate"
                              [candidateProductModel]="supplierProductService.candidate.candidateProducts[0]"
                              (supplierChange)="onSupplierChange($event)"
                              [permissions]="supplierDetailsCardPermissions"
                              [candidateErrorModel]="candidateError">
    </pm-supplier-details-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
