import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardModule} from '../../shared/card/card.module';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {OmiRemarksCardComponent} from './omi-remarks-card.component';


@NgModule({
  declarations: [OmiRemarksCardComponent],
  exports: [OmiRemarksCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule,
    AttributeDisplayModule
  ]
})
export class OmiRemarksCardModule { }
