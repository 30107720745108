import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AttributeTypes, ReviewComponent} from 'pm-components';
import {
  Attribute,
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  TextInputType,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {VertexTaxCategoryDefaults} from 'pm-models/lib/vertexTaxCategoriesDefault';
import {AuthService} from '../auth/auth.service';
import {GrowlService} from '../growl/growl.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {CandidateService} from '../service/candidate.service';
import {CostService} from '../service/cost.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {FileService} from '../service/file.service';
import {LookupService} from '../service/lookup.service';
import {WorkflowService} from '../service/workflow.service';
import {LabelInsightService} from '../service/label-insight.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {MatUtilService} from '../service/mat-util.service';
import {SupplierProductService} from '../service/supplier-product.service';

@Component({
  selector: 'app-pia-mrt-inner-review-closed',
  templateUrl: './pia-mrt-inner-review-closed.component.html',
  styleUrls: ['./pia-mrt-inner-review-closed.component.scss']
})
export class PiaMrtInnerReviewClosedComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public PRICE_REQUIRED = 'Price Required';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';

  constructor(private route: ActivatedRoute, private workflowService: WorkflowService,
              private router: Router, private candidateService: CandidateService, private lookupService: LookupService,
              private growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public fileService: FileService, public candidateUtilService: CandidateUtilService,
              private authService: AuthService, public labelInsightService: LabelInsightService, public permissionService: NgxPermissionsService,
              public matUtilService: MatUtilService, public supplierProductService: SupplierProductService) {}

  private isActivateDisabled = false;
  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  public lastBuyerChangedCandidate: Candidate;
  public lastScaChangedCandidate: Candidate;
  public commodities: any;
  public subCommodities: any;
  public warehouses = [];
  public merchandiseTypes: any;
  public isViewingPage = true;
  public candidateError: CandidateError;
  public warehouseCandidateError: CandidateError;
  public warehouseCandidateProductError: CandidateProductError;
  public candidateProductError: CandidateProductError;
  public orderRestrictions: any[];
  public warehouseData = [];
  public productImages = [];
  public labelInsightImages = [];
  public packageTypes: any;
  public unitsOfMeasures: any;
  private openCommentDrawer = false;
  private tempCommentHolder: string;
  private piaName: string;
  public decimalCount = 1;
  public mrtParentDescription: string;
  private mrtParentCandidate: any;
  public isNonReplenishItem: boolean = false;
  private defaultTaxCategories: VertexTaxCategoryDefaults;
  private isPartOfMrt: boolean = false;
  private oldSupplierCandidateFound: boolean;
  private oldBuyerCandidateFound: boolean;
  private oldScaCandidateFound: boolean;

  showMatAttributes = false;
  isLoadingMatData = true;

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => true,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  piaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'piaCommentId',
    maxLength: 4000
  };

  ngOnInit() {
    this.supplierProductService.resetService();
    this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.MRT_INNER) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setDefaultVertexTaxCategory(candidate: Candidate) {
    // initialize it to the default category if it's not set
    if (!candidate.vertexTaxCategory) {
      candidate.vertexTaxCategory = CandidateHelper.getVertexTaxCategory(candidate);
    }
  }

  private getDefaultTaxCategories(candidate: Candidate) {
    if (!candidate.subCommodity) {
      return;
    }
    this.lookupService.findDefaultTaxCategories(candidate.subCommodity.subCommodityId, candidate.taxable.toString())
      .subscribe(defaultTaxCategories => {
          this.defaultTaxCategories = defaultTaxCategories;
        }
      );
  }

  private setInitialValues(candidate: Candidate) {
    this.setDefaultVertexTaxCategory(candidate);
    this.getDefaultTaxCategories(candidate);

    this.setOriginalAndCurrentCandidate(candidate);
    this.getOrderRestrictions();

    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }

    if (candidate.brand.brandId !== undefined) {
      this.setBrandAndCostOwners(candidate.brand.brandId);
    }

    if (candidate.buyer.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }

    if (this.candidate.vendor.apNumber !== undefined) {
      this.setVendor(this.candidate.vendor.apNumber);
    }

    // Always have to check if a this 'draft' candidate is part of a MRT
    this.setMrtCaseInfo();

    this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].labelInsightsImageLinks);

    this.piaName = this.authService.getUser();
    if (this.candidate.piaComment) {
      this.tempCommentHolder = this.candidate.piaComment;
    }
    this.setupMatAttributes();
  }


  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
      }
    });
  }

  private updateRegulatoryFields() {
    this.candidate.foodStamp = CandidateHelper.isFoodStamp(this.candidate);
    this.candidate.taxable = CandidateHelper.isTaxCode(this.candidate);
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.originalCandidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.originalCandidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      if (!this.originalCandidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });

  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
  }

  /**
   * Sets available selectable cost owners
   * @param brandId the brandId id.
   */
  private setBrandAndCostOwners(brandId) {
    this.lookupService.findBrandsById(brandId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.brand.brandId === data[index].brandId) {
          this.candidate.brand.costOwners = data[index].costOwners;
          this.candidate.brand.subBrands = data[index].subBrands;
          break;
        }
      }
    });
  }
  /**
   * Sets available selectable cost owners
   * @param vendorId the vendorId id.
   */
  private setVendor(vendorId) {
    this.lookupService.findVendor(vendorId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.vendor.apNumber === data[index].apNumber) {
          this.candidate.vendor.lanes = data[index].lanes;
          break;
        }
      }
    });
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']);
  }

  getProductInfoString() {
    let infoString = '';

    if (this.isNonReplenishItem) {
      infoString += this.candidate.retailSize;
    } else {
      infoString += this.candidate.retailSize + ' | ' + 'Inner case cost: ' +
        this.costService.toCurrencyForCost(this.candidate.innerListCost) + ' |  Unit cost: ' +
        this.costService.toCurrencyForCost(this.candidate.unitCost);
    }

    if (this.isSellable) {
      infoString +=
        ' | Suggested Retail: '  +
        this.candidate.suggestedXFor + ' for ' + this.costService.toCurrency(this.candidate.suggestedRetailPrice);
    }

    return infoString;
  }

  getSellableString(): string {
    return this.candidate.productType === 'SELLABLE' ? 'Yes' : 'No';
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }

  openWarehouseDrawer() {
    this.getWarehouses(this.candidate);
    this.pmReview.openDrawer();
  }

  collapse() {
    this.warehouseData = [];
    this.pmReview.closeDrawer();
  }

  addRemovedWarehouseToDrawer(warehouseId) {
    for (let x = 0; x < this.warehouses.length; x++) {
      if (this.warehouses[x].warehouseId === warehouseId) {
        this.warehouseData.push(this.warehouses[x]);
        this.warehouseData[this.warehouseData.length - 1].orderUnitConfig = this.getOrderUnitConfiguration(warehouseId);
        break;
      }
    }
  }

  /**
   * Saves candidate.
   */
  save() {
    this.candidateProduct.warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.setWarehouseData();
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  /**
   * Saves candidate.
   */
  update() {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.candidateProducts[0].warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.candidateService.validateCandidate(tempCandidate, [CandidateValidatorType.WAREHOUSE_VALIDATOR])
      .subscribe(() => {
        this.candidateProduct.warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
        this.candidateService.saveCandidate(this.candidate, true).subscribe(savedCandidate => {
          this.setOriginalAndCurrentCandidate(savedCandidate);
          this.collapse();
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.warehouseCandidateError = error.error.candidateErrors;
          this.warehouseCandidateProductError  = this.warehouseCandidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message);
        }
      });
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, don't add to warehouse list.
    if (this.candidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  removeWarehouse(index) {
    const warehouse = this.candidateProduct.warehouses.splice(index, 1).pop();
    if (this.warehouseData && this.warehouses) {
      this.addRemovedWarehouseToDrawer(+warehouse.warehouseId);
    }
  }

  selectedWarehouseChange(event, warehouse) {
    if (!event) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  areMasterPackValuesSet(): boolean {
    return this.isNumberSetAndNotZero(this.candidate.masterLength) &&
      this.isNumberSetAndNotZero(this.candidate.masterWidth) &&
      this.isNumberSetAndNotZero(this.candidate.masterHeight) &&
      this.isNumberSetAndNotZero(this.candidate.masterWeight);
  }

  isNumberSetAndNotZero (value: any) {
    return value !== undefined && value !== null && value !== 0 && value !== '' && value !== '0';
  }


  get attributeType() {
    return AttributeTypes;
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.piaComment;
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.piaComment = this.tempCommentHolder;
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  getTaxCategory() {
    if (this.candidate.subCommodity) {
      if (this.candidate.taxable) {
        return this.candidate.subCommodity.taxableVertexTaxCategory;
      } else {
        return this.candidate.subCommodity.nonTaxableVertexTaxCategory;
      }
    }
  }

  getPageTitle() {
    if (this.isSellable) {
      return 'Review new product';
    } else if (this.candidate && !this.isSellable) {
      return 'Review non-sellable product';
    }
  }

  isActivateButtonDisabled() {
    if (this.isActivateDisabled) {
      return true;
    } else if (!this.candidate) {
      return true;
      // TODO DSD Activate implementation
    } else {
      return this.candidate.dsdSwitch !== undefined && this.candidate.dsdSwitch !== null && this.candidate.dsdSwitch === true;
    }
  }

  isMrtInnerNR() {
    return this.isMrtInner() && this.isNonReplenishItem;
  }

  getActivateButtonTitle() {
    let buttonNotClicked = 'Activate';
    let activatingButton = 'Activating';
    if (this.isMrtInnerNR()) {
      buttonNotClicked = 'Approve';
      activatingButton = 'Approving';
    }
    if (this.isActivateDisabled) {
      return activatingButton;
    } else {
      return buttonNotClicked;
    }
  }

  isMrtInner() {
    return this.candidate && this.candidate.candidateType === Candidate.MRT_INNER;
  }

  setMrtCaseInfo() {
    this.candidateService.findParentMrtCandidatesForCandidateId(this.candidate.candidateId,
      [Candidate.IN_PROGRESS, Candidate.COMPLETED, Candidate.ACTIVATED, Candidate.DECLINED, Candidate.DELETED]).subscribe((mrtCandidates) => {
      if (!mrtCandidates?.length) {
        this.isPartOfMrt = false;
        return;
      }
      this.isPartOfMrt = true;
      if (!this.findMrtParentAndSetMrtCaseInfo(mrtCandidates)) {
        this.setMrtCaseInfoFromWorkflow(mrtCandidates);
      }
    });
  }

  private findMrtParentAndSetMrtCaseInfo(mrtCandidates: Candidate[]): boolean {
    const mrtParentCandidate = mrtCandidates.filter(mrt => {
      return !!mrt.mrtInfo.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId && !inner.draft);
    })[0];

    if (!mrtParentCandidate) {
      return false;
    }
    const candidateInner = mrtParentCandidate.mrtInfo?.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId);
    if (!candidateInner.replenishable) {
      this.isNonReplenishItem = true;
      this.mrtParentCandidate = mrtParentCandidate;
      this.mrtParentDescription = mrtParentCandidate.description;
      this.candidateUtilService.setMrtInnerCandidateInfoFromMrtCandidate(this.candidate, this.candidateProduct, mrtParentCandidate);
    }
    return true;
  }

  private setMrtCaseInfoFromWorkflow(mrtCandidates: Candidate[]) {
    this.workflowService.getIsNonReplenishableMrtInnerCandidateId(this.candidate.candidateId).subscribe(
      (isNonReplenishable) => {
        if (!isNonReplenishable) {
          return;
        }
        this.isNonReplenishItem = true;

        if (mrtCandidates.length === 1) {
          this.mrtParentCandidate = mrtCandidates[0];
        } else {
          this.mrtParentCandidate = mrtCandidates.filter(mrt => {
            return !!mrt.mrtInfo.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId && !inner.draft);
          })[0];
          // if the parent no longer exists, just select the first one.
          if (!this.mrtParentCandidate) {
            this.mrtParentCandidate = mrtCandidates[0];
          }
        }
        this.mrtParentDescription = this.mrtParentCandidate.description;
        this.candidateUtilService.setMrtInnerCandidateInfoFromMrtCandidate(this.candidate, this.candidateProduct, this.mrtParentCandidate);
      }
    );
  }


  showCasePackInfo() {
    if (this.isMrtInner()) {
      return !this.isNonReplenishItem;
    } else {
      return this.candidate.warehouseSwitch;
    }
  }

  hasSupplierValueChangedForCandidate(objectName: string, subObjectName?: string) {
    if (!this.oldSupplierCandidateFound) {
      return false;
    }

    if (subObjectName) {
      return this.candidate[objectName][subObjectName]  !== this.lastSupplierChangedCandidate[objectName][subObjectName];
    } else {
      return this.candidate[objectName]  !== this.lastSupplierChangedCandidate[objectName];
    }
  }

  hasSupplierValueChangedForCandidateProduct(objectName: string, subObjectName?: string) {
    if (!this.oldSupplierCandidateFound) {
      return false;
    }

    if (subObjectName && this.lastBuyerChangedCandidate && this.lastBuyerChangedCandidate[objectName] !== undefined &&
      this.lastBuyerChangedCandidate[objectName][subObjectName] !== undefined) {
      return this.candidateProduct[objectName][subObjectName]  !== this.lastSupplierChangedCandidateProduct[objectName][subObjectName];
    } else {
      return this.candidateProduct[objectName]  !== this.lastSupplierChangedCandidateProduct[objectName];
    }
  }

  hasBuyerValueChangedForCandidate(objectName: string, subObjectName?: string) {
    if (!this.oldBuyerCandidateFound) {
      return false;
    }

    if (subObjectName && this.lastBuyerChangedCandidate[objectName] !== undefined &&
      this.lastBuyerChangedCandidate[objectName][subObjectName] !== undefined) {
      return this.candidate[objectName][subObjectName]  !== this.lastBuyerChangedCandidate[objectName][subObjectName];
    } else {
      return this.candidate[objectName]  !== this.lastBuyerChangedCandidate[objectName];
    }
  }

  hasScaValueChangedForCandidate(objectName: string, subObjectName?: string) {
    if (!this.oldScaCandidateFound) {
      return false;
    }

    if (subObjectName && this.lastBuyerChangedCandidate[objectName] !== undefined &&
      this.lastBuyerChangedCandidate[objectName][subObjectName] !== undefined) {
      return this.candidate[objectName][subObjectName]  !== this.lastScaChangedCandidate[objectName][subObjectName];
    } else {
      return this.candidate[objectName]  !== this.lastScaChangedCandidate[objectName];
    }
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks'], { queryParams: { tabIndex: 1 } }).then();
  }

  onClickPrint() {
    window.print();
  }

  private resetInitialValues() {
    this.isViewingPage = false;
    this.candidate = undefined;
    this.candidateProduct = undefined;

    this.productImages = [];
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }
    this.supplierProductService.resetMatHierarchyFields();
    // show attributes in context of live attributes while candidate is in progress.
    if (this.candidateUtilService.isInProgressCandidate(this.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatHierarchy(this.candidateProduct).pipe(
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
          this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes)),
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierProductService.hierarchyAttributes,
            this.supplierProductService.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierProductService.globalAttributes,
            this.supplierProductService.productAttributes, this.supplierProductService.warehouseItemAttributes, this.supplierProductService.upcAttributes);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.candidateProduct.globalAttributes, this.supplierProductService.productAttributes,
        this.supplierProductService.warehouseItemAttributes, this.supplierProductService.upcAttributes);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }
}
