import {BaseAttribute} from 'pm-components/lib/attributes/attribute-type/base-attribute';
import {Attribute, AttributeConfig} from 'pm-models';
import {
  AttributeDisplayRadioBooleanComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-radio-boolean/attribute-display-radio-boolean.component';
import {MatUtilService} from '../../../../../../src/app/2.0.0/service/mat-util.service';

export class MatAttributeBooleanRadioInput implements BaseAttribute {
  constructor() { }

  private matAttributeBooleanRadioInputConfig: AttributeConfig;


  getAttributeConfig(overrides): AttributeConfig {
    return this.matAttributeBooleanRadioInputConfig;
  }

  getComponentType(): any {
    return AttributeDisplayRadioBooleanComponent;
  }


  setupComponent(componentInstance: any, attribute: Attribute, overrides?: any): void {
    this.setAttributeComponent(attribute);
    componentInstance.attribute = this.getAttributeConfig(overrides);
    componentInstance.model = attribute.value;
    componentInstance.change.subscribe(event => {
      attribute.value = event;
    });
  }

  private setAttributeComponent(attribute: Attribute) {
    this.matAttributeBooleanRadioInputConfig = MatUtilService.getAttributeDisplayToggleConfigFromAttribute(attribute);
  }
}
