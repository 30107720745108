export class CostLink {

  costLinkNumber: number;
  description: String;
  active: boolean;
  pack: number;
  vendor: any;
  commodity: number;
  listCost: number;
  searchedItemCode: number;

}
