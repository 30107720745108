import { Injectable } from '@angular/core';
import {CandidateService} from './candidate.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private candidateService: CandidateService) { }

  downloadFile(file) {
    this.candidateService.retrieveAttachment(file.uuid, true).subscribe(arrayData => {
      const blob = new Blob([arrayData], { type: file.type });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = file.name;
      anchor.href = url;
      anchor.click();
    });
  }
}
