<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4 helper-col">

  </div>
</div>

<ng-template #card>
  <pm-card title="Label Insights"
           subtitle="Add an image of this product's label or flat packaging and we'll extract ingredients, nutrition data, keywords, and other details important for marketing and eCommerce.">
    <pm-sub-title><a [href]="imageGuideUrl">Image Guide</a></pm-sub-title>
    <ng-container *ngIf="model">
      <pm-label-insights-image-upload [attribute]="labelImageUploadConfiguration"
                                      [(model)]="model.labelInsightsImageLinks"
                                      [attributeError]="errorModel?.labelInsightsImageLinks"
                                      (onImageUpload)="clearLabelInsightsReason()"
                                      (modelChange)="validateLabelInsights()">
      </pm-label-insights-image-upload>
    </ng-container>
    <br>
    <div class="header-row mb-4">
      <p-checkbox [(ngModel)]="model.termsAndConditions"
                  [label]="checkboxLabel"
                  (onChange)="termsAndConditionsChange($event)"
                  [binary]="true">
      </p-checkbox>
      <button class="btn btn-link py-0 pr-0 m-0" style="padding-left: 1px;" (click)="toggleTermsAndConditions($event)">{{'terms and conditions'}}</button>
    </div>
    <p *ngIf="errorModel" class="attribute-error-message">
      {{errorModel?.termsAndConditions}}
    </p>
    <div *ngIf="!hasImages()">
      <div class="labelInsightReasonsHeader">Not uploading a label? Let the Buyer know why below.</div>
      <div class="labelInsightReasons">
            <pm-attribute-display-radio [attribute]="reasonConfiguration"
                                        [(model)]="model.labelInsightOmissionReason"
                                        (modelChange)="onLabelInsightOmissionReasonSelection($event)">
            </pm-attribute-display-radio>
      </div>
      <div class="otherInputRow">
        <pm-attribute-display-text-input [(model)]="otherReason"
                                         (modelChange)="setOtherReason()"
                                         [attribute]="labelInsightOtherReasonConfig"
                                         [attributeError]="getOtherError()">
        </pm-attribute-display-text-input>
        <p *ngIf="getReasonError" class="attribute-error-message pl-2">
          {{getReasonError()}}
        </p>
      </div>
    </div>
  </pm-card>
</ng-template>

<div #termsAndConditionsTextDiv></div>
<pm-static-overlay-panel #termsAndConditionsOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
  <ng-container>
    <div class="row align-items-center term-and-conditions-overlay-wrapper">
      <div class="col terms-and-conditions-overlay">
        <div class="header-row">
          <h2>Terms & conditions</h2>
        </div>
        <br>
        <textarea class="full-width" [(ngModel)]="candidateUtilService.TERMS_AND_CONDITIONS_TEXT" disabled="true" rows="10"></textarea>
        <br>
        <div class="row button-row">
          <p-button class="ghost mr-3" label="Decline" (click)="declineTermsAndConditions($event)"></p-button>
          <button pButton label="Accept" (click)="acceptTermsAndConditions($event)"></button>
        </div>
      </div>
    </div>
  </ng-container>
</pm-static-overlay-panel>
