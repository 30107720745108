<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-4">
    <!-- Helper images -->
  </div>
</div>

<ng-template #card>
  <pm-card title="H-E-B details" subtitle="We'll use this information to help you set up your product correctly.">

    <ng-container *ngIf="model">
      <pm-attribute-display-typeahead [attribute]="buyerConfiguration" [isDropdown]="false" [attributeError]="errorModel?.buyer"
        [(model)]="model.buyer" (selection)="onBuyerChange($event)" (initCallback)="buyerInitCallback($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead *ngIf="commodityConfiguration" [attribute]="commodityConfiguration" [(model)]="model.commodity"
                                      [attributeError]="errorModel?.commodity" (selection)="onCommodityChange($event)">
      </pm-attribute-display-typeahead>

      <div *ngIf="isDsd && errorModel?.noStoresError" style="margin-left: 9rem;">
        <pm-no-stores-message></pm-no-stores-message>
      </div>

      <pm-attribute-display-typeahead *ngIf="subCommodityConfiguration" [attribute]="subCommodityConfiguration" [(model)]="model.subCommodity"
                                      [attributeError]="errorModel?.subCommodity" (selection)="onSubCommodityChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-toggle [attribute]="sellableConfiguration" [attributeError]="errorModel?.productType"
                                   [model]="isSellable" (change)="sellableChange($event)">
      </pm-attribute-display-toggle>

      <pm-attribute-display-typeahead [attribute]="merchandiseTypeConfiguration" [attributeError]="errorModel?.merchandiseType"
                                      [(model)]="model.merchandiseType" (selection)="onMerchandiseTypeChange($event)" (initCallback)="merchandiseTypeInitCallback($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-text-input
        [attribute]="descriptionConfiguration"
        [(model)]="candidateProductModel.description"
        [attributeError]="candidateProductErrorModel?.description">
      </pm-attribute-display-text-input>
   </ng-container>
  </pm-card>
</ng-template>
