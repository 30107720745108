<pm-review #pmReview *ngIf="isViewingPage" [ngClass]="getReviewClass()" (closeEvent)="onClose()">
  <!-- Page Titles -->
  <pm-title>Review MRT</pm-title>
  <pm-sub-title>{{candidate?.description}}</pm-sub-title>

  <div *ngIf="candidate" class="row justify-content-md-center pb-3">
    <div class="col col-md-10 pb-5">
      <!--   MRT items & costs set by supplier   -->
      <pm-attribute-grid class="mrt-inner-info-grid-container" [title]="'MRT items & costs set by supplier'" [canEdit]="!isLoadingInners" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
        <div *ngIf="isLoadingInners" style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingInners" [strokeWidth]="'2'" class="loading-indicator"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching product data...</h5>
        </div>
        <div *ngIf="!isLoadingInners">
          <ng-template ngFor let-inner [ngForOf]="mrtCandidateInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="isPageEditable" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)" [errorMessages]="[candidateProductError?.upc, candidateProductError?.upcCheckDigit]">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(inner).upcCheckDigit}}<br>
                  <button pButton type="button" label="View details" class="ui-button-link inner-details-button" (click)="viewCandidateDetails(inner)"></button>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status === 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="pending">Pending</div>
                  <pm-grid-cell-info>
                    This item is new and requires a separate review
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status !== 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="text-capitalize">{{toLowerCase(inner?.candidate?.status)}}</div>
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).description}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(supplierMrtService.getCurrentCandidateProducts(inner).description) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="isPageEditable" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  -
                </pm-grid-cell>

              </pm-grid-column>
              <hr>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
          <ng-template ngFor let-inner [ngForOf]="mrtExistingInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="isPageEditable" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
                  {{inner?.upc}}-{{inner.upcCheckDigit}}<br>
                  <button pButton type="button" label="View details" class="ui-button-link inner-details-button" (click)="viewProductDetails(inner)"></button>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  Active
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{inner?.product?.productDescription}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(inner?.product?.productDescription) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="isPageEditable"  (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell *ngIf="!productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  {{productService.getItemCode(inner?.product)}}
                </pm-grid-cell>
                <pm-grid-cell *ngIf="productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  Multiple
                  <pm-grid-cell-info>
                    This item has multiple case configurations
                  </pm-grid-cell-info>
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
        </div>

        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'"  [canEdit]="isPageEditable" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]" (editClicked)="showEditCandidateModal(attributeType.MasterListCost)">
              {{costService.toCurrencyForCost(candidate?.masterListCost)}}
              <pm-grid-cell-info style="white-space: pre-line;">
                <div *ngIf="!isNotMissingCandidateInfo" >
                  {{getCostDisplay()}}
                </div>
                <div *ngIf="isNotMissingCandidateInfo">
                  Penny Profit: {{getPennyProfit()}}<br>
                  <a>Margin: <a [ngClass]="{'grid-cell-error':isMarginNegativeOrZero()}">{{getMarginPercent()}}%</a>
                    <img *ngIf="isMarginNegativeOrZero()" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
                  </a>
                </div>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>
      <!-- Case summary -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="isPageEditable"
                          (editClicked)="editCaseDescription(attributeType.CaseDescription)"
                          [errorMessages]="[candidateProductError?.caseDescription]">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="isPageEditable" (editClicked)="editCaseUPC()"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.buyer" (editClicked)="editBuyer()">
              {{candidate?.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="isPageEditable" (editClicked)="showEditCandidateProductModal(attributeType.VendorProductCode)" [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="isPageEditable" (editClicked)="showEditCandidateModal(attributeType.Channel)"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>

          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="isPageEditable"
                          (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master Pack'" [canEdit]="false">
              1
            </pm-grid-cell>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
              {{getMasterPack()}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.masterLength, candidateError?.masterWidth, candidateError?.masterHeight]" (editClicked)="showEditCandidateModal(attributeType.Dimensions)">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.vendorTie,candidateError?.vendorTier ]" (editClicked)="showEditCandidateModal(attributeType.BoxesOnPallet)">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="isTiedToCatchOrVariableWeightBuyer() && isPageEditable" [errorMessage]="candidateError?.itemWeightType" (editClicked)="showEditCandidateModal(attributeType.ItemWeightType)">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.masterWeight" (editClicked)="showEditCandidateModal(attributeType.Weight)">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.cubeAdjustedFactor" (editClicked)="showEditCandidateModal(attributeType.OrderFactor)">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="isPageEditable"  [errorMessage]="candidateError?.codeDate" (editClicked)="showEditCandidateModal(attributeType.CodeDate)">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="candidate?.codeDate && isPageEditable" [errorMessage]="candidateError?.maxShelfLife" (editClicked)="showEditCandidateModal(attributeType.MaxShelfLife)">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound Spec'" [canEdit]="candidate?.inboundSpecDays && isPageEditable" [errorMessage]="candidateError?.inboundSpecDays" (editClicked)="showEditCandidateModal(attributeType.InboundSpecDays)">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.displayReadyUnit" (editClicked)="showEditCandidateModal(attributeType.DisplayReadyUnit)">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="isPageEditable"  [errorMessage]="candidateError?.displayReadyUnitOrientation" (editClicked)="showEditCandidateModal(attributeType.Orientation)">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.displayReadyUnitRowsDeep, candidateError?.displayReadyUnitRowsFacing, candidateError?.displayReadyUnitRowsHigh]" (editClicked)="showEditCandidateModal(attributeType.RetailUnitCount)">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.costOwner"  (editClicked)="editCostOwner()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false" [errorMessage]="candidateError?.buyer">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false" [errorMessage]="candidateError?.topToTop">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="false">
              {{candidate.lane?.name}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false" [errorMessage]="candidateError?.vendor">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <div *ngIf="isPageEditable">
          <!--   Global Attributes   -->
          <pm-attribute-grid *ngIf="isLoadingMatData || !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
        <div *ngIf="!isPageEditable">

          <!--  Global Attributes    -->
          <pm-attribute-grid *ngIf="!!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="isPageEditable"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.buyerComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate?.supplierComment">
                <b>{{candidate?.contactEmail}}</b> <br>
                {{candidate?.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>
            </pm-comment-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <pm-attachment-upload-review-page [attachments]="candidate.attachments" (updateCallback)="addAttachments($event)"></pm-attachment-upload-review-page>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

    </div>
  </div>

  <pm-review-drawer-container>
    <ng-container *ngIf="isShowingBuyerDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link"
                    (click)="save()"></button>
          </div>
        </div>
        <div class="attributes-stacked">

          <h2>Buyer Details</h2>
          <p>
            Provide the following information and click approve to accept the MRT and send it to SCA and Procurement Support review.
          </p>
          <h3>Required Fields</h3>

          <pm-attribute-display-typeahead [attribute]="commodityConfiguration" [(model)]="candidate.commodity"
                                          [attributeError]="candidateError?.commodity" (selection)="commodityChange($event)">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-typeahead [attribute]="subCommodityConfiguration" [(model)]="candidate.subCommodity"
                                          [attributeError]="candidateError?.subCommodity" (selection)="subCommodityChange($event)">
          </pm-attribute-display-typeahead>
          <pm-attribute-display-text-input [attribute]="inboundSpecConfiguration"
                                           [attributeError]="candidateError?.inboundSpecDays"
                                           [(model)]="candidate.inboundSpecDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="reactionDaysConfiguration"
                                           [(model)]="candidate.warehouseReactionDays" [attributeError]="candidateError?.warehouseReactionDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="guaranteeToStoreDaysConfiguration"
                                           [(model)]="candidate.guaranteeToStoreDays" [attributeError]="candidateError?.guaranteeToStoreDays">
          </pm-attribute-display-text-input>

          <h3>Additional fields</h3>
          <div class="row season-year-row">
            <div class="col-9" style="padding-right: 0;">
              <pm-attribute-display-typeahead [attribute]="seasonConfiguration"
                                              [(model)]="candidate.season"
                                              [attributeError]="candidateError?.season"
                                              (selection)="seasonChange($event)"
                                              [showErrorMessage]="false">
              </pm-attribute-display-typeahead>
            </div>
            <div class="col-3" style="padding-left: 0;">
              <pm-attribute-display-text-input [attribute]="yearConfiguration" [(model)]="candidate.seasonYear"
                                               [attributeError]="candidateError?.seasonYear"
                                               [showErrorMessage]="false">
              </pm-attribute-display-text-input>
            </div>
          </div>
          <p *ngIf="candidateError?.season" class="error-message pl-2">
            {{candidateError.season}}
          </p>
          <p *ngIf="candidateError?.seasonYear" class="error-message pl-2">
            {{candidateError.seasonYear}}
          </p>
          <pm-attribute-display-text-input [attribute]="remark1Configuration"
                                           [(model)]="candidateProduct.remark1"
                                           [attributeError]="candidateProductError?.remark1">
          </pm-attribute-display-text-input>
          <pm-attribute-display-text-input [attribute]="remark2Configuration"
                                           [(model)]="candidateProduct.remark2"
                                           [attributeError]="candidateProductError?.remark2">
          </pm-attribute-display-text-input>
          <pm-attribute-display-checkbox class="checkbox-padding" [attribute]="rushFlagConfiguration"
                                         [(model)]="candidate.rushFlag">
          </pm-attribute-display-checkbox>
          <div class="text-right">
            <p-button class="m-2" label="Approve" (onClick)="onClickApprove()" [disabled]="isApproveDisabled"></p-button>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="isShowingProductDetails">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>UPC Details</h2>
        <div>
          <pm-attribute-grid [title]="'Overview'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'UPC'">
                  {{currentUpc?.scanCodeId}}-{{currentUpc?.scanCodeId | upcCheckDigit}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Brand'">
                  {{currentExistingInner?.product?.brand ? currentExistingInner?.product?.brand.description + ' [' + currentExistingInner?.product?.brand.brandId + ']': ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-brand'">
                  {{currentUpc?.subBrand ? currentUpc?.subBrand.description + ' [' + currentUpc?.subBrand.subBrandId + ']': ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Buyer'">
                  {{currentUpc?.item?.hierarchy?.commodity?.bdm ? currentUpc?.item?.hierarchy?.commodity?.bdm.fullName +
                  ' [' + currentUpc?.item?.hierarchy?.commodity?.bdm.bdmId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Product description'">
                  {{currentProduct?.productDescription}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(currentProduct?.productDescription) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell [label]="'Commodity'">
                  {{currentUpc?.item?.hierarchy?.commodity ? currentUpc?.item?.hierarchy?.commodity.description +
                  ' [' + currentUpc?.item?.hierarchy?.commodity.commodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-commodity'">
                  {{currentUpc?.item?.hierarchy ? currentUpc?.item?.hierarchy?.description +
                  ' [' + currentUpc?.item?.hierarchy?.subCommodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Merchandise type'">
                  {{currentProduct?.productTypeId}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentProduct?.productTypeId === 'GOODS'| yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Unit details'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentUpc?.unitOfMeasure?.description}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{currentUpc?.length}}in x
                  {{currentUpc?.width}}in x
                  {{currentUpc?.height}}in
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit size'">
                  {{currentUpc?.size}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 1'">
                  {{currentProduct?.customerFriendlyDescriptionOne}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 2'">
                  {{currentProduct?.customerFriendlyDescriptionTwo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentProduct?.productTypeId === 'GOODS' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Packaging & regulatory info'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Food stamp eligible'">
                  {{currentProduct?.foodStamp | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Taxable'">
                  {{currentProduct?.taxable | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'FSA/HSA eligible'">
                  {{currentProduct?.flexibleSpendingAccount | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Package type'">
                  {{currentProduct?.packageDescription}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{currentUpc?.length}}in x
                  {{currentUpc?.width}}in x
                  {{currentUpc?.height}}in
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentUpc?.unitOfMeasure?.description}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Retail size'">
                  {{currentUpc?.size}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Weight'">
                  {{candidateUtilService.getWeightString(currentUpc?.weight)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit Size'">
                  {{currentUpc?.item?.totalVolume}}
                </pm-grid-cell>

              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isShowingCandidateDetails">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>UPC Details</h2>
        <div>
          <pm-attribute-grid [title]="'Overview'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'UPC'">
                  {{currentInnerCandidateProduct?.upc}}-{{currentInnerCandidateProduct?.upcCheckDigit}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Brand'">
                  {{currentInnerCandidate?.brand?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-brand'">
                  {{currentInnerCandidateProduct?.subBrand?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Buyer'">
                  {{currentInnerCandidate?.buyer?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Product description'">
                  {{currentInnerCandidateProduct?.description}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(currentInnerCandidateProduct?.description) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell [label]="'Commodity'">
                  {{currentInnerCandidate?.commodity ? currentInnerCandidate?.commodity?.commodityName + ' [' + currentInnerCandidate?.commodity?.commodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-commodity'">
                  {{currentInnerCandidate?.subCommodity ? currentInnerCandidate?.subCommodity?.subCommodityName + ' [' + currentInnerCandidate?.subCommodity?.subCommodityId + ']' : ''}}

                </pm-grid-cell>
                <pm-grid-cell [label]="'Merchandise type'">
                  {{currentInnerCandidate?.merchandiseType ? currentInnerCandidate?.merchandiseType?.description + ' [' + currentInnerCandidate?.merchandiseType?.merchandiseTypeCode + ']' : ''}}

                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentInnerCandidate.productType === 'SELLABLE' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Unit details'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentInnerCandidate?.unitOfMeasure.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{candidateUtilService.getMasterDimensions(currentInnerCandidate)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit size'">
                  {{currentInnerCandidate.retailSize}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 1'">
                  {{currentInnerCandidateProduct?.customerFriendlyDescription1}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 2'">
                  {{currentInnerCandidateProduct?.customerFriendlyDescription2}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentInnerCandidate.productType === 'SELLABLE' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Packaging & regulatory info'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Food stamp eligible'">
                  {{currentInnerCandidate?.foodStamp | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Taxable'">
                  {{currentInnerCandidate?.taxable | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'FSA/HSA eligible'">
                  {{currentInnerCandidate?.flexibleSpendingAccount | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Package type'">
                  {{currentInnerCandidate?.packageType?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{candidateUtilService.getProductDimensionsString(currentInnerCandidate)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentInnerCandidate?.unitOfMeasure?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Retail size'">
                  {{currentInnerCandidate?.retailSize}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Weight'">
                  {{candidateUtilService.getProductWeightString(currentInnerCandidate?.productWeight)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit Size'">
                  {{currentInnerCandidate?.totalVolume}}
                </pm-grid-cell>

              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="buyerComments"
                                           [(model)]="tempCommentHolder"
                                           [attributeError]="candidateError?.buyerComment">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.buyerComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.buyerComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button *ngIf="isPageEditable" class="ghost m-2" label="Reject" (onClick)="onClickReject($event, op, targetDiv)" [disabled]="isRejectDisabled"></p-button>
    <p-button *ngIf="isPageEditable" class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-review>
<div>
  <div #targetDiv></div>
  <pm-static-overlay-panel #op [style]="{width: '100%', height: '100%'}">
    <div *ngIf="hasPendingReplenishableInner()" class="row align-items-center delete-modal-wrapper">
      <div class="col-md-5 delete-modal">
        <div class="row pt-3">
          <h2>
            Reject candidate?
          </h2>
        </div>
        <div class="row mb-1">
          <p>
            Since this MRT contains one or more new replenishable items, you can choose to reject those as well or only reject the MRT. Rejecting an MRT always rejects its non-replenishable items (which don’t have a case besides the MRT).
          </p>
          <p>
            Let the Supplier know why you’re rejecting this MRT.
          </p>
        </div>
        <div class="attributes-stacked ml-3">
          <pm-attribute-display-radio [attribute]="reasonConfiguration"
                                      [(model)]="reasonType"
                                      (change)="reasonTypeChange($event)">
          </pm-attribute-display-radio>
          <div *ngIf="reasonType === OTHER" class="other-reason-row">
            <input id="pendingOtherReasonMessage" pInputText
                   placeholder="Provide a custom reason..."
                   type="text"
                   accept-charset="UTF-8"
                   [(ngModel)]="otherReasonMessage"/>
          </div>
        </div>
        <div class="row button-row">
          <button pButton class="cancel mr-3" label="Cancel" (click)="op.hide()" [disabled]="isApproveDisabled"></button>
          <button pButton class="ui-button-danger mr-3" label="Reject MRT only" (click)="rejectMrt()" [disabled]="isRejectButtonDisabled()"></button>
          <button pButton class="ui-button-danger" label="Reject MRT and new items" (click)="rejectMrtAndNewItems(op)" [disabled]="isRejectButtonDisabled()"></button>
        </div>
      </div>
    </div>
    <pm-reject-candidate-overlay *ngIf="!hasPendingReplenishableInner()" (cancelClicked)="op.hide()" (rejectButtonClicked)="rejectMrtAndCloseModal($event, op, targetDiv)"></pm-reject-candidate-overlay>
  </pm-static-overlay-panel>
</div>

<div class="mrt-items-modal">
  <div #targetDiv></div>
  <pm-static-overlay-panel #editMrtOverlayPanel [style]="{width: '100%', height: '100%'}" [dismissable]="false">
    <div class="row align-items-center mrt-items-modal-wrapper" style="width: 100%; height: 100%; justify-content: center;">

      <div class="col-md-6 mrt-items-modal-div">
        <div class="mrt-items-body">
          <h1 style="text-align: left;">Products in this MRT</h1>

          <div class="col-md-12">
            <div class="row">
              <div class="col">
                <div class="row">
                  <label>Add products to the MRT (activated products only).</label>
                </div>
                <div class="row">
                  <pm-upc-input
                    [(upcModel)]="searchedCandidateProduct.upc"
                    [(checkDigitModel)]="searchedCandidateProduct.upcCheckDigit"
                    [(upcType)]="upcType"
                    [readOnly]="false"
                    [disabled]="false"
                    [hasError]="hasError()"
                    placeholderText="Enter UPC"
                    checkDigitPlaceholderText="Check #"
                    [upcOptions]="upcOptions"
                    [state]="upcState"
                    (upcModelChange)="setUpcAndValidate()"
                    (checkDigitModelChange)="setUpcAndValidate()"
                    (shiftEnterEmitted)="addItem()"
                    style="width: 100%;">
                  </pm-upc-input>
                </div>
                <div class="row">
                  <button pButton type="button" label="+ Add item" class="ui-button-link add-item-button" (click)="addItem()"></button>
                  <p class="add-item-description">&nbsp;or hit Shift + Enter to add multiple items quickly.</p>
                </div>
                <div class="row" *ngIf="hasError()">
                  <p *ngIf="searchedCandidateProductError?.upc" class="error-message">{{searchedCandidateProductError?.upc}}</p>
                  <p *ngIf="searchedCandidateProductError?.upcCheckDigit" class="error-message">{{searchedCandidateProductError?.upcCheckDigit}}</p>
                </div>
              </div>
            </div>

            <div *ngFor="let inner of mrtModalCandidateInners; let index = index" class="row">
              <div class="col-md-12">
                <div class="row pt-4">
                  <div class="col-md-8">
                    <div class="row header-label">
                      UPC: {{supplierMrtService.getCurrentCandidateProducts(inner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(inner).upcCheckDigit}}
                    </div>
                    <div class="row attribute-description">
                      Status: New {{!inner.replenishable ? ' (non-replenish)': ''}}<br>
                      Item code: -<br>
                      Product desc: {{supplierMrtService.getCurrentCandidateProducts(inner).description}}
                    </div>
                  </div>
                  <div class="col-md-3 attributes-stacked item-attribute">
                    <pm-attribute-display-text-input [attribute]="quantityConfiguration"
                                                     [(model)]="inner.quantity"
                                                     [attributeError]="mrtModalCandidateError?.mrtInfoError.candidateInnerErrors[inner.candidateId]?.quantity">
                    </pm-attribute-display-text-input>
                  </div>
                  <div class="col-md-1 delete-inner-button">
                    <pm-delete-button (deleteButtonClicked)="deleteCandidateInner(index)" [showConfirmationModal]="true"
                                      [disabled]="supplierMrtService.isDeleteInnerDisabled(mrtModalCandidateInners, mrtModalExistingInners)"
                                      [pTooltip]="supplierMrtService.getDeleteInnerDisabledTooltip(mrtModalCandidateInners, mrtModalExistingInners)">
                    </pm-delete-button>
                  </div>
                </div>

              </div>
            </div>
            <div *ngFor="let inner of mrtModalExistingInners; let index = index" class="row">
              <div class="col-md-12">
                <div class="row pt-4">
                  <div class="col-md-8" *ngIf="inner.product">
                    <div class="row header-label">
                      UPC: {{inner.upc}}-{{inner.upcCheckDigit}}
                    </div>
                    <div class="row attribute-description">
                      Status: Activated<br>
                      {{supplierMrtService.getItemCodeString(inner.product)}}<br>
                      Product desc: {{inner?.product.productDescription}}
                    </div>
                  </div>
                  <div class="col-md-3 attributes-stacked item-attribute">
                    <pm-attribute-display-text-input [attribute]="quantityConfiguration"
                                                     [(model)]="inner.quantity"
                                                     [attributeError]="mrtModalCandidateError?.mrtInfoError.existingInnerErrors[inner.upc]?.quantity">
                    </pm-attribute-display-text-input>
                  </div>
                  <div class="col-md-1 delete-inner-button">
                    <pm-delete-button (deleteButtonClicked)="deleteExistingInner(index)" [showConfirmationModal]="true"
                                      [disabled]="supplierMrtService.isDeleteInnerDisabled(mrtModalCandidateInners, mrtModalExistingInners)"
                                      [pTooltip]="supplierMrtService.getDeleteInnerDisabledTooltip(mrtModalCandidateInners, mrtModalExistingInners)">
                    </pm-delete-button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="mrt-items-footer">
          <div class="row m-3">
            <div class="col-md-12 text-right pr-0">
              <button pButton class="cancel m-2" label="Cancel" (click)="cancelEditMrtItems(editMrtOverlayPanel)" [disabled]="isSaveMrtButtonDisabled"></button>
              <p-button class="m-2" label="Save" (onClick)="saveMrtAndCloseModal(editMrtOverlayPanel)" [disabled]="isSaveMrtButtonDisabled"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </pm-static-overlay-panel>
</div>



<div>
  <div #rnaMatConfirmDiv></div>
  <pm-static-overlay-panel #rnaMatConfirmOverlayPanel appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'" [hideTransitionOptions]="'0ms'">
    <pm-request-new-attribute-confirm-overlay *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                              [title]="'Request a new ' + requestNewMatAttributeOverrideWrapper?.requestNewMatAttributeForm?.attributeBusinessFriendlyDescription + ' value'"
                                              [classOverride]="'col-md-5'"
                                              (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatConfirmOverlayPanel, requestNewMatAttributeOverrideWrapper)"
                                              (continueButtonClicked)="matUtilService.showRequestNewAttributeFormPanel($event, rnaMatFormOverlay, rnaMatFormOverlayDiv, rnaMatConfirmOverlayPanel)">
    </pm-request-new-attribute-confirm-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatFormOverlayDiv></div>
  <pm-static-overlay-panel #rnaMatFormOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'">
    <pm-request-new-mat-attribute-form *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                       (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatFormOverlay, requestNewMatAttributeOverrideWrapper)"
                                       [(model)]="requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm"
                                       (sendRequestClicked)="matUtilService.sendRequestAndCloseModal(this.candidate, requestNewMatAttributeOverrideWrapper, rnaMatFormOverlay)">
    </pm-request-new-mat-attribute-form>
  </pm-static-overlay-panel>
</div>
