import { Component, OnInit, ContentChild } from '@angular/core';
import { TitleComponent } from '../../shared/common';

@Component({
  selector: 'pm-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit {


  @ContentChild(TitleComponent) headerFacet;

  constructor() { }

  ngOnInit() {
  }

}
