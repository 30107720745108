<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-4">
    <!-- -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Basics" subtitle="Provide the basic details about this new product.">
    <ng-container *ngIf="model">
      <pm-attribute-display-text-input [attribute]="vendorProductCodeConfiguration" [(model)]="model.vendorProductCode"
                                       [attributeError]="errorModel?.vendorProductCode" (change)="vendorProductCodeChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-typeahead [attribute]="countryOfOriginConfiguration" [(model)]="model.countryOfOrigin"
                                      [attributeError]="errorModel?.countryOfOrigin" (selection)="countryOfOriginChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead [attribute]="subBrandConfiguration" [(model)]="model.subBrand"
                                      [attributeError]="errorModel?.subBrand" (selection)="subBrandChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-upc [attribute]="caseUPCConfiguration" [(model)]="model.caseUpc"
                                [attributeError]="errorModel?.caseUpc"
                                [(checkDigitModel)]="model.caseUpcCheckDigit"
                                (validateUpc)="validateUpcEvent($event)"
                                [state]="upcState">
      </pm-attribute-display-upc>

      <pm-attribute-display-text-input [attribute]="caseDescriptionConfiguration"
                                       [(model)]="model.description"
                                       [attributeError]="errorModel?.description"
                                       (change)="descriptionChange($event)">
      </pm-attribute-display-text-input>
    </ng-container>

  </pm-card>
</ng-template>
