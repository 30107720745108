import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayTextinputComponent} from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';
import { Candidate, AttributeConfig } from 'pm-models';

export class MaxShip implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Max Ship',
      description: 'The maximum # of cases of this product that a store can receive.',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      defaultValue: false,
      name: 'maxShipId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (candidate.maxShip === undefined) {
      candidate.maxShip = this.defaultVal;
    }
    componentInstance.model = candidate.maxShip;
    componentInstance.modelChange.subscribe(x => {
      candidate.maxShip = x;
    });
  }
}
