import {Injectable} from '@angular/core';
import {
  Attribute,
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateProduct,
  CandidateProductError,
  MatHierarchy,
  MetaDataDomain,
  Product,
  TextInputType
} from 'pm-models';
import {CandidateUtilService} from './candidate-util.service';
import {from, Observable, of} from 'rxjs';
import {map, mergeMap, switchMap, tap, toArray} from 'rxjs/operators';
import {LookupService} from './lookup.service';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';
import {EmailService} from './email.service';
import {GrowlService} from '../growl/growl.service';

@Injectable({
  providedIn: 'root'
})
export class MatUtilService {

  private static readonly TYPEAHEAD_INPUT = 'TYPEAHEAD';
  private static readonly TEXT_INPUT = 'TEXT_INPUT';
  private static readonly TOGGLE_INPUT = 'TOGGLE_INPUT';
  private static readonly BOOLEAN_RADIO_INPUT = 'BOOLEAN_RADIO_INPUT';
  private static readonly DATE_PICKER_INPUT = 'DATE_PICKER_INPUT';

  public static readonly PRODUCT_APPLICABLE_TYPE_CODE = 'PROD';
  public static readonly WAREHOUSE_ITEM_APPLICABLE_TYPE_CODE = 'ITMCD';
  public static readonly DSD_ITEM_APPLICABLE_TYPE_CODE = 'DSD';
  public static readonly UPC_APPLICABLE_TYPE_CODE = 'UPC';
  public static readonly MAX_PARALLEL_QUERIES = 5;

  constructor(private lookupService: LookupService, private emailService: EmailService, private growlService: GrowlService) {
  }

  /**
   * Returns the appropriate attribute config from the provided attribute.
   *
   * @param attribute the attribute.
   */
  public static getAttributeConfigurationFromAttribute(attribute: Attribute): AttributeConfig {

    if (MatUtilService.isInvalidAttributeDomain(attribute)) {
      console.error('Error, please provide a valid attribute.');
      return null;
    }

    if (attribute.domain.valueSelectable) {
      return this.getAttributeTypeaheadConfigFromAttribute(attribute);
    }
    switch (attribute.domain.type.id.trim()) {
      case MetaDataDomain.DECIMAL_TYPE_ID: {
        return this.getDecimalAttributeTextInputConfigFromAttribute(attribute);
      }
      case MetaDataDomain.DATE_TYPE_ID: {
        return this.getAttributeDisplayDatePickerConfigFromAttribute(attribute);
      }
      case MetaDataDomain.INTEGER_TYPE_ID: {
        return this.getIntegerAttributeTextInputConfigFromAttribute(attribute);
      }
      case MetaDataDomain.STRING_TYPE_ID: {
        return this.getStringAttributeTextInputConfigFromAttribute(attribute);
      }
      case MetaDataDomain.BOOLEAN_TYPE_ID: {
        return this.getAttributeDisplayBooleanRadioButtonConfigFromAttribute(attribute);
      }
      case MetaDataDomain.IMAGE_TYPE_ID:
      case MetaDataDomain.TIMESTAMP_TYPE_ID:
      default: {
        console.error('Attribute domain type: "' + attribute.domain.type.description + '" is not implemented!');
        return null;
      }
    }
  }


  /**
   * Returns the appropriate attribute config from the provided attribute.
   *
   * @param attribute the attribute.
   */
  public static getAttributeTextInputConfigurationFromAttribute(attribute: Attribute): AttributeTextInputConfig {

    if (!attribute || !attribute.domain || !attribute.domain.type || !attribute.domain.type.id) {
      console.error('Error, please provide a valid attribute for text input configuration.');
      return null;
    }

    switch (attribute.domain.type.id.trim()) {

      case MetaDataDomain.DECIMAL_TYPE_ID: {
        return this.getDecimalAttributeTextInputConfigFromAttribute(attribute);
      }
      case MetaDataDomain.INTEGER_TYPE_ID: {
        return this.getIntegerAttributeTextInputConfigFromAttribute(attribute);
      }
      case MetaDataDomain.STRING_TYPE_ID: {
        return this.getStringAttributeTextInputConfigFromAttribute(attribute);
      }
      default: {
        console.error('Attribute domain type: "' + attribute.domain.type.description + '" is not implemented for a text input configuration!');
        return null;
      }
    }
  }


  /**
   * Returns a integer text input config from an attribute.
   *
   * @param attribute the attribute.
   */
  public static getAttributeDisplayDatePickerConfigFromAttribute(attribute: Attribute): AttributeConfig {
    return {
      discriminator: this.DATE_PICKER_INPUT,
      label: attribute.descriptions.businessFriendlyDescription,
      description: attribute.descriptions.fieldDescription,
      isDisabled: () => false,
      isReadOnly: () => false,
      placeholderText: 'Select a date...',
      matAttributeId: attribute.identifiers.fieldId,
      isRequired: attribute.domain.required
    };
  }

  /**
   * Returns a toggle input config from an attribute.
   *
   * @param attribute the attribute.
   */
  public static getAttributeDisplayToggleConfigFromAttribute(attribute: Attribute): AttributeConfig {
    return {
      discriminator: this.TOGGLE_INPUT,
      label: attribute.descriptions.businessFriendlyDescription,
      description: attribute.descriptions.fieldDescription,
      isRequired: attribute.domain.required,
      isDisabled: () => false,
      isReadOnly: () => false,
      matAttributeId: attribute.identifiers.fieldId,
      defaultValue: false
    };
  }

  /**
   * Returns a boolean input config from an attribute.
   *
   * @param attribute the attribute.
   */
  public static getAttributeDisplayBooleanRadioButtonConfigFromAttribute(attribute: Attribute): AttributeConfig {
    return {
      discriminator: this.BOOLEAN_RADIO_INPUT,
      label: attribute.descriptions.businessFriendlyDescription,
      description: attribute.descriptions.fieldDescription,
      isRequired: attribute.domain.required,
      inputGroupClass: 'attribute-radios-block',
      isDisabled: () => false,
      isReadOnly: () => false,
      matAttributeId: attribute.identifiers.fieldId,
      defaultValue: undefined
    };
  }

  /**
   * Returns a string text input config from an attribute.
   *
   * @param attribute the attribute.
   */
  public static getStringAttributeTextInputConfigFromAttribute(attribute: Attribute): AttributeTextInputConfig {
    return {
      discriminator: this.TEXT_INPUT,
      label: attribute.descriptions.businessFriendlyDescription,
      description: attribute.descriptions.fieldDescription,
      isRequired: attribute.domain.required,
      isDisabled: () => false,
      isReadOnly: () => false,
      textInputType: TextInputType.text,
      placeholderText: '#',
      inputGroupClass: 'ui-narrow-input',
      matAttributeId: attribute.identifiers.fieldId,
      maxLength: attribute.domain.maxLengthNumber
    };
  }

  /**
   * Returns a integer text input config from an attribute.
   *
   * @param attribute the attribute.
   */
  public static getIntegerAttributeTextInputConfigFromAttribute(attribute: Attribute): AttributeTextInputConfig {
    return {
      discriminator: this.TEXT_INPUT,
      label: attribute.descriptions.businessFriendlyDescription,
      description: attribute.descriptions.fieldDescription,
      isRequired: attribute.domain.required,
      isDisabled: () => false,
      isReadOnly: () => false,
      textInputType: TextInputType.integer,
      placeholderText: '#',
      inputGroupClass: 'ui-narrow-input',
      matAttributeId: attribute.identifiers.fieldId,
      maxLength: attribute.domain.maxLengthNumber
    };
  }

  /**
   * Returns a decimal text input config from an attribute.
   *
   * @param attribute the attribute.
   */
  public static getDecimalAttributeTextInputConfigFromAttribute(attribute: Attribute): AttributeTextInputConfig {
    return {
      discriminator: this.TEXT_INPUT,
      label: attribute.descriptions.businessFriendlyDescription,
      description: attribute.descriptions.fieldDescription,
      isRequired: attribute.domain.required,
      isDisabled: () => false,
      isReadOnly: () => false,
      textInputType: TextInputType.decimal,
      placeholderText: '#',
      inputGroupClass: 'ui-narrow-input',
      decimalCount: attribute.domain.attributePrecisionNumber,
      numberCount: attribute.domain.maxLengthNumber,
      matAttributeId: attribute.identifiers.fieldId
    };
  }

  /**
   * Returns a typeahead config from an attribute.
   *
   * @param attribute the attribute.
   * @param values the values to use for the typeahead. If not provided, it will assume those from the attribute.
   */
  public static getAttributeTypeaheadConfigFromAttribute(attribute: Attribute, values?: []): AttributeTypeaheadConfig {
    return {
      discriminator: this.TYPEAHEAD_INPUT,
      label: attribute.descriptions.businessFriendlyDescription,
      description: attribute.descriptions.fieldDescription,
      isRequired: attribute.domain.required,
      isDisabled: () => false,
      isReadOnly: () => false,
      isHidden: () => false,
      matAttributeId: attribute.identifiers.fieldId,
      idRef: 'id',
      displayRef: 'description',
      collections: values ? values : attribute.domain.values,
      placeholderText: 'Select an option...',
      allowMultiple: attribute.domain.multipleValues,
      showButtonOption: true,
      buttonLabel: 'Request a new ' + attribute.descriptions.businessFriendlyDescription
    };
  }


  /**
   * Returns true if it's a typeahead configuration.
   *
   * @param attributeConfig the attribute configuration.
   */
  isTypeaheadConfiguration(attributeConfig: AttributeConfig): boolean {
    return (attributeConfig.discriminator === MatUtilService.TYPEAHEAD_INPUT);
  }

  /**
   * Returns true if it's a text input configuration.
   *
   * @param attributeConfig the attribute configuration.
   */
  isTextInputConfiguration(attributeConfig: AttributeConfig): boolean {
    return (attributeConfig.discriminator === MatUtilService.TEXT_INPUT);
  }

  /**
   * Returns true if it's a toggle input configuration.
   *
   * @param attributeConfig the attribute configuration.
   */
  isToggleInputConfiguration(attributeConfig: AttributeConfig): boolean {
    return (attributeConfig.discriminator === MatUtilService.TOGGLE_INPUT);
  }

  /**
   * Returns true if it's a toggle input configuration.
   *
   * @param attributeConfig the attribute configuration.
   */
  isBooleanRadioInputConfiguration(attributeConfig: AttributeConfig): boolean {
    return (attributeConfig.discriminator === MatUtilService.BOOLEAN_RADIO_INPUT);
  }

  /**
   * Returns true if it's a date picker input configuration.
   *
   * @param attributeConfig the attribute configuration.
   */
  isDatePickerInputConfiguration(attributeConfig: AttributeConfig): boolean {
    return (attributeConfig.discriminator === MatUtilService.DATE_PICKER_INPUT);
  }

  /**
   * Returns the appropriate attribute config from the provided attribute.
   *
   * @param attribute the attribute.
   */
  public static isTypeaheadDisplayType(attribute: Attribute): boolean {

    if (!attribute || !attribute.domain) {
      console.error('Error, please provide a valid attribute.');
      return false;
    }
    return !!attribute.domain.valueSelectable;
  }

  /**
   * Returns the appropriate attribute config from the provided attribute.
   *
   * @param attribute the attribute.
   */
  public static isTextInputDisplayType(attribute: Attribute): boolean {

    if (!attribute || !attribute.domain) {
      console.error('Error, please provide a valid attribute.');
      return false;
    } else if (!attribute.domain.type?.id) {
      return false;
    }

    return [MetaDataDomain.DECIMAL_TYPE_ID, MetaDataDomain.INTEGER_TYPE_ID, MetaDataDomain.STRING_TYPE_ID]
        .includes(attribute.domain.type.id.trim());
  }

  /**
   * Returns the appropriate attribute config from the provided attribute.
   *
   * @param attribute the attribute.
   */
  public static isDatePickerDisplayType(attribute: Attribute): boolean {


    if (!attribute || !attribute.domain) {
      console.error('Error, please provide a valid attribute.');
      return false;
    } else if (!attribute.domain.type?.id) {
      return false;
    }

    return attribute.domain.type.id.trim() === MetaDataDomain.DATE_TYPE_ID;
  }

  /**
   * Returns the appropriate attribute config from the provided attribute.
   *
   * @param attribute the attribute.
   */
  public static isBooleanRadioInputDisplayType(attribute: Attribute): boolean {

    if (!attribute || !attribute.domain) {
      console.error('Error, please provide a valid attribute.');
      return false;
    } else if (!attribute.domain.type?.id) {
      return false;
    }

    return attribute.domain.type.id.trim() === MetaDataDomain.BOOLEAN_TYPE_ID;
  }

  /**
   * Returns the selected hierarchy attribute for the given hierarchy and field id attribute.
   * @param candidate
   * @param matHierarchyId
   * @param fieldId
   */
  public getSelectedHierarchyAttribute(candidate: Candidate, matHierarchyId: number, fieldId: string): Attribute {

    const matHierarchy = this.getMatHierarchy(candidate, matHierarchyId);
    if (!matHierarchy || !matHierarchy.attributes) {
      return null;
    } else {
      return matHierarchy.attributes.find((attr) => attr.identifiers.fieldId === fieldId);
    }
  }
  /**
   * Returns the selected hierarchy attribute for the given hierarchy and field id attribute.
   * @param candidateProduct
   * @param matHierarchyId
   * @param fieldId
   */
  public getSelectedCandidateProductHierarchyAttribute(candidateProduct: CandidateProduct, matHierarchyId: number, fieldId: string): Attribute {

    const matHierarchy = this.getMatHierarchyFromCandidateProduct(candidateProduct, matHierarchyId);
    if (!matHierarchy || !matHierarchy.attributes) {
      return null;
    } else {
      return matHierarchy.attributes.find((attr) => attr.identifiers.fieldId === fieldId);
    }
  }

  /**
   * Returns the selected hierarchy attribute for the given field id.
   * @param candidateProduct
   * @param fieldId
   */
  public getHierarchyAttribute(candidateProduct: CandidateProduct, fieldId: string): Attribute {

    if (!candidateProduct?.matHierarchyList?.length || !fieldId) {
      return null;
    }
    for (const matHierarchy of candidateProduct.matHierarchyList) {
      if (!matHierarchy || !matHierarchy.attributes?.length) {
        continue;
      }
      const attribute = matHierarchy.attributes.find((attr) => attr.identifiers.fieldId === fieldId);
      if (attribute) {
        return attribute;
      }
    }
    return null;
  }


  public getSelectedGlobalAttribute(candidate: Candidate, fieldId: string): Attribute {
    return this.getGlobalAttributes(candidate).find((attr) => attr.identifiers.fieldId === fieldId);
  }

  public getSelectedGlobalAttributeFromCandidateProduct(candidateProduct: CandidateProduct, fieldId: string): Attribute {
    return candidateProduct?.globalAttributes?.find((attr) => attr.identifiers.fieldId === fieldId);
  }

  public getMatHierarchy(candidate: Candidate, matHierarchyId: number): MatHierarchy {

    if (!candidate) {
      return undefined;
    }
    const index = CandidateUtilService.getCurrentCandidateProductIndex(candidate);

    return this.getMatHierarchyFromCandidateProduct(candidate.candidateProducts[index], matHierarchyId);
  }

  getMatHierarchyFromCandidateProduct(candidateProduct: CandidateProduct, matHierarchyId: number): MatHierarchy {

    if (candidateProduct?.matHierarchyList) {
      return candidateProduct.matHierarchyList.find((hierarchy) => hierarchy.matHierarchyId === matHierarchyId);
    } else {
      return null;
    }
  }


  public getGlobalAttributes(candidate: Candidate): Attribute[] {

    if (!candidate) {
      return undefined;
    }
    const index = CandidateUtilService.getCurrentCandidateProductIndex(candidate);

    if (!candidate.candidateProducts[index].globalAttributes) {
      candidate.candidateProducts[index].globalAttributes = [];
    }
    return candidate.candidateProducts[index]?.globalAttributes;
  }

  /**
   * Returns the selected attribute values for the given field id attribute.
   * @param candidate
   * @param matHierarchyId
   * @param fieldId
   */
  public getSelectedHierarchyAttributeValue(candidate: Candidate, matHierarchyId: number, fieldId: string) {
    const attribute = this.getSelectedHierarchyAttribute(candidate, matHierarchyId, fieldId);
    return this.getAttributeValueFromAttribute(attribute);
  }


  public getSelectedCandidateProductHierarchyAttributeValue(candidateProduct: CandidateProduct, matHierarchyId: number, fieldId: string) {
    const attribute = this.getSelectedCandidateProductHierarchyAttribute(candidateProduct, matHierarchyId, fieldId);
    return this.getAttributeValueFromAttribute(attribute);
  }

  public getSelectedGlobalAttributeValue(candidate: Candidate, fieldId: string) {
    const attribute = this.getSelectedGlobalAttribute(candidate, fieldId);
    return this.getAttributeValueFromAttribute(attribute);
  }

  public getSelectedCandidateProductGlobalAttributeValue(candidateProduct: CandidateProduct, fieldId: string) {
    const attribute = candidateProduct.globalAttributes?.find((attr) => attr.identifiers.fieldId === fieldId);
    return this.getAttributeValueFromAttribute(attribute);
  }

  getAttributeValueFromAttribute(attribute: Attribute) {
    if (!attribute) {
      return '—';
    }

    if (!Array.isArray(attribute.value) && this.instanceOfCode(attribute.value)) {
      return attribute.value?.description;
    }

    if (attribute.domain.type?.id === MetaDataDomain.BOOLEAN_TYPE_ID) {
      return this.getBooleanValueString(attribute.value);
    } else {
      if (!attribute.value) {
        return '—';
      } else if (attribute.domain.type?.id === MetaDataDomain.DATE_TYPE_ID) {
        return new Date(attribute.value).toISOString().split('T')[0];
      } else {
        return attribute.value;
      }
    }
  }

  private getBooleanValueString(value): string {
    return  value ? 'Yes' : 'No';
  }


  /**
   * Where or not the value is an instance of a code.
   * @param value
   */
  private instanceOfCode(value) {
    if (!value) {
      return false;
    } else {
      return value['id'] && value['description'];
    }
  }


  public isGlobalAttributeValueAnArray(candidate: Candidate, fieldId: string): boolean {
    const value = this.getSelectedGlobalAttributeValue(candidate, fieldId);
    return value && Array.isArray(value);
  }

  public isCandidateProductGlobalAttributeValueAnArray(candidateProduct: CandidateProduct, fieldId: string): boolean {
    const value = this.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, fieldId);
    return value && Array.isArray(value);
  }

  public isHierarchyAttributeValueAnArray(candidate: Candidate, matHierarchyId: number, fieldId: string): boolean {
    const value = this.getSelectedHierarchyAttributeValue(candidate, matHierarchyId, fieldId);
    return value && Array.isArray(value);
  }

  public isCandidateProductHierarchyAttributeValueAnArray(candidateProduct: CandidateProduct, matHierarchyId: number, fieldId: string): boolean {
    const value = this.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, matHierarchyId, fieldId);
    return value && Array.isArray(value);
  }

  public getFullHierarchyPath(candidateProduct: CandidateProduct): string {
    if (!candidateProduct) {
      return '';
    }
    return this.getHierarchyPathFromMatHierarchyList(candidateProduct.matHierarchyList);
  }

  public getHierarchyPathFromMatHierarchyList(matHierarchyList: MatHierarchy[]) {
    if (!matHierarchyList?.length) {
      return '';
    }
    return matHierarchyList.map(hierarchy => hierarchy.name).join(' > ');
  }

  /**
   * Returns the lowest mat hierarchy node number.
   */
  public getLowestMatHierarchyNodeNumber(candidate: Candidate): number {

    const matHierarchyList = this.getMatHierarchyList(candidate);
    if (!!matHierarchyList?.length) {
      return matHierarchyList.reduce((prev, current) =>
          (prev.hierarchyLevel > current.hierarchyLevel) ? prev : current).matHierarchyId;
    } else {
      return null;
    }
  }

  /**
   * Returns the MAT Hierarchy List from a candidate.
   * @param candidate
   */
  public getMatHierarchyList(candidate: Candidate): MatHierarchy[] {
    if (!candidate) {
      return [];
    }
    const index = CandidateUtilService.getCurrentCandidateProductIndex(candidate);

    return candidate.candidateProducts[index].matHierarchyList;
  }


  /**
   * Takes a list of attributes and sorts into a list of hierarchy and global attributes.
   * @param allAttributes
   * @param hierarchyAttributes
   * @param globalAttributes
   */
  public addAttributesToHierarchyAndGlobalLists(allAttributes: Attribute[], hierarchyAttributes: Attribute[], globalAttributes: Attribute[]) {
    for (const attribute of allAttributes) {
      if (!attribute?.hierarchyDetails) {
        console.log('Missing hierarchy details for attribute field id: ' + attribute?.identifiers?.fieldId + '.');
        continue;
      }
      if (attribute.hierarchyDetails.global) {
        globalAttributes.push(attribute);
      } else {
        hierarchyAttributes.push(attribute);
      }
    }
  }

  /**
   * Takes a list of attributes and sorts into a list of hierarchy attributes.
   * @param allAttributes
   * @param hierarchyAttributes
   */
  public addAttributesToHierarchyLists(allAttributes: Attribute[], hierarchyAttributes: Attribute[]) {
    for (const attribute of allAttributes) {
      if (!attribute?.hierarchyDetails) {
        console.log('Missing hierarchy details for attribute field id: ' + attribute?.identifiers?.fieldId + '.');
        continue;
      }
      if (!attribute.hierarchyDetails.global) {
        hierarchyAttributes.push(attribute);
      }
    }
  }

  public setMatAttributesListsIfNotPresent(candidate: Candidate, globalAttributes: Attribute[], hierarchyAttributes: Attribute[]): Observable<any> {
    if (!globalAttributes?.length || !hierarchyAttributes.length) {
      return this.setMatAttributeLists(candidate, globalAttributes, hierarchyAttributes);
    } else {
      return of({});
    }
  }


  /**
   * Returns the attribute applicable types to filter for.
   * @param candidate
   * @private
   */
  private getMatAttributeFiltersByCandidateType(candidate: Candidate): string[] {
    if (!candidate) {
      return [];
      // new products create a new product and UPC. If whs, filter for whs. If DSD, filter for DSD.
    } else if (!candidate.candidateType || [Candidate.NEW_PRODUCT, Candidate.MRT_INNER, Candidate.PLU].includes(candidate.candidateType)) {
      const filters = [MatUtilService.PRODUCT_APPLICABLE_TYPE_CODE, MatUtilService.UPC_APPLICABLE_TYPE_CODE];
      // if a whs or dsd value hasn't been selected, include both, the channel selection should filter out the unneeded variables.
      if (!candidate.warehouseSwitch && !candidate.dsdSwitch) {
        filters.push(MatUtilService.WAREHOUSE_ITEM_APPLICABLE_TYPE_CODE);
        filters.push(MatUtilService.DSD_ITEM_APPLICABLE_TYPE_CODE);
      } else {
        if (candidate.warehouseSwitch) {
          filters.push(MatUtilService.WAREHOUSE_ITEM_APPLICABLE_TYPE_CODE);
        }
        if (candidate.dsdSwitch) {
          filters.push(MatUtilService.DSD_ITEM_APPLICABLE_TYPE_CODE);
        }
      }
      return filters;

      // additional case packs and MRTs create a new whs case so should filter for these.
    } else if ([Candidate.ADDITIONAL_CASE_PACK, Candidate.MRT].includes(candidate.candidateType)) {
      return [MatUtilService.WAREHOUSE_ITEM_APPLICABLE_TYPE_CODE];

      // bonus and replacement create a new upc and a new case, so should filter for these.
    } else if ([Candidate.BONUS_SIZE, Candidate.REPLACEMENT_UPC].includes(candidate.candidateType)) {
      return [MatUtilService.UPC_APPLICABLE_TYPE_CODE, MatUtilService.WAREHOUSE_ITEM_APPLICABLE_TYPE_CODE];

      // associate upcs only create a new upc, so should filter for these.
    } else if (candidate.candidateType === Candidate.ASSOCIATE_UPC) {
      return [MatUtilService.UPC_APPLICABLE_TYPE_CODE];

      // Store auths can create a new DSD case, only should be called when this is the case.
    } else if ([Candidate.ADDITIONAL_DISTRIBUTOR, Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR].includes(candidate.candidateType)) {
      return [MatUtilService.DSD_ITEM_APPLICABLE_TYPE_CODE];
    } else {
      return [];
    }
  }

  private filterApplicableMatAttributes(candidate: Candidate, attributes: Attribute[]): Attribute[] {
    if (!attributes?.length) {
      return [];
    }
    const applicableTypesToFilterFor = this.getMatAttributeFiltersByCandidateType(candidate);
    if (applicableTypesToFilterFor?.length) {
      return attributes.filter(attribute => applicableTypesToFilterFor.includes(attribute?.identifiers?.applicableTypeCode?.id));
    } else {
      return attributes;
    }
  }

  /**
   * Sets the MAT Hierarchy attributes.
   */
  public setMatAttributeLists(candidate: Candidate, globalAttributes: Attribute[], hierarchyAttributes: Attribute[]): Observable<any> {
    const lowestNodeNumber = this.getLowestMatHierarchyNodeNumber(candidate);
    // if there's hierarchy level found, and there's no global attributes, find them.
    if (!lowestNodeNumber && !globalAttributes?.length) {
      return this.lookupService.findAllGlobalAttributes(true).pipe(
          map(attributes => this.filterApplicableMatAttributes(candidate, attributes)),
          tap((returnedGlobalAttributes) => {
            globalAttributes.length = 0;
            globalAttributes.push(...returnedGlobalAttributes);
          })
      );
    } else if (lowestNodeNumber) {
      return this.lookupService.findAttributesByMatHierarchyId(lowestNodeNumber, true).pipe(
          map(attributes => this.filterApplicableMatAttributes(candidate, attributes)),
          tap((attributes: Attribute[]) => {
            globalAttributes.length = 0;
            hierarchyAttributes.length = 0;
            this.addAttributesToHierarchyAndGlobalLists(attributes, hierarchyAttributes, globalAttributes);
          })
      );
    } else {
      return of({});
    }
  }

  /**
   * Sets the MAT Hierarchy attributes.
   */
  public setMatHierarchyList(candidate: Candidate, hierarchyAttributes: Attribute[]): Observable<any> {
    const lowestNodeNumber = this.getLowestMatHierarchyNodeNumber(candidate);
    if (!lowestNodeNumber) {
      return of({});
    } else {
      return this.lookupService.findAttributesByMatHierarchyId(lowestNodeNumber, true).pipe(
          tap((attributes: Attribute[]) => {
            this.addAttributesToHierarchyLists(attributes, hierarchyAttributes);
          })
      );
    }
  }
  setHierarchyNumberToAttributesMapIfEmpty(attributes: Attribute[], hierarchyNumberToAttributesMap: Map<number, Attribute[]>) {
    if (hierarchyNumberToAttributesMap && !hierarchyNumberToAttributesMap?.size) {
      this.setHierarchyNumberToAttributesMap(attributes, hierarchyNumberToAttributesMap);
    }
  }

  /**
   * Initializes hierarchyNumberToAttributesMap.
   */
  setHierarchyNumberToAttributesMap(attributes: Attribute[], hierarchyNumberToAttributesMap: Map<number, Attribute[]>) {
    if (!!attributes?.length) {
      attributes.forEach((attribute) => {
        if (hierarchyNumberToAttributesMap.has(attribute.hierarchyDetails.matHierarchyId)) {
          hierarchyNumberToAttributesMap.get(attribute.hierarchyDetails.matHierarchyId).push(attribute);
        } else {
          hierarchyNumberToAttributesMap.set(attribute.hierarchyDetails.matHierarchyId, [attribute]);
        }
      });
    }
  }

  /**
   * Takes a list of global attributes and sorts into lists of attributes by their applicable type codes (currently product, warehouse item, and upc).
   * Only adds to list if they're all empty.
   * @param globalAttributes
   * @param productAttributes
   * @param warehouseItemAttributes
   * @param upcAttributes
   */
  public addGlobalAttributesToApplicableTypeListsIfNotPresent(globalAttributes: Attribute[], productAttributes: Attribute[],
                                                              warehouseItemAttributes: Attribute[], upcAttributes: Attribute[]) {
    if (!productAttributes?.length && !warehouseItemAttributes?.length && !upcAttributes?.length) {
      this.addGlobalAttributesToApplicableTypeLists(globalAttributes, productAttributes, warehouseItemAttributes, upcAttributes);
    }
  }

  /**
   * Takes a list of global attributes and sorts into lists of attributes by their applicable type codes (currently product, warehouse item, and upc).
   * @param globalAttributes
   * @param productAttributes
   * @param warehouseItemAttributes
   * @param upcAttributes
   */
  public addGlobalAttributesToApplicableTypeLists(globalAttributes: Attribute[], productAttributes: Attribute[],
                                                  warehouseItemAttributes: Attribute[], upcAttributes: Attribute[]) {

    if (!globalAttributes?.length) {
      return;
    }

    for (const attribute of globalAttributes) {
      if (!attribute?.identifiers?.applicableTypeCode?.id) {
        console.log('Missing applicable type code for attribute field id: ' + attribute?.identifiers?.fieldId + '.');
        continue;
      }

      switch (attribute.identifiers.applicableTypeCode.id) {
        case MatUtilService.PRODUCT_APPLICABLE_TYPE_CODE: {
          productAttributes.push(attribute);
          break;
        }
        case MatUtilService.WAREHOUSE_ITEM_APPLICABLE_TYPE_CODE: {
          warehouseItemAttributes.push(attribute);
          break;
        }
        case MatUtilService.UPC_APPLICABLE_TYPE_CODE: {
          upcAttributes.push(attribute);
          break;
        }
        default: {
          console.log('Unmapped applicable type code: ' + attribute.identifiers.applicableTypeCode +
              ' for attribute field id: ' + attribute?.identifiers?.fieldId + '.');
          break;
        }
      }
    }
  }

  /**
   * Filters out warehouse item level global and hierarchy attributes from a candidate product..
   * @param candidateProduct
   */
  public static removeWarehouseItemAttributes(candidateProduct: CandidateProduct) {
    this.removeAttributes(candidateProduct, [MatUtilService.WAREHOUSE_ITEM_APPLICABLE_TYPE_CODE]);
  }

  /**
   * Filters out warehouse item level global and hierarchy attributes from a candidate product..
   * @param candidateProduct
   */
  public static removeDsdItemAttributes(candidateProduct: CandidateProduct) {
    this.removeAttributes(candidateProduct, [MatUtilService.DSD_ITEM_APPLICABLE_TYPE_CODE]);
  }

  public static removeAttributes(candidateProduct: CandidateProduct, applicableTypesToRemove: string[]) {
    if (!applicableTypesToRemove?.length) {
      return;
    }

    if (!!candidateProduct?.globalAttributes.length) {
      candidateProduct.globalAttributes = candidateProduct.globalAttributes.filter(attr =>
          !applicableTypesToRemove.includes(attr?.identifiers?.applicableTypeCode?.id));
    }

    if (!!candidateProduct?.matHierarchyList?.length) {
      for (const matHierarchy of candidateProduct?.matHierarchyList) {
        if (!matHierarchy.attributes?.length) {
          continue;
        }
        matHierarchy.attributes = matHierarchy.attributes.filter(attr =>
            applicableTypesToRemove.includes(attr?.identifiers?.applicableTypeCode?.id));
      }
    }
  }

  updateMatHierarchy(candidateProduct: CandidateProduct): Observable<boolean> {
    const matHierarchyLevels = candidateProduct.matHierarchyList?.map(hierarchy => hierarchy.matHierarchyId);
    return this.findHierarchy(matHierarchyLevels).pipe(
        map((matHierarchy) => this.getMatHierarchyListFromMatHierarchy(matHierarchy)),
        switchMap((matHierarchyList) => this.handleHierarchyChanges(candidateProduct, matHierarchyList))
    );
  }

  /**
   * Returns the parent mat hierarchy list.
   * @param matHierarchy
   * @private
   */
  private getMatHierarchyListFromMatHierarchy(matHierarchy: MatHierarchy): MatHierarchy[] {
    const matHierarchyList: MatHierarchy[] = [];
    if (!matHierarchy) {
      return matHierarchyList;
    }
    matHierarchyList.push(matHierarchy);
    return this.matHierarchyToMatHierarchyList(matHierarchy, matHierarchyList);
  }

  /**
   * Recursively adds all parent hierarchies to a hierarchy list.
   * @param matHierarchy
   * @param matHierarchyList
   * @private
   */
  private matHierarchyToMatHierarchyList(matHierarchy: MatHierarchy, matHierarchyList: MatHierarchy[]): MatHierarchy[] {
    if (!matHierarchy?.parentMatHierarchy) {
      return matHierarchyList;
    }
    // add previous hierarchy node to the front of the array.
    matHierarchyList.unshift(matHierarchy.parentMatHierarchy);
    return this.matHierarchyToMatHierarchyList(matHierarchy.parentMatHierarchy, matHierarchyList);
  }

  handleHierarchyChanges(candidateProduct: CandidateProduct, newMatHierarchyList: MatHierarchy[]): Observable<boolean> {
    let hasHierarchyChanges = false;

    // if there's no results apply the empty hierarchy to the candidate product.
    if (!newMatHierarchyList?.length) {
      // if there was previously a hierarchy that means there were hierarchy changes.
      if (!!candidateProduct.matHierarchyList?.length) {
        hasHierarchyChanges = true;
      }
      candidateProduct.matHierarchyList = [];
      return of(hasHierarchyChanges);
    }
    // if there's new hierarchy changes, that means there was at least one hierarchy level previously selected. If the length is different,
    // or the hierarchy IDs don't match there's hierarchy changes.
    if (candidateProduct.matHierarchyList.length !== newMatHierarchyList.length) {
      hasHierarchyChanges = true;
    } else {
      for (let x = 0; x < newMatHierarchyList.length; x++) {
        if (newMatHierarchyList[x].matHierarchyId !== candidateProduct.matHierarchyList[x].matHierarchyId) {
          hasHierarchyChanges = true;
          break;
        }
      }
    }

    for (const newHierarchy of newMatHierarchyList) {
      const currentHierarchy = candidateProduct.matHierarchyList.find(hierarchy => hierarchy.matHierarchyId === newHierarchy.matHierarchyId);
      if (currentHierarchy) {
        newHierarchy.attributes = currentHierarchy.attributes;
      }
    }
    candidateProduct.matHierarchyList = JSON.parse(JSON.stringify(newMatHierarchyList));

    return of(hasHierarchyChanges);
  }

  /**
   * Recursively checks for the lowest hierarchy level that exists, returning null if not found.
   * @param matHierarchyLevels
   */
  findHierarchy(matHierarchyLevels: number[]): Observable<MatHierarchy> {
    if (!matHierarchyLevels?.length) {
      return of(null);
    }
    return this.lookupService.findMatHierarchyByMatHierarchyId(matHierarchyLevels.pop()).pipe(
        switchMap((hierarchy) => {
              if (hierarchy) {
                return of(hierarchy);
              } else {
                return this.findHierarchy(matHierarchyLevels);
              }
            }
        )
    );
  }

  updateMatAttributesAndValues(candidate: Candidate, globalAttributes: Attribute[], hierarchyAttributes: Attribute[]): Observable<any> {
    return this.setMatAttributesListsIfNotPresent(candidate, globalAttributes, hierarchyAttributes).pipe(
        tap(() => {
          if (Candidate.ASSOCIATE_UPC !== candidate?.candidateType) {
            const candidateProduct = CandidateUtilService.getCurrentCandidateProduct(candidate);
            const currentAttributes = this.getGlobalAndHierarchyMatAttributes(candidateProduct);
            this.updateGlobalMatAttributesAndValues(candidateProduct, currentAttributes, globalAttributes);
            this.updateHierarchyMatAttributesAndValues(candidateProduct, currentAttributes, hierarchyAttributes);
          } else if (candidate?.candidateProducts?.length) {
            // skip searched candidate product before updating.
            for (let x = 1; x < candidate.candidateProducts.length; x++) {
              const currentAttributes = this.getGlobalAndHierarchyMatAttributes(candidate.candidateProducts[x]);
              this.updateGlobalMatAttributesAndValues(candidate.candidateProducts[x], currentAttributes, globalAttributes);
              this.updateHierarchyMatAttributesAndValues(candidate.candidateProducts[x], currentAttributes, hierarchyAttributes);
            }
          }
        })
    );
  }

  public getGlobalAndHierarchyMatAttributes(candidateProduct: CandidateProduct): Attribute[] {
    let attributes: Attribute[] = [];

    if (candidateProduct?.globalAttributes?.length) {
      attributes = attributes.concat(candidateProduct?.globalAttributes);
    }
    return attributes.concat(this.getMatHierarchyAttributes(candidateProduct));
  }

  public getMatHierarchyAttributes(candidateProduct: CandidateProduct): Attribute[] {
    let attributes: Attribute[] = [];
    if (candidateProduct?.matHierarchyList?.length) {
      for (const matHierarchy of candidateProduct?.matHierarchyList) {
        if (matHierarchy?.attributes?.length) {
          attributes = attributes.concat(matHierarchy.attributes);
        }
      }
    }
    return attributes;
  }

  private updateGlobalMatAttributesAndValues(candidateProduct: CandidateProduct, currentAttributes: Attribute[], globalAttributes: Attribute[]) {

    if (!currentAttributes?.length || !globalAttributes?.length) {
      candidateProduct.globalAttributes = [];
    } else {
      const updatedAttributes: Attribute[] = [];
      globalAttributes.forEach((newAttribute) => {
        const currentGlobalAttribute = currentAttributes.find((currGlobalAttr) =>
            currGlobalAttr.identifiers?.fieldId === newAttribute?.identifiers?.fieldId);

        if (currentGlobalAttribute) {
          if (CandidateProduct.ASSOCIATE_UPC === candidateProduct.candidateProductType) {
            newAttribute = JSON.parse(JSON.stringify(newAttribute));
          }

          this.overlayNewAttributeWithValue(newAttribute, currentGlobalAttribute);
          // booleans are allowed to have a null/undefined value for unsure/not selected.
          if (this.hasValidValueForAttribute(newAttribute?.value, newAttribute)) {
            updatedAttributes.push(newAttribute);
          }
        }
      });
      candidateProduct.globalAttributes = updatedAttributes;
    }
  }

  private updateHierarchyMatAttributesAndValues(candidateProduct: CandidateProduct, currentAttributes: Attribute[], hierarchyAttributes: Attribute[]) {
    if (!candidateProduct?.matHierarchyList?.length) {
      return;
      // if there's no attributes tied to the hierarchy levels, clear out the attributes tied to each level.
    } else if (!currentAttributes?.length || !hierarchyAttributes?.length) {
      candidateProduct.matHierarchyList.forEach((currentHierarchy) => currentHierarchy.attributes = []);
    } else {
      candidateProduct.matHierarchyList.forEach((currentHierarchy) => {
        const updatedAttributes: Attribute[] = [];
        hierarchyAttributes.forEach((newAttribute) => {

          const currentHierarchyAttribute = currentAttributes.find((currHierAttr) =>
              newAttribute.hierarchyDetails?.matHierarchyId === currentHierarchy.matHierarchyId &&
              currHierAttr.identifiers?.fieldId === newAttribute?.identifiers?.fieldId);

          if (currentHierarchyAttribute) {
            if (CandidateProduct.ASSOCIATE_UPC === candidateProduct.candidateProductType) {
              newAttribute = JSON.parse(JSON.stringify(newAttribute));
            }
            this.overlayNewAttributeWithValue(newAttribute, currentHierarchyAttribute);
            if (this.hasValidValueForAttribute(newAttribute?.value, newAttribute)) {
              updatedAttributes.push(newAttribute);
            }
          }
        });
        currentHierarchy.attributes = updatedAttributes;
      });
    }
  }

  overlayNewAttributeWithValue(newAttribute: Attribute, currentAttribute: Attribute) {
    if (this.hasNoValidValueForAttribute(currentAttribute?.value, newAttribute)) {
      return;
    } else if (MatUtilService.isInvalidAttributeDomain(currentAttribute)) {
      console.error('Invalid domain configuration for current attribute id: ' + currentAttribute.identifiers.fieldId);
      return;
    } else if (MatUtilService.isInvalidAttributeDomain(newAttribute)) {
      console.error('Invalid domain configuration for new attribute id: ' + newAttribute.identifiers.fieldId);
      return;
    }
    if (this.hasDomainTypeChanged(newAttribute, currentAttribute)) {
      this.overlayNewAttributeValueForDomainTypeChange(newAttribute, currentAttribute);
      return;
    } else {
      if (this.isValueSelectable(currentAttribute)) {
        newAttribute.value = this.getValidValueSelectableValues(newAttribute, currentAttribute);
      } else if (currentAttribute.domain.type.id === MetaDataDomain.BOOLEAN_TYPE_ID && currentAttribute.value == null) {
        newAttribute.value = false;
      } else {
        newAttribute.value = currentAttribute.value;
      }
      return;
    }
  }

  private overlayNewAttributeValueForDomainTypeChange(newAttribute: Attribute, currentAttribute: Attribute) {
    // if the value was a selectable code, or now is a selectable code value, we cannot convert/maintain the data.
    if (this.isValueSelectable(newAttribute) || this.isValueSelectable(currentAttribute)) {
      console.error('Value for attribute id: ' + newAttribute.identifiers.fieldId + ' changed to/from a selectable code attribute and cannot be converted.');
      return;
      // if the value was a date, or now is a date, we cannot convert/maintain the data.
    } else if (newAttribute.domain.type.id === MetaDataDomain.DATE_TYPE_ID || currentAttribute.domain.type.id === MetaDataDomain.DATE_TYPE_ID ||
        newAttribute.domain.type.id === MetaDataDomain.TIMESTAMP_TYPE_ID || currentAttribute.domain.type.id === MetaDataDomain.TIMESTAMP_TYPE_ID) {
      console.error('Value for attribute id: ' + newAttribute.identifiers.fieldId + ' changed to/from a date/timestamp attribute and cannot be converted.');
      return;
      // if the value was a boolean, or now is a boolean, we cannot convert/maintain the data.
    } else if (newAttribute.domain.type.id === MetaDataDomain.BOOLEAN_TYPE_ID || currentAttribute.domain.type.id === MetaDataDomain.BOOLEAN_TYPE_ID) {
      console.error('Value for attribute id: ' + newAttribute.identifiers.fieldId + ' changed to/from a boolean attribute and cannot be converted.');
      return;
      // if the value was a boolean, or now is a boolean, we cannot convert/maintain the data.
    } else if (newAttribute.domain.type.id === MetaDataDomain.IMAGE_TYPE_ID || currentAttribute.domain.type.id === MetaDataDomain.IMAGE_TYPE_ID) {
      console.error('Value for attribute id: ' + newAttribute.identifiers.fieldId + ' changed to/from an image attribute and cannot be converted.');
      return;
      // if it's now a string, convert the previous values (numeric) to a string.
    } else if (newAttribute.domain.type.id === MetaDataDomain.STRING_TYPE_ID) {
      if ([MetaDataDomain.DECIMAL_TYPE_ID, MetaDataDomain.INTEGER_TYPE_ID].includes(currentAttribute.domain.type.id)) {
        newAttribute.value = '' + currentAttribute.value;
      } else {
        console.error('Conversion from type ' + currentAttribute.domain.type.id + ' to string is not implemented.');
      }
      return;
      // if the previous value was a string and it can be converted to a numeric, convert it.
    } else if (currentAttribute.domain.type.id === MetaDataDomain.STRING_TYPE_ID) {
      if (newAttribute.domain.type.id === MetaDataDomain.INTEGER_TYPE_ID) {
        if (this.isNumeric(currentAttribute.value)) {
          newAttribute.value = parseInt(currentAttribute.value, 10);
        } else {
          console.error('String value for attribute id: ' + newAttribute.identifiers.fieldId + ' cannot be converted to the new type integer.');
        }
      } else if (newAttribute.domain.type.id === MetaDataDomain.DECIMAL_TYPE_ID) {
        if (this.isNumeric(currentAttribute.value)) {
          newAttribute.value = parseFloat(currentAttribute.value);
        } else {
          console.error('String value for attribute id: ' + newAttribute.identifiers.fieldId + ' cannot be converted to the new type decimal.');
        }
      } else {
        console.error('String conversion to type ' + newAttribute.domain.type.id + ' is not implemented.');
      }
      return;
    } else if (currentAttribute.domain.type.id === MetaDataDomain.INTEGER_TYPE_ID) {
      if (newAttribute.domain.type.id === MetaDataDomain.DECIMAL_TYPE_ID) {
        if (this.isNumeric(currentAttribute.value)) {
          newAttribute.value = parseFloat(currentAttribute.value);
        } else {
          console.error('Integer value for attribute id: ' + newAttribute.identifiers.fieldId + ' cannot be converted to the new type decimal.');
        }
      } else {
        console.error('Integer conversion to type ' + newAttribute.domain.type.id + ' is not implemented.');
      }
      return;
    } else if (currentAttribute.domain.type.id === MetaDataDomain.DECIMAL_TYPE_ID) {
      if (newAttribute.domain.type.id === MetaDataDomain.INTEGER_TYPE_ID) {
        if (this.isNumeric(currentAttribute.value)) {
          newAttribute.value = parseInt(currentAttribute.value, 10);
        } else {
          console.error('Decimal value for attribute id: ' + newAttribute.identifiers.fieldId + ' cannot be converted to the new type Integer.');
        }
      } else {
        console.error('Decimal conversion to type ' + newAttribute.domain.type.id + ' is not implemented.');
      }
    } else {
      console.error('Conversion from type ' + currentAttribute.domain.type.id + ' to type ' + newAttribute.domain.type.id + ' is not implemented.');
    }
    return;
  }

  private getValidValueSelectableValues(newAttribute: Attribute, currentAttribute: Attribute) {
    if (!newAttribute.domain.values?.length || !currentAttribute.value) {
      return null;

      // if it was multivalued, but is no longer multivalued
    } else if (!newAttribute?.domain.multipleValues && currentAttribute.domain.multipleValues) {
      // if there's more than one value, clear it out, else find the existing value, and return it.
      if (currentAttribute.value.length > 1) {
        return null;
      } else {
        // if the value is pending, return it, else find the up to date value and return it.
        if (CandidateUtilService.PENDING_ID_STRING === currentAttribute.value[0].id) {
          return currentAttribute.value[0];
        } else {
          return newAttribute.domain.values.find(value => value.id === currentAttribute.value[0].id);
        }
      }

      // if it was multivalued (and is multivalued still), find and return current existing values.
    } else if (currentAttribute.domain.multipleValues) {
      let newValues = newAttribute.domain.values.filter(newValue => !!currentAttribute.value.find(currentValue => currentValue.id === newValue.id));
      // ensure any pending values are kept.
      newValues = newValues.concat(currentAttribute.value.filter(value => (CandidateUtilService.PENDING_ID_STRING) === value.id));
      return newValues.length ? newValues : null;

      // if it's not multivalued, find the value, and return it (in array since the new attribute is multivalued).
    } else {
      let newValue;
      if (CandidateUtilService.PENDING_ID_STRING === currentAttribute.value.id) {
        newValue = currentAttribute.value;
      } else {
        newValue = newAttribute.domain.values.find(newVal => newVal.id === currentAttribute.value.id);
      }
      if (!newValue) {
        return null;
      }
      return newAttribute.domain.multipleValues ? [newValue] : newValue;
    }
  }

  private static isInvalidAttributeDomain(attribute: Attribute): boolean {
    return !attribute?.domain?.type?.id && !attribute?.domain?.valueSelectable;
  }

  private isValueSelectable(attribute: Attribute) {
    return !!attribute?.domain?.valueSelectable;
  }

  private getDomainTypeId(attribute: Attribute) {
    return attribute.domain?.type?.id;
  }

  private hasDomainTypeChanged(newAttribute: Attribute, currentAttribute: Attribute) {
    return this.isValueSelectable(newAttribute) !== this.isValueSelectable(currentAttribute) ||
        this.getDomainTypeId(newAttribute) !== this.getDomainTypeId(currentAttribute);
  }

  private isNumeric(value): boolean {
    return /^-?\d+$/.test(value);
  }

  getAttributeError(fieldId: string, candidateProductError: CandidateProductError): string {
    if (!fieldId || !candidateProductError?.matAttributeErrors) {
      return null;
    } else {
      return candidateProductError.matAttributeErrors[fieldId];
    }
  }

  public updateMatHierarchyFromProduct(candidateProduct: CandidateProduct, product: Product): Observable<any> {
    if (!product?.matHierarchy?.matHierarchyId) {
      return of({});
    }
    return this.lookupService.findMatHierarchyByMatHierarchyId(product.matHierarchy.matHierarchyId).pipe(
        map((matHierarchy) => this.getMatHierarchyListFromMatHierarchy(matHierarchy)),
        switchMap((matHierarchyList) => this.handleHierarchyChanges(candidateProduct, matHierarchyList))
    );
  }

  public updateCandidateProductsMatHierarchyFromProduct(candidateProducts: CandidateProduct[], product: Product): Observable<any> {
    if (!product?.matHierarchy?.matHierarchyId || !candidateProducts?.length) {
      return of({});
    }
    return this.lookupService.findMatHierarchyByMatHierarchyId(product.matHierarchy.matHierarchyId).pipe(
        map((matHierarchy) => this.getMatHierarchyListFromMatHierarchy(matHierarchy)),
        switchMap((matHierarchyList) => {
              const candidateProductObservables = [];
              for (const candidateProduct of candidateProducts) {
                if ([CandidateProduct.SEARCHED_UPC, CandidateProduct.SEARCHED_ITEM].includes(candidateProduct.candidateProductType)) {
                  continue;
                }
                // Since the hierarchy is being leverage for many candidate products we need each one to have a difference reference value.
                const matHierarchyListCopy = JSON.parse(JSON.stringify(matHierarchyList));
                candidateProductObservables.push(this.handleHierarchyChanges(candidateProduct, matHierarchyListCopy));
              }

              // limit the concurrent hierarchy updates to prevent using too much of the CPU.
              return from(candidateProductObservables).pipe(
                  mergeMap(observable => observable, MatUtilService.MAX_PARALLEL_QUERIES),
                  toArray()
              );
            }
        )
    );
  }

  /**
   * Removes hierarchy attributes that are no longer applicable to the current hierarchy attributes. Returns a copy of the error to trigger
   * change detection.
   * @param candidateProductError
   * @param globalAttributes
   * @param hierarchyAttributes
   */
  public updateMatHierarchyErrors(candidateProductError: CandidateProductError, globalAttributes: Attribute[], hierarchyAttributes: Attribute[]) {
    if (!candidateProductError?.matAttributeErrors) {
      return;
    }
    const matAttributeErrors = new Map(Object.entries(candidateProductError.matAttributeErrors));
    for (const [fieldId, value] of matAttributeErrors) {

      // delete any errors that don't exist in current attributes.
      const globalAttr = globalAttributes?.find((attr) => attr.identifiers.fieldId === fieldId);
      const hierarchyAttr = hierarchyAttributes?.find((attr) => attr.identifiers.fieldId === fieldId);
      if (!globalAttr && !hierarchyAttr) {
        delete candidateProductError.matAttributeErrors[fieldId];
      }
    }
  }

  /**
   * Returns whether or not the value is valid. Undefined, and empty string are not valid, nor null
   * (unless boolean type as this is considered Not sure).
   *
   * @param value
   * @param attributeConfig
   */
  hasNoValidValueForAttributeConfig(value, attributeConfig: AttributeConfig) {
    return !this.hasValidValueForAttributeConfig(value, attributeConfig);
  }

  /**
   * Returns whether or not the value is valid. Undefined, and empty string are not valid, nor null
   * (unless boolean type as this is considered Not sure).
   *
   * @param value
   * @param attributeConfig
   */
  hasValidValueForAttributeConfig(value, attributeConfig: AttributeConfig): boolean {
    if (value) {
      return !Array.isArray(value) || !!value?.length;
    } else {
      return this.isBooleanRadioInputConfiguration(attributeConfig);
    }
  }

  /**
   * Returns whether or not the value is valid. Undefined, and empty string are not valid, nor null
   * (unless boolean type as this is considered Not sure).
   *
   * @param value
   * @param attribute
   */
  hasNoValidValueForAttribute(value, attribute: Attribute) {
    return !this.hasValidValueForAttribute(value, attribute);
  }

  /**
   * Returns whether or not the value is valid. Undefined, and empty string are not valid, nor null
   * (unless boolean type as this is considered Not sure).
   *
   * @param value
   * @param attribute
   */
  hasValidValueForAttribute(value, attribute: Attribute): boolean {
    if (value) {
      return !Array.isArray(value) || !!value?.length;
    } else {
      return attribute.domain?.type?.id === MetaDataDomain.BOOLEAN_TYPE_ID;
    }
  }

  hideMatRequestAttributePanel(panel, requestNewMatAttributeOverrideWrapper: RequestNewMatAttributeOverrideWrapper) {
    panel.hide();
    requestNewMatAttributeOverrideWrapper.showPanel = false;
    requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm = null;
  }


  showRequestNewAttributeFormPanel(event, panel, target, rnaMatConfirmOverlay) {
    rnaMatConfirmOverlay.hide();
    panel.show(event, target);
  }

  sendRequestAndCloseModal(candidate: Candidate, requestNewMatAttributeOverrideWrapper: RequestNewMatAttributeOverrideWrapper, panel) {
    if (!requestNewMatAttributeOverrideWrapper?.requestNewMatAttributeForm?.newMatAttributeValue) {
      return;
    }
    const form = requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm;
    this.emailService.sendRequestMatAttributeValue(candidate, requestNewMatAttributeOverrideWrapper?.requestNewMatAttributeForm).subscribe(() => {
          let updateAttributeValue;
          const valueToAdd = {
            id: CandidateUtilService.PENDING_ID_STRING,
            description: 'Pending: ' + form.newMatAttributeValue,
            pending: true
          };
          if (form.currentValue) {
            updateAttributeValue = form.currentValue.concat(valueToAdd);
          } else {
            updateAttributeValue = valueToAdd;
          }
          requestNewMatAttributeOverrideWrapper.onClickRequestNewAttributeValueEmitter.emit(updateAttributeValue);
          requestNewMatAttributeOverrideWrapper.showPanel = false;
          panel.hide();
        }, (error) => {
          this.growlService.addError(error.error);
        }
    );
  }

  removeAttributeByFieldId(candidateProduct: CandidateProduct, fieldIdToRemove: string) {
    if (!fieldIdToRemove) {
      return;
    }

    if (!!candidateProduct?.globalAttributes.length) {
      for (let x = 0; x < candidateProduct.globalAttributes.length; x++) {
        if (fieldIdToRemove === candidateProduct.globalAttributes[x].identifiers?.fieldId) {
          candidateProduct.globalAttributes.splice(x, 1);
          return;
        }
      }
    }

    if (!!candidateProduct?.matHierarchyList?.length) {
      for (const matHierarchy of candidateProduct?.matHierarchyList) {
        if (!matHierarchy.attributes?.length) {
          continue;
        }
        for (let x = 0; x < matHierarchy.attributes.length; x++) {
          if (fieldIdToRemove === matHierarchy.attributes[x].identifiers?.fieldId) {
            matHierarchy.attributes.splice(x, 1);
            return;
          }
        }
      }
    }
  }

  public hasMatHierarchyAttributeValues(candidateProduct: CandidateProduct): boolean {
    if (!candidateProduct.matHierarchyList?.length) {
      return false;
    }
    for (const matHierarchy of candidateProduct.matHierarchyList) {
      if (matHierarchy.attributes?.length) {
        return true;
      }
    }
    return false;
  }
}
