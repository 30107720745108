import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTextInputConfig } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class LikeItemCode implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Like item code',
      description: 'Enter an existing item code to use during forecasting.',
      isDisabled: () => false,
      isReadOnly: () => false,
      name: '',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.likeId;
    componentInstance.modelChange.subscribe(x => {
      candidate.likeId = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (candidate.likeId !== componentInstance.model) {
      componentInstance.model = candidate.likeId;
    }
  }
}
