import { Component, OnInit } from '@angular/core';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {MatUtilService} from '../../service/mat-util.service';
import {Candidate, CandidateError, CandidateProductError, CandidateValidatorType, TaskDecision} from 'pm-models';
import {finalize, switchMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';

@Component({
  selector: 'app-pia-mrt-extended-attributes',
  templateUrl: './pia-mrt-extended-attributes.component.html',
  styleUrls: ['./pia-mrt-extended-attributes.component.scss']
})
export class PiaMrtExtendedAttributesComponent implements OnInit {


  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public supplierMrtService: SupplierMrtService, private candidateUtilService: CandidateUtilService,
              private matUtilService: MatUtilService) { }

  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;
  candidateProductError: CandidateProductError;


  ngOnInit() {

    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(), this.supplierMrtService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierMrtService.resetService();
        this.supplierMrtService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate) => {
            if (candidate.candidateType === Candidate.MRT) {
              this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
              return of({});
            } else {
              return this.router.navigate(['/tasks']);
            }
          }),
          switchMap(() =>
            forkJoin([this.supplierMrtService.setInnerCandidates(), this.supplierMrtService.setExistingInnerProducts(),
              this.matUtilService.updateMatAttributesAndValues(this.supplierMrtService.getCandidate(), this.supplierMrtService.globalAttributes, [])])
          ),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.supplierMrtService.getMrtExtendedAttributesError();
            this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();

      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierMrtService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return of({});
            } else {
              this.supplierMrtService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          switchMap(() =>
            forkJoin([this.supplierMrtService.setInnerCandidates(), this.supplierMrtService.setExistingInnerProducts(),
              this.matUtilService.updateMatAttributesAndValues(this.supplierMrtService.getCandidate(), this.supplierMrtService.globalAttributes, [])])
          ),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.supplierMrtService.getMrtExtendedAttributesError();
            this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      }
    });
  }


  onClose() {
    this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
    this.supplierMrtService.saveCandidateAndNavigate('/tasks', null);
  }


  jumpBack(url) {
    this.canNavigate = false;
    this.supplierMrtService.saveCandidateAndNavigate(url, {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
  }


  onClickActivate() {
    // validate the candidate for this page and send errors to components to display, if any
    this.canNavigate = false;
    this.supplierMrtService.setPiaInitiatedCandidateInners();
    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(), [CandidateValidatorType.PIA_MRT_REVIEW_VALIDATOR]).subscribe(() => {
      this.candidateService.activateCandidate(this.supplierMrtService.getCandidate()).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE,
          TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
          'Successfully activated candidate.'
        );
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
      });
    }, (error) => {
      this.supplierMrtService.scrollToTop();
      // set the errors on the page
      if (error.error && error.error.candidateErrors && error.error.candidateErrors.hasErrors) {
        this.supplierMrtService.updateMrtErrors(error.error.candidateErrors);
        this.candidateError = this.supplierMrtService.getMrtWarehouseDetailsError();
        this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canNavigate = true;
    });
  }


  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.supplierMrtService.getCandidate().vendor.apNumber, this.supplierMrtService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( data => {
          this.canNavigate = true;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
      });
  }


  onClickBack() {
    this.jumpBack('/piaMrtWarehouseSelection');
  }



  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case SupplierMrtService.UPC_ITEM_CODES: {
        this.jumpBack('/piaSetupMrt');
        break;
      }
      case SupplierMrtService.NEW_ITEM_SETUP: {
        this.jumpBack('/piaSetupNewMrtItems');
        break;
      }
      case SupplierMrtService.MRT_DETAILS: {
        this.jumpBack('/piaMrtCaseDetails');
        break;
      }
      case SupplierMrtService.WAREHOUSE_SELECTION: {
        this.onClickBack();
        break;
      }
    }
  }
}
