<pm-editor #pmEditor (closeEvent)="onClose()">

  <pm-header>
    <app-mrt-case-stepper *ngIf="supplierMrtService.hasNewInners(supplierMrtService.candidate) === false"
                          [candidateErrors]="supplierMrtService.getAllSupplierMrtErrors()"
                          [stepIndex]="3" [steps]="supplierMrtService.getNoInnerFlowSteps()"
                          (indexClick)="onClickStepper($event)">
    </app-mrt-case-stepper>
    <app-mrt-case-stepper *ngIf="supplierMrtService.hasNewInners(supplierMrtService.candidate)"
                          [candidateErrors]="supplierMrtService.getAllSupplierMrtErrors()"
                          [hasInnerCandidateError]="[supplierMrtService.hasInnerErrors()]"
                          [stepIndex]="4" [steps]="supplierMrtService.getNewInnerFlowSteps()"
                          (indexClick)="onClickStepper($event)">
    </app-mrt-case-stepper>
  </pm-header>

  <pm-title>
    Extended attributes
  </pm-title>

  <div *ngIf="isLoadingData" class="col-md-8 spinner-row">
    <pm-progress-spinner [showSpinner]="isLoadingData" [strokeWidth]="'2'"
                         [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}">
    </pm-progress-spinner>
    <h5 class="ml-3">Fetching attributes...</h5>
  </div>
  <ng-container *ngIf="!isLoadingData">

    <pm-global-attributes-card [candidateModel]="supplierMrtService.candidate"
                               [candidateProductModel]="supplierMrtService.candidate.candidateProducts[0]"
                               [globalAttributes]="supplierMrtService.globalAttributes"
                               [candidateProductErrorModel]="candidateProductError">
    </pm-global-attributes-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
