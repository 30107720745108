import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeTextInputConfig,
  CandidateProduct,
  CandidateProductError,
  OmiRemarksCardModel,
  OmiRemarksCardPermissions, TextInputType
} from 'pm-models';

@Component({
  selector: 'pm-omi-remarks-card',
  templateUrl: './omi-remarks-card.component.html',
  styleUrls: ['./omi-remarks-card.component.scss']
})
export class OmiRemarksCardComponent implements OnInit {

  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  @Input()
  model: CandidateProduct | OmiRemarksCardModel;

  @Input()
  permissions: OmiRemarksCardPermissions;

  @Input()
  errorModel: CandidateProductError;

  @Output() modelChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
