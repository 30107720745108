import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, CandidateProduct, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayImageUploadComponent } from '../../shared/attribute-display/attribute-display-image-upload/attribute-display-image-upload.component';

export class ProductImages implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayImageUploadComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: '',
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: false,
      ...overrides
    };
  }

  setupComponent(
    componentInstance: AttributeDisplayImageUploadComponent,
    candidate: CandidateProduct,
    configOverrides: any = {}
  ) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.imageLinks;
    componentInstance.modelChange.subscribe(x => {
      candidate.imageLinks = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayImageUploadComponent, candidate: CandidateProduct) {
    if (componentInstance.model !== candidate.imageLinks) {
      componentInstance.model = candidate.imageLinks;
    }
  }
}
