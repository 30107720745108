export class ImportInfo {
  id: any;
  containerSize?: any;
  incoTerms?: any;
  pickupPoint?: any;
  dutyPercent?: any;
  dutyConfirmed?: number;
  dutyInfo?: any;
  minimumOrderQty?: any;
  minimumOrderDesc?: any;
  hts1?: any;
  hts2?: any;
  hts3?: any;
  agentPercent?: any;
  cartonMarking?: any;
  productColor?: any;
  prorationDate?: number;
  inStoreDate?: number;
  whsFlushDate?: number;
  freightConfirmedDate?: number;
  factory?: any;
}
