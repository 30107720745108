<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>
<ng-template #card>
  <pm-card title="Retail Link"
           subtitle="Your Buyer will make the final decision regarding retail, but you can suggest a retail link for this product.">
    <ng-container *ngIf="model">


      <pm-attribute-display-toggle [attribute]="isRetailLinkedConfiguration"
                                   [(model)]="isRetailLinked"
                                   (change)="isRetailLinkedChange($event)">
      </pm-attribute-display-toggle>

      <pm-attribute-display-muli-source-input *ngIf="isRetailLinked"
                                              [attribute]="retailLinkConfiguration"
                                              [attributeError]="errorModel?.retailLink"
                                              [model]="model?.retailLink?.searchedId"
                                              (modelChange)="onRetailLinkNumberChange($event)"
                                              [selectCategory]="model?.retailLink?.upcType"
                                              (selectCategoryChange)="onRetailLinkTypeChange($event)"
                                              [state]="retailLinkState"
                                              (validate)="validateRetailLinkEvent($event)">
      </pm-attribute-display-muli-source-input>

      <pm-attribute-display-dual-numeric-input *ngIf="isRetailLinked"
                                               [attribute]="xForConfiguration"
                                               [(firstInputModel)]="model.retailXFor"
                                               [(secondInputModel)]="model.retailPrice"
                                               [firstInputModelError]="errorModel?.retailXFor"
                                               [secondInputModelError]="errorModel?.retailPrice">
      </pm-attribute-display-dual-numeric-input>
    </ng-container>
  </pm-card>
</ng-template>
