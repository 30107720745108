import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ApDepartmentAndSubDepartment,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  LocationGroupStores
} from 'pm-models';
import {UploadCandidateService} from '../../../../../../src/app/2.0.0/service/upload-candidate.service';
import {UploadCandidateDsdConstants} from '../../../../../../src/app/2.0.0/utils/constants/upload-candidate-dsd-constants';
import {AttributeTypes} from 'pm-components';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {CostService} from '../../../../../../src/app/2.0.0/service/cost.service';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {CandidateErrorUtilService} from '../../../../../../src/app/2.0.0/service/candidate-error-util.service';
import {UploadCandidateCaseConstants} from '../../../../../../src/app/2.0.0/utils/constants/upload-candidate-case-constants';
import {EditCandidateUtilService} from '../../../../../../src/app/2.0.0/service/edit-candidate-util.service';

@Component({
  selector: 'pm-upload-dsd-table',
  templateUrl: './upload-dsd-table.component.html',
  styleUrls: ['./upload-dsd-table.component.scss']
})
export class UploadDsdTableComponent implements OnInit {

  @Input() candidates: Candidate[];
  @Input() pageSize: number = 50;
  @Input() totalNumRecords: number;
  @Input() candidateProductIndex;
  @Input() isBuyer: boolean = false;
  @Input() candidateIdToCandidateErrorMap: Map<number, CandidateError>;

  @Output() loadData = new EventEmitter<any>();
  @Output() candidatesChange = new EventEmitter<any>();


  public maxStoreGroupCount = 0;
  public currentCandidateProduct: CandidateProduct;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public locationGroupsStoresMap: Map<String, LocationGroupStores[]> = new Map();

  public allDsdColumns: any[] = [];
  public DSD_LOCATION_GROUP_COLUMNS_LENGTH;

  constructor(private uploadCandidateService: UploadCandidateService, private editCandidateModalService: EditCandidateModalService,
              private candidateService: CandidateService, public costService: CostService, private lookupService: LookupService,
              public candidateErrorUtils: CandidateErrorUtilService, private editCandidateUtilService: EditCandidateUtilService) { }

  ngOnInit(): void {
    this.initializeDsdColumns();
    this.initializeLocationGroupData();
  }

  initializeLocationGroupData() {
    const apDepartmentAndSubDepartmentList: ApDepartmentAndSubDepartment[] = [];
    for (const candidate of this.candidates) {
      if (candidate.vendor && candidate.commodity) {
        apDepartmentAndSubDepartmentList.push({apNumber: candidate?.vendor?.apNumber, departmentNumber: +candidate?.commodity?.departmentId,
          subDepartmentNumber: candidate?.commodity?.subDepartmentId});
      }
    }
    if (apDepartmentAndSubDepartmentList.length > 0) {
      this.lookupService.findAllLocationGroupStoresByApDepartmentAndSubDepartments(apDepartmentAndSubDepartmentList).subscribe(
        (locationGroupStoresWrappers) => {
          if (locationGroupStoresWrappers && locationGroupStoresWrappers.length > 0) {
            locationGroupStoresWrappers.forEach((locationGroupStoresWrapper) => {
              this.locationGroupsStoresMap.set(this.getApDepartmentAndSubDepartmentKeyString(
                  locationGroupStoresWrapper.apDepartmentAndSubDepartment?.apNumber,
                  locationGroupStoresWrapper.apDepartmentAndSubDepartment?.departmentNumber,
                  locationGroupStoresWrapper.apDepartmentAndSubDepartment?.subDepartmentNumber),
                locationGroupStoresWrapper.locationGroupStoresList);
            });
          }
        });
    }
  }


  initializeDsdColumns() {
    if (!this.candidates || this.candidates.length === 0) {
      return;
    }

    // Get max store group count.
    for (let x = 0; x < this.candidates.length; x++) {
      if (this.candidates[x].candidateProducts[this.candidateProductIndex].locationGroupStores?.length > this.maxStoreGroupCount) {
        this.maxStoreGroupCount = this.candidates[x].candidateProducts[this.candidateProductIndex].locationGroupStores.length;
      }
    }

    this.allDsdColumns = this.uploadCandidateService.getBaseDsdColumns();

    this.DSD_LOCATION_GROUP_COLUMNS_LENGTH = UploadCandidateDsdConstants.DSD_LOCATION_GROUP_COLUMNS.length;

    // Add all of the warehouse columns for each of the max counts.
    for (let x = 0; x < this.maxStoreGroupCount; x++) {
      this.allDsdColumns = this.allDsdColumns.concat(UploadCandidateDsdConstants.DSD_LOCATION_GROUP_COLUMNS);
    }
  }

  editProductUPC(index) {
    this.showEditCandidateModal(index, AttributeTypes.ProductUPC, {
      validationService: this.candidateService
    });
  }

  showEditCandidateModal(index, type: AttributeTypes, overrides?: any) {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidates[index]));
    this.editCandidateModalService.openModal(type, tempCandidate, overrides).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidates[index] = response;
      }
    });
  }

  showEditCandidateProductModal(index, type: AttributeTypes,  overrides?: any) {
    const candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(this.candidates[index]);
    this.editCandidateModalService.openModal(type, this.candidates[index].candidateProducts[candidateProductIndex], overrides)
      .subscribe(response => {
        if (response) {
          // Dispatch Update
          this.candidates[index].candidateProducts[candidateProductIndex] = response;
        }
      });
  }

  get attributeType() {
    return AttributeTypes;
  }

  /**
   * Returns total available store for a candidate.
   */
  public getStoreCountString(candidate: Candidate): String {
    if (!candidate) {
      return null;
    }

    const locationGroupStoresList = this.locationGroupsStoresMap.get(
      this.getApDepartmentAndSubDepartmentKeyString(candidate.vendor?.apNumber, candidate.commodity?.departmentId, candidate.commodity?.subDepartmentId));

    if (!locationGroupStoresList || locationGroupStoresList.length === 0) {
      return null;
    }

    let totalStoreCount = 0;
    for (let x = 0; x < locationGroupStoresList.length; x++) {
      totalStoreCount += locationGroupStoresList[x].stores.length;
    }
    let currentStoreCount = 0;
    for (let x = 0; x < candidate.candidateProducts[0]?.locationGroupStores?.length; x++) {
      currentStoreCount += candidate.candidateProducts[0]?.locationGroupStores[x]?.stores.length;
    }
    return currentStoreCount + ' / ' + totalStoreCount;
  }


  private getApDepartmentAndSubDepartmentKeyString(apNumber, departmentId, subDepartmentId): string {
    return apNumber ? apNumber : '' + '-' + departmentId ? departmentId : '' + '-' + subDepartmentId ? subDepartmentId : '';
  }

  onClickHeader(columnHeader) {
    switch (columnHeader) {
      case UploadCandidateCaseConstants.MASTER_LIST_COST: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.MasterListCost, ['masterListCost'], false);
        break;
      }
      case UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.TotalRetail, ['masterPack'], false);
        break;
      }
      case UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE: {
        this.editCandidateUtilService.showBulkEditAllCandidateProductModal(this.candidates, this.attributeType.VendorProductCode, ['vendorProductCode'], false);
        break;
      }
      case UploadCandidateCaseConstants.CASE_DESCRIPTION: {
        this.editCandidateUtilService.showBulkEditAllCandidateProductModal(this.candidates, this.attributeType.CaseDescription, ['caseDescription'], false);
        break;
      }
      case UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN: {
        this.editCandidateUtilService.showBulkEditAllCandidateProductModal(this.candidates, this.attributeType.CountryOfOrigin, ['countryOfOrigin'], false);
        break;
      }
      default: {
        console.log('Invalid edit all selection: ' + columnHeader.header);
      }
    }
  }
}
