import { BaseAttribute } from './base-attribute';
import { AttributeConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayRadioComponent } from '../../shared/attribute-display/attribute-display-radio/attribute-display-radio.component';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';

export class ItemWeightType implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayRadioComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Weight',
      description: 'Weight can impact how H-E-B pays for this case.',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      options: [
        { label: 'Fixed Weight', value: 'None' },
        { label: 'Catch Weight', value: 'Catch Weight' },
        { label: 'Variable Weight', value: 'Variable Weight' }
      ],
      defaultValue: 'None',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayRadioComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    if (!candidate.itemWeightType) {
      candidate.itemWeightType = CandidateUtilService.ITEM_WEIGHT_TYPE_NONE;
    }
    componentInstance.model = candidate.itemWeightType;
    componentInstance.modelChange.subscribe(x => {
      candidate.itemWeightType = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayRadioComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.itemWeightType) {
      componentInstance.model = candidate.itemWeightType;
    }
  }
}
