<pm-editor (closeEvent)="onClose()">

  <pm-header>
    <app-pia-mrt-inner-stepper *ngIf="supplierMrtService.innerCandidate"
                               [candidateErrors]="supplierMrtService.getAllPiaCandidateInnerErrorsByCandidateId()"
                               [isReplenishable]="supplierMrtService.isInnerCandidateReplenishable()" [stepIndex]="2"
                               [isSellable]="candidateUtilService.isSellable(supplierMrtService.innerCandidate)"
                               (indexClick)="onClickStepper($event)">
    </app-pia-mrt-inner-stepper>
  </pm-header>

  <pm-title>Tell us about this product.</pm-title>
  <pm-sub-title *ngIf="supplierMrtService.innerCandidate">UPC: {{supplierMrtService.innerCandidate.candidateProducts[0].upc}}-{{supplierMrtService.innerCandidate.candidateProducts[0].upcCheckDigit}}</pm-sub-title>

  <ng-container *ngIf="supplierMrtService.innerCandidate">
    <pm-product-descriptions-card [(model)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                                  [permissions]="productDescriptionsCardPermissions"
                                  [errorModel]="innerCandidateProductError">
    </pm-product-descriptions-card>

    <pm-product-images-card [(model)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                            [permissions]="productImagesCardPermissions"
                            [errorModel]="innerCandidateProductError">
    </pm-product-images-card>

    <pm-merchandising-info-card [(candidateModel)]="supplierMrtService.innerCandidate"
                                [(candidateProductModel)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                                [permissions]="merchandisingInfoCardPermissions"
                                [merchandiseTypes]="merchandiseTypes"
                                [commodities]="commodities"
                                (commodityChange)="onCommodityChange($event)"
                                (subCommodityChange)="onSubCommodityChange($event)"
                                (taxableChange)="setVertexTaxCategoryModel()"                                [candidateErrorModel]="innerCandidateError"
                                [candidateProductErrorModel]="innerCandidateProductError">
    </pm-merchandising-info-card>

    <pm-regulatory-card [(model)]="supplierMrtService.innerCandidate"
                        (taxableChange)="setVertexTaxCategoryModel()"
                        [permissions]="regulatoryCardPermissions"
                        [errorModel]="innerCandidateError">
    </pm-regulatory-card>

    <pm-product-pricing-card [(model)]="supplierMrtService.innerCandidate"
                             [permissions]="productPricingCardPermissions"
                             [errorModel]="innerCandidateError">
    </pm-product-pricing-card>

    <pm-pricing-card [(model)]="supplierMrtService.innerCandidate"
                     [permissions]="pricingCardPermissions"
                     [errorModel]="innerCandidateError"
                     [retailLinkState]="retailLinkState"
                     (validateRetailLink)="validateRetailLink()">
    </pm-pricing-card>

    <pm-product-packaging-card [(model)]="supplierMrtService.innerCandidate"
                               [permissions]="productPackagingCardPermissions"
                               [packageTypes]="packageTypes"
                               [unitOfMeasure]="unitsOfMeasures"
                               [errorModel]="innerCandidateError">
    </pm-product-packaging-card>

  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
