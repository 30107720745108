import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierProductService} from '../../service/supplier-product.service';
import {
  Attribute,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  ProductDescriptionsCardPermissions,
  ProductImagesCardPermissions,
  ProductPackagingCardPermissions,
  ProductPricingCardPermissions,
  RegulatoryCardPermissions,
  RetailLinkCardPermissions
} from 'pm-models';
import {LookupService} from '../../service/lookup.service';
import {CandidateService} from '../../service/candidate.service';
import {NewWarehouseProductStepperComponent} from '../new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {NewDsdProductStepperComponent} from '../new-dsd-product-stepper/new-dsd-product-stepper.component';
import {WorkflowService} from '../../service/workflow.service';
import {InputState, UPCInputState} from 'pm-components';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {LabelProductImagesCardPermissions} from 'pm-models/lib/card-models/label-product-images-model';
import {finalize} from 'rxjs/operators';
import {GrowlService} from '../../growl/growl.service';

@Component({
  selector: 'app-basic-item-setup',
  templateUrl: './basic-item-setup.component.html',
  styleUrls: ['./basic-item-setup.component.scss']
})
export class BasicItemSetupComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router,
              public supplierProductService: SupplierProductService, private lookupService: LookupService,
              private candidateService: CandidateService, private workflowService: WorkflowService,
              private permissionsService: NgxPermissionsService, public candidateUtilService: CandidateUtilService,
              private growlService: GrowlService) { }

  public isViewingPage = true;
  canNavigate = true;
  public unitsOfMeasures: any;
  public packageTypes: any;
  public candidateError: CandidateError = new CandidateError();
  public candidateProductError: CandidateProductError = new CandidateProductError();
  private taskSubscription$: any;
  public upcState: UPCInputState;
  public retailLinkState: InputState;
  public attributes: Attribute[];

  readonly productDescriptionsCardPermissions: ProductDescriptionsCardPermissions = {
    isReadOnly: false
  };
  readonly productPricingCardPermissions: ProductPricingCardPermissions = {
    isReadOnly: false
  };
  readonly retailLinkCardPermissions: RetailLinkCardPermissions = {
    isReadOnly: false
  };
  readonly regulatoryCardPermissions: RegulatoryCardPermissions = {
    isReadOnly: false,
    taxable: {
      isHidden: false
    },
    foodStamp: {
      isHidden: true
    },
    taxCategory: {
      isHidden: true
    },
    showCalories: {
      isHidden: true
    }
  };
  productPackagingCardPermissions: ProductPackagingCardPermissions = {
    depositScanCodeId: {isHidden: false,
      isDisabled: false},
    isReadOnly: false
  };

  readonly productImagesCardPermissions: ProductImagesCardPermissions = {
    isReadOnly: false
  };

  readonly labelInsightsImagesCardPermissions: LabelProductImagesCardPermissions = {
    isReadOnly: false
  };

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierProductService.getTaskId(),
        this.supplierProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT) {
            this.candidateError = this.supplierProductService.getBasicItemSetupError();
            this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
            this.supplierProductService.setOriginalAndCurrentCandidate(candidate);
            if (this.supplierProductService.getCandidate().productType === Candidate.NON_SELLABLE &&
              this.supplierProductService.getCandidate().dsdSwitch) {
              this.productPackagingCardPermissions.retailSize = { isHidden: true };
            }
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierProductService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierProductService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
  }

  initializeData() {
    this.candidateError = this.supplierProductService.getBasicItemSetupError();
    this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);

    if (this.supplierProductService.getCandidate().productType === Candidate.NON_SELLABLE &&
      this.supplierProductService.getCandidate().dsdSwitch) {
      this.productPackagingCardPermissions.retailSize = { isHidden: true };
    }
  }

  onClickNext() {
    this.canNavigate = false;
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_PRODUCT_DETAILS_VALIDATOR]).toPromise().then(data => {
      this.candidateError = this.supplierProductService.setBasicItemSetupError(new CandidateError());
      this.supplierProductService.saveCandidateAndNavigate('/casePack', false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierProductService.setBasicItemSetupError(error.error.candidateErrors);
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
        this.supplierProductService.saveCandidateAndNavigate('/casePack', false).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      } else {
        this.canNavigate = true;
      }
    });
  }

  onClickBack() {
    this.jumpTo('/supplierDetails', [CandidateValidatorType.SUPPLIER_PRODUCT_DETAILS_VALIDATOR]);
  }


  onClose() {
    this.isViewingPage = false;
    this.supplierProductService.saveCandidateAndClose();
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case NewWarehouseProductStepperComponent.UPC_ITEM_CODE:
        this.jumpTo('/setupCandidateType', SupplierProductService.BASE_VALIDATORS);
        break;
      case NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP:
        this.jumpTo('/supplierDetails', SupplierProductService.BASE_VALIDATORS);
        break;
      case NewWarehouseProductStepperComponent.CASE_PACK:
        this.onClickNext();
        break;
      case NewDsdProductStepperComponent.STORE_AUTHORIZATION:
        if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.jumpTo('/storeAuthorization', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpTo('/setUpStores', SupplierProductService.DSD_VALIDATORS);
        }
        break;
      case NewWarehouseProductStepperComponent.EXTENDED_ATTRIBUTES:
        if (this.supplierProductService.isDsdOnly()) {
          this.jumpTo('/extendedAttributes', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpTo('/extendedAttributes', SupplierProductService.BASE_VALIDATORS);
        }
        break;
      case NewWarehouseProductStepperComponent.REVIEW_PAGE:
        if (this.supplierProductService.isDsdOnly()) {
          this.jumpTo('/newProductReviewPageComponent', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpTo('/newProductReviewPageComponent', SupplierProductService.BASE_VALIDATORS);
        }
        break;
    }
  }

  jumpTo(urlToNavigate, validator) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      validator).toPromise().then(data => {
      this.candidateError = this.supplierProductService.setBasicItemSetupError(new CandidateError());
      this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      if (error.error.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierProductService.setBasicItemSetupError(error.error.candidateErrors);
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
        this.supplierProductService.setCandidateTypeError(this.candidateError);
        if (!this.supplierProductService.isDsdOnly() || !SupplierProductService.DSD_NON_NAVIGABLE_ON_ERROR_PAGES.includes(urlToNavigate)) {
          this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).pipe(
            finalize(() => this.canNavigate = true)
          ).subscribe();
        } else {
          this.canNavigate = true;
        }
      } else {
        this.canNavigate = true;
      }
    });
  }

  validateDepositUpc() {
    const validators = [];
    validators.push(CandidateValidatorType.DEPOSIT_UPC_VALIDATOR);
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.supplierProductService.getCurrentCandidateProduct().id;
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(), validators).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.depositScanCodeId = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.depositScanCodeId = returnedCandidateProductError.depositScanCodeId;
      }
    });
  }

  validateRetailLink() {
    this.retailLinkState = InputState.loading;
    this.candidateError.retailLink = undefined;
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(), [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).subscribe((candidate) => {
      this.retailLinkState = InputState.valid;

      this.supplierProductService.getCandidate().retailLink = candidate.retailLink;
      this.supplierProductService.getCandidate().retailXFor = candidate.retailXFor;
      this.supplierProductService.getCandidate().retailPrice = candidate.retailPrice;

      this.candidateError.retailLink = undefined;
    }, (error) => {
      this.retailLinkState = InputState.invalid;
      this.supplierProductService.getCandidate().retailXFor = null;
      this.supplierProductService.getCandidate().retailPrice = null;
      if (error.error.candidateErrors) {
        this.candidateError.retailLink = error.error.candidateErrors.retailLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }
}
