import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {WorkflowService} from '../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../service/candidate.service';
import {LookupService} from '../service/lookup.service';
import {GrowlService} from '../growl/growl.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {CostService} from '../service/cost.service';
import {FileService} from '../service/file.service';
import {AttributeTypes, ReviewComponent, UPCInputState} from 'pm-components';
import {
  Attribute,
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  BaseCardPermissions,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  EmailMessage,
  Product,
  Task,
  TaskDecision,
  TextInputType,
  Upc
} from 'pm-models';
import {ProductService} from '../service/product.service';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {CandidateUtilService} from '../service/candidate-util.service';
import {AuthService} from '../auth/auth.service';
import {SupplierMrtService} from '../service/supplier-mrt.service';
import {ItemWeightTypeService} from '../service/item-weight-type.service';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, finalize, switchMap, tap} from 'rxjs/operators';
import {UUID} from 'angular2-uuid';
import {MatUtilService} from '../service/mat-util.service';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';

@Component({
  selector: 'app-buyer-mrt-review',
  templateUrl: './buyer-mrt-review.component.html',
  styleUrls: ['./buyer-mrt-review.component.scss']
})
export class BuyerMrtReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public BUYER_TASK_NAME = 'Key Buyer Data';
  public BUYER_REVISE_TASK_NAME = 'Revise Buyer Data';
  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public UPC = 'UPC';
  public OTHER = Candidate.OTHER;
  private openCommentDrawer: boolean = false;
  public tempCommentHolder: string;

  constructor(private workflowService: WorkflowService, private route: ActivatedRoute,
              private router: Router, public candidateService: CandidateService, private lookupService: LookupService,
              private growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, private fileService: FileService, public productService: ProductService,
              public candidateUtilService: CandidateUtilService, private authService: AuthService,
              public permissionService: NgxPermissionsService, public supplierMrtService: SupplierMrtService,
              private itemWeightTypeService: ItemWeightTypeService, public matUtilService: MatUtilService) {
  }

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  private taskSubscription$: any;
  private task: Task;
  public isViewingPage = true;
  public opened = true;
  public merchandiseTypes: any;
  public commodities: any;
  public subCommodities: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public mrtExistingInners: ExistingInner[] = [];
  public mrtCandidateInners: CandidateInner[] = [];
  public isShowingWhatHappensNext = false;
  public isRejectDisabled = false;
  public isApproveDisabled = false;
  public isSaveMrtButtonDisabled = false;
  public isShowingBuyerDrawer = false;
  public isShowingCandidateDetails = false;
  public isShowingProductDetails = false;
  public currentExistingInner: ExistingInner = undefined;
  public currentProduct: Product = undefined;
  public currentUpc: Upc = undefined;
  public currentInnerCandidate: Candidate = undefined;
  public currentInnerCandidateProduct: CandidateProduct = undefined;
  public decimalCount = 1;
  public costOwners: any = [];
  public isLoadingInners = true;

  public mrtModalExistingInners: ExistingInner[] = [];
  public mrtModalCandidateInners: CandidateInner[] = [];
  public mrtModalCandidateError: CandidateError;
  public searchedCandidateProduct: CandidateProduct = { id: UUID.UUID() };
  public searchedCandidateProductError: CandidateProductError = new CandidateProductError();

  public upcType = 'UPC';
  public upcState: UPCInputState = UPCInputState.none;
  public upcOptions = [{ label: 'UPC', value: 'UPC' }];

  // undefined if not yet calculated, false if is missing data,
  // true if all data present
  public isNotMissingCandidateInfo: Boolean;

  otherReasonMessage: string;

  public isPageEditable: boolean = false;

  public showMatAttributes = false;
  public isLoadingMatData = true;
  public globalAttributes: Attribute[] = [];
  public warehouseItemAttributes: Attribute[] = [];

  public requestNewMatAttributeOverrideWrapper = new RequestNewMatAttributeOverrideWrapper();

  readonly permissions: BaseCardPermissions = {
    isReadOnly: false,
  };

  merchandiseTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Merchandise type',
    description: '',
    isRequired: true,
    isDisabled: () => true,
    isReadOnly: () => false,
    name: '',
    displayRef: 'description',
    placeholderText: 'Select a merchandise type...',
    collections: this.merchandiseTypes
  };

  commodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Commodity',
    description: 'Choose an appropriate category.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'commodityId',
    displayRef: 'commodityName',
    placeholderText: 'Select a commodity...',
    collections: this.commodities
  };

  subCommodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-commodity',
    description: 'Choose an appropriate subcategory.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'subcommodityId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-commodity...',
    collections: this.subCommodities
  };

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'Verify the value provided by the supplier or enter a value if empty.  ',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };

  yearConfiguration: AttributeTextInputConfig = {
    label: 'Year',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };

  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  reasonType: string = Candidate.CORRECTIONS_NEEDED;

  reasonConfiguration: AttributeConfig = {
    label: 'Reason',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    inputGroupClass: 'attribute-radios-block',
    options: [
      { label: Candidate.CORRECTIONS_NEEDED, value: Candidate.CORRECTIONS_NEEDED },
      { label: Candidate.NOT_WANTED, value: Candidate.NOT_WANTED },
      { label: Candidate.OTHER, value: Candidate.OTHER }
    ],
  };

  rushFlagConfiguration: AttributeConfig = {
    label: 'Rush review of this candidate',
    description: 'Send this Candidate to the top of other reviewer\'s queue.',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'rushFlagId',
    defaultValue: false
  };

  quantityConfiguration: AttributeTextInputConfig = {
    label: '# Sellable units',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'quantityId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# Units'
  };

  buyerComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'buyerCommentId',
    maxLength: 4000
  };


  private setIsPageEditable() {
    this.isPageEditable = this.authService.isBuyer();
  }

  ngOnInit() {

    this.setIsPageEditable();

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.BUYER_TASK_NAME && this.task.name !== this.BUYER_REVISE_TASK_NAME) {
              this.router.navigate(
                ['/tasks'],
                {
                  queryParams:
                    {
                      growlMessage: 'Candidate is not in ' + this.BUYER_TASK_NAME + ' or ' + this.BUYER_REVISE_TASK_NAME + ' status.',
                      growlToUse: GrowlService.SEVERITY_ERROR
                    }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                this.setInitialValues(candidate);
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: Candidate) {

    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    this.guaranteeToStoreDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.reactionDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.inboundSpecConfiguration.isHidden = () => !this.candidate.codeDate;
    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }
    if (candidate.buyer?.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }

    this.initializeMrtInners().subscribe();
    this.setupMatAttributes();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }

    if (this.isPageEditable) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatAttributesAndValues(this.candidate, this.globalAttributes, []).pipe(
        tap(() => {
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.globalAttributes,
            [], this.warehouseItemAttributes, []);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })
      ).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.candidateProduct.globalAttributes, [],
        this.warehouseItemAttributes, []);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  initializeMrtInners(): Observable<any> {
    this.mrtExistingInners = [];
    this.mrtCandidateInners = [];
    this.isNotMissingCandidateInfo = null;
    this.isLoadingInners = true;
    return forkJoin([this.supplierMrtService.setCandidateInnerCandidates(this.candidate),
      this.supplierMrtService.setCandidateExistingInnerProducts(this.candidate)]).pipe(
      tap(() => {
          this.handleExistingInners();
          this.handleActivatedCandidateInners();
          this.handleNonActivatedCandidateInners();
          this.isLoadingInners = false;
        }
      ),
      switchMap(() => this.supplierMrtService.getCandidateCostOwners(this.candidate)),
      tap(costOwners => this.costOwners = costOwners)
    );
  }

  private handleActivatedCandidateInners() {
    const activatedCandidateInners: CandidateInner[] = this.candidate.mrtInfo?.candidateInners
      ?.filter(candidateInner => candidateInner.candidate && candidateInner.candidate.status === Candidate.ACTIVATED);

    if (!!activatedCandidateInners?.length) {
      const upcs: number[] = activatedCandidateInners.map(activatedCandidateInner =>
        activatedCandidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate)].upc);
      this.lookupService.getProductsByUpcs(upcs).pipe(
        tap((products: Product[]) => {
          activatedCandidateInners.forEach(activatedCandidateInner => {
            const index = CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate);
            const upc = activatedCandidateInner.candidate.candidateProducts[index].upc;
            const existingInner = new ExistingInner();
            existingInner.product = this.productService.getProductByUpc(upc, products);
            existingInner.unitCost = activatedCandidateInner.unitCost;
            existingInner.quantity = activatedCandidateInner.quantity;
            existingInner.upc = upc;
            existingInner.upcCheckDigit = activatedCandidateInner.candidate.candidateProducts[index].upcCheckDigit;
            this.mrtExistingInners.push(existingInner as ExistingInner);
          });
        })
      ).subscribe();
    }
  }

  private handleNonActivatedCandidateInners() {
    let hasRetail = true;
    if (!!this.candidate.mrtInfo?.candidateInners?.length) {
      this.candidate.mrtInfo.candidateInners.forEach(candidateInner => {
        if (candidateInner.candidate && candidateInner.candidate.status !== Candidate.ACTIVATED) {
          if (!candidateInner.candidate.retailType) {
            candidateInner.candidate.retailType = CostService.KEY_RETAIL;
          }
          // if the candidate is non replenishable, the unit cost is never calculated by supplier
          // due to lack of fields. So use the value from the mrt.
          if (!candidateInner.replenishable) {
            candidateInner.candidate.unitCost = candidateInner.unitCost;
            this.mrtCandidateInners.push(candidateInner as CandidateInner);
          } else {
            this.mrtCandidateInners.unshift(candidateInner as CandidateInner);
          }
          // if the candidate doesn't have retail, set isNotMissingCandidateInfo to false.
          if (candidateInner.candidate.retailType === CostService.PRICE_REQUIRED || !candidateInner.candidate.retailPrice || !candidateInner.candidate.retailXFor) {
            hasRetail = false;
          }
        }
      });
    }
    this.isNotMissingCandidateInfo = hasRetail;
  }

  private handleExistingInners() {
    if (!!this.candidate.mrtInfo?.existingInners?.length) {
      this.candidate.mrtInfo.existingInners.forEach(existingInner => {
        this.mrtExistingInners.push(existingInner);
      });
    }
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  /**
   * Calls workflow service to end the buyer task and then navigates back to the tasks page.
   */
  private saveAndCompleteBuyerTask() {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      if (this.hasBuyerChanged(this.candidate, this.originalCandidate)) {
        this.sendEmailToBuyer(this.candidate);
      }
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_APPROVE_DECISION, 'Successfully completed task.');
    });
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate) && this.isPageEditable) {
      this.candidate.candidateProducts[0] = this.candidateProduct;
        this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        if (this.hasBuyerChanged(this.candidate, this.originalCandidate)) {
          this.sendEmailToBuyer(this.candidate);
        }
        this.isViewingPage = false;
        this.router.navigate(['/tasks']);
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']);
    }
  }


  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
  }

  get attributeType() {
    return AttributeTypes;
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.originalCandidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.originalCandidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      this.merchandiseTypeConfiguration.collections = merchandiseTypes;
      if (!this.originalCandidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
    if (!isSellable) {
      this.setNonSellableRetailValues();
    }
  }

  /**
   * Sets non sellable default values.
   */
  private setNonSellableRetailValues() {
    this.originalCandidate.retailXFor = 1;
    this.originalCandidate.retailPrice = '0.00';
    this.originalCandidate.retailType = this.KEY_RETAIL;
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
        this.subCommodityConfiguration.collections = this.subCommodities;
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
    this.subCommodityConfiguration.collections = this.subCommodities;
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
    }, (error) => {
      this.growlService.addError(error);
      this.isRejectDisabled = false;
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  getMasterPack() {
    let masterPack = 0;
    if (this.candidate.mrtInfo.existingInners && this.candidate.mrtInfo.existingInners.length > 0) {
      for (let x = 0; x < this.candidate.mrtInfo.existingInners.length; x++) {
        masterPack += this.candidate.mrtInfo.existingInners[x].quantity;
      }
    }
    if (this.candidate.mrtInfo.candidateInners && this.candidate.mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.candidate.mrtInfo.candidateInners.length; x++) {
        masterPack += this.candidate.mrtInfo.candidateInners[x].quantity;
      }
    }
    return masterPack;
  }

  hasPendingInner(): boolean {
    let result = false;
    for (let x = 0; x < this.mrtCandidateInners.length; x++) {
      if (this.mrtCandidateInners[x].candidate.status === 'IN_PROGRESS') {
        result = true;
        break;
      }
    }
    return result;
  }

  hasPendingReplenishableInner(): boolean {
    for (let x = 0; x < this.mrtCandidateInners.length; x++) {
      if (this.mrtCandidateInners[x].replenishable && this.mrtCandidateInners[x].candidate.status === 'IN_PROGRESS') {
        return true;
      }
    }
    return false;
  }

  toggleWhatHappensNext() {
    this.isShowingWhatHappensNext = !this.isShowingWhatHappensNext;
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  showEditCandidateProductModal(type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
      }
    });
  }


  onClickNext() {
    this.isShowingBuyerDrawer = true;
    this.isShowingProductDetails = false;
    this.isShowingCandidateDetails = false;
    this.currentUpc = undefined;
    this.currentProduct = undefined;
    this.currentExistingInner = undefined;
    this.currentInnerCandidate = undefined;
    this.currentInnerCandidateProduct = undefined;
    this.pmReview.openDrawer();
  }

  collapse() {
    this.isShowingBuyerDrawer = false;
    this.isShowingProductDetails = false;
    this.isShowingCandidateDetails = false;
    this.currentExistingInner = undefined;
    this.currentProduct = undefined;
    this.currentUpc = undefined;
    this.currentInnerCandidate = undefined;
    this.currentInnerCandidateProduct = undefined;
    this.pmReview.closeDrawer();
  }


  onClickReject(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  rejectMrt() {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = this.getRejectMessage();
    this.candidate.status = Candidate.DECLINED;
    this.rejectNonReplenishablesAndMrt();
  }

  rejectNonReplenishablesAndMrt() {
    if (!this.candidate.mrtInfo.candidateInners || this.candidate.mrtInfo.candidateInners.length === 0) {
      this.saveAndCompleteTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
      return;
    }

    for (let x = 0; x < this.candidate.mrtInfo.candidateInners.length; x++) {
      if (!this.candidate.mrtInfo.candidateInners[x].replenishable) {
        if (this.candidate.mrtInfo.candidateInners[x].candidate.status === Candidate.APPROVED) {
          this.candidate.mrtInfo.candidateInners[x].candidate.status = Candidate.DECLINED;
          this.candidate.mrtInfo.candidateInners[x].candidate.vendorComment = this.candidate.vendorComment;
          this.candidateService.saveCandidate(this.candidate.mrtInfo.candidateInners[x].candidate).subscribe(savedCandidate => {
            this.workflowService.updateNonReplenishableCompletedEndStateToRejected(
              this.candidate.mrtInfo.candidateInners[x].candidateId).subscribe();
          });
        } else {
          this.candidate.mrtInfo.candidateInners[x].candidate.status = Candidate.DECLINED;
          this.candidate.mrtInfo.candidateInners[x].candidate.vendorComment = this.candidate.vendorComment;
          this.candidateService.saveCandidate(this.candidate.mrtInfo.candidateInners[x].candidate).subscribe(savedCandidate => {
            this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.candidate.mrtInfo.candidateInners[x].candidateId)
              .subscribe(task => {
                // non replenishables can only be in buyer/pia stage
                if (task.name === 'Key Buyer Data') {
                  this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                    TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).subscribe();
                } else if (task.name === 'PIA Final Review') {
                  this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                    TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe();
                }
              });
          });
        }
      }
      // reject MRT last.
      if (x === this.candidate.mrtInfo.candidateInners.length - 1) {
        this.saveAndCompleteTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
      }
    }
  }

  rejectMrtAndCloseModal(event, panel, target) {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;
    this.rejectNonReplenishablesAndMrt();
    panel.hide();
  }

  viewCandidateDetails(candidateInner: CandidateInner) {

    this.isShowingProductDetails = false;
    this.isShowingBuyerDrawer = false;
    this.isShowingCandidateDetails = true;
    this.currentExistingInner = undefined;
    this.currentProduct = undefined;
    this.currentInnerCandidate = candidateInner.candidate;
    this.currentInnerCandidateProduct =
      candidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidateInner.candidate)];
    this.pmReview.openDrawer();
  }

  viewProductDetails(existingInner: ExistingInner) {

    this.lookupService.getUpc(existingInner.upc).subscribe((upc) => {
      this.currentUpc = upc;
    });
    this.isShowingProductDetails = true;
    this.isShowingBuyerDrawer = false;
    this.isShowingCandidateDetails = false;
    this.currentExistingInner = existingInner;
    this.currentProduct = existingInner.product;
    this.currentInnerCandidate = null;
    this.currentInnerCandidateProduct = null;
    this.pmReview.openDrawer();

  }

  /**
   * Saves candidate.
   */
  save() {
    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      if (this.hasBuyerChanged(this.candidate, this.originalCandidate)) {
        this.sendEmailToBuyer(this.candidate);
      }
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  seasonChange(event) {
    this.candidate.season = event;
  }


  onClickApprove() {
    this.isApproveDisabled = true;

    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.BUYER_MRT_VALIDATOR])
      .subscribe(() => {
        this.saveAndCompleteBuyerTask();

      }, (error) => {
        if (error.error.candidateErrors) {
          this.candidateError = error.error.candidateErrors;

          this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message); // TODO: new way to handle server side errors?
        }
        this.isApproveDisabled = false;
      });
  }

  rejectMrtAndNewItems(panel) {
    panel.hide();
    this.isRejectDisabled = true;
    this.candidate.vendorComment = this.getRejectMessage();
    this.candidate.status = Candidate.DECLINED;
    for (let x = 0; x < this.mrtCandidateInners.length; x++) {

      // if the non replenish is approved, save declined status and reject reason.
      if (this.mrtCandidateInners[x].candidate.status === Candidate.APPROVED && !this.mrtCandidateInners[x].replenishable) {
        this.mrtCandidateInners[x].candidate.status = Candidate.DECLINED;
        this.mrtCandidateInners[x].candidate.vendorComment = this.candidate.vendorComment;
        this.candidateService.saveCandidate(this.mrtCandidateInners[x].candidate).subscribe(savedCandidate => {
          this.workflowService.updateNonReplenishableCompletedEndStateToRejected(
            this.mrtCandidateInners[x].candidate.candidateId).subscribe(() => {
            // if it's the last iteration, save and reject MRT.
            if (x === this.mrtCandidateInners.length - 1) {
              this.saveAndCompleteTaskAndRouteToTasksPage(
                WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
            }
          });
        });
        continue;
        // if the replenishable is completed, then it will be shortly activated and we cannot reject it at this point.
      } else if (this.mrtCandidateInners[x].candidate.status === Candidate.COMPLETED && this.mrtCandidateInners[x].replenishable) {
        if (x === this.mrtCandidateInners.length - 1) {
          this.saveAndCompleteTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
        }
        continue;
      }

      this.mrtCandidateInners[x].candidate.status = Candidate.DECLINED;
      this.mrtCandidateInners[x].candidate.vendorComment = this.getRejectMessage();
      this.candidateService.saveCandidate(this.mrtCandidateInners[x].candidate).subscribe(savedCandidate => {
        this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.mrtCandidateInners[x].candidateId)
          .subscribe(task => {
            // non replenishables can only be in buyer/pia stage
            if (task.name === 'Key Buyer Data') {
              this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).subscribe(() => {
                  // reject MRT last.
                  if (x === this.mrtCandidateInners.length - 1) {
                    this.saveAndCompleteTaskAndRouteToTasksPage(
                      WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION,
                      'Successfully rejected candidate.');
                  }
                }
              );
            } else if (task.name === 'PIA Final Review') {
              this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe(() => {
                  // reject MRT last.
                  if (x === this.mrtCandidateInners.length - 1) {
                    this.saveAndCompleteTaskAndRouteToTasksPage(
                      WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION,
                      'Successfully rejected candidate.');
                  }
                }
              );
              // replenishables only
            } else if (task.name === 'Assign Warehouse') {
              // if it's an sca task, move to next, then reject from PIA.
              this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE)
                .subscribe(() => {
                  this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.mrtCandidateInners[x].candidateId)
                    .subscribe(piaTask => {
                      this.workflowService.completeTaskWithAction(piaTask, WorkflowService.ACTION_COMPLETE,
                        TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe(() => {
                          // reject MRT last.
                          if (x === this.mrtCandidateInners.length - 1) {
                            this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE,
                              TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
                          }
                        }
                      );
                    });
                });
            }
          });
      });
    }
  }

  getReviewClass(): string {
    let classString = '';
    if (this.isShowingProductDetails || this.isShowingCandidateDetails) {
      classString += ' review-grid-container';
    }
    return classString;
  }

  commodityChange(event) {
    this.candidate.commodity = event;
    this.candidate.subCommodity = null;
    this.getSubCommodities(event);
  }

  subCommodityChange(event) {
    this.candidate.subCommodity = event;
  }

  editBuyer() {
    this.editCandidateModalService.openModal(AttributeTypes.Buyer, this.candidate).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidate.commodity = null;
        this.candidate.subCommodity = null;
        if (response.buyer && response.buyer.buyerId) {
          this.setCommoditiesAndSubCommodities(response.buyer.buyerId);
        } else {
          this.commodities = [];
          this.subCommodities = [];
        }
      }
    });
  }

  sendEmailToBuyer(candidate: Candidate) {
    if (candidate.buyer) {
      const to = candidate.buyer.email;
      const toName = candidate.buyer.buyerName;
      const from = 'do-not-reply@heb.com';
      const fromName = '';
      const subject = 'You Have a New MRT in PAM';
      const url = window.location.href;
      const body = this.authService.getUser() + ' made you the buyer for a new MRT in PAM: <b>' + this.candidate.description + '</b>. ' +
        '<a href="' + url + '">Click here to view it.</a><br><br>';
      const cc = '';
      const ccName = '';
      const email = new EmailMessage(to, toName, from, fromName, subject, body, cc, ccName);
      this.lookupService.sendEmail(email).subscribe();
    }
  }

  hasBuyerChanged(savedCandidate: Candidate, originalCandidate: Candidate) {
    return JSON.stringify(savedCandidate.buyer) !== JSON.stringify(originalCandidate.buyer);
  }

  getCostDisplay() {
    // if we haven't deduced whether there's missing information, return empty string
    if (this.isNotMissingCandidateInfo === null || this.isNotMissingCandidateInfo === undefined) {
      return '';
      // if we're missing information, return message.
    } else if (this.isNotMissingCandidateInfo === false) {
      return 'We can’t calculate margin and penny profit because we’re missing retail information for one or more of the UPCs above.';
    }
  }

  getPennyProfit() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.candidate.masterListCost);

    if (!pennyProfit) {
      return '';
    }
    return this.costService.toCurrency(pennyProfit);
  }

  getMarginPercent() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.candidate.masterListCost);

    if (!pennyProfit) {
      return '';
    }
    const marginPercent = this.candidateUtilService.getMRTMarginPercent(pennyProfit, masterSuggestedRetail);
    return marginPercent.toPrecision(6);
  }

  isMarginNegativeOrZero() {
    const margin = +this.getMarginPercent();
    return margin <= 0;
  }

  toLowerCase(status: String) {
    return status.toLowerCase();
  }

  editCaseUPC() {
    this.showEditCandidateModal(AttributeTypes.CaseUPC, {
      validationService: this.candidateService
    });
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, JSON.parse(JSON.stringify(this.candidate)), overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        if (type === AttributeTypes.CaseUPC) {
          const currentCandidateProduct = CandidateUtilService.getCurrentCandidateProduct(response);
          this.candidateProduct.caseUpc = currentCandidateProduct.caseUpc;
          this.candidateProduct.caseUpcCheckDigit = currentCandidateProduct.caseUpcCheckDigit;
        }
      }
    });
  }

  /**
   * Returns whether a buyer is tied to departments that are variable or catch weight departments (2, 6, 9).
   */
  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.candidate.commodity && this.candidate.commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.candidate.commodity.departmentId);
    } else {
      return false;
    }
  }

  reasonTypeChange(event) {
  }

  /**
   * Returns true if there's no reason type, or the reason type is OTHER and the message is null, undefined or empty (not all spaces).
   */
  isRejectButtonDisabled(): boolean {
    return this.isRejectDisabled || !this.reasonType || (this.reasonType === Candidate.OTHER &&
      (!this.otherReasonMessage || !this.otherReasonMessage.trim()));
  }

  getRejectMessage(): string {
    if (this.reasonType === Candidate.OTHER) {
      return this.otherReasonMessage;
    } else {
      return this.reasonType;
    }
  }


  onClickEditMrtItems(event, panel, target) {
    this.searchedCandidateProduct = { id: UUID.UUID()};
    this.searchedCandidateProductError = new CandidateProductError();
    this.mrtModalCandidateError = undefined;
    this.mrtModalExistingInners = [];
    this.mrtModalCandidateInners = [];
    this.upcState = UPCInputState.none;
    if (this.mrtExistingInners && this.mrtExistingInners.length > 0) {
      this.mrtExistingInners.forEach(val => this.mrtModalExistingInners.push(Object.assign({}, val)));
    }
    if (this.mrtCandidateInners && this.mrtCandidateInners.length > 0) {
      this.mrtCandidateInners.forEach(val => this.mrtModalCandidateInners.push(Object.assign({}, val)));
    }
    event.stopPropagation();
    panel.show(event, target);
  }



  cancelEditMrtItems(op) {
    op.hide();
    this.mrtModalCandidateError = undefined;
    this.mrtModalCandidateInners = [];
    this.mrtModalExistingInners = [];
  }

  /**
   * Saves candidate.
   */
  saveMrtAndCloseModal(overlayPanel) {
    this.isSaveMrtButtonDisabled = true;
    const toValidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    toValidate.mrtInfo.candidateInners = this.mrtModalCandidateInners;
    toValidate.mrtInfo.existingInners = this.mrtModalExistingInners;
    const updateMrtInnerRequest =
      this.supplierMrtService.getUpdateMrtInnerRequest(this.candidate, this.mrtModalExistingInners, this.mrtModalCandidateInners);


    this.candidateService.validateCandidate(toValidate, [CandidateValidatorType.SUPPLIER_SETUP_MRT_ITEMS_VALIDATOR]).pipe(
      switchMap((validatedCandidate) =>  this.candidateService.updateMrtInners(updateMrtInnerRequest)),
      tap((updatedMrtCandidate: Candidate) => {
        this.candidate = updatedMrtCandidate;
        this.isSaveMrtButtonDisabled = false;
        this.mrtModalCandidateError = undefined;
        overlayPanel.hide();
      }),
      switchMap(() => this.initializeMrtInners()),
      catchError((error) => {
        this.isSaveMrtButtonDisabled = false;
        if (error.error?.candidateErrors) {
          this.mrtModalCandidateError = error.error.candidateErrors;
        } else {
          this.growlService.addError(error);
        }
        return throwError(error);
      })
    ).subscribe();
  }


  hasError(): boolean {
    return !!this.searchedCandidateProductError?.upc || !!this.searchedCandidateProductError?.upcCheckDigit;
  }



  setUpcAndValidate() {
    if (!this.searchedCandidateProduct.upc || this.searchedCandidateProduct.upcCheckDigit === null || this.searchedCandidateProduct.upcCheckDigit === undefined) {
      this.upcState = UPCInputState.none;
      this.searchedCandidateProductError.upc = null;
      this.searchedCandidateProductError.upcCheckDigit = null;
    } else {
      this.validateUPC();
    }
  }


  validateUPC() {
    this.upcState = UPCInputState.loading;
    this.searchedCandidateProduct.availableUpc = null;
    this.searchedCandidateProduct.existingUpc = null;

    if (this.mrtModalExistingInners.some(inner => inner.upc === this.searchedCandidateProduct.upc)) {
      this.upcState = UPCInputState.invalid;
      this.searchedCandidateProductError.upc = 'Provided UPC is already a part of this MRT.';
      return;
    }

    const validationCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    validationCandidate.candidateProducts = [this.searchedCandidateProduct];
    this.candidateService.validateCandidate(validationCandidate,
      [CandidateValidatorType.MRT_UPC_VALIDATOR]).subscribe((candidate: Candidate) => {

      if (candidate.candidateProducts[0].existingUpc) {
        this.upcState = UPCInputState.valid;
        this.searchedCandidateProduct = candidate.candidateProducts[0];
        this.searchedCandidateProductError.upc = null;
        this.searchedCandidateProductError.upcCheckDigit = null;
      } else {
        this.upcState = UPCInputState.invalid;
        this.searchedCandidateProductError.upc = 'UPC not activated. You can only add activated UPCs to this MRT.';
      }

    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        this.searchedCandidateProductError = returnedCandidateError.candidateProductErrors[this.searchedCandidateProduct.id];
      } else {
        this.growlService.addError(error.message);
      }
    });
  }


  addItem() {
    if (!this.upcState || this.upcState !== UPCInputState.valid) {
      return;
    }

    const candidateProductToUse = JSON.parse(JSON.stringify(this.searchedCandidateProduct));
    this.resetCandidateProductAndUpcSearchState();

    if (candidateProductToUse.existingUpc) {
      this.addExistingInner(candidateProductToUse);
      this.selectUpcInput();
    }
  }


  resetCandidateProductAndUpcSearchState() {
    this.searchedCandidateProduct = { id: UUID.UUID()};
    this.upcState = UPCInputState.none;
  }

  selectUpcInput() {
    window.getSelection().removeAllRanges();
    const selector: HTMLInputElement = document.getElementsByClassName('upc-input')[0] as HTMLInputElement;
    selector.select();
  }

  deleteExistingInner(index) {
    this.mrtModalExistingInners.splice(index, 1);
  }
  deleteCandidateInner(index) {
    this.mrtModalCandidateInners.splice(index, 1);
  }

  addExistingInner(candidateProduct: CandidateProduct) {
    const existingInner = new ExistingInner();
    existingInner.upc = candidateProduct.upc;
    existingInner.upcCheckDigit = candidateProduct.upcCheckDigit;

    this.lookupService.getProductByUpcAndApNumbers(candidateProduct.upc, []).subscribe((productData) => {
      existingInner.product = productData;
      this.mrtModalExistingInners.push(existingInner);
    });
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.buyerComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.buyerComment = this.tempCommentHolder;
    this.candidate.buyerCommentUser = this.authService.getUser();
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  addAttachments(event) {
    this.candidate.attachments = event;
    this.save();
  }
}
