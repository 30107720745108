import {Injectable} from '@angular/core';
import {WorkFlowTask} from 'pm-models/lib/workFlowTask';
import {Observable, of, throwError as observableThrowError} from 'rxjs';
import {PublisherService} from './publisher.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {HistoricProcessInstance, ProcessInstance, Task, TaskDecision, TaskDecisionModel} from 'pm-models';
import {ProcessVariables} from 'pm-models/lib/processVariables';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()
export class WorkflowService {
  private taskUrl2 = 'task-api/tasks';
  private getTaskByIdIncludeVariablesUrl = 'task-api/tasks/findByIdIncludingVariables';
  private getTaskByCandidateIdIncludeVariablesUrl = 'task-api/tasks/findByCandidateIdIncludingVariables';
  private getTaskByCandidateIdIncludeVariablesUrlForInternalUser = 'task-api/tasks/findByCandidateIdIncludingVariablesForInternalUser';
  private updateStatesUrl = 'task-api/tasks/updateStates';
  private taskUrl3 = 'process-api/runtime/tasks';
  private processInstanceFlowableUrl = 'process-api/runtime/process-instances';
  private processInstanceCreateUrl = 'wf-api/create';
  private processInstanceMultiCreateUrl = 'wf-api/multiCreate';
  private historicalProcessInstanceFlowableUrl = 'process-history-api/setVariableVendorViewed';
  private historicalProcessInstanceUrl = 'process-api/history/historic-process-instances';
  private processHistoryUrl = 'process-history-api/findCompletedByEndStates';
  private allTasksForCandidates = 'process-history-api/findTasksByCandidates';
  private updateNonReplenishableCompletedEndStateToRejectedUrl = 'process-history-api/updateNonReplenishableCompletedEndStateToRejected';
  private vendorUnderReviewProcessesUrl = 'wf-api/vendor/getByUnderView';
  private workflowServiceVersionBaseUrl = 'wf-api/versions';
  private getIsNonReplenishableMrtInnerCandidateIdUrl = 'wf-api/getIsNonReplenishableMrtInnerCandidateId';
  private multiCompleteUrl = 'task-api/tasks/multiComplete';

  public static ACTION_COMPLETE = 'complete';

  // workflow states
  public static PIA_APPROVED_STATE = 'approveCandidateEnd';
  public static PIA_DECLINED_STATE = 'rejectCandidateEnd';
  public static VENDOR_DECLINED_STATE = 'rejectCandidateByVendorEnd';
  public static PIA_DELETE = 'rejectCandidateByPia';

  constructor( private http: HttpClient, private publisherService: PublisherService) { }

  /** GET tasks from the server */
  getTasks(currentRole: string, currentSort?: string): Observable<Task[]> {
    if (currentSort) {
      return this.http.get<Task[]>(this.taskUrl2, {params: { currentRole: currentRole, currentSort: currentSort}})
        .pipe(
          tap(tasks => this.log(`fetched tasks` + tasks)),
          catchError(this.handleError<Task[]>(`getTasks`))
        );
    } else {
      return this.http.get<Task[]>(this.taskUrl2, {params: {currentRole: currentRole}})
        .pipe(
          tap(tasks => this.log(`fetched tasks` + tasks)),
          catchError(this.handleError<Task[]>(`getTasks`))
        );
    }
  }

  /**
   * Gets task by id, including task variables.
   * @param taskId Task id to search for.
   */
  getTaskByIdWithVariables(taskId: string): Observable<Task> {
    const url = `${this.getTaskByIdIncludeVariablesUrl}/${taskId}`;
    return this.http.get<Task>(url).pipe(
      tap(() => this.log(`fetched task id=${taskId}`)),
      catchError(this.handleError<Task>(`getTask id=${taskId}`))
    );
  }

  /**
   * Gets task by id, including task variables.
   * @param candidateId Candidate id to search for.
   */
  getTaskByCandidateIdWithVariables(candidateId: number): Observable<Task> {
    const url = `${this.getTaskByCandidateIdIncludeVariablesUrl}/${candidateId}`;
    return this.http.get<Task>(url).pipe(
      tap(() => this.log(`fetched candidate id=${candidateId}`)),
      catchError(this.handleError<Task>(`getTaskByCandidateId id=${candidateId}`))
    );
  }

  /**
   * Gets task by id, including task variables.
   * @param candidateId Candidate id to search for.
   */
  getTaskByCandidateIdWithVariablesForInternalUser(candidateId: number): Observable<Task> {
    const url = `${this.getTaskByCandidateIdIncludeVariablesUrlForInternalUser}/${candidateId}`;
    return this.http.get<Task>(url).pipe(
      tap(() => this.log(`fetched candidate id=${candidateId}`)),
      catchError(this.handleError<Task>(`getTaskByCandidateId id=${candidateId}`))
    );
  }

  /* GET tasks whose name contains search term */
  searchTasks(term: string): Observable<Task[]> {
    if (!term.trim()) {
      // if not search term, return empty hero array.
      return of([]);
    }
    return this.http.get<Task[]>(`api/tasks/?name=${term}`).pipe(
      tap(() => this.log(`found tasks matching "${term}"`)),
      catchError(this.handleError<Task[]>('searchTasks', []))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return observableThrowError(error);
    };
  }

  /** Log a WorkflowService message with the PublisherService */
  private log(message: string) {
    this.publisherService.add('WorkflowService: ' + message);
  }

  /**
   * Complete a task with the given action.
   * If passed a task decision, send decision along with the given action.
   * Else just send the action.
   * @param task Task to complete (required).
   * @param action Action to take on a task (required).
   * @param taskDecision Decision to pass (optional).
   */
  completeTaskWithAction(task: Task, action: string, taskDecision?: TaskDecision) {
    const url = this.taskUrl3.concat('/').concat(task.id);
    let body;
    if (taskDecision) {
      body = {
        action: action,
        variables : [ {
          name : taskDecision.name,
          value : taskDecision.value
        }]
      };
    } else {
      body = {
        action: action
      };
    }
    return this.http.post<Task>(url, body, httpOptions).pipe(
      tap(() => this.log(`Completed task with id: ${task.id}`)),
      catchError(this.handleError<Task>(`completeTask with id: ${task.id}`))
    );
  }

  completeTaskIdWithAction(taskId: string, action: string, taskDecision?: TaskDecision) {
    const url = this.taskUrl3.concat('/').concat(taskId);
    let body;
    if (taskDecision) {
      body = {
        action: action,
        variables : [ {
          name : taskDecision.name,
          value : taskDecision.value
        }]
      };
    } else {
      body = {
        action: action
      };
    }
    return this.http.post<Task>(url, body, httpOptions);
  }

  /**
   * Complete a task with the given action.
   * If passed a task decision, send decision along with the given action.
   * Else just send the action.
   * @param apNumber to set in the task (required).
   * @param task Task to complete (required).
   * @param action Action to take on a task (required).
   * @param taskDecision Decision to pass (optional).
   */
  completeTaskWithActionForPiaOnlyFlow(apNumber: number, task: Task, action: string, taskDecision?: TaskDecision) {
    const url = this.taskUrl3.concat('/').concat(task.id);
    let body;
    if (taskDecision) {
      body = {
        action: action,
        variables : [ {
          name : taskDecision.name,
          value : taskDecision.value
        }]
      };
    } else {
      body = {
        action: action
      };
    }
    return this.http.post<Task>(url, body, httpOptions).pipe(
      tap(() => this.log(`Completed task with id: ${task.id}`)),
      catchError(this.handleError<Task>(`completeTask with id: ${task.id}`))
    );
  }

  /**
   * Complete a task with the given action.
   * If passed a task decision, send decision along with the given action.
   * Else just send the action.
   * @param apNumber to set in the task (required).
   * @param taskId to complete (required).
   * @param action Action to take on a task (required).
   * @param taskDecision Decision to pass (optional).
   */
  completeTaskByTaskIdWithActionForPiaOnlyFlow(apNumber: number, taskId: string, action: string, taskDecision?: TaskDecision) {
    const url = this.taskUrl3.concat('/').concat(taskId);
    let body;
    if (taskDecision) {
      body = {
        action: action,
        variables : [ {
          name : taskDecision.name,
          value : taskDecision.value,
          apNumber : apNumber
        }]
      };
    } else {
      body = {
        action: action
      };
    }
    return this.http.post<Task>(url, body, httpOptions).pipe(
      tap(() => this.log(`Completed task with id: ${taskId}`)),
      catchError(this.handleError<Task>(`completeTask with id: ${taskId}`))
    );
  }

  /**
   * Create a process instance with the supplied process definition, and candidate id as a variable.
   *
   * @param candidateId Candidate id to set as a variable.
   * @param processDefinition Which process definition to use when creating a ProcessInstance.
   */
  createProcessInstanceWithCandidateId(candidateId: number, processDefinition: string): Observable<string> {
    const variables = {candidateId: candidateId, processDefinition: processDefinition};
    return this.http.post<string> (this.processInstanceCreateUrl, variables)
      .pipe(
        tap(() => this.log(`Created process instance: ${processDefinition}, with candidate id: ${candidateId}`)),
        catchError(
          this.handleError<string>(
            `createProcessInstanceWithCandidateId with process instance: ${processDefinition}, with candidate id: ${candidateId}`)));
  }

  /** GET all historic processes */
  getAllHistoricProcesses (): Observable<HistoricProcessInstance[]> {
    const endStates = [WorkflowService.PIA_APPROVED_STATE, WorkflowService.PIA_DECLINED_STATE];
    return this.getHistoricProcessesForEndStates(endStates);
  }

  /** GET approved historic processes */
  getApprovedHistoricProcesses (): Observable<HistoricProcessInstance[]> {
    const endStates = [WorkflowService.PIA_APPROVED_STATE];
    return this.getHistoricProcessesForEndStates(endStates);
  }

  /** GET declined historic processes */
  getDeclinedHistoricProcesses (): Observable<HistoricProcessInstance[]> {
    const endStates = [WorkflowService.PIA_DECLINED_STATE, WorkflowService.VENDOR_DECLINED_STATE];
    return this.getHistoricProcessesForEndStates(endStates);
  }

  /** GET historic processes for given end states */
  getHistoricProcessesForEndStates (endStates): Observable<HistoricProcessInstance[]> {
    return this.http.post<HistoricProcessInstance[]>(this.processHistoryUrl, endStates)
      .pipe(
        tap((tasks) => {
          this.log(`fetched historical processes`);
          if (tasks) {

            // remove old workflow items while concurrently traversing.
            tasks.slice().reverse().forEach(function(item, index, object) {
              if (item.processDefinitionId.includes('honeyBeansProcess')) {
                tasks.splice(object.length - 1 - index, 1);
              }
            });
          }
        }),
        catchError(this.handleError<HistoricProcessInstance[]>(`getHistoricProcesses`))
      );
  }

  getUnderReviewProcessesForVendor (): Observable<ProcessInstance[]> {
    return this.http.get<ProcessInstance[]>(this.vendorUnderReviewProcessesUrl).pipe(
      tap(() => this.log(`found under review processes for vendor`)),
      catchError(this.handleError<ProcessInstance[]>('getUnderReviewProcessesForVendor', []))
    );
  }

  /**
   * Add process variable to signify process has been viewed
   * @param processInstance
   * @param vendorViewed
   */
  addProcessVariableViewed(processInstance: string, vendorViewed: boolean = true) {
    const addVariableURL = this.processInstanceFlowableUrl + '/' + processInstance + '/variables';

    const parameters = {
      name: 'vendorViewed',
      type: 'boolean',
      value: vendorViewed
    };

    return this.http.put<String> (addVariableURL, parameters, httpOptions)
      .pipe(
        tap(() => this.log(`Added process variable: vendorViewed = ${vendorViewed}, for process instance ID: ${processInstance}`)),
        catchError(
          this.handleError<ProcessInstance>(
            `addProcessVariableViewed: vendorViewed = ${vendorViewed}, for process instance ID: ${processInstance}`)));
  }

  deleteProcessInstance(processInstance: string): Observable<any> {
    const deleteInstanceURL = this.processInstanceFlowableUrl + '/' + processInstance;
    return this.http.delete<String>(deleteInstanceURL).pipe(
      tap(() => this.log(`Deleting ProcessInstance = ${processInstance}`)),
      catchError(
        this.handleError<ProcessInstance>(
          `Deleting ProcessInstance  = ${processInstance}`)));
  }

  deleteHistoricInstance(historicalProcessInstance: string): Observable<any> {
    const deleteInstanceURL = this.historicalProcessInstanceUrl + '/' + historicalProcessInstance;
    return this.http.delete<String>(deleteInstanceURL).pipe(
      tap(() => this.log(`Deleting HistoricalProcessInstance = ${historicalProcessInstance}`)),
      catchError(
        this.handleError<ProcessInstance>(
          `Deleting HistoricalProcessInstance  = ${historicalProcessInstance}`)));
  }

  /**
   * Add process variable to signify process has been viewed
   * @param processInstance
   * @param vendorViewed
   */
  addHistoricalProcessVariableViewed(processInstance: string, vendorViewed: boolean = true) {
    const addVariableURL = this.historicalProcessInstanceFlowableUrl;

    const parameters = { 'vendorViewed' : vendorViewed, 'processInstance' : processInstance };

    return this.http.put<String> (addVariableURL, parameters, httpOptions)
      .pipe(
        tap(() => this.log(`Added historical process variable: vendorViewed = ${vendorViewed}, ` +
          `for process instance ID: ${processInstance}`)),
        catchError(
          this.handleError<ProcessInstance>(
            `addHistoricalProcessVariableViewed: vendorViewed = ${vendorViewed}, for process instance ID: ${processInstance}`)));
  }

  /**
   * @param apNumber
   * @param processId
   */
  updateApNumber(apNumber: number, processId: string) {
    const updateApNumberUrl = this.processInstanceFlowableUrl + '/' + processId + '/variables';
    const parameters = {
      'name': 'apNumber',
      'type': 'long',
      'value': apNumber};

    return this.http.put<String> (updateApNumberUrl, parameters, httpOptions)
      .pipe(
        tap(() => this.log(`Added variable to process = ${apNumber}, ` +
          `for process instance ID: ${processId}`)),
        catchError(
          this.handleError<ProcessInstance>(
            `updateApNumber: vendorApNumber = ${apNumber}, for process instance ID: ${processId}`)));
  }

  /**
   * @param apNumber
   * @param processId
   */
  updateCandidateId(candidateId: number, processId: string) {
    const updateApNumberUrl = this.processInstanceFlowableUrl + '/' + processId + '/variables';
    const parameters = {
      'name': 'candidateId',
      'type': 'long',
      'value': candidateId};

    return this.http.put<String> (updateApNumberUrl, parameters, httpOptions)
      .pipe(
        tap(() => this.log(`Added variable to process = ${candidateId}, ` +
          `for process instance ID: ${processId}`)),
        catchError(
          this.handleError<ProcessInstance>(
            `updateCandidateId: candidateId = ${candidateId}, for process instance ID: ${processId}`)));
  }

  /**
   * Retrieves workflow service's version .
   *
   * @returns {Observable<any>}
   */
  public getWorkflowServiceVersion(): Observable<any> {
    return this.http.get<any>(this.workflowServiceVersionBaseUrl + '/current').pipe(
      tap(version => this.log('workflow service version= ' + version)),
      catchError(this.handleError<any>('workflow versionEndpoint'))
    );
  }

  /**
   * Get all tasks (open and closed) for a given set of candidates.
   *
   * @param candidates to get tasks for
   */
  getAllTasksForCandidates (candidates: number[]): Observable<WorkFlowTask[]> {
    return this.http.post<WorkFlowTask[]>(this.allTasksForCandidates, candidates);
  }

  /**
   * Updates a completed NR task to rejected.
   * @param candidateId the candidate id.
   */
  updateNonReplenishableCompletedEndStateToRejected(candidateId): Observable<any> {
    const url = this.updateNonReplenishableCompletedEndStateToRejectedUrl + '?candidateId=' + candidateId;
    return this.http.post<any>(url, null);
  }

  /**
   * Finds a DSD Invited Supplier Tasks in Buyer phase that contains one of the candidate ids.
   * @param candidateIds candidate ids.
   */
  findDsdInvitedSupplierTaskInBuyer(candidateIds: string): Observable<Task[]> {
    return this.http.get<Task[]>(this.taskUrl2 + '/findDsdInvitedSupplierTasksInBuyer?candidateIds=' + candidateIds)
      .pipe(
        tap(tasks => this.log(`fetched tasks` + tasks)),
        catchError(this.handleError<Task[]>(`findDsdInvitedSupplierTaskInBuyer`))
      );
  }

  /**
   * Deletes the provided process instance, and creates a new task in the provided process definition flow for the candidate id.
   * If a newActivityId is provided, this will move the new task to the appropriate userTask in the flow.
   *
   * @param processInstanceIdToDelete the process instance id to be deleted.
   * @param candidateId the candidate id to associate to the new task.
   * @param processDefinition the process definition to create the new flow for (e.g. 'newProductDsdScaleProcess').
   * @param apNumber an optional ap number to associate the task with.
   * @param newUserActivityId an optional user activity id to move the task to in the new flow (e.g. 'buyerDetails').
   * @return the new task.
   */
  deleteProcessInstanceAndCreateNewFlow(processInstanceIdToDelete: string, candidateId: number, processDefinition: string, apNumber?: number,
                                        newUserActivityId?: string): Observable<Task> {
    const url = this.taskUrl2 + '/deleteProcessInstanceAndCreateNewFlow';

    const params = new HttpParams().append('processInstanceIdToDelete', processInstanceIdToDelete)
      .append('candidateId', '' + candidateId)
      .append('processDefinition', processDefinition)
      .append('apNumber', apNumber ? '' + apNumber : '')
      .append('newUserActivityId', newUserActivityId ? newUserActivityId : '');

    return this.http.post<Task>(url, null, {params: params});
  }

  /**
   * Creates a new for every process variable provided.
   * @param processVariables
   */
  createMultipleTasks(processVariables: ProcessVariables[]): Observable<any> {
    return this.http.post<any>(this.processInstanceMultiCreateUrl, processVariables, httpOptions);
  }

  /**
   * Updates states a new for every process variable provided.
   * @param processVariables
   */
  updateCandidateTasksStates(processVariables: ProcessVariables[]): Observable<any> {
    return this.http.post<any>(this.updateStatesUrl, processVariables, httpOptions);
  }

  /**
   *  Returns true if the  MRT Candidate Inner candidate ID is tied to NON REPLENISH task or historic task.
   *
   * @param candidateInnerId an MRT candidate inner candidate id.
   */
  getIsNonReplenishableMrtInnerCandidateId(candidateInnerId: number): Observable<boolean> {
    return this.http.get<boolean>(this.getIsNonReplenishableMrtInnerCandidateIdUrl + '/' + candidateInnerId);
  }
}
