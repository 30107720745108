import { BaseAttribute } from '../base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate, AttributeConfig } from 'pm-models';

export class InnerPackSelected implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Inner Packs',
    description:
      'Let us know if there are inner packs inside the master case.',
      isRequired: false,
      isDisabled: () => false,
      isReadOnly: () => false,
      defaultValue: true,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.innerPackSelected;

    componentInstance.change.subscribe(x => {
      candidate.innerPackSelected = x.checked;

      if (candidate.innerPackSelected === false) {
        candidate.innerPack = null;
        candidate.innerHeight = null;
        candidate.innerLength = null;
        candidate.innerWidth = null;
        candidate.innerWeight = null;
      }
    });
  }
  updateModel(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate) {
    if (candidate.innerPackSelected !== componentInstance.model) {
      componentInstance.model = candidate.innerPackSelected;
    }
  }
}
