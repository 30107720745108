import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {NoStoresMessageComponent} from './no-stores-message.component';


@NgModule({
  declarations: [NoStoresMessageComponent],
  exports: [NoStoresMessageComponent, AttributeDisplayModule],
  imports: [
    CommonModule, AttributeDisplayModule
  ]
})
export class NoStoresMessageModule { }
