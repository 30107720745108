<pm-editor *ngIf="candidate && isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pharm-new-product-flow-stepper *ngIf="candidate && !pharmProductService.isDsdOnly()"
                                      [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                      [stepIndex]="3"
                                      [isSellable]="candidateUtilService.isSellable(candidate)">
    </app-pharm-new-product-flow-stepper>
    <app-pharm-new-dsd-product-flow-stepper *ngIf="candidate && pharmProductService.isDsdOnly()"
                                          [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                          [stepIndex]="3"
                                          [isSellable]="candidateUtilService.isSellable(candidate)">
    </app-pharm-new-dsd-product-flow-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Tell us about this product.</pm-title>
  <pm-sub-title>UPC: {{candidate.candidateProducts[0].upc}}-{{candidate.candidateProducts[0].upcCheckDigit}}</pm-sub-title>
  <!-- Page Cards -->
  <!--
    We're using  an ng-container to not add additional
    DOM elements and only show the cards when the candidate is loaded
  -->
  <ng-container *ngIf="candidate">

    <pm-product-descriptions-card *ngIf="candidateUtilService.isSellable(candidate)"
                                  [(model)]="candidate.candidateProducts[0]"
                                  [permissions]="productDescriptionsCardPermissions"
                                  [errorModel]="candidateProductError">
    </pm-product-descriptions-card>

    <pm-merchandising-info-card [(candidateModel)]="candidate"
                                [(candidateProductModel)]="candidate.candidateProducts[0]"
                                [permissions]="merchandisingInfoCardPermissions"
                                [merchandiseTypes]="merchandiseTypes"
                                (taxableChange)="setVertexTaxCategoryModel()"
                                [candidateErrorModel]="candidateError"
                                [candidateProductErrorModel]="candidateProductError">
    </pm-merchandising-info-card>

    <pm-regulatory-card *ngIf="candidateUtilService.isSellable(candidate)"
                        [(model)]="candidate"
                        (taxableChange)="setVertexTaxCategoryModel()"
                        [permissions]="regulatoryCardPermissions"
                        [errorModel]="candidateError">
    </pm-regulatory-card>

    <pm-product-pricing-card *ngIf="candidateUtilService.isSellable(candidate)"
                             [(model)]="candidate"
                             [permissions]="productPricingCardPermissions"
                             [errorModel]="candidateError"
                             [onlyShowSecondInputSuggestedRetail]="true">
    </pm-product-pricing-card>

    <pm-product-packaging-card [(model)]="candidate"
                               [retailSizeLabel]="'Pack size'"
                               [permissions]="productPackagingCardPermissions"
                               [packageTypes]="packageTypes"
                               [unitOfMeasure]="unitsOfMeasures"
                               [errorModel]="candidateError">
    </pm-product-packaging-card>

    <pm-scale-information-card *ngIf="pharmProductService.isScaleProduct(pharmProductService.candidate)"
                               [(model)]="currentCandidateProduct.scaleInformation"
                               [permissions]="scaleInformationCardPermissions"
                               [errorModel]="candidateProductError.scaleInformation">
    </pm-scale-information-card>

    <pm-pharmacy-card
      [(model)]="candidate"
      [permissions]="pharmacyCardPermissions"
      [drugNameAbbreviations]="drugNameAbbreviations"
      [drugScheduleTypeCodes]="drugScheduleTypeCodes"
      [pseTypes]="pseTypes"
      (modelChange)="onPharmacyModelChange($event)"
      [errorModel]="candidateError">
    </pm-pharmacy-card>

    <pm-product-images-card [(model)]="candidate.candidateProducts[0]"
                            [permissions]="productImagesCardPermissions"
                            [errorModel]="candidateProductError">
    </pm-product-images-card>
    <pm-label-insights-image-card *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS-EDIT']"
                                  [(model)]="candidate.candidateProducts[0]"
                                  [permissions]="labelInsightsImagesCardPermissions"
                                  [errorModel]="candidateProductError">
    </pm-label-insights-image-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
