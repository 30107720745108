import {Injectable} from '@angular/core';
import {LookupService} from './lookup.service';
import {Candidate, EmailMessage, Vendor} from 'pm-models';
import {GrowlService} from '../growl/growl.service';
import {Observable, of} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {
  RequestNewMatAttributeFormModel
} from 'pm-components/lib/shared/request-new-mat-attribute-form/request-new-mat-attribute-form.component';
import {CandidateService} from './candidate.service';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private lookupService: LookupService, private growlService: GrowlService, public authService: AuthService,
              private candidateService: CandidateService) { }

  /**
   * Finds the email associated with the given vendor, then generates and sends email an email to them notifying they've been
   * requested to add a themselves add a distributor for the supplied product/upc.
   *
   * @param apNumber the vendor ap number.
   * @param productName the product name
   * @param upc the product upc.
   */
  async sendInvitedSupplierEmail(apNumber, productName, upc) {

    this.lookupService.findVendorEmailByApNumberAndVendorType(apNumber, Vendor.DSD_VENDOR_TYPE_CODE).subscribe((vendorEmail) => {
      if (!vendorEmail) {
        this.growlService.addError('Unable to find email for AP: ' + apNumber + '.');
      }

      const to = vendorEmail;
      const toName = '';
      const from = 'do-not-reply@heb.com';
      const fromName = '';
      const subject = 'PAM - DSD Store Authorizations';
      const body = 'You have been invited to submit store authorization for ' + productName + ', ' + upc +
        '. Please log in to PAM to submit your store authorizations.';

      const cc = '';
      const ccName = '';
      const email = new EmailMessage(to, toName, from, fromName, subject, body, cc, ccName);
      this.lookupService.sendEmail(email).subscribe();
    }, () => {
      this.growlService.addError('Unable to find email for AP: ' + apNumber + '.');
    });
  }

  sendRequestMatAttributeValue(candidate: Candidate, newMatAttributeFormModel: RequestNewMatAttributeFormModel): Observable<any> {

    const user = this.authService.getUser();

    let body = user + ' has requested a new MAT attribute value.' + '<br/>' + '<br/>';

    body += 'Attribute: ' + newMatAttributeFormModel.attributeBusinessFriendlyDescription + '[' + newMatAttributeFormModel.attributeFieldId + ']' + '<br/>';
    body += 'Attribute value: ' + newMatAttributeFormModel.newMatAttributeValue + '<br/>';
    body += 'H-E-B Vendor: ' + (!!newMatAttributeFormModel.apNumber ? newMatAttributeFormModel.apNumber : '—') + '<br/>';
    body += 'Additional details: ' + (!!newMatAttributeFormModel.notes ? newMatAttributeFormModel.notes : '—') + '<br/>';

    return this.getContactEmail(candidate).pipe(
      switchMap((fromEmail) => {
        const emailMessage = new EmailMessage('DataManagement@heb.com', '', fromEmail,
          '', 'PAM – New MAT attribute request', body, newMatAttributeFormModel.email, '');
        return this.lookupService.sendEmail(emailMessage);
      })
    );
  }

  getContactEmail(candidate: Candidate): Observable<string> {
    if (this.authService.isVendor()) {
      return of(candidate.contactEmail);
    } else {
      return this.candidateService.getCurrentPartnerEmail();
    }
  }


  public static isValidEmail(email: string): boolean {
    if (!email) {
      return false;
    }

    return !!email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
}
