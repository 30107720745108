import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminPreferences, AdminPreferencesType, AttributeConfig, Vendor} from 'pm-models';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';
import {PreferencesService} from '../../../../../../src/app/2.0.0/service/preferences.service';

@Component({
  selector: 'pm-upload-settings-configuration',
  templateUrl: './upload-settings-configuration.component.html',
  styleUrls: ['./upload-settings-configuration.component.scss']
})
export class UploadSettingsConfigurationComponent implements OnInit {

  private readonly CONFIGURE_UPLOAD_SETTINGS_TITLE_STRING = 'Configure upload settings';
  private readonly EDIT_UPLOAD_SETTINGS_TITLE_STRING = 'Edit upload settings';
  private readonly EDIT_UPLOAD_SETTINGS_SUB_TITLE_STRING = 'Changes will apply to this Supplier’s future uploads as well.';
  private readonly CONFIGURE_VENDOR_SUB_TITLE_BASE_STRING =
    ' hasn’t been set up for volume upload yet. You must select a channel before activating any uploaded candidates.';

  @Input()
  public vendor: Vendor;

  @Output()
  cancelClicked = new EventEmitter<void>();

  @Output()
  saveButtonClicked = new EventEmitter<AdminPreferences>();


  adminPreferences: AdminPreferences;
  hasPreviousPreferences: boolean;
  title: string;
  subTitle: string;


  channelConfiguration: AttributeConfig = {
    label: 'Channel',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => false,
    options: [
      {
        value: AdminPreferences.WAREHOUSE_FULFILLMENT_CHANNEL, label: 'Warehouse'
      },
      {
        value: AdminPreferences.DSD_FULFILLMENT_CHANNEL, label: 'DSD'
      }
    ],
  };

  constructor(private growlService: GrowlService, private preferencesService: PreferencesService) { }

  ngOnInit() {
    if (!this.vendor) {
      this.growlService.addError('A vendor must be provided to update preferences!');
      this.cancel();
      return;
    }
    this.preferencesService.getAdminPreferences(this.vendor.apNumber, AdminPreferencesType.VENDOR).subscribe((adminPreferences) => {
      this.adminPreferences = adminPreferences;
      this.setTitleAndSubTitle();
    });
  }

  /**
   * Sets the title and subtitle.
   */
  setTitleAndSubTitle() {
    if (this.adminPreferences) {
      this.hasPreviousPreferences = true;
      this.title = this.EDIT_UPLOAD_SETTINGS_TITLE_STRING;
      this.subTitle = this.EDIT_UPLOAD_SETTINGS_SUB_TITLE_STRING;
    } else {
      this.hasPreviousPreferences = false;
      this.adminPreferences = {};
      this.title = this.CONFIGURE_UPLOAD_SETTINGS_TITLE_STRING;
      this.subTitle = this.vendor.description + this.CONFIGURE_VENDOR_SUB_TITLE_BASE_STRING;
    }
  }

  cancel() {
    this.cancelClicked.emit();
  }

  saveClicked() {

    this.preferencesService.saveAdminPreferences(this.vendor.apNumber, AdminPreferencesType.VENDOR, this.adminPreferences)
      .subscribe((adminPreferences: AdminPreferences) => {
        this.saveButtonClicked.emit(adminPreferences);
      });
  }
}
