import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AttributeConfig,
  AttributeTextInputConfig, AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  MaxShipCardPermissions, TaskDecision, TextInputType, WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {AuthService} from '../../auth/auth.service';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {PharmProductService} from '../../service/pharm-product.service';
import {PiaProductService} from '../../service/pia-product.service';
import {WorkflowService} from '../../service/workflow.service';

@Component({
  selector: 'app-pharm-new-product-warehouses',
  templateUrl: './pharm-new-product-warehouses.component.html',
  styleUrls: ['./pharm-new-product-warehouses.component.scss']
})
export class PharmNewProductWarehousesComponent implements OnInit {

  public isViewingPage = true;
  private taskSubscription$: any;
  public candidate: Candidate;
  public currentCandidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public warehouses = [];
  public warehouseData = [];
  public canClickSubmit = true;
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public orderRestrictions: any[];

  private validators: CandidateValidatorType[] = [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR,
    CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR, CandidateValidatorType.PHARM_PRODUCT_DETAILS_VALIDATOR,
    CandidateValidatorType.PHARMACY_PRODUCT_CASE_DETAILS_VALIDATOR, CandidateValidatorType.SCA_PRODUCT_REVIEW_VALIDATOR];

  readonly maxShipCardPermissions: MaxShipCardPermissions = {
    isReadOnly: false
  };

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => true,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };


  constructor(private route: ActivatedRoute, private router: Router, private pharmProductService: PharmProductService,
              private authService: AuthService, private candidateService: CandidateService,
              private candidateUtilService: CandidateUtilService, private growlService: GrowlService,
              private lookupService: LookupService, private workflowService: WorkflowService) { }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      let taskId;
      if (this.pharmProductService.getTaskId()) {
        taskId = this.pharmProductService.getTaskId();
      } else if (this.pharmProductService.getTask() && this.pharmProductService.getTask().id) {
        taskId = this.pharmProductService.getTask().id;
      }
      if (params.keys.length > 0 && (!(taskId && params.has('taskId') && params['params']['taskId'] === taskId))) {
        this.pharmProductService.resetService();
        this.pharmProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else if (this.pharmProductService.getOriginalCandidate() && this.pharmProductService.getCandidate()) {
        this.initializeData();
      }  else {
        this.router.navigate(['/tasks']);
      }
    });
  }

  initializeData() {
    this.candidate = this.pharmProductService.getCandidate();
    this.originalCandidate = this.pharmProductService.getOriginalCandidate();
    this.currentCandidateProduct = this.pharmProductService.getCurrentCandidateProduct();
    this.candidateError = this.pharmProductService.getWarehouseError();
    this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
    this.getWarehouses(this.candidate);
    this.getOrderRestrictions();
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    if (this.currentCandidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.currentCandidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.currentCandidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({},
              this.currentCandidateProduct.warehouses[y], JSON.parse(JSON.stringify(this.currentCandidateProduct.warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  onClose() {
    this.currentCandidateProduct.warehouses = this.getSelectedWarehouses();
    this.isViewingPage = false;
    this.pharmProductService.saveCandidateAndClose();
  }

  onClickSubmit() {
    this.currentCandidateProduct.warehouses = this.getSelectedWarehouses();
    // validate the candidate for this page and send errors to components to display, if any
    this.canClickSubmit = false;
    this.candidateService.validateCandidate(this.candidate, this.validators).toPromise().then(data => {

      if (this.pharmProductService.getCandidate().showCalories &&
        !this.pharmProductService.isScaleProduct(this.pharmProductService.candidate)) {
        // just leveraging this WF because if follows needed flow
        this.saveAndCompletePharmTask();

      } else if (this.pharmProductService.isScaleProduct(this.pharmProductService.candidate)) {
        this.saveAndCompletePharmTask();
      } else {
        this.candidateService.activateCandidate(this.candidate).subscribe(() => {
          this.completeTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE,
            TaskDecision.PHARM_FINAL_REVIEW_APPROVE_DECISION,
            'Successfully activated candidate.'
          );
        }, (error) => {
          this.growlService.addError(error);
          this.canClickSubmit = true;
        });
      }
    }, (error) => {
      this.pharmProductService.scrollToTop();
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.pharmProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.pharmProductService.getWarehouseError();
        this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canClickSubmit = true;
    });
  }

  onClickBack() {
    this.currentCandidateProduct.warehouses = this.getSelectedWarehouses();
    this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductCaseDetails', false);
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.EACH_ID;
      this.orderUnitChange(this.EACH_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'T') {
        return this.orderRestrictions[x];
      }
    }
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.candidate.vendor.apNumber, this.pharmProductService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( data => {
          this.canClickSubmit = true;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.canClickSubmit = true;
      });
  }

  private saveAndCompletePharmTask() {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.pharmProductService.setCandidate(savedCandidate);
      this.candidate = savedCandidate;
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_SCALE_PLU_APPROVE_YES, 'Successfully completed task.');
    });
  }
}
