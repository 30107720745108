<pm-review #pmReview *ngIf="isViewingPage" [ngClass]="getReviewClass()" (closeEvent)="onClose()">
  <!-- Page Title -->
  <pm-title>{{supplierMrtService.candidate.description}}</pm-title>
  <pm-sub-title>{{getTitleSubHeading()}}</pm-sub-title>
  <pm-sub-title class="row" style="padding-left: 15px;">
    <button pButton type="button"
            [label]="'View candidate history'"
            class="ui-button-link" (click)="historyPanelOpen()">
    </button>
  </pm-sub-title>
  <pm-app-reject-status-header [candidate]="supplierMrtService.candidate"></pm-app-reject-status-header>
  <div *ngIf="supplierMrtService.candidate" class="row justify-content-md-center pb-3">
    <div class="col col-md-10 pb-5">

      <div *ngIf="hasPendingInner()" class="row pb-3 ml-0">
        <div class="col-md-9 pending-candidates-box">
          <h6 class="ml-2 mt-3">
            View only
          </h6>
          <p class="ml-2">
            This MRT contains at least 1 item that hasn’t been activated yet.
            You cannot approve this MRT until all of its items are activated.
            If you haven't already approved these new items, locate them in
            your Task View page and review them.
          </p>
          <div *ngIf="isShowingWhatHappensNext" class="ml-2 mt-2">
            <p>
              After you approve a new item, it goes to the SCA and Procurement Support teams for review.
              You can approve this MRT after Procurement Support activates any new items it contains.
            </p>
          </div>
          <button pButton type="button" label="{{getWhatHappensNextLabel()}}" class="ui-button-link ml-2 pb-3" (click)="toggleWhatHappensNext()"></button>
        </div>
      </div>

      <!--   MRT items & costs set by supplier   -->
      <pm-attribute-grid class="mrt-inner-info-grid-container" [title]="'MRT items & costs set by supplier'">
        <div *ngIf="isLoadingInners" style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingInners" [strokeWidth]="'2'" class="loading-indicator"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching product data...</h5>
        </div>
        <div *ngIf="!isLoadingInners">
          <ng-template ngFor let-inner [ngForOf]="mrtCandidateInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="false">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(inner).upcCheckDigit}}<br>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status === 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="pending">Pending</div>
                  <pm-grid-cell-info>
                    This item is new and requires a separate review
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status !== 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="text-capitalize">{{toLowerCase(inner?.candidate?.status)}}</div>
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).description}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(supplierMrtService.getCurrentCandidateProducts(inner).description) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="false">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  -
                </pm-grid-cell>

              </pm-grid-column>
              <hr>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
          <ng-template ngFor let-inner [ngForOf]="mrtExistingInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="false">
                  {{inner?.upc}}-{{inner?.upcCheckDigit}}<br>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  Active
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{inner?.product?.productDescription}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(inner?.product?.productDescription) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="false">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell *ngIf="!productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  {{productService.getItemCode(inner?.product)}}
                </pm-grid-cell>
                <pm-grid-cell *ngIf="productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  Multiple
                  <pm-grid-cell-info>
                    This item has multiple case configurations
                  </pm-grid-cell-info>
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
        </div>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'masterListCost')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(supplierMrtService.candidate?.masterListCost)}}
              <pm-grid-cell-info style="white-space: pre-line;">
                <div *ngIf="!isNotMissingCandidateInfo" >
                  {{getCostDisplay()}}
                </div>
                <div *ngIf="isNotMissingCandidateInfo">
                  Penny Profit: {{getPennyProfit()}}<br>
                  Margin: <a [ngClass]="{'grid-cell-error':isMarginNegativeOrZero()}">{{getMarginPercent()}}%</a>
                  <img *ngIf="isMarginNegativeOrZero()" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
                </div>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{supplierMrtService.candidate?.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!-- Case summary -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierMrtService.candidate.candidateProducts[0], 'caseDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.candidateProducts[0]?.caseDescription}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierMrtService.candidate?.candidateProducts[0], 'caseUpc')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.candidateProducts[0]?.caseUpc}}-{{supplierMrtService.candidate?.candidateProducts[0]?.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'buyer', 'buyerName')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [errorMessage]="candidateProductError?.vendorProductCode"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierMrtService.candidate?.candidateProducts[0], 'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.candidateProducts[0]?.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(supplierMrtService?.candidate)}}
            </pm-grid-cell>

          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierMrtService.candidate?.candidateProducts[0], 'countryOfOrigin', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.candidateProducts[0]?.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master Pack'" [canEdit]="false">
              1
            </pm-grid-cell>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
              {{getMasterPack()}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.masterLength}}in x
              {{supplierMrtService.candidate?.masterWidth}}in x
              {{supplierMrtService.candidate?.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(supplierMrtService.candidate?.masterLength, supplierMrtService.candidate?.masterWidth, supplierMrtService.candidate?.masterHeight, decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'vendorTie') || candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'vendorTier')"
                          (historyClicked)="historyPanelOpen()">
              {{+supplierMrtService.candidate?.vendorTie * +supplierMrtService.candidate?.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{supplierMrtService.candidate?.vendorTie}}
                Tier: {{supplierMrtService.candidate?.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false">
              {{candidateUtilService.getItemWeightType(supplierMrtService.candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(supplierMrtService.candidate?.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'cubeAdjustedFactor')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'maxShelfLife')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(supplierMrtService.candidate, supplierMrtService.candidate?.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="supplierMrtService.candidate.displayReadyUnit" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'displayReadyUnit')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(this.supplierMrtService.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'displayReadyUnitRowsDeep') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'displayReadyUnitRowsFacing') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'displayReadyUnitRowsHigh')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate?.displayReadyUnitRowsDeep * supplierMrtService.candidate?.displayReadyUnitRowsFacing * supplierMrtService.candidate?.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCostOwner(supplierMrtService.candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate, 'buyer', 'buyerName')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForTopToTop(supplierMrtService.candidate)"
                          (historyClicked)="historyPanelOpen()">
              <input type="text" pInputText value="{{supplierMrtService.candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierMrtService.candidate,'lane', 'name')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierMrtService.candidate.lane?.name}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
              {{supplierMrtService.candidate.vendor?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <div *ngIf="candidateUtilService.isInProgressCandidate(supplierMrtService.candidate)">
          <!--   Global Attributes   -->

          <pm-attribute-grid *ngIf="isLoadingMatData || !!supplierMrtService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierMrtService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierMrtService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierMrtService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
        <div *ngIf="!candidateUtilService.isInProgressCandidate(supplierMrtService.candidate)">

          <!--  Global Attributes    -->
          <pm-attribute-grid *ngIf="!!supplierMrtService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierMrtService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierMrtService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierMrtService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierMrtService.candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(supplierMrtService.candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="false">
              <div *ngIf="!!supplierMrtService.candidate?.supplierComment">
                <b>{{supplierMrtService.candidate?.contactEmail}}</b> <br>
                {{supplierMrtService.candidate?.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!supplierMrtService.candidate.buyerCommentUser">
                <b>{{supplierMrtService.candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!supplierMrtService.candidate.buyerComment">
                {{supplierMrtService.candidate.buyerComment}}
              </div>

              <div *ngIf="!!supplierMrtService.candidate.scaComment">
                <b>{{supplierMrtService.candidate.scaName}}</b> <br>
                {{supplierMrtService.candidate.scaComment}}
              </div>
              <br>

              <div *ngIf="!!supplierMrtService.candidate.piaComment">
                <b>{{supplierMrtService.candidate.piaCommentUser}}</b> <br>
                {{supplierMrtService.candidate.piaComment}}
              </div>
            </pm-comment-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="supplierMrtService.candidate?.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of supplierMrtService.candidate?.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <!--  Audit History -->
  <pm-review-drawer-container >
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="historyService.sortedAudits">
            <div *ngIf="historyService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{historyService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back to home" (onClick)="onClickBackToHome()" [disabled]="isResubmitting"></p-button>
    <p-button class="ghost m-2" label="Print" (onClick)="onClickPrint()" [disabled]="isResubmitting"></p-button>
    <p-button class="ghost m-2" label="Edit & Resubmit" *ngIf="isRejected()" (onClick)="resubmitCandidate()" [disabled]="isResubmitting"></p-button>
  </pm-footer>

</pm-review>

