<ng-container>
  <a *ngIf="!isSearchFormOpen" (click)="clickSupplierButton()" class="mt-2 badge badge-pill badge-light">Supplier
    <span *ngIf="checkedSupplierAps && checkedSupplierAps.length > 0" class="filterButton"> • {{checkedSupplierAps.length}}</span>
    <i class="ml-2 align-middle pi pi-chevron-down"></i>
  </a>

  <div *ngIf="isSearchFormOpen">
    <a (click)="clickSupplierButton()" class="mt-2 badge badge-pill badge-light">Supplier
      <i class="ml-2 align-middle pi pi-chevron-up"></i>
    </a>

    <!-- search box -->
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="input-group">
          <input class="form-control py-2 border-right-0 border-bottom-0 rounded-0 border" type="search" placeholder="Search..." [(ngModel)]="searchText">
          <span class="input-group-append">
            <div class="border-bottom-0 rounded-0 input-group-text bg-transparent"><i class="pi pi-search"></i></div>
          </span>
        </div>
      </div>
    </div>

    <!-- selection boxes -->
    <ng-container *ngIf="(suppliers | filterByName: searchText).length > 0; else noResults">
      <cdk-virtual-scroll-viewport class="search-results-viewport" itemSize="20">
        <div *cdkVirtualFor="let supplier of allSuppliers | filterByName: searchText; let i = index;" class="ml-1">
          <p *ngIf="i === 0" class="my-2" style="font-size: 12px">SUPPLIERS YOU FOLLOW</p>
          <p *ngIf="i === (followedSuppliers | filterByName: searchText).length"
             class="mt-4 mb-2" style="font-size: 12px">ALL SUPPLIERS</p>


          <p-checkbox [name]="supplier.displayName"
                      [id]="supplier.apNumber"
                      [label]="supplier.displayName"
                      binary="true"
                      [styleClass]="'ml-2 block'"
                      class="attribute-radio block"
                      [(ngModel)]="supplier.checked"
                      (onChange)="supplierChecked($event, supplier.apNumber)">
          </p-checkbox>
        </div>
        <p *ngIf="(allSuppliers | filterByName: searchText).length === (followedSuppliers | filterByName: searchText).length" class="mt-4 mb-2" style="font-size: 12px">ALL SUPPLIERS</p>
      </cdk-virtual-scroll-viewport>
    </ng-container>

    <!-- footer -->
    <div *ngIf="(suppliers | filterByName: searchText).length > 0" class="border">
      <div class="attribute-group">
        <div class="text-right">
          <p-button class="ghost m-2" label="Clear" (onClick)="onClickClearButton()"></p-button>
          <p-button class="m-2" label="Apply" (onClick)="onClickApplyButton()"></p-button>
        </div>
      </div>
    </div>

  </div>

  <ng-template #noResults>
    <div class="border">
      <div class="attribute-group">
        <p class="mx-2 my-2">No results found for "{{searchText}}".</p>
      </div>
    </div>
  </ng-template>
</ng-container>
