import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { File } from 'pm-models';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'pm-attachment-tile',
  templateUrl: './attachment-tile.component.html',
  styleUrls: ['./attachment-tile.component.scss']
})
export class AttachmentTileComponent implements OnInit {

  @Input() file: File;

  @Output() downloadCallback: EventEmitter<any> = new EventEmitter<any>();

  @Output() deleteCallback: EventEmitter<any> = new EventEmitter<any>();

  hasClicked: boolean;
  showDeleteButton: boolean;
  constructor() { }

  ngOnInit() {
    this.hasClicked = false;
    this.showDeleteButton = false;
  }

  showDelete() {
      this.showDeleteButton = true;
  }

  removeFile(event) {
    if (!this.hasClicked) {
      this.hasClicked = true;
      return;
    }
      this.deleteCallback.emit(event);
  }

  downloadFile(event) {
    this.downloadCallback.emit(event);
  }

  hideDelete() {
    this.hasClicked = false;
    this.showDeleteButton = false;
  }
}
