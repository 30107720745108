import {BaseAttribute} from 'pm-components/lib/attributes/attribute-type/base-attribute';
import {CandidateProduct} from 'pm-models';
import {
  AttributeDisplayPluComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-plu/attribute-display-plu.component';

export class Plu implements BaseAttribute {
  getAttributeConfig(overrides): any {
  }

  getComponentType(): any {
    return AttributeDisplayPluComponent;
  }

  setupComponent(componentInstance: AttributeDisplayPluComponent, candidateProduct: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    this.setupComponentCandidate(componentInstance, candidateProduct, configOverrides);
  }

  setupComponentCandidate(componentInstance: any, candidateProduct: CandidateProduct, configOverrides: any = {}): void {
    candidateProduct.keepPlu = true;
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidateProduct;
    componentInstance.pluRangeModelChange.subscribe((pluRange) => {
      if (configOverrides) {
          configOverrides.saveDisabled = !pluRange;
      }
    });
  }

  updateModel(componentInstance: any, candidateProduct: CandidateProduct): void {
    if (componentInstance.model !== candidateProduct) {
      componentInstance.model = candidateProduct;
    }
  }
}
