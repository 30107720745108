/**
 * Holds constants for DSD related columns.
 */
import {UploadCandidateCaseConstants} from './upload-candidate-case-constants';

export class UploadCandidateDsdConstants {

  // UPC Columns
  public static readonly EMPTY_COLUMN = {header: ' ', width: '50px', sticky: true};
  public static readonly UNIT_UPC = {header: 'Unit UPC', width: '150px', sticky: true};

  public static readonly INDEX_AND_UPC_COLUMN_GROUP: any[] = [
    UploadCandidateDsdConstants.EMPTY_COLUMN,
    UploadCandidateDsdConstants.UNIT_UPC
  ];


  public static readonly BUYER = {header: 'Buyer', width: '200px'};
  public static readonly SUPPLIER = {header: 'Supplier', width: '250px'};

  // Supplier and buyer column group
  public static readonly DSD_SUPPLIER_AND_BUYER_COLUMN_GROUP: any[] = [
    UploadCandidateDsdConstants.BUYER,
    UploadCandidateDsdConstants.SUPPLIER
  ];

  // Case Cost Column Group
  public static readonly DSD_CASE_AND_COST_COLUMN_GROUP: any[] = [
    UploadCandidateCaseConstants.MASTER_LIST_COST,
    UploadCandidateCaseConstants.UNIT_COST,
    UploadCandidateCaseConstants.HEB_MARGIN,
    UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
    UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS,
    UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE,
    UploadCandidateCaseConstants.CASE_DESCRIPTION,
    UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN,
  ];

  public static readonly TOTAL_STORES_COLUMN = {header: 'Total # of stores', width: '150px'};
  public static readonly TOTAL_STORES_COLUMN_GROUP = [UploadCandidateDsdConstants.TOTAL_STORES_COLUMN];

  public static readonly GROUP_NUMBER_COLUMN =  {header: 'Group #', width: '100px'};
  public static readonly NUMBER_OF_STORES_COLUMN = {header: '# of stores', width: '100px'};
  public static readonly STORE_LIST_COLUMN = {header: 'Stores', width: '650px'};

  // Location group columns
  public static readonly DSD_LOCATION_GROUP_COLUMNS = [
    UploadCandidateDsdConstants.GROUP_NUMBER_COLUMN,
    UploadCandidateDsdConstants.NUMBER_OF_STORES_COLUMN,
    UploadCandidateDsdConstants.STORE_LIST_COLUMN
  ];

  public static readonly BASE_DSD_COLUMNS = [
    UploadCandidateDsdConstants.EMPTY_COLUMN,
    UploadCandidateDsdConstants.UNIT_UPC,
    UploadCandidateDsdConstants.BUYER,
    UploadCandidateDsdConstants.SUPPLIER,
    UploadCandidateCaseConstants.MASTER_LIST_COST,
    UploadCandidateCaseConstants.UNIT_COST,
    UploadCandidateCaseConstants.HEB_MARGIN,
    UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
    UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS,
    UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE,
    UploadCandidateCaseConstants.CASE_DESCRIPTION,
    UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN,
    UploadCandidateDsdConstants.TOTAL_STORES_COLUMN
  ];
}

