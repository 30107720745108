import {Injectable} from '@angular/core';
import {
  Attribute,
  AttributeTypeaheadConfig,
  AttributeUPCConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  Product,
  SupplierDetailsCardPermissions,
  Task,
  TaskDecision,
  Upc
} from 'pm-models';
import {UUID} from 'angular2-uuid';
import {GrowlService} from '../growl/growl.service';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {Observable, of, throwError as observableThrowError} from 'rxjs';
import {CostService} from './cost.service';
import {WorkflowService} from './workflow.service';
import {CandidateService} from './candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from './lookup.service';
import {CandidateUtilService} from './candidate-util.service';
import {PiaBonusStepperComponent} from '../pia-new-bonus-upc-flow/pia-bonus-stepper/pia-bonus-stepper.component';
import {SupplierBonusStepperComponent} from '../supplier-bonus-size-flow/supplier-bonus-stepper/supplier-bonus-stepper.component';
import {ProductService} from './product.service';
import {MatUtilService} from './mat-util.service';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class BonusSizeService {
  DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';

  public productData: Product;
  public productImageUrl: string = null;
  public productInfoString: string;
  public primaryUpc: Upc;

  taskId = undefined;
  private task: Task;
  private isClosedTask = false;

  private NEW_PRODUCT_WORKFLOW = 'honeyBeansPIAProcess';
  private PIA_NEW_PRODUCT_WORKFLOW = 'piaNewProductProcess';

  VENDOR_TASK_NAME = 'Key Vendor Data';
  VENDOR_REVISION_TASK_NAME = 'Revise Vendor Data';

  originalCandidate: Candidate;
  currentCandidate: Candidate;

  searchedCandidateProductIndex = 0;
  currentCandidateProductIndex = 1;

  candidateError: CandidateError;
  candidateProductError: CandidateProductError;

  public departmentId = undefined;

  setupBonusSizeError: CandidateError = undefined;
  bonusDetailsError: CandidateError = undefined;
  caseDetailsError: CandidateError = undefined;
  warehouseError: CandidateError = undefined;
  extendedAttributesError: CandidateError = undefined;

  public isReplacementUpc: Boolean;
  public costOwners: any = [];
  supplierDetailsCardPermissions: SupplierDetailsCardPermissions;

  public hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  public hierarchyAttributes: Attribute[] = [];
  public globalAttributes: Attribute[] = [];
  public warehouseItemAttributes: Attribute[] = [];
  public upcAttributes: Attribute[] = [];

  getSupplierDetails(): SupplierDetailsCardPermissions {
    const permissions: SupplierDetailsCardPermissions = {
      isReadOnly: false,
      brand: {
        isDisabled: true
      },
      subBrand: {
        isHidden: true
      },
      vendor: {
        isHidden: true
      },
      lane: {
        isHidden: true
      }
    };
    return permissions;
  }

  get caseUPCConfiguration(): AttributeUPCConfig {
    return {
      label: this.getCaseUpcLabelText(),
      description: this.getCaseUpcDescriptionText(),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      placeholderText: this.getCaseUpcPlaceholderText(),
      checkDigitPlaceholderText: 'Check #',
      name: 'bonusUpcId'
    };
  }

  subBrandConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-brand',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'subBrandId',
    idRef: 'subBrandId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-brand...',
    searchUrl: '/lookup/subBrand'
  };

  constructor(private workflowService: WorkflowService, private candidateService: CandidateService, private route: ActivatedRoute,
              private router: Router, private growlService: GrowlService, private lookupService: LookupService,
              private costService: CostService, private candidateUtilService: CandidateUtilService,
              private productService: ProductService, private permissionService: NgxPermissionsService,
              public matUtilService: MatUtilService) {
    this.setupNewCandidate(Candidate.BONUS_SIZE, CandidateProduct.BONUS_SIZE);
  }

  /**
   * Resets to default values.
   */
  resetService() {
    this.taskId = undefined;
    this.task = undefined;
    this.isClosedTask = false;

    this.currentCandidate = undefined;
    this.originalCandidate = undefined;
    this.productImageUrl = null;
    this.productData = null;
    this.productInfoString = null;
    this.primaryUpc = null;
    this.departmentId = null;
    this.isReplacementUpc = null;
    this.costOwners = [];
    this.resetErrors();
    this.resetMatHierarchyFields();
  }

  public resetErrors() {
    this.setupBonusSizeError = new CandidateError();
    this.bonusDetailsError = new CandidateError();
    this.caseDetailsError = new CandidateError();
    this.warehouseError = new CandidateError();
    this.extendedAttributesError = new CandidateError();
  }

  public getCurrentCandidate() {
    return this.currentCandidate;
  }

  public getCurrentCandidateProduct(): CandidateProduct {
    return this.currentCandidate.candidateProducts[this.currentCandidateProductIndex];
  }

  public getSearchedCandidateProduct(): CandidateProduct {
    return this.currentCandidate.candidateProducts[this.searchedCandidateProductIndex];
  }

  public getOriginalCandidate() {
    return this.originalCandidate;
  }

  public getTask() {
    return this.task;
  }

  public setupNewCandidate(candidateType: string, candidateProductType: string) {
    this.currentCandidate = new Candidate();
    this.currentCandidate.warehouseSwitch = true;
    this.currentCandidate.productType = Candidate.SELLABLE;
    this.currentCandidate.candidateType = candidateType;
    this.currentCandidate.candidateProducts = [];
    this.currentCandidate.candidateProducts.push({id: UUID.UUID()});
    this.currentCandidate.candidateProducts.push({id: UUID.UUID(),
      candidateProductType: candidateProductType, upc: null, upcCheckDigit: null});
    this.originalCandidate = JSON.parse(JSON.stringify(this.currentCandidate));

    this.candidateError = new CandidateError();
    this.candidateProductError = new CandidateProductError();

    this.setIsReplacementUpc();
    this.resetErrors();
  }

  public updatePageErrors(candidateError) {
    this.setupBonusSizeError = JSON.parse(JSON.stringify(candidateError));
    this.bonusDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.caseDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.warehouseError = JSON.parse(JSON.stringify(candidateError));
    this.extendedAttributesError = JSON.parse(JSON.stringify(candidateError));
  }

  /**
   * Returns the current candidate Product error model.
   */
  public getCurrentCandidateProductError(candidateError: CandidateError): CandidateProductError {
    const tempCandidateProductError = candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id];
    if (!tempCandidateProductError) {
      // empty candidateProductErrors returns as object, so must override with a new map to prevent undefined .set() method error.
      if (!(candidateError.candidateProductErrors instanceof Map)) {
        candidateError.candidateProductErrors = new Map<string, CandidateProductError>();
      }
      const candidateProductError = new CandidateProductError();
      candidateError.candidateProductErrors.set(this.getCurrentCandidateProduct().id,
        candidateProductError);
      return candidateProductError;
    }
    return tempCandidateProductError;
  }

  /**
   * Sets and returns the original and current.
   * @param params
   */
  public setCandidateByUrlParameters(params): Observable<any> {
    if (params.has('taskId')) {
      return this.workflowService.getTaskByIdWithVariables(params['params']['taskId']).pipe(
        switchMap(
          (task) => {
            this.task = task;
            this.taskId = this.task.id;
            if (!(this.task.name === this.VENDOR_TASK_NAME || this.task.name === this.VENDOR_REVISION_TASK_NAME ||
              this.task.name === Task.PIA_NEW_PRODUCT_FLOW) ) {
              this.router.navigate(['/tasks'], {
                queryParams: {
                  growlMessage: 'Candidate is not in available for supplier revision.',
                  growlToUse: GrowlService.SEVERITY_ERROR
                }
              });
              return observableThrowError('Candidate is not in available for supplier revision.');
            }
            return this.getCandidateByCandidateId(task.candidateId);
          }
        ));
    } else if (params.has('candidateId')) {
      this.isClosedTask = true;
      const candidateId =  params['params']['candidateId'];
      this.candidateService.getCandidate(candidateId)
        .subscribe((candidate) => {
          this.setOriginalAndCurrentCandidate(candidate);
        });
    }
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = JSON.parse(JSON.stringify(candidate));
    this.currentCandidate = JSON.parse(JSON.stringify(candidate));
    this.setIsReplacementUpc();
  }

  /**
   * Returns a candidate by the candidate id.
   * @param candidateId
   */
  private getCandidateByCandidateId(candidateId): Observable<any> {
    return this.candidateService.getCandidate(candidateId).pipe(
      tap(
        (candidate) => {
          if (candidate.candidateType && candidate.candidateType === Candidate.BONUS_SIZE ||
            candidate.candidateType === Candidate.REPLACEMENT_UPC) {
            this.isReplacementUpc = candidate.candidateType === Candidate.REPLACEMENT_UPC;
            this.resetErrors();
          } else {
            const message = 'Invalid Candidate Task Flow : "' + candidate.candidateType + '" expected "' +
              Candidate.BONUS_SIZE + '" or " ' + Candidate.REPLACEMENT_UPC + '".';
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: message, growlToUse: GrowlService.SEVERITY_ERROR}
            });
            return observableThrowError(message);
          }
        }
      ),
      catchError(
        (error) => {
          // if there was an error retrieving task, route back to tasks page with the error
          this.router.navigate(['/tasks'], {
            queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
          });
          return observableThrowError(error);

        }),
      switchMap((candidate) => this.costService.updateCostLink(candidate)),
      tap((candidate) => {
        this.setOriginalAndCurrentCandidate(candidate);
      })
    );
  }

  /**
   * Set the candidate.
   * @param candidate
   */
  setCandidate(candidate: Candidate) {
    this.currentCandidate = candidate;
  }

  /**
   * Saves candidate and navigates to the url path provided.
   */
  saveCandidateAndNavigate(url: string, updateApNumber: boolean) {
    if (this.isEmptyOrSpaces(this.currentCandidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.currentCandidate)) {
        this.candidateService.saveCandidate(this.currentCandidate).subscribe(savedCandidate => {
          if (updateApNumber) {
            let apNumber;
            if (!this.currentCandidate.vendor) {
              apNumber = null;
            } else {
              apNumber = this.currentCandidate.vendor.apNumber;
            }
            this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).subscribe(() => {
              this.setOriginalAndCurrentCandidate(savedCandidate);
              this.router.navigate([url], {queryParams: {taskId: this.taskId}});
            });
          } else {
            this.setOriginalAndCurrentCandidate(savedCandidate);
            this.router.navigate([url], {queryParams: {taskId: this.taskId}});
          }
        });
      } else {
        this.router.navigate([url], {queryParams: {taskId: this.taskId}});
      }
    }
  }

  /**
   * Checks if a string is empty or just spaces
   */
  isEmptyOrSpaces(str) {
    return typeof str === 'undefined' || !str  || str.match(/^ *$/) !== null;
  }

  /**
   * Creates the candidate and navigates to the next page.
   * @param candidate
   */
  public createCandidateAndNavigate(candidate: Candidate, url: string, updateApNumber: boolean, piaOnly?: boolean) {
    this.candidateService.createNewCandidate(candidate).subscribe((newCandidate: Candidate) => {
      this.originalCandidate = newCandidate;
      this.currentCandidate = JSON.parse(JSON.stringify(newCandidate));
      this.resetErrors();
      let workflow;
      if (piaOnly) {
        workflow = this.PIA_NEW_PRODUCT_WORKFLOW;
      } else {
        workflow = this.NEW_PRODUCT_WORKFLOW;
      }
      this.createProcessInstanceWithCandidateId(newCandidate.candidateId, url, updateApNumber, workflow);    });
  }

  /**
   * Create process instance with newly created candidate id and routes to the next page.
   */
  private createProcessInstanceWithCandidateId(candidateId: number, url: string, updateApNumber: boolean, workflow: string) {
    this.workflowService.createProcessInstanceWithCandidateId(candidateId, workflow)
      .subscribe(taskId => {
        if (taskId) {
          this.taskId = taskId;
          this.workflowService.getTaskByIdWithVariables(taskId).subscribe((task) => {
            this.task = task;
            if (updateApNumber) {
              let apNumber;
              if (!this.currentCandidate.vendor) {
                apNumber = null;
              } else {
                apNumber = this.currentCandidate.vendor.apNumber;
              }
              this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).subscribe(() => {
                this.router.navigate([url], {queryParams: {taskId: taskId}});
              });
            } else {
              this.router.navigate([url], {queryParams: {taskId: taskId}});
            }
          });
        } else {
          this.growlService.addError('Problem creating process instance.');
        }
      });
  }

  clearProductData() {
    this.productData = null;
  }

  setProductData(): Observable<any> {
    if (this.productData) {
      return of({});
    }
    if (this.currentCandidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.currentCandidate.candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
        tap(productData => {
          this.productData = productData;
          this.currentCandidate.productId = productData.productId;
          this.currentCandidate.candidateProducts[this.currentCandidateProductIndex].description = productData.productDescription;
          this.setPrimaryUpc();
          this.setImage();
          this.setDepartmentAndCommodity();
          this.setCostOwners();
        }),
        switchMap(() => this.matUtilService.updateMatHierarchyFromProduct(this.currentCandidate.candidateProducts[this.currentCandidateProductIndex], this.productData))
      );
    } else if (this.currentCandidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.currentCandidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap(productData => {
          this.productData = productData;
          this.currentCandidate.candidateProducts[this.currentCandidateProductIndex].description = productData.productDescription;
          this.currentCandidate.candidateProducts[this.searchedCandidateProductIndex].upc = productData.primaryScanCodeId;
          this.setPrimaryUpc();
          this.setImage();
          this.setDepartmentAndCommodity();
          this.setCostOwners();
        }),
        switchMap(() => this.matUtilService.updateMatHierarchyFromProduct(this.currentCandidate.candidateProducts[this.currentCandidateProductIndex], this.productData))
      );
    }
    return of({});
  }

  /**
   * Sets the cost owners based on the BrandId from the parent product.
   */
  public setCostOwners() {
    this.currentCandidate.brand = {brandId: this.productData.brand.brandId,
      displayName: this.productData.brand.description + '[' + this.productData.brand.brandId + ']',
      description: this.productData.brand.description};
    this.lookupService.findBrandsById(
      [this.productData.brand.brandId]).subscribe(brandList => {
      if (brandList && brandList.length > 0) {
        this.costOwners = brandList[0].costOwners;
        this.supplierDetailsCardPermissions = this.getSupplierDetails();
      }
    });
  }

  setRetail() {
    this.currentCandidate.suggestedRetailPrice = this.primaryUpc.retailPrice;
    this.currentCandidate.suggestedXFor = 1;
  }

  setDepartmentAndCommodity() {
    if (this.productData.items && this.productData.items.length > 0) {

      for (let x = 0; x < this.productData.items.length; x++) {
        if (this.productData.items[x].hierarchy &&
          !this.productData.items[x].mrt &&
          this.productData.items[x].hierarchy.commodity &&
          this.productData.items[x].hierarchy.commodity.itemClass &&
          this.productData.items[x].hierarchy.commodity.itemClass.subDepartment &&
          this.productData.items[x].hierarchy.commodity.itemClass.subDepartment.department) {
          this.departmentId = +this.productData.items[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId;
          this.currentCandidate.commodity = { commodityId: this.productData.items[x].hierarchy.commodity.commodityId,
            commodityName: this.productData.items[x].hierarchy.commodity.description,
            departmentId: this.productData.items[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId};
          this.currentCandidate.buyer = { buyerId: this.productData.items[x].hierarchy.commodity.bdm.bdmId,
            buyerName: this.productData.items[x].hierarchy.commodity.bdm.fullName,
            userId: this.productData.items[x].hierarchy.commodity.bdm.onePassId};
          break;
        }
      }
    } else {
      for (let x = 0; x < this.productData.dsdItems.length; x++) {
        if (this.productData.dsdItems[x].hierarchy &&
          !this.productData.dsdItems[x].mrt &&
          this.productData.dsdItems[x].hierarchy.commodity &&
          this.productData.dsdItems[x].hierarchy.commodity.itemClass &&
          this.productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment &&
          this.productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department) {
          this.departmentId = +this.productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId;
          this.currentCandidate.commodity = { commodityId: this.productData.dsdItems[x].hierarchy.commodity.commodityId,
            commodityName: this.productData.dsdItems[x].hierarchy.commodity.description,
            departmentId: this.productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId
          };
          this.currentCandidate.buyer = { buyerId: this.productData.dsdItems[x].hierarchy.commodity.bdm.bdmId,
            buyerName: this.productData.dsdItems[x].hierarchy.commodity.bdm.fullName,
            userId: this.productData.dsdItems[x].hierarchy.commodity.bdm.onePassId};
          break;
        }
      }
    }
  }

  setPrimaryUpc() {
    if (this.productData.items && this.productData.items.length > 0) {
      const primaryUpcCode = this.productData.primaryScanCodeId;
      this.setPrimaryUpcAndRetailFromItem(primaryUpcCode);
    } else {
      this.setPrimaryUpcAndRetailFromDsdItem();
    }
  }


  setPrimaryUpcAndRetailFromDsdItem() {
    this.productService.getUpcBySearchedValue(this.getCandidate().candidateProducts[0],
      this.productData).subscribe(
      (upc) => {
        this.primaryUpc = upc;
        this.setProductInfoString();
        this.setRetail();
      });
  }

  setPrimaryUpcAndRetailFromItem(primaryUpcCode) {
    // find upc equal to that of the primary, if not found, set first upc of first item.
    for (let x = 0; x < this.productData.items.length; x++) {
      if (this.productData.items[x].innerUpcs && this.productData.items[x].innerUpcs.length > 0) {
        for (let y = 0; y < this.productData.items[x].innerUpcs.length; y++) {
          if (this.productData.items[x].innerUpcs[y].upc.scanCodeId === primaryUpcCode) {
            this.primaryUpc = this.productData.items[x].innerUpcs[y].upc;
            this.setProductInfoString();
            this.setRetail();
            return;
          }
        }
      } else if (this.productData.items[x].containedUpc && this.productData.items[x].containedUpc.upc &&
        this.productData.items[x].containedUpc.upc.scanCodeId === primaryUpcCode) {
        this.primaryUpc = this.productData.items[x].containedUpc.upc;
        this.setRetail();
        this.setProductInfoString();
        return;
      }
    }
    if (this.productData.items[0].containedUpc) {
      this.primaryUpc = this.productData.items[0].containedUpc.upc;
    } else {
      this.primaryUpc = this.productData.items[0].innerUpcs[0].upc;
    }
    this.setRetail();
    this.setProductInfoString();
  }

  setProductInfoString() {
    this.productInfoString = '' + this.primaryUpc.size +
      ' | Retail: '  +  this.costService.toCurrency(this.primaryUpc.retailPrice);
  }

  setImage() {
    this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
  }

  public getCandidate() {
    return this.currentCandidate;
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param growlMessage Message to display after routing to task page.
   */
  public saveAndCompleteTaskAndRouteToUrl(action: string, taskDecision: TaskDecision, url: string, growlMessage: string) {
    this.candidateService.saveCandidate(this.currentCandidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, url, growlMessage);
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user to URL with candidate ID
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param candidateID to include in query params of URL.
   */
  public saveAndCompleteTaskAndRouteToUrlWithCandidateId(action: string, taskDecision: TaskDecision, url: string, candidateID: number) {
    this.candidateService.saveCandidate(this.currentCandidate, true).subscribe(() => {
      this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
        .subscribe(() => {
          this.router.navigate([url], {queryParams: {candidateId : candidateID}}).then();
        });
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param growlMessage Message to display after routing to task page.
   */
  public completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, url: string, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate([url], { queryParams: { growlMessage: growlMessage } });
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  getAllCandidateErrors(): CandidateError[] {
    return [this.setupBonusSizeError, this.bonusDetailsError, this.caseDetailsError, this.warehouseError, this.extendedAttributesError];
  }

  scrollToTop() {
    document.getElementsByClassName('pm-editor-header')[0].scrollIntoView();
  }

  public getWarehouseError(): CandidateError {
    return this.warehouseError;
  }

  public setWarehouseError(candidateError): CandidateError {
    this.warehouseError = candidateError;
    return this.warehouseError;
  }

  /**
   * Saves candidate.
   */
  saveCandidateAndClose() {
    if (this.candidateUtilService.isEmptyOrSpaces(this.currentCandidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.currentCandidate)) {
        this.candidateService.saveCandidate(this.currentCandidate).subscribe(savedCandidate => {
          this.router.navigate(['/tasks']);
        });
      } else {
        this.router.navigate(['/tasks']);
      }
    }
  }

  setIsReplacementUpc() {
    if (this.currentCandidate.candidateType === Candidate.REPLACEMENT_UPC) {
      this.isReplacementUpc = true;
    } else {
      this.isReplacementUpc = false;
    }
  }

  /**
   * Returns the url for the supplier setup page.
   */
  getSupplierSetupUrl() {
    return this.isReplacementUpc ? '/setupReplacementUpc' : '/setupBonusSize';
  }

  /**
   * Returns the url for the supplier upc details page.
   */
  getSupplierUpcDetailsUrl() {
    return this.isReplacementUpc ? '/replacementDetails' : '/bonusDetails';
  }

  /**
   * Returns the url for the pia setup upc url page.
   */
  getPiaSetupUpcUrl() {
    return this.isReplacementUpc ? '/piaSetupReplacementUpc' : '/piaSetupBonusSizeComponent';
  }

  /**
   * Returns the url for the pia upc details page.
   */
  getPiaUpcDetailsUrl() {
    return this.isReplacementUpc ? '/piaReplacementDetails' : '/piaBonusDetailsComponent';
  }

  /**
   * Returns the url for the pia case details page.
   */
  getPiaCaseDetailsUrl() {
    return this.isReplacementUpc ? '/piaReplacementCaseDetails' : '/piaBonusCaseDetailsComponent';
  }

  /**
   * Returns the url for the pia case details page.
   */
  getPiaExtendedAttributesUrl() {
    return this.isReplacementUpc ? 'piaReplacementUpcExtendedAttributes' : 'piaBonusExtendedAttributes';
  }

  /**
   * Returns the url for the pia warehouse url.
   */
  getPiaWarehouseUrl() {
    return this.isReplacementUpc ? '/piaReplacementWarehouse' : '/piaBonusWarehouseComponent';
  }

  /**
   * Returns the basic sub title.
   */
  getBasicSubTitle() {
    if (this.isReplacementUpc) {
      return 'Provide the basic details about this replacement product.';
    } else {
      return 'Provide the basic details about this bonus.';
    }
  }

  /**
   * Returns the case upc label.
   */
  getCaseUpcLabelText() {
    if (this.isReplacementUpc) {
      return  'Unit UPC';
    } else {
      return 'Bonus Unit UPC';
    }
  }

  /**
   * Returns the case upc description text.
   */
  getCaseUpcDescriptionText() {
    if (this.isReplacementUpc) {
      return  'Enter the UPC and check digit that appears on the replacement product packaging.';
    } else {
      return 'Enter the UPC and check digit that appears on the bonus packaging.';
    }
  }

  /**
   * The case upc placeholder.
   */
  getCaseUpcPlaceholderText() {
    if (this.isReplacementUpc) {
      return  'Enter replacement UPC...';
    } else {
      return 'Bonus UPC';
    }
  }

  /**
   * The case details page title.
   */
  getBonusDetailsPageTitle() {
    if (this.isReplacementUpc) {
      return 'Replacement product details';
    } else {
      return  'Tell us about this bonus.';
    }
  }

  /**
   * The case details page title.
   */
  getCaseDetailsPageTitle() {
    if (this.isReplacementUpc) {
      return 'Case pack';
    } else {
      return  'Create a new case for this bonus.';
    }
  }

  getPiaStepperSteps() {
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return [{text: PiaBonusStepperComponent.UPC_ITEM_CODE}, {text: this.setPiaDetailPageLabelText()},
        {text: PiaBonusStepperComponent.CASE_DETAILS}, {text: PiaBonusStepperComponent.WAREHOUSE_SELECTION},
        {text: PiaBonusStepperComponent.EXTENDED_ATTRIBUTES}];
    } else {
      return [{text: PiaBonusStepperComponent.UPC_ITEM_CODE}, {text: this.setPiaDetailPageLabelText()},
        {text: PiaBonusStepperComponent.CASE_DETAILS}, {text: PiaBonusStepperComponent.WAREHOUSE_SELECTION}];
    }
  }

  getSupplierStepperSteps() {
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return [{text: SupplierBonusStepperComponent.UPC_ITEM_CODE}, {text: this.setSupplierDetailPageLabelText()},
        {text: SupplierBonusStepperComponent.CASE_DETAILS}, {text: SupplierBonusStepperComponent.EXTENDED_ATTRIBUTES}];
    } else {
      return [{text: SupplierBonusStepperComponent.UPC_ITEM_CODE}, {text: this.setSupplierDetailPageLabelText()},
        {text: SupplierBonusStepperComponent.CASE_DETAILS}];
    }
  }

  /**
   * Sets the pia detail page stepper label.
   */
  setPiaDetailPageLabelText() {
    if (!this.isReplacementUpc) {
      return PiaBonusStepperComponent.BONUS_DETAILS;
    } else {
      return PiaBonusStepperComponent.REPLACEMENT_DETAILS;
    }
  }

  /**
   * Sets the suppleir detail page stepper label.
   */
  setSupplierDetailPageLabelText() {
    if (!this.isReplacementUpc) {
      return SupplierBonusStepperComponent.BONUS_DETAILS;
    } else {
      return SupplierBonusStepperComponent.REPLACEMENT_DETAILS;
    }
  }

  resetMatHierarchyFields() {
    this.globalAttributes = [];
    this.hierarchyAttributes = [];
    this.warehouseItemAttributes = [];
    this.upcAttributes = [];
    this.hierarchyNumberToAttributesMap = new Map();
  }
}
