import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentsCardComponent } from './attachments-card.component';

import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import { CardModule } from '../../shared/card/card.module';

@NgModule({
  declarations: [AttachmentsCardComponent],
  imports: [CommonModule, AttributeDisplayModule, CardModule],
  exports: [AttachmentsCardComponent, AttributeDisplayModule, CardModule]
})
export class AttachmentsCardModule {}
