<pm-editor-base *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-title class="setup-candidate-title">What are you setting up today?</pm-title>

  <div class="row justify-content-sm-center mb-5">

    <div *ngxPermissionsOnly="['ROLE_PRODUCT_TYPE_BASIC-EDIT']" class="col col-sm-6  col-md-4 mt-4">
      <div [ngClass]="'image-card p-3' " id="imageCard1" (click)="onClickNewProduct()">
        <img class="img-fluid" src="../../../assets/images/item-setup-new-products.png">
        <div class="mt-2">
          <h2>
            New Product
          </h2>
          <p>
            Set up a brand new product.
          </p>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['ROLE_PRODUCT_TYPE_FREIGHT-EDIT']" class="col col-sm-6  col-md-4 mt-4">
      <div [ngClass]="'image-card p-3 mt'" id="imageCard6" (click)="onClickNewFreightTaxUpc()">
        <img class="img-fluid" src="../../../assets/images/freight-tax-upc-tile.png">

        <div class="mt-2">
          <h2>
            Freight/Tax UPC
          </h2>
          <p>
            Create a freight or tax UPC for a supplier.
          </p>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['ROLE_PRODUCT_TYPE_BONUS_SIZE-EDIT']" class="col col-sm-6 col-md-4 mt-4">
      <div [ngClass]="'image-card p-3'" id="imageCard3" (click)="onClickBonusSize()">
        <img class="img-fluid" src="../../../assets/images/item-setup-bonus-size.png">

        <div class="mt-2">
          <h2>
            Bonus
          </h2>
          <p>
            Set up a bonus size of an existing product.
          </p>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['ROLE_PRODUCT_TYPE_CASE_PACK-EDIT']" class="col col-sm-6  col-md-4 mt-4">
      <div [ngClass]="'image-card p-3'" id="imageCard2" (click)="onClickCasePack()">
        <img class="img-fluid" src="../../../assets/images/item-setup-case-pack.png">

        <div class="mt-2">
          <h2>
            Case Pack
          </h2>
          <p>
            Create a new case of an existing product.
          </p>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['ROLE_PD_TYPE_ASSOCIATE_UPC-EDIT']" class="col col-sm-6 col-md-4 mt-4">
      <div [ngClass]="'image-card p-3'" id="imageCard4" (click)="onClickAssociateUpc()">
        <img class="img-fluid" src="../../../assets/images/associate-upc.png">

        <div class="mt-2">
          <h2>
            Associate UPC
          </h2>
          <p>
            Add a related UPC to an existing case.
          </p>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['ROLE_PRODUCT_TYPE_MRT-EDIT']" class="col col-sm-6 col-md-4 mt-4">
      <div [ngClass]="'image-card p-3'" id="imageCard5" (click)="onClickMrt()">
        <img class="img-fluid" src="../../../assets/images/item-setup-mrt.png">

        <div class="mt-2">
          <h2>
            MRT
          </h2>
          <p>
            Set up a pallet containing multiple products.
          </p>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['ROLE_REPLACEMENT_UPC-EDIT']" class="col col-sm-6 col-md-4 mt-4">
      <div [ngClass]="'image-card p-3'" id="replacementItemCardId" (click)="onClickReplacementUpc()">
        <img class="img-fluid" src="../../../assets/images/replacement-upc.png">

        <div class="mt-2">
          <h2>
            Replacement UPC
          </h2>
          <p>
            Set up a product that can replace or swap for an existing product.
          </p>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['ROLE_INVITE_DISTRIBUTOR-EDIT']" class="col col-sm-6 col-md-4 mt-4">
      <div [ngClass]="'image-card p-3'" id="inviteDistributorCardId" (click)="onClickAddDistributor()">
        <img class="img-fluid" src="../../../assets/images/dsd-store-auth.png">

        <div class="mt-2">
          <h2>
            Authorize a Supplier (DSD Only)
          </h2>
          <p>
            Authorize store delivery for an existing UPC.
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="authService.isPia()" class="col col-sm-6  col-md-4 mt-4">
      <div [ngClass]="'image-card p-3' " id="imageCard7" (click)="onClickVolumeUpload()">
        <img class="img-fluid" src="../../../assets/images/volume-upload.png">
        <div class="mt-2">
          <h2>
            Upload
          </h2>
          <p>
            Upload a spreadsheet of new products.
          </p>
        </div>
      </div>
    </div>
  </div>
</pm-editor-base>
