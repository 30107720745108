<div class="attachment-tile inline-block" (mouseleave)="hideDelete()">
  <label class="file-name-doc" >{{file?.name}}</label>
  <label class="row" [pTooltip]="file?.name">
    <img class="download-icon" src="/assets/images/download-24px-white.svg" (click)="downloadFile(file)">
    <img class="remove-icon" src="/assets/images/remove-24px-white.svg" (click)="showDelete()" *ngIf="!showDeleteButton">
    <button class="ui-button-small ui-button-confirm" pButton
          type="button" label="Delete" (click)="removeFile(file)" *ngIf="showDeleteButton" [disabled]="!showDeleteButton"></button>
  </label>

</div>
