import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-dropdown',
  templateUrl: './attribute-display-dropdown.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayDropdownComponent extends BaseAttributeDisplayComponent implements OnInit {

  _model;
  @Input()
  get model() {
    return this._model;
  }
  set model(val) {
    if ((val === undefined || val === null) && this.attribute && this.attribute.defaultValue !== undefined) {
      val = this.attribute.defaultValue;
    }
    this._model = val;
    this.modelChange.emit(this._model);
  }

  ngOnInit() {
  }

  isReadOnly() {
    return (this.attribute.isReadOnly && this.attribute.isReadOnly() === true);
  }

  isDisabled() {
    return (this.attribute.isDisabled && this.attribute.isDisabled() === true);
  }

  handleChange(event) {
    this.change.emit(event);
  }

}
