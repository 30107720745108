import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AttributeConfig,
  Candidate,
  CasePackBasicsCardModel,
  CasePackBasicsCardPermissions,
  AttributeTextInputConfig,
  TextInputType, CandidateError
} from 'pm-models';
import { DisabledPermissionsMapper, ReadOnlyPermissionsMapper, VisiblePermissionsMapper } from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-case-pack-basics-card',
  templateUrl: './case-pack-basics-card.component.html',
  styleUrls: ['./case-pack-basics-card.component.scss']
})
export class CasePackBasicsCardComponent implements OnInit {
  @Input()
  model: CasePackBasicsCardModel | Candidate;

  @Input()
  permissions: CasePackBasicsCardPermissions;

  @Input()
  errorModel: CandidateError;

  @Output() modelChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  weightConfiguration: AttributeConfig = {
    label: 'Weight',
    description: 'Weight can impact how H-E-B pays for this case.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.itemWeightType),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.itemWeightType),
    isHidden: () => VisiblePermissionsMapper(this.permissions,  this.permissions.itemWeightType),
    isRequired: true,
    options: [
      { label: 'Fixed Weight', value: 'None' },
      { label: 'Catch Weight', value: 'Catch Weight' },
      { label: 'Variable Weight', value: 'Variable Weight' }
    ],
    defaultValue: 'None'
  };

  weightChange(event) {
    this.model.itemWeightType = event;
    this.modelChange.emit(this.model);
  }

  codeDateConfiguration: AttributeConfig = {
    label: 'Code date',
    description: 'Let us know if this pack has an expiration date.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.codeDate),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.codeDate),
    isRequired: false,
    defaultValue: false,
    name: 'codeDateId'
  };

  codeDateChange(e) {
    this.model.codeDate = e.checked;
    if (this.model.codeDate === true) {
      this.model.maxShelfLife = null;
      this.model.inboundSpecDays = null;
    }
    this.modelChange.emit(this.model);
  }

  maxShelfLifeConfiguration: AttributeTextInputConfig = {
    label: 'Max shelf life',
    description: 'The maximum numbers of days this product is shelf-stable.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.maxShelfLife),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.maxShelfLife),
    isHidden: () => !this.model.codeDate,
    placeholderText: '# of days',
    name: 'maxShelfLifeId',
    isRequired: true,
    textInputType: TextInputType.integer
  };

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'The number of days between the product\'s arrival date to the warehouse and the product\'s ' +
      'expiration date that H-E-B is guaranteed to sell through the product.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.inboundSpecDays),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.inboundSpecDays),
    isHidden: () => !this.model.codeDate,
    placeholderText: '# of days',
    isRequired: false,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };
}
