import {AttributeTextInputConfig, ScaleInformation} from 'pm-models';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayTextinputComponent} from '../../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';
import {AttributeDisplayUpcComponent} from '../../../shared/attribute-display/attribute-display-upc/attribute-display-upc.component';
import {BaseAttribute} from '../base-attribute';

export class SpanishLabelTwo implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Spanish label 2 ',
      description: `Enter a description for the second Spanish line of the scale printout.`,
      placeholderText: 'Line 2 Spanish description...',
      isHidden: () => false,
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: false,
      name: 'englishLabelDescriptionTwoBilingual',
      charLimit: 32
    };
  }

  setupComponent(componentInstance: AttributeDisplayUpcComponent, scaleInformation: ScaleInformation, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = scaleInformation.spanishLabelTwo;
    componentInstance.modelChange.subscribe(x => {
      scaleInformation.spanishLabelTwo = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayUpcComponent, scaleInformation: ScaleInformation) {
    if (componentInstance.model !== scaleInformation.spanishLabelTwo) {
      componentInstance.model = scaleInformation.spanishLabelTwo;
    }
  }
}
