import {Component, Input, OnInit} from '@angular/core';
import {
  BaseAttributeDisplayComponent
} from 'pm-components/lib/shared/attribute-display/base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-radio-boolean',
  templateUrl: './attribute-display-radio-boolean.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-radio-boolean.component.scss'],
})
export class AttributeDisplayRadioBooleanComponent extends BaseAttributeDisplayComponent implements OnInit {

  _stringModel;

  options = [
    {
      value: 'true',
      label: 'Yes',
      disabled: () => false
    },
    {
      value: 'false',
      label: 'No',
      disabled: () => false
    },
    {
      value: 'undefined',
      label: 'Undefined',
      disabled: () => false,
      hidden: () => true
    }
  ];

  @Input()
  model;

  get stringModel() {
    return this._stringModel;
  }

  set stringModel(val) {
    if (val !== undefined) {
      this._stringModel = '' + val;
    }
  }

  constructor() {
    super();
  }


  isOptionDisabled(option) {
    return (this.attribute.isReadOnly && this.attribute.isReadOnly()) ||
      (this.attribute.isDisabled && this.attribute.isDisabled()) ||
      (option.disabled && option.disabled());
  }

  isOptionHidden(option) {
    return (this.attribute.isHidden && this.attribute.isHidden()) ||
      (option.hidden && option.hidden());
  }

  ngOnInit() {
    this.stringModel = this.model;
  }

  onChange(event) {
    if (event === 'true') {
      this.model = true;
    } else if (event === 'false') {
      this.model = false;
    } else if (event === 'null') {
      this.model = null;
    } else {
      this.model = undefined;
    }
    this.modelChange.emit(this.model);
    this.change.emit(this.model);
  }
}
