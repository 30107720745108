import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  AfterContentInit,
  DoCheck,
  OnDestroy,
  ContentChildren,
  ContentChild,
  ViewChild,
  QueryList,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Optional,
  NgZone,
  ChangeDetectorRef,
  Inject,
  forwardRef
} from '@angular/core';

import { MTR_DRAWER_DEFAULT_AUTOSIZE } from './drawer-common';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { takeUntil, startWith, debounceTime, filter, take } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';

import { throwMtrDuplicatedDrawerError } from './drawer-errors';
import { DrawerComponent } from './drawer.component';
import { Subject } from 'rxjs';
import { untilDestroyed } from '../common/takeUntilDestroyed';
import { coerceBooleanProperty } from '../common/coerceBooleanProperty';

@Component({
  selector: 'pm-drawer-content',
  template: '<ng-content></ng-content>',
  host: {
    class: 'mtr-drawer__content',
    '[style.margin-left.px]': 'container.contentMargins.left',
    '[style.margin-right.px]': 'container.contentMargins.right'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerContentComponent implements AfterContentInit {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(forwardRef(() => DrawerContainerComponent)) public container,
    elementRef: ElementRef<HTMLElement>,
    //    scrollDispatcher: ScrollDispatcher,
    ngZone: NgZone
  ) {
    //   super(elementRef, scrollDispatcher, ngZone);
  }

  ngAfterContentInit() {
    this.container.contentMarginChanges.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }
}

@Component({
  selector: 'pm-drawer-container',
  exportAs: 'pmDrawerContainer',
  templateUrl: './drawer-container.component.html',
  host: {
    class: 'mtr-drawer__container',
    '[class.mtr-drawer__container--explicit-backdrop]': 'backdropOverride'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerContainerComponent {
  @ContentChildren(DrawerComponent) drawers: QueryList<DrawerComponent>;
  @ContentChild(DrawerContentComponent) content: DrawerContentComponent;
  @ViewChild(DrawerContentComponent) userContent: DrawerContentComponent;

  /** The drawer child with the `start` position. */
  public get start(): DrawerComponent | null {
    return this._start;
  }

  /** The drawer child with the `end` position. */
  public get end(): DrawerComponent | null {
    return this._end;
  }

  /** The drawer at the start/end position, independent of direction. */
  private _start: DrawerComponent | null;
  private _end: DrawerComponent | null;

  /**
   * Whether to automatically resize the container whenever
   * the size of any of its drawers changes.
   *
   * **Use at your own risk!** Enabling this option can cause layout thrashing by measuring
   * the drawers on every change detection cycle. Can be configured globally via the
   * `MTR_DRAWER_DEFAULT_AUTOSIZE` token.
   */
  @Input()
  get autosize(): boolean {
    return this._autosize;
  }
  set autosize(value: boolean) {
    this._autosize = coerceBooleanProperty(value);
  }
  private _autosize: boolean;

  /**
   * Whether the drawer container should have a backdrop while one of the sidenavs is open.
   * If explicitly set to `true`, the backdrop will be enabled for drawers in the `side`
   * mode as well.
   */
  @Input()
  get hasBackdrop() {
    if (this.backdropOverride == null) {
      return !this.start || this.start.mode !== 'side' || !this.end || this.end.mode !== 'side';
    }

    return this.backdropOverride;
  }
  set hasBackdrop(value: boolean) {
    this.backdropOverride = value == null ? null : coerceBooleanProperty(value);
  }
  /** @hidden */
  backdropOverride: boolean | null;

  /** Event emitted when the drawer backdrop is clicked. */
  @Output() readonly backdropClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * The drawer at the left/right. When direction changes, these will change as well.
   * They're used as aliases for the above to set the left/right style properly.
   */
  private left: DrawerComponent | null;
  private right: DrawerComponent | null;

  /** Emits on every ngDoCheck. Used for debouncing reflows. */
  private readonly doCheckSubject = new Subject<void>();

  /**
   * Margins to be applied to the content. These are used to push / shrink the drawer content when a
   * drawer is open. We use margin rather than transform even for push mode because transform breaks
   * fixed position elements inside of the transformed element.
   */
  contentMargins: { left: number | null; right: number | null } = { left: null, right: null };

  /** @hidden */
  readonly contentMarginChanges = new Subject<{ left: number | null; right: number | null }>();

  /** Reference to the CdkScrollable instance that wraps the scrollable content. */
  // get scrollable(): CdkScrollable {
  //   return this.userContent || this.content;
  // }
  get scrollable() {
    return this.userContent || this.content;
  }

  constructor(
    private element: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    // viewportRuler: ViewportRuler,
    @Inject(MTR_DRAWER_DEFAULT_AUTOSIZE) defaultAutosize = false,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) private animationMode?: string
  ) {
    // Since the minimum width of the drawer depends on the viewport width,
    // we need to recompute the margins if the viewport changes.
    // viewportRuler
    //   .change()
    //   .pipe(untilDestroyed(this))
    //   .subscribe(() => this.updateContentMargins());

    this._autosize = defaultAutosize;
  }

  ngAfterContentInit() {
    // tslint:disable-next-line: deprecation
    this.drawers.changes.pipe(startWith(null)).subscribe(() => {
      this.validateDrawers();

      this.drawers.forEach((drawer: DrawerComponent) => {
        this.watchDrawerToggle(drawer);
        this.watchDrawerPosition(drawer);
        this.watchDrawerMode(drawer);
      });

      if (!this.drawers.length || this.isDrawerOpen(this.start) || this.isDrawerOpen(this.end)) {
        this.updateContentMargins();
      }

      this.changeDetectorRef.markForCheck();
    });

    this.doCheckSubject
      .pipe(
        debounceTime(10), // Arbitrary debounce time, less than a frame at 60fps
        untilDestroyed(this)
      )
      .subscribe(() => this.updateContentMargins());
  }

  ngOnDestroy() {
    this.contentMarginChanges.complete();
    this.doCheckSubject.complete();
  }

  /** Calls `open` of both start and end drawers */
  public open(): void {
    this.drawers.forEach(drawer => drawer.open());
  }

  /** Calls `close` of both start and end drawers */
  public close(): void {
    this.drawers.forEach(drawer => drawer.close());
  }

  ngDoCheck() {
    // If users opted into autosizing, do a check every change detection cycle.
    if (this.autosize && this.isPushed()) {
      // Run outside the NgZone, otherwise the debouncer will throw us into an infinite loop.
      this.ngZone.runOutsideAngular(() => this.doCheckSubject.next());
    }
  }

  /**
   * Recalculates and updates the inline styles for the content. Note that this should be used
   * sparingly, because it causes a reflow.
   */
  public updateContentMargins() {
    // 1. For drawers in `over` or 'peek' mode, they don't affect the content.
    // 2. For drawers in `side` mode they should shrink the content. We do this by adding to the
    //    left margin (for left drawer) or right margin (for right the drawer).
    // 3. For drawers in `push` mode the should shift the content without resizing it. We do this by
    //    adding to the left or right margin and simultaneously subtracting the same amount of
    //    margin from the other side.
    let left = 0;
    let right = 0;

    if (this.left && this.left.opened) {
      if (this.left.mode === 'side') {
        left += this.left.width;
      } else if (this.left.mode === 'push') {
        const width = this.left.width;
        left += width;
        right -= width;
      }
    }

    if (this.right && this.right.opened) {
      if (this.right.mode === 'side') {
        right += this.right.width;
      } else if (this.right.mode === 'push') {
        const width = this.right.width;
        right += width;
        left -= width;
      }
    }

    // If either `right` or `left` is zero, don't set a style to the element. This
    // allows users to specify a custom size via CSS class in SSR scenarios where the
    // measured widths will always be zero. Note that we reset to `null` here, rather
    // than below, in order to ensure that the types in the `if` below are consistent.
    // tslint:disable-next-line:no-non-null-assertion
    left = left || null!;
    // tslint:disable-next-line:no-non-null-assertion
    right = right || null!;

    if (left !== this.contentMargins.left || right !== this.contentMargins.right) {
      this.contentMargins = { left, right };

      // Pull back into the NgZone since in some cases we could be outside. We need to be careful
      // to do it only when something changed, otherwise we can end up hitting the zone too often.
      this.ngZone.run(() => this.contentMarginChanges.next(this.contentMargins));
    }
  }

  /**
   * Subscribes to drawer events in order to set a class on the main container element when the
   * drawer is open and the backdrop is visible. This ensures any overflow on the container element
   * is properly hidden.
   */
  private watchDrawerToggle(drawer: DrawerComponent): void {
    drawer.animationStarted
      .pipe(
        filter((event: AnimationEvent) => event.fromState !== event.toState),
        takeUntil(this.drawers.changes)
      )
      .subscribe((event: AnimationEvent) => {
        // Set the transition class on the container so that the animations occur. This should not
        // be set initially because animations should only be triggered via a change in state.
        if (event.toState !== 'open-instant' && this.animationMode !== 'NoopAnimations') {
          this.element.nativeElement.classList.add('mtr-drawer--transition');
        }

        this.updateContentMargins();
        this.changeDetectorRef.markForCheck();
      });

    if (drawer.mode !== 'side') {
      drawer.openedChange.pipe(takeUntil(this.drawers.changes)).subscribe(() => this.setContainerClass(drawer.opened));
    }
  }

  /**
   * Subscribes to drawer onPositionChanged event in order to
   * re-validate drawers when the position changes.
   */
  private watchDrawerPosition(drawer: DrawerComponent): void {
    if (!drawer) {
      return;
    }
    // NOTE: We need to wait for the microtask queue to be empty before validating,
    // since both drawers may be swapping positions at the same time.
    drawer.onPositionChanged.pipe(takeUntil(this.drawers.changes)).subscribe(() => {
      this.ngZone.onMicrotaskEmpty
        .asObservable()
        .pipe(take(1))
        .subscribe(() => {
          this.validateDrawers();
        });
    });
  }

  /** Subscribes to changes in drawer mode so we can run change detection. */
  private watchDrawerMode(drawer: DrawerComponent): void {
    if (drawer) {
      drawer.modeChanged.pipe(untilDestroyed(this), takeUntil(this.drawers.changes)).subscribe(() => {
        this.updateContentMargins();
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  /** Toggles the 'mtr-drawer--opened' class on the main 'mtr-drawer__container' element. */
  private setContainerClass(isAdd: boolean): void {
    if (isAdd) {
      this.element.nativeElement.classList.add('mtr-drawer--opened');
    } else {
      this.element.nativeElement.classList.remove('mtr-drawer--opened');
    }
  }

  /** Validate the state of the drawer children components. */
  private validateDrawers() {
    this._start = this._end = null;

    // Ensure that we have at most one start and one end drawer.
    this.drawers.forEach(drawer => {
      if (drawer.position === 'end') {
        if (this._end != null) {
          throwMtrDuplicatedDrawerError('end');
        }
        this._end = drawer;
      } else {
        if (this._start != null) {
          throwMtrDuplicatedDrawerError('start');
        }
        this._start = drawer;
      }
    });

    this.right = this.left = null;

    this.left = this.start;
    this.right = this.end;
  }

  /** Whether the container is being pushed to the side by one of the drawers. */
  private isPushed() {
    return (
      (this.isDrawerOpen(this.start) && this.start.mode !== 'over') ||
      (this.isDrawerOpen(this.end) && this.end.mode !== 'over') ||
      (this.isDrawerOpen(this.start) && this.start.mode !== 'peek') ||
      (this.isDrawerOpen(this.end) && this.end.mode !== 'peek')
    );
  }

  onBackdropClicked() {
    this.backdropClick.emit();
    this.closeModalDrawer();
  }

  closeModalDrawer() {
    // Close all open drawers where closing is not disabled and the mode is not `side`.
    [this.start, this.end]
      .filter(drawer => drawer && !drawer.disableClose && this.canHaveBackdrop(drawer))
      // tslint:disable-next-line:no-non-null-assertion
      .forEach(drawer => drawer!.close());
  }

  isShowingBackdrop(): boolean {
    return (
      (this.isDrawerOpen(this.start) && this.canHaveBackdrop(this.start)) ||
      (this.isDrawerOpen(this.end) && this.canHaveBackdrop(this.end))
    );
  }

  private canHaveBackdrop(drawer: DrawerComponent): boolean {
    return (drawer.mode !== 'side' && drawer.mode !== 'peek') || !!this.backdropOverride;
  }

  private isDrawerOpen(drawer: DrawerComponent | null): drawer is DrawerComponent {
    return drawer != null && drawer.opened;
  }
}
