import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import {
  Candidate,
  InnerPackDetailsCardModel,
  InnerPackDetailsCardPermissions,
  InnerPackDetailsCardErrors,
  AttributeConfig,
  AttributeVolumeConfig,
  AttributeTextInputConfig,
  TextInputType, CandidateError
} from 'pm-models';

import { DisabledPermissionsMapper, ReadOnlyPermissionsMapper } from '../attribute-permissions-mapper';
import {CostService} from '../../../../../../src/app/2.0.0/service/cost.service';

@Component({
  selector: 'pm-inner-pack-details-card',
  templateUrl: './inner-pack-details-card.component.html',
  styleUrls: ['./inner-pack-details-card.component.scss']
})
export class InnerPackDetailsCardComponent implements OnInit {
  @Input()
  model: InnerPackDetailsCardModel | Candidate;

  @Output() modelChange = new EventEmitter<any>();

  @Output() innerPackQuantityChange = new EventEmitter<any>();

  @Input()
  permissions: InnerPackDetailsCardPermissions;

  @Input()
  errorModel: InnerPackDetailsCardErrors | CandidateError;

  @Input()
  defaultInnerSelectedValue;

  constructor(public costService: CostService) {}

  ngOnInit() {
    if (this.defaultInnerSelectedValue !== null && this.defaultInnerSelectedValue !== undefined) {
      this.isInnerPackConfiguration.defaultValue = this.defaultInnerSelectedValue;
    } else {
      this.isInnerPackConfiguration.defaultValue = true;
    }
  }
  isInnerPackConfiguration: AttributeConfig = {
    label: 'Inner packs',
    description: `Let us know if there are inner packs inside the master case.`,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.innerPackSelected),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.innerPackSelected),
    isRequired: false,
  };

  isInnerPackChange(e) {
    this.model.innerPackSelected = e.checked;
    if (!this.model.innerPackSelected) {
      this.model.innerPack = this.model.masterPack;
      this.model.innerHeight = this.model.masterHeight;
      this.model.innerLength = this.model.masterLength;
      this.model.innerWidth = this.model.masterWidth;
      this.model.innerWeight = this.model.masterWeight;
    }  else {
      this.model.innerPack = null;
      this.model.innerHeight = null;
      this.model.innerLength = null;
      this.model.innerWidth = null;
      this.model.innerWeight = null;
    }
    this.costService.setInnerListCost(<Candidate>this.model);
    this.costService.setUnitCost(<Candidate>this.model);
    this.modelChange.emit(this.model);
  }
  showInnerPackHelp = false;
  toggleInnerPackHelp() {
    this.showInnerPackHelp = !this.showInnerPackHelp;
  }
  innerPackQuantityConfiguration: AttributeTextInputConfig = {
    label: 'Inner pack quantity',
    description: 'The total number of units in the inner pack.',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.innerPack),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.innerPack),
    textInputType: TextInputType.integer,
    isHidden: () => !this.model.innerPackSelected,
    placeholderText: '# Units',
    inputGroupClass: 'ui-narrow-input',
    name: 'innerPackQuantityId',
    maxLength: 3
  };

  onInnerPackQuantityChange($event) {
    // If inner pack matches master pack then prefill in inner pack size fields
    const innerPackQty = $event;
    const masterPackQty = this.model.masterPack + '';

    if (this.areMasterPackValuesSet() && innerPackQty === masterPackQty) {
      this.model.innerLength = this.model.masterLength;
      this.model.innerWidth = this.model.masterWidth;
      this.model.innerHeight = this.model.masterHeight;
      this.model.innerWeight = this.model.masterWeight;
    }
    this.costService.setInnerListCost(<Candidate>this.model);
    this.costService.setUnitCost(<Candidate>this.model);
    this.innerPackQuantityChange.emit(this.model);
    this.modelChange.emit(this.model);
  }

  areMasterPackValuesSet(): boolean {
    return this.isNumberSetAndNotZero(this.model.masterLength) &&
      this.isNumberSetAndNotZero(this.model.masterWidth) &&
      this.isNumberSetAndNotZero(this.model.masterHeight) &&
      this.isNumberSetAndNotZero(this.model.masterWeight);
  }

  isNumberSetAndNotZero (value: any) {
    return value !== undefined && value !== null && value !== 0 && value !== '' && value !== '0';
  }

  innerPackDimensionsConfiguration: AttributeVolumeConfig = {
    label: 'Dimensions',
    description: `Enter the length, width, and height of the inner pack in inches. We'll calculate the volume in cubic feet.`,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.innerLength),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.innerLength),
    isHidden: () => !this.model.innerPackSelected,
    name: '',
    isRequired: true,
    numberCount: 7,
    decimalCount: 2
  };

  volumeCalc($event) {
    // console.log($event);
    this.modelChange.emit(this.model);
  }

  weightConfiguration: AttributeTextInputConfig = {
    label: 'Weight',
    description: 'The total weight of one inner pack, in pounds (lbs).',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.innerWeight),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.innerWeight),
    isHidden: () => !this.model.innerPackSelected,
    isRequired: true,
    decimalCount: 2,
    numberCount: 6,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: 'lbs',
    name: 'innerPackWeightId'
  };
  innerWeightChange(event) {}
}
