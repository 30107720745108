import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from '../auth/auth.service';
import { GrowlService } from '../growl/growl.service';
import { SupplierProductService } from '../service/supplier-product.service';
import { WorkflowService } from '../service/workflow.service';
import {
  AttributeConfig,
  AttributeDualInputConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  BaseCardPermissions,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  LocationGroupStores,
  RetailLink,
  Task,
  TaskDecision,
  TextInputType,
  Vendor
} from 'pm-models';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService } from '../service/candidate.service';
import { LookupService } from '../service/lookup.service';
import { AttributeTypes, InputState, ReviewComponent, UpcService } from 'pm-components';
import { EditCandidateModalService } from '../service/edit-candidate-modal.service';
import { CostService } from '../service/cost.service';
import { FileService } from '../service/file.service';
import { CandidateUtilService } from '../service/candidate-util.service';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { CandidateErrorUtilService } from '../service/candidate-error-util.service';
import { OwnBrandService } from '../service/ownbrand.service';
import { NewUpcRequest } from 'pm-models/lib/newUpcRequest';
import { ProcessInstanceConstants } from '../utils/constants/process-instance-constants';
import { UUID } from 'angular2-uuid';
import { MatUtilService } from '../service/mat-util.service';
import { ItemWeightTypeService } from '../service/item-weight-type.service';
import { LabelInsightService } from '../service/label-insight.service';
import { RequestNewMatAttributeOverrideWrapper } from 'pm-components/lib/attributes/attribute-type';

@Component({
  selector: 'app-buyer-product-review',
  templateUrl: './buyer-product-review.component.html',
  styleUrls: ['./buyer-product-review.component.scss'],
  providers: [ EditCandidateModalService ]
})
export class BuyerProductReviewComponent implements OnInit {
  @ViewChild(ReviewComponent) pmReview;

  public BUYER_TASK_NAME = 'Key Buyer Data';
  public BUYER_REVISE_TASK_NAME = 'Revise Buyer Data';
  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public UPC = 'UPC';
  public ITEM_CODE = 'Item Code';
  public retailLinkState: InputState;
  public isApproveDisabled = false;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public RETAIL_LINK_NUMBER = 'Link Number';
  public costLinkState: InputState;
  public requestNewMatAttributeOverrideWrapper = new RequestNewMatAttributeOverrideWrapper();

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public fileService: FileService, public candidateUtilService: CandidateUtilService,
              public permissionService: NgxPermissionsService, public authService: AuthService,
              public supplierProductService: SupplierProductService, public candidateErrorUtils: CandidateErrorUtilService,
              public upcService: UpcService, public ownBrandService: OwnBrandService,
              public matUtilService: MatUtilService, private itemWeightTypeService: ItemWeightTypeService,
              public labelInsightService: LabelInsightService) {
  }

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  private taskSubscription$: any;
  private task: Task;
  public isViewingPage = true;
  public opened = true;
  public merchandiseTypes: any;
  public commodities: any;
  public subCommodities: any;
  public pssDepartments: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public supplierOverlayCandidateError: CandidateError = new CandidateError();
  public supplierOverlayCandidateProductError: CandidateProductError;
  public productImages = [];
  public labelInsightImages = [];
  public isRejectDisabled = false;
  public openCommentDrawer = false;
  public showAddMoreSuppliers = false;
  public showBuyerReviewDrawer = false;
  public tempCommentHolder: string;
  public decimalCount = 1;
  public mrtParentDescription: string;
  public inProgressMrtParentCandidates: Candidate[] = undefined;
  public isNonReplenishItem: boolean = false;
  public isPartOfMrt: boolean = false;
  public packageTypes: any;
  public unitsOfMeasures: any;
  public vendorsToInvite: Vendor[];
  public isViewingInvitedVendors: boolean = false;
  public invitedSuppliersString = '';
  showMatAttributes = false;
  isLoadingMatGlobalData = true;
  isLoadingMatHierarchyData = true;
  public showStoreOverlay: boolean = false;

  public tempCommodity: Commodity;

  public suppliers = new Map();

  public isPageEditable: boolean = false;

  readonly permissions: BaseCardPermissions = {
    isReadOnly: false,
  };

  merchandiseTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Merchandise type',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'description',
    placeholderText: 'Select a merchandise type...',
    collections: this.merchandiseTypes
  };

  commodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Commodity',
    description: 'Choose an appropriate category.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'commodityId',
    displayRef: 'commodityName',
    placeholderText: 'Select a commodity...',
    collections: this.commodities
  };

  subCommodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-commodity',
    description: 'Choose an appropriate subcategory.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'subcommodityId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-commodity...',
    collections: this.subCommodities
  };

  pssDepartmentConfiguration: AttributeTypeaheadConfig = {
    label: 'PSS Department',
    description: 'Select the point of sale sub-department.',
    isRequired: true,
    isDisabled: () => !this.candidate.commodity || this.candidate.dsdSwitch === false,
    isReadOnly: () => false,
    name: 'pssDepartmentId',
    displayRef: 'displayName',
    placeholderText: 'Select a PSS Department...',
    idRef: 'pssDepartmentId',
    collections: this.pssDepartments
  };

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'The number of days between the product\'s arrival date to the warehouse and the product\'s ' +
      'expiration date that H-E-B is guaranteed to sell through the product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  pricingTypeConfiguration: AttributeConfig = {
    label: 'Pricing type',
    description: '',
    isDisabled: () => this.isRetailTypeDisabled(),
    isReadOnly: () => this.isRetailTypeDisabled(),
    isRequired: true,
    inputGroupClass: 'attribute-radios-block',
    options: [
      { label: 'Key retail', value: this.KEY_RETAIL },
      { label: 'Retail link', value: this.RETAIL_LINK },
      { label: 'Price required', value: this.PRICE_REQUIRED }
    ],
  };

  xForConfiguration: AttributeDualInputConfig = {
    collections: [],
    description: '',
    label: 'Retail pricing',
    name: '',
    options: [],
    isRequired: true,
    input1DecimalCount: 0,
    input1NumberCount: 3,
    input1Placeholder: '#',
    input2DecimalCount: 2,
    input2Placeholder: '$',
    input2NumberCount: 9,
    separatingText: 'for',
    isDisabled: () => this.isRetailPriceDisabled(),
    isReadOnly: () => this.isRetailPriceDisabled(),
  };

  retailLinkConfiguration: AttributeConfig = {
    options: [
      { value: this.RETAIL_LINK_NUMBER, label: 'Link #' },
      { value: this.UPC, label: 'UPC' },
      { value: this.ITEM_CODE, label: 'Item Code' }
    ],
    label: 'Retail link',
    description: 'Inherit retail price from another product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => !this.isRetailLink(),
    isRequired: true,
    defaultValue: this.UPC
  };

  pennyProfitConfiguration: AttributeTextInputConfig = {
    label: 'H-E-B Penny profit',
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `(total)`,
    textInputType: TextInputType.text,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 2
  };

  marginPercentConfiguration: AttributeTextInputConfig = {
    label: 'H-E-B Margin %',
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `(total)`,
    textInputType: TextInputType.text,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 4
  };

  likeItemCodeConfiguration: AttributeTextInputConfig = {
    label: 'Like item code',
    description: 'Enter an existing item code to use during forecasting.',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.integer
  };

  estimatedStoreCountConfiguration: AttributeTextInputConfig = {
    label: 'Estimated store count',
    description: 'Estimated number of stores that will carry this product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    name: '',
    textInputType: TextInputType.integer
  };

  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };

  yearConfiguration: AttributeTextInputConfig = {
    label: 'Year',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };

  soldByWeightConfiguration: AttributeConfig = {
    label: 'Sold by weight',
    description: `Select if the item is sold by the pound.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    defaultValue: false
  };

  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  buyerComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'buyerCommentId',
    maxLength: 4000
  };

  rushFlagConfiguration: AttributeConfig = {
    label: 'Rush review of this candidate',
    description: 'Send this Candidate to the top of other reviewer\'s queue.',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'rushFlagId',
    defaultValue: false
  };

  supplierConfiguration: AttributeTypeaheadConfig;


  public canClickSave: boolean = true;

  private setIsPageEditable() {
    this.isPageEditable = this.authService.isBuyer();
  }

  ngOnInit() {

    this.setIsPageEditable();

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.BUYER_TASK_NAME && this.task.name !== this.BUYER_REVISE_TASK_NAME) {
              this.router.navigate(
                ['/tasks'],
                {
                  queryParams:
                    {
                      growlMessage: 'Candidate is not in ' + this.BUYER_TASK_NAME + ' or ' + this.BUYER_REVISE_TASK_NAME + ' status.',
                      growlToUse: GrowlService.SEVERITY_ERROR
                    }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                this.costService.updateCostLink(candidate).subscribe((updatedCandidate) => {
                  this.costService.updateRetailLink(updatedCandidate).subscribe( (upToDateCandidate) => {
                    this.setInitialValues(upToDateCandidate);
                  });
                });
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
  }

  private setInitialValues(candidate: Candidate) {
    this.supplierProductService.resetService();
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    this.guaranteeToStoreDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.reactionDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.inboundSpecConfiguration.isHidden = () => !this.candidate.codeDate;
    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }
    if (candidate.buyer?.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }
    if (candidate.brand?.brandId !== undefined) {
      this.setCostOwners(candidate.brand.brandId);
    }
    if (this.candidate.buyerComment) {
      this.tempCommentHolder = this.candidate.buyerComment;
    }
    this.setKeyRetailDefaults();
    // Always have to check if a this 'draft' candidate is part of a MRT
    this.setMrtCaseInfo();

    this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].labelInsightsImageLinks);

    if (this.candidate.vendor.apNumber !== undefined) {
      this.setVendor(this.candidate.vendor.apNumber);
    }
    if (this.candidate.dsdSwitch) {
      this.setAuthGroups();
    }
    this.setupMatAttributes();
    if (!this.pssDepartmentConfiguration.collections || this.pssDepartmentConfiguration.collections.length === 0) {
      this.getPssDepartments();
    }

    if (!this.candidate.vertexTaxCategoryModel) {
      this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe();
    }
  }

  setupMatAttributes() {
    this.isLoadingMatHierarchyData = true;
    this.isLoadingMatGlobalData = true;
    this.supplierProductService.hierarchyNumberToAttributesMap = new Map();

    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatHierarchyData = false;
      this.isLoadingMatGlobalData = false;
      return;
    }
    this.showMatAttributes = true;

    this.matUtilService.updateMatHierarchy(this.candidateProduct).pipe(
      tap((hasHierarchyChanges) => {
        this.supplierProductService.hasHierarchyUpdateChanges = hasHierarchyChanges;
      }),
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
        this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes)),
      tap(() => {
        this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierProductService.hierarchyAttributes,
          this.supplierProductService.hierarchyNumberToAttributesMap);
        this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierProductService.globalAttributes,
          this.supplierProductService.productAttributes, this.supplierProductService.warehouseItemAttributes, this.supplierProductService.upcAttributes);
      }),
      finalize(() => {
        this.isLoadingMatHierarchyData = false;
        this.isLoadingMatGlobalData = false;
      })
    ).subscribe();
  }


  /**
   * Sets available selectable cost owners
   * @param vendorId the vendorId id.
   */
  private setVendor(vendorId) {
    if (!vendorId) {
      return;
    }
    this.lookupService.findVendor(vendorId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.vendor.apNumber === data[index].apNumber) {
          this.candidate.vendor.lanes = data[index].lanes;
          break;
        }
      }
    });
  }

  /**
   * Sets available selectable cost owners
   * @param brandId the brandId id.
   */
  private setCostOwners(brandId) {
    this.lookupService.findBrandsById(brandId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.brand.brandId === data[index].brandId) {
          this.candidate.brand.costOwners = data[index].costOwners;
          break;
        }
      }
    });

  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
        this.getPssDepartments();
        // Initialize pssDepartment to the default pss department from commodity if no value
        if ((this.candidate.pssDepartment === null || this.candidate.pssDepartment === undefined) && !this.candidate.overrideDepartment) {
          this.candidate.pssDepartment = commodity.pssDepartment;
        }
      } else {
        this.subCommodities = [];
        this.subCommodityConfiguration.collections = this.subCommodities;
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }
  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
    this.subCommodityConfiguration.collections = this.subCommodities;
  }

  getPssDepartments() {
    if (!this.candidate.overrideDepartment) {
      if (this.candidate.commodity && this.candidate.commodity.subDepartment == null) {
        // Make call to get sub department with pss departments when commodity does not have department info.
        this.lookupService.findSubDepartment(this.candidate.commodity.departmentId + this.candidate.commodity.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
          this.pssDepartmentConfiguration.collections = this.pssDepartments;
        });
      } else {
        this.pssDepartments = this.candidate?.commodity?.subDepartment?.pssDepartments;
        this.pssDepartmentConfiguration.collections = this.pssDepartments;
      }
    } else {
      if (this.candidate.overrideSubDepartment.pssDepartments === null || this.candidate.overrideSubDepartment.pssDepartments === undefined) {
        this.lookupService.findSubDepartment(this.candidate.overrideSubDepartment.departmentId +
          this.candidate.overrideSubDepartment.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
          this.pssDepartmentConfiguration.collections = this.pssDepartments;
        });
      } else {
        this.pssDepartments = this.candidate.overrideSubDepartment?.pssDepartments;
        this.pssDepartmentConfiguration.collections = this.pssDepartments;
      }
    }
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate) && this.isPageEditable) {
      this.candidate.candidateProducts[0] = this.candidateProduct;

      if (this.supplierProductService.isDsdOnlyParms(this.candidate) && !this.candidate.commodity) {
        this.candidate.commodity = this.originalCandidate.commodity;
      }
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.supplierProductService.resetService();
        this.router.navigate(['/tasks']).then();
      });
    } else {
      this.isViewingPage = false;
      this.supplierProductService.resetService();
      this.router.navigate(['/tasks']).then();
    }
  }

  showPanel(event, panel, target) {
    panel.show(event, target);
  }

  showStoreSearchPanel(event, panel, target) {
    event.stopPropagation();
    this.supplierProductService.setCandidate(JSON.parse(JSON.stringify(this.candidate)));
    this.supplierProductService.setAuthGroups(null);
    this.supplierProductService.setSelectedAuthGroups(null);
    panel.show(event, target);
    this.showStoreOverlay = true;
  }

  isStoreSearchDisabled() {
    return !this.candidate?.commodity?.commodityId;
  }

  hideStoreSearchPanel(target) {
    this.supplierOverlayCandidateError = new CandidateError();
    this.supplierOverlayCandidateProductError = new CandidateProductError();
    this.showStoreOverlay = false;
    target.hide();
  }

  reject(event, panel, target) {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;

    // if it's not a part of an in progress MRT then just simply reject.
    if (!this.isPartOfMrt || !this.inProgressMrtParentCandidates?.length) {
      this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
      panel.hide();
      return;
    }

    // // new and replenishable inners are effectively treated the same. Reject all MRTs and NR in those MRTs,
    // // unless that NR is in another MRT not affected by this reject.
    for (let x = 0; x < this.inProgressMrtParentCandidates.length; x++) {
      for (let y = 0; y < this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners.length; y++) {
        // if it's non replenish, not the current candidate (save for last) and not a draft, reject.
        // We ignore drafts because if it's replenishable, then we don't reject regardless,
        // if NR, then it belongs to another MRT, and will be reject when that one is rejected).
        if (!this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].replenishable &&
          !this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].draft &&
          this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].candidateId !== this.candidate.candidateId) {

          // if this NR is in another MRT that isn't being rejected by this flow, don't reject, so that the other
          // MRT remains in tact.
          this.isApartOfAnotherMrtNotBeingRejected(this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].candidateId,
            this.inProgressMrtParentCandidates).subscribe((dontReject) => {

            if (!dontReject) {
              this.candidateService.getCandidate(this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].candidateId)
                .subscribe((candidate: Candidate) => {
                  // if the candidate isn't approved, it has a normal process instance. Since it's only a NonReplenish, it can
                  // only be in the buyer/pia phase.
                  if (candidate.status !== Candidate.APPROVED) {
                    this.rejectCandidate(candidate, event);
                    // if the non replenish has been approved already, update the completed historic instance to be rejected state.
                  } else {
                    if (candidate.status !== Candidate.APPROVED) {
                      candidate.status = Candidate.DECLINED;
                      candidate.vendorComment = event;
                      this.candidateService.saveCandidate(candidate).subscribe(() => {
                        this.workflowService.updateNonReplenishableCompletedEndStateToRejected(candidate.candidateId).subscribe();
                      });
                    }
                  }
                });
            }
          });
        }

        // reject parent mrt on last iteration of inners
        if (y === this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners.length - 1) {
          this.rejectCandidate(this.inProgressMrtParentCandidates[x], event);
        }
      }
      // after everything else has been deleted, delete the current candidate.
      if (x === this.inProgressMrtParentCandidates.length - 1) {
        this.saveAndCompleteTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
        panel.hide();
      }
    }
  }

  /**
   * Rejects a candidate task and updates the candidate.
   * @param candidate
   * @param rejectReason
   */
  rejectCandidate(candidate: Candidate, rejectReason: string) {
    candidate.status = Candidate.DECLINED;
    candidate.vendorComment = rejectReason;
    this.candidateService.saveCandidate(candidate).subscribe(() => {
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId)
        .subscribe(task => {
          if (task.name === Task.KEY_BUYER_DATA) {
            this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
              TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).subscribe();
          } else if (task.name === Task.PIA_FINAL_REVIEW || task.name === Task.PIA_NEW_PRODUCT_FLOW) {
            this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
              TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe();
          } else if (task.name === Task.ASSIGN_WAREHOUSE) {
            // if it's an sca task, move to next, then reject from PIA.
            this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE)
              .subscribe(() => {
                this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId)
                  .subscribe(piaTask => {
                    this.workflowService.completeTaskWithAction(piaTask, WorkflowService.ACTION_COMPLETE,
                      TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe();
                  });
              });
          } else if (task.name === Task.KEY_VENDOR_DATA) {
            this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
              TaskDecision.KEY_VENDOR_DATA_DECISION_YES).subscribe();
          }
        });
    });
  }

  /**
   * Finds all MRTs the NR candidate is apart of. If it's not apart of the MRTs being rejected, then don't reject, as to not
   * create a chain reaction of deleted MRTs.
   * @param candidateId
   * @param mrtsRejecting
   */
  isApartOfAnotherMrtNotBeingRejected(candidateId, mrtsRejecting: Candidate[]): Observable<boolean> {
    return this.candidateService.findParentMrtCandidatesForCandidateId(candidateId, [Candidate.IN_PROGRESS]).pipe(map(mrtsIncludedIn => {

      if (mrtsIncludedIn === undefined || mrtsIncludedIn === null || mrtsIncludedIn.length === 1) {
        return false;
      } else {
        for (let x = 0; x < mrtsIncludedIn.length; x++) {
          let isFound = false;
          for (let y = 0; y < mrtsRejecting.length; y++) {
            if (mrtsIncludedIn[x].candidateId === mrtsRejecting[y].candidateId) {
              isFound = true;
            }
          }
          if (!isFound) {
            return true;
          }
        }
        return false;
      }
    }));
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.updateApNumber(this.candidate.vendor.apNumber, this.task.processInstanceId).subscribe(() => {
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
      });
    }, (error) => {
      this.growlService.addError(error);
      this.isRejectDisabled = false;
    });
  }

  onClickNext() {
    this.pmReview.openDrawer();
    this.showBuyerReviewDrawer = true;
    this.showAddMoreSuppliers = false;
    this.openCommentDrawer = false;
  }

  collapse() {
    this.pmReview.closeDrawer();
    this.openCommentDrawer = false;
    this.showAddMoreSuppliers = false;
    this.showBuyerReviewDrawer = false;
  }

  /**
   * Saves candidate.
   */
  save() {
    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    if (this.supplierProductService.isDsdOnlyParms(this.candidate) && !this.candidate.commodity) {
      this.candidate.commodity = this.originalCandidate.commodity;
    }
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.setVendor(this.candidate.vendor?.apNumber);
    });
  }


  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.candidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.candidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      this.merchandiseTypeConfiguration.collections = merchandiseTypes;
      if (!this.candidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
    if (!isSellable) {
      this.setNonSellableRetailValues();
    }
  }

  /**
   * Sets non sellable default values.
   */
  private setNonSellableRetailValues() {
    this.originalCandidate.retailXFor = 1;
    this.originalCandidate.retailPrice = '0.00';
    this.originalCandidate.retailType = this.KEY_RETAIL;
  }

  merchandiseTypeChange(event) {
    this.candidate.merchandiseType = event;
  }

  commodityChange(event, panel, target) {
    // The + is for coasting to number
    if (+this.candidate?.commodity?.commodityId !== +event?.commodityId) {
      this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe();

      if (this.supplierProductService.isDsdOnlyParms(this.candidate) && event &&
        (!this.originalCandidate.commodity || event.departmentId !== this.originalCandidate.commodity.departmentId ||
          event.subDepartmentId !== this.originalCandidate.commodity.subDepartmentId)) {
        this.tempCommodity = event;
        panel.show(event, target);

      } else {

        this.candidate.commodity = event;
        this.candidate.subCommodity = null;
        this.getSubCommodities(event);
        this.getPssDepartments();
        this.candidate.candidateProducts[0].locationGroupStores = [];
        // TODO: Cost link validation on API
        // if (costLink.commodity !== Number(toValidate.commodity.commodityId)) {
        //   return 'Cost Link not valid for this Commodity. Proceed without a Cost Link or send candidate back to Supplier to request' +
        //     ' a valid Cost Link for the selected Commodity.';
        // }
        if (this.candidate.commodity) {
          this.candidate.pssDepartment = this.candidate.commodity.pssDepartment;
        }
      }
    }

  }

  subCommodityChange(event) {
    this.candidate.subCommodity = event;
    this.candidate.foodStamp = CandidateHelper.isFoodStamp(this.candidate);
    this.candidate.taxable = CandidateHelper.isTaxCode(this.candidate);
    this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe();
  }

  pssDepartmentChange(event) {
    this.candidate.pssDepartment = event;
  }

  pricingTypeChange(event) {
    this.candidate.retailType = event;

    if (this.isRetailLink()) {
      this.candidate.retailLink = new RetailLink();
      this.candidate.retailLink.upcType = this.UPC;
      this.candidate.retailXFor = '';
      this.candidate.retailPrice = '';
      this.retailLinkConfiguration.placeholderText = ''; // temp fix for bug that doesn't update retail component.
    } else {
      this.candidate.retailLink = undefined;
      if (this.isKeyRetail()) {
        this.candidate.retailXFor = '';
        this.candidate.retailPrice = '';
        this.setKeyRetailDefaults();
      } else if (this.isPriceRequired()) {
        this.candidate.retailXFor = 1;
        this.candidate.retailPrice = 0.00;
      }
    }
  }

  setKeyRetailDefaults() {
    if (this.candidate.retailType === undefined) {
      this.candidate.retailType = this.KEY_RETAIL;
    }
    if (this.isKeyRetail() && !this.candidate.retailXFor && !this.candidate.retailPrice) {
      this.candidate.retailXFor = this.candidate.suggestedXFor;
      this.candidate.retailPrice = this.candidate.suggestedRetailPrice;
    }
  }

  onRetailLinkNumberChange(event) {
    this.candidate.retailLink.searchedId = event.model;
    this.candidate.retailLink.upcType = event.category;
  }

  onRetailLinkTypeChange(event) {
    if (this.candidate.retailLink) {
      this.candidate.retailLink.upcType = event;
    }
  }

  isKeyRetail(): boolean {
    return this.candidate.retailType === this.KEY_RETAIL;
  }

  isRetailLink(): boolean {
    return this.candidate.retailType === this.RETAIL_LINK;
  }

  isPriceRequired(): boolean {
    return this.candidate.retailType === this.PRICE_REQUIRED;
  }
  seasonChange(event) {
    this.candidate.season = event;
  }
  soldByWeightChange(event) {
    this.candidate.weightSwitch = event.checked;
  }
  validateRetailLink() {
    this.retailLinkState = InputState.loading;
    this.candidateError.retailLink = undefined;
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).subscribe((candidate) => {
      this.retailLinkState = InputState.valid;

      this.candidate.retailLink = candidate.retailLink;
      this.candidate.retailXFor = candidate.retailXFor;
      this.candidate.retailPrice = candidate.retailPrice;

      this.candidateError.retailLink = undefined;
    }, (error) => {
      this.retailLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.candidateError.retailLink = error.error.candidateErrors.retailLink;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  /**
   * Sets the data on a retail info object to be displayed on a table.
   * @param upc
   /**
   * Sets the data on a retail info object to be displayed on a table.
   * @param upc
   */
  setUpcData(upc) {

  }
  onKeyPress(event) {
    const pattern = /^\d+$/;
    return pattern.test(event.key);
  }

  onClickApprove() {
    this.isApproveDisabled = true;

    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.BUYER_PRODUCT_REVIEW_VALIDATOR])
      .subscribe(() => {
        this.saveAndCompleteBuyerTask();

      }, (error) => {
        if (error.error.candidateErrors) {
          this.candidateError = error.error.candidateErrors;

          this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message); // TODO: new way to handle server side errors?
        }
        this.isApproveDisabled = false;
      });
  }

  /**
   * Calls workflow service to end the buyer task and then navigates back to the tasks page.
   */
  private saveAndCompleteBuyerTask() {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_APPROVE_DECISION, 'Successfully completed task.');
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   * @param task the task.
   */
  private completeTaskWithTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string, task: Task) {
    this.workflowService.completeTaskWithAction(task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Returns whether or not retail type is disabled.
   * @returns {boolean}
   */
  isRetailTypeDisabled() {
    return this.candidate.productType === 'NON_SELLABLE';
  }

  isRetailPriceDisabled() {
    return this.isRetailLink() || this.isPriceRequired();
  }

  getProductInfoString() {
    let infoString = '';

    if (this.isNonReplenishItem) {
      infoString += this.candidate.retailSize;
    } else {
      infoString += this.candidate.retailSize + ' | ' + 'Inner case cost: ' +
        this.costService.toCurrencyForCost(this.candidate.innerListCost) + ' |  Unit cost: ' +
        this.costService.toCurrencyForCost(this.candidate.unitCost);
    }

    if (this.isSellable) {
      infoString +=
        ' | Suggested Retail: '  +
        this.candidate.suggestedXFor + ' for ' + this.costService.toCurrency(this.candidate.suggestedRetailPrice);
    }

    return infoString;
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }

  editBuyer() {
    this.editCandidateModalService.openModal(AttributeTypes.Buyer, this.candidate).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidate.commodity = null;
        this.candidate.subCommodity = null;
        this.candidate.candidateProducts[0].locationGroupStores = [];
        this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe();
        if (response.buyer && response.buyer.buyerId) {
          this.setCommoditiesAndSubCommodities(response.buyer.buyerId);
        } else {
          this.commodities = [];
          this.subCommodities = [];
        }
      }
    });
  }

  editCandidateProduct(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  editCategory() {
    this.editCandidateModalService.openModal(AttributeTypes.CategorySelection,  this.candidateProduct, {},
      {contentStyle: { 'min-width': '700px', overflow: 'none' }}).subscribe((response) => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
        this.isLoadingMatHierarchyData = true;
        this.supplierProductService.hasHierarchyUpdateChanges = false;
        this.supplierProductService.hierarchyAttributes = [];
        this.supplierProductService.hierarchyNumberToAttributesMap = new Map();
        this.matUtilService.setMatHierarchyList(this.candidate, this.supplierProductService.hierarchyAttributes).pipe(
          tap(() => {
            this.matUtilService.setHierarchyNumberToAttributesMap(this.supplierProductService.hierarchyAttributes,
              this.supplierProductService.hierarchyNumberToAttributesMap);
            this.matUtilService.updateMatHierarchyErrors(this.candidateProductError,
              this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes);
          }),
          finalize(() => { this.isLoadingMatHierarchyData = false; } )
        ).subscribe();
      }
    });
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.candidate.brand.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.candidate.brand.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editProductDescription() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.ProductDescription},
        {type: AttributeTypes.Receipt}
      ], this.candidateProduct).subscribe( response => {

      if (response) {
        this.candidateProduct = response;
      }
    });
  }

  get attributeType() {
    return AttributeTypes;
  }
  showEditCandidateProductModal(type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
        if (type === AttributeTypes.ProductImages) {
          this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
        }
        // todo: if type == labelInsightImages.
      }
    });
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, JSON.parse(JSON.stringify(this.candidate)), overrides ).subscribe(response => {
      if ( response ) {
        let updateTaxCategory = false;
        if (response.taxable !== this.candidate.taxable) {
          updateTaxCategory = true;
        }
        // Dispatch Update
        this.candidate = response;

        if (updateTaxCategory) {
          this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe();
        }

        if (type === AttributeTypes.Vendor) {
          this.candidate.candidateProducts[0].locationGroupStores = [];
        } else if (type === AttributeTypes.Channel) {
          this.findMerchandiseTypesAndSetDefault();
          this.setupMatAttributes();
          this.candidateUtilService.handleChannelChange(this.candidate);
          this.processFlowChange(true, this.supplierProductService.getProcessInstanceToCreateByCandidate(this.candidate));
        } else if (type === AttributeTypes.Sellable) {
          this.findMerchandiseTypesAndSetDefault();
        } else if (type === AttributeTypes.CaseUPC) {
          const currentCandidateProduct = CandidateUtilService.getCurrentCandidateProduct(response);
          this.candidateProduct.caseUpc = currentCandidateProduct.caseUpc;
          this.candidateProduct.caseUpcCheckDigit = currentCandidateProduct.caseUpcCheckDigit;
        }
      }
    });
  }

  editBrandModal() {
    this.editCandidateModalService.openModal(AttributeTypes.Brand, this.candidate).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[0];
      }
    });
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.buyerComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.showAddMoreSuppliers = false;
    this.showBuyerReviewDrawer = false;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.showAddMoreSuppliers = false;
    this.showBuyerReviewDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.buyerComment = this.tempCommentHolder;
    this.candidate.buyerCommentUser = this.authService.getUser();
    this.openCommentDrawer = false;
    this.showAddMoreSuppliers = false;
    this.showBuyerReviewDrawer = false;
    this.pmReview.closeDrawer();
  }

  getPageTitle() {
    if (this.isSellable) {
      return 'Review new product';
    } else if (this.candidate && !this.isSellable) {
      return 'Review non-sellable product';
    }
  }

  /**
   * Code date is editable by the buyer if dsdSwitch set to false ==> warehouse
   */
  codeDateEditable() {
    return this.candidate.dsdSwitch === false;
  }

  isMrtInner() {
    return this.candidate.candidateType === Candidate.MRT_INNER;

  }

  setMrtCaseInfo() {
    this.candidateService.findParentMrtCandidatesForCandidateId(this.candidate.candidateId,
      [Candidate.IN_PROGRESS, Candidate.COMPLETED, Candidate.ACTIVATED, Candidate.DECLINED, Candidate.DELETED]).subscribe((mrtCandidates) => {
      if (!mrtCandidates?.length) {
        this.isPartOfMrt = false;
        return;
      }
      this.isPartOfMrt = true;
      if (!this.findMrtParentAndSetMrtCaseInfo(mrtCandidates)) {
        this.setMrtCaseInfoFromWorkflow(mrtCandidates);
      }
    });
  }

  private findMrtParentAndSetMrtCaseInfo(mrtCandidates: Candidate[]): boolean {
    const mrtParentCandidate = mrtCandidates.filter(mrt => {
      return !!mrt.mrtInfo.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId && !inner.draft);
    })[0];

    if (!mrtParentCandidate) {
      return false;
    }

    this.inProgressMrtParentCandidates = mrtCandidates.filter(mrt => mrt.status === Candidate.IN_PROGRESS);
    const candidateInner = mrtParentCandidate.mrtInfo?.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId);

    if (!candidateInner.replenishable) {
      this.isNonReplenishItem = true;
      this.mrtParentDescription = mrtParentCandidate.description;
      this.candidateUtilService.setMrtInnerCandidateInfoFromMrtCandidate(this.candidate, this.candidateProduct, mrtParentCandidate);
    }
    return true;
  }


  private setMrtCaseInfoFromWorkflow(mrtCandidates: Candidate[]) {
    this.workflowService.getIsNonReplenishableMrtInnerCandidateId(this.candidate.candidateId).subscribe(
      (isNonReplenishable) => {
        if (!isNonReplenishable) {
          return;
        }
        this.isNonReplenishItem = true;
        this.inProgressMrtParentCandidates = mrtCandidates.filter(mrt => mrt.status === Candidate.IN_PROGRESS);
        let mrtParent: Candidate = mrtCandidates.filter(mrt => {
          return !!mrt.mrtInfo.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId && !inner.draft);
        })[0];
        // if the MRT parent isn't in a valid state (missing parent), find a valid one to set the data. Note: since this is an IN PROGRESS NR,
        // there should always be an IN PROGRESS MRT.
        if (!mrtParent) {
          mrtParent = mrtCandidates.filter(mrt => [Candidate.IN_PROGRESS, Candidate.COMPLETED, Candidate.ACTIVATED].includes(mrt.status))[0];
        }

        this.mrtParentDescription = mrtParent.description;
        this.candidateUtilService.setMrtInnerCandidateInfoFromMrtCandidate(this.candidate, this.candidateProduct, mrtParent);
      }
    );
  }

  showWarehouseCasePackInfo() {
    if (this.isMrtInner()) {
      return !this.isNonReplenishItem;
    } else {
      return this.candidate.warehouseSwitch;
    }
  }

  /**
   * Returns whether a buyer is tied to departments that are variable or catch weight departments (2, 6, 9).
   */
  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.candidate.commodity && this.candidate.commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.candidate.commodity.departmentId);
    } else {
      return false;
    }
  }

  editPackageType() {
    this.showEditCandidateModal(AttributeTypes.PackageTypes, {collections: this.packageTypes});
  }

  editUnitOfMeasure() {
    this.showEditCandidateModal(AttributeTypes.UnitOfMeasure, {collections: this.unitsOfMeasures});
  }

  editCostLink() {
    this.editCandidateModalService
      .openModal(AttributeTypes.CostLinkedItem, this.candidate, {
        validationService: this.candidateService
      })
      .subscribe(response => {
        if (response) {
          this.candidate = response;
          this.validateCostLink();
        }
      });
  }

  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.candidate.costLinkFromServer = undefined;
    this.candidateError.costLink = undefined;
    this.candidateError.hasErrors = null;
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((candidate) => {
      this.costLinkState = InputState.valid;
      this.candidate.costLinkFromServer = candidate.costLinkFromServer;
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.candidateError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  editShowCalories() {
    this.editCandidateModalService
      .openModal(AttributeTypes.ShowCalories, this.candidate,
        {description: `If checked, we'll pull nutritional information for the Own Brand Regulatory team to review.`,
          showWarningMessage: false})
      .subscribe(response => {
        if (response) {
          if (response.showCalories !== this.candidate.showCalories) {
            this.candidate = response;
            let processDefinitionToUse;
            if (this.candidateUtilService.isScaleProduct(this.candidate)) {
              // already on scale flow, no need to change flow
              this.save();
            } else if (response.showCalories) {
              processDefinitionToUse =
                this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_SCALE_WORKFLOW : ProcessInstanceConstants.NEW_PRODUCT_DSD_SCALE_WORKFLOW;
              this.processFlowChange(true, processDefinitionToUse);
            } else if (!response.showCalories) {
              processDefinitionToUse =
                this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_WORKFLOW : ProcessInstanceConstants.NEW_PRODUCT_DSD_WORKFLOW;
              this.processFlowChange(true, processDefinitionToUse);
            } else {
              this.save();
            }
          }
        }
      });
  }

  editCaseUPC() {
    this.showEditCandidateModal(AttributeTypes.CaseUPC, {
      validationService: this.candidateService
    });
  }

  editContact() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.ContactName},
        {type: AttributeTypes.ContactEmail}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editWarehouseSupplier() {
    this.showEditCandidateModal(AttributeTypes.Warehouse, {collections: this.candidate.vendor.lanes});
  }

  setAuthGroups() {
    if (!this.supplierProductService.getAuthGroups()) {
      this.supplierProductService.findAllAuthGroupsByParms(this.candidate.vendor.apNumber, this.candidate.commodity.departmentId,
        this.candidate.commodity.subDepartmentId).subscribe((authGroups) => {
        this.supplierProductService.setAuthGroups(authGroups);
        this.loadVendors();
        for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
          this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
      });
    } else {
      this.loadVendors();
      for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
        this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
      }
    }
  }

  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.supplierProductService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.supplierProductService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  loadVendors() {
    for (let x = 0; x < this.candidate.candidateProducts[0].locationGroupStores.length; x++) {
      this.lookupService.findDsdVendor(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber).subscribe(vendor => {
        this.suppliers.set(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber, vendor[0]);
      });
    }
  }

  getVendor(vendorId: number) {
    return this.suppliers.get(vendorId);
  }

  getNumberOfStoresForGroup(groupId) {
    if (this.supplierProductService.authGroups) {
      for (let x = 0; x < this.supplierProductService.authGroups.length; x++) {
        if (this.supplierProductService.authGroups[x].splrLocationGroupId === groupId) {
          return this.supplierProductService.authGroups[x].stores.length;
        }
      }
    }
  }

  saveClicked(candidate: Candidate, panel) {
    this.canClickSave = false;

    this.candidateService.validateCandidate(candidate,
      [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR]).toPromise().then(() => {
      // Re-apply CP to base candidate;
      this.candidate.candidateProducts = [candidate.candidateProducts[0]];
      this.candidate = candidate;
      this.supplierOverlayCandidateError = new CandidateError();
      this.supplierOverlayCandidateProductError = new CandidateProductError();
      this.candidateService.saveCandidate(candidate).subscribe(savedCandidate => {
        this.setOriginalAndCurrentCandidate(savedCandidate);
        this.loadVendors();
        this.showStoreOverlay = false;
        panel.hide();
        this.canClickSave = true;
      });
    }, (error) => {
      this.canClickSave = true;
      this.supplierProductService.scrollToTop();
      if (error.error.candidateErrors.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.supplierOverlayCandidateError = this.supplierProductService.getStoreAuthError();
        this.supplierOverlayCandidateProductError =
          this.supplierProductService.getCurrentCandidateProductError(this.supplierOverlayCandidateError);
      }
    });
  }

  onClickAddDistributors() {
    this.setInitialSupplierConfiguration();
    this.pmReview.openDrawer();
    this.showAddMoreSuppliers = true;
    this.openCommentDrawer = false;
    this.showBuyerReviewDrawer = false;
  }

  closeInviteSuppliersPanel() {
    this.vendorsToInvite = [];
    this.isViewingInvitedVendors = false;
    this.collapse();
  }

  inviteSuppliers() {
    if (!this.hasVendorsToInvite()) {
      this.closeInviteSuppliersPanel();
      return;
    }

    // if there's no invited suppliers, add all to the list.
    if (!this.candidate.candidateProducts[0].invitedSuppliers || this.candidate.candidateProducts[0].invitedSuppliers.length === 0) {
      this.candidate.candidateProducts[0].invitedSuppliers = [];
      for (let x = 0; x < this.vendorsToInvite.length; x++) {
        this.candidate.candidateProducts[0].invitedSuppliers.push({vendor: this.vendorsToInvite[x]});
      }
      this.save();
      // if there's invited suppliers, find all the vendors to invite that aren't already invited and add them to the invitedSuppliers list.
    } else {
      const notFoundVendors = [];
      let isFound;
      for (let x = 0; x < this.vendorsToInvite.length; x++) {
        isFound = false;
        for (let y = 0; y < this.candidate.candidateProducts[0].invitedSuppliers.length; y++) {
          if (this.vendorsToInvite[x].apNumber === this.candidate.candidateProducts[0].invitedSuppliers[y].vendor.apNumber) {
            isFound = true;
          }
        }
        if (!isFound) {
          notFoundVendors.push({vendor: this.vendorsToInvite[x]});
        }
      }
      if (notFoundVendors.length === 0) {
        this.closeInviteSuppliersPanel();
        return;
      }

      this.candidate.candidateProducts[0].invitedSuppliers =
        this.candidate.candidateProducts[0].invitedSuppliers.concat(notFoundVendors);
      this.save();
    }
    this.closeInviteSuppliersPanel();
  }

  hasVendorsToInvite() {
    return this.vendorsToInvite && this.vendorsToInvite.length > 0;
  }

  onInvitedVendorSelection(event) {
    this.vendorsToInvite = event;
    if (event && event.length > 0) {
      const selectedApString = this.getSelectedApNumberString(event);

      let url = '/lookup/vendordsd/exclude';
      if (this.invitedSuppliersString) {
        url +=  this.invitedSuppliersString + ',' + selectedApString;
      } else {
        url += '/' + selectedApString;
      }

      this.supplierConfiguration = {
        label: 'Suppliers to invite',
        isRequired: false,
        isHidden: () => false,
        isDisabled: () => false,
        isReadOnly: () => false,
        name: 'vendorTypeAhead',
        displaySubRef: '',
        displayRef: 'displayName',
        placeholderText: 'Select or search for a supplier...',
        searchUrl: url,
        idRef: 'apNumber',
        showAddlInfo: true,
        allowMultiple: true
      };
    }
  }

  getSelectedApNumberString(vendors: Vendor[]): string {
    if (vendors && vendors.length > 0) {
      let apNumberString = '';
      for (let x = 0; x < vendors.length; x++) {
        apNumberString += '' + vendors[x].apNumber;
        if (x !== vendors.length - 1) {
          apNumberString += ',';
        }
      }
      return apNumberString;
    }
    return '';
  }

  getSupplierHeaderText() {
    if (this.candidate && this.candidate.candidateProducts[0].invitedSuppliers &&
      this.candidate.candidateProducts[0].invitedSuppliers.length > 0) {
      if (this.candidate.candidateProducts[0].invitedSuppliers.length === 1) {
        return '1 Supplier';
      } else {
        return '' + this.candidate.candidateProducts[0].invitedSuppliers.length + ' Suppliers';
      }
    }
    return '';
  }

  removeInvitedSupplier(apNumber) {
    for (let x = 0; x < this.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
      if (apNumber === this.candidate.candidateProducts[0].invitedSuppliers[x].vendor.apNumber) {
        this.candidate.candidateProducts[0].invitedSuppliers.splice(x, 1);
        this.save();
        this.setInitialSupplierConfiguration();
        this.onInvitedVendorSelection(this.vendorsToInvite);
        return;
      }
    }
  }

  setInitialSupplierConfiguration() {
    let url = '/lookup/vendordsd/exclude';
    this.invitedSuppliersString = '';
    if (this.candidate.candidateProducts[0].invitedSuppliers && this.candidate.candidateProducts[0].invitedSuppliers.length > 0) {
      this.invitedSuppliersString += '/';
      for (let x = 0; x < this.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
        this.invitedSuppliersString += '' + this.candidate.candidateProducts[0].invitedSuppliers[x].vendor.apNumber;
        if (x !== this.candidate.candidateProducts[0].invitedSuppliers.length - 1) {
          this.invitedSuppliersString += ',';
        }
      }
      url += this.invitedSuppliersString;
    }
    this.supplierConfiguration = {
      label: 'Suppliers to invite',
      isRequired: false,
      isHidden: () => false,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: 'vendorTypeAhead',
      displaySubRef: '',
      displayRef: 'displayName',
      placeholderText: 'Select or search for a supplier...',
      searchUrl: url,
      idRef: 'apNumber',
      showAddlInfo: true,
      allowMultiple: true
    };
  }

  addAttachments(event) {
    this.candidate.attachments = event;
    this.save();
  }

  cancelStoreAuth(storeGroupsChangeOverlay) {
    storeGroupsChangeOverlay.hide();
    this.tempCommodity = null;
    if (this.originalCandidate.commodity) {
      this.candidate.commodity = JSON.parse(JSON.stringify(this.originalCandidate.commodity));
    } else {
      this.candidate.commodity = null;
    }
  }

  changeDsdCommodity() {
    this.candidate.commodity = this.tempCommodity;
    this.candidate.subCommodity = null;
    this.candidate.candidateProducts[0].locationGroupStores = [];
    if (this.candidate.commodity) {
      this.candidate.pssDepartment = this.candidate.commodity.pssDepartment;
    }
    this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE,
      TaskDecision.KEY_BUYER_DATA_SEND_BACK_DECISION, 'Successfully sent candidate back to supplier.');
  }

  editDepositUPC() {
    this.showEditCandidateProductModal(AttributeTypes.DepositUpc, {
      validationService: this.candidateService
    });
  }

  editPlu() {
    this.editCandidateModalService.openModal(AttributeTypes.Plu, JSON.parse(JSON.stringify(this.candidateProduct)), {saveDisabled: false}).subscribe(response => {
      if (!response) {
        return;
      }
      const wasScale = this.candidateUtilService.isScaleProduct(this.candidate);
      const previousPlu = this.candidateProduct.upc;
      const previousPluType = this.candidateProduct.pluType;

      this.candidate.candidateProducts[0] = response;
      const scaleChange = wasScale !== this.candidateUtilService.isScaleProduct(this.candidate);

      if (scaleChange) {
        this.candidate.candidateProducts[0].scaleInformation = { id: UUID.UUID() };
      }
      this.candidateProduct = this.candidate.candidateProducts[0];

      if (!response.keepPlu) {
        this.updatePluAndSave(previousPlu, previousPluType);
      }
      if (scaleChange) {
        this.updateProcessInstanceForPluTypeChange(response.keepPlu);
      }
    });
  }

  updateProcessInstanceForPluTypeChange(saveCandidate: boolean) {
    let processDefinition;

    if (this.candidateUtilService.isScaleProduct(this.candidate)) {
      processDefinition = this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_SCALE_WORKFLOW :
        ProcessInstanceConstants.NEW_PRODUCT_DSD_SCALE_WORKFLOW;
    } else {
      processDefinition = this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_WORKFLOW :
        ProcessInstanceConstants.NEW_PRODUCT_DSD_WORKFLOW;
    }
    this.processFlowChange(saveCandidate, processDefinition);
  }

  processFlowChange(saveCandidate: boolean, processDefinition) {
    const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
    this.workflowService.deleteProcessInstanceAndCreateNewFlow(this.task.processInstanceId, this.candidate.candidateId, processDefinition,
      apNumber, Task.BUYER_DETAILS_ACTIVITY_ID).subscribe((task) => {
      this.task = task;
      // update url with new task id.
      window.history.replaceState({}, 'Title', '#/buyerProductReview?taskId=' + this.task.id);
      // if the plu stayed the same (we didn't save yet), and the workflow changed, save the change to the plu type so there's no mismatch
      // between the plu type and flow (scale vs non scale).
      if (saveCandidate) {
        this.save();
      }
    });
  }

  private updatePluAndSave(previousPlu, previousPluType) {
    const newUpcRequest = new NewUpcRequest();
    newUpcRequest.requestType = this.candidateProduct.pluType;
    newUpcRequest.candidate = this.candidate;
    newUpcRequest.rangeId = this.candidateProduct.pluRange.pluRangeId;
    this.ownBrandService.releaseAndFetchNewPlu(previousPlu, previousPluType, newUpcRequest).subscribe((plu) => {
      this.candidateProduct.upc = plu;
      this.candidate.candidateProducts[0].upc = plu;
      this.save();
    });
  }

  editDepartment() {
    this.editCandidateModalService.openModal(AttributeTypes.SubDepartment, this.candidate, {}).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        if (this.candidate.overrideDepartment !== response.overrideDepartment ||
          this.candidate.commodity.departmentId !== response.commodity.departmentId ||
          this.candidate.overrideSubDepartment?.departmentId !== response.overrideSubDepartment?.departmentId) {
          this.candidate = response;
          this.candidate.pssDepartment = null;
          this.getPssDepartments();
          this.candidate.candidateProducts[0].locationGroupStores = [];
          this.supplierProductService.setAuthGroups(null);
          this.supplierProductService.setSelectedAuthGroups(null);
          this.supplierProductService.setNotFoundStores(null);
          this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
            this.supplierProductService.setCandidate(savedCandidate);
          });
        }
      }
    });
  }
}
