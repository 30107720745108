import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class MaxShelfLife implements BaseAttribute {
  isHidden = false;

  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }
  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Max shelf life',
      description: 'The maximum numbers of days this product is shelf-stable.',
      isDisabled: () => false,
      isReadOnly: () => false,
      isHidden: () => this.isHidden,
      placeholderText: '# of days',
      name: 'maxShelfLifeId',
      isRequired: true,
      numberCount: 7,
      textInputType: TextInputType.integer,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.maxShelfLife;
    componentInstance.modelChange.subscribe(x => {
      candidate.maxShelfLife = x;
    });
    this.showModel(componentInstance, candidate);
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    this.showModel(componentInstance, candidate);
  }

  showModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (!candidate.codeDate) {
      this.isHidden = true;
      componentInstance.model = null;
      candidate.codeDate = null;
    } else {
      this.isHidden = false;
      if (componentInstance.model !== candidate.maxShelfLife) {
        componentInstance.model = candidate.maxShelfLife;
      }
    }
  }
}
