import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { PMToolbarSectionDirective } from './toolbar-section.directive';
import { PMToolbarTitleDirective } from './toolbar-title.directive';

const COMPONENTS = [ToolbarComponent, PMToolbarSectionDirective, PMToolbarTitleDirective];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [CommonModule]
})
export class ToolbarModule {}
