import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class TotalVolume implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Unit Size',
      description: 'This is the total volume for the retail unit. Example: Six 12oz cans would read: 72',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      decimalCount: 2,
      numberCount: 9,
      textInputType: TextInputType.decimal,
      inputGroupClass: 'ui-narrow-input',
      placeholderText: '',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.totalVolume;
    componentInstance.modelChange.subscribe(x => {
      candidate.totalVolume = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.totalVolume) {
      componentInstance.model = candidate.totalVolume;
    }
  }
}
