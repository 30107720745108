<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-4">
    <!-- Helper images -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Scale information" subtitle="Fill out all required scale attributes.">

    <ng-container *ngIf="model">

      <pm-attribute-display-text-input [attribute]="englishLabelOneConfiguration"
                                       [(model)]="model.englishLabelOne"
                                       [attributeError]="errorModel?.englishLabelOne">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="englishLabelTwoConfiguration"
                                       [(model)]="model.englishLabelTwo"
                                       [attributeError]="errorModel?.englishLabelTwo">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="prePackTareConfiguration"
                                       [(model)]="model.prePackTare"
                                       [attributeError]="errorModel?.prePackTare">
      </pm-attribute-display-text-input>


      <pm-attribute-display-text-input [attribute]="shelfLifeDaysConfiguration"
                                       [(model)]="model.shelfLifeDays"
                                       [attributeError]="errorModel?.shelfLifeDays">
      </pm-attribute-display-text-input>

    </ng-container>
  </pm-card>
</ng-template>
