import {Component, OnInit} from '@angular/core';

import {Candidate, Task, TaskDecision} from 'pm-models';
import {WorkflowService} from '../../service/workflow.service';
import {TaskListTask} from '../task-list-service/taskListTask';
import {TaskViewerTableComponent} from '../task-viewer-table/task-viewer-table.component';
import {concatMap, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-pia-task-viewer-table',
  templateUrl: './pia-task-viewer-table.component.html',
  styleUrls: ['./pia-task-viewer-table.component.scss']
})
export class PiaTaskViewerTableComponent extends TaskViewerTableComponent implements OnInit {

  mapColumnNamePrimeTableClosedToEndPoint = new Map([
    ['candidateId', 'CANDIDATE_ID'],
    ['description', 'PRODUCT_DESCRIPTION'],
    ['displayName', 'STATUS'],
    ['upc', 'UPC'],
    ['itemCode', 'ITEM_CODE'],
    ['supplierDisplayName', 'SUPPLIER_NAME'],
    ['lastUpdatedBy', 'LAST_UPDATED_BY'],
    ['lastUpdatedTime', 'LAST_UPDATED_TIME'],
  ]);

  //
  // rejected table variables
  //
  rejectedTasks: TaskListTask[] = [];
  rejectedTotalNumRecords: number;

  rejectedCurrentPageNumber: number = 0;
  rejectedCurrentSortOrder: string = 'DESC';
  rejectedCurrentSortField: string = 'CANDIDATE_ID';

  //
  // approved table variables
  //
  approvedTasks: TaskListTask[] = [];
  approvedTotalNumRecords: number;

  approvedCurrentPageNumber: number = 0;
  approvedCurrentSortOrder: string = 'DESC';
  approvedCurrentSortField: string = 'CANDIDATE_ID';

  // ---

  tabIndex: number = 0;

  OPEN_TAB = {
    title: 'Open',
    tooltip: 'Candidates that have tasks assigned to you.',
    index: 0
  };

  REJECTED_TAB = {
    title: 'Rejected',
    tooltip: 'Candidates that have been rejected.',
    index: 1
  };

  ACTIVATED_TAB = {
    title: 'Activated',
    tooltip: 'Candidates that have been activated.',
    index: 2
  };

  async ngOnInit() {
    this.permissionService.hasPermission('ROLE_BULK_ACTION').then((hasPermission) => {
      if (hasPermission) {
        this.hasBulkActionPermission = this.isPia;
      }
    });
    this.changeTab({index: this.tabIndex});
  }

  getOpenTasks() {
    super.getOpenTasks(true, this.currentPageNumber, this.currentSortOrder, this.currentSortField);
  }

  getRejectedProcesses() {
    this.getRejectedProcessesParms(true, this.rejectedCurrentPageNumber, this.rejectedCurrentSortOrder, this.rejectedCurrentSortField);
  }

  getRejectedProcessesParms(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {
    this.showSpinner = true;
    this.taskListService.getPiaRejectedProcesses(getCount, pageNumber, sortOrder, sortField).subscribe(result => {
      if (result && result.data && result.data.length > 0) {
        if (getCount) {
          this.rejectedTotalNumRecords = result.recordCount;
        }
        for (let x = 0; x < result.data.length; x++) {
          result.data[x].displayName = this.taskListService.getDisplayNameClosed(result.data[x].status);
        }
        this.rejectedTasks = result.data;
      } else {
        this.rejectedTotalNumRecords = 0;
        this.rejectedTasks = [];
      }
      this.showSpinner = false;
    });
  }

  getCompletedProcesses() {
    this.getCompletedProcessesParms(true, this.approvedCurrentPageNumber, this.approvedCurrentSortOrder, this.approvedCurrentSortField);
  }

  getCompletedProcessesParms(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {

    this.showSpinner = true;
    this.taskListService.getPiaCompletedProcesses(getCount, pageNumber, sortOrder, sortField).subscribe(result => {
      if (result && result.data && result.data.length > 0) {
        if (getCount) {
          this.approvedTotalNumRecords = result.recordCount;
        }
        for (let x = 0; x < result.data.length; x++) {
          result.data[x].displayName = this.taskListService.getDisplayNameClosed(result.data[x].status);
        }
        this.approvedTasks = result.data;
      } else {
        this.approvedTotalNumRecords = 0;
        this.approvedTasks = [];
      }
      this.showSpinner = false;
    });
  }

  changeTab(event) {
    this.tabIndex = event.index;

    switch (this.tabIndex) {
      case this.OPEN_TAB.index: {
        this.getOpenTasks();
        break;
      }
      case this.REJECTED_TAB.index: {
        this.getRejectedProcesses();
        break;
      }
      case this.ACTIVATED_TAB.index: {
        this.getCompletedProcesses();
        break;
      }
    }

    this.clearTabIndexParam();
  }

  clearTabIndexParam() {
    this.router.navigate(
      ['.'],
      { relativeTo: this.route, queryParams: { } }
    ).then();
  }

  /**
   * Delete a candidate. This logic is designed to work for the PIA only workflow trash can.
   * The logic for updating the candidate is on the server side.
   * @param candidateId to delete
   */
  deleteCandidateById(candidateId: number) {
    const task: TaskListTask = this.tasks.find(x => x.candidateId === candidateId);
    if (task) {
      const decision: TaskDecision = Task.PIA_OB_REG_FLOW_SUBMIT === task.name ? TaskDecision. PIA_SCALE_PLU_APPROVE_NO :
        TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION;

      if (task.taskType === Candidate.MRT) {
        this.deleteMrt(candidateId, task.id, decision);
      } else {
        this.deleteTask(candidateId, task.id, decision);
      }
    } else {
      this.growlService.addError('Unable to find task for Candidate Id: ' + candidateId);
    }
  }

  deleteTask(candidateId: number, taskId: string, decision: TaskDecision) {
    this.candidateService.deleteCandidateById(candidateId).subscribe(() => {
      this.workflowService.completeTaskIdWithAction(
        taskId, WorkflowService.ACTION_COMPLETE, decision)
        .subscribe(() => {
          this.getOpenTasks();
        }, (error) => {
          this.showError(error);
        });

    }, (error) => {
      this.showError(error);
    });
  }

  showError(error: any) {
    let errorMessage: string;

    if (error.error) {
      errorMessage = `${error.error.message}`;
    } else {
      errorMessage = `${error.message}`;
    }

    this.growlService.addError(errorMessage);
  }

  onLoadDataRejected(event: any) {
    const pageNumber = this.getPageNumber(event.first, this.taskListService.PAGE_SIZE);
    let sortField = this.mapColumnNamePrimeTableClosedToEndPoint.get(event.sortField);
    if (!sortField) {
      sortField = 'CANDIDATE_ID';
    }
    const sortOrder = this.getSortOrder(sortField, this.mapSortOrderPrimeTableToEndPoint.get(event.sortOrder + 0));

    if (this.rejectedCurrentPageNumber === pageNumber &&
      this.rejectedCurrentSortOrder === sortOrder &&
      this.rejectedCurrentSortField === sortField) {
      return;
    } else {
      this.rejectedCurrentPageNumber = pageNumber;
      this.rejectedCurrentSortOrder = sortOrder;
      this.rejectedCurrentSortField = sortField;
    }

    this.getRejectedProcessesParms(false, pageNumber, sortOrder, sortField);
  }

  onLoadDataApproved(event: any) {
    const pageNumber = this.getPageNumber(event.first, this.taskListService.PAGE_SIZE);
    let sortField = this.mapColumnNamePrimeTableClosedToEndPoint.get(event.sortField);
    if (!sortField) {
      sortField = 'CANDIDATE_ID';
    }
    const sortOrder = this.getSortOrder(sortField, this.mapSortOrderPrimeTableToEndPoint.get(event.sortOrder + 0));

    if (this.approvedCurrentPageNumber === pageNumber &&
      this.approvedCurrentSortOrder === sortOrder &&
      this.approvedCurrentSortField === sortField) {
      return;
    } else {
      this.approvedCurrentPageNumber = pageNumber;
      this.approvedCurrentSortOrder = sortOrder;
      this.approvedCurrentSortField = sortField;
    }

    this.getCompletedProcessesParms(false, pageNumber, sortOrder, sortField);
  }

  onClickRowApprovedDeclined(task: TaskListTask) {
    return this.router.navigate([this.getUrlForClosedTaskDetails(task)],
      {queryParams: this.taskListService.getQueryParamsForCandidateDetails(task.candidateId)}).then();
  }

  getUrlForClosedTaskDetails(task: TaskListTask): string {
    if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
      return '/procurementSupportProductReviewClosed';
    } else if (task.taskType === Candidate.MRT) {
      return '/piaMrtReviewClosed';
    } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
      return '/associateUpcProcurementReviewClosed';
    } else if (task.taskType === Candidate.BONUS_SIZE) {
      return '/piaBonusSizeReviewClosed';
    } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
      return '/piaAdditionalCasePackClosed';
    } else if (task.taskType === Candidate.MRT_INNER) {
      return '/piaMrtInnerReviewClosed';
    } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
      return '/piaReplacementUpcReviewClosed';
    } else if (task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
      return '/piaInvitedDistributorsReviewClosed';
    } else if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR) {
      return '/piaInvitedDistributorsReviewClosed';
    } else {
      return '/';
    }
  }

  onClickBulkEdit(toReview: Candidate[]) {
    const queryParams = {};
    queryParams['toReview'] = toReview;
    return this.router.navigate(['/piaBulkEdit'],
      {queryParams: queryParams});
  }

  getSortOrder(sortField: string, sortOrder: string) {
    if (sortField === 'LAST_UPDATED_TIME' && sortOrder === 'ASC') {
      return 'DESC';
    } else if (sortField === 'LAST_UPDATED_TIME') {
      return 'ASC';
    }
    return sortOrder;
  }

  /**
   * Deletes the candidate inners, if any, then the mrt and its task.
   * @param candidateId
   * @param taskId
   * @param decision
   */
  deleteMrt(candidateId: number, taskId: string, decision: TaskDecision) {
    this.candidateService.getCandidate(candidateId).pipe(
      concatMap((candidate: Candidate) => {
        if (candidate.mrtInfo && candidate.mrtInfo.candidateInners && candidate.mrtInfo.candidateInners.length > 0) {
          return this.candidateService.deleteCandidatesByIds(candidate.mrtInfo.candidateInners
            .filter(inner => !inner.draft).map(inner => inner.candidateId));
        } else {
          return of({});
        }
      }),
      tap(result => this.deleteTask(candidateId, taskId, decision))
    ).subscribe();
  }
}
