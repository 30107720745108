export class DsdSupplierItem {

  vendorLocationNumber?: number;

  // master pack
  pack?: number;

  // cost owner
  costOwner?: any;

  countryOfOriginId?: number;
}
