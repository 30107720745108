import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, CandidateProduct, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class VendorProductCode implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'VPC',
      description: 'Enter the manufacturer or vendor product code.',
      placeholderText: 'Enter VPC...',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      name: 'vpcId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.vendorProductCode;
    componentInstance.modelChange.subscribe(x => {
      candidate.vendorProductCode = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: CandidateProduct) {
    if (componentInstance.model !== candidate.vendorProductCode) {
      componentInstance.model = candidate.vendorProductCode;
    }
  }
}
