import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AttributeTypeaheadConfig, Candidate, CandidateProduct, DsdItem, Item, Product, Upc} from 'pm-models';
import {GrowlService} from '../growl/growl.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {CandidateService} from '../service/candidate.service';
import {CostService} from '../service/cost.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {FileService} from '../service/file.service';
import {LookupService} from '../service/lookup.service';
import {ProductService} from '../service/product.service';
import {WorkflowService} from '../service/workflow.service';
import {calculateCheckDigit} from '../shared/upc.utils';
import {ReviewComponent} from 'pm-components';
import {CandidateHistoryService} from '../service/candidate-history.service';
import {SupplierCasePackService} from '../service/supplier-case-pack.service';
import {MatUtilService} from '../service/mat-util.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {Observable} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-pia-additional-case-pack-closed',
  templateUrl: './pia-additional-case-pack-closed.component.html',
  styleUrls: ['./pia-additional-case-pack-closed.component.scss']
})
export class PiaAdditionalCasePackClosedComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public currentCandidateProduct: CandidateProduct;
  public isViewingPage = true;
  public productImageUrl: string = null;
  private decimalCount = 1;
  private searchedCandidateProductIndex = 0;
  public orderRestrictions: any[];
  private scanCodeCheckDigit: number;
  public productData: Product;
  public upc: Upc;
  public item: Item;
  public dsdItem: DsdItem;
  public productInfoString: string;
  public departmentId = undefined;
  showHistoryPanel: boolean = false;

  showMatAttributes = false;
  isLoadingMatData = true;

  DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  constructor(private workflowService: WorkflowService, private route: ActivatedRoute, private router: Router, private candidateService: CandidateService,
              private lookupService: LookupService, private growlService: GrowlService, public costService: CostService, private productService: ProductService,
              public fileService: FileService, public editCandidateModalService: EditCandidateModalService, public candidateUtilService: CandidateUtilService,
              public candidateHistoryService: CandidateHistoryService, public supplierCasePackService: SupplierCasePackService,
              public matUtilService: MatUtilService, public permissionService: NgxPermissionsService) { }


  ngOnInit() {
    this.supplierCasePackService.resetService();
    this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.ADDITIONAL_CASE_PACK) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.candidateHistoryService.setInitialValues(candidate);
            this.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: any) {
    this.candidate = candidate;
    this.candidateProduct = this.candidate.candidateProducts[0];
    this.currentCandidateProduct = this.candidate.candidateProducts[1];
    this.isViewingPage = true;
    this.productImageUrl = null;
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
    this.getOrderRestrictions();
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  setProductData(): Observable<any> {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
          this.getCheckDigit();
        })
      );
    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
          this.getCheckDigit();
        })
      );
    }
  }

  private setInformationFromProductData(productData: Product) {
    this.candidate.productId = productData.productId;
    this.item = this.productService.getPrimaryItem(this.candidate.candidateProducts[0], productData);
    if (!this.item) {
      this.dsdItem = this.productService.getPrimaryDsdItem(this.candidate.candidateProducts[0], productData);
      if (!this.candidate.commodity) {
        this.candidate.commodity = {
          commodityId: this.dsdItem.hierarchy.commodity.commodityId,
          commodityName: this.dsdItem.hierarchy.commodity.description
        };
      }
    } else {
      if (!this.candidate.commodity) {
        this.candidate.commodity = {
          commodityId: this.item.commodity.commodityId,
          commodityName: this.item.commodity.description
        };      }
    }
    this.productService.getUpcBySearchedValue(this.candidate.candidateProducts[0], productData).subscribe(upc => {
      this.upc = upc;
      this.productService.setCandidateRetailFromUpc(this.candidate, upc);
      this.setProductRetailInfo();
    });
    this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
    this.departmentId = this.productService.getDepartment(productData);
  }

  setProductRetailInfo() {
    const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
    tempCandidate.retailPrice = this.upc.retailPrice;
    tempCandidate.retailXFor = this.upc.xfor;
    if (!this.productData.priceRequired) {
      tempCandidate.retailType = 'KEY_RETAIL';
    }
    this.productInfoString = '' + this.upc.size +
      ' | Inner case cost: '  +  this.costService.getCaseCostAsCurrency(this.candidate) +
      ' | Unit cost: '  +  this.costService.toCurrencyForCost(tempCandidate.unitCost) +
      ' | Retail: '  +  this.costService.toCurrency(this.upc.retailPrice);
  }

  getCheckDigit() {
    if (this.productData.primaryScanCodeId) {
      this.scanCodeCheckDigit = calculateCheckDigit(this.productData.primaryScanCodeId);
    } else {
      this.scanCodeCheckDigit = null;
    }
  }

  getUpcCheckDigit() {
    if (this.upc && this.upc.scanCodeId) {
      return calculateCheckDigit(this.upc.scanCodeId);
    } else {
      return '';
    }
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks'], { queryParams: { tabIndex: 1 } }).then();
  }

  onClickPrint() {
    window.print();
  }

  private resetInitialValues() {
    this.isViewingPage = false;
    this.candidate = undefined;
    this.candidateProduct = undefined;

    this.productImageUrl = null;
  }

  onClose() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  getTitleSubHeading(): String {
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.candidate?.status);
    if (this.currentCandidateProduct?.itemCode) {
      infoString += ' | Item code: ' + this.currentCandidateProduct.itemCode;
    }

    return infoString;
  }

  getHebPennyProfit() {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebPennyProfit(tempCandidate);
    }
  }

  getHebMargin() {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebMargin(tempCandidate);
    }
  }

  getPricingType() {
    return this.productService.getPricingType(this.productData, this.upc);
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }

    this.supplierCasePackService.resetMatHierarchyFields();

    // show attributes in context of live attributes while candidate is in progress.
    if (this.candidateUtilService.isInProgressCandidate(this.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatHierarchyFromProduct(this.currentCandidateProduct, this.productData).pipe(
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
          this.supplierCasePackService.globalAttributes, this.supplierCasePackService.hierarchyAttributes)),
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierCasePackService.hierarchyAttributes,
            this.supplierCasePackService.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierCasePackService.globalAttributes,
            [], this.supplierCasePackService.warehouseItemAttributes, []);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.currentCandidateProduct?.globalAttributes, [],
        this.supplierCasePackService.warehouseItemAttributes, []);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.piaComment;
  }
}
