import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateProduct,
  CandidateValidatorType,
  DsdItem,
  Item,
  Product,
  TaskDecision,
  TextInputType,
  Upc
} from 'pm-models';
import {WorkflowService} from '../../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {GrowlService} from '../../growl/growl.service';
import {CostService} from '../../service/cost.service';
import {ProductService} from '../../service/product.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {FileService} from '../../service/file.service';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {SupplierCasePackService} from '../../service/supplier-case-pack.service';
import {SupplierProductService} from '../../service/supplier-product.service';
import {HistoryService} from '../../utils/history.service';
import {ReviewComponent} from 'pm-components';
import {NgxPermissionsService} from 'ngx-permissions';
import {calculateCheckDigit} from '../../shared/upc.utils';
import {
  AdditionalCasePackStepperComponent
} from '../../supplier/components/additional-case-pack-stepper/additional-case-pack-stepper.component';
import {CandidateHistoryService} from '../../service/candidate-history.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {MatUtilService} from '../../service/mat-util.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-additional-case-pack-review',
  templateUrl: './additional-case-pack-review.component.html',
  styleUrls: ['./additional-case-pack-review.component.scss']
})
export class AdditionalCasePackReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public resubmittedCandidate: Candidate;
  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  public isViewingPage = true;
  public productImageUrl: string = null;
  public decimalCount = 1;
  public currentCandidateProductIndex = 1;
  public searchedCandidateProductIndex = 0;
  public orderRestrictions: any[];
  public scanCodeCheckDigit: number;
  public canNavigate = true;
  public productData: Product;
  public upc: Upc;
  public item: Item;
  public dsdItem: DsdItem;
  public productInfoString: string;
  public departmentId = undefined;
  public taskId: any;
  public currentHistoryResults: any = undefined;
  public showHistoryPanel: boolean = false;
  private candidateSubscription$: any;
  public isClosedOrUnderReview = true;
  canEditAndResubmit = true;
  showMatAttributes = false;
  isLoadingMatData = true;

  DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute, public supplierCasePackService: SupplierCasePackService,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService, public growlService: GrowlService,
              public costService: CostService, public productService: ProductService, public fileService: FileService,
              public editCandidateModalService: EditCandidateModalService, public candidateUtilService: CandidateUtilService,
              public historyService: HistoryService, public permissionService: NgxPermissionsService,
              public candidateHistoryService: CandidateHistoryService, public matUtilService: MatUtilService) { }


  ngOnInit() {
    this.candidateSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId') && params.has('taskId')) {
        this.workflowService.getAllTasksForCandidates([+params.get('candidateId')]).subscribe((tasks) => {

          if (tasks && tasks.length > 0) {
            this.supplierCasePackService.setTaskId(tasks[0].id);
          } else {
            this.supplierCasePackService.resetService();
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Unable to find a task associated with candidate id: ' +
                  params.get('candidateId'), growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          }

          const candidateId: number = parseInt(params.get('candidateId'), 10);
          this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
            if (candidate.candidateType !== Candidate.ADDITIONAL_CASE_PACK) {
              this.router.navigate(['/tasks'], {
                queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
              }).then();
            } else {
              this.supplierCasePackService.setOriginalAndCurrentCandidate(candidate);
              this.candidateHistoryService.setLastSupplierChangedCandidate(this.supplierCasePackService.getCandidate());
              this.setInitialValues();
            }

          });
        });
      } else if (params.has('taskId')) {
        const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierCasePackService.getTaskId(),
          this.supplierCasePackService.getTask());
        if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
          this.supplierCasePackService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.ADDITIONAL_CASE_PACK) {
              this.isClosedOrUnderReview = false;
              this.setInitialValues();
              this.candidateHistoryService.setLastSupplierChangedCandidate(candidate);
            }
          });
        } else {
          this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierCasePackService.getCandidate()).subscribe(
            (isValid) => {
              if (isValid) {
                this.isClosedOrUnderReview = false;
                this.setInitialValues();
                this.candidateHistoryService.setLastSupplierChangedCandidate(this.supplierCasePackService.getCandidate());
              } else {
                this.supplierCasePackService.resetService();
                this.router.navigate(['/tasks']);
              }
            });
        }
      } else {
        this.supplierCasePackService.resetService();
        this.router.navigate(['/tasks'], {
          queryParams: {growlMessage: 'Invalid URL Parameters!', growlToUse: GrowlService.SEVERITY_ERROR}
        });
      }
    });
  }

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'Verify the value provided by the supplier or enter a value if empty .',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };

  yearConfiguration: AttributeTextInputConfig = {
    label: 'Year',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };

  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  private setInitialValues() {
    this.isViewingPage = true;
    this.productImageUrl = null;
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
    this.getOrderRestrictions();
    this.currentHistoryResults = this.historyService.getCandidateHistory(this.supplierCasePackService.candidate.candidateId, true);

  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  setProductData(): Observable<any> {
    if (this.supplierCasePackService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.supplierCasePackService.candidate.candidateProducts[0].upc, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
          this.getCheckDigit();
        })
      );
    } else if (this.supplierCasePackService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.supplierCasePackService.candidate.candidateProducts[0].itemCode, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(this.productData);
          this.getCheckDigit();
        })
      );
    }
  }

  private setInformationFromProductData(productData: Product) {
    this.supplierCasePackService.getCandidate().productId = productData.productId;
    this.item = this.productService.getPrimaryItem(this.supplierCasePackService.candidate.candidateProducts[0], productData);
    if (!this.item) {
      this.dsdItem = this.productService.getPrimaryDsdItem(this.supplierCasePackService.candidate.candidateProducts[0], productData);
    }
    this.productService.getUpcBySearchedValue(this.supplierCasePackService.candidate.candidateProducts[0],
      productData).subscribe(upc => {
      this.upc = upc;
      this.productService.setCandidateRetailFromUpc(this.supplierCasePackService.candidate, upc);
      this.setProductRetailInfo();
    });
    this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
    this.departmentId = this.productService.getDepartment(productData);
  }

  setProductRetailInfo() {
    const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.supplierCasePackService.candidate));
    tempCandidate.unitCost = this.costService.getUnitCost(this.supplierCasePackService.candidate);
    tempCandidate.retailPrice = this.upc.retailPrice;
    tempCandidate.retailXFor = this.upc.xfor;
    if (!this.productData.priceRequired) {
      tempCandidate.retailType = 'KEY_RETAIL';
    }
    this.productInfoString = '' + this.upc.size +
      ' | Inner case cost: '  +  this.costService.getCaseCostAsCurrency(this.supplierCasePackService.candidate) +
      ' | Unit cost: '  +  this.costService.toCurrencyForCost(tempCandidate.unitCost) +
      ' | Retail: '  +  this.costService.toCurrency(this.upc.retailPrice);
  }

  getCheckDigit() {
    if (this.productData && this.productData.primaryScanCodeId) {
      this.scanCodeCheckDigit = calculateCheckDigit(this.productData.primaryScanCodeId);
    } else {
      this.scanCodeCheckDigit = null;
    }
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks'], { queryParams: { tabIndex: 1 } }).then();
  }

  onClickPrint() {
    window.print();
  }

  private resetInitialValues() {
    this.isViewingPage = false;
    this.productImageUrl = null;
  }

  onClose() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  getTitleSubHeading(): String {
    if (!this.supplierCasePackService.candidate) {
      return;
    }
    let infoString = '';

    infoString += 'Status: ';
    infoString += this.getUIStatusFromCandidate(this.supplierCasePackService.candidate);
    if (this.supplierCasePackService.candidate.candidateProducts[1].itemCode) {
      infoString += ' | Item code: ' + this.supplierCasePackService.candidate.candidateProducts[1].itemCode;
    }

    return infoString;
  }

  getUIStatusFromCandidate(candidate: Candidate) {
    if (!candidate) {
      console.error('getUIStatusFromCandidate: Candidate is unavailable to get status');
      return 'Unknown';
    }

    return CandidateUtilService.getUIStatusFromCandidateStatus(candidate.status);
  }

  getHebPennyProfit() {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.supplierCasePackService.candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(this.supplierCasePackService.candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebPennyProfit(tempCandidate);
    }
  }

  getHebMargin() {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.supplierCasePackService.candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(this.supplierCasePackService.candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebMargin(tempCandidate);
    }
  }

  isHebMarginNegative() {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.supplierCasePackService.candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(this.supplierCasePackService.candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.isHebMarginNegativeOrZero(tempCandidate);
    }
  }

  getPricingType() {
    return this.productService.getPricingType(this.productData, this.upc);
  }

  async onClickSubmit(setupAdditional) {
    this.canNavigate = false;
    let url;
    if (setupAdditional) {
      url = '/setupAdditionalCasePack';
    } else {
      url = '/tasks';
    }

    const savedCandidate = await this.candidateService.saveCandidate(this.supplierCasePackService.getCandidate(), true).toPromise();
    this.supplierCasePackService.setOriginalAndCurrentCandidate(savedCandidate);
    this.candidateService.validateCandidate(this.supplierCasePackService.candidate,
      [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR,
        CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR]).subscribe(data => {
      this.supplierCasePackService.saveAndCompleteTaskAndRouteToUrlWithCandidateId(WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_VENDOR_DATA_DECISION_NO, url, this.supplierCasePackService.candidate.candidateId);
    }, (error) => {
      this.supplierCasePackService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.supplierCasePackService.updatePageErrors(error.error.candidateErrors);
      }
      this.canNavigate = true;
    });
  }

  onClickBack() {
    if (this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.supplierCasePackService.saveCandidateAndNavigate('/additionalCasePackExtendedAttributes', false);
    } else {
      this.supplierCasePackService.saveCandidateAndNavigate('/additionalCasePackDetails', false);
    }
  }

  getPageTitle() {
    return 'Review Additional Case Pack';
  }

  resubmitCandidate() {
    this.canNavigate = false;
    this.resubmittedCandidate = JSON.parse(JSON.stringify(this.supplierCasePackService.getCandidate()));
    this.resubmittedCandidate.candidateId = null;
    this.resubmittedCandidate.status = null;
    this.resubmittedCandidate.vendorComment = null;
    this.resubmittedCandidate.buyerComment = null;
    this.resubmittedCandidate.scaComment = null;

    // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
    this.supplierCasePackService.createCandidateAndNavigate(this.resubmittedCandidate,
      '/setupAdditionalCasePack', true);
    this.workflowService.deleteHistoricInstance(this.supplierCasePackService.getTaskId()).subscribe();

  }

  isRejected() {
    return (CandidateUtilService.getUIStatusFromCandidateStatus(this.supplierCasePackService.getCandidate().status) === 'Rejected');
  }

  historyPanelOpen() {
    this.permissionService.hasPermission('ROLE_VIEW_CANDIDATE_HISTORY').then((hasPermission) => {
      if (hasPermission) {
        this.historyService.getCandidateHistory(this.supplierCasePackService.candidate.candidateId, true);
        this.currentHistoryResults = this.historyService.sortedAudits;
        this.showHistoryPanel = true;
        this.pmReview.openDrawer();
      }
    });
  }

  historyPanelClose() {
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case AdditionalCasePackStepperComponent.UPC_ITEM_CODE: {
        this.supplierCasePackService.saveCandidateAndNavigate('/setupAdditionalCasePack', false);
        break;
      }
      case AdditionalCasePackStepperComponent.CASE_DETAILS: {
        this.supplierCasePackService.saveCandidateAndNavigate('/additionalCasePackDetails', false);
        break;
      }
      case AdditionalCasePackStepperComponent.EXTENDED_ATTRIBUTES: {
        this.onClickBack();
        break;
      }
    }
  }


  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      return;
    }
    this.supplierCasePackService.resetMatHierarchyFields();
    if (this.candidateUtilService.isInProgressCandidate(this.supplierCasePackService.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatHierarchyFromProduct(this.supplierCasePackService.getCurrentCandidateProduct(), this.productData).pipe(
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.supplierCasePackService.getCandidate(),
          this.supplierCasePackService.globalAttributes, this.supplierCasePackService.hierarchyAttributes)),
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierCasePackService.hierarchyAttributes,
            this.supplierCasePackService.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierCasePackService.globalAttributes,
            [], this.supplierCasePackService.warehouseItemAttributes, []);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.supplierCasePackService.getCurrentCandidateProduct()?.globalAttributes, [],
        this.supplierCasePackService.warehouseItemAttributes, []);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }
}
