import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class InboundSpecDays implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Inbound spec',
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      placeholderText: '# of days',
      isRequired: true,
      name: 'inboundSpecDaysId',
      numberCount: 7,
      textInputType: TextInputType.integer,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.inboundSpecDays;
    componentInstance.modelChange.subscribe(x => {
      candidate.inboundSpecDays = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.inboundSpecDays) {
      componentInstance.model = candidate.inboundSpecDays;
    }
  }
}
