export class EmailMessage {
  to: string;
  toname: string;
  from: string;
  fromname: string;
  subject: string;
  body: string;
  cc: string;
  ccname: string;

  constructor(to: string, toname: string, from: string, fromname: string, subject: string, body: string, cc: string, ccname: string) {
    this.to = to;
    this.toname = toname;
    this.from = from;
    this.fromname = fromname;
    this.subject = subject;
    this.body = body;
    this.cc = cc;
    this.ccname = ccname;
  }
}
