import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {PipesModule} from '../pipes/pipes.module';
import { BuyerDropDownFilterComponent } from './buyer-drop-down-filter.component';
import { DirectiveModule } from '../directives/directive.module';

@NgModule({
  declarations: [BuyerDropDownFilterComponent],
  exports: [BuyerDropDownFilterComponent],
  imports: [CommonModule, DirectiveModule, FormsModule, CheckboxModule, PipesModule, ButtonModule]
})
export class BuyerDropDownFilterModule {}
