import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Candidate,
  DisplayReadyUnitCardModel,
  DisplayReadyUnitCardPermissions,
  DisplayReadyUnitCardErrors,
  AttributeConfig,
  AttributeVolumeConfig, CandidateError
} from 'pm-models';

import { DisabledPermissionsMapper, ReadOnlyPermissionsMapper } from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-display-ready-unit-card',
  templateUrl: './display-ready-unit-card.component.html',
  styleUrls: ['./display-ready-unit-card.component.scss']
})
export class DisplayReadyUnitCardComponent implements OnInit {
  @Input()
  model: DisplayReadyUnitCardModel | Candidate;

  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: DisplayReadyUnitCardPermissions;

  @Input()
  errorModel: DisplayReadyUnitCardErrors | CandidateError;

  constructor() {}

  ngOnInit() {}

  isDisplayReadyUnitConfiguration: AttributeConfig = {
    label: 'Display ready unit',
    description: `Let us know if you're sending shelf ready or display ready units.`,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.displayReadyUnit),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.displayReadyUnit),
    isRequired: false,
    defaultValue: false,
    name: 'druSwitch'
  };

  isDisplayReadyUnitChange(e) {
    console.log('display change');
    console.log(e.checked);
    this.model.displayReadyUnit = e.checked;
     if (this.model.displayReadyUnit === false) {
       this.model.displayReadyUnitOrientation = null;
       this.model.displayReadyUnitRowsDeep = null;
       this.model.displayReadyUnitRowsFacing = null;
       this.model.displayReadyUnitRowsHigh = null;
       this.model.displayReadyUnitType = null;
       this.errorModel = null;
    }
    this.modelChange.emit(this.model);
  }

  retailUnitCountConfiguration: AttributeVolumeConfig = {
    label: '# Retail units',
    description: `Tell us how many retail units wide, deep, and high this display ready unit is.`,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.displayReadyUnitRowsDeep),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.displayReadyUnitRowsDeep),
    isHidden: () => !this.model.displayReadyUnit,
    isRequired: true,
    numberCount: 3,
    decimalCount: 0,
    widthPlaceholder: 'Deep',
    heightPlaceholder: 'High',
    lengthPlaceholder: 'Wide',
    volumePlaceholder: '(total)'
  };

  volumeCalc($event) {
    // console.log($event);
  }

  lengthHelpVisible = false;
  lengthHelperOffset = '0px';

  toggleLengthHelp(event, value) {
    this.hideOrientationHelp();
    if (event && event.srcElement && event.srcElement.offsetTop) {
      this.lengthHelperOffset = event.srcElement.offsetTop + 'px';
    }
    this.lengthHelpVisible = value;
  }
  widthHelpVisible = false;
  widthHelperOffset = '0px';

  toggleWidthHelp(event, value) {
    this.hideOrientationHelp();
    if (event && event.srcElement && event.srcElement.offsetTop) {
      this.widthHelperOffset = event.srcElement.offsetTop + 'px';
    }
    this.widthHelpVisible = value;
  }

  heightHelpVisible = false;
  heightHelperOffset = '0px';

  toggleHeightHelp(event, value) {
    this.hideOrientationHelp();
    if (event && event.srcElement && event.srcElement.offsetTop) {
      this.heightHelperOffset = event.srcElement.offsetTop + 'px';
    }
    this.heightHelpVisible = value;
  }

  // ============================== Orientation

  orientationConfiguration: AttributeConfig = {
    label: 'Orientation',
    description: 'How shoppers can access the products in this display ready unit.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.displayReadyUnitOrientation),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.displayReadyUnitOrientation),
    isRequired: true,
    isHidden: () => !this.model.displayReadyUnit,
    options: [
      { label: 'Shoppable from only one side', value: 'stock_facing_by_depth' },
      { label: 'Shoppable from at least 2 adjacent sides', value: 'stock_facing_by_depth_or_facing' }
    ],
    inputGroupClass: 'attribute-radios-block',
    defaultValue: 'stock_facing_by_depth'
  };

  orientationChange(event) {
    this.model.displayReadyUnitOrientation = event;
    this.modelChange.emit(this.model);
  }

  showOrientationHelp = false;
  toggleOrientationHelp() {
    this.showOrientationHelp = !this.showOrientationHelp;
  }
  hideOrientationHelp() {
    this.showOrientationHelp = false;
  }

  druTypeConfiguration: AttributeConfig = {
    label: 'Display ready unit type',
    description: 'The type of display ready unit.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.displayReadyUnitType),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.displayReadyUnitType),
    isRequired: true,
    isHidden: () => !this.model.displayReadyUnit,
    collections: [
      { label: 'Retail Ready packaging', value: 'retail_ready_packaging' },
      { label: 'Display Ready Pallets', value: 'display_ready_pallets' }
    ],
    defaultValue: 'retail_ready_packaging'
  };

  druTypeChange(event) {
    this.model.displayReadyUnitType = event.value;
    this.modelChange.emit(this.model);
  }
}
