<div *ngIf="!hasItems" class="mb-2"></div>
<div *ngIf="hasItems" class="mb-1 mt-1">
  <div *ngFor="let product of candidateProductsModel; let i = index;" class="row inline-block">
    <div class="col-md-12">
      <div *ngIf="isAssociateUpc(product)" class="row inline-block product-list-row">
        <div class="row w-100 ml-0">
          <div class="col-md-11">
            <pm-attribute-display-upc [attribute]="getConfig(product)" [(model)]="product.upc"
                                      [attributeError]="getCandidateProductError(product)"
                                      [(checkDigitModel)]="product.upcCheckDigit"
                                      (validateUpc)="validate(product)"
                                      [state]="getInputState(product)"
                                      (shiftEnterEmitted)="addAssociateUpc()">
            </pm-attribute-display-upc>
          </div>
          <div *ngxPermissionsOnly="['ROLE_BULK_ASSOCIATE']" class="col-md-1">
            <pm-delete-button *ngIf="isList" (deleteButtonClicked)="deleteExistingAssociate(product)"
                              [showConfirmationModal]="false"></pm-delete-button>
          </div>
        </div>
        <div *ngIf="i === 1">
          <div *ngxPermissionsOnly="['ROLE_COMPLIMENTARY_PLU']" class="row complimentary-plu-row">
            <pm-attribute-display-checkbox [isIndented]="false"
                                           [attribute]="isAddComplimentaryPluConfiguration"
                                           [(model)]="product.isAddComplimentaryPlu">
            </pm-attribute-display-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngxPermissionsOnly="['ROLE_BULK_ASSOCIATE']" class="row inline-block add-item-row">
  <button type="button" class="btn btn-link add-item-button p-0 mx-0" (click)="addAssociateUpc()">+ Add UPC</button>
  <p class="add-item-description">&nbsp;or hit Shift + Enter to add multiple items quickly.</p>
</div>
