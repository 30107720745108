<ng-container>
  <div class="row align-items-center request-new-mat-attribute-form-wrapper">
    <div class="col-md-4 request-new-mat-attribute-form">
      <div class="header-row">
        <h2>{{title}}</h2>
        <p>{{subTitle}}<br><br>
          We’ll send your request to our Data Management team for review.
          They will notify you via email once they’ve reviewed your request.
        </p>
      </div>
      <div class="attributes-stacked">

        <pm-attribute-display-text-input *ngIf="matAttributeValueConfiguration"
                                         [attribute]="matAttributeValueConfiguration"
                                         [(model)]="model.newMatAttributeValue">
        </pm-attribute-display-text-input>
        <pm-attribute-display-text-input [attribute]="supplierConfiguration"
                                         [(model)]="model.apNumber">
        </pm-attribute-display-text-input>
        <pm-attribute-display-text-input [attribute]="emailConfiguration"
                                         [(model)]="model.email"
                                         (modelChange)="onEmailChange($event)"
                                         [attributeError]="emailError">
        </pm-attribute-display-text-input>
        <pm-attribute-display-text-input [attribute]="notesConfiguration"
                                         [(model)]="model.notes">
        </pm-attribute-display-text-input>
      </div>
      <div class="row button-row">
        <p-button class="ghost m-2" label="Cancel" (onClick)="cancel()" [tabIndex]="5"></p-button>
        <p-button class="m-2" label="Send Request" [disabled]="isSendRequestDisabled()" (onClick)="sendRequest()" [tabIndex]="0"></p-button>
      </div>
    </div>
  </div>
</ng-container>
