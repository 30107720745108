import { Component } from '@angular/core';

@Component({
  selector: 'pm-grid-group',
  host: {
    class: 'grid-group'
  },
  template: `
    <ng-content></ng-content>
  `
})
export class AttributeGridGroupComponent {}
