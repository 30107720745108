<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>
<ng-template #card>
  <div *ngIf="!!candidateProductModel && !!globalAttributes?.length">

    <pm-card *ngIf="!!productAttributeConfigs?.length" [title]="'Additional product details'" [subtitle]="'Provide additional details for the product.'">
      <div *ngFor="let attributeConfig of productAttributeConfigs">

        <ng-container *ngIf="matUtilService.isTypeaheadConfiguration(attributeConfig)">
          <pm-attribute-display-typeahead [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                          (selection)="onAttributeSelection($event, attributeConfig)"
                                          [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)"
                                          (buttonOptionClicked)="showRequestNewAttributeConfirmationPanel(attributeConfig, $event, rnaMatConfirmOverlay, rnaMatConfirmDiv)">
          </pm-attribute-display-typeahead>
        </ng-container>

        <ng-container *ngIf="matUtilService.isTextInputConfiguration(attributeConfig)">
          <pm-attribute-display-text-input [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                           (modelChange)="onAttributeSelection($event, attributeConfig)"
                                           [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-text-input>
        </ng-container>

        <ng-container *ngIf="matUtilService.isBooleanRadioInputConfiguration(attributeConfig)">
          <pm-attribute-display-radio-boolean [attribute]="attributeConfig"
                                              [model]="getMatAttributeModel(attributeConfig)"
                                              (change)="onAttributeSelection($event, attributeConfig)"
                                              [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-radio-boolean>
        </ng-container>

        <ng-container *ngIf="matUtilService.isDatePickerInputConfiguration(attributeConfig)">
          <pm-attribute-display-date-picker [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                            (modelChange)="onAttributeSelection($event, attributeConfig)"
                                            [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-date-picker>
        </ng-container>
      </div>
    </pm-card>

    <pm-card *ngIf="!!upcAttributeConfigs?.length" [title]="'Additional UPC details'" [subtitle]="'Provide additional details for the UPC.'">
      <div *ngFor="let attributeConfig of upcAttributeConfigs">

        <ng-container *ngIf="matUtilService.isTypeaheadConfiguration(attributeConfig)">
          <pm-attribute-display-typeahead [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                          (selection)="onAttributeSelection($event, attributeConfig)"
                                          [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)"
                                          (buttonOptionClicked)="showRequestNewAttributeConfirmationPanel(attributeConfig, $event, rnaMatConfirmOverlay, rnaMatConfirmDiv)">
          </pm-attribute-display-typeahead>
        </ng-container>

        <ng-container *ngIf="matUtilService.isTextInputConfiguration(attributeConfig)">
          <pm-attribute-display-text-input [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                           (modelChange)="onAttributeSelection($event, attributeConfig)"
                                           [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-text-input>
        </ng-container>

        <ng-container *ngIf="matUtilService.isBooleanRadioInputConfiguration(attributeConfig)">
          <pm-attribute-display-radio-boolean [attribute]="attributeConfig"
                                              [model]="getMatAttributeModel(attributeConfig)"
                                              (modelChange)="onAttributeSelection($event, attributeConfig)"
                                              [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-radio-boolean>
        </ng-container>

        <ng-container *ngIf="matUtilService.isDatePickerInputConfiguration(attributeConfig)">
          <pm-attribute-display-date-picker [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                            (modelChange)="onAttributeSelection($event, attributeConfig)"
                                            [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-date-picker>
        </ng-container>
      </div>
    </pm-card>

    <pm-card *ngIf="!!warehouseItemAttributeConfigs?.length" [title]="'Additional case details'" [subtitle]="'Provide additional details for the case.'">
      <div *ngFor="let attributeConfig of warehouseItemAttributeConfigs">

        <ng-container *ngIf="matUtilService.isTypeaheadConfiguration(attributeConfig)">
          <pm-attribute-display-typeahead [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                          (selection)="onAttributeSelection($event, attributeConfig)"
                                          [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)"
                                          (buttonOptionClicked)="showRequestNewAttributeConfirmationPanel(attributeConfig, $event, rnaMatConfirmOverlay, rnaMatConfirmDiv)">
          </pm-attribute-display-typeahead>
        </ng-container>

        <ng-container *ngIf="matUtilService.isTextInputConfiguration(attributeConfig)">
          <pm-attribute-display-text-input [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                           (modelChange)="onAttributeSelection($event, attributeConfig)"
                                           [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-text-input>
        </ng-container>

        <ng-container *ngIf="matUtilService.isBooleanRadioInputConfiguration(attributeConfig)">
          <pm-attribute-display-radio-boolean [attribute]="attributeConfig"
                                              [model]="getMatAttributeModel(attributeConfig)"
                                              (modelChange)="onAttributeSelection($event, attributeConfig)"
                                              [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-radio-boolean>
        </ng-container>

        <ng-container *ngIf="matUtilService.isDatePickerInputConfiguration(attributeConfig)">
          <pm-attribute-display-date-picker [attribute]="attributeConfig" [model]="getMatAttributeModel(attributeConfig)"
                                            (modelChange)="onAttributeSelection($event, attributeConfig)"
                                            [attributeError]="matUtilService.getAttributeError(attributeConfig.matAttributeId, candidateProductErrorModel)">
          </pm-attribute-display-date-picker>
        </ng-container>
      </div>
    </pm-card>

  </div>
</ng-template>

<div>
  <div #rnaMatConfirmDiv></div>
  <pm-static-overlay-panel #rnaMatConfirmOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'" [hideTransitionOptions]="'0ms'">
    <pm-request-new-attribute-confirm-overlay *ngIf="isShowingRequestAttributePanel"
                                              [title]="'Request a new ' + requestNewMatAttributeFormModel?.attributeBusinessFriendlyDescription + ' value'"
                                              [classOverride]="'col-md-5'"
                                              (cancelClicked)="hidePanel(rnaMatConfirmOverlay)"
                                              (continueButtonClicked)="showRequestNewAttributeFormPanel($event, rnaMatFormOverlay, rnaMatFormOverlayDiv, rnaMatConfirmOverlay)">
    </pm-request-new-attribute-confirm-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatFormOverlayDiv></div>
  <pm-static-overlay-panel #rnaMatFormOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'">
    <pm-request-new-mat-attribute-form *ngIf="isShowingRequestAttributePanel"
                                       (cancelClicked)="hidePanel(rnaMatFormOverlay)"
                                       [(model)]="requestNewMatAttributeFormModel"
                                       (sendRequestClicked)="sendRequestAndCloseModal($event, rnaMatFormOverlay)">
    </pm-request-new-mat-attribute-form>
  </pm-static-overlay-panel>
</div>

