/*******
 *
 * This is a 'fork' of Mortar's Toolbar. It can be deleted if we ever fully integrate Mortar.
 *
 * */


import { Directive } from '@angular/core';

/** A default title to place in a toolbar or toolbar section. */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'pm-toolbar-title',
  host: {
    class: 'mtr-toolbar__title',
  },
})
export class PMToolbarTitleDirective {}
