import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTypeaheadConfig } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class SubDepartment implements BaseAttribute {
  warningText = 'Changing the department may remove existing store authorization requests.';
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'Department',
      description: 'Choose a Department.',
      isRequired: true,
      isDisabled: () => this.isDisabled,
      isReadOnly: () => false,
      name: 'subDepartment',
      displayRef: 'subDepartmentDescription',
      placeholderText: 'Select a Department...',
      searchUrl: '/lookup/subDepartment',
      showExtraButton: true,
      extraButtonText: 'Override',
      ...overrides
    };
  }

  isDisabled: boolean = false;


  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.attribute.warningMessageText = this.warningText;
    this.isDisabled = !candidate.overrideDepartment;
    if (candidate.overrideDepartment) {
      componentInstance.model = candidate.overrideSubDepartment;
      componentInstance.attribute.showWarningMessage = true;
    } else if (candidate.commodity) {
      componentInstance.model = candidate.commodity;
      componentInstance.attribute.showWarningMessage = true;
    }
    componentInstance.isReadOnly();
    componentInstance.selection.subscribe(x => {
      candidate.overrideSubDepartment = x;
      configOverrides.saveDisabled = false;
    });
    if (candidate.overrideDepartment) {
      componentInstance.attribute.extraButtonText = 'Reset to Default';
      componentInstance.attribute.hideDescription = false;
    } else {
      componentInstance.attribute.hideDescription = true;
    }
    componentInstance.extraButtonClicked.subscribe(x => {
      candidate.pssDepartment = null;
      if (candidate.overrideDepartment) {
        candidate.overrideSubDepartment = null;
        componentInstance.attribute.extraButtonText = 'Override';
        configOverrides.saveDisabled = false;
        componentInstance.attribute.hideDescription = true;
        if (candidate.commodity) {
          componentInstance.model = candidate.commodity;
        }
      } else {
        candidate.overrideDepartment = false;
        componentInstance.attribute.extraButtonText = 'Reset to Default';
        componentInstance.attribute.hideDescription = false;
        configOverrides.saveDisabled = true;
      }
      candidate.overrideDepartment = !candidate.overrideDepartment;
      this.isDisabled = !candidate.overrideDepartment;
    });
    componentInstance.modelChange.subscribe(x => {
      if (componentInstance.selection === null) {
        configOverrides.saveDisabled = true;
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate) {
    if (candidate.overrideDepartment) {
      if (componentInstance.model !== candidate.commodity) {
        componentInstance.model = candidate.commodity;
      }
    } else {
      if (componentInstance.model !== candidate.overrideSubDepartment) {
        componentInstance.model = candidate.overrideSubDepartment;
      }
    }
  }
}
