import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTypeaheadConfig } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class CostOwner implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'Cost owner',
      description: 'Select who is responsible for the cost of the product.',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      displayRef: 'displayName',
      placeholderText: 'Select or search for a cost owner...',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.costOwner;
    componentInstance.selection.subscribe(x => {
      candidate.costOwner = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.costOwner) {
      componentInstance.model = candidate.costOwner;
    }
  }
}
