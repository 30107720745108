import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {HttpClientModule} from '@angular/common/http';
import {ProgressSpinnerModule} from '../../shared/progress-spinner/progress-spinner.module';
import {DirectiveModule} from '../directives/directive.module';
import {UploadCandidateComponent} from './upload-candidate.component';
import {RouterModule} from '@angular/router';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';

@NgModule({
  declarations: [UploadCandidateComponent],
  exports: [UploadCandidateComponent, ButtonModule, ProgressSpinnerModule, TooltipModule, RouterModule],
  imports: [CommonModule, ButtonModule, HttpClientModule, ProgressSpinnerModule, DirectiveModule, TooltipModule, RouterModule],
  providers: [{ provide: GrowlService, useValue: undefined }]
})
export class UploadCandidateModule {}

