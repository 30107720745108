import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentUploadReviewPageComponent } from './attachment-upload-review-page.component';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from '../../shared/progress-spinner/progress-spinner.module';
import { DirectiveModule } from '../directives/directive.module';
import {AttachmentTileModule} from '../attachment-tile/attachment-tile.module';

@NgModule({
  declarations: [AttachmentUploadReviewPageComponent],
  exports: [AttachmentUploadReviewPageComponent, ButtonModule, ProgressSpinnerModule, TooltipModule],
  imports: [CommonModule, ButtonModule, HttpClientModule, ProgressSpinnerModule, DirectiveModule, TooltipModule,
    AttachmentTileModule]
})
export class AttachmentUploadReviewPageModule {}
