import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FilteringService } from '../service/filtering.service';


@Component({
  selector: 'app-date-time-filter',
  templateUrl: './date-time-filter.component.html',
  styleUrls: ['./date-time-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateTimeFilterComponent implements OnInit {
  filters: SelectItem[];
  selectedFilter: number;

  constructor(private filteringService: FilteringService) { }

  ngOnInit() {
    this.getDateFilters();
  }

  /**
   * Goes out and grabs the date filter array from the filtering service.
   */
  getDateFilters() {
    this.filters = this.filteringService.dateFilterOptions;
  }

  /**
   * Runs every time the filter by dropdown changes. This updates the selectedDateFilter variable in the filtering service,
   * which will cause the tables to update.
   */
  onFilterChange() {
    this.filteringService.selectedDateFilter.next(this.selectedFilter);
  }

}
