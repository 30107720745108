/**
 * The following rules apply to all UI permissions.
 *
 * If you have ROLE_XYZ-EDIT or ROLE_XYZ-VIEW then generate base ROLE_XYZ that the html page will use to show or not show a component.
 * If you have both ROLE_XYZ-EDIT and ROLE_XYZ-VIEW then remove ROLE_XYZ-VIEW (i.e. escalation to EDIT privilege)
 * Privileges ROLE_XYZ-EDIT and ROLE_XYZ-VIEW are only used in a component itself to decide how to render itself,
 * not in html that includes the component.
 * Exactly 1 WF ROLE (WF_SCA-EDIT, WF_BUYER-EDIT, or WF_VENDOR-EDIT) must be present for each user.
 */
export class Permission {

  constructor(name: string) {
    this.name = name;
  }

  // The base name of the permission. Used to determine if a component is present on the html page
  name: string;

  // If the user can edit the component. Component uses to render itself for edit capabilities.
  hasEdit = false;

  // If the user can view the component. Component uses to render itself for view capabilities.
  hasView = false;
}
