<pm-editor *ngIf="supplierMrtService.getCandidate() && isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-mrt-case-stepper *ngIf="supplierMrtService.hasNewInners(supplierMrtService.getCandidate()) === false"
                          [candidateErrors]="supplierMrtService.getAllSupplierMrtErrors()"
                          [stepIndex]="1" [steps]="supplierMrtService.getNoInnerFlowSteps()"
                          (indexClick)="onClickStepper($event)">
    </app-mrt-case-stepper>
    <app-mrt-case-stepper *ngIf="supplierMrtService.hasNewInners(supplierMrtService.getCandidate())"
                          [candidateErrors]="supplierMrtService.getAllSupplierMrtErrors()"
                          [hasInnerCandidateError]="[supplierMrtService.hasInnerErrors()]"
                          [stepIndex]="1" [steps]="supplierMrtService.getNewInnerFlowSteps()"
                          (indexClick)="onClickStepper($event)">
    </app-mrt-case-stepper>
  </pm-header>
  <pm-title>Let’s set up your MRT.</pm-title>
  <div class="col-md-10" style="margin-top: 25px; margin-left: 5px;">
    <div class="row">
      <label>Provide a nickname for this so it’s easy to find in PAM. We’ll only use this internally.</label>
    </div>
    <div class="row" style="margin-bottom: 25px;">
      <input id="descriptionId" pInputText placeholder="Enter a name or short description..." type="text" maxlength="100"
             minlength="1" accept-charset="UTF-8" [(ngModel)]="supplierMrtService.getCandidate().description" style="width: 80%;">
    </div>
    <div class="row my-3" *ngIf="candidateError?.description">
      <p class="error-message">{{candidateError?.description}}</p>
    </div>
  </div>
  <div class="col-md-10" style="margin-top: 25px; margin-left: 5px;">
    <div class="row">
      <label style="margin-bottom: 0.25rem;">Where the product is shipped from (origin).</label>
    </div>
  </div>
  <div class="col-md-9 attributes-stacked" style="padding-left: 0; padding-right: 60px; margin-bottom: 30px;">
    <!--  TODO: Attribute error reference to new error field location -->
    <pm-attribute-display-typeahead [attribute]="warehouseSupplierConfiguration" [(model)]="supplierMrtService.candidate.lane"
                                    [attributeError]="candidateError?.lane" (selection)="warehouseSupplierChange($event)"  >
    </pm-attribute-display-typeahead>
  </div>

  <h1 style="text-align: left;">Items in MRT</h1>

  <div class="col-md-10" style="margin-bottom: 15px; margin-left: 5px;">
    <div class="row">
      <label>Add products to the MRT.</label>
    </div>
    <div class="row">
      <pm-upc-input
        [(upcModel)]="candidateProduct.upc"
        [(checkDigitModel)]="candidateProduct.upcCheckDigit"
        [(upcType)]="upcType"
        [readOnly]="false"
        [disabled]="false"
        [hasError]="hasError()"
        placeholderText="Enter UPC"
        checkDigitPlaceholderText="Check #"
        [upcOptions]="upcOptions"
        [state]="upcState"
        (upcModelChange)="setUpcItemCodeAndValidate()"
        (checkDigitModelChange)="setUpcItemCodeAndValidate()"
        (shiftEnterEmitted)="addItem()"
        style="width: 80%;">
      </pm-upc-input>
    </div>
    <div class="row">
      <button pButton type="button" label="+ Add item" class="ui-button-link add-item-button" (click)="addItem()"></button>
      <p class="add-item-description">&nbsp;or hit Shift + Enter to add multiple items quickly.</p>
    </div>
    <div class="row" *ngIf="hasError()">
      <p *ngIf="candidateProductError?.upc" class="error-message">{{candidateProductError?.upc}}</p>
      <p *ngIf="candidateProductError?.upcCheckDigit" class="error-message">{{candidateProductError?.upcCheckDigit}}</p>
    </div>
  </div>
  <div *ngIf="isLoadingInners" style="display: flex; align-items: center">
    <pm-progress-spinner [showSpinner]="isLoadingInners" [strokeWidth]="'2'" class="loading-indicator"
                         [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
    <h5 class="ml-3">Fetching product data...</h5>
  </div>
  <div *ngIf="hasItems() && !isLoadingInners" class="col-md-9" style="margin-bottom: 100px; margin-left: 5px;" >
    <div *ngFor="let inner of mrtInners" class="row">
      <div class="col-md-12">

        <div *ngIf="isExistingInner(inner)" class="row pt-3 pb-3">
          <div class="col-md-4">
            <div class="row header-label">
              Item
            </div>
            <div class="row">
              UPC: {{inner.upc}}-{{inner.upcCheckDigit}}
            </div>
            <div class="row attribute-description">
              Status: Activated<br>
              {{getItemCodeString(inner.product)}}<br>
              Product desc: {{inner?.product.productDescription}}
            </div>
          </div>
          <div class="col-md-3 attributes-stacked">
            <pm-attribute-display-text-input [attribute]="quantityConfiguration"
                                             [(model)]="inner.quantity"
                                             [attributeError]="getExistingInnerError(inner.upc)?.quantity">
            </pm-attribute-display-text-input>
          </div>
          <div class="col-md-1">
            <pm-delete-button (deleteButtonClicked)="deleteExistingInner(inner)" [showConfirmationModal]="false"></pm-delete-button>
          </div>
        </div>

        <div *ngIf="isCandidateInner(inner)" class="row pt-3 pb-3">
          <div class="col-md-4">
            <div class="row header-label">
              Item
            </div>
            <div class="row">
              UPC: {{supplierMrtService.getCurrentCandidateProducts(inner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(inner).upcCheckDigit}}
            </div>
            <div class="row attribute-description">
              Status: {{getCandidateInnerStatus(inner)}}<br>
              Item code: -<br>
              Product desc: {{supplierMrtService.getCurrentCandidateProducts(inner).description ? supplierMrtService.getCurrentCandidateProducts(inner).description : '-'}}
            </div>
          </div>
          <div class="col-md-3 attributes-stacked">
            <pm-attribute-display-text-input [attribute]="quantityConfiguration"
                                             [(model)]="inner.quantity"
                                             [attributeError]="getCandidateInnerError(inner.candidateId)?.quantity">
            </pm-attribute-display-text-input>
          </div>
          <div class="col-md-1">
            <pm-delete-button (deleteButtonClicked)="deleteCandidateInner(inner)" [showConfirmationModal]="false"></pm-delete-button>
          </div>
        </div>

      </div>
    </div>
    <hr>
  </div>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate" ></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="isNextDisabled()"
              pTooltip="{{isNextDisabled() ? 'An MRT must contain 2 or more UPCs' : ''}}"></p-button>
  </pm-footer>
</pm-editor>
