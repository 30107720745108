import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate, AttributeConfig } from 'pm-models';

export class Sellable implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Sellable',
      isRequired: true,
      isReadOnly: () => false,
      description: `Indicate if this is a product H-E-B will sell to customers or if it's for internal store use only.`,
      defaultValue: false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.productType === 'SELLABLE';

    componentInstance.change.subscribe(event => {
      candidate.productType = event.checked ? 'SELLABLE' : 'NON_SELLABLE';
      this.sellableTypeChange(candidate);
    });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate) {
    componentInstance.model = candidate.productType === 'SELLABLE';
  }


  sellableTypeChange(candidate: Candidate) {
    candidate.merchandiseType = null;

    if (candidate.productType === 'SELLABLE') {
      candidate.retailXFor = null;
      candidate.retailPrice = null;
    } else {
      candidate.retailXFor = 1;
      candidate.retailPrice = '0.0';
      candidate.suggestedRetailPrice = null;
      candidate.suggestedXFor = null;
      candidate.retailSize = '1';
    }
  }
}
