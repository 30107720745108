<div>
  <div class="candidate-upload-container">
    <input type="file" class="inputfile" #fileInput id="fileInput" style="display:none;"
           accept="{{getFileTypeString()}}" (change)="onFilesAdded(fileInput.files)"
           (click)="fileInput.value = null" value="" multiple>

    <div class="ui-upload-container" *ngIf="!attachment">
      <div pmDragDrop (files)="filesDropped($event)" *ngIf="!isInProgress" class="p-3">

        <div class="upload-container">
          <img class="upload-icon" src="/assets/images/cloud-upload.svg">
          <h3 class="img-sub-title">Drop your file here</h3>

          <div>or
            <a [routerLink]="" queryParamsHandling="preserve" onClick="document.getElementById('fileInput').click()">
              browse your computer
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-attachments-container" *ngIf="attachment">
      <ul *ngIf="attachment" class="upload-candidate-list">
        <li class="attachment">
          <div class="row ml-0">
            <div class="col-md-10 file-column">
              <img class="file-icon" src="/assets/images/file-icon.svg">
              <label class="file-name-doc">{{attachment.name}}</label>
            </div>
            <div class="col-md-2">
              <pm-progress-spinner [showSpinner]="isInProgress" [style]="{width: '2rem', height: '2rem', left: '40%', top: '0.5rem'}">
              </pm-progress-spinner>
            </div>
          </div>
        </li>
      </ul>

    </div>
    <div class="image-footer">
      <div *ngIf="error" class="upload-error-message mt-2">
        <span [innerHTML]="fileErrors()"></span>

      </div>
    </div>

    <div *ngIf="!attachment">
      <p class="upload-container-description mt-1 mb-0">
        Accepted file formats: CSV, XLSX, XLS
      </p>
      <p class="upload-container-description">
        The column headers in the uploaded file must match the
        <a [href]="'/assets/documents/HEB Spreadsheet for Volume Uploads.xls'"
           [download]="'HEB Spreadsheet for Volume Uploads.xls'"> template
        </a>.
      </p>
    </div>
    <div class="mt-5 text-right" *ngIf="attachment || isInProgress">
      <p-button class="ghost" label="Back to home" (onClick)="onClickBackToHome()"></p-button>
    </div>
  </div>

</div>
