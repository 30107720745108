import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeTextInputConfig,
  AttributeTypeaheadConfig, AttributeUPCConfig,
  AttributeVolumeConfig,
  Candidate,
  CandidateError, CandidateProduct, CandidateProductError,
  ProductPackagingCardErrors,
  ProductPackagingCardModel,
  ProductPackagingCardPermissions,
  TextInputType
} from 'pm-models';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper, VisiblePermissionsMapper} from '../attribute-permissions-mapper';
import {UPCInputState} from '../../shared/upc-inputs/base-upc-input/base-upc-input.component';

@Component({
  selector: 'pm-product-packaging-card',
  templateUrl: './product-packaging-card.component.html',
  styleUrls: ['./product-packaging-card.component.scss']
})
export class ProductPackagingCardComponent implements OnInit {
  @Input()
  model: Candidate | ProductPackagingCardModel;

  @Input()
  productModel: CandidateProduct | ProductPackagingCardModel;

  @Input()
  permissions: ProductPackagingCardPermissions;

  @Input()
  packagingTitle: string;

  @Input()
  packagingSubtitle: string;

  @Input()
  associateUpcConfig: false;

  @Input()
  retailSizeLabel: string;

  @Input()
  errorModel: ProductPackagingCardErrors | CandidateError;

  @Input()
  productErrorModel?: ProductPackagingCardErrors | CandidateProductError;

  @Input()
  state: UPCInputState;

  _packageTypes;
  @Input()
  get packageTypes() {
    return this._packageTypes;
  }
  set packageTypes(e) {
    this._packageTypes = e;
    this.packageTypeConfiguration.collections = this._packageTypes;
  }

  _unitOfMeasure;
  @Input()
  get unitOfMeasure() {
    return this._unitOfMeasure;
  }
  set unitOfMeasure(e) {
    this._unitOfMeasure = e;
    this.unitOfMeasureConfiguration.collections = this._unitOfMeasure;
  }

  @Output() modelChange = new EventEmitter<any>();

  @Output() productModelChange = new EventEmitter<any>();

  @Output() validateDepositUPC = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (!this.retailSizeLabel) {
      this.retailSizeLabel = 'Retail size';
    }

    if (!this.packagingTitle) {
      this.packagingTitle = 'Packaging';
    }

    if (!this.packagingSubtitle) {
      this.packagingSubtitle = 'Provide information about the unit\'s packaging and contents.';
    }

    if (this.associateUpcConfig) {
      this.checkAssociateUpcValues();
    }

  }

  packageTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Package type',
    description: `Choose the option that best describes this unit's packaging as customers will see it in the store.`,
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.packageType),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.packageType),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.packageType),
    name: 'packageTypeId',
    displayRef: 'description',
    placeholderText: 'Select the package type...',
    collections: this.packageTypes
  };

  packageTypeChange(event) {
    this.model.packageType = event;
    this.modelChange.emit(this.model);
  }

  dimensionsConfiguration: AttributeVolumeConfig = {
    label: 'Dimensions',
    description: `Enter the length, width, and height of the product's shelf packaging in inches.
     We'll calculate the volume in cubic feet.`,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.productLength),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.productLength),
    numberCount: 7,
    decimalCount: 2,
    name: '',
    isRequired: true
  };
  volumeCalc($event) {
    // console.log($event);
  }

  weightConfiguration: AttributeTextInputConfig = {
    label: 'Weight',
    description: 'The total weight of the unit, in ounces (oz).',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.productWeight),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.productWeight),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.productWeight),
    isRequired: true,
    decimalCount: 2,
    numberCount: 7,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: 'oz',
    name: 'weightId'
  };
  productWeightChange(event) {}

  unitOfMeasureConfiguration: AttributeTypeaheadConfig = {
    label: 'Unit of measure',
    description: `The unit of measure for the retail unit's total volume.`,
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.unitOfMeasure),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.unitOfMeasure),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.unitOfMeasure),
    name: 'uomId',
    displayRef: 'description',
    placeholderText: 'Select measurement...',
    collections: this.unitOfMeasure
  };

  unitOfMeasureChange(event) {
    this.model.unitOfMeasure = event;
    this.modelChange.emit(this.model);
  }

  getRetailSize() {
    const retailSizeConfiguration: AttributeTextInputConfig = {
      label: this.retailSizeLabel ? this.retailSizeLabel : 'Retail size',
      description: 'This is the retail unit size. Example: six 12oz cans would read 6/12oz',
      isRequired: true,
      name: 'retailSizeId',
      isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.retailSize),
      isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.retailSize),
      isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.retailSize),
      textInputType: TextInputType.text,
      inputGroupClass: 'ui-narrow-input',
      placeholderText: '',
      maxLength: 6
    };

    return retailSizeConfiguration;
  }

  retailSizeChange(event) {
    this.modelChange.emit(this.model);
  }

  volumeConfiguration: AttributeTextInputConfig = {
    label: 'Unit Size',
    description: 'This is the total volume for the retail unit. Example: Six 12oz cans would read: 72',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.totalVolume),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.totalVolume),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.totalVolume),
    isRequired: true,
    decimalCount: 2,
    name: 'totalVolumeId',
    numberCount: 9,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: ''
  };
  depositUpcConfiguration: AttributeUPCConfig = {
    label: 'Deposit Upc',
    description: 'If this product has components the customer will return after purchase (e.g. a keg or a portable carpet cleaner' +
      'we need a UPC in order to collect a refundable deposit).',
    isRequired: false,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.depositScanCodeId),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.depositScanCodeId),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.depositScanCodeId),
    placeholderText: 'Deposit UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'depositUpcId',
  };

  get isSellable(): boolean {
    return this.model && this.model.productType === 'SELLABLE';
  }

  totalVolumeChange(event) {}

  private checkAssociateUpcValues() {
    if (this.model) {
      if (this.model.productLength === undefined) {
        this.model.productLength = '--';
      }
      if (this.model.productWidth === undefined) {
        this.model.productWidth = '--';
      }
      if (this.model.productHeight === undefined) {
        this.model.productHeight = '--';
      }
      if (this.model.unitOfMeasure === undefined) {
        this.model.unitOfMeasure = '--';
      }
      if (this.model.retailSize === undefined) {
        this.model.retailSize = '--';
      }
      if (this.model.totalVolume === undefined) {
        this.model.totalVolume = 0;
      }
    }
  }

  validateDepositUpcEvent() {
    if (this.productModel.depositScanCodeId && this.productModel.depositScanCodeCheckDigit) {
      this.validateDepositUPC.emit();
    }
  }
}
