import {NgModule} from '@angular/core';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {CardModule} from '../../shared/card/card.module';
import {CommonModule} from '@angular/common';
import {ScaleInformationCardComponent} from './scale-information-card.component';

@NgModule({
  declarations: [ScaleInformationCardComponent],
  exports: [ScaleInformationCardComponent, AttributeDisplayModule, CardModule],
  imports: [CommonModule, AttributeDisplayModule, CardModule]
})
export class ScaleInformationCardModule {}
