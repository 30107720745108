import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  AttributeUPCConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CaseDetailsCardModel,
  CaseDetailsCardPermissions,
  SubDepartment,
  TextInputType
} from 'pm-models';
import {UPCInputState} from '../../shared/upc-inputs/base-upc-input/base-upc-input.component';
import {
  DisabledPermissionsMapper,
  ReadOnlyPermissionsMapper,
  VisiblePermissionsMapper
} from '../attribute-permissions-mapper';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import { CostService } from '../../../../../../src/app/2.0.0/service/cost.service';

@Component({
  selector: 'pm-case-details-card',
  templateUrl: './case-details-card.component.html',
  styleUrls: ['./case-details-card.component.scss']
})
export class CaseDetailsCardComponent implements OnInit {

  @Input() canEditDepartment: boolean = false;

  @Input() canEditPssDepartment: boolean = false;

  @Input()
  candidateModel: Candidate | CaseDetailsCardModel;

  @Input()
  candidateProductModel: CandidateProduct | CaseDetailsCardModel;

  @Input()
  permissions: CaseDetailsCardPermissions;

  @Input()
  upcState: UPCInputState;

  @Input()
  candidateErrorModel: CandidateError;

  @Input()
  candidateProductErrorModel: CandidateProductError;

  @Input()
  setPharmacyCodeDateDefaults: boolean = false;

  _commodities;
  @Input()
  get commodities() {
    return this._commodities;
  }

  set commodities(e) {
    this._commodities = e;
  }

  public subCommodities: any;
  public pssDepartments: any;

  @Output() candidateModelChange = new EventEmitter<any>();
  @Output() candidateProductModelChange = new EventEmitter<any>();
  @Output() validateUPC = new EventEmitter<any>();
  @Output() buyerChange = new EventEmitter<any>();
  @Output() masterPackChange = new EventEmitter<any>();
  @Output() overrideSubDepartmentChange = new EventEmitter<any>();

  constructor(private lookupService: LookupService, public costService: CostService) { }

  ngOnInit() {
    // inbound spec is required if warehouse reaction days or guarantee to store days are not hidden.
    this.inboundSpecConfiguration.isRequired = this.permissions &&
      (!VisiblePermissionsMapper(this.permissions,  this.permissions.warehouseReactionDays) ||
        !VisiblePermissionsMapper(this.permissions,  this.permissions.guaranteeToStoreDays));
    if (!this.pssDepartmentConfiguration.collections || this.pssDepartmentConfiguration.collections.length === 0) {
      this.getPssDepartments();
    }
  }

  get channelModel(): string {
    return (this.candidateModel.warehouseSwitch === true) ? 'warehouse' : 'dsd';
  }

  buyerConfiguration: AttributeTypeaheadConfig = {
    label: 'Buyer',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.buyer),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.buyer),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.buyer),
    description: 'Choose the H-E-B buyer you\'re working with.',
    name: 'Buyer',
    idRef: 'buyerId',
    displayRef: 'displayName',
    displaySubRef: '',
    searchUrl: '/lookup/buyer',
    placeholderText: 'Type to search for a Buyer...',
    showAddlInfo: true
  };

  channelConfiguration: AttributeConfig = {
    label: 'Channel',
    description: 'Let us know where you\'ll send this product.',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.distributionChannel),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.distributionChannel),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.distributionChannel),

    options: [
      {
        value: 'warehouse', label: 'Warehouse',
        disabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.warehouseSwitch)
          || ReadOnlyPermissionsMapper(this.permissions, this.permissions.warehouseSwitch)
      },
      {
        value: 'dsd', label: 'Direct (DSD)', disabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.dsdSwitch)
          || ReadOnlyPermissionsMapper(this.permissions, this.permissions.dsdSwitch)
      }
    ],
  };


  channelChange(event) {
    this.candidateModel.dsdSwitch = (event === 'dsd');
    this.candidateModel.warehouseSwitch = (event === 'warehouse');

    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }


  caseUPCConfiguration: AttributeUPCConfig = {
    label: 'Case UPC',
    description: `Enter the UPC and check digit for
    the case this product will arrive in. You can’t enter a UPC that already exists in our system.`,
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.caseUpc),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.caseUpc),
    isHidden: () => this.isCaseUpcHidden(),
    placeholderText: 'Case UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'caseUpcId'
  };

  // hide case upc if permissions says, or it's a dsd only item
  isCaseUpcHidden() {
    return  VisiblePermissionsMapper(this.permissions, this.permissions.caseUpc) ||
      this.isDsdOnly();

  }

  // hide case upc if permissions says, or it's a dsd only item
  isMasterPackQuantityHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.masterPack) || !this.isDsdOnly() ||
      (!this.isDsdOnly() && this.candidateModel.productType !== Candidate.NON_SELLABLE);
  }

  // hide case upc if permissions says, or it's a dsd only item
  isItemWeightTypeHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.itemWeightType) || this.isDsdOnly();
  }

  isCaseDescriptionHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.caseDescription);
  }

  upcChange(event) {
    this.candidateProductModel.caseUpc = event;
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }

  validateUpcEvent(e) {
    if (this.candidateProductModel.caseUpc && this.candidateProductModel.caseUpcCheckDigit) {
      this.validateUPC.emit(e);
    }
  }

  caseDescriptionConfiguration: AttributeTextInputConfig = {
    label: 'Case description',
    description: `Provide a short description of the case. Customers won't see this.`,
    placeholderText: 'Description',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.caseDescription),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.caseDescription),
    isHidden: () => this.isCaseDescriptionHidden(),
    isRequired: true,
    name: 'caseDescriptionId',
    charLimit: 30
  };

  descriptionChange(event) {
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }

  totalRetailUnitsConfiguration: AttributeTextInputConfig = {
    label: 'Total retail units',
    description: 'The total number of units in the master pack.',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.masterPack),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.masterPack),
    isHidden: () => this.isMasterPackQuantityHidden(),
    textInputType: TextInputType.integer,
    placeholderText: '# Units',
    inputGroupClass: 'ui-narrow-input',
    name: 'totalRetailUnitsId',
    maxLength: 3
  };

  totalRetailUnitsChange(event) {
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
    this.masterPackChange.emit(this.candidateModel.masterPack);
    this.costService.setInnerListCost(<Candidate>this.candidateModel);
    this.costService.setUnitCost(<Candidate>this.candidateModel);
    this.candidateModelChange.emit(this.candidateModel);
  }

  vendorProductCodeConfiguration: AttributeTextInputConfig = {
    label: 'VPC',
    description: 'Enter the manufacturer or vendor product code.',
    placeholderText: 'Enter VPC...',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.vendorProductCode),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.vendorProductCode),
    isRequired: true,
    name: 'vpcId',
    maxLength: 20
  };

  vendorProductCodeChange(event) {
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }

  countryOfOriginConfiguration: AttributeTypeaheadConfig = {
    label: 'Country of origin',
    description: '',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.countryOfOrigin),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.countryOfOrigin),
    name: 'countryOfOriginId',
    displayRef: 'description',
    placeholderText: 'Select or search a country...',
    searchUrl: '/lookup/countryOfOrigin'
  };

  countryOfOriginChange(event) {
    this.candidateProductModel.countryOfOrigin = event;
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);  }


  weightConfiguration: AttributeConfig = {
    label: 'Weight',
    description: 'Weight can impact how H-E-B pays for this case.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.itemWeightType),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.itemWeightType),
    isHidden: () => this.isItemWeightTypeHidden(),
    isRequired: true,
    options: [
      { label: 'Fixed Weight', value: 'None' },
      { label: 'Catch Weight', value: 'Catch Weight' },
      { label: 'Variable Weight', value: 'Variable Weight' }
    ],
    defaultValue: 'None'
  };

  weightChange(event) {
    this.candidateModel.itemWeightType = event;
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }

  codeDateConfiguration: AttributeConfig = {
    label: 'Code date',
    description: 'Let us know if this pack has an expiration date.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.codeDate),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.codeDate),
    isHidden: () => this.isCodeDateHidden(),
    isRequired: false,
    defaultValue: false,
    name: 'codeDateId'
  };

  pssDepartmentConfiguration: AttributeTypeaheadConfig = {
    label: 'PSS Department',
    description: 'Select the point of sale sub-department.',
    isRequired: true,
    isDisabled: () => this.isPssDepartmentDisabled(),
    isReadOnly: () => false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.pssDepartment),
    name: 'pssDepartmentId',
    displayRef: 'displayName',
    placeholderText: 'Select a PSS Department...',
    idRef: 'pssDepartmentId',
    collections: this.pssDepartments
  };

  isPssDepartmentDisabled() {
    return !this.candidateModel || !this.candidateModel.commodity ||
      (this.permissions.pssDepartment && this.permissions.pssDepartment.isDisabled);
  }

  // code date is hidden if it's not warehouse.
  isCodeDateHidden() {
    return VisiblePermissionsMapper(this.permissions,  this.permissions.codeDate) ||
      !this.candidateModel.warehouseSwitch;
  }

  codeDateChange(e) {
    this.candidateModel.codeDate = e.checked;

    if (this.setPharmacyCodeDateDefaults && e.checked) {
      this.candidateModel.maxShelfLife = 1825;
      this.candidateModel.inboundSpecDays = 270;
      this.candidateModel.warehouseReactionDays = 210;
      this.candidateModel.guaranteeToStoreDays = 180;
    } else {
      this.candidateModel.maxShelfLife = null;
      this.candidateModel.inboundSpecDays = null;
      this.candidateModel.warehouseReactionDays = null;
      this.candidateModel.guaranteeToStoreDays = null;
    }

    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }

  maxShelfLifeConfiguration: AttributeTextInputConfig = {
    label: 'Max shelf life',
    description: 'The maximum numbers of days this product is shelf-stable.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.maxShelfLife),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.maxShelfLife),
    isHidden: () => !this.candidateModel.codeDate,
    placeholderText: '# of days',
    name: 'maxShelfLifeId',
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input'
  };

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'The number of days between the product\'s arrival date to the warehouse and the product\'s ' +
      'expiration date that H-E-B is guaranteed to sell through the product.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.inboundSpecDays),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.inboundSpecDays),
    isHidden: () => !this.candidateModel.codeDate,
    placeholderText: '# of days',
    isRequired: false,
    numberCount: 7,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input'
  };


  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () =>
      VisiblePermissionsMapper(this.permissions,  this.permissions.warehouseReactionDays) ||
      !this.candidateModel.codeDate,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () =>
      VisiblePermissionsMapper(this.permissions,  this.permissions.guaranteeToStoreDays) ||
      !this.candidateModel.codeDate,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
  };

  buyerInitCallback(event) {
    this.buyerChange.emit(event);
  }

  onBuyerChange(event) {
    if (!event || !event.buyerId) {
      this.candidateModel.buyer = null;
      this.candidateModelChange.emit(this.candidateModel);
      this.buyerChange.emit(event);
    } else {
      this.candidateModel.buyer = event;
      this.candidateModelChange.emit(this.candidateModel);
      this.buyerChange.emit(event);
    }
  }

  isDsdOnly() {
    return !this.candidateModel.warehouseSwitch && this.candidateModel.dsdSwitch;
  }

  dealOfferedConfiguration: AttributeConfig = {
    checkboxLabel: 'List cost deal',
    label: 'Deal offered',
    // tslint:disable-next-line:max-line-length
    description: 'Let us know if you plan to offer a deal. If we accept this candidate, you can set up the deal in our Cost & Deals app.',
    isRequired: false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.dealOffered),
    name: 'dealOfferedName',
    defaultValue: false
  };

  dealOfferedChange(event) {

  }

  departmentConfiguration: AttributeTypeaheadConfig = {
    label: 'Department',
    description: 'Choose a department.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.subCommodity),
    placeholderText: 'Department Name',
    displayRef: 'displayName',
    searchUrl: '/lookup/subDepartment',
    idRef: 'normalizedId'
  };

  defaultDepartmentConfiguration: AttributeTextInputConfig = {
    label: 'Department',
    description: '',
    name: 'departmentId',
    isDisabled: () => true,
    isReadOnly: () => true,
    inputGroupClass: 'typeahead-width',
    placeholderText: ''
  };

  getDepartment() {
    if (this.candidateModel.commodity) {
      return this.candidateModel.commodity.departmentDescription + ' - ' +
        this.candidateModel.commodity.subDepartmentDescription;
    }
    return '';
  }

  onSubCommodityDepartmentChange(event: SubDepartment) {
    if (event) {
      this.candidateModel.overrideSubDepartment = event;
    } else {
      this.candidateModel.overrideSubDepartment = null;
    }
    this.candidateModel.pssDepartment = null;
    this.getPssDepartments();
    this.overrideSubDepartmentChange.emit();
  }

  toggleDepartmentOverride() {
    if (this.candidateModel.commodity) {
      this.candidateModel.overrideDepartment = !this.candidateModel.overrideDepartment;
      if (!this.candidateModel.overrideDepartment) {
        this.candidateModel.overrideSubDepartment = null;
      }
      this.overrideSubDepartmentChange.emit();
    }
    this.candidateModel.pssDepartment = null;
    this.getPssDepartments();
  }

  pssDepartmentChange(event) {
    this.candidateModel.pssDepartment = event;
  }

  getPssDepartments() {
    if (!this.candidateModel.overrideDepartment) {
      if (this.candidateModel.commodity && this.candidateModel.commodity.subDepartment == null) {
        // Make call to get sub department with pss departments when commodity does not have department info.
        this.lookupService.findSubDepartment(this.candidateModel.commodity.departmentId + this.candidateModel.commodity.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
          this.pssDepartmentConfiguration.collections = this.pssDepartments;
        });
      } else {
        this.pssDepartments = this.candidateModel.commodity.subDepartment.pssDepartments;
        this.pssDepartmentConfiguration.collections = this.pssDepartments;
      }
    } else {
      if (this.candidateModel.overrideSubDepartment.pssDepartments === null || this.candidateModel.overrideSubDepartment.pssDepartments === undefined) {
        this.lookupService.findSubDepartment(this.candidateModel.overrideSubDepartment.departmentId + this.candidateModel.overrideSubDepartment.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
          this.pssDepartmentConfiguration.collections = this.pssDepartments;
        });
      } else {
        this.pssDepartments = this.candidateModel.overrideSubDepartment?.pssDepartments;
        this.pssDepartmentConfiguration.collections = this.pssDepartments;
      }
    }
  }
}
