import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeModalComponent, DynamicContentDirective } from './attribute-modal.component';
import { ButtonModule } from 'primeng/button';

import { DialogService } from 'primeng/dynamicdialog';
import { AttributeDisplayModule } from '../shared/attribute-display/attribute-display.module';

@NgModule({
  declarations: [AttributeModalComponent, DynamicContentDirective],
  exports: [AttributeModalComponent],
  entryComponents: [AttributeModalComponent],
  imports: [CommonModule, ButtonModule, AttributeDisplayModule],
  providers: [DialogService]
})
export class AttributeModalModule {}
