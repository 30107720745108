import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {
  AttachmentsCardPermissions,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProductError,
  CandidateValidatorType,
  CaseDetailsCardPermissions,
  Commodity,
  CostCardPermissions,
  HEBDetailsCardPermissions,
  ImportedProductCardPermissions,
  MasterPackDetailsCardPermissions,
  MerchandisingInfoCardPermissions,
  OmiRemarksCardPermissions,
  SupplierDetailsCardPermissions
} from 'pm-models';
import {UPCInputState} from 'pm-components';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {ItemWeightTypeService} from '../../service/item-weight-type.service';
import {forkJoin} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-pia-mrt-case-details',
  templateUrl: './pia-mrt-case-details.component.html',
  styleUrls: ['./pia-mrt-case-details.component.scss']
})
export class PiaMrtCaseDetailsComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private growlService: GrowlService, private lookupService: LookupService,
              private candidateUtilService: CandidateUtilService, private itemWeightTypeService: ItemWeightTypeService) { }

  readonly hebDetailsCardPermissions: HEBDetailsCardPermissions = {
    isReadOnly: false,
    sellableType: {
      isHidden: true
    },
    productType: {
      isDisabled: true,
      isHidden: true
    },
    merchandiseType: {
      isHidden: true
    },
    description: {
      isHidden: true
    }
  };

  supplierDetailsCardPermissions: SupplierDetailsCardPermissions = {
    isReadOnly: false,
    vendor: {
      isHidden: true
    },
    brand: {
      isHidden: true
    },
    subBrand: {
      isHidden: true
    }
  };

  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false,
    unitCost: {
      isHidden: true
    },
    innerCost: {
      isHidden: true
    },
    pennyProfit: {
      isHidden: true
    },
    marginPercent: {
      isHidden: true
    }
  };

  readonly masterPackDetailsCardPermissions: MasterPackDetailsCardPermissions = {
    isReadOnly: false,
    masterPack: {
      isHidden: true
    }
  };

  readonly merchandisingInfoCardPermissions: MerchandisingInfoCardPermissions = {
    isReadOnly: false,
    merchandiseType: {
      isHidden: true
    },
    season: {
      isHidden: false
    },
    seasonYear: {
      isHidden: false
    },
    pssDepartment: {
      isHidden: true
    },
    likeId: {
      isHidden: true
    },
    numberOfStores: {
      isHidden: true
    }
  };

  readonly attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };

  readonly omiRemarksPermissions: OmiRemarksCardPermissions = {
    isReadOnly: false,
  };

  readonly importProductCardPermissions: ImportedProductCardPermissions = {
    isReadOnly: false
  };

  public costCardSubTitle: string = 'Tell us how much this MRT costs.';
  public costCardMasterListCostDesc: string = 'How much H-E-B will pay for the entire MRT.';
  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError = new CandidateProductError();
  public isViewingPage = true;
  private commodities: Commodity[] = undefined;
  public upcState: UPCInputState;
  public canClickSubmit = true;
  public productData: any;
  public inputCostOwners: any = [];
  public containerSizes: any;
  public incoTermsList: any;
  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions;
  public canNavigate = true;

  getAdditionalCasePackBasicsCardPermissions(): CaseDetailsCardPermissions {
    return {
      isReadOnly: false,
      itemWeightType: {
        isReadOnly: false,
        isDisabled: !this.isTiedToCatchOrVariableWeightBuyer()
      },
      buyer: {
        isHidden: true
      },
      dsdSwitch: {
        isDisabled: true
      },
      warehouseSwitch: {
        isDisabled: true
      },
      dealOffered: {
        isHidden: true
      }
    };
  }

  ngOnInit() {

    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierMrtService.resetService();
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          if (candidate.candidateType === Candidate.MRT) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.resetErrors();
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierMrtService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  initializeData() {
    forkJoin([this.supplierMrtService.setInnerCandidates(), this.supplierMrtService.setExistingInnerProducts()])
      .pipe(
        switchMap(() => this.supplierMrtService.getCostOwners()),
        tap(costOwners => this.inputCostOwners = costOwners)
      ).subscribe();

    this.lookupService.findAllContainerSizes().subscribe(containerSizes => {
      this.containerSizes = containerSizes;
    });
    this.lookupService.findAllIncoTerms().subscribe(incoTermsList => {
      this.incoTermsList = incoTermsList;
    });
    this.candidateError = this.supplierMrtService.getMrtCaseDetailsError();
    this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
    if (this.supplierMrtService.getCandidate().buyer && this.supplierMrtService.getCandidate().buyer.buyerId) {
      this.setCommoditiesAndSubCommodities(this.supplierMrtService.getCandidate().buyer.buyerId);
    }
  }

  onClose() {
    this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
    this.supplierMrtService.saveCandidateAndNavigate('/tasks', null);
  }

  /**
   * Callback when a buyer is selected. Buyers have associated Commodities that can determine casepack weight options.
   * @param updatedBuyer
   */
  public updateBuyerCallBack(updatedBuyer) {
    this.lookupService.findAllCommoditiesByBuyerId(updatedBuyer.buyerId).subscribe(data => {
      this.commodities = data;
      if (updatedBuyer.buyerId !== this.supplierMrtService.getOriginalCandidate().buyer.buyerId) {
        this.supplierMrtService.getCandidate().itemWeightType = null;
      }
    });
  }

  onBuyerChange(event) {
    this.updateBuyerCallBack(event);
  }

  validateUpc() {
    if (this.candidateProductError === undefined) {
      return;
    }

    this.upcState = UPCInputState.loading;
    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(),
      [CandidateValidatorType.CASE_UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      if (this.candidateProductError) {
        this.candidateProductError.caseUpc = undefined;
      }
    }, (error) => {
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const candidateProductId = this.supplierMrtService.getCandidateProduct(0).id;
        const candidateErrors: CandidateError = error.error.candidateErrors;
        const candidateProductError: CandidateProductError =
          candidateErrors.candidateProductErrors[candidateProductId];

        if (this.candidateError.candidateProductErrors.size === 0 ||
          !this.candidateError.candidateProductErrors[candidateProductId]) {

          this.candidateError.candidateProductErrors.set(candidateProductId,
            candidateProductError);
          this.candidateProductError = candidateProductError;
          this.candidateError.itemWeightType = candidateErrors.itemWeightType;
          if (this.candidateProductError.caseUpc ||  this.candidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.supplierMrtService.setMrtCaseDetailsError(candidateErrors);
            this.upcState = UPCInputState.valid;
          }
        } else {
          this.candidateProductError.caseUpc = candidateProductError.caseUpc;
          this.candidateProductError.caseUpcCheckDigit = candidateProductError.caseUpcCheckDigit;
          this.candidateError.itemWeightType = candidateErrors.itemWeightType;
          if (this.candidateProductError.caseUpc ||  this.candidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.supplierMrtService.setMrtCaseDetailsError(candidateErrors);
            this.upcState = UPCInputState.valid;
          }
        }
      } else {
        this.upcState = UPCInputState.invalid;
        this.growlService.addError(error.message);
      }
    });
  }

  onClickBack() {
    this.canNavigate = false;
    this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
    if (this.hasItemsToSetup()) {
      this.supplierMrtService.saveCandidateAndNavigate('/piaSetupNewMrtItems', null);
    } else {
      this.supplierMrtService.saveCandidateAndNavigate('/piaSetupMrt', null);
    }
  }

  onClickNext() {
    this.jumpToPage('/piaMrtWarehouseSelection');
  }

  hasItemsToSetup() {
    if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners &&
      this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
        // if there's inners that aren't drafts (so created by this mrt)
        if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate.candidateType === Candidate.MRT_INNER
          && !this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].draft) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      // this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, set the commodity w/ sub com list
      if (commodity) {
        this.supplierMrtService.getCandidate().commodity = commodity;
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.supplierMrtService.getCandidate().commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.supplierMrtService.getCandidate().commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case SupplierMrtService.UPC_ITEM_CODES:
        this.jumpBack('/piaSetupMrt');
        break;
      case SupplierMrtService.NEW_ITEM_SETUP:
        this.jumpBack('/piaSetupNewMrtItems');
        break;
      case SupplierMrtService.WAREHOUSE_SELECTION:
        this.onClickNext();
        break;
      case SupplierMrtService.EXTENDED_ATTRIBUTES:
        this.jumpToPage('/piaMrtExtendedAttributes');
        break;
    }
  }

  jumpBack(urlToNavigate) {
    this.canNavigate = false;
    this.supplierMrtService.saveCandidateAndNavigate(urlToNavigate, false);
  }

  jumpToPage(urlToNavigate) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(), [CandidateValidatorType.PIA_MRT_CASE_DETAILS_VALIDATOR]).subscribe(() => {
      this.candidateError = this.supplierMrtService.setMrtCaseDetailsError(new CandidateError());
      this.supplierMrtService.saveMrtCandidate().subscribe((result) => {
        this.supplierMrtService.saveCandidateAndNavigate(urlToNavigate, {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
      });
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierMrtService.setMrtCaseDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.candidateError.candidateProductErrors[this.supplierMrtService.getCandidateProduct(0).id] ;
      }
      this.supplierMrtService.saveMrtCandidate().subscribe(() => {
        this.supplierMrtService.saveCandidateAndNavigate(urlToNavigate, {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
      });
    });
  }

  onSubCommodityChange(event) {
    this.supplierMrtService.getCandidate().subCommodity = event;

    if (event) {
      this.supplierMrtService.getCandidate().foodStamp = CandidateHelper.isFoodStamp(this.supplierMrtService.getCandidate());
      this.supplierMrtService.getCandidate().taxable = CandidateHelper.isTaxCode(this.supplierMrtService.getCandidate());
    }
    this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
  }

  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.supplierMrtService.getCandidate()?.commodity && this.supplierMrtService.getCandidate().commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.supplierMrtService.getCandidate().commodity?.departmentId);
    } else {
      return false;
    }
  }
}
