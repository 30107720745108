/**
 * Model containing  AdminPreferences.
 *
 * @author s573181
 * @since 1.57.0
 */
export class AdminPreferences {

  id?: string;

  // fulfillmentChannel options
  public static readonly WAREHOUSE_FULFILLMENT_CHANNEL = 'WAREHOUSE';
  public static readonly DSD_FULFILLMENT_CHANNEL = 'DSD';

  fulfillmentChannel?: string;
  autoApproveActivation?: Boolean;
}
