import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate } from 'pm-models';

export class CodeDate implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }
  getAttributeConfig(overrides?) {
    return {
      label: 'Code date',
      description: 'Let us know if this pack has an expiration date.',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.codeDate;
    componentInstance.change.subscribe(x => {
      candidate.codeDate = x.checked;
      candidate.maxShelfLife = null;
      candidate.inboundSpecDays = null;
      candidate.warehouseReactionDays = null;
      candidate.guaranteeToStoreDays = null;
    });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.codeDate) {
      componentInstance.model = candidate.codeDate;
    }
  }
}
