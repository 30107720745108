<pm-review *ngIf="isViewingPage" (closeEvent)="onClose()">
  <div class="row justify-content-md-center pb-3 ng-star-inserted" style="margin-left: 5px;">
    <div class="col-md-10 pt-5">
      <div class="row">
        <h2>Find a candidate</h2>
      </div>
      <div class="row pt-2">
        <label>Search by UPC, Buyer, or Supplier name or number.</label>
      </div>
      <div class="row">
        <div class="ui-inputgroup">
          <p-dropdown class="search-type-dropdown"
                      appendTo="body"
                      [options]="searchOptions"
                      [(ngModel)]="searchType"
                      (onChange)="onSearchOptionChange()">
          </p-dropdown>
          <input *ngIf="searchType === UPC_SEARCH_TYPE"
                 type="text"
                 pInputText class="search-number-input"
                 placeholder="Enter a unit UPC..."
                 [(ngModel)]="searchModel"
                 (keyup)="searchInputKeyUp($event)"
                 appOnlyNumber maxlength="13"
                 (focusin)="showUpcFocusMessage()"
                 (focusout)="hideFocusMessage()"/>

          <input *ngIf="searchType === CANDIDATE_ID_SEARCH_TYPE"
                 type="text"
                 pInputText class="search-number-input"
                 placeholder="Enter a candidate ID..."
                 [(ngModel)]="searchModel"
                 (keyup)="searchInputKeyUp($event)"
                 appOnlyNumber maxlength="13"
                 (focusin)="showCandidateIdFocusMessage()"
                 (focusout)="hideFocusMessage()"/>

          <pm-typeahead *ngIf="searchType === BUYER_SEARCH_TYPE"
                        class="search-typeahead"
                        [id]="buyerConfiguration.name"
                        [displayRef]="buyerConfiguration?.displayRef"
                        [labelName]="'Buyer'"
                        [placeholder]="buyerConfiguration?.placeholderText" [maxSearchSize]="5"
                        [model]="searchModel" [idRef]="buyerConfiguration?.idRef" [url]="buyerConfiguration?.searchUrl"
                        [isDropDown]="false" (selection)="handleTypeaheadChange($event)" ngDefaultControl
                        [ngStyle]="typeaheadStyle">
          </pm-typeahead>

          <pm-typeahead *ngIf="searchType === SUPPLIER_SEARCH_TYPE"
                        class="search-typeahead"
                        [id]="supplierConfiguration.name"
                        [displayRef]="supplierConfiguration?.displayRef"
                        [labelName]="'Supplier'"
                        [placeholder]="supplierConfiguration?.placeholderText" [maxSearchSize]="5"
                        [model]="searchModel" [idRef]="supplierConfiguration?.idRef" [url]="supplierConfiguration?.searchUrl"
                        [isDropDown]="true" (selection)="handleTypeaheadChange($event)" ngDefaultControl
                        [ngStyle]="typeaheadStyle">
          </pm-typeahead>
        </div>
      </div>
      <div class="pt-1" style="min-height: 2rem;">
        <p *ngIf="searchFocusMessage$ | async" class="row attribute-description" [innerHTML]="searchFocusMessage$ | async">
        </p>
      </div>
    </div>
    <div class="col-md-10">
      <pm-progress-spinner [showSpinner]="isShowingSpinner"
                           [style]="{ 'width': '6rem', 'height': '6rem' }">
      </pm-progress-spinner>

      <div *ngIf="hasSearchResults" class="pt-3">
        <div class="row">
          <h3>Search results</h3>
        </div>
        <div *ngIf="hasSelectedResults()" class="row pt-3 pb-3">
          <p-button class="ghost" label="Export" (onClick)="exportResults()" [disabled]="isExportDisabled"></p-button>
        </div>
        <div class="row pt-1" *ngIf="searchResults.length > 0">
          <p-table class="product-search-table"
                   selectionMode="single"
                   [columns]="searchResultsColumns"
                   [value]="searchResults"
                   [paginator]="false"
                   [rows]="searchResults.length"
                   [totalRecords]="searchResults.length"
                   [resizableColumns]="true" [autoLayout]="true">
            <ng-template pTemplate="header" let-taskHeaderColumns>
              <tr>
                <th style="width: 3%;">
                  <p-checkbox [(ngModel)]="allTasksCheckbox"
                              (onChange)="selectedAllChange()"
                              [checkboxIcon]="getCheckAllIcon()"
                              [ngStyle]="{'padding-left': '2px'}"
                              binary="true">
                  </p-checkbox>
                </th>

                <th *ngFor="let col of searchResultsColumns"  [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
                  <div>
                    {{col.header}}
                  </div>
                  <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-index="rowIndex">
              <tr style="height: 16px !important;" [pSelectableRow]="product">

                <td>
                  <p-checkbox [(ngModel)]="product.checked"
                              (onChange)="onCheckSearchResult(index, $event)"
                              binary="true">
                  </p-checkbox>
                </td>

                <td *ngIf="!product.isMrt && product.taskType !== 'ASSOCIATE_UPC'" (click)="onClickRow(product)">
                  {{product?.upc}}
                </td>
                <td *ngIf="!product.isMrt && product.taskType === 'ASSOCIATE_UPC'" (click)="onClickRow(product)">
                  <div  *ngFor="let upc of product.associateUpcs; let i = index">
                    <div *ngIf="i < 3">
                      {{upc}}
                    </div>
                    <div *ngIf="i === 3" style="font-style: italic;">
                      +{{product.associateUpcs.length - 3}} more
                    </div>
                  </div>
                </td>
                <td *ngIf="product.isMrt" (click)="onClickRow(product)">
                  <div  *ngFor="let upc of product.mrtUpcs; let i = index">
                    <div *ngIf="i < 3">
                      {{upc}}
                    </div>
                    <div *ngIf="i === 3" style="font-style: italic;">
                      +{{product.mrtUpcs.length - 3}} more
                    </div>
                  </div>
                </td>
                <td (click)="onClickRow(product)">{{product?.candidateDescription}}</td>
                <td (click)="onClickRow(product)">{{taskListService.getDisplayName(product?.taskType)}}</td>
                <td (click)="onClickRow(product)">{{product?.buyerDisplayName}}</td>
                <td (click)="onClickRow(product)">{{product?.supplierDisplayName}}</td>
                <td (click)="onClickRow(product)">
                  {{getDisplayName(product)}}
                  <div class="attribute-description mb-0 overflowWrap">
                    {{getDisplayNameWorkflowStatus(product)}}
                  </div>
                </td>
                <td (click)="onClickRow(product)">
                  {{product?.lastUpdatedTime | dateStandard : {showTime: true} }}
                  <p class="attribute-description mb-0">
                    {{product?.lastUpdatedBy}}
                  </p>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="searchResults.length === 0" class="row pt-1">
          <label>
            We didn't find any candidates matching your search criteria. You can modify your search and try again.
          </label>
        </div>
      </div>
    </div>
  </div>
</pm-review>
