import {Injectable} from '@angular/core';
import {
  Attribute,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError, CandidateValidatorType,
  Product,
  Task,
  TaskDecision,
  UpdateMrtInnerRequest
} from 'pm-models';
import {WorkflowService} from './workflow.service';
import {CandidateService} from './candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../growl/growl.service';
import {from, Observable, of, Subject, throwError as observableThrowError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {UUID} from 'angular2-uuid';
import {CandidateUtilService} from './candidate-util.service';
import {AuthService} from '../auth/auth.service';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {CostService} from './cost.service';
import {LookupService} from './lookup.service';
import {ProductService} from './product.service';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class SupplierMrtService {


  constructor(private workflowService: WorkflowService, private candidateService: CandidateService, private route: ActivatedRoute,
              private router: Router, private growlService: GrowlService, private candidateUtilService: CandidateUtilService,
              private auth: AuthService, private costService: CostService, private lookupService: LookupService,
              private productService: ProductService, private permissionService: NgxPermissionsService) {
  }

  private taskId = undefined;
  private task: Task;
  private VENDOR_TASK_NAME = 'Key Vendor Data';
  private VENDOR_REVISION_TASK_NAME = 'Revise Vendor Data';
  private PIA_NEW_PRODUCT_TASK_NAME = 'PIA New Product Flow';
  private NEW_PRODUCT_WORKFLOW = 'honeyBeansPIAProcess';
  private PIA_NEW_PRODUCT_WORKFLOW = 'piaNewProductProcess';
  private isClosedTask = false;
  public candidate: Candidate;
  private originalCandidate: Candidate = undefined;
  private currentCandidateProductIndex = 0;
  private mrtItemError: CandidateError = new CandidateError();
  private mrtCaseDetailsError: CandidateError = new CandidateError();
  private mrtExtendedAttributesError: CandidateError = new CandidateError();
  private mrtWarehouseDetailsError: CandidateError = new CandidateError();
  public globalAttributes: Attribute[] = [];
  public warehouseItemAttributes: Attribute[] = [];

  // inner candidate related fields.
  private innerCandidateId = undefined;
  public innerCandidate: Candidate;
  private originalInnerCandidate: Candidate = undefined;
  public candidateInnerHierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  public candidateInnerHierarchyAttributes: Attribute[] = [];
  public candidateInnerGlobalAttributes: Attribute[] = [];
  public candidateInnerProductAttributes: Attribute[] = [];
  public candidateInnerWarehouseItemAttributes: Attribute[] = [];
  public candidateInnerUpcAttributes: Attribute[] = [];
  public hasCandidateInnerHierarchyUpdateChanges = false;

  private supplierAndHebDetailsErrors: Map<number, CandidateError> = new Map<number, CandidateError>();
  private productDetailsErrors: Map<number, CandidateError> = new Map<number, CandidateError>();
  private innerCasePackErrors: Map<number, CandidateError> = new Map<number, CandidateError>();
  private innerCaseDetailsErrors: Map<number, CandidateError> = new Map<number, CandidateError>();
  private innerWarehouseErrors: Map<number, CandidateError> = new Map<number, CandidateError>();
  private innerExtendedAttributesErrors: Map<number, CandidateError> = new Map<number, CandidateError>();
  private replenishableErrors: Map<number, CandidateError> = new Map<number, CandidateError>();

  public static readonly UPC_ITEM_CODES = 'UPCs/Item codes';
  public static readonly MRT_DETAILS = 'MRT details';
  public static readonly NEW_ITEM_SETUP = 'New item setup';
  public static readonly WAREHOUSE_SELECTION = 'Warehouse Selection';
  public static readonly EXTENDED_ATTRIBUTES = 'Extended attributes';
  public static readonly REVIEW = 'Review';

  public static readonly REPLENISHABLE_PRODUCT_TYPES = [Candidate.NEW_PRODUCT, Candidate.BONUS_SIZE, Candidate.ASSOCIATE_UPC, Candidate.REPLACEMENT_UPC];

  public static readonly FULL_SUPPLIER_NON_REPLENISHABLE_VALIDATORS: CandidateValidatorType[] =
    [CandidateValidatorType.MRT_INNER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
      CandidateValidatorType.UNIT_COST_VALIDATOR, CandidateValidatorType.MAT_HIERARCHY_VALIDATOR, CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR];

  public static readonly FULL_SUPPLIER_REPLENISHABLE_VALIDATORS: CandidateValidatorType[] =
    [CandidateValidatorType.MRT_INNER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
      CandidateValidatorType.ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR, CandidateValidatorType.MAT_HIERARCHY_VALIDATOR,
      CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR];

  public static readonly FULL_PIA_NON_REPLENISHABLE_VALIDATORS: CandidateValidatorType[] =
    [CandidateValidatorType.PIA_INNER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_HEADER_VALIDATOR,
      CandidateValidatorType.UNIT_COST_VALIDATOR, CandidateValidatorType.MAT_HIERARCHY_VALIDATOR,
      CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR];

  public static readonly FULL_PIA_REPLENISHABLE_VALIDATORS: CandidateValidatorType[] =
    [CandidateValidatorType.PIA_INNER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_HEADER_VALIDATOR,
      CandidateValidatorType.PIA_MRT_INNER_CASE_DETAILS_VALIDATOR, CandidateValidatorType.LANE_VALIDATOR,
      CandidateValidatorType.WAREHOUSE_VALIDATOR, CandidateValidatorType.MAT_HIERARCHY_VALIDATOR,
      CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR];


  resetService() {
    this.taskId = undefined;
    this.task = undefined;
    this.isClosedTask = false;
    this.candidate = undefined;
    this.originalCandidate = undefined;
    this.currentCandidateProductIndex = 0;
    this.resetMrtMatHierarchyFields();
    this.resetInnerFlow();
    this.resetErrors();
  }

  resetInnerFlow() {
    this.innerCandidateId = undefined;
    this.innerCandidate = undefined;
    this.originalInnerCandidate = undefined;
    this.resetMatHierarchyFieldsForInnerCandidate();
  }

  public resetErrors() {
    this.mrtItemError = new CandidateError();
    this.mrtCaseDetailsError = new CandidateError();
    this.mrtWarehouseDetailsError = new CandidateError();
    this.mrtExtendedAttributesError = new CandidateError();
    this.supplierAndHebDetailsErrors = new Map<number, CandidateError>();
    this.productDetailsErrors = new Map<number, CandidateError>();
    this.innerCasePackErrors = new Map<number, CandidateError>();
    this.innerWarehouseErrors = new Map<number, CandidateError>();
    this.innerCaseDetailsErrors = new Map<number, CandidateError>();
    this.innerExtendedAttributesErrors = new Map<number, CandidateError>();
  }

  public updateMrtErrors(candidateError) {
    this.mrtItemError = JSON.parse(JSON.stringify(candidateError));
    this.mrtCaseDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.mrtWarehouseDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.mrtExtendedAttributesError = JSON.parse(JSON.stringify(candidateError));
  }

  public updateInnerErrors(candidateId, candidateError) {
    this.setSupplierAndHebDetailsError(candidateId, JSON.parse(JSON.stringify(candidateError)));
    this.setProductDetailsError(candidateId, JSON.parse(JSON.stringify(candidateError)));
    this.setInnerCasePackError(candidateId, JSON.parse(JSON.stringify(candidateError)));
    this.setInnerCaseDetailsError(candidateId, JSON.parse(JSON.stringify(candidateError)));
    this.setInnerWarehouseError(candidateId, JSON.parse(JSON.stringify(candidateError)));
    this.setInnerExtendedAttributesError(candidateId, JSON.parse(JSON.stringify(candidateError)));
  }

  /**
   * Returns the task.
   */
  public getTask() {
    return this.task;
  }

  /**
   * Returns task id.
   */
  public getTaskIdString() {
    return '' + this.taskId;
  }

  /**
   * Returns task id.
   */
  public getTaskId() {
    return this.taskId;
  }

  /**
   * Returns inner candidate id.
   */
  public getInnerCandidateId() {
    return this.innerCandidateId;
  }

  /**
   * Set the candidate.
   * @param candidate
   */
  setCandidate(candidate: Candidate) {
    this.candidate = candidate;
  }

  /**
   * Sets and returns the original and current.
   * @param params
   */
  public setCandidateByUrlParameters(params): Observable<Candidate> {
    if (params.has('taskId')) {
      return this.workflowService.getTaskByIdWithVariables(params['params']['taskId']).pipe(
        switchMap(
          (task) => {
            this.task = task;
            this.taskId = this.task.id;
            if (!(this.task.name === this.VENDOR_TASK_NAME || this.task.name === this.VENDOR_REVISION_TASK_NAME ||
              this.task.name === this.PIA_NEW_PRODUCT_TASK_NAME)) {
              this.router.navigate(['/tasks'], {
                queryParams: {
                  growlMessage: 'Candidate is not in available for revision.',
                  growlToUse: GrowlService.SEVERITY_ERROR
                }
              }).then();
              return observableThrowError('Candidate is not in available for revision.');
            }
            return this.getCandidateByCandidateId(task.candidateId);
          }
        ));
    } else if (params.has('candidateId')) {
      this.isClosedTask = true;
      const candidateId = params['params']['candidateId'];
      return this.candidateService.getCandidate(candidateId).pipe(
        tap((candidate) => this.setOriginalAndCurrentCandidate(candidate)));
    }
  }

  /**
   * Returns a candidate by the candidate id.
   * @param candidateId
   */
  public getCandidateByCandidateId(candidateId): Observable<Candidate> {
    return this.candidateService.getCandidate(candidateId).pipe(
      tap(
        (candidate) => {
          if (!candidate.candidateType ||
            (candidate.candidateType !== Candidate.MRT && candidate.candidateType !== Candidate.MRT_INNER)) {
            const message = 'Invalid Candidate Task Flow : "' + candidate.candidateType + '" expected "' +
              Candidate.MRT + '" or " ' + Candidate.MRT_INNER + '".';
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
            return observableThrowError(message);
          }
        }
      ),
      catchError(
        (error) => {
          // if there was an error retrieving task, route back to tasks page with the error
          this.router.navigate(['/tasks'], {
            queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
          }).then();
          return observableThrowError(error);

        }),
      switchMap((candidate) => this.costService.updateCostLink(candidate)),
      switchMap((candidate) => this.costService.updateRetailLink(candidate))
    );
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = JSON.parse(JSON.stringify(candidate));
    this.candidate = JSON.parse(JSON.stringify(candidate));
  }

  /**
   * Sets the original and current inner candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  setOriginalAndCurrentInnerCandidate(candidate: Candidate) {
    this.innerCandidateId = candidate.candidateId;
    this.originalInnerCandidate = JSON.parse(JSON.stringify(candidate));
    this.innerCandidate = JSON.parse(JSON.stringify(candidate));
  }

  /**
   * Sets the original. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  setOriginalInnerCandidate(candidate: Candidate) {
    this.originalInnerCandidate = JSON.parse(JSON.stringify(candidate));
  }

  /**
   * Sets the current inner candidate objects.
   */
  seInnerCandidate(candidate: Candidate) {
    this.innerCandidateId = candidate.candidateId;
    this.innerCandidate = JSON.parse(JSON.stringify(candidate));
  }

  /**
   * Returns the current candidate.
   */
  public getCandidate() {
    return this.candidate;
  }

  /**
   * Returns the original candidate.
   */
  public getOriginalCandidate() {
    return this.originalCandidate;
  }

  /**
   * Returns the current inner candidate.
   */
  public getInnerCandidate() {
    return this.innerCandidate;
  }

  /**
   * Returns the original inner candidate.
   */
  public getOriginalInnerCandidate() {
    return this.originalInnerCandidate;
  }

  public setupNewCandidate() {
    this.candidate = new Candidate();
    this.candidate.warehouseSwitch = true;
    this.candidate.productType = Candidate.SELLABLE;
    this.candidate.candidateType = Candidate.MRT;
    this.candidate.mrtInfo = {id: UUID.UUID()};
    this.candidate.candidateProducts = [];
    this.candidate.candidateProducts.push({id: UUID.UUID(), candidateProductType: CandidateProduct.MRT_ITEM});
    this.originalCandidate = JSON.parse(JSON.stringify(this.candidate));
    this.resetErrors();
  }

  public getNewCandidateInner(pamCandidateProduct: CandidateProduct) {
    const newInnerCandidate = new Candidate();
    newInnerCandidate.warehouseSwitch = true;
    newInnerCandidate.productType = Candidate.SELLABLE;
    newInnerCandidate.candidateType = Candidate.MRT_INNER;
    newInnerCandidate.description = 'MRT Item – ' + pamCandidateProduct.upc;
    newInnerCandidate.candidateProducts = [];

    newInnerCandidate.candidateProducts.push(pamCandidateProduct);
    return newInnerCandidate;
  }

  saveMrtCandidate(): Observable<string> {
    const isDone = new Subject<string>();

    if (this.candidate.candidateId) {
      this.candidateService.saveCandidate(this.candidate, true).subscribe((candidate) => {
        this.setOriginalAndCurrentCandidate(candidate);
        isDone.next('done1');
      });
    } else {
      this.candidateService.createNewCandidate(this.candidate).subscribe((createdCandidate) => {
        this.createProcessInstanceWithCandidateId(createdCandidate.candidateId, null, this.hasVendorChanged());
        this.setOriginalAndCurrentCandidate(createdCandidate);
        isDone.next('done2');
      });
    }

    return isDone;
  }

  /**
   * Saves candidate and navigates to the url path provided.
   */
  saveCandidateAndNavigate(url: string, queryParams) {
    if (this.candidateUtilService.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          if (this.hasVendorChanged()) {
            let apNumber;
            if (!this.candidate.vendor) {
              apNumber = null;
            } else {
              apNumber = this.candidate.vendor.apNumber;
            }
            this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).subscribe(() => {
              this.setOriginalAndCurrentCandidate(savedCandidate);
              this.navigate(url, queryParams).subscribe();
            });
          } else {
            this.setOriginalAndCurrentCandidate(savedCandidate);
            this.navigate(url, queryParams).subscribe();
          }
        });
      } else {
        this.navigate(url, queryParams).subscribe();
      }
    }
  }

  /**
   * Saves the inner candidate and navigates to the url path provided.
   */
  saveInnerCandidateAndNavigate(innerCandidate: Candidate, url: string, queryParams, updateApNumber: boolean, resetValid?: boolean) {

    this.candidateService.saveCandidate(innerCandidate).pipe(
      tap((savedCandidate) =>  this.setOriginalAndCurrentInnerCandidate(savedCandidate)),
      switchMap(() => {
          if (!updateApNumber) {
            return of({});
          }
          const apNumber = !!this.candidate?.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
          return this.workflowService.updateApNumber(apNumber, this.task.processInstanceId);
        }
      ),
      switchMap(() => {
          if (!resetValid) {
            return of({});
          }
          this.getCandidateInnerByCandidateId(innerCandidate.candidateId).valid = null;
          return this.saveMrtCandidate();
        }
      ),
      switchMap(() => this.navigate(url, queryParams))
    ).subscribe();
  }

  /**
   * Saves the inner candidate and navigates to the url path provided.
   */
  saveInnerCandidate(innerCandidate: Candidate) {
    this.candidateService.saveCandidate(innerCandidate, true).subscribe(savedCandidate => {
      this.setOriginalAndCurrentInnerCandidate(savedCandidate);
    });
  }

  navigate(url, queryParams): Observable<any> {
    if (url === '/tasks') {
      return from(this.router.navigate([url]));
    } else if (queryParams) {
      return from(this.router.navigate([url], queryParams));
    } else {
      return from(this.router.navigate([url], {queryParams: {taskId: this.getTaskIdString()}}));
    }
  }

  /**
   * Creates the candidate and navigates to the supplier details.
   * @param candidate
   * @param url
   */
  public createCandidateAndNavigate(candidate: Candidate, url: string, updateApNumber: boolean) {
    this.candidateService.createNewCandidate(candidate).subscribe((newCandidate: Candidate) => {
      this.setOriginalAndCurrentCandidate(newCandidate);
      if (url) {
        this.resetErrors();
      }
      this.createProcessInstanceWithCandidateId(newCandidate.candidateId, url, updateApNumber);
    });
  }

  /**
   * Create process instance with newly created candidate id and routes to the supplier details page.
   */
  public createProcessInstanceWithCandidateId(candidateId: number, url: string, updateApNumber: boolean) {

    let workflow;

    if (this.auth.isPia()) {
      workflow = this.PIA_NEW_PRODUCT_WORKFLOW;

    } else {
      workflow = this.NEW_PRODUCT_WORKFLOW;
    }

    this.workflowService.createProcessInstanceWithCandidateId(candidateId, workflow)
      .subscribe(taskId => {
        if (taskId) {
          this.taskId = taskId;
          this.workflowService.getTaskByIdWithVariables(taskId).subscribe((task) => {
            this.task = task;

            if (updateApNumber) {
              let apNumber;
              if (!this.candidate.vendor) {
                apNumber = null;
              } else {
                apNumber = this.candidate.vendor.apNumber;
              }
              this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).subscribe(() => {
                if (url) {
                  this.router.navigate([url], {queryParams: {taskId: taskId}});
                }
              });
            } else {
              if (url) {
                this.router.navigate([url], {queryParams: {taskId: taskId}});
              }
            }
          });
        } else {
          this.growlService.addError('Problem creating process instance.');
        }
      });
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    if (this.candidate !== undefined && this.originalCandidate === undefined && this.candidate.vendor !== undefined) {
      return true;
    }
    return JSON.stringify(this.originalCandidate.vendor) !== JSON.stringify(this.candidate.vendor);
  }

  public getMrtCaseDetailsError(): CandidateError {
    return this.mrtCaseDetailsError;
  }

  public setMrtCaseDetailsError(candidateError: CandidateError): CandidateError {
    this.mrtCaseDetailsError = candidateError;
    return this.mrtCaseDetailsError;
  }
  public getMrtWarehouseDetailsError(): CandidateError {
    return this.mrtWarehouseDetailsError;
  }

  public setMrtWarehouseDetailsError(candidateError: CandidateError): CandidateError {
    this.mrtWarehouseDetailsError = candidateError;
    return this.mrtWarehouseDetailsError;
  }

  public getMrtItemError(): CandidateError {
    return this.mrtItemError;
  }

  public setMrtItemError(candidateError: CandidateError): CandidateError {
    this.mrtItemError = candidateError;
    return this.mrtItemError;
  }

  public getMrtExtendedAttributesError(): CandidateError {
    return this.mrtExtendedAttributesError;
  }

  public setMrtExtendedAttributesError(candidateError: CandidateError): CandidateError {
    this.mrtExtendedAttributesError = candidateError;
    return this.mrtExtendedAttributesError;
  }

  public getSupplierAndHebDetailsError(candidateId): CandidateError {
    return this.supplierAndHebDetailsErrors.get(candidateId);
  }

  public setSupplierAndHebDetailsError(candidateId: number, candidateError: CandidateError): CandidateError {
    this.supplierAndHebDetailsErrors.set(candidateId, candidateError);
    return  this.supplierAndHebDetailsErrors.get(candidateId);
  }

  public getProductDetailsError(candidateId): CandidateError {
    return this.productDetailsErrors.get(candidateId);
  }

  public setProductDetailsError(candidateId: number, candidateError: CandidateError): CandidateError {
    this.productDetailsErrors.set(candidateId, candidateError);
    return this.productDetailsErrors.get(candidateId);
  }

  public getInnerCasePackError(candidateId): CandidateError {
    return this.innerCasePackErrors.get(candidateId);
  }

  public setInnerCasePackError(candidateId: number, candidateError: CandidateError): CandidateError {
    this.innerCasePackErrors.set(candidateId, candidateError);
    return this.innerCasePackErrors.get(candidateId);
  }

  public getInnerCaseDetailsError(candidateId): CandidateError {
    return this.innerCaseDetailsErrors.get(candidateId);
  }

  public setInnerCaseDetailsError(candidateId: number, candidateError: CandidateError): CandidateError {
    this.innerCaseDetailsErrors.set(candidateId, candidateError);
    return this.innerCaseDetailsErrors.get(candidateId);
  }

  public getInnerWarehouseError(candidateId): CandidateError {
    return this.innerWarehouseErrors.get(candidateId);
  }

  public setInnerWarehouseError(candidateId: number, candidateError: CandidateError): CandidateError {
    this.innerWarehouseErrors.set(candidateId, candidateError);
    return this.innerWarehouseErrors.get(candidateId);
  }

  public getInnerExtendedAttributesError(candidateId): CandidateError {
    return this.innerExtendedAttributesErrors.get(candidateId);
  }

  public setInnerExtendedAttributesError(candidateId: number, candidateError: CandidateError): CandidateError {
    this.innerExtendedAttributesErrors.set(candidateId, candidateError);
    return this.innerExtendedAttributesErrors.get(candidateId);
  }

  public getReplenishableError(candidateId): CandidateError {
    return this.replenishableErrors.get(candidateId);
  }

  public setReplenishableError(candidateId: number, candidateError: CandidateError): CandidateError {
    this.replenishableErrors.set(candidateId, candidateError);
    return this.replenishableErrors.get(candidateId);
  }

  public getAllPiaMrtErrors(): CandidateError[] {
    return [this.getMrtItemError(), this.getMrtCaseDetailsError(), this.getMrtWarehouseDetailsError(), this.getMrtExtendedAttributesError()];
  }
  public getAllSupplierMrtErrors(): CandidateError[] {
    return [this.getMrtItemError(), this.getMrtCaseDetailsError(), this.getMrtExtendedAttributesError()];
  }

  public getAllCandidateInnerErrorsByCandidateId(): CandidateError[] {
    if (!this.innerCandidate) {
      return [];
    }
    return [this.getSupplierAndHebDetailsError(this.innerCandidate.candidateId),
      this.getProductDetailsError(this.innerCandidate.candidateId),
      this.getInnerCasePackError(this.innerCandidate.candidateId),
      this.getInnerWarehouseError(this.innerCandidate.candidateId),
      this.getInnerExtendedAttributesError(this.innerCandidate.candidateId)];
  }

  public getAllPiaCandidateInnerErrorsByCandidateId(): CandidateError[] {
    if (!this.innerCandidate) {
      return [];
    }
    return [this.getSupplierAndHebDetailsError(this.innerCandidate.candidateId),
      this.getProductDetailsError(this.innerCandidate.candidateId),
      this.getInnerCasePackError(this.innerCandidate.candidateId),
      this.getInnerCaseDetailsError(this.innerCandidate.candidateId),
      this.getInnerWarehouseError(this.innerCandidate.candidateId),
      this.getInnerExtendedAttributesError(this.innerCandidate.candidateId)];
  }

  public deleteInnerErrors(candidateId) {
    if (this.getSupplierAndHebDetailsError(candidateId)) {
      this.supplierAndHebDetailsErrors.delete(candidateId);
    }

    if (this.getProductDetailsError(candidateId)) {
      this.productDetailsErrors.delete(candidateId);
    }

    if (this.getInnerCasePackError(candidateId)) {
      this.innerCasePackErrors.delete(candidateId);
    }
    if (this.getInnerCaseDetailsError(candidateId)) {
      this.innerCaseDetailsErrors.delete(candidateId);
    }
    if (this.getInnerWarehouseError(candidateId)) {
      this.innerWarehouseErrors.delete(candidateId);
    }
    if (this.getInnerExtendedAttributesError(candidateId)) {
      this.innerExtendedAttributesErrors.delete(candidateId);
    }
  }

  public hasInnerErrors(): boolean {
    return !!this.candidate?.mrtInfo?.candidateInners?.some(candidateInner => candidateInner.valid === false);
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user to URL with candidate ID
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param candidateID to include in query params of URL.
   */
  public saveAndCompleteTaskAndRouteToUrlWithCandidateId(action: string, taskDecision: TaskDecision, url: string, candidateID: number) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
        .subscribe(() => {
          this.router.navigate([url], {queryParams: {candidateId : candidateID}}).then();
        });
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param growlMessage Message to display after routing to task page.
   */
  public completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, url: string, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate([url], {queryParams: {growlMessage: growlMessage}}).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  hasNewInners(candidate: Candidate) {
    if (!candidate?.mrtInfo) {
      return;
    }
    if (!candidate.mrtInfo.candidateInners?.length) {
      return false;
    }
    for (let x = 0; x < candidate.mrtInfo.candidateInners.length; x++) {
      if (!candidate.mrtInfo.candidateInners[x].candidate) {
        return;
      }
      if (candidate.mrtInfo.candidateInners[x].candidate.candidateType === Candidate.MRT_INNER) {
        return true;
      }
    }
    return false;
  }

  getNewInnerFlowSteps() {
    if (this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT-EDIT')) {
      return [{text: SupplierMrtService.UPC_ITEM_CODES}, {text: SupplierMrtService.NEW_ITEM_SETUP},
        {text: SupplierMrtService.MRT_DETAILS}, {text: SupplierMrtService.EXTENDED_ATTRIBUTES}, {text: SupplierMrtService.REVIEW}];
    } else {
      return [{text: SupplierMrtService.UPC_ITEM_CODES}, {text: SupplierMrtService.NEW_ITEM_SETUP},
        {text: SupplierMrtService.MRT_DETAILS}, {text: SupplierMrtService.REVIEW}];
    }
  }

  getNoInnerFlowSteps(): any[] {
    if (this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      return [{text: SupplierMrtService.UPC_ITEM_CODES}, {text: SupplierMrtService.MRT_DETAILS},
        {text: SupplierMrtService.EXTENDED_ATTRIBUTES}, {text: SupplierMrtService.REVIEW}];
    } else {
      return [{text: SupplierMrtService.UPC_ITEM_CODES}, {text: SupplierMrtService.MRT_DETAILS},
        {text: SupplierMrtService.REVIEW}];
    }
  }

  getPiaNewInnerFlowSteps(): any[] {
    if (this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      return [{text: SupplierMrtService.UPC_ITEM_CODES}, {text: SupplierMrtService.NEW_ITEM_SETUP},
        {text: SupplierMrtService.MRT_DETAILS}, {text: SupplierMrtService.WAREHOUSE_SELECTION},
        {text: SupplierMrtService.EXTENDED_ATTRIBUTES}];
    } else {
      return [{text: SupplierMrtService.UPC_ITEM_CODES}, {text: SupplierMrtService.NEW_ITEM_SETUP},
        {text: SupplierMrtService.MRT_DETAILS}, {text: SupplierMrtService.WAREHOUSE_SELECTION}];
    }
  }

  getPiaNoInnerFlowSteps() {
    if (this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      return [{text: SupplierMrtService.UPC_ITEM_CODES}, {text: SupplierMrtService.MRT_DETAILS},
        {text: SupplierMrtService.WAREHOUSE_SELECTION}, {text: SupplierMrtService.EXTENDED_ATTRIBUTES}];
    } else {
      return [{text: SupplierMrtService.UPC_ITEM_CODES}, {text: SupplierMrtService.MRT_DETAILS},
        {text: SupplierMrtService.WAREHOUSE_SELECTION}];
    }
  }

  scrollToTop() {
    document.getElementsByClassName('pm-editor-header')[0].scrollIntoView();
  }

  getInnerCandidateProduct(index) {
    return this.innerCandidate.candidateProducts[index];
  }

  getCandidateProduct(index) {
    return this.candidate.candidateProducts[index];
  }

  setCandidateProduct(index, candidateProduct) {
    this.candidate.candidateProducts[index] = candidateProduct;
  }

  getExistingInnerByUpc(upc) {
    for (let y = 0; y < this.candidate.mrtInfo.existingInners.length; y++) {
      if (this.candidate.mrtInfo.existingInners[y].upc === upc) {
        return this.candidate.mrtInfo.existingInners[y];
      }
    }
    return null;
  }
  getCandidateInnerByCandidateId(candidateId) {
    for (let y = 0; y < this.candidate.mrtInfo.candidateInners.length; y++) {
      if (this.candidate.mrtInfo.candidateInners[y].candidateId === candidateId) {
        return this.candidate.mrtInfo.candidateInners[y];
      }
    }
    return null;
  }

  isNewItem(candidateInner: CandidateInner) {
    return candidateInner.valid && candidateInner.replenishable;
  }

  getDisabledTooltip() {
    if (this.isDeleteDisabled()) {
      return 'MRTs must have at least 2 items. You must add additional items before you can remove this item from the MRT.';
    }
  }


  isDeleteDisabled() {
    let count = 0;
    if (this.getCandidate().mrtInfo && this.getCandidate().mrtInfo.candidateInners) {
      count += this.getCandidate().mrtInfo.candidateInners.length;
    }
    if (this.getCandidate().mrtInfo && this.getCandidate().mrtInfo.existingInners) {
      count += this.getCandidate().mrtInfo.existingInners.length;
    }
    return count < 3;
  }


  /**
   * if There's a MRT INNER candidate that hasn't been edited, or that has errors, it needs set up so return true.
   */
  hasInnersToSetup() {
    if (!this.getCandidate()?.mrtInfo?.candidateInners?.length) {
      return;
    }
    for (let x = 0; x < this.getCandidate().mrtInfo.candidateInners.length; x++) {
      // if it's not an inner (should be NEW_PRODUCT aka a draft), currently continue -
      if (this.getCandidate().mrtInfo.candidateInners[x].candidate &&
        this.getCandidate().mrtInfo.candidateInners[x].candidate.candidateType !== Candidate.MRT_INNER) {
        continue;
      }
      if (this.getCandidate().mrtInfo.candidateInners[x].draft) {
        continue;
      }
      if (!this.getCandidate().mrtInfo.candidateInners[x].vendorEdited) {
        return true;
      } else if (!this.getCandidate().mrtInfo.candidateInners[x].valid) {
        return true;
      }
    }
    return false;
  }

  isScaleProduct(candidate: Candidate) {
    if (!candidate.candidateProducts[0].candidateProductType ||
      candidate.candidateProducts[0].candidateProductType !== CandidateProduct.PLU) {
      return false;
    }

    if (candidate.candidateProducts[0] && (+candidate.candidateProducts[0].pluType === UpcRequestType.CHECKER_AND_SCALE ||
      +candidate.candidateProducts[0].pluType === UpcRequestType.SCALE)) {
      return true;
    }
    return false;
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    return this.candidate.candidateProducts[this.currentCandidateProductIndex];
  }

  /**
   * Returns the current candidate Product error model.
   */
  public getCurrentCandidateProductError(candidateError: CandidateError): CandidateProductError {
    const tempCandidateProductError = candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id];
    if (!tempCandidateProductError) {
      // empty candidateProductErrors returns as object, so must override with a new map to prevent undefined .set() method error.
      if (!(candidateError.candidateProductErrors instanceof Map)) {
        candidateError.candidateProductErrors = new Map<string, CandidateProductError>();
      }
      const candidateProductError = new CandidateProductError();
      candidateError.candidateProductErrors.set(this.getCurrentCandidateProduct().id,
        candidateProductError);
      return candidateProductError;
    }
    return tempCandidateProductError;
  }

  /**
   * Saves candidate.
   */
  saveCandidateAndClose() {
    if (this.candidateUtilService.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          this.router.navigate(['/tasks']);
        });
      } else {
        this.router.navigate(['/tasks']);
      }
    }
  }

  isInnerCandidateReplenishable() {
    if (!this.innerCandidate || !this.candidate.mrtInfo || !this.candidate.mrtInfo.candidateInners ||
      this.candidate.mrtInfo.candidateInners.length === 0) {
      return null;
    }

    for (let x = 0; x < this.candidate.mrtInfo.candidateInners.length; x++) {
      if (this.innerCandidate.candidateId === this.candidate.mrtInfo.candidateInners[x].candidateId) {
        return this.candidate.mrtInfo.candidateInners[x].replenishable;
      }
    }
    return null;
  }


  validateOtherInnerCandidates(): boolean {
    for (let x = 0; x < this.getCandidate().mrtInfo.candidateInners.length; x++) {
      const tempCandidateInner = this.getCandidate().mrtInfo.candidateInners[x];
      if (!tempCandidateInner.candidate) {
        return false;
      }
      if (tempCandidateInner.candidate.candidateType === Candidate.MRT_INNER) {
        if (tempCandidateInner.candidateId !== this.getInnerCandidate().candidateId && !tempCandidateInner.valid) {
          return false;
        }
      }
    }
    return true;
  }

  setNonReplenishableValues() {
    this.innerCandidate.lane = this.candidate.lane;
    this.innerCandidate.masterListCost = null;
    this.innerCandidate.masterPack = null;
    this.innerCandidate.masterLength = null;
    this.innerCandidate.masterWidth = null;
    this.innerCandidate.masterHeight = null;
    this.innerCandidate.masterWeight = null;
    this.innerCandidate.cubeAdjustedFactor = null;
    this.innerCandidate.vendorTie = null;
    this.innerCandidate.vendorTier = null;
    this.innerCandidate.innerPackSelected = null;
    this.innerCandidate.innerPack = null;
    this.innerCandidate.innerLength = null;
    this.innerCandidate.innerWidth = null;
    this.innerCandidate.innerHeight = null;
    this.innerCandidate.innerWeight = null;
    this.innerCandidate.candidateProducts[0].caseUpc = this.innerCandidate.candidateProducts[0].upc;
    this.innerCandidate.candidateProducts[0].caseUpcCheckDigit = this.innerCandidate.candidateProducts[0].upcCheckDigit;
    this.innerCandidate.candidateProducts[0].caseDescription = null;
    this.innerCandidate.candidateProducts[0].vendorProductCode = null;
    this.innerCandidate.candidateProducts[0].countryOfOrigin = null;
    this.innerCandidate.candidateProducts[0].generatedUpc = null;
    this.innerCandidate.itemWeightType = null;
    this.innerCandidate.codeDate = null;
    this.innerCandidate.maxShelfLife = null;
    this.innerCandidate.inboundSpecDays = null;
    this.innerCandidate.warehouseReactionDays = null;
    this.innerCandidate.guaranteeToStoreDays = null;

    this.innerCandidate.candidateProducts[0].importInfo = null;
    this.innerCandidate.displayReadyUnit = null;
    this.innerCandidate.displayReadyUnitRowsDeep = null;
    this.innerCandidate.displayReadyUnitRowsFacing = null;
    this.innerCandidate.displayReadyUnitRowsHigh = null;
    this.innerCandidate.displayReadyUnitOrientation = null;
    this.innerCandidate.costLink = null;
    this.innerCandidate.costLinkBy = null;
    this.innerCandidate.costLinked = null;
    this.innerCandidate.costLinkFromServer = null;
    this.innerCandidate.masterListCost = null;
    this.innerCandidate.innerListCost = null;

    this.innerCandidate.weightSwitch = null;
    this.innerCandidate.candidateProducts[0].remark1 = null;
    this.innerCandidate.candidateProducts[0].remark2 = null;
    this.innerCandidate.candidateProducts[0].imported = null;

  }

  hasInnerChanged() {
    return JSON.stringify(this.getOriginalInnerCandidate()) !== JSON.stringify(this.getInnerCandidate());
  }

  /**
   * Sets valid to false if any changes have been made, then saves and routes to task page.
   */
  onInnerClose() {
    // if there's changes to the inner, and they're not validating (on back), then set valid to false.
    this.saveInnerCandidateAndNavigate(this.getInnerCandidate(), '/tasks',
      {queryParams: {taskId: this.getTaskIdString(), candidateId: this.getInnerCandidate().candidateId}}, false, this.hasInnerChanged());
  }

  getCurrentCandidateProducts(inner: CandidateInner) {
    if (!inner || !inner?.candidate) {
      return null;
    } else {
      return this.getCandidatesCurrentCandidateProducts(inner.candidate);
    }
  }

  getCandidatesCurrentCandidateProducts(candidate: Candidate) {
    if (!candidate) {
      return null;
    } else if (candidate?.candidateType === Candidate.ADDITIONAL_CASE_PACK ||
      candidate?.candidateType === Candidate.ASSOCIATE_UPC ||
      candidate?.candidateType === Candidate.BONUS_SIZE ||
      candidate?.candidateType === Candidate.REPLACEMENT_UPC) {
      return candidate?.candidateProducts[1];
    }
    return candidate?.candidateProducts[0];
  }

  setInnerCandidates(updateAll?: boolean): Observable<any> {
    return this.setCandidateInnerCandidates(this.candidate, updateAll);
  }

  setCandidateInnerCandidates(candidate: Candidate, updateAll?: boolean): Observable<any> {
    if (!!candidate.mrtInfo?.candidateInners?.length) {
      let candidateIds;
      if (updateAll) {
        candidateIds = candidate.mrtInfo.candidateInners.map(inner => inner.candidateId);
      } else {
        candidateIds = candidate.mrtInfo.candidateInners.filter(inner => !inner.candidate).map(inner => inner.candidateId);
      }

      return this.candidateService.getCandidatesByCandidateIds(candidateIds).pipe(
        tap((candidates: Candidate[]) => {
          candidate.mrtInfo.candidateInners.forEach((candidateInner) => {
            const foundCandidate = candidates.find((currentCandidate) => currentCandidate.candidateId === candidateInner.candidateId);
            if (foundCandidate) {
              candidateInner.candidate = foundCandidate;
            }
          });
        })
      );
    } else {
      return of({});
    }
  }

  setExistingInnerProducts(): Observable<any> {
    return this.setCandidateExistingInnerProducts(this.candidate);
  }


  setCandidateExistingInnerProducts(candidate: Candidate): Observable<any> {
    if (!!candidate.mrtInfo?.existingInners?.length) {
      const upcs = candidate.mrtInfo.existingInners.map(existingInner => existingInner.upc);
      return this.lookupService.getProductsByUpcs(upcs).pipe(
        tap((products: Product[]) => {
            candidate.mrtInfo.existingInners.forEach(existingInner => {
              const product = this.productService.getProductByUpc(existingInner.upc, products);
              if (product) {
                existingInner.product = product;
              }
            });
          }
        ));
    } else {
      return of({});
    }
  }

  getCostOwners(): Observable<any> {
    return this.getCandidateCostOwners(this.candidate);
  }

  getCandidateCostOwners(candidate: Candidate): Observable<any> {
    const brandIds = new Set<number>();

    // set candidate inners brand ids.
    if (!!candidate.mrtInfo?.candidateInners?.length) {
      candidate.mrtInfo.candidateInners.forEach(candidateInner => {
        if (!!candidateInner?.candidate?.brand?.brandId) {
          brandIds.add(candidateInner.candidate.brand.brandId);
        }
      });
    }

// set existing inners brand ids.
    if (!!candidate.mrtInfo?.existingInners?.length) {
      candidate.mrtInfo.existingInners.forEach(existingInner => {
        if (!!existingInner?.product?.brand?.brandId) {
          brandIds.add(existingInner.product.brand.brandId);
        }
      });
    }

    if (!brandIds.size) {
      return of([]);
    }
    const costOwners = [];
    return this.lookupService.findBrandsById(Array.from(brandIds)).pipe(
      map(brandList => {
        if (!brandList?.length) {
          return costOwners;
        }
        for (let index = 0; index < brandList.length; index++) {
          const currentBrandCostOwners = brandList[index].costOwners;
          for (let costOwnersIndex = 0; costOwnersIndex < currentBrandCostOwners.length; costOwnersIndex++) {
            costOwners.push(currentBrandCostOwners[costOwnersIndex]);
          }
        }
        return costOwners;
      })
    );
  }

  getItemCodeString(product: Product) {
    if (!product) {
      return '';
    }
    if (product.items && product.items.length === 1) {
      return 'Item code: ' + product.items[0].itemCode;
    } else if (product.dsdItems && product.dsdItems.length === 1) {
      return 'Item code: ' + product.dsdItems[0].upc;
    } else {
      return 'Item code: Multiple (this item has multiple case configurations)';
    }
  }

  public getUpdateMrtInnerRequest(mrtCandidateToUpdate: Candidate, mrtModalExistingInners: ExistingInner[],
                                  mrtModalCandidateInners: CandidateInner[]): UpdateMrtInnerRequest {
    const updateMrtInnerRequest: UpdateMrtInnerRequest = new UpdateMrtInnerRequest();
    updateMrtInnerRequest.mrtCandidate = mrtCandidateToUpdate;

    this.handleEditingExistingInners(mrtCandidateToUpdate, updateMrtInnerRequest, mrtModalExistingInners);
    this.handleEditingCandidateInners(mrtCandidateToUpdate, updateMrtInnerRequest, mrtModalCandidateInners);
    return updateMrtInnerRequest;
  }

  /**
   * Handles editing changes of mrt candidate inners.
   * @param mrtCandidateToUpdate
   * @param updateMrtInnerRequest
   * @param mrtModalCandidateInners
   * @private
   */
  private handleEditingCandidateInners(mrtCandidateToUpdate: Candidate, updateMrtInnerRequest: UpdateMrtInnerRequest, mrtModalCandidateInners: CandidateInner[]) {

    if (!mrtCandidateToUpdate.mrtInfo.candidateInners?.length) {
      updateMrtInnerRequest.deletedCandidateInners = [];
    } else {
      const currentCandidateIds = mrtModalCandidateInners.map(currentCandidateInner => currentCandidateInner.candidateId);
      updateMrtInnerRequest.deletedCandidateInners = mrtCandidateToUpdate.mrtInfo.candidateInners
        .filter(oldCandidateInner => !currentCandidateIds.includes(oldCandidateInner.candidateId));

      for (const currentCandidateInner of mrtCandidateToUpdate.mrtInfo.candidateInners) {
        for (const mrtModalCandidateInner of mrtModalCandidateInners) {
          if (currentCandidateInner.candidateId === mrtModalCandidateInner.candidateId) {
            currentCandidateInner.quantity = mrtModalCandidateInner.quantity;
            break;
          }
        }
      }
    }
  }

  /**
   * Handles editing changes of mrt existing inners.
   * @param mrtCandidateToUpdate
   * @param updateMrtInnerRequest
   * @param mrtModalExistingInners
   * @private
   */
  private handleEditingExistingInners(mrtCandidateToUpdate: Candidate, updateMrtInnerRequest: UpdateMrtInnerRequest, mrtModalExistingInners: ExistingInner[]) {

    if (!mrtCandidateToUpdate.mrtInfo.existingInners?.length) {
      updateMrtInnerRequest.addedExistingInners = mrtModalExistingInners;
      updateMrtInnerRequest.deletedExistingInners = [];
    } else {
      const oldUpcs = mrtCandidateToUpdate.mrtInfo.existingInners.map(oldExistingInner => oldExistingInner.upc);
      const currentUpcs = mrtModalExistingInners.map(currentExistingInner => currentExistingInner.upc);

      updateMrtInnerRequest.addedExistingInners = mrtModalExistingInners.filter(currentExistingInner => !oldUpcs.includes(currentExistingInner.upc));
      updateMrtInnerRequest.deletedExistingInners = mrtCandidateToUpdate.mrtInfo.existingInners.filter(oldExistingInner => !currentUpcs.includes(oldExistingInner.upc));

      // update the qty of existing inners that weren't added/removed.
      for (const currentExistingInner of mrtCandidateToUpdate.mrtInfo.existingInners) {
        for (const modalExistingInner of mrtModalExistingInners) {
          if (currentExistingInner.upc === modalExistingInner.upc) {
            currentExistingInner.quantity = modalExistingInner.quantity;
            break;
          }
        }
      }
    }
  }

  public isDeleteInnerDisabled(mrtModalCandidateInners: CandidateInner[], mrtModalExistingInners: ExistingInner[]): boolean {
    if (!mrtModalCandidateInners && !mrtModalExistingInners) {
      return true;
    } else if (!mrtModalCandidateInners) {
      return mrtModalExistingInners.length  <= 2;
    } else if (!mrtModalExistingInners) {
      return mrtModalCandidateInners.length  <= 2;
    } else {
      return mrtModalCandidateInners.length + mrtModalExistingInners.length <= 2;
    }
  }

  public getDeleteInnerDisabledTooltip(mrtModalCandidateInners: CandidateInner[], mrtModalExistingInners: ExistingInner[]) {
    if (this.isDeleteInnerDisabled(mrtModalCandidateInners, mrtModalExistingInners)) {
      return 'An MRT must contain 2 or more UPCs.';
    }
  }

  resetMatHierarchyFieldsForInnerCandidate() {
    this.candidateInnerGlobalAttributes = [];
    this.candidateInnerHierarchyAttributes = [];
    this.candidateInnerProductAttributes = [];
    this.candidateInnerWarehouseItemAttributes = [];
    this.candidateInnerUpcAttributes = [];
    this.candidateInnerHierarchyNumberToAttributesMap = new Map();
    this.hasCandidateInnerHierarchyUpdateChanges = false;
  }

  setPiaInitiatedCandidateInners() {
    if (this.candidate.mrtInfo.candidateInners) {
      for (let t = 0; t < this.candidate.mrtInfo.candidateInners.length; t++) {
        if (!this.candidate.mrtInfo.candidateInners[t].draft) {
          this.candidate.mrtInfo.candidateInners[t].isPiaInitiated = true;
        }
      }
    }
  }


  resetMrtMatHierarchyFields() {
    this.globalAttributes = [];
    this.warehouseItemAttributes = [];
  }

}
