import { Injectable } from '@angular/core';
import {CandidateService} from '../service/candidate.service';
import {GrowlService} from '../growl/growl.service';
import {AuthService} from '../auth/auth.service';

@Injectable(
)
export class HistoryService {

  public candidateAudits: any = undefined;
  public candidateProductAudits: any = undefined;
  public sortedAudits: any = undefined;

  constructor(public candidateService: CandidateService, public growlService: GrowlService,
              public authService: AuthService) { }

  getCandidateHistory(candidateId, isSupplier: boolean) {
    this.candidateService.candidateAudit(candidateId).subscribe((result) => {
      this.candidateAudits = result.auditRecords;
      this.candidateProductAudits = result.candidateProductAudits;
      // these list are combined and sorted because the business doesn't wants the user to be aware of the difference in
      // in candidate and candidate product level fields
      const tempArray: [] = this.candidateAudits.concat(this.candidateProductAudits);
      if (isSupplier) {
        for (let index = 0; index < this.candidateAudits.length; index++) {
          if (this.candidateAudits[index].changedBy !== this.authService.getUser()) {
            this.candidateAudits[index].changedBy = 'HEB Partner';
          }
        }
        for (let index = 0; index < this.candidateProductAudits.length; index++) {
          if (this.candidateProductAudits[index].changedBy !== this.authService.getUser()) {
            this.candidateProductAudits[index].changedBy = 'HEB Partner';
          }
        }
      }
      this.sortedAudits =  tempArray.sort((val1, val2) => val2 ['changedOn'] - val1  ['changedOn']);
    }, error => {
      this.growlService.addError(error);
    });
  }

  displayAudit(auditChange: String) {
    let toReturn: string;
    if (auditChange !== undefined && auditChange.includes('Optional[]')) {
      return '-';
    }
    // Candidate api is designed to return the just the description/name of the models, this is a safe guard
    if (auditChange !== undefined && auditChange.includes('Optional[')) {
      toReturn = auditChange.replace('Optional[', '').replace(']', '');
      if (auditChange.includes('description=')) {
        return toReturn.split('description=')[1].replace('}', '');
      } else if (auditChange.includes('name=')) {
        return toReturn.split('name=')[1].replace('}', '');
      }
      return toReturn;
    } else {
      return auditChange;
    }
  }

  showCandidateAudits(candidateAudits: any) {
    if ((candidateAudits.changedFrom.includes('Uninitialized') && candidateAudits.changedTo.includes('null')) ||
      (candidateAudits.changedFrom.includes('Uninitialized') && candidateAudits.changedTo.includes('[]'))) {
      return false;
    }
    if (candidateAudits.changedFrom.includes('[]')) {
      return false;
    }
    if (candidateAudits.changedTo.includes('Optional[com.heb.pm.candidate')) {
      return false;
    }
    if (candidateAudits.changedTo.includes('Optional[com.heb.pm.api')) {
      return false;
    }
    return true;
  }
}
