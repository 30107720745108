import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

/**
 * This service streams different types of message to the growl message array
 *
 * @author s793785
 * @since 1.0.0
 */
@Injectable()
export class GrowlService {

  public static SEVERITY_ERROR = 'error';
  public static SEVERITY_SUCCESS = 'success';
  public static SEVERITY_WARN = 'warn';
  public static SEVERITY_INFO = 'info';

  // default severity if not defined
  private static DEFAULT_SEVERITY = GrowlService.SEVERITY_SUCCESS;

  /**
   * streams the error message to the Growl component
   * @type {Subject<string>}
   */
  private errorMessageSource = new Subject<string>();
  errorMessage$ = this.errorMessageSource.asObservable();

  /**
   * streams the success message to the Growl component
   * @type {Subject<string>}
   */
  private successMessageSource = new Subject<string>();
  successMessage$ = this.successMessageSource.asObservable();

  /**
   * streams the warning message to the Growl component
   * @type {Subject<string>}
   */
  private warnMessageSource = new Subject<string>();
  warnMessage$ = this.warnMessageSource.asObservable();

  /**
   * streams the info message to the Growl component
   * @type {Subject<string>}
   */
  private infoMessageSource = new Subject<string>();
  infoMessage$ = this.infoMessageSource.asObservable();

  /**
   * clears all messages
   * @type {Subject<any>}
   */
  private clearMessageSource = new Subject();
  clearMessage$ = this.clearMessageSource.asObservable();

  constructor() {}

  addError(error: string) {
      this.errorMessageSource.next(error);
  }
  addSuccess(success: string) {
    this.successMessageSource.next(success);
  }
  addWarn(warn: string) {
    this.warnMessageSource.next(warn);
  }
  addInfo(info: string) {
    this.infoMessageSource.next(info);
  }
  clearMessages() {
    this.clearMessageSource.next();
  }

  // add message to growl given message (required) and severity (set to default if not specified)
  addMessage(message: string, severity = GrowlService.DEFAULT_SEVERITY) {
    switch (severity) {
      case GrowlService.SEVERITY_SUCCESS: {
        this.addSuccess(message);
        break;
      }
      case GrowlService.SEVERITY_ERROR: {
        this.addError(message);
        break;
      }
      case GrowlService.SEVERITY_INFO: {
        this.addInfo(message);
        break;
      }
      case GrowlService.SEVERITY_WARN: {
        this.addWarn(message);
        break;
      }
    }
  }
}
