import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NgxPermissionsServiceMock {
  constructor() {}

  hasPermission(permission: string | string[]) {
    return new Promise(resolve => {
      return true;
    });
  }
}
