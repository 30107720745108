import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AttributeConfig, AttributeTextInputConfig, TextInputType} from 'pm-models';
import {UserPreferences} from 'pm-models/lib/userPreferences';
import {PreferencesService} from '../service/preferences.service';

@Component({
  selector: 'app-supplier-notifications',
  templateUrl: './supplier-notifications.component.html',
  styleUrls: ['./supplier-notifications.component.scss']
})
export class SupplierNotificationsComponent implements OnInit {

  constructor(  private preferencesService: PreferencesService, private router: Router) { }

  public isViewingPage = true;

  private userPreferences: UserPreferences;

  public emailFrequencyModel: string;
  public emailToggleModel: boolean;

  // If any changes were made to the component
  public changes: boolean;

  emailFrequencyConfig: AttributeTextInputConfig = {
    label: 'Frequency',
    description: 'When there\'s new work, we\'ll send you one summary at the end of the day.',
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `Never`,
    textInputType: TextInputType.text,
    inputGroupClass: 'email-frequency-text-input',
  };

  emailToggleConfiguration: AttributeConfig = {
    label: 'Emails',
    description: 'Opt-in to receiving notifications via email.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    defaultValue: false
  };

  ngOnInit() {

    this.preferencesService.getPreferences().subscribe(prefs => {
      this.userPreferences = prefs;
      this.setEmailPreferences(prefs.sendEmails);
    });
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']);
  }

  emailToggleChange(event) {
    this.changes = true;
    this.setEmailPreferences(event.checked);
  }

  setEmailPreferences(enabled) {
    if (enabled) {
      this.emailFrequencyConfig.placeholderText = `Once a day`;
      this.emailToggleModel = true;
    } else {
      this.emailFrequencyConfig.placeholderText = `Never`;
      this.emailToggleModel = false;
    }
  }

  cancel() {
    this.setEmailPreferences(this.userPreferences.sendEmails);
    this.changes = false;
    this.onClose();
  }

  save() {
    this.changes = false;
    this.userPreferences.sendEmails = this.emailToggleModel;
    this.userPreferences.hasSetupPreferences = true;
    this.preferencesService.updatePreferences(this.userPreferences).subscribe(prefs => {
      this.userPreferences = prefs;
      this.setEmailPreferences(prefs.sendEmails);
      this.onClose();
    });
  }
}
