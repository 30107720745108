import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  MerchandisingInfoCardPermissions,
  PricingCardPermissions,
  ProductDescriptionsCardPermissions,
  ProductImagesCardPermissions,
  ProductPackagingCardPermissions,
  ProductPricingCardPermissions,
  RegulatoryCardPermissions
} from 'pm-models';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CostService} from '../../service/cost.service';
import {InputState} from 'pm-components';
import {GrowlService} from '../../growl/growl.service';
import {PiaMrtInnerStepperComponent} from '../pia-mrt-inner-stepper/pia-mrt-inner-stepper.component';

@Component({
  selector: 'app-pia-inner-product-details',
  templateUrl: './pia-inner-product-details.component.html',
  styleUrls: ['./pia-inner-product-details.component.scss']
})
export class PiaInnerProductDetailsComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private lookupService: LookupService,
              public candidateUtilService: CandidateUtilService,  private growlService: GrowlService) { }

  private taskSubscription$: any;
  public innerCandidateError: CandidateError;
  public innerCandidateProductError: CandidateProductError;
  public unitsOfMeasures: any;
  public packageTypes: any;
  public merchandiseTypes: any;
  public commodities: any;
  public retailLinkState: InputState = InputState.none;


  readonly productDescriptionsCardPermissions: ProductDescriptionsCardPermissions = {
    isReadOnly: false
  };

  readonly merchandisingInfoCardPermissions: MerchandisingInfoCardPermissions = {
    isReadOnly: false,
  };

  readonly regulatoryCardPermissions: RegulatoryCardPermissions = {
    isReadOnly: false,
    showCalories: {
      isHidden: true
    }
  };

  readonly productPricingCardPermissions: ProductPricingCardPermissions = {
    isReadOnly: false
  };

  readonly pricingCardPermissions: PricingCardPermissions = {
    isReadOnly: false,
  };
  readonly productPackagingCardPermissions: ProductPackagingCardPermissions = {
    isReadOnly: false
  };

  readonly productImagesCardPermissions: ProductImagesCardPermissions = {
    isReadOnly: false
  };


  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {

        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId()  ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).subscribe((innerCandidate) => {
              this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
              this.initializeData();
            });
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierMrtService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {

    this.innerCandidateError = new CandidateError();
    this.innerCandidateProductError = new CandidateProductError();
    this.innerCandidateError = this.supplierMrtService.getProductDetailsError(this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.innerCandidateError) {
      this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
        this.supplierMrtService.getInnerCandidateProduct(0).id];
    }
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
    if (this.supplierMrtService.getInnerCandidate().buyer && this.supplierMrtService.getInnerCandidate().buyer.buyerId) {
      this.setCommoditiesAndSubCommodities(this.supplierMrtService.getInnerCandidate().buyer.buyerId);
    }
    this.findMerchandiseTypesAndSetDefault();

    if (!this.supplierMrtService.getInnerCandidate().vertexTaxCategoryModel) {
      this.setVertexTaxCategoryModel();
    }
  }


  onClickNext() {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(),
      [CandidateValidatorType.PIA_INNER_PRODUCT_DETAILS_VALIDATOR]).toPromise().then(data => {
      this.innerCandidateError = this.supplierMrtService.setProductDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
        new CandidateError());
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaAddNewMrtCase',
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(), candidateId:
            this.supplierMrtService.getInnerCandidate().candidateId}}, false);
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.innerCandidateError = this.supplierMrtService.setProductDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
          error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
      }
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaAddNewMrtCase',
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
            candidateId: this.supplierMrtService.getInnerCandidate().candidateId}}, false, true);
    });
  }

  onClose() {
    this.supplierMrtService.onInnerClose();
  }


  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.supplierMrtService.getInnerCandidate().productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.supplierMrtService.getInnerCandidate());
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      // Default to basic for sellable (if there's no merchandise type).
      if (!this.supplierMrtService.getOriginalCandidate().merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.supplierMrtService.getOriginalCandidate().merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.supplierMrtService.getCandidate().merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
    if (!isSellable) {
      this.setNonSellableRetailValues();
    }
  }

  /**
   * Sets non sellable default values.
   */
  private setNonSellableRetailValues() {
    this.supplierMrtService.getInnerCandidate().retailXFor = 1;
    this.supplierMrtService.getInnerCandidate().retailPrice = '0.00';
    this.supplierMrtService.getInnerCandidate().retailType = CostService.KEY_RETAIL;
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      // this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, set the commodity w/ sub com list
      if (commodity) {
        this.supplierMrtService.getInnerCandidate().commodity = commodity;
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.supplierMrtService.getInnerCandidate().commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.supplierMrtService.getInnerCandidate().commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  onClickBack() {
    this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaSupplierAndHebDetails',
      {queryParams: {taskId: this.supplierMrtService.getTaskIdString(), candidateId:
          this.supplierMrtService.getInnerCandidate().candidateId}}, false, this.supplierMrtService.hasInnerChanged());
  }

  validateRetailLink() {
    this.retailLinkState = InputState.loading;
    this.innerCandidateError.retailLink = undefined;
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(),
      [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).subscribe((candidate) => {
      this.retailLinkState = InputState.valid;

      this.supplierMrtService.getInnerCandidate().retailLink = candidate.retailLink;
      this.supplierMrtService.getInnerCandidate().retailXFor = candidate.retailXFor;
      this.supplierMrtService.getInnerCandidate().retailPrice = candidate.retailPrice;

      this.innerCandidateError.retailLink = undefined;
    }, (error) => {
      this.retailLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.innerCandidateError.retailLink = error.error.candidateErrors.retailLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }
  public onCommodityChange(event) {
    this.setVertexTaxCategoryModel();
  }

  public onSubCommodityChange(event) {
    this.setVertexTaxCategoryModel();
  }

  /**
   * Sets the vertex tax category model.
   */
  setVertexTaxCategoryModel() {
    this.candidateUtilService.setVertexTaxCategoryModel(this.supplierMrtService.getInnerCandidate()).subscribe((innerCandidate) => {
      this.supplierMrtService.seInnerCandidate(innerCandidate);
    });
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaMrtInnerStepperComponent.SUPPLIER_HEB_SETUP: {
        this.onClickBack();
        break;
      }
      case PiaMrtInnerStepperComponent.CASE_PACK: {
        this.onClickNext();
        break;
      }
      case PiaMrtInnerStepperComponent.CASE_DETAILS: {
        this.jumpToPage('/piaInnerCaseDetails', [CandidateValidatorType.PIA_INNER_PRODUCT_DETAILS_VALIDATOR]);
        break;
      }
      case PiaMrtInnerStepperComponent.WAREHOUSE: {
        this.jumpToPage('/piaMrtInnerWarehouseDetails', [CandidateValidatorType.PIA_INNER_PRODUCT_DETAILS_VALIDATOR]);
        break;
      }
      case PiaMrtInnerStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/piaMrtInnerExtendedAttributes', [CandidateValidatorType.PIA_INNER_PRODUCT_DETAILS_VALIDATOR]);
        break;
      }
    }
  }


  jumpToPage(url: string, validators: CandidateValidatorType[]) {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), validators).subscribe(data => {
      this.innerCandidateError = this.supplierMrtService.setProductDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
        new CandidateError());
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(), candidateId:
            this.supplierMrtService.getInnerCandidate().candidateId}}, false);
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.innerCandidateError = this.supplierMrtService.setProductDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
          error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
          {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId}}, false, true);
      } else {
        this.growlService.addError(error.message);
      }
    });
  }
}
