import { AttributeConfig } from './attributeConfig';
export interface AttributeTextInputConfig extends AttributeConfig {
  textInputType?: TextInputType;
  maxLength?: number;
  charLimit?: number;
  numberCount?: number;
  decimalCount?: number;
}

export enum TextInputType {
  'text',
  'textarea',
  'integer',
  'decimal'
}
