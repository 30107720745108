import { Injectable } from '@angular/core';
import {Candidate, WorkflowState} from 'pm-models';
import {GrowlService} from '../growl/growl.service';
import {UploadCandidateProductConstants} from '../utils/constants/upload-candidate-product-constants';
import {UploadCandidateCaseConstants} from '../utils/constants/upload-candidate-case-constants';
import {UploadCandidateSupplierAndHebConstants} from '../utils/constants/upload-candidate-supplier-and-heb-constants';
import {UploadCandidateDsdConstants} from '../utils/constants/upload-candidate-dsd-constants';

/**
 * Service to manage the upload candidate table columns.
 *
 * @author s573181
 * @since 1.50.0
 */
@Injectable({
  providedIn: 'root'
})
export class UploadCandidateService {

  constructor(private growlService: GrowlService) { }

  /**
   * Returns the columns for Product tab.
   * @param candidateType
   */
  public getProductColumnsFromCandidateType(candidateType: string): any[] {
    if (!candidateType) {
      this.growlService.addError(candidateType + ' not provided!');
      return [];
    }
    switch (candidateType) {
      case Candidate.NEW_PRODUCT:
      case Candidate.NEW_PRODUCT_UPLOAD: {
        return UploadCandidateProductConstants.NEW_PRODUCT_PRODUCT_TAB_COLUMNS;
      }
      default: {
        this.growlService.addError('Table for Candidate Type "' + candidateType + '" not implemented!');
        return [];
      }
    }
  }
  /**
   * Returns the columns for Product tab based on workflow state.
   * @param workflowState the workflow state.
   */
  public getProductColumnsFromWorkflowState(workflowState: string): any[] {
    if (!workflowState) {
      this.growlService.addError('Workflow state not provided!');
      return [];
    }
    switch (workflowState) {
      case WorkflowState.BUYER_NEW_PRODUCT_NO_CODE_DATE_REVIEW:
      case WorkflowState.BUYER_NEW_PRODUCT_REVIEW: {
        return UploadCandidateProductConstants.BUYER_REVIEW_PRODUCT_TAB_COLUMNS;
      } case WorkflowState.SCA_NEW_PRODUCT_NO_CODE_DATE_REVIEW:
        case WorkflowState.SCA_NEW_PRODUCT_REVIEW: {
        return UploadCandidateProductConstants.SCA_REVIEW_PRODUCT_TAB_COLUMNS;
      } case WorkflowState.PIA_NEW_PRODUCT_REVIEW: {
        return UploadCandidateProductConstants.NEW_PRODUCT_PRODUCT_TAB_COLUMNS;
      }
      default: {
        this.growlService.addError('Table for Candidate Type "' + workflowState + '" not implemented!');
        return [];
      }
    }
  }

  /**
   * Returns the columns for the case tab.
   * @param candidateType
   */
  public getCaseColumnsFromCandidateType(candidateType: string, isWarehouse: boolean): any[] {
    if (!candidateType) {
      this.growlService.addError(candidateType + ' not provided!');
      return [];
    }
    switch (candidateType) {
      case Candidate.NEW_PRODUCT:
      case Candidate.NEW_PRODUCT_UPLOAD: {
        if (isWarehouse) {
          return UploadCandidateCaseConstants.NEW_WAREHOUSE_PRODUCT_CASE_TAB_COLUMNS;
        } else {
          return UploadCandidateCaseConstants.NEW_DSD_PRODUCT_CASE_TAB_COLUMNS;
        }
      }

      default: {
        this.growlService.addError('Table for Candidate Type "' + candidateType + '" not implemented!');
        return [];
      }
    }
  }
  /**
   * Returns the columns for the case tab.
   * @param workflowState the workflow state.
   */
  public getCaseColumnsFromWorkflowState(workflowState: string): any[] {
    if (!workflowState) {
      this.growlService.addError('Workflow state not provided!');
      return [];
    }
    switch (workflowState) {
      case WorkflowState.BUYER_NEW_PRODUCT_NO_CODE_DATE_REVIEW: {
        return UploadCandidateCaseConstants.BUYER_REVIEW_NO_CODE_DATE_CASE_TAB_COLUMNS;
      } case WorkflowState.BUYER_NEW_PRODUCT_REVIEW: {
        return UploadCandidateCaseConstants.BUYER_REVIEW_CASE_TAB_COLUMNS;
      } case WorkflowState.SCA_NEW_PRODUCT_NO_CODE_DATE_REVIEW: {
        return UploadCandidateCaseConstants.SCA_REVIEW_NO_CODE_DATE_CASE_TAB_COLUMNS;
      } case WorkflowState.SCA_NEW_PRODUCT_REVIEW: {
        return UploadCandidateCaseConstants.SCA_REVIEW_CASE_TAB_COLUMNS;
      } case WorkflowState.PIA_NEW_PRODUCT_REVIEW: {
        return UploadCandidateCaseConstants.NEW_WAREHOUSE_PRODUCT_CASE_TAB_COLUMNS;
      }

      default: {
        this.growlService.addError('Table for Candidate Type "' + workflowState + '" not implemented!');
        return [];
      }
    }
  }

  /**
   * Returns the columns for supplier and heb tab.
   * @param candidateType
   */
  public getSupplierAndHebColumnsFromCandidateType(candidateType: string, isWarehouse: boolean): any[] {
    if (!candidateType) {
      this.growlService.addError(candidateType + ' not provided!');
      return [];
    }
    switch (candidateType) {
      case Candidate.NEW_PRODUCT:
        if (isWarehouse) {
          return UploadCandidateSupplierAndHebConstants.NEW_WAREHOUSE_PRODUCT_SUPPLIER_AND_HEB_TAB_COLUMNS;
        } else {
          return UploadCandidateSupplierAndHebConstants.NEW_DSD_PRODUCT_SUPPLIER_AND_HEB_TAB_COLUMNS;
        }
      case Candidate.NEW_PRODUCT_UPLOAD: {
        if (isWarehouse) {
          return UploadCandidateSupplierAndHebConstants.UPLOADED_NEW_WAREHOUSE_PRODUCT_SUPPLIER_AND_HEB_TAB_COLUMNS;
        } else {
          return UploadCandidateSupplierAndHebConstants.NEW_DSD_PRODUCT_SUPPLIER_AND_HEB_TAB_COLUMNS;
        }
      }
      default: {
        this.growlService.addError('Table for Candidate Type "' + candidateType + '" not implemented!');
        return [];
      }
    }
  }

  /**
   * Returns the columns for supplier and heb tab.
   * @param workflowState the workflow state.
   */
  public getSupplierAndHebColumnsFromWorkflowState(workflowState: string): any[] {
    if (!workflowState) {
      this.growlService.addError('Workflow state not provided!');
      return [];
    }
    switch (workflowState) {
      case WorkflowState.BUYER_NEW_PRODUCT_NO_CODE_DATE_REVIEW:
      case WorkflowState.BUYER_NEW_PRODUCT_REVIEW: {
        return UploadCandidateSupplierAndHebConstants.BUYER_REVIEW_SUPPLIER_AND_HEB_TAB_COLUMNS;
      }  case WorkflowState.SCA_NEW_PRODUCT_NO_CODE_DATE_REVIEW:
         case WorkflowState.SCA_NEW_PRODUCT_REVIEW: {
        return UploadCandidateSupplierAndHebConstants.SCA_REVIEW_SUPPLIER_AND_HEB_TAB_COLUMNS;
      } case WorkflowState.PIA_NEW_PRODUCT_REVIEW: {
        return UploadCandidateSupplierAndHebConstants.PIA_REVIEW_SUPPLIER_AND_HEB_TAB_COLUMNS;
      }
      default: {
        this.growlService.addError('Table for workflow state "' + workflowState + '" not implemented!');
        return [];
      }
    }
  }

  /**
   * Returns the count of candidate columns in the supplied group columns.
   *
   * @param candidateColumns all of the candidate type's columns.
   * @param groupColumns the columns in the column group.
   * @return the count of columns provided in the group array.
   */
  getColumnGroupCount(candidateColumns: any[], groupColumns: any[]): number {
    if (!candidateColumns || candidateColumns.length === 0) {
      return 0;
    }
    let columnGroupCount = 0;
    for (let x = 0; x < groupColumns.length; x++) {
      if (candidateColumns.includes(groupColumns[x])) {
        columnGroupCount++;
      }
    }
    return columnGroupCount;
  }

  /**
   * Returns whether or not there is warehouse columns. All warehouse tables will have the same columns,
   * so only a boolean is needed in this case.
   *
   * @param candidateType
   */
  getHasWarehouseColumnsFromCandidateType(candidateType: string, showWarehouse?: boolean): boolean {
    switch (candidateType) {
      case Candidate.NEW_PRODUCT:
      case Candidate.ADDITIONAL_CASE_PACK:
      case Candidate.BONUS_SIZE:
      case Candidate.MRT:
      case Candidate.MRT_INNER:
      case Candidate.PLU:
      case Candidate.REPLACEMENT_UPC: {
        return true;
      }
      case Candidate.ASSOCIATE_UPC: {
        return false;
      }
      case Candidate.NEW_PRODUCT_UPLOAD: {
        return showWarehouse;
      }
      default: {
        this.growlService.addError('Table for Candidate Type "' + candidateType + '" not implemented!');
        return false;
      }
    }
  }
  /**
   * Returns whether or not there is dsd columns. All warehouse tables will have the same columns,
   * so only a boolean is needed in this case.
   *
   * @param candidateType
   */
  getHasDsdColumnsFromCandidateType(candidateType: string, showDsd?: boolean): boolean {
    switch (candidateType) {
      case Candidate.NEW_PRODUCT:
      case Candidate.ADDITIONAL_CASE_PACK:
      case Candidate.BONUS_SIZE:
      case Candidate.MRT:
      case Candidate.MRT_INNER:
      case Candidate.PLU:
      case Candidate.REPLACEMENT_UPC: {
        return false;
      }
      case Candidate.ASSOCIATE_UPC: {
        return false;
      }
      case Candidate.NEW_PRODUCT_UPLOAD: {
        return showDsd;
      }
      default: {
        this.growlService.addError('Table for Candidate Type "' + candidateType + '" not implemented!');
        return false;
      }
    }
  }
  /**
   * Returns whether or not there is warehouse columns. All warehouse tables will have the same columns,
   * so only a boolean is needed in this case.
   *
   * @param workflowState the workflow state.
   */
  getHasWarehouseColumnsFromWorkflowState(workflowState: string, isWarehouse?: boolean): boolean {
    switch (workflowState) {
      case WorkflowState.BUYER_NEW_PRODUCT_REVIEW:
      case WorkflowState.BUYER_NEW_PRODUCT_NO_CODE_DATE_REVIEW:
      case WorkflowState.SCA_NEW_PRODUCT_NO_CODE_DATE_REVIEW:
      case WorkflowState.SCA_NEW_PRODUCT_REVIEW: {
        return false;
      }
      case WorkflowState.PIA_NEW_PRODUCT_REVIEW: {
        return isWarehouse;
      }

      default: {
        this.growlService.addError('Warehouse Table for workflow state "' + workflowState + '" not implemented!');
        return false;
      }
    }
  }
  /**
   * Returns whether or not there are dsd columns. All dsd tables will have the same columns,
   * so only a boolean is needed in this case.
   *
   * @param workflowState the workflow state.
   */
  getHasDsdColumnsFromWorkflowState(workflowState: string, isDsd?: boolean): boolean {
    switch (workflowState) {
      case WorkflowState.SCA_NEW_PRODUCT_NO_CODE_DATE_REVIEW:
      case WorkflowState.SCA_NEW_PRODUCT_REVIEW: {
        return false;
      }
      case WorkflowState.BUYER_NEW_PRODUCT_REVIEW:
      case WorkflowState.BUYER_NEW_PRODUCT_NO_CODE_DATE_REVIEW:
      case WorkflowState.PIA_NEW_PRODUCT_REVIEW: {
        return isDsd;
      }

      default: {
        this.growlService.addError('Warehouse Table for workflow state "' + workflowState + '" not implemented!');
        return false;
      }
    }
  }

  /**
   * Returns the base DSD Columns.
   */
  getBaseDsdColumns() {
    return UploadCandidateDsdConstants.BASE_DSD_COLUMNS;
  }
}
