import { Brand } from 'pm-models/lib/brand';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PublisherService } from './publisher.service';
import { catchError, tap } from 'rxjs/operators';
import { GrowlService } from '../growl/growl.service';
import {
  ApDepartmentAndSubDepartment,
  Attribute,
  EmailMessage,
  LocationGroupStores,
  LocationGroupStoresWrapper,
  MatHierarchy,
  Product,
  Vendor
} from 'pm-models';
import { PageableResult } from 'pm-models/lib/pageableResult';

@Injectable()
export class LookupService {

  private lookupServiceBaseUrl = '/lookup';
  private lookUpServiceVersionBaseUrl = this.lookupServiceBaseUrl + '/versions';
  private emailBaseUrl = '/external/email';
  private SEARCH_BY_BRAND_IDS = '/brand/searchBrandIds/';
  private readonly GET_PRODUCTS_BY_UPCS_URL = this.lookupServiceBaseUrl + '/product/byUpcs';
  private readonly MAT_HIERARCHY_URL = this.lookupServiceBaseUrl + '/mat/hierarchy/';

  public static SUPPLIER_PAGE_SIZE = 150;
  public static readonly PRODUCT_SEARCH_SUPPLY_CHAIN_FILTER = 'SUPPLY-CHAIN';
  public static readonly PRODUCT_SEARCH_DSD_SUPPLY_CHAIN_FILTER = 'SUPPLY-CHAIN-DSD';

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(private http: HttpClient, private publisherService: PublisherService, private growlService: GrowlService) {
  }

  public findAllPackageTypes(): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/packageType').pipe(
      tap(() => this.logSuccess('Found all package Types')),
      catchError(this.handleError<any>('Find all Package Types'))
    );
  }

  public findAllUnitsOfMeasures(): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/unitOfMeasure').pipe(
      tap(() => this.logSuccess('Found all units of measure')),
      catchError(this.handleError<any>('Find all units of measure'))
    );
  }

  /**
   * Gets the upc object.
   * @param {number} upc UPC to check.
   * @returns {Observable<any>}
   */
  public getUpc(upc: number): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/upc/' + upc).pipe(
      tap(() => this.logSuccess('Found upc')),
      catchError(this.handleError<any>('Find upc'))
    );
  }

  /**
   * Gets the item object.
   * @param {number} item Item to check.
   * @returns {Observable<any>}
   */
  public getItem(item: number): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/item/' + item).pipe(
      tap(() => this.logSuccess('Found item')),
      catchError(this.handleError<any>('Find item'))
    );
  }

  /**
   * Finds all MerchandiseTypes by product type (sellable ('GOODS') or non-sellable ('SPLY')).
   * @returns {Observable<any>}
   */
  public findAllMerchandiseTypes(itemType, isSellable): Observable<any> {
    let params = new HttpParams();
    params = params.append('itemType', itemType);
    params = params.append('isSellable', isSellable);

    return this.http.get<any>(this.lookupServiceBaseUrl + '/merchandiseType', {params: params}).pipe(
      tap(() => this.logSuccess('Found all merchandise Types')),
      catchError(this.handleError<any>('Find all merchandise Types'))
    );
  }

  /**
   * Finds all OrderUnits.
   * @returns {Observable<any>}
   */
  public findAllOrderUnits(): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/order/orderUnit').pipe(
      tap(() => this.logSuccess('Found all order units')),
      catchError(this.handleError<any>('Find all Order Units'))
    );
  }

  /**
   * Finds all OrderRestrictions.
   * @returns {Observable<any>}
   */
  public findAllOrderRestrictions(): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/order/orderRestrictions').pipe(
      tap(() => this.logSuccess('Found all order restrictions')),
      catchError(this.handleError<any>('Find all Order Restrictions'))
    );
  }

  /**
   * Finds all Warehouses by ap number and lane id.
   * @param apNumber the vendor ap number.
   * @param laneId the lane id.
   * @returns {Observable<any>}
   */
  public findWarehousesByVendorApNumberAndLaneId(apNumber, laneId): Observable<any> {
    let params = new HttpParams();
    params = params.append('apNumber', apNumber);
    params = params.append('laneId', laneId);

    return this.http.get<any>(this.lookupServiceBaseUrl + '/whsvendor/warehousesByApNumberAndLaneId', {params: params}).pipe(
      tap(() => this.logSuccess('Found all warehouses.')),
      catchError(this.handleError<any>('Find all warehouses.'))
    );
  }

  /**
   * Finds all Commodities by buyer id.
   * @returns {Observable<any>}
   */
  public findAllCommoditiesByBuyerId(buyerId): Observable<any> {
    let params = new HttpParams();
    params = params.append('buyerId', buyerId);
    return this.http.get<any>(this.lookupServiceBaseUrl + '/commodity/searchByBuyerId', {params: params}).pipe(
      tap(() => this.logSuccess('Found commodities')),
      catchError(this.handleError<any>('Find all commodities'))
    );
  }

  public findBrand(brandId): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', brandId);
    params = params.append('exactSearch', 'true');
    return this.http.get<any>(this.lookupServiceBaseUrl + '/brand', {params: params}).pipe(
      tap(() => this.logSuccess('Found Brand')),
      catchError(this.handleError<any>('Find Brand By Id'))
    );
  }

  public findAllBrands(): Observable<Brand[]> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/brand').pipe(
      tap(() => this.logSuccess('Found Brands')),
      catchError(this.handleError<any>('Find All Brands'))
    );
  }

  public findVendor(vendorId): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', vendorId);
    params = params.append('exactSearch', 'true');
    return this.http.get<any>(this.lookupServiceBaseUrl + '/whsvendor/search', {params: params}).pipe(
      tap(() => this.logSuccess('Found Vendor')),
      catchError(this.handleError<any>('Find Vendor By Id'))
    );
  }

  public findDsdVendor(vendorId): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', vendorId);
    params = params.append('exactSearch', 'true');
    return this.http.get<any>(this.lookupServiceBaseUrl + '/vendordsd/search', {params: params}).pipe(
      tap(() => this.logSuccess('Found Vendor')),
      catchError(this.handleError<any>('Find DSD Vendor By Id'))
    );
  }

  public findSingleVendor(apNumber): Observable<any> {
    let params = new HttpParams();
    params = params.append('apNumber', apNumber);
    return this.http.get<any>(this.lookupServiceBaseUrl + '/whsvendor/vendorByApNumber', {params: params}).pipe(
      tap(() => this.logSuccess('Found Vendor')),
      catchError(this.handleError<any>('Find Vendor By Id'))
    );
  }

  /**
   * findVendorEmailByApNumberAndVendorType.
   *
   * @param apNumber the ap number.
   * @param vendorTypeCode the vendor type code. ('AP' for Warehouse, 'DS' for DSD). If both DSD/WHS will  contain a record for both.
   */
  public findVendorEmailByApNumberAndVendorType(apNumber, vendorTypeCode): Observable<any> {
    const httpOptionsPlain = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }),
      'responseType': 'text' as 'json'
    };
    return this.http.get<any>(this.emailBaseUrl + '/vendorEmail/apNumber/' + apNumber + '/vendorTypeCode/' + vendorTypeCode,
      httpOptionsPlain).pipe(
      tap(() => this.logSuccess('Found Vendor email')),
      catchError(this.handleError<any>('Find Vendor email'))
    );
  }

  public findAllWarehouseVendors(pageSize?: number): Observable<any> {
    let url = this.lookupServiceBaseUrl + '/whsvendor';
    if (pageSize) {
      url += '?pageSize=' + pageSize;
    }

    return this.http.get<any>(url).pipe(
      tap(() => this.logSuccess('Found Vendors')),
      catchError(this.handleError<any>('Find All Vendors'))
    );
  }

  public findBuyers(pageSize?: number): Observable<any> {
    let url = this.lookupServiceBaseUrl + '/buyer';
    if (pageSize) {
      url += '?pageSize=' + pageSize;
    }

    return this.http.get<any>(url).pipe(
      tap(() => this.logSuccess('Found Buyers')),
      catchError(this.handleError<any>('Find All Buyers'))
    );
  }

  public findAllQualifyingConditions(): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/qualifyingConditions').pipe(
      tap(() => this.logSuccess('Found qualifying Conditions')),
      catchError(this.handleError<any>('Find All qualifying Conditions'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.logError(`error in ${operation} failed: ${error.message}`);
      return observableThrowError(error);
    };
  }

  /** Log a CandidateService message with the GrowlService */
  private logSuccess(message: string) {
    this.publisherService.add('LookupService: ' + message);
  }

  /** Log a CandidateService message with the GrowlService */
  private logError(message: string) {
    this.growlService.addError(message);
    this.publisherService.add('LookupService: ' + message);
  }

  public sendEmail(emailMessage: EmailMessage): Observable<any> {
    return this.http.post(this.emailBaseUrl, emailMessage, this.httpOptions).pipe(
      catchError(this.handleError<any>('Unable to send email notification.'))
    );
  }

  /**
   * Retrieves lookup service's version .
   *
   * @returns {Observable<any>}
   */
  public getLookupServiceVersion(): Observable<any> {
    return this.http.get<any>(this.lookUpServiceVersionBaseUrl + '/current').pipe(
      tap(version => this.logSuccess('lookup service version= ' + version)),
      catchError(this.handleError<any>('lookup versionEndpoint'))
    );
  }

  public getProductByUpcAndApNumbers(upc, apNumbers, filters?: string[]): Observable<any> {
    let params = new HttpParams();
    params = params.append('upc', upc);
    params = params.append('apNumbers', apNumbers);
    params = params.append('filters', !!filters?.length ? filters.toString() : '');
    return this.http.get<any>(this.lookupServiceBaseUrl + '/product/byUpcAndApNumbers', {params: params}).pipe(
      tap(() => this.logSuccess('Found Product by UPC:' + upc)),
      catchError(this.handleError<any>('Find product by UPC'))
    );
  }

  public getProductsByUpcs(upcs: number[], filters?: string[]): Observable<Product[]> {
    let params = new HttpParams();
    if (!!filters?.length) {
      params = params.append('filters', filters.toString());
    }
    return this.http.post<Product[]>(this.GET_PRODUCTS_BY_UPCS_URL, upcs, {params: params}).pipe(
      catchError(this.handleError<any>('Find products by UPCs'))
    );
  }

  public getProductByItemCodeAndApNumbers(itemCode, apNumbers): Observable<any> {
    let params = new HttpParams();
    params = params.append('itemCode', itemCode);
    params = params.append('apNumbers', apNumbers);
    return this.http.get<any>(this.lookupServiceBaseUrl + '/product/byItemCodeAndApNumbers', {params: params}).pipe(
      tap(() => this.logSuccess('Found Product by item code:' + itemCode)),
      catchError(this.handleError<any>('Find product by item code'))
    );
  }

  public findCommodity(commodityId): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', commodityId);
    return this.http.get<any>(this.lookupServiceBaseUrl + '/commodity/search', {params: params}).pipe(
      tap(() => this.logSuccess('Found Commodity')),
      catchError(this.handleError<any>('Find Commodity By Id'))
    );
  }

  public findCommodityById(commodityId): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/commodity/findByCommodityId/' + commodityId).pipe(
      tap(() => this.logSuccess('Found Commodity')),
      catchError(this.handleError<any>('Find Commodity By Id'))
    );
  }

  public findPssDepartment(pssDepartmentName): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', pssDepartmentName);
    return this.http.get<any>(this.lookupServiceBaseUrl + '/pssdepartment/search', {params: params}).pipe(
      tap(() => this.logSuccess('Found PssDepartment')),
      catchError(this.handleError<any>('Find PssDepartment by Name'))
    );
  }

  public findBuyer(buyerId): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', buyerId);
    params = params.append('exactSearch', 'true');
    return this.http.get<any>(this.lookupServiceBaseUrl + '/buyer/search', {params: params}).pipe(
      tap(() => this.logSuccess('Found Buyer')),
      catchError(this.handleError<any>('Find Buyer By Id'))
    );
  }

  public findBrandsById(brandIds: number[]): Observable<any[]> {
    return this.http.post(this.lookupServiceBaseUrl + this.SEARCH_BY_BRAND_IDS, brandIds, this.httpOptions).pipe(
      catchError(this.handleError<any>('Unable to search by brandIds.'))
    );
  }

  public findDefaultTaxCategories(subCommodityCode: string, taxable): Observable<any> {
    let params = new HttpParams();
    params = params.append('subCommodityCode', subCommodityCode);
    params = params.append('taxable', taxable);
    params = params.append('pageSize', 500 + ''); // get all the categories

    return this.http.get<any>(this.lookupServiceBaseUrl + '/vertexTaxCategory/findAllWithDefaults', {params: params}).pipe(
      tap(() => this.logSuccess('Found Default Tax Categories')),
      catchError(this.handleError<any>('Find Tax Categories By Id and taxable'))
    );
  }

  public findAllIncoTerms(): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/import/incoTerms').pipe(
      tap(() => this.logSuccess('Found all IncoTerms')),
      catchError(this.handleError<any>('Find all IncoTerms'))
    );
  }

  public findAllContainerSizes(): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/import/containerSizes').pipe(
      tap(() => this.logSuccess('Found all containerSizes')),
      catchError(this.handleError<any>('Find all containerSizes'))
    );
  }

  public findAllAuthGroups(apNumber, deptNo, subDept): Observable<LocationGroupStores[]> {
    return this.http.post(this.lookupServiceBaseUrl + '/vendordsd/dsd/' + apNumber + '/dept/' + deptNo +
      '/subDept/' + subDept + '/findAllAuthGroupsByApNumberAndDepartmentAndSubDepartment', this.httpOptions).pipe(
      tap(() => this.logSuccess('Found all containerSizes')),
      catchError(this.handleError<any>('Find all containerSizes'))
    );
  }

  findAllDrugNameAbbreviations() {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/pharmacy/findAllDrugNameAbbreviations').pipe(
      tap(() => this.logSuccess('Found all DrugNameAbbreviations')),
      catchError(this.handleError<any>('Find all DrugNameAbbreviations'))
    );
  }

  findAllDrugScheduleTypeCodes() {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/pharmacy/findAllDrugScheduleTypeCodes').pipe(
      tap(() => this.logSuccess('Found all DrugScheduleTypeCodes')),
      catchError(this.handleError<any>('Find all DrugScheduleTypeCodes'))
    );
  }

  findAllPseTypes() {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/pharmacy/findAllPSE').pipe(
      tap(() => this.logSuccess('Found all PSE types')),
      catchError(this.handleError<any>('Find all PSE types'))
    );
  }

  /**
   * Finds vertex tax category by id.
   * @returns {Observable<any>}
   */
  public findVertexTaxCategoryById(id): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/vertexTaxCategory/' + id).pipe(
      tap(() => this.logSuccess('Found vertex tax category by id')),
      catchError(this.handleError<any>('Find vertex tax category by id'))
    );
  }

  /**
   * Returns all vendors (whs/dsd/both) by page number and page size.
   *
   * @param pageNumber page number
   * @param pageSize page size.
   * @param includeCount whether or not to run the count query.
   * @param apNumbers the ap numbers to filter on, if any.
   */
  public findAllVendorsByPage(pageNumber: number, pageSize: number, includeCount: boolean,
                              apNumbers?: string[]): Observable<PageableResult> {
    let params = new HttpParams();
    params = params.append('page', pageNumber.toString());
    params = params.append('pageSize', pageSize.toString());
    params = params.append('includeCount', String(includeCount));

    if (apNumbers && apNumbers.length > 0) {
      params = params.append('apNumbers', apNumbers.toString());
    }

    return this.http.get<any>(this.lookupServiceBaseUrl + '/vendor/byPage', {params: params}).pipe(
      tap(() => this.logSuccess('Found Vendors')),
      catchError(this.handleError<any>('Find All Vendors'))
    );
  }
  /**
   * Returns all vendors (whs/dsd/both).
   */
  public findAllVendors(): Observable<Vendor[]> {
    let params = new HttpParams();
    params = params.append('byPage', 'false');

    return this.http.get<any>(this.lookupServiceBaseUrl + '/vendor', {params: params}).pipe(
      tap(() => this.logSuccess('Found Vendors')),
      catchError(this.handleError<any>('Find All Vendors'))
    );
  }

  /**
   * Finds attributes by Mat Hierarchy id. Returns the global attributes by default. Will include attribute values if requested.
   *
   * @param matHierarchyId the MAT hierarchy node id to find attributes for.
   * @param includeAttributeValues whether or not to include attribute values. Defaults to false.
   * @return attributes by Mat Hierarchy id.
   */
  public findAttributesByMatHierarchyId(matHierarchyId: number, includeAttributeValues: boolean): Observable<Attribute[]> {

    let params = new HttpParams();
    params = params.append('matHierarchyId', matHierarchyId.toString());
    params = params.append('includeAttributeValues', String(includeAttributeValues));


    return this.http.get<Attribute[]>(this.lookupServiceBaseUrl + '/attribute/findByMatHierarchyId', {params: params}).pipe(
      tap(() => this.logSuccess('Found attribute')),
      catchError(this.handleError<any>('Find All attribute'))
    );
  }

  /**
   * Finds all global attributes.
   * @param includeAttributeValues whether or not to include attribute values. Defaults to false.
   * @return all global attributes.
   */
  public findAllGlobalAttributes(includeAttributeValues: boolean): Observable<Attribute[]> {
    let params = new HttpParams();
    params = params.append('includeAttributeValues', String(includeAttributeValues));

    return this.http.get<Attribute[]>(this.lookupServiceBaseUrl + '/attribute/findAllGlobalAttributes', {params: params}).pipe(
      tap(() => this.logSuccess('Found global attributes')),
      catchError(this.handleError<any>('Find All attribute'))
    );
  }

  /**
   * Finds country of origin by id.
   * @returns {Observable<any>}
   */
  public findCountryOfOrigin(countryOfOriginId): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', countryOfOriginId);
    return this.http.get<any>(this.lookupServiceBaseUrl + '/countryOfOrigin/searchById', {params: params}).pipe(
      tap(() => this.logSuccess('Found Country Of Origin')),
      catchError(this.handleError<any>('Find Country Of Origin By Id'))
    );
  }

  /**
   * Gets nutrition by plu.
   * @param {number} plu the plu.
   * @returns {Observable<any>}
   */
  public findNutrition(plu: number): Observable<any> {
    return this.http.get<any>(this.lookupServiceBaseUrl + '/nutrition/' + plu).pipe(
      tap(() => this.logSuccess('Found nutrition')),
      catchError(this.handleError<any>('Find nutrition'))
    );
  }

  /**
   * Returns a list of location group store information for a list of ApDepartmentAndSubDepartments.
   */
  public findAllLocationGroupStoresByApDepartmentAndSubDepartments(apDepartmentAndSubDepartment: ApDepartmentAndSubDepartment[]):
    Observable<LocationGroupStoresWrapper[]> {

    return this.http.post<LocationGroupStoresWrapper[]>(this.lookupServiceBaseUrl + '/vendordsd/locationGroupsByApDepartmentAndSubDepartments',
      apDepartmentAndSubDepartment, this.httpOptions).pipe(
      tap(() => this.logSuccess('Found LocationGroupStoresWrappers!')),
      catchError(this.handleError<any>('Error finding location group stores!'))
    );
  }

  public findSubDepartment(departmentId): Observable<any> {
    let params = new HttpParams();
    params = params.append('query', departmentId);
    return this.http.get<any>(this.lookupServiceBaseUrl + '/subDepartment/search', {params: params}).pipe(
      tap(() => this.logSuccess('Found Sub Department')),
      catchError(this.handleError<any>('Find Sub Department by Id'))
    );
  }

  public findMatHierarchyByMatHierarchyId(matHierarchyId): Observable<MatHierarchy> {
    return this.http.get<any>(this.MAT_HIERARCHY_URL.concat(matHierarchyId)).pipe(
      tap(() => this.logSuccess('Found mat hierarchy'))
    );
  }
}
