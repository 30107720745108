import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BaseAttributeDisplayComponent} from '../base-attribute-display/base-attribute-display.component';
import {UpcService} from '../../services/upc-service/upc.service';
import {AttributeUPCConfig} from 'pm-models';

export enum InputState {
  none,
  loading,
  valid,
  invalid
}
@Component({
  selector: 'pm-attribute-display-upc',
  templateUrl: './attribute-display-upc.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-upc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayUpcComponent extends BaseAttributeDisplayComponent implements OnInit {
  @Output()
  shiftEnterEmitted?: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  attribute: AttributeUPCConfig;

  checkDigitValue;
  @Input()
  get checkDigitModel() {
    return this.checkDigitValue;
  }
  set checkDigitModel(val) {
    if (val === undefined || val === null) {
      this.checkDigitValue = null;
    } else if (val !== this.checkDigitValue) {
      this.checkDigitValue = val;
      this.checkDigitModelChange.emit(this.checkDigitValue);
    }
  }

  @Input()
  state: InputState;

  @Output() checkDigitModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateUpc?: EventEmitter<any> = new EventEmitter<any>();

  constructor(private upcService: UpcService) {
    super();
  }

  ngOnInit() {}

  get modelDisplay(): string {
    if (this.model) {
      return this.upcService.getG14Upc(this.model);
    }
    return '';
  }

  upcKeyDown(event) {
    const ESCAPE_KEYBOARD_KEYS = [9, 13, 27];
    if (ESCAPE_KEYBOARD_KEYS.indexOf(event.keyCode) !== -1) {
      this.validateUpc.emit(event);
    }
  }

  checkDigitKeyDown(event) {
    const SHIFT_KEY = 13;
    if (event.shiftKey && event.keyCode === SHIFT_KEY) {
      this.shiftEnterEmitted.emit(event);
    } else {
      this.upcKeyDown(event);
    }
  }

  upcBlur(e) {
    this.validateUpc.emit(e);
  }

  upcChange(e) {
    this.model = e;
    this.modelChange.emit(e);
  }
}
