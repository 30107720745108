<p-multiSelect [options]="items" [(ngModel)]="model" [panelStyle]="{minWidth:'12em'}" #ms [showHeader]="false" class="pm-selection-filter">
  <ng-template let-value pTemplate="selectedItems">
    <span class="ui-multiselected-empty-token ui-corner-all">{{label}} <span
        *ngIf="value?.length > 0">{{value?.length}}</span></span>
  </ng-template>
  <p-footer>
    <button pButton (click)="clearStatusFilter(ms, $event)" label="Clear" class="ui-button-secondary"></button>
    <button pButton (click)="applyStatusFilter(ms, $event)" label="Apply" class="ui-button-info"></button>
  </p-footer>
</p-multiSelect>
