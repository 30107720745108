import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';

import {Candidate, Task, TaskDecision} from 'pm-models';
import {forkJoin, Observable, of} from 'rxjs';
import {WorkflowService} from '../../service/workflow.service';

import {TaskListTask} from '../task-list-service/taskListTask';
import {TaskViewerTableComponent} from '../task-viewer-table/task-viewer-table.component';
import {catchError, concatMap, map} from 'rxjs/operators';

@Component({
  selector: 'app-vendor-task-viewer-table',
  templateUrl: './vendor-task-viewer-table.component.html',
  styleUrls: ['./vendor-task-viewer-table.component.scss']
})
export class VendorTaskViewerTableComponent extends TaskViewerTableComponent implements OnInit, AfterViewInit {

  @ViewChild('retireCpsOverlay') retireCpsOverlay;

  mapColumnNamePrimeTableClosedToEndPoint = new Map([
    ['candidateId', 'CANDIDATE_ID'],
    ['description', 'PRODUCT_DESCRIPTION'],
    ['displayName', 'STATUS'],
    ['upc', 'UPC'],
    ['itemCode', 'ITEM_CODE'],
    ['supplierDisplayName', 'SUPPLIER_NAME'],
    ['lastUpdatedBy', 'LAST_UPDATED_BY'],
    ['lastUpdatedTime', 'LAST_UPDATED_TIME'],
  ]);

  mapColumnNamePrimeTableInProgressToEndPoint = new Map([
    ['candidateId', 'CANDIDATE_ID'],
    ['description', 'PRODUCT_DESCRIPTION'],
    ['supplierDisplayName', 'SUPPLIER_NAME'],
    ['lastUpdatedBy', 'LAST_UPDATED_BY'],
    ['lastUpdatedTime', 'LAST_UPDATED_TIME'],
  ]);

  //
  // rejected table variables
  //
  rejectedTasks: TaskListTask[] = [];
  rejectedTotalNumRecords: number;

  rejectedCurrentPageNumber: number = 0;
  rejectedCurrentSortOrder: string = 'DESC';
  rejectedCurrentSortField: string = 'CANDIDATE_ID';

  //
  // approved table variables
  //
  approvedTasks: TaskListTask[] = [];
  approvedTotalNumRecords: number;

  approvedCurrentPageNumber: number = 0;
  approvedCurrentSortOrder: string = 'DESC';
  approvedCurrentSortField: string = 'CANDIDATE_ID';

  //
  // in-progress table variables
  //
  inProgressTasks: TaskListTask[] = [];
  inProgressTotalNumRecords: number;

  inProgressCurrentPageNumber: number = 0;
  inProgressCurrentSortOrder: string = 'DESC';
  inProgressCurrentSortField: string = 'CANDIDATE_ID';

  // ---

  tabIndex: number = 0;

  OPEN_TAB = {
    title: 'Open',
    tooltip: 'Candidates that have tasks assigned to you.',
    index: 0
  };

  UNDER_REVIEW_TAB = {
    title: 'Under Review',
    tooltip: 'Candidates that have tasks assigned to H-E-B.',
    index: 1
  };

  REJECTED_TAB = {
    title: 'Rejected',
    tooltip: 'Candidates that have been rejected.',
    index: 2
  };

  ACTIVATED_TAB = {
    title: 'Activated',
    tooltip: 'Candidates that have been activated.',
    index: 3
  };

  async ngOnInit() {
    this.changeTab({index: this.tabIndex});
  }

  ngAfterViewInit(): void {
    if (this.authService.isVendor()) {
      this.preferencesService.getPreferences().subscribe((preferences) => {
        if (!preferences || !preferences.hideRetireCpsModal) {
          this.userPreferences = preferences;
          this.retireCpsOverlay.show('taskTable', null);
        }
      });
    }
  }

  getOpenTasks() {
    super.getOpenTasks(true, this.currentPageNumber, this.currentSortOrder, this.currentSortField);
  }

  getRejectedProcesses() {
    this.getRejectedProcessesParms(true, this.rejectedCurrentPageNumber, this.rejectedCurrentSortOrder, this.rejectedCurrentSortField);
  }

  getRejectedProcessesParms(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {
    this.showSpinner = true;
    this.taskListService.getRejectedProcesses(getCount, pageNumber, sortOrder, sortField).subscribe(result => {
      if (result.length > 0) {
        if (getCount) {
          this.rejectedTotalNumRecords = result[0].count;
        }
        this.rejectedTasks = result;
      } else {
        this.rejectedTotalNumRecords = 0;
        this.rejectedTasks = [];
      }
      this.showSpinner = false;
    });
  }

  getCompletedProcesses() {
    this.getCompletedProcessesParms(true, this.approvedCurrentPageNumber, this.approvedCurrentSortOrder, this.approvedCurrentSortField);
  }

  getCompletedProcessesParms(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {
    this.showSpinner = true;
    this.taskListService.getCompletedProcesses(getCount, pageNumber, sortOrder, sortField).subscribe(result => {
      if (result.length > 0) {
        if (getCount) {
          this.approvedTotalNumRecords = result[0].count;
        }
        this.approvedTasks = result;
      } else {
        this.approvedTotalNumRecords = 0;
        this.approvedTasks = [];
      }
      this.showSpinner = false;
    });
  }

  getInProgressTasks() {
    this.getInProgressTasksParms(
      true,
      this.inProgressCurrentPageNumber,
      this.inProgressCurrentSortOrder,
      this.inProgressCurrentSortField);
  }

  getInProgressTasksParms(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {
    this.showSpinner = true;

    this.taskListService.getInProgressTasks(getCount, pageNumber, sortOrder, sortField).subscribe(result => {
      if (result.length > 0) {
        if (getCount) {
          this.inProgressTotalNumRecords = result[0].count;
        }
        this.inProgressTasks = result;
      } else {
        this.inProgressTotalNumRecords = 0;
        this.inProgressTasks = [];
      }
      this.showSpinner = false;
    });
  }

  changeTab(event) {
    this.tabIndex = event.index;

    switch (this.tabIndex) {
      case this.OPEN_TAB.index: {
        this.getOpenTasks();
        break;
      }
      case this.UNDER_REVIEW_TAB.index: {
        this.getInProgressTasks();
        break;
      }
      case this.REJECTED_TAB.index: {
        this.getRejectedProcesses();
        break;
      }
      case this.ACTIVATED_TAB.index: {
        this.getCompletedProcesses();
        break;
      }
    }

    this.clearTabIndexParam();
  }

  clearTabIndexParam() {
    this.router.navigate(
      ['.'],
      { relativeTo: this.route, queryParams: { } }
    ).then();
  }

  showError(error: any) {
    let errorMessage: string;

    if (error.error) {
      errorMessage = `${error.error.message}`;
    } else {
      errorMessage = `${error.message}`;
    }

    this.growlService.addError(errorMessage);
  }

  onLoadDataRejected(event: any) {
    const pageNumber = this.getPageNumber(event.first, this.taskListService.PAGE_SIZE);
    let sortField = this.mapColumnNamePrimeTableClosedToEndPoint.get(event.sortField);
    if (!sortField) {
      sortField = 'CANDIDATE_ID';
    }
    const sortOrder = this.getSortOrder(sortField, this.mapSortOrderPrimeTableToEndPoint.get(event.sortOrder + 0));

    if (this.rejectedCurrentPageNumber === pageNumber &&
      this.rejectedCurrentSortOrder === sortOrder &&
      this.rejectedCurrentSortField === sortField) {
      return;
    } else {
      this.rejectedCurrentPageNumber = pageNumber;
      this.rejectedCurrentSortOrder = sortOrder;
      this.rejectedCurrentSortField = sortField;
    }

    this.getRejectedProcessesParms(false, pageNumber, sortOrder, sortField);
  }

  onLoadDataApproved(event: any) {
    const pageNumber = this.getPageNumber(event.first, this.taskListService.PAGE_SIZE);
    let sortField = this.mapColumnNamePrimeTableClosedToEndPoint.get(event.sortField);
    if (!sortField) {
      sortField = 'CANDIDATE_ID';
    }
    const sortOrder = this.getSortOrder(sortField, this.mapSortOrderPrimeTableToEndPoint.get(event.sortOrder + 0));

    if (this.approvedCurrentPageNumber === pageNumber &&
      this.approvedCurrentSortOrder === sortOrder &&
      this.approvedCurrentSortField === sortField) {
      return;
    } else {
      this.approvedCurrentPageNumber = pageNumber;
      this.approvedCurrentSortOrder = sortOrder;
      this.approvedCurrentSortField = sortField;
    }

    this.getCompletedProcessesParms(false, pageNumber, sortOrder, sortField);
  }

  onLoadDataInProgress(event: any) {
    const pageNumber = this.getPageNumber(event.first, this.taskListService.PAGE_SIZE);
    let sortField = this.mapColumnNamePrimeTableInProgressToEndPoint.get(event.sortField);
    if (!sortField) {
      sortField = 'CANDIDATE_ID';
    }
    const sortOrder = this.getSortOrder(sortField, this.mapSortOrderPrimeTableToEndPoint.get(event.sortOrder + 0));

    if (this.inProgressCurrentPageNumber === pageNumber &&
      this.inProgressCurrentSortOrder === sortOrder &&
      this.inProgressCurrentSortField === sortField) {
      return;
    } else {
      this.inProgressCurrentPageNumber = pageNumber;
      this.inProgressCurrentSortOrder = sortOrder;
      this.inProgressCurrentSortField = sortField;
    }

    this.getInProgressTasksParms(false, pageNumber, sortOrder, sortField);
  }

  onClickRowNotOpen(task: TaskListTask) {
    return this.router.navigate([this.getUrlForNotOpenTaskDetails(task)],
      {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)}).then();
  }

  getUrlForNotOpenTaskDetails(task: TaskListTask): string {
    if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
      return '/newProductReviewPageComponent';
    } else if (task.taskType === Candidate.MRT) {
      return '/mrtCaseDetailsReview';
    } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
      return '/associateSupplierReview';
    } else if (task.taskType === Candidate.BONUS_SIZE) {
      return '/bonusReview';
    } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
      return '/additionalCasePackReview';
    } else if (task.taskType === Candidate.MRT_INNER) {
      return '/mrtInnerSupplierReview';
    } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
      return '/replacementUpcReview';
    } else if (task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
      return '/invitedDistributorReviewPage';
    } else {
      return '/';
    }
  }

  /**
   * Complete any workflow tasks associated with the specified candidate ID.
   * @param candidateId to check for tasks and complete
   */
  completeTaskByCandidateId(candidateId: number): Observable<any> {
    return this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidateId).pipe(
      concatMap((taskResult) => {
          if (taskResult.name === Task.KEY_VENDOR_DATA || taskResult.name === Task.ASSOCIATE_VENDOR_TASK_NAME) {
            return this.workflowService.completeTaskWithAction(taskResult, WorkflowService.ACTION_COMPLETE,
              TaskDecision.KEY_VENDOR_DATA_DECISION_YES);
          } else if (taskResult.name === 'Key Buyer Data') {
            return this.workflowService.completeTaskWithAction(taskResult, WorkflowService.ACTION_COMPLETE,
              TaskDecision.KEY_BUYER_DATA_REJECT_DECISION);
          } else if (taskResult.name === 'PIA Final Review') {
            return this.workflowService.completeTaskWithAction(taskResult, WorkflowService.ACTION_COMPLETE,
              TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION);
          } else if (taskResult.name === Task.DSD_INVITED_SUPPLIER_VENDOR_TASK_NAME) {
            return this.workflowService.completeTaskWithAction(taskResult, WorkflowService.ACTION_COMPLETE,
              TaskDecision.KEY_VENDOR_DATA_DECISION_REJECT);
          } else {
            console.log('Delete not setup for task: ' + taskResult.name);
            return of({});
          }
        }
      ), catchError(() => {
        return of({});
      })
    );
  }

  /**
   * Rejects the specified candidate, the parent MRT candidates (if any) and related non draft candidates of the parent.
   * Sets status to Declined, and vendorComment to Deleted.
   * @param candidateId to reject.
   */
  rejectCandidate(candidateId: number) {
    this.rejectCandidateById(candidateId, [])
      .subscribe(
        () => {
          this.getOpenTasks();
        });
  }


  /**
   * Rejects a candidate and any associated candidates by id.
   * @param candidateId
   * @param deletedCandidateIds
   */
  rejectCandidateById(candidateId: number, deletedCandidateIds: number[]): Observable<any>  {
    return this.candidateService.findParentMrtCandidatesForCandidateId(candidateId, [Candidate.IN_PROGRESS])
      .pipe(
        // Filter out candidates that have been deleted by a previous call.
        map((mrtParentCandidates: Candidate[]) => this.removeDeletedCandidates(mrtParentCandidates, deletedCandidateIds)),
        // delete candidate and any directly associated candidates.
        concatMap((mrtParentCandidates: Candidate[]) =>
          this.deleteAllAssociatedTasksAndCandidates(candidateId, mrtParentCandidates, deletedCandidateIds)),
      );
  }

  /**
   * Updates candidates to delete to account for already deleted candidates (e.g. dual references).
   * @param mrtParentCandidates
   * @param deletedCandidateIds
   */
  removeDeletedCandidates(mrtParentCandidates: Candidate[], deletedCandidateIds: number[]): Candidate[] {
    if (!deletedCandidateIds || deletedCandidateIds.length === 0) {
      return mrtParentCandidates;
    } else {
      return mrtParentCandidates.filter((candidate) => !deletedCandidateIds.includes(candidate.candidateId));
    }
  }


  /**
   * Deletes the candidate and all associated candidates.
   * @param candidateId
   * @param mrtParentCandidates
   * @param deletedCandidates
   */
  deleteAllAssociatedTasksAndCandidates(candidateId: number, mrtParentCandidates: Candidate[],
                                        deletedCandidates: number[]): Observable<any> {

    return this.candidateService.getCandidate(candidateId).pipe(
      concatMap((candidateToDelete: Candidate) => {
          // if the candidate isn't associated with any MRTs and also isn't an MRT itself or there's no candidate inners to delete,
          // simply delete the candidate and task.
          if ((!mrtParentCandidates || mrtParentCandidates.length === 0) && (candidateToDelete.candidateType !== Candidate.MRT ||
            !candidateToDelete.mrtInfo.candidateInners || candidateToDelete.mrtInfo.candidateInners.length === 0)) {
            deletedCandidates.push(candidateId);
            return this.deleteTaskAndCandidateByCandidateId(candidateId);
          }

          let candidatesToDelete: Candidate[] = [candidateToDelete];
          if (mrtParentCandidates && mrtParentCandidates.length > 0) {
            candidatesToDelete = [candidateToDelete].concat(mrtParentCandidates);
          }
          const observables: Observable<any>[] = [];
          candidatesToDelete.forEach((candidate) => {
            observables.push(
              of(candidate).pipe(
                // delete non drafts associated with this task (if it's an MRT)
                concatMap(() =>
                  this.deleteMrtNonDraftInners(candidate, deletedCandidates)),
                // delete the candidate itself.
                concatMap(() => {
                  deletedCandidates.push(candidate.candidateId);
                  return this.deleteTaskAndCandidateByCandidateId(candidate.candidateId);
                }),
              )
            );
          });
          return forkJoin(observables);
        }
      )
    );
  }

  /**
   * Deletes all candidate inners that aren't drafts since these belong to another MRT or flow (including NEW_PRODUCT type candidates).
   * @param candidateToDelete
   * @param deletedCandidates
   */
  deleteMrtNonDraftInners(candidateToDelete: Candidate, deletedCandidates: number[]): Observable<Candidate> {

    // if there's no candidate inners to delete, delete the mrt and complete its task (unless it is the original candidate being deleted-
    // as this will be done last.
    if (!candidateToDelete.mrtInfo || !candidateToDelete.mrtInfo.candidateInners ||
      candidateToDelete.mrtInfo.candidateInners.length === 0) {
      return of(candidateToDelete);
    }

    const observables: Observable<any>[] = [];
    // remove already deleted inners, as well as MRT_INNER drafts (these will be deleted by parent MRT)- note NEW_PRODUCT drafts will be
    // removes in observable list call.
    const innersToReject = candidateToDelete.mrtInfo.candidateInners.filter(
      (candidateInner) => !deletedCandidates.includes(candidateInner.candidateId) && !candidateInner.draft);

    if (innersToReject && innersToReject.length > 0) {
      innersToReject.forEach((inner) => {
        observables.push(
          of(inner).pipe(
            concatMap((candidateInner) => this.candidateService.getCandidate(candidateInner.candidateId)),
            // NEW_PRODUCT types are also drafts in an MRT (since not MRT_INNER), so only delete the non drafts belonging to this MRT.
            concatMap((candidate: Candidate) => {
                if (candidate.candidateType !== Candidate.NEW_PRODUCT) {
                  deletedCandidates.push(candidate.candidateId);
                  return this.rejectCandidateById(candidate.candidateId, deletedCandidates);
                } else {
                  return of({});
                }
              }
            )
          )
        );
      });
      return forkJoin(observables).pipe(concatMap(() => of(candidateToDelete)));
    } else {
      return of(candidateToDelete);
    }
  }


  /**
   * Updates a candidate to deleted, and rejects the task.
   * @param candidateId
   */
  deleteTaskAndCandidateByCandidateId(candidateId: number): Observable<any> {
    return this.candidateService.deleteCandidateById(candidateId)
      .pipe(
        concatMap(() => this.completeTaskByCandidateId(candidateId))
      );
  }

  getSortOrder(sortField: string, sortOrder: string) {
    if (sortField === 'LAST_UPDATED_TIME' && sortOrder === 'ASC') {
      return 'DESC';
    } else if (sortField === 'LAST_UPDATED_TIME') {
      return 'ASC';
    }
    return sortOrder;
  }

  /**
   * Hides CPS is retiring overlay, and updates user preferences so they don't see the modal again.
   */
  onClickGotIt() {
    this.retireCpsOverlay.hide();

    if (!this.userPreferences) {
      this.userPreferences = { sendEmails: false, hasSetupPreferences: false, followingDesks: []};
    }
    this.userPreferences.hideRetireCpsModal = true;
    this.preferencesService.updatePreferences(this.userPreferences).subscribe(preferences => this.userPreferences = preferences);
  }

  /**
   * Opens the cps retirement pdf.
   */
  onClickLearnMore() {
    window.open('assets/documents/PAM Announcement - CPS Retirement.pdf');
  }
}

