<div *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())" class="col col-md-body">
  <div class="attributeRow">
    <div *ngFor="let product of model.slice(1)">
      <div class="row">
        <div class="inline-block col-md-10 col-md-upc">
          <pm-attribute-display-upc [attribute]="getConfig(product)" [(model)]="product.upc"
                                    [attributeError]="getCandidateProductError(product)"
                                    [(checkDigitModel)]="product.upcCheckDigit"
                                    (validateUpc)="validate(product)"
                                    [state]="getInputState(product)"
                                    (shiftEnterEmitted)="addAssociateUpc()">
          </pm-attribute-display-upc>
        </div>
        <div class="inline-block col-md-2 col-md-delete" *ngIf="isList">
          <div *ngxPermissionsOnly="['ROLE_BULK_ASSOCIATE']">
            <pm-delete-button (deleteButtonClicked)="deleteExistingAssociate(product)" [showConfirmationModal]="false"></pm-delete-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngxPermissionsOnly="['ROLE_BULK_ASSOCIATE']" class="row divRow">
      <button type="button" class="btn btn-link add-item-button p-0 mx-0" (click)="addAssociateUpc()">+ Add UPC</button>
      <p class="add-item-description">&nbsp;or hit Shift + Enter to add multiple items quickly.</p>
    </div>
  </div>
</div>
