import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Candidate, CandidateError, CandidateProduct, CandidateProductError, Product} from 'pm-models';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {CostService} from '../../service/cost.service';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {LookupService} from '../../service/lookup.service';
import {ProductService} from '../../service/product.service';
import {WorkflowService} from '../../service/workflow.service';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {ReviewComponent} from 'pm-components';
import {HistoryService} from '../../utils/history.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {UserRoleConstants} from '../../core/header/user-role-constants';
import {finalize, tap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {CandidateHistoryService} from '../../service/candidate-history.service';
import {MatUtilService} from '../../service/mat-util.service';
import {FileService} from '../../service/file.service';

@Component({
  selector: 'app-mrt-case-details-review',
  templateUrl: './mrt-case-details-review.component.html',
  styleUrls: ['./mrt-case-details-review.component.scss']
})
export class MrtCaseDetailsReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public UPC = 'UPC';

  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  public isViewingPage = false;
  public opened = true;
  public merchandiseTypes: any;
  public commodities: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public mrtExistingInners: ExistingInner[] = [];
  public mrtCandidateInners: CandidateInner[] = [];
  public isShowingWhatHappensNext = false;
  public decimalCount = 1;
  private taskId: any;
  public currentHistoryResults: any = undefined;
  public showHistoryPanel: boolean = false;
  public isResubmitting: boolean = false;
  public isLoadingInners = true;

  showMatAttributes = false;
  isLoadingMatData = true;

  // undefined if not yet calculated, false if is missing data,
  // true if all data present
  public isNotMissingCandidateInfo: Boolean;

  constructor(private workflowService: WorkflowService, private route: ActivatedRoute,
              private router: Router, private candidateService: CandidateService, private lookupService: LookupService,
              private growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public productService: ProductService, public candidateUtilService: CandidateUtilService,
              public supplierMrtService: SupplierMrtService, public historyService: HistoryService, public permissionService: NgxPermissionsService,
              public candidateHistoryService: CandidateHistoryService, public matUtilService: MatUtilService, public fileService: FileService) {
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.MRT) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.candidateHistoryService.setLastSupplierChangedCandidate(candidate);
            this.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
      if (params.has('taskId')) {
        this.taskId = parseInt(params.get('taskId'), 10);
      }
    });
  }

  private setInitialValues(candidate: any) {
    this.supplierMrtService.setCandidate(candidate);
    forkJoin([this.supplierMrtService.setExistingInnerProducts(), this.supplierMrtService.setInnerCandidates()]).pipe(
      tap(() => {
          this.handleExistingInners();
          this.handleActivatedCandidateInners();
          this.handleNonActivatedCandidateInners();
          this.isLoadingInners = false;
        }
      )
    ).subscribe();

    this.setupMatAttributes();

    this.currentHistoryResults = this.historyService.getCandidateHistory(this.supplierMrtService.getCandidate().candidateId, true);
    this.isViewingPage = true;
  }


  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      return;
    }
    this.supplierMrtService.resetMrtMatHierarchyFields();
    if (this.candidateUtilService.isInProgressCandidate(this.supplierMrtService.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatAttributesAndValues(this.supplierMrtService.getCandidate(), this.supplierMrtService.globalAttributes, []).pipe(
        tap(() => {
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierMrtService.globalAttributes,
            [], this.supplierMrtService.warehouseItemAttributes, []);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })
      ).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.supplierMrtService.getCurrentCandidateProduct()?.globalAttributes, [],
        this.supplierMrtService.warehouseItemAttributes, []);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }


  private handleExistingInners() {
    if (!!this.supplierMrtService.getCandidate().mrtInfo?.existingInners?.length) {
      this.supplierMrtService.getCandidate().mrtInfo.existingInners.forEach(existingInner => {
        this.mrtExistingInners.push(existingInner);
      });
    }
  }

  private handleActivatedCandidateInners() {
    const activatedCandidateInners: CandidateInner[] = this.supplierMrtService.getCandidate().mrtInfo?.candidateInners
      ?.filter(candidateInner => candidateInner.candidate && candidateInner.candidate.status === Candidate.ACTIVATED);

    if (!!activatedCandidateInners?.length) {
      const upcs: number[] = activatedCandidateInners.map(activatedCandidateInner =>
        activatedCandidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate)].upc);
      this.lookupService.getProductsByUpcs(upcs).pipe(
        tap((products: Product[]) => {
          activatedCandidateInners.forEach(activatedCandidateInner => {
            const index = CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate);
            const upc = activatedCandidateInner.candidate.candidateProducts[index].upc;
            const existingInner = new ExistingInner();
            existingInner.product = this.productService.getProductByUpc(upc, products);
            existingInner.unitCost = activatedCandidateInner.unitCost;
            existingInner.quantity = activatedCandidateInner.quantity;
            existingInner.upc = upc;
            existingInner.upcCheckDigit = activatedCandidateInner.candidate.candidateProducts[index].upcCheckDigit;
            this.mrtExistingInners.push(existingInner as ExistingInner);
          });
        })
      ).subscribe();
    }
  }

  private handleNonActivatedCandidateInners() {
    let hasRetail = true;
    if (!!this.supplierMrtService.getCandidate().mrtInfo?.candidateInners?.length) {
      this.supplierMrtService.getCandidate().mrtInfo.candidateInners.forEach(candidateInner => {
        if (candidateInner.candidate && candidateInner.candidate.status !== Candidate.ACTIVATED) {
          if (!candidateInner.candidate.retailType) {
            candidateInner.candidate.retailType = CostService.KEY_RETAIL;
          }
          // if the candidate is non replenishable, the unit cost is never calculated by supplier
          // due to lack of fields. So use the value from the mrt.
          if (!candidateInner.replenishable) {
            candidateInner.candidate.unitCost = candidateInner.unitCost;
            this.mrtCandidateInners.push(candidateInner as CandidateInner);
          } else {
            this.mrtCandidateInners.unshift(candidateInner as CandidateInner);
          }
          // if the candidate doesn't have retail, set isNotMissingCandidateInfo to false.
          if (candidateInner.candidate.retailType === CostService.PRICE_REQUIRED || !candidateInner.candidate.retailPrice || !candidateInner.candidate.retailXFor) {
            hasRetail = false;
          }
        }
      });
    }
    this.isNotMissingCandidateInfo = hasRetail;
  }


  private resetInitialValues() {
    this.isViewingPage = false;
  }

  onClose() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  getReviewClass(): string {
    let classString = '';
    classString += ' review-grid-container';
    return classString;
  }

  hasPendingInner(): boolean {
    let result = false;
    for (let x = 0; x < this.mrtCandidateInners.length; x++) {
      if (this.mrtCandidateInners[x].candidate.status === 'IN_PROGRESS') {
        result = true;
        break;
      }
    }
    return result;
  }

  getWhatHappensNextLabel() {
    if (this.isShowingWhatHappensNext) {
      return 'Show less.';
    } else {
      return 'What happens next?';
    }
  }

  toggleWhatHappensNext() {
    this.isShowingWhatHappensNext = !this.isShowingWhatHappensNext;
  }

  getMasterPack() {
    let masterPack = 0;
    if (this.supplierMrtService.getCandidate().mrtInfo.existingInners &&
      this.supplierMrtService.getCandidate().mrtInfo.existingInners.length > 0) {
      for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.existingInners.length; x++) {
        masterPack += this.supplierMrtService.getCandidate().mrtInfo.existingInners[x].quantity;
      }
    }
    if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners &&
      this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
        masterPack += this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].quantity;
      }
    }
    return masterPack;
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  onClickPrint() {
    window.print();
  }

  getTitleSubHeading(): String {
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.supplierMrtService.getCandidate().status);
    if (this.supplierMrtService.getCandidateProduct(0).itemCode) {
      infoString += ' | Item code: ' + this.supplierMrtService.getCandidateProduct(0).itemCode;
    }

    return infoString;
  }

  getCostDisplay() {
    // if we haven't deduced whether there's missing information, return empty string
    if (this.isNotMissingCandidateInfo === null || this.isNotMissingCandidateInfo === undefined) {
      return '';
      // if we're missing information, return message.
    } else if (this.isNotMissingCandidateInfo === false) {
      return 'We can’t calculate margin and penny profit because we’re missing retail information for one or more of the UPCs above.';
    }
  }

  getPennyProfit() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.supplierMrtService.getCandidate().masterListCost);

    if (!pennyProfit) {
      return '';
    }
    return this.costService.toCurrency(pennyProfit);
  }

  getMarginPercent() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.supplierMrtService.getCandidate().masterListCost);

    if (!pennyProfit) {
      return '';
    }
    const marginPercent = this.candidateUtilService.getMRTMarginPercent(pennyProfit, masterSuggestedRetail);
    return marginPercent.toPrecision(6);
  }

  isMarginNegativeOrZero() {
    const margin = +this.getMarginPercent();
    return margin <= 0;
  }


  toLowerCase(status: String) {
    return status.toLowerCase();
  }

  isRejected() {
    return this.supplierMrtService.getCandidate().status === Candidate.DECLINED;
  }

  resubmitCandidate() {
    this.isResubmitting = true;
    this.candidateService.reviveMrtCandidateByCandidateId(this.supplierMrtService.getCandidate()).subscribe((result: Candidate) => {
      this.supplierMrtService.setCandidate(result);
      this.supplierMrtService.createProcessInstanceWithCandidateId(result.candidateId, '/setupMrt', true);
      this.workflowService.deleteHistoricInstance(this.taskId).subscribe(() => {
      });
    }, (error) => {
      this.isResubmitting = false;
      this.growlService.addError(error);
    });
  }

  historyPanelOpen() {
    this.permissionService.hasPermission('ROLE_VIEW_CANDIDATE_HISTORY').then((hasPermission) => {
      if (hasPermission) {
        this.historyService.getCandidateHistory(this.supplierMrtService.getCandidate().candidateId, true);
        this.currentHistoryResults = this.historyService.sortedAudits;
        this.showHistoryPanel = true;
        this.pmReview.openDrawer();
      }
    });
  }

  historyPanelClose() {
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }
}
