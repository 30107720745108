<pm-review #pmReview (closeEvent)="onClose()">

  <div class="row">

    <div class="col-md-2"></div>
    <div class="col-md-10">
      <div class="row pt-5">
        <div class="col">
          <h1 class="text-left">Reserved UPC</h1>
        </div>
      </div>
      <div class="row" *ngIf="upcReservation">
        <div class="col-md-8">
          <pm-attribute-grid class="title-medium" title="UPC: {{upcReservation?.upc}}-{{upcReservation?.upc | upcCheckDigit}}">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Product description'" [canEdit]="canEdit" (editClicked)="editProductDescription()">
                  {{upcReservation?.description}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Supplier'" [canEdit]="canEdit" (editClicked)="editCandidate(attributeType.Vendor)">
                  {{upcReservation?.vendor?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Buyer'" [canEdit]="canEdit" (editClicked)="editCandidate(attributeType.Buyer)">
                  {{upcReservation?.buyer?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Reserved until'" [canEdit]="false">
                  <b>{{getDateString(upcReservation?.expirationDate)}}</b>
                </pm-grid-cell>
                <pm-grid-cell [label]="'Notes'" [canEdit]="false">
                  {{upcReservation?.upcRequestNotes}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>
      <div class="row" *ngIf="upcReservation && canEdit">
        <div class="col-md-8 text-right">
          <p-button class="ghost m-2" label="Delete & release UPC" (onClick)="onClickDeleteAndReleaseUpc($event, unreserveUpcOverlay, unreserveUpcDiv)"></p-button>
          <p-button class="m-2" label="Set up item" (onClick)="onClickSetUpItem()" [disabled]="isSetUpItemDisabled"></p-button>
        </div>
      </div>
    </div>
  </div>
</pm-review>

<div>
  <div #unreserveUpcDiv></div>
  <pm-static-overlay-panel #unreserveUpcOverlay appendTo="body" [style]="{width: '100%', height: '100%'}">

    <ng-container>
      <div class="row align-items-center unreserve-upc-overlay-wrapper">
        <div class="col-md-4 unreserve-upc-overlay">
          <div class="header-row">
            <h2>Delete reservation?</h2>
            <p>Deleting this UPC reservation will release the UPC and make it available for others to reserve.</p>
          </div>
          <div class="row button-row">
            <button pButton class="cancel mr-3" label="Cancel" (click)="unreserveUpcOverlay.hide()"></button>
            <button pButton class="ui-button-danger" label="Delete" (click)="deleteAndReleaseUpc(unreserveUpcOverlay)" [disabled]="isDeleteUpcDisabled"></button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-static-overlay-panel>
</div>
