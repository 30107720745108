import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierProductService} from '../../service/supplier-product.service';
import {
  AttachmentsCardPermissions,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  CaseDetailsCardPermissions,
  CostCardPermissions,
  CostLinkCardPermissions,
  DisplayReadyUnitCardPermissions,
  InnerPackDetailsCardPermissions,
  MasterPackDetailsCardPermissions
} from 'pm-models';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {InputState, UPCInputState} from 'pm-components';
import {WorkflowService} from '../../service/workflow.service';
import {NewWarehouseProductStepperComponent} from '../new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {NewDsdProductStepperComponent} from '../new-dsd-product-stepper/new-dsd-product-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CostService} from '../../service/cost.service';
import {finalize} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-case-pack',
  templateUrl: './case-pack.component.html',
  styleUrls: ['./case-pack.component.scss']
})
export class CasePackComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public supplierProductService: SupplierProductService,
              private candidateService: CandidateService, private growlService: GrowlService, private workflowService: WorkflowService,
              private candidateUtilService: CandidateUtilService, private costService: CostService,
              private permissionService: NgxPermissionsService) { }

  public isViewingPage = true;
  public currentCandidateProductIndex;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public costLinkState: InputState;
  public upcState: UPCInputState;
  private taskSubscription$: any;
  private validators: CandidateValidatorType[] =
    [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR, CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
      CandidateValidatorType.SUPPLIER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_CASE_PACK_VALIDATOR];
  canNavigate = true;
  public stepIndex: number;
  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions;

  readonly masterPackDetailsCardPermissions: MasterPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly innerPackDetailsCardPermissions: InnerPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly displayReadyUnitCardPermissions: DisplayReadyUnitCardPermissions = {
    isReadOnly: false
  };
  readonly costLinkCardPermissions: CostLinkCardPermissions = {
    isReadOnly: false
  };
  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false,
    masterPack: {
      isHidden: true
    }
  };
  readonly attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };

  getAdditionalCasePackBasicsCardPermissions(): CaseDetailsCardPermissions {
    return {
      isReadOnly: false,
      itemWeightType: {
        isReadOnly: false,
        isDisabled: !this.candidateUtilService.isTiedToCatchOrVariableWeightBuyer(this.supplierProductService.getCandidate())
      },
      distributionChannel: {
        isHidden: true,
      },
      buyer: {
        isHidden: true
      },
      warehouseReactionDays: {
        isHidden: true
      },
      guaranteeToStoreDays: {
        isHidden: true
      },
      dealOffered: {
        isHidden: true
      }
    };
  }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierProductService.getTaskId(),
        this.supplierProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT) {
            this.candidateError = this.supplierProductService.getCasePackError();
            this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
            this.currentCandidateProductIndex = this.supplierProductService.getCurrentCandidateProductIndex();
            this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierProductService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierProductService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.currentCandidateProductIndex = this.supplierProductService.getCurrentCandidateProductIndex();
    this.candidateError = this.supplierProductService.getCasePackError();
    this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
    this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
  }

  onClickNext() {
    // validate the candidate for this page and send errors to components to display, if any
    this.canNavigate = false;
    const url = !!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT') ? '/extendedAttributes' : '/newProductReviewPageComponent';
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(), this.validators).toPromise().then(data => {
      this.supplierProductService.saveCandidateAndNavigate(url, this.hasVendorChanged()).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
      this.candidateError = this.supplierProductService.setCasePackError(new CandidateError());
    }, (error) => {
      this.supplierProductService.scrollToTop();
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.supplierProductService.getCasePackError();
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
        this.supplierProductService.saveCandidateAndNavigate(url, this.hasVendorChanged()).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      } else {
        this.canNavigate = true;
      }
    });
  }

  onClickBack() {
    this.jumpTo('/basicItemSetup', [CandidateValidatorType.SUPPLIER_CASE_PACK_VALIDATOR]);
  }

  onClose() {
    this.isViewingPage = false;
    this.supplierProductService.saveCandidateAndClose();
  }

  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.supplierProductService.getCandidate().costLinkFromServer = undefined;
    this.candidateError.costLink = undefined;
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((validatedCandidate) => {
      this.costLinkState = InputState.valid;
      this.costService.updateCostLinkRelatedValuesFromValidatedCandidate(this.supplierProductService.getCandidate(), validatedCandidate);
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.candidateError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }


  validateUpc() {
    this.upcState = UPCInputState.loading;
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      [CandidateValidatorType.CASE_UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      if (this.candidateProductError) {
        this.candidateProductError.caseUpc = undefined;
      }
    }, (error) => {
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const candidateProductId = this.supplierProductService.getCandidate().candidateProducts[0].id;
        const candidateErrors: CandidateError = error.error.candidateErrors;
        const candidateProductError: CandidateProductError =
          candidateErrors.candidateProductErrors[candidateProductId];

        if (this.candidateError.candidateProductErrors.size === 0 ||
          !this.candidateError.candidateProductErrors[candidateProductId]) {

          this.candidateError.candidateProductErrors.set(candidateProductId,
            candidateProductError);
          this.candidateProductError = candidateProductError;
          this.candidateError.itemWeightType = candidateErrors.itemWeightType;
          if (this.candidateProductError.caseUpc ||  this.candidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.supplierProductService.setCasePackError(candidateErrors);
            this.upcState = UPCInputState.valid;
          }
        } else {
          this.candidateProductError.caseUpc = candidateProductError.caseUpc;
          this.candidateProductError.caseUpcCheckDigit = candidateProductError.caseUpcCheckDigit;
          this.candidateError.itemWeightType = candidateErrors.itemWeightType;
          if (this.candidateProductError.caseUpc ||  this.candidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.supplierProductService.setCasePackError(candidateErrors);
            this.upcState = UPCInputState.valid;
          }
        }
      } else {
        this.upcState = UPCInputState.invalid;
        this.growlService.addError(error.message);
      }
    });
  }

  onInnerPackChange() {
    if (!this.supplierProductService.getCandidate().innerPackSelected) {
      if (this.candidateProductError) {
        this.candidateProductError.caseUpc = null;
      }
      this.upcState = UPCInputState.none;
    }
    if (this.supplierProductService.getCandidate() && this.supplierProductService.getCandidate().costLinked
      && this.supplierProductService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }

  innerPackQuantityChange() {
    if (this.candidateProductError && this.candidateProductError.caseUpc) {
      this.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
  }

  get isSellable(): boolean {
    return this.supplierProductService.getCandidate() && this.supplierProductService.getCandidate().productType === 'SELLABLE';
  }

  onClickNextDsd() {
    this.canNavigate = false;
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR, CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
        CandidateValidatorType.SUPPLIER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_CASE_PACK_VALIDATOR]).toPromise().then(data => {
      this.candidateError = this.supplierProductService.setCasePackError(new CandidateError());
      this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
      if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
        this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
        this.supplierProductService.saveCandidateAndNavigate('/storeAuthorization', this.hasVendorChanged()).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      } else {
        this.supplierProductService.saveCandidateAndNavigate('/setUpStores', this.hasVendorChanged()).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      }
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.supplierProductService.setCasePackError(error.error.candidateErrors);
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canNavigate = true;
    });
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.supplierProductService.getOriginalCandidate().vendor)
      !== JSON.stringify(this.supplierProductService.getCandidate().vendor);
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case NewWarehouseProductStepperComponent.UPC_ITEM_CODE:
        this.jumpTo('/setupCandidateType');
        break;
      case NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP:
        this.jumpTo('/supplierDetails');
        break;
      case NewWarehouseProductStepperComponent.PRODUCT_DETAILS:
        this.onClickBack();
        break;
      case NewDsdProductStepperComponent.STORE_AUTHORIZATION:
        if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.onClickNextDsd();
        } else {
          this.jumpTo('/setUpStores', SupplierProductService.BASE_VALIDATORS);
        }
        break;
      case NewWarehouseProductStepperComponent.EXTENDED_ATTRIBUTES:
        if (this.supplierProductService.isDsdOnly()) {
          this.jumpTo('/extendedAttributes', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.onClickNext();
        }
        break;
      case NewWarehouseProductStepperComponent.REVIEW_PAGE:
        if (this.supplierProductService.isDsdOnly()) {
          this.jumpTo('/newProductReviewPageComponent', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpTo('/newProductReviewPageComponent', SupplierProductService.BASE_VALIDATORS);
        }
        break;
    }
  }

  jumpTo(urlToNavigate, validator?) {
    this.canNavigate = false;

    if (!validator?.length) {
      this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, this.hasVendorChanged()).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }

    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      validator).toPromise().then(data => {
      this.candidateError = this.supplierProductService.setCasePackError(new CandidateError());
      this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);

      this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, this.hasVendorChanged()).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      if (error.error.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierProductService.setCasePackError(error.error.candidateErrors);
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
        if (!this.supplierProductService.isDsdOnly() || !SupplierProductService.DSD_NON_NAVIGABLE_ON_ERROR_PAGES.includes(urlToNavigate)) {
          this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).pipe(
            finalize(() => this.canNavigate = true)
          ).subscribe();
        } else {
          this.canNavigate = true;
        }
      } else {
        this.canNavigate = true;
      }
    });
  }

  /**
   * Trigger cost link validation on master pack change.
   * @param event
   */
  onMasterPackChange(event) {
    if (this.supplierProductService.getCandidate() && this.supplierProductService.getCandidate().costLinked
      && this.supplierProductService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }
}
