import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseUpcInputComponent } from '../base-upc-input/base-upc-input.component';
import { UpcService } from '../../services/upc-service/upc.service';
import { AttributeTypeaheadConfig, CandidateProductError } from 'pm-models';
import { PluRange } from 'pm-models/lib/pluRange';
import { UpcRequestType } from 'pm-models/lib/newUpcRequest';
import { calculateCheckDigit } from '../../../../../../../src/app/2.0.0/shared/upc.utils';

export { UPCInputState } from '../base-upc-input/base-upc-input.component';

@Component({
  selector: 'pm-upc-plu-input',
  templateUrl: './upc-plu-input.component.html',
  styleUrls: ['../base-upc-input/base-upc-input.component.scss', './upc-plu-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpcPluInputComponent extends BaseUpcInputComponent implements OnInit {
  upcTypeValue;
  isShowingReservationPanel = false;
  pluUpcType = { label: 'PLU', value: 'PLU' };

  pluRangeConfiguration: AttributeTypeaheadConfig = {
    label: `PLU range`,
    isDisabled: () => this.isPluTypeUnselected() || this.isFetchingPlu,
    isReadOnly: () => false,
    name: 'pluRangeId',
    idRef: 'pluRangeId',
    displayRef: 'displayName',
    searchUrl: '/lookup/pluRange',
    placeholderText: 'Select range...',
  };

  @Input()
  label: string;
  @Input()
  hidePlu: boolean;
  @Input()
  hideCheckDigit: boolean;
  @Input()
  isManuallyEnteredPlu: boolean;
  @Input()
  upcOptions: { label: string, value: string }[];

  @Input()
  get upcType() {
    return this.upcTypeValue;
  }

  @Input()
  fullPlu: number;
  @Input()
  pluRangeModel: PluRange;
  @Input()
  pluTypes: { label: string, value: UpcRequestType }[];
  @Input()
  pluTypeModel: UpcRequestType;
  @Input()
  pluTypePlaceholderText: string;
  @Input()
  candidateProductError: CandidateProductError;
  @Input()
  isFetchingPlu: boolean;

  @Output()
  upcTypeChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  pluRangeModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  pluTypeModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  isManuallyEnteredPluChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  manuallyEnteredPluChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  validateManuallyEnteredPlu?: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  reserveUpcClicked?: EventEmitter<number> = new EventEmitter<number>();

  set upcType(val) {
    this.upcTypeValue = val;
    if (this.autoCheckDigit && this.upcModel) {
      this.checkDigitModel = calculateCheckDigit(this.upcModel);
    }
    this.upcTypeChange.emit(val);
  }

  constructor(private parentUPCService: UpcService) {
    super(parentUPCService);
  }


  ngOnInit(): void {
    if (!this.hidePlu) {
      this.hidePlu = true;
    }
    if (!this.pluTypes) {
      this.pluTypes = [{ label: 'Checker PLU', value: UpcRequestType.CHECKER },
        { label: 'Scale PLU', value: UpcRequestType.SCALE },
        { label: 'Checker & scale PLU', value: UpcRequestType.CHECKER_AND_SCALE }];
    }
    if (this.upcTypeValue === 'PLU') {
      this.hidePlu = false;
      if (this.pluTypeModel !== null && this.pluTypeModel !== undefined) {
        this.setPluType();
      }
    }
  }


  resetPlu() {
    if (this.pluRangeModel || this.pluTypeModel) {
      this.pluRangeModel = null;
      this.pluTypeModel = null;
    }
  }

  setPluType() {
    if (this.pluTypeModel !== null && this.pluTypeModel !== undefined) {
      for (let x = 0; x < this.pluTypes.length; x++) {
        if (+this.pluTypeModel === this.pluTypes[x].value) {
          this.pluTypeModel = this.pluTypes[x].value;
        }
      }
    }
  }

  onTypeSelection(event) {
    this.pluTypeModel = event.value;
    this.pluTypeModelChange.emit(this.pluTypeModel);
  }

  isPluTypeUnselected(): boolean {
    return !this.pluTypeModel;
  }

  onRangeSelection(event) {
    this.pluRangeModel = event;
    this.pluRangeModelChange.emit(this.pluRangeModel);
  }

  showPlu() {
    return this.pluTypeModel === UpcRequestType.CHECKER_AND_SCALE ||
      this.pluTypeModel === UpcRequestType.CHECKER;
  }

  showFullPlu() {
    return this.pluTypeModel === UpcRequestType.CHECKER_AND_SCALE ||
      this.pluTypeModel === UpcRequestType.SCALE;
  }

  toggleManuallyEnteredPlu() {
    this.isManuallyEnteredPlu = !this.isManuallyEnteredPlu;
    this.isManuallyEnteredPluChange.emit(this.isManuallyEnteredPlu);
  }

  onClickIsManuallyEnteredPlu(event, panel, target) {
    this.toggleManuallyEnteredPlu();
    if (this.isManuallyEnteredPlu) {
      this.onRangeSelection(null);
      this.isShowingReservationPanel = true;
      this.upcChange(null);
      this.checkDigitModel = null;
      panel.show(event, target);
    } else {
      this.isShowingReservationPanel = false;
      this.upcChange(null);
      this.checkDigitModel = null;
    }
  }

  isReservePLUDisabled(): boolean {
    return this.state !== 2;
  }

  onClickCancelManualPluEntry(manualPluEntryOverlay) {
    this.toggleManuallyEnteredPlu();
    this.upcChange(null);
    this.isShowingReservationPanel = false;
    manualPluEntryOverlay.hide();
  }


  hasErrorMessages() {
    return this.candidateProductError?.upc || this.candidateProductError.upcCheckDigit;
  }

  onClickReservePlu(manualPluEntryOverlay) {
    this.reserveUpcClicked.emit(this.upcModel);
    this.isShowingReservationPanel = false;
    manualPluEntryOverlay.hide();
  }
}
