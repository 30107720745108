import {
  Component,
  OnInit,
  Directive,
  ViewContainerRef,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  KeyValueDiffer,
  KeyValueDiffers
} from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AttributeTypes } from '../attributes/attribute-types.enum';
import { AttributeMapperService } from '../attributes/attribute-mapper.service';
import { Candidate, CandidateProduct } from 'pm-models';
import { BaseAttribute } from '../attributes/attribute-type/base-attribute';

/* // Leave In, future enhancement
import { DynamicOverlayPanelRef } from '../shared/overlay-panels/dynamic-overlay-panel/dynamic-overlay-panel-ref';
import { DynamicOverlayConfig } from '../shared/overlay-panels/dynamic-overlay-panel/dynamic-overlay.config';
*/

export interface ComponentConfigs {
  type: AttributeTypes;
  candidate: Candidate | CandidateProduct;
  overrides: any;
}

@Directive({
  selector: '[pmModalContent]'
})
export class DynamicContentDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'pm-attribute-modal',
  templateUrl: './attribute-modal.component.html',
  styleUrls: ['./attribute-modal.component.scss'],
  providers: [AttributeMapperService]
})
export class AttributeModalComponent implements AfterViewInit {
  @ViewChild(DynamicContentDirective) insertionPoint: DynamicContentDirective;
  private candidateDiffer: KeyValueDiffer<string, any>;

  constructor(
    public ref: DynamicDialogRef,
    // public ref: DynamicOverlayPanelRef,  // For future enhancement
    private cd: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private attributeMapperService: AttributeMapperService,
    public config: DynamicDialogConfig,
    // public config: DynamicOverlayConfig, // For future enhancement
    private differs: KeyValueDiffers
  ) {}

  candidate: Candidate;
  attributes: BaseAttribute[] = [];
  attrConfig: any[] = [];
  tabIndex?: number;

  ngAfterViewInit() {
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.candidate = this.config.data.candidate;

    if (!this.config.data.configs) {
      this.loadChildComponent(viewContainerRef, this.config.data.type, this.config.data.overrides);
    } else {
      this.loadChildComponents(viewContainerRef, this.config.data.configs);
      this.candidateDiffer = this.differs.find(this.candidate).create();
    }

    this.cd.detectChanges();
  }

  /**
   * Iterates through configurations and adds components to the modal
   *
   * @param {ViewContainerRef} vcr
   * @param {ComponentConfigs[]} configs
   * @memberof AttributeModalComponent
   */
  loadChildComponents(vcr: ViewContainerRef, configs: ComponentConfigs[]) {
    configs.forEach((config, i) => {
      // There is a UI bug that wont allow more than two attributes to display at a time.
      // A timeout delays the detection enough to allow it to run
      setTimeout(() => {
        this.loadChildComponent(vcr, config.type, config.overrides);
      }, 0);
    });
  }


  /**
   * Generates a component based on configuration and adds it to the modal
   *
   * @param {ViewContainerRef} vcr
   * @param {AttributeTypes} attributeType
   * @param {*} overrides
   * @memberof AttributeModalComponent
   */
  loadChildComponent(vcr: ViewContainerRef, attributeType: AttributeTypes, overrides: any) {
    const attributeConfiguration = this.attributeMapperService.getConfiguration(attributeType);
    if (attributeType === AttributeTypes.MatAttributeTypeahead) {
      this.tabIndex = -1;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(attributeConfiguration.getComponentType());

    const componentRef = vcr.createComponent(componentFactory);

    attributeConfiguration.setupComponent(componentRef.instance, this.candidate, overrides);

    this.attrConfig.push({ config: attributeConfiguration, componentRef: componentRef });
  }

  ngDoCheck(): void {
    if (this.candidateDiffer) {
      const changes = this.candidateDiffer.diff(this.candidate);
      if (changes) {
        this.attrConfig.forEach(e => {
          e.config.updateModel(e.componentRef.instance, this.candidate);
        });
      }
    }
  }

  /**
   * Closes window and dispatches updated candidate
   *
   * @memberof AttributeModalComponent
   */
  close() {
    this.ref.close(this.candidate);
  }

  /**
   * Closes modal without dispatching candidate
   *
   * @memberof AttributeModalComponent
   */
  cancel() {
    this.ref.close();
  }

  getCloseLabel() {
    if (this.config.data.overrides && this.config.data.overrides.isApplyToAll) {
      return 'Apply to all';
    }
    return 'Save';
  }

  isSaveDisabled() {
    return (this.config.data.overrides && this.config.data.overrides.saveDisabled) ||
      (this.config.data.configs && this.config.data.configs.filter(
        config => config.overrides && config.overrides['saveDisabled'] && config.overrides['saveDisabled']).length > 0);
  }

}
