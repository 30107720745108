<pm-editor #pmEditor (closeEvent)="supplierMrtService.onInnerClose()">
  <pm-header>
    <app-pia-mrt-inner-stepper *ngIf="supplierMrtService.innerCandidate"
                               [candidateErrors]="supplierMrtService.getAllPiaCandidateInnerErrorsByCandidateId()"
                               [isReplenishable]="supplierMrtService.isInnerCandidateReplenishable()"
                               [stepIndex]="supplierMrtService.isInnerCandidateReplenishable() ? 6 : 4"
                               [isSellable]="candidateUtilService.isSellable(supplierMrtService.innerCandidate)"
                               (indexClick)="onClickStepper($event)">
    </app-pia-mrt-inner-stepper>
  </pm-header>
  <pm-title>Extended attributes</pm-title>
  <div *ngIf="isLoadingData" class="col-md-8 spinner-row">
    <pm-progress-spinner [showSpinner]="isLoadingData" [strokeWidth]="'2'"
                         [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}">
    </pm-progress-spinner>
    <h5 class="ml-3">Fetching attributes...</h5>
  </div>
  <ng-container *ngIf="!isLoadingData">

    <pm-global-attributes-card [candidateModel]="supplierMrtService.innerCandidate"
                               [candidateProductModel]="supplierMrtService.innerCandidate.candidateProducts[0]"
                               [globalAttributes]="supplierMrtService.candidateInnerGlobalAttributes"
                               [candidateProductErrorModel]="innerCandidateProductError">
    </pm-global-attributes-card>

    <pm-mat-categories-card [candidateModel]="supplierMrtService.innerCandidate"
                            [candidateProductModel]="supplierMrtService.innerCandidate.candidateProducts[0]"
                            [hierarchyAttributes]="supplierMrtService.candidateInnerHierarchyAttributes"
                            [candidateProductErrorModel]="innerCandidateProductError">
    </pm-mat-categories-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
