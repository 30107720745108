import {Component, OnInit} from '@angular/core';
import {
  AttributeTypeaheadConfig,
  BaseCardPermissions,
  CandidateError,
  CandidateHelper,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  HEBDetailsCardPermissions,
  SupplierDetailsCardPermissions
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {MrtInnerStepperComponent} from '../mrt-inner-stepper/mrt-inner-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {LookupService} from '../../service/lookup.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {MatUtilService} from '../../service/mat-util.service';
import {GrowlService} from '../../growl/growl.service';

@Component({
  selector: 'app-inner-supplier-and-heb-details',
  templateUrl: './inner-supplier-and-heb-details.component.html',
  styleUrls: ['./inner-supplier-and-heb-details.component.scss']
})
export class InnerSupplierAndHebDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private candidateUtilService: CandidateUtilService,
              private lookupService: LookupService, private permissionService: NgxPermissionsService, public matUtilService: MatUtilService,
              private growlService: GrowlService) { }

  readonly matHierarchyCardPermissions: BaseCardPermissions = {
    isReadOnly: false
  };

  readonly hebDetailsCardPermissions: HEBDetailsCardPermissions = {
    isReadOnly: false,
    productType: {
      isDisabled: true
    },
    merchandiseType: {
      isHidden: true
    },
    description: {
      isHidden: true
    }
  };

  readonly supplierDetailsCardPermissions: SupplierDetailsCardPermissions = {
    isReadOnly: false
  };

  private taskSubscription$: any;
  public innerCandidateError: CandidateError;
  public innerCandidateProductError: CandidateProductError;
  public isViewingPage = true;
  public commodities: any;
  public subCommodities: any;
  public isLoadingMatData = false;
  public static readonly BASE_VALIDATORS: CandidateValidatorType[] =
    [CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR, CandidateValidatorType.MAT_HIERARCHY_VALIDATOR];

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {

        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId()  ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          // if the candidate is an mrt, and contains the inner set the inner candidate.
          if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).subscribe((innerCandidate) => {
              this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
              this.initializeData();
            });
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierMrtService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  getCommodityConfiguration(): AttributeTypeaheadConfig {
    return {
      label: 'Commodity',
      description: 'Choose an appropriate category.',
      isRequired: this.supplierMrtService.getInnerCandidate() && this.supplierMrtService.getInnerCandidate().dsdSwitch,
      isDisabled: () => !(this.commodities && this.commodities.length > 0),
      isReadOnly: () => false,
      name: 'commodityId',
      displayRef: 'commodityName',
      placeholderText: 'Select a commodity...',
      collections: this.commodities
    };
  }
  getSubCommodityConfiguration(): AttributeTypeaheadConfig {
    return {
      label: 'Sub-commodity',
      description: 'Choose an appropriate subcategory.',
      isRequired: this.supplierMrtService.getInnerCandidate() && this.supplierMrtService.getInnerCandidate().dsdSwitch,
      isDisabled: () => !(this.subCommodities && this.subCommodities.length > 0),
      isReadOnly: () => false,
      name: 'subcommodityId',
      displayRef: 'displayName',
      placeholderText: 'Select a sub-commodity...',
      collections: this.subCommodities
    };
  }

  initializeData() {

    this.setVendorEditedTrue();
    if (this.supplierMrtService.getInnerCandidate().buyer) {
      this.setCommoditiesAndSubCommodities(this.supplierMrtService.getInnerCandidate().buyer);
    }
    this.innerCandidateError = this.supplierMrtService.getSupplierAndHebDetailsError(
      this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.innerCandidateError) {
      this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
        this.supplierMrtService.getInnerCandidateProduct(0).id];
    }
    this.setupMatAttributes();
  }

  /**
   * Sets mrt inner status to vendor edited.
   */
  setVendorEditedTrue() {
    for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
      if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidateId === this.supplierMrtService.getInnerCandidateId()) {
        this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].vendorEdited = true;
        this.supplierMrtService.saveMrtCandidate();
      }
    }
  }

  onBuyerChange(event) {
    if (!event) {
      this.commodities = [];
    }
    this.supplierMrtService.getInnerCandidate().commodity = null;
    this.supplierMrtService.getInnerCandidate().subCommodity = null;
    if (event && event.buyerId) {
      this.setCommoditiesAndSubCommodities(event);
    }
  }

  onClickBack() {
    if (this.supplierMrtService.hasInnerChanged()) {
      this.supplierMrtService.getCandidateInnerByCandidateId(this.supplierMrtService.getInnerCandidate().candidateId).valid = false;
      this.supplierMrtService.saveMrtCandidate().subscribe(() => {
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/setUpNewMrtItems', null,
          this.hasVendorChanged());
      });
    } else {
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/setUpNewMrtItems', null,
        this.hasVendorChanged());
    }
  }

  onClickNext() {
    this.jumpToPage('/innerProductDetails', InnerSupplierAndHebDetailsComponent.BASE_VALIDATORS);
  }

  jumpToPage(url: string, validators: CandidateValidatorType[]) {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), validators).subscribe(data => {
      this.innerCandidateError = this.supplierMrtService.setSupplierAndHebDetailsError(
        this.supplierMrtService.getInnerCandidate().candidateId,
        new CandidateError());
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
            candidateId: this.supplierMrtService.getInnerCandidate().candidateId}},
        this.hasVendorChanged());
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.innerCandidateError = this.supplierMrtService.setSupplierAndHebDetailsError(
          this.supplierMrtService.getInnerCandidate().candidateId, error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
          {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId}},
          this.hasVendorChanged(), true);
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  onClose() {
    this.supplierMrtService.onInnerClose();
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.supplierMrtService.getOriginalCandidate().vendor) !==
      JSON.stringify(this.supplierMrtService.getCandidate().vendor);
  }

  onClickStepper(stepperItem) {
    if (this.isLoadingMatData) {
      return;
    }
    switch (stepperItem.text) {
      case MrtInnerStepperComponent.PRODUCT_DETAILS:
        this.onClickNext();
        break;
      case MrtInnerStepperComponent.CASE_PACK:
        this.jumpToPage('/addNewMrtCase', InnerSupplierAndHebDetailsComponent.BASE_VALIDATORS);
        break;
      case MrtInnerStepperComponent.EXTENDED_ATTRIBUTES:
        this.jumpToPage('/innerExtendedAttributes',  InnerSupplierAndHebDetailsComponent.BASE_VALIDATORS);
        break;
    }
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyer the buyer.
   */
  private setCommoditiesAndSubCommodities(buyer) {
    if (!buyer || !buyer.buyerId) {
      this.commodities = [];
      this.subCommodities = [];
    }
    this.lookupService.findAllCommoditiesByBuyerId(buyer.buyerId).subscribe( data => {
      this.commodities = data;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.supplierMrtService.getInnerCandidate().commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.supplierMrtService.getInnerCandidate().commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
  }

  onCommodityChange(event) {
    this.supplierMrtService.getInnerCandidate().commodity = event;
    this.supplierMrtService.getInnerCandidate().subCommodity = null;
    this.getSubCommodities(event);
  }

  onSubCommodityChange(event) {
    this.supplierMrtService.getInnerCandidate().subCommodity = event;

    if (event) {
      this.supplierMrtService.getInnerCandidate().foodStamp = CandidateHelper.isFoodStamp(this.supplierMrtService.getInnerCandidate());
      this.supplierMrtService.getInnerCandidate().taxable = CandidateHelper.isTaxCode(this.supplierMrtService.getInnerCandidate());
    }
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return;
    }
    this.isLoadingMatData = true;

    this.matUtilService.updateMatHierarchy(this.supplierMrtService.getInnerCandidateProduct(0)).pipe(
      tap((hasHierarchyChanges) => {
        this.supplierMrtService.hasCandidateInnerHierarchyUpdateChanges = hasHierarchyChanges;
      }),
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.supplierMrtService.getInnerCandidate(),
        this.supplierMrtService.candidateInnerGlobalAttributes, this.supplierMrtService.candidateInnerHierarchyAttributes)),
      finalize(() => this.isLoadingMatData = false)
    ).subscribe();
  }


  onHierarchyChange() {
    this.supplierMrtService.candidateInnerHierarchyAttributes = [];
    this.supplierMrtService.hasCandidateInnerHierarchyUpdateChanges = false;
    const candidateError = this.supplierMrtService.getInnerExtendedAttributesError(this.supplierMrtService.getInnerCandidate().candidateId);
    let candidateProductError;
    if (candidateError) {
      candidateProductError = candidateError.candidateProductErrors[this.supplierMrtService.getInnerCandidateProduct(0).id];
      this.matUtilService.updateMatHierarchyErrors(candidateProductError, this.supplierMrtService.candidateInnerGlobalAttributes,
        this.supplierMrtService.candidateInnerHierarchyAttributes);
      this.supplierMrtService.setInnerExtendedAttributesError(this.supplierMrtService.getInnerCandidate().candidateId, JSON.parse(JSON.stringify(candidateError)));
    }
  }
}
