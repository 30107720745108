import {Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CandidateError} from 'pm-models';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-pia-mrt-inner-stepper',
  templateUrl: './pia-mrt-inner-stepper.component.html',
  styleUrls: ['./pia-mrt-inner-stepper.component.scss']
})
export class PiaMrtInnerStepperComponent implements OnInit, OnChanges {

  private errorCandidateDiffer: KeyValueDiffer<string, any>;
  private errorCandidateProductDiffer: KeyValueDiffer<string, any>;

  private readonly errorToolTip = 'This page contains errors or is missing information in required fields.';
  private readonly errorIcon = 'warning';

  @Input()
  candidateErrors: CandidateError[];

  @Input()
  stepIndex: number;

  @Input()
  isSellable: boolean;

  @Input()
  isReplenishable: Boolean;

  steps;

  @Output() indexClick = new EventEmitter<any>();

  constructor(private differs: KeyValueDiffers, private permissionService: NgxPermissionsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isReplenishable?.currentValue !== changes?.isReplenishable?.previousValue) {
      this.setSteps();
      this.applyErrors();
    }
  }

  public static SUPPLIER_HEB_SETUP = 'Supplier & H-E-B setup';
  public static PRODUCT_DETAILS = 'Product details';
  public static CASE_PACK = 'Case pack';
  public static CASE_DETAILS = 'Case Details';
  public static WAREHOUSE = 'Warehouse';
  public static EXTENDED_ATTRIBUTES = 'Extended attributes';

  public ngOnInit() {
    this.setSteps();
  }

  private setSteps() {
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      if (this.isReplenishable) {
        this.steps = [{text: PiaMrtInnerStepperComponent.SUPPLIER_HEB_SETUP}, {text: PiaMrtInnerStepperComponent.PRODUCT_DETAILS},
          {text: PiaMrtInnerStepperComponent.CASE_PACK}, {text: PiaMrtInnerStepperComponent.CASE_DETAILS},
          {text: PiaMrtInnerStepperComponent.WAREHOUSE}, {text: PiaMrtInnerStepperComponent.EXTENDED_ATTRIBUTES}];
      } else {
        this.steps = [{text: PiaMrtInnerStepperComponent.SUPPLIER_HEB_SETUP}, {text: PiaMrtInnerStepperComponent.PRODUCT_DETAILS},
          {text: PiaMrtInnerStepperComponent.CASE_PACK}, {text: PiaMrtInnerStepperComponent.EXTENDED_ATTRIBUTES}];
      }
    } else {
      if (this.isReplenishable) {
        this.steps = [{text: PiaMrtInnerStepperComponent.SUPPLIER_HEB_SETUP}, {text: PiaMrtInnerStepperComponent.PRODUCT_DETAILS},
          {text: PiaMrtInnerStepperComponent.CASE_PACK}, {text: PiaMrtInnerStepperComponent.CASE_DETAILS},
          {text: PiaMrtInnerStepperComponent.WAREHOUSE}];
      } else {
        this.steps = [{text: PiaMrtInnerStepperComponent.SUPPLIER_HEB_SETUP}, {text: PiaMrtInnerStepperComponent.PRODUCT_DETAILS},
          {text: PiaMrtInnerStepperComponent.CASE_PACK}];
      }
    }
  }

  /**
   * Change detection that compares candidate errors with previous version.
   * If the candidate differ doesn't exist, it creates it
   * Runs apply errors if any changes have been detected
   * @memberof CandidateCreationStepperComponent
   */
  ngDoCheck(): void {
    let candidateHasChanges = false;

    if (this.errorCandidateDiffer) {
      candidateHasChanges = this.errorCandidateDiffer.diff(this.candidateErrors) ? true : false;
    } else if (this.candidateErrors) {
      this.errorCandidateDiffer = this.differs.find(this.candidateErrors).create();
    }

    if (candidateHasChanges) {
      this.applyErrors();
    }
  }

  /**
   * Runs through each step of menu and runs validation on each step
   *
   * @memberof CandidateCreationStepperComponent
   */
  applyErrors() {
    this.steps.forEach((x, i) => {
      switch (i) {
        case 0:
          this.validateSupplierAndHebDetails(x, this.candidateErrors[i]);
          break;
        case 1:
          this.validateProductDetails(x, this.candidateErrors[i]);
          break;
        case 2:
          this.validateCasePack(x, this.candidateErrors[i]);
          break;
        case 3:
          // if it's replenishable, the 4th page will be the case details page.
          // if it's not replenishable, and there's a 4th page, it will be the extended attributes page,
          // which has its own error and skips the case details error.
          if (this.isReplenishable) {
            this.validateCaseDetails(x, this.candidateErrors[i]);
          } else {
            this.validateExtendedAttributes(x, this.candidateErrors[i + 1]);
          }
          break;
        case 4:
          if (this.isReplenishable) {
            this.validateWarehouses(x, this.candidateErrors[i]);
          }
          break;
        case 5:
          if (this.isReplenishable) {
            this.validateExtendedAttributes(x, this.candidateErrors[i]);
          }
          break;
      }
    });
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateSupplierAndHebDetails(stepItem, candidateErrors) {
    const candidateKeys = ['buyer', 'productType', 'brand', 'subBrand', 'vendor', 'costOwner', 'topToTop'];
    const candidateProductKeys = ['categorySelectionError'];
    const warehouseKeys = [];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors);
  }
  /**
   * Validation for second screen screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateCaseDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'masterPack',
      'masterWidth',
      'masterHeight',
      'masterLength',
      'masterWeight',
      'cubeAdjustedFactor',
      'vendorTie',
      'vendorTier',
      'innerPackSelected',
      'innerPack',
      'innerWidth',
      'innerHeight',
      'innerLength',
      'innerWeight',
      'displayReadyUnit',
      'displayReadyUnitRowsDeep',
      'displayReadyUnitRowsHigh',
      'displayReadyUnitRowsFacing',
      'displayReadyUnitOrientation',
      'costLink',
      'masterListCost',
      'innerListCost',
      'itemWeightType',
      'maxShelfLife',
      'inboundSpecDays',
      'guaranteeToStoreDays',
      'warehouseReactionDays',
      'unitCost'];
    const candidateProductKeys = [
      'caseUpc',
      'caseDescription',
      'vendorProductCode',
      'countryOfOrigin',
      'attachments',
      'imported',
      'containerSize',
      'incoTerms',
      'pickupPoint',
      'dutyPercent',
      'dutyConfirmed',
      'dutyInfo',
      'minimumOrderQty',
      'minimumOrderDesc',
      'hts1',
      'hts2',
      'hts3',
      'agentPercent',
      'cartonMarking',
      'productColor',
      'prorationDate',
      'inStoreDate',
      'whsFlushDate',
      'freightConfirmedDate',
      'factory',
      'remark1',
      'remark2'];

    const warehouseKeys = [];

    this.validateStep(stepItem, candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors);
  }
  /**
   * Validation for second screen screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateProductDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'merchandiseType',
      'commodity',
      'subCommodity',
      'pssDepartment',
      'packageType',
      'productWidth',
      'productHeight',
      'productLength',
      'productWeight',
      'unitOfMeasure',
      'retailSize',
      'totalVolume',
      'vertexTaxCategory'
    ];

    const candidateProductKeys = [
      'imageLinks',
      'termsAndConditions'
    ];

    if (this.isSellable) {
      candidateKeys.push('suggestedXFor');
      candidateKeys.push('suggestedRetailPrice');
      candidateKeys.push('mapRetail');
      candidateKeys.push('retailType');
      candidateKeys.push('retailPrice');
      candidateKeys.push('retailXFor');
      candidateKeys.push('season');
      candidateKeys.push('seasonYear');

      candidateProductKeys.push('description');
      candidateProductKeys.push('customerFriendlyDescription1');
      candidateProductKeys.push('customerFriendlyDescription2');
      candidateProductKeys.push('romanceCopy');
    }
    const warehouseKeys = [];

    this.validateStep(stepItem, candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors);
  }

  /**
   * Validates step with associated keys, applies icon and error message if found
   *
   * @private
   * @param {*} stepItem - Menu Items
   * @param {*} candidateKeys - Candidate Error Keys associated with this step
   * @param {*} candidateProductKeys - Candidate Product Error Keys associated with this step
   * @memberof CandidateCreationStepperComponent
   */
  private validateStep(stepItem, candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!this.validateKeys(candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, validateMatAttributes)) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    } else {
      stepItem.icon = undefined;
      stepItem.message = undefined;
    }
  }

  /**
   * Checks each batch of keys to see if candidateErrors or candidate Product errors
   * contain any values. Immediate return false if error found for performance.
   *
   * @private
   * @param {*} candidateKeys
   * @param {*} candidateProductKeys
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private validateKeys(candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!candidateErrors) {
      return true;
    }
    for (let i = 0; i < candidateKeys.length; i++) {
      if (this.candidateErrors && this.fieldHasValue(candidateErrors[candidateKeys[i]])) {
        return false;
      }
    }
    for (let i = 0; i < candidateProductKeys.length; i++) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && this.fieldHasValue(value[candidateProductKeys[i]])) {
            return false;
          }
        }
      }
    }

    for (let i = 0; i < warehouseKeys.length; i++) {
      if (!candidateErrors.candidateProductErrors) {
        return true;
      }
      for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
        for (const [candidateProductKey, candidateProductValue] of Object.entries(value)) {
          if (candidateProductKey === 'warehouseErrors') {
            for (const [warehouseKey, warehouseValue] of Object.entries(candidateProductValue)) {
              if (warehouseValue && this.fieldHasValue(warehouseValue[warehouseKeys[i]])) {
                return false;
              }
            }
          }
        }
      }
    }

    if (validateMatAttributes) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && value['matAttributeErrors'] &&  Object.entries(value['matAttributeErrors']).length) {
            return false;
          }
        }
      }
    }

    return true;
  }

  /**
   * Takes  a property and immediately checks if it has any sort of value
   * returns 'true' if any value is found or present.
   * @private
   * @param {*} field
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private fieldHasValue(field) {
    return field !== undefined && field !== null && field !== '';
  }



  validateWarehouses(stepItem, candidateErrors) {
    const candidateKeys = ['maxShip'];
    const candidateProductKeys = ['missingWarehouses'];
    const warehouseKeys = ['maxShip', 'orderRestriction', 'orderUnit'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors);

  }

  validateExtendedAttributes(stepItem, candidateErrors) {
    this.validateStep(stepItem, [], [], [], candidateErrors, true);
  }

  validateCasePack(stepItem, candidateErrors) {
    const candidateKeys = ['lane', 'unitCost'];
    this.validateStep(stepItem, candidateKeys, [], [], candidateErrors);
  }

  click(event: any) {
    this.indexClick.emit(event);
  }
}
