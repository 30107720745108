import { Component, OnInit, ViewEncapsulation, ContentChild, Input, Output, EventEmitter } from '@angular/core';
import {TooltipModule} from 'primeng/tooltip';

@Component({
  selector: 'pm-grid-cell-info',
  template: '<ng-content></ng-content>'
})
export class GridCellInfoComponent {}

@Component({
  selector: 'pm-grid-cell',
  templateUrl: './grid-cell.component.html',
  styleUrls: ['./grid-cell.component.scss']
})
export class GridCellComponent implements OnInit {
  constructor() {}

  @Input()
  label;

  @Input()
  errorMessage;

  @Input()
  errorMessages;

  @Input()
  editNoHover;

  @Input()
  canEdit: boolean;

  @Input()
  hasChanged: boolean = false;

  @Output() editClicked: EventEmitter<any> = new EventEmitter();

  @Output() historyClicked: EventEmitter<any> = new EventEmitter();

  @ContentChild(GridCellInfoComponent, { static: true }) gridCellInfo;

  ngOnInit() {}

  onEditClicked($event) {
    this.editClicked.emit($event);
  }
  onHistoryClicked($event) {
    this.historyClicked.emit($event);
  }
}
