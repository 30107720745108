import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import {from, Observable, of, zip} from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { AppConfigService } from '../../service/app-config.service';
import {AttributeConfig} from 'pm-models';
import {
  DisabledPermissionsMapper,
  ReadOnlyPermissionsMapper
} from '../../../../../projects/pm-components/src/lib/cards/attribute-permissions-mapper';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isSCA = false;
  isPIA = false;
  isBDM = false;
  isVendor = false;
  isSSO: Boolean = false;

  canReserveUPC: Boolean = false;

  checkedOnceForRoles: boolean = false;

  menuModel = [
    {
      label: 'Search',
      id: 'productSearch',
      routerLink: '/productSearch'
    },
    {
      label: 'Volume upload settings',
      id: 'volumeUploadSettings',
      routerLink: '/volumeUploadSettings'
    },
    {
      label: 'Account settings',
      id: 'buyerNotifications',
      routerLink: '/buyerNotifications'
    },
    {
      label: 'Account settings',
      id: 'supplierNotifications',
      routerLink: '/supplierNotifications'
    },
    {
      label: 'Reserved UPCs',
      id: 'reservedUpcs',
      routerLink: '/reservedUpcs'
    },
    {
      label: 'Log Out',
      id: 'headerLogoutLink',
      command: this.authService.isNotAuthenticatedLogout,
      routerLink: '/login'
    },
    {
      label: 'Get help',
      id: 'needAssistance',
      routerLink: '/needAssistance'
    }
  ];

  constructor(
    public authService: AuthService,
    private appConfig: AppConfigService,
    private permissionService: NgxPermissionsService, private router: Router
  ) {}

  ngOnInit() {
    this.isSSO = this.appConfig.isSsoEnabled();
    this.getPermissions();
    this.toggleMenu();
  }

  private getPermissions() {
    this.getUserTypes().subscribe(result => {
      this.updateUserTypes(result);
    });
  }

  /**
   * Runs through the user's permissions to see if it has any roles for menu
   *
   * @private
   * @memberof HeaderComponent
   */
  private getUserTypes(): Observable<[boolean, boolean, boolean, boolean, boolean]> {
    const isSca$: Observable<boolean> = from(of(this.authService.isSca()));
    const isBdm$: Observable<boolean> = from(of(this.authService.isBuyer()));
    const isPia$: Observable<boolean> = from(of(this.authService.isPia()));
    const isVendor$: Observable<boolean> = from(of(this.authService.isVendor()));
    const canReserveUPC$: Observable<boolean> = from(this.permissionService.hasPermission('ROLE_PD_RESERVE_UPC'));

    return zip(isSca$, isBdm$, isPia$, isVendor$, canReserveUPC$);
  }

  /**
   * Set the roles from which come from NgXPermissions service
   * @param result
   */
  private updateUserTypes(result: [boolean, boolean, boolean, boolean, boolean]) {
    this.isSCA = result[0];
    this.isBDM = result[1];
    this.isPIA = result[2];
    this.isVendor = result[3];
    this.canReserveUPC = result[4];
  }

  /**
   * @memberof HeaderComponent
   */
  toggleMenu() {
    this.getUserTypes().subscribe(result => {
      this.updateUserTypes(result);
    });
  }

  private canReserve() {
    const reservePermission = this.permissionService.getPermission('ROLE_PD_RESERVE_UPC');
    return !((reservePermission === undefined) || reservePermission === null);
  }

  headerClick() {
    this.router.navigate(['/']);
  }
}
