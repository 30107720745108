<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-4">
    <!-- Helper images -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Buyer details" subtitle="Choose the Buyer that should review this request.">

    <ng-container *ngIf="model">
      <pm-attribute-display-typeahead [attribute]="buyerConfiguration" [attributeError]="errorModel?.buyer"
                                      [(model)]="model.buyer" (selection)="onBuyerChange($event)"
                                      (initCallback)="buyerInitCallback($event)" [isDropdown]="false">
      </pm-attribute-display-typeahead>
    </ng-container>
  </pm-card>
</ng-template>
