import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AttributeConfig } from 'pm-models';
import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-toggle',
  templateUrl: './attribute-display-toggle.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayToggleComponent extends BaseAttributeDisplayComponent implements OnInit {

  @Input()
  public warningMessageText?: String;

  @Input()
  public showWarningMessage: boolean = false;

  _model;
  @Input()
  get model() {
    return this._model;
  }
  set model(val) {
    if ((val === undefined || val === null) && this.attribute && this.attribute.defaultValue !== undefined) {
      val = this.attribute.defaultValue;
    }
    this._model = val;
    this.modelChange.emit(this._model);
  }

  constructor() {
    super();
  }

  isDisabled() {
    return (
      (this.attribute.isReadOnly && this.attribute.isReadOnly()) || (this.attribute.isDisabled && this.attribute.isDisabled())
    );
  }

  ngOnInit() {}

  handleChange(event) {
    this.change.emit(event);
  }
}
