import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class SeasonYear implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Year',
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      inputGroupClass: 'ui-narrow-input',
      placeholderText: 'Year',
      name: '',
      textInputType: TextInputType.integer,
      maxLength: 4,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.seasonYear;
    componentInstance.modelChange.subscribe(x => {
      candidate.seasonYear = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.seasonYear) {
      componentInstance.model = candidate.seasonYear;
    }
  }
}
