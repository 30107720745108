// tslint:disable:max-line-length
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './2.0.0/login/login.component';
import {AuthGuard} from './2.0.0/auth/auth.guard';
import {BdaOrScaGuard} from './2.0.0/auth/bda-or-sca.guard';
import {BuyerBonusSizeReviewComponent} from './2.0.0/buyer-bonus-size-flow/buyer-bonus-size-review/buyer-bonus-size-review.component';
import {
  PharmNewProductCaseDetailsComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-case-details/pharm-new-product-case-details.component';
import {PharmNewProductClosedComponent} from './2.0.0/pharm-new-product-flow/pharm-new-product-closed/pharm-new-product-closed.component';
import {
  PharmNewProductDetailsComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-details/pharm-new-product-details.component';
import {PharmNewProductSetupComponent} from './2.0.0/pharm-new-product-flow/pharm-new-product-setup/pharm-new-product-setup.component';
import {
  PharmNewProductSupplierDetailsComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-supplier-details/pharm-new-product-supplier-details.component';
import {
  PharmNewProductWarehousesComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-warehouses/pharm-new-product-warehouses.component';
import {PiaAdditionalCasePackClosedComponent} from './2.0.0/pia-additional-case-pack-closed/pia-additional-case-pack-closed.component';

import {
  AssociateUpcProcurementReviewClosedComponent
} from './2.0.0/pia-associate-upc-flow/associate-upc-procurement-review-closed/associate-upc-procurement-review-closed.component';
import {PiaBonusSizeReviewClosedComponent} from './2.0.0/pia-bonus-size-review-closed/pia-bonus-size-review-closed.component';
import {PiaBonusSizeReviewComponent} from './2.0.0/pia-bonus-size-review/pia-bonus-size-review.component';
import {PiaMrtInnerReviewClosedComponent} from './2.0.0/pia-mrt-inner-review-closed/pia-mrt-inner-review-closed.component';
import {PiaMrtReviewClosedComponent} from './2.0.0/pia-mrt-review-closed/pia-mrt-review-closed.component';
import {PiaNewFreightTaxUpcComponent} from './2.0.0/pia-new-freight-tax-upc/pia-new-freight-tax-upc.component';

import {
  ProcurementSupportProductReviewClosedComponent
} from './2.0.0/procurement-support-product-review-closed/procurement-support-product-review-closed.component';
import {SCABonusSizeReviewComponent} from './2.0.0/sca-bonus-size-review/sca-bonus-size-review.component';
import {ScaNotificationsComponent} from './2.0.0/sca-notifications/sca-notifications.component';
import {SetUpNotificationsComponent} from './2.0.0/set-up-notifications/set-up-notifications.component';
import {BonusDetailsComponent} from './2.0.0/supplier-bonus-size-flow/bonus-details/bonus-details.component';
import {BonusCaseDetailsComponent} from './2.0.0/supplier-bonus-size-flow/bonus-case-details/bonus-case-details.component';
import {SetupBonusSizeComponent} from './2.0.0/supplier-bonus-size-flow/setup-bonus-size/setup-bonus-size.component';
import {MrtCaseDetailsReviewComponent} from './2.0.0/supplier-mrt-flow/mrt-case-details-review/mrt-case-details-review.component';
import {SupplierMrtInnerReviewComponent} from './2.0.0/supplier-mrt-flow/supplier-mrt-inner-review/supplier-mrt-inner-review.component';
import {SupplierNotificationsComponent} from './2.0.0/supplier-notifications/supplier-notifications.component';
import {StoreAuthorizationComponent} from './2.0.0/supplier-product-flow/store-authorization/store-authorization.component';
import {SupplierDetailsComponent} from './2.0.0/supplier-product-flow/supplier-details/supplier-details.component';
import {SetupCandidateComponent} from './2.0.0/setup-candidate/setup-candidate.component';
import {SetupCandidateTypeComponent} from './2.0.0/supplier-product-flow/setup-candidate-type/setup-candidate-type.component';
import {TaskViewerComponent} from './2.0.0/task-list/task-viewer/task-viewer.component';
import {BasicItemSetupComponent} from './2.0.0/supplier-product-flow/basic-item-setup/basic-item-setup.component';
import {CasePackComponent} from './2.0.0/supplier-product-flow/case-pack/case-pack.component';
import {BuyerNotificationsComponent} from './2.0.0/buyer-notifications/buyer-notifications.component';
import {BuyerProductReviewComponent} from './2.0.0/buyer-product-review/buyer-product-review.component';
import {ScaProductReviewComponent} from './2.0.0/sca-product-review/sca-product-review.component';

import {
  ProcurementSupportProductReviewComponent
} from './2.0.0/procurement-support-product-review/procurement-support-product-review.component';
import {UnsubscribeNotificationsComponent} from './2.0.0/unsubscribe-notifications/unsubscribe-notifications.component';

import {
  SetupAdditionalCasePackComponent
} from './2.0.0/supplier-case-pack-flow/setup-additional-case-pack/setup-additional-case-pack.component';

import {
  AdditionalCasePackDetailsComponent
} from './2.0.0/supplier-case-pack-flow/additional-case-pack-details/additional-case-pack-details.component';

import {
  BuyerAdditionalCasePackReviewComponent
} from './2.0.0/buyer-additional-case-pack-review/buyer-additional-case-pack-review.component';
import {ScaAdditionalCasePackComponent} from './2.0.0/sca-additional-case-pack/sca-additional-case-pack.component';
import {PiaAdditionalCasePackComponent} from './2.0.0/pia-additional-case-pack/pia-additional-case-pack.component';

import {
  AssociateBasicItemSetupComponent
} from './2.0.0/supplier-associate-upc-flow/associate-basic-item-setup/associate-basic-item-setup.component';
import {SetupAssociateUpcComponent} from './2.0.0/supplier-associate-upc-flow/setup-associate-upc/setup-associate-upc.component';

import {
  AssociateUpcProcurementReviewComponent
} from './2.0.0/pia-associate-upc-flow/associate-upc-procurement-review/associate-upc-procurement-review.component';

import {
  AssociateCaseSelectionComponent
} from './2.0.0/supplier-associate-upc-flow/associate-case-selection/associate-case-selection.component';
import {SetupMrtComponent} from './2.0.0/supplier-mrt-flow/setup-mrt/setup-mrt.component';
import {MrtCaseDetailsComponent} from './2.0.0/supplier-mrt-flow/mrt-case-details/mrt-case-details.component';
import {SetUpNewMrtItemsComponent} from './2.0.0/supplier-mrt-flow/set-up-new-mrt-items/set-up-new-mrt-items.component';
import {RequestHebUpcComponent} from './2.0.0/upc-reservation/request-heb-upc/request-heb-upc.component';
import {
  InnerSupplierAndHebDetailsComponent
} from './2.0.0/supplier-mrt-flow/inner-supplier-and-heb-details/inner-supplier-and-heb-details.component';
import {InnerProductDetailsComponent} from './2.0.0/supplier-mrt-flow/inner-product-details/inner-product-details.component';
import {AddNewMrtCaseComponent} from './2.0.0/supplier-mrt-flow/add-new-mrt-case/add-new-mrt-case.component';
import {InnerCaseDetailsComponent} from './2.0.0/supplier-mrt-flow/inner-case-details/inner-case-details.component';
import {SupplierMrtReviewComponent} from './2.0.0/supplier-mrt-flow/supplier-mrt-review/supplier-mrt-review.component';
import {BuyerMrtReviewComponent} from './2.0.0/buyer-mrt-review/buyer-mrt-review.component';
import {ScaMrtReviewComponent} from './2.0.0/sca-mrt-review/sca-mrt-review.component';
import {PiaMrtReviewComponent} from './2.0.0/pia-mrt-review/pia-mrt-review.component';
import {RequestHebUpcSuccessComponent} from './2.0.0/upc-reservation/request-heb-upc-success/request-heb-upc-success.component';
import {PiaSetupNewProductComponent} from './2.0.0/pia-new-product-flow/pia-setup-new-product/pia-setup-new-product.component';

import {
  PiaNewProductSupplierDetailsComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-supplier-details/pia-new-product-supplier-details.component';
import {SupplierBonusReviewComponent} from './2.0.0/supplier-bonus-size-flow/supplier-bonus-review/supplier-bonus-review.component';

import {PiaNewProductDetailsComponent} from './2.0.0/pia-new-product-flow/pia-new-product-details/pia-new-product-details.component';

import {
  PiaNewProductCaseDetailsComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-case-details/pia-new-product-case-details.component';

import {
  PiaNewProductWarehousesComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-warehouses/pia-new-product-warehouses.component';
import {AssociateReviewPageComponent} from './2.0.0/supplier-associate-upc-flow/associate-review-page/associate-review-page.component';
import {NewProductReviewPageComponent} from './2.0.0/supplier-product-flow/new-product-review-page/new-product-review-page.component';

import {
  AdditionalCasePackReviewComponent
} from './2.0.0/supplier-case-pack-flow/additional-case-pack-review/additional-case-pack-review.component';
import {ProductSearchComponent} from './2.0.0/product-search/product-search.component';
import {NeedAssistanceComponent} from './2.0.0/need-assistance/need-assistance.component';
import {SetUpStoresComponent} from './2.0.0/supplier-product-flow/set-up-stores/set-up-stores.component';
import {
  PiaAssociateBasicItemSetupComponent
} from './2.0.0/pia-new-associate-upc-flow/pia-associate-basic-item-setup/pia-associate-basic-item-setup.component';
import {
  PiaAssociateUpcSetupComponentComponent
} from './2.0.0/pia-new-associate-upc-flow/pia-associate-upc-setup-component/pia-associate-upc-setup-component.component';
import {
  PiaAssociateCaseSelectionComponentComponent
} from './2.0.0/pia-new-associate-upc-flow/pia-associate-case-selection-component/pia-associate-case-selection-component.component';
import {PiaBonusDetailsComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-details/pia-bonus-details.component';
import {PiaBonusCaseDetailsComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-case-details/pia-bonus-case-details.component';
import {PiaSetupBonusSizeComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-setup-bonus-size/pia-setup-bonus-size.component';
import {PiaBonusWarehouseComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-warehouse/pia-bonus-warehouse.component';
import {
  PiaAdditionalCasePackDetailsComponent
} from './2.0.0/pia-new-case-pack-flow/pia-additional-case-pack-details/pia-additional-case-pack-details.component';
import {
  PiaAdditionalCasePackWarehouseComponent
} from './2.0.0/pia-new-case-pack-flow/pia-additional-case-pack-warehouse/pia-additional-case-pack-warehouse.component';
import {PiaSetupCasePackComponent} from './2.0.0/pia-new-case-pack-flow/pia-setup-case-pack/pia-setup-case-pack.component';

import {ObRegScalePluReviewComponent} from './2.0.0/scale-plu-flow/ob-reg-scale-plu-review/ob-reg-scale-plu-review.component';
import {PiaStoreAuthorizationComponent} from './2.0.0/pia-new-product-flow/pia-store-authorization/pia-store-authorization.component';
import {PiaSetUpStoresComponent} from './2.0.0/pia-new-product-flow/pia-set-up-stores/pia-set-up-stores.component';
import {NewUserRoleRequestComponent} from './2.0.0/new-user-flow/new-user-role-request/new-user-role-request.component';
import {PiaScalePluReviewComponent} from './2.0.0/scale-plu-flow/pia-scale-plu-review/pia-scale-plu-review.component';
import {
  InvitedDistributorCaseDetailsComponent
} from './2.0.0/supplier-invited-distributor-flow/invited-distributor-case-details/invited-distributor-case-details.component';
import {
  InvitedDistributorStoreAuthorizationComponent
} from './2.0.0/supplier-invited-distributor-flow/invited-distributor-store-authorization/invited-distributor-store-authorization.component';
import {
  SetUpInvitedDistributorStoresComponent
} from './2.0.0/supplier-invited-distributor-flow/set-up-invited-distributor-stores/set-up-invited-distributor-stores.component';
import {
  BuyerInvitedDistributorsReviewComponent
} from './2.0.0/buyer-invited-distributors-review/buyer-invited-distributors-review.component';
import {PiaInvitedDistributorsReviewComponent} from './2.0.0/pia-invited-distributors-review/pia-invited-distributors-review.component';
import {PiaSetupMrtComponent} from './2.0.0/pia-new-mrt-flow/pia-setup-mrt/pia-setup-mrt.component';
import {PiaMrtCaseDetailsComponent} from './2.0.0/pia-new-mrt-flow/pia-mrt-case-details/pia-mrt-case-details.component';
import {PiaMrtWarehouseComponent} from './2.0.0/pia-new-mrt-flow/pia-mrt-warehouse/pia-mrt-warehouse.component';
import {PiaSetupNewMrtItemsComponent} from './2.0.0/pia-new-mrt-flow/pia-setup-new-mrt-items/pia-setup-new-mrt-items.component';
import {
  PiaInnerSupplierAndHebDetailsComponent
} from './2.0.0/pia-new-mrt-flow/pia-inner-supplier-and-heb-details/pia-inner-supplier-and-heb-details.component';
import {PiaInnerProductDetailsComponent} from './2.0.0/pia-new-mrt-flow/pia-inner-product-details/pia-inner-product-details.component';
import {PiaAddNewMrtCaseComponent} from './2.0.0/pia-new-mrt-flow/pia-add-new-mrt-case/pia-add-new-mrt-case.component';
import {PiaInnerCaseDetailsComponent} from './2.0.0/pia-new-mrt-flow/pia-inner-case-details/pia-inner-case-details.component';
import {PiaMrtInnerWarehouseComponent} from './2.0.0/pia-new-mrt-flow/pia-mrt-inner-warehouse/pia-mrt-inner-warehouse.component';
import {VolumeUploadComponent} from './2.0.0/volume-upload/volume-upload/volume-upload.component';
import {
  SupplierAddDistributorComponent
} from './2.0.0/supplier-invited-distributor-flow/supplier-add-distributor/supplier-add-distributor.component';
import {BuyerBulkReviewComponent} from './2.0.0/task-list/buyer-bulk-review/buyer-bulk-review.component';
import {ScaBulkReviewComponent} from './2.0.0/task-list/sca-bulk-review/sca-bulk-review.component';
import {UploadProductsComponent} from './2.0.0/volume-upload/upload-products/upload-products.component';
import {
  InvitedDistributorReviewPageComponent
} from './2.0.0/supplier-invited-distributor-flow/invited-distributor-review-page/invited-distributor-review-page.component';
import {
  PiaInvitedDistributorsReviewClosedComponent
} from './2.0.0/pia-invited-distributors-review-closed/pia-invited-distributors-review-closed.component';
import {VolumeUploadSettingsComponent} from './2.0.0/volume-upload-settings/volume-upload-settings.component';
import {PiaAddDistributorComponent} from './2.0.0/pia-new-invited-distributor-flow/pia-add-distributor/pia-add-distributor.component';
import {
  PiaNewInvitedDistributorCaseDetailsComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-case-details/pia-new-invited-distributor-case-details.component';
import {
  PiaNewInvitedDistributorStoreAuthorizationComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-store-authorization/pia-new-invited-distributor-store-authorization.component';
import {
  PiaNewInvitedDistributorReviewComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-review/pia-new-invited-distributor-review.component';
import {
  PiaNewInvitedDistributorSetupStoresComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-setup-stores/pia-new-invited-distributor-setup-stores.component';
import {PiaBulkReviewComponent} from './2.0.0/task-list/pia-bulk-review/pia-bulk-review.component';
import {ReservedUpcsComponent} from './2.0.0/upc-reservation/reserved-upcs/reserved-upcs.component';
import {ReservedUpcReviewComponent} from './2.0.0/upc-reservation/reserved-upc-review/reserved-upc-review.component';
import {ExtendedAttributesComponent} from './2.0.0/supplier-product-flow/extended-attributes/extended-attributes.component';
import {InnerExtendedAttributesComponent} from './2.0.0/supplier-mrt-flow/inner-extended-attributes/inner-extended-attributes.component';
import {
  PiaNewProductExtendedAttributesComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-extended-attributes/pia-new-product-extended-attributes.component';
import {
  PiaInnerExtendedAttributesComponent
} from './2.0.0/pia-new-mrt-flow/pia-inner-extended-attributes/pia-inner-extended-attributes.component';
import {
  BonusExtendedAttributesComponent
} from './2.0.0/supplier-bonus-size-flow/bonus-extended-attributes/bonus-extended-attributes.component';
import {
  PiaBonusExtendedAttributesComponent
} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-extended-attributes/pia-bonus-extended-attributes.component';
import {
  AdditionalCasePackExtendedAttributesComponent
} from './2.0.0/supplier-case-pack-flow/additional-case-pack-extended-attributes/additional-case-pack-extended-attributes.component';
import {
  PiaAdditionalCasePackExtendedAttributesComponent
} from './2.0.0/pia-new-case-pack-flow/pia-additional-case-pack-extended-attributes/pia-additional-case-pack-extended-attributes.component';
import {MrtExtendedAttributesComponent} from './2.0.0/supplier-mrt-flow/mrt-extended-attributes/mrt-extended-attributes.component';
import {
  PiaMrtExtendedAttributesComponent
} from './2.0.0/pia-new-mrt-flow/pia-mrt-extended-attributes/pia-mrt-extended-attributes.component';
import {
  AssociateExtendedAttributesComponent
} from './2.0.0/supplier-associate-upc-flow/associate-extended-attributes/associate-extended-attributes.component';
import {
  PiaAssociateExtendedAttributesComponent
} from './2.0.0/pia-new-associate-upc-flow/pia-associate-extended-attributes/pia-associate-extended-attributes.component';

const routes: Routes = [
  { path: '', redirectTo: '/tasks', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },

  // 2.0.0 paths
  { path: 'tasks', component: TaskViewerComponent, canActivate: [AuthGuard, BdaOrScaGuard] },
  { path: 'setupCandidate', component: SetupCandidateComponent, canActivate: [AuthGuard] },
  { path: 'buyerNotifications', component: BuyerNotificationsComponent, canActivate: [AuthGuard] },
  { path: 'supplierNotifications', component: SupplierNotificationsComponent, canActivate: [AuthGuard] },
  { path: 'scaNotifications', component: ScaNotificationsComponent, canActivate: [AuthGuard] },
  { path: 'setUpNotifications', component: SetUpNotificationsComponent, canActivate: [AuthGuard] },
  { path: 'needAssistance', component: NeedAssistanceComponent, canActivate: [AuthGuard] },
  { path: 'buyerBulkEdit', component: BuyerBulkReviewComponent, canActivate: [AuthGuard] },
  { path: 'scaBulkEdit', component: ScaBulkReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaBulkEdit', component: PiaBulkReviewComponent, canActivate: [AuthGuard] },

  // Product Candidate Flow

  { path: 'setupCandidateType', component: SetupCandidateTypeComponent, canActivate: [AuthGuard] },
  { path: 'supplierDetails', component: SupplierDetailsComponent, canActivate: [AuthGuard] },
  { path: 'basicItemSetup', component: BasicItemSetupComponent, canActivate: [AuthGuard] },
  { path: 'casePack', component: CasePackComponent, canActivate: [AuthGuard] },
  { path: 'storeAuthorization', component: StoreAuthorizationComponent, canActivate: [AuthGuard] },
  { path: 'setUpStores', component: SetUpStoresComponent, canActivate: [AuthGuard] },
  { path: 'extendedAttributes', component: ExtendedAttributesComponent, canActivate: [AuthGuard] },
  { path: 'newProductReviewPageComponent', component: NewProductReviewPageComponent, canActivate: [AuthGuard] },

  { path: 'buyerProductReview', component: BuyerProductReviewComponent, canActivate: [AuthGuard] },
  { path: 'scaProductReview', component: ScaProductReviewComponent, canActivate: [AuthGuard] },
  { path: 'procurementSupportProductReview', component: ProcurementSupportProductReviewComponent, canActivate: [AuthGuard] },
  { path: 'procurementSupportProductReviewClosed', component: ProcurementSupportProductReviewClosedComponent, canActivate: [AuthGuard] },
  { path: 'unsubscribe', component: UnsubscribeNotificationsComponent, canActivate: [AuthGuard] },

  // Additional case pack flow
  { path: 'setupAdditionalCasePack', component: SetupAdditionalCasePackComponent, canActivate: [AuthGuard] },
  { path: 'additionalCasePackDetails', component: AdditionalCasePackDetailsComponent, canActivate: [AuthGuard] },
  { path: 'additionalCasePackExtendedAttributes', component: AdditionalCasePackExtendedAttributesComponent, canActivate: [AuthGuard] },
  { path: 'additionalCasePackReview', component: AdditionalCasePackReviewComponent, canActivate: [AuthGuard] },
  { path: 'buyerAdditionalCasePackReview', component: BuyerAdditionalCasePackReviewComponent, canActivate: [AuthGuard] },
  { path: 'scaAdditionalCasePack', component: ScaAdditionalCasePackComponent, canActivate: [AuthGuard] },
  { path: 'piaAdditionalCasePack', component: PiaAdditionalCasePackComponent, canActivate: [AuthGuard] },
  { path: 'piaAdditionalCasePackClosed', component: PiaAdditionalCasePackClosedComponent, canActivate: [AuthGuard] },

  // Supplier Bonus Size Flow
  { path: 'setupBonusSize', component: SetupBonusSizeComponent, canActivate: [AuthGuard] },
  { path: 'bonusDetails', component: BonusDetailsComponent, canActivate: [AuthGuard] },
  { path: 'caseDetails', component: BonusCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'bonusUpcExtendedAttributes', component: BonusExtendedAttributesComponent, canActivate: [AuthGuard] },
  { path: 'bonusReview', component: SupplierBonusReviewComponent, canActivate: [AuthGuard] },

  // Associate UPC flow
  { path: 'setupAssociateUpc', component: SetupAssociateUpcComponent, canActivate: [AuthGuard] },
  { path: 'associateBasicItemSetup', component: AssociateBasicItemSetupComponent, canActivate: [AuthGuard] },
  { path: 'associateUpcProcurementReview', component: AssociateUpcProcurementReviewComponent, canActivate: [AuthGuard] },
  { path: 'associateUpcProcurementReviewClosed', component: AssociateUpcProcurementReviewClosedComponent, canActivate: [AuthGuard] },
  { path: 'associateCaseSelection', component: AssociateCaseSelectionComponent, canActivate: [AuthGuard] },
  { path: 'associateExtendedAttributes', component: AssociateExtendedAttributesComponent, canActivate: [AuthGuard] },
  { path: 'associateSupplierReview', component: AssociateReviewPageComponent, canActivate: [AuthGuard] },

  // Bonus Size Flow
  { path: 'buyerBonusSizeReview', component: BuyerBonusSizeReviewComponent, canActivate: [AuthGuard] },
  { path: 'scaBonusSizeReview', component: SCABonusSizeReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaBonusSizeReview', component: PiaBonusSizeReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaBonusSizeReviewClosed', component: PiaBonusSizeReviewClosedComponent, canActivate: [AuthGuard] },

  // MRT Flow
  { path: 'setupMrt', component: SetupMrtComponent, canActivate: [AuthGuard] },
  { path: 'mrtCaseDetails', component: MrtCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'mrtExtendedAttributes', component: MrtExtendedAttributesComponent, canActivate: [AuthGuard] },
  { path: 'mrtCaseDetailsReview', component: MrtCaseDetailsReviewComponent, canActivate: [AuthGuard] },
  { path: 'setUpNewMrtItems', component: SetUpNewMrtItemsComponent, canActivate: [AuthGuard] },

  // MRT INNER FLOW
  { path: 'supplierAndHebDetails', component: InnerSupplierAndHebDetailsComponent, canActivate: [AuthGuard] },
  { path: 'innerProductDetails', component: InnerProductDetailsComponent, canActivate: [AuthGuard] },
  { path: 'addNewMrtCase', component: AddNewMrtCaseComponent, canActivate: [AuthGuard] },
  { path: 'innerCaseDetails', component: InnerCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'innerExtendedAttributes', component: InnerExtendedAttributesComponent, canActivate: [AuthGuard] },
  { path: 'supplierMrtReview', component: SupplierMrtReviewComponent, canActivate: [AuthGuard] },
  { path: 'buyerMrtReview', component: BuyerMrtReviewComponent, canActivate: [AuthGuard] },
  { path: 'scaMrtReview', component: ScaMrtReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaMrtReview', component: PiaMrtReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaMrtReviewClosed', component: PiaMrtReviewClosedComponent, canActivate: [AuthGuard] },
  { path: 'mrtInnerSupplierReview', component: SupplierMrtInnerReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaMrtInnerReviewClosed', component: PiaMrtInnerReviewClosedComponent, canActivate: [AuthGuard] },

  // HEB Own Brand UPC Flow
  { path: 'reservedUpcs', component: ReservedUpcsComponent, canActivate: [AuthGuard] },
  { path: 'reservedUpcReview', component: ReservedUpcReviewComponent, canActivate: [AuthGuard] },
  { path: 'requestHebUpc', component: RequestHebUpcComponent, canActivate: [AuthGuard] },
  { path: 'requestHebUpcSuccess', component: RequestHebUpcSuccessComponent, canActivate: [AuthGuard] },

  // PIA New Product Flow
  { path: 'piaSetupNewProduct', component: PiaSetupNewProductComponent, canActivate: [AuthGuard] },
  { path: 'piaNewProductSupplierDetails', component: PiaNewProductSupplierDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaNewProductDetails', component: PiaNewProductDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaNewProductCaseDetails', component: PiaNewProductCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaNewProductWarehouses', component: PiaNewProductWarehousesComponent, canActivate: [AuthGuard] },
  { path: 'piaStoreAuthorization', component: PiaStoreAuthorizationComponent, canActivate: [AuthGuard] },
  { path: 'piaSetUpStores', component: PiaSetUpStoresComponent, canActivate: [AuthGuard] },
  { path: 'piaNewProductExtendedAttributes', component: PiaNewProductExtendedAttributesComponent, canActivate: [AuthGuard] },

  // Pharm New Product Flow
  { path: 'pharmSetupNewProduct', component: PharmNewProductSetupComponent, canActivate: [AuthGuard] },
  { path: 'pharmNewProductSupplierDetails', component: PharmNewProductSupplierDetailsComponent, canActivate: [AuthGuard] },
  { path: 'pharmNewProductDetails', component: PharmNewProductDetailsComponent, canActivate: [AuthGuard] },
  { path: 'pharmNewProductCaseDetails', component: PharmNewProductCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'pharmNewProductWarehouses', component: PharmNewProductWarehousesComponent, canActivate: [AuthGuard] },
  { path: 'pharmProductReviewClosed', component: PharmNewProductClosedComponent, canActivate: [AuthGuard] },

  // Freight Tax UPC Flow
  { path: 'piaSetupNewFreightTaxUpc', component: PiaNewFreightTaxUpcComponent, canActivate: [AuthGuard] },

  // Product Search
  { path: 'productSearch', component: ProductSearchComponent, canActivate: [AuthGuard] },

  // PIA Associate UPC Flow
  { path: 'piaAssociateUpcSetup', component: PiaAssociateUpcSetupComponentComponent, canActivate: [AuthGuard] },
  { path: 'piaAssociateBasicItemSetup', component: PiaAssociateBasicItemSetupComponent, canActivate: [AuthGuard] },
  { path: 'piaAssociateCaseSelection', component: PiaAssociateCaseSelectionComponentComponent, canActivate: [AuthGuard] },
  { path: 'piaAssociateExtendedAttributes', component: PiaAssociateExtendedAttributesComponent, canActivate: [AuthGuard] },

  // PIA Bonus UPC Flow
  { path: 'piaSetupBonusSizeComponent', component: PiaSetupBonusSizeComponent, canActivate: [AuthGuard] },
  { path: 'piaBonusDetailsComponent', component: PiaBonusDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaBonusCaseDetailsComponent', component: PiaBonusCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaBonusWarehouseComponent', component: PiaBonusWarehouseComponent, canActivate: [AuthGuard] },
  { path: 'piaBonusExtendedAttributes', component: PiaBonusExtendedAttributesComponent, canActivate: [AuthGuard] },

  // PIA Additional Case Pack Flow
  { path: 'piaSetupCasePackComponent', component: PiaSetupCasePackComponent, canActivate: [AuthGuard] },
  { path: 'piaCasePackDetailsComponent', component: PiaAdditionalCasePackDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaCasePackWarehouseComponent', component: PiaAdditionalCasePackWarehouseComponent, canActivate: [AuthGuard] },
  { path: 'piaCasePackExtendedAttributes', component: PiaAdditionalCasePackExtendedAttributesComponent, canActivate: [AuthGuard] },


  // Ob Reg Scale PLU Flow
  { path: 'obRegScalePluReview', component: ObRegScalePluReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaScalePluReview', component: PiaScalePluReviewComponent, canActivate: [AuthGuard] },

  // New User Flow
  { path: 'requestRole', component: NewUserRoleRequestComponent, canActivate: [AuthGuard]},

  // Invited Distributor Flow
  { path: 'supplierAddDistributor', component: SupplierAddDistributorComponent, canActivate: [AuthGuard]},
  { path: 'invitedDistributorCaseDetails', component: InvitedDistributorCaseDetailsComponent, canActivate: [AuthGuard]},
  { path: 'invitedDistributorStoreAuthorization', component: InvitedDistributorStoreAuthorizationComponent, canActivate: [AuthGuard] },
  { path: 'setUpInvitedDistributorStores', component: SetUpInvitedDistributorStoresComponent, canActivate: [AuthGuard] },
  { path: 'buyerInvitedDistributorsReview', component: BuyerInvitedDistributorsReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaInvitedDistributorsReview', component: PiaInvitedDistributorsReviewComponent, canActivate: [AuthGuard] },
  { path: 'invitedDistributorReviewPage', component: InvitedDistributorReviewPageComponent, canActivate: [AuthGuard] },
  { path: 'piaInvitedDistributorsReviewClosed', component: PiaInvitedDistributorsReviewClosedComponent, canActivate: [AuthGuard] },

  // PIA MRT NR Flow
  { path: 'piaSetupMrt', component: PiaSetupMrtComponent, canActivate: [AuthGuard] },
  { path: 'piaMrtCaseDetails', component: PiaMrtCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaMrtWarehouseSelection', component: PiaMrtWarehouseComponent, canActivate: [AuthGuard] },
  { path: 'piaMrtExtendedAttributes', component: PiaMrtExtendedAttributesComponent, canActivate: [AuthGuard] },
  { path: 'piaSetupNewMrtItems', component: PiaSetupNewMrtItemsComponent, canActivate: [AuthGuard] },
  { path: 'piaSupplierAndHebDetails', component: PiaInnerSupplierAndHebDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaInnerProductDetails', component: PiaInnerProductDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaAddNewMrtCase', component: PiaAddNewMrtCaseComponent, canActivate: [AuthGuard] },
  { path: 'piaInnerCaseDetails', component: PiaInnerCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaMrtInnerWarehouseDetails', component: PiaMrtInnerWarehouseComponent, canActivate: [AuthGuard] },
  { path: 'piaMrtInnerExtendedAttributes', component: PiaInnerExtendedAttributesComponent, canActivate: [AuthGuard] },

  // Volume Upload Flow
  { path: 'uploadProducts', component: UploadProductsComponent, canActivate: [AuthGuard] },
  { path: 'volumeUpload', component: VolumeUploadComponent, canActivate: [AuthGuard] },
  { path: 'ediUpload', component: VolumeUploadComponent, canActivate: [AuthGuard] },
  { path: 'volumeUploadSettings', component: VolumeUploadSettingsComponent, canActivate: [AuthGuard] },

  // Supplier Replacement item Flow
  { path: 'setupReplacementUpc', component: SetupBonusSizeComponent, canActivate: [AuthGuard] },
  { path: 'replacementDetails', component: BonusDetailsComponent, canActivate: [AuthGuard] },
  { path: 'replacementUpcExtendedAttributes', component: BonusExtendedAttributesComponent, canActivate: [AuthGuard] },
  { path: 'replacementUpcReview', component: SupplierBonusReviewComponent, canActivate: [AuthGuard] },

  // Replacement item Size Flow
  { path: 'buyerReplacementUpcReview', component: BuyerBonusSizeReviewComponent, canActivate: [AuthGuard] },
  { path: 'scaReplacementUpcReview', component: SCABonusSizeReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaReplacementUpcReview', component: PiaBonusSizeReviewComponent, canActivate: [AuthGuard] },
  { path: 'piaReplacementUpcReviewClosed', component: PiaBonusSizeReviewClosedComponent, canActivate: [AuthGuard] },

  // PIA Replacement UPC Flow
  { path: 'piaSetupReplacementUpc', component: PiaSetupBonusSizeComponent, canActivate: [AuthGuard] },
  { path: 'piaReplacementDetails', component: PiaBonusDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaReplacementCaseDetails', component: PiaBonusCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaReplacementWarehouse', component: PiaBonusWarehouseComponent, canActivate: [AuthGuard] },
  { path: 'piaReplacementUpcExtendedAttributes', component: PiaBonusExtendedAttributesComponent, canActivate: [AuthGuard] },

  // PIA Invited Distributor Flow
  { path: 'piaAddDistributor', component: PiaAddDistributorComponent, canActivate: [AuthGuard] },
  { path: 'piaAddDistributorCaseDetails', component: PiaNewInvitedDistributorCaseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'piaAddDistributorStoreAuth', component: PiaNewInvitedDistributorStoreAuthorizationComponent, canActivate: [AuthGuard] },
  { path: 'piaAddDistributorStoreSetup', component: PiaNewInvitedDistributorSetupStoresComponent, canActivate: [AuthGuard] },
  { path: 'piaAddDistributorReview', component: PiaNewInvitedDistributorReviewComponent, canActivate: [AuthGuard] },


];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {useHash : true, scrollPositionRestoration: 'enabled'}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
