import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InputState, UPCInputState} from 'pm-components';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  LocationGroupStores,
  TextInputType
} from 'pm-models';
import {SelectItem} from 'primeng/api';
import {PiaProductService} from '../../service/pia-product.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {PiaNewProductFlowStepperComponent} from '../pia-new-product-flow-stepper/pia-new-product-flow-stepper.component';
import {PiaNewDsdProductFlowStepperComponent} from '../pia-new-dsd-product-flow-stepper/pia-new-dsd-product-flow-stepper.component';
import {CandidateService} from '../../service/candidate.service';

@Component({
  selector: 'app-store-authorization',
  templateUrl: './pia-store-authorization.component.html',
  styleUrls: ['./pia-store-authorization.component.scss']
})
export class PiaStoreAuthorizationComponent implements OnInit {

  public isViewingPage = true;
  public currentCandidateProductIndex;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public costLinkState: InputState;
  public upcState: UPCInputState;
  private taskSubscription$: any;
  public canClickSubmit = true;
  public storesInput: any = '';

  storesInputConfiguration: AttributeTextInputConfig = {
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'storesListId',
    textInputType: TextInputType.textarea,
    inputGroupClass: 'find-stores-by-selec',
    placeholderText: '',
    description: 'Separate store numbers by a comma or enter each store on a separate line.',
  };

  allGroupsConfiguration: AttributeConfig = {
    label: 'All groups',
    description: 'Show stores in all groups',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'allGroupsId',
    defaultValue: false
  };

  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.piaProductService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.piaProductService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  private getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }


  public distributorName: any;
  public storesErrorMessage: string;
  public selectAllAuthGroups: boolean;
  public showNoStoresError: boolean;
  public STORES = 'stores';
  public GROUPS = 'groups';

  types: SelectItem[];
  selectedType: string = 'stores';

  constructor(private route: ActivatedRoute, private router: Router, public piaProductService: PiaProductService,
              public candidateUtilService: CandidateUtilService, private candidateService: CandidateService) {
    this.types = [
      { label: 'Stores', value: this.STORES },
      { label: 'Groups', value: this.GROUPS }
    ];
  }

  ngOnInit() {

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.piaProductService.getTaskId(), this.piaProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.piaProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.initializeData();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.piaProductService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.piaProductService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  initializeData() {
    this.distributorName = this.piaProductService.getCandidate().vendor.description;
    this.candidateError = this.piaProductService.getStoreAuthError();
    this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
    this.currentCandidateProductIndex = this.piaProductService.getCurrentCandidateProductIndex();
    this.setAuthGroups();
    if (this.piaProductService.getStoresInput()) {
      this.storesInput = this.piaProductService.getStoresInput();
    } else {
      this.onFilterChange();
    }
  }

  setAuthGroups() {
    if (this.piaProductService.getCandidate().overrideDepartment && this.piaProductService.getCandidate().overrideSubDepartment &&
      !this.piaProductService.getAuthGroups()) {

      this.piaProductService.findAllAuthGroupsByParms(this.piaProductService.getCandidate().vendor.apNumber,
        this.piaProductService.getCandidate().overrideSubDepartment.departmentId,
        this.piaProductService.getCandidate().overrideSubDepartment.subDepartmentId).subscribe((authGroups) => {

        this.piaProductService.setAuthGroups(authGroups);
        this.showNoStoresError = this.piaProductService.validateHasNoStores();
        for (let x = 0; x < this.piaProductService.getAuthGroups().length; x++) {
          this.piaProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
      });
    } else if (!this.piaProductService.getAuthGroups()) {

      this.piaProductService.findAllAuthGroups().subscribe((authGroups) => {
        this.piaProductService.setAuthGroups(authGroups);
        this.showNoStoresError = this.piaProductService.validateHasNoStores();
        for (let x = 0; x < this.piaProductService.getAuthGroups().length; x++) {
          this.piaProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
      });
    } else {
      this.showNoStoresError = this.piaProductService.validateHasNoStores();
      for (let x = 0; x < this.piaProductService.getAuthGroups().length; x++) {
        this.piaProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
      }
    }
  }

  onClickBack() {
    this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
  }

  onClose() {
    this.isViewingPage = false;
    this.piaProductService.saveCandidateAndClose();
  }

  storesInputChangeEvent(storesInput: any) {
    this.storesErrorMessage = this.validateStoresInput(storesInput);
  }

  isShowTheseStoresDisabled() {
    return this.selectedType === this.STORES &&
      (this.storesErrorMessage || !this.storesInput);
  }

  /**
   * Stores input should be comma or newline separated group of store numbers.
   * @param storesInput
   */
  validateStoresInput(storesInput: any) {
    const stores = storesInput.trim().replace(/,/g, '\n');
    const storesArray = stores.split('\n');
    const regexp: RegExp = /^\d+$/; // only numbers

    let result;

    for (let i = 0; i < storesArray.length; i++) {
      // make sure all stores are a number
      if (!regexp.test(storesArray[i])) {
        result = 'Please enter store numbers, separated by a comma or each store on a new line.';
        break;
      }
    }
    if (!result) {
      this.piaProductService.setStoresSearched(storesArray);
      this.piaProductService.setStoresInput(storesInput);
    }
    return result;
  }

  onClickAllAuthGroupCheckbox(event) {
    for (let x = 0; x < this.piaProductService.getAuthGroups().length; x++) {
      this.piaProductService.getAuthGroups()[x].checked = event;
    }
    // trigger update
    this.piaProductService.setAuthGroups(JSON.parse(JSON.stringify(this.piaProductService.getAuthGroups())));
  }

  onClickShowAllStores() {
    this.piaProductService.setSelectedAuthGroups(this.piaProductService.getAuthGroups());
    this.piaProductService.openAuthGroups();
    this.piaProductService.setNotFoundStores(null);
    this.router.navigate(['/piaSetUpStores'], {queryParams: {taskId: this.piaProductService.getTaskId()}});

  }

  onClickGroupShowStores() {

    const selectedAuthGroups: LocationGroupStores[] = [];

    for (let x = 0; x < this.piaProductService.getAuthGroups().length; x++) {
      if (this.piaProductService.getAuthGroups()[x].checked) {
        selectedAuthGroups.push(JSON.parse(JSON.stringify(this.piaProductService.getAuthGroups()[x])));
      }
    }
    this.piaProductService.setSelectedAuthGroups(selectedAuthGroups);
    this.piaProductService.openAuthGroups();
    this.router.navigate(['/piaSetUpStores'], {queryParams: {taskId: this.piaProductService.getTaskId()}});

  }

  onClickShowTheseStores() {
    if (!this.isShowTheseStoresDisabled()) {
      this.piaProductService.setNotFoundStores(null);
      this.piaProductService.setSelectedAuthGroups(this.piaProductService.getAuthGroups());
      for (let x = 0; x < this.piaProductService.getStoresSearched().length; x++) {
        this.findAndCheckStore(this.piaProductService.getStoresSearched()[x]);
      }
      this.setAuthGroupCheckboxesAndHideNonSelectedStores();
      this.router.navigate(['/piaSetUpStores'], {queryParams: {taskId: this.piaProductService.getTaskId()}});
    }
  }

  hideNonSelectedStores() {
    for (let x = 0; x < this.piaProductService.getSelectedAuthGroups().length; x++) {
      if (!this.piaProductService.getSelectedAuthGroups()[x].isViewing) {
        continue;
      }
      for (let y = 0; y < this.piaProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (!this.piaProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          this.piaProductService.getSelectedAuthGroups()[x].stores[y].isHidden = true;
        }
      }
    }
  }

  findAndCheckStore(storeNumber) {
    for (let x = 0; x < this.piaProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.piaProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (+storeNumber === this.piaProductService.getSelectedAuthGroups()[x].stores[y].custLocationNumber) {
          this.piaProductService.getSelectedAuthGroups()[x].stores[y].checked = true;
          this.piaProductService.getSelectedAuthGroups()[x].isViewing = true;
          return;
        }
      }
    }
    // if store not found-
    this.piaProductService.getNotFoundStores().push(storeNumber);
  }

  /**
   * Sets auth groups to checked if all stores are checked.
   */
  setAuthGroupCheckboxesAndHideNonSelectedStores() {
    let areAllStoresChecked = true;
    for (let x = 0; x < this.piaProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.piaProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (!this.piaProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        this.piaProductService.getSelectedAuthGroups()[x].checked = true;
      }

    }
    this.hideNonSelectedStores();
  }

  onAuthGroupButtonClick(index, event) {

    if (!event) {
      this.selectAllAuthGroups = false;
    } else {
      let areAllGroupsChecked = true;
      for (let x = 0; x < this.piaProductService.getAuthGroups().length; x++) {
        if (!this.piaProductService.getAuthGroups()[x].checked) {
          areAllGroupsChecked = false;
        }
      }
      if (areAllGroupsChecked) {
        this.selectAllAuthGroups = true;
      }

    }
  }

  /**
   * On filter changes, set all the checkboxes/viewports to false and remove searched stores.
   */
  onFilterChange() {
    this.piaProductService.setStoresSearched(null);
    this.piaProductService.setStoresInput(null);
    this.piaProductService.setNotFoundStores(null);
    this.storesInput = '';
    this.selectAllAuthGroups = false;
    for (let x = 0; this.piaProductService.getAuthGroups().length; x++) {
      this.piaProductService.getAuthGroups()[x].isViewing = false;
      this.piaProductService.getAuthGroups()[x].checked = false;
      for (let y = 0; y < this.piaProductService.getAuthGroups()[x].stores.length; y++) {
        this.piaProductService.getAuthGroups()[x].stores[y].checked = false;
      }
    }
  }

  hasSelectedGroup() {
    if (!this.piaProductService.getAuthGroups() || this.piaProductService.getAuthGroups().length === 0) {
      return true;
    }
    for (let x = 0; x < this.piaProductService.getAuthGroups().length; x++) {
      if (this.piaProductService.getAuthGroups()[x].checked) {
        return true;
      }
    }
    return false;
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaNewProductFlowStepperComponent.UPC_ITEM_CODE: {
        this.piaProductService.saveCandidateAndNavigate('/piaSetupNewProduct', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.SUPPLIER_HEB_SETUP: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductSupplierDetails', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.PRODUCT_DETAILS: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductDetails', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.CASE_PACK: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
        break;
      }
      case PiaNewDsdProductFlowStepperComponent.STORE_AUTHORIZATION: {
        // no changes can be made on this page, so no need to make a save call and route to the same page.
        break;
      }
      case PiaNewProductFlowStepperComponent.EXTENDED_ATTRIBUTES: {
        this.onClickExtendedAttributes();
        break;
      }
    }
  }


  onClickExtendedAttributes() {
    this.canClickSubmit = false;

    this.candidateService.validateCandidate(this.piaProductService.getCandidate(),
      [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR]).subscribe(data => {
      this.piaProductService.setStoreAuthError(new CandidateError());
      this.piaProductService.saveCandidateAndNavigate('/piaNewProductExtendedAttributes', false);
    }, (error) => {
      this.piaProductService.scrollToTop();
      if (error.error.candidateErrors?.hasErrors) {
        this.piaProductService.setStoreAuthError(error.error.candidateErrors);
        this.candidateError = this.piaProductService.getStoreAuthError();
        this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
      }
      this.piaProductService.saveCandidateAndNavigate('/piaNewProductExtendedAttributes', false);
    });
  }
}
