import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class Weight implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Weight',
      description: 'The weight of one master pack case, in pounds (lbs).',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      decimalCount: 4,
      numberCount: 9,
      textInputType: TextInputType.decimal,
      inputGroupClass: 'ui-narrow-input',
      placeholderText: 'lbs',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.masterWeight;
    componentInstance.modelChange.subscribe(x => {
      candidate.masterWeight = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.masterWeight) {
      componentInstance.model = candidate.masterWeight;
    }
  }
}
