import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ContentChild, ViewEncapsulation } from '@angular/core';

import { TitleComponent, FooterComponent } from '../../shared/common';

@Component({
  selector: 'pm-editor-base',
  templateUrl: './editor-base.component.html',
  styleUrls: ['./editor-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EditorBaseComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  @ContentChild(TitleComponent) headerFacet;

  @ContentChild(FooterComponent) footerFacet;


  constructor() { }

  ngOnInit() {
  }

}
