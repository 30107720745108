import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';

export enum InputState {
  none,
  loading,
  valid,
  invalid
}

@Component({
  selector: 'pm-attribute-display-muli-source-input',
  templateUrl: './attribute-display-muli-source-input.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-muli-source-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayMuliSourceInputComponent extends BaseAttributeDisplayComponent implements OnInit {
  @Input()
  state: InputState;

  selectCategoryValue;

  @Input()
  get selectCategory() {
    return this.selectCategoryValue;
  }
  set selectCategory(val) {
    this.selectCategoryValue = val;
    this.selectCategoryChange.emit(val);
  }
  @Output() selectCategoryChange?: EventEmitter<any> = new EventEmitter<any>();

  @Output() validate?: EventEmitter<any> = new EventEmitter<any>();

  updateModel(e) {
    this.model = e;
    this.modelChange.emit({ model: this.model, category: this.selectCategory });
  }

  validateModel(e) {
    this.validate.emit(e);
  }
  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.state && this.attributeError) {
      this.state = InputState.invalid;
    }
  }
}
