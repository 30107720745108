import {Component, ContentChild, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommentGridCellInfoComponent} from '../../attribute-grid/comment-grid-cell/comment-grid-cell.component';

@Component({
  selector: 'pm-submit-button-spinner',
  templateUrl: './submit-button-spinner.component.html',
  styleUrls: ['./submit-button-spinner.component.scss']
})
export class SubmitButtonSpinnerComponent implements OnInit {

  // Show/hide component
  @Input() showSpinner: boolean;

  // Submit button clicked
  @Output() submitClicked: EventEmitter<any> = new EventEmitter();

  onSubmitClicked($event) {
    this.submitClicked.emit($event);
    this.showSpinner = true;
  }
  constructor() { }

  ngOnInit() {
  }
}
