<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pia-mrt-stepper [innerFlow]="supplierMrtService.hasNewInners(supplierMrtService.candidate) === true"
                         [candidateErrors]="supplierMrtService.getAllPiaMrtErrors()"
                         [hasInnerCandidateError]="[supplierMrtService.hasInnerErrors()]"
                         [stepIndex]="supplierMrtService.hasNewInners(supplierMrtService.candidate) ? 3: 2"
                         (indexClick)="onClickStepper($event)">
    </app-pia-mrt-stepper>
  </pm-header>

  <pm-title>Tell us about the MRT’s case.</pm-title>
  <ng-container *ngIf="supplierMrtService.candidate">

    <pm-heb-details-card [(model)]="supplierMrtService.candidate"
                         [(candidateProductModel)]="supplierMrtService.candidate.candidateProducts[0]"
                         (buyerChange)="onBuyerChange($event)"
                         [permissions]="hebDetailsCardPermissions"
                         [errorModel]="candidateError"
                         [candidateProductErrorModel]="candidateProductError">

    </pm-heb-details-card>

    <pm-supplier-details-card [(candidateModel)]="supplierMrtService.candidate"
                              [(candidateProductModel)]="supplierMrtService.candidate.candidateProducts[0]"
                              [permissions]="supplierDetailsCardPermissions"
                              [candidateErrorModel]="candidateError"
                              [candidateProductErrorModel]="candidateProductError"
                              [inputCostOwners]="inputCostOwners">
    </pm-supplier-details-card>

    <pm-merchandising-info-card [(candidateModel)]="supplierMrtService.candidate"
                                [(candidateProductModel)]="supplierMrtService.candidate.candidateProducts[0]"
                                [permissions]="merchandisingInfoCardPermissions"
                                [commodities]="commodities"
                                (subCommodityChange)="onSubCommodityChange($event)"
                                [candidateErrorModel]="candidateError"
                                [candidateProductErrorModel]="candidateProductError">
    </pm-merchandising-info-card>

    <pm-case-details-card *ngIf="additionalCasePackBasicsCardPermissions" [(candidateModel)]="supplierMrtService.candidate"
                          [(candidateProductModel)]="supplierMrtService.candidate.candidateProducts[0]"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="candidateError"
                          [candidateProductErrorModel]="candidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUpc()"
                          (buyerChange)="onBuyerChange($event)">
    </pm-case-details-card>

    <pm-imported-product-card [(model)]="supplierMrtService.candidate.candidateProducts[0]"
                              [containerSizes]="containerSizes"
                              [incoTermsList]="incoTermsList"
                              [permissions]="importProductCardPermissions"
                              [errorModel]="candidateProductError">
    </pm-imported-product-card>

    <pm-master-pack-details-card [(model)]="supplierMrtService.candidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="candidateError">
    </pm-master-pack-details-card>

    <pm-cost-card [model]="supplierMrtService.candidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="candidateError"
                  [subTitle]="costCardSubTitle"
                  [masterListCostDescription]="costCardMasterListCostDesc">
    </pm-cost-card>
    <pm-omi-remarks-card [(model)]="supplierMrtService.candidate.candidateProducts[0]"
                         [permissions]="omiRemarksPermissions"
                         [errorModel]="candidateProductError">
    </pm-omi-remarks-card>

    <pm-attachments-card [(model)]="supplierMrtService.candidate"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="candidateError">
    </pm-attachments-card>

  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
