import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CasePackBasicsCardModule} from './case-pack-basics-card/case-pack-basics-card.module';
import {HebDetailsCardModule} from './heb-details-card/heb-details-card.module';
import {ProductBasicsCardModule} from './product-basics-card/product-basics-card.module';
import {RegulatoryCardModule} from './regulatory-card/regulatory-card.module';
import {SupplierDetailsCardModule} from './supplier-details-card/supplier-details-card.module';
import {ProductDescriptionsCardModule} from './product-descriptions-card/product-descriptions-card.module';
import {ProductPricingCardModule} from './product-pricing-card/product-pricing-card.module';
import {AttachmentsCardModule} from './attachments-card/attachments-card.module';
import {MasterPackDetailsCardModule} from './master-pack-details-card/master-pack-details-card.module';
import {CostLinkCardModule} from './cost-link-card/cost-link-card.module';
import {CostCardModule} from './cost-card/cost-card.module';
import {DisplayReadyUnitCardModule} from './display-ready-unit-card/display-ready-unit-card.module';
import {InnerPackDetailsCardModule} from './inner-pack-details-card/inner-pack-details-card.module';
import {ProductPackagingCardModule} from './product-packaging-card/product-packaging-card.module';
import {CaseDetailsCardModule} from './case-details-card/case-details-card.module';
import {DistributionChannelModule} from './distribution-channel/distribution-channel.module';
import {MerchandisingInfoCardModule} from './merchandising-info-card/merchandising-info-card.module';
import {PricingCardModule} from './pricing-card/pricing-card.module';
import {PharmacyCardModule} from './pharmacy-card/pharmacy-card.module';
import {OmiRemarksCardModule} from './omi-remarks-card/omi-remarks-card.module';
import {MaxShipCardModule} from './max-ship-card/max-ship-card.module';
import {ImportFactoryCardModule} from './import-factory-card/import-factory-card.module';
import {ImportedProductCardModule} from './imported-product-card/imported-product-card.module';
import {GlobalAttributesCardModule} from 'pm-components/lib/cards/global-attributes-card/global-attributes-card.module';
import {MatCategoriesCardModule} from 'pm-components/lib/cards/mat-categories-card/mat-categories-card.module';

const CardModules = [
  HebDetailsCardModule,
  RegulatoryCardModule,
  SupplierDetailsCardModule,
  CasePackBasicsCardModule,
  CostLinkCardModule,
  ProductBasicsCardModule,
  ProductDescriptionsCardModule,
  ProductPricingCardModule,
  AttachmentsCardModule,
  MasterPackDetailsCardModule,
  ProductPackagingCardModule,
  DisplayReadyUnitCardModule,
  CostCardModule,
  InnerPackDetailsCardModule,
  CaseDetailsCardModule,
  DistributionChannelModule,
  MerchandisingInfoCardModule,
  PricingCardModule,
  PharmacyCardModule,
  OmiRemarksCardModule,
  MaxShipCardModule,
  ImportFactoryCardModule,
  ImportedProductCardModule,
  GlobalAttributesCardModule,
  MatCategoriesCardModule
];

@NgModule({
  declarations: [],
  exports: [...CardModules],
  imports: [CommonModule, ...CardModules]
})
export class ComponentCardsModule {}
