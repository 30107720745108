import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class GuaranteeToStoreDays implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Guarantee to store days',
      description: 'The minimum number of days stores have to sell the product before the code date.',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      name: 'guaranteeToStoreDaysId',
      numberCount: 7,
      textInputType: TextInputType.integer,
      inputGroupClass: 'ui-narrow-input',
      placeholderText: '# of days',
      isHidden: () => false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.guaranteeToStoreDays;
    componentInstance.modelChange.subscribe(x => {
      candidate.guaranteeToStoreDays = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.guaranteeToStoreDays) {
      componentInstance.model = candidate.guaranteeToStoreDays;
    }
  }
}
