export class TaskDecision {

  // key vendor data decisions
  public static KEY_VENDOR_DATA_DECISION_NO = new TaskDecision('vendor_stop', 'no');
  public static KEY_VENDOR_DATA_DECISION_YES = new TaskDecision('vendor_stop', 'yes');
  public static KEY_VENDOR_DATA_DECISION_REJECT = new TaskDecision('vendor_stop', 'reject');

  // key buyer data decisions
  public static KEY_BUYER_DATA_REJECT_DECISION = new TaskDecision('approve_vendor_details', 'reject');
  public static KEY_BUYER_DATA_SEND_BACK_DECISION = new TaskDecision('approve_vendor_details', 'no');
  public static KEY_BUYER_DATA_APPROVE_DECISION = new TaskDecision('approve_vendor_details', 'yes');

  // buyer final review
  public static BUYER_FINAL_REVIEW_SEND_TO_SCA_DECISION = new TaskDecision('approve_final_review', 'returnToSCA');
  public static BUYER_FINAL_REVIEW_SEND_TO_VENDOR_DECISION = new TaskDecision('approve_final_review', 'returnToVendor');
  public static BUYER_FINAL_REVIEW_APPROVE_DECISION = new TaskDecision('approve_final_review', 'approve');
  public static BUYER_FINAL_REVIEW_REJECT_DECISION = new TaskDecision('approve_final_review', 'reject');

  // PIA final review
  public static PIA_FINAL_REVIEW_SEND_TO_SCA_DECISION = new TaskDecision('approve_final_review', 'returnToSCA');
  public static PIA_FINAL_REVIEW_SEND_TO_BDM_DECISION = new TaskDecision('approve_final_review', 'returnToBDM');
  public static PIA_FINAL_REVIEW_SEND_TO_VENDOR_DECISION = new TaskDecision('approve_final_review', 'returnToVendor');
  public static PIA_FINAL_REVIEW_APPROVE_DECISION = new TaskDecision('approve_final_review', 'approve');
  public static PIA_FINAL_REVIEW_REJECT_DECISION = new TaskDecision('approve_final_review', 'reject');

  // PIA NEW PRODUCT FLOW DECISIONS
  // public static PIA_DATA_DECISION_NO = new TaskDecision('pia_stop', 'no');
  // public static PIA_DATA_DECISION_YES = new TaskDecision('pia_stop', 'yes');

  // PIA OB REG FLOW
  public static PIA_SCALE_PLU_APPROVE_NO = new TaskDecision('pia_submit', 'no');
  public static PIA_SCALE_PLU_APPROVE_YES = new TaskDecision('pia_submit', 'yes');
  public static OB_REG_SCALE_PLU_REJECT_DECISION = new TaskDecision('approve_scale_plu', 'reject');
  public static OB_REG_SCALE_PLU_APPROVE_DECISION = new TaskDecision('approve_scale_plu', 'yes');
  public static PIA_REVIEW_SCALE_PLU_REJECT = new TaskDecision('approve_final_review', 'reject');
  public static PIA_REVIEW_SCALE_PLU_APPROVE_DECISION = new TaskDecision('approve_final_review', 'approve');

  // Pharm flow
  public static PHARM_FINAL_REVIEW_APPROVE_DECISION = new TaskDecision('approve_final_review', 'approve');
  public static PHARM_FINAL_REVIEW_REJECT_DECISION = new TaskDecision('approve_final_review', 'reject');

  // Invited Distributor flow
  public static INVITED_DISTRIBUTOR_VENDOR_REJECT = new TaskDecision('vendor_stop', 'reject');
  public static INVITED_DISTRIBUTOR_VENDOR_CONTINUE = new TaskDecision('vendor_stop', 'continue');
  public static INVITED_DISTRIBUTOR_VENDOR_WAITING = new TaskDecision('vendor_stop', 'waiting');

  public static DISTRIBUTOR_WAITING_APPROVE = new TaskDecision('is_waiting_stop', 'approve');
  public static DISTRIBUTOR_WAITING_REJECT = new TaskDecision('is_waiting_stop', 'reject');
  public static DISTRIBUTOR_WAITING_CONTINUE_TO_BUYER = new TaskDecision('is_waiting_stop', 'continueToBuyer');
  public static DISTRIBUTOR_WAITING_CONTINUE_TO_PIA = new TaskDecision('is_waiting_stop', 'continueToPia');

  public static INVITED_DISTRIBUTOR_BUYER_REJECT_DECISION = new TaskDecision('approve_vendor_details', 'reject');
  public static INVITED_DISTRIBUTOR_BUYER_APPROVE_DECISION = new TaskDecision('approve_vendor_details', 'yes');

  public static INVITED_DISTRIBUTOR_PIA_REJECT_DECISION = new TaskDecision('approve_final_review', 'reject');
  public static INVITED_DISTRIBUTOR_PIA_APPROVE_DECISION = new TaskDecision('approve_final_review', 'approve');

  name: string;
  value: string;

  public constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}
