<pm-editor *ngIf="candidate && isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pharm-new-product-flow-stepper *ngIf="candidate && !pharmProductService.isDsdOnly()"
                                      [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                      [stepIndex]="5"
                                      [isSellable]="candidateUtilService.isSellable(candidate)">
    </app-pharm-new-product-flow-stepper>

    <app-pharm-new-dsd-product-flow-stepper *ngIf="candidate && pharmProductService.isDsdOnly()"
                                          [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                          [stepIndex]="5"
                                          [isSellable]="candidateUtilService.isSellable(candidate)">
    </app-pharm-new-dsd-product-flow-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Select warehouses</pm-title>

  <ng-container *ngIf="candidate">
    <pm-max-ship-card [(candidateModel)]="candidate"
                      [permissions]="maxShipCardPermissions"
                      [candidateErrorModel]="candidateError">
    </pm-max-ship-card>

    <div class="mt-3">
      <h2>Warehouses</h2>
      <h4 class="sub-title">Configure one or more of the warehouses below for this product.</h4>
      <hr>
      <ng-template ngFor let-warehouse [ngForOf]="warehouseData" let-index="index">
        <div class="attribute-group">
          <div class="ml-2">
            <p-checkbox id="whsCheckbox"
                        [(ngModel)]="warehouse.checked"
                        (onChange)="selectedWarehouseChange($event, warehouse)"
                        binary="true">
            </p-checkbox>
          </div>
          <div>
            <div class="attribute-label">
              <label>
                {{warehouse.name}}
              </label>
            </div>
            <p class="attribute-description">
              ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
            </p>
            <p *ngIf="warehouse.checked && candidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
               class="error-message bicep-error">
              {{candidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
            </p>
          </div>
        </div>
        <div *ngIf="warehouse.checked">
          <div class="attributes-stacked">
            <div class="row warehouse-details-row">
              <div class="col-md-4">
                <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                                [(model)]="warehouse.orderRestriction"
                                                (selection)="orderRestrictionChange($event, warehouse)"
                                                [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
                </pm-attribute-display-typeahead>
              </div>
              <div class="col-md-4">
                <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                            [(model)]="warehouse.orderUnitId"
                                            (change)="orderUnitChange($event, warehouse)"
                                            [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
                </pm-attribute-display-radio>
              </div>
            </div>
          </div>
        </div>

        <hr>
      </ng-template>
    </div>
  </ng-container>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Activate" (onClick)="onClickSubmit()" [disabled]="!canClickSubmit || !this.isSelectedWarehouse()"></p-button>
  </pm-footer>
</pm-editor>
