export class Store {

  custLocationNumber: number;

  locationName: string;

  listCost: number;

  checked?: boolean;

  isHidden: boolean;

  overrideActivatedStore: Boolean;

  overriddenApNumber: number;

  overriddenVendorDisplayName: string;
}
