<pm-header [logoRef]="logoRef" class="pam-header">

  <pm-header-section align="start">
    <pm-header-title class="header-title" (click)="headerClick()">Product Attribute Management</pm-header-title>
  </pm-header-section>
  <pm-header-section align="end">
    <ul class="inline-list" *ngIf="authService.isAuthenticated() && authService.getCurrentRole()">

      <!-- Search -->
      <a class="ui-menuitem" *ngxPermissionsOnly="['ROLE_SEARCH_PRODUCTS-EDIT']">
        <a [routerLink]="menuModel[0].routerLink" [attr.id]="menuModel[0].id" class="ui-menuitem-link ui-corner-all">

          <span class="ui-menuitem-icon">
            <i class="material-icons" style="vertical-align: middle; margin-bottom: 0.25rem;">
              {{'search'}}
            </i>
          </span>
          <span class="ui-menuitem-text">{{menuModel[0].label}}</span>
        </a>
        <a> | </a>
      </a>

      <!-- Account settings for buyer or sca -->
      <a class="ui-menuitem" *ngIf="authService.isPia()">
        <a [routerLink]="menuModel[1].routerLink" [attr.id]="menuModel[1].id" class="ui-menuitem-link ui-corner-all">{{menuModel[1].label}}</a>
        <a> | </a>
      </a>

      <!-- Account settings for buyer or sca -->
      <a class="ui-menuitem" *ngIf="authService.isBuyer() || authService.isSca()">
        <a [routerLink]="menuModel[2].routerLink" [attr.id]="menuModel[2].id" class="ui-menuitem-link ui-corner-all">{{menuModel[2].label}}</a>
        <a> | </a>
      </a>

      <!-- Account settings for vendor -->
      <li class="ui-menuitem" *ngIf="authService.isVendor()">
        <a [routerLink]="menuModel[3].routerLink" [attr.id]="menuModel[3].id" class="ui-menuitem-link ui-corner-all">{{menuModel[3].label}}</a>
        <a> | </a>
      </li>

      <!-- Reserved UPCs -->
      <li class="ui-menuitem" *ngIf="authService.isVendor() || authService.isPia()">
        <a [routerLink]="menuModel[4].routerLink" [attr.id]="menuModel[4].id" class="ui-menuitem-link ui-corner-all">{{menuModel[4].label}}</a>
        <a> | </a>
      </li>

      <!-- Log Out -->
      <li class="ui-menuitem" *ngIf="!appConfig.isSsoEnabled()">
        <a [routerLink]="menuModel[5].routerLink" [attr.id]="menuModel[5].id" class="ui-menuitem-link ui-corner-all">{{menuModel[5].label}}</a>
        <a> | </a>
      </li>

      <!-- Get help -->
      <li class="ui-menuitem">
        <a [routerLink]="menuModel[6].routerLink" [attr.id]="menuModel[6].id" class="ui-menuitem-link ui-corner-all">{{menuModel[6].label}}</a>
        <i class="pi pi-question-circle"></i>
      </li>

    </ul>
  </pm-header-section>
</pm-header>


<ng-template #logoRef>
  <svg class="mtr-header__logo" role="img" aria-label="H-E-B Logo" viewBox="0 0 66 69" version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 59.1 (86144) - https://sketch.com -->
    <title>Artboard</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <polygon id="path-1" points="0.14078996 0.7595429 57.7525389 0.7595429 57.7525389 20.9803559 0.14078996 20.9803559"></polygon>
    </defs>
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <ellipse id="Oval" stroke="#FFFFFF" fill="#FFFFFF" cx="33" cy="35" rx="32" ry="33"></ellipse>
      <g id="Group-2" transform="translate(2.000000, 3.000000)">
        <g>
          <path d="M60.9348452,39.9843004 C61.5605523,37.4556473 61.8936402,34.8063086 61.8936402,32.0767739 C61.8936402,14.4569208 48.0383264,0.173165714 30.9468201,0.173165714 C13.8553138,0.173165714 0,14.4569208 0,32.0767739 C0,34.8063086 0.333087866,37.4556473 0.958794979,39.9843004 L60.9348452,39.9843004 Z" id="Fill-26-Copy" fill="#0066CC" fill-rule="nonzero"></path>
          <g id="Fill-28-Clipped" transform="translate(2.000000, 43.000000)">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1"></use>
            </mask>
            <g id="path-1"></g>
            <path d="M0.14078996,0.7595429 C4.66289874,12.6003265 15.8555849,20.9804082 28.9466644,20.9804082 C42.0380033,20.9804082 53.2306895,12.6003265 57.7525389,0.7595429 L0.14078996,0.7595429 Z" id="Fill-28" fill="#E32826" fill-rule="nonzero" mask="url(#mask-2)"></path>
          </g>
        </g>
        <g id="Group-3" transform="translate(13.000000, 13.000000)" fill="#FFFFFF" fill-rule="nonzero">
          <rect id="Rectangle" x="3" y="3" width="1" height="17"></rect>
          <rect id="Rectangle-Copy-9" x="13" y="3" width="1" height="17"></rect>
          <rect id="Rectangle-Copy-2" x="5" y="3" width="2" height="17"></rect>
          <rect id="Rectangle-Copy-10" x="15" y="3" width="2" height="17"></rect>
          <rect id="Rectangle-Copy-3" x="9" y="3" width="1" height="17"></rect>
          <rect id="Rectangle-Copy-11" x="18" y="3" width="1" height="17"></rect>
          <rect id="Rectangle-Copy-4" x="11" y="3" width="1" height="17"></rect>
          <rect id="Rectangle-Copy-5" x="30" y="3" width="3" height="17"></rect>
          <rect id="Rectangle-Copy-6" x="27" y="3" width="2" height="17"></rect>
          <rect id="Rectangle-Copy-7" x="23" y="3" width="1" height="17"></rect>
          <rect id="Rectangle-Copy-12" x="25" y="3" width="1" height="17"></rect>
          <rect id="Rectangle-Copy-8" x="20" y="3" width="2" height="17"></rect>
          <g id="Group">
            <rect id="Rectangle" x="0" y="0" width="2" height="6"></rect>
            <rect id="Rectangle-Copy" transform="translate(3.000000, 1.000000) rotate(90.000000) translate(-3.000000, -1.000000) " x="2" y="-2" width="2" height="6"></rect>
          </g>
          <g id="Group-Copy-2" transform="translate(3.000000, 20.000000) scale(-1, 1) rotate(-180.000000) translate(-3.000000, -20.000000) translate(0.000000, 17.000000)">
            <rect id="Rectangle" x="0" y="0" width="2" height="6"></rect>
            <rect id="Rectangle-Copy" transform="translate(3.000000, 1.000000) rotate(90.000000) translate(-3.000000, -1.000000) " x="2" y="-2" width="2" height="6"></rect>
          </g>
          <g id="Group-Copy" transform="translate(33.000000, 3.000000) scale(-1, 1) translate(-33.000000, -3.000000) translate(30.000000, 0.000000)">
            <rect id="Rectangle" x="0" y="0" width="2" height="6"></rect>
            <rect id="Rectangle-Copy" transform="translate(3.000000, 1.000000) rotate(90.000000) translate(-3.000000, -1.000000) " x="2" y="-2" width="2" height="6"></rect>
          </g>
          <g id="Group-Copy-3" transform="translate(33.000000, 20.000000) rotate(180.000000) translate(-33.000000, -20.000000) translate(30.000000, 17.000000)">
            <rect id="Rectangle" x="0" y="0" width="2" height="6"></rect>
            <rect id="Rectangle-Copy" transform="translate(3.000000, 1.000000) rotate(90.000000) translate(-3.000000, -1.000000) " x="2" y="-2" width="2" height="6"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>

</ng-template>
