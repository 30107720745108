import {ExistingInnerError} from 'pm-models/lib/existingInnerError';
import {CandidateInnerError} from 'pm-models/lib/candidateInnerError';

export class MrtInfoError {

  existingInnerErrors: Map<number, ExistingInnerError> = new Map<number, ExistingInnerError>();
  candidateInnerErrors: Map<number, CandidateInnerError> = new Map<number, CandidateInnerError>();

  errors: string[] = [];

}
