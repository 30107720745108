import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AppConfigService} from '../service/app-config.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  window = window;

  username: string;
  password: string;
  dataLoading = false;
  returnTo: string;
  code: number;
  errorMessage: string;
  newUserPage = 'requestRole';
  tasksPage = 'tasks';
  codes = [
    { code: 201, message: 'You Have Successfully Logged Out'},
    { code: 400, message: 'Invalid credentials. Please try again.'},
    { code: 401, message: 'Your Session Has Expired'},
    { code: 403, message: 'You Do Not Have Sufficient Privileges To Access'}
  ];

  constructor(public auth: AuthService, private router: Router, public appConfig: AppConfigService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.auth.successMessage$.pipe(
      take(1),
    ).subscribe(result => {
      this.loginSuccess(result);
    });
    this.auth.errorMessage$.subscribe(error => {
      this.loginFailure(error);
    });
    // sets route return to
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.returnTo = this.activatedRoute.snapshot.queryParams['returnTo'] || '/';
      this.code = params['code'];
    });
    this.auth.isNotAuthenticatedLogout();
  }

  /**
   * Login handler.
   */
  login() {
    this.errorMessage = null;
    this.dataLoading = true;
    this.auth.login(this.username, this.password);
  }

  /**
   * Login handler success.
   *
   * @param user
   */
  loginSuccess(user) {
    this.dataLoading = false;
    this.username = undefined;
    this.password = undefined;
    this.auth.initializeCurrentRole().subscribe(() => {
      if (this.auth.isUser()) {
        this.router.navigateByUrl(this.returnTo);
      } else {
        this.router.navigateByUrl(this.newUserPage);
      }
    });
  }


  /**
   * Login hanlder fail.
   *
   * @param error
   */
  loginFailure(error) {
    this.dataLoading = false;
    for (let index = 0; index < this.codes.length; index++) {
      if (this.codes[index].code === error.status) {
        this.errorMessage = this.codes[index].message;
      }
    }
    if (!this.errorMessage) {
      this.errorMessage = error.message;
    }
  }

  getEncodedURL() {
    return encodeURIComponent('/#'.concat(this.returnTo));
  }
}
