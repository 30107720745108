import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {PreferencesService} from '../service/preferences.service';

@Component({
  selector: 'app-unsubscribe-notifications',
  templateUrl: './unsubscribe-notifications.component.html',
  styleUrls: ['./unsubscribe-notifications.component.scss']
})
export class UnsubscribeNotificationsComponent implements OnInit {

  public isViewingPage = true;

  constructor(private preferencesService: PreferencesService, private router: Router) { }

  ngOnInit() {
    this.preferencesService.unsubscribe().subscribe();
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']);
  }

}
