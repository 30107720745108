/**
 * Holds constants for the Upload Candidate Supplier and HEB tab.
 *
 * @author s573181
 * @since 1.50.0
 */
export class UploadCandidateSupplierAndHebConstants {

  // UPC Columns
  public static readonly EMPTY_COLUMN = {header: ' ', width: '50px', sticky: true};
  public static readonly UNIT_UPC = {header: 'Unit UPC', width: '150px', sticky: true};


  // HEB DETAILS COLUMNS
  public static readonly CHANNEL = {header: 'Channel', width: '100px'};
  public static readonly SELLABLE = {header: 'Sellable', width: '100px'};
  public static readonly BUYER = {header: 'Buyer', width: '200px'};

  public static readonly HEB_DETAILS_COLUMN_GROUP =
    [UploadCandidateSupplierAndHebConstants.CHANNEL, UploadCandidateSupplierAndHebConstants.SELLABLE,
      UploadCandidateSupplierAndHebConstants.BUYER];

  // SUPPLIER DETAILS COLUMNS
  public static readonly CONTACT = {header: 'Contact', width: '200px'};
  public static readonly BRAND = {header: 'Brand', width: '175px'};
  public static readonly SUB_BRAND = {header: 'Sub-brand', width: '175px'};
  public static readonly COST_OWNER = {header: 'Cost owner', width: '250px'};
  public static readonly TOP_TO_TOP = {header: 'Top to top', width: '250px'};
  public static readonly SUPPLIER = {header: 'Supplier (AP)', width: '250px'};
  public static readonly WAREHOUSE_SUPPLIER = {header: 'Warehouse supplier', width: '400px'}; // todo?

  public static readonly SUPPLIER_DETAILS_COLUMN_GROUP =
    [UploadCandidateSupplierAndHebConstants.CONTACT, UploadCandidateSupplierAndHebConstants.BRAND,
      UploadCandidateSupplierAndHebConstants.SUB_BRAND, UploadCandidateSupplierAndHebConstants.COST_OWNER,
      UploadCandidateSupplierAndHebConstants.TOP_TO_TOP, UploadCandidateSupplierAndHebConstants.SUPPLIER,
      UploadCandidateSupplierAndHebConstants.WAREHOUSE_SUPPLIER];


  // All the Case columns for New Warehouse Product
  public static readonly NEW_WAREHOUSE_PRODUCT_SUPPLIER_AND_HEB_TAB_COLUMNS = [
    UploadCandidateSupplierAndHebConstants.EMPTY_COLUMN,
    UploadCandidateSupplierAndHebConstants.UNIT_UPC, UploadCandidateSupplierAndHebConstants.CHANNEL,
    UploadCandidateSupplierAndHebConstants.SELLABLE, UploadCandidateSupplierAndHebConstants.BUYER,
    UploadCandidateSupplierAndHebConstants.CONTACT, UploadCandidateSupplierAndHebConstants.BRAND,
    UploadCandidateSupplierAndHebConstants.SUB_BRAND, UploadCandidateSupplierAndHebConstants.COST_OWNER,
    UploadCandidateSupplierAndHebConstants.TOP_TO_TOP, UploadCandidateSupplierAndHebConstants.SUPPLIER,
    UploadCandidateSupplierAndHebConstants.WAREHOUSE_SUPPLIER
  ];
  // All the Case columns for New Warehouse Product
  public static readonly UPLOADED_NEW_WAREHOUSE_PRODUCT_SUPPLIER_AND_HEB_TAB_COLUMNS = [
    UploadCandidateSupplierAndHebConstants.EMPTY_COLUMN,
    UploadCandidateSupplierAndHebConstants.UNIT_UPC, UploadCandidateSupplierAndHebConstants.CHANNEL,
    UploadCandidateSupplierAndHebConstants.SELLABLE, UploadCandidateSupplierAndHebConstants.BUYER,
    UploadCandidateSupplierAndHebConstants.CONTACT, UploadCandidateSupplierAndHebConstants.BRAND,
    UploadCandidateSupplierAndHebConstants.SUB_BRAND, UploadCandidateSupplierAndHebConstants.COST_OWNER,
    UploadCandidateSupplierAndHebConstants.TOP_TO_TOP, UploadCandidateSupplierAndHebConstants.SUPPLIER
  ];

  // All the Case columns for New DSD Product
  public static readonly NEW_DSD_PRODUCT_SUPPLIER_AND_HEB_TAB_COLUMNS = [
    UploadCandidateSupplierAndHebConstants.EMPTY_COLUMN,
    UploadCandidateSupplierAndHebConstants.UNIT_UPC, UploadCandidateSupplierAndHebConstants.CHANNEL,
    UploadCandidateSupplierAndHebConstants.SELLABLE, UploadCandidateSupplierAndHebConstants.BUYER,
    UploadCandidateSupplierAndHebConstants.CONTACT, UploadCandidateSupplierAndHebConstants.BRAND,
    UploadCandidateSupplierAndHebConstants.SUB_BRAND, UploadCandidateSupplierAndHebConstants.COST_OWNER,
    UploadCandidateSupplierAndHebConstants.TOP_TO_TOP, UploadCandidateSupplierAndHebConstants.SUPPLIER
  ];

  // All the Case columns for Buyer New Product
  public static readonly BUYER_REVIEW_SUPPLIER_AND_HEB_TAB_COLUMNS = [
    UploadCandidateSupplierAndHebConstants.EMPTY_COLUMN,
    UploadCandidateSupplierAndHebConstants.UNIT_UPC, UploadCandidateSupplierAndHebConstants.CHANNEL,
    UploadCandidateSupplierAndHebConstants.SELLABLE, UploadCandidateSupplierAndHebConstants.BUYER,
    UploadCandidateSupplierAndHebConstants.CONTACT, UploadCandidateSupplierAndHebConstants.BRAND,
    UploadCandidateSupplierAndHebConstants.SUB_BRAND, UploadCandidateSupplierAndHebConstants.COST_OWNER,
    UploadCandidateSupplierAndHebConstants.TOP_TO_TOP, UploadCandidateSupplierAndHebConstants.SUPPLIER,
    UploadCandidateSupplierAndHebConstants.WAREHOUSE_SUPPLIER
  ];
  // All the Case columns for SCA New Product
  public static readonly SCA_REVIEW_SUPPLIER_AND_HEB_TAB_COLUMNS = [
    UploadCandidateSupplierAndHebConstants.EMPTY_COLUMN,
    UploadCandidateSupplierAndHebConstants.UNIT_UPC, UploadCandidateSupplierAndHebConstants.CHANNEL,
    UploadCandidateSupplierAndHebConstants.SELLABLE, UploadCandidateSupplierAndHebConstants.BUYER,
    UploadCandidateSupplierAndHebConstants.CONTACT, UploadCandidateSupplierAndHebConstants.BRAND,
    UploadCandidateSupplierAndHebConstants.SUB_BRAND, UploadCandidateSupplierAndHebConstants.COST_OWNER,
    UploadCandidateSupplierAndHebConstants.TOP_TO_TOP, UploadCandidateSupplierAndHebConstants.SUPPLIER,
    UploadCandidateSupplierAndHebConstants.WAREHOUSE_SUPPLIER
  ];
  // All the Case columns for PIA New Product
  public static readonly PIA_REVIEW_SUPPLIER_AND_HEB_TAB_COLUMNS = [
    UploadCandidateSupplierAndHebConstants.EMPTY_COLUMN,
    UploadCandidateSupplierAndHebConstants.UNIT_UPC, UploadCandidateSupplierAndHebConstants.CHANNEL,
    UploadCandidateSupplierAndHebConstants.SELLABLE, UploadCandidateSupplierAndHebConstants.BUYER,
    UploadCandidateSupplierAndHebConstants.CONTACT, UploadCandidateSupplierAndHebConstants.BRAND,
    UploadCandidateSupplierAndHebConstants.SUB_BRAND, UploadCandidateSupplierAndHebConstants.COST_OWNER,
    UploadCandidateSupplierAndHebConstants.TOP_TO_TOP, UploadCandidateSupplierAndHebConstants.SUPPLIER,
    UploadCandidateSupplierAndHebConstants.WAREHOUSE_SUPPLIER
  ];
}
