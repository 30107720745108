import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BaseAttributeDisplayComponent} from '../base-attribute-display/base-attribute-display.component';
import {UpcService} from '../../services/upc-service/upc.service';
import {AttributeUPCConfig, CandidateProduct, CandidateProductError} from 'pm-models';
import {UUID} from 'angular2-uuid';

export enum InputState {
  none,
  loading,
  valid,
  invalid
}
@Component({
  selector: 'pm-attribute-display-associate-upc',
  templateUrl: './attribute-display-associate-upc.component.html',
  styleUrls: ['./attribute-display-associate-upc.component.scss']
})
export class AttributeDisplayAssociateUpcComponent extends BaseAttributeDisplayComponent implements OnInit {

  @Output()
  shiftEnterEmitted?: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  attribute: AttributeUPCConfig;

  checkDigitValue;
  @Input()
  private productCaseUpc;
  private productItemCode;
  get checkDigitModel() {
    return this.checkDigitValue;
  }
  set checkDigitModel(val) {
    this.checkDigitValue = val;
    this.checkDigitModelChange.emit(this.checkDigitValue);
  }

  @Input()
  state: InputState;

  @Output() checkDigitModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateUpc?: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateAllUpcs?: EventEmitter<any> = new EventEmitter<any>();
  isList: boolean = false;

  initialUnitUPCConfiguration: AttributeUPCConfig = {
    label: 'Unit UPC',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: 'Unit UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'associateUpcId'
  };

  genericUnitUPCConfiguration: AttributeUPCConfig = {
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: 'Unit UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'associateUpcId'
  };

  finalUnitUPCConfiguration: AttributeUPCConfig = {
    description: `Enter the UPC and check digit that appears on this unit's packaging.`,
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: 'Unit UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'associateUpcId'
  };

  constructor() {
    super();
  }

  upcChange(e, product) {
    e.upc = product;
    this.modelChange.emit(e);
    this.validateUpc.emit(e);
  }

  validate(product) {
    if (!this.hasError(product) && product.upc && product.checkDigit) {
      this.validateAllUpcs.emit();
    } else {
      this.validateUpc.emit(product);
      if (!this.hasError(product)) {
        this.validateAllUpcs.emit();
      }
    }
  }

  ngOnInit() {
    this.attributeError = new CandidateProductError();
    this.checkItems();
    this.setCaseUpc();
  }


  integerPattern(event) {
    const pattern = /^\d+$/;
    return pattern.test(event.key);
  }

  addAssociateUpc() {
    const tempCandidateProduct = new CandidateProduct();
    tempCandidateProduct.id = UUID.UUID();
    tempCandidateProduct.candidateProductType = CandidateProduct.ASSOCIATE_UPC;
    tempCandidateProduct.caseUpc = this.productCaseUpc;
    tempCandidateProduct.itemCode = this.productItemCode;
    this.model.push((JSON.parse(JSON.stringify(tempCandidateProduct))));
    this.checkItems();
  }

  deleteExistingAssociate(product: CandidateProduct) {
    this.model.splice(this.model.indexOf(product), 1);
    this.checkItems();
  }

  private checkItems() {
    if (this.model) {
      this.isList = (this.model.length > 2);
    } else {
      this.isList = false;
    }
    for (const product of this.model) {
      if (!product.upc) {
        this.validate(product);
      }
    }
  }

  getCandidateProductError(product: CandidateProduct) {
    if (this.attributeError && this.attributeError[product.id]) {
      if (this.attributeError[product.id].upc) {
        return this.attributeError[product.id].upc;
      } else if (this.attributeError[product.id].upcCheckDigit) {
        return this.attributeError[product.id].upcCheckDigit;
      }
    }
    return false;
  }

  getInputState(product) {
    if (this.state === InputState.loading) {
      return InputState.loading;
    } else if (this.getCandidateProductError(product)) {
      return InputState.invalid;
    } else if (!product.upc && !product.upcCheckDigit) {
      return InputState.none;
    } else {
      return InputState.valid;
    }
}

hasError(product: any) {
  return this.attributeError && (this.attributeError[product.id] !== undefined);
}

getConfig(product: CandidateProduct) {
  const candidateProductIndex = this.model.indexOf(product);
  let config;
  switch (candidateProductIndex) {
    case 1: config = this.initialUnitUPCConfiguration;
      break;
    case this.model.length - 1: config = this.finalUnitUPCConfiguration;
      break;
    default: config = this.genericUnitUPCConfiguration;
      break;
  }
  return config;
}

setCaseUpc() {
  const candidateProducts = this.model.slice(1);
  for (const product of candidateProducts) {
    if (product.caseUpc || product.itemCode) {
      this.productCaseUpc = product.caseUpc;
      this.productItemCode = product.itemCode;
      return;
    }
  }
}
}
