import {CandidateError} from 'pm-models/lib/candidateError';

export class CandidateErrorModel {
  candidateId: number;
  upc?: number;
  rowNumber: number;
  displayNameToErrorMap: Map<string, string> = new Map<string, string>();
  validationErrors: string[];
  isAllCandidatesError?: boolean;
  candidateError?: CandidateError;
}
