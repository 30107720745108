import {DsdItem, Item} from 'pm-models';

export class Upc {
  scanCodeId?: number;
  retailLink?: number;
  size?: string;
  retailPrice?: number;
  xfor?: number;
  weightSw?: boolean;
  unitOfMeasure: any;
  subBrand: any;
  length: number;
  width: number;
  height: number;
  weight: number;
  item?: Item;
  sellingSizeOne: number;
  dsdItem?: DsdItem;
}
