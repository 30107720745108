import { AttributePermission, BaseCardPermissions } from 'pm-models';

export function ReadOnlyPermissionsMapper(base: BaseCardPermissions, attr: AttributePermission) {
  return mapper(base, attr, 'isReadOnly', false);
}

export function DisabledPermissionsMapper(base: BaseCardPermissions, attr: AttributePermission) {
  return mapper(base, attr, 'isDisabled', false);
}
export function VisiblePermissionsMapper(base: BaseCardPermissions, attr: AttributePermission) {
  return mapper(base, attr, 'isHidden', false);
}



function mapper(base: BaseCardPermissions, attr: AttributePermission, type: string, defaultValue: boolean) {
  let isPropActive = defaultValue;

  if (base) {

    if (attr && attr[type]) {
      isPropActive = attr[type];
    }

    if (base[type] && base[type] !== defaultValue) {
      isPropActive = !defaultValue;
    }

  }

  return isPropActive;
}
