import {Component, OnInit} from '@angular/core';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate, CandidateError,
  CandidateProduct, CandidateProductError,
  CandidateValidatorType,
  MaxShipCardPermissions,
  TaskDecision,
  TextInputType,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {CandidateService} from '../../service/candidate.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {PiaBonusStepperComponent} from '../pia-bonus-stepper/pia-bonus-stepper.component';

@Component({
  selector: 'app-pia-bonus-warehouse',
  templateUrl: './pia-bonus-warehouse.component.html',
  styleUrls: ['./pia-bonus-warehouse.component.scss']
})
export class PiaBonusWarehouseComponent implements OnInit {

  public isViewingPage = true;
  private taskSubscription$: any;
  public candidate: Candidate;
  public currentCandidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  // any errors for this page will be here
  public warehouses = [];
  public warehouseData = [];
  public canNavigate = true;
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public orderRestrictions: any[];

  private validators: CandidateValidatorType[] = [CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR,
    CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_VALIDATOR,
    CandidateValidatorType.WAREHOUSE_VALIDATOR, CandidateValidatorType.BICEP_VALIDATOR];

  readonly maxShipCardPermissions: MaxShipCardPermissions = {
    isReadOnly: false
  };

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => true,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };


  constructor(private route: ActivatedRoute, private router: Router, public bonusSizeService: BonusSizeService,
              private authService: AuthService, private candidateService: CandidateService,
              private candidateUtilService: CandidateUtilService, private growlService: GrowlService,
              private lookupService: LookupService, private workflowService: WorkflowService) { }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId, this.bonusSizeService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.BONUS_SIZE || candidate.candidateType === Candidate.REPLACEMENT_UPC) {
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.bonusSizeService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.candidate = this.bonusSizeService.getCandidate();
    this.originalCandidate = this.bonusSizeService.getOriginalCandidate();
    this.currentCandidateProduct = this.bonusSizeService.getCurrentCandidateProduct();
    this.candidateError = this.bonusSizeService.getWarehouseError();
    this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
    this.getWarehouses(this.candidate);
    this.getOrderRestrictions();
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    if (this.currentCandidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.currentCandidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.currentCandidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({},
              this.currentCandidateProduct.warehouses[y], JSON.parse(JSON.stringify(this.currentCandidateProduct.warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  onClose() {
    this.currentCandidateProduct.warehouses = this.getSelectedWarehouses();
    this.isViewingPage = false;
    this.bonusSizeService.saveCandidateAndClose();
  }

  onClickSubmit() {
    this.canNavigate = false;
    this.currentCandidateProduct.warehouses = this.getSelectedWarehouses();
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.candidate, this.validators).toPromise().then(data => {
      this.candidateService.activateCandidate(this.candidate).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE,
          TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
          'Successfully activated candidate.'
        );
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
      });
    }, (error) => {
      this.bonusSizeService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.bonusSizeService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.bonusSizeService.getWarehouseError();
        this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canNavigate = true;
    });
  }

  onClickBack() {
    this.currentCandidateProduct.warehouses = this.getSelectedWarehouses();
    this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaCaseDetailsUrl(), false);
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(this.candidate.vendor.apNumber, this.bonusSizeService.getTask(), action,
      taskDecision).subscribe(() => {
      this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( data => {
        this.canNavigate = true;
      });
    }, (error) => {
      this.growlService.addError(error);
      this.canNavigate = true;
    });
  }


  onClickNext() {
    this.canNavigate = false;
    this.currentCandidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidateService.validateCandidate(this.candidate, this.validators).subscribe(() => {
      this.bonusSizeService.warehouseError = new CandidateError();
      this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaExtendedAttributesUrl(), false);
    }, (error) => {
      this.bonusSizeService.scrollToTop();

      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.bonusSizeService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.bonusSizeService.getWarehouseError();
        this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canNavigate = true;
    });
  }


  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    this.currentCandidateProduct.warehouses = this.getSelectedWarehouses();
    switch (stepperItem.text) {
      case PiaBonusStepperComponent.UPC_ITEM_CODE:
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaSetupUpcUrl(), false);
        break;
      case PiaBonusStepperComponent.BONUS_DETAILS:
      case PiaBonusStepperComponent.REPLACEMENT_DETAILS:
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaUpcDetailsUrl(), false);
        break;
      case PiaBonusStepperComponent.CASE_DETAILS:
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaCaseDetailsUrl(), false);
        break;
      case PiaBonusStepperComponent.EXTENDED_ATTRIBUTES:
        this.onClickNext();
        break;
    }
  }
}
