import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BuyerDetailsCardComponent} from './buyer-details-card.component';
import {CardModule} from '../../shared/card/card.module';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';



@NgModule({
  declarations: [BuyerDetailsCardComponent],
  exports: [BuyerDetailsCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule,
    CardModule,
    AttributeDisplayModule
  ]
})
export class BuyerDetailsCardModule { }
