import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AttributeTypeaheadConfig, Candidate, Task } from 'pm-models';
import { AuthService } from '../auth/auth.service';
import { TaskListService } from '../task-list/task-list-service/task-list.service';
import { TaskListTask } from '../task-list/task-list-service/taskListTask';
import { WorkflowService } from '../service/workflow.service';
import { CandidateService } from '../service/candidate.service';
import { GrowlService } from '../growl/growl.service';
import { UpcService } from 'pm-components';
import { InvitedDistributorService } from '../service/invited-distributor.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CandidateUtilService } from '../service/candidate-util.service';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit {
  searchFocusMessage$ = new BehaviorSubject(null);
  public isViewingPage = true;
  public isShowingSpinner = false;
  public hasSearchResults = false;

  public searchOptions = [{ label: 'UPC', value: 'UPC' }, { label: 'Candidate ID', value: 'CANDIDATE_ID'},
    { label: 'Buyer', value: 'BUYER' }, { label: 'Supplier', value: 'SUPPLIER' }];
  public UPC_SEARCH_TYPE = 'UPC';
  public CANDIDATE_ID_SEARCH_TYPE = 'CANDIDATE_ID';
  public BUYER_SEARCH_TYPE = 'BUYER';
  public SUPPLIER_SEARCH_TYPE = 'SUPPLIER';
  public searchType = this.UPC_SEARCH_TYPE;

  public searchResultsColumns = [
    {field: 'upc', header: 'Unit UPC', sortable: false},
    {field: 'description', header: 'Name', sortable: false},
    {field: 'type', header: 'Type', sortable: false},
    {field: 'buyerDisplayName', header: 'Buyer', sortable: false},
    {field: 'supplierDisplayName', header: 'Supplier', sortable: false},
    {field: 'status', header: 'Status', sortable: false},
    {field: 'lastUpdatedTime', header: 'Updated', sortable: false}];

  public searchResults = [];
  public allTasksCheckbox: boolean = false;
  public hasDifferentTaskTypes: boolean = false;
  searchModel: any;
  public isExportDisabled = false;
  public typeaheadStyle = {'border-top-right-radius' : '3px', 'border-bottom-right-radius' : '3px',
    'border-right' : '1px solid rgb(184, 184, 184)', 'border-top' : '1px solid rgb(184, 184, 184)',
    'border-bottom' : '1px solid rgb(184, 184, 184)'};

  buyerConfiguration: AttributeTypeaheadConfig = {
    label: 'Buyer',
    description: 'Choose an H-E-B buyer for this UPC.',
    isRequired: false,
    name: 'buyerTypeAhead',
    idRef: 'buyerId',
    displayRef: 'displayName',
    placeholderText: 'Type to search for a Buyer...',
    searchUrl: '/lookup/buyer',
  };

  supplierConfiguration: AttributeTypeaheadConfig = {
    label: 'Supplier',
    description: 'The supplier of record that H-E-B pays.',
    isRequired: false,
    name: 'vendorTypeAhead',
    idRef: 'vendorId',
    displayRef: 'displayName',
    placeholderText: 'Select or search for a Supplier...',
    searchUrl: '/lookup/vendor'
  };

  constructor(private router: Router, public taskListService: TaskListService, private auth: AuthService,
              public candidateService: CandidateService, private upcService: UpcService,
              private invitedDistributorService: InvitedDistributorService) { }

  ngOnInit() {
  }

  searchInputKeyUp(event: KeyboardEvent) {
    if (!this.isShowingSpinner && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
      this.search();
    }
  }

  search() {
    this.hasSearchResults = false;
    this.allTasksCheckbox = false;
    this.isShowingSpinner = true;
    if (this.searchModel) {
      switch (this.searchType) {
        case this.UPC_SEARCH_TYPE:
          this.getProductsByUpc();
          break;
        case this.CANDIDATE_ID_SEARCH_TYPE:
          this.getProductsByCandidateId();
          break;
        case this.BUYER_SEARCH_TYPE:
          this.allTasksCheckbox = false;
          this.getProductsByBuyer();
          break;
        case this.SUPPLIER_SEARCH_TYPE:
          this.allTasksCheckbox = false;
          this.getProductsBySupplier();
          break;
      }
    } else {
      this.hasSearchResults = true;
      this.isShowingSpinner = false;
      this.searchResults = [];
    }
  }

  getProductsByUpc() {
    this.taskListService.getCandidateSearchsResultsFor('UPC', this.searchModel).pipe(
      switchMap(result => {
      this.searchResults = result;
      return this.handleSearchResults();
    })).subscribe(() => {
      this.hasSearchResults = true;
      this.isShowingSpinner = false;
    });
  }

  handleSearchResults(): Observable<any> {
    if (!this.searchResults?.length) {
      return of({});
    }

    const mrtCandidateIdToSearchResultsMap = new Map<number, any>(
      this.searchResults.filter(result => result?.taskType === 'MRT')?.map(searchResult => {
        searchResult.isMrt = true;
        searchResult.mrtUpcs = [];
        return [searchResult.candidateId, searchResult];
      }));

    if (!mrtCandidateIdToSearchResultsMap?.size) {
      return of({});
    }

    const mrtCandidateIds = Array.from(mrtCandidateIdToSearchResultsMap.keys());
    return this.candidateService.getCandidatesByCandidateIds(mrtCandidateIds).pipe(
      switchMap((mrtCandidates: Candidate[]) => {
      if (!mrtCandidates?.length) {
        return of({});
      }
      const candidateInnerIds = mrtCandidates.filter(mrtCandidate => !!mrtCandidate?.mrtInfo?.candidateInners?.length)
        .map(mrtCandidate => mrtCandidate.mrtInfo.candidateInners.map(candidateInner => candidateInner.candidateId))
        .reduce((acc, cur) => [...acc, ...cur], []);

      if (candidateInnerIds?.length) {
        return this.candidateService.getCandidatesByCandidateIds(candidateInnerIds).pipe(
          switchMap((innerCandidates: Candidate[]) => {
          mrtCandidates.forEach(mrtCandidate => {
            if (mrtCandidate?.mrtInfo?.existingInners?.length) {
              const existingInnerUpcs = mrtCandidate.mrtInfo.existingInners.map(inner => inner.upc);
              mrtCandidateIdToSearchResultsMap.get(mrtCandidate.candidateId)?.mrtUpcs.push(...existingInnerUpcs);
            }
            if (mrtCandidate?.mrtInfo?.candidateInners?.length && innerCandidates?.length) {
              const mrtInnerIds = mrtCandidate.mrtInfo.candidateInners.map(inner => inner.candidateId);
              const candidateInnerUpcs = innerCandidates.filter(innerCandidate => mrtInnerIds.includes(innerCandidate.candidateId))
                .map(innerCandidate => innerCandidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(innerCandidate)]?.upc);
              mrtCandidateIdToSearchResultsMap.get(mrtCandidate.candidateId)?.mrtUpcs.push(...candidateInnerUpcs);
            }
          });
            return of({});
        }));
      } else {
        mrtCandidates.forEach(mrtCandidate => {
          if (mrtCandidate?.mrtInfo?.existingInners?.length) {
            const existingInnerUpcs = mrtCandidate.mrtInfo.existingInners.map(inner => inner.upc);
            mrtCandidateIdToSearchResultsMap.get(mrtCandidate?.candidateId)?.mrtUpcs.push(...existingInnerUpcs);
          }
        });
        return of({});
      }
    }));
  }

  getProductsByCandidateId() {
    this.taskListService.getCandidateSearchsResultsFor('CANDIDATE_ID', this.searchModel).pipe(
      switchMap(result => {
        this.searchResults = result;
        return this.handleSearchResults();
      })
    ).subscribe(() => {
      this.hasSearchResults = true;
      this.isShowingSpinner = false;
    });
  }

  handleTypeaheadChange($event) {
    this.searchModel = $event;
    this.search();
  }

  getProductsByBuyer() {
    this.taskListService.getCandidateSearchsResultsFor('BUYER', this.searchModel.buyerId).pipe(
      switchMap(result => {
        this.searchResults = result;
        return this.handleSearchResults();
      })
    ).subscribe(() => {
      this.hasSearchResults = true;
      this.isShowingSpinner = false;
    });
  }

  getProductsBySupplier() {
    this.taskListService.getCandidateSearchsResultsFor('SUPPLIER', this.searchModel.apNumber).pipe(
      switchMap(result => {
        this.searchResults = result;
        return this.handleSearchResults();
      })
    ).subscribe(() => {
      this.hasSearchResults = true;
      this.isShowingSpinner = false;
    });
  }

  onSearchOptionChange() {
    this.searchModel = null;
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']).then();
  }

  showUpcFocusMessage() {
    this.searchFocusMessage$.next('Hit&nbsp;<b>enter</b>&nbsp;to find candidate(s) containing this unit UPC.');
  }

  showCandidateIdFocusMessage() {
    this.searchFocusMessage$.next('Hit&nbsp;<b>enter</b>&nbsp;to find candidate(s) containing this candidate ID.');
  }

  hideFocusMessage() {
    this.searchFocusMessage$.next(null);
  }

  getDisplayName(task) {
    if (task.status === 'IN_PROGRESS') {
      if (this.isPiaDraftInProgressStateWorkflow(task) || this.isVendorDraftInProgressStateWorkflow(task) ||
        this.isPharmDraftInProgressStateWorkflow(task)) {
        return 'Draft';
      } else {
        return 'Pending';
      }
    } else {
      return this.taskListService.getDisplayNameClosed(task.status);
    }
  }

  onClickRow(task: any) {
    if (!task) {
      return;
    }

    if (this.auth.isVendor()) {
      if (this.isVendorDraftInProgressStateWorkflow(task)) {
        return this.router.navigate([this.getVendorDraftInProgressTasksUrl(task)],
          {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      } else {
        return this.router.navigate([this.getUrlForNotOpenTaskDetails(task)],
          {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      }
    } else if (this.auth.isPia()) {
      this.routeToPathForPIA(task);
      // is SCA/BDM
    } else if (this.auth.isBuyer() || this.auth.isSca()) {
      if (this.isEndStateWorkflow(task)) {
        return this.router.navigate([this.getClosedPiaUrlForTasks(task)],
          {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      } else {
        if (this.isEndStateWorkflow(task)) {
          this.router.navigate([this.getClosedPiaUrlForTasks(task)],
            {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
        } else {
          this.routeToUrlForBuyerOrScaTaskDetails(task);
        }
      }
    } else if (this.auth.isObReg()) {
      if (this.isEndStateWorkflow(task)) {
        return this.router.navigate([this.getClosedPiaUrlForTasks(task)],
          {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      } else {
        return this.router.navigate([this.getUrlPathForObReg(task)],
          {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      }
    } else if (this.auth.isPharm()) {
      if (this.isEndStateWorkflow(task)) {
        return this.router.navigate([this.getClosedPiaUrlForTasks(task)],
          {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      } else {
        return this.router.navigate([this.getUrlPathForPharmacy(task)],
          {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      }
    } else {
      this.router.navigate(['/tasks'], {
        queryParams: {
          growlMessage: 'Search paths for user not implemented.', growlToUse: GrowlService.SEVERITY_ERROR
        }
      });
    }
  }

  getUrlPathForObReg(task) {
    if (task.name === 'Key OB Reg Data') {
      return '/obRegScalePluReview';
    } else {
      return this.getClosedPiaUrlForTasks(task);
    }
  }

  getUrlPathForPharmacy(task) {
    if (task.name === Task.PHARM_NEW_PRODUCT_FLOW) {
      return '/pharmSetupNewProduct';
    } else {
      return this.getClosedPiaUrlForTasks(task);
    }
  }


  routeToPathForPIA(task) {
    if (task.name === Task.PIA_FINAL_REVIEW || task.name === Task.PIA_REVIEW_ASSOCIATE_UPC || task.name === Task.PIA_REVIEW_DSD_INVITED_SUPPLIERS) {
      return this.router.navigate([this.getPiaUrlForPiaTasks(task)],
        {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      // PIA Drafts
    } else if (task.name === Task.PIA_OB_REG_FLOW_ACTIVATE) {
      return this.router.navigate(['/piaScalePluReview'],
        {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
    } else if (this.isPiaDraftInProgressStateWorkflow(task)) {
      return this.router.navigate([this.getPiaDraftInProgressTasksUrl(task)],
        {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
    } else {
      if (this.isEndStateWorkflow(task)) {
        return this.router.navigate([this.getClosedPiaUrlForTasks(task)],
          {queryParams: this.taskListService.getQueryParamsForCandidateAndTaskDetails(task.candidateId, task)});
      } else {
        this.routeToUrlForBuyerOrScaTaskDetails(task);
      }
    }
  }

  isEndStateWorkflow(task: TaskListTask): boolean {
    const endStates = [WorkflowService.PIA_APPROVED_STATE, WorkflowService.PIA_DECLINED_STATE, WorkflowService.VENDOR_DECLINED_STATE,
      WorkflowService.PIA_DELETE];

    return endStates.includes(task.name);
  }

  /**
   * Returns whether or not the task is a vendor draft in progress task.
   * @param task
   */
  isVendorDraftInProgressStateWorkflow(task: TaskListTask): boolean {
    const vendorDraftInProgressTasks = [Task.KEY_VENDOR_DATA, Task.ASSOCIATE_VENDOR_TASK_NAME, Task.DSD_INVITED_SUPPLIER_VENDOR_TASK_NAME];

    return vendorDraftInProgressTasks.includes(task.name);
  }

  /**
   * Returns whether or not the task is a vendor draft in progress task.
   * @param task
   */
  isPharmDraftInProgressStateWorkflow(task: TaskListTask): boolean {
    const pharmDraftInProgressTasks = [Task.PHARM_NEW_PRODUCT_FLOW];

    return pharmDraftInProgressTasks.includes(task.name);
  }
  /**
   * Returns whether or not the task is a pia draft in progress task.
   * @param task
   */
  isPiaDraftInProgressStateWorkflow(task: TaskListTask): boolean {
    const piaDraftInProgressTasks = [Task.PIA_NEW_PRODUCT_FLOW, Task.PIA_OB_REG_FLOW_SUBMIT];

    return piaDraftInProgressTasks.includes(task.name);
  }

  /**
   * Returns in progress task urls that are currently vendor drafts.
   * @param task
   */
  getVendorDraftInProgressTasksUrl(task) {
    if (task.taskType === Candidate.NEW_PRODUCT || (task.taskType === Candidate.PLU && task.name !== Task.PIA_OB_REG_FLOW_ACTIVATE)) {
      return '/setupCandidateType';
    } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
      return '/setupAdditionalCasePack';
    } else if (task.taskType === Candidate.BONUS_SIZE) {
      return '/setupBonusSize';
    } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
      return '/setupReplacementUpc';
    } else if (task.taskType === Candidate.MRT) {
      return '/setupMrt';
    } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
      return '/setupAssociateUpc';
    } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
      return '/setupReplacementUpc';
    } else if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR) {
      this.invitedDistributorService.resetService();
      return '/invitedDistributorCaseDetails';
    } else if (task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
      this.invitedDistributorService.resetService();
      return '/supplierAddDistributor';
    } else {
      this.router.navigate(['/tasks'], {
        queryParams: {
          growlMessage: 'Search path for Supplier Draft Type: ' + task.taskType + ' not implemented.',
          growlToUse: GrowlService.SEVERITY_ERROR
        }
      });
    }
  }

  /**
   * Returns in progress task urls that are currently pia drafts.
   * @param task
   */
  getPiaDraftInProgressTasksUrl(task) {
    if (task.name === Task.PIA_NEW_PRODUCT_FLOW) {
      if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
        return '/piaSetupNewProduct';
      } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
        return '/piaAssociateBasicItemSetup';
      } else if (task.taskType === Candidate.BONUS_SIZE) {
        return '/piaSetupBonusSizeComponent';
      } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
        return '/piaSetupReplacementUpc';
      } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
        return '/piaSetupCasePackComponent';
      } else if (task.taskType === Candidate.MRT) {
        return '/piaSetupMrt';
      } else if (task.taskType === Candidate.NEW_PRODUCT_UPLOAD_PARENT && task.status === Candidate.UPLOADED) {
        return '/volumeUpload';
      } else if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR) {
        return '/piaAddDistributor';
      }
    } else if (task.name === Task.PIA_OB_REG_FLOW_SUBMIT) {
      if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
        return '/piaSetupNewProduct';
      }
    } else if (task.name === Task.PIA_REVIEW_DSD_INVITED_SUPPLIERS) {
      if (task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
        return 'piaInvitedDistributorsReview';
      }
    } else {
      this.router.navigate(['/tasks'], {
        queryParams: {
          growlMessage: 'Search path for PIA Draft Name: ' + task.name + ' not implemented.', growlToUse: GrowlService.SEVERITY_ERROR
        }
      });
    }
  }

  // These pages will handle switching b/w readonly and editable
  // depending on the user's role.
  routeToUrlForBuyerOrScaTaskDetails(task: TaskListTask) {
    let needsCandidateId = true;
    let routeUrl = '';
    if (task.name === Task.KEY_BUYER_DATA || task.name === Task.REVISE_BUYER_DATA ||
      task.name === Task.BUYER_REVIEW_DSD_INVITED_SUPPLIERS) {

      if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
        routeUrl = '/buyerAdditionalCasePackReview';
      } else if (task.taskType === Candidate.BONUS_SIZE) {
        routeUrl = '/buyerBonusSizeReview';
      } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
        routeUrl = '/buyerReplacementUpcReview';
      } else if (task.taskType === Candidate.MRT) {
        routeUrl = '/buyerMrtReview';
      } else if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.MRT_INNER || task.taskType === Candidate.PLU || !task.taskType) {
        routeUrl = '/buyerProductReview';
      } else if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR || task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
        needsCandidateId = false;
        routeUrl = '/buyerInvitedDistributorsReview';
      } else {
        this.router.navigate(['/tasks'], {
          queryParams: {
            growlMessage: 'Search path for buyer task: ' + task.taskType + ' not implemented.', growlToUse: GrowlService.SEVERITY_ERROR
          }
        });
      }
      // if is warehouse task, or is buyer reviewing pia task, route to SCA view
    } else if (task.name === 'Assign Warehouse') {
      if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
        routeUrl = '/scaAdditionalCasePack';
      } else if (task.taskType === Candidate.BONUS_SIZE) {
        routeUrl = '/scaBonusSizeReview';
      } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
        routeUrl = '/scaReplacementUpcReview';
      }  else if (task.taskType === Candidate.MRT) {
        routeUrl = '/scaMrtReview';
      } else if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.MRT_INNER || task.taskType === Candidate.PLU || !task.taskType) {
        routeUrl = '/scaProductReview';
      } else {
        this.router.navigate(['/tasks'], {
          queryParams: {
            growlMessage: 'Search path for SCA task: ' + task.taskType + ' not implemented.', growlToUse: GrowlService.SEVERITY_ERROR
          }
        });
      }
    } else if (task.name === Task.PIA_FINAL_REVIEW || task.name === Task.PIA_REVIEW_ASSOCIATE_UPC || task.name === Task.KEY_OB_REG_DATA ||
      task.name === Task.PIA_OB_REG_FLOW_ACTIVATE || task.name === Task.PIA_OB_REG_FLOW_SUBMIT || task.name === Task.PIA_NEW_PRODUCT_FLOW ||
      task.name === Task.PIA_REVIEW_DSD_INVITED_SUPPLIERS) {
      routeUrl = this.getClosedPiaUrlForTasks(task);
    }
    const params = {
      taskId: task.id,
      ...needsCandidateId && {candidateId: task.candidateId}
    };
    this.router.navigate([routeUrl], {queryParams: params});
  }

  getUrlForNotOpenTaskDetails(task: TaskListTask): string {
    if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
      return '/newProductReviewPageComponent';
    } else if (task.taskType === Candidate.MRT) {
      return '/mrtCaseDetailsReview';
    } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
      return '/associateSupplierReview';
    } else if (task.taskType === Candidate.BONUS_SIZE) {
      return '/bonusReview';
    } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
      return '/replacementUpcReview';
    } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
      return '/additionalCasePackReview';
    } else if (task.taskType === Candidate.MRT_INNER) {
      return '/mrtInnerSupplierReview';
    } else if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR || task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
      return '/invitedDistributorReviewPage';
    } else {
      return '/';
    }
  }

  getClosedPiaUrlForTasks(task: TaskListTask): string {
    if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
      return '/procurementSupportProductReviewClosed';
    } else if (task.taskType === Candidate.MRT) {
      return '/piaMrtReviewClosed';
    } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
      return '/associateUpcProcurementReviewClosed';
    } else if (task.taskType === Candidate.BONUS_SIZE) {
      return '/piaBonusSizeReviewClosed';
    } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
      return '/piaReplacementUpcReviewClosed';
    } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
      return '/piaAdditionalCasePackClosed';
    } else if (task.taskType === Candidate.MRT_INNER) {
      return '/piaMrtInnerReviewClosed';
    } else if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR || task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
      return '/piaInvitedDistributorsReviewClosed';
    } else {
      return '/';
    }
  }

  getPiaUrlForPiaTasks(task: TaskListTask): string {
    if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
      return '/procurementSupportProductReview';
    } else if (task.taskType === Candidate.MRT) {
      return '/piaMrtReview';
    } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
      return '/associateUpcProcurementReview';
    } else if (task.taskType === Candidate.BONUS_SIZE) {
      return '/piaBonusSizeReview';
    } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
      return '/piaReplacementUpcReview';
    } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
      return '/piaAdditionalCasePack';
    } else if (task.taskType === Candidate.MRT_INNER) {
      return '/procurementSupportProductReview';
    } else if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR || task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
      return '/piaInvitedDistributorsReview';
    } else {
      return '/';
    }
  }

  getDisplayNameWorkflowStatus(event) {
    if (event) {
      if (event.name === WorkflowService.VENDOR_DECLINED_STATE || event.name === WorkflowService.PIA_DECLINED_STATE) {
        if (!(event.vendorComment === Candidate.CORRECTIONS_NEEDED || event.vendorComment === Candidate.NOT_WANTED)) {
          const other = 'Other-';
          return other.concat(event.vendorComment);
        } else {
          return event.vendorComment;
        }
      } else {
        return this.taskListService.getDisplayNameWorkflowStatus(event.name);
      }
    } else {
      return '';
    }
  }

  getMrtUpcs(index) {
    if (index === null || index === undefined || !this.searchResults || !this.searchResults.length) {
      return;
    }
    this.candidateService.getCandidate(this.searchResults[index].candidateId).subscribe((mrtCandidate: Candidate) => {
      if (mrtCandidate.mrtInfo.existingInners) {
        for (let x = 0; x < mrtCandidate.mrtInfo.existingInners.length; x++) {
          this.searchResults[index].mrtUpcs.push(mrtCandidate.mrtInfo.existingInners[x].upc);
        }
      }
      if (mrtCandidate.mrtInfo.candidateInners) {
        const candidateIds = mrtCandidate.mrtInfo.candidateInners.map(candidateInner => candidateInner.candidateId);
        this.candidateService.getCandidatesByCandidateIds(candidateIds).subscribe((candidates: Candidate[]) => {
          if (candidates) {
            this.searchResults[index].mrtUpcs = this.searchResults[index].mrtUpcs
              .concat(candidates.map(candidate => candidate.candidateProducts[0].upc));
          }
        });
      }
    });
  }


  /**
   * On click of check all button, if only some or all of the rows are currently selected, unselect all,
   * or if none are currently selected- select all.
   */
  selectedAllChange() {
    let areAnyChecked = false;
    this.checkSelectedSearchResult();
    for (let x = 0; x < this.searchResults.length; x++) {
      if (this.searchResults[x].checked) {
        areAnyChecked = true;
        break;
      }
    }
    // if only some or all are currently selected, unselect all.
    if (areAnyChecked) {
      this.setAllSearchResultsSelectedValue(false);
      // if none are currently selected- select all.
    } else {
      this.setAllSearchResultsSelectedValue(true);
    }
  }


  /**
   * Parse the task list to see if all checked task are the same type.
   */
  checkSelectedSearchResult() {
    let candidateTaskType = '';
    let firstTaskTypeFound = false;
    for (let x = 0; x < this.searchResults.length; x++) {
      if (this.searchResults[x].checked) {
        if (!firstTaskTypeFound) {
          firstTaskTypeFound = true;
          candidateTaskType = this.searchResults[x].taskType;
          this.hasDifferentTaskTypes = false;
        } else {
        }

        if (firstTaskTypeFound && candidateTaskType !== this.searchResults[x].taskType) {
          this.hasDifferentTaskTypes = true;
        }
      }
    }
  }


  /**
   * Sets all tasks selected value to the boolean supplied.
   * @param value the value to set the selected.
   */
  setAllSearchResultsSelectedValue(value: boolean) {
    for (let x = 0; x < this.searchResults.length; x++) {
      this.searchResults[x].checked = value;
    }
  }

  /**
   * If any buttons aren't checked, return dash icon, else return the check icon.
   */
  getCheckAllIcon() {
    for (let x = 0; x < this.searchResults.length; x++) {
      if (!this.searchResults[x].checked) {
        return 'pi pi-minus';
      }
    }
    return 'pi pi-check';
  }


  /**
   * If the box is checked or any are selected, set allTasksCheckbox to true, else set to false.
   * @param index the index of the checkbox.
   * @param event check event from click of task checkbox.
   */
  onCheckSearchResult(index, event) {
    const checked = event.checked;
    this.checkSelectedSearchResult();
    if (checked) {
      this.allTasksCheckbox = true;
      return;
    } else {
      for (let x = 0; x < this.searchResults.length; x++) {
        if (this.searchResults[x].checked) {
          this.allTasksCheckbox = true;
          return;
        }
      }
    }
    this.allTasksCheckbox = false;
  }

  exportResults() {
    this.isExportDisabled = true;
    const checkedResultsIds = this.searchResults.filter(candidate => candidate.checked).map((result) => result.candidateId);
    this.candidateService.exportCandidates(checkedResultsIds).subscribe(
      (response) => {
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData,
          {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
        downloadLink.setAttribute('download', 'Candidate Exports');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.isExportDisabled = false;

      });
  }

  hasSelectedResults() {
    if (!this.searchResults || this.searchResults.length === 0) {
      return false;
    }

    const checkedResult = this.searchResults.find((result) => result.checked);

    return !!checkedResult;
  }
}

