import {Component, OnInit} from '@angular/core';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {MatUtilService} from '../../service/mat-util.service';
import {SupplierCasePackService} from '../../service/supplier-case-pack.service';
import {Candidate, CandidateError, CandidateProduct, CandidateProductError, CandidateValidatorType} from 'pm-models';
import {finalize, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
  AdditionalCasePackStepperComponent
} from '../../supplier/components/additional-case-pack-stepper/additional-case-pack-stepper.component';

@Component({
  selector: 'app-additional-case-pack-extended-attributes',
  templateUrl: './additional-case-pack-extended-attributes.component.html',
  styleUrls: ['./additional-case-pack-extended-attributes.component.scss']
})
export class AdditionalCasePackExtendedAttributesComponent implements OnInit {


  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public supplierCasePackService: SupplierCasePackService, private candidateUtilService: CandidateUtilService,
              private matUtilService: MatUtilService) { }

  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;
  candidateProductError: CandidateProductError;

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierCasePackService.getTaskId(),
        this.supplierCasePackService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierCasePackService.resetService();
        this.supplierCasePackService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.ADDITIONAL_CASE_PACK) {
              this.supplierCasePackService.setOriginalAndCurrentCandidate(candidate);
              return this.updateHierarchyAndAttributes();
            } else {
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.supplierCasePackService.getExtendedAttributesError();
            this.candidateProductError = this.supplierCasePackService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierCasePackService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return this.updateHierarchyAndAttributes();
            } else {
              this.supplierCasePackService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.supplierCasePackService.getExtendedAttributesError();
            this.candidateProductError = this.supplierCasePackService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      }
    });
  }

  updateHierarchyAndAttributes(): Observable<any> {
    if (this.supplierCasePackService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.supplierCasePackService.candidate.candidateProducts[0].upc, []).pipe(
        switchMap((productData) => this.matUtilService.updateMatHierarchyFromProduct(this.supplierCasePackService.candidate.candidateProducts[1], productData)),
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.supplierCasePackService.getCandidate(),
          this.supplierCasePackService.globalAttributes, this.supplierCasePackService.hierarchyAttributes)),
      );
    } else if (this.supplierCasePackService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.supplierCasePackService.candidate.candidateProducts[0].itemCode, []).pipe(
        switchMap((productData) => this.matUtilService.updateMatHierarchyFromProduct(this.supplierCasePackService.candidate.candidateProducts[1], productData)),
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.supplierCasePackService.getCandidate(),
          this.supplierCasePackService.globalAttributes, this.supplierCasePackService.hierarchyAttributes)),
      );
    }
  }


  onClickBack() {
    this.supplierCasePackService.saveCandidateAndNavigate('/additionalCasePackDetails', false);
  }

  onClickNext() {
    this.canNavigate = false;

    this.candidateService.validateCandidate(this.supplierCasePackService.candidate, [CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR]).subscribe(data => {
      this.candidateError = this.supplierCasePackService.setExtendedAttributesError(new CandidateError());
      this.supplierCasePackService.saveCandidateAndNavigate('/additionalCasePackReview', false);
    }, (error) => {
      this.supplierCasePackService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierCasePackService.setExtendedAttributesError(error.error.candidateErrors);
        this.candidateProductError = this.candidateError.candidateProductErrors[this.supplierCasePackService.candidate.candidateProducts[1].id] ;
      }
      this.supplierCasePackService.saveCandidateAndNavigate('/additionalCasePackReview', false);
    });
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case AdditionalCasePackStepperComponent.UPC_ITEM_CODE: {
        this.supplierCasePackService.saveCandidateAndNavigate('/setupAdditionalCasePack', false);
        break;
      }
      case AdditionalCasePackStepperComponent.CASE_DETAILS: {
        this.onClickBack();
        break;
      }
      case AdditionalCasePackStepperComponent.REVIEW: {
        this.onClickNext();
        break;
      }
    }
  }

  onClose() {
    this.supplierCasePackService.saveCandidateAndNavigate('/tasks', false);
  }
}
