import {Component, OnInit} from '@angular/core';
import {
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  CaseDetailsCardPermissions,
  CostCardPermissions, HEBDetailsCardPermissions,
  SupplierDetailsCardPermissions,
  TaskDecision
} from 'pm-models';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from '../../service/lookup.service';
import {CandidateService} from '../../service/candidate.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {WorkflowService} from '../../service/workflow.service';

@Component({
  selector: 'app-pia-new-invited-distributor-case-details',
  templateUrl: './pia-new-invited-distributor-case-details.component.html',
  styleUrls: ['./pia-new-invited-distributor-case-details.component.scss']
})
export class PiaNewInvitedDistributorCaseDetailsComponent implements OnInit {


  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public costOwners: any = [];
  public commodities: any;

  constructor(public invitedDistributorService: InvitedDistributorService, private route: ActivatedRoute,
              private router: Router, private lookupService: LookupService, private candidateService: CandidateService,
              private candidateUtilService: CandidateUtilService, private workflowService: WorkflowService) { }

  readonly buyerDetailsCardPermissions: HEBDetailsCardPermissions = {
    isReadOnly: false
  };

  supplierDetailsCardPermissions: SupplierDetailsCardPermissions;

  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions = {
    isReadOnly: false,
    itemWeightType: {
      isHidden: true
    },
    distributionChannel: {
      isHidden: true
    },
    buyer: {
      isHidden: true
    },
    dealOffered: {
      isHidden: true
    },
    masterPack: {
      isDisabled: true
    }
  };

  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false,
    masterPack: {
      isHidden: true
    }
  };

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.invitedDistributorService.getTaskId(),
        this.invitedDistributorService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.invitedDistributorService.resetService();
        this.invitedDistributorService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          this.invitedDistributorService.initializeProductData().subscribe(() => {
            this.initializeData();
          });
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.invitedDistributorService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              if (this.invitedDistributorService.productData) {
                this.initializeData();
              } else {
                this.invitedDistributorService.initializeProductData().subscribe(() => {
                  this.initializeData();
                });
              }
            } else {
              this.invitedDistributorService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.candidateError = this.invitedDistributorService.getCaseDetailsError();
    this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
    this.setCostOwners();
    this.setMasterPackPermissions();
  }

  onClose() {
    this.invitedDistributorService.saveCandidateAndNavigate('/tasks', this.invitedDistributorService.hasVendorChanged());
  }

  public setCostOwners() {
    this.lookupService.findBrandsById(
      [this.invitedDistributorService.productData.brand.brandId]).subscribe(brandList => {
      if (brandList && brandList.length > 0) {
        this.costOwners = brandList[0].costOwners;
        this.supplierDetailsCardPermissions = this.getSupplierDetails();
      }
    });
  }

  getSupplierDetails(): SupplierDetailsCardPermissions {
    const permissions: SupplierDetailsCardPermissions = {
      isReadOnly: false,
      brand: {
        isHidden: true
      },
      subBrand: {
        isHidden: true
      },
      lane: {
        isHidden: true
      }
    };
    return permissions;
  }


  onClickNext() {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.invitedDistributorService.getCandidate(),
      [CandidateValidatorType.INVITED_DISTRIBUTOR_CASE_DETAILS_VALIDATOR]).toPromise().then(data => {
      this.candidateError = this.invitedDistributorService.setCaseDetailsError(new CandidateError());
      this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
      this.invitedDistributorService.saveCandidateAndNavigate(
        '/piaAddDistributorStoreAuth', this.invitedDistributorService.hasVendorChanged());
    }, (error) => {
      if (this.invitedDistributorService.hasVendorChanged()) {
        const apNumber = this.invitedDistributorService.getCandidate().vendor?.apNumber || null;
        this.workflowService.updateApNumber(apNumber, this.invitedDistributorService.getTask()?.processInstanceId).subscribe();
      }
      this.invitedDistributorService.saveCandidate();
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = this.invitedDistributorService.setCaseDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
      }
    });
  }

  onClickBack() {
    this.invitedDistributorService.saveCandidateAndNavigate('/piaAddDistributor', this.invitedDistributorService.hasVendorChanged());
  }

  /**
   * Disables masterpack if there's no upc information, or there's a DSD item tied to the upc.
   */
  setMasterPackPermissions() {
    this.additionalCasePackBasicsCardPermissions.masterPack.isDisabled = !this.invitedDistributorService.candidate.candidateProducts[0].isAddComplimentaryPlu &&
      (!this.invitedDistributorService.upc || !!this.invitedDistributorService.upc.dsdItem);
  }

  /**
   * If the user chooses to start over when AP vendor is already authorized, remove the already stored data, and route to start of flow.
   */
  onClickStartOver() {
    this.invitedDistributorService.candidate.vendor = null;
    this.invitedDistributorService.onSupplierChange(null, null, null);
    this.invitedDistributorService.candidate.brand = null;
    this.invitedDistributorService.candidate.buyer = null;
    this.invitedDistributorService.candidate.commodity = null;
    this.invitedDistributorService.candidate.subCommodity = null;
    this.invitedDistributorService.candidate.costOwner = null;
    this.invitedDistributorService.candidate.dealOffered = null;
    this.invitedDistributorService.candidate.innerListCost = null;
    this.invitedDistributorService.candidate.masterListCost = null;
    this.invitedDistributorService.candidate.masterPack = null;
    this.invitedDistributorService.candidate.suggestedRetailPrice = null;
    this.invitedDistributorService.candidate.suggestedXFor = null;
    this.invitedDistributorService.candidate.unitCost = null;

    this.invitedDistributorService.candidate.candidateProducts[0].caseDescription = null;
    this.invitedDistributorService.candidate.candidateProducts[0].countryOfOrigin = null;
    this.invitedDistributorService.candidate.candidateProducts[0].description = null;
    this.invitedDistributorService.candidate.candidateProducts[0].upc = null;
    this.invitedDistributorService.candidate.candidateProducts[0].upcCheckDigit = null;
    this.invitedDistributorService.candidate.candidateProducts[0].vendorProductCode = null;

    this.invitedDistributorService.productData = null;

    this.invitedDistributorService.saveCandidateAndNavigate('/piaAddDistributor', this.invitedDistributorService.hasVendorChanged());
  }

  /**
   * If the user chooses to return to home when AP vendor is already authorized, delete the candidate and the task.
   */
  onClickReturnHome() {
    this.candidateService.deleteCandidateById(this.invitedDistributorService.candidate.candidateId).subscribe(() => {
      this.workflowService.completeTaskIdWithAction(this.invitedDistributorService.getTaskId(), WorkflowService.ACTION_COMPLETE,
        TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe(() => {
        this.router.navigate(['/tasks']).then();
      });
    });
  }

  /**
   * If override department changes, set the auth group information to null.
   */
  public onOverrideSubDepartmentChange() {
    this.invitedDistributorService.candidate.candidateProducts[0].locationGroupStores = [];
    this.invitedDistributorService.setAuthGroups(null);
    this.invitedDistributorService.setSelectedAuthGroups(null);
    this.invitedDistributorService.setNotFoundStores(null);
  }
}
