<div class="product-table-container">
  <div class="row ml-0 mr-0">
    <p-table selectionMode="single"
             [rowHover]="true"
             [columns]="supplierAndHebHeaderColumns"
             [value]="candidates"
             [rows]="pageSize"
             [totalRecords]="totalNumRecords"
             [scrollable]="true"
             styleClass="p-datatable-gridlines"
             [ngStyle]="{'width': '100%'}">

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns"
               [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-productHeaderColumns>
        <tr class="product-table-column-group">
          <th class="left-sticky-header" [attr.colspan]="1">#</th>
          <th class="left-sticky-header" [attr.colspan]="1">UPC</th>
          <th *ngIf="HEB_DETAILS_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="HEB_DETAILS_COLUMN_GROUP_LENGTH">H-E-B details</th>
          <th *ngIf="SUPPLIER_DETAILS_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="SUPPLIER_DETAILS_COLUMN_GROUP_LENGTH">Supplier details</th>
        </tr>
        <tr class="product-table-column-headers">
          <th *ngFor="let col of productHeaderColumns"
              [ngClass]="col.sticky ? 'left-sticky-header' : ''"
              [ngStyle]="{'width': col.width}"
              (click)="onClickHeader(col)">
            <div>
              {{col.header}}
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-candidate let-index="rowIndex">
        <tr class="product-table-body" [pSelectableRow]="candidate">

          <!--  candidate index -->
          <td class="sticky-column col">
            <div>{{index + 1}}</div>
          </td>
          <!--  UPC -->
          <td class="sticky-column" (click)="editProductUPC(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])}}">
            </i>
            {{candidate?.candidateProducts[candidateProductIndex].upc}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.CHANNEL)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['dsdSwitch', 'warehouseSwitch'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['dsdSwitch', 'warehouseSwitch'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['dsdSwitch', 'warehouseSwitch'])}}">
            </i>
            {{candidateUtils.getChannel(candidate)}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.SELLABLE)" (click)="showEditCandidateModal(index, attributeType.Sellable)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['productType'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['productType'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['productType'])}}">
            </i>
            {{candidateUtils.isSellable(candidate) | yesNo}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.BUYER)" (click)="editBuyer(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['buyer'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['buyer'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['buyer'])}}">
            </i>
            {{candidate.buyer?.displayName}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.CONTACT)">
            {{candidate.contactName}}<br/>
            {{candidate.contactEmail}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.BRAND)" (click)="editBrandModal(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['brand'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['brand'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['brand'])}}">
            </i>
            {{candidate.brand?.displayName}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.SUB_BRAND)" (click)="this.showEditCandidateProductModal(index, attributeType.SubBrand)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['subBrand'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['subBrand'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['subBrand'])}}">
            </i>
            {{candidate?.candidateProducts[candidateProductIndex]?.subBrand?.displayName | emptyToValue: '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.COST_OWNER)" (click)="editCostOwner(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['costOwner'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['costOwner'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['costOwner'])}}">
            </i>
            {{candidate.costOwner?.displayName}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.TOP_TO_TOP)" (click)="editCostOwner(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['topToTop'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['topToTop'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['topToTop'])}}">
            </i>
            {{candidate.costOwner?.topToTopDisplayName}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.SUPPLIER)" (click)="editSupplier(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['vendor'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['vendor'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['vendor'])}}">
            </i>
            {{candidate.vendor?.displayName}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateSupplierAndHebConstants.WAREHOUSE_SUPPLIER)" (click)="editWarehouseSupplier(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['lane'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['lane'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['lane'])}}">
            </i>
            {{candidate.lane?.name}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
