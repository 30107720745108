import { Component, OnInit } from '@angular/core';
import {Candidate, TaskDecision} from 'pm-models';
import {WorkflowService} from '../../../service/workflow.service';
import {TaskListTask} from '../../task-list-service/taskListTask';
import {TaskViewerTableComponent} from '../../task-viewer-table/task-viewer-table.component';

@Component({
  selector: 'app-pharm-task-viewer-table',
  templateUrl: './pharm-task-viewer-table.component.html',
  styleUrls: ['./pharm-task-viewer-table.component.scss']
})
export class PharmTaskViewerTableComponent extends TaskViewerTableComponent implements OnInit {

  mapColumnNamePrimeTableClosedToEndPoint = new Map([
    ['candidateId', 'CANDIDATE_ID'],
    ['description', 'PRODUCT_DESCRIPTION'],
    ['displayName', 'STATUS'],
    ['upc', 'UPC'],
    ['itemCode', 'ITEM_CODE'],
    ['supplierDisplayName', 'SUPPLIER_NAME'],
    ['lastUpdatedBy', 'LAST_UPDATED_BY'],
    ['lastUpdatedTime', 'LAST_UPDATED_TIME'],
  ]);

  //
  // rejected table variables
  //
  rejectedTasks: TaskListTask[] = [];
  rejectedTotalNumRecords: number;

  rejectedCurrentPageNumber: number = 0;
  rejectedCurrentSortOrder: string = 'DESC';
  rejectedCurrentSortField: string = 'CANDIDATE_ID';

  //
  // approved table variables
  //
  approvedTasks: TaskListTask[] = [];
  approvedTotalNumRecords: number;

  approvedCurrentPageNumber: number = 0;
  approvedCurrentSortOrder: string = 'DESC';
  approvedCurrentSortField: string = 'CANDIDATE_ID';

  // ---

  tabIndex: number = 0;

  OPEN_TAB = {
    title: 'Drafts',
    tooltip: 'Candidates that have tasks assigned to you.',
    index: 0
  };

  ACTIVATED_TAB = {
    title: 'Activated',
    tooltip: 'Candidates that have been activated.',
    index: 1
  };

  async ngOnInit() {
    this.changeTab({index: this.tabIndex});
  }

  getOpenTasks() {
    super.getOpenTasks(true, this.currentPageNumber, this.currentSortOrder, this.currentSortField);
  }

  getCompletedProcesses() {
    this.getCompletedProcessesParms(true, this.approvedCurrentPageNumber, this.approvedCurrentSortOrder, this.approvedCurrentSortField);
  }

  getCompletedProcessesParms(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {
    this.showSpinner = true;
    this.taskListService.getCompletedProcesses(getCount, pageNumber, sortOrder, sortField).subscribe(result => {
      if (result.length > 0) {
        if (getCount) {
          this.approvedTotalNumRecords = result[0].count;
        }
        this.approvedTasks = result;
      } else {
        this.approvedTotalNumRecords = 0;
        this.approvedTasks = [];
      }
      this.showSpinner = false;
    });
  }

  changeTab(event) {
    this.tabIndex = event.index;

    switch (this.tabIndex) {
      case this.OPEN_TAB.index: {
        this.getOpenTasks();
        break;
      }
      case this.ACTIVATED_TAB.index: {
        this.getCompletedProcesses();
        break;
      }
    }

    this.clearTabIndexParam();
  }

  clearTabIndexParam() {
    this.router.navigate(
      ['.'],
      { relativeTo: this.route, queryParams: { } }
    ).then();
  }

  /**
   * Delete a candidate. This logic is designed to work for the Pharm only workflow trash can.
   * The logic for updating the candidate is on the server side.
   * @param candidateId to delete
   */
  deleteCandidateById(candidateId: number) {
    const task: TaskListTask = this.tasks.find(x => x.candidateId === candidateId);

    if (task) {
      this.candidateService.deleteCandidateById(candidateId).subscribe(() => {
        this.workflowService.completeTaskIdWithAction(
          task.id, WorkflowService.ACTION_COMPLETE, TaskDecision.PHARM_FINAL_REVIEW_REJECT_DECISION)
          .subscribe(() => {
            this.getOpenTasks();
          }, (error) => {
            this.showError(error);
          });

      }, (error) => {
        this.showError(error);
      });
    } else {
      this.growlService.addError('Unable to find task for Candidate Id: ' + candidateId);
    }
  }

  showError(error: any) {
    let errorMessage: string;

    if (error.error) {
      errorMessage = `${error.error.message}`;
    } else {
      errorMessage = `${error.message}`;
    }

    this.growlService.addError(errorMessage);
  }

  onLoadDataApproved(event: any) {
    const pageNumber = this.getPageNumber(event.first, this.taskListService.PAGE_SIZE);
    const sortOrder = this.mapSortOrderPrimeTableToEndPoint.get(event.sortOrder + 0);
    let sortField = this.mapColumnNamePrimeTableClosedToEndPoint.get(event.sortField);
    if (!sortField) {
      sortField = 'CANDIDATE_ID';
    }

    if (this.approvedCurrentPageNumber === pageNumber &&
      this.approvedCurrentSortOrder === sortOrder &&
      this.approvedCurrentSortField === sortField) {
      return;
    } else {
      this.approvedCurrentPageNumber = pageNumber;
      this.approvedCurrentSortOrder = sortOrder;
      this.approvedCurrentSortField = sortField;
    }

    this.getCompletedProcessesParms(false, pageNumber, sortOrder, sortField);
  }

  onClickRowApprovedDeclined(task: TaskListTask) {
    return this.router.navigate([this.getUrlForClosedTaskDetails(task)],
      {queryParams: this.taskListService.getQueryParamsForCandidateDetails(task.candidateId)}).then();
  }

  getUrlForClosedTaskDetails(task: TaskListTask): string {
    if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
      return '/pharmProductReviewClosed';
    } else {
      return '/';
    }
  }
}
