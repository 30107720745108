<div class="attribute-group attribute-required">
  <div class="attribute-label">
    <label>{{ label }}</label>
  </div>
  <div class="attribute-control">
    <div *ngIf="hidePlu">
      <div class="ui-upcinput">
        <div class="ui-inputgroup" [ngClass]="{'ui-upc-invalid' : hasError}">
          <p-dropdown [options]="upcOptions" [(ngModel)]="upcType" *ngIf="upcOptions"
                      [readonly]="readOnly"
                      [disabled]="disabled" placeholder="" optionLabel="">
          </p-dropdown>
          <input type="text" pInputText class="upc-input" placeholder="{{placeholderText}}" appOnlyNumber
                 (blur)="upcBlur($event)" (keydown)="upcKeyDown($event)" [readonly]="readOnly" [disabled]="disabled"
                 [ngClass]="{'ui-inputtext-invalid' : hasError}" [ngModel]="modelDisplay" (ngModelChange)="upcChange($event)"
                 [ngModelOptions]="{updateOn:'blur'}" maxlength="13">
          <span class="ui-inputgroup-addon" *ngIf="!readOnly && !disabled">
          <i class="pi pi-spin pi-spinner" *ngIf="state === 1"></i>
          <i class="material-icons ui-success" *ngIf="state === 2">check_circle_outline</i>
          <i class="material-icons ui-invalid rotate-45" *ngIf="state === 3">add_circle_outline</i>
        </span>
        </div>
        <input *ngIf="!hideCheckDigit" type="text" pInputText [(ngModel)]="checkDigitModel" placeholder="{{checkDigitPlaceholderText}}"
               appOnlyNumber
               (keydown)="checkDigitKeyDown($event)" class="upc-check-digit-input" (blur)="upcBlur($event)" [readonly]="readOnlyCheckDigit"
               [disabled]="readOnlyCheckDigit" [ngClass]="{'ui-inputtext-invalid' : hasError}" maxlength="1">
      </div>
      <div class="row pl-3" *ngIf="hasErrorMessages()">
        <p *ngIf="candidateProductError?.upc" class="error-message">{{ candidateProductError?.upc }}</p>
        <p *ngIf="candidateProductError?.upcCheckDigit" class="error-message">{{ candidateProductError?.upcCheckDigit }}</p>
      </div>
    </div>
    <div class="plu-input-group" *ngIf="!hidePlu">
      <div class="plu-input">
        <div class="ui-inputgroup" [ngClass]="{'ui-upc-invalid' : hasError}">
          <p-dropdown class="upcOptions" [options]="upcOptions" [(ngModel)]="upcType" *ngIf="upcOptions"
                      [readonly]="readOnly" (onChange)="resetPlu()"
                      [disabled]="disabled" placeholder="" optionLabel="">
          </p-dropdown>
          <div class="row plu-type ml-0">
            <p-dropdown placeholder="{{pluTypePlaceholderText}}" [options]="pluTypes"
                        [(ngModel)]="pluTypeModel" [readonly]="readOnly" (onChange)="onTypeSelection($event)"
                        [disabled]="disabled" optionLabel="">
            </p-dropdown>
          </div>
        </div>
      </div>
      <div class="row manually-enter-plu-button">
        <p-button class="ghost small ml-3" label="{{ isManuallyEnteredPlu && this.upcModel ? 'Remove PLU' : 'Manually enter PLU' }}"
                  [disabled]="isPluTypeUnselected() || isFetchingPlu"
                  (onClick)="onClickIsManuallyEnteredPlu($event, manualPluEntryOverlay, manualPluEntryDiv)"></p-button>
      </div>
      <div class="attributes-stacked pt-2" style="margin-left: -0.5rem;">
        <pm-attribute-display-typeahead *ngIf="!isManuallyEnteredPlu" [attribute]="pluRangeConfiguration"
                                        [(model)]="pluRangeModel" (selection)="onRangeSelection($event)">
        </pm-attribute-display-typeahead>
      </div>
      <div *ngIf="upcModel" class="plu-display col" [ngClass]="{'ui-upc-invalid' : hasError}">
        <div class="row" *ngIf="showPlu()">PLU: {{ upcModel | upc }}-{{ upcModel | upcCheckDigit }}</div>
        <div class="row" *ngIf="showFullPlu()">UPC: {{ fullPlu | upc }}-{{ fullPlu | upcCheckDigit }}</div>
      </div>
      <div class="row mt-0 ml-0" *ngIf="hasErrorMessages() && !isShowingReservationPanel">
        <p *ngIf="candidateProductError?.upc" class="error-message">{{ candidateProductError?.upc }}</p>
        <p *ngIf="candidateProductError?.upcCheckDigit" class="error-message">{{ candidateProductError?.upcCheckDigit }}</p>
      </div>
    </div>
  </div>
</div>

<div>
  <div #manualPluEntryDiv></div>
  <pm-static-overlay-panel #manualPluEntryOverlay appendTo="body"
                           [classOverride]="'pm-overlaypanel-full-size-fixed-top'"
                           [showTransitionOptions]="'0ms ease-out'"
                           [dismissable]="false">
    <ng-container>
      <div class="row align-items-center manual-plu-entry-form-overlay-wrapper">
        <div class="col-md-3 manual-plu-entry-form-overlay">
          <div class="mb-3">
            <h2>Manual PLU reservation</h2>
          </div>
          <div class="attributes-stacked">

            <div class="ui-upcinput">
              <div class="ui-inputgroup" [ngClass]="{'ui-upc-invalid' : hasError}">
                <p-dropdown [options]="[pluUpcType]" [(ngModel)]="pluUpcType">
                </p-dropdown>
                <input type="text" pInputText class="upc-input" placeholder="{{placeholderText}}" appOnlyNumber
                       (blur)="upcBlur($event)" (keydown)="upcKeyDown($event)"
                       [ngClass]="{'ui-inputtext-invalid' : hasError}" [ngModel]="modelDisplay" (ngModelChange)="upcChange($event)"
                       [ngModelOptions]="{updateOn:'blur'}" maxlength="13">
                <span class="ui-inputgroup-addon">
                    <i class="pi pi-spin pi-spinner" *ngIf="state === 1"></i>
                    <i class="material-icons ui-success" *ngIf="state === 2">check_circle_outline</i>
                    <i class="material-icons ui-invalid rotate-45" *ngIf="state === 3">add_circle_outline</i>
                </span>
              </div>
              <input type="text" pInputText [(ngModel)]="checkDigitModel" placeholder="{{checkDigitPlaceholderText}}" appOnlyNumber
                     (keydown)="checkDigitKeyDown($event)" class="upc-check-digit-input" (blur)="upcBlur($event)"
                     [ngClass]="{'ui-inputtext-invalid' : hasError}" maxlength="1">

            </div>
            <div class="row mt-0 ml-0" *ngIf="hasErrorMessages()">
              <p *ngIf="candidateProductError?.upc" class="error-message">{{ candidateProductError?.upc }}</p>
              <p *ngIf="candidateProductError?.upcCheckDigit" class="error-message">{{ candidateProductError?.upcCheckDigit }}</p>
            </div>

            <div class="row button-row">
              <p-button class="ghost m-2" label="Cancel" (onClick)="onClickCancelManualPluEntry(manualPluEntryOverlay)"></p-button>
              <p-button class="m-2" label="Reserve PLU" (onClick)="onClickReservePlu(manualPluEntryOverlay)"
                        [disabled]="isReservePLUDisabled()"></p-button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-static-overlay-panel>
</div>
