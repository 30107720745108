<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4">

  </div>
</div>



<ng-template #card>
  <pm-card title="Imported Product" subtitle="Let us know if H-E-B is the importer of record for this product.">
    <ng-container *ngIf="model">
      <pm-attribute-display-toggle [attribute]="importedProductConfiguration"
                                   [(model)]="model.imported"
                                   (change)="isImportedProductFlagChange($event)"
                                   [attributeError]="errorModel?.imported">
      </pm-attribute-display-toggle>

      <div *ngIf="model.importInfo">

        <pm-attribute-display-dropdown [attribute]="containerSizeConfiguration"
                                       [(model)]="model.importInfo.containerSize"
                                       (change)="containerSizeChange($event)"
                                       [attributeError]="errorModel?.containerSize">
        </pm-attribute-display-dropdown>

        <pm-attribute-display-dropdown [attribute]="incoTermsConfiguration"
                                       [(model)]="model.importInfo.incoTerms"
                                       (change)="incoTermsChange($event)"
                                       [attributeError]="errorModel?.incoTerms">
        </pm-attribute-display-dropdown>

        <pm-attribute-display-text-input [attribute]="pickupPointConfiguration"
                                         [(model)]="model.importInfo.pickupPoint"
                                         (change)="pickupPointChange($event)"
                                         [attributeError]="errorModel?.pickupPoint">
        </pm-attribute-display-text-input>

        <pm-attribute-display-text-input [attribute]="dutyPercentConfiguration"
                                         [(model)]="model.importInfo.dutyPercent"
                                         (change)="dutyPercentChange($event)"
                                         [attributeError]="errorModel?.dutyPercent">
        </pm-attribute-display-text-input>

        <pm-attribute-display-date-picker [attribute]="dutyConfirmedConfiguration"
                                          [(model)]="model.importInfo.dutyConfirmed"
                                          (change)="dutyConfirmedChange($event)"
                                          [attributeError]="errorModel?.dutyConfirmed">
        </pm-attribute-display-date-picker>

        <pm-attribute-display-text-input [attribute]="dutyInfoConfiguration"
                                         [(model)]="model.importInfo.dutyInfo"
                                         (change)="dutyInfoChange($event)"
                                         [attributeError]="errorModel?.dutyInfo">
        </pm-attribute-display-text-input>

        <div class="row">
          <div class="col-7 pr-0" style="padding-right: 0;">
            <pm-attribute-display-text-input [attribute]="minimumOrderQtyConfiguration"
                                             [(model)]="model.importInfo.minimumOrderQty"
                                             (change)="minimumOrderQtyChange($event)"
                                             [attributeError]="errorModel?.minimumOrderQty">
            </pm-attribute-display-text-input>
          </div>
          <div class="col-3 pr-0 pl-0" style="padding-right: 0;">
            <pm-attribute-display-text-input [attribute]="minimumOrderDescConfiguration"
                                             [(model)]="model.importInfo.minimumOrderDesc"
                                             (change)="minimumOrderDescChange($event)"
                                             [attributeError]="errorModel?.minimumOrderDesc">
            </pm-attribute-display-text-input>
          </div>
        </div>
        <pm-attribute-display-text-input [attribute]="hts1Configuration"
                                         [(model)]="model.importInfo.hts1"
                                         (modelChange)="hts1Change()"
                                         [attributeError]="errorModel?.hts1">
        </pm-attribute-display-text-input>

        <pm-attribute-display-text-input [attribute]="hts2Configuration"
                                         [(model)]="model.importInfo.hts2"
                                         (modelChange)="hts2Change()"
                                         [attributeError]="errorModel?.hts2">
        </pm-attribute-display-text-input>

        <pm-attribute-display-text-input [attribute]="hts3Configuration"
                                         [(model)]="model.importInfo.hts3"
                                         (modelChange)="hts3Change()"
                                         [attributeError]="errorModel?.hts3">
        </pm-attribute-display-text-input>

        <pm-attribute-display-text-input [attribute]="agentPercentConfiguration"
                                         [(model)]="model.importInfo.agentPercent"
                                         (change)="agentPercentChange($event)"
                                         [attributeError]="errorModel?.agentPercent">
        </pm-attribute-display-text-input>

        <pm-attribute-display-text-input [attribute]="cartonMarkingConfiguration"
                                         [(model)]="model.importInfo.cartonMarking"
                                         (change)="cartonMarkingChange($event)"
                                         [attributeError]="errorModel?.cartonMarking">
        </pm-attribute-display-text-input>

        <pm-attribute-display-text-input [attribute]="productColorConfiguration"
                                         [(model)]="model.importInfo.productColor"
                                         (change)="productColorChange($event)"
                                         [attributeError]="errorModel?.productColor">
        </pm-attribute-display-text-input>

        <pm-attribute-display-date-picker [attribute]="prorationDateConfiguration"
                                          [(model)]="model.importInfo.prorationDate"
                                          (change)="prorationDateChange($event)"
                                          [attributeError]="errorModel?.prorationDate"
                                          [minimumDate]="minimumDate">
        </pm-attribute-display-date-picker>

        <pm-attribute-display-date-picker [attribute]="inStoreDateConfiguration"
                                          [(model)]="model.importInfo.inStoreDate"
                                          (change)="inStoreDateChange($event)"
                                          [attributeError]="errorModel?.inStoreDate"
                                          [minimumDate]="minimumDate">
        </pm-attribute-display-date-picker>

        <pm-attribute-display-date-picker [attribute]="whsFlushDateConfiguration"
                                          [(model)]="model.importInfo.whsFlushDate"
                                          (change)="whsFlushDateChange($event)"
                                          [attributeError]="errorModel?.whsFlushDate"
                                          [minimumDate]="minimumDate">
        </pm-attribute-display-date-picker>

        <pm-attribute-display-date-picker [attribute]="freightConfirmedDateConfiguration"
                                          [(model)]="model.importInfo.freightConfirmedDate"
                                          (change)="freightConfirmedDateChange($event)"
                                          [attributeError]="errorModel?.freightConfirmedDate">
        </pm-attribute-display-date-picker>

        <pm-import-factory-search-bar [attribute]="importFactoryConfiguration"
                                      [(factoryModel)]="model.importInfo.factory"
                                      [attributeError]="errorModel?.factory">
        </pm-import-factory-search-bar>

      </div>
    </ng-container>

  </pm-card>
</ng-template>

