import {NgModule} from '@angular/core';
import {
  AdditionalCasePackStepperComponent
} from './components/additional-case-pack-stepper/additional-case-pack-stepper.component';
import {CommonModule} from '@angular/common';
import {StepperModule} from '../shared/components/stepper/stepper.module';

@NgModule({
  declarations: [AdditionalCasePackStepperComponent],
  exports: [AdditionalCasePackStepperComponent],
  imports: [CommonModule, StepperModule]
})
export class SupplierModule {}
