import { BaseAttribute } from '../base-attribute';
import { AttributeConfig, Candidate, TextInputType, RetailLink } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayRadioComponent } from '../../../shared/attribute-display/attribute-display-radio/attribute-display-radio.component';

import {RETAIL_LINK, PRICE_REQUIRED, KEY_RETAIL, UPC, ITEM_CODE} from '../../attribute-constants';

export class RetailType implements BaseAttribute {
  public KEY_RETAIL = KEY_RETAIL;
  public RETAIL_LINK = RETAIL_LINK;
  public PRICE_REQUIRED = PRICE_REQUIRED;
  public UPC = UPC;
  public ITEM_CODE = ITEM_CODE;

  getComponentType() {
    return AttributeDisplayRadioComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Pricing type',
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-block',
      options: [
        { label: 'Key retail', value: this.KEY_RETAIL },
        { label: 'Retail link', value: this.RETAIL_LINK },
        { label: 'Price required', value: this.PRICE_REQUIRED }
      ],
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayRadioComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    this.updateModel(componentInstance, candidate);
    componentInstance.modelChange.subscribe(x => {
      console.log('setting up');
      candidate.retailType = x;
      if (x === this.RETAIL_LINK) {
        candidate.retailLink = new RetailLink();
        candidate.retailLink.upcType = this.UPC;
        candidate.retailXFor = '';
        candidate.retailPrice = '';
      } else {
        candidate.retailLink = null;
        if (x === this.KEY_RETAIL) {
          candidate.retailXFor = '';
          candidate.retailPrice = '';
        } else if (x === this.PRICE_REQUIRED) {
          candidate.retailXFor = 1;
          candidate.retailPrice = 0.0;
        }
      }
      console.log(candidate);
    });

  }

  updateModel(componentInstance: AttributeDisplayRadioComponent, candidate: Candidate) {
    componentInstance.attribute.isDisabled = () => candidate.productType === 'NON_SELLABLE';
    componentInstance.attribute.isReadOnly = () => candidate.productType === 'NON_SELLABLE';

    if (componentInstance.model !== candidate.retailType) {
      componentInstance.model = candidate.retailType;
    }
  }
}
