<div class="upload-candidate-table">
  <p-tabView [activeIndex]="tabIndex" (onChange)="changeTab($event)">
    <p-tabPanel *ngIf="isShowingProductTab" header="{{PRODUCT_TAB.title}}" tooltip="{{PRODUCT_TAB.tooltip}}"
                leftIcon="{{hasProductDetailErrors ? 'pi pi-exclamation-triangle' : ''}}" headerStyleClass="{{isProductTabSelected ? 'black-error-triangle-table' : 'red-error-triangle-table'}}">
      <div class="object-manager-page">
        <pm-upload-product-table [(candidates)]="candidates"
                                 [candidateType]="candidateType"
                                 [workflowState]="workflowState"
                                 [candidateProductIndex]="candidateProductIndex"
                                 [uuidToImageMap]="uuidToImageMap"
                                 [candidateIdToCandidateErrorMap]="candidateIdToCandidateErrorMap">
        </pm-upload-product-table>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="isShowingCaseTab" header="{{CASE_TAB.title}}" tooltip="{{CASE_TAB.tooltip}}"
                leftIcon="{{hasCaseDetailErrors ? 'pi pi-exclamation-triangle' : ''}}" headerStyleClass="{{isCaseTabSelected ? 'black-error-triangle-table' : 'red-error-triangle-table'}}">
      <div class="object-manager-page">
        <pm-upload-case-table [(candidates)]="candidates"
                              [candidateType]="candidateType"
                              [workflowState]="workflowState"
                              [isWarehouse]="isWarehouse"
                              [isBuyer]="isBuyer"
                              [isSca]="isSca"
                              [candidateProductIndex]="candidateProductIndex"
                              [candidateIdToCandidateErrorMap]="candidateIdToCandidateErrorMap">
        </pm-upload-case-table>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="isShowingSupplierAndHebTab" header="{{SUPPLIER_AND_HEB_TAB.title}}" tooltip="{{SUPPLIER_AND_HEB_TAB.tooltip}}"
                leftIcon="{{hasSupplierDetailErrors ? 'pi pi-exclamation-triangle' : ''}}" headerStyleClass="{{isSupplierAndHebTabSelected ? 'black-error-triangle-table' : 'red-error-triangle-table'}}">
      <div class="object-manager-page">
        <pm-upload-supplier-and-heb-table [(candidates)]="candidates"
                                          [candidateType]="candidateType"
                                          [workflowState]="workflowState"
                                          [isSca]="isSca"
                                          [isWarehouse]="isWarehouse"
                                          [candidateProductIndex]="candidateProductIndex"
                                          [candidateIdToCandidateErrorMap]="candidateIdToCandidateErrorMap">
        </pm-upload-supplier-and-heb-table>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="isShowingWarehouseTab" header="{{WAREHOUSE_TAB.title}}" tooltip="{{WAREHOUSE_TAB.tooltip}}"
                leftIcon="{{hasWarehouseDetailErrors ? 'pi pi-exclamation-triangle' : ''}}" headerStyleClass="{{isWarehouseTabSelected ? 'black-error-triangle-table' : 'red-error-triangle-table'}}">
      <div class="object-manager-page">
        <pm-upload-warehouse-table [(candidates)]="candidates"
                                   [isBuyer]="isBuyer"
                                   [isSca]="isSca"
                                   [candidateProductIndex]="candidateProductIndex"
                                   [candidateIdToCandidateErrorMap]="candidateIdToCandidateErrorMap">
        </pm-upload-warehouse-table>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="isShowingDsdTab" header="{{DSD_TAB.title}}" tooltip="{{DSD_TAB.tooltip}}"
                leftIcon="{{hasDsdErrors ? 'pi pi-exclamation-triangle' : ''}}" headerStyleClass="{{isDsdTabSelected ? 'black-error-triangle-table' : 'red-error-triangle-table'}}">
      <div class="object-manager-page">
        <pm-upload-dsd-table [(candidates)]="candidates"
                             [isBuyer]="isBuyer"
                             [candidateProductIndex]="candidateProductIndex"
                             [candidateIdToCandidateErrorMap]="candidateIdToCandidateErrorMap">
        </pm-upload-dsd-table>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
