import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {OnlyNumberDirective} from './directives/only-number.directive';
import {UpcCheckDigitPipe} from './pipes/upc-check-digit.pipe';
import {CodeDateDaysPipe} from './pipes/code-date-days.pipe';
import {StepperModule} from './components/stepper/stepper.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StepperModule
  ],
  declarations: [
    OnlyNumberDirective,
    UpcCheckDigitPipe,
    CodeDateDaysPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OnlyNumberDirective,
    UpcCheckDigitPipe,
    CodeDateDaysPipe,
    StepperModule
  ],
  providers: [
  ],
  entryComponents: [
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule { }
