import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper} from '../attribute-permissions-mapper';

import {
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  AttributeUPCConfig,
  CandidateProduct,
  CandidateProductError,
  ProductBasicsCardModel,
  ProductBasicsCardPermissions
} from 'pm-models';
import {UPCInputState} from '../../shared/upc-inputs/base-upc-input/base-upc-input.component';

@Component({
  selector: 'pm-product-basics-card',
  templateUrl: './product-basics-card.component.html',
  styleUrls: ['./product-basics-card.component.scss']
})
export class ProductBasicsCardComponent implements OnInit {
  @Input()
  model: ProductBasicsCardModel | CandidateProduct;

  @Input()
  permissions: ProductBasicsCardPermissions;

  @Input()
  upcState: UPCInputState;

  @Input()
  errorModel: CandidateProductError;

  brand;
  @Input()
  get selectedBrand() {
    return this.brand;
  }
  set selectedBrand(e) {
    this.brand = e;
    this.subBrandConfiguration.collections = this.brand.subBrands;
  }

  get availableSubBrands() {
    if (this.selectedBrand && this.selectedBrand.subBrands) {
      return this.selectedBrand.subBrands;
    }
    return [];
  }

  @Output() modelChange = new EventEmitter<any>();
  @Output() validateUPC = new EventEmitter<any>();

  vendorProductCodeConfiguration: AttributeTextInputConfig = {
    label: 'VPC',
    description: 'Enter the manufacturer or vendor product code.',
    placeholderText: 'Enter VPC...',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.vendorProductCode),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.vendorProductCode),
    isRequired: true,
    name: 'vpcId',
    maxLength: 20
  };

  vendorProductCodeChange(event) {
    this.modelChange.emit(this.model);
  }

  countryOfOriginConfiguration: AttributeTypeaheadConfig = {
    label: 'Country of origin',
    description: '',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.countryOfOrigin),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.countryOfOrigin),
    name: 'countryOfOriginId',
    displayRef: 'description',
    placeholderText: 'Select or search a country...',
    searchUrl: '/lookup/countryOfOrigin'
  };

  countryOfOriginChange(event) {
    this.model.countryOfOrigin = event;
    this.modelChange.emit(this.model);
  }

  subBrandConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-brand',
    description: '',
    isRequired: false,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.subBrand),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.subBrand),
    name: 'subBrandId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-brand...',
    collections: []
  };

  subBrandChange(event) {
    this.model.subBrand = event;
    this.modelChange.emit(this.model);
  }

  caseUPCConfiguration: AttributeUPCConfig = {
    label: 'Case UPC',
    description: `Enter the UPC and check digit for
    the case this product will arrive in. You can’t enter a UPC that already exists in our system.`,
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.caseUpc),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.caseUpc),
    placeholderText: 'Case UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'caseUpcId'
  };

  upcChange(event) {
    this.model.caseUpc = event;
    this.modelChange.emit(this.model);
  }

  checkDigitModelChange(event) {
    this.model.caseUpcCheckDigit = event;
    this.modelChange.emit(this.model);
  }

  validateUpcEvent(e) {
    this.validateUPC.emit(e);
  }
  caseDescriptionConfiguration: AttributeTextInputConfig = {
    label: 'Case description',
    description: `Provide a short description of the case. Customers won't see this.`,
    placeholderText: 'Description',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.caseDescription),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.caseDescription),
    isRequired: true,
    name: 'caseDescriptionId',
    maxLength: 30
  };

  descriptionChange(event) {
    // Todo: I don't know what description currently maps to
    this.modelChange.emit(this.model);
  }
  constructor() {}

  ngOnInit() {}
}
