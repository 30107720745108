
/**
 * Calculate check digit from upc
 * @param code
 */
export function calculateCheckDigit(code): number {
  let check = 0;
  code = code.toString();

  while (code.length < 13) { code = '0' + code; }

  for (let i = 0; i < code.length; i += 2) {
    check += parseInt (code.charAt(i), 10);
  }

  check *= 3;

  for (let i = 1; i < code.length; i += 2) {
    check += parseInt (code.charAt(i), 10);
  }

  check %= 10;
  check = (check === 0) ? check : 10 - check;

  return check;
}

export function getTypeAFormat(upc: string): string {
  const checkDigit = calculateCheckDigit(upc);

  if (upc.length === 10) {
    return '0' + '-' +
      upc.substr(0, 5) + '-' +
      upc.substr(5, 5) + '-' + checkDigit;
  } else {
    return upc + '-' + checkDigit;
  }
}
