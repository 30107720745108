<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>
<ng-template #card>
  <pm-card title="Max ship" subtitle="Set max ship for all warehouses.">
    <ng-container *ngIf="candidateModel">
      <pm-attribute-display-text-input [attribute]="maxShipConfiguration"
                                       [(model)]="candidateModel.maxShip"
                                       [attributeError]="candidateErrorModel?.maxShip">
      </pm-attribute-display-text-input>
    </ng-container>

  </pm-card>
</ng-template>
