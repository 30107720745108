/**
 * Information used to help identify an attribute.
 *
 * @author s573181
 * @since 1.58.0
 */
import {Code} from 'pm-models';

export class MetaDataIdentifiers {

  applicableTypeCode: Code;
  audienceCode?: Code;
  fieldId: string;
}
