export class TaskDecisionModel {
  candidateId: number;
  taskDecisionName: string;
  taskDecisionValue: string;


  constructor(candidateId: number, taskDecisionName?: string, taskDecisionValue?: string) {
    this.candidateId = candidateId;
    this.taskDecisionName = taskDecisionName;
    this.taskDecisionValue = taskDecisionValue;
  }
}
