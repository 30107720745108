import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {EditCandidateModalServiceMock} from './edit-candidate-modal-service-mock.service';
import {LookupServiceMock} from './lookup-service-mock.service';
import { RegulatoryCardComponent } from './regulatory-card.component';

import { CardModule } from '../../shared/card/card.module';

import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [RegulatoryCardComponent],
  exports: [RegulatoryCardComponent, AttributeDisplayModule, CardModule],
  imports: [
    AttributeDisplayModule,
    CardModule,
    CommonModule,
    RouterModule
  ],
  providers: [
    { provide: EditCandidateModalService, useClass: EditCandidateModalServiceMock },
    { provide: LookupService, useClass: LookupServiceMock }]
})
export class RegulatoryCardModule { }
