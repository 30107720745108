import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'pm-buyer-drop-down-filter',
  templateUrl: './buyer-drop-down-filter.component.html',
  styleUrls: ['./buyer-drop-down-filter.component.scss']
})
export class BuyerDropDownFilterComponent implements OnInit {

  @Input()
  buyers: any[];

  @Input()
  buyerFilters: string[];

  @Output()
  dropDownChange: EventEmitter<any> = new EventEmitter<any>();

  followedBuyers: any[];
  notFollowedBuyers: any[];

  isSearchFormOpen: boolean = false;

  searchText: string = '';

  followedBuyersModel: any = [];
  notFollowedBuyersModel: any = [];

  constructor() { }

  ngOnInit() {
    if (!this.buyers) {
      return;
    }
    this.followedBuyers = this.buyers.filter((buyer) => buyer.isFollowed === true);
    this.notFollowedBuyers = this.buyers.filter((buyer) => buyer.isFollowed === false);

    this.checkBuyerFiltersFromPreferences(this.followedBuyers, true);
    this.checkBuyerFiltersFromPreferences(this.notFollowedBuyers, false);
  }

  clickBuyerButton() {
    if (this.isSearchFormOpen) {
      // same as clicking on apply button
      this.onClickApplyButton();
    } else {
      // toggle hide/show search form
      this.isSearchFormOpen = !this.isSearchFormOpen;
    }
  }

  onClickClearButton() {
    this.followedBuyersModel = [];
    this.notFollowedBuyersModel = [];
  }

  onClickApplyButton() {
    // hide search form
    this.isSearchFormOpen = !this.isSearchFormOpen;

    // emit selected items to caller
    const selectedItems: any[] = [];

    this.followedBuyersModel.forEach(e => {
      selectedItems.push(this.findItem(e));
    });

    this.notFollowedBuyersModel.forEach(e => {
      selectedItems.push(this.findItem(e));
    });

    this.dropDownChange.emit(selectedItems);
  }

  numberSelectedBuyers() {
    return this.followedBuyersModel.length + this.notFollowedBuyersModel.length;
  }

  onChangeFollowedBuyersModel(event: any) {
  }

  onChangeNotFollowedBuyersModel(event: any) {
  }

  findItem(id: string) {
    return this.buyers.find(item => id === item.buyerId);
  }

  /**
   * Updates selected candidate lists with values from user preferences.
   * @param buyerList the buyer list.
   * @param isFollowingBuyers whether this is is using the followed buyers or non followed buyers.
   */
  checkBuyerFiltersFromPreferences(buyerList: any[], isFollowingBuyers: boolean) {
    // if any of the lists are empty, just return.
    if (!this.buyerFilters || this.buyerFilters.length === 0 || !buyerList || buyerList.length === 0) {
      return;
    }

    const selectedBuyerList = buyerList.filter((buyer) => this.buyerFilters.includes(buyer.buyerId))
      .map((buyer) => buyer.buyerId);
    if (isFollowingBuyers) {
      this.followedBuyersModel = selectedBuyerList;
    } else {
      this.notFollowedBuyersModel = selectedBuyerList;
    }
  }
}
