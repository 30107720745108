import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {
  AttributeConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError, EmailMessage,
  SupplierDetailsCardModel,
  SupplierDetailsCardPermissions
} from 'pm-models';
import {
  DisabledPermissionsMapper,
  ReadOnlyPermissionsMapper,
  VisiblePermissionsMapper
} from '../attribute-permissions-mapper';
import {FormModel} from '../../shared/request-new-attribute-form/request-new-attribute-form.component';
import {AuthService} from '../../../../../../src/app/2.0.0/auth/auth.service';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';

@Component({
  selector: 'pm-supplier-details-card',
  templateUrl: './supplier-details-card.component.html',
  styleUrls: ['./supplier-details-card.component.scss']
})
export class SupplierDetailsCardComponent implements OnInit {

  @Input()
  candidateModel: Candidate | SupplierDetailsCardModel;

  @Input()
  candidateProductModel: CandidateProduct | SupplierDetailsCardModel;

  @Input()
  permissions: SupplierDetailsCardPermissions;

  @Input()
  candidateErrorModel: CandidateError;

  @Input()
  candidateProductErrorModel: CandidateProductError;

  private static readonly NEW_PRODUCT_TYPES = [Candidate.NEW_PRODUCT, Candidate.PLU, Candidate.MRT_INNER];

  _inputCostOwners;
  @Input()
  get inputCostOwners() {
    return this._inputCostOwners;
  }

  set inputCostOwners(e) {
    this._inputCostOwners = e;
    this.availableCostOwners = this._inputCostOwners;
    this.costOwnerConfiguration.collections = this.availableCostOwners;
    this.costOwnerConfiguration = JSON.parse(JSON.stringify(this.costOwnerConfiguration));
  }

  @Output() candidateModelChange = new EventEmitter<any>();
  @Output() supplierChange = new EventEmitter<any>();
  @Output() candidateProductModelChange = new EventEmitter<any>();

  availableCostOwners: any;
  availableWarehouseSuppliers: any;
  formModel: FormModel;

  constructor(public authService: AuthService, private lookupService: LookupService, private candidateService: CandidateService,
              public permissionService: NgxPermissionsService, public candidateUtilService: CandidateUtilService) {}

  ngOnInit() {
    this.setSupplierConfiguartion();
  }

  ngAfterViewInit() {
    // have to use this life hook to set the static list
    if (this.inputCostOwners !== undefined) {
      this.availableCostOwners = this.inputCostOwners;
      this.costOwnerConfiguration.collections = this.availableCostOwners;
    }
  }

  brandConfiguration: AttributeTypeaheadConfig = {
    label: 'Brand',
    description: `Choose this product's brand.`,
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.brand),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.brand),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.brand),
    name: 'brandTypeAhead',
    idRef: 'brandId',
    displayRef: 'displayName',
    placeholderText: 'Select or search for a brand...',
    searchUrl: '/lookup/brand',
    showButtonOption: true,
    buttonLabel: 'Request a new brand'
  };

  onBrandChange($event) {
    // Brand Id can be 0, causing error. So had to check individual empty fields options.
    if ($event?.brandId === null || $event?.brandId === undefined) {
      this.candidateModel.brand = null;

      // Don't clear cost owner if it's disabled by the parent (i.e. Pharmacy). If there's no permissions, it's enabled by default.
      if (!this.permissions.costOwner || !this.permissions.costOwner.isDisabled) {
        this.candidateModel.costOwner = null;
      }

      this.availableCostOwners = [];
      this.costOwnerConfiguration.collections = [];
      this.candidateModelChange.emit(this.candidateModel);
      this.candidateProductModelChange.emit(this.candidateProductModel);
    } else if (!this.candidateModel.brand || this.candidateModel.brand.brandId !== $event.brandId) {
      this.candidateModel.brand = $event;

      this.availableCostOwners = $event.costOwners;
      this.costOwnerConfiguration.collections = $event.costOwners;

      // Don't clear cost owner if it's disabled by the parent (i.e. Pharmacy). If there's no permissions, it's enabled by default.
      if (!this.permissions.costOwner || !this.permissions.costOwner.isDisabled) {
        this.candidateModel.costOwner = null;
      }

      this.candidateModelChange.emit(this.candidateModel);
      this.candidateProductModelChange.emit(this.candidateProductModel);    }
  }

  subBrandConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-brand',
    description: '',
    isRequired: false,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.subBrand),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.subBrand),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.subBrand),
    name: 'subBrandId',
    idRef: 'subBrandId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-brand...',
    searchUrl: '/lookup/subBrand'
  };

  subBrandChange(event) {
    this.candidateProductModel.subBrand = event;
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }

  costOwnerConfiguration: AttributeTypeaheadConfig = {
    label: 'Cost owner',
    description: 'Select who is responsible for the cost of the product.',
    name: 'costOwnerId',
    isRequired: true,
    isDisabled: () =>
      DisabledPermissionsMapper(this.permissions, this.permissions.costOwner) ||
      !(this.availableCostOwners && this.availableCostOwners.length > 0),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.costOwner),
    collections: this.availableCostOwners,
    displayRef: 'displayName',
    placeholderText: 'Select or search for a cost owner...',
    showButtonOption: true,
    buttonLabel: 'Request a new cost owner'
  };

  costOwnerChange($event) {
    this.candidateModel.costOwner = $event;
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }

  get topToTopModel(): string {
    if (this.candidateModel && this.candidateModel.costOwner && this.candidateModel.costOwner.topToTopDisplayName) {
      return this.candidateModel.costOwner.topToTopDisplayName;
    }
    return '';
  }

  topToTopConfiguration: AttributeConfig = {
    label: 'Top to Top',
    isRequired: true,
    inputGroupClass: 'typeahead-width',
    isReadOnly: () => true
  };

  supplierConfiguration: AttributeTypeaheadConfig;

  setSupplierConfiguartion () {
    let url;
    if (this.candidateModel && this.candidateModel.dsdSwitch) {
      url = '/lookup/vendordsd';
    } else {
      url = '/lookup/whsvendor';
    }
    this.supplierConfiguration = {
      label: 'Supplier',
      description: 'The supplier of record that H-E-B pays.',
      isRequired: true,
      isHidden: () => this.isVendorHidden(),
      isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.vendor),
      isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.vendor),
      name: 'vendorTypeAhead',
      displayRef: 'displayName',
      placeholderText: 'Select or search for a Supplier...',
      searchUrl: url,
      idRef: 'apNumber'
    };
  }

  onSupplierChange($event) {
    this.candidateModel.vendor = $event;
    if ($event) {
      this.availableWarehouseSuppliers = $event.lanes;
      this.warehouseSupplierConfiguration.collections = $event.lanes;
    } else {
      this.availableWarehouseSuppliers = null;
      this.warehouseSupplierConfiguration.collections = null;
    }
    this.candidateModel.lane = null;
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
    this.supplierChange.emit(this.candidateModel.vendor);
  }

  warehouseSupplierConfiguration: AttributeTypeaheadConfig = {
    label: 'Warehouse supplier',
    description: 'Where the product is shipped from (origin).',
    isRequired: true,
    isDisabled: () =>
      DisabledPermissionsMapper(this.permissions, this.permissions.lane) ||
      !(this.availableWarehouseSuppliers && this.availableWarehouseSuppliers.length > 0),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.lane),
    isHidden: () => this.isLaneHidden(),
    collections: this.availableWarehouseSuppliers,
    displayRef: 'name',
    name: 'warehouseId',
    placeholderText: 'Select a warehouse...'
  };

  isLaneHidden(): boolean {
    return VisiblePermissionsMapper(this.permissions, this.permissions.lane) || this.candidateModel.dsdSwitch;
  }

  isVendorHidden(): boolean {
    return VisiblePermissionsMapper(this.permissions, this.permissions.vendor);
  }

  warehouseSupplierChange($event) {
    this.candidateModel.lane = $event;
    this.candidateModelChange.emit(this.candidateModel);
    this.candidateProductModelChange.emit(this.candidateProductModel);
  }

  brandInitCallback($event) {
    if ($event.costOwners) {
      this.availableCostOwners = $event.costOwners;
      this.costOwnerConfiguration.collections = $event.costOwners;

    }
  }

  supplierInitCallback($event) {
    if ($event.lanes) {
      this.availableWarehouseSuppliers = $event.lanes;
      this.warehouseSupplierConfiguration.collections = $event.lanes;
    }
  }

  async sendRequestAndCloseModal(returned, panel, target) {
    // Only set the brand if this is a new product type, as existing products already have an existing brand association.
    if (returned.brand && (!this.candidateModel.candidateType ||
      SupplierDetailsCardComponent.NEW_PRODUCT_TYPES.includes(this.candidateModel.candidateType))) {
      this.candidateModel.brand = {brandId: CandidateUtilService.PENDING_ID, description: 'Pending: ' + returned.brand,
        displayName: 'Pending: ' + returned.brand, pending: true};
    }
    if (returned.costOwner) {
      this.candidateModel.costOwner = {costOwnerId: CandidateUtilService.PENDING_ID, costOwnerName: 'Pending: ' + returned.costOwner,
        displayName: 'Pending: ' + returned.costOwner, pending: true};
    }
    if (returned.topToTop) {
      this.candidateModel.costOwner = {
        costOwnerId: CandidateUtilService.PENDING_ID, costOwnerName: 'Pending: ' + returned.costOwner,
        displayName: 'Pending: ' + returned.costOwner,
        topToTopId: CandidateUtilService.PENDING_ID, topToTopName: 'Pending: ' + returned.topToTop, pending: true};
    }

    const user = this.authService.getUser();

    let fromEmail: string;
    if (this.authService.isVendor()) {
      fromEmail = this.candidateModel.contactEmail;
    } else {
      fromEmail = await this.candidateService.getCurrentPartnerEmail().toPromise().then();
    }

    let body = user + ' has requested a new attribute value.' + '<br />' + '<br />';

    body += 'Brand: ' + returned.brand + '<br />';
    body += 'Cost owner: ' + returned.costOwner + '<br />';
    body += 'Top to top: ' + returned.topToTop + '<br />';
    body += 'H-E-B Vendor: ' + (!!returned.supplier ? returned.supplier : '—') + '<br />';
    body += 'Additional details: ' + (!!returned.notes ? returned.notes : '—') + '<br />';


    const emailMessage = new EmailMessage('DataManagement@heb.com', '', fromEmail,
      '', 'PAM – New attribute request', body, returned.email, '');
    this.lookupService.sendEmail(emailMessage).subscribe(() => {
        this.candidateModelChange.emit(this.candidateModel);
        panel.hide();
      }
    );
  }

  showRequestNewAttributeConfirmationPanel(event, panel, target, previousPanel) {
    panel.show(event, target);
    previousPanel.hide();
  }
  showPanel(event, panel, target) {
    panel.show(event, target);
  }
}
