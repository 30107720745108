import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, throwError as observableThrowError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgxPermissionsService} from 'ngx-permissions';
import {AuthCookie} from './auth-cookie';
import {GrowlService} from '../growl/growl.service';
import {Permission} from 'pm-models';
import {PublisherService} from '../service/publisher.service';
import {UserRoleConstants} from '../core/header/user-role-constants';
import {PreferencesService} from '../service/preferences.service';
import {UserPreferences} from 'pm-models/lib/userPreferences';
import {Router} from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'Basic c3ByaW5nLXNlY3VyaXR5LW9hdXRoMi1yZWFkLXdyaXRlLWNsaWVudDpzcHJpbmctc2VjdXJpd' +
      'Hktb2F1dGgyLXJlYWQtd3JpdGUtY2xpZW50LXBhc3N3b3JkMTIzNA=='
  })
};

const authServiceGetToken = '/auth/oauth/token';
const authServiceCheckToken = '/auth/oauth/check_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // local storage keys
  private authFlag = 'isLoggedIn';
  private token = 'token';
  private roles = 'roles';
  private user = 'user';
  private userEmail = 'userEmail';
  private accessTokenExpireTime = 'accessTokenExpireTime';
  private sessionTimeoutTime = 'sessionTimeoutTime';
  private sessionTimeoutInSeconds = 3600000; // 1hr in msec
  private currentRoleKey = 'currentRole';

  public hasCheckedBDAorSCA = 'hasCheckedBDAorSCA';

  // Cookie keys
  private OATH_TOKEN_COOKIE_NAME = 'X-PAM-LOGIN-TOKEN';
  private OATH_REFRESH_TOKEN_COOKIE_NAME = 'X-PAM-REFRESH-TOKEN';
  private OATH_EXPIRES_IN_TOKEN_NAME = 'X-PAM-EXPIRES-IN-TOKEN';
  private OATH_ACCOUNT_TYPE_TOKEN_NAME = 'X-PAM-ACCOUNT-TYPE-TOKEN';

  isRefreshingToken = false;

  // Create stream for token
  token$ = new BehaviorSubject<string>(null);

  // Create stream for user profile data
  userProfile$ = new BehaviorSubject<any>(null);

  /**
   * streams the error message to the Growl component
   * @type {Subject<string>}
   */
  private errorMessageSource = new Subject<string>();
  errorMessage$ = this.errorMessageSource.asObservable();

  /**
   * streams the success message to the Growl component
   * @type {Subject<string>}
   */
  private successMessageSource = new Subject<string>();
  successMessage$ = this.successMessageSource.asObservable();

  constructor(private http: HttpClient, private growlService: GrowlService,
              private permissionService: NgxPermissionsService,
              private publisherService: PublisherService,
              private preferencesService: PreferencesService,
              private _injector: Injector) {
    if (this.isAuthenticated()) {
      this.addRoles(atob(sessionStorage.getItem(this.roles)).split(','));
    }
  }

  login(userid: string, password: string): any  {
    return this.getToken(userid, password).subscribe(token => {
      this.checkToken(token.access_token).subscribe(roles => {
        sessionStorage.setItem(this.roles, btoa(roles.authorities));
        this.addRoles(roles.authorities);
        this.localLogin(token, roles);
        this.setSessionTimeout();
        this.setExpireTokenTime(token);
        this.addSuccess('success');
      });
    });
  }

  addError(error: any) {
    this.errorMessageSource.next(error);
  }

  addSuccess(success: string) {
    this.successMessageSource.next(success);
  }

  /**
   * Set the time the token will expire
   * @param token
   */
  public setExpireTokenTime(token) {
    const expireTokenTime = new Date();
    expireTokenTime.setTime(expireTokenTime.getTime() + token.expires_in * 1000);
    sessionStorage.setItem(this.accessTokenExpireTime, '' + btoa(expireTokenTime.getTime().toString()));
  }

  /**
   * Set the session timeout
   */
  private setSessionTimeout() {
    if (this.isAuthenticated()) {
      const expireSessionTime = new Date();
      expireSessionTime.setTime(expireSessionTime.getTime() + this.sessionTimeoutInSeconds);
      sessionStorage.setItem(this.sessionTimeoutTime, '' + btoa(expireSessionTime.getTime().toString()));
    }
  }

  /**
   * Check to see if the token expire time is set
   * @return {boolean}
   */
  private doesExpireTokenTimeExist() {
    const accessTokenExpireTime = atob(sessionStorage.getItem(this.accessTokenExpireTime));

    return accessTokenExpireTime !== null;
  }

  /**
   * Check if the session has expired
   * @return {boolean}
   */
  public isSessionExpired(): boolean {
    const sessionTimeoutTime = atob(sessionStorage.getItem(this.sessionTimeoutTime));
    if (new Date().getTime() > parseInt(sessionTimeoutTime, 10)) {
      setTimeout(() => {
        this.growlService.addError('Your session has expired. Please login.');
      }, 0);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check if the access token has expired
   * @return {boolean}
   */
  public isAccessTokenExpired(): boolean {
    const accessTokenExpireTime = atob(sessionStorage.getItem(this.accessTokenExpireTime));
    return new Date().getTime() > parseInt(accessTokenExpireTime, 10);
  }

  /**
   * Refresh the token against the http auth service.
   */
  refreshToken(): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('grant_type', 'refresh_token');

    const refreshToken = JSON.parse(atob(sessionStorage.getItem(this.token))).refresh_token;
    formData.append('refresh_token', refreshToken);

    return this.http.post<any>(authServiceGetToken, formData, httpOptions)
      .pipe(
        catchError(this.handleAndDisplayError<any>('did not get refresh token'))
      );
  }

  private getToken(userid: string, password: string): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', userid);
    formData.append('password', password);

    return this.http.post<any>(authServiceGetToken, formData, httpOptions)
      .pipe(
        catchError(this.handleAndDisplayError<any>('did not get token'))
      );
  }

  private checkToken(access_token: string): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('token', access_token);

    return this.http.post<any>(authServiceCheckToken, formData, httpOptions)
      .pipe(
        catchError((error) => {
            if (error && error.status === 400) {
              this.localLogout();
              this._router.navigate(['/login']).then(() => {
                this.growlService.addError('Invalid credentials. Please login.');
              });
            } else {
              this.handleAndDisplayError<any>('did not check token');
            }
            return of({});
          }
        )
      );
  }

  private localLogin(authResult, checkTokenResults) {
    this.token$.next(authResult.access_token);

    // Emit value for display name for user
    this.userProfile$.next(checkTokenResults.display_name);

    // Set flag in local storage stating this user is logged in
    sessionStorage.setItem(this.authFlag, btoa(JSON.stringify(true)));

    this.setAuthToken(authResult);
    this.setUser(JSON.stringify(checkTokenResults.display_name));
    this.setUserEmail(JSON.stringify(checkTokenResults.user_email));
  }

  public setAuthToken(token) {
    // Set auth token in local storage
    sessionStorage.setItem(this.token, btoa(JSON.stringify(token)));
  }

  public setUser(userDisplayName) {
    sessionStorage.setItem(this.user, btoa(userDisplayName));
  }

  public setUserEmail(userDisplayName) {
    sessionStorage.setItem(this.userEmail, btoa(userDisplayName));
  }

  public isAuthenticated(): boolean {
    if (sessionStorage.getItem(this.authFlag)) {
      const result = JSON.parse(atob(sessionStorage.getItem(this.authFlag)));
      return (this.doesExpireTokenTimeExist() && result);
    }
    return false;
  }

  isNotAuthenticatedLogout() {
    if (!this.isAuthenticatedWithAuthCookie()) {
      this.localLogout();
    }
  }

  public localLogout() {
    this.permissionService.flushPermissions();
    sessionStorage.setItem(this.authFlag, btoa(JSON.stringify(false)));
    sessionStorage.removeItem(this.token);
    sessionStorage.removeItem(this.roles);
    sessionStorage.removeItem(this.user);
    sessionStorage.removeItem(this.userEmail);
    sessionStorage.removeItem(this.accessTokenExpireTime);
    sessionStorage.removeItem(this.sessionTimeoutTime);
    sessionStorage.removeItem(this.sessionTimeoutTime);
    sessionStorage.removeItem(this.hasCheckedBDAorSCA);
    sessionStorage.removeItem(this.currentRoleKey);
    this.removeAuthCookies();
    this.token$.next(null);
  }

  /**
   * Returns the user name.
   * @returns {string}
   */
  getUser(): string {
    return JSON.parse(atob(sessionStorage.getItem(this.user)));
  }

  /**
   * Returns the user email.
   * @returns {string}
   */
  getUserEmail(): string {
    return JSON.parse(atob(sessionStorage.getItem(this.userEmail)));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleAndDisplayError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMessage = `Error in ${operation} failed: ${error.message}`;
      this.addError(errorMessage);

      return observableThrowError(errorMessage);
    };
  }

  /**
   * Returns whatever is stored in the current token. May be null.
   *
   * @returns {string} Whatever is stored in the current token.
   */
  get currentToken(): string {
    if (this.isAuthenticatedWithAuthCookie()) {
      return this.getCookieValue(this.OATH_TOKEN_COOKIE_NAME);
    } else {
      return JSON.parse(atob(sessionStorage.getItem(this.token))).access_token;
    }
  }

  /**
   * Extend the inactivity session timeout after the user took some action
   */
  public updateActionTime() {
    if (!this.isSessionExpired()) {
      this.setSessionTimeout();
    }
  }
  /**
   * Returns whether or not a user is in the given role.
   * If the user doesn't have the role, it returns false.
   * If the user only has this role, returns true.
   * If the user has more than 1 role it verifies against the preferences-
   *
   * If there's a current role, it returns whether or not it equals supplied user role (already verified for permissions)
   * If there's no preferences or it's a role the user no longer has, then it verifies against the default role.
   * If there's preferences and it equals the supplied role, it returns true, else returns false.
   * @param userRole the user role.
   */
  public isInUserRole(userRole: string) {
    if (!userRole || !this.permissionService.getPermission(userRole)) {
      return false;
    } else if (this.hasMoreThanOneRole()) {
      const currentRole = this.getCurrentRole();
      if (currentRole) {
        return currentRole === userRole;
      } else {
        this.initializeCurrentRole().subscribe((role) => {
          return role === userRole;
        });
      }
    } else {
      return true;
    }
  }

  /**
   * Sets the current role for a user. If the current role is already sets, does nothing. Else,
   * it checks the preferences, and sets the role if one exists, else it sets the default role based
   * on the role hierarchy and the roles the user currently has.
   */
  initializeCurrentRole(): Observable<string> {
    const currentStoredRole = this.getCurrentRole();

    if (currentStoredRole) {
      return of(currentStoredRole);
    }
    return this.preferencesService.getPreferences().pipe(
      map((preferences) => {
          // if there's no preference, or they no long have the role of their preference- compare against default role.
          if (!preferences || !preferences.currentUserRole || !this.permissionService.getPermission(preferences.currentUserRole)) {
            const currentRoleToSet = this.getDefaultRole();
            sessionStorage.setItem(this.currentRoleKey, btoa(JSON.stringify(currentRoleToSet)));
            return currentRoleToSet;
            // return if current preference role is equal to current role
          } else {
            sessionStorage.setItem(this.currentRoleKey, btoa(JSON.stringify(preferences.currentUserRole)));
            return preferences.currentUserRole;
          }
        }
      )
    );
  }

  setCurrentRole(role): Observable<any> {

    if (!role || !this.permissionService.getPermission(role)) {
      this.growlService.addError('User doesn\'t have the requested role: ' + role);
      return of();
    } else {
      sessionStorage.setItem(this.currentRoleKey, btoa(JSON.stringify(role)));
      return this.preferencesService.getPreferences().pipe(
        map(
          (preference) => {
            if (preference) {
              preference.currentUserRole = role;
              return preference;
              // return this.preferencesService.updatePreferences(preference);
            } else {
              return { sendEmails: false, hasSetupPreferences: false, followingDesks: [], currentUserRole: role };
              // return this.preferencesService.updatePreferences(userPreference);
            }
          }),
        switchMap(
          (preference: UserPreferences) =>
            this.preferencesService.updatePreferences(preference))
      );
    }
  }

  /**
   * Returns the current role from the session storage, or else null.
   */
  getCurrentRole() {
    const base64Role = sessionStorage.getItem(this.currentRoleKey);

    if (!base64Role) {
      return null;
    } else {
      return JSON.parse(atob(sessionStorage.getItem(this.currentRoleKey)));
    }
  }

  /**
   * Returns the default role for the roles that the user has.
   */
  getDefaultRole() {
    const userRoles = UserRoleConstants.USER_ROLES.filter((role) => this.permissionService.getPermission(role));

    for (let x = 0; x < UserRoleConstants.DEFAULT_ROLE_ORDER.length; x++) {
      if (userRoles.includes(UserRoleConstants.DEFAULT_ROLE_ORDER[x])) {
        return UserRoleConstants.DEFAULT_ROLE_ORDER[x];
      }
    }
  }


  /**
   * Returns whether or not a user is in the vendor role.
   * If the user doesn't have the role, it returns false.
   * If the user only has this role, returns true.
   * If the user has more than 1 role it verifies against the preferences-
   *
   * If there's a current role, it returns whether or not it equals supplied user role (already verified for permissions)
   * If there's no preferences or it's a role the user no longer has, then it verifies against the default role.
   * If there's preferences and it equals the vendor role, it returns true, else returns false.
   */
  public isVendor() {
    return this.isInUserRole(UserRoleConstants.VENDOR_ROLE);
  }

  /**
   * Returns whether or not a user is in the PIA role.
   * If the user doesn't have the role, it returns false.
   * If the user only has this role, returns true.
   * If the user has more than 1 role it verifies against the preferences-
   *
   * If there's a current role, it returns whether or not it equals supplied user role (already verified for permissions)
   * If there's no preferences or it's a role the user no longer has, then it verifies against the default role.
   * If there's preferences and it equals the PIA role, it returns true, else returns false.
   */
  public isPia() {
    return this.isInUserRole(UserRoleConstants.PROCUREMENT_SUPPORT_ROLE);
  }

  /**
   * Returns whether or not a user is in the Buyer role.
   * If the user doesn't have the role, it returns false.
   * If the user only has this role, returns true.
   * If the user has more than 1 role it verifies against the preferences-
   *
   * If there's a current role, it returns whether or not it equals supplied user role (already verified for permissions)
   * If there's no preferences or it's a role the user no longer has, then it verifies against the default role.
   * If there's preferences and it equals the Buyer role, it returns true, else returns false.
   */
  public isBuyer() {
    return this.isInUserRole(UserRoleConstants.BUYER_ROLE);
  }

  /**
   * Returns whether or not a user is in the Own Brand Regulatory role.
   * If the user doesn't have the role, it returns false.
   * If the user only has this role, returns true.
   * If the user has more than 1 role it verifies against the preferences-
   *
   * If there's a current role, it returns whether or not it equals supplied user role (already verified for permissions)
   * If there's no preferences or it's a role the user no longer has, then it verifies against the default role.
   * If there's preferences and it equals the Own Brand Regulatory role, it returns true, else returns false.
   */
  public isObReg() {
    return this.isInUserRole(UserRoleConstants.OWN_BRAND_REGULATORY_ROLE);
  }

  /**
   * Returns whether or not a user is in the Pharmacy role.
   * If the user doesn't have the role, it returns false.
   * If the user only has this role, returns true.
   * If the user has more than 1 role it verifies against the preferences-
   *
   * If there's a current role, it returns whether or not it equals supplied user role (already verified for permissions)
   * If there's no preferences or it's a role the user no longer has, then it verifies against the default role.
   * If there's preferences and it equals the Pharmacy role, it returns true, else returns false.
   */
  public isPharm() {
    return this.isInUserRole(UserRoleConstants.PHARMACY_ROLE);
  }

  /**
   * Returns whether or not a user is in the Supply Chain Analyst role.
   * If the user doesn't have the role, it returns false.
   * If the user only has this role, returns true.
   * If the user has more than 1 role it verifies against the preferences-
   *
   * If there's a current role, it returns whether or not it equals supplied user role (already verified for permissions)
   * If there's no preferences or it's a role the user no longer has, then it verifies against the default role.
   * If there's preferences and it equals the Supply Chain Analyst role, it returns true, else returns false.
   */
  public isSca() {
    return this.isInUserRole(UserRoleConstants.SUPPLY_CHAIN_ANALYST_ROLE);
  }

  /**
   * Add privileges for this user
   * @param roles
   */
  addRoles(roles: string []) {
    this.permissionService.loadPermissions(this.generateUIRoles(roles));
  }

  /**
   * Given a cookie key `name`, returns the value of
   * the cookie or `null`, if the key is not found.
   */
  getCookieValue(name: string): string {
    const nameLenPlus = (name.length + 1);
    return document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null;
  }


  /**
   * Constructs a auth token object from cookies
   */
  constructAuthCookieObject(): AuthCookie {
    return new AuthCookie(
      this.getCookieValue(this.OATH_TOKEN_COOKIE_NAME),
      this.getCookieValue(this.OATH_REFRESH_TOKEN_COOKIE_NAME),
      this.getCookieValue(this.OATH_EXPIRES_IN_TOKEN_NAME),
      this.getCookieValue(this.OATH_ACCOUNT_TYPE_TOKEN_NAME));
  }

  public isAuthenticatedWithAuthCookie(): boolean {
    return document.cookie.includes(this.OATH_TOKEN_COOKIE_NAME);
  }

  /**
   *
   * @param bookMarkUrl
   */
  loginWithCookieToken(bookMarkUrl: string): Observable<any> {
    return new Observable((observer) => {
      const token = this.constructAuthCookieObject();

      // setting token expire time first because token interceptor needs this
      this.setExpireTokenTime(token);
      this.setAuthToken(token);

      return this.checkToken(token.access_token).subscribe(roles => {
        token.setTokenWithCheckToken(roles);
        if (Array.isArray(roles.authorities)) {
          sessionStorage.setItem(this.roles, btoa(roles.authorities));
          this.addRoles(roles.authorities);
        }
        this.localLogin(token, roles);
        this.setSessionTimeout();
        this.addSuccess('success');
        observer.next('ok');
        observer.complete();
      });
    });
  }

  /**
   * Clears the Auth cookies and set their expire time to expire immediately.
   */
  removeAuthCookies() {
    if (this.isAuthenticatedWithAuthCookie()) {
      const date = new Date();

      // Set it expire in -1 days
      date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

      document.cookie = this.OATH_TOKEN_COOKIE_NAME + '= ; expires =' + date.toUTCString();
      document.cookie = this.OATH_REFRESH_TOKEN_COOKIE_NAME + '= ; expires =' + date.toUTCString();
      document.cookie = this.OATH_EXPIRES_IN_TOKEN_NAME + '= ; expires =' + date.toUTCString();
      document.cookie = this.OATH_ACCOUNT_TYPE_TOKEN_NAME + '= ; expires =' + date.toUTCString();
    }
  }

  /**
   * Enforce permissions rules:
   * 1. If you have ROLE_XYZ-EDIT or ROLE_XYZ-VIEW then generate ROLE_XYZ that the html page will use to show or not show a component.
   * 2. If you have both ROLE_XYZ-EDIT and ROLE_XYZ-VIEW then remove ROLE_XYZ-VIEW (i.e. escalation to EDIT privilege)
   * 3. Privileges ROLE_XYZ-EDIT and ROLE_XYZ-VIEW are only used in a component itself to decide how to render itself,
   *    not in html that includes the component.
   * 4. Exactly 1 WF ROLE (WF_SCA-EDIT, WF_BUYER-EDIT, or WF_VENDOR-EDIT) must be present for each user.
   * @param roles The roles for a user
   */
  private normalizeRoles(roles: string[]): Map<string, Permission> {
    const roleMap: Map<string, Permission> = new Map<string, Permission>();

    roles.forEach(role => {
      const baseRole = AuthService.getBaseRoleFor(role);
      if (roleMap.get(baseRole) === undefined) {
        roleMap.set(baseRole, new Permission(baseRole));
      }

      const roleType = AuthService.getRoleType(role);
      if (roleType === 'EDIT') {
        roleMap.get(baseRole).hasEdit = true;
      } else if (roleType === 'VIEW') {
        roleMap.get(baseRole).hasView = true;
      }
    });

    return roleMap;
  }

  /**
   * Return base persmission and escalated permission types (i.e. 1 of EDIT or VIEW).
   * @param roles
   */
  private generateUIRoles(roles: string[]): string[] {
    const roleMap: Map<string, Permission> = this.normalizeRoles(roles);

    const result: string[] = [];

    roleMap.forEach(permission => {
      result.push(permission.name);
      if (permission.hasEdit) {
        result.push(permission.name + '-EDIT');
      } else if (permission.hasView) {
        result.push(permission.name + '-VIEW');
      }
    });

    return result;
  }

  /**
   * Get the base role (i.e. without -EDIT or -VIEW suffix).
   * @param role
   */
  private static getBaseRoleFor(role: string) {
    const lastDashIndex = role.lastIndexOf('-');
    return role.substring(0, lastDashIndex);
  }

  /**
   * Return the type of role (i.e. EDIT or VIEW)
   * @param role
   */
  private static getRoleType(role: string) {
    const lastDashIndex = role.lastIndexOf('-');
    if (lastDashIndex !== -1) {
      return role.substring(lastDashIndex + 1, role.length);
    } else {
      return undefined;
    }
  }

  /**
   * Retrieves auth service's version .
   *
   * @returns {Observable<any>}
   */
  public getAuthServiceVersion(): Observable<any> {
    return this.http.get<any>('/auth/versions/current').pipe(
      tap(version => this.logSuccess('auth service version= ' + version)),
      catchError(this.handleAndDisplayError<any>('auth versionEndpoint'))
    );
  }

  private logSuccess(message: string) {
    this.publisherService.add('LookupService: ' + message);
  }

  /**
   * Returns whether or not a given user has any of the four applicable workflow roles assigned.
   * If not, the user is assumed to be new.
   */
  public isUser() {
    return (this.isSca() || this.isVendor() || this.isBuyer() || this.isPia() || this.isObReg() || this.isPharm());
  }

  /**
   * Returns whether or not a given user has any internal workflow roles assigned.
   * If not, the user is assumed to be new.
   */
  public isInternalUser() {
    return (this.isSca() || this.isBuyer() || this.isPia() || this.isObReg() || this.isPharm());
  }

  /**
   * Returns whether or not the user has more than one role.
   */
  hasMoreThanOneRole(): boolean {
    const permissions  = UserRoleConstants.USER_ROLES.filter((role) => this.permissionService.getPermission(role));
    return permissions.length > 1;
  }

  // Helper property to resolve the service dependency.
  private get _router() { return this._injector.get(Router); }
}
