import { BaseAttribute } from './base-attribute';
import { AttributeAreaConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayAreaComponent } from '../../shared/attribute-display/attribute-display-area/attribute-display-area.component';

export class BoxesOnPallet implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayAreaComponent;
  }

  getAttributeConfig(overrides?): AttributeAreaConfig {
    return {
      description: 'On a pallet, tell us how many boxes are on one layer and how many layers there are.',
      label: 'Boxes on pallet',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      areaPlaceholder: '(total)',
      lengthPlaceholder: 'Tie',
      widthPlaceholder: 'Tier',
      name: 'boxesPerLayerId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayAreaComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    // componentInstance.model = candidate.masterPack;
    componentInstance.lengthModel = candidate.vendorTie;
    componentInstance.lengthModelChange.subscribe(x => {
      candidate.vendorTie = x;
    });

    componentInstance.widthModel = candidate.vendorTier;
    componentInstance.widthModelChange.subscribe(x => {
      candidate.vendorTier = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayAreaComponent, candidate: Candidate) {
    if (componentInstance.lengthModel !== candidate.vendorTie) {
      componentInstance.lengthModel = candidate.vendorTie;
    }

    if (componentInstance.widthModel !== candidate.vendorTier) {
      componentInstance.widthModel = candidate.vendorTier;
    }
  }
}
