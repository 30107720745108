import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AttributeTypes, InputState, ReviewComponent} from 'pm-components';
import {
  Attribute,
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  BaseCardPermissions,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  RetailLink,
  Task,
  TaskDecision,
  TextInputType
} from 'pm-models';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {AuthService} from '../../auth/auth.service';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {CostService} from '../../service/cost.service';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {FileService} from '../../service/file.service';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {LabelInsightService} from '../../service/label-insight.service';
import {finalize, tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {MatUtilService} from '../../service/mat-util.service';

@Component({
  selector: 'app-supplier-mrt-inner-review',
  templateUrl: './supplier-mrt-inner-review.component.html',
  styleUrls: ['./supplier-mrt-inner-review.component.scss']
})
export class SupplierMrtInnerReviewComponent implements OnInit {
  @ViewChild(ReviewComponent) pmReview;

  public BUYER_REVISE_TASK_NAME = 'Revise Buyer Data';
  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public UPC = 'UPC';
  public ITEM_CODE = 'Item Code';
  public RETAIL_LINK_NUMBER = 'Link Number';
  public retailLinkState: InputState;
  public isApproveDisabled = false;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  showMatAttributes = false;
  isLoadingMatData = true;
  hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  hierarchyAttributes: Attribute[] = [];
  globalAttributes: Attribute[] = [];
  productAttributes: Attribute[] = [];
  warehouseItemAttributes: Attribute[] = [];
  upcAttributes: Attribute[] = [];


  constructor(private workflowService: WorkflowService, private route: ActivatedRoute,
              private router: Router, private candidateService: CandidateService, private lookupService: LookupService,
              private growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public fileService: FileService, public candidateUtilService: CandidateUtilService,
              public authService: AuthService, public labelInsightService: LabelInsightService,
              public permissionService: NgxPermissionsService, public matUtilService: MatUtilService) {
  }

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  private task: Task;
  public isViewingPage = true;
  public opened = true;
  public merchandiseTypes: any;
  public commodities: any;
  public subCommodities: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public productImages = [];
  public labelInsightImages = [];
  public pssDepartments: any;
  private isRejectDisabled = false;
  private openCommentDrawer = false;
  private tempCommentHolder: string;
  public decimalCount = 1;
  public mrtParentDescription: string;
  private mrtParentCandidate: Candidate;
  public isNonReplenishItem: boolean = false;
  private isPartOfMrt: boolean = false;

  readonly permissions: BaseCardPermissions = {
    isReadOnly: false,
  };

  merchandiseTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Merchandise type',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'description',
    placeholderText: 'Select a merchandise type...',
    collections: this.merchandiseTypes
  };

  commodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Commodity',
    description: 'Choose an appropriate category.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'commodityId',
    displayRef: 'commodityName',
    placeholderText: 'Select a commodity...',
    collections: this.commodities
  };

  subCommodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-commodity',
    description: 'Choose an appropriate subcategory.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'subcommodityId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-commodity...',
    collections: this.subCommodities
  };

  pssDepartmentConfiguration: AttributeTypeaheadConfig = {
    label: 'PSS Department',
    description: 'Select the point of sale sub-department.',
    isRequired: true,
    isDisabled: () => !this.candidate.commodity || this.candidate.dsdSwitch === false,
    isReadOnly: () => false,
    name: 'pssDepartmentId',
    displayRef: 'displayName',
    placeholderText: 'Select a PSS Department...',
    idRef: 'pssDepartmentId',
    collections: this.pssDepartments
  };

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'The number of days between the product\'s arrival date to the warehouse and the product\'s ' +
      'expiration date that H-E-B is guaranteed to sell through the product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  pricingTypeConfiguration: AttributeConfig = {
    label: 'Pricing type',
    description: '',
    isDisabled: () => this.isRetailTypeDisabled(),
    isReadOnly: () => this.isRetailTypeDisabled(),
    isRequired: true,
    inputGroupClass: 'attribute-radios-block',
    options: [
      {label: 'Key retail', value: this.KEY_RETAIL},
      {label: 'Retail link', value: this.RETAIL_LINK},
      {label: 'Price required', value: this.PRICE_REQUIRED}
    ],
  };

  retailLinkConfiguration: AttributeConfig = {
    options: [
      { value: this.RETAIL_LINK_NUMBER, label: 'Link #' },
      {value: this.UPC, label: 'UPC'},
      {value: this.ITEM_CODE, label: 'Item Code'}
    ],
    label: 'Retail link',
    description: 'Inherit retail price from another product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => !this.isRetailLink(),
    isRequired: true,
    defaultValue: this.UPC
  };

  pennyProfitConfiguration: AttributeTextInputConfig = {
    label: 'H-E-B Penny profit',
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `(total)`,
    textInputType: TextInputType.text,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 2
  };

  estimatedStoreCountConfiguration: AttributeTextInputConfig = {
    label: 'Estimated store count',
    description: 'Estimated number of stores that will carry this product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    name: '',
    textInputType: TextInputType.integer
  };
  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };
  yearConfiguration: AttributeTextInputConfig = {
    label: 'Year',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };
  soldByWeightConfiguration: AttributeConfig = {
    label: 'Sold by weight',
    description: `Select if the item is sold by the pound.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    defaultValue: false
  };
  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };
  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };
  buyerComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'buyerCommentId',
    maxLength: 4000
  };

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.MRT_INNER) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    this.guaranteeToStoreDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.reactionDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.inboundSpecConfiguration.isHidden = () => !this.candidate.codeDate;
    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }
    if (candidate.buyer?.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }
    if (candidate.brand?.brandId !== undefined) {
      this.setCostOwners(candidate.brand.brandId);
    }
    if (this.candidate.buyerComment) {
      this.tempCommentHolder = this.candidate.buyerComment;
    }
    // Always have to check if a this 'draft' candidate is part of a MRT
    this.setMrtCaseInfo();

    this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].labelInsightsImageLinks);

    this.setupMatAttributes();
  }


  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }
    this.resetMatHierarchyFields();
    if (this.candidateUtilService.isInProgressCandidate(this.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.setMatAttributesListsIfNotPresent(this.candidate, this.globalAttributes,
        this.hierarchyAttributes).pipe(
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.hierarchyAttributes, this.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.globalAttributes,
            this.productAttributes, this.warehouseItemAttributes, this.upcAttributes);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })
      ).subscribe();
    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.candidate.candidateProducts[0].globalAttributes,
        this.productAttributes, this.warehouseItemAttributes, this.upcAttributes);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  /**
   * Sets available selectable cost owners
   * @param brandId the brandId id.
   */
  private setCostOwners(brandId) {
    this.lookupService.findBrandsById(brandId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.brand.brandId === data[index].brandId) {
          this.candidate.brand.costOwners = data[index].costOwners;
          break;
        }
      }
    });

  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe(data => {
      this.commodities = data;
      this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
        this.getPssDepartments();

        // Initialize pssDepartment to the default pss department from commodity
        this.candidate.pssDepartment = commodity.pssDepartment;

      } else {
        this.subCommodities = [];
        this.subCommodityConfiguration.collections = this.subCommodities;
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
    this.subCommodityConfiguration.collections = this.subCommodities;
  }

  getPssDepartments() {
    if (!this.candidate.overrideDepartment) {
      this.pssDepartments = this.candidate.commodity?.subDepartment?.pssDepartments;
      this.pssDepartmentConfiguration.collections = this.pssDepartments;
    } else {
      this.pssDepartments = this.candidate.overrideSubDepartment?.pssDepartments;
      this.pssDepartmentConfiguration.collections = this.pssDepartments;
    }
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
  }

  onClose() {
    this.router.navigate(['/tasks']).then();
  }

  reject() {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = 'Buyer has declined to carry your products at this time.';
    this.candidate.status = Candidate.DECLINED;
    // if non replenish mrt item, reject all non replenish items in mrt and mrt
    if (this.candidate.candidateType === Candidate.MRT_INNER || this.isPartOfMrt) {
      this.candidateService.saveCandidate(this.candidate, true);
      let candidateInner: CandidateInner;
      for (let x = 0; x < this.mrtParentCandidate.mrtInfo.candidateInners.length; x++) {
        candidateInner = this.mrtParentCandidate.mrtInfo.candidateInners[x];
        if (!candidateInner.replenishable) {
          this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidateInner.candidateId)
            .subscribe(task => {
              if (task.name === 'Key Buyer Data') {
                this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                  TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).subscribe();
              } else if (task.name === 'PIA Final Review') {
                this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                  TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe();
              }
            });
        }
      }
      // rejecting draft or replenish mrt inner
      this.workflowService.completeTaskWithAction(this.task, WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).subscribe();
      // reject parent mrt
      this.workflowService.getTaskByCandidateIdWithVariables(this.mrtParentCandidate.candidateId)
        .subscribe(task => {
          this.completeTaskWithTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION,
            'Successfully rejected non replenish candidate and mrt.', task);
        });
    } else {
      this.saveAndCompleteTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
    }

  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
    }, (error) => {
      this.growlService.addError(error);
      this.isRejectDisabled = false;
    });
  }

  onClickNext() {
    this.pmReview.openDrawer();
  }

  collapse() {
    this.pmReview.closeDrawer();
  }

  /**
   * Saves candidate.
   */
  save() {
    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.candidateUtilService.isSellable(this.candidate);
    const itemType = CandidateUtilService.getItemType(this.originalCandidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      this.merchandiseTypeConfiguration.collections = merchandiseTypes;
      if (!this.originalCandidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
    if (!isSellable) {
      this.setNonSellableRetailValues();
    }
  }

  /**
   * Sets non sellable default values.
   */
  private setNonSellableRetailValues() {
    this.originalCandidate.retailXFor = 1;
    this.originalCandidate.retailPrice = '0.00';
    this.originalCandidate.retailType = this.KEY_RETAIL;
  }

  commodityChange(event) {
    this.candidate.commodity = event;
    this.candidate.subCommodity = null;
    this.getSubCommodities(event);
    this.getPssDepartments();
    // TODO: Cost link validation on API
    // if (costLink.commodity !== Number(toValidate.commodity.commodityId)) {
    //   return 'Cost Link not valid for this Commodity. Proceed without a Cost Link or send candidate back to Supplier to request' +
    //     ' a valid Cost Link for the selected Commodity.';
    // }
    if (this.candidate.commodity) {
      this.candidate.pssDepartment = this.candidate.commodity.pssDepartment;
    }
  }

  subCommodityChange(event) {
    this.candidate.subCommodity = event;
    this.candidate.foodStamp = CandidateHelper.isFoodStamp(this.candidate);
    this.candidate.taxable = CandidateHelper.isTaxCode(this.candidate);
  }

  pssDepartmentChange(event) {
    this.candidate.pssDepartment = event;
  }

  pricingTypeChange(event) {
    this.candidate.retailType = event;

    if (this.isRetailLink()) {
      this.candidate.retailLink = new RetailLink();
      this.candidate.retailLink.upcType = this.UPC;
      this.candidate.retailXFor = '';
      this.candidate.retailPrice = '';
      this.retailLinkConfiguration.placeholderText = ''; // temp fix for bug that doesn't update retail component.
    } else {
      this.candidate.retailLink = undefined;
      if (this.isKeyRetail()) {
        this.candidate.retailXFor = '';
        this.candidate.retailPrice = '';
      } else if (this.isPriceRequired()) {
        this.candidate.retailXFor = 1;
        this.candidate.retailPrice = 0.00;
      }
    }
  }

  onRetailLinkTypeChange(event) {
    if (this.candidate.retailLink) {
      this.candidate.retailLink.upcType = event;
    }
  }

  isKeyRetail(): boolean {
    return this.candidate.retailType === this.KEY_RETAIL;
  }

  isRetailLink(): boolean {
    return this.candidate.retailType === this.RETAIL_LINK;
  }

  isPriceRequired(): boolean {
    return this.candidate.retailType === this.PRICE_REQUIRED;
  }

  seasonChange(event) {
    this.candidate.season = event;
  }

  soldByWeightChange(event) {
    this.candidate.weightSwitch = event.checked;
  }

  /**
   * Sets the data on a retail info object to be displayed on a table.
   * @param upc
   /**
   * Sets the data on a retail info object to be displayed on a table.
   * @param upc
   */
  setUpcData(upc) {

  }

  onKeyPress(event) {
    const pattern = /^\d+$/;
    return pattern.test(event.key);
  }

  onClickApprove() {
    this.isApproveDisabled = true;

    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.BUYER_PRODUCT_REVIEW_VALIDATOR])
      .subscribe(() => {
        this.saveAndCompleteBuyerTask();

      }, (error) => {
        if (error.error.candidateErrors) {
          this.candidateError = error.error.candidateErrors;

          this.candidateProductError = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message); // TODO: new way to handle server side errors?
        }
        this.isApproveDisabled = false;
      });
  }

  /**
   * Calls workflow service to end the buyer task and then navigates back to the tasks page.
   */
  private saveAndCompleteBuyerTask() {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_APPROVE_DECISION, 'Successfully completed task.');
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], {queryParams: {growlMessage: growlMessage}}).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   * @param task the task.
   */
  private completeTaskWithTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string, task: Task) {
    this.workflowService.completeTaskWithAction(task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], {queryParams: {growlMessage: growlMessage}}).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Returns whether or not retail type is disabled.
   * @returns {boolean}
   */
  isRetailTypeDisabled() {
    return this.candidate.productType === 'NON_SELLABLE';
  }

  isRetailPriceDisabled() {
    return this.isRetailLink() || this.isPriceRequired();
  }

  getProductInfoString() {
    let infoString = '';

    if (this.isNonReplenishItem) {
      infoString += this.candidate.retailSize;
    } else {
      infoString += this.candidate.retailSize + ' | ' + 'Inner case cost: ' +
        this.costService.toCurrencyForCost(this.candidate.innerListCost) + ' |  Unit cost: ' +
        this.costService.toCurrencyForCost(this.candidate.unitCost);
    }

    if (this.candidateUtilService.isSellable(this.candidate)) {
      infoString +=
        ' | Suggested Retail: ' +
        this.candidate.suggestedXFor + ' for ' + this.costService.toCurrency(this.candidate.suggestedRetailPrice);
    }

    return infoString;
  }

  getSellableString(): string {
    return this.originalCandidate.productType === 'SELLABLE' ? 'Yes' : 'No';
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }

  editBuyer() {
    this.editCandidateModalService.openModal(AttributeTypes.Buyer, this.candidate).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidate = response;
        this.candidate.commodity = null;
        this.candidate.subCommodity = null;
        if (response.buyer && response.buyer.buyerId) {
          this.setCommoditiesAndSubCommodities(response.buyer.buyerId);
        } else {
          this.commodities = [];
          this.subCommodities = [];
        }
      }
    });
  }

  editCandidateProduct(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: {collections: this.candidate.brand.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: {collections: this.candidate.brand.costOwners}}
      ], this.candidate).subscribe(response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editProductDescription() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.ProductDescription},
        {type: AttributeTypes.Receipt}
      ], this.candidateProduct).subscribe(response => {

      if (response) {
        this.candidateProduct = response;
      }
    });
  }

  get attributeType() {
    return AttributeTypes;
  }

  showEditCandidateProductModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidateProduct = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
      }
    });
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidate, overrides).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidate = response;
      }
    });
  }

  editBrandModal() {
    this.editCandidateModalService.openModal(AttributeTypes.Brand, this.candidate).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[0];
      }
    });
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.buyerComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.buyerComment = this.tempCommentHolder;
    this.candidate.buyerCommentUser = this.authService.getUser();
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  getPageTitle() {
    if (this.candidateUtilService.isSellable(this.candidate)) {
      return 'Review new product';
    } else if (this.candidate && !this.candidateUtilService.isSellable(this.candidate)) {
      return 'Review non-sellable product';
    }
  }

  /**
   * Code date is editable by the buyer if dsdSwitch set to false ==> warehouse
   */
  codeDateEditable() {
    return this.candidate.dsdSwitch === false;
  }

  isMrtInner() {
    return this.candidate.candidateType === Candidate.MRT_INNER;
  }

  setMrtCaseInfo() {
    this.candidateService.findParentMrtCandidatesForCandidateId(this.candidate.candidateId,
      [Candidate.IN_PROGRESS, Candidate.COMPLETED, Candidate.ACTIVATED, Candidate.DECLINED, Candidate.DELETED]).subscribe((mrtCandidates) => {
      if (!mrtCandidates?.length) {
        this.isPartOfMrt = false;
        return;
      }
      this.isPartOfMrt = true;
      if (!this.findMrtParentAndSetMrtCaseInfo(mrtCandidates)) {
        this.setMrtCaseInfoFromWorkflow(mrtCandidates);
      }
    });
  }

  private setMrtCaseInfoFromWorkflow(mrtCandidates: Candidate[]) {
    this.workflowService.getIsNonReplenishableMrtInnerCandidateId(this.candidate.candidateId).subscribe(
      (isNonReplenishable) => {
        if (!isNonReplenishable) {
          return;
        }
        this.isNonReplenishItem = true;

        if (mrtCandidates.length === 1) {
          this.mrtParentCandidate = mrtCandidates[0];
        } else {
          this.mrtParentCandidate = mrtCandidates.filter(mrt => {
            return !!mrt.mrtInfo.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId && !inner.draft);
          })[0];
          // if the parent no longer exists, just select the first one.
          if (!this.mrtParentCandidate) {
            this.mrtParentCandidate = mrtCandidates[0];
          }
        }
        this.mrtParentDescription = this.mrtParentCandidate.description;
        this.candidateUtilService.setMrtInnerCandidateInfoFromMrtCandidate(this.candidate, this.candidateProduct, this.mrtParentCandidate);
      }
    );
  }

  private findMrtParentAndSetMrtCaseInfo(mrtCandidates: Candidate[]): boolean {
    this.mrtParentCandidate = mrtCandidates.filter(mrt => {
      return !!mrt.mrtInfo.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId && !inner.draft);
    })[0];

    if (!this.mrtParentCandidate) {
      return false;
    }
    const candidateInner = this.mrtParentCandidate.mrtInfo?.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId);
    if (!candidateInner.replenishable) {
      this.isNonReplenishItem = true;
      this.mrtParentDescription = this.mrtParentCandidate.description;
      this.candidateUtilService.setMrtInnerCandidateInfoFromMrtCandidate(this.candidate, this.candidateProduct, this.mrtParentCandidate);
    }
    return true;
  }

  showCasePackInfo() {
    if (this.isMrtInner()) {
      return !this.isNonReplenishItem;
    } else {
      return this.candidate.warehouseSwitch;
    }
  }

  getPennyProfit() {
    return (this.candidate.suggestedRetailPrice * this.candidate.retailSize) - this.candidate.masterListCost;
  }

  getPennyProfitDisplay() {
    return this.costService.toCurrency(this.getPennyProfit());
  }

  getMarginPercent() {
    return 100 * (this.getPennyProfit() / this.candidate.masterListCost);
  }

  getMrtItemHebPennyProfit() {
    return (this.candidate.retailPrice * this.candidate.retailXFor) - this.candidate.masterListCost;
  }

  private resetInitialValues() {
    this.isViewingPage = false;
    this.candidate = undefined;
    this.candidateProduct = undefined;
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks'], { queryParams: { tabIndex: 1 } }).then();
  }

  onClickPrint() {
    window.print();
  }

  resetMatHierarchyFields() {
    this.globalAttributes = [];
    this.hierarchyAttributes = [];
    this.productAttributes = [];
    this.warehouseItemAttributes = [];
    this.upcAttributes = [];
    this.hierarchyNumberToAttributesMap = new Map();
  }
}
