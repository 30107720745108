import {Component, OnInit} from '@angular/core';
import {
  AttachmentsCardPermissions,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  CaseDetailsCardPermissions,
  CostCardPermissions,
  CostLinkCardPermissions,
  DisplayReadyUnitCardPermissions, ImportedProductCardPermissions,
  InnerPackDetailsCardPermissions,
  MasterPackDetailsCardPermissions, OmiRemarksCardPermissions
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {PiaProductService} from '../../service/pia-product.service';
import {AuthService} from '../../auth/auth.service';
import {CandidateService} from '../../service/candidate.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {InputState, UPCInputState} from 'pm-components';
import {GrowlService} from '../../growl/growl.service';
import {PricingCardPermissions} from 'pm-models/lib/card-models/pricing-card-model';
import {LookupService} from '../../service/lookup.service';
import {CostService} from '../../service/cost.service';
import {PiaNewProductFlowStepperComponent} from '../pia-new-product-flow-stepper/pia-new-product-flow-stepper.component';
import {PiaNewDsdProductFlowStepperComponent} from '../pia-new-dsd-product-flow-stepper/pia-new-dsd-product-flow-stepper.component';

@Component({
  selector: 'app-new-product-pia-case-details',
  templateUrl: './pia-new-product-case-details.component.html',
  styleUrls: ['./pia-new-product-case-details.component.scss']
})
export class PiaNewProductCaseDetailsComponent implements OnInit {

  public isViewingPage = true;
  private taskSubscription$: any;
  public candidate: Candidate;
  public currentCandidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public costLinkState: InputState;
  public upcState: UPCInputState;
  public retailLinkState: InputState;
  public containerSizes: any;
  public incoTermsList: any;
  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions;

  readonly masterPackDetailsCardPermissions: MasterPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly innerPackDetailsCardPermissions: InnerPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly displayReadyUnitCardPermissions: DisplayReadyUnitCardPermissions = {
    isReadOnly: false
  };
  readonly costLinkCardPermissions: CostLinkCardPermissions = {
    isReadOnly: false
  };
  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false,
    pennyProfit: {
      isHidden: true
    },
    marginPercent: {
      isHidden: true
    },
    masterPack: {
      isHidden: true
    }
  };
  readonly pricingCardPermissions: PricingCardPermissions = {
    isReadOnly: false,
  };
  readonly omiRemarksPermissions: OmiRemarksCardPermissions = {
    isReadOnly: false,
  };

  readonly attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };
  readonly importProductCardPermissions: ImportedProductCardPermissions = {
    isReadOnly: false
  };

  constructor(private route: ActivatedRoute, private router: Router, public piaProductService: PiaProductService,
              private authService: AuthService, private candidateService: CandidateService,
              public candidateUtilService: CandidateUtilService, private growlService: GrowlService,
              private lookupService: LookupService, private costService: CostService) { }

  getAdditionalCasePackBasicsCardPermissions(): CaseDetailsCardPermissions {
    return {
      isReadOnly: false,
      itemWeightType: {
        isReadOnly: false,
        isDisabled: !this.piaProductService.isTiedToCatchOrVariableWeightBuyer()
      },
      distributionChannel: {
        isHidden: true
      },
      buyer: {
        isHidden: true
      },
      dealOffered: {
        isHidden: true
      }
    };
  }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.piaProductService.getTaskId(), this.piaProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.piaProductService.resetService();
        this.piaProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.piaProductService.getCandidate()).subscribe((isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.piaProductService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.candidate = this.piaProductService.getCandidate();
    this.originalCandidate = this.piaProductService.getOriginalCandidate();
    this.currentCandidateProduct = this.piaProductService.getCurrentCandidateProduct();
    this.candidateError = this.piaProductService.getCasePackDetailsError();
    this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
    this.lookupService.findAllContainerSizes().subscribe(containerSizes => {
      this.containerSizes = containerSizes;
    });
    this.lookupService.findAllIncoTerms().subscribe(incoTermsList => {
      this.incoTermsList = incoTermsList;
    });
    this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
  }

  onClose() {
    this.isViewingPage = false;
    this.piaProductService.saveCandidateAndClose();
  }

  onClickNext() {

    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.PIA_PRODUCT_CASE_DETAILS_VALIDATOR]).toPromise().then(data => {
        // TODO change this to correct validation as part of validation task
      this.candidateError = this.piaProductService.setCasePackDetailsError(new CandidateError());
      this.candidateProductError = new CandidateProductError();

      if (this.piaProductService.isDsdOnly()) {
        if (!this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.piaProductService.saveCandidateAndNavigate('/piaStoreAuthorization', false);
        } else {
          this.piaProductService.saveCandidateAndNavigate('/piaSetUpStores', false);
        }
      } else {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductWarehouses', false);
      }
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = this.piaProductService.setCasePackDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
        if (!this.piaProductService.isDsdOnly()) {
          this.piaProductService.saveCandidateAndNavigate('/piaNewProductWarehouses', false);
        }
      }
    });
  }

  onClickBack() {
    this.piaProductService.saveCandidateAndNavigate('/piaNewProductDetails', false);
  }


  onInnerPackChange() {
    if (!this.candidate.innerPackSelected) {
      this.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
    if (this.piaProductService.getCandidate() && this.piaProductService.getCandidate().costLinked
      && this.piaProductService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }

  innerPackQuantityChange() {
    if (this.candidateProductError && this.candidateProductError.caseUpc) {
      this.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
  }

  validateUpc() {
    this.upcState = UPCInputState.loading;
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.CASE_UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.caseUpc = undefined;
    }, (error) => {
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const candidateProductId = this.candidate.candidateProducts[0].id;
        const candidateErrors: CandidateError = error.error.candidateErrors;
        const candidateProductError: CandidateProductError =
          candidateErrors.candidateProductErrors[candidateProductId];

        if (this.candidateError.candidateProductErrors.size === 0 ||
          !this.candidateError.candidateProductErrors[candidateProductId]) {

          this.candidateError.candidateProductErrors.set(candidateProductId,
            candidateProductError);
          this.candidateProductError = candidateProductError;
          this.candidateError.itemWeightType = candidateErrors.itemWeightType;
          if (this.candidateProductError.caseUpc ||  this.candidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.piaProductService.setCasePackDetailsError(candidateErrors);
            this.upcState = UPCInputState.valid;
          }
        } else {
          this.candidateProductError.caseUpc = candidateProductError.caseUpc;
          this.candidateProductError.caseUpcCheckDigit = candidateProductError.caseUpcCheckDigit;
          this.candidateError.itemWeightType = candidateErrors.itemWeightType;
          if (this.candidateProductError.caseUpc ||  this.candidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.piaProductService.setCasePackDetailsError(candidateErrors);
            this.upcState = UPCInputState.valid;
          }
        }
      } else {
        this.upcState = UPCInputState.invalid;
        this.growlService.addError(error.message);
      }
    });
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }


  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.candidate.costLinkFromServer = undefined;
    this.candidateError.costLink = undefined;
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((validatedCandidate) => {
      this.costLinkState = InputState.valid;
      this.costService.updateCostLinkRelatedValuesFromValidatedCandidate(this.piaProductService.getCandidate(), validatedCandidate);
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.candidateError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  validateRetailLink() {
    this.retailLinkState = InputState.loading;
    this.candidateError.retailLink = undefined;
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).subscribe((candidate) => {
      this.retailLinkState = InputState.valid;

      this.candidate.retailLink = candidate.retailLink;
      this.candidate.retailXFor = candidate.retailXFor;
      this.candidate.retailPrice = candidate.retailPrice;

      this.candidateError.retailLink = undefined;
    }, (error) => {
      this.retailLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.candidateError.retailLink = error.error.candidateErrors.retailLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaNewProductFlowStepperComponent.UPC_ITEM_CODE: {
        this.jumpToPage('/piaSetupNewProduct', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.SUPPLIER_HEB_SETUP: {
        this.jumpToPage('/piaNewProductSupplierDetails', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.PRODUCT_DETAILS: {
       this.onClickBack();
        break;
      }
      case PiaNewProductFlowStepperComponent.CASE_PACK: {
        this.jumpToPage('/piaNewProductCaseDetails', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewDsdProductFlowStepperComponent.STORE_AUTHORIZATION: {
       this.onClickNext();
        break;
      }
      case PiaNewProductFlowStepperComponent.WAREHOUSE: {
        this.onClickNext();
        break;
      }
      case PiaNewProductFlowStepperComponent.EXTENDED_ATTRIBUTES: {
        if (this.piaProductService.isDsdOnly()) {
          this.jumpToPage('/piaNewProductExtendedAttributes', PiaProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/piaNewProductExtendedAttributes', PiaProductService.BASE_VALIDATORS);
        }
        break;
      }
    }
  }

  jumpToPage(urlToNavigate, validator) {
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.piaProductService.getCandidate(),
      [validator]).toPromise().then(data => {
      this.candidateError = this.piaProductService.setSetupCandidateError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      this.piaProductService.setCandidate(this.piaProductService.getCandidate());
      this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);

    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError =
          this.candidateError.candidateProductErrors[this.piaProductService.getCurrentCandidateProduct().id];
        this.piaProductService.setSetupCandidateError(this.candidateError);
        if (!this.piaProductService.isDsdOnly() || !PiaProductService.DSD_NON_NAVIGABLE_ON_ERROR_PAGES.includes(urlToNavigate)) {
          this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);
        }
      }
    });
  }
  /**
   * Trigger cost link validation on master pack change.
   * @param event
   */
  onMasterPackChange(event) {
    if (this.piaProductService.getCandidate() && this.piaProductService.getCandidate().costLinked
      && this.piaProductService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }
}
