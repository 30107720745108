import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierProductService} from '../../service/supplier-product.service';
import {GrowlService} from '../../growl/growl.service';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {MatUtilService} from '../../service/mat-util.service';
import {CandidateService} from '../../service/candidate.service';
import {AssociateUpcService} from '../../service/associate-upc.service';
import {LookupService} from '../../service/lookup.service';
import {Candidate, CandidateError, CandidateProduct, CandidateValidatorType, TaskDecision} from 'pm-models';
import {finalize, switchMap} from 'rxjs/operators';
import {AssociateUpcStepperComponent} from '../../shared/components/associate-upc-stepper/associate-upc-stepper.component';
import {Observable} from 'rxjs';
import { UpcService } from 'pm-components';

@Component({
  selector: 'app-pia-associate-extended-attributes',
  templateUrl: './pia-associate-extended-attributes.component.html',
  styleUrls: ['./pia-associate-extended-attributes.component.scss']
})
export class PiaAssociateExtendedAttributesComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, public supplierProductService: SupplierProductService,
              private growlService: GrowlService, private workflowService: WorkflowService, private candidateUtilService: CandidateUtilService,
              private matUtils: MatUtilService, private candidateService: CandidateService, public associateUpcService: AssociateUpcService,
              public lookupService: LookupService, private matUtilService: MatUtilService, public upcService: UpcService) { }

  private isActivating = false;

  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.associateUpcService.getTaskId(),
        this.associateUpcService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.associateUpcService.resetService();
        this.associateUpcService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.ASSOCIATE_UPC) {
              this.associateUpcService.setOriginalAndCurrentCandidate(candidate);
              return this.updateHierarchyAndAttributes();
            } else {
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
            this.initializeData();
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.associateUpcService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return this.updateHierarchyAndAttributes();
            } else {
              this.associateUpcService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
            this.initializeData();
          })
        ).subscribe();
      }
    });
  }

  initializeData() {
    this.associateUpcService.initializeExtendedAttributesIsViewing();
    this.candidateError = this.associateUpcService.getAssociateExtendedAttributesError();
    this.isLoadingData = false;
  }

  onClickBack() {
    this.jumpBack('/piaAssociateCaseSelection');
  }

  onClickActivate() {
    this.isActivating = true;
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), [CandidateValidatorType.ASSOCIATE_FINAL_REVIEW_VALIDATOR]).subscribe(() => {
      this.candidateService.activateCandidate(this.associateUpcService.getCandidate()).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE,
          TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
          'Successfully activated candidate.'
        );
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
        this.isActivating = false;
      });
    }, (error) => {
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.associateUpcService.updatePageErrors(this.candidateError);
      } else {
        this.growlService.addError(error.message);
      }
      this.isActivating = false;
      this.canNavigate = true;
    });
  }

  onClose() {
    this.associateUpcService.saveCandidateAndClose();
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case AssociateUpcStepperComponent.ORIGINAL_UPC_ITEM_CODE: {
        this.jumpBack('/piaAssociateUpcSetup');
        break;
      }
      case AssociateUpcStepperComponent.ITEM_DETAILS: {
        this.jumpBack('/piaAssociateBasicItemSetup');
        break;
      }
      case AssociateUpcStepperComponent.CASE_SELECTION: {
        this.onClickBack();
        break;
      }
    }
  }

  updateHierarchyAndAttributes(): Observable<any> {
    if (this.associateUpcService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.associateUpcService.candidate.candidateProducts[0].upc, []).pipe(
        switchMap((productData) => this.matUtilService.updateCandidateProductsMatHierarchyFromProduct(this.associateUpcService.candidate.candidateProducts, productData)),
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.associateUpcService.getCandidate(),
          this.associateUpcService.globalAttributes, this.associateUpcService.hierarchyAttributes)),
      );
    } else if (this.associateUpcService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.associateUpcService.candidate.candidateProducts[0].itemCode, []).pipe(
        switchMap((productData) => this.matUtilService.updateCandidateProductsMatHierarchyFromProduct(this.associateUpcService.candidate.candidateProducts, productData)),
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.associateUpcService.getCandidate(),
          this.associateUpcService.globalAttributes, this.associateUpcService.hierarchyAttributes)),
      );
    }
  }

  jumpBack(urlToNavigate) {
    this.canNavigate = false;
    this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
      finalize(() => this.canNavigate = true)
    ).subscribe();
  }

  hasExtendedAttributesErrors(candidateProductId): boolean {
    if (!this.candidateError?.candidateProductErrors[candidateProductId]?.matAttributeErrors) {
      return false;
    }
    return !!Object.keys(this.candidateError.candidateProductErrors[candidateProductId].matAttributeErrors).length;
  }


  getActivateButtonTitle() {
    if (this.isActivating) {
      return 'Activating';
    } else {
      return 'Activate';
    }
  }


  /**
   * Complete Task and Route to Task Page but the method is meant for PIA Only.
   *
   * @param action
   * @param taskDecision
   * @param growlMessage
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.associateUpcService.getCandidate().vendor.apNumber, this.associateUpcService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
        this.isActivating = false;
      });
  }

}
