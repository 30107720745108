<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">
  <div [ngClass]="{'attribute-group' : attribute, 'indent' : isIndented, 'attribute-required': attribute.isRequired}">
    <div *ngIf="attribute.checkboxLabel" class="attribute-label" [ngClass]="attribute.labelInputGroupClass">
      <label for="">{{attribute.checkboxLabel}}</label>
    </div>
    <div class="attribute-control">
      <p-checkbox class= "padded" [(ngModel)]="model" binary="true" (ngModelChange)="modelChange.emit($event)" (onChange)="handleChange($event)"
                  [disabled]="isDisabled()" [id]="attribute?.name"></p-checkbox>

      <label class="label-two" for="">{{attribute.label}}</label>

      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="attributeError" class="attribute-error-message">
        {{attributeError}}
      </p>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
