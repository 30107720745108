import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByName'
})
export class FilterByNameSearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    return items.filter(item => {
      return Object.keys(item).some(key => {
        if (key === 'displayName') {
          return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
        } else {
          return false;
        }
      });
    });
  }
}
