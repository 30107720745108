import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, CandidateProduct, Candidate } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class ShelfTag2 implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Shelf tag line 2',
      description: `You can optionally add additional details that will appear as a second line on the shelf tag.`,
      placeholderText: 'Description',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      name: 'shelfTagLine2Id',
      charLimit: 30,
      ...overrides
    };
  }

  setupComponent(
    componentInstance: AttributeDisplayTextinputComponent,
    candidateProduct: CandidateProduct,
    configOverrides: any = {}
  ) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidateProduct.customerFriendlyDescription2;
    componentInstance.modelChange.subscribe(x => {
      candidateProduct.customerFriendlyDescription2 = x;
    });
  }
}
