/**
 * Holds constants for the Upload Candidate Case tab.
 * @author s573181
 * @since 1.50.0
 */
export class UploadCandidateCaseConstants {

  // UPC Columns
  public static readonly EMPTY_COLUMN = {header: ' ', width: '50px', sticky: true};
  public static readonly UNIT_UPC = {header: 'Unit UPC', width: '150px', sticky: true};

  // COST COLUMNS
  public static readonly COST_LINKED = {header: 'Cost linked', width: '100px'};
  public static readonly COST_LINKED_ITEM = {header: 'Cost linked item', width: '150px'};
  public static readonly MASTER_LIST_COST = {header: 'Master list cost', width: '125px'};
  public static readonly INNER_LIST_COST = {header: 'Inner list cost', width: '125px'};
  public static readonly DEAL_OFFERED = {header: 'Deal offered', width: '100px'};
  public static readonly UNIT_COST = {header: 'Unit cost', width: '125px'};
  public static readonly HEB_MARGIN = {header: 'HEB margin %', width: '125px'};
  public static readonly HEB_PENNY_PROFIT = {header: 'HEB penny profit', width: '125px'};
  public static readonly SOLD_BY_WEIGHT = {header: 'Sold by weight', width: '100px'};

  public static readonly COST_COLUMN_GROUP =
    [UploadCandidateCaseConstants.COST_LINKED, UploadCandidateCaseConstants.COST_LINKED_ITEM,
      UploadCandidateCaseConstants.MASTER_LIST_COST, UploadCandidateCaseConstants.INNER_LIST_COST,
      UploadCandidateCaseConstants.DEAL_OFFERED, UploadCandidateCaseConstants.UNIT_COST,
      UploadCandidateCaseConstants.HEB_MARGIN, UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
      UploadCandidateCaseConstants.SOLD_BY_WEIGHT];


  // MASTER PACK COLUMNS
  public static readonly MASTER_PACK_TOTAL_RETAIL_UNITS = {header: 'Total retail units', width: '125px'};
  public static readonly MASTER_PACK_WEIGHT = {header: 'Weight', width: '125px'};
  public static readonly MASTER_PACK_DIMENSIONS = {header: 'Dimensions', width: '175px'};
  public static readonly ORDER_FACTOR = {header: 'Order factor', width: '100px'};
  public static readonly BOXES_ON_PALLET = {header: 'Boxes on pallet', width: '150px'};

  public static readonly MASTER_PACK_COLUMN_GROUP =
    [UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS, UploadCandidateCaseConstants.MASTER_PACK_WEIGHT,
      UploadCandidateCaseConstants.MASTER_PACK_DIMENSIONS, UploadCandidateCaseConstants.ORDER_FACTOR,
      UploadCandidateCaseConstants.BOXES_ON_PALLET];

  // INNER PACK COLUMNS
  public static readonly INNER_PACKS = {header: 'Inner packs', width: '100px'};
  public static readonly INNER_PACK_QUANTITY = {header: 'Inner pack quantity', width: '100px'};
  public static readonly INNER_PACK_DIMENSIONS = {header: 'Dimensions', width: '175px'};
  public static readonly INNER_PACK_WEIGHT = {header: 'Weight', width: '100px'};

  public static readonly INNER_PACK_COLUMN_GROUP =
    [UploadCandidateCaseConstants.INNER_PACKS, UploadCandidateCaseConstants.INNER_PACK_QUANTITY,
      UploadCandidateCaseConstants.INNER_PACK_DIMENSIONS, UploadCandidateCaseConstants.INNER_PACK_WEIGHT];

  // CASE DETAILS COLUMNS
  public static readonly CASE_UPC = {header: 'Case UPC', width: '150px'};
  public static readonly CASE_DESCRIPTION = {header: 'Case description', width: '270px'};
  public static readonly VENDOR_PRODUCT_CODE = {header: 'VPC', width: '100px'};
  public static readonly COUNTRY_OF_ORIGIN = {header: 'Country of origin', width: '200px'};
  public static readonly WEIGHT_TYPE = {header: 'Weight type', width: '150px'};
  public static readonly CODE_DATE = {header: 'Code date', width: '100px'};
  public static readonly MAX_SHELF_LIFE = {header: 'Max shelf life', width: '125px'};
  public static readonly INBOUND_SPEC_DAYS = {header: 'Inbound spec', width: '125px'};
  public static readonly REACTION_DAYS = {header: 'Reaction days', width: '125px'};
  public static readonly GUARANTEE_TO_STORE_DAYS = {header: 'Guarantee to store days', width: '125px'};

  public static readonly CASE_DETAILS_COLUMN_GROUP =
    [UploadCandidateCaseConstants.CASE_UPC, UploadCandidateCaseConstants.CASE_DESCRIPTION,
      UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE, UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN,
      UploadCandidateCaseConstants.WEIGHT_TYPE, UploadCandidateCaseConstants.CODE_DATE,
      UploadCandidateCaseConstants.MAX_SHELF_LIFE, UploadCandidateCaseConstants.INBOUND_SPEC_DAYS,
      UploadCandidateCaseConstants.REACTION_DAYS, UploadCandidateCaseConstants.GUARANTEE_TO_STORE_DAYS];

  // DISPLAY READY UNITS
  public static readonly DISPLAY_READY_UNIT = {header: 'DRU', width: '100px'};
  public static readonly DRU_RETAIL_UNITS = {header: '# Retail units', width: '100px'};
  public static readonly DRU_ORIENTATION = {header: 'Orientation', width: '150px'};
  public static readonly DRU_TYPE = {header: 'DRU type', width: '150px'};

  public static readonly DISPLAY_READY_UNIT_COLUMN_GROUP =
    [UploadCandidateCaseConstants.DISPLAY_READY_UNIT, UploadCandidateCaseConstants.DRU_RETAIL_UNITS,
      UploadCandidateCaseConstants.DRU_ORIENTATION, UploadCandidateCaseConstants.DRU_TYPE];

  // OMI REMARKS
  public static readonly OMI_REMARK_1 = {header: 'Remark 1 for OMI', width: '100px'};
  public static readonly OMI_REMARK_2 = {header: 'Remark 2 for OMI', width: '100px'};

  public static readonly OMI_REMARKS_COLUMN_GROUP =
    [UploadCandidateCaseConstants.OMI_REMARK_1, UploadCandidateCaseConstants.OMI_REMARK_2];


  // All the Case columns for a New Warehouse Product
  public static readonly NEW_WAREHOUSE_PRODUCT_CASE_TAB_COLUMNS = [
    UploadCandidateCaseConstants.EMPTY_COLUMN,
    UploadCandidateCaseConstants.UNIT_UPC,
    UploadCandidateCaseConstants.COST_LINKED,
    UploadCandidateCaseConstants.COST_LINKED_ITEM,
    UploadCandidateCaseConstants.MASTER_LIST_COST,
    UploadCandidateCaseConstants.INNER_LIST_COST,
    UploadCandidateCaseConstants.DEAL_OFFERED,
    UploadCandidateCaseConstants.UNIT_COST,
    UploadCandidateCaseConstants.HEB_MARGIN,
    UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
    UploadCandidateCaseConstants.SOLD_BY_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS,
    UploadCandidateCaseConstants.MASTER_PACK_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.ORDER_FACTOR,
    UploadCandidateCaseConstants.BOXES_ON_PALLET,
    UploadCandidateCaseConstants.INNER_PACKS,
    UploadCandidateCaseConstants.INNER_PACK_QUANTITY,
    UploadCandidateCaseConstants.INNER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.INNER_PACK_WEIGHT,
    UploadCandidateCaseConstants.CASE_UPC,
    UploadCandidateCaseConstants.CASE_DESCRIPTION,
    UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE,
    UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN,
    UploadCandidateCaseConstants.WEIGHT_TYPE,
    UploadCandidateCaseConstants.CODE_DATE,
    UploadCandidateCaseConstants.MAX_SHELF_LIFE,
    UploadCandidateCaseConstants.INBOUND_SPEC_DAYS,
    UploadCandidateCaseConstants.REACTION_DAYS,
    UploadCandidateCaseConstants.GUARANTEE_TO_STORE_DAYS,
    UploadCandidateCaseConstants.DISPLAY_READY_UNIT,
    UploadCandidateCaseConstants.DRU_RETAIL_UNITS,
    UploadCandidateCaseConstants.DRU_ORIENTATION,
    UploadCandidateCaseConstants.DRU_TYPE,
    UploadCandidateCaseConstants.OMI_REMARK_1,
    UploadCandidateCaseConstants.OMI_REMARK_2];


  // All the Case columns for a New Warehouse Product
  public static readonly NEW_DSD_PRODUCT_CASE_TAB_COLUMNS = [
    UploadCandidateCaseConstants.EMPTY_COLUMN,
    UploadCandidateCaseConstants.UNIT_UPC,
    UploadCandidateCaseConstants.COST_LINKED,
    UploadCandidateCaseConstants.COST_LINKED_ITEM,
    UploadCandidateCaseConstants.MASTER_LIST_COST,
    UploadCandidateCaseConstants.DEAL_OFFERED,
    UploadCandidateCaseConstants.UNIT_COST,
    UploadCandidateCaseConstants.HEB_MARGIN,
    UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
    UploadCandidateCaseConstants.SOLD_BY_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS,
    UploadCandidateCaseConstants.CASE_DESCRIPTION,
    UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE,
    UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN];

// All the Case columns for Buyer New Product
  public static readonly BUYER_REVIEW_CASE_TAB_COLUMNS = [
    UploadCandidateCaseConstants.EMPTY_COLUMN,
    UploadCandidateCaseConstants.UNIT_UPC,
    UploadCandidateCaseConstants.COST_LINKED,
    UploadCandidateCaseConstants.COST_LINKED_ITEM,
    UploadCandidateCaseConstants.MASTER_LIST_COST,
    UploadCandidateCaseConstants.INNER_LIST_COST,
    UploadCandidateCaseConstants.DEAL_OFFERED,
    UploadCandidateCaseConstants.UNIT_COST,
    UploadCandidateCaseConstants.HEB_MARGIN,
    UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
    UploadCandidateCaseConstants.SOLD_BY_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS,
    UploadCandidateCaseConstants.MASTER_PACK_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.ORDER_FACTOR,
    UploadCandidateCaseConstants.BOXES_ON_PALLET,
    UploadCandidateCaseConstants.INNER_PACKS,
    UploadCandidateCaseConstants.INNER_PACK_QUANTITY,
    UploadCandidateCaseConstants.INNER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.INNER_PACK_WEIGHT,
    UploadCandidateCaseConstants.CASE_UPC,
    UploadCandidateCaseConstants.CASE_DESCRIPTION,
    UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE,
    UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN,
    UploadCandidateCaseConstants.WEIGHT_TYPE,
    UploadCandidateCaseConstants.CODE_DATE,
    UploadCandidateCaseConstants.MAX_SHELF_LIFE,
    UploadCandidateCaseConstants.DISPLAY_READY_UNIT,
    UploadCandidateCaseConstants.DRU_RETAIL_UNITS,
    UploadCandidateCaseConstants.DRU_ORIENTATION,
    UploadCandidateCaseConstants.DRU_TYPE];

// All the Case columns for Buyer New Product
  public static readonly BUYER_REVIEW_NO_CODE_DATE_CASE_TAB_COLUMNS = [
    UploadCandidateCaseConstants.EMPTY_COLUMN,
    UploadCandidateCaseConstants.UNIT_UPC,
    UploadCandidateCaseConstants.COST_LINKED,
    UploadCandidateCaseConstants.COST_LINKED_ITEM,
    UploadCandidateCaseConstants.MASTER_LIST_COST,
    UploadCandidateCaseConstants.INNER_LIST_COST,
    UploadCandidateCaseConstants.DEAL_OFFERED,
    UploadCandidateCaseConstants.UNIT_COST,
    UploadCandidateCaseConstants.HEB_MARGIN,
    UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
    UploadCandidateCaseConstants.SOLD_BY_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS,
    UploadCandidateCaseConstants.MASTER_PACK_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.ORDER_FACTOR,
    UploadCandidateCaseConstants.BOXES_ON_PALLET,
    UploadCandidateCaseConstants.INNER_PACKS,
    UploadCandidateCaseConstants.INNER_PACK_QUANTITY,
    UploadCandidateCaseConstants.INNER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.INNER_PACK_WEIGHT,
    UploadCandidateCaseConstants.CASE_UPC,
    UploadCandidateCaseConstants.CASE_DESCRIPTION,
    UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE,
    UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN,
    UploadCandidateCaseConstants.WEIGHT_TYPE,
    UploadCandidateCaseConstants.MAX_SHELF_LIFE,
    UploadCandidateCaseConstants.DISPLAY_READY_UNIT,
    UploadCandidateCaseConstants.DRU_RETAIL_UNITS,
    UploadCandidateCaseConstants.DRU_ORIENTATION,
    UploadCandidateCaseConstants.DRU_TYPE];

// All the Case columns for Buyer New Product
  public static readonly SCA_REVIEW_CASE_TAB_COLUMNS = [
    UploadCandidateCaseConstants.EMPTY_COLUMN,
    UploadCandidateCaseConstants.UNIT_UPC,
    UploadCandidateCaseConstants.COST_LINKED,
    UploadCandidateCaseConstants.COST_LINKED_ITEM,
    UploadCandidateCaseConstants.MASTER_LIST_COST,
    UploadCandidateCaseConstants.INNER_LIST_COST,
    UploadCandidateCaseConstants.DEAL_OFFERED,
    UploadCandidateCaseConstants.UNIT_COST,
    UploadCandidateCaseConstants.HEB_MARGIN,
    UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
    UploadCandidateCaseConstants.SOLD_BY_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS,
    UploadCandidateCaseConstants.MASTER_PACK_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.ORDER_FACTOR,
    UploadCandidateCaseConstants.BOXES_ON_PALLET,
    UploadCandidateCaseConstants.INNER_PACKS,
    UploadCandidateCaseConstants.INNER_PACK_QUANTITY,
    UploadCandidateCaseConstants.INNER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.INNER_PACK_WEIGHT,
    UploadCandidateCaseConstants.CASE_UPC,
    UploadCandidateCaseConstants.CASE_DESCRIPTION,
    UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE,
    UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN,
    UploadCandidateCaseConstants.WEIGHT_TYPE,
    UploadCandidateCaseConstants.CODE_DATE,
    UploadCandidateCaseConstants.MAX_SHELF_LIFE,
    UploadCandidateCaseConstants.INBOUND_SPEC_DAYS,
    UploadCandidateCaseConstants.REACTION_DAYS,
    UploadCandidateCaseConstants.GUARANTEE_TO_STORE_DAYS,
    UploadCandidateCaseConstants.DISPLAY_READY_UNIT,
    UploadCandidateCaseConstants.DRU_RETAIL_UNITS,
    UploadCandidateCaseConstants.DRU_ORIENTATION,
    UploadCandidateCaseConstants.DRU_TYPE];

// All the Case columns for Buyer New Product
  public static readonly SCA_REVIEW_NO_CODE_DATE_CASE_TAB_COLUMNS = [
    UploadCandidateCaseConstants.EMPTY_COLUMN,
    UploadCandidateCaseConstants.UNIT_UPC,
    UploadCandidateCaseConstants.COST_LINKED,
    UploadCandidateCaseConstants.COST_LINKED_ITEM,
    UploadCandidateCaseConstants.MASTER_LIST_COST,
    UploadCandidateCaseConstants.INNER_LIST_COST,
    UploadCandidateCaseConstants.DEAL_OFFERED,
    UploadCandidateCaseConstants.UNIT_COST,
    UploadCandidateCaseConstants.HEB_MARGIN,
    UploadCandidateCaseConstants.HEB_PENNY_PROFIT,
    UploadCandidateCaseConstants.SOLD_BY_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS,
    UploadCandidateCaseConstants.MASTER_PACK_WEIGHT,
    UploadCandidateCaseConstants.MASTER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.ORDER_FACTOR,
    UploadCandidateCaseConstants.BOXES_ON_PALLET,
    UploadCandidateCaseConstants.INNER_PACKS,
    UploadCandidateCaseConstants.INNER_PACK_QUANTITY,
    UploadCandidateCaseConstants.INNER_PACK_DIMENSIONS,
    UploadCandidateCaseConstants.INNER_PACK_WEIGHT,
    UploadCandidateCaseConstants.CASE_UPC,
    UploadCandidateCaseConstants.CASE_DESCRIPTION,
    UploadCandidateCaseConstants.VENDOR_PRODUCT_CODE,
    UploadCandidateCaseConstants.COUNTRY_OF_ORIGIN,
    UploadCandidateCaseConstants.WEIGHT_TYPE,
    UploadCandidateCaseConstants.MAX_SHELF_LIFE,
    UploadCandidateCaseConstants.DISPLAY_READY_UNIT,
    UploadCandidateCaseConstants.DRU_RETAIL_UNITS,
    UploadCandidateCaseConstants.DRU_ORIENTATION,
    UploadCandidateCaseConstants.DRU_TYPE];
}
