import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TableModule} from 'primeng/table';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {TaskViewerTableInProgressComponent} from './task-viewer-table-in-progress.component';

@NgModule({
  declarations: [TaskViewerTableInProgressComponent],
  exports: [TaskViewerTableInProgressComponent],
  imports: [
    CommonModule, TableModule, PipesModule
  ]
})
export class TaskViewerTableInProgressModule { }
