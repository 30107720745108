import { Injectable } from '@angular/core';
import {Candidate, CandidateError, CandidateProductError, WarehouseSupplierItemError} from 'pm-models';

@Injectable({
  providedIn: 'root'
})
export class CandidateErrorUtilService {

  constructor() { }


  /**
   * Whether or not there's a 706 store error.
   * @param candidateProductError
   * @param splrLocationGroupId
   */
  public has706StoreError(candidateProductError, splrLocationGroupId): boolean {
    return candidateProductError && candidateProductError.locationGroupStoresErrors &&
      candidateProductError.locationGroupStoresErrors[splrLocationGroupId] &&
      candidateProductError.locationGroupStoresErrors[splrLocationGroupId].stores;
  }

  /**
   * Returns the 706 store error, if any.
   * @param candidateProductError
   * @param splrLocationGroupId
   */
  public get706StoreError(candidateProductError, splrLocationGroupId): string {
    if (this.has706StoreError(candidateProductError, splrLocationGroupId)) {
      return candidateProductError.locationGroupStoresErrors[splrLocationGroupId].stores;
    } else {
      return '';
    }
  }

  /**
   * Used for checking if any candidateErrors are in candidateErrorsMap when handling multiple candidates ona single page.
   * @param candidateErrorsMap
   */
  hasCandidateProductErrorMessagesFromCandidateErrorMap(candidateErrorsMap): boolean {
    for (const candidateError of candidateErrorsMap.values()) {
      if (candidateError.hasErrors || candidateError.candidateProductErrors[0]?.errors?.length) {
        return true;
      }
    }
    return false;
  }

  getCandidateProductErrorMessagesFromCandidateErrorMap(candidateErrorsMap): string[] {
    let errors = [];
    candidateErrorsMap.forEach(candidateError => {
      if (candidateError.hasErrors) {
        errors = errors.concat(candidateError.errors);
        for (const key of Object.keys(candidateError.candidateProductErrors)) {
          if (candidateError.candidateProductErrors && candidateError.candidateProductErrors[key]?.errors?.length) {
            errors = errors.concat(candidateError.candidateProductErrors[key].errors);
          }
        }
        if (candidateError.costLink) {
          errors = errors.concat(candidateError.costLink);
        }
      }
    });
    return errors;
  }

  /**
   * Returns whether or not there are candidate product error messages.
   * @param candidateError
   */
  public hasCandidateProductErrorMessages(candidateError: CandidateError): boolean {
    if (!candidateError || !candidateError.candidateProductErrors || candidateError.candidateProductErrors.size === 0) {
      return false;
    }

    let candidateProductError;
    for (const key of Object.keys(candidateError.candidateProductErrors)) {
      candidateProductError = candidateError.candidateProductErrors[key];
      if (candidateProductError && candidateProductError.errors && candidateProductError.errors.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns any candidate product error messages.
   * @param candidateError
   */
  public getCandidateProductErrorMessages(candidateError: CandidateError): string[] {
    if (!candidateError || !candidateError.candidateProductErrors || candidateError.candidateProductErrors.size === 0) {
      return [];
    }

    let errors: string[] = [];
    let candidateProductError;
    for (const key of Object.keys(candidateError.candidateProductErrors)) {
      candidateProductError = candidateError.candidateProductErrors[key];
      if (candidateProductError && candidateProductError.errors && candidateProductError.errors.length > 0) {
        errors = errors.concat(candidateProductError.errors);
      }
    }
    return errors;
  }

  /**
   * Returns if there's any product detail level errors.
   * @param candidateErrors
   */
  public hasProductDetailErrors(candidateErrors: CandidateError[]) {
    if (!candidateErrors || !candidateErrors.length) {
      return false;
    }
    const candidateErrorKeys = ['commodity', 'subCommodity', 'merchandiseType', 'pssDepartment', 'season', 'seasonYear',
      'likeId', 'numberOfStores', 'taxable', 'vertexTaxCategoryModel', 'foodStamp', 'flexibleSpendingAccount', 'retailXFor', 'retailPrice', 'retailLink',
      'weightSwitch', 'suggestedXFor', 'suggestedRetailPrice', 'mapRetail', 'packageType', 'productLength', 'productWidth', 'productHeight',
      'productWeight', 'unitOfMeasure', 'retailSize', 'totalVolume'];
    const candidateProductErrorKeys = ['description', 'customerFriendlyDescription1', 'customerFriendlyDescription2', 'romanceCopy'];
    const warehouseErrorKeys = [];
    return this.hasCandidateErrorsByKeys(candidateErrors, candidateErrorKeys, candidateProductErrorKeys, warehouseErrorKeys);
  }

  /**
   * Returns if there's any case detail level errors.
   * @param candidateErrors
   */
  public hasCaseDetailErrors(candidateErrors: CandidateError[]) {
    if (!candidateErrors || !candidateErrors.length) {
      return false;
    }
    const candidateErrorKeys = ['displayReadyUnit', 'costLinked', 'costLink', 'masterListCost', 'innerListCost', 'dealOffered', 'unitCost', 'weightSwitch', 'masterPack',
      'masterWeight', 'cubeAdjustedFactor', 'vendorTie', 'vendorTier', 'innerPack', 'innerLength', 'innerWidth', 'innerHeight', 'innerWeight',
      'itemWeightType', 'codeDate', 'maxShelfLife', 'inboundSpecDays', 'warehouseReactionDays', 'guaranteeToStoreDays',
      'displayReadyUnitRowsDeep', 'displayReadyUnitRowsFacing', 'displayReadyUnitRowsHigh', 'displayReadyUnitOrientation',
      'displayReadyUnitType'];
    const candidateProductErrorKeys = ['upc', 'caseUpc', 'caseDescription', 'vendorProductCode', 'countryOfOrigin', 'remark1', 'remark2'];
    const warehouseErrorKeys = [];
    return this.hasCandidateErrorsByKeys(candidateErrors, candidateErrorKeys, candidateProductErrorKeys, warehouseErrorKeys);
  }

  /**
   * Returns if there's any supplier detail level errors.
   * @param candidateErrors
   */
  public hasSupplierDetailErrors(candidateErrors: CandidateError[]) {
    if (!candidateErrors || !candidateErrors.length) {
      return false;
    }
    const candidateErrorKeys = ['dsdSwitch', 'warehouseSwitch', 'productType', 'buyer', 'brand', 'subBrand', 'costOwner', 'topToTop', 'vendor', 'lane'];
    const candidateProductErrorKeys = ['upc'];
    const warehouseErrorKeys = [];
    return this.hasCandidateErrorsByKeys(candidateErrors, candidateErrorKeys, candidateProductErrorKeys, warehouseErrorKeys);
  }

  /**
   * Returns if there's any warehouse detail level errors.
   * @param candidateErrors
   */
  public hasWarehouseDetailErrors(candidateErrors: CandidateError[]) {
    if (!candidateErrors || !candidateErrors.length) {
      return false;
    }
    const candidateErrorKeys = ['maxShip'];
    const candidateProductErrorKeys = ['upc', 'missingWarehouseSupplier'];
    const warehouseErrorKeys = ['bicep'];
    return this.hasCandidateErrorsByKeys(candidateErrors, candidateErrorKeys, candidateProductErrorKeys, warehouseErrorKeys);
  }

  /**
   * Returns if there's any dsd level errors.
   * @param candidateErrors
   */
  public hasDsdErrors(candidateErrors: CandidateError[]) {
    if (!candidateErrors || !candidateErrors.length) {
      return false;
    }
    const candidateErrorKeys = ['buyer', 'vendor', 'masterListCost', 'masterPack', 'innerPack'];
    const candidateProductErrorKeys = ['upc', 'vendorProductCode', 'caseDescription', 'countryOfOrigin', 'missingStoresError'];
    const warehouseErrorKeys = [];
    return this.hasCandidateErrorsByKeys(candidateErrors, candidateErrorKeys, candidateProductErrorKeys, warehouseErrorKeys);
  }

  /**
   * Returns if there's candidate error level error for the supplied fields names.
   * @param candidate
   * @param candidateIdToCandidateErrorMap
   * @param fieldNames
   */
  public hasCandidateError(candidate: Candidate, candidateIdToCandidateErrorMap: Map<number, CandidateError>, fieldNames: string[]): boolean {

    if (!candidate || !fieldNames  || !fieldNames.length || !candidateIdToCandidateErrorMap || !candidateIdToCandidateErrorMap.get(candidate.candidateId)) {
      return false;
    }

    for (const fieldName of fieldNames) {
      if (candidateIdToCandidateErrorMap.get(candidate.candidateId)[fieldName]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns candidate error level text for the supplied fields names.
   * @param candidate
   * @param candidateIdToCandidateErrorMap
   * @param fieldNames
   */
  public getCandidateErrorText(candidate: Candidate, candidateIdToCandidateErrorMap: Map<number, CandidateError>, fieldNames: string[]) {

    if (!candidate || !fieldNames  || !fieldNames.length || !candidateIdToCandidateErrorMap || !candidateIdToCandidateErrorMap.get(candidate.candidateId)) {
      return null;
    }
    let errors = '';
    for (const fieldName of fieldNames) {
      if (candidateIdToCandidateErrorMap.get(candidate.candidateId)[fieldName]) {
        if (errors) {
          errors += '\n\n';
        }
        errors += candidateIdToCandidateErrorMap.get(candidate.candidateId)[fieldName];
      }
    }
    return errors;
  }

  /**
   * Returns candidate product error level text for the supplied fields names.
   * @param candidate
   * @param candidateProductIndex
   * @param candidateIdToCandidateErrorMap
   * @param fieldNames
   */
  public getCandidateProductErrorText(candidate: Candidate, candidateProductIndex: number,
                                      candidateIdToCandidateErrorMap: Map<number, CandidateError>, fieldNames: string[]) {

    if (!candidate || !fieldNames || !fieldNames.length || candidateProductIndex === null || candidateProductIndex === undefined ||
      !candidateIdToCandidateErrorMap || !candidateIdToCandidateErrorMap.get(candidate.candidateId)) {
      return null;
    }
    const candidateProductError = candidateIdToCandidateErrorMap.get(candidate.candidateId)
      .candidateProductErrors[candidate?.candidateProducts[candidateProductIndex].id];

    if (!candidateProductError) {
      return null;
    }

    let errors = '';
    for (const fieldName of fieldNames) {
      if (candidateProductError[fieldName]) {
        if (errors) {
          errors += '\n';
        }
        errors += candidateProductError[fieldName];
      }
    }
    return errors;
  }

  /**
   * Returns if there's candidate product error level error for the supplied fields names.
   * @param candidate
   * @param candidateProductIndex
   * @param candidateIdToCandidateErrorMap
   * @param fieldNames
   */
  public hasCandidateProductError(candidate: Candidate, candidateProductIndex: number,
                                  candidateIdToCandidateErrorMap: Map<number, CandidateError>, fieldNames: string[]): boolean {

    if (!candidate || !fieldNames || !fieldNames.length || candidateProductIndex === null || candidateProductIndex === undefined ||
      !candidateIdToCandidateErrorMap || !candidateIdToCandidateErrorMap.get(candidate.candidateId)) {
      return false;
    }
    const candidateProductError = candidateIdToCandidateErrorMap.get(candidate.candidateId)
      .candidateProductErrors[candidate?.candidateProducts[candidateProductIndex].id];

    if (!candidateProductError) {
      return false;
    }

    for (const fieldName of fieldNames) {
      if (candidateProductError[fieldName]) {
        return true;
      }
    }
    return false;
  }


  private hasCandidateErrorsByKeys(candidateErrors: CandidateError[], candidateErrorKeys: string[], candidateProductErrorKeys: string[], warehouseErrorKeys: string[]): boolean {

    for (const candidateError of candidateErrors) {
      if (this.hasCandidateErrorByKeys(candidateError, candidateErrorKeys, candidateProductErrorKeys, warehouseErrorKeys)) {
        return true;
      }
    }
    return false;
  }


  private hasCandidateErrorByKeys(candidateError: CandidateError, candidateErrorKeys: string[], candidateProductErrorKeys: string[], warehouseErrorKeys: string[]): boolean {
    if (!candidateError || !candidateError.hasErrors) {
      return false;
    }

    // find and return any candidate level errors, if any.
    if (candidateErrorKeys && candidateErrorKeys.length > 0) {
      for (const candidateErrorKey of candidateErrorKeys) {
        if (candidateError[candidateErrorKey]) {
          return true;
        }
      }
    }

    // if there's no candidate level error or candidate product errors, return false.
    if (!candidateError.candidateProductErrors) {
      return false;
    }

    // validate lower fields.
    for (const [candidateProductErrorId, candidateProductError] of Object.entries(candidateError.candidateProductErrors)) {
      if (!candidateProductError) {
        continue;
      }
      if (this.hasCandidateProductErrorByKeys(candidateProductError, candidateProductErrorKeys)) {
        return true;
      }
      if (this.hasWarehouseErrorByKeys(candidateProductError, warehouseErrorKeys)) {
        return true;
      }
      if (this.hasWarehouseSupplierItemsErrorByKeys(candidateProductError, warehouseErrorKeys)) {
        return true;
      }
    }
    return false;
  }

  private hasCandidateProductErrorByKeys(candidateProductError: CandidateProductError, candidateProductErrorKeys: string[]): boolean {
    if (!candidateProductError) {
      return false;
    }

    // check candidate product level fields.
    if (candidateProductErrorKeys && candidateProductErrorKeys.length > 0) {
      for (const candidateProductErrorKey of candidateProductErrorKeys) {
        if (candidateProductError[candidateProductErrorKey]) {
          return true;
        }
      }
    }
  }

  private hasWarehouseErrorByKeys(candidateProductError: CandidateProductError, warehouseErrorKeys: string[]): boolean {
    if (!candidateProductError) {
      return false;
    }

    // check warehouse level fields
    if (warehouseErrorKeys && warehouseErrorKeys.length > 0) {
      const warehouseEntry = Object.entries(candidateProductError).filter(([key, warehouseErrors]) => key === 'warehouseErrors');

      // if there's no warehouse errors return.
      if (!warehouseEntry) {
        return false;
      }
      for (const warehouseErrorKey of warehouseErrorKeys) {
        for (const [warehouseKey, warehouseValue] of Object.entries(warehouseEntry.values())) {
          if (warehouseValue && warehouseValue[warehouseErrorKey]) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public hasWarehouseSupplierItemsErrorByKeys(candidateProductError: CandidateProductError, warehouseErrorKeys: string[]): boolean {
    if (!warehouseErrorKeys || !warehouseErrorKeys.length || !candidateProductError || !candidateProductError.warehouseSupplierItemErrors) {
      return false;
    }

    for (const warehouseErrorKey of warehouseErrorKeys) {
      for (const [warehouseSupplierItemKey, warehouseSupplierItemValue] of  Object.entries(candidateProductError.warehouseSupplierItemErrors)) {
        if (this.hasWarehouseSupplierItemErrorByKey(warehouseSupplierItemValue, warehouseErrorKey)) {
          return true;
        }
      }
    }
    return false;
  }

  private hasWarehouseSupplierItemErrorByKey(warehouseSupplierItemError: WarehouseSupplierItemError, warehouseErrorKey: string): boolean {
    if (!warehouseSupplierItemError) {
      return false;
    } else if (warehouseSupplierItemError.errors && warehouseSupplierItemError.errors.length) {
      return true;
    }
    for (const [warehouseKey, warehouseErrorValue] of Object.entries(warehouseSupplierItemError.warehouseErrors)) {
      if (warehouseErrorValue && warehouseErrorValue[warehouseErrorKey]) {
        return true;
      }
    }
    return false;
  }

  public getWarehouseSupplierItemError(candidate: Candidate, candidateProductIndex: number,
                                       candidateIdToCandidateErrorMap: Map<number, CandidateError>, fieldNames: string[],
                                       warehouseSupplierId, warehouseId) {

    if (!candidate || !fieldNames || !fieldNames.length || !warehouseSupplierId || !warehouseId || candidateProductIndex === null ||
      candidateProductIndex === undefined || !candidateIdToCandidateErrorMap || !candidateIdToCandidateErrorMap.get(candidate.candidateId)) {
      return null;
    }

    const candidateProductError: CandidateProductError = candidateIdToCandidateErrorMap.get(candidate.candidateId)
      .candidateProductErrors[candidate?.candidateProducts[candidateProductIndex].id];

    if (!candidateProductError || !candidateProductError.warehouseSupplierItemErrors) {
      return null;
    }

    for (const warehouseErrorKey of fieldNames) {
      for (const [warehouseSupplierItemErrorKey, warehouseSupplierItemError] of  Object.entries(candidateProductError.warehouseSupplierItemErrors)) {
        if (!warehouseSupplierItemErrorKey || warehouseSupplierItemErrorKey !== warehouseSupplierId) {
          continue;
        }
        const error = this.getWarehouseSupplierItemWarehouseError(warehouseSupplierItemError, warehouseId, warehouseErrorKey);
        if (error) {
          return error;
        }
      }
    }
    return null;
  }

  getWarehouseSupplierItemWarehouseError(warehouseSupplierItemError: WarehouseSupplierItemError, warehouseId, warehouseErrorKey) {
    if (!warehouseSupplierItemError) {
      return null;
    }
    for (const [warehouseKey, warehouseErrorValue] of Object.entries(warehouseSupplierItemError.warehouseErrors)) {
      if (warehouseKey !== warehouseId) {
        continue;
      }
      if (warehouseErrorValue && warehouseErrorValue[warehouseErrorKey]) {
        return warehouseErrorValue[warehouseErrorKey];
      }
    }
    return null;
  }
}
