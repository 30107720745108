import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  Commodity,
  ErrorCandidate,
  HEBDetailsCardErrors,
  HEBDetailsCardModel,
  HEBDetailsCardPermissions,
  SupplierDetailsCardModel
} from 'pm-models';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper, VisiblePermissionsMapper} from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-heb-details-card',
  templateUrl: './heb-details-card.component.html',
  styleUrls: ['./heb-details-card.component.scss']
})
export class HebDetailsCardComponent implements OnInit {

  @Input()
  model: HEBDetailsCardModel | Candidate;

  @Input()
  candidateProductModel: CandidateProduct | SupplierDetailsCardModel;

  @Input()
  errorModel: HEBDetailsCardErrors | ErrorCandidate | CandidateError;

  @Input()
  candidateProductErrorModel: CandidateProductError;

  @Input()
  permissions: HEBDetailsCardPermissions;

  @Output() modelChange = new EventEmitter<any>();
  @Output() candidateProductModelChange = new EventEmitter<any>();

  @Output() buyerChange = new EventEmitter<any>();
  @Output() merchandiseTypeChange = new EventEmitter<any>();
  @Output() commodityChange = new EventEmitter<any>();
  @Output() subCommodityChange = new EventEmitter<any>();
  @Output() sellableTypeChange = new EventEmitter<any>();

  @Input() commodities: any;
  @Input() isDsd: boolean;
  @Input() commodityConfiguration: any;
  @Input() subCommodityConfiguration: any;

  _merchandiseTypes;

  @Input()
  get merchandiseTypes() {
    return this._merchandiseTypes;
  }

  set merchandiseTypes(e) {
    this._merchandiseTypes = e;
    this.merchandiseTypeConfiguration.collections = this._merchandiseTypes;
  }

  constructor() { }

  ngOnInit() {
  }

  buyerConfiguration: AttributeTypeaheadConfig = {
    label: 'Buyer',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.buyer),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.buyer),
    description: 'Choose the H-E-B buyer you\'re working with.',
    name: 'Buyer',
    idRef: 'buyerId',
    displayRef: 'displayName',
    displaySubRef: '',
    searchUrl: '/lookup/buyer',
    placeholderText: 'Type to search for a Buyer...'
  };

  buyerInitCallback(event) {
    this.sellableTypeChange.emit(this.model.productType);
  }

  onBuyerChange(event) {
    if (!event || !event.buyerId) {
      this.model.buyer = null;
      this.modelChange.emit(this.model);
      this.buyerChange.emit(event);
    } else {
      this.model.buyer = event;
      this.modelChange.emit(this.model);
      this.buyerChange.emit(event);
    }
  }

  get isSellable(): boolean {
    return this.model && this.model.productType === Candidate.SELLABLE;
  }

  isProductTypeHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.productType);
  }

  isMerchandiseTypeHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.merchandiseType) ||
      (this.model && this.model.productType === Candidate.SELLABLE);
  }

  isProductDescriptionHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.description) ||
      (this.model && this.model.productType === Candidate.SELLABLE);
  }

  sellableConfiguration: AttributeConfig = {
    label: 'Sellable',
    isRequired: true,
    isHidden: () => this.isProductTypeHidden(),
    isDisabled: () => this.isSellableDisabled(),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.productType),
    description: 'Indicate if this is a product H-E-B will sell to customers or if it\'s for internal store use only.',
    defaultValue: false
  };

  isSellableDisabled() {
    return DisabledPermissionsMapper(this.permissions, this.permissions.productType) ||
      this.model.dsdSwitch;

  }

  merchandiseTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Merchandise type',
    description: 'Tell us what kind of non-sellable item this is.',
    isRequired: true,
    isHidden: () => this.isMerchandiseTypeHidden(),
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'merchandiseTypeCode',
    displayRef: 'description',
    placeholderText: 'Select a merchandise type...',
    collections: this.merchandiseTypes
  };

  sellableChange(event) {
    this.model.productType = (event.checked) ? 'SELLABLE' : 'NON_SELLABLE';
    this.sellableTypeChange.emit(this.model.productType);
  }

  onMerchandiseTypeChange(event) {
    if (!event || !event.merchandiseTypeCode) {
      this.model.merchandiseType = null;
      this.modelChange.emit(this.model);
      this.merchandiseTypeChange.emit(this.model.merchandiseType);
    } else {
      this.model.merchandiseType = event;
      this.modelChange.emit(this.model);
      this.merchandiseTypeChange.emit(this.model.merchandiseType);
    }
  }

  merchandiseTypeInitCallback(event) {
    this.merchandiseTypeChange.emit(event);
  }

  descriptionConfiguration: AttributeTextInputConfig = {
    label: 'Product Description',
    description: `Enter a description of this product. Many H-E-B apps will refer to this description.`,
    placeholderText: 'Description',
    isHidden: () => this.isProductDescriptionHidden(),
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.description),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.description),
    isRequired: true,
    name: 'productDescriptionId'
  };

  onSubCommodityChange(event) {
    if (!event || !event.subCommodityId) {
      this.model.subCommodity = null;
      this.modelChange.emit(this.model);
      this.subCommodityChange.emit(event);
    } else {
      this.model.subCommodity = event;
      this.modelChange.emit(this.model);
      this.subCommodityChange.emit(event);
    }
  }

  onCommodityChange(event) {
    if (!event || !event.commodityId) {
      this.model.commodity = null;
      this.model.pssDepartment = null;
      this.modelChange.emit(this.model);
      this.commodityChange.emit(event);
    } else {
      this.model.commodity = event;
      this.getSubCommodities(event);
      this.modelChange.emit(this.model);
      this.commodityChange.emit(event);
      this.model.pssDepartment = event.pssDepartment;
    }
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodityConfiguration.collections = e.subCommodityList;
  }
}
