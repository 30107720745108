import {DsdSupplierItem, Hierarchy, StoreAuthorization} from 'pm-models';

export class DsdItem {
  upc?: number;
  description?: string;
  storeAuthorizations: StoreAuthorization[] = [];
  hierarchy?: Hierarchy;
  dsdSupplierItems?: DsdSupplierItem[];
  totalVolume?: any;
  mrt?: any;
  innerPackQuantity?: number;
  isViewing?: Boolean;
}
