<div *ngIf="candidate && isRejected()" class="row justify-content-md-center isRejected">
  <div class="col-md-10">
    <div class="row ml-0">
      <div class="borderLeft rejectBackground p-2 col-md-0">
        <i class="material-icons ui-invalid rotate-45 inline-block">add_circle_outline</i>
      </div>
      <div class="col-md-8 rejectBackground">
        <div class="row pt-2 pb-2 font-weight-bold">
          <div>Candidate Rejected</div>
        </div>
        <div class="row pb-2">
          <div>Reason:</div>
          <div class="pl-1">{{getRejectReason()}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
