import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardModule} from '../../shared/card/card.module';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {MaxShipCardComponent} from './max-ship-card.component';


@NgModule({
  declarations: [MaxShipCardComponent],
  exports: [MaxShipCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule,
    AttributeDisplayModule
  ]
})
export class MaxShipCardModule { }
