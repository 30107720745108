import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent, MtrHeaderSectionDirective, MtrHeaderTitleDirective } from './header.component';
import { RouterModule } from '@angular/router';
import { ToolbarModule } from '../shared/toolbar/toolbar.module';

const COMPONENTS = [HeaderComponent, MtrHeaderSectionDirective, MtrHeaderTitleDirective];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, RouterModule, ToolbarModule],
  exports: [...COMPONENTS]
})
export class HeaderModule {}
