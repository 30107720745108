import { InjectionToken } from '@angular/core';

/** Available drawer position options */
export type MtrDrawerPosition = 'start' | 'end';

/** Available drawer mode options */
export type MtrDrawerMode = 'over' | 'push' | 'side' | 'peek';

/** Result of the toggle promise that indicates the state of the drawer. */
export type MtrDrawerToggleResult = 'open' | 'close';

/** Configures whether drawers should use auto sizing by default. */
export const MTR_DRAWER_DEFAULT_AUTOSIZE = new InjectionToken<boolean>(
  'MTR_DRAWER_DEFAULT_AUTOSIZE',
  {
    providedIn: 'root',
    factory: MTR_DRAWER_DEFAULT_AUTOSIZE_FACTORY,
  }
);

/** @hidden */
export function MTR_DRAWER_DEFAULT_AUTOSIZE_FACTORY(): boolean {
  return false;
}
