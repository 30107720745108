import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {SellableComponent} from './sellable.component';


@NgModule({
  declarations: [SellableComponent],
  exports: [SellableComponent, AttributeDisplayModule],
  imports: [
    CommonModule, AttributeDisplayModule
  ]
})
export class SellableModule { }
