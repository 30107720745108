<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-new-dsd-product-stepper *ngIf="supplierProductService.getCandidate() && supplierProductService.isDsdOnly()"
                                 [candidateErrors]="supplierProductService.getAllDsdCandidateErrors()"
                                 [stepIndex]="5"
                                 [isSellable]="supplierProductService.isSellable(supplierProductService.getCandidate())"
                                 (indexClick)="onClickStepper($event)">
    </app-new-dsd-product-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Authorize stores</pm-title>
  <pm-sub-title>Set up store authorization for {{distributorName}}.</pm-sub-title>
  <p></p><p></p><p></p>

  <div style="width: 95%;">
    <pm-no-stores-message-extended *ngIf="showNoStoresError"
                                   [candidate]="supplierProductService.candidate">
    </pm-no-stores-message-extended>
  </div>

  <div *ngIf="candidateProductError?.missingStoresError" class="row ml-0">
    <div class="col-md-10 p-0">
      <div class="row stores-not-found-container">
        <img class="ban-icon" src="/assets/images/ban-icon.svg">
        <div class="header-row">
          Select at least one store.
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showNoStoresError === false">
    <h4>Find stores</h4>
    <p style="max-width: 70%;">Find stores by selecting one or more authorization groups, entering a list of store numbers, or searching for stores by region or county.</p>

    <ng-container *ngIf="supplierProductService.getCandidate()">
      <p-selectButton class="authGroupFilter" [options]="types" [(ngModel)]="selectedType" (onChange)="onFilterChange()"></p-selectButton>

      <div class="mt-4 mb-3" style="font-weight: bold;" *ngIf="selectedType === 'stores'">
        <p>Stores</p>
        <div class="row storeGroup">
          <div class="col-md-6">
            <pm-attribute-display-text-input [attributeError]="storesErrorMessage"
                                             (modelChange)="storesInputChangeEvent($event)"
                                             [attribute]="storesInputConfiguration"
                                             [(model)]="storesInput">
            </pm-attribute-display-text-input>
            <p-button class="ghost m-0"
                      label="Show all of my stores"
                      (onClick)="onClickShowAllStores()">
            </p-button>
            <div style="display: inline-block;" pTooltip="{{validateStoresInput(storesInput) ? 'Enter at least one store.' : ''}}">
              <button pButton type="button"
                      class="ui-button-info ml-2"
                      label="Show these stores"
                      (click)="onClickShowTheseStores()"
                      [disabled]="validateStoresInput(storesInput)">
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 mb-3" style="font-weight: bold;" *ngIf="selectedType === 'groups'">
        <p>Authorization groups</p>
        <div *ngIf="supplierProductService.authGroups">
          <div class="row ml-0">
            <div class="col-md-4 authGroupHeader authGroupCheckboxGroup">
              <pm-attribute-display-checkbox class="pl-0"
                                             [attribute]="allGroupsConfiguration"
                                             [(model)]="selectAllAuthGroups"
                                             (change)="onClickAllAuthGroupCheckbox($event)">
              </pm-attribute-display-checkbox>
            </div>
          </div>
          <div class="row ml-0">
            <div class="col-md-4 authGroupBody authGroupCheckboxGroup">
              <ng-template ngFor let-authGroup let-i="index" [ngForOf]="supplierProductService.authGroups">

                <pm-attribute-display-checkbox *ngIf="authGroup.stores && authGroup.stores.length > 0" class="pl-0"
                                               [attribute]="authGroup.configuration"
                                               [(model)]="authGroup.checked"
                                               (change)="onAuthGroupButtonClick(i, $event)">
                </pm-attribute-display-checkbox>
              </ng-template>
            </div>
          </div>
          <div class="row ml-0">
            <div class="col-md-4 mt-3 pr-0 text-right" pTooltip="{{!hasSelectedGroup() ? 'Select at least one group.' : ''}}">
              <button pButton type="button" class="ui-button-info" label="Show stores"
                      [disabled]="!hasSelectedGroup()"
                      (click)="onClickGroupShowStores()">
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
