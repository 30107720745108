import { BaseAttribute } from './base-attribute';
import { AttributeVolumeConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayVolumeComponent } from '../../shared/attribute-display/attribute-display-volume/attribute-display-volume.component';

export class RetailUnitCount implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayVolumeComponent;
  }

  getAttributeConfig(overrides?): AttributeVolumeConfig {
    return {
      label: '# Retail units',
      description: `Tell us how many retail units wide, deep, and high this display ready unit is.`,
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      numberCount: 3,
      decimalCount: 0,
      widthPlaceholder: 'Deep',
      heightPlaceholder: 'High',
      lengthPlaceholder: 'Wide',
      volumePlaceholder: '(total)',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayVolumeComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    // componentInstance.model = candidate.masterPack;
    componentInstance.lengthModel = candidate.displayReadyUnitRowsFacing;
    componentInstance.lengthModelChange.subscribe(x => {
      candidate.displayReadyUnitRowsFacing = x;
    });

    componentInstance.heightModel = candidate.displayReadyUnitRowsHigh;
    componentInstance.heightModelChange.subscribe(x => {
      candidate.displayReadyUnitRowsHigh = x;
    });

    componentInstance.widthModel = candidate.displayReadyUnitRowsDeep;
    componentInstance.widthModelChange.subscribe(x => {
      candidate.displayReadyUnitRowsDeep = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayVolumeComponent, candidate: Candidate) {
    if (componentInstance.lengthModel !== candidate.displayReadyUnitRowsFacing) {
      componentInstance.lengthModel = candidate.displayReadyUnitRowsFacing;
    }

    if (componentInstance.heightModel !== candidate.displayReadyUnitRowsHigh) {
      componentInstance.heightModel = candidate.displayReadyUnitRowsHigh;
    }

    if (componentInstance.widthModel !== candidate.displayReadyUnitRowsDeep) {
      componentInstance.widthModel = candidate.displayReadyUnitRowsDeep;
    }
  }
}
