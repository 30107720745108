import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RejectStatusHeaderComponent } from './reject-status-header.component';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';



@NgModule({
  declarations: [RejectStatusHeaderComponent],
  exports: [RejectStatusHeaderComponent, AttributeDisplayModule],
  imports: [
    CommonModule
  ]
})
export class RejectStatusHeaderModule { }
