import {Component, OnInit} from '@angular/core';
import {
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  MerchandisingInfoCardPermissions,
  ProductDescriptionsCardPermissions,
  ProductImagesCardPermissions,
  ProductPackagingCardPermissions,
  ProductPricingCardPermissions,
  RegulatoryCardPermissions,
  ScaleInformationCardPermissions
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {PiaProductService} from '../../service/pia-product.service';
import {AuthService} from '../../auth/auth.service';
import {CandidateService} from '../../service/candidate.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {LookupService} from '../../service/lookup.service';
import {UPCInputState, UpcService} from 'pm-components';
import {PiaNewProductFlowStepperComponent} from '../pia-new-product-flow-stepper/pia-new-product-flow-stepper.component';
import {PiaNewDsdProductFlowStepperComponent} from '../pia-new-dsd-product-flow-stepper/pia-new-dsd-product-flow-stepper.component';

@Component({
  selector: 'app-pia-new-product-details',
  templateUrl: './pia-new-product-details.component.html',
  styleUrls: ['./pia-new-product-details.component.scss']
})
export class PiaNewProductDetailsComponent implements OnInit {

  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public KEY_RETAIL = 'Key Retail';

  public isViewingPage = true;
  private taskSubscription$: any;
  public candidate: Candidate;
  public currentCandidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;

  public unitsOfMeasures: any;
  public packageTypes: any;
  public merchandiseTypes: any;
  public commodities: any;
  public upcState: UPCInputState;

  readonly productDescriptionsCardPermissions: ProductDescriptionsCardPermissions = {
    isReadOnly: false
  };

  readonly productPricingCardPermissions: ProductPricingCardPermissions = {
    isReadOnly: false
  };

  productPackagingCardPermissions: ProductPackagingCardPermissions = {
    depositScanCodeId: {isHidden: false,
      isDisabled: false},
    isReadOnly: false
  };

  readonly productImagesCardPermissions: ProductImagesCardPermissions = {
    isReadOnly: false
  };

  readonly merchandisingInfoCardPermissions: MerchandisingInfoCardPermissions = {
    isReadOnly: false
  };

  readonly regulatoryCardPermissions: RegulatoryCardPermissions = {
    isReadOnly: false
  };

  readonly scaleInformationCardPermissions: ScaleInformationCardPermissions = {
    isReadOnly: false
  };


  constructor(private route: ActivatedRoute, private router: Router, public piaProductService: PiaProductService,
              private authService: AuthService, private candidateService: CandidateService,
              public candidateUtilService: CandidateUtilService, private lookupService: LookupService, public upcService: UpcService) { }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.piaProductService.getTaskId(),
        this.piaProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.piaProductService.resetService();
        this.piaProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']).then();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.piaProductService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.piaProductService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  initializeData() {
    this.candidate = this.piaProductService.getCandidate();
    this.originalCandidate = this.piaProductService.getOriginalCandidate();
    this.currentCandidateProduct = this.piaProductService.getCurrentCandidateProduct();
    this.candidateError = this.piaProductService.getProductDetailsError();
    this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }
    if (this.candidate.buyer && this.candidate.buyer.buyerId) {
      this.setCommoditiesAndSubCommodities(this.candidate.buyer.buyerId);
    }

    if (!this.candidate.vertexTaxCategoryModel) {
      this.setVertexTaxCategoryModel();
    }
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.originalCandidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.originalCandidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      // Default to basic for sellable (if there's no merchandise type and no merchandise type error).
      if (!this.candidateError.merchandiseType && !this.originalCandidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
    if (!isSellable) {
      this.setNonSellableRetailValues();
    }
  }
  onClose() {
    this.isViewingPage = false;
    this.piaProductService.setCandidate(this.candidate);
    if (this.hasUserChangedFlows()) {
      this.piaProductService.deleteAndCreateNewProcessInstance().subscribe(() => {
        this.piaProductService.saveCandidateAndClose();
      });
    } else {
      this.piaProductService.saveCandidateAndClose();
    }
  }

  onClickNext() {
    this.piaProductService.setCandidate(this.candidate);

    const validators = [CandidateValidatorType.PIA_PRODUCT_DETAILS_VALIDATOR];
    if (this.piaProductService.isScaleProduct(this.piaProductService.getCandidate())) {
      validators.push(CandidateValidatorType.SCALE_ENGLISH_LABEL_VALIDATOR);
    }
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.candidate, validators).toPromise().then(data => {
      this.candidateError = this.piaProductService.setProductDetailsError(new CandidateError());
      this.candidateProductError = new CandidateProductError();
      if (this.hasUserChangedFlows()) {
        this.piaProductService.deleteAndCreateNewProcessInstance().subscribe(() => {
          this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
        });
      } else {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
      }
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = this.piaProductService.setProductDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
        if (this.hasUserChangedFlows()) {
          this.piaProductService.deleteAndCreateNewProcessInstance().subscribe(() => {
            this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
          });
        } else {
          this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
        }
      }
    });
  }

  hasUserChangedFlows() {
    // if show calories has changed, and it's not a scale, we need to switch off of the ob reg flow.
    return this.piaProductService.getOriginalCandidate().showCalories !== this.candidate.showCalories &&
      !this.piaProductService.isScaleProduct(this.piaProductService.candidate);
  }

  onClickBack() {
    this.piaProductService.setCandidate(this.candidate);
    if (this.hasUserChangedFlows()) {
      this.piaProductService.deleteAndCreateNewProcessInstance().subscribe(() => {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductSupplierDetails', false);
      });
    } else {
      this.piaProductService.saveCandidateAndNavigate('/piaNewProductSupplierDetails', false);
    }
  }

  /**
   * Sets non sellable default values.
   */
  private setNonSellableRetailValues() {
    this.originalCandidate.retailXFor = 1;
    this.originalCandidate.retailPrice = '0.00';
    this.originalCandidate.retailType = this.KEY_RETAIL;
  }
  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      // this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, set the commodity w/ sub com list
      if (commodity) {
        this.candidate.commodity = commodity;
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  public onCommodityChange(event) {
    this.candidate.candidateProducts[0].locationGroupStores = [];
    this.piaProductService.setAuthGroups(null);
    this.piaProductService.setSelectedAuthGroups(null);
    this.piaProductService.setNotFoundStores(null);
    this.setVertexTaxCategoryModel();
  }

  public onSubCommodityChange(event) {
    this.setVertexTaxCategoryModel();
  }

  /**
   * If override department changes, set the auth group information to null.
   */
  public onOverrideSubDepartmentChange() {
    this.candidate.candidateProducts[0].locationGroupStores = [];
    this.piaProductService.setAuthGroups(null);
    this.piaProductService.setSelectedAuthGroups(null);
    this.piaProductService.setNotFoundStores(null);
  }

  setVertexTaxCategoryModel() {
    this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe((candidate) => {
      this.candidate = candidate;
    });
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaNewProductFlowStepperComponent.UPC_ITEM_CODE: {
        this.jumpToPage('/piaSetupNewProduct', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.SUPPLIER_HEB_SETUP: {
        this.onClickBack();
        break;
      }
      case PiaNewProductFlowStepperComponent.PRODUCT_DETAILS: {
        this.jumpToPage('/piaNewProductDetails', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.CASE_PACK: {
        this.onClickNext();
        break;
      }
      case PiaNewDsdProductFlowStepperComponent.STORE_AUTHORIZATION: {
        if (!this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.jumpToPage('/piaStoreAuthorization', PiaProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/piaSetUpStores', PiaProductService.DSD_VALIDATORS);
        }
        break;
      }
      case PiaNewProductFlowStepperComponent.WAREHOUSE: {
        this.jumpToPage('/piaNewProductWarehouses', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.EXTENDED_ATTRIBUTES: {
        if (this.piaProductService.isDsdOnly()) {
          this.jumpToPage('/piaNewProductExtendedAttributes', PiaProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/piaNewProductExtendedAttributes', PiaProductService.BASE_VALIDATORS);
        }
        break;
      }
    }
  }

  jumpToPage(urlToNavigate, validator) {
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.piaProductService.getCandidate(),
      [validator]).toPromise().then(data => {
      this.candidateError = this.piaProductService.setSetupCandidateError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      this.piaProductService.setCandidate(this.piaProductService.getCandidate());
      if (this.hasUserChangedFlows()) {
        this.piaProductService.deleteAndCreateNewProcessInstance().subscribe(() => {
          this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);
        });
      } else {
        this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);
      }
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError =
          this.candidateError.candidateProductErrors[this.piaProductService.getCurrentCandidateProduct().id];
        this.piaProductService.setSetupCandidateError(this.candidateError);
        if (!this.piaProductService.isDsdOnly() || !PiaProductService.DSD_NON_NAVIGABLE_ON_ERROR_PAGES.includes(urlToNavigate)) {
          this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);
        }
      }
    });
  }


  validateDepositUpc() {
    const validators = [];
    validators.push(CandidateValidatorType.DEPOSIT_UPC_VALIDATOR);
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.piaProductService.getCurrentCandidateProduct().id;
    this.candidateService.validateCandidate(this.candidate, validators).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.depositScanCodeId = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.depositScanCodeId = returnedCandidateProductError.depositScanCodeId;
      }
    });
  }
}
