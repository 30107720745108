import {CandidateSearch} from 'pm-models/lib/candidateSearch';
import {CandidateSearchFilter} from 'pm-models/lib/candidateSearchFilter';
import {CandidateSearchParams} from 'pm-models/lib/candidateSearchParams';
import {CandidateSearchResult} from 'pm-models/lib/candidateSearchResult';
import {CandidateSearchResultsParams} from 'pm-models/lib/candidateSearchResultsParams';
import {CandidateSearchSort} from 'pm-models/lib/candidateSearchSort';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {GrowlService} from '../growl/growl.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {Candidate, CandidateErrorModel, CandidateValidatorType, File, ImageFile, UpdateMrtInnerRequest} from 'pm-models';
import {UserRoleConstants} from '../core/header/user-role-constants';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  private candidateServiceBaseUrl = '/candidates';
  private candidateServiceVersionBaseUrl = this.candidateServiceBaseUrl + '/versions';
  private uploadImageBaseUrl = this.candidateServiceBaseUrl + '/upload';
  private exportBaseUrl = this.candidateServiceBaseUrl + '/export';
  private candidateErrorBaseUrl = this.candidateServiceBaseUrl + '/error';
  private findDistributorCandidatesByUpcUrl = this.candidateServiceBaseUrl + '/findDistributorCandidatesByUpc';
  private findUploadedCandidatesErrorsByParentIdUrl = this.candidateErrorBaseUrl + '/findUploadedCandidatesErrorsByParentId';
  private findValidationErrorsByParentIdUrl = this.candidateErrorBaseUrl + '/findValidationErrorsByParentId';
  private findAllCandidatesByCandidateIdsUrl = this.candidateServiceBaseUrl + '/findAll';
  private updateMrtInnersUrl = this.candidateServiceBaseUrl + '/mrt/updateInners';

  constructor(private http: HttpClient, private growlService: GrowlService, private router: Router, private auth: AuthService) {
  }

  /**
   * Creates a new candidate.
   *
   * @returns {Observable<any>}
   */
  public createCandidate(uuid: string, candidateName: string): Observable<any> {
    const params = {params: {uuid: uuid, candidateName: candidateName}};
    return this.http.get<any>(this.candidateServiceBaseUrl + '/new', params);
  }

  /**
   * Creates a new candidate.
   *
   * @returns {Observable<any>}
   */
  public createNewCandidate(candidate: Candidate): Observable<any> {
    return this.http.post<any>(this.candidateServiceBaseUrl + '/create', candidate);
  }

  /**
   * Saves the candidate.
   *
   * @param productCandidate Candidate to save.
   * @param doNotShowGrowl Indicate to not include the growl success.
   * @returns {Observable<any>}
   */
  public saveCandidate(productCandidate, doNotShowGrowl?: boolean): Observable<any> {
    this.setRole(productCandidate);

    return this.http.post<any>(this.candidateServiceBaseUrl, productCandidate).pipe(
      tap(candidate => {
        if (!doNotShowGrowl) {
          this.logAndDisplaySuccess('Successfully saved candidate: ' + candidate.displayName);
        }
      }),
      catchError(this.handleAndDisplayError<any>('saving candidate'))
    );
  }
  /**
   * Saves the candidates.
   *
   * @param candidates Candidates to save.
   * @param doNotShowGrowl Indicate to not include the growl success.
   * @returns {Observable<any>}
   */
  public saveAllCandidates(candidates: Candidate[], doNotShowGrowl?: boolean): Observable<any> {
    candidates.forEach(candidate => this.setRole(candidate));

    return this.http.post<any>(this.candidateServiceBaseUrl + '/updateAll', candidates).pipe(
      tap(() => {
        if (!doNotShowGrowl) {
          this.logAndDisplaySuccess('Successfully saved candidate.');
        }
      }),
      catchError(this.handleAndDisplayError<any>('saving candidates'))
    );
  }

  /**
   * Upload file.
   *
   * @param genericFile File to save.
   * @param fileType type of file
   * @param doNotShowGrowl? Indicate to not include the growl success.
   * @returns {Observable<any>}
   */
  public uploadFile(genericFile: File, fileType: string, doNotShowGrowl?: boolean): Observable<String> {
    const file = new FormData();
    file.append('file', genericFile.data);
    return this.http.post(this.uploadImageBaseUrl + '/uploadFile?uuid=' + genericFile.uuid + '&fileType=' + fileType, file,
      {responseType: 'text'}).pipe(
      tap((successUuid) => {
        if (!doNotShowGrowl) {
          this.logAndDisplaySuccess('Successfully saved ' + fileType + ': ' + successUuid);
        }
      }),
      catchError(this.handleAndDisplayError<any>('saving image'))
    );
  }

  /**
   * Upload file.
   *
   * @param genericFile File to save.
   * @param fileType type of file
   * @param doNotShowGrowl? Indicate to not include the growl success.
   * @returns {Observable<any>}
   */
  public uploadImage(genericFile: File, fileType: string, doNotShowGrowl?: boolean): Observable<String> {
    const file = new FormData();
    file.append('file', genericFile.data);
    return this.http.post(this.uploadImageBaseUrl + '/uploadImage?uuid=' + genericFile.uuid + '&fileType=' + fileType, file,
      {responseType: 'text'}).pipe(
      tap((successUuid) => {
        if (!doNotShowGrowl) {
          this.logAndDisplaySuccess('Successfully saved ' + fileType + ': ' + successUuid);
        }
      }),
      catchError(this.handleAndDisplayTextError<any>('saving image'))
    );
  }

  /**
   * Upload Label Insights.
   *
   * @param genericFile File to save.
   * @param fileType type of file
   * @param doNotShowGrowl? Indicate to not include the growl success.
   * @returns {Observable<any>}
   */
  public uploadLabelInsights(genericFile: File, fileType: string, doNotShowGrowl?: boolean): Observable<String> {
    const file = new FormData();
    file.append('file', genericFile.data);
    return this.http.post(this.uploadImageBaseUrl + '/uploadLabelInsights?uuid=' + genericFile.uuid + '&fileType=' + fileType, file,
      {responseType: 'text'}).pipe(
      tap((successUuid) => {
        if (!doNotShowGrowl) {
          this.logAndDisplaySuccess('Successfully saved ' + fileType + ': ' + successUuid);
        }
      }),
      catchError(this.handleAndDisplayTextError<any>('saving image'))
    );
  }

  /**
   * Retrieve attachment file.
   *
   * @param genericUuid uuid to search by.
   * @param doNotShowGrowl? Indicate to not include the growl success.
   * @returns {Observable<Blob>}
   */
  public retrieveAttachment(genericUuid: String, doNotShowGrowl?: boolean): Observable<Blob> {
    return this.http.get(this.uploadImageBaseUrl + '/retrieveFile?uuid=' + genericUuid,
      {responseType: 'blob'}).pipe(
      tap((successUuid) => {
        if (!doNotShowGrowl) {
          this.logAndDisplaySuccess('Successfully saved ' + successUuid);
        }
      }),
      catchError(this.handleAndDisplayError<any>('downloading file'))
    );
  }

  /**
   * Makes a call to the candidate service to validate the candidate can be moved from tbe vendor task and save it.
   *
   * @param productCandidate
   * @returns {Observable<any>}
   */
  public validateVendor(productCandidate): Observable<any> {
    return this.http.post<any>(this.candidateServiceBaseUrl + '/validate/vendor', productCandidate).pipe(
      catchError(this.handleAndDisplayError<any>('saving and moving candidate'))
    );
  }

  /**
   * Makes a call to the candidate service to validate the candidate can be moved from tbe buyer task and save it.
   *
   * @param productCandidate The candidate to validate.
   * @returns {Observable<any>} The saved candidate.
   */
  public validateBuyer(productCandidate): Observable<any> {
    return this.http.post<any>(this.candidateServiceBaseUrl + '/validate/buyer', productCandidate).pipe(
      catchError(this.handleAndDisplayError<any>('saving and moving candidate'))
    );
  }

  /**
   * Makes a call to the candidate service to validate the candidate can be moved from the SCA task and save it.
   *
   * @param productCandidate The candidate to validate.
   * @returns {Observable<any>} The saved candidate.
   */
  public validateSca(productCandidate): Observable<any> {
    return this.http.post<any>(this.candidateServiceBaseUrl + '/validate/sca', productCandidate).pipe(
      catchError(this.handleAndDisplayError<any>('saving and moving candidate'))
    );
  }

  /**
   * Returns a candidate by candidate id.
   *
   * @param {number} candidateId
   * @returns {Observable<any>}
   */
  public getCandidate(candidateId: number): Observable<Candidate> {
    const url = `${this.candidateServiceBaseUrl}/${candidateId}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleAndDisplayError<Candidate>('get candidate'))
    );
  }

  /**
   * Returns a candidate by candidate id.
   *
   * @param  candidateIds
   * @returns {Observable<any>}
   */
  public getCandidatesByCandidateIds(candidateIds: number[]): Observable<Candidate[]> {
    return this.http.post<Candidate[]>(this.findAllCandidatesByCandidateIdsUrl, candidateIds).pipe(
      catchError(this.handleAndDisplayError<Candidate[]>('get candidates by candidate ids'))
    );
  }

  /**
   * Returns a candidate by up cor case upc.
   *
   * @param {number} upc
   * @returns {Observable<any>}
   */
  public getCandidateByUpcOrCaseUpc(upc: number): Observable<any> {
    const url = `${this.candidateServiceBaseUrl}/upc/${upc}`;
    return this.http.get<any>(url);
  }

  /**
   * Returns a candidate by candidate id.
   *
   * @param {number} candidateId
   * @param role role to get
   * @returns {Observable<any>}
   */
  public getLatestCandidate(candidateId: number, role: string): Observable<any> {
    const url = `${this.candidateServiceBaseUrl}/oldCandidate?candidateId=${candidateId}&role=${role}`;
    return this.http.get<any>(url);
  }

  /**
   * Returns candidate by upc.
   *
   * @param {number} upc
   * @returns {Observable<any>}
   */
  public getCandidatesByUpcForMrtValidation(upc: number): Observable<any> {
    return this.http.get<any>(this.candidateServiceBaseUrl + '/upc/candidatesByCandidateTypesAndUpc/' + upc +
      '?candidateTypes=' + Candidate.ADDITIONAL_CASE_PACK + ',' + Candidate.ASSOCIATE_UPC + ',' + Candidate.BONUS_SIZE + ',' +
      Candidate.REPLACEMENT_UPC + ',' + Candidate.NEW_PRODUCT + ',' + Candidate.MRT_INNER).pipe(
      catchError(this.handleAndDisplayError<Candidate>('get New Product Candidate By Upc'))
    );
  }

  /**
   * Activates a candidate.
   * @param candidate to activate.
   */
  public activateCandidate(candidate: Candidate): Observable<any> {
    return this.http.post<any>(this.candidateServiceBaseUrl + '/activateCandidate', candidate).pipe(
      catchError(this.handleAndDisplayError<any>('activating candidate'))
    );
  }

  /**
   * Retrieves an image.
   * @param uuid uuid to retrieve.
   */
  public retrieveCandidateImage(uuid: string): Observable<Blob> {
    return this.http.get(this.uploadImageBaseUrl + '/retrieveFile' + '?uuid=' + uuid,
      {responseType: 'blob'}).pipe(
      catchError(this.handleAndDisplayError<any>('retrieving image'))
    );
  }

  /**
   * Retrieves files.
   * @param uuids uuidss to retrieve.
   */
  public retrieveCandidateImages(uuids: string[]): Observable<ImageFile[]> {
    return this.http.post(this.uploadImageBaseUrl + '/stream/retrieveFiles', uuids).pipe(
      catchError(this.handleAndDisplayError<any>('retrieving image'))
    );
  }

  /**
   * Retrieves candidate service's version.
   *
   * @returns {Observable<any>}
   */
  public getCandidateServiceVersion(): Observable<any> {
    return this.http.get<any>(this.candidateServiceVersionBaseUrl + '/current');
  }
  /**
   * Makes a call to the candidate service to validate the candidate.
   *
   * @param candidate The candidate to validate.
   * @param candidateValidatorTypes the validators to use.
   * @returns {Observable<any>} The saved candidate.
   */
  public validateCandidate(candidate: Candidate, candidateValidatorTypes: CandidateValidatorType[]): Observable<any> {
    this.setRole(candidate);

    return this.http.post<any>(this.candidateServiceBaseUrl + '/validate/candidate?validator=' +
      candidateValidatorTypes.toString(), candidate).pipe(
    );
  }

  /**
   * Makes a call to the candidate service to validate the candidate.
   *
   * @param candidate The candidate to validate.
   * @param candidateValidatorTypes the validators to use.
   * @returns {Observable<any>} The saved candidate.
   */
  public validateBulkCandidate(candidates: Candidate[], candidateValidatorTypes: CandidateValidatorType[]): Observable<any> {
    candidates.forEach(candidate => this.setRole(candidate));

    return this.http.post<any>(this.candidateServiceBaseUrl + '/validate/bulkCandidates?validator=' +
      candidateValidatorTypes.toString(), candidates).pipe(
    );
  }

  /**
   * Find MRT candidates that this candidate is a part of.
   *
   * @param candidateId child to check
   * @param statuses the possible mrt candidate statuses.
   * @returns {Observable<any>}
   */
  public findParentMrtCandidatesForCandidateId(candidateId: number, statuses: string[]): Observable<Candidate[]> {

    let params = new HttpParams();
    params = params.append('candidateId', candidateId.toString());
    params = params.append('statuses', statuses.join(','));
    return this.http.get<Candidate[]>(this.candidateServiceBaseUrl + '/mrtCandidate', { params: params }).pipe(
      catchError(this.handleAndDisplayError<Candidate[]>('get mrt candidate'))
    );
  }

  public getCandidateSearchsFor(candidateIds: number[], getCount: boolean, pageSize: number,
                                pageNumber: number,
                                sortOrder: string, sortField: string,
                                filter?: CandidateSearchFilter[]): Observable<CandidateSearchResult> {
    const candidateSearchSort: CandidateSearchSort = new CandidateSearchSort(sortOrder, sortField);
    const candidateSearchParams: CandidateSearchParams =
      new CandidateSearchParams(candidateIds, pageNumber, pageSize, getCount, candidateSearchSort, filter);

    return this.http.post<any>(this.candidateServiceBaseUrl + '/search', candidateSearchParams);
  }

  public findAllCompletedCandidates(includeCount: boolean, pageSize: number, pageNumber: number, sortOrder: string, sortField: string,
                                    filter?: CandidateSearchFilter[]) {
    const candidateSearchSort: CandidateSearchSort = new CandidateSearchSort(sortOrder, sortField);
    const candidateSearchParams: CandidateSearchParams =
      new CandidateSearchParams(null, pageNumber, pageSize, includeCount, candidateSearchSort, filter);

    return this.http.post<any>(this.candidateServiceBaseUrl + '/findAllCompletedCandidates', candidateSearchParams);
  }

  public findAllRejectedCandidates(includeCount: boolean, pageSize: number, pageNumber: number, sortOrder: string, sortField: string,
                                   filter?: CandidateSearchFilter[]) {
    const candidateSearchSort: CandidateSearchSort = new CandidateSearchSort(sortOrder, sortField);
    const candidateSearchParams: CandidateSearchParams =
      new CandidateSearchParams(null, pageNumber, pageSize, includeCount, candidateSearchSort, filter);

    return this.http.post<any>(this.candidateServiceBaseUrl + '/findAllRejectedCandidates', candidateSearchParams);
  }

  // TODO pagination is not implemented, passing dummy values
  public getCandidateSearchsResultsFor(searchType: string, searchString: string, getCount: boolean, pageSize: number,
                                       pageNumber: number, sortOrder: string, sortField: string): Observable<CandidateSearch[]> {
    const candidateSearchSort: CandidateSearchSort = new CandidateSearchSort(sortOrder, sortField);
    const candidateSearchResultsParams: CandidateSearchResultsParams =
      new CandidateSearchResultsParams(searchType, searchString, pageNumber, pageSize, getCount, candidateSearchSort);

    return this.http.post<any>(this.candidateServiceBaseUrl + '/searchResults', candidateSearchResultsParams);
  }

  /**
   * Update the candidate with a declined status and set the role on the server side.
   * @param candidateId to update
   */
  public deleteCandidateById(candidateId: number): Observable<any> {
    return this.http.delete<any>(this.candidateServiceBaseUrl + '/' + candidateId);
  }

  /**
   * Update the candidate ids with a declined status and set the role on the server side.
   * @param candidateIds to update.
   */
  public deleteCandidatesByIds(candidateIds: number[]): Observable<any> {
    return this.http.request<any>('delete', this.candidateServiceBaseUrl, {body: candidateIds});
  }

  /**
   * Revive mrt by creating a new copy of it.
   *
   * @param candidate of mrt
   * @returns {Observable<any>}
   */
  public reviveMrtCandidateByCandidateId(candidate: Candidate): Observable<any> {
    return this.http.post<any>(this.candidateServiceBaseUrl + '/reviveMrtCandidate', candidate).pipe(
      catchError(this.handleAndDisplayError<Candidate>('get mrt candidate'))
    );
  }

  /**
   * Makes a call to the candidate service for the candidate history.
   *
   * @param candidateId The candidate to use.
   * @returns {Observable<any>} The saved candidate.
   */
  public candidateAudit(candidateId: number): Observable<any> {
    return this.http.get<any>(this.candidateServiceBaseUrl + '/audit/candidate?candidateId=' + candidateId);
  }

  /**
   * Finds current logged in partner's email.
   *
   * @returns {Observable<any>}
   */
  public getCurrentPartnerEmail(): Observable<any> {
    return this.http.get<any>(this.candidateServiceBaseUrl + '/getCurrentPartnerEmail');
  }

  /**
   * Finds dsd candidate ids by upc
   *
   * @param upc the dsd upc.
   * @returns {Observable<any>} The saved candidate.
   */
  public findDistributorCandidatesByUpc(upc: number, candidateStatus?: string): Observable<Candidate[]> {
    let params = new HttpParams();
    if (isNotNullOrUndefined(upc)) {
      params = params.set('upc', String(upc));
    }
    if (isNotNullOrUndefined(candidateStatus)) {
      params = params.set('candidateStatus', candidateStatus);
    }
    return this.http.get<any>(this.findDistributorCandidatesByUpcUrl, { params });
  }

  /**
   * Adds candidate as a invited candidate to the candidate with the supplied candidate id.
   *
   * @param candidate candidate.
   * @param candidateId candidateId.
   */
  public addInvitedCandidateToCandidate(candidate: Candidate, candidateId: number) {
    return this.http.post<any>(this.candidateServiceBaseUrl +
      '/addInvitedCandidateToCandidate?candidateId=' + candidateId, candidate).pipe(
      catchError(this.handleAndDisplayError<any>('saving candidate'))
    );
  }

  /**
   * Updates mrt inners.
   * @param updateMrtInnerRequest
   */
  public updateMrtInners(updateMrtInnerRequest: UpdateMrtInnerRequest): Observable<Candidate> {
    return this.http.post<Candidate>(this.updateMrtInnersUrl, updateMrtInnerRequest).pipe(
      catchError(this.handleAndDisplayError<any>('update mrt inners'))
    );
  }

  /**
   * Finds uploaded candidate errors by the parent candidate id.
   *
   * @param candidateId the parent candidate id.
   * @returns {Observable<CandidateErrorModel[]>} The uploaded candidate errors.
   */
  public findUploadedCandidatesErrorsByParentId(candidateId: number): Observable<CandidateErrorModel[]> {
    return this.http.get<any>(this.findUploadedCandidatesErrorsByParentIdUrl + '?parentCandidateId=' + candidateId);
  }

  /**
   * Finds uploaded candidate errors by the parent candidate id.
   *
   * @param candidateId the parent candidate id.
   * @returns {Observable<CandidateErrorModel[]>} The uploaded candidate errors.
   */
  public findValidationErrorsByParentId(candidateId: number): Observable<CandidateErrorModel[]> {
    return this.http.get<any>(this.findValidationErrorsByParentIdUrl + '?parentCandidateId=' + candidateId);
  }

  /**
   * Exports volume upload error excel by candidate id.
   *
   * @param candidateId the candidate id.
   */
  public exportVolumeUploadErrors(candidateId: number) {
    this.http.get<any>(this.exportBaseUrl + /volumeUploadErrors/ + candidateId, {responseType: 'blob' as 'json'}).subscribe(
      (response) => {
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData,
          {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
        downloadLink.setAttribute('download', 'Volume Upload Errors');
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  /**
   * Exports candidates by candidate ids.
   *
   * @param candidateIds the candidate ids to export.
   */
  public exportCandidates(candidateIds: number[]) {
    return this.http.post<any>(this.exportBaseUrl,  candidateIds, {responseType: 'blob' as 'json'});
  }

  private setRole(candidate: Candidate) {
    if (this.auth.isVendor()) {
      candidate.role = UserRoleConstants.VENDOR_ROLE;
    } else if (this.auth.isBuyer()) {
      candidate.role = UserRoleConstants.BUYER_ROLE;
    } else if (this.auth.isSca()) {
      candidate.role = UserRoleConstants.SUPPLY_CHAIN_ANALYST_ROLE;
    } else if (this.auth.isPia()) {
      candidate.role = UserRoleConstants.PROCUREMENT_SUPPORT_ROLE;
    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleAndDisplayError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 400 || error.status === 500) {
          let message = `Error in ${operation}: ${error.error.message}`;

          if (error.error.candidateErrors && error.error.candidateErrors.errors) {
            message += ' ' + error.error.validationErrors.join(' ');
          }
          return throwError(message);
        }
      }

      let errorMessage: string;
      if (error.error && error.error.message) {
        errorMessage = `Error in ${operation}: ${error.error.message}`;
      } else {
        errorMessage = `Error in ${operation}: ${error.message}`;
      }
      this.growlService.addError(errorMessage);
      return throwError(error);
    };
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleAndDisplayTextError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 400 || error.status === 500) {
          const errorJson = JSON.parse(error.error);
          return throwError(errorJson);
        }
      }

      return throwError(error);
    };
  }

  /** Log a CandidateService message with the GrowlService */
  private logAndDisplaySuccess(message: string) {
    this.growlService.addSuccess(message);
  }

  /**
   * Uploads a file for candidate upload.
   * @param genericFile
   * @param doNotShowGrowl
   */
  public uploadCandidates(genericFile: File, doNotShowGrowl?: boolean) {
    const uploadedFile = new FormData();
    uploadedFile.append('uploadedFile', genericFile.data);
    return this.http.post(this.uploadImageBaseUrl + '/uploadCandidates', uploadedFile);
  }
}
