import { Component, Input, Inject, forwardRef, ViewEncapsulation } from '@angular/core';

import { MenuComponent } from './menu.component';
import { PMMenuItem } from '../../common/menu-item.interface';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[pmMenuItemContent]',
  templateUrl: './menu-item.component.html',
  encapsulation: ViewEncapsulation.None,

})
export class MenuItemComponent {

  // tslint:disable-next-line:no-input-rename
  @Input('pmMenuItemContent') item: PMMenuItem;

  menu: MenuComponent;

  constructor(@Inject(forwardRef(() => MenuComponent)) menu) {
    this.menu = menu as MenuComponent;
  }
}
