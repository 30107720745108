import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DisabledPermissionsMapper, ReadOnlyPermissionsMapper } from '../attribute-permissions-mapper';

import {
  Candidate,
  AttributeConfig,
  AttachmentsCardModel,
  AttachmentsCardErrors,
  AttachmentsCardPermissions,
  CandidateError,
  AttributeTextInputConfig,
  TextInputType
} from 'pm-models';

@Component({
  selector: 'pm-attachments-card',
  templateUrl: './attachments-card.component.html',
  styleUrls: ['./attachments-card.component.scss']
})
export class AttachmentsCardComponent implements OnInit {
  @Input()
  model: Candidate | AttachmentsCardModel;

  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: AttachmentsCardPermissions;

  @Input()
  commentsPlaceholder: string;

  @Input()
  errorModel: AttachmentsCardErrors | CandidateError;

  constructor() { }
  attributeUploadConfiguration: AttributeConfig = {
    label: '',
    description: '',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.attachments),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.attachments),
    isRequired: false
  };

  supplierCommentConfiguration: AttributeTextInputConfig = {
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.supplierComment),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.supplierComment),
    isRequired: false,
    textInputType: TextInputType.textarea,
    name: 'supplierCommentId',
    charLimit: 4000
  };

  ngOnInit() {
    if (this.commentsPlaceholder) {
      this.supplierCommentConfiguration.placeholderText = this.commentsPlaceholder;
    }
  }

  attachmentsChange(e) {
    this.modelChange.emit(this.model);
  }

  supplierCommentChange(e) {
    this.modelChange.emit(this.model);
  }
}
