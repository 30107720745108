<div class="pm-editor-body">
  <div class="container">

    <div class="row">
      <div class="pm-editor-header px-3 py-4 col">
        <div class="row">
          <div class="col-1">
            <pm-close (click)="closeEvent.emit()"></pm-close>
          </div>
          <div class="col">
            <ng-content select="pm-header"></ng-content>
          </div>
        </div>
      </div>
    </div>
    <header class="row pt-5" *ngIf="headerFacet">
      <div class="col offset-2 offset-md-3">
        <h1 class="editor-title">
          <ng-content select="pm-title"></ng-content>
        </h1>
        <h2 class="editor-sub-title">
          <ng-content select="pm-sub-title"></ng-content>
        </h2>
      </div>
    </header>

    <div class="row justify-content-md-center">
     <div class="col col-md-10">
        <ng-content></ng-content>
      </div>
    </div>
    <footer class="row my-4 editor-footer" *ngIf="footerFacet">
      <div class="col-6 py-3 offset-3 text-right">
        <ng-content select="pm-footer"></ng-content>
      </div>
    </footer>
  </div>

</div>
