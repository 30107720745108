import {DsdItem, Item, ProductMatHierarchy} from 'pm-models';
import {Brand} from 'pm-models/lib/brand';

export class Product {
  public static readonly SELLABLE_PRODUCT_TYPE = 'GOODS';

  productId?: number;
  productDescription?: string;
  primaryScanCodeId?: number;
  items?: Item[];
  dsdItems?: DsdItem[];
  customerFriendlyDescriptionOne?: String;
  customerFriendlyDescriptionTwo?: String;
  packageDescription?: String;
  signRomanceCopy?: String;
  flexibleSpendingAccount?: boolean;
  taxable?: boolean;
  foodStamp?: boolean;
  brand?: Brand;
  costOwner?: any;
  prePrice?: boolean;
  productTypeId?: string;
  priceRequired?: boolean;
  matHierarchy?: ProductMatHierarchy;
}
