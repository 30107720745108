import {BaseAttribute} from './base-attribute';
import {AttributeUPCConfig, Candidate, CandidateProductError, CandidateValidatorType} from 'pm-models';
/* tslint:disable:max-line-length */
import {
  AttributeDisplayUpcComponent,
  InputState
} from '../../shared/attribute-display/attribute-display-upc/attribute-display-upc.component';
import {AttributeDisplayAssociateUpcComponent} from '../../shared/attribute-display/attribute-display-associate-upc/attribute-display-associate-upc.component';

export class AssociateUpc implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayAssociateUpcComponent;
  }

  getAttributeConfig(overrides?): AttributeUPCConfig {
    return {
      label: 'Unit UPC',
      description: `Enter the product's UPC or another unique product identification code`,
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: '',
      placeholderText: `Enter UPC`,
      checkDigitPlaceholderText: `Check #`,
      productIndex: 1,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayAssociateUpcComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    this.setupComponentCandidate(componentInstance, candidate, configOverrides);
  }

  setupComponentCandidate(componentInstance: AttributeDisplayAssociateUpcComponent, candidate: Candidate,
                          configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.candidateProducts;
    componentInstance.checkDigitModelChange.subscribe(x => {
      candidate.candidateProducts[x.id] = x;
    });

    componentInstance.modelChange.subscribe(x => {
      candidate.candidateProducts[x.id] = x;
    });
    componentInstance.validateAllUpcs.subscribe(s => {
      this.validateAll(componentInstance, candidate, configOverrides);
    });

    componentInstance.validateUpc.subscribe(s => {
      const productIndex = s.id;
      const tempCandidate = JSON.parse(JSON.stringify(candidate));
      let tempCandidateProducts = JSON.parse(JSON.stringify(candidate.candidateProducts));
      tempCandidateProducts = tempCandidateProducts.slice(0, 1);
      tempCandidateProducts.push(JSON.parse(JSON.stringify(s)));
      tempCandidate.candidateProducts = tempCandidateProducts;
      if (configOverrides.validationService) {
        componentInstance.state = InputState.loading;
        configOverrides.validationService.validateCandidate(tempCandidate, [CandidateValidatorType.UPC_VALIDATOR]).subscribe(
          () => {
            componentInstance.state = InputState.valid;
            componentInstance.attributeError[productIndex] = undefined;
            this.validateAll(componentInstance, candidate, configOverrides);
          },
          error => {
            componentInstance.state =  InputState.invalid;
            // if there's an error, and it's an instance of candidate error model, update the candidate product's
            // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
            // add the whole candidate product error model.
            if (error.error.candidateErrors) {
              configOverrides.saveDisabled = true;
              if (!componentInstance.attributeError) {
                componentInstance.attributeError = new CandidateProductError();
              }
              const returnedCandidateProductError = error.error.candidateErrors.candidateProductErrors[productIndex];
              if (returnedCandidateProductError) {
                componentInstance.attributeError[productIndex] = returnedCandidateProductError;
              }
            }
          }
        );
      }
    });
  }

  validateAll(componentInstance: AttributeDisplayAssociateUpcComponent, candidate: Candidate, configOverrides: any = {}) {
    if (configOverrides.validationService) {
      componentInstance.state = InputState.loading;
      configOverrides.validationService.validateCandidate(candidate, [CandidateValidatorType.UPC_VALIDATOR,
        CandidateValidatorType.ASSOCIATE_FINAL_REVIEW_VALIDATOR]).subscribe(
        () => {
          configOverrides.saveDisabled = false;
          componentInstance.state = InputState.valid;
          componentInstance.attributeError = new CandidateProductError();
        },
        error => {
          componentInstance.state =  InputState.invalid;
          // if there's an error, and it's an instance of candidate error model, update the candidate product's
          // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
          // add the whole candidate product error model.
          if (error.error.candidateErrors) {
            configOverrides.saveDisabled = true;
            if (!componentInstance.attributeError) {
              componentInstance.attributeError = new CandidateProductError();
            }
            componentInstance.attributeError = error.error.candidateErrors.candidateProductErrors;
          }
        }
      );
    }
  }

  updateModel(componentInstance: AttributeDisplayUpcComponent, candidate: Candidate) {
    this.updateModelCandidate(componentInstance, candidate, componentInstance.attribute.productIndex);
  }

  updateModelCandidate(componentInstance: AttributeDisplayUpcComponent, candidate: Candidate, index: number) {
    if (componentInstance.model !== candidate.candidateProducts[index].upc) {
      componentInstance.model = candidate.candidateProducts[index].upc;
    }

    if (componentInstance.checkDigitModel !== candidate.candidateProducts[index].upcCheckDigit) {
      componentInstance.checkDigitModel = candidate.candidateProducts[index].upcCheckDigit;
    }
  }
}
