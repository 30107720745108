import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {
  RequestNewMatAttributeFormComponent
} from 'pm-components/lib/shared/request-new-mat-attribute-form/request-new-mat-attribute-form.component';


@NgModule({
  declarations: [RequestNewMatAttributeFormComponent],
  exports: [RequestNewMatAttributeFormComponent, AttributeDisplayModule],
  imports: [
    CommonModule, AttributeDisplayModule
  ]
})
export class RequestNewMatAttributeFormModule { }
