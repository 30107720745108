import {AttributeTextInputConfig, ScaleInformation} from 'pm-models';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayTextinputComponent} from '../../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';
import {AttributeDisplayUpcComponent} from '../../../shared/attribute-display/attribute-display-upc/attribute-display-upc.component';
import {BaseAttribute} from '../base-attribute';

export class EnglishLabelOneBilingual implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'English label one',
      description: `Enter a description for the first line of the scale sticker.`,
      placeholderText: 'English label description line 1...',
      isDisabled: () => false,
      isReadOnly: () => false,
      isHidden: () => false,
      isRequired: true,
      name: 'englishLabelDescriptionOne',
      charLimit: 32
    };
  }

  setupComponent(componentInstance: AttributeDisplayUpcComponent, scaleInformation: ScaleInformation, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = scaleInformation.englishLabelOneBilingual;
    componentInstance.modelChange.subscribe(x => {
      scaleInformation.englishLabelOneBilingual = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayUpcComponent, scaleInformation: ScaleInformation) {
    if (componentInstance.model !== scaleInformation.englishLabelOneBilingual) {
      componentInstance.model = scaleInformation.englishLabelOneBilingual;
    }
  }
}
