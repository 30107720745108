import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'pm-decimal-input',
  templateUrl: './decimal-input.component.html',
  styleUrls: ['./decimal-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecimalInputComponent {
  @Input() model;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() error: boolean;
  @Input() inputClass;
  @Input() inputStyle;
  @Input() numOfIntegers;
  @Input() numOfDecimals;
  @Input() inputId;
  @Input() inputName;
  @Input() inputDisabled;
  @Input() inputReadOnly;
  @Input() inputTabIndex;
  @Input() placeholderText;

  @Output() inputFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputBlur?: EventEmitter<any> = new EventEmitter<any>();


  constructor(private currencyPipe: CurrencyPipe) {}

  /**
   * Format the display automatically append missing decimals if need.
   * Note: The min number of decimals is 2.
   */
  formatModel(event) {
    if (this.model && this.numOfDecimals >= 2) {
      return this.currencyPipe.transform(event, '', '', `1.${this.numOfDecimals}-${this.numOfDecimals}`).replace(/,\s?/g, '');
    } else {
      return event;
    }
  }

  updateModel(event) {
    const parsed = parseFloat(event);
    this.model = !isNaN(parsed) ? parsed : event;
    this.modelChange.emit(this.model);
  }

  /**
   * Call function to format input and emit the changes to the parent component.
   */
  onBlur(event) {
    this.inputBlur.emit(event);
  }
  onFocus(event) {
    this.inputFocus.emit(event);
  }
}
