import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OwnBrandService} from '../../service/ownbrand.service';
import {LookupService} from '../../service/lookup.service';
import {Observable} from 'rxjs';
import {concatMap, map, tap} from 'rxjs/operators';
import {Buyer} from '../../../../../projects/pm-components/src/lib/attributes/attribute-type';
import {CandidateSearchFilter, CandidateSearchFilter_Column} from 'pm-models/lib/candidateSearchFilter';
import {CandidateSearchParams} from 'pm-models/lib/candidateSearchParams';
import {Vendor} from 'pm-models';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-reserved-upcs',
  templateUrl: './reserved-upcs.component.html',
  styleUrls: ['./reserved-upcs.component.scss']
})
export class ReservedUpcsComponent implements OnInit {

  reservedUpcsHeaderColumns = [
    {field: 'unitUpc', header: 'Unit UPC', sortable: false, width: '15%'},
    {field: 'name', header: 'Name', sortable: false, width: '30%'},
    {field: 'ap', header: 'AP', sortable: false, width: '30%'},
    {field: 'buyer', header: 'Buyer', sortable: false, width: '15%'},
    {field: 'reservedUntil', header: 'Reserved Until', sortable: false, width: '10%'}
  ];

  buyers: Buyer[] = [];
  buyerFilters: string[] = [];
  suppliers: Vendor[] = [];
  supplierFilters: string[] = [];
  currentBuyerFilter: CandidateSearchFilter = new CandidateSearchFilter(CandidateSearchFilter_Column.BUYER_ID, []);
  currentSupplierFilter: CandidateSearchFilter = new CandidateSearchFilter(CandidateSearchFilter_Column.VENDOR_AP_NUMBER, []);

  upcReservations: any[];
  readonly PAGE_SIZE = 20;
  recordCount = 0;
  currentPageNumber: number = 0;
  showSpinner: boolean = true;
  maxNumRecords: number = 999;
  showFilters = false;

  constructor(public router: Router, public ownBrandService: OwnBrandService, public lookupService: LookupService,
              public authService: AuthService) { }

  ngOnInit() {
    // find all buyers, set is followed to false, and assign.
    this.lookupService.findBuyers(this.maxNumRecords).pipe(
      // Set followed buyers to false, and assign
      map((buyers: any[]) => {
        buyers.forEach((buyer) => {
          buyer.isFollowed = false;
        });
        this.buyers = buyers;
      }),
      // fetch vendors, set is followed to false, and assign.
      concatMap(() => this.ownBrandService.findAllOwnBrandUpcReservationVendors()),
      map((vendors) => {
        vendors.forEach((supplier) => {
          supplier.isFollowed = false;
        });
        this.suppliers = vendors;
      }),
      // fetch the page.
      concatMap(() => this.fetchPage([this.currentBuyerFilter, this.currentSupplierFilter], true))
    ).subscribe();

    this.showFilters = !this.authService.isVendor();
  }

  onClickReserveUpc() {
    this.router.navigate(['/requestHebUpc']).then();
  }

  onLoadReservations(event) {
    const pageNumber = this.getPageNumber(event.first, this.PAGE_SIZE);
    if (this.currentPageNumber === pageNumber) {
      return;
    } else {
      this.currentPageNumber = pageNumber;
    }

    this.fetchPage([this.currentBuyerFilter, this.currentSupplierFilter], false).subscribe();
  }

  fetchPage(filters: any[], includeCount): Observable<any> {
    this.showSpinner = true;
    const candidateSearchParams: CandidateSearchParams = {
      pageNumber: this.currentPageNumber, pageSize: this.PAGE_SIZE, needsCount: includeCount, filter: filters };
    return this.ownBrandService.findAllOwnBrandUpcs(candidateSearchParams).pipe(
      tap(
        (results) => {
          if (includeCount) {
            this.recordCount = results.recordCount;
          }
          this.upcReservations = results.data;
          this.showSpinner = false;
        }
      )
    );
  }

  getPageNumber(first: number, pageSize: number) {
    return first / pageSize;
  }

  /**
   * Returns date string offset from GMT.
   * @param dateNumber
   */
  getDateString(dateNumber) {
    if (!dateNumber) {
      return '';
    } else {
      const date = new Date(dateNumber);

      // offset GMT time
      date.setHours(date.getHours() + 5);

      return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
        '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +
        '/' + date.getFullYear();

    }
  }

  onApplyBuyerFilter(event: any) {
    if (event.map) {
      this.currentBuyerFilter.values = event.map(buyer => buyer.buyerId);
    } else {
      this.currentBuyerFilter.values = [];
    }

    this.fetchPage([this.currentBuyerFilter, this.currentSupplierFilter], true).subscribe();
  }

  onApplySupplierFilter(supplierAps: any) {
    this.currentSupplierFilter.values = supplierAps ? supplierAps : [];
    this.fetchPage([this.currentBuyerFilter, this.currentSupplierFilter], true).subscribe();
  }

  onClickRow(event) {
    this.router.navigate(['/reservedUpcReview'], {queryParams: {upc: event.data.upc}});
  }
}
