import {NgModule} from '@angular/core';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {CategorySelectionComponent} from './category-selection.component';

@NgModule({
  declarations: [CategorySelectionComponent],
  exports: [CategorySelectionComponent, AutoCompleteModule, FormsModule],
  entryComponents: [],
  imports: [
    CommonModule,
    HttpClientModule,
    AutoCompleteModule,
    FormsModule,
    ButtonModule
  ]
})
export class CategorySelectionModule { }
