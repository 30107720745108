<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">
  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label" [ngClass]="attribute.labelInputGroupClass" *ngIf="attribute.label">
      <label for="">{{attribute.label}}</label>
    </div>
    <div class="attribute-control">

      <ng-template [ngIf]="!isReadOnly()" [ngIfElse]="readOnly">
        <p-dropdown [options]="attribute.collections" [id]="attribute.name" [(ngModel)]="model"
                    placeholder="{{attribute.placeholderText}}"
                    optionLabel="{{attribute.displayRef}}" [showClear]="attribute.showClear"
                    (onChange)="handleChange($event)"
                    [disabled]="isDisabled()"
                    [ngClass]="{'ui-inputtext-invalid' : attributeError}"
                    [class]="attribute.inputGroupClass">
        </p-dropdown>
      </ng-template>


      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="attributeError" class="attribute-error-message">
        {{attributeError}}
      </p>
    </div>
  </div>
</ng-container>
<ng-template #readOnly>
  <div class="ui-autocomplete-readonly ui-corner-all">
    <div *ngIf="model">
      {{model[attribute.displayRef]}}
    </div>
  </div>
</ng-template>
