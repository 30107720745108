import {Attribute} from 'pm-models';

export class MatHierarchy {

  matHierarchyId: number;

  name: string;

  description?: string;

  parentMatHierarchyId?: number;

  hierarchyLevel: number;

  lowestLevel?: boolean;

  parentMatHierarchy?: MatHierarchy;

  parentHierarchyIds?: number[];

  parentHierarchyDisplayName?: string;

  attributes?: Attribute[];
}
