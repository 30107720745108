import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'pm-selection-filter',
  templateUrl: './selection-filter.component.html',
  styleUrls: ['./selection-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectionFilterComponent implements OnInit {

  @Input() label;
  @Input() items: SelectItem[];

  @Output() clearFilters: EventEmitter<any> = new EventEmitter();
  @Output() applyFilters: EventEmitter<any> = new EventEmitter();

  model;

  clearStatusFilter(multiSelect, event) {
    this.model = [];
    multiSelect.close(event);
    this.clearFilters.emit(this.model);
  }

  applyStatusFilter(multiSelect, event) {
    multiSelect.close(event);
    this.applyFilters.emit(this.model);
  }

  constructor() { }

  ngOnInit() {
  }

}
