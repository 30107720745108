<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-4">
    <!-- -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Pharmacy Details" subtitle="Fill out the pharmacy attributes for this product.">
    <ng-container *ngIf="model">
      <pm-attribute-display-text-input [attribute]="nationalDrugCodeIdConfiguration" [(model)]="model.nationalDrugCodeId"
                                       [attributeError]="errorModel?.nationalDrugCodeId" (modelChange)="nationalDrugCodeIdChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-typeahead [attribute]="drugNameAbbreviationConfiguration" [(model)]="model.drugNameAbbreviation"
                                      [attributeError]="errorModel?.drugNameAbbreviation" (selection)="drugNameAbbreviationChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead [attribute]="drugScheduleTypeCodeConfiguration" [(model)]="model.drugScheduleTypeCode"
                                      [attributeError]="errorModel?.drugScheduleTypeCode" (selection)="drugScheduleTypeCodeChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead [attribute]="pseTypeConfiguration" [(model)]="model.pseType"
                                      [attributeError]="errorModel?.pseType" (selection)="pseChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-text-input [attribute]="pseGramsConfiguration" [(model)]="model.pseGrams"
                                       [attributeError]="errorModel?.pseGrams" (modelChange)="pseGramsChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="averageWholesalePharmacyCostConfiguration" [(model)]="model.averageWholesalePharmacyCost"
                                       [attributeError]="errorModel?.averageWholesalePharmacyCost" (modelChange)="averageWholesalePharmacyCostChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="directPharmacyCostConfiguration" [(model)]="model.directPharmacyCost"
                                       [attributeError]="errorModel?.directPharmacyCost" (modelChange)="directPharmacyCostChange($event)">
      </pm-attribute-display-text-input>

    </ng-container>

  </pm-card>
</ng-template>
