import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate, AttributeConfig } from 'pm-models';

export class ShowCalories implements BaseAttribute {

  warningText = 'This edit will remove the nutrition panel requested from Genesis or OBPS.' +
    ' This doesn\'t reject the candidate, but it may no longer require your review.';
  private defaultVal = false;

  getComponentType() {
    return AttributeDisplayToggleComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Show Calories',
      isRequired: false,
      isReadOnly: () => false,
      description: `If checked, we'll pull nutritional information for the Own Brand Regulatory team to review.
      You'll be able to activate this candidate after they approve the nutrition panel.`,
      defaultValue: false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.warningMessageText = this.warningText;
    const initialValue = candidate.showCalories;
    if (candidate.showCalories === undefined) {
      candidate.showCalories = this.defaultVal;
    }
    componentInstance.model = candidate.showCalories;
    componentInstance.change.subscribe(x => {
      candidate.showCalories = x.checked;
      if (initialValue && !candidate.showCalories) {
        componentInstance.showWarningMessage = !configOverrides.showWarningMessage ? false : true;
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.showCalories) {
      componentInstance.model = candidate.showCalories;
    }  }

}
