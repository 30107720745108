import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {File, FileHandle} from 'pm-models';

import {Observable} from 'rxjs';
import {UUID} from 'angular2-uuid';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';

@Component({
  selector: 'pm-upload-candidate',
  templateUrl: './upload-candidate.component.html',
  styleUrls: ['./upload-candidate.component.scss']
})
export class UploadCandidateComponent implements OnInit {


  @Input() attachment: File;

  @Output() updateCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() backToHomeClick: EventEmitter<any> = new EventEmitter<any>();

  public fileTypes = ['.xls', '.xlsx', '.csv'];
  private tsFileTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];


  uploadedFile: File;
  failedUploads: string[] = [];
  isInProgress: boolean;
  fileErrorMap: Map<string, string[]> = new Map<string, string[]>();
  error: boolean;
  errorMessage: string;

  constructor(private candidateService: CandidateService, private growlService: GrowlService) {}

  ngOnInit() {}

  /**
   * Notify parent of a change to the data.
   */
  updateParentAndSave() {
    this.updateCallback.emit(this.attachment);
    this.disableAndResetDisplay();
  }

  /**
   * Disables and resets all the values for this display.
   */
  disableAndResetDisplay() {
    this.uploadedFile = null;
    this.isInProgress = false;
    this.fileErrorMap.clear();
  }

  onFilesAdded(files) {
    const filesToSend = [];
    const filesToDiscard = [];
    this.clearOldErrors();
    for (const file of files) {
      if (this.checkForErrors({ file: file })) {
        filesToDiscard.push(file);
        this.error = true;
      } else {
        filesToSend.push({ file: file });
      }
    }
    if (filesToSend.length > 0) {
      this.uploadAttachment(filesToSend[0]);
    }
  }

  filesDropped(files: FileHandle[]): void {
    const filesToSend = [];
    const filesToDiscard = [];
    this.clearOldErrors();
    for (const file of files) {
      if (this.checkForErrors(file)) {
        filesToDiscard.push(file);
        this.error = true;
      } else {
        filesToSend.push(file);
      }
    }
    if (filesToSend.length > 0) {
      this.uploadAttachment(filesToSend[0]);
    }
  }

  uploadAttachment(fileInput) {
    this.clearError();
    this.isInProgress = true;
    const file = fileInput.file;
    const fileReader = new FileReader();
    const fileReader$ = new Observable<File>(o => {
      fileReader.onloadend = e => {
        const attachment = new File();
        attachment.data = file;
        attachment.uuid = UUID.UUID();
        attachment.name = file.name;
        attachment.type = file.type;
        attachment.size = file.size;
        this.uploadedFile = attachment;
        o.next(attachment);
        o.complete();
      };
    });
    fileReader.readAsDataURL(fileInput.file);

    fileReader$.subscribe(attachment => {
        this.attachment = this.uploadedFile;
        this.candidateService.uploadCandidates(attachment).subscribe(() => {
          this.isInProgress = false;
          this.updateParentAndSave();
        }, (error) => {
          this.isInProgress = false;
          this.attachment = null;
          const errorMessage = error.error && error.error.message ? error.error.message : error.message;
          this.growlService.addError('Error uploading file: ' + errorMessage);
          this.disableAndResetDisplay();
        });

      }, error => {
        this.isInProgress = false;
      }
    );
  }

  clearOldErrors() {
    this.growlService.clearMessages(); // clear out any growl message(s)
    this.error = false;
    this.fileErrorMap.clear();
    this.fileErrorMap.set('fileTypeError', []);
    this.fileErrorMap.set('fileSizeError', []);
    this.fileErrorMap.set('serverError', []);
  }

  private checkForErrors(file) {
    this.clearOldErrors();
    let hasError = false;

    if (this.tsFileTypes.includes(file.file.type)) {
      // do nothing
    } else {
      hasError = true;
      this.fileErrorMap.get('fileTypeError').push(file.file.name);
    }
    return hasError;
  }

  fileErrors() {
    let errorsToReturn = '';

    if (this.fileErrorMap.get('fileTypeError').length === 1) {
      errorsToReturn = errorsToReturn
        .concat('We couldn’t upload ' + this.fileErrorMap.get('fileTypeError').toString().bold() +
          ' because it is the wrong format. Please upload one of the following file formats - ' + this.getFileTypeString() + '.');
    }

    return errorsToReturn;
  }

  clearError() {
    this.errorMessage = '';
    this.error = false;
  }

  getFileTypeString() {
    return this.fileTypes.join(', ');
  }

  onClickBackToHome() {
    this.backToHomeClick.emit();
  }
}
