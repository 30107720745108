import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilteringService {
  selectedDateFilter: Subject<number> = new Subject<number>();

  MILLISECONDS_IN_A_DAY = 86400000;
  filterBy: number;
  filteredData: any[];

  dateFilterOptions: SelectItem[] = [
    {label: 'Today', value: 1},
    {label: 'Last 7 Days', value: 7},
    {label: 'Last 30 Days', value: 30},
    {label: 'Last 60 Days', value: 60}
  ];

  constructor() {
    this.selectedDateFilter
      .subscribe((value) => {
        this.filterBy = value;
      });
   }


  /**
   * This filters the data by number of days.
   * The selectedDateFilter is updated by the datefilter component,which gets placed into the filterBy variable and then that
   * is used to filter the data.
   * @param data The data to run the date filter against
   * @returns Data filtered by number of days in the lastUpdatedTime value.
   */
  datefilter(data) {
    this.filteredData = data.filter((i) => {
      const currentDate = new Date();
      const lastUpdated = i.candidate.lastUpdatedTime;
      const timeDifference = Math.abs(lastUpdated - currentDate.getTime());
      const timeInDays = timeDifference / this.MILLISECONDS_IN_A_DAY;
      return timeInDays <= this.filterBy;
    });
    return this.filteredData;
  }

}
