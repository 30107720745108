<div #container
  [ngClass]="{'ui-menu ui-widget ui-widget-content ui-corner-all': true, 'pm-ui-menu': !popup,  'ui-menu-dynamic ui-shadow': popup}"
  [class]="styleClass" [ngStyle]="style" (click)="preventDocumentDefault=true" *ngIf="!popup || visible"
  [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
  [@.disabled]="popup !== true" (@overlayAnimation.start)="onOverlayAnimationStart($event)">
  <ul>
    <ng-template ngFor let-submenu [ngForOf]="model" *ngIf="hasSubMenu()">
      <li class="ui-menu-separator ui-widget-content" *ngIf="submenu.separator"
        [ngClass]="{'ui-helper-hidden': submenu.visible === false}"></li>
      <li class="ui-submenu-header ui-widget-header ui-corner-all" [attr.data-automationid]="submenu.automationId"
        *ngIf="!submenu.separator" [ngClass]="{'ui-helper-hidden': submenu.visible === false}">{{submenu.label}}</li>
      <ng-template ngFor let-item [ngForOf]="submenu.items">
        <li class="ui-menu-separator ui-widget-content" *ngIf="item.separator"
          [ngClass]="{'ui-helper-hidden': (item.visible === false || submenu.visible === false)}"></li>
        <li class="ui-menuitem" *ngIf="!item.separator" [pmMenuItemContent]="item"
          [ngClass]="{'ui-helper-hidden': (item.visible === false || submenu.visible === false)}" [ngStyle]="item.style"
          [class]="item.styleClass"></li>
      </ng-template>
    </ng-template>
    <ng-template ngFor let-item [ngForOf]="model" *ngIf="!hasSubMenu()">
      <li class="ui-menu-separator ui-widget-content" *ngIf="item.separator"
        [ngClass]="{'ui-helper-hidden': item.visible === false}"></li>
      <li class="ui-menuitem" *ngIf="!item.separator" [pmMenuItemContent]="item"
        [ngClass]="{'ui-helper-hidden': item.visible === false}" [ngStyle]="item.style" [class]="item.styleClass"></li>
    </ng-template>
  </ul>
</div>
