import {Pipe, PipeTransform} from '@angular/core';
import {Candidate} from 'pm-models';

@Pipe({name: 'codeDateDays'})
export class CodeDateDaysPipe implements PipeTransform {
  transform(candidate: Candidate, days: number): string {
    if (candidate?.codeDate) {
      if (days) {
        return '' + days + ' Days';
      }
      return '0 Days';
    } else {
      return '';
    }
  }
}
