import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AttributeConfig, AttributeTextInputConfig, AttributeTypeaheadConfig, TextInputType} from 'pm-models';
import {UserPreferences} from 'pm-models/lib/userPreferences';
import {PreferencesService} from '../service/preferences.service';

@Component({
  selector: 'app-buyer-notifications',
  templateUrl: './buyer-notifications.component.html',
  styleUrls: ['./buyer-notifications.component.scss']
})
export class BuyerNotificationsComponent implements OnInit {
  public isViewingPage = true;
  public buyerModel: any;
  public emailFrequencyModel: string;
  public emailToggleModel: boolean = false;

  // If any changes were made to the component
  public changes: boolean;
  buyerConfiguration: AttributeTypeaheadConfig = {
    label: 'Buyer',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    description: 'Choose the H-E-B buyer(s) you work with. ' + `You'll receive notifications when they receive new work.`,
    name: 'Buyer',
    idRef: 'buyerId',
    displayRef: 'displayName',
    displaySubRef: '',
    searchUrl: '/lookup/buyer',
    placeholderText: 'Type to search for a Buyer...',
    showAddlInfo: true,
    allowMultiple: true
  };
  emailFrequencyConfig: AttributeTextInputConfig = {
    label: 'Frequency',
    description: `When there's new work, we'll send you one summary at the end of the day.`,
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `Never`,
    textInputType: TextInputType.text,
    inputGroupClass: 'email-frequency-text-input'
  };
  emailToggleConfiguration: AttributeConfig = {
    label: 'Emails',
    description: 'Opt-in to receiving notifications via email.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    defaultValue: false
  };
  private userPreferences: UserPreferences;

  constructor(private preferencesService: PreferencesService, private router: Router) {
  }

  ngOnInit() {
    this.preferencesService.getPreferences().subscribe(prefs => {
      this.userPreferences = prefs;
      this.setEmailPreferences(this.userPreferences.sendEmails);
      this.setDesksUI();
    });
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']);
  }

  buyerChange(event) {
    this.changes = true;
    this.buyerModel = event;
  }

  emailToggleChange(event) {
    this.changes = true;
    this.setEmailPreferences(event.checked);
  }

  setEmailPreferences(enabled) {
    if (enabled) {
      this.emailFrequencyConfig.placeholderText = `Once a day`;
      this.emailToggleModel = true;
    } else {
      this.emailFrequencyConfig.placeholderText = `Never`;
      this.emailToggleModel = false;
    }
  }

  setDesksUI() {
    if (this.userPreferences.followingDesks) {
      this.buyerModel = this.userPreferences.followingDesks.map(x => {
        return {buyerId: x};
      });
    } else {
      this.buyerModel = [];
    }
  }

  setDesksDB() {
    this.userPreferences.followingDesks = this.buyerModel.map( y => {
      return y.buyerId;
    });
  }

  cancel() {
    this.setEmailPreferences(this.userPreferences.sendEmails);
    this.setDesksUI();
    this.changes = false;
    this.onClose();
  }

  save() {
    this.changes = false;
    this.userPreferences.sendEmails = this.emailToggleModel;
    this.userPreferences.hasSetupPreferences = true;
    this.setDesksDB();
    this.preferencesService.updatePreferences(this.userPreferences).subscribe(prefs => {
      this.userPreferences = prefs;
      this.setEmailPreferences(prefs.sendEmails);
      this.onClose();
    });
  }
}
