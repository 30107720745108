import {AttributeTextInputConfig, CandidateProduct, ScaleInformation, TextInputType} from 'pm-models';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayTextinputComponent} from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';
import {AttributeDisplayUpcComponent} from '../../shared/attribute-display/attribute-display-upc/attribute-display-upc.component';
import {BaseAttribute} from './base-attribute';

export class RemarkOne implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Remark 1 for OMI',
      charLimit: 72,
      description: `Provide comments for internal use.`,
      isDisabled: () => false,
      isReadOnly: () => false,
      inputGroupClass: 'text-area-resize-disabled',
      textInputType: TextInputType.textarea
    };
  }

  setupComponent(componentInstance: AttributeDisplayUpcComponent, candidateProduct: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidateProduct.remark1;
    componentInstance.modelChange.subscribe(x => {
      candidateProduct.remark1 = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayUpcComponent, candidateProduct: CandidateProduct) {
    if (componentInstance.model !== candidateProduct.remark1) {
      componentInstance.model = candidateProduct.remark1;
    }
  }
}
