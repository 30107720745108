import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BaseUpcInputComponent} from '../base-upc-input/base-upc-input.component';
import {UpcService} from '../../services/upc-service/upc.service';
import {AttributeTypeaheadConfig} from 'pm-models';
import {PluRange} from 'pm-models/lib/pluRange';
import {UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {calculateCheckDigit} from '../../../../../../../src/app/2.0.0/shared/upc.utils';

export { UPCInputState } from '../base-upc-input/base-upc-input.component';

@Component({
  selector: 'pm-upc-input',
  templateUrl: './upc-input.component.html',
  styleUrls: ['../base-upc-input/base-upc-input.component.scss', './upc-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpcInputComponent extends BaseUpcInputComponent implements OnInit {
  upcTypeValue;

  pluRangeConfiguration: AttributeTypeaheadConfig = {
    description: ``,
    isDisabled: () => this.isPluTypeDisabled(),
    isReadOnly: () => false,
    name: 'pluRangeId',
    idRef: 'pluRangeId',
    displayRef: 'displayName',
    searchUrl: '/lookup/pluRange',
    placeholderText: 'Select range...',
  };

  @Input()
  get upcType() {
    return this.upcTypeValue;
  }
  set upcType(val) {
    this.upcTypeValue = val;
    if (this.autoCheckDigit && this.upcModel) {
      this.checkDigitModel = calculateCheckDigit(this.upcModel);
    }
    this.upcTypeChange.emit(val);
  }
  @Output() upcTypeChange?: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  upcOptions: {label: string, value: string}[];

  @Input() pluTypes: {label: string, value: UpcRequestType}[];

  @Input()
  pluRanges: PluRange[];

  @Input()
  hideCheckDigit: boolean;

  @Input()
  hideUpcField: boolean;

  @Input()
  hidePlu: boolean;

  @Input()
  pluTypeModel: UpcRequestType;

  @Input()
  pluRangeModel: PluRange;

  @Input()
  pluTypePlaceholderText: string;

  @Input()
  pluRangePlaceholderText: string;

  @Output() pluRangeModelChange?: EventEmitter<any> = new EventEmitter<any>();

  @Output() pluTypeModelChange?: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  fullPlu: number;
  constructor(private parentUPCService: UpcService) {
    super(parentUPCService);
  }

  ngOnInit() {
    if (!this.hidePlu) {
      this.hidePlu = true;
    }
    if (!this.pluTypes) {
      this.pluTypes = [{label: 'Checker PLU', value: UpcRequestType.CHECKER},
        {label: 'Scale PLU', value: UpcRequestType.SCALE},
        {label: 'Checker & scale PLU', value: UpcRequestType.CHECKER_AND_SCALE}];
    }
    if (this.upcTypeValue === 'PLU') {
      this.hidePlu = false;
      if (this.pluTypeModel !== null && this.pluTypeModel !== undefined) {
        this.setPluType();
      }
    }
  }

  integerPattern(event) {
    const pattern = /^\d+$/;
    return pattern.test(event.key);
  }

  onRangeSelection(event) {
    this.pluRangeModel = event;
    this.pluRangeModelChange.emit(this.pluRangeModel);
  }

  onTypeSelection(event) {
    this.pluTypeModel = event.value;
    this.pluTypeModelChange.emit(this.pluTypeModel);
  }

  resetPlu() {
    if (this.pluRangeModel || this.pluTypeModel) {
      this.pluRangeModel = null;
      this.pluTypeModel = null;
    }
  }

  setPluType() {
    if (this.pluTypeModel !== null && this.pluTypeModel !== undefined) {
      for (let x = 0; x < this.pluTypes.length; x++) {
        if (+this.pluTypeModel === this.pluTypes[x].value) {
          this.pluTypeModel = this.pluTypes[x].value;
        }
      }
    }
  }

  showPlu() {
    return this.pluTypeModel === UpcRequestType.CHECKER_AND_SCALE ||
      this.pluTypeModel === UpcRequestType.CHECKER;
  }

  showFullPlu() {
    return this.pluTypeModel === UpcRequestType.CHECKER_AND_SCALE ||
      this.pluTypeModel === UpcRequestType.SCALE;
  }

  isPluTypeDisabled(): boolean {
    return !this.pluTypeModel;
  }
}
