import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CostCardComponent } from './cost-card.component';

import { CardModule } from '../../shared/card/card.module';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';



@NgModule({
  declarations: [CostCardComponent],
  exports: [CostCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule, AttributeDisplayModule
  ]
})
export class CostCardModule { }
