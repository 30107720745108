<div *ngIf="!appConfig.isSsoEnabled()" class="container-fluid mx-0 px-0" style="margin-top:20px;">
  <div class="login-form">
    <!-- Section Title Bar begins -->
    <div class="row justify-content-center mx-0">
      <div class="h2" style="text-align:center;">
        <span>Product Attribute Management</span>
      </div>
    </div>
    <!-- Section Title Bar ends -->

    <div class="row message-bar justify-content-center align-items-center mx-0 px-0">
      <p class="text-center py-0 my-0">
        If you need assistance please contact the H-E-B Help Desk at
        1-210-938-8464 or on <a href="https://partnernet.heb.com/IS/HelpDesk/Pages/default.aspx" target="_blank">PartnerNet</a>
      </p>
    </div>

    <div class="row justify-content-center mx-0">
      <div class="col-md-5 col-sm-8 col-lg-3 mx-auto">

        <!-- Login Form -->
        <form (ngSubmit)="login()" role="form" class="form-signin" #form="ngForm">
          <div class="form-group" style="margin-bottom: 30px;">
            <label for="username">User Name:</label>
            <input type="text" id="username" class="form-control" name="username" size="25" placeholder="Enter username"
                   [(ngModel)]="username"
                   #user="ngModel"
                   required autofocus />
            <div [hidden]="user.valid || user.pristine"
                 class="text-danger">User Name is required</div>
          </div>
          <div class="form-group" style="margin-bottom: 30px;">
            <label for="password">Password:</label>
            <input class="form-control" type="password" name="password" id="password"
                   size="25" required
                   placeholder="Enter password"
                   [(ngModel)]="password"
                   #pass="ngModel" />
            <div [hidden]="pass.valid || pass.pristine"
                 class="text-danger">Password is required</div>
            <div *ngIf="errorMessage" class="text-danger">{{errorMessage}}</div>
          </div>
          <button id="login" class="btn btn-block btn-primary" [disabled]="form.invalid || dataLoading"
                  type="submit">Login
          </button>
        </form>

      </div>
    </div>

    <div class="row message-bar mx-0">
      <div class="col-md-6 mx-auto">
        <p class="text-center">
          This system and all related equipment is for official
          HEB business only. All systems are subject to monitoring
          for management, unauthorized access, and verification of
          security procedures. Use of the system constitutes consent
          to monitoring for this purpose. Unauthorized use of this
          system may subject you to criminal prosecution and penalties.
        </p>
      </div>
    </div>

  </div>
</div>
<noscript><meta *ngIf="appConfig.isSsoEnabled() && !auth.isAuthenticated()" http-equiv="refresh" content="0; url={{'auth/saml/login?RelayState=' + window.location.href}}"></noscript>
