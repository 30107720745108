/**
 * Generic class to hold generic code table results.
 *
 * @author s573181
 * @since 1.58.0
 */
export class Code {
  id: string;
  description: string;
}
