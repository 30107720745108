import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageUploadComponent } from './image-upload.component';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from '../../shared/progress-spinner/progress-spinner.module';
import { DirectiveModule } from '../directives/directive.module';

@NgModule({
  declarations: [ImageUploadComponent],
  exports: [ImageUploadComponent, ButtonModule, ProgressSpinnerModule],
  imports: [CommonModule, ButtonModule, HttpClientModule, ProgressSpinnerModule, DirectiveModule]
})
export class ImageUploadModule {}
