<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <pm-associate-upc-stepper [candidateErrors]="associateUpcService.getAllCandidateErrors()" [stepIndex]="2"
                              (indexClick)="onClickStepper($event)" [isProcurementOnly]="false">
    </pm-associate-upc-stepper>
  </pm-header>

  <pm-title>Tell us about this new item.</pm-title>

  <div class="row" style="margin-top: 25px;">
  </div>

  <div *ngIf="associateUpcService.candidate" class="col-md-8">
    <h3>Primary UPC Summary</h3>
    <div class="row">
      <img class="product-image" src="{{productImageUrl ? productImageUrl : DEFAULT_NO_PRODUCT_IMAGE}}">
      <div class="col">
        <div class="row">
          <div class="upc-header">
            UPC: {{productData?.primaryScanCodeId}}
          </div>
        </div>
        <div class="attribute-description">
          <p>{{productData?.productDescription}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="associateUpcService.candidate" class="col-12 col-md-8">
      <pm-card title="Basics"
               subtitle="Provide the unit's UPC. The unit must have the same sub-brand as the associated primary UPC.">
        <ng-container>
          <pm-candidate-product-list [candidateProductsModel]="associateUpcService.getCandidate().candidateProducts"
                                     (deleteProductEmitted)="deleteExistingAssociate($event)" (addProductEmitted)="addAssociateUpc()"
                                     [errorModel]="candidateError" [unitUpcConfiguration]="unitUPCConfiguration"
                                     [isValidating]="isValidating" (validateSingleUpc)="validateSingleUpc($event)"></pm-candidate-product-list>
          <pm-attribute-display-text-input [attribute]="subBrandConfiguration" [model]="subBrand">
          </pm-attribute-display-text-input>
        </ng-container>
      </pm-card>
    </div>
  </div>

  <ng-container *ngIf="associateUpcService.candidate">
    <pm-product-packaging-card [(model)]="primaryUpc"
                               [associateUpcConfig]="true"
                               [permissions]="productPackagingCardPermissions"
                               [packageTypes]="packageTypes"
                               [unitOfMeasure]="primaryUpc?.unitOfMeasure"
                               [errorModel]="candidateError">
    </pm-product-packaging-card>

    <pm-attachments-card [(model)]="associateUpcService.candidate"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="candidateError">
    </pm-attachments-card>
  </ng-container>


  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
