<pm-review (closeEvent)="onClose()">

  <div class="row justify-content-md-center pt-3 pb-3">

    <div class="col col-md-6">
      <h1 class="title">Request an H-E-B UPC</h1>
      <label class="pt-1">Fill out the form below to reserve an H-E-B UPC. We’ll reserve the UPC for <b>18 months</b>, so make sure you can use it by <b>{{expirationDateString}}.</b></label>
      <label class="pt-1">We’ll email you the UPC along with your reservation details.</label>

      <pm-card *ngIf="upcRequest" title="Reservation Details"
               subtitle="Tell us about this product and who should have access to this UPC.">
        <div class="col-md-10">
          <div class="row">
            <div class="col">
              <pm-attribute-display-text-input [attribute]="descriptionConfiguration"
                                               [attributeError]="newUpcRequestError.description"
                                               [(model)]="upcRequest.candidate.description">
              </pm-attribute-display-text-input>

              <pm-attribute-display-typeahead [attribute]="buyerConfiguration"
                                              [(model)]="upcRequest.candidate.buyer"
                                              [isDropdown]="false"
                                              [attributeError]="newUpcRequestError?.buyer"
                                              (selection)="onBuyerChange($event)">
              </pm-attribute-display-typeahead>

              <pm-attribute-display-typeahead [attribute]="supplierConfiguration"
                                              [(model)]="upcRequest.candidate.vendor"
                                              [attributeError]="newUpcRequestError.vendor"
                                              (selection)="onSupplierChange($event)">
              </pm-attribute-display-typeahead>

              <pm-attribute-display-text-input *ngIf="!isVendor" [attribute]="numberOfUpcsConfiguration"
                                               [(model)]="upcRequest.count"
                                               [attributeError]="newUpcRequestError?.count">
              </pm-attribute-display-text-input>

              <pm-attribute-display-text-input [attribute]="upcRequestNotesConfiguration"
                                               [(model)]="upcRequest.upcRequestNotes"
                                               [attributeError]="newUpcRequestError?.upcRequestNotes"
                                               (modelChange)="onUpcRequestNotesChange($event)">
              </pm-attribute-display-text-input>
            </div>
          </div>
        </div>

      </pm-card>
    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Cancel" (onClick)="onClose()" [disabled]="isSubmitOrCancelDisabled"></p-button>
    <p-button class="m-2" label="Request UPC" (onClick)="onClickRequestUpcs($event, reservationSuccessOverlay, reservationSuccessOverlayDiv)" [disabled]="getIsSubmitDisabled()"></p-button>
  </pm-footer>
</pm-review>

<div>
  <div #reservationSuccessOverlayDiv></div>
  <pm-static-overlay-panel  #reservationSuccessOverlay [hideTransitionOptions]="'0ms ease-in'"
                            [showTransitionOptions]="'0ms ease-out'" appendTo="body"
                            [style]="{width: '100%', height: '100%'}"
                            [dismissable]="false">
    <app-request-heb-upc-success *ngIf="isShowingReservationSuccessOverlay"
                                 [upcs]="reservedUpcs"
                                 [upcRequest]="upcRequest">
    </app-request-heb-upc-success>
  </pm-static-overlay-panel>
</div>
