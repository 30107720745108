<div class="product-table-container">
  <div class="row ml-0 mr-0">
    <p-table selectionMode="single"
             [rowHover]="true"
             [columns]="productHeaderColumns"
             [value]="candidates"
             [rows]="pageSize"
             [totalRecords]="totalNumRecords"
             [scrollable]="true"
             styleClass="p-datatable-gridlines"
             [ngStyle]="{'width': '100%'}">

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns"
               [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header" let-productHeaderColumns>
        <tr class="product-table-column-group">
          <th class="left-sticky-header" [attr.colspan]="1">#</th>
          <th class="left-sticky-header" [attr.colspan]="1">Candidate</th>
          <th *ngIf="DESCRIPTION_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="DESCRIPTION_COLUMN_GROUP_LENGTH">Descriptions</th>
          <th [attr.colspan]="1">Images</th>
          <th *ngIf="MERCHANDISING_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="MERCHANDISING_COLUMN_GROUP_LENGTH">Merchandising</th>
          <th *ngIf="REGULATORY_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="REGULATORY_COLUMN_GROUP_LENGTH">Regulatory</th>
          <th *ngIf="RETAIL_PRICING_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="RETAIL_PRICING_COLUMN_GROUP_LENGTH">Retail pricing</th>
          <th *ngIf="PACKAGING_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="PACKAGING_COLUMN_GROUP_LENGTH">Packaging</th>
        </tr>
        <tr class="product-table-column-headers">
          <th *ngFor="let col of productHeaderColumns"
              [ngClass]="col.sticky ? 'left-sticky-header' : ''"
              [ngStyle]="{'width': col.width}"
              (click)="onClickHeader(col)">
            <div>
              {{col.header}}
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-product let-index="rowIndex">
        <tr class="product-table-body" [pSelectableRow]="product">

          <td class="sticky-column col">
            <div>{{index + 1}}</div>
          </td>
          <td class="sticky-column col">
            <div>{{product?.candidateProducts[candidateProductIndex].upc}}</div>
            <div>{{product?.candidateProducts[candidateProductIndex].description}}</div>
          </td>

          <!--  Product Description -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.PRODUCT_DESCRIPTION)" (click)="editProductDescription(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['description'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['description'])"
               class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['description'])}}">
            </i>
            {{product?.candidateProducts[candidateProductIndex]?.description}}
          </td>

          <!--  Receipt -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.RECEIPT)" (click)="editProductDescription(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['description'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['description'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['description'])}}"></i>
            {{candidateUtils.getReceiptString(product?.candidateProducts[candidateProductIndex]?.description)}}
          </td>

          <!--  Shelf tag line 1  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.SHELF_TAG_1)" (click)="editCandidateProduct(index, attributeType.ShelfTag1)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['customerFriendlyDescription1'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['customerFriendlyDescription1'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['customerFriendlyDescription1'])}}"></i>
            {{product?.candidateProducts[candidateProductIndex]?.customerFriendlyDescription1}}
          </td>

          <!--  Shelf tag line 2  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.SHELF_TAG_2)" (click)="editCandidateProduct(index, attributeType.ShelfTag2)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['customerFriendlyDescription2'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['customerFriendlyDescription2'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['customerFriendlyDescription2'])}}"></i>
            {{product?.candidateProducts[candidateProductIndex]?.customerFriendlyDescription2 | emptyToValue: '-'}}
          </td>

          <!--  eComm copy -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.ECOM_COPY)" (click)="editCandidateProduct(index, attributeType.ECommCopy)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['romanceCopy'])}">
            <div class="overflow-ellipsis-css">
              <i *ngIf="candidateErrorUtils.hasCandidateProductError(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['romanceCopy'])" class="pi pi-exclamation-triangle errorTriangle"
                 pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(product, candidateProductIndex, candidateIdToCandidateErrorMap, ['romanceCopy'])}}"></i>
              {{product?.candidateProducts[candidateProductIndex]?.romanceCopy}}
            </div>
          </td>

          <td *ngIf="hasColumn(uploadCandidateProductConstants.IMAGES)" (click)="editCandidateProduct(index, attributeType.ProductImages)">
            <div class="row" *ngIf="hasImages(product)">
              <ng-template ngFor let-image [ngForOf]="product?.candidateProducts[candidateProductIndex]?.imageLinks">
                <div *ngIf="getImage(image.uuid)">
                  <img class="product-image" src="{{getImage(image.uuid)}}">
                </div>
              </ng-template>
            </div>
          </td>

          <!--  Commodity  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.COMMODITY)" (click)="editCommodity(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['commodity'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['commodity'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['commodity'])}}"></i>
            {{product?.commodity?.commodityName}}
          </td>

          <!--  subCommodity  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.SUB_COMMODITY)" (click)="editSubCommodity(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['subCommodity'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['subCommodity'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['subCommodity'])}}"></i>
            {{product?.subCommodity?.displayName}}
          </td>

          <!--  Merchandise type  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.MERCHANDISE_TYPE)" (click)="editMerchandiseType(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['merchandiseType'])}">
            <i *ngIf="candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['merchandiseType'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['merchandiseType'])}}"></i>
            {{product?.merchandiseType?.description}}
          </td>

          <!--  PSS department  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.PSS_DEPARTMENT)"  (click)="editPssDepartment(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['pssDepartment'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['pssDepartment'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['pssDepartment'])}}"></i>
            {{product?.pssDepartment?.displayName}}
          </td>

          <!--  Season --->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.SEASON)" (click)="editSeasonYear(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['season', 'seasonYear'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['season', 'seasonYear'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['season', 'seasonYear'])}}"></i>
            {{getSeasonYearString(product) | emptyToValue: '-'}}
          </td>

          <!--  Like item code  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.LIKE_ITEM_CODE)" (click)="showEditCandidateModal(index, attributeType.LikeItemCode)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['likeId'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['likeId'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['likeId'])}}"></i>
            {{product?.likeId}}
          </td>

          <!--  Est. store count  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.ESTIMATED_STORE_COUNT)" (click)="showEditCandidateModal(index, attributeType.EstimatedStoreCount)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['numberOfStores'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['numberOfStores'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['numberOfStores'])}}"></i>
            {{product?.numberOfStores}}
          </td>

          <!--  Taxable -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.TAXABLE)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['taxable'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['taxable'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['taxable'])}}"></i>
            {{product?.taxable | yesNo}}
          </td>

          <!--  Tax category -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.TAX_CATEGORY)" (click)="editVertexTaxCategory(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['vertexTaxCategoryModel'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['vertexTaxCategoryModel'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['vertexTaxCategoryModel'])}}"></i>
            {{product?.vertexTaxCategoryModel?.displayName | emptyToValue: '-'}}
          </td>

          <!--  Food stamp eligible  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.FOOD_STAMP_ELIGIBLE)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['foodStamp'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['foodStamp'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['foodStamp'])}}"></i>
            {{product?.foodStamp | yesNo}}
          </td>

          <!--  FSA/HSA eligible  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.FSA_ELIGIBLE)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['flexibleSpendingAccount'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['flexibleSpendingAccount'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['flexibleSpendingAccount'])}}"></i>
            {{product?.flexibleSpendingAccount | yesNo}}
          </td>

          <!--  Pricing  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.PRICING)" (click)="editPricingType(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['retailXFor', 'retailPrice', 'retailType', 'retailLink'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['retailXFor', 'retailPrice', 'retailType', 'retailLink'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['retailXFor', 'retailPrice', 'retailType', 'retailLink'])}}"></i>
            {{product?.retailXFor}} for ${{product?.retailPrice}} <br>
            <div class="cell-info">{{product?.retailType}}</div>
          </td>

          <!--  Penny profit: -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.PENNY_PROFIT)">
            {{costService.getHebPennyProfit(product)}}
          </td>

          <!--  Margin -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.MARGIN)" >
            {{costService.getHebMargin(product)}}
          </td>

          <!--  Sold by weight -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.SOLD_BY_WEIGHT)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['weightSwitch'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['weightSwitch'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['weightSwitch'])}}"></i>
            {{product?.weightSwitch | yesNo}}
          </td>

          <!--  Suggested retail  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.SUGGESTED_RETAIL)" (click)="showEditCandidateModal(index, attributeType.SuggestedRetail)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['suggestedXFor', 'suggestedRetailPrice'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['suggestedXFor', 'suggestedRetailPrice'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['suggestedXFor', 'suggestedRetailPrice'])}}"></i>
            {{product?.suggestedXFor}} for ${{product?.suggestedRetailPrice}}
          </td>

          <!--  MAP retail  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.MAP_RETAIL)" (click)="showEditCandidateModal(index, attributeType.MapRetail)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['mapRetail'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['mapRetail'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['mapRetail'])}}"></i>
            {{product?.mapRetail | emptyToValue: '-'}}
          </td>

          <!--  Package type  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.PACKAGE_TYPE)" (click)="editPackageType(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['packageType'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['packageType'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['packageType'])}}"></i>
            {{product?.packageType?.description}}
          </td>

          <!--  Dimensions  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.DIMENSIONS)" (click)="showEditCandidateModal(index, attributeType.ProductDimensions)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['productLength', 'productWidth', 'productHeight'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['productLength', 'productWidth', 'productHeight'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['productLength', 'productWidth', 'productHeight'])}}"></i>
            {{candidateUtils.getProductDimensionsString(product)}}
          </td>

          <!--  Weight  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.WEIGHT)" (click)="showEditCandidateModal(index, attributeType.ProductWeight)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['productWeight'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['productWeight'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['productWeight'])}}"></i>
            {{product?.productWeight}}
          </td>

          <!--  Unit of measure  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.UNIT_OF_MEASURE)" (click)="editUnitOfMeasure(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['unitOfMeasure'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['unitOfMeasure'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['unitOfMeasure'])}}"></i>
            {{product?.unitOfMeasure?.description}}
          </td>

          <!--  Retail size  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.RETAIL_SIZE)" (click)="showEditCandidateModal(index, attributeType.RetailSize)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['retailSize'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['retailSize'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['retailSize'])}}"></i>
            {{product?.retailSize}}
          </td>

          <!--  Total volume  -->
          <td *ngIf="hasColumn(uploadCandidateProductConstants.TOTAL_VOLUME)" (click)="showEditCandidateModal(index, attributeType.TotalVolume)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['totalVolume'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(product, candidateIdToCandidateErrorMap, ['totalVolume'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(product, candidateIdToCandidateErrorMap, ['totalVolume'])}}"></i>
            {{product?.totalVolume}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
