<ng-container>
  <a *ngIf="!isSearchFormOpen" (click)="clickBuyerButton()" class="mt-2 badge badge-pill badge-light">Buyer
    <span *ngIf="numberSelectedBuyers() > 0" class="filterButton"> • {{numberSelectedBuyers()}}</span>
    <i class="ml-2 align-middle pi pi-chevron-down"></i>
  </a>

  <div *ngIf="isSearchFormOpen">
    <a (click)="clickBuyerButton()" class="mt-2 badge badge-pill badge-light">Buyer
      <i class="ml-2 align-middle pi pi-chevron-up"></i>
    </a>

    <!-- search box -->
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="input-group">
          <input class="form-control py-2 border-right-0 border-bottom-0 rounded-0 border" type="search" placeholder="Search..." [(ngModel)]="searchText">
          <span class="input-group-append">
            <div class="border-bottom-0 rounded-0 input-group-text bg-transparent"><i class="pi pi-search"></i></div>
          </span>
        </div>
      </div>
    </div>

    <!-- selection boxes -->
    <div class="box border attribute-group" *ngIf="(buyers | filterByName: searchText).length > 0; else noResults">
      <div class="ui-scrollpanel">
        <div class="ui-scrollpanel-wrapper">
          <div class="ui-scrollpanel-content">

            <p class="my-2" style="font-size: 12px">BUYERS YOU FOLLOW</p>
            <p-checkbox *ngFor="let option of followedBuyers | filterByName: searchText;"
                        [name]="option.displayName"
                        [id]="option.buyerId"
                        [value]="option.buyerId"
                        [label]="option.displayName"
                        [styleClass]="'ml-2 block'"
                        class="attribute-radio block"
                        [(ngModel)]="followedBuyersModel"
                        (ngModelChange)="onChangeFollowedBuyersModel($event)">
            </p-checkbox>

            <p class="mt-4 mb-2" style="font-size: 12px">ALL BUYERS</p>
            <p-checkbox *ngFor="let option of notFollowedBuyers | filterByName: searchText;"
                        [name]="option.displayName"
                        [id]="option.buyerId"
                        [value]="option.buyerId"
                        [label]="option.displayName"
                        [styleClass]="'ml-2 block'"
                        class="attribute-radio block"
                        [(ngModel)]="notFollowedBuyersModel"
                        (ngModelChange)="onChangeNotFollowedBuyersModel($event)">
            </p-checkbox>
          <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div *ngIf="(buyers | filterByName: searchText).length > 0" class="border">
      <div class="attribute-group">
        <div class="text-right">
          <p-button class="ghost m-2" label="Clear" (onClick)="onClickClearButton()"></p-button>
          <p-button class="m-2" label="Apply" (onClick)="onClickApplyButton()"></p-button>
        </div>
      </div>
    </div>

  </div>

  <ng-template #noResults>
    <div class="border">
      <div class="attribute-group">
        <p class="mx-2 my-2">No results found for "{{searchText}}".</p>
      </div>
    </div>
  </ng-template>
</ng-container>
