import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';

export class TotalRetailUnits implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Total retail units',
      description: 'The total number of units in the master pack.',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      textInputType: TextInputType.integer,
      placeholderText: '# Units',
      inputGroupClass: 'ui-narrow-input',
      name: 'totalRetailUnitsId',
      maxLength: 7,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.masterPack;
    componentInstance.modelChange.subscribe(x => {
      candidate.masterPack = x;
      if (candidate.masterPack > CandidateUtilService.OMI_TOTAL_RETAIL_UNITS_MAX && candidate.innerPackSelected) {
        candidate.innerPackSelected = false;
        candidate.innerPack = candidate.masterPack;
        candidate.innerLength = candidate.masterLength;
        candidate.innerWidth = candidate.masterWidth;
        candidate.innerHeight = candidate.masterHeight;
        candidate.innerWeight = candidate.masterWeight;
      }
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.masterPack) {
      componentInstance.model = candidate.masterPack;
    }
  }
}
