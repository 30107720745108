<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-4">
    <!-- -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Regulatory" subtitle="Let us know if any of these regulatory items apply to this product.">
    <ng-container *ngIf="model">

      <pm-attribute-display-toggle [attribute]="taxableConfiguration"
                                   [(model)]="model.taxable"
                                   (change)="onTaxableChange($event)">
      </pm-attribute-display-toggle>

      <pm-attribute-display-typeahead class="vertex-category-typeahead"
                                      [attribute]="taxCategoryConfiguration"
                                      [(model)]="model.vertexTaxCategoryModel"
                                      [attributeError]="errorModel?.vertexTaxCategory"
                                      (selection)="onTypeaheadChange($event)">
      </pm-attribute-display-typeahead>
      <div *ngIf="showTaxCategoryEdit()" class="row pb-4 attribute-description">
        <div>
          Sub-commodity sets a default tax category, but you can change it. Not sure which category to select?
          <a [routerLink]="" queryParamsHandling="preserve" class="pl-1" (click)="editTaxCategory()">
            Use the advance selection tool.
          </a>
        </div>
      </div>

      <pm-attribute-display-toggle [attribute]="foodStampsConfiguration"
                                   [(model)]="model.foodStamp"
                                   (change)="foodStampChange($event)">
      </pm-attribute-display-toggle>

      <pm-attribute-display-toggle [attribute]="fsaConfiguration"
                                   [(model)]="model.flexibleSpendingAccount"
                                   (change)="flexSpendingChange($event)">
      </pm-attribute-display-toggle>
      <pm-attribute-display-checkbox [isIndented]="false" [attribute]="showCaloriesConfiguration" [(model)]="model.showCalories"
                                     (change)="showCaloriesChange($event)">
      </pm-attribute-display-checkbox>
    </ng-container>
  </pm-card>
</ng-template>
