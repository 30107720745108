<pm-editor (closeEvent)="supplierProductService.saveCandidateAndClose()">
  <pm-header>
    <app-new-warehouse-product-stepper *ngIf="supplierProductService.candidate && !supplierProductService.isDsdOnly()"
                                       [candidateErrors]="supplierProductService.getAllWhsCandidateErrors()"
                                       [stepIndex]="5"
                                       [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                       (indexClick)="onClickStepper($event)">
    </app-new-warehouse-product-stepper>
    <app-new-dsd-product-stepper *ngIf="supplierProductService.candidate && supplierProductService.isDsdOnly()"
                                 [candidateErrors]="supplierProductService.getAllDsdCandidateErrors()"
                                 [stepIndex]="6"
                                 [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                 (indexClick)="onClickStepper($event)">
    </app-new-dsd-product-stepper>
  </pm-header>
  <pm-title>Extended attributes
  </pm-title>
  <div *ngIf="isLoadingData" class="col-md-8 spinner-row">
    <pm-progress-spinner [showSpinner]="isLoadingData" [strokeWidth]="'2'"
                         [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}">
    </pm-progress-spinner>
    <h5 class="ml-3">Fetching attributes...</h5>
  </div>
  <ng-container *ngIf="!isLoadingData">

    <pm-global-attributes-card [candidateModel]="supplierProductService.candidate"
                               [candidateProductModel]="supplierProductService.candidate.candidateProducts[0]"
                               [globalAttributes]="supplierProductService.globalAttributes"
                               [candidateProductErrorModel]="candidateProductError">
    </pm-global-attributes-card>

    <pm-mat-categories-card [candidateModel]="supplierProductService.candidate"
                            [candidateProductModel]="supplierProductService.candidate.candidateProducts[0]"
                            [hierarchyAttributes]="supplierProductService.hierarchyAttributes"
                            [candidateProductErrorModel]="candidateProductError">
    </pm-mat-categories-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
