import {Component, OnInit} from '@angular/core';
import {UPCInputState} from 'pm-components';
import {
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType
} from 'pm-models';
import {CandidateService} from '../../service/candidate.service';
import {WorkflowService} from '../../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BonusSizeService} from '../../service/bonus-size.service';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {SupplierBonusStepperComponent} from '../../supplier-bonus-size-flow/supplier-bonus-stepper/supplier-bonus-stepper.component';
import {PiaBonusStepperComponent} from '../pia-bonus-stepper/pia-bonus-stepper.component';

@Component({
  selector: 'app-pia-setup-bonus-size',
  templateUrl: './pia-setup-bonus-size.component.html',
  styleUrls: ['./pia-setup-bonus-size.component.scss']
})
export class PiaSetupBonusSizeComponent implements OnInit {
  // inputs for pm-upc-input component
  upcState: UPCInputState;
  upcOptions = [{ label: 'UPC', value: 'UPC' }, { label: 'Item Code', value: 'ITMCD' }];
  public canNavigate = true;

  public searchedCode: number;
  public upcType: any;

  public taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  isViewingPage = true;

  warehouseSupplierConfiguration: AttributeTypeaheadConfig = {
    label: 'Warehouse supplier',
    description: 'Where the product is shipped from (origin).',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    idRef: 'id',
    displayRef: 'name',
    name: 'warehouseId',
    placeholderText: 'Select a warehouse...',
    searchUrl: '/lookup/lane',
    showAddlInfo: true
  };

  constructor(public candidateService: CandidateService, public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public bonusSizeService: BonusSizeService, public growlService: GrowlService,
              public lookupService: LookupService, private candidateUtilService: CandidateUtilService) { }

  ngOnInit() {
    // If taskId specified in URL, then get it from server.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId, this.bonusSizeService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          this.bonusSizeService.currentCandidate = candidate;
          this.bonusSizeService.originalCandidate = JSON.parse(JSON.stringify(candidate));
          this.initializeData();
        });
      } else if (this.bonusSizeService.getOriginalCandidate() && this.bonusSizeService.getCandidate() && !taskId
        && !this.bonusSizeService.getCandidate().candidateId) {
        this.initializeData();
      } else {
        // if there's a task id and current candidates, get the task by candidate id. If the tasks IDs don't match, reset everything.
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.bonusSizeService.resetService();
            if (this.route.snapshot.routeConfig.path.includes('piaSetupReplacementUpc')) {
              window.history.replaceState({}, 'Title', '#/piaSetupReplacementUpc');
              this.bonusSizeService.setupNewCandidate(Candidate.REPLACEMENT_UPC, CandidateProduct.REPLACEMENT_UPC);
            } else {
              window.history.replaceState({}, 'Title', '#/piaSetupBonusSizeComponent');
              this.bonusSizeService.setupNewCandidate(Candidate.BONUS_SIZE, CandidateProduct.BONUS_SIZE);
            }
            this.initializeData();
          }
        });
      }
    });
  }

  initializeData() {
    this.setInitialUpcType();
    this.setSearchedCode();
    this.bonusSizeService.setProductData().subscribe();
    this.bonusSizeService.setIsReplacementUpc();
    this.candidateError = this.bonusSizeService.setupBonusSizeError;
    this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
  }

  setSearchedCode () {
    this.searchedCode = this.isItemCode() ? this.bonusSizeService.getSearchedCandidateProduct().itemCode :
      this.isUPC() ? this.bonusSizeService.getSearchedCandidateProduct().upc : null;
  }

  setInitialUpcType() {
    if (this.bonusSizeService.getSearchedCandidateProduct().itemCode !== null &&
      this.bonusSizeService.getSearchedCandidateProduct().itemCode !== undefined) {
      this.upcType = 'ITMCD';
    } else {
      this.upcType = 'UPC';
    }
  }

  hasTaskInParams(params) {
    return params.keys.length > 0 && params.has('taskId');
  }

  hasError() {
    return this.candidateProductError && (this.candidateProductError.upc || this.candidateProductError.itemCode);
  }

  getError() {
    if (!this.hasError()) {
      return;
    }
    if (this.isItemCode()) {
      return this.candidateProductError.itemCode;
    } else if (this.isUPC()) {
      return this.candidateProductError.upc;
    }
  }

  setUpcItemCodeAndValidate() {
    if (this.isItemCode()) {
      this.bonusSizeService.getSearchedCandidateProduct().itemCode = +this.searchedCode;
    } else {
      this.bonusSizeService.getSearchedCandidateProduct().upc = +this.searchedCode;
    }
    if (this.isItemCode() ||
      (this.bonusSizeService.getSearchedCandidateProduct().upc &&
        (this.bonusSizeService.getSearchedCandidateProduct().upcCheckDigit || this.bonusSizeService.getSearchedCandidateProduct().upcCheckDigit === 0))) {
      this.validateUPC();
    } else {
      this.candidateProductError.upc = undefined;
      this.candidateProductError.upcCheckDigit = undefined;
      this.upcState = UPCInputState.none;
    }
    this.bonusSizeService.productData = null;
    this.bonusSizeService.resetMatHierarchyFields();
  }

  onUpcTypeChange() {
    if (this.isUPC()) {
      this.bonusSizeService.getSearchedCandidateProduct().itemCode = null;
      this.searchedCode = this.bonusSizeService.getSearchedCandidateProduct().upc;
      this.bonusSizeService.getSearchedCandidateProduct().candidateProductType = CandidateProduct.SEARCHED_UPC;
    } else if (this.isItemCode()) {
      this.bonusSizeService.getSearchedCandidateProduct().upc = null;
      this.bonusSizeService.getSearchedCandidateProduct().upcCheckDigit = null;
      this.searchedCode = this.bonusSizeService.getSearchedCandidateProduct().itemCode;
      this.bonusSizeService.getSearchedCandidateProduct().candidateProductType = CandidateProduct.SEARCHED_ITEM;
    }
    this.bonusSizeService.productData = null;
    this.bonusSizeService.resetMatHierarchyFields();
  }

  isItemCode() {
    return this.upcType === 'ITMCD';
  }

  isUPC() {
    return this.upcType === 'UPC';
  }

  validateUPC() {
    this.upcState = UPCInputState.loading;
    const validators = [];
    if (this.isItemCode()) {
      validators.push(CandidateValidatorType.EXISTING_ITEM_CODE_VALIDATOR);
    } else if (this.isUPC()) {
      validators.push(CandidateValidatorType.EXISTING_UPC_VALIDATOR);
    }

    const candidateProductId = this.bonusSizeService.getSearchedCandidateProduct().id;

    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      validators).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.upc = undefined;
      this.candidateProductError.upcCheckDigit = undefined;
      this.candidateProductError.itemCode = undefined;
      if (this.bonusSizeService.currentCandidate.vendor.apNumber) {
        this.bonusSizeService.setProductData().subscribe();
      }
    }, (error) => {
      this.upcState = UPCInputState.invalid;

      this.bonusSizeService.clearProductData();

      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.upc = returnedCandidateProductError.upc;
        this.candidateProductError.upcCheckDigit = returnedCandidateProductError.upcCheckDigit;
        this.candidateProductError.itemCode = returnedCandidateProductError.itemCode;

        this.bonusSizeService.setupBonusSizeError = JSON.parse(JSON.stringify(this.bonusSizeService.setupBonusSizeError));
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  onClickNext() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR]).subscribe(() => {
      this.bonusSizeService.setupBonusSizeError = new CandidateError();
      if (this.bonusSizeService.taskId) {
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaUpcDetailsUrl(), this.hasVendorChanged());
      } else {
        if (this.bonusSizeService.currentCandidate.description) {
          this.bonusSizeService.createCandidateAndNavigate(
            this.bonusSizeService.currentCandidate, this.bonusSizeService.getPiaUpcDetailsUrl(), this.hasVendorChanged(), true);
        }
      }
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.bonusSizeService.setupBonusSizeError = error.error.candidateErrors;
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.candidateError.candidateProductErrors[this.bonusSizeService.getSearchedCandidateProduct().id];
      }
      this.canNavigate = true;
    });
  }

  onClickBack() {
    if (this.bonusSizeService.taskId) {
      this.bonusSizeService.saveCandidateAndNavigate('/setupCandidate', this.hasVendorChanged());
    } else {
      this.router.navigate(['/setupCandidate']).then();
    }
  }

  onClose() {
    this.isViewingPage = false;
    if (this.bonusSizeService.taskId) {
      this.bonusSizeService.saveCandidateAndNavigate('/tasks', this.hasVendorChanged());
    } else {
      this.router.navigate(['/tasks']).then();
    }
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.bonusSizeService.originalCandidate.vendor) !== JSON.stringify(this.bonusSizeService.currentCandidate.vendor);
  }

  warehouseSupplierChange($event) {
    if ($event === undefined) {
      this.bonusSizeService.currentCandidate.lane = null;
      this.bonusSizeService.currentCandidate.vendor = null;
      return;
    }

    this.bonusSizeService.currentCandidate.lane = $event;
    this.bonusSizeService.currentCandidate.vendor = this.bonusSizeService.currentCandidate.lane.vendor;

  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    let urlToNavigate;
    let validators;
    switch (stepperItem.text) {
      case PiaBonusStepperComponent.BONUS_DETAILS:
      case PiaBonusStepperComponent.REPLACEMENT_DETAILS:
        this.onClickNext();
        break;
      case PiaBonusStepperComponent.CASE_DETAILS:
        urlToNavigate = this.bonusSizeService.getPiaCaseDetailsUrl();
        validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
      case PiaBonusStepperComponent.WAREHOUSE_SELECTION:
        urlToNavigate = this.bonusSizeService.getPiaWarehouseUrl();
        validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR,
          CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
      case PiaBonusStepperComponent.EXTENDED_ATTRIBUTES:
        urlToNavigate = this.bonusSizeService.getPiaExtendedAttributesUrl();
        validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR,
          CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR,  CandidateValidatorType.WAREHOUSE_VALIDATOR, CandidateValidatorType.BICEP_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
    }
  }


  jumpToPage(urlToNavigate, validators: CandidateValidatorType[]) {
    this.canNavigate = false;
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate, validators).subscribe(() => {
      this.bonusSizeService.candidateError = this.bonusSizeService.setupBonusSizeError = new CandidateError();
      if (this.bonusSizeService.taskId) {
        this.bonusSizeService.saveCandidateAndNavigate(urlToNavigate, this.hasVendorChanged());
      } else {
        if (this.bonusSizeService.currentCandidate.description) {
          this.bonusSizeService.createCandidateAndNavigate(this.bonusSizeService.currentCandidate, urlToNavigate, this.hasVendorChanged());
        }
      }
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.bonusSizeService.candidateError = error.error.candidateErrors;
        this.bonusSizeService.candidateProductError =
          this.bonusSizeService.candidateError.candidateProductErrors[this.bonusSizeService.getSearchedCandidateProduct().id];
        this.bonusSizeService.setupBonusSizeError = this.bonusSizeService.candidateError;
      }
      this.canNavigate = true;
    });
  }
}
