import {BaseAttribute} from 'pm-components/lib/attributes/attribute-type/base-attribute';
import {Attribute, AttributeTypeaheadConfig} from 'pm-models';
import {MatUtilService} from '../../../../../../src/app/2.0.0/service/mat-util.service';
import {
  AttributeDisplayTypeaheadComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';
import {
  RequestNewMatAttributeFormModel
} from 'pm-components/lib/shared/request-new-mat-attribute-form/request-new-mat-attribute-form.component';
import {EventEmitter} from '@angular/core';

export class RequestNewMatAttributeOverrideWrapper {
  constructor() {
    this.onClickRequestNewAttributeValueEmitter = new EventEmitter<RequestNewMatAttributeOverrideWrapper>();
  }

  onClickRequestNewAttributeValueEmitter?: EventEmitter<any>;
  requestNewMatAttributeForm?: RequestNewMatAttributeFormModel;
  showPanel?: boolean = false;
  newAttributeConfirmationPanel?: any;
  target?: any;
}

export class MatAttributeTypeahead implements BaseAttribute {

  constructor() { }

  private attributeTypeaheadConfig: AttributeTypeaheadConfig;

  getAttributeConfig(overrides): AttributeTypeaheadConfig {
    return this.attributeTypeaheadConfig;
  }

  getComponentType(): any {
    return AttributeDisplayTypeaheadComponent;
  }

  setupComponent(componentInstance: any, attribute: Attribute, overrides?: any): void {
    this.setAttributeComponent(attribute, overrides?.collections);
    componentInstance.attribute = this.getAttributeConfig(overrides);
    componentInstance.model = attribute.value;
    componentInstance.selection.subscribe(event => {
      attribute.value = event;
    });

    componentInstance.buttonOptionClicked.subscribe((event) => {
      this.showRequestNewMatAttributeFormModel(event, overrides?.requestNewMatAttributeOverrideWrapper);
    });

    if (overrides?.requestNewMatAttributeOverrideWrapper.onClickRequestNewAttributeValueEmitter) {
      overrides.requestNewMatAttributeOverrideWrapper.onClickRequestNewAttributeValueEmitter.subscribe((value) => {
        attribute.value = value;
        componentInstance.model = value;
      });
    }
  }

  private setAttributeComponent(attribute: Attribute, values) {
    this.attributeTypeaheadConfig = MatUtilService.getAttributeTypeaheadConfigFromAttribute(attribute, values);
    this.attributeTypeaheadConfig.tabIndex = -1;
  }

  private showRequestNewMatAttributeFormModel(event, requestNewMatAttributeOverrideWrapper: RequestNewMatAttributeOverrideWrapper) {
    if (!requestNewMatAttributeOverrideWrapper?.newAttributeConfirmationPanel) {
      return;
    }

    const requestNewMatAttributeFormModel = new RequestNewMatAttributeFormModel();
    requestNewMatAttributeFormModel.attributeFieldId = this.attributeTypeaheadConfig.matAttributeId;
    requestNewMatAttributeFormModel.attributeBusinessFriendlyDescription = this.attributeTypeaheadConfig.label;
    requestNewMatAttributeFormModel.attributeTypeaheadConfig = this.attributeTypeaheadConfig;

    requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm = requestNewMatAttributeFormModel;
    // remove stub event
    if (this.attributeTypeaheadConfig.allowMultiple && Array.isArray(event) && !!event.length) {
      event.pop();
      requestNewMatAttributeFormModel.currentValue = event;
    }

    requestNewMatAttributeOverrideWrapper.showPanel = true;
    requestNewMatAttributeOverrideWrapper.newAttributeConfirmationPanel.show(event, requestNewMatAttributeOverrideWrapper.target);

  }
}
