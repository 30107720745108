import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {
  BaseCardPermissions,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  HEBDetailsCardPermissions,
  SupplierDetailsCardPermissions
} from 'pm-models';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {PiaMrtInnerStepperComponent} from '../pia-mrt-inner-stepper/pia-mrt-inner-stepper.component';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {MatUtilService} from '../../service/mat-util.service';
import {GrowlService} from '../../growl/growl.service';

@Component({
  selector: 'app-pia-inner-supplier-and-heb-details',
  templateUrl: './pia-inner-supplier-and-heb-details.component.html',
  styleUrls: ['./pia-inner-supplier-and-heb-details.component.scss']
})
export class PiaInnerSupplierAndHebDetailsComponent implements OnInit {


  public static readonly BASE_VALIDATORS: CandidateValidatorType[] =
    [CandidateValidatorType.SUPPLIER_HEADER_VALIDATOR, CandidateValidatorType.MAT_HIERARCHY_VALIDATOR];

  public innerCandidateError: CandidateError;
  public innerCandidateProductError: CandidateProductError;
  public isLoadingMatData = false;

  readonly hebDetailsCardPermissions: HEBDetailsCardPermissions = {
    isReadOnly: false,
    productType: {
      isDisabled: true
    },
    merchandiseType: {
      isHidden: true
    },
    description: {
      isHidden: true
    }
  };
  readonly matHierarchyCardPermissions: BaseCardPermissions = {
    isReadOnly: false
  };
  readonly supplierDetailsCardPermissions: SupplierDetailsCardPermissions = {
    isReadOnly: false,
    lane: {
      isHidden: true
    }
  };

  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, public candidateUtilService: CandidateUtilService,
              private permissionService: NgxPermissionsService, public matUtilService: MatUtilService, private growlService: GrowlService) { }

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.route.queryParamMap.subscribe(params => {

      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {
        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId()  ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          // if the candidate is an mrt, and contains the inner set the inner candidate.
          if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).subscribe((innerCandidate) => {
              this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
              this.initializeData();
            });
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierMrtService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }


  initializeData() {

    this.setVendorEditedTrue();
    this.innerCandidateError = this.supplierMrtService.getSupplierAndHebDetailsError(
      this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.innerCandidateError) {
      this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
        this.supplierMrtService.getInnerCandidateProduct(0).id];
    }
    this.setupMatAttributes();
  }


  /**
   * Sets mrt inner status to vendor edited.
   */
  setVendorEditedTrue() {
    for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
      if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidateId === this.supplierMrtService.getInnerCandidateId()) {
        this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].vendorEdited = true;
        this.supplierMrtService.saveMrtCandidate();
      }
    }
  }

  onClose() {
    this.supplierMrtService.onInnerClose();
  }


  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.supplierMrtService.getOriginalCandidate().vendor) !==
      JSON.stringify(this.supplierMrtService.getCandidate().vendor);
  }

  onBuyerChange(event) {}


  onClickBack() {
    this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaSetupNewMrtItems', null,
      this.hasVendorChanged(), this.supplierMrtService.hasInnerChanged());
  }

  onClickNext() {
    this.jumpToPage('/piaInnerProductDetails');
  }

  supplierChange(event) {
    if (!event || !this.supplierMrtService.getInnerCandidate().vendor ||
      event.apNumber !== this.supplierMrtService.getInnerCandidate().vendor.apNumber) {
      this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses = [];
    }
  }


  onClickStepper(stepperItem) {
    if (this.isLoadingMatData) {
      return;
    }
    switch (stepperItem.text) {
      case PiaMrtInnerStepperComponent.PRODUCT_DETAILS: {
        this.onClickNext();
        break;
      }
      case PiaMrtInnerStepperComponent.CASE_PACK: {
        this.jumpToPage('/piaAddNewMrtCase');
        break;
      }
      case PiaMrtInnerStepperComponent.CASE_DETAILS: {
        this.jumpToPage('/piaInnerCaseDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.WAREHOUSE: {
        this.jumpToPage('/piaMrtInnerWarehouseDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/piaMrtInnerExtendedAttributes');
        break;
      }
    }
  }

  jumpToPage(url: string) {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), PiaInnerSupplierAndHebDetailsComponent.BASE_VALIDATORS).subscribe(data => {
      this.innerCandidateError = this.supplierMrtService.setSupplierAndHebDetailsError(
        this.supplierMrtService.getInnerCandidate().candidateId,
        new CandidateError());
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
        {
          queryParams: {
            taskId: this.supplierMrtService.getTaskIdString(),
            candidateId: this.supplierMrtService.getInnerCandidate().candidateId
          }
        },
        this.hasVendorChanged());
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.innerCandidateError = this.supplierMrtService.setSupplierAndHebDetailsError(
          this.supplierMrtService.getInnerCandidate().candidateId, error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
          {
            queryParams: {
              taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId
            }
          },
          this.hasVendorChanged(), true);
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return;
    }
    this.isLoadingMatData = true;

    this.matUtilService.updateMatHierarchy(this.supplierMrtService.getInnerCandidateProduct(0)).pipe(
      tap((hasHierarchyChanges) => {
        this.supplierMrtService.hasCandidateInnerHierarchyUpdateChanges = hasHierarchyChanges;
      }),
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.supplierMrtService.getInnerCandidate(),
        this.supplierMrtService.candidateInnerGlobalAttributes, this.supplierMrtService.candidateInnerHierarchyAttributes)),
      finalize(() => this.isLoadingMatData = false)
    ).subscribe();
  }


  onHierarchyChange() {
    this.supplierMrtService.candidateInnerHierarchyAttributes = [];
    this.supplierMrtService.hasCandidateInnerHierarchyUpdateChanges = false;
    const candidateError = this.supplierMrtService.getInnerExtendedAttributesError(this.supplierMrtService.getInnerCandidate().candidateId);
    let candidateProductError;
    if (candidateError) {
      candidateProductError = candidateError.candidateProductErrors[this.supplierMrtService.getInnerCandidateProduct(0).id];
      this.matUtilService.updateMatHierarchyErrors(candidateProductError, this.supplierMrtService.candidateInnerGlobalAttributes,
        this.supplierMrtService.candidateInnerHierarchyAttributes);
      this.supplierMrtService.setInnerExtendedAttributesError(this.supplierMrtService.getInnerCandidate().candidateId, JSON.parse(JSON.stringify(candidateError)));
    }
  }
}
