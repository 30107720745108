import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  MaxShipCardModel,
  MaxShipCardPermissions,
  TextInputType
} from 'pm-models';

@Component({
  selector: 'pm-max-ship-card',
  templateUrl: './max-ship-card.component.html',
  styleUrls: ['./max-ship-card.component.scss']
})
export class MaxShipCardComponent implements OnInit {


  @Input()
  candidateModel: Candidate | MaxShipCardModel;

  @Input()
  permissions: MaxShipCardPermissions;

  @Input()
  candidateErrorModel: CandidateError;

  @Output() candidateModelChange = new EventEmitter<any>();

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive. ' +
      'This will apply to all warehouses.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  constructor() { }

  ngOnInit() {
  }

}
