import {Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, debounceTime, distinctUntilChanged, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {from, Observable, of, Subject} from 'rxjs';
import {AutoComplete} from 'primeng/autocomplete';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';

@Injectable({ providedIn: 'root' })
export class TypeaheadService {
  constructor(private http: HttpClient) {}

  search(query: string, url: string, exactSearch?: boolean) {
    const currentUrl = query ? url + '/search' : url;
    const currentParams = {};
    if (query) {
      currentParams['query'] = query;
    }
    if (exactSearch) {
      currentParams['exactSearch'] = exactSearch;
    }
    return this.http
      .get<any>(currentUrl, { params: currentParams })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}

@Component({
  selector: 'pm-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TypeaheadComponent implements OnInit {
  private static DEFAULT_STYLE_CLASS = '';
  private static ERROR_STYLE_CLASS = 'ui-input-invalid';

  // Component Id
  @Input() id: string;
  // Show/hide component
  @Input() showIf = true;
  // The display variable name (e.g. displayName)
  @Input() displayRef: string;
  // URL Path (if using async)
  @Input() url: string;
  // List to filter on (if not async)
  @Input() staticList;
  // Search Params
  @Input() params: any = {};
  // Time in milliseconds
  @Input() debounceTime: any = 300;

  // Label Name
  @Input() labelName: string;

  @Input() allowMultiple: boolean = false;

  // Placeholder
  @Input() placeholder: string;
  // Limit Search result size
  @Input() maxSearchSize = 5;

  // Override the label css class
  @Input() overrideLabelClass: string;
  // Override typeahead css class
  @Input() overrideTypeAheadWidth: string;

  // Object model that's acted upon selection.
  _model;
  @Input()
  get model() {
    return this._model;
  }

  set model(e) {
    // Only apply funcitonality if model changes.
    if (this._model !== e) {
      this._model = e;
      // Only apply ui updates after component is initialized.
      if (this.initialized) {
        this.updateUI(e);
        this.modelUpdated();
      }
    }
  }
  initialized = false;
  internalModelUpdate = false;

  displayModel: any;

  // Object model that's acted upon selection.
  @Input() isDisabled: boolean;
  @Input() isDropDown: boolean;
  // Is the field required.
  @Input() requiredField = false;
  // Is the field editable.
  @Input() canEdit = true;
  // The tab index.
  @Input() tabIndex: number;
  // Call init function.
  @Input() doInitCallback = false;
  // Id field of searched object.
  @Input() idRef = null;

  @Input() displaySubRef = null;
  @Input() showAddlInfo = false;

  // Whether to show tooltip or not.
  @Input() showTooltip = false;
  // Whether an error exists or not.
  @Input() hasError = false;

  // Emitter used when a selection is made.
  @Output() selection = new EventEmitter<any>();
  // Emitter used for initialization.
  @Output() initCallBack = new EventEmitter<any>();

  @Input() maxListSize = 10;

  @ViewChild('autoComplete') autoComplete: AutoComplete;

  @Input()
  showButtonOption = false;

  @Input()
  buttonLabel: string;

  @Output()
  buttonOptionClicked = new EventEmitter<any>();

  options = [];
  // Boolean for when search failed
  searchFailed = false;
  // Actual Css USed for Typeahead
  // typeAheadWidth: string;
  // Actual Css used for label.
  // labelClass: string;
  inputEvent: Event = new Event('input');
  private isSelected: boolean;
  private exactSearch = false;
  private querySubject = new Subject<any>();

  constructor(private service: TypeaheadService) {
    this.search = this.search.bind(this);
  }

  ngOnInit() {
    this.isSelected = false;

    // Apply initial values. If we are showing detailed selection or a pending value, we put it in an array.
    if (this.model) {
      this.updateUI(this.model);
    }
    this.querySubject.pipe(
      debounceTime(100),
      switchMap(value => {
        return of(value);
      })
    ).subscribe(value => this.searchQuery(value));

    this.initialized = true;

    if (!this.allowMultiple) {
      this.doInitSearch();
    } else {
      this.doInitMultiSearch();
    }
  }

  /**
   * If this component was passed a doInitCallback, and an ID reference, do an initial search.
   */
  private doInitSearch() {
    if (this.doInitCallback && this.idRef && this.model) {
      this.exactSearch = true;
      let query = '';
      if (typeof this.model === 'string') {
        if (CandidateUtilService.PENDING_ID_STRING === this.model) {
          return;
        }
        query = this.model;
      } else {
        if (CandidateUtilService.PENDING_ID_STRING === '' + this.model[this.idRef]) {
          return;
        }
        query = this.model[this.idRef];
      }
      this.search({ query: query });
    }
  }

  private doInitMultiSearch() {
    if (this.idRef && this.model && Array.isArray(this.model)) {
      const models = [];
      this.model.forEach(currentModel => {
        if (currentModel[this.idRef]) {
          models.push(currentModel);
        }
      });
      if (this.showAsync()) {
        this.apiMultiSearch(models);
      } else {
        this.staticMultiSearch(models);
      }
    }
  }

  private modelUpdated() {
    if (this.internalModelUpdate) {
      this.internalModelUpdate = false;
    } else {
      // Do initial search if it's an external update.
      if (this.allowMultiple) {
        this.doInitMultiSearch();
      } else {
        this.doInitSearch();
      }
    }
  }

  /**
   * Search function for ngb typeahead. Takes the passed in URL and Param object to search by Term in input field.
   * @param {Observable<string>} text$
   * @returns {Observable<any>}
   */
  apiSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      switchMap(query => {
        query = query && query.trim ? query.trim() : query;
        return this.service.search(query, this.url, this.exactSearch).pipe(
          tap(results => {
            this.searchFailed = false;
            if (this.exactSearch && results.length > 0) {
              this.findExactMatchInResults(results);
            }
            this.exactSearch = false;
          }),
          catchError(() => {
            this.searchFailed = true;
            this.exactSearch = false;
            return of([]);
          })
        );
      })
    )

  apiMultiSearch(models: any[]) {
    const searchResults = from(models).pipe(
      mergeMap(currentModel => {
        if (CandidateUtilService.PENDING_ID_STRING === '' + currentModel[this.idRef]) {
          return of([currentModel]);
        }

        let query =  currentModel[this.idRef];
        query = query && query.trim ? query.trim() : query;
        return this.service.search(query, this.url, true).pipe(
          map(results => {
            if (results.length > 0) {
              return this.findAndReturnExactMatchInResults(results);
            }
            return results;
          }),
          catchError(() => {
            return of([]);
          })
        );
      })
    );

    // Loop through results and update UI when done
    let allResults = [];
    searchResults.subscribe(
      results => {
        allResults = allResults.concat(results);
      },
      err => {},
      () => {
        this.updateUI(allResults);
      }
    );
  }

  staticMultiSearch(models: any[]) {
    const allResults = [];
    models.forEach(model => {
      if (CandidateUtilService.PENDING_ID_STRING === '' + model[this.idRef]) {
        allResults.push(model);
      } else {
        const foundItem = this.staticList.find(sl => {
          return sl[this.idRef] === model[this.idRef];
        });
        if (foundItem) {
          allResults.push(foundItem);
        }
      }
    });
    this.updateUI(allResults);
  }
  /**
   * Search function for ngb typeahead. Takes the passed in static array and Param object to search by Term in input field.
   * @param {Observable<string>} text$
   * @returns {Observable<any>}
   */
  staticSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      map(term => {
        term = term && term.toLocaleLowerCase ? term.toLocaleLowerCase() : term;
        return this.staticList.filter(v => v[this.displayRef].toLowerCase().indexOf(term) > -1).slice(0, this.maxListSize);
      })
    )

  showAsync() {
    return this.url !== undefined && this.url != null;
  }
  showStatic() {
    return this.staticList !== undefined && this.staticList !== null;
  }

  /**
   * After click, this formats the data into the input box.
   * @param {{description: string}} x
   * @returns {string}
   */
  formatter = x => x[this.displayRef];

  /**
   * Handle focus of type ahead.
   */
  onFocus($event) {
    setTimeout(() => {
      // this was added to fix a bug when user selects a value that re-opened the dropdown
      if (this.isSelected) {
        this.isSelected = false;
        return;
      }

      // this was added to handle a bug when show additional is false, but additional input is being added to search for
      // when user already has a value if user is searching for another value, and has a current value, clear the current value.
      if (!this.allowMultiple && this.showAddlInfo) {
        this.displayModel = null;
        this.model = null;
      }
      if (this.isDropDown) {
        this.autoComplete.handleDropdownClick(this.inputEvent);
      }
    });
  }

  /**
   * Handle clear of type ahead.
   */
  onClear() {
    this.internalModelUpdate = true;
    this.selection.emit(this.model);
    this.autoComplete.hide();
  }

  /**
   * Handle type ahead selection.
   * @param selectedItem Item that was selected.
   */
  onSelect(selectedItem: any) {
    // for multiselect, the autocomplete is rendering the button option value and displaying on the ui, since this is really just
    // a button, we need to manually remove this.
    if (selectedItem?.isButtonOptionSelected && Array.isArray(this.autoComplete?.value)) {
      this.autoComplete.value = this.autoComplete.value.filter(value => !value?.isButtonOptionSelected);
    }
    this.isSelected = true;
    this.internalModelUpdate = true;
    this.selection.emit(this.model);
    this.autoComplete.hide();
  }

  onModelChange($event) {
    // Flag model update is internal to prevent side effects.
    this.internalModelUpdate = true;
    if (!this.allowMultiple && Array.isArray($event)) {
      // If we are not allowing multiples and multiples are sent in.
      // We will need to get the last item of the array and make that the selected value
      const lastSelectedItem = $event.pop();
      if (lastSelectedItem.isButtonOptionSelected) {
        this.buttonOptionClicked.emit(lastSelectedItem);
        return;
      }
      this.model = lastSelectedItem;
    } else {
      // since a stub value is needed in the options array for the showButtonOption, ignore setting the model for the empty value
      if ($event && ($event.isButtonOptionSelected || (Array.isArray($event) && $event[$event.length - 1]?.isButtonOptionSelected))) {
        this.buttonOptionClicked.emit($event);
        return;
      }
      this.model = $event;
    }
  }

  /**
   * Directs the input to a static search or search by url.
   *
   * @param {Observable<string>} event
   * @returns {Observable<any>}
   */
  search(event) {
    if (event?.query === CandidateUtilService.PENDING_ID) {
      return;
    }
    this.querySubject.next(event.query);
  }

  searchQuery(text) {
    const queryObservable = of(text);
    if (this.showAsync()) {
      this.apiSearch(queryObservable).subscribe(results => {
        this.options = results;
        if (this.showButtonOption && this.buttonLabel) {
          this.options.push({isButtonOptionSelected: true});
        }
      });
    } else {
      this.staticSearch(queryObservable).subscribe(results => {
        this.options = results;
        if (this.showButtonOption && this.buttonLabel) {
          this.options.push({isButtonOptionSelected: true});
        }
      });
    }
  }

  /**
   * Checks if the input should be disabled.
   *
   * @returns {boolean}
   */
  isInputDisabled() {
    if (!this.isDisabled) {
      return false;
    } else {
      return true;
    }
  }

  private findExactMatchInResults(results: any[]) {
    const result = this.findAndReturnExactMatchInResults(results);
    if (result) {
      this.updateUI(result);
      this.initCallBack.emit(result);
    }
  }

  private findAndReturnExactMatchInResults(results: any[]) {
    for (let index = 0; index < results.length; index++) {
      if (!Array.isArray(this.model)) {
        const found = this.findMatchInObject(results, index, this.model);
        if (found) {
          return found;
        }
      } else {
        for (let modelIndex = 0; modelIndex < this.model.length; modelIndex++) {
          const found = this.findMatchInObject(results, index, this.model[modelIndex]);
          if (found) {
            return found;
          }
        }
      }
    }
  }

  private findMatchInObject(results, index, model) {
    if (!model) {
      return null;
    }
    if (results[index][this.idRef] === model[this.idRef] || results[index][this.idRef] === model) {
      return results[index];
    }
  }

  private updateUI(value) {
    if (this.allowMultiple) {
      this.displayModel = value;
    } else {
      if ((this.showAddlInfo || (this.showButtonOption && this.isValuePending(value)))
        && !Array.isArray(value) && value !== undefined && value !== null) {
        this.displayModel = [value];
      } else {
        this.displayModel = value;
      }
    }
  }

  /**
   * Returns the input style for the class. This will always return 'full-width', with an additional 'has-error' when there is an error.
   *
   * @returns {boolean}
   */
  getInputStyleClass() {
    return TypeaheadComponent.DEFAULT_STYLE_CLASS;
  }

  getStyleClass() {
    let styleClass = '';
    if (this.hasError) {
      styleClass = styleClass.concat(TypeaheadComponent.ERROR_STYLE_CLASS);
    }
    return styleClass;
  }
  /**
   * If the user has not selected a drop down item, then clear any text they may have entered.
   */
  onBlur() {
    if (typeof this.displayModel === 'string') {
      this.model = '';
      this.displayModel = '';
      this.selection.emit(null);
    }
  }

  onKeyUp() {
    // this was added to handle a bug when show additional is false, but additional input is being added to search for
    // when user already has a value if user is searching for another value, and has a current value, clear the current value.
    if (!this.allowMultiple && this.showAddlInfo) {
      this.displayModel = null;
      this.model = null;
    }
  }

  showDefaultOption(index) {
    // show every option if there's no button present in options or there's no label for the button provided.
    if (!this.showButtonOption || !this.buttonLabel) {
      return true;
    }
    // return true if there's an option list, and you're not on the option button (the last option).
    return this.options && index < this.options.length - 1;
  }

  isShowingButtonOption(index) {
    // never show create button option if flag is false or there's no create button label.
    if (!this.showButtonOption || !this.buttonLabel) {
      return false;
    }
    // return true if there's an option list, and you're on the option button (the last option).
    return this.options && index === this.options.length - 1;
  }

  /**
   * If it's allowMultiple, showAddlInfo, show multi select format for the selected value.
   * If it's showButtonOption && it doesn't have an id, show multiselect (for the pending value),
   * else show the normal p-autoComplete format.
   */
  showMultiSelectedFormat() {
    if (this.allowMultiple || this.showAddlInfo) {
      return true;
    }
    return this.showButtonOption && this.isPendingModel();
  }

  isPendingModel() {
    return this.isValuePending(this.model);
  }

  isValuePending(value) {
    if (!value) {
      return false;
    }
    return value.pending;
  }
}
