import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticOverlayPanelComponent } from './static-overlay-panel.component';

@NgModule({
  declarations: [StaticOverlayPanelComponent],
  exports: [StaticOverlayPanelComponent],
  imports: [CommonModule]
})
export class StaticOverlayPanelModule {}
