import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTypeaheadConfig } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class UnitOfMeasure implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'Unit of measure',
      description: `The unit of measure for the retail unit's total volume.`,
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: 'uomId',
      displayRef: 'description',
      placeholderText: 'Select measurement...',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.unitOfMeasure;
    componentInstance.selection.subscribe(x => {
      candidate.unitOfMeasure = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.unitOfMeasure) {
      componentInstance.model = candidate.unitOfMeasure;
    }
  }
}
