import {Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output} from '@angular/core';
import {CandidateError} from 'pm-models';

@Component({
  selector: 'app-pia-bonus-stepper',
  templateUrl: './pia-bonus-stepper.component.html',
  styleUrls: ['./pia-bonus-stepper.component.scss']
})
export class PiaBonusStepperComponent implements OnInit {

  private errorCandidateDiffer: KeyValueDiffer<string, any>;
  private errorCandidateProductDiffer: KeyValueDiffer<string, any>;

  private readonly errorToolTip = 'This page contains errors or is missing information in required fields.';
  private readonly errorIcon = 'warning';

  public static readonly UPC_ITEM_CODE = 'UPC/Item code';
  public static readonly BONUS_DETAILS = 'Bonus details';
  public static readonly REPLACEMENT_DETAILS = 'Replacement details';
  public static readonly CASE_DETAILS = 'Case details';
  public static readonly WAREHOUSE_SELECTION = 'Warehouse selection';
  public static readonly EXTENDED_ATTRIBUTES = 'Extended attributes';

  @Input()
  candidateErrors: CandidateError[];

  @Input()
  isSellable: boolean;

  @Input()
  stepIndex: number;

  @Input()
  isReplacementUpc: Boolean;

  @Input()
  steps;

  @Output() indexClick = new EventEmitter<any>();

  modelSteps;

  constructor(private differs: KeyValueDiffers) {}

  ngOnInit() {
    this.modelSteps = this.steps;
  }

  /**
   * Change detection that compares candidate errors with previous version.
   * If the candidate differ doesn't exist, it creates it
   * Runs apply errors if any changes have been detected
   * @memberof NewWarehouseProductStepperComponent
   */
  ngDoCheck(): void {
    let candidateHasChanges = false;

    if (this.errorCandidateDiffer) {
      candidateHasChanges = this.errorCandidateDiffer.diff(this.candidateErrors) ? true : false;
    } else if (this.candidateErrors) {
      this.errorCandidateDiffer = this.differs.find(this.candidateErrors).create();
    }

    if (candidateHasChanges) {
      this.applyErrors();
    }
  }

  /**
   * Runs through each step of menu and runs validation on each step
   *
   * @memberof NewWarehouseProductStepperComponent
   */
  applyErrors() {
    this.modelSteps.forEach((x, i) => {
      switch (i) {
        case 0:
          this.validateUPCItemCode(x, this.candidateErrors[i]);
          break;
        case 1:
          this.validateBonusDetails(x, this.candidateErrors[i]);
          break;
        case 2:
          this.validateCaseDetails(x, this.candidateErrors[i]);
          break;
        case 3:
          this.validateWarehouses(x, this.candidateErrors[i]);
          break;
        case 4:
          this.validateExtendedAttributes(x, this.candidateErrors[i]);
          break;
      }
    });
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof NewWarehouseProductStepperComponent
   */
  validateUPCItemCode(stepItem, candidateErrors) {
    const candidateKeys = ['description', 'warehouseSwitch', 'dsdSwitch', 'productType'];
    const candidateProductKeys = ['upc'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, [], candidateErrors);
  }

  /**
   * Vaidation for Supplier Setup
   *
   * @param {*} stepItem - Menu Item
   * @memberof NewWarehouseProductStepperComponent
   */
  validateBonusDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'packageType',
      'productHeight',
      'productLength',
      'productWeight',
      'productWidth',
      'retailSize',
      'totalVolume',
      'unitOfMeasure',
      'season',
      'seasonYear'];

    const candidateProductKeys = [
      'upc',
      'upcCheckDigit',
      'attachments'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, [], candidateErrors);
  }

  validateProductDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'merchandiseType',
      'commodity',
      'subCommodity',
      'packageType',
      'productWidth',
      'productHeight',
      'productLength',
      'productWeight',
      'unitOfMeasure',
      'retailSize',
      'totalVolume'
    ];

    const candidateProductKeys = [
      'imageLinks',
      'termsAndConditions'
    ];

    if (this.isSellable) {
      candidateKeys.push('suggestedXFor');
      candidateKeys.push('suggestedRetailPrice');
      candidateKeys.push('mapRetail');

      candidateProductKeys.push('description');
      candidateProductKeys.push('customerFriendlyDescription1');
      candidateProductKeys.push('customerFriendlyDescription2');
      candidateProductKeys.push('romanceCopy');
    }
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, [], candidateErrors);
  }

  validateCaseDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'itemWeightType',
      'maxShelfLife',
      'inboundSpecDays',
      'guaranteeToStoreDays',
      'warehouseReactionDays',
      'masterPack',
      'masterWidth',
      'masterHeight',
      'masterLength',
      'masterWeight',
      'cubeAdjustedFactor',
      'vendorTie',
      'vendorTier',
      'innerPackSelected',
      'innerPack',
      'innerWidth',
      'innerHeight',
      'innerLength',
      'innerWeight',
      'displayReadyUnit',
      'displayReadyUnitRowsDeep',
      'displayReadyUnitRowsHigh',
      'displayReadyUnitRowsFacing',
      'displayReadyUnitOrientation',
      'costLink',
      'masterListCost',
      'innerListCost',
      'costOwner'];
    const candidateProductKeys = [
      'caseUpc',
      'caseDescription',
      'vendorProductCode',
      'countryOfOrigin',
      'attachments',
      'imported',
      'containerSize',
      'incoTerms',
      'pickupPoint',
      'dutyPercent',
      'dutyConfirmed',
      'dutyInfo',
      'minimumOrderQty',
      'minimumOrderDesc',
      'hts1',
      'hts2',
      'hts3',
      'agentPercent',
      'cartonMarking',
      'productColor',
      'prorationDate',
      'inStoreDate',
      'whsFlushDate',
      'freightConfirmedDate',
      'factory',
      'remark1',
      'remark2'];

    this.validateStep(stepItem, candidateKeys, candidateProductKeys, [], candidateErrors);
  }

  validateWarehouses(stepItem, candidateErrors) {
    const candidateProductKeys = ['missingWarehouses'];
    const warehouseKeys = ['maxShip', 'orderRestriction', 'orderUnit'];

    this.validateStep(stepItem, [], candidateProductKeys, warehouseKeys, candidateErrors);

  }

  validateExtendedAttributes(stepItem, candidateErrors) {
    this.validateStep(stepItem, [], [], [], candidateErrors, true);
  }

  /**
   * Validates step with associated keys, applies icon and error message if found
   *
   * @private
   * @param {*} stepItem - Menu Items
   * @param {*} candidateKeys - Candidate Error Keys associated with this step
   * @param {*} candidateProductKeys - Candidate Product Error Keys associated with this step
   * @memberof NewWarehouseProductStepperComponent
   */
  private validateStep(stepItem, candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!this.validateKeys(candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, validateMatAttributes)) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    } else {
      stepItem.icon = undefined;
      stepItem.message = undefined;
    }
  }

  /**
   * Checks each batch of keys to see if candidateErrors or candidate Product errors
   * contain any values. Immediate return false if error found for performance.
   *
   * @private
   * @param {*} candidateKeys
   * @param {*} candidateProductKeys
   * @param {*} warehouseKeys
   * @param {*} candidateErrors
   * @returns
   * @memberof NewWarehouseProductStepperComponent
   */
  private validateKeys(candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, validateMatAttributes?: boolean) {
    for (let i = 0; i < candidateKeys.length; i++) {
      if (this.candidateErrors && this.fieldHasValue(candidateErrors[candidateKeys[i]])) {
        return false;
      }
    }
    for (let i = 0; i < candidateProductKeys.length; i++) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && this.fieldHasValue(value[candidateProductKeys[i]])) {
            return false;
          }
        }
      }
    }
    for (let i = 0; i < warehouseKeys.length; i++) {
      if (!candidateErrors.candidateProductErrors) {
        return true;
      }
      for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
        for (const [candidateProductKey, candidateProductValue] of Object.entries(value)) {
          if (candidateProductKey === 'warehouseErrors') {
            for (const [warehouseKey, warehouseValue] of Object.entries(candidateProductValue)) {
              if (warehouseValue && this.fieldHasValue(warehouseValue[warehouseKeys[i]])) {
                return false;
              }
            }
          }
        }
      }
    }

    if (validateMatAttributes) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && value['matAttributeErrors'] &&  Object.entries(value['matAttributeErrors']).length) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * Takes  a property and immediately checks if it has any sort of value
   * returns 'true' if any value is found or present.
   * @private
   * @param {*} field
   * @returns
   * @memberof NewWarehouseProductStepperComponent
   */
  private fieldHasValue(field) {
    return field !== undefined && field !== null && field !== '';
  }
  onIndexClick(event: any) {
    this.indexClick.emit(event);
  }
}
