import {Component, OnInit} from '@angular/core';
import {
  AttributeConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  MaxShipCardPermissions,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {PiaMrtInnerStepperComponent} from '../pia-mrt-inner-stepper/pia-mrt-inner-stepper.component';
import {NgxPermissionsService} from 'ngx-permissions';
import {GrowlService} from '../../growl/growl.service';

@Component({
  selector: 'app-pia-mrt-inner-warehouse',
  templateUrl: './pia-mrt-inner-warehouse.component.html',
  styleUrls: ['./pia-mrt-inner-warehouse.component.scss']
})
export class PiaMrtInnerWarehouseComponent implements OnInit {


  private taskSubscription$: any;
  public innerCandidateError: CandidateError;
  public innerCandidateProductError: CandidateProductError;
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public warehouses = [];
  public warehouseData = [];
  public orderRestrictions: any[];
  public canClickNext = true;
  public showMatAttributes = false;

  readonly maxShipCardPermissions: MaxShipCardPermissions = {
    isReadOnly: false
  };


  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private lookupService: LookupService,
              public candidateUtilService: CandidateUtilService, private permissionService: NgxPermissionsService,
              private growlService: GrowlService) { }

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {

        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId()  ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          // if the candidate is an mrt, and contains the inner set the inner candidate.
          if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).subscribe((innerCandidate) => {
              this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
              this.initializeData();
            });
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierMrtService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {

    this.innerCandidateError = this.supplierMrtService.getInnerWarehouseError(this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.innerCandidateError) {
      this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
        this.supplierMrtService.getInnerCandidateProduct(0).id];
    }
    this.getWarehouses(this.supplierMrtService.getInnerCandidate());
    this.getOrderRestrictions();
    this.supplierMrtService.setInnerCandidates().subscribe();
    this.showMatAttributes = !!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT');
  }

  onClose() {
    this.supplierMrtService.onInnerClose();
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    if (!candidate.vendor  || !candidate.lane) {
      return;
    }
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    if (this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId ===
            +this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({},
              this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses[y],
              JSON.parse(JSON.stringify(this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }


  onClickBack() {
    this.jumpBack('/piaInnerCaseDetails');
  }

  onClickNext() {
    if (this.showMatAttributes) {
      this.onClickNextWithExtendedAttributes();
    } else {
      this.onClickNextNoExtendedAttributes();
    }
  }

  onClickNextWithExtendedAttributes() {
    this.canClickNext = false;
    this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses = this.getSelectedWarehouses();

    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), [CandidateValidatorType.WAREHOUSE_VALIDATOR]).subscribe(data => {
      this.supplierMrtService.setInnerWarehouseError(this.supplierMrtService.getInnerCandidate().candidateId, null);
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaMrtInnerExtendedAttributes',
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
            candidateId: this.supplierMrtService.getInnerCandidate().candidateId}}, false);
    }, (error) => {
      // set the errors on the page and save.
      if (error.error?.candidateErrors?.hasErrors) {
        this.innerCandidateError =
          this.supplierMrtService.setInnerWarehouseError(this.supplierMrtService.getInnerCandidate().candidateId, error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaMrtInnerExtendedAttributes',
          {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId}}, false);
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  onClickNextNoExtendedAttributes() {
    this.canClickNext = false;
    this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses = this.getSelectedWarehouses();
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(),
      [CandidateValidatorType.WAREHOUSE_VALIDATOR]).subscribe(() => {
      this.supplierMrtService.setInnerWarehouseError(this.supplierMrtService.getInnerCandidate().candidateId, null);
      this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(),
        [CandidateValidatorType.PIA_INNER_PRODUCT_DETAILS_VALIDATOR,
          CandidateValidatorType.PIA_MRT_INNER_CASE_DETAILS_VALIDATOR,
          CandidateValidatorType.SUPPLIER_HEADER_VALIDATOR]).toPromise().then(() => {
        if (this.supplierMrtService.validateOtherInnerCandidates()) {
          this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaMrtCaseDetails',
            {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
                candidateId: this.supplierMrtService.getInnerCandidate().candidateId}}, false, true);
        } else {
          // if there's errors on other candidates, navigate to setup inners page.
          this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaSetupNewMrtItems',
            {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}}, false);
        }
        // if there's errors on the other pages, continue to setup inners page.
      }, (error) => {
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaSetupNewMrtItems',
          {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}}, false, true);
      });
    }, (error) => {
      this.supplierMrtService.scrollToTop();
      // set the errors on the page and save.
      if (error.error.candidateErrors.hasErrors) {
        this.innerCandidateError = this.supplierMrtService.setInnerWarehouseError(this.supplierMrtService.getInnerCandidate().candidateId,
          error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id] ;
      }
      this.supplierMrtService.saveInnerCandidate(this.supplierMrtService.getInnerCandidate());
      this.canClickNext = true;
    });
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaMrtInnerStepperComponent.SUPPLIER_HEB_SETUP: {
        this.jumpBack('/piaSupplierAndHebDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.PRODUCT_DETAILS: {
        this.jumpBack('/piaInnerProductDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.CASE_PACK: {
        this.jumpBack('/piaAddNewMrtCase');
        break;
      }
      case PiaMrtInnerStepperComponent.CASE_DETAILS: {
        this.onClickBack();
        break;
      }
      case PiaMrtInnerStepperComponent.EXTENDED_ATTRIBUTES: {
        this.onClickNextWithExtendedAttributes();
        break;
      }
    }
  }

  jumpBack(urlToNavigate) {
    this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses = this.getSelectedWarehouses();
    this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), urlToNavigate,
      {
        queryParams: {
          taskId: this.supplierMrtService.getTaskIdString(),
          candidateId: this.supplierMrtService.getInnerCandidate().candidateId
        }
      }, false, this.supplierMrtService.hasInnerChanged());
  }
}
