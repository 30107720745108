import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalInputComponent } from './decimal-input.component';

import { FormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import {SharedModule} from "../../../../../../src/app/2.0.0/shared/shared.module";

@NgModule({
  providers: [CurrencyPipe],
  declarations: [DecimalInputComponent],
  exports: [DecimalInputComponent, FormsModule, InputTextModule],
    imports: [
        CommonModule,
        FormsModule,
        InputTextModule,
        SharedModule
    ]
})
export class DecimalInputModule { }
