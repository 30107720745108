import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper, VisiblePermissionsMapper} from '../attribute-permissions-mapper';

import {
  Candidate,
  ProductPricingCardModel,
  ProductPricingCardPermissions,
  ProductPricingCardErrors,
  AttributeConfig,
  AttributeTextInputConfig,
  TextInputType, AttributeDualInputConfig
} from 'pm-models';

@Component({
  selector: 'pm-product-pricing-card',
  templateUrl: './product-pricing-card.component.html',
  styleUrls: ['./product-pricing-card.component.scss']
})
export class ProductPricingCardComponent implements OnInit {
  @Input()
  model: ProductPricingCardModel | Candidate;

  @Input()
  permissions: ProductPricingCardPermissions;

  @Input()
  errorModel: ProductPricingCardErrors;

  @Input()
  onlyShowSecondInputSuggestedRetail: boolean;

  @Output() modelChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (this.model && this.model.prePrice === undefined) {
      this.model.prePrice = false;
    }
  }

  prePriceConfiguration: AttributeConfig = {
    label: 'Visible on packaging (pre-price)',
    description: 'Recommend a quantity and a retail price for selling this product.',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.prePrice),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.prePrice),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.prePrice),
    name: 'prePriceId',
    defaultValue: false
  };

  prePriceChange(event) {
    this.modelChange.emit(this.model);
  }

  getSuggestedRetailConfiguration() {
    const suggestedRetailConfiguration: AttributeDualInputConfig = {
      collections: [],
      description: 'Enter the recommended price for this product.',
      label: 'Suggested retail',
      name: 'suggestedRetailId',
      options: [],
      isRequired: true,
      input1DecimalCount: 0,
      input1NumberCount: 3,
      input1Placeholder: '#',
      input2DecimalCount: 2,
      input2Placeholder: '$',
      input2NumberCount: 9,
      separatingText: 'for',
      isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.suggestedRetailPrice),
      isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.suggestedRetailPrice),
      onlyShowSecondInput: this.onlyShowSecondInputSuggestedRetail ? this.onlyShowSecondInputSuggestedRetail : false
    };

    return suggestedRetailConfiguration;
  }


  suggestedRetailChange(event) {
    this.modelChange.emit(this.model);
  }
  mapPricingConfiguration: AttributeTextInputConfig = {
    label: 'MAP retail',
    description: 'Enter the lowest price H-E-B can advertise for this product.',
    isRequired: false,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.mapRetail),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.mapRetail),
    textInputType: TextInputType.decimal,
    placeholderText: '$',
    inputGroupClass: 'ui-narrow-input',
    numberCount: 9,
    decimalCount: 2,
    name: 'mapRetailId',
  };

  mapPricingChange(event) {
    this.modelChange.emit(this.model);
  }
}
