import {Injectable} from '@angular/core';
import {EditCandidateModalService} from './edit-candidate-modal.service';
import {CandidateService} from './candidate.service';
import {Candidate, CandidateProduct} from 'pm-models';
import {UserRoleConstants} from '../core/header/user-role-constants';
import {CandidateUtilService} from './candidate-util.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateHistoryService {

  private oldSupplierCandidateFound: boolean;
  private oldBuyerCandidateFound: boolean;
  private oldScaCandidateFound: boolean;
  private candidate: Candidate;
  private candidateProduct: CandidateProduct;
  private lastSupplierChangedCandidate: Candidate;
  private lastSupplierChangedCandidateProduct: CandidateProduct;
  private lastBuyerChangedCandidate: Candidate;
  private lastBuyerChangedCandidateProduct: CandidateProduct;
  private lastScaChangedCandidate: Candidate;
  private lastScaChangedCandidateProduct: CandidateProduct;
  private candidateProductIndex: number;

  public candidateAudits: any = undefined;
  public candidateProductAudits: any = undefined;
  public currentHistoryResults: any = undefined;
  private oldBuyerCandidateSearched: boolean = false;
  private oldSupplierCandidateSearched: boolean = false;
  private oldScaCandidateSearched: boolean = false;

  constructor( public editCandidateModalService: EditCandidateModalService,
               private candidateService: CandidateService) { }

  setInitialValues(upToDateCandidate) {
    this.candidate = JSON.parse(JSON.stringify(upToDateCandidate));
    this.candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(this.candidate);
    this.candidateProduct = this.candidate.candidateProducts[this.candidateProductIndex];
    this.setLastSupplierChangedCandidate(upToDateCandidate);
    this.setLastBuyerChangedCandidate(upToDateCandidate);
    this.setLastScaChangedCandidate(upToDateCandidate);
  }

  /**
   * Sets the last supplier saved candidate object.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  async setLastSupplierChangedCandidate(candidate: Candidate) {
    this.oldSupplierCandidateSearched = true;
    this.candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(candidate);
    if (candidate.role) {
      await this.candidateService.getLatestCandidate(candidate.candidateId, UserRoleConstants.VENDOR_ROLE).subscribe((results) => {
        if (results) {
          this.oldSupplierCandidateFound = true;
          this.lastSupplierChangedCandidate = results;
          this.lastSupplierChangedCandidate = JSON.parse(JSON.stringify(this.lastSupplierChangedCandidate));
          this.lastSupplierChangedCandidateProduct = this.lastSupplierChangedCandidate.candidateProducts[this.candidateProductIndex];
        } else {
          this.oldSupplierCandidateFound = false;
        }
      });
    } else {
      // Since older candidates will not have a role, lastSupplierChangedCandidate needs to be set to avoid html errors
      this.lastSupplierChangedCandidate = candidate;
      this.lastSupplierChangedCandidate = JSON.parse(JSON.stringify(this.lastSupplierChangedCandidate));
      this.lastSupplierChangedCandidateProduct = this.lastSupplierChangedCandidate.candidateProducts[this.candidateProductIndex];
    }
  }

  hasSupplierValueChangedForCandidate(candidate: Candidate, objectName: string, subObjectName?: string) {
    if (!this.oldSupplierCandidateSearched) {
      this.setLastSupplierChangedCandidate(candidate).then();
    }
    if (!this.oldSupplierCandidateFound) {
      return false;
    }

    if (candidate[objectName] == null || (subObjectName && candidate[objectName][subObjectName] === undefined )) {
      // can't do compare
      return;
    } else if (subObjectName &&
      this.lastSupplierChangedCandidate !== undefined &&
      this.lastSupplierChangedCandidate[objectName] !== undefined &&
      this.lastSupplierChangedCandidate[objectName][subObjectName] !== undefined) {
      return candidate[objectName][subObjectName]  !== this.lastSupplierChangedCandidate[objectName][subObjectName];
    } else {
      return candidate[objectName]  !== this.lastSupplierChangedCandidate[objectName];
    }
  }

  hasSupplierValueChangedForCandidateProduct(candidateProduct: CandidateProduct, objectName: string, subObjectName?: string) {
    if (!this.oldSupplierCandidateFound) {
      return false;
    }

    if (subObjectName &&
      this.lastSupplierChangedCandidateProduct !== undefined &&
      this.lastSupplierChangedCandidateProduct[objectName] !== undefined &&
      this.lastSupplierChangedCandidateProduct[objectName][subObjectName] !== undefined) {
      return candidateProduct[objectName][subObjectName]  !== this.lastSupplierChangedCandidateProduct[objectName][subObjectName];
    } else {
      return candidateProduct[objectName]  !== this.lastSupplierChangedCandidateProduct[objectName];
    }
  }

  /**
   * Sets the buyer last saved candidate object.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setLastBuyerChangedCandidate(candidate: Candidate) {
    this.oldBuyerCandidateSearched = true;
    if (candidate.role) {
      this.candidateService.getLatestCandidate(candidate.candidateId, UserRoleConstants.BUYER_ROLE).subscribe((results) => {
        if (results) {
          this.oldBuyerCandidateFound = true;
          this.lastBuyerChangedCandidate = results;
          this.lastBuyerChangedCandidate = JSON.parse(JSON.stringify(this.lastBuyerChangedCandidate));
          this.lastBuyerChangedCandidateProduct = this.lastBuyerChangedCandidate.candidateProducts[this.candidateProductIndex];
        } else {
          this.oldBuyerCandidateFound = false;
        }
      });
    } else {
      // Since older candidates will not have a role, lastBuyerChangedCandidate needs to be set to avoid html errors
      this.lastBuyerChangedCandidate = candidate;
      this.lastBuyerChangedCandidate = JSON.parse(JSON.stringify(this.lastBuyerChangedCandidate));
      this.lastBuyerChangedCandidateProduct = this.lastBuyerChangedCandidate.candidateProducts[this.candidateProductIndex];
    }
  }

  hasBuyerValueChangedForCandidate(candidate: Candidate, objectName: string, subObjectName?: string) {
    if (!this.oldBuyerCandidateSearched) {
      this.setLastBuyerChangedCandidate(candidate);
    }
    if (!this.oldBuyerCandidateFound || !candidate[objectName]) {
      return false;
    }

    if (subObjectName && this.lastBuyerChangedCandidate[objectName] !== undefined &&
      this.lastBuyerChangedCandidate[objectName][subObjectName] !== undefined &&
      candidate[objectName][subObjectName] !== undefined) {
      return candidate[objectName][subObjectName]  !== this.lastBuyerChangedCandidate[objectName][subObjectName];
    } else {
      return candidate[objectName]  !== this.lastBuyerChangedCandidate[objectName];
    }
  }

  /**
   * Sets the buyer last saved candidate object.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setLastScaChangedCandidate(candidate: Candidate) {
    this.oldScaCandidateSearched = true;
    if (candidate.role) {
      this.candidateService.getLatestCandidate(candidate.candidateId, UserRoleConstants.SUPPLY_CHAIN_ANALYST_ROLE).subscribe((results) => {
        if (results) {
          this.oldScaCandidateFound = true;
          this.lastScaChangedCandidate = results;
          this.lastScaChangedCandidate = JSON.parse(JSON.stringify(this.lastScaChangedCandidate));
          this.lastScaChangedCandidateProduct = this.lastScaChangedCandidate.candidateProducts[this.candidateProductIndex];
        } else {
          this.oldScaCandidateFound = false;
        }
      }, error => {
        console.error('setLastScaChangedCandidate: ' + error);
      });
    } else {
      // Since older candidates will not have a role, lastBuyerChangedCandidate needs to be set to avoid html errors
      this.lastScaChangedCandidate = candidate;
      this.lastScaChangedCandidate = JSON.parse(JSON.stringify(this.lastScaChangedCandidate));
      this.lastScaChangedCandidateProduct = this.lastScaChangedCandidate.candidateProducts[this.candidateProductIndex];
    }
  }

  hasScaValueChangedForCandidate(candidate: Candidate, objectName: string, subObjectName?: string) {
    if (!this.oldScaCandidateSearched) {
      this.setLastScaChangedCandidate(candidate);
    }
    if (!this.oldScaCandidateFound) {
      return false;
    }

    if (subObjectName && this.lastScaChangedCandidate[objectName] !== undefined &&
      this.lastScaChangedCandidate[objectName][subObjectName] !== undefined) {
      return candidate[objectName][subObjectName]  !== this.lastScaChangedCandidate[objectName][subObjectName];
    } else {
      return candidate[objectName]  !== this.lastScaChangedCandidate[objectName];
    }
  }

  hasScaValueChangedForWarehouse(candidateProduct: CandidateProduct, objectName: string, subObjectName?: string) {
    if (!this.oldScaCandidateFound) {
      return false;
    }
    let warehouses: any;
    if (this.lastScaChangedCandidate && this.lastScaChangedCandidateProduct.warehouses[0]) {
      warehouses = this.lastScaChangedCandidateProduct.warehouses[0];
    }

    if (!warehouses) {
      return false;
    }

    if (subObjectName && this.lastScaChangedCandidate && warehouses[objectName] !== undefined &&
      warehouses[objectName][subObjectName] !== undefined) {
      return candidateProduct.warehouses[0][objectName][subObjectName]  !==
        warehouses[objectName][subObjectName];
    } else {
      return candidateProduct.warehouses[0][objectName]  !== warehouses[objectName];
    }
  }

  hasSupplierValueChangedForCostOwner(candidate: Candidate) {
    if (!this.oldSupplierCandidateSearched) {
      this.setLastSupplierChangedCandidate(candidate).then();
    }
    if (!this.oldSupplierCandidateFound) {
      return false;
    }
    return candidate?.costOwner?.costOwnerId !== this.lastSupplierChangedCandidate?.costOwner?.costOwnerId;
  }

  hasSupplierValueChangedForTopToTop(candidate: Candidate) {
    if (!this.oldSupplierCandidateSearched) {
      this.setLastSupplierChangedCandidate(candidate).then();
    }
    if (!this.oldSupplierCandidateFound) {
      return false;
    }
    return candidate?.costOwner?.topToTopId !== this.lastSupplierChangedCandidate?.costOwner?.topToTopId;
  }

  historyPanelOpen() {
    this.candidateService.candidateAudit(this.candidate.candidateId).subscribe((result) => {
      this.candidateAudits = result.auditRecords;
      this.candidateProductAudits = result.candidateProductAudits;
      // these list are combined and sorted because the business doesn't want the user to be aware of the difference in
      // candidate and candidate product level fields
      const tempArray: [] = this.candidateAudits.concat(this.candidateProductAudits);
      this.currentHistoryResults = tempArray.sort((val1, val2) => val2 ['changedOn'] - val1  ['changedOn']);
    });
  }

  historyPanelClose() {
    this.currentHistoryResults = undefined;
  }

  showCandidateAudits(candidateAudits: any) {
    if ((candidateAudits.changedFrom.includes('Uninitialized') && candidateAudits.changedTo.includes('null')) ||
      (candidateAudits.changedFrom.includes('Uninitialized') && candidateAudits.changedTo.includes('[]'))) {
      return false;
    }
    if (candidateAudits.changedFrom.includes('[]')) {
      return false;
    }
    if (candidateAudits.changedTo.includes('Optional[com.heb.pm.candidate')) {
      return false;
    }
    if (candidateAudits.changedTo.includes('Optional[com.heb.pm.api')) {
      return false;
    }
    return true;
  }

  displayAudit(auditChange: String) {
    let toReturn: string;
    if (auditChange !== undefined && auditChange.includes('Optional[]')) {
      return '-';
    }
    // Candidate api is designed to return just the description/name of the models, this is a safeguard
    if (auditChange !== undefined && auditChange.includes('Optional[')) {
      toReturn = auditChange.replace('Optional[', '').replace(']', '');
      if (auditChange.includes('description=')) {
        return toReturn.split('description=')[1].replace('}', '');
      } else if (auditChange.includes('name=')) {
        return toReturn.split('name=')[1].replace('}', '');
      }
      return toReturn;
    } else {
      return auditChange;
    }
  }

}
