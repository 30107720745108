import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ContentChild,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';

import { Candidate, CandidateProduct } from 'pm-models';
import { TitleComponent, SidebarComponent, FooterComponent } from '../../shared/common';
import { DrawerComponent } from '../../drawer/drawer.component';

@Component({
  selector: 'pm-review-drawer-container',
  template: '<ng-content></ng-content>'
})
export class ReviewDrawerContainerComponent {}

@Component({
  selector: 'pm-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReviewComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  @ContentChild(TitleComponent) headerFacet;
  @ContentChild(FooterComponent) footerFacet;
  @ContentChild(ReviewDrawerContainerComponent) drawerContainerFacet;

  @ViewChild(DrawerComponent) drawer: DrawerComponent;
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}
  opened: boolean = false;

  public openDrawer() {
    this.drawer.open();
    this.cd.detectChanges();
  }
  public closeDrawer() {
    this.drawer.close();
    this.cd.detectChanges();
  }
}
