import {AfterViewInit, Component, ViewEncapsulation} from '@angular/core';
import {MessageService} from 'primeng/api';
import {GrowlService} from './growl.service';

/**
 * This component subscribes to the streams of different types of message to the growl message array
 *
 * @author s793785
 * @since 1.0.0
 */
@Component({
  selector: 'app-growl',
  templateUrl: './growl.component.html',
  styleUrls: ['./growl.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class GrowlComponent implements AfterViewInit {

  private static ENDLESS_LIFE = 999999999;

  constructor(private messageService: MessageService, private growlService: GrowlService) {}

  ngAfterViewInit(): void {
    this.growlService.errorMessage$.subscribe(
      message => {
        this.messageService.add({severity: 'error', summary: 'Error Message', detail: message, life: GrowlComponent.ENDLESS_LIFE});
      }
    );

    this.growlService.successMessage$.subscribe(
      message => {
        this.messageService.add({severity: 'success', summary: 'Success Message', detail: message});
      }
    );

    this.growlService.warnMessage$.subscribe(
      message => {
        this.messageService.add({severity: 'warn', summary: 'Warning Message', detail: message});
      }
    );

    this.growlService.infoMessage$.subscribe(
      message => {
        this.messageService.add({severity: 'info', summary: 'Info Message', detail: message});
      }
    );

    this.growlService.clearMessage$.subscribe(
      () => {
        this.messageService.clear();
      }
    );
  }

  isError(message) {
    return (message.severity === 'error'
      || message.severity === 'warn'
      || message.severity === 'info');
  }

  isSuccess(message) {
    return (message.severity === 'success' );
  }
}

