<ng-container>
  <div class="mb-1">
    <label>Where are you sending this product?</label>
  </div>
  <div class="col-md-10">
    <div class="row">
      <div class="col-md-4 pl-0 ">
        <div [ngClass]="isWarehouseDisabledOrReadOnly() ? 'distribution-channel distribution-channel-disabled': 'distribution-channel'">
          <div class="row m-0">
            <p-radioButton [id]="'warehouseButton'"
                           class="attribute-radio"
                           [(ngModel)]="channel"
                           value="whs"
                           (onClick)="onClickWarehouse()"
                           (ngModelChange)="onChange($event)"
                           [disabled]="isWarehouseDisabledOrReadOnly()">
            </p-radioButton>
            <div>
              <label>Warehouses</label>
            </div>
          </div>
          <p class="attribute-description ml-4">
            Use H-E-B distribution.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div [ngClass]="isDsdDisabledOrReadOnly() ? 'distribution-channel distribution-channel-disabled': 'distribution-channel'">
          <div class="row m-0">
            <p-radioButton [id]="'dsdButton'"
                           class="attribute-radio"
                           [(ngModel)]="channel"
                           value="dsd"
                           (onClick)="onClickDsd()"
                           (ngModelChange)="onChange($event)"
                           [disabled]="isDsdDisabledOrReadOnly()">
            </p-radioButton>
            <div>
              <label>Stores (DSD)</label>
            </div>
          </div>
          <p class="attribute-description ml-4">
            Deliver to stores directly.
          </p>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <p *ngIf="errorModel && errorModel.warehouseSwitch" class="attribute-error-message">
        {{errorModel.warehouseSwitch}}
      </p>
      <p *ngIf="errorModel && errorModel.dsdSwitch" class="attribute-error-message">
        {{errorModel.dsdSwitch}}
      </p>
    </div>
  </div>
</ng-container>
