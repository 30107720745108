<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">
  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label">
      <label for="">{{attribute.label}}</label>
    </div>

    <div class="attribute-control">
      <div class="ui-inputgroup" [ngClass]="{'ui-multisource-invalid' : attributeError}">
        <p-dropdown [options]="attribute.options" [(ngModel)]="selectCategory"
          [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
          [disabled]="attribute.isDisabled && attribute.isDisabled()" placeholder="">
        </p-dropdown>
        <input type="number" pInputText [(ngModel)]="model" [ngModelOptions]="{updateOn:'blur'}"
          [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
          [disabled]="attribute.isDisabled && attribute.isDisabled()" (ngModelChange)="updateModel($event)"
          [placeholder]="attribute.placeholderText"
          (blur)="validateModel($event)">
        <span class="ui-inputgroup-addon"
          *ngIf="!(attribute.isReadOnly && attribute.isReadOnly()) && !(attribute.isDisabled && attribute.isDisabled())">
          <i class="pi pi-spin pi-spinner" *ngIf="state===1"></i>
          <i class="material-icons ui-success" *ngIf="state === 2">check_circle_outline</i>
          <i class="material-icons ui-invalid rotate-45" *ngIf="state === 3">add_circle_outline</i>
        </span>
      </div>

      <p *ngIf="attribute.description" class="attribute-description">

        {{attribute.description}}
      </p>
      <p *ngIf="attributeError" class="attribute-error-message">
        {{attributeError}}
      </p>
    </div>
  </div>
</ng-container>
