import {BaseAttribute} from 'pm-components/lib/attributes/attribute-type/base-attribute';
import {
  AttributeDisplayToggleComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import {ScaleInformation} from 'pm-models';

export class BilingualLabels implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }
  getAttributeConfig(overrides?) {
    return {
      label: 'Bilingual label?',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: false,
      defaultValue: false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, scaleInformation: ScaleInformation, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (scaleInformation.bilingual === undefined) {
      scaleInformation.bilingual = this.defaultVal;
    }
    componentInstance.model = scaleInformation.bilingual;
    componentInstance.change.subscribe(x => {
      scaleInformation.bilingual = x.checked;
      if (!scaleInformation.bilingual) {
        scaleInformation.englishLabelOneBilingual = null;
        scaleInformation.englishLabelTwoBilingual = null;
        scaleInformation.spanishLabelOne = null;
        scaleInformation.spanishLabelTwo = null;
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, scaleInformation: ScaleInformation) {
    if (componentInstance.model !== scaleInformation.bilingual) {
      componentInstance.model = scaleInformation.bilingual;
    }
  }
}
