<div class="pm-stepper">
  <ul role="tablist">
    <li role="tab" *ngFor="let item of stepperItems; let i = index" [pTooltip]="item.message" [ngClass]="{'pm-steps-current':(i+1 === activeIndex)}">
      <i class="material-icons" style="color: darkred" *ngIf="item.icon">
        {{item.icon}}
      </i>
      <span style="color: darkred" *ngIf="item.icon && (i+1 !== activeIndex)" (click)="onClick(item)">{{item.text}}</span>
      <span *ngIf="item.icon && (i+1 === activeIndex)" (click)="onClick(item)">{{item.text}}</span>
      <span *ngIf="!item.icon" (click)="onClick(item)">{{item.text}}</span>
    </li>
  </ul>
</div>
