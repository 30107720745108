import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TaskListTask} from '../../../../../../src/app/2.0.0/task-list/task-list-service/taskListTask';

interface TaskHeaderType {
  field: string;
  header: string;
  sortable: boolean;
}

@Component({
  selector: 'pm-task-viewer-table-in-progress',
  templateUrl: './task-viewer-table-in-progress.component.html',
  styleUrls: ['./task-viewer-table-in-progress.component.scss']
})
export class TaskViewerTableInProgressComponent implements OnInit {
  @Input() tasks: TaskListTask[];

  @Input() totalNumRecords: number;

  @Input() lazyLoadOn: boolean = false;

  @Output() taskClicked = new EventEmitter<TaskListTask>();

  @Output() loadData = new EventEmitter<any>();

  @Input() pageSize: number = 50;

  taskHeaderColumns: TaskHeaderType[];

  constructor() { }

  ngOnInit() {
    this.createTaskColumns();
  }

  createTaskColumns() {
    this.taskHeaderColumns = [
      {field: 'candidateId', header: 'ID', sortable: true},
      {field: 'description', header: 'Candidate', sortable: false},
      {field: 'supplierDisplayName', header: 'Supplier [AP]', sortable: true},
      {field: 'lastUpdatedBy', header: 'Updated By', sortable: true},
      {field: 'lastUpdatedTime', header: 'Last Updated', sortable: true}
    ];
  }

  showPagination(): boolean {
    return true;
  }

  onClickRow(row: any) {
    this.taskClicked.emit(row.data);
  }

  loadCandidatesLazy(event: any) {
    this.loadData.emit(event);
  }

}
