<div class="row" *ngIf="!permissions?.isReadOnly || associateUpcConfig else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4">

  </div>
</div>

<ng-template #card>
  <pm-card title="{{packagingTitle}}" subtitle="{{packagingSubtitle}}">
    <ng-container *ngIf="model">


      <pm-attribute-display-typeahead [attribute]="packageTypeConfiguration" [(model)]="model.packageType"
        [attributeError]="errorModel?.packageType" (selection)="packageTypeChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-volume [attribute]="dimensionsConfiguration" [(lengthModel)]="model.productLength"
        [(widthModel)]="model.productWidth" [(heightModel)]="model.productHeight"
        (volumeModelChange)="volumeCalc($event)" [attributeWidthError]="errorModel?.productWidth"
        [attributeHeightError]="errorModel?.productHeight" [attributeLengthError]="errorModel?.productLength"
        [divisor]="1728">
      </pm-attribute-display-volume>

      <pm-attribute-display-text-input [attribute]="weightConfiguration" [(model)]="model.productWeight"
        [attributeError]="errorModel?.productWeight" (change)="productWeightChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="volumeConfiguration" [(model)]="model.totalVolume"
                                       [attributeError]="errorModel?.totalVolume" (change)="totalVolumeChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-typeahead [attribute]="unitOfMeasureConfiguration" [(model)]="model.unitOfMeasure"
        [attributeError]="errorModel?.unitOfMeasure" (selection)="unitOfMeasureChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-text-input [attribute]="getRetailSize()" [(model)]="model.retailSize"
        [attributeError]="errorModel?.retailSize" (change)="retailSizeChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-upc *ngIf="productModel" [attribute]="depositUpcConfiguration" [(model)]="productModel.depositScanCodeId"
                                [attributeError]="productErrorModel.depositScanCodeId" [(checkDigitModel)]="productModel.depositScanCodeCheckDigit"
                                (modelChange)="validateDepositUpcEvent()" (checkDigitModelChange)="validateDepositUpcEvent()"
                                [state]="state">
      </pm-attribute-display-upc>

    </ng-container>

  </pm-card>
</ng-template>
