/*
  This is a Fork form PrimeNG's Menu. We need better customization of the m
*/
import {
  Component,
  ElementRef,
  OnDestroy, Input, Output, EventEmitter, Renderer2, ViewChild, ChangeDetectionStrategy, ViewEncapsulation
} from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { DomHandler } from 'primeng/dom';

import { PMMenuItem } from '../../common/menu-item.interface';

@Component({
  selector: 'pm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('overlayAnimation', [
      state('void', style({
        transform: 'translateY(5%)',
        opacity: 0
      })),
      state('visible', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}'))
    ])
  ]
})
export class MenuComponent implements OnDestroy {
  @Input() model: PMMenuItem[];

  @Input() popup: boolean;

  @Input() style: any;

  @Input() styleClass: string;

  @Input() appendTo: any;

  @Input() autoZIndex: boolean = true;

  @Input() baseZIndex: number = 0;

  @Input() showTransitionOptions: string = '225ms ease-out';

  @Input() hideTransitionOptions: string = '195ms ease-in';

  @ViewChild('container') containerViewChild: ElementRef;

  @Output() showEvent: EventEmitter<any> = new EventEmitter();

  @Output() hideEvent: EventEmitter<any> = new EventEmitter();

  container: HTMLDivElement;

  documentClickListener: any;

  documentResizeListener: any;

  preventDocumentDefault: boolean;

  target: any;

  visible: boolean;
  constructor(public el: ElementRef, public renderer: Renderer2) { }
  toggle(event) {
    if (this.visible) {
      this.hide();
    } else {
      this.show(event);
    }

    this.preventDocumentDefault = true;
  }

  show(event) {
    this.target = event.currentTarget;
    this.visible = true;
    this.preventDocumentDefault = true;
  }
  onOverlayAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        if (this.popup) {
          this.container = event.element;
          this.moveOnTop();
          this.showEvent.emit({});
          this.appendOverlay();
          if (this.target) {
            DomHandler.absolutePosition(this.container, this.target);
          }
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
        }
        break;

      case 'void':
        this.onOverlayHide();
        this.hideEvent.emit({});
        break;
    }
  }

  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        document.body.appendChild(this.container);
      } else {
        DomHandler.appendChild(this.container, this.appendTo);
      }
    }
  }

  restoreOverlayAppend() {
    if (this.container && this.appendTo) {
      this.el.nativeElement.appendChild(this.container);
    }
  }

  moveOnTop() {
    if (this.autoZIndex) {
      this.container.style.zIndex = String(this.baseZIndex + (++DomHandler.zindex));
    }
  }

  hide() {
    this.visible = false;
  }

  onWindowResize() {
    this.hide();
  }

  itemClick(event, item: PMMenuItem) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (!item.url) {
      event.preventDefault();
    }
    // Menu items may or may not be nested
    this.model.forEach(x => {
      if (x.items) {
        x.items.forEach(y => {
          y.isSelected = false;
        });
      } else {
        x.isSelected = false;
      }
    });

    item.isSelected = true;

    if (item.command) {
      item.command({
        originalEvent: event,
        item: item
      });
    }

    if (this.popup) {
      this.hide();
    }
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.preventDocumentDefault) {
          this.hide();
        }

        this.preventDocumentDefault = false;
      });
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }

  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      this.documentResizeListener = null;
    }
  }

  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.preventDocumentDefault = false;
    this.target = null;
  }

  ngOnDestroy() {
    if (this.popup) {
      this.restoreOverlayAppend();
      this.onOverlayHide();
    }
  }

  hasSubMenu(): boolean {
    if (this.model) {
      for (const item of this.model) {

        if (item.items) {
          return true;
        }
      }
    }
    return false;
  }
}
