import {Component, OnInit} from '@angular/core';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {MatUtilService} from '../../service/mat-util.service';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {Candidate, CandidateError, CandidateProductError, CandidateValidatorType} from 'pm-models';
import {finalize, switchMap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';

@Component({
  selector: 'app-mrt-extended-attributes',
  templateUrl: './mrt-extended-attributes.component.html',
  styleUrls: ['./mrt-extended-attributes.component.scss']
})
export class MrtExtendedAttributesComponent implements OnInit {

  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public supplierMrtService: SupplierMrtService, private candidateUtilService: CandidateUtilService,
              private matUtilService: MatUtilService) { }

  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;
  candidateProductError: CandidateProductError;


  ngOnInit() {

    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(), this.supplierMrtService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierMrtService.resetService();
        this.supplierMrtService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate) => {
            if (candidate.candidateType === Candidate.MRT) {
              this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
              return of({});
            } else {
              return this.router.navigate(['/tasks']);
            }
          }),
          switchMap(() =>
            forkJoin([this.supplierMrtService.setInnerCandidates(), this.supplierMrtService.setExistingInnerProducts(),
              this.matUtilService.updateMatAttributesAndValues(this.supplierMrtService.getCandidate(), this.supplierMrtService.globalAttributes, [])])
          ),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.supplierMrtService.getMrtExtendedAttributesError();
            this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();

      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierMrtService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return of({});
            } else {
              this.supplierMrtService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          switchMap(() =>
            forkJoin([this.supplierMrtService.setInnerCandidates(), this.supplierMrtService.setExistingInnerProducts(),
              this.matUtilService.updateMatAttributesAndValues(this.supplierMrtService.getCandidate(), this.supplierMrtService.globalAttributes, [])])
          ),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.supplierMrtService.getMrtExtendedAttributesError();
            this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      }
    });
  }

  onClose() {
    this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
    this.supplierMrtService.saveCandidateAndNavigate('/tasks', null);
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case SupplierMrtService.UPC_ITEM_CODES: {
        this.jumpBack('/setupMrt');
        break;
      }
      case SupplierMrtService.NEW_ITEM_SETUP: {
        this.jumpBack('/setUpNewMrtItems');
        break;
      }
      case SupplierMrtService.MRT_DETAILS: {
        this.jumpBack('/mrtCaseDetails');
        break;
      }
      case SupplierMrtService.REVIEW: {
        this.onClickNext();
        break;
      }
    }
  }

  onClickBack() {
    this.jumpBack('/mrtCaseDetails');
  }

  jumpBack(url) {
    this.canNavigate = false;
    this.supplierMrtService.saveCandidateAndNavigate(url, {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
  }

  onClickNext() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(), [CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR]).subscribe(() => {
      this.candidateError = this.supplierMrtService.setMrtExtendedAttributesError(new CandidateError());
      this.supplierMrtService.saveMrtCandidate().subscribe((result) => {
        this.supplierMrtService.saveCandidateAndNavigate('/supplierMrtReview', {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
      });
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierMrtService.setMrtExtendedAttributesError(error.error.candidateErrors);
        this.candidateProductError = this.candidateError.candidateProductErrors[this.supplierMrtService.getCandidateProduct(0).id] ;
      }
      this.supplierMrtService.saveMrtCandidate().subscribe(() => {
        this.supplierMrtService.saveCandidateAndNavigate('/supplierMrtReview', {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
      });
    });
  }
}
