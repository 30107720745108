<!--  this input is placed here so the multiple buttons corresponding to this can have access to it-->
<input *ngIf="imageInputId" id="{{imageInputId}}" type="file" accept="image/jpeg, image/png" style="display: none;"
  (change)="onFilesAdded($event.target?.files)" (click)="$event.target.value = null" value="" multiple size="40"/>
<div class="ui-upload-container">
  <pm-progress-spinner [showSpinner]="isInProgress" [strokeWidth]="'2'" class="loading-indicator"
    [style]="{width: '200px', height: '200px', paddingTop: '40%'}"></pm-progress-spinner>

  <div pmDragDrop (files)="filesDropped($event)" *ngIf="!isInProgress" class="p-3">

    <div class="" *ngIf="!uploadedFiles.length" class="upload-container">
      <img src="assets/images/illustrations-images.png" class="img-fluid" alt="Files">
      <h3 class="upload-sub-title">Drop images here</h3>
      <p>You can upload up to 12 images. Upload images that:</p>
      <ul>
        <li>Are high quality (at least 300 dpi)</li>
        <li>Are JPEG or PNG format</li>
        <li>Show 3D Front, Right, Left views of the product</li>
        <li>Show 2D Front, Back, Top, Bottom, Right, Left views</li>
        <li>Shows the UPC</li>
      </ul>
      <p-button label="Upload images" class="uploadButton ghost" (onClick)="onClickUploadImages()"></p-button>
    </div>


    <div class="row align-items-center" *ngIf="uploadedFiles.length">
      <div class="col-3 thumbnail-container" *ngFor="let file of uploadedFiles">
        <img [src]="file.fullFile" class="img-thumbnail" title="{{file.name}}">
       <div class="top-right"><i class="pi pi-times-circle" (click)="removeFile(file)"></i></div>
      </div>
    </div>
  </div>
</div>
<div class="image-footer">
  <div class="mt-3" *ngIf="uploadedFiles.length">
    <p-button label="Upload images" class="uploadButton ghost" (onClick)="onClickUploadImages()">
    </p-button>
  </div>
  <div *ngIf="fileError" class="upload-error-message">
    <span [innerHTML]="fileError"></span>

  </div>
</div>

