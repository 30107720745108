<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">

  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label">
      <label for="">{{attribute.label}}</label>
    </div>

    <div class="attribute-control">
      <div class="attribute-area">

        <input type="text" pInputText [(ngModel)]="lengthModel" (keypress)="onKeyPress($event)"
          (blur)="onLengthBlur($event)" (focus)="onLengthFocus($event)"
          [placeholder]="attribute.lengthPlaceholder || 'Length'"
          [readonly]="attribute.isReadOnly && attribute.isReadOnly()" [maxlength]="attribute.maxLength"
          [disabled]="attribute.isDisabled && attribute.isDisabled()"
          [ngClass]="{'ui-inputtext-invalid' : attributeLengthError, 'length-input': true}" />

        <input type="text" pInputText [(ngModel)]="widthModel" (keypress)="onKeyPress($event)"
          (blur)="onWidthBlur($event)" (focus)="onWidthFocus($event)"
          [placeholder]="attribute.widthPlaceholder || 'Width'"
          [readonly]="attribute.isReadOnly && attribute.isReadOnly()" [maxlength]="attribute.maxLength"
          [disabled]="attribute.isDisabled && attribute.isDisabled()"
          [ngClass]="{'ui-inputtext-invalid' : attributeWidthError, 'width-input': true}" />

        <input type="text" pInputText [(ngModel)]="areaModel" [placeholder]="attribute.areaPlaceholder || 'Area'"
          [readonly]="true" [ngClass]="{'ui-inputtext-invalid' : attributeAreaError, 'area-input': true}" tabindex="-1" />

      </div>

      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <ng-content></ng-content>
      <p *ngIf="attributeLengthError" class="attribute-error-message">
        {{attributeLengthError}}
      </p>
      <p *ngIf="attributeWidthError" class="attribute-error-message">
        {{attributeWidthError}}
      </p>
      <p *ngIf="attributeAreaError" class="attribute-error-message">
        {{attributeAreaError}}
      </p>
    </div>
  </div>
</ng-container>
