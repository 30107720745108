<!-- Menu Button -->
<header class="mtr-header__default-content">
  <!-- Logo -->
  <ng-container *ngTemplateOutlet="(logoRouterLink) ? linkLogoContainer : basicLogoContainer"></ng-container>

</header>

<!-- Content -->
<ng-content></ng-content>

<!-- Renders the logo in a span -->
<ng-template #basicLogoContainer>
  <span class="mtr-header__logo__container">
    <ng-container *ngTemplateOutlet="(logoRef) ? logoRef : defaultLogo"></ng-container>
  </span>
</ng-template>

<!-- Renders the logo as a router link -->
<ng-template #linkLogoContainer>
  <a class="mtr-header__logo__container" [routerLink]="logoRouterLink">
    <ng-container *ngTemplateOutlet="(logoRef) ? logoRef : defaultLogo"></ng-container>
  </a>
</ng-template>


<ng-template #defaultLogo>
  <svg
    class="mtr-header__logo"
    role="img"
    aria-label="H-E-B Logo"
    viewBox="0 0 188 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs><path id="a" d="M0 .082h187.871v63.712H.001z" /></defs>
    <g fill="none" fill-rule="evenodd">
      <path
        d="M183.79 57.91v-1.046h.696c.355 0 .734.076.734.495 0 .52-.386.55-.821.55h-.608zm0 .435h.585l.89 1.453h.572l-.96-1.477c.497-.061.876-.325.876-.93 0-.668-.395-.963-1.193-.963h-1.278v3.37h.509v-1.453zm.617 2.69c1.592 0 2.95-1.237 2.95-2.922s-1.358-2.907-2.95-2.907c-1.609 0-2.975 1.222-2.975 2.907s1.366 2.921 2.975 2.921zm-2.388-2.922c0-1.39 1.055-2.416 2.388-2.416 1.32 0 2.36 1.026 2.36 2.416 0 1.4-1.04 2.432-2.36 2.432-1.333 0-2.388-1.031-2.388-2.432z"
        fill="#DC291E"
      />
      <g transform="translate(0 .125)">
        <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
        <path
          d="M155.997.082H31.885c-8.52 0-16.5 3.318-22.53 9.333C3.33 15.422 0 23.453 0 31.948 0 40.453 3.33 48.47 9.34 54.483c6.046 6.018 14.027 9.326 22.546 9.31h124.129c8.52 0 16.497-3.312 22.507-9.328 6.044-6.023 9.35-14.038 9.35-22.532 0-17.57-14.29-31.851-31.874-31.851"
          fill="#DC291E"
          mask="url(#b)"
        />
      </g>
      <path
        d="M156.014 59.945c15.403 0 27.888-12.5 27.888-27.887 0-15.412-12.503-27.912-27.888-27.897l-124.13.022c-15.425 0-27.92 12.515-27.92 27.89 0 15.43 12.532 27.897 27.92 27.872h124.13z"
        fill="#FFF"
      />
      <path
        d="M156.014 57.191c13.89 0 25.168-11.227 25.168-25.133 0-13.893-11.278-25.171-25.168-25.171l-124.13.032c-13.92 0-25.17 11.277-25.14 25.153 0 13.892 11.242 25.195 25.14 25.168l124.13-.049"
        fill="#DC291E"
      />
      <path
        fill="#FFF"
        d="M46.926 36.226l.175 9.778 11.425-.02 1.65-27.82H46.612l.191 12.445h-8.1l.178-12.444h-13.54l1.675 27.84H38.44l.14-9.779zM72.557 36.318v-5.612l-8.192.034v5.578zM123.899 36.318l-.058-5.612h-8.19l-.019 5.612zM105.268 30.609H90.639l.062-4.844h20.418l.524-7.6H77.271l1.164 27.8h31.706l.428-5.225H90.445l.075-4.514h14.546zM154.736 18.152h-26.121l1.658 27.812h22.75c6.799 0 8.502-3.217 8.99-5.828.473-2.429.736-6.122-4.315-6.651 3.947-.858 5.908-3.309 5.994-7.677.017-2.785-1.535-7.681-8.956-7.656zm-8.422 7.107c1.972 0 4.22.805 4.144 2.707-.16 2.298-2.088 2.886-4.52 2.886h-3.98l.113-5.593h4.243zm3.103 13.177c-.252 2.724-3.159 2.5-5.16 2.5h-2.464l.077-5.154h3.85c1.87 0 3.908.404 3.697 2.654z"
      />
    </g>
  </svg>

</ng-template>
