import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableModule} from 'primeng/table';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {TaskViewerTableRejectedComponent} from './task-viewer-table-rejected.component';

@NgModule({
  declarations: [TaskViewerTableRejectedComponent],
  exports: [TaskViewerTableRejectedComponent],
  imports: [
    CommonModule, TableModule, PipesModule
  ]
})
export class TaskViewerTableRejectedModule { }


