/**
 * Constant class for User Roles.
 */
export class UserRoleConstants {
  public static readonly VENDOR_ROLE: string = 'ROLE_WF_VENDOR';
  public static readonly PROCUREMENT_SUPPORT_ROLE: string = 'ROLE_WF_PIA';
  public static readonly BUYER_ROLE: string = 'ROLE_WF_BUYER';
  public static readonly OWN_BRAND_REGULATORY_ROLE: string = 'ROLE_WF_OBREG';
  public static readonly PHARMACY_ROLE: string = 'ROLE_WF_PHARM';
  public static readonly SUPPLY_CHAIN_ANALYST_ROLE: string = 'ROLE_WF_SCA';

  /**
   * Map from role to user friendly display name.
   */
  public static readonly ROLE_TO_DISPLAY_NAME_MAP: Map<string, string> = new Map([
    [UserRoleConstants.BUYER_ROLE, 'Buyer'],
    [UserRoleConstants.OWN_BRAND_REGULATORY_ROLE, 'Own Brand Regulatory'],
    [UserRoleConstants.PHARMACY_ROLE, 'Pharmacy'],
    [UserRoleConstants.PROCUREMENT_SUPPORT_ROLE, 'Procurement Support'],
    [UserRoleConstants.VENDOR_ROLE, 'Supplier'],
    [UserRoleConstants.SUPPLY_CHAIN_ANALYST_ROLE, 'Supply Chain Analyst']
  ]);

  public static USER_ROLES: string[] =
    [UserRoleConstants.BUYER_ROLE, UserRoleConstants.OWN_BRAND_REGULATORY_ROLE, UserRoleConstants.PHARMACY_ROLE,
      UserRoleConstants.PROCUREMENT_SUPPORT_ROLE, UserRoleConstants.VENDOR_ROLE, UserRoleConstants.SUPPLY_CHAIN_ANALYST_ROLE];

  /**
   * Represents the order of defaulting roles based on the roles the user has.
   */
  public static DEFAULT_ROLE_ORDER: string[] =
    [UserRoleConstants.VENDOR_ROLE, UserRoleConstants.BUYER_ROLE, UserRoleConstants.SUPPLY_CHAIN_ANALYST_ROLE,
      UserRoleConstants.PROCUREMENT_SUPPORT_ROLE, UserRoleConstants.OWN_BRAND_REGULATORY_ROLE, UserRoleConstants.PHARMACY_ROLE];


}
