import { BaseAttribute } from '../base-attribute';
import { AttributeConfig, Warehouse } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayRadioComponent } from '../../../shared/attribute-display/attribute-display-radio/attribute-display-radio.component';

export class WarehouseOrderUnit implements BaseAttribute {
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  getComponentType() {
    return AttributeDisplayRadioComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Order unit',
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ],
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayRadioComponent, candidate: Warehouse, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.attribute.name = `orderUnit_${candidate.warehouseId}`,

    componentInstance.model = candidate.orderUnit.id;
    componentInstance.modelChange.subscribe(event => {
      if (event === this.CASE_ID) {
        candidate.orderUnit = {id: this.CASE_ID, description: 'CASE'};
      } else if (event === this.EACH_ID) {
        candidate.orderUnit = {id: this.EACH_ID, description: 'EACH'};
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayRadioComponent, candidate: Warehouse) {
    if (componentInstance.model !== candidate.orderUnit.id) {
      componentInstance.model = candidate.orderUnit.id;
    }
  }
}
