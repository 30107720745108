<pm-review #pmReview *ngIf="isViewingPage" [ngClass]="'review-grid-container'" (closeEvent)="onClose()">

  <pm-title>Review new product</pm-title>

  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages.length > 0 ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div *ngIf="!candidateUtilService.isPlu(candidate)" class="upc-header">
            UPC: {{candidate.candidateProducts[0].upc}}-{{candidate.candidateProducts[0].upcCheckDigit}}
          </div>
          <div *ngIf="candidateUtilService.isPlu(candidate)" class="upc-header">
            <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
              PLU: {{candidate.candidateProducts[0].upc | upc}}-{{candidate.candidateProducts[0].upc | upcCheckDigit}}<br>
            </div>
            <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
              UPC: {{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc)}}-{{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc) | upcCheckDigit}}
            </div>
          </div>
          <div class="attribute-description">
            <p>{{candidateProduct.description}}</p>
            <p>{{getProductInfoString()}}</p>
          </div>
        </div>
      </div>
      <!--Scale information-->
      <pm-attribute-grid *ngIf="!candidate?.showCalories" [title]="'Scale information'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-pack tare'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.prePackTare}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Shelf life'" [canEdit]="false">
              {{getDaysString(candidateProduct?.scaleInformation?.shelfLifeDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <pm-attribute-grid *ngIf="!candidate?.showCalories" [title]="'Scale labels'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'English label 1'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.englishLabelOne}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'English label 2'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.englishLabelTwo}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <pm-attribute-grid *ngIf="isSellable" [title]="'Retail set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false">
              {{candidate.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: {{costService.getMargin(candidate)}}%
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Retail set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.getInnerListCost(candidate)}}<br>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.unitCost)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!--Cost set by buyer-->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Costs set by buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false">
              {{candidate.retailType}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              {{costService.getHebMargin(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false">
              {{costService.getRetailPricing(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{costService.getHebPennyProfit(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false" [errorMessage]="candidateError?.masterPack">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight, 1)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid *ngIf="showCasePackInfo() && !candidate.dsdSwitch" [title]="'Inner packs'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="false">
              {{candidate.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false">
              {{candidate.innerPack }}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getInnerDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight, 1)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="isSellable && candidate.warehouseSwitch" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false">
              {{candidate.displayReadyUnit | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.displayReadyUnit">
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false">
              {{candidate.displayReadyUnit ? candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh : 0}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit type'" [canEdit]="false">
              {{candidateUtilService.getDruType(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Display ready units, not showing for non sellable -->

      <!-- Product details from buyer -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false">
              {{candidate.merchandiseType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{candidate.subCommodity?.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>

          </pm-grid-column>

          <pm-grid-column>

            <pm-grid-cell [label]="'PSS department'" [canEdit]="false">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Like item code'" [canEdit]="false">
              {{candidate.likeId}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season & Year'" [canEdit]="false">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>


            <pm-grid-cell [label]="'Estimated store count'" [canEdit]="false">
              {{candidate.numberOfStores}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Sold by weight'">
              {{candidate.weightSwitch | yesNo}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!-- Product details from buyer -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false">
              {{candidate.merchandiseType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'PSS department'" [canEdit]="false">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{candidate.subCommodity?.subCommodityName}}  [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Warehouses -->
      <pm-attribute-grid *ngIf="showCasePackInfo() && !isDsdOnlyParms(candidate)" [title]="'Warehouses'">

        <!-- Warehouses grid content -->
        <pm-grid-group *ngIf="candidateProduct.warehouses.length > 0">
          <pm-grid-column>
            <pm-grid-cell [label]="'Max ship'" [canEdit]="false">
              {{candidate.maxShip}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

        <ng-template ngFor let-warehouse [ngForOf]="candidateProduct.warehouses" let-index="index">

          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Warehouse'" [canEdit]="false" class="warehouse-grid-cell">
                {{warehouse.name}}
                <pm-grid-cell-info>
                  ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                </pm-grid-cell-info>
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order unit'" [canEdit]="false">
                {{warehouse.orderUnit?.description}}
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order restriction'" [canEdit]="false">
                {{warehouse.orderRestriction?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <div *ngIf="index !== candidateProduct.warehouses.length - 1" class="grid-divider"></div>
        </ng-template>
      </pm-attribute-grid>

      <!-- Authorized stores -->
      <pm-attribute-grid *ngIf="isDsdOnlyParms(candidate)" [title]="'Authorized stores'">
        <pm-grid-group *ngFor="let locationGroupStore of candidate.candidateProducts[0].locationGroupStores">
          <pm-grid-column>
            <pm-grid-cell [label]="'Distributor'" [canEdit]="false">
              <div>{{getVendor(locationGroupStore.splrLocationNumber)?.displayName}}</div>
              <pm-grid-cell-info>
                <b>Total stores: {{locationGroupStore.stores.length}} of {{getNumberOfStoresForGroup(locationGroupStore.splrLocationGroupId)}} stores</b>
              </pm-grid-cell-info>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Authorization group'">
              <div>{{locationGroupStore.splrLocationGroupId}}</div>
              <pm-grid-cell-info>
                <div *ngIf="locationGroupStore.listCost">${{locationGroupStore.listCost| number : '1.2-2'}}</div>
                <div *ngIf="!locationGroupStore.listCost">${{candidate.masterListCost| number : '1.2-2'}}</div>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Stores'">
              <div class="mb-2">{{getAuthGroupLabel(locationGroupStore)}}</div>
              <div *ngFor="let store of locationGroupStore.stores">
                <div class="ui-grid-col-2 mr-2 mb-2" style="background: #f2f2f2; text-align: center;">{{store.custLocationNumber}}</div>
              </div>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>


      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false">
              {{candidate.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false">
              {{candidateProduct.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">

              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="false">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Channel'" [canEdit]="false">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false">
              {{getSellableString()}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false">
              {{candidate.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false">
              {{candidate.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'">
              {{candidate.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{candidate.retailSize}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Total volume'" [canEdit]="false">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>

            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Show Calories'" [editNoHover]="true" (editClicked)="editCandidate(attributeType.ShowCalories)">
              {{candidate.showCalories | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Qualifying conditions'" [canEdit]="false">
              {{candidateProduct?.qualifyingCondition?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Tax category'" [canEdit]="false" [errorMessage]="candidateError?.vertexTaxCategory">
              {{candidate.vertexTaxCategoryModel?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line & Packaging -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product line & Packaging'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Qualifying conditions'" [canEdit]="false">
              {{candidateProduct?.qualifyingCondition?.description}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>

            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Show Calories'" [editNoHover]="true" (editClicked)="editCandidate(attributeType.ShowCalories)">
              {{candidate.showCalories | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Total volume'" [canEdit]="false">
              {{candidate.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{candidate.retailSize}}
            </pm-grid-cell>
            <pm-grid-column>
              <pm-grid-cell [canEdit]="false" [errorMessage]="candidateError?.vertexTaxCategory"
                            [label]="'Tax category'">
                {{candidate.vertexTaxCategoryModel?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Unit Details -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="false">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false">
              {{candidateProduct.customerFriendlyDescription1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false">
              {{candidateProduct.customerFriendlyDescription2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false">
              {{candidateProduct.romanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Unit Details -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="false">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Global Attributes   -->
      <div *ngIf="showMatAttributes">
        <pm-attribute-grid [title]="'Extended attributes: Product'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>

        <pm-attribute-grid [title]="'Extended attributes: UPC'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>

        <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && isLoadingMatGlobalData" [title]="'Extended attributes: Case'">
          <div style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
        </pm-attribute-grid>
        <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && !isLoadingMatGlobalData && !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>
      </div>
      <!--   Mat Hierarchy   -->
      <pm-attribute-grid *ngIf="showMatAttributes && isLoadingMatHierarchyData && matUtilService.getMatHierarchyList(candidate).length > 0" [title]="'Category attributes'">
        <div style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingMatHierarchyData" [strokeWidth]="'2'"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching extended attribute data...</h5>
        </div>
      </pm-attribute-grid>
      <div *ngIf="showMatAttributes && hierarchyNumberToAttributesMap.size > 0">
        <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
          <pm-attribute-grid *ngIf="hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>


      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'Comments'" [canEdit]="true"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.scaComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate.piaComment">
                <b>Procurement Support</b> <br>
                {{candidate.piaComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.obRegComment">
                <b>{{candidate.obRegName}}</b> <br>
                {{candidate.obRegComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="false">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="false">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <pm-review-drawer-container>
    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="obRegComments"
                                           [(model)]="tempCommentHolder">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.buyerComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.buyerComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-review-drawer-container>
    <ng-container *ngIf="candidate && openOwnBrandDetailDrawer && !openCommentDrawer">
      <div class="container review-drawer p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link"
                    (click)="save()"></button>
          </div>
        </div>
        <div class="attributes-stacked">

          <h3>Own Brand details</h3>
          <p>Provide the following information and click approve to send this candidate to Procurement
            Support for review.</p>
          <h3 class="mt-2 mb-3">Statement numbers</h3>

          <pm-attribute-display-text-input [attribute]="ingredientStatementConfiguration"
                                           [attributeError]="candidateProductError?.scaleInformation?.ingredientStatementNumber"
                                           [(model)]="candidateProduct?.scaleInformation.ingredientStatementNumber">
          </pm-attribute-display-text-input>

          <pm-attribute-display-toggle [attribute]="nutritionStatemenToggleConfiguration"
                                       [model]="candidateProduct.scaleInformation.nutritionStatementNumber"
                                       (change)="nutritionStatementChange($event)">
          </pm-attribute-display-toggle>

          <pm-attribute-display-text-input [attribute]="nutritionStatementConfiguration"
                                           [attributeError]="candidateProductError?.scaleInformation?.nutritionStatementNumber"
                                           [(model)]="candidateProduct.scaleInformation.nutritionStatementNumber">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="gradeConfiguration"
                                           [attributeError]="candidateProductError?.scaleInformation?.gradeNumber"
                                           [(model)]="candidateProduct?.scaleInformation.gradeNumber">
          </pm-attribute-display-text-input>

          <h3 class="mt-2 mb-3">Labels</h3>

          <pm-attribute-display-text-input [attribute]="englishLabelOneConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.englishLabelOne"
                                           [attributeError]="candidateProductError?.scaleInformation?.englishLabelOne">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="englishLabelTwoConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.englishLabelTwo"
                                           [attributeError]="candidateProductError?.scaleInformation?.englishLabelTwo">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="englishLabelThreeConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.englishLabelThree"
                                           [attributeError]="candidateProductError?.scaleInformation?.englishLabelThree">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="englishLabelFourConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.englishLabelFour"
                                           [attributeError]="candidateProductError?.scaleInformation?.englishLabelFour">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="labelFormatOneConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.labelFormatOne"
                                           [attributeError]="candidateProductError?.scaleInformation?.labelFormatOne">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="labelFormatTwoConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.labelFormatTwo"
                                           [attributeError]="candidateProductError?.scaleInformation?.labelFormatTwo">
          </pm-attribute-display-text-input>

          <pm-attribute-display-toggle [attribute]="isBilingualConfiguration"
                                       [(model)]="candidateProduct?.scaleInformation.bilingual"
                                       (change)="isBilingualChange($event)">
          </pm-attribute-display-toggle>

          <div *ngIf="candidateProduct?.scaleInformation.bilingual">

            <pm-attribute-display-text-input [attribute]="englishLabelOneBilingualConfiguration"
                                             [(model)]="candidateProduct?.scaleInformation.englishLabelOneBilingual"
                                             [attributeError]="candidateProductError?.scaleInformation?.englishLabelOneBilingual">
            </pm-attribute-display-text-input>
            <pm-attribute-display-text-input [attribute]="englishLabelTwoBilingualConfiguration"
                                             [(model)]="candidateProduct?.scaleInformation.englishLabelTwoBilingual"
                                             [attributeError]="candidateProductError?.scaleInformation?.englishLabelTwoBilingual">
            </pm-attribute-display-text-input>

            <pm-attribute-display-text-input [attribute]="spanishLabelOneConfiguration"
                                             [(model)]="candidateProduct?.scaleInformation.spanishLabelOne"
                                             [attributeError]="candidateProductError?.scaleInformation?.spanishLabelOne">
            </pm-attribute-display-text-input>
            <pm-attribute-display-text-input [attribute]="spanishLabelTwoConfiguration"
                                             [(model)]="candidateProduct?.scaleInformation.spanishLabelTwo"
                                             [attributeError]="candidateProductError?.scaleInformation?.spanishLabelTwo">
            </pm-attribute-display-text-input>
          </div>

          <h3 class="mt-2 mb-3">Tare</h3>

          <pm-attribute-display-text-input [attribute]="prePackTareConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.prePackTare"
                                           [attributeError]="candidateProductError?.scaleInformation?.prePackTare">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="serviceCounterTareConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.serviceCounterTare"
                                           [attributeError]="candidateProductError?.scaleInformation?.serviceCounterTare">
          </pm-attribute-display-text-input>

          <pm-attribute-display-radio [attribute]="forceTareConfiguration"
                                      [(model)]="forceTare"
                                      [attributeError]="candidateProductError?.scaleInformation?.forceTare"
                                      (change)="forceTareChange($event)">
          </pm-attribute-display-radio>

          <h3 class="mt-2 mb-3">Additional Details</h3>

          <pm-attribute-display-text-input [attribute]="shelfLifeDaysConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.shelfLifeDays"
                                           [attributeError]="candidateProductError?.scaleInformation?.shelfLifeDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-radio [attribute]="mechanicallyTenderizedConfiguration" [(model)]="mechanicallyTenderized"
                                      [attributeError]="candidateProductError?.scaleInformation?.mechanicallyTenderized"
                                      (change)="mechanicallyTenderizedChange($event)">
          </pm-attribute-display-radio>

          <pm-attribute-display-text-input [attribute]="actionCodeConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.actionCode"
                                           [attributeError]="candidateProductError?.scaleInformation?.actionCode">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="graphicsCode1Configuration"
                                           [(model)]="candidateProduct?.scaleInformation.graphicsCodeOne"
                                           [attributeError]="candidateProductError?.scaleInformation?.graphicsCodeOne">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="graphicsCode2Configuration"
                                           [(model)]="candidateProduct?.scaleInformation.graphicsCodeTwo"
                                           [attributeError]="candidateProductError?.scaleInformation?.graphicsCodeTwo">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="graphicsCode3Configuration"
                                           [(model)]="candidateProduct?.scaleInformation.graphicsCodeThree"
                                           [attributeError]="candidateProductError?.scaleInformation?.graphicsCodeThree">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="netWeightConfiguration"
                                           [(model)]="candidateProduct?.scaleInformation.netWeight"
                                           [attributeError]="candidateProductError?.scaleInformation?.netWeight">
          </pm-attribute-display-text-input>
          <div class="text-right">
            <p-button *ngIf="candidate.showCalories" class="ghost m-2" label="Back" (onClick)="onClickBackToNutrition()"></p-button>
            <p-button class="m-2" label="Approve" (onClick)="onClickApprove()" [disabled]="isApproveDisabled"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>


  <pm-review-drawer-container>
    <ng-container *ngIf="openNutritionDrawer && !openOwnBrandDetailDrawer && nutritionResults && candidate.showCalories">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapseNutritionDrawer()"></button>
          </div>
        </div>
        <div class="col-12">
          If this candidate doesn't need a nutrition panel, you can <a [routerLink]="" queryParamsHandling="preserve" (click)="showPanel($event, nutritionPanelModal, removeNutritionPanelModal)">remove it</a> from your tasks.
        </div>
        <div class="col-12">
          <div class="row">
            UPC: {{candidate.candidateProducts[0].upc}}
          </div>
          <div class="row">
            Source: Genesis
          </div>
          <div class="row">
            Serving Size: {{servingSize}}
          </div>
          <div class="row">
            Serving Per Container: {{servingsPerContainer}}
          </div>
        </div>
        <br>

        <p-table [columns]="nutritionHeaderColumns"
                 [resizableColumns]="true" [autoLayout]="true"
                 [value]="nutritionResults">

          <ng-template pTemplate="header" let-nutritionHeaderColumns>
            <tr>
              <th *ngFor="let col of nutritionHeaderColumns" [pSortableColumn]="col.field"
                  [pSortableColumnDisabled]="!col.sortable" [ngStyle]="{'width' : col.width}">
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
              </th>
            </tr>
          </ng-template>


          <ng-template pTemplate="body" let-nutrition >
            <tr style="height: 16px !important;">

              <td>{{nutrition?.field}}</td>
              <td>{{nutrition?.gramPerServing}}</td>
              <td>{{nutrition?.dailyValue}}</td>

            </tr>
          </ng-template>

        </p-table>

        <br>
        <div class="col-12">
          Ingredients List: {{ingredientsList}}
          <br>
          <br>
          Allergens: {{allergensList}}
        </div>
        <br>

        <div class="text-right">
          <p-button class="m-2" label="Approve" (onClick)="onClickNutritionDrawerApprove()" [disabled]="isNutritionDrawerApproveDisabled"></p-button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="openNutritionDrawer && !openOwnBrandDetailDrawer && scaleNutritionPanel && candidate.showCalories">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapseNutritionDrawer()"></button>
          </div>
        </div>
        <div class="col-12">
          If this candidate doesn't need a nutrition panel, you can <a [routerLink]="" queryParamsHandling="preserve" (click)="showPanel($event, nutritionPanelModal, removeNutritionPanelModal)">remove it</a> from your tasks.
        </div>
        <div class="col-12">
          <div class="row">
            UPC: {{candidate.candidateProducts[0].upc}}
          </div>
          <div class="row">
            Source: Scales
          </div>
          <div class="row">
            Serving Size: {{scaleNutritionPanel.servingSize}}
          </div>
          <div class="row">
            Serving Per Container: {{scaleNutritionPanel.servingsPerContainer}}
          </div>
        </div>
        <br>
        <p-table [columns]="nutritionHeaderColumns" [resizableColumns]="true" [autoLayout]="true" [value]="scaleNutritionPanel.columns[0].detail">
          <ng-template pTemplate="header" let-nutritionHeaderColumns>
            <tr>
              <th *ngFor="let col of nutritionHeaderColumns" [pSortableColumn]="col.field"
                  [pSortableColumnDisabled]="!col.sortable" [ngStyle]="{'width' : col.width}">
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-nutrition >
            <tr style="height: 16px !important;">
              <td>{{nutrition?.description}}</td>
              <td>{{nutrition?.nutrientValue}}</td>
              <td>{{nutrition?.nutrientPercentDailyValue}}</td>
            </tr>
          </ng-template>
        </p-table>
        <br>
        <div class="col-12">
          Ingredients List: {{scaleNutritionPanel?.ingredients}}
          <br>
          <br>
          Allergens: {{scaleNutritionPanel?.allergens}}
        </div>
        <br>

        <div class="text-right">
          <p-button class="m-2" label="Next" (onClick)="onClickScaleNutritionDrawerApprove()" [disabled]="isNutritionDrawerApproveDisabled"></p-button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="openNutritionDrawer && !openOwnBrandDetailDrawer && !nutritionResults && !scaleNutritionPanel && candidate.showCalories">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapseNutritionDrawer()"></button>
          </div>
        </div>
        <h5>{{candidateUtilService.isScaleProduct(candidate) ? 'Pending Nutrition Data' : 'Pending Genesis Data'}}</h5>
        <div class="col-12">
          If this candidate doesn't need a nutrition panel, you can <a [routerLink]="" queryParamsHandling="preserve" (click)="showPanel($event, nutritionPanelModal, removeNutritionPanelModal)">remove it</a> from your tasks.
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button  class="ghost m-2" label="Reject" (onClick)="showPanel($event, rejectOverlay, rejectCandidateDiv)" [disabled]="isRejectDisabled"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-review>

<div>
  <div #rejectCandidateDiv></div>
  <pm-static-overlay-panel #rejectOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
    <pm-reject-candidate-overlay [rejectMessage]="rejectMessage" (cancelClicked)="rejectOverlay.hide()" (rejectButtonClicked)="reject($event, rejectOverlay, rejectCandidateDiv)"></pm-reject-candidate-overlay>
  </pm-static-overlay-panel>
</div>

<div #removeNutritionPanelModal></div>
<pm-static-overlay-panel #nutritionPanelModal appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
  <ng-container>
    <div class="row align-items-center remove-nutrition-overlay-wrapper">
      <div class="col remove-nutrition-overlay">
        <div class="header-row">
          <h2>Remove nutrition Panel?</h2>
        </div>
        <div>You will no longer need to review nutrition information and the candidate might be removed from your task list entirely.</div>
        <br>
        <div class="row button-row">
          <p-button class="ghost mr-3" label="Cancel" (click)="nutritionPanelModal.hide()"></p-button>
          <button pButton label="Remove" (click)="removeNutrition(nutritionPanelModal)"></button>
        </div>
      </div>
    </div>
  </ng-container>
</pm-static-overlay-panel>
