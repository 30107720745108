import {Component, OnInit} from '@angular/core';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {
  CandidateError,
  CandidateProductError, CandidateValidatorType,
  CaseDetailsCardPermissions,
  CostCardPermissions, CostLinkCardPermissions, HEBDetailsCardPermissions,
  SupplierDetailsCardPermissions, TaskDecision
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from '../../service/lookup.service';
import {CandidateService} from '../../service/candidate.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {WorkflowService} from '../../service/workflow.service';
import {InputState} from 'pm-components';
import {CostService} from '../../service/cost.service';
import {GrowlService} from '../../growl/growl.service';

@Component({
  selector: 'app-invited-distributor-case-details',
  templateUrl: './invited-distributor-case-details.component.html',
  styleUrls: ['./invited-distributor-case-details.component.scss']
})
export class InvitedDistributorCaseDetailsComponent implements OnInit {

  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public costOwners: any = [];
  public costLinkState: InputState;


  constructor(public invitedDistributorService: InvitedDistributorService, private route: ActivatedRoute,
              private router: Router, private lookupService: LookupService, private candidateService: CandidateService,
              private candidateUtilService: CandidateUtilService, private workflowService: WorkflowService,
              private costService: CostService, private growlService: GrowlService) { }

  supplierDetailsCardPermissions: SupplierDetailsCardPermissions;

  readonly buyerDetailsCardPermissions: HEBDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly costLinkCardPermissions: CostLinkCardPermissions = {
    isReadOnly: false
  };
  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions = {
    isReadOnly: false,
    itemWeightType: {
      isHidden: true
    },
    distributionChannel: {
      isHidden: true
    },
    buyer: {
      isHidden: true
    },
    dealOffered: {
      isHidden: true
    },
    masterPack: {
      isDisabled: true
    }
  };

  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false,
    masterPack: {
      isHidden: true
    }
  };

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.invitedDistributorService.getTaskId(),
        this.invitedDistributorService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.invitedDistributorService.resetService();
        this.invitedDistributorService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          this.invitedDistributorService.initializeProductData().subscribe(() => {
            this.initializeData();
          });
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.invitedDistributorService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              if (this.invitedDistributorService.productData && this.invitedDistributorService.upc) {
                this.initializeData();
              } else {
                this.invitedDistributorService.initializeProductData().subscribe(() => {
                  this.initializeData();
                });
              }
            } else {
              this.invitedDistributorService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.candidateError = this.invitedDistributorService.getCaseDetailsError();
    this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
    this.setCostOwners();
    this.setMasterPackPermissions();
  }

  onClose() {
    this.invitedDistributorService.saveCandidateAndNavigate('/tasks', this.invitedDistributorService.hasVendorChanged());
  }

  public setCostOwners() {
    this.lookupService.findBrandsById(
      [this.invitedDistributorService.productData.brand.brandId]).subscribe(brandList => {
      if (brandList && brandList.length > 0) {
        this.costOwners = brandList[0].costOwners;
        this.supplierDetailsCardPermissions = this.getSupplierDetails();
      }
    });
  }

  getSupplierDetails(): SupplierDetailsCardPermissions {
    const permissions: SupplierDetailsCardPermissions = {
      isReadOnly: false,
      brand: {
        isHidden: true
      },
      subBrand: {
        isHidden: true
      },
      lane: {
        isHidden: true
      }
    };
    return permissions;
  }


  onClickNext() {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.invitedDistributorService.getCandidate(),
      [CandidateValidatorType.INVITED_DISTRIBUTOR_CASE_DETAILS_VALIDATOR]).toPromise().then(data => {
      this.candidateError = this.invitedDistributorService.setCaseDetailsError(new CandidateError());
      this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
      if (!this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores ||
        this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
        this.invitedDistributorService.saveCandidateAndNavigate('/invitedDistributorStoreAuthorization',
          this.invitedDistributorService.hasVendorChanged());
      } else {
        this.invitedDistributorService.saveCandidateAndNavigate('/setUpInvitedDistributorStores',
          this.invitedDistributorService.hasVendorChanged());
      }
    }, (error) => {
      if (this.invitedDistributorService.hasVendorChanged()) {
        const apNumber = this.invitedDistributorService.getCandidate().vendor?.apNumber || null;
        this.workflowService.updateApNumber(apNumber, this.invitedDistributorService.getTask()?.processInstanceId).subscribe();
      }
      this.invitedDistributorService.saveCandidate();
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = this.invitedDistributorService.setCaseDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
      }
    });
  }

  onClickBack() {
    this.invitedDistributorService.saveCandidateAndNavigate('/supplierAddDistributor', this.invitedDistributorService.hasVendorChanged());
  }

  /**
   * Disables masterpack if there's no upc information, or there's a DSD item tied to the upc.
   */
  setMasterPackPermissions() {
    this.additionalCasePackBasicsCardPermissions.masterPack.isDisabled =
      !this.invitedDistributorService.upc || !!this.invitedDistributorService.upc.dsdItem;
  }

  /**
   * If the user chooses to start over when AP vendor is already authorized, remove the already stored data, and route to start of flow.
   */
  onClickStartOver() {
    this.invitedDistributorService.candidate.vendor = null;
    this.invitedDistributorService.onSupplierChange(null, null, null);
    this.invitedDistributorService.candidate.brand = null;
    this.invitedDistributorService.candidate.buyer = null;
    this.invitedDistributorService.candidate.commodity = null;
    this.invitedDistributorService.candidate.subCommodity = null;
    this.invitedDistributorService.candidate.costOwner = null;
    this.invitedDistributorService.candidate.dealOffered = null;
    this.invitedDistributorService.candidate.innerListCost = null;
    this.invitedDistributorService.candidate.masterListCost = null;
    this.invitedDistributorService.candidate.masterPack = null;
    this.invitedDistributorService.candidate.suggestedRetailPrice = null;
    this.invitedDistributorService.candidate.suggestedXFor = null;
    this.invitedDistributorService.candidate.unitCost = null;

    this.invitedDistributorService.candidate.candidateProducts[0].caseDescription = null;
    this.invitedDistributorService.candidate.candidateProducts[0].countryOfOrigin = null;
    this.invitedDistributorService.candidate.candidateProducts[0].description = null;
    this.invitedDistributorService.candidate.candidateProducts[0].upc = null;
    this.invitedDistributorService.candidate.candidateProducts[0].upcCheckDigit = null;
    this.invitedDistributorService.candidate.candidateProducts[0].vendorProductCode = null;

    this.invitedDistributorService.productData = null;

    this.invitedDistributorService.saveCandidateAndNavigate('/supplierAddDistributor',
      this.invitedDistributorService.hasVendorChanged());
  }

  /**
   * If the user chooses to return to home when AP vendor is already authorized, delete the candidate and the task.
   */
  onClickReturnHome() {
    this.candidateService.deleteCandidateById(this.invitedDistributorService.candidate.candidateId).subscribe(() => {
      this.workflowService.completeTaskWithAction(this.invitedDistributorService.getTask(), WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_VENDOR_DATA_DECISION_REJECT).subscribe(() => {
        this.router.navigate(['/tasks']).then();
      });
    });
  }

  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.invitedDistributorService.getCandidate().costLinkFromServer = undefined;
    this.candidateError.costLink = undefined;
    this.candidateService.validateCandidate(this.invitedDistributorService.getCandidate(),
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((validatedCandidate) => {
      this.costLinkState = InputState.valid;
      this.costService.updateCostLinkRelatedValuesFromValidatedCandidate(this.invitedDistributorService.getCandidate(), validatedCandidate);
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.candidateError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }
}
