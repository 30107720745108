import {Component, OnInit} from '@angular/core';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  MaxShipCardPermissions,
  TaskDecision,
  TextInputType,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {CandidateService} from '../../service/candidate.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {forkJoin} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-pia-mrt-warehouse',
  templateUrl: './pia-mrt-warehouse.component.html',
  styleUrls: ['./pia-mrt-warehouse.component.scss']
})
export class PiaMrtWarehouseComponent implements OnInit {

  public isViewingPage = true;
  private taskSubscription$: any;
  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public warehouses = [];
  public warehouseData = [];
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public orderRestrictions: any[];
  public canNavigate = true;

  readonly maxShipCardPermissions: MaxShipCardPermissions = {
    isReadOnly: false
  };

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => true,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };


  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private authService: AuthService, private candidateService: CandidateService,
              private candidateUtilService: CandidateUtilService, private growlService: GrowlService,
              private lookupService: LookupService, private workflowService: WorkflowService) { }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierMrtService.resetService();
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.MRT) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.resetErrors();
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierMrtService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  initializeData() {
    forkJoin([this.supplierMrtService.setInnerCandidates(), this.supplierMrtService.setExistingInnerProducts()]).subscribe();
    this.candidateError = this.supplierMrtService.getMrtWarehouseDetailsError();
    this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
    this.getWarehouses(this.supplierMrtService.getCandidate());
    this.getOrderRestrictions();
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    if (this.supplierMrtService.getCandidateProduct(0).warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.supplierMrtService.getCandidateProduct(0).warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.supplierMrtService.getCandidateProduct(0).warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({}, this.supplierMrtService.getCandidateProduct(0).warehouses[y],
              JSON.parse(JSON.stringify(this.supplierMrtService.getCandidateProduct(0).warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  onClose() {
    this.supplierMrtService.getCandidateProduct(0).warehouses = this.getSelectedWarehouses();
    this.isViewingPage = false;
    this.supplierMrtService.saveCandidateAndClose();
  }

  onClickActivate() {
    this.supplierMrtService.getCandidateProduct(0).warehouses = this.getSelectedWarehouses();
    // validate the candidate for this page and send errors to components to display, if any
    this.canNavigate = false;
    this.supplierMrtService.setPiaInitiatedCandidateInners();
    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(), [CandidateValidatorType.PIA_MRT_REVIEW_VALIDATOR]).subscribe(() => {

      if (this.supplierMrtService.getCandidate().showCalories &&
        !this.supplierMrtService.isScaleProduct(this.supplierMrtService.candidate)) {
        // just leveraging this WF because if follows needed flow
        this.saveAndCompletePiaTask();

      } else if (this.supplierMrtService.isScaleProduct(this.supplierMrtService.candidate)) {
        this.saveAndCompletePiaTask();
      } else {
        this.candidateService.activateCandidate(this.supplierMrtService.getCandidate()).subscribe(() => {
          this.completeTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE,
            TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
            'Successfully activated candidate.'
          );
        }, (error) => {
          this.growlService.addError(error);
          this.canNavigate = true;
        });
      }
    }, (error) => {
      this.supplierMrtService.scrollToTop();
      // set the errors on the page
      if (error.error && error.error.candidateErrors && error.error.candidateErrors.hasErrors) {
        this.supplierMrtService.updateMrtErrors(error.error.candidateErrors);
        this.candidateError = this.supplierMrtService.getMrtWarehouseDetailsError();
        this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canNavigate = true;
    });
  }

  onClickBack() {
    this.jumpBack('/piaMrtCaseDetails');
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.supplierMrtService.getCandidate().vendor.apNumber, this.supplierMrtService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( data => {
          this.canNavigate = true;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
      });
  }

  private saveAndCompletePiaTask() {
    this.candidateService.saveCandidate(this.supplierMrtService.getCandidate()).subscribe(savedCandidate => {
      this.supplierMrtService.setCandidate(savedCandidate);
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_SCALE_PLU_APPROVE_YES, 'Successfully completed task.');
    });
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case SupplierMrtService.UPC_ITEM_CODES:
        this.jumpBack('/piaSetupMrt');
        break;
      case SupplierMrtService.NEW_ITEM_SETUP:
        this.jumpBack('/piaSetupNewMrtItems');
        break;
      case SupplierMrtService.MRT_DETAILS:
        this.onClickBack();
        break;
      case SupplierMrtService.EXTENDED_ATTRIBUTES:
        this.onClickNext();
        break;
    }
  }

  onClickNext() {
    this.canNavigate = false;
    this.supplierMrtService.getCandidateProduct(0).warehouses = this.getSelectedWarehouses();
    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(),
      [CandidateValidatorType.WAREHOUSE_VALIDATOR, CandidateValidatorType.BICEP_VALIDATOR]).subscribe(() => {
      this.candidateError = this.supplierMrtService.setMrtWarehouseDetailsError(new CandidateError());
      this.supplierMrtService.saveMrtCandidate().subscribe((result) => {
        this.supplierMrtService.saveCandidateAndNavigate('/piaMrtExtendedAttributes', {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
      });
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierMrtService.setMrtWarehouseDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.supplierMrtService.getCurrentCandidateProductError(this.candidateError);
      }
      this.supplierMrtService.saveMrtCandidate().subscribe(() => {
        this.supplierMrtService.saveCandidateAndNavigate('/piaMrtExtendedAttributes', {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
      });
    });
  }

  jumpBack(urlToNavigate) {
    this.canNavigate = false;
    this.supplierMrtService.getCandidateProduct(0).warehouses = this.getSelectedWarehouses();
    this.supplierMrtService.saveCandidateAndNavigate(urlToNavigate, false);
  }
}
