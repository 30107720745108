import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';
import { AttributeAreaConfig } from 'pm-models';

@Component({
  selector: 'pm-attribute-display-area',
  templateUrl: './attribute-display-area.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-area.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayAreaComponent extends BaseAttributeDisplayComponent implements OnInit {
  @Input()
  attribute: AttributeAreaConfig;

  // ---Length
  lengthValue;
  @Input()
  get lengthModel() {
    return this.lengthValue;
  }
  set lengthModel(val) {
    this.lengthValue = val;
    this.calcArea();
    this.lengthModelChange.emit(this.lengthValue);
  }
  @Output() lengthModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() lengthFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() lengthBlur?: EventEmitter<any> = new EventEmitter<any>();

  onLengthBlur(event) {
    this.lengthBlur.emit(event);
    this.lengthModelChange.emit(this.lengthModel);
  }

  onLengthFocus(event) {
    this.lengthFocus.emit(event);
  }

  @Input()
  public attributeLengthError?: any;

  // --- Width
  widthValue;
  @Input()
  get widthModel() {
    return this.widthValue;
  }
  set widthModel(val) {
    this.widthValue = val;
    this.calcArea();
    this.widthModelChange.emit(this.widthValue);
  }
  @Output() widthFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() widthBlur?: EventEmitter<any> = new EventEmitter<any>();
  @Output() widthModelChange?: EventEmitter<any> = new EventEmitter<any>();

  onWidthBlur(event) {
    this.widthBlur.emit(event);
    this.widthModelChange.emit(this.widthModel);
  }

  onWidthFocus(event) {
    this.widthFocus.emit(event);
  }

  @Input()
  public attributeWidthError?: any;

  // --- Volume
  areaValue;
  @Input()
  get areaModel() {
    return this.areaValue;
  }
  set areaModel(val) {
    this.areaValue = val;
    this.areaModelChange.emit(this.areaValue);
  }
  @Output() areaModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public attributeAreaError?: any;

  constructor() {
    super();
  }

  ngOnInit() {
    this.calcArea();
  }
  onKeyPress(event) {
    const pattern = /^\d+$/;
    return pattern.test(event.key);
  }

  calcArea() {
    const vol = this.lengthModel * this.widthModel;
    if (vol > 0) {
      this.areaModel = vol;
    } else {
      this.areaModel = '';
    }
  }
}
