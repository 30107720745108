import {Component, OnInit} from '@angular/core';
import {Candidate, CandidateError, CandidateProduct, CandidateProductError, CandidateValidatorType, Product} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {GrowlService} from '../../growl/growl.service';
import {AssociateUpcService} from '../../service/associate-upc.service';
import {UUID} from 'angular2-uuid';
import {UPCInputState} from 'pm-components';
// tslint:disable-next-line:max-line-length
import {AssociateUpcStepperComponent} from '../../shared/components/associate-upc-stepper/associate-upc-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {finalize} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-associate-case-selection',
  templateUrl: './associate-case-selection.component.html',
  styleUrls: ['./associate-case-selection.component.scss']
})
export class AssociateCaseSelectionComponent implements OnInit {


  public isViewingPage = true;
  private taskSubscription$: any;
  public canClickSubmit = true;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public upcState: UPCInputState;
  private currentCandidateProductIndex = 1;
  private searchedCandidateProductIndex = 0;
  public productData: Product;
  public caseUpcData = [];
  public selectedCasePack: number;
  canNavigate = true;

  constructor(private route: ActivatedRoute, private workflowService: WorkflowService, public associateUpcService: AssociateUpcService,
              private router: Router, private candidateService: CandidateService, private lookupService: LookupService,
              private growlService: GrowlService, private candidateUtilService: CandidateUtilService,
              private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.associateUpcService.getTaskId(),
        this.associateUpcService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.associateUpcService.resetService();
        this.associateUpcService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.ASSOCIATE_UPC) {
            this.associateUpcService.setOriginalAndCurrentCandidate(candidate);
            this.candidateError = this.associateUpcService.getAssociateCasePackDetailsError();
            this.candidateProductError = new CandidateProductError();
            this.setProductData();
            this.setCasePack();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.associateUpcService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.associateUpcService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    if (this.associateUpcService.candidate.candidateProducts.length > 1) {
      return this.associateUpcService.candidate.candidateProducts[this.currentCandidateProductIndex];
    } else {
      const candidateProduct = new CandidateProduct();
      candidateProduct.id = UUID.UUID();
      candidateProduct.candidateProductType = CandidateProduct.ASSOCIATE_UPC;
      return candidateProduct;
    }
  }
  setProductData() {

    if (this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_UPC) {
      this.lookupService.getProductByUpcAndApNumbers(
        this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].upc,
        []).subscribe((productData) => {
        this.productData = productData;
        this.associateUpcService.candidate.productId = productData.productId;
        this.setCaseUpcData(productData);
      });
    } else if (this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_ITEM) {
      this.lookupService.getProductByItemCodeAndApNumbers(
        this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, [])
        .subscribe((productData) => {
          this.productData = productData;
          this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].upc = productData.primaryScanCodeId;
          this.setCaseUpcData(productData);
        });
    }
  }

  initializeData() {
    this.candidateError = this.associateUpcService.getAssociateCasePackDetailsError();
    this.candidateProductError = this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id] ?
      this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id] : new CandidateProductError();
    this.setProductData();
  }

  setCaseUpcData(productData: any) {
    this.caseUpcData = productData.items;
    this.setCasePack();
  }

  onClose() {
    this.isViewingPage = false;
    this.setProductCasePack();
    this.associateUpcService.saveCandidateAndClose();
  }

  onClickBack() {
    this.jumpBack('/associateBasicItemSetup');
  }

  onClickNext() {
    const url = this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT') ? '/associateExtendedAttributes' : '/associateSupplierReview';
    this.jumpToPage(url);
  }

  jumpToPage(urlToNavigate) {
    this.canNavigate = false;
    this.setNewCandidateProduct();
    this.setProductCasePack();
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), [CandidateValidatorType.PIA_ASSOCIATE_CASE_PACK_DETAILS_VALIDATOR,
      CandidateValidatorType.SUPPLIER_COMMENT_VALIDATOR]).subscribe(() => {
      this.candidateError = this.associateUpcService.setAssociateCasePackDetailsError(new CandidateError());
      this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      this.associateUpcService.scrollToTop();
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        this.candidateError = this.associateUpcService.setAssociateCasePackDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id];
        this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      } else {
        this.canNavigate = true;
      }
    });
  }

  setNewCandidateProduct() {
    if (this.associateUpcService.getCandidate().candidateProducts.length > 1) {
      this.associateUpcService.getCandidate().candidateProducts[1] = this.getCurrentCandidateProduct();
    } else {
      this.associateUpcService.getCandidate().candidateProducts.push(this.getCurrentCandidateProduct());
    }
  }

  selectedCaseChange(event, casePack) {
    if (!event) {
      this.selectedCasePack = undefined;
    } else {
      this.selectedCasePack = casePack.caseUpc;
    }
  }

  getSelectedCasePack() {
    return this.selectedCasePack;
  }

  setCasePack() {
    for (let x = 0; x < this.caseUpcData.length; x++) {
      if (this.caseUpcData[x].caseUpc === this.getCurrentCandidateProduct().caseUpc) {
        this.selectedCasePack = this.getCurrentCandidateProduct().caseUpc;
      }
    }
  }


  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case AssociateUpcStepperComponent.ORIGINAL_UPC_ITEM_CODE: {
        this.jumpBack('/setupAssociateUpc');
        break;
      }
      case AssociateUpcStepperComponent.ITEM_DETAILS: {
        this.onClickBack();
        break;
      }
      case AssociateUpcStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/associateExtendedAttributes');
        break;
      }
      case AssociateUpcStepperComponent.REVIEW: {
        this.jumpToPage('/associateSupplierReview');
        break;
      }
    }
  }

  jumpBack(urlToNavigate) {
    this.canNavigate = false;
    this.setProductCasePack();
    this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
      finalize(() => this.canNavigate = true)
    ).subscribe();
  }

  setProductCasePack() {
    for (const candidateProduct of this.associateUpcService.getCandidate().candidateProducts.slice(1)) {
      candidateProduct.caseUpc = this.getSelectedCasePack();
    }
  }
}
