import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UpcService} from '../../services/upc-service/upc.service';
import {calculateCheckDigit} from '../../../../../../../src/app/2.0.0/shared/upc.utils';

export enum UPCInputState {
  none,
  loading,
  valid,
  invalid
}

@Component({
  template: ``,
  styles: []
})
export class BaseUpcInputComponent implements OnInit {
  // Models
  @Input()
  upcModel: number;

  checkDigitValue;
  @Input()
  get checkDigitModel() {
    return this.checkDigitValue;
  }
  set checkDigitModel(val) {
    if (val === undefined || val === null) {
      this.checkDigitValue = null;
    } else if (this.checkDigitValue === undefined || this.checkDigitValue === null || +val !== +this.checkDigitValue) {
      this.checkDigitValue = val;
      this.checkDigitModelChange.emit(this.checkDigitValue);
    }
  }

  // Attributes
  @Input()
  readOnly: boolean;

  @Input()
  readOnlyCheckDigit: boolean;

  @Input()
  disabled: boolean;

  @Input()
  hasError: boolean;

  @Input()
  placeholderText: string;

  @Input()
  checkDigitPlaceholderText: string;

  @Input()
  autoCheckDigit: boolean;

  // State dictates the loading indicator on the right side of the text box
  @Input()
  state: UPCInputState;

  // Outputs
  @Output() upcModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkDigitModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateUPC?: EventEmitter<any> = new EventEmitter<any>();
  @Output() shiftEnterEmitted?: EventEmitter<any> = new EventEmitter<any>();

  constructor(private upcService: UpcService) {}

  get modelDisplay(): string {
    if (this.upcModel) {
      if (this.autoCheckDigit) {
        this.checkDigitModel = calculateCheckDigit(this.upcModel);
      }
      return this.upcService.getG14Upc(this.upcModel);
    }
    this.checkDigitModel = null;
    return '';
  }

  ngOnInit() {}
  upcKeyDown(event) {
    const ESCAPE_KEYBOARD_KEYS = [9, 13, 27];
    if (ESCAPE_KEYBOARD_KEYS.indexOf(event.keyCode) !== -1) {
      this.validateUPC.emit(event);
    }
  }
  checkDigitKeyDown(event) {
    const SHIFT_KEY = 13;
    if (event.shiftKey && event.keyCode === SHIFT_KEY) {
      this.shiftEnterEmitted.emit(event);
    } else {
      this.upcKeyDown(event);
    }
  }
  upcBlur(event) {
    if (this.checkDigitModel && this.upcModel) {
      this.validateUPC.emit(event);
    }
  }
  upcChange(event) {
    this.upcModel = event;
    if (this.autoCheckDigit) {
      this.checkDigitModel = calculateCheckDigit(this.upcModel);
    }
    this.upcModelChange.emit(event);
  }
}
