<pm-editor *ngIf="isViewingPage && bonusSizeService.currentCandidate" (closeEvent)="onClose()">
  <pm-header>
    <app-pia-bonus-stepper *ngIf="bonusSizeService.getCurrentCandidate()"
                           [candidateErrors]="bonusSizeService.getAllCandidateErrors()"
                           (indexClick)="onClickStepper($event)"
                           [isReplacementUpc]="bonusSizeService.isReplacementUpc" [stepIndex]="1"
                           [steps]="bonusSizeService.getPiaStepperSteps()">
    </app-pia-bonus-stepper>
  </pm-header>

  <pm-title></pm-title>
  <div class="col-md-10" style="margin-top: 25px; margin-left: 5px;">
    <div class="row">
      <label>Provide a nickname for this so it’s easy to find in PAM. We’ll only use this internally.</label>
    </div>
    <div class="row">
      <input id="descriptionId" pInputText
             [ngClass]="{'ui-inputtext-invalid' : bonusSizeService.candidateError.description}"
             placeholder="Enter a name or short description..."
             type="text"
             maxlength="100"
             minlength="1"
             accept-charset="UTF-8"
             [(ngModel)]="bonusSizeService.currentCandidate.description"
             style="width: 80%;">
    </div>
    <div class="row mt-0" *ngIf="bonusSizeService.setupBonusSizeError.description">
      <p class="error-message">{{bonusSizeService.setupBonusSizeError.description}}</p>
    </div>
  </div>

  <div class="col-md-9 attributes-stacked mt-4" style="padding-left: 0; padding-right: 60px;">
    <pm-attribute-display-typeahead [attribute]="warehouseSupplierConfiguration" [(model)]="bonusSizeService.currentCandidate.lane"
                                    [attributeError]="bonusSizeService.setupBonusSizeError?.lane" (selection)="warehouseSupplierChange($event)"  >
    </pm-attribute-display-typeahead>
  </div>

  <div class="col-md-10" style="margin-bottom: 100px;">
    <div *ngIf="!bonusSizeService.isReplacementUpc" class="row">
      <label>Find the original, <b>non-bonus version</b> of this product.</label>
    </div>
    <div *ngIf="bonusSizeService.isReplacementUpc"  class="row">
      <label>Enter the UPC for the product that this replaces.</label>
    </div>
    <div class="row">
      <pm-upc-input
        [(upcModel)]="searchedCode"
        [(checkDigitModel)]="bonusSizeService.currentCandidate.candidateProducts[bonusSizeService.searchedCandidateProductIndex].upcCheckDigit"
        [(upcType)]="upcType"
        [readOnly]="false"
        [disabled]="false"
        [hasError]="hasError()"
        placeholderText="Search for item..."
        checkDigitPlaceholderText="Check #"
        [upcOptions]="upcOptions"
        [state]="upcState"
        (upcModelChange)="setUpcItemCodeAndValidate()"
        (upcTypeChange)="onUpcTypeChange()"
        [hideCheckDigit]="isItemCode()"
        [autoCheckDigit]="true"
        [readOnlyCheckDigit]="true"
        style="width: 80%;">
      </pm-upc-input>
    </div>
    <div class="row" *ngIf="hasError()">
      <p class="error-message">{{getError()}}</p>
    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
