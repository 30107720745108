import {BaseAttribute} from './base-attribute';
import {AttributeTextInputConfig, CandidateProduct} from 'pm-models';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayTextinputComponent} from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class ProductDescription implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Product description',
      description: `Enter a description of this product. The first 25 characters will appear on the customer's printed receipt.`,
      placeholderText: 'Description',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      name: 'description',
      charLimit: 30,
      labelInputGroupClass: 'product-description-label-width',
      ...overrides
    };
  }
  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidateProduct: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidateProduct.description;
    componentInstance.modelChange.subscribe(x => {
      candidateProduct.description = x;
      if (configOverrides) {
        configOverrides.saveDisabled = !candidateProduct.description || candidateProduct.description.trim() === '';
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidateProduct: CandidateProduct) {
    if (componentInstance.model !== candidateProduct.description) {
      componentInstance.model = candidateProduct.description;
    }
  }
}
