import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  Attribute,
  AttributeConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateProduct,
  Commodity,
  Product,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {GrowlService} from '../growl/growl.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {CandidateService} from '../service/candidate.service';
import {CostService} from '../service/cost.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {LookupService} from '../service/lookup.service';
import {ProductService} from '../service/product.service';
import {WorkflowService} from '../service/workflow.service';
import {SupplierMrtService} from '../service/supplier-mrt.service';
import {finalize, tap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {ReviewComponent} from 'pm-components';
import {CandidateHistoryService} from '../service/candidate-history.service';
import {MatUtilService} from '../service/mat-util.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {FileService} from '../service/file.service';

@Component({
  selector: 'app-pia-mrt-review-closed',
  templateUrl: './pia-mrt-review-closed.component.html',
  styleUrls: ['./pia-mrt-review-closed.component.scss']
})
export class PiaMrtReviewClosedComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public PRICE_REQUIRED = 'Price Required';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public commodities: any;
  public subCommodities: any;
  public warehouses = [];
  public merchandiseTypes: any;
  public isViewingPage = true;
  public orderRestrictions: any[];
  public warehouseData = [];
  public productImages = [];
  public packageTypes: any;
  public unitsOfMeasures: any;
  public mrtExistingInners: ExistingInner[] = [];
  public mrtCandidateInners: CandidateInner[] = [];
  private decimalCount = 1;
  // undefined if not yet calculated, false if is missing data,
  // true if all data present
  public isNotMissingCandidateInfo: Boolean;
  public isLoadingInners = true;
  showHistoryPanel: boolean = false;

  public showMatAttributes = false;
  public isLoadingMatData = true;
  public globalAttributes: Attribute[] = [];
  public warehouseItemAttributes: Attribute[] = [];

  constructor(private route: ActivatedRoute, private workflowService: WorkflowService,
              private router: Router, private candidateService: CandidateService, private lookupService: LookupService,
              public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public productService: ProductService,
              public candidateUtilService: CandidateUtilService, public supplierMrtService: SupplierMrtService,
              public candidateHistoryService: CandidateHistoryService, public matUtilService: MatUtilService,
              public permissionService: NgxPermissionsService, public fileService: FileService) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.MRT) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.candidateHistoryService.setInitialValues(candidate);
            this.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
      this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
        this.packageTypes = packageTypes;
      });
      this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
        this.unitsOfMeasures = unitsOfMeasures;
      });
    });
  }

  private setInitialValues(candidate: any) {
    this.candidate = candidate;
    this.candidateProduct = this.candidate.candidateProducts[0];
    this.isViewingPage = true;

    forkJoin([this.supplierMrtService.setCandidateInnerCandidates(this.candidate),
      this.supplierMrtService.setCandidateExistingInnerProducts(this.candidate)])
      .pipe(
        tap(() => {
            this.handleExistingInners();
            this.handleActivatedCandidateInners();
            this.handleNonActivatedCandidateInners();
            this.isLoadingInners = false;
          }
        )
      ).subscribe();
    this.getOrderRestrictions();
    this.getWarehouses(candidate);

    if (this.candidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }

    if (candidate.brand && candidate.brand.brandId !== undefined) {
      this.setBrandAndCostOwners(candidate.brand.brandId);
    }
    if (candidate.buyer.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }
    if (this.candidate.vendor.apNumber !== undefined) {
      this.setVendor(this.candidate.vendor.apNumber);
    }

    this.setupMatAttributes();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }

    if (this.candidateUtilService.isInProgressCandidate(this.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatAttributesAndValues(this.candidate, this.globalAttributes, []).pipe(
        tap(() => {
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.globalAttributes,
            [], this.warehouseItemAttributes, []);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })
      ).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.candidateProduct.globalAttributes, [],
        this.warehouseItemAttributes, []);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }


  private handleActivatedCandidateInners() {
    const activatedCandidateInners: CandidateInner[] = this.candidate.mrtInfo?.candidateInners
      ?.filter(candidateInner => candidateInner.candidate && candidateInner.candidate.status === Candidate.ACTIVATED);

    if (!!activatedCandidateInners?.length) {
      const upcs: number[] = activatedCandidateInners.map(activatedCandidateInner =>
        activatedCandidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate)].upc);
      this.lookupService.getProductsByUpcs(upcs).pipe(
        tap((products: Product[]) => {
          activatedCandidateInners.forEach(activatedCandidateInner => {
            const index = CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate);
            const upc = activatedCandidateInner.candidate.candidateProducts[index].upc;
            const existingInner = new ExistingInner();
            existingInner.product = this.productService.getProductByUpc(upc, products);
            existingInner.unitCost = activatedCandidateInner.unitCost;
            existingInner.quantity = activatedCandidateInner.quantity;
            existingInner.upc = upc;
            existingInner.upcCheckDigit = activatedCandidateInner.candidate.candidateProducts[index].upcCheckDigit;
            this.mrtExistingInners.push(existingInner as ExistingInner);
          });
        })
      ).subscribe();
    }
  }

  private handleNonActivatedCandidateInners() {
    let hasRetail = true;
    if (!!this.candidate.mrtInfo?.candidateInners?.length) {
      this.candidate.mrtInfo.candidateInners.forEach(candidateInner => {
        if (candidateInner.candidate && candidateInner.candidate.status !== Candidate.ACTIVATED) {
          if (!candidateInner.candidate.retailType) {
            candidateInner.candidate.retailType = CostService.KEY_RETAIL;
          }
          // if the candidate is non replenishable, the unit cost is never calculated by supplier
          // due to lack of fields. So use the value from the mrt.
          if (!candidateInner.replenishable) {
            candidateInner.candidate.unitCost = candidateInner.unitCost;
            this.mrtCandidateInners.push(candidateInner as CandidateInner);
          } else {
            this.mrtCandidateInners.unshift(candidateInner as CandidateInner);
          }
          // if the candidate doesn't have retail, set isNotMissingCandidateInfo to false.
          if (candidateInner.candidate.retailType === CostService.PRICE_REQUIRED || !candidateInner.candidate.retailPrice || !candidateInner.candidate.retailXFor) {
            hasRetail = false;
          }
        }
      });
    }
    this.isNotMissingCandidateInfo = hasRetail;
  }

  private handleExistingInners() {
    if (!!this.candidate.mrtInfo?.existingInners?.length) {
      this.candidate.mrtInfo.existingInners.forEach(existingInner => {
        this.mrtExistingInners.push(existingInner);
      });
    }
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, don't add to warehouse list.
    if (this.candidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  /**
   * Sets available selectable cost owners
   * @param vendorId the vendorId id.
   */
  private setVendor(vendorId) {
    this.lookupService.findVendor(vendorId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.vendor.apNumber === data[index].apNumber) {
          this.candidate.vendor.lanes = data[index].lanes;
          break;
        }
      }
    });
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
  }

  /**
   * Sets available selectable cost owners
   * @param brandId the brandId id.
   */
  private setBrandAndCostOwners(brandId) {
    this.lookupService.findBrandsById(brandId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.brand.brandId === data[index].brandId) {
          this.candidate.brand.costOwners = data[index].costOwners;
          this.candidate.brand.subBrands = data[index].subBrands;
          break;
        }
      }
    });
  }


  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.candidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.candidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      if (!this.candidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
  }

  getTitleSubHeading(): String {
    if (!this.candidate) {
      return '';
    }
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.candidate.status);
    if (this.candidateProduct.itemCode) {
      infoString += ' | Item code: ' + this.candidateProduct.itemCode;
    }

    return infoString;
  }

  getReviewClass(): string {
    let classString = '';
    classString += ' review-grid-container';
    return classString;
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks'], { queryParams: { tabIndex: 1 } }).then();
  }

  onClickPrint() {
    window.print();
  }

  private resetInitialValues() {
    this.isViewingPage = false;
    this.candidate = undefined;
    this.candidateProduct = undefined;

    this.productImages = [];
  }

  onClose() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  getMasterPack() {
    let masterPack = 0;
    if (this.candidate.mrtInfo.existingInners && this.candidate.mrtInfo.existingInners.length > 0) {
      for (let x = 0; x < this.candidate.mrtInfo.existingInners.length; x++) {
        masterPack += this.candidate.mrtInfo.existingInners[x].quantity;
      }
    }
    if (this.candidate.mrtInfo.candidateInners && this.candidate.mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.candidate.mrtInfo.candidateInners.length; x++) {
        masterPack += this.candidate.mrtInfo.candidateInners[x].quantity;
      }
    }
    return masterPack;
  }

  getCostDisplay() {
    // if we haven't deduced whether there's missing information, return empty string
    if (this.isNotMissingCandidateInfo === null || this.isNotMissingCandidateInfo === undefined) {
      return '';
      // if we're missing information, return message.
    } else if (this.isNotMissingCandidateInfo === false) {
      return 'We can’t calculate margin and penny profit because we’re missing retail information for one or more of the UPCs above.';
    }
  }

  getPennyProfit() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.candidate.masterListCost);

    if (!pennyProfit) {
      return '';
    }
    return this.costService.toCurrency(pennyProfit);
  }

  isMarginNegativeOrZero() {
    const margin = +this.getMarginPercent();
    return margin <= 0;
  }

  getMarginPercent() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.candidate.masterListCost);

    if (!pennyProfit) {
      return '';
    }
    const marginPercent = this.candidateUtilService.getMRTMarginPercent(pennyProfit, masterSuggestedRetail);
    return marginPercent.toPrecision(6);
  }

  toLowerCase(status: String) {
    return status.toLowerCase();
  }

  isRejected() {
    return (CandidateUtilService.getUIStatusFromCandidateStatus(this.candidate.status) === 'Rejected');
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

}
