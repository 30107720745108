<p-autoComplete appendTo="body" #autoComplete inputId="{{id}}" [ngModel]="displayModel" [suggestions]="options"
                (completeMethod)="search($event)" placeholder="{{placeholder}}" field="{{displayRef}}" (onFocus)="onFocus($event)"
                [styleClass]="getStyleClass()" [inputStyleClass]="getInputStyleClass()" [disabled]="isInputDisabled()"
                [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" (ngModelChange)="onModelChange($event)" (onSelect)="onSelect($event)" (onClear)="onClear()"
                (onUnselect)="onClear()" (onBlur)="onBlur()"
                [autoHighlight]="true" [dropdown]="isDropDown" tabindex="{{tabIndex}}"
                [multiple]="showMultiSelectedFormat()">

  <!--  display the selected value for when showMultiSelectedFormat() is true (else controlled by p-autocomplete) -->
  <ng-template let-item pTemplate="selectedItem">
    <div *ngIf="!isPendingModel()" class="ui-autocomplete-token-label">
      {{item[displayRef]}}
      <div class="ui-autocomplete-token-item-description" *ngIf="showAddlInfo">
        {{item[displaySubRef]}}
      </div>
    </div>
    <div *ngIf="isPendingModel()" class="ui-autocomplete-token-label">
      {{item[displayRef]}}
      <div class="ui-autocomplete-token-item-description" *ngIf="showAddlInfo">
        {{item[displaySubRef]}}
      </div>
    </div>
  </ng-template>

  <!-- type ahead options -->
  <ng-template let-item pTemplate="item" let-index="index">
    <div *ngIf="showDefaultOption(index)" class="ui-helper-clearfix">
      <div>
        {{item[displayRef]}}
      </div>
      <div class="ui-autocomplete-list-item-description" *ngIf="showAddlInfo">
        {{item[displaySubRef]}}
      </div>
    </div>
    <div *ngIf="isShowingButtonOption(index)" class="typeahead-create-button">
      <button style="text-decoration: none !important;" class="btn btn-link p-0 m-0">{{buttonLabel}}</button>
    </div>
  </ng-template>

</p-autoComplete>
