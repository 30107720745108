import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'emptyToValue'
})
export class EmptyToValuePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, valueToReplaceNull: string): string {
    if (value !== null && value !== undefined && value !== '') {
      return value;
    }
    return valueToReplaceNull;
  }

}
