<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">
  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label">
      <label>{{attribute.label}}</label>
    </div>
    <div class="attribute-control">
      <div class="attribute-radios" [ngClass]="attribute.inputGroupClass">
        <p-radioButton *ngFor="let option of options" [name]="attribute.name" [id]="option.value" [value]="option.value"
                       [disabled]="isOptionDisabled(option)" [hidden]="isOptionHidden(option)" [label]="option.label"
                       class="attribute-radio " [(ngModel)]="stringModel" (ngModelChange)="onChange($event)">
        </p-radioButton>
      </div>
      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="attributeError" class="attribute-error-message">
        {{attributeError}}
      </p>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
