import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Attribute, AttributeConfig, Candidate, CandidateHelper, CandidateProduct, LocationGroupStores} from 'pm-models';
import {AuthService} from '../auth/auth.service';
import {GrowlService} from '../growl/growl.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {CandidateService} from '../service/candidate.service';
import {CostService} from '../service/cost.service';
import {FileService} from '../service/file.service';
import {LookupService} from '../service/lookup.service';
import {SupplierProductService} from '../service/supplier-product.service';
import {ReviewComponent, UpcService} from 'pm-components';
import {MatUtilService} from '../service/mat-util.service';
import {LabelInsightService} from '../service/label-insight.service';
import {finalize, tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {CandidateHistoryService} from '../service/candidate-history.service';

@Component({
  selector: 'app-procurement-support-product-review-closed',
  templateUrl: './procurement-support-product-review-closed.component.html',
  styleUrls: ['./procurement-support-product-review-closed.component.scss']
})
export class ProcurementSupportProductReviewClosedComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  candidate: Candidate;
  isViewingPage = false;
  productImages = [];
  labelInsightImages = [];
  candidateProduct: CandidateProduct;
  decimalCount = 1;
  piaName: string;
  public hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  showMatAttributes = false;
  isLoadingMatData = true;
  hierarchyAttributes: Attribute[] = [];
  globalAttributes: Attribute[] = [];
  productAttributes: Attribute[] = [];
  warehouseItemAttributes: Attribute[] = [];
  upcAttributes: Attribute[] = [];
  showHistoryPanel: boolean = false;

  DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';

  public suppliers = new Map();

  constructor(public route: ActivatedRoute, public router: Router, public candidateService: CandidateService,
              public growlService: GrowlService, public costService: CostService, public candidateUtilService: CandidateUtilService,
              public fileService: FileService, public authService: AuthService, public supplierProductService: SupplierProductService,
              public lookupService: LookupService, public upcService: UpcService, public matUtilService: MatUtilService,
              public labelInsightService: LabelInsightService, public permissionService: NgxPermissionsService,
              public candidateHistoryService: CandidateHistoryService) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.NEW_PRODUCT && candidate.candidateType !== Candidate.PLU) {
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.setInitialValues(candidate);
            this.candidateHistoryService.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: Candidate) {
    this.candidate = candidate;
    this.candidateProduct = this.candidate.candidateProducts[0];
    this.isViewingPage = true;

    this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].labelInsightsImageLinks);

    this.piaName = this.authService.getUser();

    this.setDefaultVertexTaxCategory(candidate);

    if (this.candidate.dsdSwitch) {
      this.setAuthGroups();
    }

    this.setupMatAttributes();
  }


  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }
    if (this.candidateUtilService.isInProgressCandidate(this.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.setMatAttributesListsIfNotPresent(this.candidate, this.globalAttributes,
        this.hierarchyAttributes).pipe(
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.hierarchyAttributes,
            this.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.globalAttributes,
            this.productAttributes, this.warehouseItemAttributes, this.upcAttributes);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })
      ).subscribe();
    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.candidateProduct.globalAttributes,
        this.productAttributes, this.warehouseItemAttributes, this.upcAttributes);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  private resetInitialValues() {
    this.isViewingPage = false;
    this.candidate = undefined;
    this.candidateProduct = undefined;

    this.productImages = [];
  }

  onClose() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }

  getProductInfoString() {
    let infoString = '';

    infoString += this.candidate.retailSize + ' | ' + 'Inner case cost: ' +
      this.costService.toCurrencyForCost(this.candidate.innerListCost) + ' |  Unit cost: ' +
      this.costService.toCurrencyForCost(this.candidate.unitCost);

    if (this.isSellable) {
      infoString +=
        ' | Suggested Retail: '  +
        this.candidate.suggestedXFor + ' for ' + this.costService.toCurrency(this.candidate.suggestedRetailPrice);
    }

    return infoString;
  }

  getSellableString(): string {
    return this.candidate.productType === 'SELLABLE' ? 'Yes' : 'No';
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.piaComment;
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks'], { queryParams: { tabIndex: 1 } }).then();
  }

  onClickPrint() {
    window.print();
  }

  getTitleSubHeading(): String {
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.candidate.status);
    if (this.candidateProduct.itemCode) {
      infoString += ' | Item code: ' + this.candidateProduct.itemCode;
    }

    return infoString;
  }

  private setDefaultVertexTaxCategory(candidate: Candidate) {
    // initialize it to the default category if it's not set
    if (!candidate.vertexTaxCategory) {
      candidate.vertexTaxCategory = CandidateHelper.getVertexTaxCategory(candidate);
    }
  }

  setAuthGroups() {
    if (!this.supplierProductService.getAuthGroups()) {
      this.supplierProductService.findAllAuthGroupsByParms(this.candidate.vendor.apNumber, this.candidate.commodity.departmentId,
        this.candidate.commodity.subDepartmentId).subscribe((authGroups) => {
        this.supplierProductService.setAuthGroups(authGroups);
        this.loadVendors();
        for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
          this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
      });
    } else {
      this.loadVendors();
      for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
        this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
      }
    }
  }

  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.supplierProductService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.supplierProductService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  loadVendors() {
    for (let x = 0; x < this.candidate.candidateProducts[0].locationGroupStores.length; x++) {
      this.lookupService.findDsdVendor(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber).subscribe(vendor => {
        this.suppliers.set(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber, vendor[0]);
      });
    }
  }

  getVendor(vendorId: number) {
    return this.suppliers.get(vendorId);
  }

  getNumberOfStoresForGroup(groupId) {
    if (this.supplierProductService.authGroups) {
      for (let x = 0; x < this.supplierProductService.authGroups.length; x++) {
        if (this.supplierProductService.authGroups[x].splrLocationGroupId === groupId) {
          return this.supplierProductService.authGroups[x].stores.length;
        }
      }
    }
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }
}
