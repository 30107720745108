import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {AttributeTypeaheadConfig} from 'pm-models';
import {
  BaseAttributeDisplayComponent
} from 'pm-components/lib/shared/attribute-display/base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-plu',
  templateUrl: './attribute-display-plu.component.html',
  styleUrls: ['./attribute-display-plu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayPluComponent extends BaseAttributeDisplayComponent implements OnInit {

  public pluTypes = [{label: 'Checker PLU', value: UpcRequestType.CHECKER},
    {label: 'Scale PLU', value: UpcRequestType.SCALE},
    {label: 'Checker & scale PLU', value: UpcRequestType.CHECKER_AND_SCALE}];

  public upcOptions = [{ label: 'PLU', value: 'PLU' }];
  public pluTypePlaceholderText = 'Select a PLU Type...';
  public canKeep: boolean;
  public pluRangeId;

  @Output() pluRangeModelChange?: EventEmitter<any> = new EventEmitter<any>();


  pluRangeConfiguration: AttributeTypeaheadConfig = {
    description: ``,
    isDisabled: () => this.isPluTypeDisabled(),
    isReadOnly: () => false,
    name: 'pluRangeId',
    idRef: 'pluRangeId',
    displayRef: 'displayName',
    searchUrl: '/lookup/pluRange',
    placeholderText: 'Select range...',
  };

  constructor() {
    super();
  }

  ngOnInit() {
    this.canKeep = !!this.model.pluRange;
    this.model.keepPlu = true;
    this.pluRangeId = this.model.pluRange?.pluRangeId;
  }

  isPluTypeDisabled() {
    return false;
  }

  onRangeSelection(event) {
    // if they clear out the range or there's no current range, they can't keep the upc.
    if (!event || !this.pluRangeId) {
      this.canKeep = false;
    } else {
      this.canKeep = event.pluRangeId === this.pluRangeId;
    }
    if (!this.canKeep) {
      this.model.keepPlu = false;
    }
    this.model.pluRange = event;
    this.pluRangeModelChange.emit(event);
  }
}
