<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">

  <h2 class="mt-2 mb-4">Choose a Tax Category</h2>

  <div class="row">
    <div class="col-md-12">
      <div class="input-group">
        <input class="form-control py-2 border-right-0 border-bottom-0 rounded-0 border" type="search" placeholder="Search..." id="tax-category-search-input" [(ngModel)]="searchText">
        <span class="input-group-append">
          <div class="border-bottom-0 rounded-0 input-group-text bg-transparent"><i class="pi pi-search"></i></div>
        </span>
      </div>
    </div>
  </div>

  <div class="mb-2 box border border-dark " *ngIf="(attribute.collections | filter: searchText).length > 0; else noResults">

    <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
      <div class="attribute-control">
        <div class="attribute-radios block" [ngClass]="attribute.inputGroupClass">

          <p class="my-2" style="font-size: 12px">RECOMMENDED BASED ON SUB-COMMODITY</p>

          <p-radioButton *ngFor="let option of recommendedCategories | filter: searchText;"
                         [name]="option.displayName" [id]="option.id" [value]="option"
                         [disabled]="isOptionDisabled(option)" [label]="getNonDefaultDisplayName(option)"
                         class="attribute-radio block" [(ngModel)]="model" (ngModelChange)="onChange($event)">
          </p-radioButton>

          <p class="mb-2 mt-4" style="font-size: 12px">ALL OTHER TAX CATEGORIES</p>

          <p-radioButton *ngFor="let option of notRecommendedCategories | filter: searchText;"
                         [name]="option.displayName" [id]="option.id" [value]="option"
                         [disabled]="isOptionDisabled(option)" [label]="getNonDefaultDisplayName(option)"
                         class="attribute-radio block" [(ngModel)]="model" (ngModelChange)="onChange($event)">
          </p-radioButton>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <ng-template #noResults>
    <p class="my-2">No results found for "{{searchText}}".</p>
  </ng-template>
</ng-container>
