import {Injectable} from '@angular/core';
import {Attribute, Candidate, CandidateProduct} from 'pm-models';
import {DialogService} from 'primeng/dynamicdialog';
import {AttributeTypes} from 'pm-components/lib/attributes/attribute-types.enum';
import {AttributeModalComponent} from 'pm-components/lib/attribute-modal/attribute-modal.component';
import {MatUtilService} from './mat-util.service';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';
import {GrowlService} from '../growl/growl.service';

export interface MultiEditConfiguration {
  type: AttributeTypes;
  overrides?: any;
}

@Injectable()
export class EditCandidateModalService {
  readonly defaultModalSettings = {
    showHeader: false,
    contentStyle: { 'max-height': '550px', overflow: 'auto' },
    dismissableMask: false
  };

  constructor(public dialogService: DialogService, private matUtilService: MatUtilService, private growlService: GrowlService) {}

  openModal(type: AttributeTypes, candidate: Candidate | CandidateProduct | Attribute, overrides?: any, overridesForModal?: any) {
    const modalSettings = {...this.defaultModalSettings, ...overridesForModal};

    const modalConfig = {
      ...modalSettings,
      data: { type: type, candidate: { ...candidate }, overrides: overrides }
    };
    const ref = this.dialogService.open(AttributeModalComponent, modalConfig);

    return ref.onClose;
  }

  openMultiEditModal(configs: MultiEditConfiguration[], candidate: Candidate | CandidateProduct, overrides?: any) {
    const modalConfig = {
      ...this.defaultModalSettings,
      data: {
        candidate: { ...candidate },
        configs: configs,
        overrides: overrides
      }
    };

    const ref = this.dialogService.open(AttributeModalComponent, modalConfig);

    return ref.onClose;
  }

  public editMatAttribute(candidateProduct: CandidateProduct, fullAttribute: Attribute, global: boolean,
                          requestNewMatAttributeOverrideWrapper?: RequestNewMatAttributeOverrideWrapper,
                          newAttributeConfirmationPanel?: any, target?: any) {
    let attributeType;
    let modalOverride: any = {};
    let parameterOverrides: any;
    if (MatUtilService.isTypeaheadDisplayType(fullAttribute)) {
      attributeType = AttributeTypes.MatAttributeTypeahead;
      modalOverride = {contentStyle: { 'max-width': '600px', overflow: 'none' }};
      requestNewMatAttributeOverrideWrapper.newAttributeConfirmationPanel = newAttributeConfirmationPanel;
      requestNewMatAttributeOverrideWrapper.target = target;
      parameterOverrides = {
        collections: fullAttribute.domain.values,
        requestNewMatAttributeOverrideWrapper: requestNewMatAttributeOverrideWrapper
      };
    } else if (MatUtilService.isDatePickerDisplayType(fullAttribute)) {
      attributeType = AttributeTypes.MatAttributeDatePicker;
      modalOverride = {contentStyle: { 'overflow': 'inherit' }};
    } else if (MatUtilService.isBooleanRadioInputDisplayType(fullAttribute)) {
      attributeType = AttributeTypes.MatAttributeBooleanRadioInput;
      modalOverride = {contentStyle: { 'max-width': '600px', overflow: 'none' }};
    } else if (MatUtilService.isTextInputDisplayType(fullAttribute)) {
      attributeType = AttributeTypes.MatAttributeTextInput;
      modalOverride = {contentStyle: { 'max-width': '600px', overflow: 'none' }};
    } else {
      this.growlService.addError('Invalid attribute configuration for Attribute: ' + fullAttribute.descriptions?.businessFriendlyDescription
        + '[' + fullAttribute?.identifiers?.fieldId + ']');
      return;
    }
    let currentAttribute;

    if (global) {
      currentAttribute = this.matUtilService.getSelectedGlobalAttributeFromCandidateProduct(candidateProduct, fullAttribute.identifiers.fieldId);
    } else {
      currentAttribute = this.matUtilService.getSelectedCandidateProductHierarchyAttribute(
        candidateProduct, fullAttribute.hierarchyDetails.matHierarchyId, fullAttribute.identifiers.fieldId);
    }

    const currentlyHasAttribute = !!currentAttribute;

    // if the current attribute wasn't currently selected by the user, add it to the candidate.
    if (!currentlyHasAttribute) {
      if (CandidateProduct.ASSOCIATE_UPC === candidateProduct.candidateProductType) {
        currentAttribute = JSON.parse(JSON.stringify(fullAttribute));
      } else {
        currentAttribute = fullAttribute;
      }
      // if there's no value for a current selected boolean attribute, default it to unsure (null vs undefined).
    } else if (currentAttribute.value === undefined && MatUtilService.isBooleanRadioInputDisplayType(currentAttribute)) {
      currentAttribute.value = null;
    }

    this.openModal(attributeType, currentAttribute, parameterOverrides, modalOverride).subscribe(response => {
      if (!response) {
        return;
      }
      currentAttribute.value = response?.value;
      // if there's currently an attribute and the value is valid updating the value will suffice.
      // if the value isn't valid, we need to remove the attribute from the candidate.
      if (currentlyHasAttribute) {
        if (!this.matUtilService.hasValidValueForAttribute(response.value, fullAttribute)) {
         this.matUtilService.removeAttributeByFieldId(candidateProduct, '' + fullAttribute.identifiers.fieldId);
        }
        return;
      }
      if (this.matUtilService.hasValidValueForAttribute(response.value, fullAttribute)) {
        if (global) {
          if (!candidateProduct.globalAttributes) {
            candidateProduct.globalAttributes = [];
          }
          candidateProduct.globalAttributes.push(currentAttribute);
        } else {
          const matHierarchy = this.matUtilService.getMatHierarchyFromCandidateProduct(candidateProduct, fullAttribute.hierarchyDetails.matHierarchyId);
          if (!matHierarchy.attributes) {
            matHierarchy.attributes = [];
          }
          matHierarchy.attributes.push(currentAttribute);
        }
      }
    });
  }

}
