import {Component, OnInit} from '@angular/core';
import {UPCInputState} from 'pm-components';
import {
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Product,
  TextInputType
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {CostService} from '../../service/cost.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {UUID} from 'angular2-uuid';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {ExistingInnerError} from 'pm-models/lib/existingInnerError';
import {CandidateInnerError} from 'pm-models/lib/candidateInnerError';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {ProductService} from '../../service/product.service';
import {switchMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';

@Component({
  selector: 'app-pia-setup-mrt',
  templateUrl: './pia-setup-mrt.component.html',
  styleUrls: ['./pia-setup-mrt.component.scss']
})
export class PiaSetupMrtComponent implements OnInit {


  public upcType = 'UPC';
  public upcState: UPCInputState;
  public upcOptions = [{ label: 'UPC', value: 'UPC' }];
  private taskSubscription$: any;
  public candidateProduct: CandidateProduct = { id: UUID.UUID()};
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public isViewingPage = true;
  public mrtInners: any[] = [];
  public isValidating = false;
  public isLoadingInners = true;
  public canNavigate = false;

  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private growlService: GrowlService, private lookupService: LookupService,
              private costService: CostService, private candidateUtilService: CandidateUtilService, private productService: ProductService) { }

  warehouseSupplierConfiguration: AttributeTypeaheadConfig = {
    label: 'Warehouse supplier',
    description: 'Where the product is shipped from (origin).',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    idRef: 'id',
    displayRef: 'name',
    name: 'warehouseId',
    placeholderText: 'Select a warehouse...',
    searchUrl: '/lookup/lane',
    showAddlInfo: true
  };

  quantityConfiguration: AttributeTextInputConfig = {
    label: '# Sellable units',
    isDisabled: () => this.isValidating,
    isReadOnly: () => false,
    isRequired: true,
    name: 'quantityId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# Units'
  };

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierMrtService.resetService();
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          if (candidate.candidateType === Candidate.MRT) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.resetErrors();
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else if (this.supplierMrtService.getOriginalCandidate() && this.supplierMrtService.getCandidate() && !taskId
        && !this.supplierMrtService.getCandidate().candidateId) {
        this.initializeData();
      } else {
        // if there's a task id and current candidates, get the task by candidate id. If the tasks IDs don't match, reset everything.
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierMrtService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            window.history.replaceState({}, 'Title', '#/piaSetupMrt');
            this.supplierMrtService.resetService();
            this.supplierMrtService.setupNewCandidate();
            this.initializeData();
          }
        });
      }
    });
  }

  onClose() {
    this.isViewingPage = false;
    if (this.supplierMrtService.getTaskIdString()) {
      this.supplierMrtService.saveCandidateAndNavigate('/tasks', null);
    } else {
      if (!this.candidateUtilService.isEmptyOrSpaces(this.supplierMrtService.getCandidate().description)) {
        this.supplierMrtService.createCandidateAndNavigate(this.supplierMrtService.getCandidate(), '/tasks', this.hasVendorChanged());
      }
    }
    this.router.navigate(['/tasks']);
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.supplierMrtService.getOriginalCandidate().vendor) !==
      JSON.stringify(this.supplierMrtService.getCandidate().vendor);
  }


  initializeData() {
    this.candidateError = this.supplierMrtService.getMrtItemError();
    this.candidateProductError = new CandidateProductError();
    this.candidateProduct = { id: UUID.UUID()};
    forkJoin([this.setupCandidateInners(), this.setupExistingInners()]).pipe(
      switchMap(() => this.handleActivatedCandidateInners()),
      tap(() => {
        this.isLoadingInners = false;
        this.canNavigate = true;
      })
    ).subscribe();
  }

  private handleActivatedCandidateInners(): Observable<any> {
    const activatedCandidateInners: CandidateInner[] = this.supplierMrtService.getCandidate().mrtInfo?.candidateInners
      ?.filter(candidateInner => candidateInner.candidate && candidateInner.candidate.status === Candidate.ACTIVATED);

    if (!!activatedCandidateInners?.length) {
      const upcs: number[] = activatedCandidateInners.map(activatedCandidateInner =>
        activatedCandidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate)].upc);
      return this.lookupService.getProductsByUpcs(upcs, [LookupService.PRODUCT_SEARCH_SUPPLY_CHAIN_FILTER, LookupService.PRODUCT_SEARCH_DSD_SUPPLY_CHAIN_FILTER]).pipe(
        tap((products: Product[]) => {
          activatedCandidateInners.forEach(activatedCandidateInner => {
            const index = CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate);
            const upc = activatedCandidateInner.candidate.candidateProducts[index].upc;
            const existingInner = new ExistingInner();
            existingInner.product = this.productService.getProductByUpc(upc, products);
            existingInner.unitCost = activatedCandidateInner.unitCost;
            existingInner.quantity = activatedCandidateInner.quantity;
            existingInner.upc = upc;
            existingInner.upcCheckDigit = activatedCandidateInner.candidate.candidateProducts[index].upcCheckDigit;
            this.mrtInners.push(existingInner as ExistingInner);
          });
        })
      );
    } else {
      return of({});
    }
  }

  setupCandidateInners(): Observable<any> {
    if (!!this.supplierMrtService.getCandidate().mrtInfo?.candidateInners?.length) {
      const candidateIds = this.supplierMrtService.getCandidate().mrtInfo.candidateInners.map(inner => inner.candidateId);
      return this.candidateService.getCandidatesByCandidateIds(candidateIds).pipe(
        tap((candidates: Candidate[]) => {
          this.supplierMrtService.getCandidate().mrtInfo.candidateInners.forEach((candidateInner) => {
            candidateInner.candidate = candidates.find((candidate) => candidate.candidateId === candidateInner.candidateId);
            if (candidateInner.candidate.status !== Candidate.ACTIVATED) {
              this.mrtInners.push(candidateInner as CandidateInner);
              this.setNrCandidateMrt(candidateInner.candidateId);
            }
          });
        })
      );
    } else {
      return of({});
    }
  }

  setupExistingInners() {
    if (!!this.supplierMrtService.getCandidate().mrtInfo?.existingInners?.length) {
      const upcs = this.supplierMrtService.getCandidate().mrtInfo.existingInners.map(existingInner => existingInner.upc);
      return this.lookupService.getProductsByUpcs(upcs, [LookupService.PRODUCT_SEARCH_SUPPLY_CHAIN_FILTER, LookupService.PRODUCT_SEARCH_DSD_SUPPLY_CHAIN_FILTER]).pipe(
        tap((products: Product[]) => {
          const existingInners: ExistingInner [] = [];
          for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.existingInners.length; x++) {
            const existingInner = this.supplierMrtService.getCandidate().mrtInfo.existingInners[x];
            const product = this.productService.getProductByUpc(existingInner.upc, products);

            // if the product no longer exists, add it as a candidate inner.
            if (!product) {
              const candidateProduct: CandidateProduct = { id: UUID.UUID(), upc: existingInner.upc, upcCheckDigit: existingInner.upcCheckDigit };
              this.addNewCandidateInner(candidateProduct);
            } else {
              this.supplierMrtService.getCandidate().mrtInfo.existingInners[x].product = product;
              this.mrtInners.push(existingInner as ExistingInner);
              existingInners.push(existingInner);
            }
          }
          // if any existing inners weren't added, add those that do exist.
          if (this.supplierMrtService.getCandidate().mrtInfo.existingInners.length !== existingInners.length) {
            this.supplierMrtService.getCandidate().mrtInfo.existingInners = existingInners;
          }
        })
      );
    } else {
      return of({});
    }
  }

  warehouseSupplierChange($event) {
    if ($event === undefined) {
      this.supplierMrtService.getCandidate().lane = null;
      this.supplierMrtService.getCandidate().vendor = null;
      return;
    }
    this.supplierMrtService.getCandidate().lane = $event;
    this.supplierMrtService.getCandidate().vendor = this.supplierMrtService.getCandidate().lane.vendor;
  }

  hasError() {
    return this.candidateProductError && (this.candidateProductError.upc || this.candidateProductError.itemCode);
  }

  setUpcItemCodeAndValidate() {
    if (!this.candidateProduct.itemCode && (!this.candidateProduct.upc ||
      this.candidateProduct.upcCheckDigit === null || this.candidateProduct.upcCheckDigit === undefined)) {
      this.upcState = UPCInputState.none;
      this.candidateProductError.upc = null;
      this.candidateProductError.upcCheckDigit = null;
    } else {
      this.validateUPC();
    }
  }

  validateUPC() {
    this.isValidating = true;
    this.upcState = UPCInputState.loading;
    const validationCandidate: Candidate = JSON.parse(JSON.stringify(this.supplierMrtService.getCandidate()));
    validationCandidate.candidateProducts = [this.candidateProduct];
    this.candidateService.validateCandidate(validationCandidate,
      [CandidateValidatorType.MRT_UPC_VALIDATOR]).subscribe((candidate: Candidate) => {

      this.upcState = UPCInputState.valid;
      this.candidateProduct = candidate.candidateProducts[0];
      this.candidateProductError.upc = null;
      this.candidateProductError.upcCheckDigit = null;
      this.candidateProductError.itemCode = null;
      this.isValidating = false;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        // const returnedCandidateProductError =
        this.candidateProductError = returnedCandidateError.candidateProductErrors[this.candidateProduct.id];
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
      this.isValidating = false;
    });

  }

  onClickBack() {
    this.isViewingPage = false;
    if (this.supplierMrtService.getTaskIdString()) {
      this.supplierMrtService.saveCandidateAndNavigate('/setupCandidate', null);
    } else {
      if (!this.candidateUtilService.isEmptyOrSpaces(this.supplierMrtService.getCandidate().description)) {
        this.supplierMrtService.createCandidateAndNavigate(this.supplierMrtService.getCandidate(),
          '/setupCandidate', this.hasVendorChanged());
      }
    }
    this.supplierMrtService.setOriginalAndCurrentCandidate(this.supplierMrtService.getCandidate());
    this.router.navigate(['/setupCandidate']);
  }

  addItem() {
    if (this.upcState && this.upcState !== UPCInputState.valid) {
      return;
    }

    const candidateProductToUse = JSON.parse(JSON.stringify(this.candidateProduct));
    this.resetCandidateProductAndUpcSearchState();

    if (candidateProductToUse.existingUpc) {
      this.addExistingInner(candidateProductToUse);
    } else if (candidateProductToUse.pamCandidateUpc) {
      this.addCandidateInner(candidateProductToUse);
    } else if (candidateProductToUse.availableUpc) {
      this.addNewCandidateInner(candidateProductToUse);
    }
    this.selectUpcInput();
  }

  selectUpcInput() {
    window.getSelection().removeAllRanges();
    const selector: HTMLInputElement = document.getElementsByClassName('upc-input')[0] as HTMLInputElement;
    selector.select();
  }

  resetCandidateProductAndUpcSearchState() {
    this.candidateProduct = new CandidateProduct();
    this.candidateProduct.id = UUID.UUID();
    this.upcState = UPCInputState.none;
  }


  addExistingInner(candidateProduct: CandidateProduct) {
    const existingInner = new ExistingInner();
    existingInner.upc = candidateProduct.upc;
    existingInner.upcCheckDigit = candidateProduct.upcCheckDigit;

    this.lookupService.getProductByUpcAndApNumbers(
      candidateProduct.upc, [], [LookupService.PRODUCT_SEARCH_SUPPLY_CHAIN_FILTER, LookupService.PRODUCT_SEARCH_DSD_SUPPLY_CHAIN_FILTER]).subscribe((productData) => {
      existingInner.product = productData;
      if (!this.supplierMrtService.getCandidate().mrtInfo.existingInners) {
        this.supplierMrtService.getCandidate().mrtInfo.existingInners = [existingInner];
      } else {
        this.supplierMrtService.getCandidate().mrtInfo.existingInners.push(existingInner);
      }
      this.mrtInners.push(this.supplierMrtService.getCandidate().mrtInfo.existingInners[
      this.supplierMrtService.getCandidate().mrtInfo.existingInners.length - 1]);

      this.supplierMrtService.saveMrtCandidate().subscribe(() => {
        this.setMrtInners();
      });
    });
  }

  addCandidateInner(candidateProduct: CandidateProduct) {
    this.candidateService.getCandidatesByUpcForMrtValidation(candidateProduct.upc)
      .subscribe((candidateList: Candidate[]) => {
        const candidateInner = new CandidateInner();
        candidateInner.candidate = candidateList[0];
        candidateInner.candidateId = candidateList[0].candidateId;
        candidateInner.draft = true;
        // if the candidate is a new upc type then it's replenishable
        if (SupplierMrtService.REPLENISHABLE_PRODUCT_TYPES.includes(candidateInner.candidate.candidateType)) {
          candidateInner.replenishable = true;
        }
        if (!this.supplierMrtService.getCandidate().mrtInfo.candidateInners) {
          this.supplierMrtService.getCandidate().mrtInfo.candidateInners = [candidateInner];
        } else {
          this.supplierMrtService.getCandidate().mrtInfo.candidateInners.push(candidateInner);
        }
        this.mrtInners.push(this.supplierMrtService.getCandidate().mrtInfo.candidateInners[
        this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length - 1]);
        this.setNrCandidateMrt(candidateList[0].candidateId);
        this.supplierMrtService.saveMrtCandidate().subscribe(() => {
          this.setMrtInners();
        });
      });
  }

  addNewCandidateInner(candidateProduct: CandidateProduct) {
    candidateProduct.id = UUID.UUID();
    const newPamInnerCandidate = this.supplierMrtService.getNewCandidateInner(candidateProduct);

    this.candidateService.createNewCandidate(newPamInnerCandidate).subscribe((candidate: Candidate) => {
      const candidateInner = new CandidateInner();
      candidateInner.candidate = candidate;
      candidateInner.candidateId = candidate.candidateId;
      if (!this.supplierMrtService.getCandidate().mrtInfo.candidateInners) {
        this.supplierMrtService.getCandidate().mrtInfo.candidateInners = [candidateInner];
      } else {
        this.supplierMrtService.getCandidate().mrtInfo.candidateInners.push(candidateInner);
      }
      this.mrtInners.push(this.supplierMrtService.getCandidate().mrtInfo.candidateInners[
      this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length - 1]);
      this.supplierMrtService.saveMrtCandidate().subscribe(() => {
        this.setMrtInners();
      });
    });
  }

  hasItems() {
    return this.mrtInners.length > 0;
  }

  getItemCodeString(product: Product) {
    if (product.items && product.items.length === 1) {
      return 'Item code: ' + product.items[0].itemCode;
    } else if (product.dsdItems && product.dsdItems.length === 1) {
      return 'Item code: ' + product.dsdItems[0].upc;
    }  else {
      return 'Item code: Multiple (this item has multiple case configurations)';
    }
  }

  isExistingInner(inner) {
    // there's a documented bug for instance of. Sometimes it doesn't recognize the type,
    // even though is in a list of the type.
    return inner instanceof ExistingInner || !!inner.upc;
  }

  isCandidateInner(inner) {
    // there's a documented bug for instance of. Sometimes it doesn't recognize the type,
    // even though is in a list of the type.
    return inner instanceof CandidateInner || !!inner.candidateId;
  }

  getCandidateInnerStatus(inner: CandidateInner) {
    if (SupplierMrtService.REPLENISHABLE_PRODUCT_TYPES.includes(inner.candidate.candidateType)) {
      return 'Draft–UPC';
    } else if (inner.candidate.candidateType === Candidate.MRT_INNER && inner.draft) {
      return 'Draft–UPC';
    } else {
      return 'New–UPC';
    }
  }

  deleteExistingInner(inner: ExistingInner) {
    for (let x = 0; x < this.mrtInners.length; x++) {
      if (this.mrtInners[x].upc && this.mrtInners[x].upc === inner.upc) {
        this.mrtInners.splice(x, 1);
        break;
      }
    }
    for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.existingInners.length; x++) {
      if (this.supplierMrtService.getCandidate().mrtInfo.existingInners[x].upc === inner.upc) {
        this.supplierMrtService.getCandidate().mrtInfo.existingInners.splice(x, 1);
        break;
      }
    }
    this.supplierMrtService.saveMrtCandidate();
  }

  deleteCandidateInner(inner: CandidateInner) {
    for (let x = 0; x < this.mrtInners.length; x++) {
      if (this.mrtInners[x].candidate &&
        this.mrtInners[x].candidate.candidateProducts[0].upc === inner.candidate.candidateProducts[0].upc) {
        this.mrtInners.splice(x, 1);
        break;
      }
    }
    for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
      if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate.candidateProducts[0].upc ===
        inner.candidate.candidateProducts[0].upc) {
        this.supplierMrtService.getCandidate().mrtInfo.candidateInners.splice(x, 1);
        break;
      }
    }
    if (inner.candidate.candidateType === Candidate.MRT_INNER) {
      inner.candidate.status = 'DELETED';
      this.candidateService.saveCandidate(inner.candidate, true).subscribe();
    }
    this.supplierMrtService.saveMrtCandidate();

  }

  onClickNext() {
    if (this.isNextDisabled()) {
      return;
    }
    this.canNavigate = false;

    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_SETUP_MRT_ITEMS_VALIDATOR]).subscribe((candidate: Candidate) => {
      this.candidateError = this.supplierMrtService.setMrtItemError(null);
      this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
      if (this.hasItemsToSetup()) {
        this.supplierMrtService.saveCandidateAndNavigate('/piaSetupNewMrtItems', null);
      } else {
        this.supplierMrtService.saveCandidateAndNavigate('/piaMrtCaseDetails', null);
      }
    }, (error) => {
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error?.candidateErrors) {
        this.candidateError = this.supplierMrtService.setMrtItemError(error.error.candidateErrors);
        this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
        if (this.hasItemsToSetup()) {
          this.supplierMrtService.saveCandidateAndNavigate('/piaSetupNewMrtItems', null);
        } else {
          this.supplierMrtService.saveCandidateAndNavigate('/piaMrtCaseDetails', null);
        }
      } else {
        this.canNavigate = true;
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  getExistingInnerError(upc): ExistingInnerError {

    if (this.candidateError) {
      return this.candidateError.mrtInfoError.existingInnerErrors[upc];
    }
    return null;
  }
  getCandidateInnerError(candidateId): CandidateInnerError {

    if (this.candidateError) {
      return this.candidateError.mrtInfoError.candidateInnerErrors[candidateId];
    }
    return null;
  }

  isNextDisabled() {
    return !this.mrtInners || this.mrtInners.length < 2 || !this.canNavigate;
  }

  hasItemsToSetup() {
    if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners &&
      this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
        if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate.candidateType === Candidate.MRT_INNER) {
          // if there's inners that aren't drafts (so created by this mrt)
          if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate.candidateType === Candidate.MRT_INNER
            && !this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].draft) {
            return true;
          }
        } else if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate.candidateType === Candidate.NEW_PRODUCT) {
          // draft candidate
          this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].replenishable = true;
        }
      }
    }
    return false;
  }

  setNrCandidateMrt(candidateId: number) {
    this.candidateService.findParentMrtCandidatesForCandidateId(candidateId, [Candidate.IN_PROGRESS]).subscribe((results) => {
      if (results === undefined || results === null) {
        return;
      }
      let mrtParentCandidate: Candidate = results[0];
      if (mrtParentCandidate.candidateId === this.supplierMrtService.getCandidate().candidateId) {
        if (results.length === 1) {
          return;
        }
        mrtParentCandidate = results[1];
      }

      for (let x = 0; x < mrtParentCandidate.mrtInfo.candidateInners.length; x++) {
        if (mrtParentCandidate.candidateId !== this.supplierMrtService.getCandidate().candidateId) {

          if (mrtParentCandidate.mrtInfo.candidateInners[x].candidateId === candidateId &&
            !mrtParentCandidate.mrtInfo.candidateInners[x].replenishable) {

            for (let y = 0; y < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; y++) {
              if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[y].candidate.candidateId === candidateId) {
                this.supplierMrtService.getCandidate().mrtInfo.candidateInners[y].replenishable = false;
                this.supplierMrtService.getCandidate().mrtInfo.candidateInners[y].unitCost =
                  mrtParentCandidate.mrtInfo.candidateInners[x].unitCost;
                this.supplierMrtService.getCandidate().mrtInfo.candidateInners[y].valid =
                  mrtParentCandidate.mrtInfo.candidateInners[x].valid;
                this.supplierMrtService.getCandidate().mrtInfo.candidateInners[y].vendorEdited =
                  mrtParentCandidate.mrtInfo.candidateInners[x].vendorEdited;
              }
            }
          }
        }
      }
    });
  }

  setMrtInners() {
    const tempInners = [];
    for (let x = 0; x < this.mrtInners.length; x++) {
      if (this.isExistingInner(this.mrtInners[x])) {
        const existingInner = this.supplierMrtService.getExistingInnerByUpc(this.mrtInners[x].upc);
        existingInner.product = this.mrtInners[x].product;
        tempInners.push(existingInner);
      } else {
        const candidateInner = this.supplierMrtService.getCandidateInnerByCandidateId(this.mrtInners[x].candidateId);
        candidateInner.candidate = this.mrtInners[x].candidate;
        tempInners.push(candidateInner);
      }
    }
    this.mrtInners = tempInners;
  }

  onClickStepper(stepperItem) {
    if (this.isNextDisabled()) {
      return;
    }
    switch (stepperItem.text) {
      case SupplierMrtService.NEW_ITEM_SETUP: {
        this.onClickNext();
        break;
      }
      case SupplierMrtService.MRT_DETAILS: {
        this.jumpToPage('/piaMrtCaseDetails');
        break;
      }
      case SupplierMrtService.WAREHOUSE_SELECTION: {
        this.jumpToPage('/piaMrtWarehouseSelection');
        break;
      }
      case SupplierMrtService.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/piaMrtExtendedAttributes');
        break;
      }
    }
  }

  jumpToPage(urlToNavigate) {
    this.canNavigate = false;
    if (this.supplierMrtService.hasInnersToSetup()) {
      this.canNavigate = true;
      return;
    }

    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_SETUP_MRT_ITEMS_VALIDATOR]).subscribe((candidate: Candidate) => {
      this.candidateError = this.supplierMrtService.setMrtItemError(null);
      this.supplierMrtService.saveCandidateAndNavigate(urlToNavigate, {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
    }, (error) => {
      if (error.error?.candidateErrors) {
        this.candidateError = this.supplierMrtService.setMrtItemError(error.error.candidateErrors);
      } else {
        this.growlService.addError(error.message);
      }
      this.supplierMrtService.saveCandidateAndNavigate(urlToNavigate, {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
    });

  }
}
