export class RetailLink {
  retailLinkNumber: number;
  upcType: string;
  searchedId: any;
  unitUpc: number;
  productDescription: string;
  size: string;
  commodity: number;
  subCommodity: number;

  constructor() { }
}
