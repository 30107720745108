import { Component, OnInit } from '@angular/core';
import {
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  MerchandisingInfoCardPermissions,
  ProductImagesCardPermissions,
  ProductPackagingCardPermissions
} from 'pm-models';
import { UPCInputState, UpcService } from 'pm-components';
import { WorkflowService } from '../../service/workflow.service';
import { CandidateService } from '../../service/candidate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GrowlService } from '../../growl/growl.service';
import { LookupService } from '../../service/lookup.service';
import { BonusSizeService } from '../../service/bonus-size.service';
import { CostService } from '../../service/cost.service';
import { CandidateUtilService } from '../../service/candidate-util.service';
import { PiaBonusStepperComponent } from '../pia-bonus-stepper/pia-bonus-stepper.component';
import { Observable } from 'rxjs';
import { NewUpcRequest, UpcRequestType } from 'pm-models/lib/newUpcRequest';
import { OwnBrandService } from '../../service/ownbrand.service';
import { UUID } from 'angular2-uuid';
import { calculateCheckDigit } from '../../shared/upc.utils';

@Component({
  selector: 'app-pia-bonus-details',
  templateUrl: './pia-bonus-details.component.html',
  styleUrls: ['./pia-bonus-details.component.scss']
})
export class PiaBonusDetailsComponent implements OnInit {

  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public KEY_RETAIL = 'Key Retail';
  public isViewingPage = true;
  PLU_CANDIDATE_PRODUCT_TYPES = [CandidateProduct.BONUS_SIZE_PLU, CandidateProduct.REPLACEMENT_PLU];
  UPC_CANDIDATE_PRODUCT_TYPES = [CandidateProduct.BONUS_SIZE, CandidateProduct.REPLACEMENT_UPC];

  readonly productPackagingCardPermissions: ProductPackagingCardPermissions = {
    isReadOnly: false
  };

  readonly merchandisingInfoCardPermissions: MerchandisingInfoCardPermissions = {
    isReadOnly: false,
    merchandiseType: {
      isHidden: true
    },
    commodity: {
      isHidden: true
    },
    subCommodity: {
      isHidden: true
    },
    pssDepartment: {
      isHidden: true
    }
  };
  readonly productImagesCardPermissions: ProductImagesCardPermissions = {
    isReadOnly: false
  };
  public unitsOfMeasures: any;
  public packageTypes: any;
  upcState: UPCInputState;
  upcOptions: { label: string, value: string } [] = [{ label: 'UPC', value: 'UPC' }, { label: 'PLU', value: 'PLU' }];
  pluRanges = [];
  upcType;
  canNavigate = true;
  isFetchingPlu = false;

  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public bonusSizeService: BonusSizeService, public costService: CostService,
              private candidateUtilService: CandidateUtilService, public ownBrandService: OwnBrandService, public upcService: UpcService) {
  }

  ngOnInit() {
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });

    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId, this.bonusSizeService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.BONUS_SIZE || candidate.candidateType === Candidate.REPLACEMENT_UPC) {
            this.bonusSizeService.currentCandidate = candidate;
            this.bonusSizeService.originalCandidate = JSON.parse(JSON.stringify(candidate));
            this.upcTypeInit();
            this.bonusSizeService.setProductData().subscribe();
          } else {
            this.router.navigate(['/tasks']).then();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.upcTypeInit();
              this.bonusSizeService.setProductData().subscribe();
            } else {
              this.bonusSizeService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  onClickNext() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR]).toPromise().then(() => {
      this.bonusSizeService.bonusDetailsError = new CandidateError();
      this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaCaseDetailsUrl(), false);
    }, (error) => {
      this.bonusSizeService.scrollToTop();

      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.bonusSizeService.bonusDetailsError = error.error.candidateErrors;
      }
      this.canNavigate = true;
    });
  }

  onClickBack() {
    this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaSetupUpcUrl(), false);
  }

  onClose() {
    this.isViewingPage = false;
    this.bonusSizeService.saveCandidateAndNavigate('/tasks', false);
  }

  subBrandChange(selectedValue: any) {
    this.bonusSizeService.currentCandidate.candidateProducts[this.bonusSizeService.currentCandidateProductIndex].subBrand = selectedValue;
  }

  validateUPC() {
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.bonusSizeService.getCurrentCandidateProduct().id;

    const validator = this.bonusSizeService.getCurrentCandidateProduct().isManuallyEnteredPlu ?
      CandidateValidatorType.UNRESERVED_PLU_VALIDATOR : CandidateValidatorType.UPC_VALIDATOR;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [validator]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.bonusSizeService.candidateProductError.upc = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError: CandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.bonusSizeService.candidateProductError.upc =
          returnedCandidateProductError.upc;
        this.bonusSizeService.candidateProductError.upcCheckDigit =
          returnedCandidateProductError.upcCheckDigit;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  public setDefaultVertexTaxCategory(candidate: Candidate) {
    // initialize it to the default category if it's not set
    if (!candidate.vertexTaxCategory) {
      candidate.vertexTaxCategory = CandidateHelper.getVertexTaxCategory(candidate);
    }
  }


  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    let urlToNavigate;
    let validators;
    switch (stepperItem.text) {
      case PiaBonusStepperComponent.UPC_ITEM_CODE:
        this.onClickBack();
        break;
      case PiaBonusStepperComponent.CASE_DETAILS:
        this.onClickNext();
        break;
      case PiaBonusStepperComponent.WAREHOUSE_SELECTION:
        urlToNavigate = this.bonusSizeService.getPiaWarehouseUrl();
        validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR,
          CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
      case PiaBonusStepperComponent.EXTENDED_ATTRIBUTES:
        urlToNavigate = this.bonusSizeService.getPiaExtendedAttributesUrl();
        validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR,
          CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR, CandidateValidatorType.WAREHOUSE_VALIDATOR, CandidateValidatorType.BICEP_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
    }
  }

  jumpToPage(urlToNavigate, validators: CandidateValidatorType[]) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate, validators).subscribe(() => {
      this.bonusSizeService.candidateError = this.bonusSizeService.bonusDetailsError = new CandidateError();
      this.bonusSizeService.candidateProductError = new CandidateProductError();
      this.bonusSizeService.saveCandidateAndNavigate(urlToNavigate, false);
    }, (error) => {
      this.bonusSizeService.scrollToTop();

      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.bonusSizeService.candidateError = error.error.candidateErrors;
        this.bonusSizeService.candidateProductError =
          this.bonusSizeService.candidateError.candidateProductErrors[this.bonusSizeService.getCurrentCandidateProduct().id];
        this.bonusSizeService.bonusDetailsError = this.bonusSizeService.candidateError;
      }
      this.canNavigate = true;
    });
  }

  hasUpcError(): boolean {
    return this.bonusSizeService.candidateProductError !== undefined && this.bonusSizeService.candidateProductError !== null &&
      this.bonusSizeService.candidateProductError.upc !== undefined && this.bonusSizeService.candidateProductError.upc !== null;
  }

  upcChange() {
    if (this.bonusSizeService.getCurrentCandidateProduct().upc &&
      this.bonusSizeService.getCurrentCandidateProduct().upcCheckDigit) {
      this.validateUPC();
    } else {
      this.bonusSizeService.candidateProductError.upc = null;
      this.bonusSizeService.candidateProductError.upcCheckDigit = null;
      this.upcState = UPCInputState.none;
    }
  }

  onUpcTypeChange(event) {
    // this is done to avoid onInit call, which would reset the data.
    if (!event || (event === 'PLU' && this.PLU_CANDIDATE_PRODUCT_TYPES.includes(this.bonusSizeService.getCurrentCandidateProduct().candidateProductType))
      || (event === 'UPC' && this.UPC_CANDIDATE_PRODUCT_TYPES.includes(this.bonusSizeService.getCurrentCandidateProduct().candidateProductType))) {
      return;
    }
    if (this.isUPC()) {
      if (this.bonusSizeService.getCurrentCandidateProduct().upc) {
        this.releasePlu(this.bonusSizeService.getCurrentCandidateProduct().upc).subscribe();
      }
      this.bonusSizeService.getCurrentCandidateProduct().upc = null;
      this.bonusSizeService.getCurrentCandidateProduct().upcCheckDigit = null;
      this.bonusSizeService.getCurrentCandidateProduct().candidateProductType =
        this.bonusSizeService.getCandidate().candidateType === Candidate.BONUS_SIZE ? CandidateProduct.BONUS_SIZE : CandidateProduct.REPLACEMENT_UPC;
      this.bonusSizeService.getCurrentCandidateProduct().pluRange = null;
      this.bonusSizeService.getCurrentCandidateProduct().pluType = null;
      this.bonusSizeService.getCurrentCandidateProduct().scaleInformation = null;
      this.bonusSizeService.getCurrentCandidateProduct().isManuallyEnteredPlu = false;
      this.bonusSizeService.getCurrentCandidateProduct().generatedUpc = false;
      this.upcState = null;
    } else if (this.isPLU()) {
      this.bonusSizeService.getCurrentCandidateProduct().candidateProductType =
        this.bonusSizeService.getCandidate().candidateType === Candidate.BONUS_SIZE ? CandidateProduct.BONUS_SIZE_PLU : CandidateProduct.REPLACEMENT_PLU;
      this.bonusSizeService.getCurrentCandidateProduct().upc = null;
      this.bonusSizeService.getCurrentCandidateProduct().upcCheckDigit = null;
      this.bonusSizeService.candidateProductError.upc = undefined;
      this.bonusSizeService.getCurrentCandidateProduct().isManuallyEnteredPlu = false;
      this.bonusSizeService.getCurrentCandidateProduct().generatedUpc = false;
      this.upcState = null;
    }
  }

  isPLU() {
    return this.upcType === 'PLU';
  }

  isUPC() {
    return this.upcType === 'UPC';
  }

  upcTypeInit() {
    if (this.UPC_CANDIDATE_PRODUCT_TYPES.includes(this.bonusSizeService.getCurrentCandidateProduct().candidateProductType)) {
      this.upcType = 'UPC';
    } else {
      this.upcType = 'PLU';

    }
  }

  releasePlu(plu): Observable<any> {
      return this.ownBrandService.releaseUpc(plu, UpcRequestType.CHECKER_AND_SCALE);
  }

  generatePlu() {
    this.isFetchingPlu = true;
    const newUpcRequest = new NewUpcRequest();
    newUpcRequest.requestType = this.bonusSizeService.getCurrentCandidateProduct().pluType;
    newUpcRequest.candidate = this.bonusSizeService.getCandidate();
    newUpcRequest.rangeId = this.bonusSizeService.getCurrentCandidateProduct().pluRange.pluRangeId;
    this.reserveUpc(newUpcRequest);
  }

  reservePlu(plu) {
    this.isFetchingPlu = true;
    const newUpcRequest = new NewUpcRequest();
    newUpcRequest.requestType = this.bonusSizeService.getCurrentCandidateProduct().pluType;
    newUpcRequest.candidate = this.bonusSizeService.getCandidate();
    newUpcRequest.requestedUpc = plu;
    this.reserveUpc(newUpcRequest);
  }

  reserveUpc(newUpcRequest: NewUpcRequest) {
    this.ownBrandService.reserveUpcs(newUpcRequest).subscribe(plus => {
        this.bonusSizeService.getCurrentCandidateProduct().upc = plus[0];
        this.bonusSizeService.getCurrentCandidateProduct().upcCheckDigit = calculateCheckDigit(plus[0]);
        this.bonusSizeService.getCurrentCandidateProduct().generatedUpc = true;
        if (this.bonusSizeService.candidateProductError) {
          this.bonusSizeService.candidateProductError.upc = null;
        }
        this.isFetchingPlu = false;
      }, (error) => {
        this.bonusSizeService.getCurrentCandidateProduct().upc = null;
        if (error.status === 400) {
          this.bonusSizeService.candidateError = error.error.candidateErrors;
          this.bonusSizeService.bonusDetailsError = this.bonusSizeService.candidateError;

          if (error.error.validationErrors && error.error.validationErrors.length > 0) {
            this.bonusSizeService.candidateError.description = error.error.validationErrors[0];
          } else {
            this.bonusSizeService.candidateError.description = null;
          }

        } else if (error.status === 500) {
          if (this.bonusSizeService.candidateProductError) {
            this.bonusSizeService.candidateProductError.upc = 'No UPCs in this range available, please contact procurement support.';
            this.bonusSizeService.candidateError.description = null;
          }
        } else {
          this.growlService.addError(error.message);
        }
        this.isFetchingPlu = false;
      }
    );
  }


  onPluRangeModelChange(event) {
    this.bonusSizeService.getCurrentCandidateProduct().pluRange = event;
    this.onPluValueChange();
  }

  onPluTypeModelChange(event) {
    this.bonusSizeService.getCurrentCandidateProduct().pluType = event;

    if (+event ===  +UpcRequestType.SCALE || +event ===  +UpcRequestType.CHECKER_AND_SCALE) {
      if (!this.bonusSizeService.getCurrentCandidateProduct().scaleInformation) {
        this.bonusSizeService.getCurrentCandidateProduct().scaleInformation = { id: UUID.UUID() };
      }
    } else {
      this.bonusSizeService.getCurrentCandidateProduct().scaleInformation = null;
    }
    this.onPluValueChange();
  }

  onManuallyEnteredPluChange(isManuallyEnterPlu) {
    this.bonusSizeService.candidateProductError.upc = undefined;
    this.bonusSizeService.candidateProductError.upcCheckDigit = undefined;
    this.bonusSizeService.getCurrentCandidateProduct().generatedUpc = false;
    this.bonusSizeService.getCurrentCandidateProduct().upcCheckDigit = null;
    if (this.bonusSizeService.getCurrentCandidateProduct().upc && this.bonusSizeService.getCurrentCandidateProduct().pluType) {
      this.releasePlu(this.bonusSizeService.getCurrentCandidateProduct().upc).subscribe();
    }
    this.bonusSizeService.getCurrentCandidateProduct().upc = null;
    this.bonusSizeService.getCurrentCandidateProduct().isManuallyEnteredPlu = isManuallyEnterPlu;
  }

  onPluValueChange() {
    if (this.bonusSizeService.getCurrentCandidateProduct().pluRange && this.bonusSizeService.getCurrentCandidateProduct().pluType) {
      if (this.bonusSizeService.getCurrentCandidateProduct().upc) {
        this.releasePlu(this.bonusSizeService.getCurrentCandidateProduct().upc).subscribe();
      }
      this.generatePlu();
    }
  }

  getFullPlu() {
    if (this.isPLU()) {
      return this.upcService.pluToPreDigitTwo(this.bonusSizeService.getCurrentCandidateProduct().upc);
    }
    return null;
  }
}
