<ng-container *ngIf="adminPreferences">
  <div class="row align-items-center upload-settings-configuration-wrapper">
    <div class="col-md-5 upload-settings-configuration">
      <div class="header-row">
        <h2 class="mb-4">{{title}}</h2>
        <p class="mb-4">{{subTitle}}</p>
      </div>
      <div class="attributes-stacked mb-4">
        <pm-attribute-display-radio [attribute]="channelConfiguration"
                                    [(model)]="adminPreferences.fulfillmentChannel">
        </pm-attribute-display-radio>
      </div>
      <div class="header-row auto-approve-activation-label">
        <label>Auto-approve</label>
      </div>
      <div class="header-row mb-4">
        <p-checkbox [(ngModel)]="adminPreferences.autoApproveActivation"
                    binary="true">
        </p-checkbox>
        <label class="ml-2">Automatically activate uploaded candidates in the future</label>
      </div>

      <div class="row button-row">
        <button *ngIf="hasPreviousPreferences" pButton class="cancel mr-3" label="Cancel" (click)="cancel()"></button>
        <button pButton label="Save" (click)="saveClicked()" [disabled]="!adminPreferences.fulfillmentChannel"></button>
      </div>

    </div>
  </div>
</ng-container>
