import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'pm-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent implements OnInit {

  @Output()
  editButtonClicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  edit() {
    this.editButtonClicked.emit();
  }
}
