import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AppConfigService } from '../service/app-config.service';
@Injectable()
export class AuthGuard implements CanActivate {
  /**
   * streams the error message to the Growl component
   * @type {Subject<string>}
   */
  private errorMessageSource = new Subject<string>();
  errorMessage$ = this.errorMessageSource.asObservable();

  constructor(private auth: AuthService, private router: Router, private appConfig: AppConfigService) {}

  /**
   * Checks if the user has the correct roles and resources, if not it routes to login page, saving the returnTo url, so that they can
   * be rerouted to the requested url, else it routes to the correct page.
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles = route.data['roles'];

    let returnTo: string = state.url;
    if (this.checkLogin() && this.checkRoles(roles)) {
      return true;
    } else if (this.appConfig.isSsoEnabled() && this.auth.isAuthenticatedWithAuthCookie()) {
      // auth service redirects to a "book marked" url with auth cookies
      // it's saved here because later auth.service will make a call to check token and the url we be over written

      return this.auth.loginWithCookieToken(returnTo).pipe(
        catchError(err => {
          this.addError('SSO failed ' + err);
          return of(false);
        }),
        switchMap(() => this.auth.initializeCurrentRole()),
        map(x => {
            if (!this.auth.isUser()) {
              returnTo = 'requestRole';
            }
            this.router.navigateByUrl(returnTo).then(
              () => {
              },
              error => {
                this.addError('error navigating ' + error + '/nURL: ' + returnTo);
              }
            );
            return true;
          },
          catchError(err => {
            this.addError('SSO failed ' + err);
            return of(false);
          })
        )
      );

    } else {
      this.router.navigate(['/login'], { queryParams: { returnTo: state.url } });
      return false;
    }
  }

  addError(error: any) {
    this.errorMessageSource.next(error);
  }

  /**
   * Checks if the user is authenticated.
   * @returns {boolean}
   */
  private checkLogin(): boolean {
    return this.auth.isAuthenticated() && !this.auth.isSessionExpired();
  }

  /**
   * Function to check if user has the correct role to access the page.
   * @param roles
   * @returns {boolean}
   */
  private checkRoles(roles): boolean {
    return true;
  }
}
