import {Component, Input, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {CandidateError} from 'pm-models';

@Component({
  selector: 'app-pharm-new-dsd-product-flow-stepper',
  templateUrl: './pharm-new-dsd-product-flow-stepper.component.html',
  styleUrls: ['./pharm-new-dsd-product-flow-stepper.component.scss']
})
export class PharmNewDsdProductFlowStepperComponent implements OnInit {

  private errorCandidateDiffer: KeyValueDiffer<string, any>;
  private errorCandidateProductDiffer: KeyValueDiffer<string, any>;

  private readonly errorToolTip = 'This page contains errors or is missing information in required fields.';
  private readonly errorIcon = 'warning';

  @Input()
  candidateErrors: CandidateError[];

  @Input()
  isSellable: boolean;

  @Input()
  stepIndex: number;

  steps = [{ text: 'UPC/Item code' }, { text: 'Supplier & H-E-B setup' },
    { text: 'Product details' }, { text: 'Case pack' }];

  constructor(private differs: KeyValueDiffers) {}

  ngOnInit() {}

  /**
   * Change detection that compares candidate errors with previous version.
   * If the candidate differ doesn't exist, it creates it
   * Runs apply errors if any changes have been detected
   * @memberof NewWarehouseProductStepperComponent
   */
  ngDoCheck(): void {
    let candidateHasChanges = false;

    if (this.errorCandidateDiffer) {
      candidateHasChanges = this.errorCandidateDiffer.diff(this.candidateErrors) ? true : false;
    } else if (this.candidateErrors) {
      this.errorCandidateDiffer = this.differs.find(this.candidateErrors).create();
    }

    if (candidateHasChanges) {
      this.applyErrors();
    }
  }

  /**
   * Runs through each step of menu and runs validation on each step
   *
   * @memberof NewWarehouseProductStepperComponent
   */
  applyErrors() {
    this.steps.forEach((x, i) => {
      switch (i) {
        case 0:
          this.validateUPCItemCode(x, this.candidateErrors[i]);
          break;
        case 1:
          this.validateSupplierHEBSetup(x, this.candidateErrors[i]);
          break;
        case 2:
          this.validateProductDetails(x, this.candidateErrors[i]);
          break;
        case 3:
          this.validateCaseDetails(x, this.candidateErrors[i]);
          break;
      }
    });
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof NewWarehouseProductStepperComponent
   */
  validateUPCItemCode(stepItem, candidateErrors) {
    const candidateKeys = ['description', 'warehouseSwitch', 'dsdSwitch', 'productType'];
    const candidateProductKeys = ['upc'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, [], candidateErrors, []);
  }

  /**
   * Vaidation for Supplier Setup
   *
   * @param {*} stepItem - Menu Item
   * @memberof NewWarehouseProductStepperComponent
   */
  validateSupplierHEBSetup(stepItem, candidateErrors) {
    // todo: cost owner on other flow.
    const candidateKeys = ['buyer', 'vendor', 'brand', 'subBrand', 'costOwner', 'lane', 'noStoresError', 'commodity',
      'subCommodity'];
    const candidateProductKeys = [];
    if (!this.isSellable) {
      // candidateKeys.push('merchandiseType'); // todo other new product flow.
      candidateProductKeys.push('description');
    }
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, [], candidateErrors, []);
  }

  validateProductDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'merchandiseType',
      'commodity',
      'subCommodity',
      'packageType',
      'productWidth',
      'productHeight',
      'productLength',
      'productWeight',
      'unitOfMeasure',
      'retailSize',
      'totalVolume',
      'pssDepartment',
      'pseGrams',
      'vertexTaxCategory'
    ];

    const candidateProductKeys = [
      'imageLinks',
      'termsAndConditions'
    ];

    const scaleInformationKeys = [
      'englishLabelOne',
      'englishLabelTwo'
    ];

    if (this.isSellable) {
      candidateKeys.push('suggestedXFor');
      candidateKeys.push('suggestedRetailPrice');
      candidateKeys.push('mapRetail');
      candidateKeys.push('season');
      candidateKeys.push('seasonYear');

      candidateProductKeys.push('description');
      candidateProductKeys.push('customerFriendlyDescription1');
      candidateProductKeys.push('customerFriendlyDescription2');
      candidateProductKeys.push('romanceCopy');
    }
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, [], candidateErrors, scaleInformationKeys);
  }

  validateCaseDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'masterPack',
      'masterWidth',
      'masterHeight',
      'masterLength',
      'masterWeight',
      'cubeAdjustedFactor',
      'vendorTie',
      'vendorTier',
      'innerPack',
      'innerWidth',
      'innerHeight',
      'innerLength',
      'innerWeight',
      'itemWeightType',
      'maxShelfLife',
      'inboundSpecDays',
      'guaranteeToStoreDays',
      'warehouseReactionDays',
      'displayReadyUnitRowsDeep',
      'displayReadyUnitRowsHigh',
      'displayReadyUnitRowsFacing',
      'displayReadyUnitOrientation',
      'costLink',
      'masterListCost',
      'unitCost',
      'innerCost',
      'retailType',
      'retailXFor',
      'retailPrice',
      'retailLink',
      'attachments'
    ];
    const candidateProductKeys = [
      'caseUpc',
      'caseUpcCheckDigit',
      'caseDescription',
      'vendorProductCode',
      'countryOfOrigin'
    ];

    this.validateStep(stepItem, candidateKeys, candidateProductKeys, [], candidateErrors, []);
  }

  /**
   * Validates step with associated keys, applies icon and error message if found
   *
   * @private
   * @param {*} stepItem - Menu Items
   * @param {*} candidateKeys - Candidate Error Keys associated with this step
   * @param {*} candidateProductKeys - Candidate Product Error Keys associated with this step
   * @memberof NewWarehouseProductStepperComponent
   */
  private validateStep(stepItem, candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, scaleInformationErrors) {
    if (!this.validateKeys(candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, scaleInformationErrors)) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    } else {
      stepItem.icon = undefined;
      stepItem.message = undefined;
    }
  }

  /**
   * Checks each batch of keys to see if candidateErrors or candidate Product errors
   * contain any values. Immediate return false if error found for performance.
   *
   * @private
   * @param {*} candidateKeys
   * @param {*} candidateProductKeys
   * @param {*} warehouseKeys
   * @param {*} candidateErrors
   * @returns
   * @memberof NewWarehouseProductStepperComponent
   */
  private validateKeys(candidateKeys, candidateProductKeys, warehouseKeys, candidateErrors, scaleInformationErrors) {
    for (let i = 0; i < candidateKeys.length; i++) {
      if (this.candidateErrors && this.fieldHasValue(candidateErrors[candidateKeys[i]])) {
        return false;
      }
    }
    for (let i = 0; i < candidateProductKeys.length; i++) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && this.fieldHasValue(value[candidateProductKeys[i]])) {
            return false;
          }
        }
      }
    }
    for (let i = 0; i < warehouseKeys.length; i++) {
      if (!candidateErrors.candidateProductErrors) {
        return true;
      }
      for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
        for (const [candidateProductKey, candidateProductValue] of Object.entries(value)) {
          if (candidateProductKey === 'warehouseErrors') {
            for (const [warehouseKey, warehouseValue] of Object.entries(candidateProductValue)) {
              if (warehouseValue && this.fieldHasValue(warehouseValue[warehouseKeys[i]])) {
                return false;
              }
            }
          }
        }
      }
    }

    for (let i = 0; i < scaleInformationErrors.length; i++) {
      for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
        for (const [candidateProductKey, candidateProductValue] of Object.entries(value)) {
          if (candidateProductKey === 'scaleInformation') {
            if (candidateProductValue && this.fieldHasValue(candidateProductValue[scaleInformationErrors[i]])) {
              return false;

            }
          }
        }
      }
    }
    return true;
  }

  /**
   * Takes  a property and immediately checks if it has any sort of value
   * returns 'true' if any value is found or present.
   * @private
   * @param {*} field
   * @returns
   * @memberof NewWarehouseProductStepperComponent
   */
  private fieldHasValue(field) {
    return field !== undefined && field !== null && field !== '';
  }
}
