import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UUID} from 'angular2-uuid';
import {UPCInputState} from 'pm-components';
import {
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  DistributionChannelPermissions, TaskDecision
} from 'pm-models';
import {SellablePermissions} from 'pm-models/lib/card-models/sellable-model';
import {NewUpcRequest, UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {take} from 'rxjs/operators';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {OwnBrandService} from '../../service/ownbrand.service';
import {PharmProductService} from '../../service/pharm-product.service';
import {WorkflowService} from '../../service/workflow.service';
import {calculateCheckDigit} from '../../shared/upc.utils';
import {Observable} from 'rxjs';
import {MatUtilService} from '../../service/mat-util.service';

@Component({
  selector: 'app-pharm-new-product-setup',
  templateUrl: './pharm-new-product-setup.component.html',
  styleUrls: ['./pharm-new-product-setup.component.scss']
})
export class PharmNewProductSetupComponent implements OnInit {

  public upcType;
  public upcState: UPCInputState;
  public upcOptions: { label: string, value: string } [] = [{label: 'UPC', value: 'UPC'}, {label: 'PLU', value: 'PLU'}];

  public pluTypes = [{label: 'Checker PLU', value: UpcRequestType.CHECKER}];
  public pluRanges = [];
  public isViewingPage = true;
  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;

  readonly sellablePermissions: SellablePermissions = {
    isReadOnly: false,
    isDisabled: false
  };

  readonly channelPermissions: DistributionChannelPermissions = {
    isReadOnly: false,
    isDisabled: false
  };

  constructor(public candidateService: CandidateService, public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public pharmProductService: PharmProductService, public growlService: GrowlService,
              public candidateUtilService: CandidateUtilService, public ownBrandService: OwnBrandService) {
  }

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      let taskId;
      if (this.pharmProductService.getTaskId()) {
        taskId = this.pharmProductService.getTaskId();
      } else if (this.pharmProductService.getTask() && this.pharmProductService.getTask().id) {
        taskId = this.pharmProductService.getTask().id;
      }
      // if there's params, and it doesn't have a task id equal to the services current task id
      if (params.keys.length > 0 && (!(taskId && params.has('taskId') && params['params']['taskId'] === taskId))) {
        this.pharmProductService.resetService();
        this.pharmProductService.setCandidateByUrlParameters(params).subscribe(() => {
          this.upcTypeInit();
          this.candidateError = this.pharmProductService.getSetupCandidateError();
          this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
        });
      } else if (this.pharmProductService.getOriginalCandidate() && this.pharmProductService.getCandidate()) {
        this.upcTypeInit();
        this.candidateError = this.pharmProductService.getSetupCandidateError();
        this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
      }  else {
        this.pharmProductService.resetService();
        this.pharmProductService.setupNewCandidate();
        this.candidateError = this.pharmProductService.getSetupCandidateError();
        this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
      }
    });
  }

  onClose() {
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.pharmProductService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR]).toPromise().then(() => {
      this.candidateError = this.pharmProductService.setSetupCandidateError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.pharmProductService.getTaskId()) {
        this.pharmProductService.saveCandidateAndNavigate('/tasks', false);
      } else {
        if (this.pharmProductService.getCandidate().description) {
          this.pharmProductService.createCandidateAndNavigate(this.pharmProductService.getCandidate(), '/tasks');
        }
      }

    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.candidateError.candidateProductErrors[this.pharmProductService.getCurrentCandidateProduct().id];
        this.pharmProductService.setSetupCandidateError(this.candidateError);
      }
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.pharmProductService.getTaskId() && !this.candidateError.description) {
        this.pharmProductService.saveCandidateAndNavigate('/tasks', false);
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
    if (this.pharmProductService.getCurrentCandidateProduct().generatedUpc) {
      this.ownBrandService.releaseUpc(this.pharmProductService.getCurrentCandidateProduct().upc,
        UpcRequestType.CHECKER_AND_SCALE).subscribe();
    }
  }

  hasUpcError(): boolean {
    return this.candidateProductError !== undefined &&  this.candidateProductError !== null &&
      this.candidateProductError.upc !== undefined && this.candidateProductError.upc !== null;
  }


  validateUPC() {
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.pharmProductService.getCurrentCandidateProduct().id;

    this.candidateService.validateCandidate(this.pharmProductService.getCandidate(),
      [CandidateValidatorType.UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.upc = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/check-digit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.upc = returnedCandidateProductError.upc;
        this.candidateProductError.upcCheckDigit = returnedCandidateProductError.upcCheckDigit;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  onClickBack() {
    if (this.pharmProductService.getTask() || this.pharmProductService.getTaskId()) {
      // a task exists, so candidate can be saved
      this.pharmProductService.saveCandidate();
    } else {
      // store candidate info, just in case the user returns to the screen.
      this.pharmProductService.setOriginalAndCurrentCandidate(this.pharmProductService.getCandidate());
    }
    this.router.navigate(['/tasks']).then();
  }

  /**
   * Saves candidate.
   */
  saveCandidate() {
    if (this.candidateUtilService.isEmptyOrSpaces(this.pharmProductService.getCandidate().description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.pharmProductService.getOriginalCandidate()) !== JSON.stringify(this.pharmProductService.getCandidate())) {
        this.candidateService.saveCandidate(this.pharmProductService.getCandidate()).subscribe(savedCandidate => {
          this.pharmProductService.setOriginalAndCurrentCandidate(savedCandidate);
        });
      }
    }
  }

  onClickNext() {
    if (this.isPLU()) {
      this.pharmProductService.getCurrentCandidateProduct().generatedUpc = true;
    }
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.pharmProductService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR,
        CandidateValidatorType.DISTRIBUTION_CHANNEL_VALIDATOR]).toPromise().then(() => {
      this.candidateError = this.pharmProductService.setSetupCandidateError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.pharmProductService.getTaskId()) {

        if (this.hasUserChangedFlows()) {
          this.rejectTaskByPharm(this.pharmProductService.getTask());
          if (this.pharmProductService.isEmptyOrSpaces(this.pharmProductService.getCandidate().description)) {
            this.growlService.addError('Failed to save because a candidate name is required.');
          } else {
            if (JSON.stringify(this.pharmProductService.getOriginalCandidate()) !==
              JSON.stringify(this.pharmProductService.getCandidate())) {
              this.candidateService.saveCandidate(this.pharmProductService.getCandidate()).subscribe(savedCandidate => {
                this.pharmProductService.setOriginalAndCurrentCandidate(savedCandidate);
                this.pharmProductService.createProcessInstanceWithCandidateId(this.pharmProductService.getCandidate(),
                  '/pharmNewProductSupplierDetails');
              });
            }
          }

        } else {
          this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductSupplierDetails', false);
        }
      } else {
        if (this.pharmProductService.getCandidate().description) {
          this.pharmProductService.createCandidateAndNavigate(this.pharmProductService.getCandidate(), '/pharmNewProductSupplierDetails');
        }
      }

    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.candidateError.candidateProductErrors[this.pharmProductService.getCurrentCandidateProduct().id];
        this.pharmProductService.setSetupCandidateError(this.candidateError);
      }
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.pharmProductService.getTaskId() && !this.candidateError.description) {
        // this.pharmProductService.setCandidate(this.candidate);
        this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductSupplierDetails', false);
      }
    });
  }

  generate04UPC() {
    this.pharmProductService.getCurrentCandidateProduct().upc = null;
    this.pharmProductService.getCurrentCandidateProduct().upcCheckDigit = null;

    this.ownBrandService.generate04NonSellableUPC().subscribe((upc) => {
      this.pharmProductService.getCurrentCandidateProduct().upc = upc;
      this.pharmProductService.getCurrentCandidateProduct().upcCheckDigit = calculateCheckDigit(upc);
      this.pharmProductService.getCurrentCandidateProduct().generatedUpc = true;
      this.pharmProductService.getCurrentCandidateProduct().id = UUID.UUID();

    }, (error) => {
      this.growlService.addError('Unable to reserve a pre-digit four UPC. Error:' + error.message);
    });
  }

  sellableTypeChange(event: Candidate) {
    this.pharmProductService.getCandidate().merchandiseType = null;

    if (event.productType === 'SELLABLE') {
      if (this.pharmProductService.getOriginalCandidate().candidateProducts) {
        this.pharmProductService.getCurrentCandidateProduct().description =
          this.pharmProductService.getOriginalCandidate().candidateProducts[0].description;
      }
      this.pharmProductService.candidate.retailXFor = null;
      this.pharmProductService.candidate.retailPrice = null;
      this.pharmProductService.getCurrentCandidateProduct().generatedUpc = false;
      this.ownBrandService.releaseUpc(this.pharmProductService.getCurrentCandidateProduct().upc,
        UpcRequestType.CHECKER_AND_SCALE).subscribe();

    } else {
      this.pharmProductService.getCandidate().retailXFor = 1;
      this.pharmProductService.getCandidate().retailPrice = '0.0';
    }
  }

  isPLU() {
    return this.upcType === 'PLU';
  }

  isUPC() {
    return this.upcType === 'UPC';
  }

  onUpcTypeChange(event) {
    // this is done to avoid onInit call, which would reset the data.
    if (event === 'PLU' && this.pharmProductService.getCurrentCandidateProduct().candidateProductType === CandidateProduct.PLU) {
      return;
    } else if (event === 'UPC' && !this.pharmProductService.getCurrentCandidateProduct().candidateProductType) {
      return;
    }
    if (this.isUPC()) {
      this.pharmProductService.getCandidate().candidateType = Candidate.NEW_PRODUCT;
      this.pharmProductService.getCurrentCandidateProduct().upc = null;
      this.pharmProductService.getCurrentCandidateProduct().upcCheckDigit = null;
      this.pharmProductService.getCurrentCandidateProduct().candidateProductType = null;
      this.pharmProductService.getCurrentCandidateProduct().pluRange = null;
      this.pharmProductService.getCurrentCandidateProduct().pluType = null;
      this.pharmProductService.getCurrentCandidateProduct().scaleInformation = null;
      this.releasePlu().subscribe();
    } else if (this.isPLU()) {
      this.pharmProductService.getCandidate().candidateType = Candidate.PLU;
      this.pharmProductService.getCurrentCandidateProduct().upc = null;
      this.pharmProductService.getCurrentCandidateProduct().upcCheckDigit = null;
      this.pharmProductService.getCurrentCandidateProduct().candidateProductType = CandidateProduct.PLU;
    }
  }

  onPluRangeModelChange(event) {
    this.pharmProductService.getCurrentCandidateProduct().pluRange = event;
    this.onPluValueChange();
  }

  onPluRTypeModelChange(event) {
    this.pharmProductService.getCurrentCandidateProduct().pluType = event;

    if (+event === +UpcRequestType.SCALE || +event === +UpcRequestType.CHECKER_AND_SCALE) {
      if (! this.pharmProductService.getCurrentCandidateProduct().scaleInformation) {
        this.pharmProductService.getCurrentCandidateProduct().scaleInformation = { id: UUID.UUID() };
      }
    } else {
      this.pharmProductService.getCurrentCandidateProduct().scaleInformation = null;
    }
    this.onPluValueChange();

  }

  onPluValueChange() {
    if (this.pharmProductService.getCurrentCandidateProduct().pluRange &&
      this.pharmProductService.getCurrentCandidateProduct().pluType) {
      if (this.pharmProductService.getCurrentCandidateProduct().upc) {
        this.releasePlu().subscribe();
      }
      this.generatePlu();
    }
  }

  generatePlu() {
    const newUpcRequest = new NewUpcRequest();
    newUpcRequest.requestType = this.pharmProductService.getCurrentCandidateProduct().pluType;
    newUpcRequest.candidate = this.pharmProductService.getCandidate();
    newUpcRequest.rangeId = this.pharmProductService.getCurrentCandidateProduct().pluRange.pluRangeId;
    this.ownBrandService.reserveUpcs(newUpcRequest).subscribe(plus => {
        this.pharmProductService.getCurrentCandidateProduct().upc = plus[0];
        if (this.candidateProductError) {
          this.candidateProductError.upc = null;
        }
      }, () => {
        if (this.candidateProductError) {
          this.candidateProductError.upc = 'No UPCs in this range available, please contact procurement support.';
        }
      }
    );
  }

  releasePlu(): Observable<any> {
    return this.ownBrandService.releaseUpc(
      this.pharmProductService.getCurrentCandidateProduct().upc, UpcRequestType.CHECKER_AND_SCALE);
  }

  upcTypeInit() {
    if (this.pharmProductService.getCurrentCandidateProduct().candidateProductType
      === CandidateProduct.PLU) {
      this.upcOptions = [{label: 'UPC', value: 'UPC'}, {label: 'PLU', value: 'PLU'}];
      this.upcType = 'PLU';
    } else {
      this.upcType = 'UPC';
    }

  }

  hasUserChangedFlows() {

    if (!this.pharmProductService.getOriginalCandidate()) {
      return false;
    }

    return this.pharmProductService.isScaleProduct(this.pharmProductService.getCandidate()) !==
      this.pharmProductService.isScaleProduct(this.pharmProductService.getOriginalCandidate());
  }

  /**
   * Delete a task.
   * @param task to delete
   */
  rejectTaskByPharm(task) {
    let workflowDeleteType: TaskDecision;

    workflowDeleteType = TaskDecision.PHARM_FINAL_REVIEW_REJECT_DECISION;

    this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE, workflowDeleteType)
      .pipe(take(1))
      .subscribe(() => {
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  upcChange() {
    // if it's a dsd item, copy upc values to case upc.
    if (this.pharmProductService.getCandidate() && this.pharmProductService.getCandidate().dsdSwitch &&
      !this.pharmProductService.getCandidate().warehouseSwitch) {
      this.pharmProductService.getCandidate().candidateProducts[0].caseUpc =
        this.pharmProductService.getCandidate().candidateProducts[0].upc;
      this.pharmProductService.getCandidate().candidateProducts[0].caseUpcCheckDigit =
        this.pharmProductService.getCandidate().candidateProducts[0].upcCheckDigit;
    }
    if (this.pharmProductService.getCandidate().candidateProducts[0].upc &&
      this.pharmProductService.getCandidate().candidateProducts[0].upcCheckDigit) {
      this.validateUPC();
    } else {
      this.candidateProductError.upc = null;
      this.candidateProductError.upcCheckDigit = null;
      this.upcState = UPCInputState.none;
    }
  }


  channelChange() {
    this.pharmProductService.setCandidate(this.candidateUtilService.handleChannelChange(this.pharmProductService.getCandidate()));
    if (this.pharmProductService.getCandidate().dsdSwitch) {
      MatUtilService.removeWarehouseItemAttributes(this.pharmProductService.getCandidate().candidateProducts[0]);
    } else {
      MatUtilService.removeDsdItemAttributes(this.pharmProductService.getCandidate().candidateProducts[0]);
    }
  }

}
