<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-new-warehouse-product-stepper *ngIf="this.supplierProductService.candidate && !supplierProductService.isDsdOnly()"
                                       [candidateErrors]="supplierProductService.getAllWhsCandidateErrors()"
                                       [stepIndex]="1"
                                       [isSellable]="supplierProductService.isSellable(this.supplierProductService.candidate)"
                                       (indexClick)="onClickStepper($event)">
    </app-new-warehouse-product-stepper>
    <app-new-dsd-product-stepper *ngIf="this.supplierProductService.candidate && supplierProductService.isDsdOnly()"
                                 [candidateErrors]="supplierProductService.getAllDsdCandidateErrors()"
                                 [stepIndex]="1"
                                 [isSellable]="supplierProductService.isSellable(this.supplierProductService.candidate)"
                                 (indexClick)="onClickStepper($event)">
    </app-new-dsd-product-stepper>
  </pm-header>

  <pm-title>What product are you setting up?</pm-title>

  <div *ngIf="this.supplierProductService.candidate" class="col-md-10 mt-3">
    <div class="row">
      <label>Provide a nickname for this so it’s easy to find in PAM. We’ll only use this internally.</label>
    </div>
    <div class="row">
      <input id="descriptionId" pInputText
             [ngClass]="{'ui-inputtext-invalid' : candidateError?.description}"
             placeholder="Enter a name or short description..."
             type="text"
             maxlength="100"
             minlength="1"
             accept-charset="UTF-8"
             [(ngModel)]="this.supplierProductService.candidate.description"
             style="width: 80%;">
    </div>
    <div class="row mt-0" *ngIf="candidateError?.description">
      <p class="attribute-error-message">{{candidateError?.description}}</p>
    </div>
  </div>
  <div *ngIf="this.supplierProductService.candidate" class="mt-3">
    <pm-sellable [permissions]="sellablePermissions"
                 [(model)]="this.supplierProductService.candidate"
                 [errorModel]="candidateError"
                 (modelChange)="sellableTypeChange($event)">
    </pm-sellable>
  </div>
  <div *ngIf="this.supplierProductService.candidate" class="mt-3">
    <pm-distribution-channel [permissions]="channelPermissions"
                             [(model)]="this.supplierProductService.candidate"
                             [errorModel]="candidateError"
                             (modelChange)="channelChange()">
    </pm-distribution-channel>
  </div>
  <div *ngIf="this.supplierProductService.getCandidate()" style="margin-bottom: 100px;">
    <div *ngIf="upcType" class="col-md-10 mt-3">
      <div class="row">
        <label>Enter the product's UPC or another unique product identification code.</label>
      </div>
      <div class="row">
        <pm-upc-input
          [(upcModel)]="this.supplierProductService.candidate.candidateProducts[0].upc"
          [(checkDigitModel)]="this.supplierProductService.candidate.candidateProducts[0].upcCheckDigit"
          [(upcType)]="upcType"
          [readOnly]="supplierProductService.candidate.candidateProducts[0].generatedUpc === true"
          [disabled]="supplierProductService.candidate.candidateProducts[0].generatedUpc === true"
          [autoCheckDigit]="supplierProductService.candidate.candidateProducts[0].generatedUpc === true"
          [readOnlyCheckDigit]="supplierProductService.candidate.candidateProducts[0].generatedUpc === true"
          [hasError]="hasUpcError()"
          placeholderText="Enter UPC"
          checkDigitPlaceholderText="Check #"
          [upcOptions]="upcOptions"
          [state]="upcState"
          (validateUPC)="onUpcModelChange()"
          (upcModelChange)="upcChange()"
          (checkDigitModelChange)="upcChange()"
          [pluTypes]="pluTypes"
          [pluRanges]="pluRanges"
          pluRangePlaceholderText="Select a PLU Range..."
          pluTypePlaceholderText="Select a PLU Type..."
          (upcTypeChange)="onUpcTypeChange($event)"
          (pluRangeModelChange)="onPluRangeModelChange($event)"
          (pluTypeModelChange)="onPluTypeModelChange($event)"
          [hideCheckDigit]="isPLU()"
          [hideUpcField]="isPLU()"
          [hidePlu]="!isPLU()"
          [(pluRangeModel)]="this.supplierProductService.candidate.candidateProducts[0].pluRange"
          [(pluTypeModel)]="this.supplierProductService.candidate.candidateProducts[0].pluType"
          [fullPlu]="getFullPlu()"
          style="width: 80%;">
        </pm-upc-input>
      </div>
      <div *ngIf="!isPLU()" class="row">
        <p class="ml-0" style="font-size: 12px; color: #7B7B7B;">
          Don't have a UPC? Generate a <span (click)="generate04UPC()" style="color: #0066CC; cursor: pointer;">standard 04UPC</span>.
        </p>
      </div>

      <div class="row" *ngIf="hasUpcError()">
        <p class="error-message">{{candidateProductError?.upc}}</p>
      </div>
    </div>

    <div *ngIf="showMatAttributes" class="col-md-10 mt-3">

      <div *ngIf="isLoadingMatData" class="row" style="display: flex; align-items: center">
        <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                             [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
        <h5 class="ml-3">Fetching extended attribute data...</h5>
      </div>
      <div *ngIf="!isLoadingMatData" class="row">
        <pm-category-selection [model]="supplierProductService.candidate.candidateProducts[0].matHierarchyList"
                               (selection)="onHierarchySelection($event)"
                               [attributeError]="candidateProductError?.categorySelectionError"
                               style="width: 80%">

        </pm-category-selection>
      </div>
    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="isLoadingMatData"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="isNextDisabled || isLoadingMatData"></p-button>
  </pm-footer>
</pm-editor>
