import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTypeaheadConfig } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class Buyer implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'Buyer',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      description: `Choose the H-E-B buyer you're working with.`,
      name: 'Buyer',
      idRef: 'buyerId',
      displayRef: 'displayName',
      displaySubRef: '',
      searchUrl: '/lookup/buyer',
      placeholderText: 'Type to search for a buyer...',
      showAddlInfo: false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.isDropdown = false;
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.buyer;
    componentInstance.selection.subscribe(x => {
      candidate.buyer = x;
      candidate.commodity = null;
      candidate.subCommodity = null;
      if (configOverrides) {
        configOverrides.saveDisabled = !candidate.buyer;
      }
    });
  }
  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.buyer) {
      componentInstance.model = candidate.buyer;
    }
  }
}
