import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {RequestNewAttributeFormComponent} from './request-new-attribute-form.component';


@NgModule({
  declarations: [RequestNewAttributeFormComponent],
  exports: [RequestNewAttributeFormComponent, AttributeDisplayModule],
  imports: [
    CommonModule, AttributeDisplayModule
  ]
})
export class RequestNewAttributeFormModule { }
