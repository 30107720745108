import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardModule} from '../../shared/card/card.module';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {MerchandisingInfoCardComponent} from './merchandising-info-card.component';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [MerchandisingInfoCardComponent],
  exports: [MerchandisingInfoCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule,
    AttributeDisplayModule, RouterModule
  ],
  providers: [{ provide: LookupService, useValue: undefined }]
})
export class MerchandisingInfoCardModule { }
