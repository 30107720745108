<pm-review *ngIf="isViewingPage" (closeEvent)="onClose()">
  <div *ngIf="candidate" class="reviewCard">
    <ng-container>
      <div class="col">
        <div class="sectionHeader">{{candidate.description}}</div>
        <pm-app-reject-status-header class="isRejectedAssociate" [candidate]="candidate"></pm-app-reject-status-header>
        <div class="row">
          <div class="primary-attribute">
            <img class="product-image" src="{{productImageUrl ? productImageUrl : DEFAULT_NO_PRODUCT_IMAGE}}">
          </div>
          <div class="col pl-0">
            <div class="row-md-4 primary-attribute">
              <div class="col">
                <div class="upc-header">UPC: {{candidate?.candidateProducts[0].upc}}-{{candidate?.candidateProducts[0].upc | upcCheckDigit}}</div>
                <button pButton type="button"
                        [label]="'View candidate history'"
                        class="ui-button-link" (click)="historyPanelOpen()">

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="primaryUpcTitle">New Associate Details</div>
      <div class="section-header header">Associate Upc Details</div>
      <pm-attribute-grid>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(associateCandidateProduct, 'upc') || candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'upcCheckDigit')"
                          (historyClicked)="historyPanelOpen()">
              <tr *ngFor="let candidateProduct of associateCandidateProductsList">
                <div *ngIf="!candidateProduct.isAddComplimentaryPlu">
                  {{candidateProduct?.upc}}-{{candidateProduct?.upcCheckDigit}}
                </div>
                <div *ngIf="candidateProduct.isAddComplimentaryPlu">
                  {{ associateUpcService.getComplimentaryUpcAndCheckDigit(candidateProduct) }}
                </div>
              </tr>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-Brand'">
              <div class="pm-grid-cell-text">{{candidate?.candidateProducts[0]?.subBrand?.description}}</div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate?.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate?.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'">
              {{candidateUtilService.getMasterDimensions(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit of measure'">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'Supplier Comments'" [canEdit]="false">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </ng-container>

    <ng-container>
      <p style="margin-top: 2rem; font-weight: 400;">The supplier added this associate UPC to the following existing case.</p>
      <div class="row divider"></div>
      <ng-template ngFor let-caseUpc [ngForOf]="productData">
        <div class="attribute-group"
             *ngIf="(equals(caseUpc.caseUpc))">
          <div>
            <p-radioButton id="associateCaseSelection"
                           name="associateCaseSelection"
                           [(ngModel)]="selectedCasePack"
                           [value]="caseUpc.caseUpc">
            </p-radioButton>
          </div>
          <div style="margin-bottom: 10px;">
            <div class="attribute-label">
              <label>
                Case Upc: {{caseUpc?.caseUpc}}-{{caseUpc?.caseUpc | upcCheckDigit}}
              </label>
            </div>
            <p class="attribute-description-label">
              {{caseUpc?.description}}
            </p>
            <p class="attribute-description">
              Item Code: <mark class="mark-css">{{caseUpc?.itemCode}}</mark>
              Channel: <mark class="mark-css">WHS</mark>
              Master Pack: <mark class="mark-css">{{caseUpc?.supplierItems[0]?.pack}}</mark>
              Inner Pack: <mark class="mark-css">{{caseUpc?.innerPackQuantity}}</mark>
              Status: <mark class="mark-css">Active</mark>
            </p>
            <p class="attribute-description">
              # UPCs in case: <mark class="mark-css">{{caseUpc?.containedUpc?.associatedUpcs?.length + 1}}</mark>
              VPC: <mark class="mark-css">{{caseUpc?.supplierItems[0]?.supplierItemId}}</mark>
            </p>
          </div>
        </div>
      </ng-template>


      <div *ngIf="showMatAttributes">

        <div *ngIf="candidateUtilService.isInProgressCandidate(candidate)">
          <pm-attribute-grid *ngIf="isLoadingMatData" [title]="'Extended attributes: UPC'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>
          <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(candidate)?.length" [title]="'Category attributes'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>


          <div *ngIf="!isLoadingMatData && !!upcAttributes?.length">
            <div *ngFor="let candidateProduct of candidate.candidateProducts; let i = index" style="margin-bottom: 4rem;">
              <div *ngIf="i !== 0">
                <h2 style="text-align: left; font-weight: 600;">Associate UPC: {{ associateUpcService.getUpc(candidateProduct) }}</h2>

                <!--  Global Attributes    -->
                <pm-attribute-grid [title]="'Extended attributes: UPC'">
                  <pm-grid-group>
                    <pm-grid-column>
                      <div *ngFor="let attribute of upcAttributes; let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of upcAttributes; let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of upcAttributes; let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct,attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct,attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>

                  </pm-grid-group>
                </pm-attribute-grid>
                <!--   Mat Hierarchy attributes   -->
                <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
                  <pm-attribute-grid *ngIf="hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                    <pm-grid-group>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>

                    </pm-grid-group>
                  </pm-attribute-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!candidateUtilService.isInProgressCandidate(candidate)">

          <pm-attribute-grid *ngIf="isLoadingMatData" [title]="'Extended attributes: UPC'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>
          <pm-attribute-grid *ngIf="isLoadingMatData" [title]="'Category attributes'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>

          <div *ngFor="let candidateProduct of candidate.candidateProducts; let i = index" style="margin-bottom: 4rem;">
            <div *ngIf="i !== 0">
              <h2 *ngIf="!!candidateProduct.globalAttributes?.length || matUtilService.hasMatHierarchyAttributeValues(candidateProduct)" style="text-align: left; font-weight: 600;">Associate UPC: {{associateUpcService.getUpc(candidateProduct)}}</h2>
              <!--  Global Attributes    -->
              <pm-attribute-grid *ngIf="!!candidateProduct.globalAttributes?.length" [title]="'Extended attributes: UPC'">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of candidateProduct.globalAttributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of candidateProduct.globalAttributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of candidateProduct.globalAttributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct,attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct,attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct,attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>

              <!--   Mat Hierarchy attributes   -->
              <div *ngIf="!!candidateProduct?.matHierarchyList.length">
                <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
                  <pm-attribute-grid *ngIf="!!hierarchy.attributes?.length" [title]="'Category attributes: ' + hierarchy.name">
                    <pm-grid-group>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>

                    </pm-grid-group>
                  </pm-attribute-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
  </div>

  <pm-review-drawer-container>
    <!--  Audit History -->
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="candidateHistoryService.currentHistoryResults">
            <div *ngIf="candidateHistoryService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{candidateHistoryService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>
  <pm-footer>
    <p-button class="ghost m-2" label="Back to home" (onClick)="onClickBackToHome()"></p-button>
    <p-button class="m-2" label="Print" (onClick)="onClickPrint()"></p-button>
  </pm-footer>
</pm-review>
