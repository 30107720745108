import {Candidate} from './candidate';

export class WorkFlowEntity {
  // variables related to Workflow Entities
  id: string;
  status: string;
  candidate: Candidate;
  candidateId: number;
  elapsedTime: number;
  upc: number;
  itemCode: number;

  processInstanceId: string;

  vendorViewed: boolean;

  data: any;
  apNumber: number;
}
