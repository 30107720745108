<ng-container>
  <div class="row align-items-center request-new-attribute-form-overlay-wrapper">
    <div class="col-md-4 request-new-attribute-form-overlay">
      <div class="header-row">
        <h2>Request new brand or cost owner</h2>
        <p>Fill out the fields below to request a new brand and/or cost owner.<br><br>

          We’ll send your request to our Data Management team for review. They will notify you via email once they’ve reviewed your request.</p>
      </div>
      <div class="attributes-stacked">
        <pm-attribute-display-text-input [attribute]="brandConfiguration" (modelChange)="brandChange($event)">
        </pm-attribute-display-text-input>
        <pm-attribute-display-text-input [attribute]="costOwnerConfiguration" (modelChange)="costOwnerChange($event)">
        </pm-attribute-display-text-input>
        <pm-attribute-display-text-input [attribute]="topToTopConfiguration" (modelChange)="topToTopChange($event)">
        </pm-attribute-display-text-input>
        <pm-attribute-display-text-input [attribute]="supplierConfiguration" (modelChange)="supplierChange($event)">
        </pm-attribute-display-text-input>
        <pm-attribute-display-text-input [attribute]="emailConfiguration"
                                         [(model)]="model.email"
                                         (modelChange)="onEmailChange($event)"
                                         [attributeError]="emailError">
        </pm-attribute-display-text-input>
        <pm-attribute-display-text-input [attribute]="notesConfiguration" (modelChange)="notesChange($event)">
        </pm-attribute-display-text-input>
      </div>
      <div class="row button-row">
        <p-button class="ghost m-2" label="Cancel" (onClick)="cancel()"></p-button>
        <p-button class="m-2" label="Send Request" [disabled]="isDisabled()" (onClick)="sendRequest()"></p-button>
      </div>
    </div>
  </div>
</ng-container>
