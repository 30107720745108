import {CandidateProduct} from 'pm-models/lib/candidateProduct';
import {DrugNameAbbreviation} from 'pm-models/lib/drugNameAbbreviation';
import {DrugScheduleTypeCode} from 'pm-models/lib/drugScheduleTypeCode';
import {PseType} from 'pm-models/lib/pseType';
import {PssDepartment} from 'pm-models/lib/pssDepartment';
import {Commodity} from './commodity';
import {SubCommodity} from './subCommodity';
import {RetailLink} from './retailLink';
import {CostLink} from './costLink';
import {Vendor} from './vendor';
import {File} from './file';
import {MrtInfo} from 'pm-models/lib/mrtInfo';
import {MerchandisingInfoCardModel, SubDepartment, VertexTaxCategoryModel} from 'pm-models';
import {NutritionPanel} from 'pm-models/lib/nutritionPanel';
import {FreightTaxUpcRequest} from 'pm-models/lib/freightTaxUpcRequest';

export class Candidate {
  public static SELLABLE = 'SELLABLE';
  public static NON_SELLABLE = 'NON_SELLABLE';

  public static KEY_RETAIL = 'Key Retail';
  public static PRICE_REQUIRED = 'Price Required';

  public static RETAIL_LINK = 'Retail Link';

  // Candidate Types
  public static NEW_PRODUCT = 'NEW_PRODUCT';
  public static ADDITIONAL_CASE_PACK = 'ADDITIONAL_CASE_PACK';
  public static BONUS_SIZE = 'BONUS_SIZE';
  public static ASSOCIATE_UPC = 'ASSOCIATE_UPC';
  public static MRT = 'MRT';
  public static MRT_INNER = 'MRT_INNER';
  public static PLU = 'PLU';
  public static ADDITIONAL_DISTRIBUTOR = 'ADDITIONAL_DISTRIBUTOR';
  public static SUPPLIER_ADDITIONAL_DISTRIBUTOR = 'SUPPLIER_ADDITIONAL_DISTRIBUTOR';
  public static REPLACEMENT_UPC = 'REPLACEMENT_UPC';
  public static NEW_PRODUCT_UPLOAD_PARENT = 'NEW_PRODUCT_UPLOAD_PARENT';
  public static EDI_UPLOAD_PARENT = 'EDI_UPLOAD_PARENT';
  public static NEW_PRODUCT_UPLOAD = 'NEW_PRODUCT_UPLOAD';

  // Candidate Statuses (status) field
  public static ACTIVATED = 'ACTIVATED';
  public static DECLINED = 'DECLINED';
  public static COMPLETED = 'COMPLETED';
  public static IN_PROGRESS = 'IN_PROGRESS';
  public static UPLOADED = 'UPLOADED';
  public static ACTIVATING_BATCH = 'ACTIVATING_BATCH';
  public static PARTIAL_FAILURE = 'PARTIAL_FAILURE';
  public static FAILED = 'FAILED';

  /**
   * Approved status is for Non Replenishable Products reviewed by the procurement support team
   * but awaiting the parent MRT for activation.
   */
  public static APPROVED = 'APPROVED';
  /**
   * Candidate it set to deleted when its a part of an MRT but the MRT is rejected.
   */
  public static DELETED = 'DELETED';

  public static CORRECTIONS_NEEDED = 'Corrections needed';
  public static NOT_WANTED = 'No longer wanted';
  public static OTHER = 'Other';

  candidateId: number;
  candidateType: string;
  likeId: number;
  numberOfStores: number;
  description: string;
  displayName: string;
  createdTime: number;
  createdBy: string;
  lastUpdatedTime: number;
  lastUpdatedBy: string;
  candidateProducts: CandidateProduct[];
  vendor: Vendor;
  contactName: any;
  contactNumber: any;
  contactEmail: any;
  buyer: any;
  brand: any;
  costOwner: any;
  totalVolume: any;
  productLength: any;
  prePrice: Boolean;
  unitOfMeasure: any;
  productWidth: any;
  retailXFor: any;
  retailPrice: any;
  suggestedXFor: any;
  suggestedRetailPrice: any;
  retailSize: any;
  productHeight: any;
  packageType: any;
  productWeight: any;
  mapRetail: any;
  foodStamp: Boolean;
  taxable: Boolean;
  flexibleSpendingAccount: Boolean;
  masterLength: any;
  innerLength: any;
  vendorTier: any;
  innerListCost: any;
  masterHeight: any;
  innerHeight: any;
  vendorTie: any;
  unitCost: any;
  masterWidth: any;
  innerWidth: any;
  codeDate: any;

  masterWeight: any;
  innerWeight: any;
  maxShelfLife: any;
  inboundSpecDays: any;
  guaranteeToStoreDays: any;
  warehouseReactionDays: any;
  masterCube: any;
  innerCube: any;
  masterPack: any;
  innerPack: number;
  innerPackSelected: boolean;
  commodity: Commodity;
  subCommodity: SubCommodity;
  season: any;
  seasonYear: any;
  retailLink: RetailLink;
  weightSwitch: any;
  retailType: string;
  productType: string;
  merchandiseType: any;
  masterListCost: any;
  vendorComment: string;
  scaComment: string;
  bdmComment: string;
  obRegComment?: string;
  obRegName?: string;
  dsdSwitch: boolean;
  warehouseSwitch: Boolean;
  lane: any;
  status: string;
  cubeAdjustedFactor: any;
  costLinked: Boolean;
  costLink: number;
  costLinkBy: string;
  costLinkFromServer: CostLink;

  displayReadyUnit: boolean;
  displayReadyUnitType: string;
  displayReadyUnitRowsFacing: number;
  displayReadyUnitRowsDeep: number;
  displayReadyUnitRowsHigh: number;
  displayReadyUnitOrientation: string;
  itemWeightType: any;
  attachments: File[];
  supplierComment: string;
  buyerComment: string;
  buyerCommentUser: string;
  scaName: string;
  piaComment: string;
  piaCommentUser: string;
  apNumbers?: number[];
  productId?: number;

  mrtInfo: MrtInfo;
  maxShip: any;
  role: any;
  pssDepartment: PssDepartment;
  vertexTaxCategory: any;
  vertexTaxCategoryModel?: VertexTaxCategoryModel;
  dealOffered: boolean;
  missingWarehouses: boolean;
  missingWarehousesComment?: string;
  missingWarehousesCommentUser?: string;
  missingWarehousesCommentTime?: number;
  missingWarehousesResolveUser?: string;
  rushFlag?: boolean;
  showCalories?: boolean;
  selected?: boolean;
  candidateNutrition?: any;
  scaleCandidateNutrition?: NutritionPanel;

  // Pharmacy fields
  nationalDrugCodeId?: string;
  drugNameAbbreviation?: DrugNameAbbreviation;
  drugScheduleTypeCode?: DrugScheduleTypeCode;
  directPharmacyCost?: number;
  averageWholesalePharmacyCost?: number;
  pseType?: PseType;
  pseGrams?: number;
  upcRequestNotes?: any;

  overrideDepartment?: boolean;
  overrideSubDepartment?: SubDepartment;
}

/**
 * Provides helper functions for the Candidate class.
 */
export class CandidateHelper {

  /**
   * Returns the Vertex tax category for a candidate.
   *
   * @param {Candidate} c The candidate to find the Vertex tax category for.
   * @returns {string} The Vertex tax category. This may be null.
   */
  static getVertexTaxCategory(c: Candidate): string {

    // If the sub-commodity is not set, then tax category is undefined.
    if (c.subCommodity == null) {
      return null;
    }

    // If the product is taxable, return the taxable Vertex tax category.
    if (c.taxable) {
      if (c.subCommodity.taxableVertexTaxCategory == null) {
        return null;
      }
      return c.subCommodity.taxableVertexTaxCategory;
    }

    // If the product is not taxable, return the non-taxable Vertex tax category.
    if (c.subCommodity.nonTaxableVertexTaxCategory == null) {
      return null;
    }

    return c.subCommodity.nonTaxableVertexTaxCategory;
  }

  static getVertexTaxCategoryNumber(c: Candidate | FreightTaxUpcRequest): string {

    // If the sub-commodity is not set, then tax category is undefined.
    if (c.subCommodity === null || c.subCommodity === undefined) {
      return null;
    }

    // If the product is taxable, return the taxable Vertex tax category.
    if (c.taxable) {
      if (c.subCommodity.taxableVertexTaxCategory == null) {
        return null;
      }
      return c.subCommodity.taxableVertexTaxCategory.substr(0, c.subCommodity.taxableVertexTaxCategory.indexOf('-')).trim();
    }

    // If the product is not taxable, return the non-taxable Vertex tax category.
    if (c.subCommodity.nonTaxableVertexTaxCategory == null) {
      return null;
    }

    return c.subCommodity.nonTaxableVertexTaxCategory.substr(0, c.subCommodity.nonTaxableVertexTaxCategory.indexOf('-')).trim();
  }

  /**
   * Returns is tax code for a candidate.
   *
   * @param {Candidate} c The candidate to check.
   * @returns {Boolean} is tax code for a candidate.
   */
  static isTaxCode(c: Candidate | MerchandisingInfoCardModel): Boolean {
    if (
      c.subCommodity === undefined || c.subCommodity === null ||
      c.subCommodity.subCommodityTaxCode === undefined || c.subCommodity.subCommodityTaxCode === null
    ) {
      return null;
    } else {
      return c.subCommodity.subCommodityTaxCode;
    }
  }

  /**
   * Returns is food stamp for a candidate.
   *
   * @param {Candidate} c The candidate to check.
   * @returns {Boolean} is food stamp for a candidate.
   */
  static isFoodStamp(c: Candidate | MerchandisingInfoCardModel): Boolean {
    if (
      c.subCommodity === undefined || c.subCommodity === null ||
      c.subCommodity.subCommodityFoodStamp === undefined || c.subCommodity.subCommodityFoodStamp === null
    ) {
      return null;
    } else {
      return c.subCommodity.subCommodityFoodStamp;
    }
  }
}
