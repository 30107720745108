import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

export interface StepperItem {
  text: '';
  icon: '';
  message: '';
}

@Component({
  selector: 'pm-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input()
  stepperItems: StepperItem[];

  @Input()
  activeIndex: number;

  @Output() indexClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  onClick(event) {
    this.indexClick.emit(event);
  }
}
