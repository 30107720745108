// tslint:disable: max-line-length
import {Component, Input, OnInit} from '@angular/core';
import {Candidate, CandidateError} from 'pm-models';
import {UploadCandidateService} from '../../../../../../src/app/2.0.0/service/upload-candidate.service';

@Component({
  selector: 'pm-upload-candidate-table',
  templateUrl: './upload-candidate-table.component.html',
  styleUrls: ['./upload-candidate-table.component.scss']
})
export class UploadCandidateTableComponent implements OnInit {

  @Input() candidateType: string;
  @Input() isWarehouse: boolean;
  @Input() isDsd: boolean;
  @Input() workflowState: string;
  @Input() candidates: Candidate[];
  @Input() isBuyer: boolean = false;
  @Input() isSca: boolean = false;
  @Input() candidateProductIndex;
  @Input() uuidToImageMap: Map<string, any>;
  @Input() candidateIdToCandidateErrorMap: Map<number, CandidateError>;
  @Input() candidateErrorArray: any[];

  @Input() hasProductDetailErrors: boolean = false;
  @Input() hasCaseDetailErrors: boolean = false;
  @Input() hasSupplierDetailErrors: boolean = false;
  @Input() hasWarehouseDetailErrors: boolean = false;
  @Input() hasDsdErrors: boolean = false;

  isShowingProductTab: boolean = false;
  isShowingCaseTab: boolean = false;
  isShowingSupplierAndHebTab: boolean = false;
  isShowingWarehouseTab: boolean = false;
  isShowingDsdTab: boolean = false;

  isProductTabSelected: boolean = false;
  isCaseTabSelected: boolean = false;
  isSupplierAndHebTabSelected: boolean = false;
  isWarehouseTabSelected: boolean = false;
  isDsdTabSelected: boolean = false;

  constructor(private uploadCandidateService: UploadCandidateService) {
  }

  tabIndex: number = 0;

  PRODUCT_TAB = {
    title: 'Product',
    tooltip: '',
    index: 0
  };

  CASE_TAB = {
    title: 'Case',
    tooltip: '',
    index: 1
  };

  SUPPLIER_AND_HEB_TAB = {
    title: 'Supplier & H-E-B',
    tooltip: '',
    index: 2
  };

  WAREHOUSE_TAB = {
    title: 'Warehouse',
    tooltip: '',
    index: 3
  };

  DSD_TAB = {
    title: 'DSD',
    tooltip: '',
    index: 4
  };

  ngOnInit() {
    this.changeTab({index: this.tabIndex});
    this.initializeColumnsAndTabs();
  }


  changeTab(event) {
    this.resetSelected();
    this.tabIndex = event.index;
    switch (this.tabIndex) {
      case 0: this.isProductTabSelected = true; break;
      case 1: this.isCaseTabSelected = true; break;
      case 2: this.isSupplierAndHebTabSelected = true; break;
      case 3: this.isWarehouseTabSelected = true; break;
      case 4: this.isDsdTabSelected = true; break;
    }
  }

  resetSelected() {
    this.isProductTabSelected = false;
    this.isCaseTabSelected = false;
    this.isSupplierAndHebTabSelected = false;
    this.isWarehouseTabSelected = false;
    this.isDsdTabSelected = false;
  }

  /**
   * Initializes whether or not to display tabs in the table.
   */
  private initializeColumnsAndTabs() {
    if (this.candidateType) {
      this.isShowingProductTab = this.uploadCandidateService.getProductColumnsFromCandidateType(this.candidateType).length !== 0;
      this.isShowingCaseTab = this.uploadCandidateService.getCaseColumnsFromCandidateType(this.candidateType, this.isWarehouse).length !== 0;
      this.isShowingSupplierAndHebTab = this.uploadCandidateService.getSupplierAndHebColumnsFromCandidateType(this.candidateType, this.isWarehouse).length !== 0;
      this.isShowingWarehouseTab = this.uploadCandidateService.getHasWarehouseColumnsFromCandidateType(this.candidateType, this.isWarehouse);
      this.isShowingDsdTab = this.uploadCandidateService.getHasDsdColumnsFromCandidateType(this.candidateType, this.isDsd);
    } else if (this.workflowState) {
      this.isShowingProductTab = this.uploadCandidateService.getProductColumnsFromWorkflowState(this.workflowState).length !== 0;
      this.isShowingCaseTab = this.uploadCandidateService.getCaseColumnsFromWorkflowState(this.workflowState).length !== 0;
      this.isShowingSupplierAndHebTab = this.uploadCandidateService.getSupplierAndHebColumnsFromWorkflowState(this.workflowState).length !== 0;
      this.isShowingWarehouseTab = this.uploadCandidateService.getHasWarehouseColumnsFromWorkflowState(this.workflowState, this.isWarehouse);
      this.isShowingDsdTab = this.uploadCandidateService.getHasDsdColumnsFromWorkflowState(this.workflowState, this.isDsd);
    }
  }
}
