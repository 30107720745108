import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpcService {
  private readonly MAX_G14_LENGTH = 13;

  constructor() { }

  isG14Upc(upc) {
    const upcAsString = upc + '';
    return upcAsString.length <= this.MAX_G14_LENGTH;
  }

  validCheckDidgit(upcCode, checkDigit) {
    // Check if only digits.
    const upcCodeAsString = this.getG14Upc(upcCode);
    const validChars = '0123456789';
    for (let i = 0; i < upcCodeAsString.length; i++) {
      const digit = upcCodeAsString.charAt(i);
      if (validChars.indexOf(digit) === -1) {
        return false;
      }
    }

    // Make sure the check digit is only on digit.
    const checkDigitAsString = checkDigit.toString();
    if (checkDigitAsString.length !== 1) {
      return false;
    }
    if (validChars.indexOf(checkDigitAsString) === -1) {
      return false;
    }

    // Add up the individual digits into two buckets, one is locations 0, 2, 4, etc. and the
    // other is locations 1, 3, 5, etc.
    let groupOneSum = 0;
    let groupTwoSum = 0;

    for (let i = 0; i < upcCodeAsString.length; i++) {

      if (i % 2 === 0) {
        groupOneSum += Number(upcCodeAsString.charAt(i));
      } else {
        groupTwoSum += Number(upcCodeAsString.charAt(i));
      }
    }

    // Add the sum of the even digits times 3 to the sum of the odd digits.
    const total: number = (groupOneSum * 3) + groupTwoSum;

    // Mod that total by 10.
    let checksum: number = total % 10;

    // If result is not 0 then take that number away from 10.
    if (checksum !== 0) {
      checksum = 10 - checksum;
    }

    // Return the result
    return checksum === checkDigit;
  }

  getG14Upc(upcCode: number): string {
    const upcAsString = upcCode + '';
    return upcAsString.padStart(this.MAX_G14_LENGTH, '0');
  }

  pluToPreDigitTwo(plu: number) {
    return 20000000000 + plu * 100000;
  }

  public pluToG14PreDigitTwo(plu: number) {
    if (!this.isPlu(plu)) {
      return null;
    }
    return this.getG14Upc(this.pluToPreDigitTwo(plu));
  }

  public isPlu(plu: number) {
    return plu > 0 && plu < 99999;
  }

  public isPreDigitTwo(upc: number) {
    return this.getG14Upc(upc).startsWith('002');
  }

  preDigitTwoToPlu(preDigitTwo) {
    if (this.isPreDigitTwo(preDigitTwo)) {
      return this.getG14Upc(preDigitTwo).substring(3, 8);
    }
    return null;
  }
}
