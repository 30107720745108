import {Component, Input, OnInit} from '@angular/core';
import {Candidate} from 'pm-models';

@Component({
  selector: 'pm-no-stores-message-extended',
  templateUrl: './no-stores-message-extended.component.html',
  styleUrls: ['./no-stores-message-extended.component.scss']
})
export class NoStoresMessageExtendedComponent implements OnInit {

  @Input()
  public candidate: Candidate;

  constructor() { }

  ngOnInit() {
  }

}
