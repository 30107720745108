<div class="pm-review-body">
  <pm-drawer-container class="d-flex flex-grow-1 w-100 pm-review-page">
    <pm-drawer-content class="w-100  bg-surface">
      <div class="container">
        <div class="row">
          <div class="pm-editor-header px-3 py-4 col">
            <div class="row">
              <div class="col-1">
                <pm-close (click)="closeEvent.emit()"></pm-close>
              </div>
              <div class="col">
                <ng-content select="pm-header"></ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="review-body">
        <div class="container">
          <header class="row justify-content-md-center pt-5 pb-3" *ngIf="headerFacet">
            <div class="col col-md-10 ">
              <h1 class="editor-title">
                <ng-content select="pm-title"></ng-content>
              </h1>
              <h2 class="editor-sub-title">
                <ng-content select="pm-sub-title"></ng-content>
              </h2>
            </div>
          </header>
          <ng-content></ng-content>
          <div class="review-footer-spacer"></div>
        </div>
      </div>

    </pm-drawer-content>

    <pm-drawer #drawer mode="side" position="end" [(opened)]="opened" class="drawer-wrapper">
      <ng-content select="pm-review-drawer-container"></ng-content>
    </pm-drawer>
  </pm-drawer-container>

  <footer class="review-footer" *ngIf="footerFacet" [ngClass]="{'review-footer__hidden' : opened}">
    <div class="container">
      <div class="row  ">
        <div class="col-6 py-3 offset-3 text-right">
          <ng-content select="pm-footer"></ng-content>
        </div>
      </div>
    </div>
  </footer>
</div>
