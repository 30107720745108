import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeConfig,
  AttributeDualInputConfig, AttributeTextInputConfig,
  Candidate, PricingCardErrors,
  PricingCardPermissions,
  RetailLink, TextInputType
} from 'pm-models';
import {InputState} from '../../shared/attribute-display/attribute-display-muli-source-input/attribute-display-muli-source-input.component';
import {formatCurrency} from '@angular/common';
import { CostService } from '../../../../../../src/app/2.0.0/service/cost.service';

@Component({
  selector: 'pm-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss']
})
export class PricingCardComponent implements OnInit {

  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public UPC = 'UPC';
  public ITEM_CODE = 'Item Code';
  public RETAIL_LINK_NUMBER = 'Link Number';

  digitsInfo: string = '1.2-2';

  @Input()
  model: Candidate;

  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: PricingCardPermissions;

  @Input()
  errorModel: PricingCardErrors;

  @Input()
  retailLinkState: InputState;

  @Input()
  onlyShowSecondInputSuggestedRetail: boolean;

  @Output() validateRetailLink = new EventEmitter<any>();

  constructor(public costService: CostService) { }


  pricingTypeConfiguration: AttributeConfig = {
    label: 'Pricing type',
    description: '',
    isDisabled: () => this.isRetailTypeDisabled(),
    isReadOnly: () => this.isRetailTypeDisabled(),
    isRequired: true,
    inputGroupClass: 'attribute-radios-block',
    options: [
      { label: 'Key retail', value: this.KEY_RETAIL },
      { label: 'Retail link', value: this.RETAIL_LINK },
      { label: 'Price required', value: this.PRICE_REQUIRED }
    ],
  };

  retailLinkConfiguration: AttributeConfig = {
    options: [

      { value: this.RETAIL_LINK_NUMBER, label: 'Link #' },
      { value: this.UPC, label: 'UPC' },
      { value: this.ITEM_CODE, label: 'Item Code' }
    ],
    label: 'Retail link',
    description: 'Inherit retail price from another product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => !this.isRetailLink(),
    isRequired: true,
    defaultValue: this.UPC,
    placeholderText: ''
  };

  getXForConfiguration() {
    const xForConfiguration: AttributeDualInputConfig = {
      collections: [],
      description: '',
      label: 'Retail pricing',
      name: '',
      options: [],
      isRequired: true,
      input1DecimalCount: 0,
      input1NumberCount: 3,
      input1Placeholder: '#',
      input2DecimalCount: 2,
      input2Placeholder: '$',
      input2NumberCount: 9,
      separatingText: 'for',
      isDisabled: () => this.isRetailPriceDisabled(),
      isReadOnly: () => this.isRetailPriceDisabled(),
      onlyShowSecondInput: this.onlyShowSecondInputSuggestedRetail ? this.onlyShowSecondInputSuggestedRetail : false
    };

    return xForConfiguration;
  }

  soldByWeightConfiguration: AttributeConfig = {
    label: 'Sold by weight',
    description: `Select if the item is sold by the pound.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    defaultValue: false
  };

  pennyProfitConfiguration: AttributeTextInputConfig = {
    label: 'H-E-B Penny profit',
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `(total)`,
    textInputType: TextInputType.text,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 2
  };

  marginPercentConfiguration: AttributeTextInputConfig = {
    label: 'H-E-B Margin %',
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `(total)`,
    textInputType: TextInputType.text,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 4
  };

  ngOnInit() {
  }

  /**
   * Returns whether or not retail type is disabled.
   * @returns {boolean}
   */
  isRetailTypeDisabled() {
    return this.model.productType === 'NON_SELLABLE';
  }

  get isSellable(): boolean {
    return this.model && this.model.productType === 'SELLABLE';
  }


  pricingTypeChange(event) {
    this.model.retailType = event;

    if (this.isRetailLink()) {
      this.model.retailLink = new RetailLink();
      this.model.retailLink.upcType = this.UPC;
      this.model.retailXFor = '';
      this.model.retailPrice = '';
      this.retailLinkConfiguration.placeholderText = ''; // temp fix for bug that doesn't update retail component.
    } else {
      this.model.retailLink = null;
      if (this.isKeyRetail()) {
        this.model.retailXFor = '';
        this.model.retailPrice = '';
      } else if (this.isPriceRequired()) {
        this.model.retailXFor = 1;
        this.model.retailPrice = 0.00;
      }
    }
  }

  onRetailLinkNumberChange(event) {
    if (!event.model) {
      this.model.retailLink.searchedId = '';
    } else {
      this.model.retailLink.searchedId = event.model;
    }
    if (!event.category) {
      this.model.retailLink.upcType = '';
    } else {
      this.model.retailLink.upcType = event.category;
    }
  }

  onRetailLinkTypeChange(event) {
    if (event && this.model.retailLink) {
      this.model.retailLink.upcType = event;
    }
  }

  isKeyRetail(): boolean {
    return this.model.retailType === this.KEY_RETAIL;
  }

  isRetailLink(): boolean {
    return this.model.retailType === this.RETAIL_LINK;
  }

  isPriceRequired(): boolean {
    return this.model.retailType === this.PRICE_REQUIRED;
  }
  validateRetailLinkEvent(e) {
    this.validateRetailLink.emit(e);
  }

  isRetailPriceDisabled() {
    return this.isRetailLink() || this.isPriceRequired();
  }

  soldByWeightChange(event) {
    this.model.weightSwitch = event.checked;
  }
  /**
   * Calculates the H-E-B penny profit.
   */
  public getHebPennyProfit() {
    if (this.model.retailPrice && this.model.retailXFor && this.model.unitCost &&
      this.model.retailType && this.model.retailType !== this.PRICE_REQUIRED) {
      return formatCurrency(
        (+this.model.retailPrice / +this.model.retailXFor) - +this.model.unitCost, 'en-US', '', '', this.digitsInfo);
    } else {
      return;
    }
  }
}
