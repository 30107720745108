import {Product} from 'pm-models';

export class ExistingInner {

  upc?: number;

  upcCheckDigit?: number;

  unitCost?: number;

  quantity?: number;

  product?: Product;

}
