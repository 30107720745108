import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTypeaheadConfig } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class PssDepartment implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'PSS Department',
      description: 'Select the point of sale sub-department.',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: 'pssDepartmentId',
      displayRef: 'displayName',
      placeholderText: 'Select a PSS Department...',
      idRef: 'pssDepartmentId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.pssDepartment;
    componentInstance.selection.subscribe(x => {
      candidate.pssDepartment = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.pssDepartment) {
      componentInstance.model = candidate.pssDepartment;
    }
  }
}
