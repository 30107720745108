<pm-editor #pmEditor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-mrt-case-stepper *ngIf="supplierMrtService.hasNewInners(supplierMrtService.candidate) === false"
                          [candidateErrors]="supplierMrtService.getAllSupplierMrtErrors()"
                          [stepIndex]="2" [steps]="supplierMrtService.getNoInnerFlowSteps()"
                          (indexClick)="onClickStepper($event)">
    </app-mrt-case-stepper>
    <app-mrt-case-stepper *ngIf="supplierMrtService.hasNewInners(supplierMrtService.candidate)"
                          [candidateErrors]="supplierMrtService.getAllSupplierMrtErrors()"
                          [hasInnerCandidateError]="[supplierMrtService.hasInnerErrors()]"
                          [stepIndex]="3" [steps]="supplierMrtService.getNewInnerFlowSteps()"
                          (indexClick)="onClickStepper($event)">
    </app-mrt-case-stepper>
  </pm-header>

  <pm-title>Tell us about the MRT’s case.</pm-title>
  <ng-container *ngIf="supplierMrtService.candidate">

    <pm-heb-details-card [(model)]="supplierMrtService.candidate"
                         [(candidateProductModel)]="supplierMrtService.candidate.candidateProducts[0]"
                         (buyerChange)="onBuyerChange($event)"
                         (commodityChange)="onCommodityChange($event)"
                         (subCommodityChange)="onSubCommodityChange($event)"
                         [commodityConfiguration]="getCommodityConfiguration()"
                         [subCommodityConfiguration]="getSubCommodityConfiguration()"
                         [permissions]="hebDetailsCardPermissions"
                         [errorModel]="candidateError"
                         [candidateProductErrorModel]="candidateProductError">

    </pm-heb-details-card>

    <pm-supplier-details-card [(candidateModel)]="supplierMrtService.candidate"
                              [(candidateProductModel)]="supplierMrtService.candidate.candidateProducts[0]"
                              [permissions]="supplierDetailsCardPermissions"
                              [candidateErrorModel]="candidateError"
                              [candidateProductErrorModel]="candidateProductError"
                              [inputCostOwners]="inputCostOwners">

    </pm-supplier-details-card>

    <pm-master-pack-details-card [(model)]="supplierMrtService.candidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="candidateError">
    </pm-master-pack-details-card>

    <pm-case-details-card [(candidateModel)]="supplierMrtService.candidate"
                          [(candidateProductModel)]="supplierMrtService.candidate.candidateProducts[0]"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="candidateError"
                          [candidateProductErrorModel]="candidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUpc()"
                          (buyerChange)="onBuyerChange($event)">
    </pm-case-details-card>

    <pm-cost-card [model]="supplierMrtService.candidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="candidateError"
                  [subTitle]="costCardSubTitle"
                  [masterListCostDescription]="costCardMasterListCostDesc">
    </pm-cost-card>

    <pm-attachments-card [(model)]="supplierMrtService.candidate"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="candidateError">
    </pm-attachments-card>

  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
