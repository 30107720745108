import {ContainedUpc, SupplierItem} from 'pm-models';

export class Item {
  itemCode?: number;
  caseUpc?: number;
  orderingUpc?: number;
  description?: string;
  mrt?: boolean;
  innerUpcs?: ContainedUpc[];
  supplierItems?: SupplierItem[];
  containedUpc?: ContainedUpc;
  hierarchy?: any;
  totalVolume?: number;
  warehouseReactionDays?: number;
  guaranteeToStoreDays?: number;
  commodity?: any;
  isViewing?: any;
  innerPackQuantity?: any;
}
