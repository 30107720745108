import {Injectable} from '@angular/core';
import {AttributeTypes} from './attribute-types.enum';
import * as attrTypes from './attribute-type';
import {BaseAttribute} from './attribute-type/base-attribute';

@Injectable({
  providedIn: 'root'
})
export class AttributeMapperService {
  constructor() {}

  getConfiguration(type: AttributeTypes): BaseAttribute {
    const configs = [
      { id: AttributeTypes.BoxesOnPallet, config: attrTypes.BoxesOnPallet },
      { id: AttributeTypes.Brand, config: attrTypes.Brand },
      { id: AttributeTypes.Buyer, config: attrTypes.Buyer },
      { id: AttributeTypes.CaseUPC, config: attrTypes.CaseUPC },
      { id: AttributeTypes.CaseDescription, config: attrTypes.CaseDescription },
      { id: AttributeTypes.ProductDescription, config: attrTypes.ProductDescription },
      { id: AttributeTypes.Channel, config: attrTypes.Channel },
      { id: AttributeTypes.CodeDate, config: attrTypes.CodeDate },
      { id: AttributeTypes.ContactEmail, config: attrTypes.ContactEmail },
      { id: AttributeTypes.ContactName, config: attrTypes.ContactName },
      { id: AttributeTypes.Commodity, config: attrTypes.Commodity },
      { id: AttributeTypes.CostLinked, config: attrTypes.CostLinked },
      { id: AttributeTypes.CostLinkedItem, config: attrTypes.CostLinkedItem },
      { id: AttributeTypes.CostOwner, config: attrTypes.CostOwner },
      { id: AttributeTypes.CountryOfOrigin, config: attrTypes.CountryOfOrigin },
      { id: AttributeTypes.Dimensions, config: attrTypes.Dimensions },
      { id: AttributeTypes.DisplayReadyUnit, config: attrTypes.DisplayReadyUnit },
      { id: AttributeTypes.DisplayReadyUnitType, config: attrTypes.DisplayReadyUnitType },
      { id: AttributeTypes.ECommCopy, config: attrTypes.ECommCopy },
      { id: AttributeTypes.EstimatedStoreCount, config: attrTypes.EstimatedStoreCount },
      { id: AttributeTypes.FSAEligible, config: attrTypes.FSAEligible },
      { id: AttributeTypes.FoodStampEligible, config: attrTypes.FoodStampEligible },
      { id: AttributeTypes.InboundSpecDays, config: attrTypes.InboundSpecDays },
      { id: AttributeTypes.InnerPackSelected, config: attrTypes.InnerPackSelected },
      { id: AttributeTypes.InnerPackDimensions, config: attrTypes.InnerPackDimensions },
      { id: AttributeTypes.InnerPackQuantity, config: attrTypes.InnerPackQuantity },
      { id: AttributeTypes.InnerPackWeight, config: attrTypes.InnerPackWeight },
      { id: AttributeTypes.ItemWeightType, config: attrTypes.ItemWeightType },
      { id: AttributeTypes.GuaranteeDays, config: attrTypes.GuaranteeToStoreDays },
      { id: AttributeTypes.LikeItemCode, config: attrTypes.LikeItemCode },
      { id: AttributeTypes.MapRetail, config: attrTypes.MapRetail },
      { id: AttributeTypes.MasterListCost, config: attrTypes.MasterListCost },
      { id: AttributeTypes.MaxShelfLife, config: attrTypes.MaxShelfLife },
      { id: AttributeTypes.MaxShip, config: attrTypes.MaxShip },
      { id: AttributeTypes.MerchandiseType, config: attrTypes.MerchandiseType },
      { id: AttributeTypes.OrderFactor, config: attrTypes.OrderFactor },
      { id: AttributeTypes.Orientation, config: attrTypes.Orientation },
      { id: AttributeTypes.PackageTypes, config: attrTypes.PackageTypes },
      { id: AttributeTypes.PrePrice, config: attrTypes.PrePrice },
      { id: AttributeTypes.ProductDimensions, config: attrTypes.ProductDimensions },
      { id: AttributeTypes.ProductImages, config: attrTypes.ProductImages },
      { id: AttributeTypes.ProductUPC, config: attrTypes.ProductUPC },
      { id: AttributeTypes.ProductWeight, config: attrTypes.ProductWeight },
      { id: AttributeTypes.Receipt, config: attrTypes.Receipt },
      { id: AttributeTypes.ReactionDays, config: attrTypes.ReactionDays },
      { id: AttributeTypes.RetailPricing, config: attrTypes.RetailPricing },
      { id: AttributeTypes.RetailLink, config: attrTypes.RetailLink },
      { id: AttributeTypes.RetailSize, config: attrTypes.RetailSize },
      { id: AttributeTypes.RetailType, config: attrTypes.RetailType },
      { id: AttributeTypes.RetailUnitCount, config: attrTypes.RetailUnitCount },
      { id: AttributeTypes.Season, config: attrTypes.Season },
      { id: AttributeTypes.SeasonYear, config: attrTypes.SeasonYear },
      { id: AttributeTypes.Sellable, config: attrTypes.Sellable },
      { id: AttributeTypes.ShelfTag1, config: attrTypes.ShelfTag1 },
      { id: AttributeTypes.ShelfTag2, config: attrTypes.ShelfTag2 },
      { id: AttributeTypes.SoldByWeight, config: attrTypes.SoldByWeight },
      { id: AttributeTypes.SubBrand, config: attrTypes.SubBrand },
      { id: AttributeTypes.SubCommodity, config: attrTypes.SubCommodity },
      { id: AttributeTypes.SuggestedRetail, config: attrTypes.SuggestedRetail },
      { id: AttributeTypes.Taxable, config: attrTypes.Taxable },
      { id: AttributeTypes.TopToTop, config: attrTypes.TopToTop },
      { id: AttributeTypes.TotalRetail, config: attrTypes.TotalRetailUnits },
      { id: AttributeTypes.TotalVolume, config: attrTypes.TotalVolume },
      { id: AttributeTypes.UnitOfMeasure, config: attrTypes.UnitOfMeasure },
      { id: AttributeTypes.Vendor, config: attrTypes.Vendor },
      { id: AttributeTypes.VendorProductCode, config: attrTypes.VendorProductCode },
      { id: AttributeTypes.Warehouse, config: attrTypes.Warehouse },
      { id: AttributeTypes.WarehouseMaxShip, config: attrTypes.WarehouseMaxShip },
      { id: AttributeTypes.WarehouseOrderRestriction, config: attrTypes.WarehouseOrderRestriction },
      { id: AttributeTypes.WarehouseOrderUnit, config: attrTypes.WarehouseOrderUnit },
      { id: AttributeTypes.Weight, config: attrTypes.Weight },
      { id: AttributeTypes.QualifyingCondition, config: attrTypes.QualifyingCondition },
      { id: AttributeTypes.PssDepartment, config: attrTypes.PssDepartment },
      { id: AttributeTypes.VertexTaxCategory, config: attrTypes.VertexTaxCategory },
      { id: AttributeTypes.SubDepartment, config: attrTypes.SubDepartment },
      { id: AttributeTypes.DepositUpc, config: attrTypes.DepositUpc },
      { id: AttributeTypes.IngredientStatement, config: attrTypes.IngredientStatement },
      { id: AttributeTypes.NutritionStatementNumber, config: attrTypes.NutritionStatementNumber },
      { id: AttributeTypes.PrePackTare, config: attrTypes.PrePackTare },
      { id: AttributeTypes.ShelfLifeDays, config: attrTypes.ShelfLifeDays },
      { id: AttributeTypes.EnglishLabelOne, config: attrTypes.EnglishLabelOne },
      { id: AttributeTypes.EnglishLabelTwo, config: attrTypes.EnglishLabelTwo },
      { id: AttributeTypes.EnglishLabelThree, config: attrTypes.EnglishLabelThree },
      { id: AttributeTypes.EnglishLabelFour, config: attrTypes.EnglishLabelFour },
      { id: AttributeTypes.BilingualLabels, config: attrTypes.BilingualLabels },
      { id: AttributeTypes.EnglishLabelOneBilingual, config: attrTypes.EnglishLabelOneBilingual },
      { id: AttributeTypes.EnglishLabelTwoBilingual, config: attrTypes.EnglishLabelTwoBilingual },
      { id: AttributeTypes.SpanishLabelOne, config: attrTypes.SpanishLabelOne },
      { id: AttributeTypes.SpanishLabelTwo, config: attrTypes.SpanishLabelTwo },
      { id: AttributeTypes.LabelFormatOne, config: attrTypes.LabelFormatOne },
      { id: AttributeTypes.LabelFormatTwo, config: attrTypes.LabelFormatTwo },
      { id: AttributeTypes.ActionCode, config: attrTypes.ActionCode },
      { id: AttributeTypes.ServiceCounterTare, config: attrTypes.ServiceCounterTare },
      { id: AttributeTypes.MechanicallyTenderized, config: attrTypes.MechanicallyTenderized },
      { id: AttributeTypes.GraphicsCodeOne, config: attrTypes.GraphicsCode },
      { id: AttributeTypes.GraphicsCodeTwo, config: attrTypes.GraphicsCodeTwo },
      { id: AttributeTypes.GraphicsCodeThree, config: attrTypes.GraphicsCodeThree },
      { id: AttributeTypes.ForceTare, config: attrTypes.ForceTare },
      { id: AttributeTypes.NetWeight, config: attrTypes.NetWeight },
      { id: AttributeTypes.GradeNumber, config: attrTypes.GradeNumber },
      { id: AttributeTypes.AssociateUpc, config: attrTypes.AssociateUpc },
      { id: AttributeTypes.LabelInsightsImages, config: attrTypes.ProductImages },
      { id: AttributeTypes.Remark1, config: attrTypes.RemarkOne },
      { id: AttributeTypes.Remark2, config: attrTypes.RemarkTwo },
      { id: AttributeTypes.Plu, config: attrTypes.Plu },
      { id: AttributeTypes.ShowCalories, config: attrTypes.ShowCalories },
      { id: AttributeTypes.MatAttributeDatePicker, config: attrTypes.MatAttributeDatePicker },
      { id: AttributeTypes.MatAttributeTextInput, config: attrTypes.MatAttributeTextInput },
      { id: AttributeTypes.MatAttributeToggle, config: attrTypes.MatAttributeToggle },
      { id: AttributeTypes.MatAttributeBooleanRadioInput, config: attrTypes.MatAttributeBooleanRadioInput },
      { id: AttributeTypes.MatAttributeTypeahead, config: attrTypes.MatAttributeTypeahead },
      { id: AttributeTypes.CategorySelection, config: attrTypes.CategorySelection }
    ];

    const attrConfig = configs.find(x => x.id === type).config;
    if (attrConfig) {
      return new attrConfig();
    }
    throw new Error('Attribute Not Configured');
  }
}
