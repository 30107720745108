import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PharmacyCardComponent } from './pharmacy-card.component';

import { CardModule } from '../../shared/card/card.module';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';

@NgModule({
  declarations: [PharmacyCardComponent],
  exports: [PharmacyCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule, AttributeDisplayModule
  ]
})
export class PharmacyCardModule { }
