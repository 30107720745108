<ng-container>
  <div class="row align-items-center new-attribute-confirm-overlay-wrapper">
    <div [ngClass]="classOverride" class="new-attribute-confirm-overlay">
      <div class="header-row">
        <h2>{{title}}</h2>
        <div class="col-md-11 info-box">
          <h6 class="ml-2 mt-3">
            This delays submission
          </h6>
          <p class="ml-2">
            You can continue working on this candidate, but you won’t be able to submit it until your request is approved.
            Once approved, open this candidate and select the new value you requested, and then submit it.
          </p>
        </div>
      </div>

      <div class="row button-row">
        <p-button class="ghost m-2" label="Cancel" (click)="cancel()"></p-button>
        <p-button class="m-2" label="Continue" (click)="continueButton()"></p-button>
      </div>
    </div>
  </div>
</ng-container>
