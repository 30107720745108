<pm-review #reviewDrawer *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header *ngIf="!isClosedOrUnderReview">
    <pm-associate-upc-stepper *ngxPermissionsExcept="['ROLE_CATEGORY_SELECTION-EDIT']" [candidateErrors]="associateUpcService.getAllCandidateErrors()" [stepIndex]="4"
                              (indexClick)="onClickStepper($event)" [isProcurementOnly]="false">
    </pm-associate-upc-stepper>
    <pm-associate-upc-stepper *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [candidateErrors]="associateUpcService.getAllCandidateErrors()" [stepIndex]="5"
                              (indexClick)="onClickStepper($event)" [isProcurementOnly]="false">
    </pm-associate-upc-stepper>
  </pm-header>
  <div class="reviewCard">
    <div class="sectionHeader">{{getPageTitle()}}</div>
    <ng-container>
      <pm-sub-title>{{getTitleSubHeading()}}</pm-sub-title>
      <pm-app-reject-status-header class="isRejectedAssociate" [candidate]="associateUpcService.candidate"></pm-app-reject-status-header>
      <div class="col">
        <h4 class="row primaryUpcTitle">Primary Upc Summary</h4>
        <div class="row">
          <div class="primary-attribute">
            <img class="product-image" alt="blank" src="{{productImageUrl? productImageUrl : DEFAULT_NO_PRODUCT_IMAGE}}">
          </div>
          <div class="col pl-0">
            <div class="row-md-4 primary-attribute">
              <div class="col">
                <div class="upc-header">UPC: {{associateUpcService.candidate?.candidateProducts[0]?.upc}}-{{associateUpcService.candidate?.candidateProducts[0]?.upc | upcCheckDigit}}</div>
                <div class="attribute-description attribute-header">{{associateUpcService.candidate?.description}}</div>
                <button pButton type="button"
                        [label]="'View candidate history'"
                        class="ui-button-link" (click)="historyPanelOpen()">

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container>
      <div class="primaryUpcTitle">New Associate Details</div>
      <div class="section-header header">Associate Upc Details</div>
      <pm-attribute-grid>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(associateUpcService.getCandidate().candidateProducts[1], 'upc')"
                          (historyClicked)="historyPanelOpen()">
              <tr *ngFor="let candidateProduct of associateUpcService.candidate?.candidateProducts.slice(1)">{{candidateProduct?.upc}}-{{candidateProduct?.upcCheckDigit}}</tr>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-Brand'">
              <div class="pm-grid-cell-text">{{associateUpcService.candidate?.candidateProducts[0]?.subBrand?.description}}</div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="associateUpcService.candidate?.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of associateUpcService.candidate?.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(associateUpcService.candidate, 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(associateUpcService.candidate, 'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(associateUpcService.candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getMasterDimensions(associateUpcService.candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(associateUpcService.candidate, 'retailSize')"
                          (historyClicked)="historyPanelOpen()">
              {{associateUpcService.candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit of measure'"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(associateUpcService.candidate, 'unitOfMeasure', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{associateUpcService.candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'">
              {{associateUpcService.candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

        <pm-attribute-grid [title]="'Comments'">
          <pm-grid-group>
            <pm-grid-column>
              <pm-comment-grid-cell [label]="'Supplier Comments'" [canEdit]="false">
                <div *ngIf="!!associateUpcService.candidate.supplierComment">
                  <b>{{associateUpcService.candidate.contactName}}</b> <br>
                  {{associateUpcService.candidate.supplierComment}}
                </div>
                <br>
              </pm-comment-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
        </pm-attribute-grid>
      </pm-attribute-grid>

      <pm-attribute-grid [title]="'Selected Case'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(associateUpcService.getCandidate().candidateProducts[1], 'caseUpc')">
              {{associateUpcService.candidate.candidateProducts[1]?.caseUpc}}-{{associateUpcService.candidate.candidateProducts[1]?.caseUpc | upcCheckDigit}}
              <pm-grid-cell-info>
                <div style="margin-bottom: 10px;">
                  <p class="attribute-description-label">
                    {{selectedProductData?.description}}
                  </p>
                  <p class="attribute-description">
                    Item Code: <mark class="mark-css">{{selectedProductData?.itemCode}}</mark>
                    Channel: <mark class="mark-css">WHS</mark>
                    Master Pack: <mark class="mark-css">{{selectedProductData?.supplierItems[0]?.pack}}</mark>
                    Inner Pack: <mark class="mark-css">{{selectedProductData?.innerPackQuantity}}</mark>
                    Status: <mark class="mark-css">Active</mark>
                  </p>
                  <p class="attribute-description">
                    # UPCs in case: <mark class="mark-css">{{selectedProductData?.containedUpc?.associatedUpcs?.length + 1}}</mark>
                    VPC: <mark class="mark-css">{{selectedProductData?.supplierItems[0]?.supplierItemId}}</mark>
                  </p>
                </div>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <div *ngIf="showMatAttributes">

        <div *ngIf="candidateUtilService.isInProgressCandidate(associateUpcService.candidate)">
          <pm-attribute-grid *ngIf="isLoadingMatData" [title]="'Extended attributes: UPC'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>
          <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(associateUpcService.candidate)?.length" [title]="'Category attributes'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>


          <div *ngIf="!isLoadingMatData && !!associateUpcService.upcAttributes?.length">
            <div *ngFor="let candidateProduct of associateUpcService.candidate.candidateProducts; let i = index" style="margin-bottom: 4rem;">
              <div *ngIf="i !== 0">
                <h2 style="text-align: left; font-weight: 600;">Associate UPC: {{candidateProduct.upc}}</h2>

                <!--  Global Attributes    -->
                <pm-attribute-grid [title]="'Extended attributes: UPC'">
                  <pm-grid-group>
                    <pm-grid-column>
                      <div *ngFor="let attribute of associateUpcService.upcAttributes; let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of associateUpcService.upcAttributes; let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of associateUpcService.upcAttributes; let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct,attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct,attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>

                  </pm-grid-group>
                </pm-attribute-grid>
                <!--   Mat Hierarchy attributes   -->
                <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
                  <pm-attribute-grid *ngIf="associateUpcService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                    <pm-grid-group>
                      <pm-grid-column>
                        <div *ngFor="let attribute of associateUpcService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>
                      <pm-grid-column>
                        <div *ngFor="let attribute of associateUpcService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>
                      <pm-grid-column>
                        <div *ngFor="let attribute of associateUpcService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>

                    </pm-grid-group>
                  </pm-attribute-grid>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div *ngIf="!candidateUtilService.isInProgressCandidate(associateUpcService.candidate)">

          <pm-attribute-grid *ngIf="isLoadingMatData" [title]="'Extended attributes: UPC'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>
          <pm-attribute-grid *ngIf="isLoadingMatData" [title]="'Category attributes'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>

          <div *ngFor="let candidateProduct of associateUpcService.candidate.candidateProducts; let i = index" style="margin-bottom: 4rem;">
            <div *ngIf="i !== 0">
              <h2 *ngIf="!!candidateProduct.globalAttributes?.length || matUtilService.hasMatHierarchyAttributeValues(candidateProduct)" style="text-align: left; font-weight: 600;">Associate UPC: {{candidateProduct.upc}}</h2>
              <!--  Global Attributes    -->
              <pm-attribute-grid *ngIf="!!candidateProduct.globalAttributes?.length" [title]="'Extended attributes: UPC'">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of candidateProduct.globalAttributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of candidateProduct.globalAttributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of candidateProduct.globalAttributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct,attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isCandidateProductGlobalAttributeValueAnArray(candidateProduct,attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedCandidateProductGlobalAttributeValue(candidateProduct,attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>

              <!--   Mat Hierarchy attributes   -->
              <div *ngIf="!!candidateProduct?.matHierarchyList?.length">
                <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
                  <pm-attribute-grid *ngIf="!!hierarchy.attributes?.length" [title]="'Category attributes: ' + hierarchy.name">
                    <pm-grid-group>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>
                      <pm-grid-column>
                        <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                          <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                            <div *ngIf="matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              <div *ngFor="let value of matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                                {{value.description}} <br>
                              </div>
                            </div>
                            <div *ngIf="!matUtilService.isCandidateProductHierarchyAttributeValueAnArray(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{matUtilService.getSelectedCandidateProductHierarchyAttributeValue(candidateProduct, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                            </div>

                          </pm-grid-cell>
                        </div>
                      </pm-grid-column>

                    </pm-grid-group>
                  </pm-attribute-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </ng-container>
  </div>

  <!--  Audit History -->
  <pm-review-drawer-container >
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="historyService.sortedAudits">
            <div *ngIf="historyService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{historyService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>
  <pm-footer>
    <p-button *ngIf="isClosedOrUnderReview" id="backToHome" class="ghost m-2" label="Back to home" (onClick)="onClickBackToHome()" [disabled]="!canEditAndResubmit"></p-button>
    <p-button class="ghost m-2" label="Back" *ngIf="!isRejected() && !isClosedOrUnderReview" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="ghost m-2" label="Print" (onClick)="print()"></p-button>
    <p-button class="ghost m-2" label="{{getSumbitButtonTitle()}}" *ngIf="!isRejected() && !isClosedOrUnderReview" (onClick)="completeTaskAndRouteToHome()" [disabled]="!canNavigate"></p-button>
    <p-button class="ghost m-2" label="Edit & Resubmit" *ngIf="isRejected()" (onClick)="resubmitCandidate()" [disabled]="!canEditAndResubmit"></p-button>
  </pm-footer>
</pm-review>
