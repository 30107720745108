<pm-editor (closeEvent)="piaProductService.saveCandidateAndClose()">

  <pm-header>
    <app-pia-new-product-flow-stepper *ngIf="piaProductService.candidate && !piaProductService.isDsdOnly()"
                                      [candidateErrors]="piaProductService.getAllWhsCandidateErrors()"
                                      [stepIndex]="6"
                                      [isSellable]="candidateUtilService.isSellable(piaProductService.candidate)"
                                      (indexClick)="onClickStepper($event)">
    </app-pia-new-product-flow-stepper>
    <app-pia-new-dsd-product-flow-stepper *ngIf="piaProductService.candidate && piaProductService.isDsdOnly()"
                                          [candidateErrors]="piaProductService.getAllDsdCandidateErrors()"
                                          [stepIndex]="6"
                                          [isSellable]="candidateUtilService.isSellable(piaProductService.candidate)"
                                          (indexClick)="onClickStepper($event)">
    </app-pia-new-dsd-product-flow-stepper>
  </pm-header>

  <pm-title>Extended attributes
  </pm-title>
  <div *ngIf="isLoadingData" class="col-md-8 spinner-row">
    <pm-progress-spinner [showSpinner]="isLoadingData" [strokeWidth]="'2'"
                         [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}">
    </pm-progress-spinner>
    <h5 class="ml-3">Fetching attributes...</h5>
  </div>
  <ng-container *ngIf="!isLoadingData">

    <pm-global-attributes-card [candidateModel]="piaProductService.candidate"
                               [candidateProductModel]="piaProductService.candidate.candidateProducts[0]"
                               [globalAttributes]="piaProductService.globalAttributes"
                               [candidateProductErrorModel]="candidateProductError">
    </pm-global-attributes-card>

    <pm-mat-categories-card [candidateModel]="piaProductService.candidate"
                            [candidateProductModel]="piaProductService.candidate.candidateProducts[0]"
                            [hierarchyAttributes]="piaProductService.hierarchyAttributes"
                            [candidateProductErrorModel]="candidateProductError">
    </pm-mat-categories-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="{{submitButtonLabel}}" (onClick)="onClickActivateOrSubmit()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
