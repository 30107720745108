<div class="delete-button-wrapper">
  <div #targetDiv></div>
  <pm-static-overlay-panel #op appendTo="body" [style]="style">
    <div class="delete-button p-2">
      <div>
        Are you sure you want to <em>delete</em> this {{description}}?
      </div>
      <button class="ui-button-secondary ui-button-small mx-2" pButton type="button" label="Cancel"
        (click)="op.hide()"></button>
      <button class="ui-button-danger ui-button-small" pButton type="button" label="Delete"
        (click)="deleteCandidate(op)"></button>
    </div>
  </pm-static-overlay-panel>

  <i *ngIf="showConfirmationModal" class="pi pi-trash" [ngClass]="{'pi-trash-disabled':disabled}" (click)="showDeletePanel($event, op, targetDiv)" [pTooltip]="tooltip"></i>
  <i *ngIf="!showConfirmationModal" class="pi pi-trash" [ngClass]="{'pi-trash-disabled':disabled}" (click)="deleteCandidate()" [pTooltip]="tooltip"></i>
</div>
