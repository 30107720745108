import {Component, OnInit} from '@angular/core';
import {CandidateService} from '../service/candidate.service';
import {WorkflowService} from '../service/workflow.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {GrowlService} from '../growl/growl.service';
import {InvitedDistributorService} from '../service/invited-distributor.service';

@Component({
  selector: 'app-setup-candidate',
  templateUrl: './setup-candidate.component.html',
  styleUrls: ['./setup-candidate.component.scss']
})
export class SetupCandidateComponent implements OnInit {

  constructor(private candidateService: CandidateService, private workflowService: WorkflowService,
              private router: Router, private growlService: GrowlService, private authService: AuthService,
              private invitedDistributorService: InvitedDistributorService) { }

  public isViewingPage = true;
  public canClick = true;

  ngOnInit() {
  }

  onClickNewProduct() {
    if (this.canClick) {
      this.canClick = false;
      if (this.authService.isVendor()) {
        this.router.navigate(['/setupCandidateType']);
      } else if (this.authService.isPia()) {
        this.router.navigate(['/piaSetupNewProduct']);
      } else {
        this.growlService.addError('Invalid User Permissions');
        this.canClick = true;
      }
    }
  }

  onClickNewFreightTaxUpc() {
    if (this.canClick) {
      this.canClick = false;
      if (this.authService.isPia()) {
        this.router.navigate(['/piaSetupNewFreightTaxUpc']);
      } else {
        this.growlService.addError('Invalid User Permissions');
        this.canClick = true;
      }
    }
  }

  onClickCasePack() {
    if (this.canClick) {
      this.canClick = false;
      if (this.authService.isVendor()) {
        this.router.navigate(['/setupAdditionalCasePack']);
      } else if (this.authService.isPia()) {
        this.router.navigate(['/piaSetupCasePackComponent']);
      } else {
        this.growlService.addError('Invalid User Permissions');
        this.canClick = true;
      }
    }
  }

  onClickAssociateUpc() {
    if (this.canClick) {
      this.canClick = false;
      if (this.authService.isVendor()) {
        this.router.navigate(['/setupAssociateUpc']);
      } else if (this.authService.isPia()) {
        this.router.navigate(['/piaAssociateUpcSetup']);
      } else {
        this.growlService.addError('Invalid User Permissions');
        this.canClick = true;
      }
    }
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']);
  }

  onClickBonusSize() {
    if (this.canClick) {
      this.canClick = false;
      if (this.authService.isVendor()) {
        this.router.navigate(['/setupBonusSize']);
      } else if (this.authService.isPia()) {
        this.router.navigate(['/piaSetupBonusSizeComponent']);
      } else {
        this.growlService.addError('Invalid User Permissions');
        this.canClick = true;
      }
    }
  }

  onClickMrt() {
    if (this.canClick) {
      this.canClick = false;
      if (this.authService.isVendor()) {
        this.router.navigate(['/setupMrt']);
      } else if (this.authService.isPia()) {
        this.router.navigate(['/piaSetupMrt']);
      }
    }
  }

  onClickVolumeUpload() {
    if (this.canClick) {
      this.canClick = false;
      if (this.authService.isPia()) {
        this.router.navigate(['/uploadProducts']);
      } else {
        this.growlService.addError('Invalid User Permissions');
        this.canClick = true;
      }
    }
  }

  onClickAddDistributor() {
    if (this.canClick) {
      this.invitedDistributorService.resetService();
      this.canClick = false;
      if (this.authService.isVendor()) {
        this.router.navigate(['/supplierAddDistributor']);
      } else if (this.authService.isPia()) {
        this.router.navigate(['/piaAddDistributor']);
      } else {
        this.growlService.addError('Invalid User Permissions');
        this.canClick = true;
      }
    }
  }

  onClickReplacementUpc() {
    if (this.canClick) {
      this.canClick = false;
      if (this.authService.isVendor()) {
        this.router.navigate(['/setupReplacementUpc']);
      } else if (this.authService.isPia()) {
        this.router.navigate(['/piaSetupReplacementUpc']);
      } else {
        this.growlService.addError('Invalid User Permissions');
        this.canClick = true;
      }
    }
  }
}
