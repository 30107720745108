import {Component, OnInit} from '@angular/core';
import {UUID} from 'angular2-uuid';
import {UPCInputState, UpcService} from 'pm-components';
import {
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  DistributionChannelPermissions
} from 'pm-models';
import {CandidateService} from '../../service/candidate.service';
import {OwnBrandService} from '../../service/ownbrand.service';
import {WorkflowService} from '../../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {PiaProductService} from '../../service/pia-product.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {SellablePermissions} from 'pm-models/lib/card-models/sellable-model';
import {NewUpcRequest, UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {Observable} from 'rxjs';
import {calculateCheckDigit} from '../../shared/upc.utils';
import {MatUtilService} from '../../service/mat-util.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {PiaNewProductFlowStepperComponent} from '../pia-new-product-flow-stepper/pia-new-product-flow-stepper.component';
import {PiaNewDsdProductFlowStepperComponent} from '../pia-new-dsd-product-flow-stepper/pia-new-dsd-product-flow-stepper.component';

@Component({
  selector: 'app-pia-setup-new-product',
  templateUrl: './pia-setup-new-product.component.html',
  styleUrls: ['./pia-setup-new-product.component.scss']
})
export class PiaSetupNewProductComponent implements OnInit {

  public upcType;
  public upcState: UPCInputState;
  public upcOptions: {label: string, value: string} [] = [{label: 'UPC', value: 'UPC'},
    {label: 'PLU', value: 'PLU'}];

  public pluTypes = [];
  public pluRanges = [];
  public isViewingPage = true;
  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public fullPluModel: number;
  public isNextDisabled = false;

  public showMatAttributes = false;
  public isLoadingMatData = false;

  readonly sellablePermissions: SellablePermissions = {
    isReadOnly: false,
    isDisabled: false
  };

  readonly channelPermissions: DistributionChannelPermissions = {
    isReadOnly: false
  };

  constructor(public candidateService: CandidateService, public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public piaProductService: PiaProductService, public growlService: GrowlService,
              public candidateUtilService: CandidateUtilService, public ownBrandService: OwnBrandService, public upcService: UpcService,
              public matUtilService: MatUtilService, private permissionService: NgxPermissionsService) {
  }

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.piaProductService.getTaskId(),
        this.piaProductService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.piaProductService.resetService();
        this.piaProductService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          this.initializePage();
        });
      } else if (this.piaProductService.getOriginalCandidate() && this.piaProductService.getCandidate() && !taskId
        && !this.piaProductService.getCandidate().candidateId) {
        this.initializePage();
      } else {
        // if there's a task id and current candidates, get the task by candidate id. If the tasks IDs don't match, reset everything.
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.piaProductService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializePage();
          } else {
            this.resetPage();
          }
        });
      }
    });
  }

  initializePage() {
    this.candidateError = this.piaProductService.getSetupCandidateError();
    this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
    this.upcTypeInit();
    this.setupMatAttributes();
  }

  resetPage() {
    window.history.replaceState({}, 'Title', '#/piaSetupNewProduct');
    this.piaProductService.resetService();
    this.piaProductService.setupNewCandidate();
    this.initializePage();
  }

  onClose() {
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.piaProductService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR]).toPromise().then(() => {
      this.candidateError = this.piaProductService.setSetupCandidateError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.piaProductService.getTaskId()) {
        this.saveCandidateAndNavigate('/tasks');
      } else {
        if (this.piaProductService.getCandidate().description) {
          this.piaProductService.createCandidateAndNavigate(this.piaProductService.getCandidate(), '/tasks');
        }
      }

    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.candidateError.candidateProductErrors[this.piaProductService.getCurrentCandidateProduct().id];
        this.piaProductService.setSetupCandidateError(this.candidateError);
      }
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.piaProductService.getTaskId() && !this.candidateError.description) {
        this.saveCandidateAndNavigate('/tasks');
      } else if (this.isPLU() && this.piaProductService.getCandidate().candidateProducts[0].upc) {
        this.releasePlu().subscribe(() => {
          this.router.navigate(['/tasks']);
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  hasUpcError(): boolean {
    return this.candidateProductError !== undefined &&  this.candidateProductError !== null &&
      this.candidateProductError.upc !== undefined && this.candidateProductError.upc !== null;
  }


  validateUPC() {
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.piaProductService.getCurrentCandidateProduct().id;

    this.candidateService.validateCandidate(this.piaProductService.getCandidate(),
      [CandidateValidatorType.UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.upc = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/check-digit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.upc = returnedCandidateProductError.upc;
        this.candidateProductError.upcCheckDigit = returnedCandidateProductError.upcCheckDigit;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  onClickBack() {
    // if a task exists, the candidate can be saved.
    if (this.piaProductService.getTask() || this.piaProductService.getTaskId()) {
      this.saveCandidateAndNavigate('/setupCandidate');
    } else {
      // store candidate info, just in case the user returns to the screen.
      this.piaProductService.setOriginalAndCurrentCandidate(this.piaProductService.getCandidate());
      this.router.navigate(['/setupCandidate']).then();
    }
  }

  /**
   * Saves candidate.
   */
  saveCandidate() {
    if (this.candidateUtilService.isEmptyOrSpaces(this.piaProductService.getCandidate().description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.piaProductService.getOriginalCandidate()) !== JSON.stringify(this.piaProductService.getCandidate())) {
        this.candidateService.saveCandidate(this.piaProductService.getCandidate()).subscribe(savedCandidate => {
          this.piaProductService.setOriginalAndCurrentCandidate(savedCandidate);
        });
      }
    }
  }

  onClickNext() {
    this.isNextDisabled = true;

    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.piaProductService.getCandidate(), PiaProductService.BASE_VALIDATORS).subscribe(() => {
      this.candidateError = this.piaProductService.setSetupCandidateError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.piaProductService.getTaskId()) {
        this.saveCandidateAndNavigate('piaNewProductSupplierDetails');
      } else {
        if (this.piaProductService.getCandidate().description) {
          this.piaProductService.createCandidateAndNavigate(this.piaProductService.getCandidate(), '/piaNewProductSupplierDetails');
        }
      }

    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.candidateError.candidateProductErrors[this.piaProductService.getCurrentCandidateProduct().id];
        this.piaProductService.setSetupCandidateError(this.candidateError);
      }
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.piaProductService.getTaskId() && !this.candidateError.description) {
        this.saveCandidateAndNavigate('/piaNewProductSupplierDetails');
      }
      this.isNextDisabled = false;
    });
  }

  generate04UPC() {
    this.piaProductService.getCurrentCandidateProduct().upc = null;
    this.piaProductService.getCurrentCandidateProduct().upcCheckDigit = null;

    this.ownBrandService.generate04NonSellableUPC().subscribe((upc) => {
      this.piaProductService.getCurrentCandidateProduct().upc = upc;
      this.piaProductService.getCurrentCandidateProduct().upcCheckDigit = calculateCheckDigit(upc);
      this.piaProductService.getCurrentCandidateProduct().generatedUpc = true;
      this.piaProductService.getCurrentCandidateProduct().id = UUID.UUID();

    }, (error) => {
      this.growlService.addError('Unable to reserve a pre-digit four UPC. Error:' + error.message);
    });
  }

  sellableTypeChange(event: Candidate) {
    this.piaProductService.getCandidate().merchandiseType = null;

    if (event.productType === 'SELLABLE') {
      if (this.piaProductService.getOriginalCandidate().candidateProducts) {
        this.piaProductService.getCurrentCandidateProduct().description =
          this.piaProductService.getOriginalCandidate().candidateProducts[0].description;
      }
      this.piaProductService.candidate.retailXFor = null;
      this.piaProductService.candidate.retailPrice = null;
      this.piaProductService.candidate.retailSize = null;
      this.ownBrandService.releaseUpc(this.piaProductService.getCurrentCandidateProduct().upc,
        UpcRequestType.CHECKER_AND_SCALE).subscribe();

    } else {
      this.piaProductService.getCandidate().retailXFor = 1;
      this.piaProductService.getCandidate().retailPrice = '0.0';
      this.piaProductService.getCandidate().retailSize = '1';
      this.piaProductService.getCandidate().suggestedRetailPrice = null;
      this.piaProductService.getCandidate().suggestedXFor = null;
      this.piaProductService.getCandidate().retailSize = '1';
    }
  }

  isPLU() {
    return this.upcType === 'PLU';
  }

  isUPC() {
    return this.upcType === 'UPC';
  }

  onUpcTypeChange(event) {
    // this is done to avoid onInit call, which would reset the data.
    if (event === 'PLU' && this.piaProductService.getCurrentCandidateProduct().candidateProductType === CandidateProduct.PLU) {
      return;
    } else if (event === 'UPC' && !this.piaProductService.getCurrentCandidateProduct().candidateProductType) {
      return;
    }
    if (this.isUPC()) {
      this.piaProductService.getCandidate().candidateType = Candidate.NEW_PRODUCT;
      this.piaProductService.getCurrentCandidateProduct().upc = null;
      this.piaProductService.getCurrentCandidateProduct().upcCheckDigit = null;
      this.piaProductService.getCurrentCandidateProduct().candidateProductType = null;
      this.piaProductService.getCurrentCandidateProduct().pluRange = null;
      this.piaProductService.getCurrentCandidateProduct().pluType = null;
      this.piaProductService.getCurrentCandidateProduct().scaleInformation = null;
      this.releasePlu().subscribe();
    } else if (this.isPLU()) {
      this.piaProductService.getCandidate().candidateType = Candidate.PLU;
      this.piaProductService.getCurrentCandidateProduct().upc = null;
      this.piaProductService.getCurrentCandidateProduct().upcCheckDigit = null;
      this.piaProductService.getCurrentCandidateProduct().candidateProductType = CandidateProduct.PLU;
      this.candidateProductError.upc = null;
    }
  }

  onPluRangeModelChange(event) {
    this.piaProductService.getCurrentCandidateProduct().pluRange = event;
    this.onPluValueChange();
  }

  onPluTypeModelChange(event) {
    this.piaProductService.getCurrentCandidateProduct().pluType = event;

    if (+event === +UpcRequestType.SCALE || +event === +UpcRequestType.CHECKER_AND_SCALE) {
      if (! this.piaProductService.getCurrentCandidateProduct().scaleInformation) {
        this.piaProductService.getCurrentCandidateProduct().scaleInformation = { id: UUID.UUID() };
      }
    } else {
      this.piaProductService.getCurrentCandidateProduct().scaleInformation = null;
    }
    this.onPluValueChange();

  }

  onPluValueChange() {
    if (this.piaProductService.getCurrentCandidateProduct().pluRange &&
      this.piaProductService.getCurrentCandidateProduct().pluType) {
      if (this.piaProductService.getCurrentCandidateProduct().upc) {
        this.releasePlu().subscribe();
      }
      this.generatePlu();
    }
  }

  generatePlu() {
    const newUpcRequest = new NewUpcRequest();
    newUpcRequest.requestType = this.piaProductService.getCurrentCandidateProduct().pluType;
    newUpcRequest.candidate = this.piaProductService.getCandidate();
    newUpcRequest.rangeId = this.piaProductService.getCurrentCandidateProduct().pluRange.pluRangeId;
    this.ownBrandService.reserveUpcs(newUpcRequest).subscribe(plus => {
        this.piaProductService.getCurrentCandidateProduct().upc = plus[0];
        if (this.candidateProductError) {
          this.candidateProductError.upc = null;
        }
      }, (error) => {
        if (error.status === 400) {
          this.candidateError = error.error.candidateErrors;
          this.piaProductService.setSetupCandidateError(this.candidateError);

          if (error.error.validationErrors && error.error.validationErrors.length > 0) {
            this.candidateError.description = error.error.validationErrors[0];
          } else {
            this.candidateError.description = null;
          }

        } else if (error.status === 500) {
          if (this.candidateProductError) {
            this.candidateProductError.upc = 'No UPCs in this range available, please contact procurement support.';
            this.candidateError.description = null;
          }
        } else {
          this.growlService.addError(error.message);
        }
      }
    );

  }

  releasePlu(): Observable<any> {
    this.fullPluModel = null;
    return this.ownBrandService.releaseUpc(this.piaProductService.getCurrentCandidateProduct().upc, UpcRequestType.CHECKER_AND_SCALE);
  }

  upcTypeInit() {
    if (this.piaProductService.getCurrentCandidateProduct().candidateProductType === CandidateProduct.PLU) {
      this.upcOptions = [{label: 'UPC', value: 'UPC'}, {label: 'PLU', value: 'PLU'}];
      this.upcType = 'PLU';
    } else {
      this.upcType = 'UPC';
    }
  }

  hasUserChangedFlows() {

    if (!this.piaProductService.getOriginalCandidate()) {
      return false;
    }
    // if it's show calories, it'll always be OB reg flow.
    if (this.piaProductService.getCandidate().showCalories) {
      return false;
    }

    return this.piaProductService.isScaleProduct(this.piaProductService.getCandidate()) !==
      this.piaProductService.isScaleProduct(this.piaProductService.getOriginalCandidate());
  }

  upcChange() {
    // if it's a dsd item, copy upc values to case upc.
    if (this.piaProductService.getCandidate() && this.piaProductService.getCandidate().dsdSwitch &&
      !this.piaProductService.getCandidate().warehouseSwitch) {
      this.piaProductService.getCandidate().candidateProducts[0].caseUpc =
        this.piaProductService.getCandidate().candidateProducts[0].upc;
      this.piaProductService.getCandidate().candidateProducts[0].caseUpcCheckDigit =
        this.piaProductService.getCandidate().candidateProducts[0].upcCheckDigit;
    }
    if (this.piaProductService.getCandidate().candidateProducts[0].upc &&
      this.piaProductService.getCandidate().candidateProducts[0].upcCheckDigit) {
      this.validateUPC();
    } else {
      this.candidateProductError.upc = null;
      this.candidateProductError.upcCheckDigit = null;
      this.upcState = UPCInputState.none;
    }
  }


  channelChange() {
    this.piaProductService.setCandidate(this.candidateUtilService.handleChannelChange(this.piaProductService.getCandidate()));
    if (this.piaProductService.getCandidate().dsdSwitch) {
      MatUtilService.removeWarehouseItemAttributes(this.piaProductService.getCandidate().candidateProducts[0]);
    } else {
      MatUtilService.removeDsdItemAttributes(this.piaProductService.getCandidate().candidateProducts[0]);
    }
  }

  getFullPlu() {
    if (this.isPLU()) {
      return this.upcService.pluToPreDigitTwo(this.piaProductService.getCurrentCandidateProduct().upc);
    }
    return '';
  }

  onClickStepper(stepperItem) {
    if (this.isNextDisabled || this.isLoadingMatData) {
      return;
    }

    switch (stepperItem.text) {
      case PiaNewProductFlowStepperComponent.SUPPLIER_HEB_SETUP: {
        this.onClickNext();
        break;
      }
      case PiaNewProductFlowStepperComponent.PRODUCT_DETAILS: {
        this.jumpToPage('/piaNewProductDetails', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.CASE_PACK: {
        this.jumpToPage('/piaNewProductCaseDetails', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewDsdProductFlowStepperComponent.STORE_AUTHORIZATION: {
        if (!this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.jumpToPage('/piaStoreAuthorization', PiaProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/piaSetUpStores', PiaProductService.DSD_VALIDATORS);
        }
        break;
      }
      case PiaNewProductFlowStepperComponent.WAREHOUSE: {
        this.jumpToPage('/piaNewProductWarehouses', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.EXTENDED_ATTRIBUTES: {
        if (this.piaProductService.isDsdOnly()) {
          this.jumpToPage('/piaNewProductExtendedAttributes', PiaProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/piaNewProductExtendedAttributes', PiaProductService.BASE_VALIDATORS);
        }
        break;
      }
    }
  }

  jumpToPage(urlToNavigate, validator) {
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.piaProductService.getCandidate(), [validator]).subscribe(() => {
      this.candidateError = this.piaProductService.setSetupCandidateError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      if (this.piaProductService.getTaskId()) {
        this.saveCandidateAndNavigate(urlToNavigate);
      } else {
        this.piaProductService.createCandidateAndNavigate(this.piaProductService.getCandidate(), urlToNavigate);
      }
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.piaProductService.setSetupCandidateError(error.error.candidateErrors);
        this.candidateProductError =
          this.candidateError.candidateProductErrors[this.piaProductService.getCurrentCandidateProduct().id];
        if (!this.piaProductService.isDsdOnly() || !PiaProductService.DSD_NON_NAVIGABLE_ON_ERROR_PAGES.includes(urlToNavigate)) {
          if (this.piaProductService.getTaskId()) {
            this.saveCandidateAndNavigate(urlToNavigate);
          } else {
            if (this.piaProductService.getCandidate().description) {
              this.piaProductService.createCandidateAndNavigate(this.piaProductService.getCandidate(), urlToNavigate);
            }
          }
        }
      }
    });
  }

  private saveCandidateAndNavigate(url) {
    if (this.hasUserChangedFlows()) {
      if (this.piaProductService.isEmptyOrSpaces(this.piaProductService.getCandidate().description)) {
        this.growlService.addError('Failed to save because a candidate name is required.');
      } else {
        this.candidateService.saveCandidate(this.piaProductService.getCandidate()).subscribe(savedCandidate => {
          this.piaProductService.setOriginalAndCurrentCandidate(savedCandidate);
          this.piaProductService.deleteAndCreateNewProcessInstance(url).subscribe();
        });
      }
    } else {
      this.piaProductService.saveCandidateAndNavigate(url, false);
    }
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return;
    }
    this.isLoadingMatData = true;
    this.showMatAttributes = true;

    this.matUtilService.updateMatHierarchy(this.piaProductService.candidate.candidateProducts[0]).pipe(
      tap((hasHierarchyChanges) => {
        this.piaProductService.hasHierarchyUpdateChanges = hasHierarchyChanges;
      }),
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.piaProductService.candidate,
        this.piaProductService.globalAttributes, this.piaProductService.hierarchyAttributes)),
      finalize(() => this.isLoadingMatData = false)
    ).subscribe();
  }

  onHierarchySelection(event) {
    this.piaProductService.candidate.candidateProducts[0].matHierarchyList = event;
    this.piaProductService.hierarchyAttributes = [];
    this.piaProductService.hasHierarchyUpdateChanges = false;
    this.matUtilService.updateMatHierarchyErrors(
      this.piaProductService.getCurrentCandidateProductError(this.piaProductService.getExtendedAttributesError()),
      this.piaProductService.globalAttributes, this.piaProductService.hierarchyAttributes);
    this.piaProductService.setExtendedAttributesError(JSON.parse(JSON.stringify(this.piaProductService.getExtendedAttributesError())));
  }
}
