<div [ngClass]="'pm-card my-4'" [ngStyle]="style" [class]="styleClass">
  <div class="pm-card-header px-4 py-3" *ngIf="title || subtitle">
    <h2 class="pm-card-title" *ngIf="title">{{title}}</h2>
    <h4 class="pm-card-title" *ngIf="titleFacet"><ng-content select="pm-title"></ng-content></h4>
    <h4 class="pm-card-subtitle" *ngIf="subtitle">{{subtitle}}</h4>
    <h4 class="pm-card-subtitle" *ngIf="subTitleFacet"><ng-content select="pm-sub-title"></ng-content></h4>

  </div>
  <div class="pm-card-body px-4 py-3">
    <ng-content></ng-content>
  </div>
</div>
