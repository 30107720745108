import {NutritionPanelColumn} from 'pm-models/lib/nutritionPanelColumn';

export class NutritionPanel {
  panelId: number;
  nutrientStatementNumber: string;
  panelType: string;
  servingSize: string;
  servingsPerContainer: string;
  productIsOrContains: any[];
  columns: NutritionPanelColumn[];
  source: string;
  ingredients: string;
  allergens: string;
  warnings: string;
  cookingInstructions: string;
  bioengineeringStatement: string;
}
