import {Component, Input, OnInit} from '@angular/core';
import {
  Attribute,
  AttributeConfig,
  AttributeTypeaheadConfig,
  BaseCardPermissions,
  Candidate,
  CandidateProduct,
  CandidateProductError
} from 'pm-models';
import {MatUtilService} from '../../../../../../src/app/2.0.0/service/mat-util.service';
import {
  RequestNewMatAttributeFormModel
} from 'pm-components/lib/shared/request-new-mat-attribute-form/request-new-mat-attribute-form.component';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';
import {EmailService} from '../../../../../../src/app/2.0.0/service/email.service';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';

@Component({
  selector: 'pm-global-attributes-card',
  templateUrl: './global-attributes-card.component.html',
  styleUrls: ['./global-attributes-card.component.scss']
})
export class GlobalAttributesCardComponent implements OnInit {

  @Input()
  permissions: BaseCardPermissions;

  @Input()
  candidateModel: Candidate;

  @Input()
  candidateProductModel: CandidateProduct;

  @Input()
  globalAttributes: Attribute[];

  @Input()
  candidateProductErrorModel: CandidateProductError;

  productAttributeConfigs: AttributeConfig[] = [];
  warehouseItemAttributeConfigs: AttributeConfig[] = [];
  upcAttributeConfigs: AttributeConfig[] = [];

  isShowingRequestAttributePanel = false;
  requestNewMatAttributeFormModel: RequestNewMatAttributeFormModel;

  constructor(public matUtilService: MatUtilService, private emailService: EmailService, private growlService: GrowlService) { }

  ngOnInit(): void {
    this.addGlobalAttributesToApplicableTypeLists(this.globalAttributes, this.productAttributeConfigs, this.warehouseItemAttributeConfigs,
      this.upcAttributeConfigs);
  }


  /**
   * Returns the value for the mat hierarchy model.
   *
   * @param attributeConfig the attribute configuration.
   */
  getMatAttributeModel(attributeConfig: AttributeConfig) {
    const attribute: Attribute = this.candidateProductModel?.globalAttributes?.find(attr => attr.identifiers.fieldId === attributeConfig.matAttributeId);
    return attribute ? attribute.value : undefined;
  }

  /**
   * Sets the value for an attribute.
   * @param event
   * @param attributeConfig
   */
  onAttributeSelection(event, attributeConfig: AttributeConfig) {
    const fieldId = attributeConfig?.matAttributeId;
    let attribute: Attribute;
    // if there's no attributes, find it the list, add to the mat hierarchy.
    if (!this.candidateProductModel.globalAttributes?.length) {
      this.candidateProductModel.globalAttributes = [];

      // if there's no value selected, and no current attribute, just return.
      if (this.matUtilService.hasNoValidValueForAttributeConfig(event, attributeConfig)) {
        return;
      }
      attribute = this.globalAttributes.find((attr => attr.identifiers.fieldId === fieldId));
      // Associates can have many candidate products, we don't want the attributes to have the same reference to
      // prevent updates persisting across the different associate UPCs.
      if (Candidate.ASSOCIATE_UPC === this.candidateModel.candidateType) {
        attribute = JSON.parse(JSON.stringify(attribute));
      }
      this.candidateProductModel.globalAttributes.push(attribute);
    } else {
      attribute = this.candidateProductModel.globalAttributes.find((attr => attr.identifiers.fieldId === fieldId));
      // if the attribute isn't on the mat hierarchy, find it it the list, add to the mat hierarchy.
      if (!attribute) {
        // if there's no value selected, and no current attribute, just return.
        if (this.matUtilService.hasNoValidValueForAttributeConfig(event, attributeConfig)) {
          return;
        }
        attribute = this.globalAttributes.find((attr => attr.identifiers.fieldId === fieldId));
        // Associates can have many candidate products, we don't want the attributes to have the same reference to
        // prevent updates persisting across the different associate UPCs.
        if (Candidate.ASSOCIATE_UPC === this.candidateModel.candidateType) {
          attribute = JSON.parse(JSON.stringify(attribute));
        }
        this.candidateProductModel.globalAttributes.push(attribute);
      } else {
        if (this.matUtilService.hasNoValidValueForAttributeConfig(event, attributeConfig)) {
          // if there's no value selected, and remove the current attribute and return.
          for (let x = 0; x < this.candidateProductModel.globalAttributes.length; x++) {
            if (this.candidateProductModel.globalAttributes[x].identifiers.fieldId === fieldId) {
              this.candidateProductModel.globalAttributes.splice(x, 1);
              return;
            }
          }
        }
      }
    }
    attribute.value = event;
  }

  /**
   * Takes a list of global attributes and sorts into lists of configurations by their applicable type codes (currently product, warehouse item, and upc).
   * @param globalAttributes
   * @param productAttributeConfigs
   * @param warehouseItemAttributeConfigs
   * @param upcAttributesConfigs
   */
  public addGlobalAttributesToApplicableTypeLists(globalAttributes: Attribute[], productAttributeConfigs: AttributeConfig[],
                                                  warehouseItemAttributeConfigs: AttributeConfig[], upcAttributesConfigs: AttributeConfig[]) {
    if (!globalAttributes?.length) {
      return;
    }
    for (const attribute of globalAttributes) {
      if (!attribute?.identifiers?.applicableTypeCode?.id) {
        console.log('Missing applicable type code for attribute field id: ' + attribute?.identifiers?.fieldId + '.');
        continue;
      }
      const attributeConfig = MatUtilService.getAttributeConfigurationFromAttribute(attribute);
      if (!attributeConfig) {
        continue;
      }

      switch (attribute.identifiers.applicableTypeCode.id) {
        case MatUtilService.PRODUCT_APPLICABLE_TYPE_CODE: {
          productAttributeConfigs.push(attributeConfig);
          break;
        }
        case MatUtilService.WAREHOUSE_ITEM_APPLICABLE_TYPE_CODE: {
          warehouseItemAttributeConfigs.push(attributeConfig);
          break;
        }
        case MatUtilService.UPC_APPLICABLE_TYPE_CODE: {
          upcAttributesConfigs.push(attributeConfig);
          break;
        }
        default: {
          console.log('Unmapped applicable type code: ' + attribute.identifiers.applicableTypeCode +
            ' for attribute field id: ' + attribute?.identifiers?.fieldId + '.');
          break;
        }

      }
    }
  }

  showRequestNewAttributeConfirmationPanel(attributeTypeaheadConfig: AttributeTypeaheadConfig, event, panel, target) {
    this.requestNewMatAttributeFormModel = new RequestNewMatAttributeFormModel();
    this.requestNewMatAttributeFormModel.attributeFieldId = attributeTypeaheadConfig.matAttributeId;
    this.requestNewMatAttributeFormModel.attributeBusinessFriendlyDescription = attributeTypeaheadConfig.label;
    this.requestNewMatAttributeFormModel.attributeTypeaheadConfig = attributeTypeaheadConfig;
    // remove stub event
    if (attributeTypeaheadConfig.allowMultiple && Array.isArray(event) && !!event.length) {
      event.pop();
      this.requestNewMatAttributeFormModel.currentValue = event;
    }
    this.showPanel(event, panel, target);
  }

  showRequestNewAttributeFormPanel(event, panel, target, rnaMatConfirmOverlay) {
    rnaMatConfirmOverlay.hide();
    this.showPanel(event, panel, target);
  }

  sendRequestAndCloseModal(newMatAttributeFormModel: RequestNewMatAttributeFormModel, panel) {
    if (!newMatAttributeFormModel?.newMatAttributeValue) {
      return;
    }

    this.emailService.sendRequestMatAttributeValue(this.candidateModel, newMatAttributeFormModel).subscribe(() => {
        let updateAttributeValue;
        const valueToAdd = {
          id: CandidateUtilService.PENDING_ID_STRING,
          description: 'Pending: ' + newMatAttributeFormModel.newMatAttributeValue,
          pending: true
        };
        if (this.requestNewMatAttributeFormModel.currentValue) {
          updateAttributeValue = this.requestNewMatAttributeFormModel.currentValue.concat(valueToAdd);
        } else {
          updateAttributeValue = valueToAdd;
        }
        this.onAttributeSelection(updateAttributeValue, newMatAttributeFormModel.attributeTypeaheadConfig);

        this.hidePanel(panel);
      }, (error) => {
        this.growlService.addError(error.error);
      }
    );
  }

  showPanel(event, panel, target) {
    this.isShowingRequestAttributePanel = true;
    panel.show(event, target);
  }

  hidePanel(panel) {
    panel.hide();
    this.isShowingRequestAttributePanel = false;
    this.requestNewMatAttributeFormModel = null;
  }
}
