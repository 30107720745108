import {BaseAttribute} from 'pm-components/lib/attributes/attribute-type/base-attribute';
import {Attribute, AttributeConfig} from 'pm-models';
import {MatUtilService} from '../../../../../../src/app/2.0.0/service/mat-util.service';
import {
  AttributeDisplayToggleComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';

export class MatAttributeToggle implements BaseAttribute {

  constructor() { }


  private attributeToggleConfig: AttributeConfig;


  getAttributeConfig(overrides): AttributeConfig {
    return this.attributeToggleConfig;
  }

  getComponentType(): any {
    return AttributeDisplayToggleComponent;
  }

  setupComponent(componentInstance: any, attribute: Attribute, overrides?: any): void {
    this.setAttributeComponent(attribute);
    componentInstance.attribute = this.getAttributeConfig(overrides);
    componentInstance.model = attribute.value;
    componentInstance.change.subscribe(event => {
      attribute.value = event.checked;
    });
  }

  private setAttributeComponent(attribute: Attribute) {
    this.attributeToggleConfig = MatUtilService.getAttributeDisplayToggleConfigFromAttribute(attribute);
  }

}
