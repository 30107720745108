export class ScaleInformation {

  id: string;
  englishLabelOne?: string;
  englishLabelTwo?: string;
  englishLabelThree?: string;
  englishLabelFour?: string;
  englishLabelOneBilingual?: string;
  englishLabelTwoBilingual?: string;
  spanishLabelOne?: string;
  spanishLabelTwo?: string;
  prePackTare?: number;
  shelfLifeDays?: number;
  ingredientStatementNumber?: number;
  nutritionStatementNumber?: number;
  gradeNumber?: number;
  labelFormatOne?: number;
  labelFormatTwo?: number;
  bilingual?: Boolean;
  serviceCounterTare?: number;
  forceTare?: Boolean;
  mechanicallyTenderized?: Boolean;
  actionCode?: number;
  graphicsCode?: number;
  graphicsCodeOne?: number;
  graphicsCodeTwo?: number;
  graphicsCodeThree?: number;
  netWeight?: number;

}
