import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Candidate, CandidateError, DistributionChannelPermissions} from 'pm-models';
import {DistributionChannelErrors, DistributionChannelModel} from 'pm-models/lib/card-models/distribution-channel-model';

@Component({
  selector: 'pm-distribution-channel',
  templateUrl: './distribution-channel.component.html',
  styleUrls: ['./distribution-channel.component.scss']
})
export class DistributionChannelComponent implements OnInit, OnChanges {
  @Input()
  model: DistributionChannelModel | Candidate;

  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: DistributionChannelPermissions;

  @Input()
  errorModel: DistributionChannelErrors | CandidateError;

  channel;

  constructor() {}

  ngOnInit() {
    if (!this.model) {
      return;
    }

    if (this.model.warehouseSwitch && this.model.dsdSwitch) {
      this.channel = 'both';
    } else if (this.model.warehouseSwitch) {
      this.channel = 'whs';
    } else if (this.model.dsdSwitch) {
      this.channel = 'dsd';
    }
  }

  ngOnChanges(): void {
    if (this.model.warehouseSwitch && this.model.dsdSwitch) {
      this.channel = 'both';
    } else if (this.model.warehouseSwitch) {
      this.channel = 'whs';
    } else if (this.model.dsdSwitch) {
      this.channel = 'dsd';
    }
  }

  onChange(event) {}

  onClickWarehouse() {
    if (!this.model.warehouseSwitch) {
      this.channel = 'whs';
      this.model.warehouseSwitch = true;
      this.model.dsdSwitch = false;
      this.modelChange.emit(this.model);
    }
  }

  onClickDsd() {
    if (!this.model.dsdSwitch) {
      this.channel = 'dsd';
      this.model.dsdSwitch = true;
      this.model.warehouseSwitch = false;
      this.modelChange.emit(this.model);
    }
  }

  isComponentDisabledOrReadOnly(): boolean {
    return !this.permissions || this.permissions.isReadOnly || this.permissions.isDisabled;
  }

  isWarehouseDisabledOrReadOnly(): boolean {
    return this.isComponentDisabledOrReadOnly() ||
      (this.permissions.warehouseSwitch &&
        (this.permissions.warehouseSwitch.isDisabled ||
          this.permissions.warehouseSwitch.isReadOnly));
  }

  isDsdDisabledOrReadOnly(): boolean {
    return this.isComponentDisabledOrReadOnly() ||
      (this.permissions.dsdSwitch &&
        (this.permissions.dsdSwitch.isDisabled ||
          this.permissions.dsdSwitch.isReadOnly));
  }
}
