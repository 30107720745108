import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';
import { AttributeTextInputConfig, TextInputType } from 'pm-models';
@Component({
  selector: 'pm-attribute-display-text-input',
  templateUrl: './attribute-display-textinput.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-textinput.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayTextinputComponent extends BaseAttributeDisplayComponent implements OnInit {
  @Input()
  public attribute?: AttributeTextInputConfig;
  private maxLengthExceeded;

  @Output() inputFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputFocusOut?: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputBlur?: EventEmitter<any> = new EventEmitter<any>();

  inputTextType = TextInputType;
  ngOnInit() {
    this.maxLengthExceeded = false;
  }

  onBlur(event) {
    this.inputBlur.emit(event);
    this.modelChange.emit(this.model);
  }
  onFocus(event) {
    this.inputFocus.emit(event);
  }
  onFocusOut(event) {
    this.inputFocusOut.emit(event);
  }
  getErrorClass() {
    const inputGroup = [];
    if (this.attribute.inputGroupClass) {
      inputGroup.push(this.attribute.inputGroupClass);
    }
    if (this.attributeError || this.maxLengthExceeded) {
      inputGroup.push('ui-inputtext-invalid');
    }
    return inputGroup;
  }
  getCharLimitStatus() {
    if (this.attribute.charLimit && this.model && (this.model.length > this.attribute.charLimit)) {
      this.maxLengthExceeded = true;
      return 'attribute-length-error-message';
    }
    this.maxLengthExceeded = false;
    return 'attribute-description';
  }
  getModelLength() {
    if (this.model) {
      return this.model.length;
    }
    return 0;
  }
}
