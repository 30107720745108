import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorBaseComponent } from './editor-base.component';

import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [EditorBaseComponent],
  imports: [SharedModule, CommonModule],
  exports: [EditorBaseComponent, SharedModule]
})
export class EditorBaseModule {}
