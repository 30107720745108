/**
 * Holds constants for the Upload Candidate Product tab.
 */
export class UploadCandidateProductConstants {

  // UPC Columns
  public static readonly EMPTY_COLUMN = {header: ' ', width: '50px', sticky: true};
  public static readonly UNIT_UPC = {header: 'Unit UPC', width: '150px', sticky: true};
  public static readonly SUMMARY = {header: 'Summary', width: '200px', sticky: true};

  // Descriptions Columns
  public static readonly PRODUCT_DESCRIPTION = {header: 'Product description', width: '270px'};
  public static readonly RECEIPT = {header: 'Receipt',  width: '240px'};
  public static readonly SHELF_TAG_1 = {header: 'Shelf tag line 1',  width: '270px'};
  public static readonly SHELF_TAG_2 = {header: 'Shelf tag line 2',  width: '270px'};
  public static readonly ECOM_COPY = {header: 'eComm copy',  width: '270px'};

  public static readonly DESCRIPTIONS_COLUMN_GROUP =
    [UploadCandidateProductConstants.PRODUCT_DESCRIPTION, UploadCandidateProductConstants.RECEIPT,
      UploadCandidateProductConstants.SHELF_TAG_1, UploadCandidateProductConstants.SHELF_TAG_2,
      UploadCandidateProductConstants.ECOM_COPY];

  // Images Columns
  public static readonly IMAGES = {header: 'Images',  width: '620px'};

  // Merchandising Columns
  public static readonly COMMODITY = {header: 'Commodity',  width: '180px'};
  public static readonly SUB_COMMODITY = {header: 'Sub-commodity',  width: '180px'};
  public static readonly MERCHANDISE_TYPE = {header: 'Merchandise type',  width: '150px'};
  public static readonly PSS_DEPARTMENT = {header: 'PSS department',  width: '150px'};
  public static readonly SEASON = {header: 'Season',  width: '150px'};
  public static readonly LIKE_ITEM_CODE = {header: 'Like item code',  width: '150px'};
  public static readonly ESTIMATED_STORE_COUNT = {header: 'Est. store count',  width: '150px'};

  public static readonly MERCHANDISING_COLUMN_GROUP =
    [UploadCandidateProductConstants.COMMODITY, UploadCandidateProductConstants.SUB_COMMODITY,
      UploadCandidateProductConstants.MERCHANDISE_TYPE, UploadCandidateProductConstants.PSS_DEPARTMENT,
      UploadCandidateProductConstants.SEASON, UploadCandidateProductConstants.LIKE_ITEM_CODE,
      UploadCandidateProductConstants.ESTIMATED_STORE_COUNT];


  // Regulatory Columns
  public static readonly TAXABLE = {header: 'Taxable',  width: '100px'};
  public static readonly TAX_CATEGORY = {header: 'Tax category',  width: '250px'};
  public static readonly FOOD_STAMP_ELIGIBLE = {header: 'Food stamp eligible', width: '125px'};
  public static readonly FSA_ELIGIBLE = {header: 'FSA/HSA eligible', width: '100px'};

  public static readonly REGULATORY_COLUMN_GROUP =
    [UploadCandidateProductConstants.TAXABLE, UploadCandidateProductConstants.TAX_CATEGORY,
      UploadCandidateProductConstants.FOOD_STAMP_ELIGIBLE, UploadCandidateProductConstants.FSA_ELIGIBLE];

  // Retail Pricing	Columns
  public static readonly PRICING = {header: 'Pricing',  width: '180px'};
  public static readonly PENNY_PROFIT = {header: 'Penny profit',  width: '100px'};
  public static readonly MARGIN = {header: 'Margin',  width: '100px'};
  public static readonly SOLD_BY_WEIGHT = {header: 'Sold by weight',  width: '100px'};
  public static readonly SUGGESTED_RETAIL = {header: 'Suggested retail',  width: '150px'};
  public static readonly MAP_RETAIL = {header: 'MAP retail',  width: '100px'}; // trim to -

  public static readonly RETAIL_PRICING_COLUMN_GROUP =
    [UploadCandidateProductConstants.PRICING, UploadCandidateProductConstants.PENNY_PROFIT,
      UploadCandidateProductConstants.MARGIN, UploadCandidateProductConstants.SOLD_BY_WEIGHT,
      UploadCandidateProductConstants.SUGGESTED_RETAIL, UploadCandidateProductConstants.MAP_RETAIL];


  // Packaging Columns
  public static readonly PACKAGE_TYPE = {header: 'Package type',  width: '150px'};
  public static readonly DIMENSIONS = {header: 'Dimensions',  width: '175px'};
  public static readonly WEIGHT = {header: 'Weight',  width: '100px'};
  public static readonly UNIT_OF_MEASURE = {header: 'Unit of measure',  width: '100px'};
  public static readonly RETAIL_SIZE = {header: 'Retail size',  width: '100px'};
  public static readonly TOTAL_VOLUME = {header: 'Total volume',  width: '100px'};

  public static readonly PACKAGING_COLUMN_GROUP =
    [UploadCandidateProductConstants.PACKAGE_TYPE, UploadCandidateProductConstants.DIMENSIONS,
      UploadCandidateProductConstants.WEIGHT, UploadCandidateProductConstants.UNIT_OF_MEASURE,
      UploadCandidateProductConstants.RETAIL_SIZE, UploadCandidateProductConstants.TOTAL_VOLUME];


  public static readonly NEW_PRODUCT_PRODUCT_TAB_COLUMNS = [
    UploadCandidateProductConstants.EMPTY_COLUMN,
    UploadCandidateProductConstants.SUMMARY,
    UploadCandidateProductConstants.PRODUCT_DESCRIPTION,
    UploadCandidateProductConstants.RECEIPT,
    UploadCandidateProductConstants.SHELF_TAG_1,
    UploadCandidateProductConstants.SHELF_TAG_2,
    UploadCandidateProductConstants.ECOM_COPY,
    UploadCandidateProductConstants.IMAGES,
    UploadCandidateProductConstants.COMMODITY,
    UploadCandidateProductConstants.SUB_COMMODITY,
    UploadCandidateProductConstants.MERCHANDISE_TYPE,
    UploadCandidateProductConstants.PSS_DEPARTMENT,
    UploadCandidateProductConstants.SEASON,
    UploadCandidateProductConstants.TAXABLE,
    UploadCandidateProductConstants.TAX_CATEGORY,
    UploadCandidateProductConstants.FOOD_STAMP_ELIGIBLE,
    UploadCandidateProductConstants.FSA_ELIGIBLE,
    UploadCandidateProductConstants.PRICING,
    UploadCandidateProductConstants.PENNY_PROFIT,
    UploadCandidateProductConstants.MARGIN,
    UploadCandidateProductConstants.SOLD_BY_WEIGHT,
    UploadCandidateProductConstants.SUGGESTED_RETAIL,
    UploadCandidateProductConstants.MAP_RETAIL,
    UploadCandidateProductConstants.PACKAGE_TYPE,
    UploadCandidateProductConstants.DIMENSIONS,
    UploadCandidateProductConstants.WEIGHT,
    UploadCandidateProductConstants.UNIT_OF_MEASURE,
    UploadCandidateProductConstants.RETAIL_SIZE,
    UploadCandidateProductConstants.TOTAL_VOLUME,
  ];

  public static readonly BUYER_REVIEW_PRODUCT_TAB_COLUMNS = [
    UploadCandidateProductConstants.EMPTY_COLUMN,
    UploadCandidateProductConstants.SUMMARY,
    UploadCandidateProductConstants.RECEIPT,
    UploadCandidateProductConstants.SHELF_TAG_1,
    UploadCandidateProductConstants.SHELF_TAG_2,
    UploadCandidateProductConstants.ECOM_COPY,
    UploadCandidateProductConstants.IMAGES,
    UploadCandidateProductConstants.SEASON,
    UploadCandidateProductConstants.ESTIMATED_STORE_COUNT,
    UploadCandidateProductConstants.TAXABLE,
    UploadCandidateProductConstants.TAX_CATEGORY,
    UploadCandidateProductConstants.FOOD_STAMP_ELIGIBLE,
    UploadCandidateProductConstants.FSA_ELIGIBLE,
    UploadCandidateProductConstants.SUGGESTED_RETAIL,
    UploadCandidateProductConstants.MAP_RETAIL,
    UploadCandidateProductConstants.PACKAGE_TYPE,
    UploadCandidateProductConstants.DIMENSIONS,
    UploadCandidateProductConstants.WEIGHT,
    UploadCandidateProductConstants.UNIT_OF_MEASURE,
    UploadCandidateProductConstants.RETAIL_SIZE,
    UploadCandidateProductConstants.TOTAL_VOLUME,
  ];
  public static readonly SCA_REVIEW_PRODUCT_TAB_COLUMNS = [
    UploadCandidateProductConstants.EMPTY_COLUMN,
    UploadCandidateProductConstants.SUMMARY,
    UploadCandidateProductConstants.RECEIPT,
    UploadCandidateProductConstants.SHELF_TAG_1,
    UploadCandidateProductConstants.SHELF_TAG_2,
    UploadCandidateProductConstants.ECOM_COPY,
    UploadCandidateProductConstants.IMAGES,
    UploadCandidateProductConstants.COMMODITY,
    UploadCandidateProductConstants.SUB_COMMODITY,
    UploadCandidateProductConstants.MERCHANDISE_TYPE,
    UploadCandidateProductConstants.PSS_DEPARTMENT,
    UploadCandidateProductConstants.SEASON,
    UploadCandidateProductConstants.LIKE_ITEM_CODE,
    UploadCandidateProductConstants.ESTIMATED_STORE_COUNT,
    UploadCandidateProductConstants.TAXABLE,
    UploadCandidateProductConstants.TAX_CATEGORY,
    UploadCandidateProductConstants.FOOD_STAMP_ELIGIBLE,
    UploadCandidateProductConstants.FSA_ELIGIBLE,
    UploadCandidateProductConstants.PRICING,
    UploadCandidateProductConstants.PENNY_PROFIT,
    UploadCandidateProductConstants.MARGIN,
    UploadCandidateProductConstants.SOLD_BY_WEIGHT,
    UploadCandidateProductConstants.SUGGESTED_RETAIL,
    UploadCandidateProductConstants.MAP_RETAIL,
    UploadCandidateProductConstants.PACKAGE_TYPE,
    UploadCandidateProductConstants.DIMENSIONS,
    UploadCandidateProductConstants.WEIGHT,
    UploadCandidateProductConstants.UNIT_OF_MEASURE,
    UploadCandidateProductConstants.RETAIL_SIZE,
    UploadCandidateProductConstants.TOTAL_VOLUME,
  ];

}
