import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {GrowlService} from '../growl/growl.service';
import {PreferencesService} from '../service/preferences.service';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';

@Injectable()
export class BdaOrScaGuard implements CanActivate {

  constructor(private authService: AuthService,
              private preferencesService: PreferencesService,
              private growlService: GrowlService,
              private router: Router) {
  }

  /**
   * Checks if the user is a BDA or SCA logging in for the first time,
   * if yes then route to the set up notifications page,
   * else it routes to the correct page.
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns boolean}
   */
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {

    // only do remote check once per session
    if (sessionStorage.getItem(this.authService.hasCheckedBDAorSCA)) {
      return of(true);
    }

    // Not a buyer or sca, so continue as normal
    if (!this.authService.isBuyer() && !this.authService.isSca()) {
      return of(true);
    }

    return this.preferencesService.checkBDAOrSCA().pipe(
      map(res => {

        // remember that we already checked for this session
        sessionStorage.setItem(this.authService.hasCheckedBDAorSCA, 'true');

        if (res) {
          // BDA must go through set up notifications page
          this.router.navigate(['/setUpNotifications'], {queryParams: {returnTo: state.url}});
          return false;
        } else {
          return true;
        }
      }));
  }
}
