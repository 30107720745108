export class Vendor {

  public static readonly DSD_VENDOR_TYPE_CODE = 'DS';
  public static readonly WAREHOUSE_VENDOR_TYPE_CODE = 'AP';

  description: any;
  apNumber: any;
  displayName: any;
  lanes?: any;
  email?: any;
  isFollowed?: any;
  checked?: Boolean;

}
