import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewComponent, ReviewDrawerContainerComponent } from './review.component';

import { SharedModule } from '../../shared/shared.module';
import { DrawerModule } from '../../drawer/drawer.module';

@NgModule({
  declarations: [ReviewComponent, ReviewDrawerContainerComponent],
  exports: [ReviewComponent, ReviewDrawerContainerComponent, SharedModule],
  imports: [CommonModule, SharedModule, DrawerModule]
})
export class ReviewModule {}
