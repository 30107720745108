import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {CandidateProductListComponent} from './candidate-product-list.component';
import {DeleteButtonsModule} from '../delete-button/delete-button.module';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {NgxPermissionsModule, NgxPermissionsService} from 'ngx-permissions';
import {NgxPermissionsServiceMock} from '../../cards/supplier-details-card/ngx-permissions-service-mock.service';

@NgModule({
  declarations: [CandidateProductListComponent],
  exports: [CandidateProductListComponent],
  imports: [CommonModule, ButtonModule, DeleteButtonsModule, TooltipModule, AttributeDisplayModule, NgxPermissionsModule],
  providers: [
    {provide: NgxPermissionsService, useClass: NgxPermissionsServiceMock}
    ]
})
export class CandidateProductListModule {}
