import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeConfig, AttributeTextInputConfig, Candidate, TextInputType} from 'pm-models';

@Component({
  selector: 'pm-reject-candidate-overlay',
  templateUrl: './reject-candidate.component.html',
  styleUrls: ['./reject-candidate.component.scss']
})
export class RejectCandidateComponent implements OnInit {

  @Output()
  rejectButtonClicked = new EventEmitter<string>();

  @Output()
  cancelClicked = new EventEmitter<void>();


  @Input()
  public rejectMessage = 'Let the Supplier know why you\'re rejecting this candidate.';

  otherReasonMessage: string;

  reasonType: string = Candidate.CORRECTIONS_NEEDED;

  public OTHER = Candidate.OTHER;

  reasonConfiguration: AttributeConfig = {
    label: 'Reason',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    inputGroupClass: 'attribute-radios-block',
    options: [
      { label: Candidate.CORRECTIONS_NEEDED, value: Candidate.CORRECTIONS_NEEDED },
      { label: Candidate.NOT_WANTED, value: Candidate.NOT_WANTED },
      { label: Candidate.OTHER, value: Candidate.OTHER }
    ],
  };

  constructor() { }

  ngOnInit() {
  }

  rejectCandidate() {
    if (!this.reasonType) {
      return;
    }
    if (this.reasonType === Candidate.OTHER) {
      this.rejectButtonClicked.emit(this.otherReasonMessage);

    } else {
      this.rejectButtonClicked.emit(this.reasonType);
    }
  }

  cancel() {
    this.cancelClicked.emit();
  }

  reasonTypeChange(event) {
  }

  /**
   * Returns true if there's no reason type, or the reason type is OTHER and the message is null, undefined or empty (not all spaces).
   */
  isRejectDisabled(): boolean {
    return !this.reasonType || (this.reasonType === Candidate.OTHER &&
      (!this.otherReasonMessage || !this.otherReasonMessage.trim()));
  }
}
