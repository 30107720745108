/*******
 *
 * This is a 'fork' of Mortar's Toolbar. It can be deleted if we ever fully integrate Mortar.
 *
 * */

import { Input, Directive } from '@angular/core';

/** Toolbar sections allow for separation of content and actions within a single toolbar */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'pm-toolbar-section',
  host: {
    'class': 'mtr-toolbar__section',
    '[class.mtr-toolbar__section--align-start]': 'align === "start"',
    '[class.mtr-toolbar__section--align-end]': 'align === "end"',
  },
})
export class PMToolbarSectionDirective {
  /** Whether a section should align to the start or the end of the tool bar */
  @Input() align: 'start' | 'end' | '';

  constructor() {}
}

