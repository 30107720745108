import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeTextInputConfig,
  ScaleInformation,
  ScaleInformationCardErrors,
  ScaleInformationCardModel,
  ScaleInformationCardPermissions, TextInputType
} from 'pm-models';
import {ScaleInformationError} from 'pm-models/lib/scaleInformationError';
import {
  DisabledPermissionsMapper,
  ReadOnlyPermissionsMapper,
  VisiblePermissionsMapper
} from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-scale-information-card',
  templateUrl: './scale-information-card.component.html',
  styleUrls: ['./scale-information-card.component.scss']
})
export class ScaleInformationCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input()
  model: ScaleInformationCardModel | ScaleInformation;

  @Input()
  permissions: ScaleInformationCardPermissions;

  @Input()
  errorModel: ScaleInformationCardErrors | ScaleInformationError;

  @Output() modelChange = new EventEmitter<any>();


  englishLabelOneConfiguration: AttributeTextInputConfig = {
    label: 'English label one',
    description: `Enter a description for the first line of the scale sticker.`,
    placeholderText: 'English label description line 1...',
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.englishLabelOne),
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.englishLabelOne),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.englishLabelOne),
    isRequired: true,
    name: 'englishLabelDescriptionOne',
    charLimit: 32
  };

  englishLabelTwoConfiguration: AttributeTextInputConfig = {
    label: 'English label two',
    description: `Enter a description for the second line of the scale sticker.`,
    placeholderText: 'English label description line 2...',
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.englishLabelTwo),
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.englishLabelTwo),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.englishLabelTwo),
    isRequired: false,
    name: 'englishLabelDescriptionTwo',
    charLimit: 32
  };


  prePackTareConfiguration: AttributeTextInputConfig = {
    label: 'Pre-pack tare',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.prePackTare),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.prePackTare),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.prePackTare),
    isRequired: false,
    name: 'prePackTare',
    decimalCount: 3,
    numberCount: 1,
    textInputType: TextInputType.decimal,
    placeholderText: 'Tare',
    inputGroupClass: 'ui-narrow-input',
  };

  shelfLifeDaysConfiguration: AttributeTextInputConfig = {
    label: 'Shelf life (days)',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.prePackTare),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.prePackTare),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.prePackTare),
    isRequired: false,
    name: 'shelfLifeDays',
    maxLength: 3,
    textInputType: TextInputType.integer,
    placeholderText: 'Days',
    inputGroupClass: 'ui-narrow-input',
  };
}
