/**
 * Represents a work flow status, and provides a means to sort (as defined by the business).
 */
export enum WorkFlowStatus {
  IN_PROGRESS = 'In Progress',
  UNDER_REVIEW = 'Under Review',
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  UNKNOWN = 'Unknown'
}

export namespace WorkFlowStatus {
  function getSort(status: WorkFlowStatus): number {
    switch (status) {
      case WorkFlowStatus.IN_PROGRESS:
        return 0;
      case WorkFlowStatus.UNDER_REVIEW:
        return 1;
      case WorkFlowStatus.APPROVED:
        return 2;
      case WorkFlowStatus.DECLINED:
        return 3;
      default:
        return 4;
    }
  }
  export function compare(first: WorkFlowStatus, second: WorkFlowStatus): number {
    const difference = getSort(first) - getSort(second);
    return difference > 0 ? 1 : difference < 0 ? -1 : 0;
  }
}
