<input type="file" class="inputfile" #fileInput id="fileInput" style="display:none;"
       accept=".doc, .pdf, .xls, .xlsx, .txt, .csv, .docx, .ppt, .pptx" (change)="onFilesAdded(fileInput.files)"
       (click)="fileInput.value = null" value="" multiple>

<pm-progress-spinner [showSpinner]="isInProgress" [style]="{width: '30px', height: '30px', left: '33%'}">
</pm-progress-spinner>
<ul *ngIf="attachments?.length" class="attachment-list">
  <li class="attachment-panel" *ngFor="let file of attachments">
    <pm-attachment-tile [file]="file" (deleteCallback)="removeFile($event)" (downloadCallback)="downloadFile($event)"></pm-attachment-tile>
  </li>
</ul>
<button class="upload-button"
        onclick="document.getElementById('fileInput').click();">Add attachments</button>

