import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTextInputConfig, CandidateProduct, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class ECommCopy implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'eComm copy',
      description: `Provide a description that explains what this product is and why it's worth buying.
      This will appear under the "Product description" section on our eCommerce platforms. <br/><br />

      Only enter plain text. Our apps will handle styling this content.
      `,
      placeholderText: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      textInputType: TextInputType.textarea,
      name: 'romanceCopyId',
      charLimit: 4000,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.romanceCopy;
    componentInstance.modelChange.subscribe(x => {
      candidate.romanceCopy = x;
    });
  }
}
