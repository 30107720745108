import {Component, OnInit, ViewChild} from '@angular/core';
import {EditorComponent, InputState, UPCInputState} from 'pm-components';
import {
  AttachmentsCardPermissions,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  CaseDetailsCardPermissions,
  CostCardPermissions,
  CostLinkCardPermissions,
  DisplayReadyUnitCardPermissions,
  ImportedProductCardPermissions,
  InnerPackDetailsCardPermissions,
  MasterPackDetailsCardPermissions,
  OmiRemarksCardPermissions
} from 'pm-models';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {CostService} from '../../service/cost.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {PiaBonusStepperComponent} from '../pia-bonus-stepper/pia-bonus-stepper.component';

@Component({
  selector: 'app-pia-bonus-case-details',
  templateUrl: './pia-bonus-case-details.component.html',
  styleUrls: ['./pia-bonus-case-details.component.scss']
})
export class PiaBonusCaseDetailsComponent implements OnInit {

  @ViewChild(EditorComponent) pmEditor;

  public isViewingPage = true;

  public containerSizes: any;
  public incoTermsList: any;
  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions;

  readonly importProductCardPermissions: ImportedProductCardPermissions = {
    isReadOnly: false
  };

  readonly masterPackDetailsCardPermissions: MasterPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly innerPackDetailsCardPermissions: InnerPackDetailsCardPermissions = {
    isReadOnly: false
  };

  readonly displayReadyUnitCardPermissions: DisplayReadyUnitCardPermissions = {
    isReadOnly: false
  };
  readonly costLinkCardPermissions: CostLinkCardPermissions = {
    isReadOnly: false
  };
  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false
  };

  readonly omiRemarksPermissions: OmiRemarksCardPermissions = {
    isReadOnly: false,
  };

  public costLinkState: InputState;

  attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };

  canNavigate = true;
  upcState: UPCInputState;

  private taskSubscription$: any;

  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public bonusSizeService: BonusSizeService,
              public costService: CostService, private candidateUtilService: CandidateUtilService) { }

  getAdditionalCasePackBasicsCardPermissions(): CaseDetailsCardPermissions {
    return {
      isReadOnly: false,
      itemWeightType: {
        isDisabled : !this.candidateUtilService.isTiedToCatchOrVariableWeightBuyer(this.bonusSizeService.getCandidate())
      },
      dsdSwitch: {
        isDisabled: true
      },
      warehouseSwitch: {
        isDisabled: true
      },
      buyer: {
        isHidden: true
      },
      dealOffered: {
        isHidden: true
      }
    };
  }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId, this.bonusSizeService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.BONUS_SIZE || candidate.candidateType === Candidate.REPLACEMENT_UPC) {
            this.bonusSizeService.currentCandidate = candidate;
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']).then();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.setUpBasicInfo();
            } else {
              this.bonusSizeService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.bonusSizeService.originalCandidate = JSON.parse(JSON.stringify(this.bonusSizeService.currentCandidate));
    this.bonusSizeService.candidateError = this.bonusSizeService.bonusDetailsError;
    this.bonusSizeService.candidateProductError = new CandidateProductError();
    this.setUpBasicInfo();
  }

  setUpBasicInfo() {
    this.lookupService.findAllContainerSizes().subscribe(containerSizes => {
      this.containerSizes = containerSizes;
    });
    this.lookupService.findAllIncoTerms().subscribe(incoTermsList => {
      this.incoTermsList = incoTermsList;
    });
    this.bonusSizeService.setProductData().subscribe();
    this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
  }

  validateAndGoToNext() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR]).subscribe(() => {
      this.bonusSizeService.caseDetailsError = new CandidateError();
      this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaWarehouseUrl(), false);
    }, (error) => {

      this.bonusSizeService.scrollToTop();
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.bonusSizeService.caseDetailsError = error.error.candidateErrors;
      }
      this.canNavigate = true;
    });
  }

  onClickNext() {
    this.validateAndGoToNext();
  }

  onClickBack() {
    this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaUpcDetailsUrl(), false);
  }

  onClose() {
    this.isViewingPage = false;
    this.bonusSizeService.saveCandidateAndNavigate('/tasks', false);
  }

  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.bonusSizeService.currentCandidate.costLinkFromServer = undefined;
    this.bonusSizeService.caseDetailsError.costLink = undefined;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((validatedCandidate) => {
      this.costLinkState = InputState.valid;
      this.costService.updateCostLinkRelatedValuesFromValidatedCandidate(this.bonusSizeService.getCandidate(), validatedCandidate);
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.bonusSizeService.caseDetailsError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }


  showExistingCasePacks() {
    this.pmEditor.openDrawer();
  }

  collapse() {
    this.pmEditor.closeDrawer();
  }

  onClickCasePack(casePack) {
    casePack.isViewing = !casePack.isViewing;
  }

  validateUPC() {
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.bonusSizeService.getCurrentCandidateProduct().id;

    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.CASE_UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.bonusSizeService.getCurrentCandidateProductError(this.bonusSizeService.caseDetailsError).caseUpc = undefined;
    }, (error) => {
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError: CandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.bonusSizeService.getCurrentCandidateProductError(this.bonusSizeService.caseDetailsError).caseUpc =
          returnedCandidateProductError.caseUpc;
        this.bonusSizeService.getCurrentCandidateProductError(this.bonusSizeService.caseDetailsError).caseUpcCheckDigit =
          returnedCandidateProductError.caseUpcCheckDigit;
        this.bonusSizeService.caseDetailsError.itemWeightType = returnedCandidateError.itemWeightType;
        if (returnedCandidateProductError.caseUpc ||  returnedCandidateProductError.caseUpcCheckDigit) {
          this.upcState = UPCInputState.invalid;
        } else {
          this.bonusSizeService.caseDetailsError = returnedCandidateError;
          this.upcState = UPCInputState.valid;
        }
      } else {
        this.upcState = UPCInputState.invalid;
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  onInnerPackChange() {
    if (!this.bonusSizeService.currentCandidate.innerPackSelected) {
      this.bonusSizeService.getCurrentCandidateProductError(this.bonusSizeService.caseDetailsError).caseUpc = null;
      this.upcState = UPCInputState.none;
    }
    if (this.bonusSizeService.getCandidate() && this.bonusSizeService.getCandidate().costLinked
      && this.bonusSizeService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }

  innerPackQuantityChange() {
    if (this.bonusSizeService.getCurrentCandidateProductError(this.bonusSizeService.caseDetailsError) &&
      this.bonusSizeService.getCurrentCandidateProductError(this.bonusSizeService.caseDetailsError).caseUpc) {
      this.bonusSizeService.getCurrentCandidateProductError(this.bonusSizeService.caseDetailsError).caseUpc = null;
      this.upcState = UPCInputState.none;
    }
  }

  /**
   * Trigger cost link validation on master pack change.
   * @param event
   */
  onMasterPackChange(event) {
    if (this.bonusSizeService.getCandidate() && this.bonusSizeService.getCandidate().costLinked
      && this.bonusSizeService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    let urlToNavigate;
    let validators;
    switch (stepperItem.text) {
      case PiaBonusStepperComponent.UPC_ITEM_CODE:
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaSetupUpcUrl(), false);
        break;
      case PiaBonusStepperComponent.BONUS_DETAILS:
      case PiaBonusStepperComponent.REPLACEMENT_DETAILS:
        this.onClickBack();
        break;
      case PiaBonusStepperComponent.WAREHOUSE_SELECTION:
        this.validateAndGoToNext();
        break;
      case PiaBonusStepperComponent.EXTENDED_ATTRIBUTES:
        urlToNavigate = this.bonusSizeService.getPiaExtendedAttributesUrl();
        validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR,
          CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR,  CandidateValidatorType.WAREHOUSE_VALIDATOR, CandidateValidatorType.BICEP_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
    }
  }


  jumpToPage(urlToNavigate, validators: CandidateValidatorType[]) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate, validators).subscribe(() => {
      this.bonusSizeService.candidateError = this.bonusSizeService.caseDetailsError = new CandidateError();
      this.bonusSizeService.candidateProductError = new CandidateProductError();
      this.bonusSizeService.saveCandidateAndNavigate(urlToNavigate, false);
    }, (error) => {
      this.bonusSizeService.scrollToTop();

      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.bonusSizeService.candidateError = error.error.candidateErrors;
        this.bonusSizeService.candidateProductError =
          this.bonusSizeService.candidateError.candidateProductErrors[this.bonusSizeService.getCurrentCandidateProduct().id];
        this.bonusSizeService.caseDetailsError = this.bonusSizeService.candidateError;
      }
      this.canNavigate = true;
    });
  }
}
