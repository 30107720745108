import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTypeaheadConfig } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class Brand implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'Brand',
      description: `Choose this product's brand.`,
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: 'brandTypeAhead',
      displayRef: 'displayName',
      placeholderText: 'Select or search for a brand...',
      searchUrl: '/lookup/brand',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.brand;
    componentInstance.selection.subscribe(x => {
      candidate.brand = x;
      candidate.costOwner = null;
    });
  }

  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.brand) {
      componentInstance.model = candidate.brand;
    }
  }
}
