import { BaseAttribute } from './base-attribute';
import { AttributeVolumeConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayVolumeComponent } from '../../shared/attribute-display/attribute-display-volume/attribute-display-volume.component';

export class InnerPackDimensions implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayVolumeComponent;
  }

  getAttributeConfig(overrides?): AttributeVolumeConfig {
    return {
      label: 'Dimensions',
      description: `Enter the length, width, and height of the inner pack in inches.
       We'll calculate the volume in cubic feet.`,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: '',
      isRequired: true,
      numberCount: 7,
      decimalCount: 2,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayVolumeComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    // componentInstance.model = candidate.masterPack;
    componentInstance.lengthModel = candidate.innerLength;
    componentInstance.lengthModelChange.subscribe(x => {
      candidate.innerLength = x;
    });

    componentInstance.heightModel = candidate.innerHeight;
    componentInstance.heightModelChange.subscribe(x => {
      candidate.innerHeight = x;
    });

    componentInstance.widthModel = candidate.innerWidth;
    componentInstance.widthModelChange.subscribe(x => {
      candidate.innerWidth = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayVolumeComponent, candidate: Candidate) {
    if (componentInstance.lengthModel !== candidate.innerLength) {
      componentInstance.lengthModel = candidate.innerLength;
    }

    if (componentInstance.heightModel !== candidate.innerHeight) {
      componentInstance.heightModel = candidate.innerHeight;
    }

    if (componentInstance.widthModel !== candidate.innerWidth) {
      componentInstance.widthModel = candidate.innerWidth;
    }
  }
}
