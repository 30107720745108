<pm-review #pmReview *ngIf="isViewingPage" [ngClass]="getReviewClass()" (closeEvent)="onClose()">
  <!-- Page Title -->
  <pm-title>{{candidate?.description}}</pm-title>
  <pm-sub-title>{{getTitleSubHeading()}}</pm-sub-title>
  <pm-app-reject-status-header [candidate]="candidate"></pm-app-reject-status-header>
  <pm-sub-title class="row" style="padding-left: 15px;">
    <button pButton type="button"
            [label]="'View candidate history'"
            class="ui-button-link" (click)="historyPanelOpen()">
    </button>
  </pm-sub-title>
  <div *ngIf="candidate" class="row justify-content-md-center pb-3">
    <div class="col col-md-10 pb-5">

      <pm-attribute-grid class="mrt-inner-info-grid-container" [title]="'MRT items & costs set by supplier'">
        <div *ngIf="isLoadingInners" style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingInners" [strokeWidth]="'2'" class="loading-indicator"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching product data...</h5>
        </div>
        <div *ngIf="!isLoadingInners">
          <ng-template ngFor let-inner [ngForOf]="mrtCandidateInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="false">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(inner).upcCheckDigit}}<br>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status === 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="pending">Pending</div>
                  <pm-grid-cell-info>
                    This item is new and requires a separate review
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status !== 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="text-capitalize">{{toLowerCase(inner?.candidate?.status)}}</div>
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).description}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(supplierMrtService.getCurrentCandidateProducts(inner).description) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="false">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  -
                </pm-grid-cell>

              </pm-grid-column>
              <hr>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
          <ng-template ngFor let-inner [ngForOf]="mrtExistingInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="false">
                  {{inner?.upc}}-{{inner.upcCheckDigit}}<br>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  Active
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{inner?.product?.productDescription}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(inner?.product?.productDescription) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="false">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell *ngIf="!productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  {{productService.getItemCode(inner?.product)}}
                </pm-grid-cell>
                <pm-grid-cell *ngIf="productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  Multiple
                  <pm-grid-cell-info>
                    This item has multiple case configurations
                  </pm-grid-cell-info>
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
        </div>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterListCost')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(candidate?.masterListCost)}}
              <pm-grid-cell-info style="white-space: pre-line;">
                <div *ngIf="!isNotMissingCandidateInfo" >
                  {{getCostDisplay()}}
                </div>
                <div *ngIf="isNotMissingCandidateInfo">
                  Penny Profit: {{getPennyProfit()}}<br>
                  Margin: <a [ngClass]="{'grid-cell-error':isMarginNegativeOrZero()}">{{getMarginPercent()}}%</a>
                  <img *ngIf="isMarginNegativeOrZero()" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
                </div>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Case summary -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseUpc')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'buyer', 'buyerName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate?.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="false">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>

          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'countryOfOrigin', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Case details from buyer -->
      <pm-attribute-grid [title]="'Case details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'inboundSpecDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'seasonYear') || candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'season', 'seasonDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'warehouseReactionDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'guaranteeToStoreDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{candidate.subCommodity?.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master Pack'" [canEdit]="false">
              1
            </pm-grid-cell>
            <pm-grid-cell [label]="'Total retail units'">
              {{getMasterPack()}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTie') || candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTier')"
                          (historyClicked)="historyPanelOpen()">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'itemWeightType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'cubeAdjustedFactor')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'codeDate')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'maxShelfLife')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnit')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsDeep') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsFacing') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsHigh')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Warehouses -->
      <pm-attribute-grid [title]="'Warehouses'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Max ship'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasScaValueChangedForCandidate(candidate, 'maxShip')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.maxShip}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <ng-template ngFor let-warehouse [ngForOf]="candidateProduct.warehouses" let-index="index">

          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Warehouse'" [canEdit]="false" class="warehouse-grid-cell">
                {{warehouse.name}}
                <pm-grid-cell-info>
                  ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                </pm-grid-cell-info>
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order unit'" [canEdit]="false"
                            [hasChanged]="candidateHistoryService.hasScaValueChangedForWarehouse(candidateProduct, 'orderUnit', 'description')"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderUnit?.description}}
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order restriction'" [canEdit]="false"
                            [hasChanged]="candidateHistoryService.hasScaValueChangedForWarehouse(candidateProduct, 'orderRestriction', 'description')"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderRestriction?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <div *ngIf="index !== candidateProduct.warehouses.length - 1" class="grid-divider"></div>
        </ng-template>
      </pm-attribute-grid>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCostOwner(candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'buyer', 'buyerName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForTopToTop(candidate)"
                          (historyClicked)="historyPanelOpen()">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate,'lane', 'name')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.lane?.name}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Import Info  -->
      <pm-attribute-grid *ngIf="!candidateProduct?.imported" [title]="'Imported product'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Imported product'" [canEdit]="false">
              {{candidateProduct?.imported | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Import Info  -->
      <pm-attribute-grid *ngIf="candidateProduct?.imported" [title]="'Imported product'">
        <pm-grid-group>
          <pm-grid-column>

            <pm-grid-cell [label]="'Imported product'" [canEdit]="false">
              {{candidateProduct?.imported | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pickup point'" [canEdit]="false">
              {{candidateProduct?.importInfo?.pickupPoint}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty %'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyPercent}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS1'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Carton marking'" [canEdit]="false">
              {{candidateProduct?.importInfo?.cartonMarking}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Instore date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.inStoreDate | date}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Container size'" [canEdit]="false">
              {{candidateProduct?.importInfo?.containerSize.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Agent %'" [canEdit]="false">
              {{candidateProduct?.importInfo?.agentPercent}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty confirmed'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyConfirmed | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS2'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts2}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product color'" [canEdit]="false">
              {{candidateProduct?.importInfo?.productColor}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'WHS flush date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.whsFlushDate | date}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Inco terms'" [canEdit]="false">
              {{candidateProduct?.importInfo?.incoTerms.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Proraction date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.prorationDate | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty info'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyInfo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS3'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts3}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Freight confirmed'" [canEdit]="false">
              {{candidateProduct?.importInfo?.freightConfirmedDate | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Import factory'" [canEdit]="false">
              {{candidateProduct?.importInfo?.factory.factoryName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Minimum order'" [canEdit]="false">
              {{candidateProduct?.importInfo?.minimumOrderQty}}, {{candidateProduct?.importInfo?.minimumOrderDesc}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <div *ngIf="candidateUtilService.isInProgressCandidate(candidate)">
          <!--   Global Attributes   -->
          <pm-attribute-grid *ngIf="isLoadingMatData || !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
        <div *ngIf="!candidateUtilService.isInProgressCandidate(candidate)">
          <!--  Global Attributes    -->
          <pm-attribute-grid *ngIf="!!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>

      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="false">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="false">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="false">
              <div *ngIf="!!candidate?.supplierComment">
                <b>{{candidate?.contactEmail}}</b> <br>
                {{candidate?.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.piaComment">
                <b>{{candidate.piaCommentUser}}</b> <br>
                {{candidate.piaComment}}
              </div>
            </pm-comment-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate?.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate?.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

    </div>
  </div>


  <!--  Audit History -->
  <pm-review-drawer-container>
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="candidateHistoryService.currentHistoryResults">
            <div *ngIf="candidateHistoryService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{candidateHistoryService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back to home" (onClick)="onClickBackToHome()"></p-button>
    <p-button class="m-2" label="Print" (onClick)="onClickPrint()"></p-button>
  </pm-footer>
</pm-review>
