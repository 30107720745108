import {Component, OnInit} from '@angular/core';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from '../../service/lookup.service';
import {CandidateService} from '../../service/candidate.service';
import {CandidateError, CandidateProductError, CandidateValidatorType} from 'pm-models';
import {UPCInputState} from 'pm-components';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';

@Component({
  selector: 'app-supplier-add-distributor',
  templateUrl: './supplier-add-distributor.component.html',
  styleUrls: ['./supplier-add-distributor.component.scss']
})
export class SupplierAddDistributorComponent implements OnInit {

  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  private upcType: string;
  public upcState: UPCInputState;
  public upcOptions = [{ label: 'UPC', value: 'UPC' }];
  searchedCode: number;
  public isNextOrBackDisabled = false;

  constructor(public invitedDistributorService: InvitedDistributorService, private route: ActivatedRoute,
              private router: Router, private lookupService: LookupService, private candidateService: CandidateService,
              private growlService: GrowlService, private candidateUtilService: CandidateUtilService) { }

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.invitedDistributorService.getTaskId(),
        this.invitedDistributorService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.invitedDistributorService.resetService();
        this.invitedDistributorService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          this.initializeData();
        });

        // if there's a candidate that hasn't been created (no candidate id), and no task id, then allow for set up page.
      } else if (this.invitedDistributorService.getOriginalCandidate() && this.invitedDistributorService.getCandidate() && !taskId
        && !this.invitedDistributorService.getCandidate().candidateId) {
        this.initializeData();
      } else {
        // if there's a task id and current candidates, get the task by candidate id. If the tasks IDs don't match, reset everything.
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.invitedDistributorService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              window.history.replaceState({}, 'Title', '#/supplierAddDistributor');
              this.invitedDistributorService.resetService();
              this.invitedDistributorService.setupSupplierInitiatedNewCandidate();
              this.initializeData();
            }
          });
      }
    });
  }


  initializeData() {
    this.candidateError = this.invitedDistributorService.getCaseDetailsError();
    this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
    this.setInitialUpcType();
    this.setSearchedCode();
  }

  setSearchedCode () {
    this.searchedCode = this.isItemCode() ? this.invitedDistributorService.getCurrentCandidateProduct().itemCode :
      this.isUPC() ? this.invitedDistributorService.getCurrentCandidateProduct().upc : null;
  }

  setInitialUpcType() {
    if (this.invitedDistributorService.getCurrentCandidateProduct().itemCode !== null &&
      this.invitedDistributorService.getCurrentCandidateProduct().itemCode !== undefined) {
      this.upcType = 'ITMCD';
    } else {
      this.upcType = 'UPC';
    }
  }

  onClose() {
    if (this.invitedDistributorService.getCandidate().description && this.invitedDistributorService.getCandidate().candidateProducts[0].upc
      && this.invitedDistributorService.getCandidate().candidateProducts[0].upcCheckDigit) {
      if (this.invitedDistributorService.getCandidate().candidateId) {
        this.invitedDistributorService.saveCandidateAndNavigate('/tasks', this.invitedDistributorService.hasVendorChanged());
      } else {
        this.invitedDistributorService.createCandidateAndNavigate(this.invitedDistributorService.getCandidate(), '/tasks');
      }
    } else {
      this.router.navigate(['/tasks']);
    }
  }

  hasUpcError() {
    return this.candidateProductError && (this.candidateProductError.upc || this.candidateProductError.itemCode);
  }


  validateUPC() {
    const validators = [];
    if (this.isItemCode()) {
      validators.push(CandidateValidatorType.EXISTING_ITEM_CODE_VALIDATOR);
    } else if (this.isUPC()) {
      validators.push(CandidateValidatorType.EXISTING_UPC_VALIDATOR);
    }
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.invitedDistributorService.getCurrentCandidateProduct().id;
    this.candidateService.validateCandidate(this.invitedDistributorService.getCandidate(), validators).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.upc = undefined;
      this.candidateProductError.upcCheckDigit = undefined;
      this.candidateProductError.itemCode = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.upc = returnedCandidateProductError.upc;
        this.candidateProductError.upcCheckDigit = returnedCandidateProductError.upcCheckDigit;
        this.candidateProductError.itemCode = returnedCandidateProductError.itemCode;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  isItemCode() {
    return this.upcType === 'ITMCD';
  }
  isUPC() {
    return this.upcType === 'UPC';
  }

  //  UpcModelChange occurs on blur to handle leading 0s for UPCs. Since validation needs to also occur on blur,
  // a race condition arises with the value not always being set before it tries to validate. To circumvent this,
  // Call validation after upc/item code is set.
  setUpcItemCodeAndValidate() {
    this.invitedDistributorService.resetProductAndStoreData();
    if (this.isItemCode()) {
      this.invitedDistributorService.getCurrentCandidateProduct().itemCode = +this.searchedCode;
      this.validateUPC();
    } else {
      this.invitedDistributorService.getCurrentCandidateProduct().upc = +this.searchedCode;
      if (this.invitedDistributorService.getCurrentCandidateProduct().upc &&
        (this.invitedDistributorService.getCurrentCandidateProduct().upcCheckDigit || this.invitedDistributorService.getCurrentCandidateProduct().upcCheckDigit === 0)) {
        this.validateUPC();
      }
    }
  }

  onClickBack() {
    this.router.navigate(['/setupCandidate']);
  }

  onClickNext() {
    this.isNextOrBackDisabled = true;
    const validators = [CandidateValidatorType.CANDIDATE_NAME_VALIDATOR];
    if (this.isItemCode()) {
      validators.push(CandidateValidatorType.EXISTING_ITEM_CODE_VALIDATOR);
    } else if (this.isUPC()) {
      validators.push(CandidateValidatorType.EXISTING_UPC_VALIDATOR);
    }
    this.candidateService.validateCandidate(this.invitedDistributorService.getCandidate(), validators).toPromise().then(data => {
      this.candidateError = this.invitedDistributorService.setSetupAddDistributorDetailsError(new CandidateError());
      if (this.invitedDistributorService.getTaskId()) {
        this.invitedDistributorService.saveCandidateAndNavigate('/invitedDistributorCaseDetails',
          this.invitedDistributorService.hasVendorChanged());
      } else {
        if (this.invitedDistributorService.getCandidate().description) {
          this.invitedDistributorService.createCandidateAndNavigate(this.invitedDistributorService.getCandidate(),
            '/invitedDistributorCaseDetails');
        }
      }
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError =
          this.candidateError.candidateProductErrors[this.invitedDistributorService.getCurrentCandidateProduct().id];
        this.invitedDistributorService.setSetupAddDistributorDetailsError(this.candidateError);
      }
      this.isNextOrBackDisabled = false;
    });

  }
}
