import {WorkFlowEntity} from './workFlowEntity';

export class Task extends WorkFlowEntity {

  public static readonly PIA_NEW_PRODUCT_FLOW = 'PIA New Product Flow';
  public static readonly PHARM_NEW_PRODUCT_FLOW = 'PHARM New Product Flow';
  public static readonly KEY_VENDOR_DATA = 'Key Vendor Data';
  public static readonly KEY_BUYER_DATA = 'Key Buyer Data';
  public static readonly REVISE_VENDOR_DATA = 'Revise Vendor Data';
  public static readonly REVISE_BUYER_DATA = 'Revise Buyer Data';
  public static readonly ASSOCIATE_VENDOR_TASK_NAME = 'New Associate UPC';
  public static readonly DSD_INVITED_SUPPLIER_VENDOR_TASK_NAME = 'Dsd Invited Supplier';
  // PIA OB REG FLOW- 1st time PIA has task (submit vs activate)
  public static readonly PIA_OB_REG_FLOW_SUBMIT = 'PIA OB Reg Flow Submit';
  public static readonly PIA_OB_REG_FLOW_ACTIVATE = 'PIA OB Reg Flow Activate';
  public static readonly PIA_REVIEW_DSD_INVITED_SUPPLIERS = 'PIA Review Dsd Invited Suppliers';
  public static readonly BUYER_REVIEW_DSD_INVITED_SUPPLIERS = 'Buyer Review Dsd Invited Suppliers';
  public static readonly PIA_FINAL_REVIEW = 'PIA Final Review';
  public static readonly PIA_REVIEW_ASSOCIATE_UPC = 'Review Associate UPC';
  public static readonly KEY_OB_REG_DATA = 'Key OB Reg Data';
  public static readonly ASSIGN_WAREHOUSE = 'Assign Warehouse';

  // Task activities
  public static readonly VENDOR_DETAILS_ACTIVITY_ID = 'vendorDetails';
  public static readonly BUYER_DETAILS_ACTIVITY_ID = 'buyerDetails';
  public static readonly WAREHOUSE_DETAILS_ACTIVITY_ID = 'warehouseDetails';
  public static readonly PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID = 'piaReview';
  public static readonly PROCUREMENT_SUPPORT_NEW_PRODUCT = 'piaNewProductFlow';

  // variables related to Workflow REST service
  createTime: Date;
  name: string;
  variables: any[];
  displayName: string;

  // variables related to parent workflow
  createdBy: string;

  // variables related to linked candidate
  waitingForCandidateInformation: boolean;

  // variables related to updates
  hasUpdated: boolean;
  rushFlag: boolean;
}
