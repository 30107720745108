import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EditorComponent, InputState, UPCInputState} from 'pm-components';
import {
  AttachmentsCardPermissions,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  CaseDetailsCardPermissions,
  CostCardPermissions,
  CostLinkCardPermissions,
  DisplayReadyUnitCardPermissions,
  InnerPackDetailsCardPermissions,
  MasterPackDetailsCardPermissions,
  TaskDecision
} from 'pm-models';
import {GrowlService} from '../../growl/growl.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {CandidateService} from '../../service/candidate.service';
import {CostService} from '../../service/cost.service';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {SupplierBonusStepperComponent} from '../supplier-bonus-stepper/supplier-bonus-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-bonus-case-details',
  templateUrl: './bonus-case-details.component.html',
  styleUrls: ['./bonus-case-details.component.scss']
})
export class BonusCaseDetailsComponent implements OnInit {

  @ViewChild(EditorComponent) pmEditor;

  public isViewingPage = true;
  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions;

  readonly masterPackDetailsCardPermissions: MasterPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly innerPackDetailsCardPermissions: InnerPackDetailsCardPermissions = {
    isReadOnly: false
  };

  readonly displayReadyUnitCardPermissions: DisplayReadyUnitCardPermissions = {
    isReadOnly: false
  };
  readonly costLinkCardPermissions: CostLinkCardPermissions = {
    isReadOnly: false
  };
  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false
  };

  public costLinkState: InputState;

  attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };

  public canNavigate = true;
  upcState: UPCInputState;

  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public bonusSizeService: BonusSizeService, public costService: CostService,
              private candidateUtilService: CandidateUtilService) {
  }

  getAdditionalCasePackBasicsCardPermissions(): CaseDetailsCardPermissions {
    return {
      isReadOnly: false,
      itemWeightType: {
        isDisabled : !this.candidateUtilService.isTiedToCatchOrVariableWeightBuyer(this.bonusSizeService.getCandidate())
      },
      dsdSwitch: {
        isDisabled: true
      },
      warehouseSwitch: {
        isDisabled: true
      },
      buyer: {
        isHidden: true
      },
      warehouseReactionDays: {
        isHidden: true
      },
      guaranteeToStoreDays: {
        isHidden: true
      },
      dealOffered: {
        isHidden: true
      }
    };
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId,
        this.bonusSizeService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.BONUS_SIZE || candidate.candidateType === Candidate.REPLACEMENT_UPC) {
              this.bonusSizeService.currentCandidate = candidate;
              this.bonusSizeService.originalCandidate = JSON.parse(JSON.stringify(candidate));
              this.bonusSizeService.candidateError = this.bonusSizeService.bonusDetailsError;
              this.bonusSizeService.candidateProductError = new CandidateProductError();
              this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
              return this.bonusSizeService.setProductData();
            } else {
              return this.router.navigate(['/tasks']);
            }
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
              return this.bonusSizeService.setProductData();
            } else {
              this.bonusSizeService.resetService();
              return this.router.navigate(['/tasks']);
            }
          })
        ).subscribe();
      }
    });
  }

  validateAndSubmit(url) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.BONUS_CASE_PACK_DETAILS_VALIDATOR,  CandidateValidatorType.SUPPLIER_COMMENT_VALIDATOR])
      .toPromise().then(() => {
      this.bonusSizeService.saveAndCompleteTaskAndRouteToUrlWithCandidateId(WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_VENDOR_DATA_DECISION_NO, url, this.bonusSizeService.originalCandidate.candidateId);
    }, (error) => {

      this.bonusSizeService.scrollToTop();
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.bonusSizeService.candidateError = error.error.candidateErrors;
        this.bonusSizeService.candidateProductError =
          this.bonusSizeService.candidateError.candidateProductErrors[this.bonusSizeService.getCurrentCandidateProduct().id];
        this.bonusSizeService.caseDetailsError = this.bonusSizeService.candidateError;
      } else {
        this.growlService.addError(error.message);
      }
      this.canNavigate = true;
    });
  }

  onClickNext() {
    this.canNavigate = false;
    const urlToNavigate = this.bonusSizeService.isReplacementUpc ? 'replacementUpcExtendedAttributes' : 'bonusUpcExtendedAttributes';
    const validators = [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR, CandidateValidatorType.BONUS_SIZE_DETAILS_VALIDATOR,
      CandidateValidatorType.BONUS_CASE_PACK_DETAILS_VALIDATOR,  CandidateValidatorType.SUPPLIER_COMMENT_VALIDATOR];
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate, validators).subscribe(() => {
      this.bonusSizeService.caseDetailsError = new CandidateError();
      this.bonusSizeService.candidateError = this.bonusSizeService.caseDetailsError;
      this.bonusSizeService.candidateProductError = new CandidateProductError();
      this.bonusSizeService.saveCandidateAndNavigate(urlToNavigate, false);
    }, (error) => {
      this.bonusSizeService.scrollToTop();

      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.bonusSizeService.candidateError = error.error.candidateErrors;
        this.bonusSizeService.candidateProductError =
          this.bonusSizeService.candidateError.candidateProductErrors[this.bonusSizeService.getCurrentCandidateProduct().id];
        this.bonusSizeService.caseDetailsError = this.bonusSizeService.candidateError;

        this.bonusSizeService.updatePageErrors(error.error.candidateErrors);
      } else {
        this.growlService.addError(error.message);
      }
      this.canNavigate = true;
    });
  }

  onClickSubmit() {
    this.validateAndSubmit('/bonusReview');
  }

  onClickBack() {
    this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getSupplierUpcDetailsUrl(), false);
  }

  onClose() {
    this.isViewingPage = false;
    this.bonusSizeService.saveCandidateAndNavigate('/tasks', false);
  }

  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.bonusSizeService.currentCandidate.costLinkFromServer = undefined;
    this.bonusSizeService.candidateError.costLink = undefined;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((validatedCandidate) => {
      this.costLinkState = InputState.valid;
      this.costService.updateCostLinkRelatedValuesFromValidatedCandidate(this.bonusSizeService.getCandidate(), validatedCandidate);
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.bonusSizeService.candidateError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }


  showExistingCasePacks() {
    this.pmEditor.openDrawer();
  }

  collapse() {
    this.pmEditor.closeDrawer();
  }

  onClickCasePack(casePack) {
    casePack.isViewing = !casePack.isViewing;
  }

  validateUPC() {
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.bonusSizeService.getCurrentCandidateProduct().id;

    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.CASE_UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.bonusSizeService.candidateProductError.caseUpc = undefined;
    }, (error) => {
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError: CandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.bonusSizeService.candidateProductError.caseUpc = returnedCandidateProductError.caseUpc;
        this.bonusSizeService.candidateProductError.caseUpcCheckDigit = returnedCandidateProductError.caseUpcCheckDigit;
        this.bonusSizeService.caseDetailsError.itemWeightType = returnedCandidateError.itemWeightType;
        if (returnedCandidateProductError.caseUpc ||  returnedCandidateProductError.caseUpcCheckDigit) {
          this.upcState = UPCInputState.invalid;
        } else {
          this.bonusSizeService.caseDetailsError = returnedCandidateError;
          this.upcState = UPCInputState.valid;
        }
      } else {
        this.upcState = UPCInputState.invalid;
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  onInnerPackChange() {
    if (!this.bonusSizeService.currentCandidate.innerPackSelected) {
      this.bonusSizeService.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
    if (this.bonusSizeService.getCandidate() && this.bonusSizeService.getCandidate().costLinked
      && this.bonusSizeService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }

  innerPackQuantityChange() {
    if (this.bonusSizeService.candidateProductError && this.bonusSizeService.candidateProductError.caseUpc) {
      this.bonusSizeService.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case SupplierBonusStepperComponent.UPC_ITEM_CODE:
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getSupplierSetupUrl(), false);
        break;
      case SupplierBonusStepperComponent.BONUS_DETAILS:
      case SupplierBonusStepperComponent.REPLACEMENT_DETAILS:
        this.onClickBack();
        break;
      case SupplierBonusStepperComponent.EXTENDED_ATTRIBUTES: {
        this.onClickNext();
        break;
      }
    }
  }

  /**
   * Trigger cost link validation on master pack change.
   * @param event
   */
  onMasterPackChange(event) {
    if (this.bonusSizeService.getCandidate() && this.bonusSizeService.getCandidate().costLinked
      && this.bonusSizeService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }
}
