import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate, AttributeConfig } from 'pm-models';

export class CostLinked implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Cost linked',
    description:
      'Setting up a cost link lets you quickly manage cost changes for multiple linked items or cases in our Cost tool.',
      isRequired: false,
      isDisabled: () => false,
      isReadOnly: () => false,
      defaultValue: false,
      name: 'costLinkId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (candidate.costLinked === undefined) {
      candidate.costLinked = this.defaultVal;
    }
    componentInstance.model = candidate.costLinked;
    componentInstance.change.subscribe(x => {
      candidate.costLinked = x.checked;
      if (candidate.costLinked === false) {
        candidate.costLink = null;
        candidate.costLinkBy = '';
        candidate.costLinkFromServer = null;
      }
    });
  }
}
