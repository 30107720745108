import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class InnerPackQuantity implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Inner pack quantity',
      description: 'The total number of units in the inner pack.',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      textInputType: TextInputType.integer,
      placeholderText: '# Units',
      inputGroupClass: 'ui-narrow-input',
      name: 'innerPackQuantityId',
      maxLength: 3,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.innerPack;
    componentInstance.modelChange.subscribe(x => {
      candidate.innerPack = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.innerPack) {
      componentInstance.model = candidate.innerPack;
    }
  }
}
