import {Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output} from '@angular/core';
import {CandidateError} from 'pm-models';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-mrt-case-stepper',
  templateUrl: './mrt-case-stepper.component.html',
  styleUrls: ['./mrt-case-stepper.component.scss']
})
export class MrtCaseStepperComponent implements OnInit {


  private errorCandidateDiffer: KeyValueDiffer<string, any>;
  private errorCandidateProductDiffer: KeyValueDiffer<string, any>;
  private errorCandidateInnersDiffer: KeyValueDiffer<string, any>;

  private readonly errorToolTip = 'This page contains errors or is missing information in required fields.';
  private readonly errorIcon = 'warning';

  @Input()
  candidateErrors: CandidateError[];

  @Input()
  hasInnerCandidateError: Boolean[] = [];

  @Input()
  stepIndex: number;

  @Input()
  steps;

  @Output() indexClick = new EventEmitter<any>();

  modelSteps;
  hasInnerFlow: boolean;

  constructor(private differs: KeyValueDiffers, private permissionService: NgxPermissionsService) {}

  ngOnInit() {
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.hasInnerFlow = this.steps?.length === 5;
    } else {
      this.hasInnerFlow = this.steps?.length === 4;
    }
    this.modelSteps = this.steps;
  }

  /**
   * Change detection that compares candidate errors with previous version.
   * If the candidate differ doesn't exist, it creates it
   * Runs apply errors if any changes have been detected
   * @memberof CandidateCreationStepperComponent
   */
  ngDoCheck(): void {
    let candidateHasChanges = false;

    if (this.errorCandidateDiffer) {
      candidateHasChanges = !!this.errorCandidateDiffer.diff(this.candidateErrors);
    } else if (this.candidateErrors) {
      this.errorCandidateDiffer = this.differs.find(this.candidateErrors).create();
    }
    if (!candidateHasChanges && this.errorCandidateInnersDiffer) {
      candidateHasChanges = !!this.errorCandidateInnersDiffer.diff(this.hasInnerCandidateError);

    } else if (this.hasInnerCandidateError) {
      this.errorCandidateInnersDiffer = this.differs.find(this.hasInnerCandidateError).create();

    }
    if (candidateHasChanges) {
      this.applyErrors();
    }
  }

  /**
   * Runs through each step of menu and runs validation on each step
   *
   * @memberof CandidateCreationStepperComponent
   */
  applyErrors() {
    if (!this.modelSteps?.length) {
      return;
    }
    this.modelSteps.forEach((x, i) => {
      switch (i) {
        case 0:
          this.validateItems(x, this.candidateErrors[0]);
          break;
        case 1:
          // if there's an inner flow, the 2nd page is the new item setup.
          if (this.hasInnerFlow) {
            this.validateNewItemSetup(x, this.hasInnerCandidateError);
          } else {
            this.validateMrtCaseDetails(x, this.candidateErrors[1]);
          }
          break;
        case 2:
          // if there's an inner flow, the 3rd page is the case details page, else it's extended attributes, if applicable.
          if (this.hasInnerFlow) {
            this.validateMrtCaseDetails(x, this.candidateErrors[1]);
          } else {
            this.validateExtendedAttributes(x, this.candidateErrors[2]);
          }
          break;
        case 3:
          // if there's an inner flow, the 4th page is extended attributes, if applicable (else the review page).
          if (this.hasInnerFlow) {
            this.validateExtendedAttributes(x, this.candidateErrors[2]);
          }
          break;
      }
    });
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateItems(stepItem, candidateErrors) {
    const candidateKeys = ['description', 'lane'];
    const candidateProductKeys = [];
    const mrtInfoKeys = ['quantity', 'unitCost'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, mrtInfoKeys, candidateErrors);
  }
  /**
   * Validation for second screen screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateMrtCaseDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'buyer',
      'costOwner',
      'topToTop',
      'itemWeightType',
      'maxShelfLife',
      'inboundSpecDays',
      'masterWidth',
      'masterHeight',
      'masterLength',
      'masterWeight',
      'cubeAdjustedFactor',
      'vendorTie',
      'vendorTier',
      'displayReadyUnit',
      'displayReadyUnitRowsDeep',
      'displayReadyUnitRowsHigh',
      'displayReadyUnitRowsFacing',
      'displayReadyUnitOrientation',
      'masterListCost',
      'supplierComment'];
    const candidateProductKeys = [
      'caseUpc',
      'caseUpcCheckDigit',
      'caseDescription',
      'vendorProductCode',
      'countryOfOrigin'];
    const mrtInfoKeys = [];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, mrtInfoKeys, candidateErrors);
  }

  validateNewItemSetup(stepItem, hasInnerCandidateError) {
    if (hasInnerCandidateError[0]) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    }
  }

  validateExtendedAttributes(stepItem, candidateErrors) {
    this.validateStep(stepItem, [], [], [], candidateErrors, true);
  }

  /**
   * Validates step with associated keys, applies icon and error message if found
   *
   * @private
   * @param {*} stepItem - Menu Items
   * @param {*} candidateKeys - Candidate Error Keys associated with this step
   * @param {*} candidateProductKeys - Candidate Product Error Keys associated with this step
   * @memberof CandidateCreationStepperComponent
   */
  private validateStep(stepItem, candidateKeys, candidateProductKeys, mrtInnerKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!this.validateKeys(candidateKeys, candidateProductKeys, mrtInnerKeys, candidateErrors, validateMatAttributes)) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    }
  }

  /**
   * Checks each batch of keys to see if candidateErrors or candidate Product errors
   * contain any values. Immediate return false if error found for performance.
   *
   * @private
   * @param {*} candidateKeys
   * @param {*} candidateProductKeys
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private validateKeys(candidateKeys, candidateProductKeys, mrtInnerKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!candidateErrors) {
      return true;
    }
    for (let i = 0; i < candidateKeys.length; i++) {
      if (this.candidateErrors && this.fieldHasValue(candidateErrors[candidateKeys[i]])) {
        return false;
      }
    }
    for (let i = 0; i < candidateProductKeys.length; i++) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && this.fieldHasValue(value[candidateProductKeys[i]])) {
            return false;
          }
        }
      }
    }
    for (let i = 0; i < mrtInnerKeys.length; i++) {
      if (candidateErrors.mrtInfoError && candidateErrors.mrtInfoError.existingInnerErrors) {
        for (const [key, value] of Object.entries(candidateErrors.mrtInfoError.existingInnerErrors)) {
          if (value && this.fieldHasValue(value[mrtInnerKeys[i]])) {
            return false;
          }
        }
      }
      if (candidateErrors.mrtInfoError && candidateErrors.mrtInfoError.candidateInnerErrors) {
        for (const [key, value] of Object.entries(candidateErrors.mrtInfoError.candidateInnerErrors)) {
          if (value && this.fieldHasValue(value[mrtInnerKeys[i]])) {
            return false;
          }
        }
      }
    }

    if (validateMatAttributes) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && value['matAttributeErrors'] &&  Object.entries(value['matAttributeErrors']).length) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * Takes  a property and immediately checks if it has any sort of value
   * returns 'true' if any value is found or present.
   * @private
   * @param {*} field
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private fieldHasValue(field) {
    return field !== undefined && field !== null && field !== '';
  }

  click(event: any) {
    this.indexClick.emit(event);
  }
}

