<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pharm-new-product-flow-stepper *ngIf="candidate && !pharmProductService.isDsdOnly()"
                                      [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                      [stepIndex]="4"
                                      [isSellable]="candidateUtilService.isSellable(candidate)">
    </app-pharm-new-product-flow-stepper>

    <app-pharm-new-dsd-product-flow-stepper *ngIf="candidate && pharmProductService.isDsdOnly()"
                                          [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                          [stepIndex]="4"
                                          [isSellable]="candidateUtilService.isSellable(candidate)">
    </app-pharm-new-dsd-product-flow-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Case pack</pm-title>
  <ng-container *ngIf="candidate">
    <pm-master-pack-details-card *ngIf="!pharmProductService.isDsdOnly() ||
    (!candidateUtilService.isSellable(candidate) && !pharmProductService.isDsdOnly())" [(model)]="candidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="candidateError">
    </pm-master-pack-details-card>

    <pm-case-details-card [(candidateModel)]="candidate"
                          [(candidateProductModel)]="currentCandidateProduct"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="candidateError"
                          [candidateProductErrorModel]="candidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUpc()"
                          [setPharmacyCodeDateDefaults]="true"> <!-- TODO make dynamic for DSD-->
    </pm-case-details-card>

    <pm-cost-link-card [(model)]="candidate"
                       (validateCostLink)="validateCostLink()"
                       [costLinkState]="costLinkState"
                       [errorModel]="candidateError"
                       [permissions]="costLinkCardPermissions">
    </pm-cost-link-card>

    <pm-cost-card [(model)]="candidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="candidateError">
    </pm-cost-card>

    <pm-pricing-card *ngIf="isSellable"
                     [(model)]="candidate"
                     [permissions]="pricingCardPermissions"
                     [errorModel]="candidateError"
                     [retailLinkState]="retailLinkState"
                     (validateRetailLink)="validateRetailLink()"
                      [onlyShowSecondInputSuggestedRetail]="true">
    </pm-pricing-card>

    <pm-omi-remarks-card *ngIf="!pharmProductService.isDsdOnly()" [(model)]="currentCandidateProduct"
                         [permissions]="omiRemarksPermissions"
                         [errorModel]="candidateProductError">
    </pm-omi-remarks-card>

    <pm-attachments-card [(model)]="candidate"
                         [commentsPlaceholder]="'Add notes for future reference. This will only be visible in PAM.'"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="candidateError">
    </pm-attachments-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button *ngIf="pharmProductService.isDsdOnly()" class="m-2" label="Activate" (onClick)="onClickActivate()" [disabled]="!canClickSubmit"></p-button>
    <p-button *ngIf="!pharmProductService.isDsdOnly()"class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
