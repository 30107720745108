import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UUID} from 'angular2-uuid';
import {
  AttributeConfig, AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  Commodity,
  TaskDecision
} from 'pm-models';
import {GrowlService} from '../growl/growl.service';
import {LookupService} from '../service/lookup.service';
import {OwnBrandService} from '../service/ownbrand.service';
import {SupplierProductService} from '../service/supplier-product.service';
import {WorkflowService} from '../service/workflow.service';
import {calculateCheckDigit} from '../shared/upc.utils';
import {CandidateUtilService} from '../service/candidate-util.service';
import {FreightTaxUpcRequest} from 'pm-models/lib/freightTaxUpcRequest';

@Component({
  selector: 'app-pia-new-freight-tax-upc',
  templateUrl: './pia-new-freight-tax-upc.component.html',
  styleUrls: ['./pia-new-freight-tax-upc.component.scss']
})
export class PiaNewFreightTaxUpcComponent implements OnInit {

  private static DEFAULT_BUYER_ID = 2650;

  candidate: FreightTaxUpcRequest;
  candidateError: CandidateError;

  isViewingPage: boolean = true;

  upcType: string = null;
  upcLabel: string = 'Unknown UPC';

  commodities: any;
  subCommodities: any;

  showNoStoresError: boolean = false;

  canClickActivate: boolean = true;
  availableCostOwners: any;

  private PIA_NEW_PRODUCT_WORKFLOW = 'piaNewProductProcess';

  buyerConfiguration: AttributeTypeaheadConfig = {
    label: 'Who is the buyer?',
    description: '',
    isRequired: true,
    name: 'buyerTypeAhead',
    idRef: 'buyerId',
    displayRef: 'displayName',
    placeholderText: 'Type to search for a Buyer...',
    searchUrl: '/lookup/buyer',
    showAddlInfo: true
  };

  supplierConfiguration: AttributeTypeaheadConfig = {
    label: 'Which supplier is this UPC for?',
    description: '',
    isRequired: true,
    name: 'vendorTypeAhead',
    idRef: 'vendorId',
    displayRef: 'displayName',
    placeholderText: 'Select the supplier...',
    searchUrl: '/lookup/vendordsd',
    showAddlInfo: true
  };

  vendorProductCodeConfiguration: AttributeTextInputConfig = {
    label: 'VPC',
    description: 'Enter the manufacturer or vendor product code.',
    placeholderText: 'Enter VPC...',
    isRequired: true,
    name: 'vpcId',
    maxLength: 20
  };

  costOwnerConfiguration: AttributeTypeaheadConfig = {
    label: 'Cost owner',
    description: 'Select who is responsible for the cost of the product.',
    name: 'costOwnerId',
    isRequired: true,
    collections: this.availableCostOwners,
    displayRef: 'displayName',
    placeholderText: 'Select or search for a cost owner...',
  };

  topToTopConfiguration: AttributeConfig = {
    label: 'Top to Top',
    isRequired: true,
    inputGroupClass: 'typeahead-width',
    isReadOnly: () => true
  };

  commodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Commodity',
    isRequired: true,
    isDisabled: () => !this.candidate.buyer,
    isReadOnly: () => false,
    name: 'commodityId',
    displayRef: 'commodityName',
    placeholderText: 'Select a commodity...',
    collections: this.commodities
  };

  subCommodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-commodity',
    isRequired: true,
    isDisabled: () => !this.candidate.buyer || !this.candidate.commodity,
    isReadOnly: () => false,
    name: 'subcommodityId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-commodity...',
    collections: this.subCommodities
  };

  constructor(private ownBrandService: OwnBrandService,
              private growlService: GrowlService,
              private router: Router,
              private lookupService: LookupService,
              private workflowService: WorkflowService,
              public supplierProductService: SupplierProductService,
              public candidateUtilService: CandidateUtilService) { }

  ngOnInit() {
    this.resetErrors();
    this.initializeProduct();
    this.setDefaultBrand();

    this.ownBrandService.generate04NonSellableUPC().subscribe((upc) => {
      this.candidate.candidateProducts[0].upc = upc;
      this.candidate.candidateProducts[0].upcCheckDigit = calculateCheckDigit(upc);
      this.candidate.candidateProducts[0].generatedUpc = true;
      this.candidate.candidateProducts[0].id = UUID.UUID();
    }, (error) => {
      this.growlService.addError('Unable to reserve a pre-digit four UPC. Error:' + error.message);
    });
  }

  initializeProduct() {
    this.candidate = {
      vendor: null,
      buyer: null,
      brand: null,
      costOwner: null,
      candidateProducts: [],
      commodity: null,
      subCommodity: null,
      masterListCost: null,
      pssDepartment: null,
      vertexTaxCategoryModel: null,
      vertexTaxCategory: null,
      taxable: null,
      isFreightOrTaxUpc: true
    };

    const product1: CandidateProduct = new CandidateProduct();
    product1.upc = null;
    product1.upcCheckDigit = null;
    this.candidate.candidateProducts.push(product1);
  }

  setDefaultBrand() {
    this.lookupService.findBrandsById([PiaNewFreightTaxUpcComponent.DEFAULT_BUYER_ID]).subscribe(data => {
      this.candidate.brand = data[0];
      this.availableCostOwners = data[0].costOwners;
      this.costOwnerConfiguration.collections = this.availableCostOwners;
    });
  }

  onBuyerChange(event) {
    this.candidate.buyer = event;
    this.candidate.commodity = null;
    this.candidate.subCommodity = null;
    this.candidate.pssDepartment = null;
    this.showNoStoresError = false;

    if (this.candidate.buyer && this.candidate.buyer.buyerId) {
      this.setCommoditiesAndSubCommodities(this.candidate.buyer.buyerId);
    }
  }

  onSupplierChange(event) {
    this.candidate.buyer = null;
    this.candidate.commodity = null;
    this.candidate.subCommodity = null;
    this.candidate.pssDepartment = null;
    this.showNoStoresError = false;
    this.candidate.vendor = event;
  }

  costOwnerChange($event) {
    this.candidate.costOwner = $event;
  }

  get topToTopModel(): string {
    if (this.candidate?.costOwner) {
      return this.candidate?.costOwner?.topToTopDisplayName;
    }
    return '';
  }

  onClickActivate() {
    this.resetErrors();

    this.canClickActivate = false;

    this.ownBrandService.activateFreightTaxUpc(this.upcType, this.candidate).subscribe((candidate: Candidate) => {
      this.workflowService.createProcessInstanceWithCandidateId(candidate.candidateId, this.PIA_NEW_PRODUCT_WORKFLOW)
        .subscribe((taskId) => {
          this.completeTaskAndRouteToTasksPage(
            candidate, taskId,
            WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION, 'Successfully activated candidate.'
          );
        }, (error) => {
          this.isViewingPage = true;
          this.canClickActivate = true;
          if (error.error.candidateErrors) {
            this.candidateError = error.error.candidateErrors;
          } else {
            this.growlService.addError(error.message);
          }
        });
    }, (error) => {
      this.isViewingPage = true;
      this.canClickActivate = true;
      if (error.error.candidateErrors) {
        this.candidateError = error.error.candidateErrors;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  /**
   * Complete Task and Route to Task Page but the method is meant for PIA Only.
   * @param candidate
   * @param taskId
   * @param action
   * @param taskDecision
   * @param growlMessage
   * @private
   */
  private completeTaskAndRouteToTasksPage(candidate: Candidate, taskId: string,
                                          action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskByTaskIdWithActionForPiaOnlyFlow(
      candidate.vendor.apNumber, taskId, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( () => {
          this.canClickActivate = true;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.canClickActivate = true;
      });
  }

  onClose() {
    this.isViewingPage = false;
    this.resetErrors();
    this.router.navigate(['/tasks']).then();
  }

  resetErrors() {
    this.candidateError = new CandidateError();
  }

  onClickFreight() {
    this.upcLabel = 'Freight UPC';
  }

  onClickTax() {
    this.upcLabel = 'Tax UPC';
  }

  commodityChange(event) {
    this.candidate.commodity = event;

    // Initialize pssDepartment to the default pss department from commodity
    if (this.candidate.commodity) {
      this.candidate.pssDepartment = this.candidate.commodity.pssDepartment;
    }

    this.candidate.subCommodity = null;

    if (this.candidate.vendor && this.candidate.commodity) {
      this.supplierProductService.findAllAuthGroupsByParms
        (this.candidate.vendor.apNumber, this.candidate.commodity.departmentId, this.candidate.commodity.subDepartmentId)
        .subscribe((authGroups) => {
          this.supplierProductService.setAuthGroups(authGroups);
          this.supplierProductService.setSelectedAuthGroups(null);
          this.showNoStoresError = this.supplierProductService.validateHasNoStores();
          this.candidate.candidateProducts[0].locationGroupStores = authGroups;
      });
    } else {
      this.supplierProductService.setAuthGroups(null);
      this.supplierProductService.setSelectedAuthGroups(null);
      this.showNoStoresError = false;
      this.candidate.candidateProducts[0].locationGroupStores = [];
    }
    this.getSubCommodities(event);
  }

  subCommodityChange(event) {
    this.candidate.subCommodity = event;
    this.candidate.taxable = CandidateHelper.isTaxCode(this.candidate);
    this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe((candidate) => {
      this.candidate = candidate;
    });
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);

        // Initialize pssDepartment to the default pss department from commodity
        if (commodity) {
          this.candidate.pssDepartment = this.candidate.commodity.pssDepartment;
        }

      } else {
        this.subCommodities = [];
        this.subCommodityConfiguration.collections = this.subCommodities;
      }
    });
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    if (e) {
      this.subCommodities = e.subCommodityList;
      this.subCommodityConfiguration.collections = this.subCommodities;
    }
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  areAllRequiredFieldsFilledOut(): boolean {
    return (this.upcType && this.candidate.commodity && this.candidate.subCommodity && this.candidate.buyer &&
      this.candidate.candidateProducts[0].upc && this.candidate.candidateProducts[0].upcCheckDigit &&
      this.candidate.pssDepartment && this.candidate.masterListCost && this.candidate.vendor &&
      this.candidate.candidateProducts[0].vendorProductCode && this.candidate.costOwner &&
      this.showNoStoresError === false && this.canClickActivate);
  }

  getMasterListCostClass() {
    if (this.candidateError.masterListCost) {
      return 'red-border';
    } else {
      return '';
    }
  }
}
