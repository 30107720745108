import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductImagesCardComponent } from './product-images-card.component';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import { CardModule } from '../../shared/card/card.module';

@NgModule({
  declarations: [ProductImagesCardComponent],
  imports: [CommonModule, AttributeDisplayModule, CardModule],
  exports: [AttributeDisplayModule, CardModule, ProductImagesCardComponent]
})
export class ProductImagesCardModule {}
