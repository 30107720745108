import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, CandidateProduct, Candidate } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class ShelfTag1 implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Shelf tag line 1',
      description: `Enter what will appear on the shelf tag in the store.`,
      placeholderText: 'Description',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      name: 'shelfTagLine1Id',
      charLimit: 30,
      ...overrides
    };
  }

  setupComponent(
    componentInstance: AttributeDisplayTextinputComponent,
    candidateProduct: CandidateProduct,
    configOverrides: any = {}
  ) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidateProduct.customerFriendlyDescription1;
    componentInstance.modelChange.subscribe(x => {
      candidateProduct.customerFriendlyDescription1 = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidateProduct: CandidateProduct) {
    if (candidateProduct.customerFriendlyDescription1 !== componentInstance.model) {
      componentInstance.model = candidateProduct.customerFriendlyDescription1;
    }
  }
}
