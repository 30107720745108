import {Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {AutoComplete} from 'primeng/autocomplete';
import {from, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AttributeConfig, MatHierarchy} from 'pm-models';

@Injectable({ providedIn: 'root' })
export class CategorySelectionService {
  constructor(private http: HttpClient) {}

  search(query: string, url: string, lowestMatHierarchyNode: MatHierarchy, exactSearch: boolean) {
    const currentUrl = query ? url + '/search' : url;
    const currentParams = {};

    if (lowestMatHierarchyNode && lowestMatHierarchyNode.lowestLevel) {
      return of([]);
    }

    if (query) {
      currentParams['query'] = query;

      // if the user is filtering after a selection, search all descendents of the parent id.
      if (lowestMatHierarchyNode) {
        currentParams['parentHierarchyId'] = lowestMatHierarchyNode.matHierarchyId;
      }
      // if the user isn't filtering and there's already a selection, find all children of the parent hierarchy id in the next node level.
    } else if (lowestMatHierarchyNode) {
      currentParams['parentHierarchyId'] = lowestMatHierarchyNode.matHierarchyId;
      currentParams['nodeLevel'] = lowestMatHierarchyNode.hierarchyLevel + 1;
    }

    if (exactSearch) {
      currentParams['exactSearch'] = exactSearch;
    }

    return this.http
      .get<any>(currentUrl, { params: currentParams })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}

@Component({
  selector: 'pm-category-selection',
  templateUrl: './category-selection.component.html',
  styleUrls: ['./category-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategorySelectionComponent implements OnInit {

  public readonly PLACEHOLDER_TEXT = 'Select or search for a category...';
  public readonly DISPLAY_REF = 'name';
  private readonly DEFAULT_STYLE_CLASS = '';
  private readonly ERROR_STYLE_CLASS = 'ui-input-invalid';
  public readonly HIERARCHY_DISPLAY_REF = 'parentHierarchyDisplayName';
  private readonly BASE_HIERARCHY_URL = '/lookup/mat/hierarchy';
  private readonly RESULTS_MESSAGE = 'RESULTS';
  private readonly ALL_CATEGORIES_MESSAGE = 'ALL CATEGORIES';
  private readonly ALL_SUBCATEGORIES_MESSAGE = 'ALL SUBCATEGORIES';
  private readonly NO_CATEGORIES_MESSAGE = 'No categories match your search. Find the best match by starting one of these categories:';
  private readonly NO_SUB_CATEGORIES_MESSAGE =
    'No subcategories match your search. Find the best match by selecting one of these subcategories:';


  @ViewChild('categorySelection') categorySelection: AutoComplete;

  @Input()
  public attribute?: AttributeConfig;

  @Input() isDisabled: boolean;

  // Whether an error exists or not.
  @Input() attributeError;

  // Time in milliseconds
  @Input() debounceTime: any = 300;

  // The tab index.
  @Input() tabIndex: number;

  // Object model that's acted upon selection.
  _model: MatHierarchy[] = [];

  @Input()
  get model(): MatHierarchy[] {
    return this._model;
  }

  set model(e: MatHierarchy[]) {
    // Only apply funcitonality if model changes.
    if (this._model !== e) {
      this._model = e;
      // Only apply ui updates after component is initialized.
      if (this.initialized) {
        this.updateUI(e);
        this.modelUpdated();
      }
    }
  }


  setModel(e: MatHierarchy[]) {
    // Only apply funcitonality if model changes.
    if (this._model !== e) {
      this._model = e;
      // Only apply ui updates after component is initialized.
      if (this.initialized) {
        this.updateUI(e);
        this.modelUpdated();
      }
    }
  }


  // Emitter used for initialization.
  @Output() initCallBack = new EventEmitter<any>();

  // Emitter used when a selection is made.
  @Output() selection = new EventEmitter<any>();

  private exactSearch = false;
  private isSelected: boolean;
  public hasNoResultsMessage;
  inputEvent: Event = new Event('input');

  displayModel: any;

  idRef = null;
  options = [];
  initialized = false;
  internalModelUpdate = false;
  searchFailed = false;
  originalMatHierarchyList: MatHierarchy[];

  constructor(private service: CategorySelectionService) { }

  ngOnInit() {
    if (!this.attribute) {
      this.attribute = {
        label: 'Select this product\'s categories.',
        isRequired: false
      };
    }

    this.isSelected = false;

    // Apply initial values. If we are showing detailed selection or a pending value, we put it in an array.
    if (this.model) {
      this.originalMatHierarchyList = this.model;
      this.updateUI(this.model);
    }

    this.initialized = true;

    this.doInitMultiSearch();
  }


  /**
   * Directs the input to a static search or search by url.
   *
   * @param {Observable<string>} event
   * @returns {Observable<any>}
   */
  search(event) {
    const queryObservable = of(event.query);
    this.apiSearch(queryObservable).subscribe(results => {
      this.options = results;
    });
  }

  /**
   * Search function for ngb typeahead. Takes the passed in URL and Param object to search by Term in input field.
   * @param {Observable<string>} text$
   * @returns {Observable<any>}
   */
  apiSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      switchMap(query => {
        query = query && query.trim ? query.trim() : query;
        const lowestMatHierarchyNode = this.getLowestMatHierarchyNode();
        return this.callSearchService(query, lowestMatHierarchyNode).pipe(
          switchMap((results) => {
            return this.updateResultsListWithResultMessage(query, results);
          })
        );
      })
    )

  callSearchService(query, lowestMatHierarchyNode) {
    return this.service.search(query, this.BASE_HIERARCHY_URL, lowestMatHierarchyNode, this.exactSearch).pipe(
      tap(results => {
        this.searchFailed = false;
        if (this.exactSearch && results.length > 0) {
          this.findExactMatchInResults(results);
        }
        this.exactSearch = false;
      }),
      catchError(() => {
        this.searchFailed = true;
        this.exactSearch = false;
        return of([]);
      })
    );
  }

  updateResultsListWithResultMessage(query, results: MatHierarchy[]): Observable<any> {
    this.hasNoResultsMessage = null;

    const resultMessageSelection: MatHierarchy = { matHierarchyId: -1, hierarchyLevel: 0, lowestLevel: false, name: '', description: ''};

    if (results && results.length > 0) {
      if (!this.model || this.model.length === 0) {
        resultMessageSelection.name = query ? this.RESULTS_MESSAGE : this.ALL_CATEGORIES_MESSAGE;
      } else {
        resultMessageSelection.name = query ? this.RESULTS_MESSAGE : this.ALL_SUBCATEGORIES_MESSAGE;
      }
      results.unshift(resultMessageSelection);
      return of(results);
    } else if (this.hasCategory()) {
      return of([]);
    }

    return this.callSearchService('', this.getLowestMatHierarchyNode()).pipe(
      switchMap((findAllResults) => {
        if (!findAllResults || findAllResults.length === 0) {
          this.hasNoResultsMessage = false;
          resultMessageSelection.name = 'No Results';
          return of([resultMessageSelection]);
        } else {
          if (!this.model || this.model.length === 0) {
            this.hasNoResultsMessage = this.ALL_CATEGORIES_MESSAGE;
            resultMessageSelection.name = this.NO_CATEGORIES_MESSAGE;
          } else {
            this.hasNoResultsMessage = this.ALL_SUBCATEGORIES_MESSAGE;
            resultMessageSelection.name = this.NO_SUB_CATEGORIES_MESSAGE;
          }
          findAllResults.unshift(resultMessageSelection);
          return of(findAllResults);
        }
      })
    );
  }

  private findExactMatchInResults(results: any[]) {
    const result = this.findAndReturnExactMatchInResults(results);
    if (result) {
      this.updateUI(result);
      this.initCallBack.emit(result);
    }
  }


  private findAndReturnExactMatchInResults(results: any[]) {
    for (let index = 0; index < results.length; index++) {
      // todo remove?
      if (!Array.isArray(this.model)) {
        const found = this.findMatchInObject(results, index, this.model);
        if (found) {
          return found;
        }
      } else {
        for (let modelIndex = 0; modelIndex < this.model.length; modelIndex++) {
          const found = this.findMatchInObject(results, index, this.model[modelIndex]);
          if (found) {
            return found;
          }
        }
      }
    }
  }


  private findMatchInObject(results, index, model) {
    if (results[index][this.idRef] === model[this.idRef] || results[index][this.idRef] === model) {
      return results[index];
    }
  }


  private updateUI(value) {
    this.displayModel = value;
  }

  private modelUpdated() {
    if (this.internalModelUpdate) {
      this.internalModelUpdate = false;
    } else {
      // Do initial search if it's an external update.
      this.doInitMultiSearch();
    }
  }

  private doInitMultiSearch() {
    // todo remove is array?
    if (this.idRef && this.model && Array.isArray(this.model)) {
      const queries = [];
      this.model.forEach(x => {
        if (x[this.idRef]) {
          queries.push(x[this.idRef]);
        }
      });
      this.apiMultiSearch(queries);
    }
  }

  apiMultiSearch(text: string[]) {
    const searchResults = from(text).pipe(
      mergeMap(query => {
        query = query && query.trim ? query.trim() : query;
        return this.service.search(query, this.BASE_HIERARCHY_URL, this.getLowestMatHierarchyNode(), true).pipe(
          map(results => {
            if (results.length > 0) {
              return this.findAndReturnExactMatchInResults(results);
            }
            return results;
          }),
          catchError(() => {
            return of([]);
          })
        );
      })
    );

    // Loop through results and update UI when done
    let allResults = [];
    searchResults.subscribe(
      results => {
        allResults = allResults.concat(results);
      },
      err => {},
      () => {
        this.updateUI(allResults);
      }
    );
  }

  getStyleClass() {
    let styleClass = '';
    if (this.attributeError) {
      styleClass = styleClass.concat(this.ERROR_STYLE_CLASS);
    }
    return styleClass;
  }


  /**
   * Handle focus of type ahead.
   */
  onFocus() {
    setTimeout(() => {
      // this was added to fix a bug when user selects a value that re-opened the dropdown
      if (this.isSelected) {
        this.isSelected = false;
        return;
      }

      this.categorySelection.handleDropdownClick(this.inputEvent);
    });
  }

  /**
   * Returns the input style for the class. This will always return 'full-width', with an additional 'has-error' when there is an error.
   *
   * @returns {boolean}
   */
  getInputStyleClass() {
    return this.DEFAULT_STYLE_CLASS;
  }

  onModelChange($event) {
    // Flag model update is internal to prevent side effects.
    this.internalModelUpdate = true;
    this.model = $event;
  }


  /**
   * Handle type ahead selection.
   * @param selectedItem Item that was selected.
   */
  onSelect(selectedItem: MatHierarchy) {
    this.isSelected = true;
    this.internalModelUpdate = true;

    // if the user selects a stub value, e.g. results message return after performing normal drop down handling.
    if (selectedItem.matHierarchyId === -1) {
      this.setModel(this.model.filter((item) => item.matHierarchyId !== -1));
      this.categorySelection.handleDropdownClick(this.inputEvent);
      return;
    }

    if (selectedItem.hierarchyLevel !== this.model.length) {

      const newList: MatHierarchy[] = [];
      let matHierarchy: MatHierarchy = selectedItem;

      while (matHierarchy) {
        newList.push(matHierarchy);
        matHierarchy = matHierarchy.parentMatHierarchy;
      }
      newList.sort((val1, val2) => val1.hierarchyLevel - val2.hierarchyLevel);
      this.setModel(newList);
    }
    this.overlaySelection();
    this.selection.emit(this.model);
    if (selectedItem && !selectedItem.lowestLevel) {
      this.categorySelection.inputClick = true;
      this.search('');
    }
  }

  overlaySelection() {
    if (!this.model || !this.model.length || !this.originalMatHierarchyList || !this.originalMatHierarchyList.length) {
      return;
    }
    for (const currentHierarchy of this.model) {
      const previousHierarchy = this.originalMatHierarchyList.find((originalHierarchy) =>
        originalHierarchy.matHierarchyId === currentHierarchy.matHierarchyId);

      // if the hierarchy was previously selected at one point, repopulate the attributes.
      if (previousHierarchy) {
        currentHierarchy.attributes = JSON.parse(JSON.stringify(previousHierarchy.attributes || []));
      }
    }
  }

  /**
   * Handle clear of type ahead.
   */
  onClear(event: MatHierarchy) {
    this.internalModelUpdate = true;
    this.setModel(this.model.filter(node => node.hierarchyLevel <= event.hierarchyLevel));
    this.selection.emit(this.model);
    this.categorySelection.hide();
  }

  /**
   * If the user has not selected a drop down item, then clear any text they may have entered.
   */
  onBlur() {
    // todo: remove?
    if (typeof this.displayModel === 'string') {
      this.model = [];
      this.displayModel = '';
      this.selection.emit(null);
    }
  }

  getLowestMatHierarchyNode(): MatHierarchy {
    if (!this.model || this.model.length === 0) {
      return null;
    }
    return this.model.reduce(function(prev, current) {
      return (prev.hierarchyLevel > current.hierarchyLevel) ? prev : current;
    });
  }

  hasCategory(): boolean {
    const matHierarchy = this.getLowestMatHierarchyNode();
    return !matHierarchy ? false : matHierarchy.lowestLevel;
  }

  getDisplayClass(): string {
    let classString = 'ui-autocomplete-hierarchy';

    if (this.hasCategory()) {
      classString += ' hide-last-node-caret';
    } else {
      classString += ' show-last-node-caret';
    }
    return classString;
  }
}
