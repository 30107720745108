import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeTextInputConfig, AttributeTypeaheadConfig, Code, MatHierarchy, TextInputType} from 'pm-models';
import {EmailService} from '../../../../../../src/app/2.0.0/service/email.service';

export class RequestNewMatAttributeFormModel {
  attributeFieldId: string;
  attributeBusinessFriendlyDescription?: string;
  matHierarchy?: MatHierarchy;
  attributeTypeaheadConfig: AttributeTypeaheadConfig;
  newMatAttributeValue: string;
  apNumber?: number;
  email?: string;
  notes?: string;
  currentValue?: Code[];
}

@Component({
  selector: 'pm-request-new-mat-attribute-form',
  templateUrl: './request-new-mat-attribute-form.component.html',
  styleUrls: ['./request-new-mat-attribute-form.component.scss']
})
export class RequestNewMatAttributeFormComponent implements OnInit {


  @Input()
  model: RequestNewMatAttributeFormModel;

  @Output() modelChange = new EventEmitter<any>();

  @Output()
  sendRequestClicked = new EventEmitter<RequestNewMatAttributeFormModel>();

  @Output()
  cancelClicked = new EventEmitter<void>();


  title: string;
  subTitle: string;
  emailError: string;

  matAttributeValueConfiguration: AttributeTextInputConfig;

  supplierConfiguration: AttributeTextInputConfig = {
    label: 'Enter your H-E-B Supplier # (optional).',
    placeholderText: 'Enter H-E-B Supplier #...',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 38,
    numberCount: 38,
    tabIndex: 2
  };

  emailConfiguration: AttributeTextInputConfig = {
    label: 'Provide an additional email for notifications (optional).',
    placeholderText: 'Enter email address...',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.text,
    tabIndex: 3
  };

  notesConfiguration: AttributeTextInputConfig = {
    label: 'Add details about your request (optional).',
    placeholderText: 'Add notes for reviewer...',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea,
    tabIndex: 4
  };


  constructor() { }

  ngOnInit(): void {
    this.title = 'Request a new ' + this.model?.attributeBusinessFriendlyDescription + ' value';
    this.subTitle = 'Fill out the fields below to request a new ' + this.model?.attributeBusinessFriendlyDescription + ' value';
    this.setMatAttributeValueConfiguration();
  }



  cancel() {
    this.cancelClicked.emit();
  }

  sendRequest() {
    this.sendRequestClicked.emit(this.model);
  }

  setMatAttributeValueConfiguration() {
    this.matAttributeValueConfiguration = {
      label: 'Enter the name of the ' + this.model.attributeBusinessFriendlyDescription + '.',
      placeholderText: 'Enter ' + this.model.attributeBusinessFriendlyDescription + '...',
      isDisabled: () => false,
      isReadOnly: () => false,
      name: '',
      textInputType: TextInputType.text,
      isRequired: true,
      tabIndex: 1
    };
  }
  onEmailChange(event) {
    if (!this.model?.email || EmailService.isValidEmail(event)) {
      this.emailError = null;
    } else {
      this.emailError = 'Please enter a valid email address.';
    }
  }

  isSendRequestDisabled() {
    return !this.model?.newMatAttributeValue || !!this.emailError;
  }
}
