<p-confirmDialog width="425" key="cd1" #cd1>
  <p-footer>
    <button type="button" pButton id="confirm1" label="OK" style="background-color: green;" (click)="cd1.accept()"></button>
    <button type="button" pButton id="reject1" label="Cancel" style="background-color: red;" (click)="cd1.reject()"></button>
  </p-footer>
</p-confirmDialog>

<p-tabView [activeIndex]="tabIndex" (onChange)="changeTab($event)">
  <p-tabPanel header="{{OPEN_TAB.title}}" tooltip="{{OPEN_TAB.tooltip}}">
    <div class="object-manager-page">

      <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

      <pm-task-viewer-table [hidden]="showSpinner" [tasks]="tasks"
                            [lazyLoadOn]="true"
                            [totalNumRecords]="totalNumRecords"
                            [pageSize]="taskListService.PAGE_SIZE"
                            (taskClicked)="onClickRow($event)"
                            (loadData)="onLoadData($event)"
                            [rushFlag]="true"
                            (deleteClicked)="deleteCandidateById($event)"
                            [bulkUpdate]="hasBulkActionPermission"
                            (bulkReview)="onClickBulkEdit($event)"
                            [isPia]="true">
      </pm-task-viewer-table>
    </div>

  </p-tabPanel>

  <p-tabPanel header="{{REJECTED_TAB.title}}" tooltip="{{REJECTED_TAB.tooltip}}">
    <div class="object-manager-page">

      <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

      <pm-task-viewer-table-rejected [hidden]="showSpinner" [tasks]="rejectedTasks"
                            [lazyLoadOn]="true"
                            [totalNumRecords]="rejectedTotalNumRecords"
                            [pageSize]="taskListService.PAGE_SIZE"
                            (taskClicked)="onClickRowApprovedDeclined($event)"
                            (loadData)="onLoadDataRejected($event)">
      </pm-task-viewer-table-rejected>
    </div>
  </p-tabPanel>

  <p-tabPanel header="{{ACTIVATED_TAB.title}}" tooltip="{{ACTIVATED_TAB.tooltip}}">
    <div class="object-manager-page">

      <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

      <pm-task-viewer-table-closed [hidden]="showSpinner" [tasks]="approvedTasks"
                            [lazyLoadOn]="true"
                            [totalNumRecords]="approvedTotalNumRecords"
                            [pageSize]="taskListService.PAGE_SIZE"
                            (taskClicked)="onClickRowApprovedDeclined($event)"
                            (loadData)="onLoadDataApproved($event)">
      </pm-task-viewer-table-closed>
    </div>
  </p-tabPanel>

</p-tabView>
