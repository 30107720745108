<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">

  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label">
      <label for="">{{attribute.label}}</label>
    </div>

    <div class="attribute-control">
      <div class="attribute-upc">
        <div class="ui-inputgroup" [ngClass]="{'ui-upc-invalid' : attributeError}">
          <input type="text" pInputText class="upc-input" placeholder="{{attribute.placeholderText}}"
                 appOnlyNumber
            (blur)="upcBlur($event)" (keydown)="upcKeyDown($event)"
            [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
            [disabled]="attribute.isDisabled && attribute.isDisabled()"
            [ngClass]="{'ui-inputtext-invalid' : attributeError}" [ngModel]="modelDisplay"
            (ngModelChange)="upcChange($event)" [ngModelOptions]="{updateOn:'blur'}" maxlength="13">
          <span class="ui-inputgroup-addon"
            *ngIf="!(attribute.isReadOnly && attribute.isReadOnly()) && !(attribute.isDisabled && attribute.isDisabled())">
            <i class="pi pi-spin pi-spinner" *ngIf="state===1"></i>
            <i class="material-icons ui-success" *ngIf="state === 2">check_circle_outline</i>
            <i class="material-icons ui-invalid rotate-45" *ngIf="state === 3">add_circle_outline</i>
          </span>
        </div>
        <input type="text" id="unitUpcCheckDigit" pInputText [(ngModel)]="checkDigitModel"
               appOnlyNumber
          placeholder="{{attribute.checkDigitPlaceholderText}}" (keydown)="checkDigitKeyDown($event)"
          class="upc-check-digit-input" (blur)="upcBlur($event)"
          [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
          [disabled]="attribute.isDisabled && attribute.isDisabled()"
          [ngClass]="{'ui-inputtext-invalid' : attributeError}" maxlength="1">
      </div>

      <p *ngIf="attribute.description" class="attribute-description">

        {{attribute.description}}
      </p>
      <p *ngIf="attributeError" class="attribute-error-message">
        {{attributeError}}
      </p>
    </div>
  </div>
</ng-container>
