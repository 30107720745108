<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4 helper-col">
    <div *ngIf="descriptionHelpVisible" class="helper-image" [style.top]="descriptionHelpOffset">
      <pm-card>
        <h3>Receipt</h3>
        <img alt="HEB Receipt" src="assets/images/heb-receipt.png" />
      </pm-card>
    </div>
    <div *ngIf="st1HelpVisible" class="helper-image" [style.top]="st1HelperOffset">
      <pm-card>
        <h3>Shelf tag</h3>
        <img alt="HEB Shelf Tag" src="assets/images/heb-shelftag.png" />
      </pm-card>
    </div>
  </div>
</div>

<ng-template #card>
  <pm-card title="Descriptions" subtitle="Provide the product descriptions that customers will see.">
    <ng-container *ngIf="model">
      <pm-attribute-display-text-input
        [attribute]="descriptionConfiguration1"
        [(model)]="model.description"
        [attributeError]="errorModel?.description"
        (change)="descriptionChange($event)"
        (modelChange)="updateReceiptModel() || checkDescriptionLength()">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input
        [attribute]="descriptionConfiguration2"
        [(model)]="receiptModel">
      </pm-attribute-display-text-input>

      <div class="row pb-4">
        <button class="btn btn-link py-0 pr-0 m-0" style="padding-left: 170px;" (click)="toggleDescriptionHelp($event)">{{descriptionHelpVisible ? 'Hide example.' : 'Show example.'}}</button>
      </div>

      <pm-attribute-display-text-input [attribute]="st1Configuration" [(model)]="model.customerFriendlyDescription1"
        [attributeError]="errorModel?.customerFriendlyDescription1" (change)="st1Change($event)"
        (inputFocus)="toggleSt1Help($event, true)" (inputBlur)="toggleSt1Help($event, false)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="st2Configuration" [(model)]="model.customerFriendlyDescription2"
        [attributeError]="errorModel?.customerFriendlyDescription2" (change)="st2Change($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="ecommCopyConfiguration" [(model)]="model.romanceCopy"
        [attributeError]="errorModel?.romanceCopy" (change)="ecommChange($event)">
      </pm-attribute-display-text-input>
    </ng-container>
  </pm-card>
</ng-template>
