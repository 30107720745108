<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4 helper-col">

  </div>
</div>

<ng-template #card>
  <pm-card title="Additional Information"
    subtitle="Optionally provide additional comments, instructions, or documents.">
    <ng-container *ngIf="model">
      <b>Attachments</b>
      <pm-attribute-display-attachment-upload [attribute]="attributeUploadConfiguration" [(model)]="model.attachments"
        [attributeError]="errorModel?.attachments" (change)="attachmentsChange($event)">
      </pm-attribute-display-attachment-upload>

      <br>
      <b>Comments</b>
      <pm-attribute-display-text-input [attribute]="supplierCommentConfiguration" [(model)]="model.supplierComment"
        [attributeError]="errorModel?.supplierComment" (change)="supplierCommentChange($event)">
    </pm-attribute-display-text-input>
    </ng-container>

  </pm-card>
</ng-template>
