import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeConfig,
  AttributeDualInputConfig,
  Candidate,
  CandidateError, RetailLink,
  RetailLinkCardErrors,
  RetailLinkCardModel,
  RetailLinkCardPermissions
} from 'pm-models';
import {InputState} from 'pm-components';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper} from 'pm-components/lib/cards/attribute-permissions-mapper';

@Component({
  selector: 'pm-retail-link',
  templateUrl: './retail-link-card.component.html',
  styleUrls: ['./retail-link-card.component.scss']
})
export class RetailLinkCardComponent implements OnInit {

  private static readonly RETAIL_LINK_NUMBER = 'Link Number';
  private static readonly UPC = 'UPC';
  private static readonly ITEM_CODE = 'Item Code';

  @Input()
  model: RetailLinkCardModel | Candidate;

  @Input()
  errorModel: RetailLinkCardErrors | CandidateError;

  @Input()
  permissions: RetailLinkCardPermissions;

  @Input()
  retailLinkState: InputState;

  @Output() modelChange = new EventEmitter<any>();

  @Output() validateRetailLink = new EventEmitter<any>();

  isRetailLinked: boolean;


  constructor() { }

  ngOnInit(): void {
    if (this.model?.retailType === Candidate.RETAIL_LINK) {
      this.isRetailLinked = true;
    }
  }



  retailLinkConfiguration: AttributeConfig = {
    options: [
      { value: RetailLinkCardComponent.RETAIL_LINK_NUMBER, label: 'Link #' },
      { value: RetailLinkCardComponent.UPC, label: 'UPC' },
      { value: RetailLinkCardComponent.ITEM_CODE, label: 'Item Code' }
    ],
    label: 'Retail link',
    description: 'Inherit retail price from another product.',
    placeholderText: 'Search for retail link...',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.retailLink),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.retailLink),
    isRequired: true,
    defaultValue: RetailLinkCardComponent.UPC
  };

  isRetailLinkedConfiguration: AttributeConfig = {
    label: 'Retail linked',
    description: 'Let us know if we should add this product to an existing retail link.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.retailLink),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.retailLink),
    isRequired: false,
    defaultValue: false,
    name: 'retailLinkId'
  };


  xForConfiguration: AttributeDualInputConfig = {
    collections: [],
    description: '',
    label: 'Retail pricing',
    name: '',
    options: [],
    isRequired: true,
    input1DecimalCount: 0,
    input1NumberCount: 3,
    input1Placeholder: '#',
    input2DecimalCount: 2,
    input2Placeholder: '$',
    input2NumberCount: 9,
    separatingText: 'for',
    isDisabled: () => true,
    isReadOnly: () => true,
  };


  onRetailLinkNumberChange(event) {
    this.model.retailLink.searchedId = event.model;
    this.model.retailLink.upcType = event.category;
  }


  onRetailLinkTypeChange(event) {
    if (this.model.retailLink && this.model.retailLink.upcType !== event) {
      this.model.retailLink.upcType = event;
      this.retailLinkState = InputState.none;
      this.model.retailLink.searchedId = null;
      this.model.retailXFor = null;
      this.model.retailPrice = null;

      if (this.errorModel?.retailLink) {
        this.errorModel.retailLink = null;
      }
    }
  }

  validateRetailLinkEvent(event) {
    this.validateRetailLink.emit(event);
  }

  isRetailLinkedChange(event) {
    this.isRetailLinked = event.checked;

    if (this.errorModel?.retailLink) {
      this.errorModel.retailLink = null;
    }

    if (this.isRetailLinked) {
      this.retailLinkState = InputState.none;
      this.model.retailType = Candidate.RETAIL_LINK;
      this.model.retailLink = new RetailLink();
      this.model.retailLink.upcType = RetailLinkCardComponent.UPC;
    } else {
      this.model.retailType = null;
      this.model.retailLink = null;
    }
    this.model.retailXFor = null;
    this.model.retailPrice = null;
  }
}
