import {PssDepartment} from 'pm-models/lib/pssDepartment';

export class SubDepartment {

  normalizedId: string;

  subDepartmentId: string;

  subDepartmentDescription: string;

  departmentId: string;

  pssDepartments: PssDepartment[];
}
