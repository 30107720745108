import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  ErrorCandidate,
  HEBDetailsCardErrors,
  HEBDetailsCardModel,
  HEBDetailsCardPermissions
} from 'pm-models';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper} from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-buyer-details-card',
  templateUrl: './buyer-details-card.component.html',
  styleUrls: ['./buyer-details-card.component.scss']
})
export class BuyerDetailsCardComponent {
  @Input()
  model: HEBDetailsCardModel | Candidate;

  @Input()
  errorModel: HEBDetailsCardErrors | ErrorCandidate | CandidateError;

  @Input()
  permissions: HEBDetailsCardPermissions;

  @Output()
  modelChange = new EventEmitter<any>();

  @Output()
  buyerChange = new EventEmitter<any>();

  buyerConfiguration: AttributeTypeaheadConfig = {
    label: 'Buyer',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.buyer),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.buyer),
    description: 'Choose either an H-E-B Buyer or Central Market Buyer to review this request. Right now, you need to send ' +
      'separate requests for H-E-B stores and Central Market stores.',
    name: 'Buyer',
    idRef: 'buyerId',
    displayRef: 'displayName',
    displaySubRef: '',
    searchUrl: '/lookup/buyer',
    placeholderText: 'Type to search for a Buyer...'
  };

  constructor() { }

  buyerInitCallback(event) {
    this.buyerChange.emit(event);
  }

  onBuyerChange(event) {
    if (!event || !event.buyerId) {
      this.model.buyer = null;
      this.modelChange.emit(this.model);
      this.buyerChange.emit(event);
    } else {
      this.model.buyer = event;
      this.modelChange.emit(this.model);
      this.buyerChange.emit(event);
    }
  }
}
