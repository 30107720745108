<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-4">
    <!-- -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Supplier details"
           subtitle="Choose a point of contact and provide basic information about the product's brand.">
    <ng-container *ngIf="candidateModel">
      <pm-attribute-display-typeahead [attribute]="supplierConfiguration" [(model)]="candidateModel.vendor"
                                      [attributeError]="candidateErrorModel?.vendor" (selection)="onSupplierChange($event)"
                                      (initCallback)="supplierInitCallback($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead [attribute]="brandConfiguration" [(model)]="candidateModel.brand"
                                      [attributeError]="candidateErrorModel?.brand"
                                      (selection)="onBrandChange($event)"
                                      (initCallback)="brandInitCallback($event)"
                                      (buttonOptionClicked)="showPanel($event, rnaConfirmOverlay, rnaConfirmDiv)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead [attribute]="subBrandConfiguration" [(model)]="candidateProductModel.subBrand"
                                      [attributeError]="candidateProductErrorModel?.subBrand" (selection)="subBrandChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead [attribute]="costOwnerConfiguration" [(model)]="candidateModel.costOwner"
                                      [attributeError]="candidateErrorModel?.costOwner" (selection)="costOwnerChange($event)"
                                      (buttonOptionClicked)="showPanel($event, rnaConfirmOverlay, rnaConfirmDiv)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-text-input [attribute]="topToTopConfiguration" [model]="topToTopModel">
      </pm-attribute-display-text-input>

      <pm-attribute-display-typeahead [attribute]="warehouseSupplierConfiguration" [(model)]="candidateModel.lane"
                                      [attributeError]="candidateErrorModel?.lane" (selection)="warehouseSupplierChange($event)">
      </pm-attribute-display-typeahead>
    </ng-container>
  </pm-card>
</ng-template>

<div>
  <div #rnaConfirmDiv></div>
  <pm-static-overlay-panel #rnaConfirmOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'" [hideTransitionOptions]="'0ms'">
    <pm-request-new-attribute-confirm-overlay [title]="'Request new brand or cost owner'"
                                              (cancelClicked)="rnaConfirmOverlay.hide()"
                                              (continueButtonClicked)="showRequestNewAttributeConfirmationPanel($event, rnaFormOverlay, rnaFormOverlayDiv, rnaConfirmOverlay)">
    </pm-request-new-attribute-confirm-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaFormOverlayDiv></div>
  <pm-static-overlay-panel #rnaFormOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'">
    <pm-request-new-attribute-form-overlay (cancelClicked)="rnaFormOverlay.hide()" [(model)]="formModel"
                                           (sendRequestClicked)="sendRequestAndCloseModal($event, rnaFormOverlay, rnaFormOverlayDiv)">

    </pm-request-new-attribute-form-overlay>
  </pm-static-overlay-panel>
</div>
