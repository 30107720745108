<div class="attribute-grid-container">
  <div class="attribute-grid-title-group">
    <h3 class="attribute-grid-title">{{title}}</h3>
    <button *ngIf="canEdit" pButton type="button" label="Edit" class="ui-button-link attribute-grid-edit"
            (click)="onEditClicked($event)"></button>
  </div>
  <div class="attribute-grid">
    <ng-content></ng-content>

  </div>

</div>
