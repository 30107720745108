import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private auth: AuthService) {}
  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 || error.status === 403) {
        console.error('custom error handler got error status = ' + error.status);
        this.auth.localLogout();
      }
    } else {
      console.log(error);
    }
  }
}
