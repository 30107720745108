import {Injectable} from '@angular/core';
import {WorkflowService} from './workflow.service';
import {
  Attribute,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError, CandidateValidatorType,
  Commodity,
  LocationGroupStores,
  Task,
  TaskDecision
} from 'pm-models';
import {GrowlService} from '../growl/growl.service';
import {CandidateService} from './candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import { from, Observable, of, throwError, throwError as observableThrowError } from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {UUID} from 'angular2-uuid';
import {LookupService} from '../service/lookup.service';
import {CostService} from './cost.service';
import {CandidateUtilService} from './candidate-util.service';
import {ProcessInstanceConstants} from '../utils/constants/process-instance-constants';

@Injectable({
  providedIn: 'root'
})
export class SupplierProductService {

  constructor(private workflowService: WorkflowService, private candidateService: CandidateService, private route: ActivatedRoute,
              private router: Router, private growlService: GrowlService, private lookupService: LookupService,
              private costService: CostService, private candidateUtilService: CandidateUtilService) {
  }

  private taskId = undefined;
  private task: Task;
  private VENDOR_TASK_NAME = 'Key Vendor Data';
  private VENDOR_REVISION_TASK_NAME = 'Revise Vendor Data';
  private BUYER_TASK_NAME = 'Key Buyer Data';
  private BUYER_REVISE_TASK_NAME = 'Revise Buyer Data';
  public PIA_TASK_NAME = 'PIA Final Review';
  public PIA_OB_REG_ACTIVATE_TASK_NAME = 'PIA OB Reg Flow Activate';
  private CLOSED_VENDOR_TASK_NAME = 'Closed Vendor Task';

  private isClosedTask = false;
  candidate: Candidate;
  originalCandidate: Candidate = undefined;
  private currentCandidateProductIndex = 0;
  private candidateTypeError: CandidateError = undefined;
  private supplierDetailsError: CandidateError = undefined;
  private basicItemSetupError: CandidateError = undefined;
  private casePackError: CandidateError = undefined;
  private storeAuthError: CandidateError = undefined;
  private extendedAttributesError: CandidateError = undefined;
  private commodities: Commodity[] = undefined;
  public hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  public hierarchyAttributes: Attribute[] = [];
  public globalAttributes: Attribute[] = [];
  public productAttributes: Attribute[] = [];
  public warehouseItemAttributes: Attribute[] = [];
  public upcAttributes: Attribute[] = [];
  public hasHierarchyUpdateChanges = false;
  private storesSearched: number[];
  private storesInput: string;

  public authGroups: LocationGroupStores[];
  public selectedAuthGroups: LocationGroupStores[];
  public notFoundStores: number[];
  public static readonly DSD_NON_NAVIGABLE_ON_ERROR_PAGES: string[] = ['/newProductReviewPageComponent', '/extendedAttributes',
    '/storeAuthorization', '/setUpStores'];
  public static readonly BASE_VALIDATORS = [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR];
  public static readonly DSD_VALIDATORS =
    [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR, CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
      CandidateValidatorType.SUPPLIER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_CASE_PACK_VALIDATOR];

  /**
   * Sets and returns the original and current.
   * @param params
   */
  public setCandidateByUrlParameters(params): Observable<any> {
    if (params.has('taskId')) {
      return this.workflowService.getTaskByIdWithVariables(params['params']['taskId']).pipe(
        switchMap(
          (task) => {
            this.task = task;
            this.taskId = this.task.id;

            if (!
              (
                this.task.name === this.VENDOR_TASK_NAME ||
                this.task.name === this.VENDOR_REVISION_TASK_NAME ||
                this.task.name === this.BUYER_TASK_NAME ||
                this.task.name === this.BUYER_REVISE_TASK_NAME ||
                this.task.name === this.PIA_TASK_NAME ||
                this.task.name === this.PIA_OB_REG_ACTIVATE_TASK_NAME
              )
            ) {
              this.router.navigate(['/tasks'], {
                queryParams: {
                  growlMessage: 'Candidate is not in available for supplier revision.',
                  growlToUse: GrowlService.SEVERITY_ERROR
                }
              });
              return observableThrowError('Candidate is not in available for supplier revision.');
            }

            return this.getCandidateByCandidateId(task.candidateId);
          }
        ));
    } else if (params.has('candidateId')) {
      this.isClosedTask = true;
      const candidateId = params['params']['candidateId'];
      this.candidateService.getCandidate(candidateId)
        .subscribe((candidate) => {
          this.setOriginalAndCurrentCandidate(candidate);
        });
    }
  }

  /**
   * Returns a candidate by the candidate id.
   * @parnam candidateId
   */
  private getCandidateByCandidateId(candidateId): Observable<any> {
    return this.candidateService.getCandidate(candidateId).pipe(
      tap(
        (candidate) => {
          if (!candidate.candidateType || candidate.candidateType === Candidate.NEW_PRODUCT ||
            candidate.candidateType === Candidate.PLU) {
            this.resetErrors();
          } else {
            const message = 'Invalid Candidate Task Flow : "' + candidate.candidateType + '" expected "' + Candidate.NEW_PRODUCT +
              '" or "' + Candidate.PLU + '"';
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: message, growlToUse: GrowlService.SEVERITY_ERROR}
            });
            return observableThrowError(message);
          }
        }
      ),
      catchError(
        (error) => {
          // if there was an error retrieving task, route back to tasks page with the error
          this.router.navigate(['/tasks'], {
            queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
          });
          return observableThrowError(error);

        }),
      switchMap((candidate) => this.costService.updateCostLink(candidate)),
      switchMap((candidate) => this.costService.updateRetailLink(candidate)),
      tap((candidate) => {
        this.setOriginalAndCurrentCandidate(candidate);
      })
    );
  }

  /**
   * Creates the candidate and navigates to the supplier details.
   * @param candidate
   */
  public createCandidateAndNavigate(candidate: Candidate, url: string) {
    this.candidateService.createNewCandidate(candidate).subscribe((newCandidate: Candidate) => {
      this.originalCandidate = newCandidate;
      this.candidate = JSON.parse(JSON.stringify(newCandidate));
      this.resetErrors();
      this.createProcessInstanceWithCandidateId(newCandidate, url);
    });
  }

  /**
   * Create process instance with newly created candidate id and routes to the supplier details page.
   */
  public createProcessInstanceWithCandidateId(candidate: Candidate, url: string) {
    const processInstanceToCreate = this.getProcessInstanceToCreate();

    this.workflowService.createProcessInstanceWithCandidateId(candidate.candidateId, processInstanceToCreate)
      .subscribe(taskId => {
        if (taskId) {
          this.taskId = taskId;
          this.workflowService.getTaskByIdWithVariables(taskId).subscribe((task) => {
            this.task = task;
            if (candidate.vendor && candidate.vendor.apNumber) {
              this.workflowService.updateApNumber(candidate.vendor.apNumber, task.processInstanceId).subscribe(() => {
                this.task = task;
                this.router.navigate([url], {queryParams: {taskId: taskId}});
              });
            } else {
              this.router.navigate([url], {queryParams: {taskId: taskId}});
            }
          });
        } else {
          this.growlService.addError('Problem creating process instance.');
        }
      });
  }

  deleteAndCreateNewProcessInstance(url?: string): Observable<any> {
    return this.workflowService.deleteProcessInstanceAndCreateNewFlow(this.task.processInstanceId, this.candidate.candidateId,
      this.getProcessInstanceToCreate(), this.candidate.vendor?.apNumber).pipe(
      switchMap((task) => {
          this.task = task;
          this.taskId = task?.id;
          if (url) {
            return this.router.navigate([url], {queryParams: {taskId: this.taskId}});
          } else {
            return of({});
          }
        }
      )
    );
  }

  getProcessInstanceToCreate(): string {
    return this.getProcessInstanceToCreateByCandidate(this.candidate);
  }

  getProcessInstanceToCreateByCandidate(candidate: Candidate): string {
    if (candidate.warehouseSwitch && candidate.dsdSwitch) {
      this.growlService.addError('Distribution channel must be Warehouse or DSD only, not both.');
      return null;
    } else if (candidate.warehouseSwitch && (this.candidateUtilService.isScaleProduct(candidate) || candidate.showCalories)) {
      return ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_SCALE_WORKFLOW;
    } else if (candidate.warehouseSwitch) {
      return ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_WORKFLOW;
    } else if (candidate.dsdSwitch && (this.candidateUtilService.isScaleProduct(candidate) || candidate.showCalories)) {
      return ProcessInstanceConstants.NEW_PRODUCT_DSD_SCALE_WORKFLOW;
    } else {
      return ProcessInstanceConstants.NEW_PRODUCT_DSD_WORKFLOW;
    }
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = JSON.parse(JSON.stringify(candidate));
    this.candidate = JSON.parse(JSON.stringify(candidate));
  }

  /**
   * Saves candidate.
   */
  saveCandidate() {
    if (this.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          this.setOriginalAndCurrentCandidate(savedCandidate);
        });
      }
    }
  }

  /**
   * Saves candidate and navigates to the url path provided.
   */
  saveCandidateAndNavigate(url: string, updateApNumber: boolean): Observable<any> {
    if (this.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
      return throwError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        return this.candidateService.saveCandidate(this.candidate).pipe(
          tap(savedCandidate => {
            if (updateApNumber) {
              let apNumber;
              if (!this.candidate.vendor) {
                apNumber = null;
              } else {
                apNumber = this.candidate.vendor.apNumber;
              }
              this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).subscribe(() => {
                this.setOriginalAndCurrentCandidate(savedCandidate);
                this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}});
              });
            } else {
              this.setOriginalAndCurrentCandidate(savedCandidate);
              this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}});
            }
          })
        );
      } else {
        return from(this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}}));
      }
    }
  }

  /**
   * Saves candidate.
   */
  saveCandidateAndClose() {
    if (this.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          this.router.navigate(['/tasks']);
        });
      } else {
        this.router.navigate(['/tasks']);
      }
    }
  }

  /**
   * Checks if a string is empty or just spaces
   */
  isEmptyOrSpaces(str) {
    return typeof str === 'undefined' || !str || str.match(/^ *$/) !== null;
  }

  /**
   * Resets default value.
   */
  resetService() {
    this.taskId = undefined;
    this.task = undefined;
    this.isClosedTask = false;
    this.candidate = undefined;
    this.originalCandidate = undefined;
    this.currentCandidateProductIndex = 0;
    this.commodities = null;
    this.storesSearched = null;
    this.authGroups = null;
    this.selectedAuthGroups = null;
    this.storesInput = null;
    this.notFoundStores = null;
    this.resetMatHierarchyFields();
    this.resetErrors();
  }

  resetMatHierarchyFields() {
    this.globalAttributes = [];
    this.hierarchyAttributes = [];
    this.productAttributes = [];
    this.warehouseItemAttributes = [];
    this.upcAttributes = [];
    this.hierarchyNumberToAttributesMap = new Map();
    this.hasHierarchyUpdateChanges = false;
  }

  /**
   * Returns the current candidate.
   */
  public getCandidate() {
    return this.candidate;
  }

  /**
   * Returns the original candidate.
   */
  public getOriginalCandidate() {
    return this.originalCandidate;
  }

  /**
   * Returns the task.
   */
  public getTask() {
    return this.task;
  }

  /**
   * Returns task id.
   */
  public getTaskId() {
    return this.taskId;
  }
  /**
   * sets task id.
   */
  public setTaskId(taskId) {
    this.taskId = taskId;
  }

  /**
   * Returns the Current Candidate Product Index.
   */
  public getCurrentCandidateProductIndex() {
    return this.currentCandidateProductIndex;
  }

  /**
   * Returns the current candidate Product error model.
   */
  public getCurrentCandidateProductError(candidateError: CandidateError): CandidateProductError {
    if (candidateError) {
      const tempCandidateProductError = candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id];
      if (!tempCandidateProductError) {
        // empty candidateProductErrors returns as object, so must override with a new map to prevent undefined .set() method error.
        if (!(candidateError.candidateProductErrors instanceof Map)) {
          candidateError.candidateProductErrors = new Map<string, CandidateProductError>();
        }
        const candidateProductError = new CandidateProductError();
        candidateError.candidateProductErrors.set(this.getCurrentCandidateProduct().id,
          candidateProductError);
        return candidateProductError;
      }
      return tempCandidateProductError;
    }
    return new CandidateProductError();
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    if (this.candidate && this.candidate.candidateProducts) {
      return this.candidate.candidateProducts[this.currentCandidateProductIndex];
    }
  }

  public setupNewCandidate() {
    this.candidate = new Candidate();
    this.candidate.productType = Candidate.SELLABLE;
    this.candidate.candidateType = Candidate.NEW_PRODUCT;
    this.candidate.candidateProducts = [];
    this.candidate.candidateProducts.push({id: UUID.UUID()});
    this.resetErrors();
  }

  public updatePageErrors(candidateError) {
    this.candidateTypeError = JSON.parse(JSON.stringify(candidateError));
    this.supplierDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.basicItemSetupError = JSON.parse(JSON.stringify(candidateError));
    this.casePackError = JSON.parse(JSON.stringify(candidateError));
    this.storeAuthError = JSON.parse(JSON.stringify(candidateError));
    this.extendedAttributesError = JSON.parse(JSON.stringify(candidateError));
  }

  public getCandidateTypeError(): CandidateError {
    return this.candidateTypeError;
  }

  public getSupplierDetailsError(): CandidateError {
    return this.supplierDetailsError;
  }

  public getBasicItemSetupError(): CandidateError {
    return this.basicItemSetupError;
  }

  public getCasePackError(): CandidateError {
    return this.casePackError;
  }

  public getStoreAuthError(): CandidateError {
    return this.storeAuthError;
  }

  public getExtendedAttributesError(): CandidateError {
    return this.extendedAttributesError;
  }

  public setCandidateTypeError(candidateError): CandidateError {
    this.candidateTypeError = candidateError;
    return this.candidateTypeError;
  }

  public setSupplierDetailsError(candidateError): CandidateError {
    this.supplierDetailsError = candidateError;
    return this.supplierDetailsError;
  }

  public setBasicItemSetupError(candidateError): CandidateError {
    this.basicItemSetupError = candidateError;
    return this.basicItemSetupError;
  }

  public setCasePackError(candidateError): CandidateError {
    this.casePackError = candidateError;
    return this.casePackError;
  }

  public setStoreAuthError(candidateError): CandidateError {
    this.storeAuthError = candidateError;
    return this.storeAuthError;
  }

  public setExtendedAttributesError(candidateError): CandidateError {
    this.extendedAttributesError = candidateError;
    return this.extendedAttributesError;
  }

  public resetErrors() {
    this.candidateTypeError = new CandidateError();
    this.supplierDetailsError = new CandidateError();
    this.basicItemSetupError = new CandidateError();
    this.casePackError = new CandidateError();
    this.storeAuthError = new CandidateError();
    this.extendedAttributesError = new CandidateError();
  }

  /**
   * Set the candidate.
   * @param candidate
   */
  setCandidate(candidate: Candidate) {
    this.candidate = candidate;
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  public saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  public completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], {queryParams: {growlMessage: growlMessage}});
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Callback when a buyer is selected. Buyers have associated Commodities that can determine casepack weight options.
   * @param updatedBuyer
   */
  public updateBuyerCallBack(updatedBuyer) {
    if (!updatedBuyer) {
      this.commodities = [];
      this.candidate.itemWeightType = null;
      return;
    }
    this.lookupService.findAllCommoditiesByBuyerId(updatedBuyer.buyerId).subscribe(data => {
      this.commodities = data;
      if (updatedBuyer.buyerId !== this.originalCandidate.buyer?.buyerId) {
        this.candidate.itemWeightType = null;
      }
    });
  }

  getAllCandidateErrors(): CandidateError[] {
    return [this.getCandidateTypeError(), this.getSupplierDetailsError(), this.getBasicItemSetupError(),
      this.getCasePackError(), this.getStoreAuthError(), this.getExtendedAttributesError()];
  }
  getAllDsdCandidateErrors(): CandidateError[] {
    return [this.getCandidateTypeError(), this.getSupplierDetailsError(), this.getBasicItemSetupError(),
      this.getCasePackError(), this.getStoreAuthError(), this.getExtendedAttributesError()];
  }
  getAllWhsCandidateErrors(): CandidateError[] {
    return [this.getCandidateTypeError(), this.getSupplierDetailsError(), this.getBasicItemSetupError(),
      this.getCasePackError(), this.getExtendedAttributesError()];
  }

  scrollToTop() {
    document.getElementsByClassName('pm-editor-header')[0].scrollIntoView();
  }

  isSellable(candidate) {
    return candidate.productType === Candidate.SELLABLE;
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user to URL with candidate ID
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param candidateID to include in query params of URL.
   */
  public saveAndCompleteTaskAndRouteToUrlWithCandidateId(action: string, taskDecision: TaskDecision, url: string, candidateID: number) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
        .subscribe(() => {
          this.router.navigate([url], {queryParams: {candidateId: candidateID}}).then();
        });
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user to URL with candidate ID
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param candidateID to include in query params of URL.
   */
  public saveAndCompleteTaskAndRouteToTasks(action: string, taskDecision: TaskDecision, candidateID: number) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
        .subscribe(() => {
          this.router.navigate(['/tasks']).then();
        });
    });
  }

  isWarehouseOnly(): boolean {
    return this.candidate && this.candidate.warehouseSwitch && !this.candidate.dsdSwitch;
  }

  isDsdOnly(): boolean {
    return this.candidate && this.candidate.dsdSwitch && !this.candidate.warehouseSwitch;
  }

  isDsdOnlyParms(candidate: Candidate): boolean {
    return candidate && candidate.dsdSwitch && !candidate.warehouseSwitch;
  }

  isWarehouseAndDsd(): boolean {
    return this.candidate && this.candidate.warehouseSwitch && this.candidate.dsdSwitch;
  }

  getCandidateProduct(index?) {
    if (index) {
      return this.candidate.candidateProducts[index];
    }
    return this.candidate.candidateProducts[this.currentCandidateProductIndex];
  }

  getStoresSearched() {
    if (this.storesSearched && this.storesSearched.length > 0) {
      return this.storesSearched;
    } else {
      return  null;
    }
  }

  setStoresSearched(storesSearched: number[]) {
    this.storesSearched = storesSearched;
  }

  getStoresInput() {
    return this.storesInput;
  }

  setStoresInput(storesInput: string) {
    this.storesInput = storesInput;
  }

  getNotFoundStores() {
    if (!this.notFoundStores) {
      this.notFoundStores = [];
    }
    return this.notFoundStores;
  }

  setNotFoundStores(notFoundStores: number[]) {
    this.notFoundStores = notFoundStores;
  }

  getAuthGroups() {

    if (this.authGroups && this.authGroups.length > 0) {
      return this.authGroups;
    } else {
      return null;
    }
  }
  getSelectedAuthGroups() {

    if (this.selectedAuthGroups && this.selectedAuthGroups.length > 0) {
      return this.selectedAuthGroups;
    } else {
      return null;
    }
  }

  setAuthGroups(authGroups: LocationGroupStores[]) {
    this.authGroups = authGroups;
  }

  setSelectedAuthGroups(authGroups: LocationGroupStores[]) {
    this.selectedAuthGroups = JSON.parse(JSON.stringify(authGroups));
  }

  findAllAuthGroups(): Observable<LocationGroupStores[]> {
    if (this.candidate.overrideDepartment) {
      return this.lookupService.findAllAuthGroups(this.candidate.vendor.apNumber, this.candidate.overrideSubDepartment.departmentId,
        this.candidate.overrideSubDepartment.subDepartmentId);
    } else {
      return this.lookupService.findAllAuthGroups(this.candidate.vendor.apNumber, this.candidate.commodity.departmentId,
        this.candidate.commodity.subDepartmentId);
    }
  }

  findAllAuthGroupsByParms(apNumber, departmentId, subDepartmentId): Observable<LocationGroupStores[]> {
    return this.lookupService.findAllAuthGroups(apNumber, departmentId, subDepartmentId);
  }

  /**
   * if There's checked auth groups, open them, and selected all stores. Else, open all
   * groups.
   */
  openAuthGroups() {
    let isCheckedGroup = false;
    for (let x = 0; x < this.getSelectedAuthGroups().length; x++) {
      if (this.getSelectedAuthGroups()[x].checked === true) {
        isCheckedGroup = true;
      }
    }

    // If groups sent, show them as viewing, and check all stores inside.
    if (isCheckedGroup) {
      for (let x = 0; x < this.getSelectedAuthGroups().length; x++) {
        if (this.getSelectedAuthGroups()[x].checked === true) {
          this.getSelectedAuthGroups()[x].isViewing = true;
          this.onAuthGroupChecked({checked: true}, this.getSelectedAuthGroups()[x]);
        }
      }
      // if no groups set, show all as viewing.
    } else {
      for (let x = 0; x < this.getSelectedAuthGroups().length; x++) {
        this.getSelectedAuthGroups()[x].isViewing = true;
      }
    }
  }


  onAuthGroupChecked(event, authGroup) {
    const checked = event.checked;

    if (!authGroup.stores || authGroup.stores.length === 0) {
      return;
    }

    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].checked = checked;
    }
  }

  /**
   * Finds selected auth group from candidate in fetched auth groups, hides not selected stores, and checks selected stores.
   */
  setSelectedAuthGroupsFromCandidate() {
    // if there's no candidate groups or no auth groups to update, return;
    if (!this.getCandidate().candidateProducts[0].locationGroupStores ||
      this.getCandidate().candidateProducts[0].locationGroupStores.length === 0 ||
      !this.getAuthGroups() || this.getAuthGroups().length === 0) {
      return;
    }
    const selectedAuthGroups: LocationGroupStores[] = [];
    for (let x = 0; x < this.getCandidate().candidateProducts[0].locationGroupStores.length; x++) {
      for (let y = 0; y < this.getAuthGroups().length; y++) {
        if (this.getCandidate().candidateProducts[0].locationGroupStores[x].splrLocationGroupId ===
          this.getAuthGroups()[y].splrLocationGroupId) {
          const selectedAuthGroup: LocationGroupStores = JSON.parse(JSON.stringify(this.getAuthGroups()[y]));
          selectedAuthGroup.isViewing = true;
          selectedAuthGroup.listCost = this.getCandidate().candidateProducts[0].locationGroupStores[x].listCost;
          selectedAuthGroups.push(selectedAuthGroup);
          this.setAuthGroupStores(this.getCandidate().candidateProducts[0].locationGroupStores[x], selectedAuthGroup);
          this.setMissingStores(this.getCandidate().candidateProducts[0].locationGroupStores[x], selectedAuthGroup);
          break;
        }
      }
    }
    this.setSelectedAuthGroups(selectedAuthGroups);
  }

  setMissingStores(candidateAuthGroup: LocationGroupStores, selectedAuthGroup: LocationGroupStores) {
    for (let x = 0; x < candidateAuthGroup.stores.length; x++) {
      let isFound = false;
      for (let y = 0; y < selectedAuthGroup.stores.length; y++) {
        if (candidateAuthGroup.stores[x].custLocationNumber === selectedAuthGroup.stores[y].custLocationNumber) {
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        this.getNotFoundStores().push(candidateAuthGroup.stores[x].custLocationNumber);
      }
    }
  }

  /**
   * Hides not found candidateAuthGroup stores, and checks found candidateAuthGroup stores in selectedAuthGroup.
   */
  setAuthGroupStores(candidateAuthGroup: LocationGroupStores, selectedAuthGroup: LocationGroupStores) {

    let allChecked = true;
    for (let y = 0; y < selectedAuthGroup.stores.length; y++) {
      selectedAuthGroup.stores[y].isHidden = true;
      selectedAuthGroup.stores[y].listCost = selectedAuthGroup.listCost;
      for (let x = 0; x < candidateAuthGroup.stores.length; x++) {
        if (candidateAuthGroup.stores[x].custLocationNumber === selectedAuthGroup.stores[y].custLocationNumber) {
          selectedAuthGroup.stores[y].checked = true;
          selectedAuthGroup.stores[y].isHidden = false;
          break;
        }
      }
      if (!selectedAuthGroup.stores[y].checked) {
        allChecked = false;
      }
    }
    if (allChecked) {
      selectedAuthGroup.checked = true;
    }
  }

  validateHasNoStores(): boolean {
    // if auth groups haven't been loaded, return false.
    if (!this.authGroups) {
      this.setHasNoStoresError(null);
      return false;
    } else if (this.authGroups.length === 0) {
      this.setHasNoStoresError(true);
      return true;
    }
    for (let x = 0; x < this.authGroups.length; x++) {
      if (this.authGroups[x].stores || this.authGroups[x].stores.length > 0) {
        this.setHasNoStoresError(null);
        return false;
      }
    }
    this.setHasNoStoresError(true);
    return true;
  }

  setHasNoStoresError(hasError: Boolean) {
    if (this.getSupplierDetailsError()) {
      this.getSupplierDetailsError().noStoresError = hasError;
    } else {
      const error = new CandidateError();
      error.noStoresError = hasError;
      this.setSupplierDetailsError(error);
    }
  }
}
