import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class InnerPackWeight implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Weight',
    description: 'The total weight of one inner pack, in pounds (lbs).',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    decimalCount: 4,
    numberCount: 9,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: 'lbs',
    name: 'innerPackWeightId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.innerWeight;
    componentInstance.modelChange.subscribe(x => {
      candidate.innerWeight = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.innerWeight) {
      componentInstance.model = candidate.innerWeight;
    }
  }
}
