<pm-review #pmReview *ngIf="candidate && isViewingPage" [ngClass]="getReviewClass()" (closeEvent)="onClose()">
  <!-- Page Titles -->
  <pm-title>Review MRT</pm-title>
  <pm-sub-title>{{candidate?.description}}</pm-sub-title>
  <pm-sub-title class="row" style="padding-left: 15px;">
    <button pButton type="button"
            [label]="'View candidate history'"
            class="ui-button-link" (click)="historyPanelOpen()">
    </button>
  </pm-sub-title>

  <!-- Missing warehouses content -->
  <div *ngIf="candidate && candidate.missingWarehouses && !isWarehouseMissingResolved" class="row pt-2 pb-3">
    <div class="col-md-1"></div>
    <div class="ml-3 borderLeft col col-md-6 p-2 justify-content-md-left">
      <div class="row ml-2">
        <img style="width: 20px; height: 20px;" src="/assets/images/triangle_exclamation.svg">
        <div class="ml-2 bold">Missing warehouse</div>
      </div>
    </div>
  </div>

  <div *ngIf="candidate" class="row justify-content-md-center pb-3">
    <div class="col col-md-10 pb-5">

      <div *ngIf="hasPendingInner()" class="row pb-3 ml-0">
        <div class="col-md-9 pending-candidates-box">
          <h6 class="ml-2 mt-3">
            View only
          </h6>
          <p class="ml-2 pb-3">
            This MRT contains at least 1 item that hasn’t been activated yet.
            You cannot approve this MRT until all of its items are activated.
            If you haven't already approved these new items, locate them in
            your Task View page and review them.
          </p>
        </div>
      </div>

      <pm-attribute-grid class="mrt-inner-info-grid-container" [title]="'MRT items & costs set by supplier'" [canEdit]="!isLoadingInners" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
        <div *ngIf="isLoadingInners" style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingInners" [strokeWidth]="'2'" class="loading-indicator"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching product data...</h5>
        </div>
        <div *ngIf="!isLoadingInners">
          <ng-template ngFor let-inner [ngForOf]="mrtCandidateInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="true" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(inner).upcCheckDigit}}<br>
                  <button pButton type="button" label="View details" class="ui-button-link inner-details-button" (click)="viewCandidateDetails(inner)"></button>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status === 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="pending">Pending</div>
                  <pm-grid-cell-info>
                    This item is new and requires a separate review
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status !== 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="text-capitalize">{{toLowerCase(inner?.candidate?.status)}}</div>
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).description}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(supplierMrtService.getCurrentCandidateProducts(inner).description) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="true" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  -
                </pm-grid-cell>

              </pm-grid-column>
              <hr>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
          <ng-template ngFor let-inner [ngForOf]="mrtExistingInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="true" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
                  {{inner?.upc}}-{{inner.upcCheckDigit}}<br>
                  <button pButton type="button" label="View details" class="ui-button-link inner-details-button" (click)="viewProductDetails(inner)"></button>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  Active
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{inner?.product?.productDescription}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(inner?.product?.productDescription) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="true" (editClicked)="onClickEditMrtItems($event, editMrtOverlayPanel, targetDiv)">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell *ngIf="!productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  {{productService.getItemCode(inner?.product)}}
                </pm-grid-cell>
                <pm-grid-cell *ngIf="productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  Multiple
                  <pm-grid-cell-info>
                    This item has multiple case configurations
                  </pm-grid-cell-info>
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
        </div>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'"  [canEdit]="true" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]"
                          (editClicked)="showEditCandidateModal(attributeType.MasterListCost)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterListCost')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(candidate?.masterListCost)}}
              <pm-grid-cell-info style="white-space: pre-line;">
                <div *ngIf="!isNotMissingCandidateInfo" >
                  {{getCostDisplay()}}
                </div>
                <div *ngIf="isNotMissingCandidateInfo">
                  Penny Profit: {{getPennyProfit()}}<br>
                  <a>Margin: <a [ngClass]="{'grid-cell-error':isMarginNegativeOrZero()}">{{getMarginPercent()}}%</a>
                    <img *ngIf="isMarginNegativeOrZero()" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
                  </a>
                </div>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Case summary -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="true"
                          (editClicked)="editCaseDescription(attributeType.CaseDescription)"
                          [errorMessages]="[candidateProductError?.caseDescription]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="true" (editClicked)="editCaseUPC()" [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseUpc')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="true" [errorMessage]="candidateError?.buyer" (editClicked)="editBuyer()"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'buyer', 'buyerName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate?.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="true" (editClicked)="showEditCandidateProductModal(attributeType.VendorProductCode)" [errorMessage]="candidateProductError?.vendorProductCode"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="true" (editClicked)="showEditCandidateModal(attributeType.Channel)"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>

          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="true" (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)" [errorMessage]="candidateProductError?.countryOfOrigin"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'countryOfOrigin', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Case details from buyer -->
      <pm-attribute-grid [title]="'Case details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.inboundSpecDays"  (editClicked)="showEditCandidateModal(attributeType.InboundSpecDays)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'inboundSpecDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season'" [canEdit]="true" (editClicked)="editSeasonYear()" [errorMessages]="[candidateError?.season, candidateError?.seasonYear]"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'seasonYear') || candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'season', 'seasonDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Reaction days'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.warehouseReactionDays" (editClicked)="showEditCandidateModal(attributeType.ReactionDays)"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'warehouseReactionDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.guaranteeToStoreDays" (editClicked)="showEditCandidateModal(attributeType.GuaranteeDays)"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'guaranteeToStoreDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{candidate.subCommodity?.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master Pack'" [canEdit]="false">
              1
            </pm-grid-cell>
            <pm-grid-cell [label]="'Total retail units'">
              {{getMasterPack()}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="true" [errorMessages]="[candidateError?.masterLength, candidateError?.masterWidth, candidateError?.masterHeight]" (editClicked)="showEditCandidateModal(attributeType.Dimensions)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="true" [errorMessages]="[candidateError?.vendorTie,candidateError?.vendorTier ]" (editClicked)="editCandidate(attributeType.BoxesOnPallet)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTie') || candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTier')"
                          (historyClicked)="historyPanelOpen()">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="isTiedToCatchOrVariableWeightBuyer()" [errorMessage]="candidateError?.itemWeightType" (editClicked)="showEditCandidateModal(attributeType.ItemWeightType)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'itemWeightType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="true" [errorMessage]="candidateError?.masterWeight" (editClicked)="showEditCandidateModal(attributeType.Weight)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="true" [errorMessage]="candidateError?.cubeAdjustedFactor" (editClicked)="showEditCandidateModal(attributeType.OrderFactor)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'cubeAdjustedFactor')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="true"  [errorMessage]="candidateError?.codeDate" (editClicked)="showEditCandidateModal(attributeType.CodeDate)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'codeDate')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.maxShelfLife" (editClicked)="showEditCandidateModal(attributeType.MaxShelfLife)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'maxShelfLife')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="true" [errorMessage]="candidateError?.displayReadyUnit" (editClicked)="showEditCandidateModal(attributeType.DisplayReadyUnit)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnit')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="true"  [errorMessage]="candidateError?.displayReadyUnitOrientation" (editClicked)="showEditCandidateModal(attributeType.Orientation)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="true" [errorMessages]="[candidateError?.displayReadyUnitRowsDeep, candidateError?.displayReadyUnitRowsFacing, candidateError?.displayReadyUnitRowsHigh]" (editClicked)="showEditCandidateModal(attributeType.RetailUnitCount)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsDeep') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsFacing') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsHigh')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Warehouses -->
      <pm-attribute-grid [title]="'Warehouses'">

        <!-- Missing warehouses content -->
        <div *ngIf="candidate && candidate.missingWarehouses" class="row pt-2 pb-3">
          <div [ngClass]="{'borderLeft': !isWarehouseMissingResolved, 'borderLeftResolved' : isWarehouseMissingResolved}"
               class="ml-3 col col-md-10 p-2 justify-content-md-left">
            <div class="row">
              <div class="col col-md-10">
                <img *ngIf="!isWarehouseMissingResolved" style="width: 20px; height: 20px;" src="/assets/images/triangle_exclamation.svg">
                <img *ngIf="isWarehouseMissingResolved" style="width: 20px; height: 20px;" src="/assets/images/circle_check.svg">
                <div *ngIf="isWarehouseMissingResolved" class="inline-block ml-2 mb-2 bold">(Resolved)</div>
                <div class="inline-block ml-2 mb-2 bold">Missing warehouse</div>
              </div>
              <div *ngIf="isWarehouseMissingResolved" class="col col-md-2">
                <button type="button" (click)="toggleExpandCollapseMissingWarehouse()" class="ml-2 float-right btn btn-link" style="font-size: 12px;">{{expandCollapseMissingWarehouseButtonText}}</button>
              </div>
            </div>

            <div *ngIf="isExpandedWarehouse">
              <p class="ml-3">An SCA was unable to select the desired warehouse (see their note below). You may need to modify one or more fields related to the bicep or adjust the warehouse configuration outside of PAM.</p>
              <hr/>
              <div class="float-left ml-2 mb-0 bold">{{candidate.missingWarehousesCommentUser}}</div>
              <div style="font-size: 14px;" class="mt-2 float-right text-muted">{{candidate.missingWarehousesCommentTime | dateStandard}}</div>
              <div style="clear: both;"></div>

              <div class="ml-2 mb-2">{{candidate.missingWarehousesComment}}</div>

              <div *ngIf="candidateProduct.warehouses.length > 0" class="ml-2 mb-2 mt-2" style="font-size: 14px;">
                <button type="button" style="font-size: 1em;" class="btn btn-link pl-0" (click)="toggleResolved()" *ngIf="!isWarehouseMissingResolved">Mark as resolved</button>
                <div *ngIf="isWarehouseMissingResolved" class="text-body">Resolved by {{candidate.missingWarehousesResolveUser}}
                  <span (click)="toggleResolved()" class="btn btn-link ml-2 pl-0" style="font-size: 12px;">Undo</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- Warehouses grid content -->
        <pm-grid-group *ngIf="candidateProduct.warehouses.length > 0">
          <pm-grid-column>
            <pm-grid-cell [label]="'Max ship'" [canEdit]="true" [errorMessage]="candidateError?.maxShip"
                          (editClicked)="editCandidate(attributeType.MaxShip)"
                          [hasChanged]="candidateHistoryService.hasScaValueChangedForCandidate(candidate, 'maxShip')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.maxShip}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <ng-template ngFor let-warehouse [ngForOf]="candidateProduct.warehouses" let-index="index">

          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Warehouse'" [canEdit]="false" class="warehouse-grid-cell"
                            [errorMessages]="[candidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep,
                            candidateProductError?.warehouseErrors[warehouse.warehouseId]?.ecomWarehouseError]">
                {{warehouse.name}}
                <pm-grid-cell-info>
                  ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                </pm-grid-cell-info>
                <button pButton *ngIf="candidateProduct.warehouses.length !== 1" type="button" label="Remove" class="ui-button-link" (click)="removeWarehouse(index)"></button>
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order unit'" [canEdit]="true" [errorMessage]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit"
                            (editClicked)="editWarehouse(warehouse, attributeType.WarehouseOrderUnit)"
                            [hasChanged]="candidateHistoryService.hasScaValueChangedForWarehouse(candidateProduct, 'orderUnit', 'description')"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderUnit?.description}}
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order restriction'" [canEdit]="true"  [errorMessage]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction"
                            (editClicked)="editOrderRestrictions(warehouse)"
                            [hasChanged]="candidateHistoryService.hasScaValueChangedForWarehouse(candidateProduct, 'orderRestriction', 'description')"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderRestriction?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <div *ngIf="index !== candidateProduct.warehouses.length - 1" class="grid-divider"></div>
        </ng-template>
      </pm-attribute-grid>
      <div class="row">
        <div class="col-6">
          <button pButton type="button" label="View additional warehouse options" class="ui-button-link" (click)="openWarehouseDrawer()"></button>
        </div>
      </div>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="true" [errorMessage]="candidateError?.costOwner"  (editClicked)="editCostOwner()"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCostOwner(candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false" [errorMessage]="candidateError?.buyer"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'buyer', 'buyerName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false" [errorMessage]="candidateError?.topToTop"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForTopToTop(candidate)"
                          (historyClicked)="historyPanelOpen()">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="true" (editClicked)="editWarehouseSupplier()" [errorMessage]="candidateError?.lane"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate,'lane', 'name')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.lane?.name}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false" [errorMessage]="candidateError?.vendor">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <!--   Global Attributes   -->
        <div>
          <pm-attribute-grid *ngIf="isLoadingMatData || !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>

      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="true" (editClicked)="showEditCandidateProductModal(attributeType.Remark1)" [errorMessage]="candidateProductError?.remark1">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="true" (editClicked)="showEditCandidateProductModal(attributeType.Remark2)" [errorMessage]="candidateProductError?.remark2">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="true"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.piaComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate?.supplierComment">
                <b>{{candidate?.contactEmail}}</b> <br>
                {{candidate?.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b><br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}} <br><br>
              </div>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.piaComment">
                <b>{{candidate.piaCommentUser}}</b> <br>
                {{candidate.piaComment}}
              </div>
            </pm-comment-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <pm-attachment-upload-review-page [attachments]="candidate.attachments" (updateCallback)="addAttachments($event)"></pm-attachment-upload-review-page>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

    </div>
  </div>
  <pm-review-drawer-container>
    <ng-container *ngIf="isViewingCaseDetails">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link" (click)="save()">
            </button>
          </div>
        </div>
        <h2>Additional warehouses</h2>
        <hr/>
        <ng-template ngFor let-warehouse [ngForOf]="warehouseData">
          <div class="attribute-group">
            <div>
              <p-checkbox id="whsCheckbox"
                          [(ngModel)]="warehouse.checked"
                          (onChange)="selectedWarehouseChange($event, warehouse)"
                          binary="true">
              </p-checkbox>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{warehouse.name}}
                </label>
              </div>
              <p class="attribute-description">
                ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
              </p>
              <p *ngIf="warehouse.checked && warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
                 class="error-message bicep-error">
                {{warehouseCandidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
              </p>
              <p *ngIf="warehouse.checked && warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.ecomWarehouseError"
                 class="error-message bicep-error">
                {{warehouseCandidateProductError.warehouseErrors[warehouse.warehouseId].ecomWarehouseError}}
              </p>
            </div>
          </div>
          <div *ngIf="warehouse.checked">
            <div class="attributes-stacked">
              <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                          [(model)]="warehouse.orderUnitId"
                                          (change)="orderUnitChange($event, warehouse)"
                                          [attributeError]="warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
              </pm-attribute-display-radio>
              <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                              [(model)]="warehouse.orderRestriction"
                                              (selection)="orderRestrictionChange($event, warehouse)"
                                              [attributeError]="warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
              </pm-attribute-display-typeahead>
            </div>
          </div>
          <hr/>
        </ng-template>
        <div class="text-right">
          <p-button class="m-2" label="Cancel" (onClick)="collapse()"></p-button>
          <p-button class="m-2" label="Update" (onClick)="update()" [disabled]="!this.isSelectedWarehouse()"></p-button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isViewingProductDetails">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>UPC Details</h2>
        <div>
          <pm-attribute-grid [title]="'Overview'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'UPC'">
                  {{currentUpc?.scanCodeId}}-{{currentUpc?.scanCodeId | upcCheckDigit}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Brand'">
                  {{currentExistingInner?.product?.brand ? currentExistingInner?.product?.brand.description + ' [' + currentExistingInner?.product?.brand.brandId + ']': ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-brand'">
                  {{currentUpc?.subBrand ? currentUpc?.subBrand.description + ' [' + currentUpc?.subBrand.subBrandId + ']': ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Buyer'">
                  {{currentUpc?.item?.hierarchy?.commodity?.bdm ? currentUpc?.item?.hierarchy?.commodity?.bdm.fullName +
                  ' [' + currentUpc?.item?.hierarchy?.commodity?.bdm.bdmId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Product description'">
                  {{currentProduct?.productDescription}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(currentProduct?.productDescription) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell [label]="'Commodity'">
                  {{currentUpc?.item?.hierarchy?.commodity ? currentUpc?.item?.hierarchy?.commodity.description +
                  ' [' + currentUpc?.item?.hierarchy?.commodity.commodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-commodity'">
                  {{currentUpc?.item?.hierarchy ? currentUpc?.item?.hierarchy?.description +
                  ' [' + currentUpc?.item?.hierarchy?.subCommodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Merchandise type'">
                  {{currentProduct?.productTypeId}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentProduct?.productTypeId === 'GOODS'| yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Unit details'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentUpc?.unitOfMeasure?.description}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{currentUpc?.length}}in x
                  {{currentUpc?.width}}in x
                  {{currentUpc?.height}}in
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit size'">
                  {{currentUpc?.size}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 1'">
                  {{currentProduct?.customerFriendlyDescriptionOne}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 2'">
                  {{currentProduct?.customerFriendlyDescriptionTwo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentProduct?.productTypeId === 'GOODS' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Packaging & regulatory info'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Food stamp eligible'">
                  {{currentProduct?.foodStamp | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Taxable'">
                  {{currentProduct?.taxable | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'FSA/HSA eligible'">
                  {{currentProduct?.flexibleSpendingAccount | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Package type'">
                  {{currentProduct?.packageDescription}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{currentUpc?.length}}in x
                  {{currentUpc?.width}}in x
                  {{currentUpc?.height}}in
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentUpc?.unitOfMeasure?.description}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Retail size'">
                  {{currentUpc?.size}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Weight'">
                  {{candidateUtilService.getProductWeightString(currentUpc.weight)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit Size'">
                  {{currentUpc?.item?.totalVolume}}
                </pm-grid-cell>

              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isViewingCandidateDetails">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>UPC Details</h2>
        <div>
          <pm-attribute-grid [title]="'Overview'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'UPC'">
                  {{currentInnerCandidateProduct?.upc}}-{{currentInnerCandidateProduct.upcCheckDigit}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Brand'">
                  {{currentInnerCandidate?.brand?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-brand'">
                  {{currentInnerCandidateProduct?.subBrand?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Buyer'">
                  {{currentInnerCandidate?.buyer?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Product description'">
                  {{currentInnerCandidateProduct?.description}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(currentInnerCandidateProduct?.description) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell [label]="'Commodity'">
                  {{currentInnerCandidate?.commodity ? currentInnerCandidate?.commodity?.commodityName + ' [' + currentInnerCandidate?.commodity?.commodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-commodity'">
                  {{currentInnerCandidate?.subCommodity ? currentInnerCandidate?.subCommodity?.subCommodityName + ' [' + currentInnerCandidate?.subCommodity?.subCommodityId + ']' : ''}}

                </pm-grid-cell>
                <pm-grid-cell [label]="'Merchandise type'">
                  {{currentInnerCandidate?.merchandiseType ? currentInnerCandidate?.merchandiseType?.description + ' [' + currentInnerCandidate?.merchandiseType?.merchandiseTypeCode + ']' : ''}}

                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentInnerCandidate.productType === 'SELLABLE' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Unit details'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentInnerCandidate?.unitOfMeasure.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{candidateUtilService.getMasterDimensions(currentInnerCandidate)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit size'">
                  {{currentInnerCandidate.retailSize}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 1'">
                  {{currentInnerCandidateProduct?.customerFriendlyDescription1}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 2'">
                  {{currentInnerCandidateProduct?.customerFriendlyDescription2}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentInnerCandidate.productType === 'SELLABLE' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Packaging & regulatory info'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Food stamp eligible'">
                  {{currentInnerCandidate?.foodStamp | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Taxable'">
                  {{currentInnerCandidate?.taxable | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'FSA/HSA eligible'">
                  {{currentInnerCandidate?.flexibleSpendingAccount | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Package type'">
                  {{currentInnerCandidate?.packageType?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{candidateUtilService.getProductDimensionsString(currentInnerCandidate)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentInnerCandidate?.unitOfMeasure?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Retail size'">
                  {{currentInnerCandidate?.retailSize}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Weight'">
                  {{candidateUtilService.getProductWeightString(currentInnerCandidate?.productWeight)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit Size'">
                  {{currentInnerCandidate?.totalVolume}}
                </pm-grid-cell>

              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>

        </div>
      </div>
    </ng-container>

  </pm-review-drawer-container>

  <!--  Audit History -->
  <pm-review-drawer-container>
    <ng-container *ngIf="showHistoryPanel && !isViewingCaseDetails">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="candidateHistoryService.currentHistoryResults">
            <div *ngIf="candidateHistoryService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{candidateHistoryService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="piaComments"
                                           [(model)]="tempCommentHolder"
                                           [attributeError]="candidateError?.piaComment">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.piaComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.piaComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <div *ngIf="isShowingNormalFooter()">
      <p-button class="ghost m-2" label="Reject" (onClick)="showRejectPanel($event, rejectOverlay, rejectCandidateDiv)" [disabled]="isRejectDisabled"></p-button>
      <p-button class="m-2" label="{{getActivateButtonTitle()}}" (onClick)="onClickActivate()" [disabled]="isActivateDisabled || isActivateButtonDisabled()"></p-button>
    </div>
    <div *ngIf="isShowingPendingFooter()">
      <div class="row text-right" style="white-space: nowrap; display: block;">
        You can’t approve this MRT until all the items it contains are activated.
      </div>
      <div class="row" style="float: right;">

        <p-button class="ghost m-2 text-right" label="Reject MRT" (onClick)="showRejectPanel($event, rejectOverlay, rejectCandidateDiv)" [disabled]="isRejectDisabled"></p-button>
        <p-button class="m-2 text-right" label="Return to task page" (onClick)="onClickReturnToTaskPage()"></p-button>
      </div>
    </div>
  </pm-footer>
</pm-review>

<div class="mrt-items-modal">
  <div #targetDiv></div>
  <pm-static-overlay-panel #editMrtOverlayPanel [style]="{width: '100%', height: '100%'}" [dismissable]="false">
    <div class="row align-items-center mrt-items-modal-wrapper" style="width: 100%; height: 100%; justify-content: center;">

      <div class="col-md-6 mrt-items-modal-div">
        <div class="mrt-items-body">
          <h1 style="text-align: left;">Products in this MRT</h1>

          <div class="col-md-12">
            <div class="row">
              <div class="col">
                <div class="row">
                  <label>Add products to the MRT (activated products only).</label>
                </div>
                <div class="row">
                  <pm-upc-input
                    [(upcModel)]="searchedCandidateProduct.upc"
                    [(checkDigitModel)]="searchedCandidateProduct.upcCheckDigit"
                    [(upcType)]="upcType"
                    [readOnly]="false"
                    [disabled]="false"
                    [hasError]="hasError()"
                    placeholderText="Enter UPC"
                    checkDigitPlaceholderText="Check #"
                    [upcOptions]="upcOptions"
                    [state]="upcState"
                    (upcModelChange)="setUpcAndValidate()"
                    (checkDigitModelChange)="setUpcAndValidate()"
                    (shiftEnterEmitted)="addItem()"
                    style="width: 100%;">
                  </pm-upc-input>
                </div>
                <div class="row">
                  <button pButton type="button" label="+ Add item" class="ui-button-link add-item-button" (click)="addItem()"></button>
                  <p class="add-item-description">&nbsp;or hit Shift + Enter to add multiple items quickly.</p>
                </div>
                <div class="row" *ngIf="hasError()">
                  <p *ngIf="searchedCandidateProductError?.upc" class="error-message">{{searchedCandidateProductError?.upc}}</p>
                  <p *ngIf="searchedCandidateProductError?.upcCheckDigit" class="error-message">{{searchedCandidateProductError?.upcCheckDigit}}</p>
                </div>
              </div>
            </div>

            <div *ngFor="let inner of mrtModalCandidateInners; let index = index" class="row">
              <div class="col-md-12">
                <div class="row pt-4">
                  <div class="col-md-8">
                    <div class="row header-label">
                      UPC: {{supplierMrtService.getCurrentCandidateProducts(inner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(inner).upcCheckDigit}}
                    </div>
                    <div class="row attribute-description">
                      Status: New {{!inner.replenishable ? ' (non-replenish)': ''}}<br>
                      Item code: -<br>
                      Product desc: {{supplierMrtService.getCurrentCandidateProducts(inner).description}}
                    </div>
                  </div>
                  <div class="col-md-3 attributes-stacked item-attribute">
                    <pm-attribute-display-text-input [attribute]="quantityConfiguration"
                                                     [(model)]="inner.quantity"
                                                     [attributeError]="mrtModalCandidateError?.mrtInfoError.candidateInnerErrors[inner.candidateId]?.quantity">
                    </pm-attribute-display-text-input>
                  </div>
                  <div class="col-md-1 delete-inner-button">
                    <pm-delete-button (deleteButtonClicked)="deleteCandidateInner(index)" [showConfirmationModal]="true"
                                      [disabled]="supplierMrtService.isDeleteInnerDisabled(mrtModalCandidateInners, mrtModalExistingInners)"
                                      [pTooltip]="supplierMrtService.getDeleteInnerDisabledTooltip(mrtModalCandidateInners, mrtModalExistingInners)">
                    </pm-delete-button>
                  </div>
                </div>

              </div>
            </div>
            <div *ngFor="let inner of mrtModalExistingInners; let index = index" class="row">
              <div class="col-md-12">
                <div class="row pt-4">
                  <div class="col-md-8" *ngIf="inner.product">
                    <div class="row header-label">
                      UPC: {{inner.upc}}-{{inner.upcCheckDigit}}
                    </div>
                    <div class="row attribute-description">
                      Status: Activated<br>
                      {{supplierMrtService.getItemCodeString(inner.product)}}<br>
                      Product desc: {{inner?.product.productDescription}}
                    </div>
                  </div>
                  <div class="col-md-3 attributes-stacked item-attribute">
                    <pm-attribute-display-text-input [attribute]="quantityConfiguration"
                                                     [(model)]="inner.quantity"
                                                     [attributeError]="mrtModalCandidateError?.mrtInfoError.existingInnerErrors[inner.upc]?.quantity">
                    </pm-attribute-display-text-input>
                  </div>
                  <div class="col-md-1 delete-inner-button">
                    <pm-delete-button (deleteButtonClicked)="deleteExistingInner(index)" [showConfirmationModal]="true"
                                      [disabled]="supplierMrtService.isDeleteInnerDisabled(mrtModalCandidateInners, mrtModalExistingInners)"
                                      [pTooltip]="supplierMrtService.getDeleteInnerDisabledTooltip(mrtModalCandidateInners, mrtModalExistingInners)">
                    </pm-delete-button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="mrt-items-footer">
          <div class="row m-3">
            <div class="col-md-12 text-right pr-0">
              <button pButton class="cancel m-2" label="Cancel" (click)="cancelEditMrtItems(editMrtOverlayPanel)" [disabled]="isSaveMrtButtonDisabled"></button>
              <p-button class="m-2" label="Save" (onClick)="saveMrtAndCloseModal(editMrtOverlayPanel)" [disabled]="isSaveMrtButtonDisabled"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rejectCandidateDiv></div>
  <pm-static-overlay-panel #rejectOverlay [style]="{width: '100%', height: '100%', top: 0}">
    <div *ngIf="hasPendingReplenishableInner()" class="row align-items-center delete-modal-wrapper">
      <div class="col-md-5 delete-modal">
        <div class="row pt-3">
          <h2>
            Reject candidate?
          </h2>
        </div>
        <div class="row mb-1">
          <p>
            Since this MRT contains one or more new replenishable items, you can choose to reject those as well or only reject the MRT. Rejecting an MRT always rejects its non-replenishable items (which don’t have a case besides the MRT).
          </p>
          <p>
            Let the Supplier know why you’re rejecting this MRT.
          </p>
        </div>
        <div class="attributes-stacked ml-3">
          <pm-attribute-display-radio [attribute]="reasonConfiguration"
                                      [(model)]="reasonType"
                                      (change)="reasonTypeChange($event)">
          </pm-attribute-display-radio>
          <div *ngIf="reasonType === OTHER" class="other-reason-row">
            <input id="pendingOtherReasonMessage" pInputText
                   placeholder="Provide a custom reason..."
                   type="text"
                   accept-charset="UTF-8"
                   [(ngModel)]="otherReasonMessage"/>
          </div>
        </div>
        <div class="row button-row">
          <button pButton class="cancel mr-3" label="Cancel" (click)="rejectOverlay.hide()"></button>
          <button pButton class="ui-button-danger mr-3" label="Reject MRT only" (click)="rejectMrt()" [disabled]="isRejectButtonDisabled()"></button>
          <button pButton class="ui-button-danger" label="Reject MRT and new items" (click)="rejectMrtAndNewItems(rejectOverlay)" [disabled]="isRejectButtonDisabled()"></button>
        </div>
      </div>
    </div>
    <pm-reject-candidate-overlay *ngIf="!hasPendingReplenishableInner()" (cancelClicked)="rejectOverlay.hide()" (rejectButtonClicked)="rejectMrtAndCloseModal($event, rejectOverlay, targetDiv)"></pm-reject-candidate-overlay>
  </pm-static-overlay-panel>
</div>


<div>
  <div #rnaMatConfirmDiv></div>
  <pm-static-overlay-panel #rnaMatConfirmOverlayPanel appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'" [hideTransitionOptions]="'0ms'">
    <pm-request-new-attribute-confirm-overlay *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                              [title]="'Request a new ' + requestNewMatAttributeOverrideWrapper?.requestNewMatAttributeForm?.attributeBusinessFriendlyDescription + ' value'"
                                              [classOverride]="'col-md-5'"
                                              (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatConfirmOverlayPanel, requestNewMatAttributeOverrideWrapper)"
                                              (continueButtonClicked)="matUtilService.showRequestNewAttributeFormPanel($event, rnaMatFormOverlay, rnaMatFormOverlayDiv, rnaMatConfirmOverlayPanel)">
    </pm-request-new-attribute-confirm-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatFormOverlayDiv></div>
  <pm-static-overlay-panel #rnaMatFormOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'">
    <pm-request-new-mat-attribute-form *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                       (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatFormOverlay, requestNewMatAttributeOverrideWrapper)"
                                       [(model)]="requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm"
                                       (sendRequestClicked)="matUtilService.sendRequestAndCloseModal(this.candidate, requestNewMatAttributeOverrideWrapper, rnaMatFormOverlay)">
    </pm-request-new-mat-attribute-form>
  </pm-static-overlay-panel>
</div>
