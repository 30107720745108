<p-toast>
  <ng-template let-message pTemplate="message">
      <div>
        <div class="d-flex">
          <i *ngIf="isError(message)" class="pi pi-exclamation-triangle px-2" style="font-size: 2rem"></i>
          <i *ngIf="isSuccess(message)" class="pi pi-check-circle px-2" style="font-size: 2rem"></i>
          <h4>{{message.summary}}</h4>
        </div>
        <div class="text-wrap text-break">
          <p>{{message.detail}}</p>
        </div>
      </div>
  </ng-template>
</p-toast>
