export enum UpcRequestType {
  HEB_41220,
  HEB_PRE_DIGIT_4,
  CHECKER,
  SCALE,
  CHECKER_AND_SCALE,
  NON_SELLABLE_004,
  HEB_197870
}
export class NewUpcRequest {
  requestedUpc?: number;
  requestType?: UpcRequestType;
  rangeId?: number;
  userId?: string;
  candidate?: any;
  upcRequestNotes?: string;
  count?: any;
}
