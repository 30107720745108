import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeConfig, CandidateProduct, CandidateProductError} from 'pm-models';
import {
  ImportFactoryCardErrors,
  ImportFactoryCardModel,
  ImportFactoryCardPermissions
} from 'pm-models/lib/card-models/import-factory-card-model';

@Component({
  selector: 'pm-import-factory-card',
  templateUrl: './import-factory-card.component.html',
  styleUrls: ['./import-factory-card.component.scss']
})
export class ImportFactoryCardComponent implements OnInit {


  @Input()
  candidateProductModel: CandidateProduct | ImportFactoryCardModel;

  @Input()
  candidateProductErrorModel: CandidateProductError | ImportFactoryCardErrors;

  @Input()
  permissions: ImportFactoryCardPermissions;

  @Output() candidateProductModelChange = new EventEmitter<any>();

  importFactoryConfiguration: AttributeConfig = {
    label: 'Import factory',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => false
  };

  constructor() { }

  ngOnInit() {
  }

  factoryModelChange(event) {
  }
}
