import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RequestNewAttributeConfirmComponent} from './request-new-attribute-confirm.component';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';


@NgModule({
  declarations: [RequestNewAttributeConfirmComponent],
  exports: [RequestNewAttributeConfirmComponent, AttributeDisplayModule],
  imports: [
    CommonModule, AttributeDisplayModule
  ]
})
export class RequestNewAttributeConfirmModule { }
