import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductPackagingCardComponent } from './product-packaging-card.component';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import { CardModule } from '../../shared/card/card.module';

@NgModule({
  declarations: [ProductPackagingCardComponent],
  exports: [ProductPackagingCardComponent, AttributeDisplayModule, CardModule],
  imports: [CommonModule, AttributeDisplayModule, CardModule]
})
export class ProductPackagingCardModule {}
