import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {
  Attribute,
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Product,
  Task,
  TextInputType,
  Upc,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {AuthService} from '../auth/auth.service';
import {GrowlService} from '../growl/growl.service';
import {WorkflowService} from '../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../service/candidate.service';
import {LookupService} from '../service/lookup.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {CostService} from '../service/cost.service';
import {FileService} from '../service/file.service';
import {ProductService} from '../service/product.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {AttributeTypes, ReviewComponent} from 'pm-components';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {SupplierMrtService} from '../service/supplier-mrt.service';
import {forkJoin} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {MatUtilService} from '../service/mat-util.service';

@Component({
  selector: 'app-sca-mrt-review',
  templateUrl: './sca-mrt-review.component.html',
  styleUrls: ['./sca-mrt-review.component.scss']
})
export class ScaMrtReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public WAREHOUSE_TASK_NAME = 'Assign Warehouse';
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public showMatAttributes = false;
  public isLoadingMatData = true;
  public globalAttributes: Attribute[] = [];
  public warehouseItemAttributes: Attribute[] = [];
  private openCommentDrawer: boolean = false;
  public tempCommentHolder: string;

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public fileService: FileService, public productService: ProductService,
              public candidateUtilService: CandidateUtilService, public authService: AuthService,
              public permissionService: NgxPermissionsService, public supplierMrtService: SupplierMrtService,
              public matUtilService: MatUtilService) {
  }

  public warehouseMissingNotesEntered: boolean = false;

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  private taskSubscription$: any;
  private task: Task;
  public isViewingPage = true;
  public opened = true;
  public merchandiseTypes: any;
  public commodities: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public mrtExistingInners: ExistingInner[] = [];
  public mrtCandidateInners: CandidateInner[] = [];
  public isApproveDisabled = false;
  public currentExistingInner: ExistingInner = undefined;
  public currentProduct: Product = undefined;
  public currentUpc: Upc = undefined;
  public currentInnerCandidate: Candidate = undefined;
  public currentInnerCandidateProduct: CandidateProduct = undefined;
  public orderRestrictions: any[];
  public warehouseData = [];
  public warehouses: any = undefined;
  public isViewingCaseDetails = false;
  public isViewingCandidateDetails = false;
  public isViewingProductDetails = false;
  public decimalCount = 1;
  public isLoadingInners = true;

  // undefined if not yet calculated, false if is missing data,
  // true if all data present
  public isNotMissingCandidateInfo: Boolean;
  public isPageEditable: boolean = false;

  missingWarehousesCommentsConfiguration: AttributeTextInputConfig = {
    label: 'Notes for Procurement Support',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-half-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: '',
    name: 'missingWarehouseCommentId',
    maxLength: 4000,
    isRequired: true
  };

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  scaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'scaCommentId',
    maxLength: 4000
  };

  private setIsPageEditable() {
    this.isPageEditable = this.authService.isSca();
  }

  ngOnInit() {

    this.setIsPageEditable();

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.WAREHOUSE_TASK_NAME) {
              this.router.navigate(
                ['/tasks'],
                {
                  queryParams:
                    {
                      growlMessage: 'Candidate is not in ' + this.WAREHOUSE_TASK_NAME + ' status.',
                      growlToUse: GrowlService.SEVERITY_ERROR
                    }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                this.setInitialValues(candidate);
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    this.getOrderRestrictions();
    this.getWarehouses(candidate);
    this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe();
    if (this.candidate.missingWarehousesComment && this.candidate.missingWarehousesComment.length > 0) {
      this.warehouseMissingNotesEntered = true;
    }

    forkJoin([this.supplierMrtService.setCandidateInnerCandidates(this.candidate),
      this.supplierMrtService.setCandidateExistingInnerProducts(this.candidate)])
      .pipe(
        tap(() => {
            this.handleExistingInners();
            this.handleActivatedCandidateInners();
            this.handleNonActivatedCandidateInners();
            this.isLoadingInners = false;
          }
        )
      ).subscribe();

    this.setupMatAttributes();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }
    this.showMatAttributes = true;
    this.matUtilService.updateMatAttributesAndValues(this.candidate, this.globalAttributes, []).pipe(
      tap(() => {
        this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.globalAttributes,
          [], this.warehouseItemAttributes, []);
      }),
      finalize(() => {
        this.isLoadingMatData = false;
      })
    ).subscribe();
  }


  private handleActivatedCandidateInners() {
    const activatedCandidateInners: CandidateInner[] = this.candidate.mrtInfo?.candidateInners
      ?.filter(candidateInner => candidateInner.candidate && candidateInner.candidate.status === Candidate.ACTIVATED);

    if (!!activatedCandidateInners?.length) {
      const upcs: number[] = activatedCandidateInners.map(activatedCandidateInner =>
        activatedCandidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate)].upc);
      this.lookupService.getProductsByUpcs(upcs).pipe(
        tap((products: Product[]) => {
          activatedCandidateInners.forEach(activatedCandidateInner => {
            const index = CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate);
            const upc = activatedCandidateInner.candidate.candidateProducts[index].upc;
            const existingInner = new ExistingInner();
            existingInner.product = this.productService.getProductByUpc(upc, products);
            existingInner.unitCost = activatedCandidateInner.unitCost;
            existingInner.quantity = activatedCandidateInner.quantity;
            existingInner.upc = upc;
            existingInner.upcCheckDigit = activatedCandidateInner.candidate.candidateProducts[index].upcCheckDigit;
            this.mrtExistingInners.push(existingInner as ExistingInner);
          });
        })
      ).subscribe();
    }
  }

  private handleNonActivatedCandidateInners() {
    let hasRetail = true;
    if (!!this.candidate.mrtInfo?.candidateInners?.length) {
      this.candidate.mrtInfo.candidateInners.forEach(candidateInner => {
        if (candidateInner.candidate && candidateInner.candidate.status !== Candidate.ACTIVATED) {
          if (!candidateInner.candidate.retailType) {
            candidateInner.candidate.retailType = CostService.KEY_RETAIL;
          }
          // if the candidate is non replenishable, the unit cost is never calculated by supplier
          // due to lack of fields. So use the value from the mrt.
          if (!candidateInner.replenishable) {
            candidateInner.candidate.unitCost = candidateInner.unitCost;
            this.mrtCandidateInners.push(candidateInner as CandidateInner);
          } else {
            this.mrtCandidateInners.unshift(candidateInner as CandidateInner);
          }
          // if the candidate doesn't have retail, set isNotMissingCandidateInfo to false.
          if (candidateInner.candidate.retailType === CostService.PRICE_REQUIRED || !candidateInner.candidate.retailPrice || !candidateInner.candidate.retailXFor) {
            hasRetail = false;
          }
        }
      });
    }
    this.isNotMissingCandidateInfo = hasRetail;
  }

  private handleExistingInners() {
    if (!!this.candidate.mrtInfo?.existingInners?.length) {
      this.candidate.mrtInfo.existingInners.forEach(existingInner => {
        this.mrtExistingInners.push(existingInner);
      });
    }
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidateProduct.warehouses = this.getSelectedWarehouses();
      this.candidate.candidateProducts[0] = this.candidateProduct;
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.router.navigate(['/tasks']).then();
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']).then();
    }
  }


  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
    this.candidate.scaName = this.authService.getUser();
    if (this.candidate?.scaComment) {
      this.tempCommentHolder = this.candidate.scaComment;
    }
  }

  viewProductDetails(existingInner: ExistingInner) {

    this.lookupService.getUpc(existingInner.upc).subscribe((upc) => {
      this.currentUpc = upc;
    });

    this.currentExistingInner = existingInner;
    this.currentProduct = existingInner.product;
    this.currentInnerCandidate = null;
    this.currentInnerCandidateProduct = null;
    this.isViewingCaseDetails = false;
    this.isViewingProductDetails = true;
    this.isViewingCandidateDetails = false;
    this.pmReview.openDrawer();

  }
  viewCandidateDetails(candidateInner: CandidateInner) {

    this.isViewingCaseDetails = false;
    this.isViewingProductDetails = false;
    this.isViewingCandidateDetails = true;
    this.currentExistingInner = undefined;
    this.currentProduct = undefined;
    this.currentInnerCandidate = candidateInner.candidate;
    this.currentInnerCandidateProduct =
      candidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidateInner.candidate)];
    this.pmReview.openDrawer();
  }

  getMasterPack() {
    let masterPack = 0;
    if (this.candidate.mrtInfo.existingInners && this.candidate.mrtInfo.existingInners.length > 0) {
      for (let x = 0; x < this.candidate.mrtInfo.existingInners.length; x++) {
        masterPack += this.candidate.mrtInfo.existingInners[x].quantity;
      }
    }
    if (this.candidate.mrtInfo.candidateInners && this.candidate.mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.candidate.mrtInfo.candidateInners.length; x++) {
        masterPack += this.candidate.mrtInfo.candidateInners[x].quantity;
      }
    }
    return masterPack;
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  collapse() {
    this.pmReview.closeDrawer();
  }

  onClickNext() {
    this.isViewingCaseDetails = true;
    this.isViewingProductDetails = false;
    this.isViewingCandidateDetails = false;
    this.pmReview.openDrawer();
  }

  /**
   * Saves candidate.
   */
  save() {
    this.candidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }
  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }
  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }


  onClickApprove() {
    this.isApproveDisabled = true;
    this.candidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.SCA_PRODUCT_REVIEW_VALIDATOR]).subscribe(() => {
      this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE, 'Successfully completed assign warehouse task.');
    }, (error) => {
      this.isApproveDisabled = false;
      if (error.error.candidateErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        if (this.candidateProductError.errors.length > 0) {
          let errorString = '';
          for (let x = 0; x < this.candidateError.candidateProductErrors[this.candidateProduct.id].errors.length; x++) {
            errorString += this.candidateError.candidateProductErrors[this.candidateProduct.id].errors[x];
          }
          this.growlService.addError(errorString); // TODO: new way to handle server side errors?
        }
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }
  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.workflowService.completeTaskWithAction(this.task, action)
        .subscribe(() => {
          this.router.navigate(['/tasks'], {queryParams: {growlMessage: growlMessage}}).then();
        }, (error) => {
          this.growlService.addError(error);
          this.isApproveDisabled = false;
        });
    });
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    if (this.candidateProduct.warehouses && this.candidateProduct.warehouses.length > 0) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({},
              this.candidateProduct.warehouses[y], JSON.parse(JSON.stringify(this.candidateProduct.warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  getReviewClass(): string {
    let classString = '';
    if (this.isViewingProductDetails || this.isViewingCandidateDetails) {
      classString += ' review-grid-container';
    }
    return classString;
  }

  getCostDisplay() {
    // if we haven't deduced whether there's missing information, return empty string
    if (this.isNotMissingCandidateInfo === null || this.isNotMissingCandidateInfo === undefined) {
      return '';
      // if we're missing information, return message.
    } else if (this.isNotMissingCandidateInfo === false) {
      return 'We can’t calculate margin and penny profit because we’re missing retail information for one or more of the UPCs above.';
    }
  }

  getPennyProfit() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.candidate.masterListCost);

    if (!pennyProfit) {
      return '';
    }
    return this.costService.toCurrency(pennyProfit);
  }

  getMarginPercent() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.candidate.masterListCost);

    if (!pennyProfit) {
      return '';
    }
    const marginPercent = this.candidateUtilService.getMRTMarginPercent(pennyProfit, masterSuggestedRetail);
    return marginPercent.toPrecision(6);
  }

  isMarginNegativeOrZero() {
    const margin = +this.getMarginPercent();
    return margin <= 0;
  }

  toLowerCase(status: String) {
    return status.toLowerCase();
  }

  editWarehouseSupplier() {
    if (this.candidate.vendor && !this.candidate.vendor.lanes && this.candidate.vendor.apNumber) {
      this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe(() => {
        this.showEditCandidateModal(AttributeTypes.Warehouse, {collections: this.candidate.vendor.lanes});
      });
    } else {
      this.showEditCandidateModal(AttributeTypes.Warehouse,
        {collections: this.candidate.vendor ? this.candidate.vendor.lanes : []});
    }
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.resetWarehouseData();
      }
    });
  }

  private resetWarehouseData() {
    if (this.candidate.lane.id !== null && this.candidate.lane.id !== undefined) {
      this.lookupService.findWarehousesByVendorApNumberAndLaneId(this.candidate.vendor.apNumber,
        this.candidate.lane.id).subscribe(warehouses => {
        this.warehouses = warehouses;
        this.setWarehouseData();
      });
    } else {
      this.warehouses = [];
      this.warehouseData = [];
      this.candidateProduct.warehouses = [];
      this.setWarehouseData();
    }
  }

  updatedMissingWarehousesComment() {
    this.warehouseMissingNotesEntered = true;
  }

  warehouseMissingCheckboxChange(event) {
    const checked = event.checked;
    if (!checked) {
      this.warehouseMissingNotesEntered = false;
      this.candidate.missingWarehousesComment = null;
      this.candidate.missingWarehousesCommentUser = null;
      this.candidate.missingWarehousesCommentTime = null;
      this.candidateError.missingWarehousesComment = undefined;
    } else {
      this.candidate.missingWarehousesCommentUser = this.authService.getUser();
      this.candidate.missingWarehousesCommentTime = new Date().getTime();
    }
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.scaComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.isViewingCaseDetails = false;
    this.isViewingProductDetails = false;
    this.isViewingCandidateDetails = false;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.scaComment = this.tempCommentHolder;
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  addAttachments(event) {
    this.candidate.attachments = event;
    this.save();
  }
}
