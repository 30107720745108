<div class="grid-cell">
  <div class="grid-cell-label">
    <label>
      {{label}}
    </label>
  </div>
  <div class="grid-cell-content">
    <div class="grid-cell-info">
      <ng-content></ng-content>
      <div class="grid-cell-addl-info" *ngIf="commentGridCellInfo">
        <ng-content select="pm-grid-cell-info"></ng-content>
      </div>
      <span class="grid-cell-error" *ngIf="errorMessage">{{errorMessage}}</span>
      <span class="grid-cell-error" *ngFor="let message of errorMessages">
        {{message}}
      </span>
    </div>
    <button *ngIf="canEdit && hasReviewerComment" pButton type="button" label="Edit your comment" class="ui-button-link grid-cell-edit"
      (click)="onEditClicked($event)"></button>
    <button *ngIf="canEdit && !hasReviewerComment" pButton type="button" label="+ Add a comment" class="ui-button-link grid-cell-edit"
      (click)="onEditClicked($event)"></button>
  </div>
</div>
