import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate } from 'pm-models';

export class DisplayReadyUnit implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }
  getAttributeConfig(overrides?) {
    return {
      label: 'Display ready unit',
      description: `Let us know if you're sending shelf ready or display ready units.`,
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: false,
      defaultValue: false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (candidate.displayReadyUnit === undefined) {
      candidate.displayReadyUnit = this.defaultVal;
    }
    componentInstance.model = candidate.displayReadyUnit;
    componentInstance.change.subscribe(x => {
      candidate.displayReadyUnit = x.checked;
      if (candidate.codeDate === true) {
        candidate.displayReadyUnitOrientation = null;
        candidate.displayReadyUnitRowsDeep = null;
        candidate.displayReadyUnitRowsFacing = null;
        candidate.displayReadyUnitRowsHigh = null;
      }
      if (candidate.displayReadyUnit === false) {
        candidate.displayReadyUnitOrientation = null;
        candidate.displayReadyUnitRowsDeep = null;
        candidate.displayReadyUnitRowsFacing = null;
        candidate.displayReadyUnitRowsHigh = null;
        candidate.displayReadyUnitType = null;
      } else {
        // set defaults
        candidate.displayReadyUnitOrientation = 'stock_facing_by_depth';
        candidate.displayReadyUnitType = 'retail_ready_packaging';
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.displayReadyUnit) {
      componentInstance.model = candidate.displayReadyUnit;
    }

    if (candidate.displayReadyUnit === false) {
      candidate.displayReadyUnitOrientation = null;
      candidate.displayReadyUnitRowsDeep = null;
      candidate.displayReadyUnitRowsFacing = null;
      candidate.displayReadyUnitRowsHigh = null;
      candidate.displayReadyUnitType = null;
    }
  }
}
