import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate, AttributeConfig } from 'pm-models';

export class FSAEligible implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'FSA/HSA eligible',
      description: 'Indicate if customers can use funds from a Flexible Spending Account (FSA) or Health Savings Account (HSA) to purchase this product.',
      isRequired: false,
      isDisabled: () => false,
      isReadOnly: () => false,
      defaultValue: false,
      name: 'fsaEligibleId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (candidate.flexibleSpendingAccount === undefined) {
      candidate.flexibleSpendingAccount = this.defaultVal;
    }
    componentInstance.model = candidate.flexibleSpendingAccount;

    componentInstance.change.subscribe(event => {
      candidate.flexibleSpendingAccount = event.checked;
    });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate) {
    componentInstance.model = candidate.flexibleSpendingAccount;
  }
}
