<div *ngIf="candidate" class="row no-stores-container">
  <img class="ban-icon" src="/assets/images/ban-icon.svg">
  <div class="header-row">
    No stores available
  </div>

  <div class="row message-body-row">
    <p>
      Either your account isn't set up for direct store delivery at all or you don't have any authorization groups
      configured for the commodity you selected ({{candidate.commodity?.commodityName}}).<br><br>
      Our Product Support team can troubleshoot this issue and get you set up for direct store delivery correctly.
    </p>
  </div>
  <div class="row phone-row">
    <img class="phone-icon" src="/assets/images/phone-icon.svg">
    <div class="phone-label">Phone:</div>
    <div class="phone-number">(210) 938-7800</div>
  </div>
  <div class="row envelope-row">
    <img class="envelope-icon" src="/assets/images/envelope-icon.svg">
    <div class="envelope-label">Email:</div>
    <div class="envelope-number">ml.productteam@heb.com</div>
  </div>

</div>
