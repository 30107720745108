import {Component, OnInit} from '@angular/core';
import {Candidate, CandidateError, CandidateProduct, CandidateProductError, CandidateValidatorType, TaskDecision} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {GrowlService} from '../../growl/growl.service';
import {AssociateUpcService} from '../../service/associate-upc.service';
import {UUID} from 'angular2-uuid';
import {UPCInputState} from 'pm-components';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {finalize} from 'rxjs/operators';
import {AssociateUpcStepperComponent} from '../../shared/components/associate-upc-stepper/associate-upc-stepper.component';

@Component({
  selector: 'app-pia-associate-case-selection-component',
  templateUrl: './pia-associate-case-selection-component.component.html',
  styleUrls: ['./pia-associate-case-selection-component.component.scss']
})
export class PiaAssociateCaseSelectionComponentComponent implements OnInit {

  private taskSubscription$: any;
  private isActivating = false;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public upcState: UPCInputState;
  public productImages = [];
  private currentCandidateProductIndex = 1;
  private searchedCandidateProductIndex = 0;
  private productData: any;
  public caseUpcData = [];
  public selectedCasePack: number;
  public originalCandidate: any = {};
  canNavigate = true;

  constructor(private route: ActivatedRoute, private workflowService: WorkflowService, public associateUpcService: AssociateUpcService,
              private router: Router, private candidateService: CandidateService, private lookupService: LookupService,
              private growlService: GrowlService, private candidateUtilService: CandidateUtilService) { }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.associateUpcService.getTaskId(),
        this.associateUpcService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.associateUpcService.resetService();
        this.associateUpcService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.ASSOCIATE_UPC) {
            this.associateUpcService.setOriginalAndCurrentCandidate(candidate);
            this.candidateError = this.associateUpcService.getAssociateCasePackDetailsError();
            this.candidateProductError = new CandidateProductError();
            this.setProductData();
            this.setUpcData();
            this.setCasePack();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.associateUpcService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.associateUpcService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    if (this.associateUpcService.candidate.candidateProducts.length > 1) {
      return this.associateUpcService.candidate.candidateProducts[this.currentCandidateProductIndex];
    } else {
      const candidateProduct = new CandidateProduct();
      candidateProduct.id = UUID.UUID();
      candidateProduct.candidateProductType = CandidateProduct.ASSOCIATE_UPC;
      return candidateProduct;
    }
  }

  setProductData() {
    if (this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_UPC) {
      this.lookupService.getProductByUpcAndApNumbers(
        this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].upc,
        []).subscribe((productData) => {
        this.productData = productData;
        this.associateUpcService.candidate.productId = productData.productId;
        this.setCaseUpcData(productData);
      });
    } else if (this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_ITEM) {
      this.lookupService.getProductByItemCodeAndApNumbers(
        this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, [])
        .subscribe((productData) => {
          this.productData = productData;
          this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].upc = productData.primaryScanCodeId;
          this.setCaseUpcData(productData);
        });
    }
  }

  initializeData() {
    this.candidateError = this.associateUpcService.getAssociateCasePackDetailsError();
    this.candidateProductError = this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id] ?
      this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id] : new CandidateProductError();
    this.setProductData();
    this.setUpcData();
  }

  setCaseUpcData(productData: any) {
    this.caseUpcData = productData.items;
    this.setCasePack();
  }

  onClose() {
    if (this.isActivating) {
      return;
    }
    this.setProductCasePack();
    this.associateUpcService.saveCandidateAndClose();
  }

  onClickBack() {
    this.jumpBack('/piaAssociateBasicItemSetup');
  }

  jumpBack(urlToNavigate) {
    this.canNavigate = false;
    this.setProductCasePack();
    this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
      finalize(() => this.canNavigate = true)
    ).subscribe();
  }

  getActivateButtonTitle() {
    if (this.isActivating) {
      return 'Activating';
    } else {
      return 'Activate';
    }
  }

  setNewCandidateProduct() {
    if (this.associateUpcService.getCandidate().candidateProducts.length > 1) {
      this.associateUpcService.getCandidate().candidateProducts[1] = this.getCurrentCandidateProduct();
    } else {
      this.associateUpcService.getCandidate().candidateProducts.push(this.getCurrentCandidateProduct());
    }
  }

  selectedCaseChange(event, casePack) {
    if (!event) {
      this.selectedCasePack = undefined;
    } else {
      this.selectedCasePack = casePack.caseUpc;
    }
  }

  getSelectedCasePack() {
    return this.selectedCasePack;
  }

  setCasePack() {
    for (let x = 0; x < this.caseUpcData.length; x++) {
      if (this.caseUpcData[x].caseUpc === this.getCurrentCandidateProduct().caseUpc) {
        this.selectedCasePack = this.getCurrentCandidateProduct().caseUpc;
      }
    }
  }

  getItemCode() {
    for (let x = 0; x < this.productData.items.length; x++) {
      if (this.productData.items[x].caseUpc === this.getSelectedCasePack()) {
        return this.productData.items[x].itemCode;
      }
    }
  }

  onClickActivate() {
    this.validateAndActivate();
  }

  /**
   * Validates and activates a candidate on the Case Selection Page.
   */
  validateAndActivate() {
    this.isActivating = true;
    this.canNavigate = false;
    this.setCasePack();
    this.setNewCandidateProduct();
    this.setProductCasePack();
    this.getCurrentCandidateProduct().caseUpc = this.getSelectedCasePack();
    this.getCurrentCandidateProduct().itemCode = this.getItemCode();
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), [CandidateValidatorType.ASSOCIATE_FINAL_REVIEW_VALIDATOR]).subscribe(() => {
      this.candidateService.activateCandidate(this.associateUpcService.getCandidate()).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE,
          TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
          'Successfully activated candidate.'
        );
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
        this.isActivating = false;
      });
    }, (error) => {
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.candidateError.candidateProductErrors[this.associateUpcService.getCandidate().candidateId];
        if (this.candidateProductError.caseUpc) {
          this.growlService.addError(this.candidateProductError.caseUpc);
        }
        this.associateUpcService.updatePageErrors(this.candidateError);
      } else {
        this.growlService.addError(error.message);
      }
      this.isActivating = false;
      this.canNavigate = true;
    });
  }

  /**
   * Complete Task and Route to Task Page but the method is meant for PIA Only.
   *
   * @param action
   * @param taskDecision
   * @param growlMessage
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.associateUpcService.getCandidate().vendor.apNumber, this.associateUpcService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
        this.isActivating = false;
      });
  }

  /**
   * Sets the data on a retail info object to be displayed on a table.
   * @param upc
   */
  setUpcData() {
    if (this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex]
      .candidateProductType === CandidateProduct.SEARCHED_UPC) {
      this.lookupService.getUpc(this.associateUpcService.getCandidate()
        .candidateProducts[this.searchedCandidateProductIndex].upc).subscribe(
        (upc) => {
          this.associateUpcService.getCandidate().masterHeight = upc.height;
          this.associateUpcService.getCandidate().masterWidth = upc.width;
          this.associateUpcService.getCandidate().masterLength = upc.length;
          this.associateUpcService.getCandidate().retailSize = upc.size;
          this.associateUpcService.getCandidate().unitOfMeasure = upc.unitOfMeasure;
          this.associateUpcService.getCandidate().candidateProducts[0].subBrand = upc.subBrand;
          this.associateUpcService.getCandidate().totalVolume = upc.sellingSizeOne;
        });
    } else if (this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex]
      .candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      this.lookupService.getItem(
        this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].itemCode)
        .subscribe((productData) => {
          // using the the upc from the item information to get all the needed fields
          this.lookupService.getUpc(productData.containedUpc.upc.scanCodeId).subscribe(
            (upc) => {
              this.associateUpcService.getCandidate().masterHeight = upc.height;
              this.associateUpcService.getCandidate().masterWidth = upc.width;
              this.associateUpcService.getCandidate().masterLength = upc.length;
              this.associateUpcService.getCandidate().retailSize = upc.size;
              this.associateUpcService.getCandidate().unitOfMeasure = upc.unitOfMeasure;
              this.associateUpcService.getCandidate().candidateProducts[0].subBrand = upc.subBrand;
              this.associateUpcService.getCandidate().totalVolume = upc.sellingSizeOne;
            });
        });
    }
  }

  setProductCasePack() {
    for (const candidateProduct of this.associateUpcService.getCandidate().candidateProducts.slice(1)) {
      candidateProduct.caseUpc = this.getSelectedCasePack();
    }
  }

  onClickNext() {
    this.canNavigate = false;

    this.setCasePack();
    this.setNewCandidateProduct();
    this.setProductCasePack();
    this.getCurrentCandidateProduct().caseUpc = this.getSelectedCasePack();
    this.getCurrentCandidateProduct().itemCode = this.getItemCode();
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), [CandidateValidatorType.PIA_ASSOCIATE_CASE_PACK_DETAILS_VALIDATOR]).subscribe(() => {
      this.candidateError = this.associateUpcService.setAssociateCasePackDetailsError(new CandidateError());
      this.associateUpcService.saveCandidateAndNavigateTo('/piaAssociateExtendedAttributes', false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      this.associateUpcService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.associateUpcService.setAssociateCasePackDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id];
        this.associateUpcService.saveCandidateAndNavigateTo('/piaAssociateExtendedAttributes', false).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      } else {
        this.growlService.addError(error.error);
        this.canNavigate = true;
      }
    });
  }


  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case AssociateUpcStepperComponent.ORIGINAL_UPC_ITEM_CODE: {
        this.jumpBack('/piaAssociateUpcSetup');
        break;
      }
      case AssociateUpcStepperComponent.ITEM_DETAILS: {
        this.onClickBack();
        break;
      }
      case AssociateUpcStepperComponent.EXTENDED_ATTRIBUTES: {
        this.onClickNext();
        break;
      }
    }
  }

}
