export class ErrorProductLineTable {
  hasUpcError: boolean;
  hasUpcCheckDigitError: boolean;
  hasCaseUpcError: boolean;
  hasCaseUpcCheckDigitError: boolean;
  hasDescriptionError: boolean;
  hasVendorProductCodeError: boolean;
  hasCFD1Error: boolean;
  hasRomanceCopyError: boolean;
  hasCountryOfOriginError: boolean;
  hasWarehouseError: boolean;
  caseUpcMessage: string;
  upcMessage: string;

  constructor(hasUpcError: boolean, hasUpcCheckDigitError: boolean, hasCaseUpcError: boolean, hasCaseUpcCheckDigitError: boolean,
              hasDescriptionError: boolean, hasVendorProductCodeError: boolean, hasCFD1Error: boolean, hasCountryOfOriginError: boolean,
              hasWarehouseError: boolean, hasRomanceCopyError: boolean) {
    this.hasUpcError = hasUpcError;
    this.hasUpcCheckDigitError = hasUpcCheckDigitError;
    this.hasCaseUpcError = hasCaseUpcError;
    this.hasCaseUpcCheckDigitError = hasCaseUpcCheckDigitError;
    this.hasDescriptionError = hasDescriptionError;
    this.hasVendorProductCodeError = hasVendorProductCodeError;
    this.hasCFD1Error = hasCFD1Error;
    this.hasRomanceCopyError = hasRomanceCopyError;
    this.hasCountryOfOriginError = hasCountryOfOriginError;
    this.hasWarehouseError = hasWarehouseError;
    this.caseUpcMessage = '';
    this.upcMessage = '';
  }
}
