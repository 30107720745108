<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4 helper-col">

  </div>
</div>

<ng-template #card>
  <pm-card title="Images"
    subtitle="Images are vital to success in our eComm channels, but that’s not their only use. We use these images for coupons and promotional materials as well.">
    <pm-sub-title> See our <a [href]="globalContentStandardsUrl">Global Content Standards</a> for more information</pm-sub-title>
    <ng-container *ngIf="model">
      <pm-attribute-display-image-upload [attribute]="imageUploadConfiguration" [(model)]="model.imageLinks"
        [attributeError]="errorModel?.imageLinks" (change)="imageChange($event)">
      </pm-attribute-display-image-upload>
    </ng-container>

  </pm-card>
</ng-template>
