<input type="file" class="inputfile" #fileInput id="fileInput" style="display:none;"
  accept=".doc, .pdf, .xls, .xlsx, .txt, .csv, .docx, .ppt, .pptx" (change)="onFilesAdded(fileInput.files)"
  (click)="fileInput.value = null" value="" multiple>

<div class="ui-upload-container" *ngIf="!attachments?.length">
  <pm-progress-spinner [showSpinner]="isInProgress" [style]="{width: '30px', height: '30px', left: '33%'}">
  </pm-progress-spinner>

  <div pmDragDrop (files)="filesDropped($event)" *ngIf="!isInProgress" class="p-3">

    <div class="" class="upload-container">
      <h3 class="img-sub-title">Drop file here</h3>
      <p>Drag and drop files here or click to open your file picker.</p>

      <p-button label="Add attachments" class="uploadButton ghost"
        onclick="document.getElementById('fileInput').click();"></p-button>
    </div>
  </div>
</div>
<div class="ui-attachments-container" *ngIf="attachments?.length">
  <ul *ngIf="attachments.length" class="attachment-list">
    <li class="attachment" *ngFor="let file of attachments">

      <div>
        <label (click)="downloadFile(file)" class="file-name-doc " [pTooltip]="file.name">{{file.name}}</label>
        <i class="pi pi-times" (click)="removeFile(file)"></i>
      </div>
    </li>
  </ul>

  <pm-progress-spinner [showSpinner]="isInProgress" [style]="{width: '30px', height: '30px', left: '33%'}">
  </pm-progress-spinner>

</div>
<div class="image-footer">
  <div class="mt-3" *ngIf="attachments?.length">
    <p-button label="Upload attachments" class="uploadButton ghost" onclick="document.getElementById('fileInput').click();">
    </p-button>
  </div>
  <div *ngIf="error" class="upload-error-message">
    <span [innerHTML]="fileErrors()"></span>

  </div>
</div>
