<pm-review *ngIf="isViewingPage" (closeEvent)="onClose()">
  <div class="row justify-content-md-left pt-3 pb-3">
    <pm-attribute-grid [title]="'Get help'">
      <label class="mb-3">If you need help, look through our training materials, give us a call, or email us.</label>
      <br>
      <div class="row col-md-12">
        <div class="col-md-4">
          <h4>Training materials</h4>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="'../../assets/documents/PAM Supplier Warehouse New Item.docx'"
               [download]="'SupplierNewItemSetUp.docx'">
              Supplier new item set up
            </a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="'../../assets/documents/PAM Supplier MRT.docx'"
               [download]="'SupplierMRTSetUp.docx'">
              Supplier MRT set up
            </a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="dsdNewItemSetupUrl">Supplier DSD new item set up</a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="authorizeAnItemDSDUrl">Supplier authorize an item (DSD only)</a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="casePackSetupUrl">Supplier case pack set up</a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="bonusItemSetupUrl">Supplier bonus item set up</a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="replacementItemSetupUrl">Supplier replacement item set up</a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="associateItemSetupUrl">Supplier associate item set up</a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="upcBarcodeFormatGuideUrl">UPC and Barcode Format Guidelines</a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="globalContentStandardsUrl">Global Content Standards</a>
          </div>
          <div>
            <i class="pi pi-cloud-download"></i>
            <a [href]="OwnBrandUpcReservationVTAurl">Own Brand UPC Reservation VTA</a>
          </div>
        </div>
        <div class="col-md-6">
          <h4>Product team</h4>
          <i class="pi pi-mobile"></i>
          <b>Phone:</b>
          <a class="blue-color-text"> (210) 938-7800</a> or
          <a class="blue-color-text">(800) 765-8335</a>, ext. 1
          <br>
          <i class="pi pi-envelope"></i>
          <b>Email:</b>
          <a class="blue-color-text"> ml.productteam@heb.com</a>
        </div>
      </div>
      <br>
      <div class="row col-md-12">
        <div class="col-md-12">
          <h4>Supplier help desk</h4>
          <i class="pi pi-mobile"></i>
          <b>Phone:</b>
          <a class="blue-color-text"> (210) 938-9836</a>
        </div>
      </div>
      <br>
      <div class="row col-md-12">
        <div class="col-md-12">
          <h4>Feedback</h4>
          <p>Use the form below to let us know how we’re doing or how we can improve. If you’re experiencing an urgent technical issue, contact one of our support teams listed above.</p>
        </div>
      </div>
      <br>
      <div class="row col-md-12">
        <div class="col-md-12">
          <a>How would you rate your experience using PAM so far?</a>
          <br>
          <ngb-rating [(ngModel)]="rating" [max]="5" style="color: blue;"></ngb-rating>
        </div>
      </div>
      <br>
      <div class="row col-md-12 mb-5">
        <div class="col-md-12">
          <a>Have a suggestion, see something that’s missing, or like something we’re doing?</a>
          <br>
          <textarea id="feedbackText" class="full-width" [(ngModel)]="feedbackText" rows="12"></textarea>
          <br>
          <p-checkbox [(ngModel)]="wantContact" label="Please check if you’d like us to contact you." [binary]="true"></p-checkbox>
          <br>
        </div>
      </div>
    </pm-attribute-grid>
  </div>
  <p-footer class="row">
    <div class="col-md-2 offset-11">
      <button pButton type="button" class="ghost m-2" (click)="sendFeedback()" label="Submit"></button>
    </div>
  </p-footer>
</pm-review>
