import {BaseAttribute} from './base-attribute';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayTypeaheadComponent} from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';
import {AttributeTypeaheadConfig, CandidateProduct} from 'pm-models';

export class QualifyingCondition implements BaseAttribute {

  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'Qualifying conditions',
      description: '',
      isRequired: false,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: '',
      displayRef: 'description',
      placeholderText: 'Select a qualifying condition...',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidateProduct: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidateProduct.qualifyingCondition;
    componentInstance.selection.subscribe(x => {
      candidateProduct.qualifyingCondition = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidateProduct: CandidateProduct) {
    if (componentInstance.model !== candidateProduct.qualifyingCondition) {
      componentInstance.model = candidateProduct.qualifyingCondition;
    }
  }
}
