import { BaseAttribute } from './base-attribute';
import { AttributeDualInputConfig, Candidate } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayDualNumericInputComponent } from '../../shared/attribute-display/attribute-display-dual-numeric-input/attribute-display-dual-numeric-input.component';

export class SuggestedRetail implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayDualNumericInputComponent;
  }

  getAttributeConfig(overrides?): AttributeDualInputConfig {
    return {
      collections: [],
      description: 'Enter the recommended price for this product.',
      label: 'Suggested retail',
      name: 'suggestedRetailId',
      options: [],
      isRequired: true,
      input1DecimalCount: 0,
      input1NumberCount: 3,
      input1Placeholder: '#',
      input2DecimalCount: 2,
      input2Placeholder: '$',
      input2NumberCount: 9,
      separatingText: 'for',
      isDisabled: () => false,
      isReadOnly: () => false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayDualNumericInputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.firstInputModel = candidate.suggestedXFor;
    componentInstance.firstInputModelChange.subscribe(x => {
      candidate.suggestedXFor = x;
    });

    componentInstance.secondInputModel = candidate.suggestedRetailPrice;
    componentInstance.secondInputModelChange.subscribe(x => {
      candidate.suggestedRetailPrice = x;
    });
  }
}
