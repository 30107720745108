import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';
import { AttributeVolumeConfig } from 'pm-models';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'pm-attribute-display-volume',
  templateUrl: './attribute-display-volume.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-volume.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayVolumeComponent extends BaseAttributeDisplayComponent implements OnInit {
  @Input()
  attribute: AttributeVolumeConfig;

  @Input()
  divisor: number;

  // ---Length
  lengthValue;
  @Input()
  get lengthModel() {
    return this.lengthValue;
  }
  set lengthModel(val) {
    this.lengthValue = val;
    this.calcVolume();
    this.lengthModelChange.emit(this.lengthValue);
  }
  @Output() lengthModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() lengthFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() lengthBlur?: EventEmitter<any> = new EventEmitter<any>();

  onLengthBlur(event) {
    this.lengthBlur.emit(event);
    this.lengthModelChange.emit(this.lengthModel);
  }

  onLengthFocus(event) {
    this.lengthFocus.emit(event);
  }

  @Input()
  public attributeLengthError?: any;

  // --- Width
  widthValue;
  @Input()
  get widthModel() {
    return this.widthValue;
  }
  set widthModel(val) {
    this.widthValue = val;
    this.calcVolume();
    this.widthModelChange.emit(this.widthValue);
  }
  @Output() widthModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() widthFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() widthBlur?: EventEmitter<any> = new EventEmitter<any>();

  onWidthBlur(event) {
    this.widthBlur.emit(event);
    this.widthModelChange.emit(this.widthModel);
  }

  onWidthFocus(event) {
    this.widthFocus.emit(event);
  }

  @Input()
  public attributeWidthError?: any;

  // --- Height
  heightValue;
  @Input()
  get heightModel() {
    return this.heightValue;
  }
  set heightModel(val) {
    this.heightValue = val;
    this.calcVolume();
    this.heightModelChange.emit(this.heightValue);
  }
  @Output() heightModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() heightFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() heightBlur?: EventEmitter<any> = new EventEmitter<any>();

  onHeightBlur(event) {
    this.heightBlur.emit(event);
    this.heightModelChange.emit(this.heightModel);
  }

  onHeightFocus(event) {
    this.heightFocus.emit(event);
  }

  @Input()
  public attributeHeightError?: any;

  // --- Volume
  volumeValue;
  @Input()
  get volumeModel() {
    return this.volumeValue;
  }
  set volumeModel(val) {
    this.volumeValue = val;
    this.volumeModelChange.emit(this.volumeValue);
  }

  @Output() volumeModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public attributeVolumeError?: any;

  constructor(private decimalPipe: DecimalPipe) {
    super();
  }

  ngOnInit() {
    this.calcVolume();
  }

  calcVolume() {
    // 1728 is the divider to calculate in cubic feet from inches.
    // If Dru divisor is 1, set on the html for the attribute.
    const vol = (this.lengthModel * this.widthModel * this.heightModel) / this.divisor;

    if (vol > 0) {
      this.volumeModel = parseFloat(
        this.decimalPipe.transform(vol, '1.0-5' + this.attribute.decimalCount + '').replace(/,\s?/g, '')
      );
    } else {
      this.volumeModel = '';
    }
  }
}
