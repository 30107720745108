import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  CandidateProductError,
  Commodity, SubDepartment,
  TextInputType
} from 'pm-models';
import {MerchandisingInfoCardModel, MerchandisingInfoCardPermissions} from 'pm-models/lib/card-models/merchandising-info-card-model';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper, VisiblePermissionsMapper} from '../attribute-permissions-mapper';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';

@Component({
  selector: 'pm-merchandising-info-card',
  templateUrl: './merchandising-info-card.component.html',
  styleUrls: ['./merchandising-info-card.component.scss']
})
export class MerchandisingInfoCardComponent implements OnInit, OnChanges {

  @Input()
  candidateModel: Candidate | MerchandisingInfoCardModel;

  @Input()
  candidateProductModel: CandidateProduct | MerchandisingInfoCardModel;

  @Input()
  permissions: MerchandisingInfoCardPermissions;

  @Input()
  candidateErrorModel: CandidateError;

  @Input()
  candidateProductErrorModel: CandidateProductError;

  @Input() canEditDepartment: boolean = false;

  @Output() commodityChange = new EventEmitter<any>();

  @Output() subCommodityChange = new EventEmitter<any>();

  @Output() overrideSubDepartmentChange = new EventEmitter<any>();

  _merchandiseTypes;

  @Input()
  get merchandiseTypes() {
    return this._merchandiseTypes;
  }

  set merchandiseTypes(e) {
    this._merchandiseTypes = e;
    this.merchandiseTypeConfiguration.collections = this._merchandiseTypes;
  }

  _commodities;
  @Input()
  get commodities() {
    return this._commodities;
  }

  set commodities(e) {
    this._commodities = e;
    this.commodityConfiguration.collections = this._commodities;
  }

  public subCommodities: any;
  public pssDepartments: any;

  @Output() candidateModelChange = new EventEmitter<any>();

  @Output() candidateProductModelChange = new EventEmitter<any>();

  @Output() taxableChange = new EventEmitter<any>();

  merchandiseTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Merchandise type',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.merchandiseType),
    name: '',
    displayRef: 'description',
    placeholderText: 'Select a merchandise type...',
    collections: this.merchandiseTypes
  };

  commodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Commodity',
    description: 'Choose an appropriate category.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.commodity),
    name: 'commodityId',
    displayRef: 'commodityName',
    placeholderText: 'Select a commodity...',
    collections: this.commodities
  };

  subCommodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-commodity',
    description: 'Choose an appropriate subcategory.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.subCommodity),
    name: 'subcommodityId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-commodity...',
    collections: this.subCommodities
  };


  pssDepartmentConfiguration: AttributeTypeaheadConfig = {
    label: 'PSS Department',
    description: 'Select the point of sale sub-department.',
    isRequired: true,
    isDisabled: () => this.isPssDepartmentDisabled(),
    isReadOnly: () => false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.pssDepartment),
    name: 'pssDepartmentId',
    displayRef: 'displayName',
    placeholderText: 'Select a PSS Department...',
    idRef: 'pssDepartmentId',
    collections: this.pssDepartments
  };


  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => this.isSeasonHidden(),
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };

  yearConfiguration: AttributeTextInputConfig = {
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => this.isYearHidden(),
    inputGroupClass: 'season-year-card-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };

  descriptionConfiguration: AttributeTextInputConfig = {
    label: 'Product Description',
    description: `Enter a description of this product. Many H-E-B apps will refer to this description.`,
    placeholderText: 'Description',
    isHidden: () => this.isProductDescriptionHidden(),
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.description),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.description),
    isRequired: true,
    name: 'productDescriptionId'
  };

  isProductDescriptionHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.description) ||
      (this.candidateModel && this.candidateModel.productType === Candidate.SELLABLE) ||
      (this.candidateModel && this.candidateModel.productType !== Candidate.NON_SELLABLE &&
        !this.candidateModel.dsdSwitch);
  }

  likeItemCodeConfiguration: AttributeTextInputConfig = {
    label: 'Like item code',
    description: 'Enter an existing item code to use during forecasting.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => this.isLikeItemCodeHidden(),
    name: '',
    inputGroupClass: 'typeahead-width',
    textInputType: TextInputType.integer
  };


  estimatedStoreCountConfiguration: AttributeTextInputConfig = {
    label: 'Estimated store count',
    description: 'Estimated number of stores that will carry this product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => this.isEstimatedStoreCountHidden(),
    inputGroupClass: 'ui-calculated-text-input',
    name: '',
    textInputType: TextInputType.integer
  };

  departmentConfiguration: AttributeTypeaheadConfig = {
    label: 'Department',
    description: 'Choose a department.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.subCommodity),
    placeholderText: 'Department Name',
    displayRef: 'displayName',
    searchUrl: '/lookup/subDepartment',
    idRef: 'normalizedId'
  };

  defaultDepartmentConfiguration: AttributeTextInputConfig = {
    label: 'Department',
    description: '',
    name: 'departmentId',
    isDisabled: () => true,
    isReadOnly: () => true,
    inputGroupClass: 'typeahead-width',
    placeholderText: ''
  };

  constructor(private lookupService: LookupService) {
  }

  ngOnInit() {
    if (!this.pssDepartmentConfiguration.collections || this.pssDepartmentConfiguration.collections.length === 0) {
      this.getPssDepartments();
    }
  }

  ngOnChanges() {
    if (!this.subCommodityConfiguration.collections || this.subCommodityConfiguration.collections.length === 0) {
      if (this.candidateModel.commodity && this.candidateModel.commodity.subCommodityList) {
        this.getSubCommodities(this.candidateModel.commodity);
      }
    }
    if (!this.pssDepartmentConfiguration.collections || this.pssDepartmentConfiguration.collections.length === 0) {
        this.getPssDepartments();
    }
  }

  merchandiseTypeChange(event) {
    this.candidateModel.merchandiseType = event;
  }

  onCommodityChange(event) {
    this.candidateModel.commodity = event;
    this.candidateModel.subCommodity = null;
    this.candidateModel.overrideDepartment = false;
    this.candidateModel.overrideSubDepartment = null;
    this.getSubCommodities(event);
    this.getPssDepartments();

    if (!event || !event.commodityId) {
      this.candidateModel.pssDepartment = null;
    } else {
      this.candidateModel.pssDepartment = event.pssDepartment;
    }
    this.commodityChange.emit(event);

    // TODO: Cost link validation on API
    // if (costLink.commodity !== Number(toValidate.commodity.commodityId)) {
    //   return 'Cost Link not valid for this Commodity. Proceed without a Cost Link or send candidate back to Supplier to request' +
    //     ' a valid Cost Link for the selected Commodity.';
    // }
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
    this.subCommodityConfiguration.collections = this.subCommodities;
  }

  getPssDepartments() {
    if (!this.candidateModel.overrideDepartment) {
      if (this.candidateModel.commodity && this.candidateModel.commodity.subDepartment == null) {
        // Make call to get sub department with pss departments when commodity does not have department info.
        this.lookupService.findSubDepartment(this.candidateModel.commodity.departmentId + this.candidateModel.commodity.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
          this.pssDepartmentConfiguration.collections = this.pssDepartments;
        });
      } else {
        this.pssDepartments = this.candidateModel.commodity.subDepartment.pssDepartments;
        this.pssDepartmentConfiguration.collections = this.pssDepartments;
      }
    } else {
      if (this.candidateModel.overrideSubDepartment.pssDepartments === null || this.candidateModel.overrideSubDepartment.pssDepartments === undefined) {
        this.lookupService.findSubDepartment(this.candidateModel.overrideSubDepartment.departmentId + this.candidateModel.overrideSubDepartment.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
          this.pssDepartmentConfiguration.collections = this.pssDepartments;
        });
      } else {
        this.pssDepartments = this.candidateModel.overrideSubDepartment?.pssDepartments;
        this.pssDepartmentConfiguration.collections = this.pssDepartments;
      }
    }
  }

  onSubCommodityChange(event) {
    this.candidateModel.subCommodity = event;
    this.candidateModel.foodStamp = CandidateHelper.isFoodStamp(this.candidateModel);
    this.candidateModel.taxable = CandidateHelper.isTaxCode(this.candidateModel);
    this.taxableChange.emit();
    this.subCommodityChange.emit(event);
  }

  onSubCommodityDepartmentChange(event: SubDepartment) {
    if (event) {
      this.candidateModel.overrideSubDepartment = event;
    } else {
      this.candidateModel.overrideSubDepartment = null;
    }
    this.candidateModel.pssDepartment = null;
    this.getPssDepartments();
    this.overrideSubDepartmentChange.emit();
  }

  getDepartment() {
    if (this.candidateModel.commodity) {
      return this.candidateModel.commodity.departmentDescription + ' - ' +
        this.candidateModel.commodity.subDepartmentDescription;
    }
    return '';
  }

  seasonChange(event) {
    this.candidateModel.season = event;
  }

  get isSellable(): boolean {
    return this.candidateModel && this.candidateModel.productType === 'SELLABLE';
  }

  isPssDepartmentDisabled() {
    return !this.candidateModel || !this.candidateModel.commodity ||
      (this.permissions.pssDepartment && this.permissions.pssDepartment.isDisabled);
  }

  pssDepartmentChange(event) {
    this.candidateModel.pssDepartment = event;
  }

  isNonSellable() {
    return this.candidateModel.productType === Candidate.NON_SELLABLE;
  }
  isDsdOnly() {
    return !this.candidateModel.warehouseSwitch && this.candidateModel.dsdSwitch;
  }
  isSeasonHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.season) ||
      (!this.isNonSellable() && this.isDsdOnly());
  }
  isYearHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.seasonYear) ||
      (!this.isNonSellable() && this.isDsdOnly());
  }
  isLikeItemCodeHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.likeId) ||
      (!this.isNonSellable() && this.isDsdOnly());
  }
  isEstimatedStoreCountHidden() {
    return VisiblePermissionsMapper(this.permissions, this.permissions.numberOfStores) ||
      (!this.isNonSellable() && this.isDsdOnly());
  }

  /**
   * Only toggle if there's a commodity. If toggled to not have an override, reset the override sub department model.
   */
  toggleDepartmentOverride() {
    if (this.candidateModel.commodity) {
      this.candidateModel.overrideDepartment = !this.candidateModel.overrideDepartment;
      if (!this.candidateModel.overrideDepartment) {
      }
      this.overrideSubDepartmentChange.emit();
    }
    this.candidateModel.overrideSubDepartment = null;
    this.candidateModel.pssDepartment = null;
    this.getPssDepartments();
  }
}
