import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssociateUpcStepperComponent } from './associate-upc-stepper.component';
import {StepperModule} from '../stepper/stepper.module';

@NgModule({
  declarations: [AssociateUpcStepperComponent],
  exports: [AssociateUpcStepperComponent],
  imports: [CommonModule, StepperModule]
})
export class AssociateUpcStepperModule {}
