import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InputState, UPCInputState} from 'pm-components';
import {
  AttachmentsCardPermissions,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError, CandidateValidatorType,
  CaseDetailsCardPermissions,
  CostCardPermissions,
  CostLinkCardPermissions,
  DisplayReadyUnitCardPermissions,
  ImportedProductCardPermissions,
  InnerPackDetailsCardPermissions,
  MasterPackDetailsCardPermissions,
  OmiRemarksCardPermissions,
  PricingCardPermissions, TaskDecision
} from 'pm-models';
import {AuthService} from '../../auth/auth.service';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {PharmProductService} from '../../service/pharm-product.service';
import {WorkflowService} from '../../service/workflow.service';
import {calculateCheckDigit} from '../../shared/upc.utils';

@Component({
  selector: 'app-pharm-new-product-case-details',
  templateUrl: './pharm-new-product-case-details.component.html',
  styleUrls: ['./pharm-new-product-case-details.component.scss']
})
export class PharmNewProductCaseDetailsComponent implements OnInit {

  public isViewingPage = true;
  private taskSubscription$: any;
  public candidate: Candidate;
  public currentCandidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public costLinkState: InputState;
  public upcState: UPCInputState;
  public retailLinkState: InputState;
  public containerSizes: any;
  public incoTermsList: any;


  readonly masterPackDetailsCardPermissions: MasterPackDetailsCardPermissions = {
    isReadOnly: false,
    masterWeight: {
      isHidden: true
    },
    masterLength: {
      isHidden: true
    },
  };
  readonly innerPackDetailsCardPermissions: InnerPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly displayReadyUnitCardPermissions: DisplayReadyUnitCardPermissions = {
    isReadOnly: false
  };
  readonly costLinkCardPermissions: CostLinkCardPermissions = {
    isReadOnly: false
  };
  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false,
    pennyProfit: {
      isHidden: true
    },
    marginPercent: {
      isHidden: true
    },
    masterPack: {
      isHidden: true
    }
  };
  readonly pricingCardPermissions: PricingCardPermissions = {
    isReadOnly: false,
  };
  readonly omiRemarksPermissions: OmiRemarksCardPermissions = {
    isReadOnly: false,
  };

  readonly attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };
  readonly importProductCardPermissions: ImportedProductCardPermissions = {
    isReadOnly: false
  };

  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions = {
    isReadOnly: false,
    itemWeightType: {
      isReadOnly: false,
      isDisabled : !this.pharmProductService.isTiedToCatchOrVariableWeightBuyer()
    },
    distributionChannel: {
      isHidden: true
    },
    buyer: {
      isHidden: true
    },
    dealOffered: {
      isHidden: true
    },
    caseDescription: {
      isHidden: true
    },
    caseUpc: {
      isHidden: true
    },
    caseUpcCheckDigit: {
      isHidden: true
    }
  };

  canClickSubmit: boolean = true;

  private validators: CandidateValidatorType[] = [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR,
    CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR, CandidateValidatorType.PHARM_PRODUCT_DETAILS_VALIDATOR,
    CandidateValidatorType.PIA_PRODUCT_CASE_DETAILS_VALIDATOR, CandidateValidatorType.SCA_PRODUCT_REVIEW_VALIDATOR];

  constructor(public route: ActivatedRoute, public router: Router, public pharmProductService: PharmProductService,
              public authService: AuthService, public candidateService: CandidateService,
              public candidateUtilService: CandidateUtilService, public growlService: GrowlService,
              public lookupService: LookupService,
              private workflowService: WorkflowService) { }


  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      let taskId;
      if (this.pharmProductService.getTaskId()) {
        taskId = this.pharmProductService.getTaskId();
      } else if (this.pharmProductService.getTask() && this.pharmProductService.getTask().id) {
        taskId = this.pharmProductService.getTask().id;
      }
      if (params.keys.length > 0 && (!(taskId && params.has('taskId') && params['params']['taskId'] === taskId))) {
        this.pharmProductService.resetService();
        this.pharmProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']).then();
          }
        });
      } else if (this.pharmProductService.getOriginalCandidate() && this.pharmProductService.getCandidate()) {
        this.initializeData();
      }  else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  initializeData() {
    this.candidate = this.pharmProductService.getCandidate();

    // set defaults for Pharmacy
    this.candidate.retailXFor = 1;
    this.candidate.retailType = 'Key Retail';
    this.candidate.candidateProducts[0].countryOfOrigin = {
      countryId: 77,
      description: 'UNITED STATES '
    };
    this.candidate.innerPackSelected = false;

    this.originalCandidate = this.pharmProductService.getOriginalCandidate();
    this.currentCandidateProduct = this.pharmProductService.getCurrentCandidateProduct();
    this.candidateError = this.pharmProductService.getCasePackDetailsError();
    this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
    this.lookupService.findAllContainerSizes().subscribe(containerSizes => {
      this.containerSizes = containerSizes;
    });
    this.lookupService.findAllIncoTerms().subscribe(incoTermsList => {
      this.incoTermsList = incoTermsList;
    });
  }

  onClose() {
    this.isViewingPage = false;
    this.pharmProductService.saveCandidateAndClose();
  }

  onClickNext() {
    this.setPharmacyDefaults();

    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.PHARMACY_PRODUCT_CASE_DETAILS_VALIDATOR]).toPromise().then(() => {
      this.candidateError = this.pharmProductService.setCasePackDetailsError(new CandidateError());
      this.candidateProductError = new CandidateProductError();

      this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductWarehouses', false);
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = this.pharmProductService.setCasePackDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
        if (!this.pharmProductService.isDsdOnly()) {
          this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductWarehouses', false);
        }
      }
    });
  }

  onClickBack() {
    this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductDetails', false);
  }


  onInnerPackChange() {
    if (!this.candidate.innerPackSelected) {
      this.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
  }

  innerPackQuantityChange() {
    if (this.candidateProductError && this.candidateProductError.caseUpc) {
      this.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
  }

  validateUpc() {
    this.upcState = UPCInputState.loading;
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.CASE_UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.caseUpc = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc / check digit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const candidateProductId = this.candidate.candidateProducts[0].id;
        const candidateErrors: CandidateError = error.error.candidateErrors;
        const candidateProductError: CandidateProductError =
          candidateErrors.candidateProductErrors[candidateProductId];

        if (this.candidateError.candidateProductErrors.size === 0 ||
          !this.candidateError.candidateProductErrors[candidateProductId]) {

          this.candidateError.candidateProductErrors.set(candidateProductId,
            candidateProductError);
          this.candidateProductError = candidateProductError;
        } else {
          this.candidateProductError.caseUpc = candidateProductError.caseUpc;
          this.candidateProductError.caseUpcCheckDigit = candidateProductError.caseUpcCheckDigit;
        }
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }


  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.candidate.costLinkFromServer = undefined;
    this.candidateError.costLink = undefined;
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((candidate) => {
      this.costLinkState = InputState.valid;
      this.candidate.costLinkFromServer = candidate.costLinkFromServer;
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.candidateError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  validateRetailLink() {
    this.retailLinkState = InputState.loading;
    this.candidateError.retailLink = undefined;
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).subscribe((candidate) => {
      this.retailLinkState = InputState.valid;

      this.candidate.retailLink = candidate.retailLink;
      this.candidate.retailXFor = candidate.retailXFor;
      this.candidate.retailPrice = candidate.retailPrice;

      this.candidateError.retailLink = undefined;
    }, (error) => {
      this.retailLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.candidateError.retailLink = error.error.candidateErrors.retailLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  onClickActivate() {
    this.setPharmacyDefaults();

    // validate the candidate for this page and send errors to components to display, if any
    this.canClickSubmit = false;
    this.candidateService.validateCandidate(this.candidate, this.validators).toPromise().then(() => {

      if (this.pharmProductService.getCandidate().showCalories &&
        !this.pharmProductService.isScaleProduct(this.pharmProductService.candidate)) {
        // just leveraging this WF because if follows needed flow
        this.saveAndCompletePharmTask();

      } else if (this.pharmProductService.isScaleProduct(this.pharmProductService.candidate)) {
        this.saveAndCompletePharmTask();
      } else {
        this.candidateService.activateCandidate(this.candidate).subscribe(() => {
          this.completeTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE,
            TaskDecision.PHARM_FINAL_REVIEW_APPROVE_DECISION,
            'Successfully activated candidate.'
          );
        }, (error) => {
          this.growlService.addError(error);
          this.canClickSubmit = true;
        });
      }
    }, (error) => {
      this.pharmProductService.scrollToTop();
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.pharmProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.pharmProductService.getWarehouseError();
        this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canClickSubmit = true;
    });
  }

  private saveAndCompletePharmTask() {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.pharmProductService.setCandidate(savedCandidate);
      this.candidate = savedCandidate;
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_SCALE_PLU_APPROVE_YES, 'Successfully completed task.');
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.candidate.vendor.apNumber, this.pharmProductService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( () => {
          this.canClickSubmit = true;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.canClickSubmit = true;
      });
  }

  /**
   * Set the defaults for Pharmacy items since they aren't visible in the UI.
   */
  private setPharmacyDefaults() {
    if (this.candidate.candidateProducts[0].candidateProductType === Candidate.PLU) {
      this.candidate.candidateProducts[0].caseDescription = this.candidate.candidateProducts[0].description;
      this.candidate.candidateProducts[0].caseUpc = this.candidate.candidateProducts[0].upc;
      this.candidate.candidateProducts[0].caseUpcCheckDigit = calculateCheckDigit(this.candidate.candidateProducts[0].upc);
    } else {
      this.candidate.candidateProducts[0].caseDescription = this.candidate.candidateProducts[0].description;
      this.candidate.candidateProducts[0].caseUpc = this.candidate.candidateProducts[0].upc;
      this.candidate.candidateProducts[0].caseUpcCheckDigit = this.candidate.candidateProducts[0].upcCheckDigit;
    }
    this.candidate.masterLength = 2;
    this.candidate.masterWidth = 3;
    this.candidate.masterHeight = 4;
    this.candidate.masterWeight = 5;
  }
}
