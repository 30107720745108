<ng-container>
  <div class="row request-heb-upc-success-overlay-wrapper">
    <div class="col-md-5 request-heb-upc-success-overlay">

      <div class="reservation-confirmed-div">
        <img class="img-thumbnail" src="../../../assets/images/u123.png">
        <h1>Reservation Confirmed!</h1>
        <div *ngIf="multiReserve">
          <div class="confirmationText">
            You’ve successfully reserved multiple H-E-B UPCs! We’ll send you a confirmation email containing your UPCs and reservation details.
          </div>
          <div>You reserved:</div>
          <h3 class="mb-3">{{upcs.length}} UPCs</h3>
          <p-button class="m-2" label="Download list of UPCs" (onClick)="onClickDownloadUpcs()"></p-button>
          <p-button class="m-2" label="Return home" (onClick)="onClickReturnHome()"></p-button>
        </div>
        <div *ngIf="!multiReserve">
          <div class="confirmationText">
            You’ve successfully reserved an H-E-B UPC! We’ll send you a confirmation email containing your UPC and reservation details.
          </div>
          <div>Your H-E-B brand UPC is:</div>
          <h3 class="mb-3">{{formattedUpc}}</h3>
          <p-button class="m-2" label="Return home" (onClick)="onClickReturnHome()"></p-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
