import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate, AttributeConfig } from 'pm-models';

export class Taxable implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Taxable',
      description: 'Indicate if H-E-B should collect sales tax on this product.',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      defaultValue: false,
      name: 'taxableId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (candidate.taxable === undefined) {
      candidate.taxable = this.defaultVal;
    }
    componentInstance.model = candidate.taxable;

    componentInstance.change.subscribe(event => {
      candidate.taxable = event.checked;
    });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate) {
    componentInstance.model = candidate.taxable;
  }
}
