import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ContentChild,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';

import { TitleComponent, FooterComponent } from '../../shared/common';
import { DrawerComponent } from '../../drawer/drawer.component';

@Component({
  selector: 'pm-editor-drawer-container',
  template: '<ng-content></ng-content>'
})
export class EditorDrawerContainerComponent {}

@Component({
  selector: 'pm-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  @ContentChild(EditorDrawerContainerComponent) drawerContainerFacet;

  @ViewChild(DrawerComponent) drawer: DrawerComponent;
  @ContentChild(TitleComponent) headerFacet;

  @ContentChild(FooterComponent) footerFacet;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}
  opened: boolean = false;
  public openDrawer() {
    this.drawer.open();
    this.cd.detectChanges();
  }
  public closeDrawer() {
    this.drawer.close();
    this.cd.detectChanges();
  }
}
