<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Cost link"
           subtitle="Let us know if this case contains any cost-linked items.">
    <ng-container *ngIf="model">

      <pm-attribute-display-toggle
        [attribute]="isCostLinkedConfiguration"
        [(model)]="model.costLinked"
        (change)="isCostLinkedChange($event)">
      </pm-attribute-display-toggle>

      <pm-attribute-display-muli-source-input
        [attribute]="costLinkedConfiguration"
        [attributeError]="errorModel?.costLink"
        [model]="model.costLink"
        (modelChange)="onCostLinkChange($event)"
        [selectCategory]="model.costLinkBy"
        (selectCategoryChange)="onMultiSelectChange($event)"
        [state]="costLinkState"
        (validate)="validateCostLinkEvent($event)"
      >

      </pm-attribute-display-muli-source-input>

    </ng-container>

  </pm-card>
</ng-template>
