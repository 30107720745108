import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ImportFactorySearchBarComponent} from './import-factory-search-bar.component';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {TypeaheadModule} from '../typeahead/typeahead.module';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';

@NgModule({
  declarations: [ImportFactorySearchBarComponent],
  exports: [ImportFactorySearchBarComponent, InputTextModule, DropdownModule, TypeaheadModule, AttributeDisplayModule],
  imports: [CommonModule, HttpClientModule, FormsModule, InputTextModule, DropdownModule, TypeaheadModule, AttributeDisplayModule]
})
export class ImportFactorySearchBarModule {}
