import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent, EditorDrawerContainerComponent } from './editor.component';

import { SharedModule } from '../../shared/shared.module';
import { ButtonModule } from 'primeng/button';


import { DrawerModule } from '../../drawer/drawer.module';
@NgModule({
  declarations: [EditorComponent, EditorDrawerContainerComponent],
  imports: [
    CommonModule, SharedModule, ButtonModule, DrawerModule
  ],
  exports: [EditorComponent, EditorDrawerContainerComponent, SharedModule, ButtonModule]
})
export class EditorModule { }
