<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-mrt-inner-stepper
      [candidateErrors]="supplierMrtService.getAllCandidateInnerErrorsByCandidateId()"
      [stepIndex]="3" (indexClick)="onClickStepper($event)">
    </app-mrt-inner-stepper>
  </pm-header>

  <pm-title>Case pack</pm-title>
  <pm-sub-title *ngIf="supplierMrtService.innerCandidate">UPC: {{supplierMrtService.innerCandidate.candidateProducts[0].upc}}-{{supplierMrtService.innerCandidate.candidateProducts[0].upcCheckDigit}}</pm-sub-title>

  <ng-container *ngIf="supplierMrtService.innerCandidate">

    <pm-master-pack-details-card [(model)]="supplierMrtService.innerCandidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="innerCandidateError">
    </pm-master-pack-details-card>


    <pm-inner-pack-details-card [(model)]="supplierMrtService.innerCandidate"
                                [defaultInnerSelectedValue]="false"
                                [permissions]="innerPackDetailsCardPermissions"
                                [errorModel]="innerCandidateError"
                                (innerPackQuantityChange)="innerPackQuantityChange()">
    </pm-inner-pack-details-card>

    <pm-case-details-card [(candidateModel)]="supplierMrtService.innerCandidate"
                          [(candidateProductModel)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="innerCandidateError"
                          [candidateProductErrorModel]="innerCandidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUpc()">
    </pm-case-details-card>


    <pm-display-ready-unit-card [(model)]="supplierMrtService.innerCandidate"
                                [permissions]="displayReadyUnitCardPermissions"
                                [errorModel]="innerCandidateError">
    </pm-display-ready-unit-card>

    <pm-cost-link-card [model]="supplierMrtService.innerCandidate"
                       (validateCostLink)="validateCostLink()"
                       [costLinkState]="costLinkState"
                       [errorModel]="innerCandidateError"
                       [permissions]="costLinkCardPermissions">
    </pm-cost-link-card>

    <pm-cost-card [model]="supplierMrtService.innerCandidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="innerCandidateError">
    </pm-cost-card>

    <pm-attachments-card [(model)]="supplierMrtService.innerCandidate"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="innerCandidateError">
    </pm-attachments-card>
  </ng-container>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
