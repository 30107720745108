<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-supplier-bonus-stepper *ngIf="bonusSizeService.getCurrentCandidate()" [candidateErrors]="bonusSizeService.getAllCandidateErrors()"
                                [stepIndex]="3" (indexClick)="onClickStepper($event)"
                                [steps]="bonusSizeService.getSupplierStepperSteps()">
    </app-supplier-bonus-stepper>
  </pm-header>

  <pm-title>
    <div *ngIf="bonusSizeService.getCandidate()">{{bonusSizeService.getCaseDetailsPageTitle()}}</div>
  </pm-title>

  <div class="row" style="margin-top: 25px;">
  </div>

  <div *ngIf="bonusSizeService.currentCandidate" class="col-md-8">
    <div class="row">
      <img class="product-image" src="{{bonusSizeService?.productImageUrl ? bonusSizeService.productImageUrl : bonusSizeService.DEFAULT_NO_PRODUCT_IMAGE}}">
      <div class="col">
        <div class="upc-header">
          UPC: {{bonusSizeService?.productData?.primaryScanCodeId}}
        </div>
        <div class="attribute-description">
          <p>{{bonusSizeService?.productData?.productDescription}}</p>
          <p>{{bonusSizeService?.productInfoString}}</p>
          <div class="row" style="padding-left: 15px;">
            <button pButton type="button" label="Show existing cases" (click)="showExistingCasePacks()"class="ui-button-link"></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="bonusSizeService.currentCandidate">

    <pm-master-pack-details-card [(model)]="bonusSizeService.currentCandidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="bonusSizeService.candidateError"
                                 (masterPackChange)="onMasterPackChange($event)">
    </pm-master-pack-details-card>


    <pm-inner-pack-details-card [(model)]="bonusSizeService.currentCandidate"
                                [permissions]="innerPackDetailsCardPermissions"
                                [errorModel]="bonusSizeService.candidateError" (modelChange)="onInnerPackChange()"
                                (innerPackQuantityChange)="innerPackQuantityChange()">
    </pm-inner-pack-details-card>

    <pm-case-details-card [(candidateModel)]="bonusSizeService.currentCandidate"
                          [(candidateProductModel)]="bonusSizeService.currentCandidate.candidateProducts[bonusSizeService.currentCandidateProductIndex]"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="bonusSizeService.candidateError"
                          [candidateProductErrorModel]="bonusSizeService.candidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUPC()">
    </pm-case-details-card>

    <pm-display-ready-unit-card [(model)]="bonusSizeService.currentCandidate"
                                [permissions]="displayReadyUnitCardPermissions"
                                [errorModel]="bonusSizeService.candidateError">
    </pm-display-ready-unit-card>

    <pm-cost-link-card [model]="bonusSizeService.currentCandidate"
                       (validateCostLink)="validateCostLink()"
                       [costLinkState]="costLinkState"
                       [errorModel]="bonusSizeService.candidateError"
                       [permissions]="costLinkCardPermissions">
    </pm-cost-link-card>

    <pm-cost-card [model]="bonusSizeService.currentCandidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="bonusSizeService.candidateError">
    </pm-cost-card>

    <pm-supplier-details-card *ngIf="bonusSizeService.supplierDetailsCardPermissions" [(candidateModel)]="bonusSizeService.currentCandidate"
                              [candidateProductModel]="bonusSizeService.currentCandidate.candidateProducts[bonusSizeService.currentCandidateProductIndex]"
                              [permissions]="bonusSizeService.supplierDetailsCardPermissions"
                              [inputCostOwners]="bonusSizeService.costOwners"
                              [candidateErrorModel]="bonusSizeService.candidateError">
    </pm-supplier-details-card>

    <pm-attachments-card [(model)]="bonusSizeService.currentCandidate"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="bonusSizeService.candidateProductError">
    </pm-attachments-card>

  </ng-container>

  <pm-editor-drawer-container>
    <ng-container>
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>Existing cases</h2>
        <ng-template *ngIf="bonusSizeService?.productData?.items" ngFor let-casePack [ngForOf]="bonusSizeService?.productData?.items">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!casePack.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(casePack)"></i>
              <i *ngIf="casePack.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(casePack)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{casePack.caseUpc}}-{{casePack.caseUpc | upcCheckDigit}}
                </label>
                <i *ngIf="casePack.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                WHS | {{casePack.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="casePack.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{casePack.supplierItems[0]?.pack}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
            <!--  Inner Pack -->
            <pm-attribute-grid [title]="'Inner Packs'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Inner pack quantity'" [canEdit]="false">
                    {{casePack.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>

        <ng-template *ngIf="bonusSizeService?.productData?.dsdItems" ngFor let-dsdItem [ngForOf]="bonusSizeService?.productData?.dsdItems">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!dsdItem.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(dsdItem)"></i>
              <i *ngIf="dsdItem.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(dsdItem)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{dsdItem.upc}}-{{dsdItem.upc | upcCheckDigit}}
                </label>
                <i *ngIf="dsdItem.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                DSD | {{dsdItem.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="dsdItem.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{dsdItem?.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>

      </div>
    </ng-container>
  </pm-editor-drawer-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button *ngxPermissionsExcept="['ROLE_CATEGORY_SELECTION-EDIT']" class="m-2" label="Submit" (onClick)="onClickSubmit()" [disabled]="!canNavigate"></p-button>
    <p-button *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
