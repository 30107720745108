import {Pipe, PipeTransform} from '@angular/core';
import {calculateCheckDigit} from '../upc.utils';

@Pipe({name: 'upcCheckDigit'})
export class UpcCheckDigitPipe implements PipeTransform {
  transform(value: any, ...args): any {
    if (value) {
      return calculateCheckDigit(value);
    } else {
      return '';
    }
  }
}
