<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">
  <div class="attribute-group"
       [ngClass]="{'attribute-required': attribute.isRequired, 'attribute-full-width': attribute?.showAddlInfo}">
    <div class="attribute-label">
      <label for="">{{attribute.label}}</label>
    </div>
    <div class="attribute-control">

      <ng-template [ngIf]="!isReadOnly()" [ngIfElse]="readOnly">
        <pm-typeahead [id]="attribute?.name" [showIf]="true" [displayRef]="attribute?.displayRef"
                      [staticList]="attribute?.collections" [params]="" [debounceTime]="300" [labelName]="'Buyer'"
                      [placeholder]="attribute?.placeholderText" [maxSearchSize]="5" [maxListSize]="maxListSize" [overrideLabelClass]="" [overrideTypeAheadWidth]=""
                      [model]="model" [isDisabled]="attribute?.isDisabled && attribute?.isDisabled()" [requiredField]="true"
                      [canEdit]="true" [tabIndex]="attribute?.tabIndex" [doInitCallback]="model"
                      [idRef]="attribute?.idRef" [showTooltip]="false" [url]="attribute?.searchUrl" [hasError]="attributeError"
                      [isDropDown]="isDropdown" (selection)="handleChange($event)" (initCallBack)="onInitCallback($event)" ngDefaultControl
                      [displaySubRef]="attribute?.displaySubRef" [showAddlInfo]="attribute?.showAddlInfo" [allowMultiple]="attribute?.allowMultiple"
                      (buttonOptionClicked)="onButtonOptionClicked($event)" [showButtonOption]="attribute?.showButtonOption"
                      [buttonLabel]="attribute?.buttonLabel">

        </pm-typeahead>
      </ng-template>


      <p *ngIf="attribute.description && !attribute.hideDescription" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="attributeError && showErrorMessage" class="attribute-error-message">
        {{attributeError}}
      </p>
      <button *ngIf="attribute.showExtraButton" (click)="onExtraButtonClicked($event)" class="attribute-extra ghost row">
        {{attribute.extraButtonText}}
      </button>
      <div *ngIf="attribute.showWarningMessage" class="borderLeft col-md-8 justify-content-md-left warning-box">
        <div class="row ml-2">
          <img src="../../../assets/images/info_24px.svg">
          <div class="col ml-2">Changing the department may remove existing store authorization requests.</div>
        </div>
      </div>
    </div>

  </div>
</ng-container>
<ng-template #readOnly>
  <div class="ui-autocomplete-readonly ui-corner-all">
    <div>
      {{getReadOnlyText()}}
    </div>
    <div class="ui-autocomplete-item-description" *ngIf="attribute?.showAddlInfo">
      {{getReadOnlyDescription()}}
    </div>

  </div>
</ng-template>
