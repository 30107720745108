import { BaseAttribute } from '../base-attribute';

import { AttributeDualInputConfig, Candidate } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayDualNumericInputComponent } from '../../../shared/attribute-display/attribute-display-dual-numeric-input/attribute-display-dual-numeric-input.component';
import { PRICE_REQUIRED, RETAIL_LINK } from '../../attribute-constants';

export class RetailPricing implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayDualNumericInputComponent;
  }

  getAttributeConfig(overrides?): AttributeDualInputConfig {
    return {
      collections: [],
      description: '',
      label: 'Retail pricing',
      name: '',
      options: [],
      isRequired: true,
      input1DecimalCount: 0,
      input1NumberCount: 3,
      input1Placeholder: '#',
      input2DecimalCount: 2,
      input2Placeholder: '$',
      input2NumberCount: 9,
      separatingText: 'for',
      isDisabled: () => false,
      isReadOnly: () => false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayDualNumericInputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    this.updateModel(componentInstance, candidate);
    componentInstance.firstInputModelChange.subscribe(x => {
      candidate.retailXFor = x;
    });

    componentInstance.secondInputModelChange.subscribe(x => {
      candidate.retailPrice = x;
    });

  }
  updateModel(componentInstance: AttributeDisplayDualNumericInputComponent, candidate: Candidate) {
    componentInstance.attribute.isDisabled = () => (candidate.retailType === RETAIL_LINK || candidate.retailType === PRICE_REQUIRED);

    console.log('Updating Pricing');
    if ((componentInstance.firstInputModel !== candidate.retailXFor)) {
      componentInstance.firstInputModel = candidate.retailXFor;
    }

    if ((componentInstance.secondInputModel !== candidate.retailPrice)) {
      componentInstance.secondInputModel = candidate.retailPrice;
    }
  }
}
