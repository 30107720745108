<pm-editor (closeEvent)="onClose()">
  <pm-header>
    <app-invited-distributor-stepper *ngIf="invitedDistributorService.getCandidate()"
                                     [candidateErrors]="invitedDistributorService.getAllCandidateErrors()"
                                     [supplierInitiated]="invitedDistributorService.isSupplierInitiated()"
                                     [stepIndex]="invitedDistributorService.isSupplierInitiated() ?  2 : 1">
    </app-invited-distributor-stepper>
  </pm-header>

  <pm-title>Case details</pm-title>
  <pm-sub-title>We’ve already set up this product, but we do need some information from you.</pm-sub-title>

  <pm-sidebar>
    <div *ngIf="invitedDistributorService.candidate" class="product-info">
      <div class="row image-row">
        <img class="product-image" src="{{invitedDistributorService.getProductImage()}}">
      </div>
      <div class="row header-row">
        UPC: {{invitedDistributorService.candidate.candidateProducts[0].upc}}
      </div>
      <div class="row description-row">
        {{invitedDistributorService.productData?.productDescription}}
      </div>
      <div class="row body-row">
        Brand: {{invitedDistributorService.productData?.brand.description}}
      </div>
      <div class="row body-row">
        Size: {{invitedDistributorService.upc?.size}}
      </div>
      <div class="row body-row">
        Retail: {{invitedDistributorService.upc?.xfor}} for ${{invitedDistributorService.upc?.retailPrice}}
      </div>
    </div>
  </pm-sidebar>

  <div *ngIf="invitedDistributorService.candidate && supplierDetailsCardPermissions">

    <pm-buyer-details-card [(model)]="invitedDistributorService.candidate"
                           [permissions]="buyerDetailsCardPermissions"
                           [errorModel]="candidateError">
    </pm-buyer-details-card>

    <pm-supplier-details-card [(candidateModel)]="invitedDistributorService.candidate"
                              [candidateProductModel]="invitedDistributorService.candidate.candidateProducts[0]"
                              [permissions]="supplierDetailsCardPermissions"
                              [candidateErrorModel]="candidateError"
                              [inputCostOwners]="costOwners"
                              (supplierChange)="invitedDistributorService.onSupplierChange($event, supplierAuthorizedOverlay, supplierAuthorizedDiv)">
    </pm-supplier-details-card>

    <pm-case-details-card [(candidateModel)]="invitedDistributorService.candidate"
                          [(candidateProductModel)]="invitedDistributorService.candidate.candidateProducts[0]"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="candidateError"
                          [candidateProductErrorModel]="candidateProductError">
    </pm-case-details-card>

    <pm-cost-link-card *ngxPermissionsOnly="['ROLE_COST_LINK']" [model]="invitedDistributorService.candidate"
                       (validateCostLink)="validateCostLink()"
                       [costLinkState]="costLinkState"
                       [errorModel]="candidateError"
                       [permissions]="costLinkCardPermissions">
    </pm-cost-link-card>

    <pm-cost-card [model]="invitedDistributorService.candidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="candidateError">
    </pm-cost-card>
  </div>

  <pm-footer>
    <p-button *ngIf="invitedDistributorService.isSupplierInitiated()" class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
<div #supplierAuthorizedDiv></div>
<pm-static-overlay-panel #supplierAuthorizedOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
  <ng-container>
    <div class="row align-items-center supplier-authorized-overlay-wrapper">
      <div class="col-md-4 supplier-authorized-overlay">
        <div class="header-row">
          <h2 class="pb-1">You’re already authorized</h2>
          <p>
            You’re already authorized to deliver this product or you have a request still pending review.
            If you need to update the stores list for <b>{{invitedDistributorService.productData?.productDescription}}</b>,
            contact Procurement Support (productteam@heb.com) directly.
          <p>
            PAM currently doesn't support updating existing store authorizations, but we’re working on it.
          </p>
        </div>
        <div class="row button-row">
          <p-button type="button" class="ghost mr-2" label="Start over" (onClick)="onClickStartOver()"></p-button>
          <p-button type="button" label="Return home" class="ml-2" (onClick)="onClickReturnHome()"></p-button>
        </div>
      </div>
    </div>
  </ng-container>
</pm-static-overlay-panel>
