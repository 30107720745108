import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'pm-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements OnInit {
  showDelete: boolean = false;

  @Input()
  description: string = 'item';

  @Input()
  showConfirmationModal = true;

  @Input() style: any;

  @Input() disabled = false;

  @Input() tooltip: any;

  @Output()
  deleteButtonClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  showDeletePanel(event, panel, target) {
    if (this.disabled) {
      return;
    }
    event.stopPropagation();
    panel.show(event, target);
  }

  deleteCandidate(panel?: any) {
    if (this.disabled) {
      return;
    }
    if (panel) {
      panel.hide();
    }
    this.deleteButtonClicked.emit();
  }
}
