<div class="row justify-content-md-center volume-upload-settings">
  <div class="col-md-7">
    <div class="headers">
      <h1 class="mb-2">Volume upload settings</h1>
      <p>Manage each Supplier’s volume upload settings.</p>
    </div>
    <div class="row justify-content-left">
      <div *ngIf="suppliers && suppliers.length > 0" class="col-md-5 mb-2">
        <pm-supplier-drop-down-filter [suppliers]="suppliers" [supplierFilters]="supplierFilters" (dropDownChange)="onApplySupplierFilter($event)">
        </pm-supplier-drop-down-filter>
      </div>
    </div>

    <p-table class="volume-upload-settings-table" selectionMode="single" [sortField]="'candidateId'" [sortOrder]="-1"
             [rowHover]="true"
             [columns]="vendorHeaderColumns"
             [value]="vendorPageAndPreferences"
             [paginator]="true"
             [rows]="pageSize"
             [totalRecords]="recordCount"
             [lazy]="true"
             (onLazyLoad)="fetchPage($event)"
             (onRowSelect)="onClickRow($event, supplierSettingsOverlay, supplierSettingsDiv)">
      <ng-template pTemplate="header" let-vendorHeaderColumns>

        <tr>
          <th *ngFor="let col of vendorHeaderColumns" [pSortableColumn]="col.field"
              [pSortableColumnDisabled]="!col.sortable" [ngStyle]="{'width' : col.width}">
            {{col.header}}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-vendorPageAndPreference let-index="rowIndex">
        <tr style="height: 16px !important;" [pSelectableRow]="vendorPageAndPreference">

          <td>{{vendorPageAndPreference?.vendor.apNumber}}</td>
          <td>{{vendorPageAndPreference?.vendor.description}}</td>
          <td>{{getUserFriendlyFulfillmentChannel(vendorPageAndPreference)}}</td>
          <td>{{getAutoApprove(vendorPageAndPreference)}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div>
  <div #supplierSettingsDiv></div>
  <!-- todo: bug in overlay not covering page. -->
  <pm-static-overlay-panel [hideTransitionOptions]="'0ms ease-in'" [showTransitionOptions]="'0ms ease-out'"
                           #supplierSettingsOverlay appendTo="body"
                           [style]="{width: '100%', height: '100%', 'margin-top': '-162px','margin-left': '15px'}">
    <pm-upload-settings-configuration *ngIf="isViewingSupplierSettingsPanel" [vendor]="vendor"
                                      (cancelClicked)="hideSupplierSettingsPanel(supplierSettingsOverlay)"
                                      (saveButtonClicked)="onSaveSupplierSettings($event, supplierSettingsOverlay)">
    </pm-upload-settings-configuration>
  </pm-static-overlay-panel>
</div>
