import {UserPreferences} from 'pm-models/lib/userPreferences';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {PublisherService} from './publisher.service';
import {GrowlService} from '../growl/growl.service';
import {AdminPreferences, AdminPreferencesType} from 'pm-models';

@Injectable()
export class PreferencesService {

  private preferencesServiceBaseUrl = '/preferences/';
  private adminPreferencesServiceBaseUrl = '/preferences/admin/';

  constructor(private http: HttpClient, private publisherService: PublisherService,
              private growlService: GrowlService) {
  }

  /**
   * Get preferences for logged-in user.
   *
   * @returns {Observable<UserPreferences>}
   */
  public getPreferences(): Observable<UserPreferences> {
    return this.http.get<any>(this.preferencesServiceBaseUrl).pipe(
      tap(prefs => {
        this.logSuccess('Successfully retrieved preferences. Preferences:' + prefs);
      }),
      catchError(this.handleError<any>('retrieving preferences'))
    );
  }

  /**
   * Check if the logged-in user is a BDA or SCA logging in for the first time.
   *
   * @returns {Observable<boolean>}
   */
  public checkBDAOrSCA(): Observable<boolean> {
    return this.http.get<boolean>(this.preferencesServiceBaseUrl + 'checkBDAorSCA').pipe(
      tap(isBDAorSCA => {
        this.logSuccess('Successfully checked first time BDA or SCA login. Result:' + isBDAorSCA);
      }),
      catchError(this.handleError<any>('checking first-time login for BDA or SCA'))
    );
  }

  /**
   * Update preferences for logged-in user.
   *
   * @param userPreferences
   * @returns {Observable<UserPreferences>}
   */
  public updatePreferences(userPreferences: UserPreferences): Observable<UserPreferences> {
    return this.http.put<any>(this.preferencesServiceBaseUrl, userPreferences).pipe(
      tap(prefs => {
        this.logSuccess('Successfully saved preferences. Preferences:' + prefs);
      }),
      catchError(this.handleError<any>('saving preferences'))
    );
  }

  /**
   * Unsubscribe preferences for logged-in user.
   *
   * @param userPreferences
   * @returns {Observable<UserPreferences>}
   */
  public unsubscribe(): Observable<any> {
    return this.http.put(this.preferencesServiceBaseUrl + 'unsubscribe', null).pipe(
      tap(ignore => {
        this.logSuccess('Successfully unsubscribed preferences.');
      }),
      catchError(this.handleError<any>('unsubscribed preferences'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.logError(`Error in ${operation} failed: ${error.message}`);
      return observableThrowError(error);
    };
  }

  /** Log a PreferencesService message */
  private logSuccess(message: string) {
    this.publisherService.add('PreferencesService: ' + message);
  }

  /** Log a PreferencesService message with the GrowlService */
  private logAndDisplaySuccess(message: string) {
    this.growlService.addSuccess(message);
    this.publisherService.add('PreferencesService: ' + message);
  }

  /** Log a PreferencesService message */
  private logError(message: string) {
    this.publisherService.add('PreferencesService error: ' + message);
  }

  /**
   * Get admin preferences.
   *
   * @returns {Observable<AdminPreferences>}
   */
  public getAdminPreferences(id: string, adminPreferencesType: AdminPreferencesType): Observable<AdminPreferences> {

    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('adminPreferencesType', AdminPreferencesType[adminPreferencesType]);
    return this.http.get<any>(this.adminPreferencesServiceBaseUrl, {params: params}).pipe(
      tap(() => {
        this.logSuccess('Successfully retrieved admin preferences for id: "' + id + '" and type: "' + adminPreferencesType + '".');
      }),
      catchError(this.handleError<any>('retrieving admin preferences'))
    );
  }
  /**
   * Get admin preferences by ids and type.
   *
   * @returns {Observable<AdminPreferences[]>}
   */
  public getAdminPreferencesByIdsAndType(ids: string[], adminPreferencesType: AdminPreferencesType): Observable<AdminPreferences[]> {

    let params = new HttpParams();
    const idString = ids.join(',');
    params = params.append('ids', idString);
    params = params.append('adminPreferencesType', AdminPreferencesType[adminPreferencesType]);
    return this.http.get<any>(this.adminPreferencesServiceBaseUrl + 'byIdsAndType', {params: params}).pipe(
      tap(() => {
        this.logSuccess('Successfully retrieved admin preferences for ids: "' + ids + '" and type: "' + adminPreferencesType + '".');
      }),
      catchError(this.handleError<any>('retrieving admin preferences'))
    );
  }

  /**
   * Save admin preferences.
   *
   * @returns {Observable<any>}
   */
  public saveAdminPreferences(id: string, adminPreferencesType: AdminPreferencesType,
                              adminPreferences: AdminPreferences): Observable<AdminPreferences> {

    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('adminPreferencesType', AdminPreferencesType[adminPreferencesType]);
    return this.http.post<any>(this.adminPreferencesServiceBaseUrl, adminPreferences, {params: params}).pipe(
      tap(() => {
        this.logSuccess('Successfully saved admin preferences for id: "' + id + '" and type: "' + adminPreferencesType + '".');
      }),
      catchError(this.handleError<any>('retrieving admin preferences'))
    );
  }
}
