import {CandidateInner} from 'pm-models/lib/candidateInner';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {Candidate} from 'pm-models';

export class UpdateMrtInnerRequest {

  mrtCandidate: Candidate;

  addedExistingInners?: ExistingInner[];

  deletedExistingInners?: ExistingInner[];

  deletedCandidateInners?: CandidateInner[];
}
