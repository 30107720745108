<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pia-new-product-flow-stepper *ngIf="candidate && !piaProductService.isDsdOnly()"
                                      [candidateErrors]="piaProductService.getAllWhsCandidateErrors()"
                                      [stepIndex]="4"
                                      [isSellable]="candidateUtilService.isSellable(candidate)"
                                      (indexClick)="onClickStepper($event)">
    </app-pia-new-product-flow-stepper>
    <app-pia-new-dsd-product-flow-stepper *ngIf="candidate && piaProductService.isDsdOnly()"
                                          [candidateErrors]="piaProductService.getAllDsdCandidateErrors()"
                                          [stepIndex]="4"
                                          [isSellable]="candidateUtilService.isSellable(candidate)"
                                          (indexClick)="onClickStepper($event)">
    </app-pia-new-dsd-product-flow-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Case pack</pm-title>
  <ng-container *ngIf="candidate">
    <pm-master-pack-details-card *ngIf="!piaProductService.isDsdOnly() ||
    (!candidateUtilService.isSellable(candidate) && !piaProductService.isDsdOnly())" [(model)]="candidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="candidateError"
                                 (masterPackChange)="onMasterPackChange($event)">>
    </pm-master-pack-details-card>


    <pm-inner-pack-details-card *ngIf="!piaProductService.isDsdOnly() ||
    (!candidateUtilService.isSellable(candidate) && !piaProductService.isDsdOnly())"
                                [(model)]="candidate"
                                [permissions]="innerPackDetailsCardPermissions"
                                [errorModel]="candidateError"
                                (modelChange)="onInnerPackChange()"
                                (innerPackQuantityChange)="innerPackQuantityChange()">
    </pm-inner-pack-details-card>

    <pm-case-details-card [(candidateModel)]="candidate"
                          [(candidateProductModel)]="currentCandidateProduct"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="candidateError"
                          [candidateProductErrorModel]="candidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUpc()"
                          (masterPackChange)="onMasterPackChange($event)">
    </pm-case-details-card>

    <pm-imported-product-card [(model)]="currentCandidateProduct"
                              [containerSizes]="containerSizes"
                              [incoTermsList]="incoTermsList"
                              [permissions]="importProductCardPermissions"
                              [errorModel]="candidateProductError">
    </pm-imported-product-card>

    <pm-display-ready-unit-card *ngIf="isSellable && !piaProductService.isDsdOnly()" [(model)]="candidate"
                                [permissions]="displayReadyUnitCardPermissions"
                                [errorModel]="candidateError">
    </pm-display-ready-unit-card>

    <pm-cost-link-card [(model)]="candidate"
                       (validateCostLink)="validateCostLink()"
                       [costLinkState]="costLinkState"
                       [errorModel]="candidateError"
                       [permissions]="costLinkCardPermissions">
    </pm-cost-link-card>

    <pm-cost-card [(model)]="candidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="candidateError">
    </pm-cost-card>

    <pm-pricing-card *ngIf="isSellable"
                     [(model)]="candidate"
                     [permissions]="pricingCardPermissions"
                     [errorModel]="candidateError"
                     [retailLinkState]="retailLinkState"
                     (validateRetailLink)="validateRetailLink()">
    </pm-pricing-card>

    <pm-omi-remarks-card *ngIf="!piaProductService.isDsdOnly()" [(model)]="currentCandidateProduct"
                         [permissions]="omiRemarksPermissions"
                         [errorModel]="candidateProductError">
    </pm-omi-remarks-card>

    <pm-attachments-card [(model)]="candidate"
                         [commentsPlaceholder]="'Add notes for future reference. This will only be visible in PAM.'"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="candidateError">
    </pm-attachments-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
