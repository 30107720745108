import {Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output} from '@angular/core';
import {CandidateError} from 'pm-models';
import {SupplierMrtService} from '../../service/supplier-mrt.service';

@Component({
  selector: 'app-pia-mrt-stepper',
  templateUrl: './pia-mrt-stepper.component.html',
  styleUrls: ['./pia-mrt-stepper.component.scss']
})
export class PiaMrtStepperComponent implements OnInit {

  private errorCandidateDiffer: KeyValueDiffer<string, any>;
  private stepsDiffer: KeyValueDiffer<string, any>;
  private errorCandidateInnersDiffer: KeyValueDiffer<string, any>;

  private readonly errorToolTip = 'This page contains errors or is missing information in required fields.';
  private readonly errorIcon = 'warning';
  hasInnerFlow;

  @Input()
  candidateErrors: CandidateError[];

  @Input()
  hasInnerCandidateError: Boolean[] = [];

  @Input()
  isSellable: boolean;

  @Input()
  stepIndex: number;

  steps: any[];

  @Input()
  set innerFlow(innerFlow: boolean) {
    if (this.previousInnerFlowValue === null || this.previousInnerFlowValue === undefined) {
      this.hasInnerFlow = innerFlow;
      this.steps = innerFlow ? this.supplierMrtService.getPiaNewInnerFlowSteps() : this.supplierMrtService.getPiaNoInnerFlowSteps();
    } else {
      this.hasInnerFlow = innerFlow;
      // If the flow type changed, copy any errors into new steps.
      const tempSteps = innerFlow ? this.supplierMrtService.getPiaNewInnerFlowSteps() : this.supplierMrtService.getPiaNoInnerFlowSteps();
      for (const tempStep of tempSteps) {
        for (const oldStep of this.steps) {
          if (tempStep.text === oldStep.text) {
            tempStep.icon =  oldStep.icon;
            tempStep.message = oldStep.message;
            break;
          }
        }
      }
      this.steps = tempSteps;
    }
    this.previousInnerFlowValue = innerFlow;
  }

  previousInnerFlowValue;

  @Output() indexClick = new EventEmitter<any>();

  constructor(private differs: KeyValueDiffers, private supplierMrtService: SupplierMrtService) {}

  ngOnInit() {
  }

  /**
   * Change detection that compares candidate errors with previous version.
   * If the candidate differ doesn't exist, it creates it
   * Runs apply errors if any changes have been detected
   * @memberof NewWarehouseProductStepperComponent
   */
  ngDoCheck(): void {
    let candidateHasChanges = false;

    if (this.errorCandidateDiffer) {
      candidateHasChanges = !!this.errorCandidateDiffer.diff(this.candidateErrors);
    } else if (this.candidateErrors) {
      this.errorCandidateDiffer = this.differs.find(this.candidateErrors).create();
    }
    if (!candidateHasChanges && this.errorCandidateInnersDiffer) {
      candidateHasChanges = !!this.errorCandidateInnersDiffer.diff(this.hasInnerCandidateError);
    } else if (this.hasInnerCandidateError) {
      this.errorCandidateInnersDiffer = this.differs.find(this.hasInnerCandidateError).create();
    }
    if (!candidateHasChanges && this.stepsDiffer) {
      candidateHasChanges = !!this.stepsDiffer.diff(this.steps);
    } else if (this.steps) {
      this.stepsDiffer = this.differs.find(this.steps).create();
    }
    if (candidateHasChanges) {
      this.applyErrors();
    }
  }

  /**
   * Runs through each step of menu and runs validation on each step
   *
   * @memberof NewWarehouseProductStepperComponent
   */
  applyErrors() {
    this.steps.forEach((x, i) => {
      switch (i) {
        case 0:
          this.validateItems(x, this.candidateErrors[0]);
          break;
        case 1:
          // if there's an inner flow, the 2nd page is the new item setup.
          if (this.hasInnerFlow) {
            this.validateNewItemSetup(x, this.hasInnerCandidateError);
          } else {
            this.validateMrtCaseDetails(x, this.candidateErrors[1]);
          }
          break;
        case 2:
          // if there's an inner flow, the 3rd page is the case details page, else it's extended attributes, if applicable.
          if (this.hasInnerFlow) {
            this.validateMrtCaseDetails(x, this.candidateErrors[1]);
          } else {
            this.validateWarehouses(x, this.candidateErrors[2]);
          }
          break;
        case 3:
          // if there's an inner flow, the 3rd page is the case details page, else it's extended attributes, if applicable.
          if (this.hasInnerFlow) {
            this.validateWarehouses(x, this.candidateErrors[2]);
          } else {
            this.validateExtendedAttributes(x, this.candidateErrors[3]);
          }
          break;
        case 4:
          this.validateExtendedAttributes(x, this.candidateErrors[3]);
          break;
      }
    });
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateItems(stepItem, candidateErrors) {
    const candidateKeys = ['description', 'lane'];
    const candidateProductKeys = [];
    const mrtInfoKeys = ['quantity', 'unitCost'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, mrtInfoKeys, [], candidateErrors);
  }
  /**
   * Validation for second screen screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateMrtCaseDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'buyer',
      'costOwner',
      'topToTop',
      'commodity',
      'subCommodity',
      'itemWeightType',
      'maxShelfLife',
      'inboundSpecDays',
      'guaranteeToStoreDays',
      'warehouseReactionDays',
      'masterListCost',
      'masterWidth',
      'masterHeight',
      'masterLength',
      'masterWeight',
      'cubeAdjustedFactor',
      'vendorTie',
      'vendorTier',
      'displayReadyUnit',
      'displayReadyUnitRowsDeep',
      'displayReadyUnitRowsHigh',
      'displayReadyUnitRowsFacing',
      'displayReadyUnitOrientation',
      'season',
      'seasonYear'];
    const candidateProductKeys = [
      'caseUpc',
      'caseUpcCheckDigit',
      'caseDescription',
      'vendorProductCode',
      'countryOfOrigin',
      'imported',
      'containerSize',
      'termsAndConditions',
      'incoTerms',
      'pickupPoint',
      'dutyPercent',
      'dutyConfirmed',
      'dutyInfo',
      'minimumOrderQty',
      'minimumOrderDesc',
      'hts1',
      'hts2',
      'hts3',
      'agentPercent',
      'cartonMarking',
      'productColor',
      'prorationDate',
      'inStoreDate',
      'whsFlushDate',
      'freightConfirmedDate',
      'factory',
      'remark1',
      'remark2'];
    const mrtInfoKeys = [];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, mrtInfoKeys, [], candidateErrors);
  }


  validateWarehouses(stepItem, candidateErrors) {
    const candidateProductKeys = ['missingWarehouses'];
    const warehouseKeys = ['maxShip', 'orderRestriction', 'orderUnit'];

    this.validateStep(stepItem, [], candidateProductKeys, [], warehouseKeys, candidateErrors);
  }

  validateExtendedAttributes(stepItem, candidateErrors) {
    this.validateStep(stepItem, [], [], [], [], candidateErrors, true);
  }

  validateNewItemSetup(stepItem, hasInnerCandidateError) {
    if (hasInnerCandidateError[0]) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    } else {
      stepItem.icon = null;
      stepItem.message = null;
    }
  }
  /**
   * Validates step with associated keys, applies icon and error message if found
   *
   * @private
   * @param {*} stepItem - Menu Items
   * @param {*} candidateKeys - Candidate Error Keys associated with this step
   * @param {*} candidateProductKeys - Candidate Product Error Keys associated with this step
   * @param mrtInnerKeys
   * @param warehouseKeys
   * @param candidateErrors
   * @param validateMatAttributes
   * @memberof CandidateCreationStepperComponent
   */
  private validateStep(stepItem, candidateKeys, candidateProductKeys, mrtInnerKeys, warehouseKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!this.validateKeys(candidateKeys, candidateProductKeys, mrtInnerKeys, warehouseKeys, candidateErrors, validateMatAttributes)) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    }
  }

  /**
   * Checks each batch of keys to see if candidateErrors or candidate Product errors
   * contain any values. Immediate return false if error found for performance.
   *
   * @private
   * @param {*} candidateKeys
   * @param {*} candidateProductKeys
   * @param mrtInnerKeys
   * @param warehouseKeys
   * @param candidateErrors
   * @param validateMatAttributes
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private validateKeys(candidateKeys, candidateProductKeys, mrtInnerKeys, warehouseKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!candidateErrors) {
      return true;
    }
    for (let i = 0; i < candidateKeys.length; i++) {
      if (this.candidateErrors && this.fieldHasValue(candidateErrors[candidateKeys[i]])) {
        return false;
      }
    }
    for (let i = 0; i < candidateProductKeys.length; i++) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && this.fieldHasValue(value[candidateProductKeys[i]])) {
            return false;
          }
        }
      }
    }
    for (let i = 0; i < mrtInnerKeys.length; i++) {
      if (candidateErrors.mrtInfoError && candidateErrors.mrtInfoError.existingInnerErrors) {
        for (const [key, value] of Object.entries(candidateErrors.mrtInfoError.existingInnerErrors)) {
          if (value && this.fieldHasValue(value[mrtInnerKeys[i]])) {
            return false;
          }
        }
      }
      if (candidateErrors.mrtInfoError && candidateErrors.mrtInfoError.candidateInnerErrors) {
        for (const [key, value] of Object.entries(candidateErrors.mrtInfoError.candidateInnerErrors)) {
          if (value && this.fieldHasValue(value[mrtInnerKeys[i]])) {
            return false;
          }
        }
      }
    }

    for (let i = 0; i < warehouseKeys.length; i++) {
      if (!candidateErrors.candidateProductErrors) {
        return true;
      }
      for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
        for (const [candidateProductKey, candidateProductValue] of Object.entries(value)) {
          if (candidateProductKey === 'warehouseErrors') {
            for (const [warehouseKey, warehouseValue] of Object.entries(candidateProductValue)) {
              if (warehouseValue && this.fieldHasValue(warehouseValue[warehouseKeys[i]])) {
                return false;
              }
            }
          }
        }
      }
    }

    if (validateMatAttributes) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && value['matAttributeErrors'] &&  Object.entries(value['matAttributeErrors']).length) {
            return false;
          }
        }
      }
    }

    return true;
  }

  /**
   * Takes  a property and immediately checks if it has any sort of value
   * returns 'true' if any value is found or present.
   * @private
   * @param {*} field
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private fieldHasValue(field) {
    return field !== undefined && field !== null && field !== '';
  }

  click(event: any) {
    this.indexClick.emit(event);
  }
}
