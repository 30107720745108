<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pia-new-product-flow-stepper *ngIf="candidate && !piaProductService.isDsdOnly()"
                                      [candidateErrors]="piaProductService.getAllWhsCandidateErrors()"
                                      [stepIndex]="2"
                                      [isSellable]="candidateUtilService.isSellable(candidate)"
                                      (indexClick)="onClickStepper($event)">
    </app-pia-new-product-flow-stepper>
    <app-pia-new-dsd-product-flow-stepper *ngIf="candidate && piaProductService.isDsdOnly()"
                                          [candidateErrors]="piaProductService.getAllDsdCandidateErrors()"
                                          [stepIndex]="2"
                                          [isSellable]="candidateUtilService.isSellable(candidate)"
                                          (indexClick)="onClickStepper($event)">
    </app-pia-new-dsd-product-flow-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Provide supplier and buyer details.</pm-title>
  <!-- Page Cards -->
  <!--
    We're using  an ng-container to not add additional
    DOM elements and only show the cards when the candidate is loaded
  -->
  <ng-container *ngIf="candidate">
    <pm-heb-details-card [(model)]="candidate"
                         [(candidateProductModel)]="candidate.candidateProducts[0]"
                         (merchandiseTypeChange)="onMerchandiseTypeChange($event)"
                         (buyerChange)="onBuyerChange($event)"
                         [permissions]="hebDetailsCardPermissions"
                         [errorModel]="candidateError"
                         [candidateProductErrorModel]="candidateProductError">

    </pm-heb-details-card>

    <pm-supplier-details-card [(candidateModel)]="candidate"
                              [candidateProductModel]="candidate.candidateProducts[0]"
                              [permissions]="supplierDetailsCardPermissions"
                              [candidateErrorModel]="candidateError"
                              (supplierChange)="onSupplierChange($event)">
    </pm-supplier-details-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canClickNext"></p-button>
  </pm-footer>
</pm-editor>
