import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pm-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OverlayComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
