import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeTextInputConfig, TextInputType} from 'pm-models';
import {EmailService} from '../../../../../../src/app/2.0.0/service/email.service';

export interface FormModel {
  brand: string;
  costOwner: string;
  topToTop: string;
  supplier: string;
  email: string;
  notes: string;
}

@Component({
  selector: 'pm-request-new-attribute-form-overlay',
  templateUrl: './request-new-attribute-form.component.html',
  styleUrls: ['./request-new-attribute-form.component.scss']
})

export class RequestNewAttributeFormComponent implements OnInit {

  @Input()
  model: FormModel;

  @Output()
  sendRequestClicked = new EventEmitter<any>();

  @Output()
  cancelClicked = new EventEmitter<void>();

  @Output() modelChange = new EventEmitter<any>();

  emailError: string;

  constructor() { }

  ngOnInit() {
    // Will reset values every time form opened
    this.model = {brand: '', costOwner: '', topToTop: '', supplier: '', email: '', notes: ''};
  }

  sendRequest() {
    this.sendRequestClicked.emit(this.model);
  }

  cancel() {
    this.cancelClicked.emit();
  }

  brandConfiguration: AttributeTextInputConfig = {
    label: 'Enter the name of the brand.',
    placeholderText: 'Enter a new or existing brand',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.text,
    isRequired: true
  };

  costOwnerConfiguration: AttributeTextInputConfig = {
    label: 'Enter the name of the cost owner.',
    placeholderText: 'Enter a new or existing cost owner...',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.text,
    isRequired: true
  };

  topToTopConfiguration: AttributeTextInputConfig = {
    label: 'Enter a “top to top” value (often the same as the cost owner value).',
    placeholderText: 'Enter top to top...',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.text,
    isRequired: true
  };

  supplierConfiguration: AttributeTextInputConfig = {
    label: 'Enter your H-E-B Supplier # (optional).',
    placeholderText: 'Enter H-E-B Supplier #...',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 38,
    numberCount: 38
  };

  emailConfiguration: AttributeTextInputConfig = {
    label: 'Provide an additional email for notifications (optional).',
    placeholderText: 'Enter email address...',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.text
  };

  notesConfiguration: AttributeTextInputConfig = {
    label: 'Add details about your request (optional).',
    placeholderText: 'Add notes for reviewer...',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  brandChange(value) {
    this.model.brand = value;
  }

  costOwnerChange(value) {
    this.model.costOwner = value;
  }

  topToTopChange(value) {
    this.model.topToTop = value;
  }

  supplierChange(value) {
    this.model.supplier = value;
  }

  onEmailChange(event) {
    if (!this.model?.email || EmailService.isValidEmail(event)) {
      this.emailError = null;
    } else {
      this.emailError = 'Please enter a valid email address.';
    }
  }

  notesChange(value) {
    this.model.notes = value;
  }

  isDisabled(): boolean {
    return !(this.model.brand && this.model.costOwner && this.model.topToTop) || !!this.emailError;
  }
}
