<pm-editor (closeEvent)="onClose()">

  <pm-header>
    <app-pia-additional-case-pack-stepper [candidateErrors]="supplierCasePackService.getAllPiaCandidateErrors()" [stepIndex]="4"
                                          (indexClick)="onClickStepper($event)">
    </app-pia-additional-case-pack-stepper>
  </pm-header>

  <pm-title>
    Extended attributes
  </pm-title>

  <div *ngIf="isLoadingData" class="col-md-8 spinner-row">
    <pm-progress-spinner [showSpinner]="isLoadingData" [strokeWidth]="'2'"
                         [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}">
    </pm-progress-spinner>
    <h5 class="ml-3">Fetching attributes...</h5>
  </div>
  <ng-container *ngIf="!isLoadingData">

    <pm-global-attributes-card [candidateModel]="supplierCasePackService.candidate"
                               [candidateProductModel]="supplierCasePackService.candidate.candidateProducts[1]"
                               [globalAttributes]="supplierCasePackService.globalAttributes"
                               [candidateProductErrorModel]="candidateProductError">
    </pm-global-attributes-card>

    <pm-mat-categories-card [candidateModel]="supplierCasePackService.candidate"
                            [candidateProductModel]="supplierCasePackService.candidate.candidateProducts[1]"
                            [hierarchyAttributes]="supplierCasePackService.hierarchyAttributes"
                            [candidateProductErrorModel]="candidateProductError">
    </pm-mat-categories-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Activate" (onClick)="onClickActivate()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
