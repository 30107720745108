<div
  class="mtr-drawer__backdrop"
  (click)="onBackdropClicked()"
  *ngIf="hasBackdrop"
  [class.mtr-drawer--shown]="isShowingBackdrop()"
></div>

<ng-content select="pm-drawer"></ng-content>

<ng-content select="pm-drawer-content"></ng-content>

<pm-drawer-content *ngIf="!content">
  <ng-content></ng-content>
</pm-drawer-content>
