import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';
import { AttributeDualInputConfig } from 'pm-models';
import { NumericProcessorsService } from '../attribute-utilities/numeric-processors.service';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: 'pm-attribute-display-dual-numeric-input',
  templateUrl: './attribute-display-dual-numeric-input.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-dual-numeric-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayDualNumericInputComponent extends BaseAttributeDisplayComponent implements OnInit {
  @Input()
  attribute: AttributeDualInputConfig;

  firstInputValue;
  @Input()
  get firstInputModel() {
    return this.firstInputValue;
  }
  set firstInputModel(val) {
    this.firstInputValue = val;
    this.firstInputModelChange.emit(this.firstInputValue);
  }


  @Input()
  public firstInputModelError?: any;

  @Output() firstInputModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() firstInputModelFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() firstInputModelBlur?: EventEmitter<any> = new EventEmitter<any>();

  onFirstInputModelBlur(event) {
    this.firstInputModelBlur.emit(event);
    this.firstInputModelChange.emit(this.firstInputModel);
  }

  onFirstInputModelFocus(event) {
    this.firstInputModelFocus.emit(event);
  }

  @Input()
  secondInputModel: any;

  @Input()
  public secondInputModelError?: any;

  @Output() secondInputModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondInputModelFocus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondInputModelBlur?: EventEmitter<any> = new EventEmitter<any>();

  onSecondInputModelBlur(event) {
    this.secondInputModelBlur.emit(event);
    this.secondInputModelChange.emit(this.secondInputModel);
  }

  onSecondInputModelFocus(event) {
    this.secondInputModelFocus.emit(event);
  }

  secondInputChange(event) {
    this.secondInputModel = event;
    this.secondInputModelBlur.emit(event);
    this.secondInputModelChange.emit(this.secondInputModel);
  }

  constructor(private numService: NumericProcessorsService, private decimalPipe: DecimalPipe) {
    super();
  }

  ngOnInit() {}

  onKeyPress(event, currentValue, numberCount, decimalCount) {
    return this.numService.numericValidation(event, currentValue, numberCount, decimalCount);
  }

  getFormatString() {
    if (!this.attribute.input2DecimalCount) {
      return null;
    }
    return '1.' + this.attribute.input2DecimalCount + '-' + this.attribute.input2DecimalCount;
  }
}
