export enum CandidateSearchFilter_Column {
  BUYER_ID = 'BUYER_ID',
  VENDOR_AP_NUMBER = 'VENDOR_AP_NUMBER'
}

export class CandidateSearchFilter {
  columnName: string;
  values: string[];

  constructor(columnName: string, values: string[]) {
    this.columnName = columnName;
    this.values = values;
  }
}
