import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  ElementRef,
  NgZone,
  Optional,
  Inject,
  AfterContentInit,
  AfterContentChecked,
  OnDestroy,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { MtrDrawerToggleResult, MtrDrawerMode, MtrDrawerPosition } from './drawer-common';

import { Subject, Observable, fromEvent } from 'rxjs';
import { filter, map, distinctUntilChanged, take } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { untilDestroyed } from '../common/takeUntilDestroyed';
import { coerceBooleanProperty } from '../common/coerceBooleanProperty';
import { mtrDrawerAnimations } from './drawer-animations';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'pm-drawer',
  exportAs: 'pmDrawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  animations: [mtrDrawerAnimations.transformDrawer],
  host: {
    class: 'mtr-drawer',
    // must prevent the browser from aligning text based on value
    '[attr.align]': 'null',
    '[class.mtr-drawer--end]': 'position === "end"',
    '[class.mtr-drawer--over]': 'mode === "over"',
    '[class.mtr-drawer--push]': 'mode === "push"',
    '[class.mtr-drawer--side]': 'mode === "side"',
    '[class.mtr-drawer--peek]': 'mode === "peek"',
    tabIndex: '-1'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerComponent implements AfterContentInit, AfterContentChecked, OnDestroy {
  private elementFocusedBeforeDrawerWasOpened: HTMLElement | null = null;

  /** Whether the drawer is initialized. Used for disabling the initial animation. */
  private enableAnimations = false;

  /** The side that the drawer is attached to; either 'start' or 'end' */
  @Input()
  get position(): MtrDrawerPosition {
    return this._position;
  }
  set position(value: MtrDrawerPosition) {
    // Make sure we have a valid value.
    value = value === 'end' ? 'end' : 'start';
    if (value !== this._position) {
      this._position = value;
      this.onPositionChanged.emit();
    }
  }
  private _position: MtrDrawerPosition = 'start';

  /** Mode of the drawer. One of: 'over', 'push', 'side' or 'peek'. */
  @Input()
  get mode(): MtrDrawerMode {
    return this._mode;
  }
  set mode(value: MtrDrawerMode) {
    this._mode = value;
    this.modeChanged.next();
  }
  private _mode: MtrDrawerMode = 'side';

  /** Whether the drawer can be closed with the escape key or by clicking on the backdrop. */
  @Input()
  get disableClose(): boolean {
    return this._disableClose;
  }
  set disableClose(value: boolean) {
    this._disableClose = coerceBooleanProperty(value);
  }
  private _disableClose: boolean = false;

  /** Whether the drawer should focus the first focusable element automatically when opened. */
  @Input()
  get autoFocus(): boolean {
    return this._autoFocus;
  }
  set autoFocus(value: boolean) {
    this._autoFocus = coerceBooleanProperty(value);
  }
  private _autoFocus: boolean = true;

  /** How the drawer was opened (keypress, mouse click etc.) */
  private openedVia: null;

  /** Emits whenever the drawer has started animating. */
  public animationStarted = new Subject<AnimationEvent>();

  /** Emits whenever the drawer is done animating. */
  public animationEnd = new Subject<AnimationEvent>();

  /** Current state of the drawer animation. */
  // @HostBinding is used in the class as it is expected to be extended.  Since @Component decorator
  // metadata is not inherited by child classes, instead the host binding data is defined in a way
  // that can be inherited.
  // tslint:disable:no-host-decorator-in-concrete
  @HostBinding('@transform')
  public animationState: 'open-instant' | 'open' | 'void' | 'void-instant' = 'void';

  /** Event emitted when the drawer open state is changed. */
  @Output() readonly openedChange: EventEmitter<boolean> =
    // Note this has to be async in order to avoid some issues with two-bindings (see #8872).
    new EventEmitter<boolean>(/* isAsync */ true);

  /** Event emitted when the drawer has been opened. */
  @Output('opened')
  get _openedStream(): Observable<void> {
    return this.openedChange.pipe(
      filter(o => o),
      map(() => {})
    );
  }

  /** Event emitted when the drawer has started opening. */
  @Output()
  get openedStart(): Observable<void> {
    return this.animationStarted.pipe(
      filter(e => e.fromState !== e.toState && (e.toState.indexOf('open') === 0 || e.toState.indexOf('open-instant') === 0)),
      map(() => {})
    );
  }

  /** Event emitted when the drawer has been closed. */
  @Output('closed')
  get _closedStream(): Observable<void> {
    return this.openedChange.pipe(
      filter(o => !o),
      map(() => {})
    );
  }

  /** Event emitted when the drawer has started closing. */
  @Output()
  get closedStart(): Observable<void> {
    return this.animationStarted.pipe(
      filter(e => e.fromState !== e.toState && (e.toState === 'void' || e.toState === 'void-instant')),
      map(() => {})
    );
  }

  /** Event emitted when the drawer's position changes. */
  // tslint:disable-next-line:no-output-on-prefix
  // tslint:disable-next-line
  @Output('positionChanged') onPositionChanged: EventEmitter<void> = new EventEmitter<void>();

  /**
   * An observable that emits when the drawer mode changes. This is used by the drawer container to
   * to know when to when the mode changes so it can adapt the margins on the content.
   */
  readonly modeChanged = new Subject();

  /** @hidden */
  get isFocusTrapEnabled(): boolean {
    // The focus trap is only enabled when the drawer is open in any mode other than side.
    return this.opened && this.mode !== 'side';
  }

  /**
   * Whether the drawer is opened. We overload this because we trigger an event when it
   * starts or end.
   */
  @Input()
  get opened(): boolean {
    return this._opened;
  }
  set opened(value: boolean) {
    this.toggle(coerceBooleanProperty(value));
  }
  private _opened: boolean = false;

  /** @hidden */
  get width(): number {
    return this.elementRef.nativeElement ? this.elementRef.nativeElement.offsetWidth || 0 : 0;
  }

  // We have to use a `HostListener` here in order to support both Ivy and ViewEngine.
  // In Ivy the `host` bindings will be merged when this class is extended, whereas in
  // ViewEngine they're overwritten.
  // TODO: we move this back into `host` once Ivy is turned on by default.
  // tslint:disable-next-line:no-host-decorator-in-concrete
  @HostListener('@transform.start', ['$event'])
  animationStartListener(event: AnimationEvent) {
    this.animationStarted.next(event);
  }

  // We have to use a `HostListener` here in order to support both Ivy and ViewEngine.
  // In Ivy the `host` bindings will be merged when this class is extended, whereas in
  // ViewEngine they're overwritten.
  // TODO: we move this back into `host` once Ivy is turned on by default.
  // tslint:disable-next-line:no-host-decorator-in-concrete
  @HostListener('@transform.done', ['$event'])
  animationDoneListener(event: AnimationEvent) {
    this.animationEnd.next(event);
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    // private focusTrapFactory: FocusTrapFactory,
    // private focusMonitor: FocusMonitor,
    // private platform: Platform,
    private ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(DOCUMENT) private doc: any
  ) {
    this.openedChange.subscribe((opened: boolean) => {
      if (opened) {
        if (this.doc) {
          this.elementFocusedBeforeDrawerWasOpened = this.doc.activeElement as HTMLElement;
        }

      } else {
        this.restoreFocus();
      }
    });

    /**
     * Listen to `keydown` events outside the zone so that change detection is not run every
     * time a key is pressed. Instead we re-enter the zone only if the `ESC` key is pressed
     * and we don't have close disabled.
     */
    this.ngZone.runOutsideAngular(() => {
      (fromEvent(this.elementRef.nativeElement, 'keydown') as Observable<KeyboardEvent>)
        .pipe(
          // tslint:disable-next-line: deprecation
          filter(event => event.keyCode === 27 && !this.disableClose),
          untilDestroyed(this)
        )
        .subscribe(event =>
          this.ngZone.run(() => {
            this.close();
            event.stopPropagation();
          })
        );
    });

    // We need a Subject with distinctUntilChanged, because the `done` event
    // fires twice on some browsers. See https://github.com/angular/angular/issues/24084
    this.animationEnd
      .pipe(
        distinctUntilChanged((x, y) => {
          return x.fromState === y.fromState && x.toState === y.toState;
        })
      )
      .subscribe((event: AnimationEvent) => {
        const { fromState, toState } = event;
        if (
          ((toState.indexOf('open') === 0 || toState.indexOf('open-instant') === 0) &&
            (fromState === 'void' || fromState === 'void-instant')) ||
          ((toState === 'void' || toState === 'void-instant') &&
            (fromState.indexOf('open') === 0 || fromState.indexOf('open-instant') === 0))
        ) {
          this.openedChange.emit(this.opened);
        }
      });
  }

  ngAfterContentInit() {
  }

  ngAfterContentChecked() {
    // Enable the animations after the lifecycle hooks have run, in order to avoid animating
    // drawers that are open by default. When we're on the server, we shouldn't enable the
    // animations, because we don't want the drawer to animate the first time the user sees
    // the page.
    this.enableAnimations = true;
  }

  ngOnDestroy() {
    this.animationStarted.complete();
    this.animationEnd.complete();
    this.modeChanged.complete();
  }

  /** Traps focus inside the drawer. */
  private trapFocus() {
    if (!this.autoFocus) {
      return;
    }

  }

  /**
   * If focus is currently inside the drawer, restores it to where it was before the drawer
   * opened.
   */
  private restoreFocus() {
    if (!this.autoFocus) {
      return;
    }

    const activeEl = this.doc && this.doc.activeElement;

    if (activeEl && this.elementRef.nativeElement.contains(activeEl)) {
      if (this.elementFocusedBeforeDrawerWasOpened instanceof HTMLElement) {
      } else {
        this.elementRef.nativeElement.blur();
      }
    }

    this.elementFocusedBeforeDrawerWasOpened = null;
    this.openedVia = null;
  }

  /**
   * Open the drawer.
   * @param openedVia Whether the drawer was opened by a key press, mouse click or programmatically.
   * @param withAnimation Whether the drawer should open instantly or not
   * Used for focus management after the drawer is closed.
   */
  public open(
    // openedVia?: FocusOrigin,
    withAnimation: boolean = true
  ): Promise<MtrDrawerToggleResult> {
    // return this.toggle(true, openedVia, withAnimation);

    return this.toggle(true, null, withAnimation);
  }

  /**
   * Close the drawer.
   * @param withAnimation Whether the drawer should open instantly or not
   */
  public close(withAnimation: boolean = true): Promise<MtrDrawerToggleResult> {
    return this.toggle(false, 'program', withAnimation);
  }

  /**
   * Toggle this drawer.
   * @param isOpen Whether the drawer should be open.
   * @param openedVia Whether the drawer was opened by a key press, mouse click or programmatically.
   * @param withAnimation Whether the drawer should open instantly or not
   * Used for focus management after the drawer is closed.
   */
  public toggle(
    isOpen: boolean = !this.opened,
    openedVia: string = 'program',
    withAnimation: boolean = true
  ): Promise<MtrDrawerToggleResult> {
    this._opened = isOpen;
    if (isOpen) {
      this.animationState = this.enableAnimations && withAnimation ? 'open' : 'open-instant';
    } else {
      this.animationState = this.enableAnimations && withAnimation ? 'void' : 'void-instant';
      this.restoreFocus();
    }

    return new Promise<MtrDrawerToggleResult>(resolve => {
      this.openedChange.pipe(take(1)).subscribe(open => {
        return resolve(open ? 'open' : 'close');
      });
    });
  }
}
