import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HebDetailsCardComponent } from './heb-details-card.component';

import { CardModule } from '../../shared/card/card.module';

import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import {NoStoresMessageModule} from '../../shared/no-stores-message/no-stores-message.module';


@NgModule({
  declarations: [HebDetailsCardComponent],
  exports: [HebDetailsCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule,
    CardModule,
    AttributeDisplayModule,
    NoStoresMessageModule
  ]
})
export class HebDetailsCardModule { }
