import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardModule} from '../../shared/card/card.module';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {ImportedProductCardComponent} from './imported-product-card.component';
import {ImportFactorySearchBarModule} from '../../shared/import-factory-search-bar/import-factory-search-bar.module';


@NgModule({
  declarations: [ImportedProductCardComponent],
  exports: [ImportedProductCardComponent, CardModule, AttributeDisplayModule, ImportFactorySearchBarModule],
  imports: [
    CommonModule,
    CardModule,
    AttributeDisplayModule,
    ImportFactorySearchBarModule
  ]
})
export class ImportedProductCardModule { }
