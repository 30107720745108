import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {MatUtilService} from '../../service/mat-util.service';
import {CandidateService} from '../../service/candidate.service';
import {PiaProductService} from '../../service/pia-product.service';
import {CandidateError, CandidateProductError, CandidateValidatorType, TaskDecision} from 'pm-models';
import {finalize, switchMap} from 'rxjs/operators';
import {PiaNewProductFlowStepperComponent} from '../pia-new-product-flow-stepper/pia-new-product-flow-stepper.component';
import {PiaNewDsdProductFlowStepperComponent} from '../pia-new-dsd-product-flow-stepper/pia-new-dsd-product-flow-stepper.component';

@Component({
  selector: 'app-pia-new-product-extended-attributes',
  templateUrl: './pia-new-product-extended-attributes.component.html',
  styleUrls: ['./pia-new-product-extended-attributes.component.scss']
})
export class PiaNewProductExtendedAttributesComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public piaProductService: PiaProductService,
              private growlService: GrowlService, private workflowService: WorkflowService, public candidateUtilService: CandidateUtilService,
              private matUtils: MatUtilService, private candidateService: CandidateService) { }

  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;
  candidateProductError: CandidateProductError;
  submitButtonLabel = '      ';
  private validators: CandidateValidatorType[] = [CandidateValidatorType.PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR];


  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.piaProductService.getTaskId(), this.piaProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.piaProductService.resetService();
        this.piaProductService.setCandidateByUrlParameters(params).pipe(
          switchMap(() => this.matUtils.updateMatAttributesAndValues(this.piaProductService.candidate,
            this.piaProductService.globalAttributes, this.piaProductService.hierarchyAttributes)),
          finalize(() => {
              this.isLoadingData = false;
              this.candidateError = this.piaProductService.getExtendedAttributesError();
              this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
              this.setSubmitButtonLabel();
            }
          )
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.piaProductService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              this.piaProductService.resetMatHierarchyFields();
              return this.matUtils.updateMatAttributesAndValues(this.piaProductService.candidate,
                this.piaProductService.globalAttributes, this.piaProductService.hierarchyAttributes);
            } else {
              this.piaProductService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
              this.isLoadingData = false;
              this.candidateError = this.piaProductService.getExtendedAttributesError();
              this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
              this.setSubmitButtonLabel();
            }
          )
        ).subscribe();
      }
    });
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaNewProductFlowStepperComponent.UPC_ITEM_CODE: {
        this.piaProductService.saveCandidateAndNavigate('/piaSetupNewProduct', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.SUPPLIER_HEB_SETUP: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductSupplierDetails', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.PRODUCT_DETAILS: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductDetails', false);
        break;
      }
      case PiaNewProductFlowStepperComponent.CASE_PACK: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductCaseDetails', false);
        break;
      }
      case PiaNewDsdProductFlowStepperComponent.STORE_AUTHORIZATION: {
        this.onClickBack();
        break;
      }
      case PiaNewProductFlowStepperComponent.WAREHOUSE: {
        this.onClickBack();
        break;
      }
      case PiaNewProductFlowStepperComponent.EXTENDED_ATTRIBUTES: {
        this.piaProductService.saveCandidateAndNavigate('/piaNewProductExtendedAttributes', false);
        break;
      }
    }
  }

  onClickBack() {
    this.canNavigate = false;
    let urlToNavigate;
    if (this.piaProductService.getCandidate().warehouseSwitch) {
      urlToNavigate = '/piaNewProductWarehouses';
    } else if (!this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores ||
      this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
      urlToNavigate = '/piaStoreAuthorization';
    } else {
      urlToNavigate = '/piaSetUpStores';
    }
    this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);
  }

  setSubmitButtonLabel() {
    if (this.piaProductService.getCandidate().showCalories || this.piaProductService.isScaleProduct(this.piaProductService.candidate)) {
      this.submitButtonLabel = 'Submit';
    } else {
      this.submitButtonLabel = 'Activate';
    }
  }



  onClickActivateOrSubmit() {
    // validate the candidate for this page and send errors to components to display, if any
    this.canNavigate = false;
    if (this.piaProductService.isScaleProduct(this.piaProductService.getCandidate())) {
      this.validators.push(CandidateValidatorType.SCALE_ENGLISH_LABEL_VALIDATOR);
    }
    this.candidateService.validateCandidate(this.piaProductService.getCandidate(), this.validators).subscribe(data => {
      this.piaProductService.updatePageErrors(new CandidateError());

      if (this.piaProductService.getCandidate().showCalories || this.piaProductService.isScaleProduct(this.piaProductService.candidate)) {
        this.saveAndCompletePiaTask();
      } else {
        this.candidateService.activateCandidate(this.piaProductService.getCandidate()).subscribe(() => {
          this.completeTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE,
            TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
            'Successfully activated candidate.'
          );
        }, (error) => {
          this.growlService.addError(error);
          this.canNavigate = true;
        });
      }
    }, (error) => {
      this.piaProductService.scrollToTop();
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.piaProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.piaProductService.getExtendedAttributesError();
        this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canNavigate = true;
    });
  }


  private saveAndCompletePiaTask() {
    this.candidateService.saveCandidate(this.piaProductService.getCandidate()).subscribe(savedCandidate => {
      this.piaProductService.setCandidate(savedCandidate);
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_SCALE_PLU_APPROVE_YES, 'Successfully completed task.');
    });
  }


  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.piaProductService.getCandidate().vendor.apNumber, this.piaProductService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( data => {
          this.canNavigate = true;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
      });
  }

}
