export class ImportInfoError {
  containerSize?: string;
  incoTerms?: string;
  pickupPoint?: string;
  dutyPercent?: string;
  dutyConfirmed?: string;
  dutyInfo?: string;
  minimumOrderQty?: string;
  minimumOrderDesc?: string;
  hts1?: string;
  hts2?: string;
  hts3?: string;
  agentPercent?: string;
  cartonMarking?: string;
  productColor?: string;
  prorationDate?: string;
  inStoreDate?: string;
  whsFlushDate?: string;
  freightConfirmedDate?: string;
  factory?: string;
}
