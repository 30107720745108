<pm-review #pmReview *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-title *ngIf="!isReplacementUpc">Review new bonus</pm-title>
  <pm-title *ngIf="isReplacementUpc">Review replacement products</pm-title>

  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages[0] ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div class="upc-header">
            UPC: {{productData?.primaryScanCodeId}}-{{upc?.scanCodeId | upcCheckDigit}}
          </div>
          <div class="attribute-description">
            <p>{{productData?.productDescription}}</p>
            <p>{{productInfoString}}</p>
            <div class="row" style="padding-left: 15px;">
              <button *ngIf="!displayingExistingCasesDrawer" type="button" (click)="showExistingCasePacks()" class="btn btn-link m-0 p-0">Show existing cases</button>
              <button *ngIf="displayingExistingCasesDrawer" type="button" (click)="collapse()" class="btn btn-link m-0 p-0">Hide existing cases</button>
            </div>
          </div>
        </div>
      </div>

      <!--   Bonus UPC Details  -->
      <pm-attribute-grid [title]="getUpcDetailLabel()">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'UPC'" [canEdit]="isPageEditable" (editClicked)="editProductUPC()" [errorMessages]="[candidateProductError?.upc, candidateProductError?.upcCheckDigit]">
              {{candidate.candidateProducts[currentCandidateProductIndex].upc}}-{{candidate.candidateProducts[currentCandidateProductIndex].upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="isPageEditable" (editClicked)="showEditCandidateProductModal(attributeType.SubBrand)" [errorMessages]="[candidateProductError?.subBrand]">
              {{candidate.candidateProducts[currentCandidateProductIndex].subBrand?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.Dimensions)" [errorMessages]="[candidateError?.masterLength, candidateError?.masterWidth, candidateError?.masterHeight]">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail Size'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.RetailSize)" [errorMessage]="candidateError?.retailSize">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="isPageEditable" (editClicked)="editUnitOfMeasure()" [errorMessage]="candidateError?.unitOfMeasure">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.TotalVolume)" [errorMessage]="candidateError?.totalVolume">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="true" (editClicked)="editCandidateProduct(attributeType.ProductImages)" [errorMessage]="candidateProductError?.images">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="candidateProductImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Cost set by supplier   -->
      <pm-attribute-grid [title]="'Cost set by supplier'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false">
              {{productData?.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.costLink" (editClicked)="editCandidate(attributeType.CostLinked)">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.costLink" (editClicked)="editCostLink()">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]"  (editClicked)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.getCaseCostAsCurrency(this.candidate)}}<br>
                Penny profit: {{costService.getHebPennyProfitFromCandidateAndUpc(candidate, upc)}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)}"></a>{{costService.getHebMarginFromCandidateAndUpc(candidate, upc)}}
                <img *ngIf="costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Case summary   -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="isPageEditable"
                          (editClicked)="editCaseDescription(attributeType.CaseDescription)"
                          [errorMessages]="[candidateProductError?.caseDescription]">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.Channel)"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{this.candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="isPageEditable" (editClicked)="editCaseUPC()" [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="isPageEditable"
                          (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="isPageEditable" (editClicked)="editCandidateProduct(attributeType.VendorProductCode)"
                          [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.masterPack" (editClicked)="costService.editCostRelatedFields(attributeType.TotalRetail, candidate)">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.Dimensions)">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.BoxesOnPallet)">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="isTiedToCatchOrVariableWeightBuyer()" [errorMessage]="candidateError?.itemWeightType" (editClicked)="editCandidate(attributeType.ItemWeightType)">
              {{this.candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.masterWeight" (editClicked)="editCandidate(attributeType.Weight)">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.cubeAdjustedFactor" (editClicked)="editCandidate(attributeType.OrderFactor)">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="isPageEditable"  [errorMessage]="candidateError?.codeDate" (editClicked)="editCandidate(attributeType.CodeDate)">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="candidate?.codeDate && isPageEditable" [errorMessage]="candidateError?.maxShelfLife" (editClicked)="editCandidate(attributeType.MaxShelfLife)">
              {{this.candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid [title]="'Inner Packs'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="true" [errorMessage]="candidateError?.innerPackSelected" (editClicked)="costService.editCostRelatedFields(attributeType.InnerPackSelected, candidate)">
              {{candidate.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.innerPack" (editClicked)="costService.editCostRelatedFields(attributeType.InnerPackQuantity, candidate)">
              {{candidate.innerPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="candidate.innerPackSelected && isPageEditable"   [errorMessages]="[candidateError?.innerLength, candidateError?.innerWidth, candidateError?.innerHeight]" (editClicked)="editCandidate(attributeType.InnerPackDimensions)">
              {{candidate.innerLength}}in x
              {{candidate.innerWidth}}in x
              {{candidate.innerHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="candidate.innerPackSelected && isPageEditable"  [errorMessage]="candidateError?.innerWeight" (editClicked)="editCandidate(attributeType.InnerPackWeight)">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.displayReadyUnit" (editClicked)="editCandidate(attributeType.DisplayReadyUnit)">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.displayReadyUnitOrientation" (editClicked)="editCandidate(attributeType.Orientation)">
              {{this.candidateUtilService.getDRUOrientation(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.displayReadyUnitRowsDeep, candidateError?.displayReadyUnitRowsFacing, candidateError?.displayReadyUnitRowsHigh]" (editClicked)="editCandidate(attributeType.RetailUnitCount)">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <div *ngIf="isPageEditable">
          <!--   Global Attributes   -->
          <div>
            <pm-attribute-grid *ngIf="isLoadingMatData || !!bonusSizeService.upcAttributes?.length" [title]="'Extended attributes: UPC'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="isLoadingMatData || !!bonusSizeService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>
          <!--   Mat Hierarchy attributes   -->
          <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(candidate)?.length" [title]="'Category attributes'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>
          <div *ngIf="!!bonusSizeService.hierarchyNumberToAttributesMap?.size">
            <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
              <pm-attribute-grid *ngIf="bonusSizeService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                    (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                    [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                    (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                    [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                    (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                    [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>
        <div *ngIf="!isPageEditable">

          <!--  Global Attributes    -->
          <div>
            <pm-attribute-grid  *ngIf="bonusSizeService.upcAttributes?.length" [title]="'Extended attributes: UPC'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="bonusSizeService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>

          <!--   Mat Hierarchy   -->
          <div *ngIf="candidateProduct?.matHierarchyList.length > 0">
            <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
              <pm-attribute-grid *ngIf="hierarchy.attributes.length > 0" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>

      </div>

      <h2 *ngIf="!isReplacementUpc" style="padding-top: 25px;">Details for original (non-bonus) UPC</h2>
      <h2 *ngIf="isReplacementUpc" style="padding-top: 25px;">Details for original (non-replacement) UPC</h2>

      <!--   Supplier and H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false">
              {{productData?.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false">
              {{upc?.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="true" [errorMessage]="candidateError?.costOwner"  (editClicked)="editCostOwner()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false" [errorMessage]="candidateError?.topToTop"   (editClicked)="editCostOwner()">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="true" (editClicked)="editWarehouseSupplier()" [errorMessage]="candidateError?.lane">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false" [errorMessage]="candidateError?.buyer">
              {{item ? item?.hierarchy?.commodity?.bdm.fullName : dsdItem?.hierarchy?.commodity?.bdm.fullName}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false">
              {{productData?.productTypeId === 'GOODS'| yesNo}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid [title]="'Product line, Packaging, & Regulatory Info'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false">
              {{productData?.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false">
              {{productData?.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'">
              {{productData?.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{productData?.packageDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{upc?.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{upc?.size  }}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{upc?.length}}in x
              {{upc?.width}}in x
              {{upc?.height}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(upc?.weight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{item ? item?.totalVolume : dsdItem?.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid [title]="'Unit details'">
        <pm-grid-group style="margin-bottom: 0;">

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false" [errorMessages]="[candidateProductError?.upc, candidateProductError?.upcCheckDigit]">
              {{upc?.scanCodeId}}-{{upc?.scanCodeId | upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'">
              {{productData?.productDescription}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(productData?.productDescription) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false" >
              {{productData?.customerFriendlyDescriptionOne}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false">
              {{productData?.customerFriendlyDescriptionTwo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false">
              {{productData?.signRomanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <pm-attachment-upload-review-page [attachments]="candidate.attachments" (updateCallback)="addAttachments($event)"></pm-attachment-upload-review-page>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="isPageEditable"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.buyerComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>


  <pm-review-drawer-container>
    <!--  Existing Cases  -->
    <ng-container *ngIf="displayingExistingCasesDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>Existing cases</h2>
        <ng-template *ngIf="productData?.items" ngFor let-casePack [ngForOf]="productData?.items">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!casePack.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(casePack)"></i>
              <i *ngIf="casePack.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(casePack)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{casePack.caseUpc}}-{{casePack.caseUpc | upcCheckDigit}}
                </label>
                <i *ngIf="casePack.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                WHS | {{casePack.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="casePack.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{casePack.supplierItems[0]?.pack}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
            <!--  Inner Pack -->
            <pm-attribute-grid [title]="'Inner Packs'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Inner pack quantity'" [canEdit]="false">
                    {{casePack.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>

        <ng-template *ngIf="productData?.dsdItems" ngFor let-dsdItem [ngForOf]="productData?.dsdItems">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!dsdItem.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(dsdItem)"></i>
              <i *ngIf="dsdItem.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(dsdItem)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{dsdItem.upc}}-{{dsdItem.upc | upcCheckDigit}}
                </label>
                <i *ngIf="dsdItem.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                DSD | {{dsdItem.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="dsdItem.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{dsdItem?.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <!--  buyer info  -->
    <ng-container *ngIf="displayingBuyerInfo">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link"
                    (click)="save()"></button>
          </div>
        </div>
        <div class="attributes-stacked">

          <h2>Buyer Details</h2>
          <p>Provide the following information and click approve to accept the product and send it to SCA and Procurement
            Support review.</p>
          <h3 *ngIf="candidate?.codeDate">Required Fields</h3>

          <pm-attribute-display-text-input [attribute]="inboundSpecConfiguration"
                                           [attributeError]="candidateError?.inboundSpecDays"
                                           [(model)]="candidate.inboundSpecDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="reactionDaysConfiguration"
                                           [(model)]="candidate.warehouseReactionDays" [attributeError]="candidateError?.warehouseReactionDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="guaranteeToStoreDaysConfiguration"
                                           [(model)]="candidate.guaranteeToStoreDays" [attributeError]="candidateError?.guaranteeToStoreDays">
          </pm-attribute-display-text-input>

          <h3 *ngIf="candidate?.codeDate">Additional fields</h3>
          <div class="row season-year-row">
            <div class="col-9" style="padding-right: 0;">
              <pm-attribute-display-typeahead [attribute]="seasonConfiguration"
                                              [(model)]="candidate.season"
                                              [attributeError]="candidateError?.season"
                                              (selection)="seasonChange($event)"
                                              [showErrorMessage]="false">
              </pm-attribute-display-typeahead>
            </div>
            <div class="col-3" style="padding-left: 0;">
              <pm-attribute-display-text-input [attribute]="yearConfiguration" [(model)]="candidate.seasonYear"
                                               [attributeError]="candidateError?.seasonYear"
                                               [showErrorMessage]="false">
              </pm-attribute-display-text-input>
            </div>
          </div>
          <p *ngIf="candidateError?.season" class="error-message pl-2">
            {{candidateError.season}}
          </p>
          <p *ngIf="candidateError?.seasonYear" class="error-message pl-2">
            {{candidateError.seasonYear}}
          </p>
          <pm-attribute-display-text-input [attribute]="remark1Configuration"
                                           [(model)]="candidateProduct.remark1"
                                           [attributeError]="candidateProductError?.remark1">
          </pm-attribute-display-text-input>
          <pm-attribute-display-text-input [attribute]="remark2Configuration"
                                           [(model)]="candidateProduct.remark2"
                                           [attributeError]="candidateProductError?.remark2">
          </pm-attribute-display-text-input>

          <pm-attribute-display-checkbox class="checkbox-padding" [attribute]="rushFlagConfiguration"
                                         [(model)]="candidate.rushFlag">
          </pm-attribute-display-checkbox>

          <div class="text-right">
            <p-button class="m-2" label="Approve" (onClick)="onClickApprove()" [disabled]="isApproveDisabled"></p-button>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="buyerComments"
                                           [(model)]="tempCommentHolder"
                                           [attributeError]="candidateError?.buyerComment">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.buyerComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.buyerComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button *ngIf="isPageEditable" class="ghost m-2" label="Reject" (onClick)="showRejectPanel($event, rejectOverlay, rejectCandidateDiv)" [disabled]="isRejectDisabled"></p-button>
    <p-button *ngIf="isPageEditable" class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-review>
<div>
  <div #rejectCandidateDiv></div>
  <pm-static-overlay-panel #rejectOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
    <pm-reject-candidate-overlay (cancelClicked)="rejectOverlay.hide()" (rejectButtonClicked)="reject($event, rejectOverlay, rejectCandidateDiv)"></pm-reject-candidate-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatConfirmDiv></div>
  <pm-static-overlay-panel #rnaMatConfirmOverlayPanel appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'" [hideTransitionOptions]="'0ms'">
    <pm-request-new-attribute-confirm-overlay *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                              [title]="'Request a new ' + requestNewMatAttributeOverrideWrapper?.requestNewMatAttributeForm?.attributeBusinessFriendlyDescription + ' value'"
                                              [classOverride]="'col-md-5'"
                                              (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatConfirmOverlayPanel, requestNewMatAttributeOverrideWrapper)"
                                              (continueButtonClicked)="matUtilService.showRequestNewAttributeFormPanel($event, rnaMatFormOverlay, rnaMatFormOverlayDiv, rnaMatConfirmOverlayPanel)">
    </pm-request-new-attribute-confirm-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatFormOverlayDiv></div>
  <pm-static-overlay-panel #rnaMatFormOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'">
    <pm-request-new-mat-attribute-form *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                       (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatFormOverlay, requestNewMatAttributeOverrideWrapper)"
                                       [(model)]="requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm"
                                       (sendRequestClicked)="matUtilService.sendRequestAndCloseModal(this.candidate, requestNewMatAttributeOverrideWrapper, rnaMatFormOverlay)">
    </pm-request-new-mat-attribute-form>
  </pm-static-overlay-panel>
</div>
