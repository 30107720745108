import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  PharmacyCardErrors,
  PharmacyCardModel,
  PharmacyCardPermissions,
  TextInputType
} from 'pm-models';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper, VisiblePermissionsMapper} from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-pharmacy-card',
  templateUrl: './pharmacy-card.component.html',
  styleUrls: ['./pharmacy-card.component.scss']
})
export class PharmacyCardComponent implements OnInit {

  @Input()
  model: PharmacyCardModel | Candidate;

  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: PharmacyCardPermissions;

  @Input()
  errorModel: PharmacyCardErrors;

  _drugNameAbbreviations;

  @Input()
  get drugNameAbbreviations() {
    return this._drugNameAbbreviations;
  }

  set drugNameAbbreviations(e) {
    this._drugNameAbbreviations = e;
    this.drugNameAbbreviationConfiguration.collections = this._drugNameAbbreviations;
  }

  _drugScheduleTypeCodes;

  @Input()
  get drugScheduleTypeCodes() {
    return this._drugScheduleTypeCodes;
  }

  set drugScheduleTypeCodes(e) {
    this._drugScheduleTypeCodes = e;
    this.drugScheduleTypeCodeConfiguration.collections = this._drugScheduleTypeCodes;
  }

  _pseTypes;

  @Input()
  get pseTypes() {
    return this._pseTypes;
  }

  set pseTypes(e) {
    this._pseTypes = e;
    this.pseTypeConfiguration.collections = this._pseTypes;
  }

  constructor() { }

  ngOnInit() {
  }

  nationalDrugCodeIdConfiguration: AttributeTextInputConfig = {
    label: 'NDC',
    description: 'Enter the national drug code.',
    placeholderText: 'Enter NDC...',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.nationalDrugCodeId),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.nationalDrugCodeId),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.nationalDrugCodeId),
    isRequired: false,
    name: 'ndcId',
    maxLength: 12,
    textInputType: TextInputType.integer
  };

  nationalDrugCodeIdChange(event) {
    this.modelChange.emit(this.model);
  }

  averageWholesalePharmacyCostConfiguration: AttributeTextInputConfig = {
    label: 'Average wholesale',
    description: 'Enter the AWP or average wholesale cost for this product.',
    placeholderText: 'Enter AWP...',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.averageWholesalePharmacyCost),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.averageWholesalePharmacyCost),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.averageWholesalePharmacyCost),
    isRequired: false,
    name: 'awpId',
    decimalCount: 4,
    numberCount: 7,
    textInputType: TextInputType.decimal
  };

  averageWholesalePharmacyCostChange(event) {
    this.modelChange.emit(this.model);
  }

  directPharmacyCostConfiguration: AttributeTextInputConfig = {
    label: 'Direct cost',
    description: '',
    placeholderText: 'Enter direct cost...',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.directPharmacyCost),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.directPharmacyCost),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.directPharmacyCost),
    isRequired: false,
    name: 'directCostId',
    decimalCount: 4,
    numberCount: 7,
    textInputType: TextInputType.decimal
  };

  directPharmacyCostChange(event) {
    this.modelChange.emit(this.model);
  }

  pseTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Contains Pseudoephedrine or Ephedrine',
    description: 'Let us know if this product contains either of these substances.',
    isRequired: false,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.pseType),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.pseType),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.pseType),
    name: '',
    idRef: 'pseId',
    displayRef: 'description',
    placeholderText: 'Select the PSE...',
    collections: this.pseTypes
  };

  pseGramsConfiguration: AttributeTextInputConfig = {
    label: 'PSE grams',
    description: 'Enter the amount of PSE present in grams.',
    placeholderText: 'Enter PSE grams...',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.pseGrams),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.pseGrams),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.pseGrams) || (!this.model || !this.model.pseType),
    isRequired: false,
    name: 'pseGrams',
    decimalCount: 4,
    numberCount: 5,
    textInputType: TextInputType.decimal,
  };

  pseGramsChange(event) {
    this.modelChange.emit(this.model);
  }

  pseChange(event) {
    this.model.pseType = event;
    if (!this.model.pseType) {
      this.model.pseGrams = null;
    }
    this.modelChange.emit(this.model);
  }

  drugNameAbbreviationConfiguration: AttributeTypeaheadConfig = {
    label: 'Drug abbreviation',
    description: ``,
    isRequired: false,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.drugNameAbbreviation),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.drugNameAbbreviation),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.drugNameAbbreviation),
    name: '',
    idRef: 'drugNameAbbreviationId',
    displayRef: 'description',
    placeholderText: 'Select the drug abbreviation...',
    collections: this.drugNameAbbreviations
  };

  drugNameAbbreviationChange(event) {
    this.model.drugNameAbbreviation = event;
    this.modelChange.emit(this.model);
  }

  drugScheduleTypeCodeConfiguration: AttributeTypeaheadConfig = {
    label: 'Drug schedule',
    description: `Select “A LEGEND ITEM” if the product’s schedule is (6), CII (2), CIII (3), 3N, CIV (4), 4n, CV (5), or 5N.`,
    isRequired: false,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.drugScheduleTypeCode),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.drugScheduleTypeCode),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.drugScheduleTypeCode),
    name: '',
    idRef: 'drugScheduleTypeCodeId',
    displayRef: 'description',
    placeholderText: 'Select the drug schedule...',
    collections: this.drugScheduleTypeCodes
  };

  drugScheduleTypeCodeChange(event) {
    this.model.drugScheduleTypeCode = event;
    this.modelChange.emit(this.model);
  }
}
