import { ErrorProductLineTable } from './errorProductLineTable';

export class ErrorCandidate {

  candidateId: any;
  likeId: any;
  numberOfStores: any;
  description: any;
  displayName: any;
  createdTime: any;
  createdBy: any;
  lastUpdatedTime: any;
  lastUpdatedBy: any;
  candidateProducts: ErrorProductLineTable[];
  hasNoProductLinesError: boolean;
  vendor: any;
  contactName: any;
  contactNumber: any;
  contactEmail: any;
  buyer: any;
  brand: any;
  costOwner: any;
  topToTop: any;
  channel: any;
  totalVolume: any;
  productLength: any;
  prePrice: any;
  unitOfMeasure: any;
  productWidth: any;
  retailXFor: any;
  retailPrice: any;
  suggestedXFor: any;
  suggestedRetailPrice: any;
  retailSize: any;
  productHeight: any;
  packageType: any;
  productWeight: any;
  mapRetail: any;
  foodStamp: any;
  taxable: any;
  flexibleSpendingAccount: any;
  masterLength: any;
  innerLength: any;
  vendorTier: any;
  innerListCost: any;
  masterHeight: any;
  innerHeight: any;
  vendorTie: any;
  unitCost: any;
  masterWidth: any;
  innerWidth: any;
  codeDate: any;
  costLink: any;
  masterWeight: any;
  innerWeight: any;
  maxShelfLife: any;
  maxShelfLifeSum: any;
  inboundSpecDays: any;
  guaranteeToStoreDays: any;
  warehouseReactionDays: any;
  masterCube: any;
  innerCube: any;
  masterPack: any;
  innerPack: any;
  commodity: any;
  subCommodity: any;
  season: any;
  seasonYear: any;
  retailLink: any;
  weightSwitch: any;
  retailType: any;
  productType: any;
  merchandiseType: any;
  masterListCost: any;
  vendorComment: any;
  scaComment: any;
  dsdSwitch: boolean;
  warehouseSwitch: boolean;
  lane: any;
  status: any;
  cubeAdjustedFactor: any;

  displayReadyUnit: boolean; // no validation on this
  displayReadyUnitType: boolean;
  displayReadyUnitRowsFacing: boolean;
  displayReadyUnitRowsDeep: boolean;
  displayReadyUnitRowsHigh: boolean;
  displayReadyUnitOrientation: boolean; // no validation on this
  displayReadyUnitCalcMsg: string;
  noStoresError: boolean;

}
