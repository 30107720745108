import { Injectable } from '@angular/core';
import { WorkflowService } from './workflow.service';
import { CandidateService } from './candidate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GrowlService } from '../growl/growl.service';
import {
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Hierarchy,
  LocationGroupStores,
  Product,
  Store,
  Task,
  TaskDecision,
  Upc,
  Vendor
} from 'pm-models';
import { BehaviorSubject, forkJoin, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CandidateUtilService } from './candidate-util.service';
import { LookupService } from './lookup.service';
import { UUID } from 'angular2-uuid';
import { EmailService } from './email.service';
import { CostService } from './cost.service';
import { UpcService } from 'pm-components';

@Injectable({
  providedIn: 'root'
})
export class InvitedDistributorService {


  constructor(private workflowService: WorkflowService, private candidateService: CandidateService, private route: ActivatedRoute,
              private router: Router, private growlService: GrowlService, private candidateUtilService: CandidateUtilService,
              private lookupService: LookupService, public emailService: EmailService, public costService: CostService, private upcService: UpcService) {
    this.invitedCandidates$.pipe(
      switchMap(invitedCandidates => {
        if (this.candidateIdsToShow) {
          return of(invitedCandidates.filter(invitedCandidate =>
            this.candidateIdsToShow.some(candidateIdToShow => invitedCandidate.candidateId === candidateIdToShow)));
        } else {
          return of(invitedCandidates);
        }
      }),
      tap(invitedCandidates => {
        this.apNumberToStoreNumbersMap = new Map<number, number[]>();
        invitedCandidates.forEach(candidate => this.addInvitedCandidateToApNumberToStoreMap(candidate));
      })
    ).subscribe(invitedCandidates => this.candidatesToShow = invitedCandidates);
  }

  private taskId = undefined;
  private task: Task;
  public candidateIdsToShow: number[];
  public candidatesToShow: Candidate[] = [];
  public invitedCandidates$ = new BehaviorSubject<Candidate[]>([]);
  private DSD_INVITED_SUPPLIER_WORKFLOW = 'dsdInvitedSupplierProcess';
  private INVITED_DISTRIBUTOR_TASK_NAME = 'Dsd Invited Supplier';
  private PIA_NEW_PRODUCT_WORKFLOW = 'piaNewProductProcess';
  private PIA_NEW_PRODUCT_TASK_NAME = 'PIA New Product Flow';
  private isClosedTask = false;
  public candidate: Candidate;
  public originalCandidate: Candidate = undefined;
  public currentCandidateProductIndex = 0;
  // private commodities: Commodity[] = undefined;
  private setupAddDistributorDetailsError: CandidateError = undefined;
  private caseDetailsError: CandidateError = undefined;
  private storeAuthError: CandidateError = undefined;


  private storesSearched: number[];
  private storesInput: string;

  public authGroups: LocationGroupStores[];
  public selectedAuthGroups: LocationGroupStores[];
  public notFoundStores: number[];

  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public productData: Product;
  public upc: Upc;
  public productImageUrl: string = null;

  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public currentErrorPanel;
  public storeErrorApNumber;
  public storeErrorStoreNumber;
  public storeErrorGroupId;
  public overrideActivatedStore: boolean;
  public vendorApToAuthGroupsMap: Map<number, LocationGroupStores[]> = new Map<number, LocationGroupStores[]>();
  public invitedCandidates: Candidate[] = [];
  public allDsdCandidates: Candidate[] = [];

  public hasSameStoreError = false;
  public resolvedSameStoreError = false;
  // supplier candidates
  public notSubmittedCandidates: Candidate[] = [];
  // pia candidates
  public submittedCandidates: Candidate[] = [];
  public approvedCandidates: Candidate[] = [];
  public rejectedCandidates: Candidate[] = [];
  // contains all invited candidates ap numbers, and their selected stores.
  public apNumberToStoreNumbersMap: Map<number, number[]> = new Map();
  // contains all activated ap numbers and their stores
  public activatedApNumberToStoreNumbersMap: Map<number, number[]> = new Map();
  public activatedApNumberToDisplayNameMap: Map<number, string> = new Map();
  public activatedApNumberToGroupStoresMap: Map<number, Map<number, number[]>> = new Map();
  public pssDepartments: Map<any, any[]> = new Map<any, any[]>();

  public vendorsToInvite: Vendor[];
  public currentSuppliersString = '';
  public invitedSuppliersString = '';

  public supplierConfiguration: AttributeTypeaheadConfig;
  public isViewingInvitedVendors: boolean = false;
  public showAddMoreSuppliers = false;
  public isSelectingDistributors: boolean = false;

  /**
   * Resets default value.
   */
  resetService() {
    this.taskId = undefined;
    this.candidateIdsToShow = undefined;
    this.invitedCandidates$.next([]);
    this.task = undefined;
    this.isClosedTask = false;
    this.candidate = undefined;
    this.originalCandidate = undefined;
    this.currentCandidateProductIndex = 0;
    this.pssDepartments = new Map();
    this.resetProductAndStoreData();
    this.resetErrors();
  }

  /**
   * Resets product and store data.
   */
  public resetProductAndStoreData() {
    this.storesSearched = null;
    this.authGroups = null;
    this.selectedAuthGroups = null;
    this.storesInput = null;
    this.notFoundStores = null;
    this.upc = null;
    this.productData = null;
    this.productImageUrl = null;
    this.currentErrorPanel = undefined;
    this.storeErrorApNumber = undefined;
    this.storeErrorStoreNumber = undefined;
    this.overrideActivatedStore = undefined;
    this.vendorApToAuthGroupsMap = new Map();
    this.invitedCandidates = [];
    this.allDsdCandidates = [];

    this.hasSameStoreError = false;
    this.resolvedSameStoreError = false;
    this.notSubmittedCandidates = [];
    this.submittedCandidates = [];
    this.approvedCandidates = [];
    this.rejectedCandidates = [];
    this.apNumberToStoreNumbersMap = new Map();
    this.activatedApNumberToStoreNumbersMap = new Map();
    this.activatedApNumberToDisplayNameMap = new Map();
    this.activatedApNumberToGroupStoresMap = new Map();

    this.vendorsToInvite = undefined;
    this.currentSuppliersString = '';
    this.invitedSuppliersString = '';

    this.supplierConfiguration = undefined;
    this.isViewingInvitedVendors = false;
    this.showAddMoreSuppliers = false;
    this.isSelectingDistributors = false;
  }


  public resetErrors() {
    this.setupAddDistributorDetailsError = new CandidateError();
    this.caseDetailsError = new CandidateError();
    this.storeAuthError = new CandidateError();
    this.candidateError = undefined;
    this.candidateProductError = undefined;
  }
  /**
   * Returns the current candidate.
   */
  public getCandidate() {
    return this.candidate;
  }

  /**
   * Returns the original candidate.
   */
  public getOriginalCandidate() {
    return this.originalCandidate;
  }

  /**
   * Returns the task.
   */
  public getTask() {
    return this.task;
  }

  /**
   * Returns task id.
   */
  public getTaskId() {
    return this.taskId;
  }


  public getStoreAuthError(): CandidateError {
    return this.storeAuthError;
  }

  public setStoreAuthError(candidateError): CandidateError {
    this.storeAuthError = candidateError;
    return this.storeAuthError;
  }

  public getCaseDetailsError(): CandidateError {
    return this.caseDetailsError;
  }

  public setCaseDetailsError(candidateError): CandidateError {
    this.caseDetailsError = candidateError;
    return this.caseDetailsError;
  }

  public getSetupAddDistributorDetailsError(): CandidateError {
    return this.setupAddDistributorDetailsError;
  }

  public setSetupAddDistributorDetailsError(candidateError): CandidateError {
    this.setupAddDistributorDetailsError = candidateError;
    return this.setupAddDistributorDetailsError;
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = JSON.parse(JSON.stringify(candidate));
    this.candidate = JSON.parse(JSON.stringify(candidate));
  }

  /**
   * Sets and returns the original and current.
   * @param params
   */
  public setCandidateByUrlParameters(params): Observable<any> {
    if (params.has('taskId')) {
      return this.workflowService.getTaskByIdWithVariables(params['params']['taskId']).pipe(
        switchMap(
          (task) => {
            this.task = task;
            this.taskId = this.task.id;

            if (this.task.name !== this.INVITED_DISTRIBUTOR_TASK_NAME &&
              this.task.name !== this.PIA_NEW_PRODUCT_TASK_NAME) {
              this.router.navigate(['/tasks'], {
                queryParams: {
                  growlMessage: 'Candidate is not in available for supplier revision.',
                  growlToUse: GrowlService.SEVERITY_ERROR
                }
              });
              return observableThrowError('Candidate is not in available for supplier revision.');
            }

            return this.getCandidateByCandidateId(task.candidateId);
          }
        ));
    } else if (params.has('candidateId')) {
      this.isClosedTask = true;
      const candidateId = params['params']['candidateId'];
      this.candidateService.getCandidate(candidateId)
        .subscribe((candidate) => {
          this.setOriginalAndCurrentCandidate(candidate);
        });
    }
  }

  /**
   * Returns a candidate by the candidate id.
   * @param candidateId
   */
  private getCandidateByCandidateId(candidateId): Observable<any> {
    return this.candidateService.getCandidate(candidateId).pipe(
      tap(
        (candidate) => {
          if (candidate.candidateType === Candidate.ADDITIONAL_DISTRIBUTOR ||
            candidate.candidateType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
            this.setOriginalAndCurrentCandidate(candidate);
            this.resetErrors();
          } else {
            const message = 'Invalid Candidate Task Flow : "' + candidate.candidateType + '" expected "' +
              Candidate.ADDITIONAL_DISTRIBUTOR + '" or "' + Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR + '" ."';
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: message, growlToUse: GrowlService.SEVERITY_ERROR}
            });
            return observableThrowError(message);
          }
        }
      ),
      catchError(
        (error) => {
          // if there was an error retrieving task, route back to tasks page with the error
          this.router.navigate(['/tasks'], {
            queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
          });
          return observableThrowError(error);

        })
    );
  }

  initializeProductData() {
    return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[0].upc, []).pipe(
      switchMap((productData) => {
          if (productData) {
            this.productData = productData;
            this.setInformationFromProductData();
            return this.lookupService.getUpc(this.candidate.candidateProducts[0].upc).pipe(
              switchMap((upc) => {
                if (upc) {
                  this.upc = upc;
                  return this.updateUpcDataForCandidate(this.candidate);
                } else {
                  return of({});
                }
              }));
          } else {
            this.growlService.addError('Unable to find an active product tied to UPC: ' + this.candidate.candidateProducts[0].upc + '.');
            return of({});
          }
        }
      )
    );
  }

  updateUpcDataForCandidate(candidate: Candidate): Observable<any> {
    if (!this.upc || !candidate) {
      return of({});
    }
    candidate.suggestedXFor = this.upc.xfor;
    candidate.suggestedRetailPrice = this.upc.retailPrice;

    let hierarchy;
    if (this.upc.dsdItem) {
      hierarchy = this.upc.dsdItem.hierarchy;
      if (!candidate.candidateProducts[0]?.isAddComplimentaryPlu) {
        candidate.masterPack = this.upc.dsdItem.innerPackQuantity;
      }
    } else if (this.upc.item) {
      hierarchy = this.upc.item?.hierarchy;
    }
    if (!hierarchy) {
      this.growlService.addError('UPC ' + this.upc.scanCodeId + ' isn\'t tied to any activate WHS or DSD items.');
      return of({});
    }
    return this.handleCommodityChanges(hierarchy, candidate);
  }

  handleCommodityChanges(hierarchy: Hierarchy, candidate: Candidate): Observable<any> {
    if (!hierarchy?.commodity?.commodityId) {
      return of({});
    }
    return this.lookupService.findCommodityById(hierarchy.commodity.commodityId).pipe(
      tap((commodity) => {
        if (!candidate.commodity) {
          candidate.commodity = commodity;
        } else if (('' + candidate.commodity.commodityId) !== commodity?.commodityId) {
          if (!candidate.overrideDepartment && (candidate.commodity.departmentId !== commodity.departmentId ||
            candidate.commodity.subDepartmentId !== commodity.subDepartmentId)) {
            candidate.candidateProducts[0].locationGroupStores = [];
          }
        }
        if (!candidate.pssDepartment && !candidate.overrideDepartment) {
          candidate.pssDepartment = commodity.pssDepartment;
        }
        for (let x = 0; x < commodity.subCommodityList.length; x++) {
          if (commodity.subCommodityList[x].subCommodityId.toString() === hierarchy.subCommodityId.toString()) {
            candidate.subCommodity = commodity.subCommodityList[x];
            break;
          }
          candidate.commodity = commodity;
        }
      })
    );
  }

  public getProductImage() {
    return this.productImageUrl ? this.productImageUrl : this.DEFAULT_NO_PRODUCT_IMAGE;
  }

  /**
   * Returns the current candidate Product error model.
   */
  public getCurrentCandidateProductError(candidateError: CandidateError): CandidateProductError {
    return this.getCandidateProductErrorByCandidateProduct(candidateError, this.getCurrentCandidateProduct());
  }

  /**
   * Returns the current candidate Product error model.
   */
  public getCandidateProductErrorByCandidateProduct(candidateError: CandidateError, candidateProduct: CandidateProduct): CandidateProductError {
    if (candidateError && candidateProduct) {
      const tempCandidateProductError = candidateError.candidateProductErrors[candidateProduct.id];
      if (!tempCandidateProductError) {
        // empty candidateProductErrors returns as object, so must override with a new map to prevent undefined .set() method error.
        if (!(candidateError.candidateProductErrors instanceof Map)) {
          candidateError.candidateProductErrors = new Map<string, CandidateProductError>();
        }
        const candidateProductError = new CandidateProductError();
        candidateError.candidateProductErrors.set(candidateProduct.id,
          candidateProductError);
        return candidateProductError;
      }
      return tempCandidateProductError;
    }
    return new CandidateProductError();
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    if (this.candidate && this.candidate.candidateProducts) {
      return this.candidate.candidateProducts[this.currentCandidateProductIndex];
    }
  }


  /**
   * Saves candidate and navigates to the url path provided.
   */
  saveCandidateAndNavigate(url: string, updateApNumber: boolean) {
    if (this.candidateUtilService.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          if (updateApNumber) {
            let apNumber;
            if (!this.candidate.vendor) {
              apNumber = null;
            } else {
              apNumber = this.candidate.vendor.apNumber;
            }
            this.workflowService.updateApNumber(apNumber, this.task.processInstanceId).subscribe(() => {
              this.setOriginalAndCurrentCandidate(savedCandidate);
              this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}});
            });
          } else {
            this.setOriginalAndCurrentCandidate(savedCandidate);
            this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}});
          }
        });
      } else {
        this.router.navigate([url], {queryParams: {taskId: this.getTaskId()}});
      }
    }
  }
  getStoresSearched() {
    if (this.storesSearched && this.storesSearched.length > 0) {
      return this.storesSearched;
    } else {
      return  null;
    }
  }

  setStoresSearched(storesSearched: number[]) {
    this.storesSearched = storesSearched;
  }

  getStoresInput() {
    return this.storesInput;
  }

  setStoresInput(storesInput: string) {
    this.storesInput = storesInput;
  }

  getNotFoundStores() {
    if (!this.notFoundStores) {
      this.notFoundStores = [];
    }
    return this.notFoundStores;
  }

  setNotFoundStores(notFoundStores: number[]) {
    this.notFoundStores = notFoundStores;
  }

  getAuthGroups() {

    if (this.authGroups && this.authGroups.length > 0) {
      return this.authGroups;
    } else {
      return null;
    }
  }
  getSelectedAuthGroups() {

    if (this.selectedAuthGroups && this.selectedAuthGroups.length > 0) {
      return this.selectedAuthGroups;
    } else {
      return null;
    }
  }

  setAuthGroups(authGroups: LocationGroupStores[]) {
    this.authGroups = authGroups;
  }

  setSelectedAuthGroups(authGroups: LocationGroupStores[]) {
    this.selectedAuthGroups = JSON.parse(JSON.stringify(authGroups));
  }

  findAllAuthGroups(): Observable<LocationGroupStores[]> {
    if (this.candidate.overrideDepartment) {
      return this.lookupService.findAllAuthGroups(this.candidate.vendor.apNumber, this.candidate.overrideSubDepartment.departmentId,
        this.candidate.overrideSubDepartment.subDepartmentId);
    } else {
      return this.lookupService.findAllAuthGroups(this.candidate.vendor.apNumber, this.candidate.commodity.departmentId,
        this.candidate.commodity.subDepartmentId);
    }
  }

  /**
   * if There's checked auth groups, open them, and selected all stores. Else, open all
   * groups.
   */
  openAuthGroups() {
    let isCheckedGroup = false;
    for (let x = 0; x < this.getSelectedAuthGroups().length; x++) {
      if (this.getSelectedAuthGroups()[x].checked === true) {
        isCheckedGroup = true;
      }
    }

    // If groups sent, show them as viewing, and check all stores inside.
    if (isCheckedGroup) {
      for (let x = 0; x < this.getSelectedAuthGroups().length; x++) {
        if (this.getSelectedAuthGroups()[x].checked === true) {
          this.getSelectedAuthGroups()[x].isViewing = true;
          this.onAuthGroupChecked({checked: true}, this.getSelectedAuthGroups()[x]);
        }
      }
      // if no groups set, show all as viewing.
    } else {
      for (let x = 0; x < this.getSelectedAuthGroups().length; x++) {
        this.getSelectedAuthGroups()[x].isViewing = true;
      }
    }
  }


  onAuthGroupChecked(event, authGroup) {
    const checked = event.checked;

    if (!authGroup.stores || authGroup.stores.length === 0) {
      return;
    }

    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].checked = checked;
    }
  }

  /**
   * Finds selected auth group from candidate in fetched auth groups, hides not selected stores, and checks selected stores.
   */
  setSelectedAuthGroupsFromCandidate() {
    // if there's no candidate groups or no auth groups to update, return;
    if (!this.getCandidate().candidateProducts[0].locationGroupStores ||
      this.getCandidate().candidateProducts[0].locationGroupStores.length === 0 ||
      !this.getAuthGroups() || this.getAuthGroups().length === 0) {
      return;
    }
    const selectedAuthGroups: LocationGroupStores[] = [];
    for (let x = 0; x < this.getCandidate().candidateProducts[0].locationGroupStores.length; x++) {
      for (let y = 0; y < this.getAuthGroups().length; y++) {
        if (this.getCandidate().candidateProducts[0].locationGroupStores[x].splrLocationGroupId ===
          this.getAuthGroups()[y].splrLocationGroupId) {
          const selectedAuthGroup: LocationGroupStores = JSON.parse(JSON.stringify(this.getAuthGroups()[y]));
          selectedAuthGroup.isViewing = true;
          selectedAuthGroup.listCost = this.getCandidate().candidateProducts[0].locationGroupStores[x].listCost;
          selectedAuthGroups.push(selectedAuthGroup);
          this.setAuthGroupStores(this.getCandidate().candidateProducts[0].locationGroupStores[x], selectedAuthGroup);
          this.setMissingStores(this.getCandidate().candidateProducts[0].locationGroupStores[x], selectedAuthGroup);
          break;
        }
      }
    }
    this.setSelectedAuthGroups(selectedAuthGroups);
  }

  setMissingStores(candidateAuthGroup: LocationGroupStores, selectedAuthGroup: LocationGroupStores) {
    for (let x = 0; x < candidateAuthGroup.stores.length; x++) {
      let isFound = false;
      for (let y = 0; y < selectedAuthGroup.stores.length; y++) {
        if (candidateAuthGroup.stores[x].custLocationNumber === selectedAuthGroup.stores[y].custLocationNumber) {
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        this.getNotFoundStores().push(candidateAuthGroup.stores[x].custLocationNumber);
      }
    }
  }

  /**
   * Hides not found candidateAuthGroup stores, and checks found candidateAuthGroup stores in selectedAuthGroup.
   */
  setAuthGroupStores(candidateAuthGroup: LocationGroupStores, selectedAuthGroup: LocationGroupStores) {

    let allChecked = true;
    for (let y = 0; y < selectedAuthGroup.stores.length; y++) {
      selectedAuthGroup.stores[y].isHidden = true;
      selectedAuthGroup.stores[y].listCost = selectedAuthGroup.listCost;
      for (let x = 0; x < candidateAuthGroup.stores.length; x++) {
        if (candidateAuthGroup.stores[x].custLocationNumber === selectedAuthGroup.stores[y].custLocationNumber) {
          selectedAuthGroup.stores[y].checked = true;
          selectedAuthGroup.stores[y].isHidden = false;
          break;
        }
      }
      if (!selectedAuthGroup.stores[y].checked) {
        allChecked = false;
      }
    }
    if (allChecked) {
      selectedAuthGroup.checked = true;
    }
  }

  validateHasNoStores(): boolean {
    // if auth groups haven't been loaded, return false.
    if (!this.authGroups) {
      return false;
    } else if (this.authGroups.length === 0) {
      return true;
    }
    for (let x = 0; x < this.authGroups.length; x++) {
      if (this.authGroups[x].stores || this.authGroups[x].stores.length > 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * Saves candidate.
   */
  saveCandidate() {
    if (this.candidateUtilService.isEmptyOrSpaces(this.candidate.description)) {
      this.growlService.addError('Failed to save because a candidate name is required.');
    } else {
      if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          this.setOriginalAndCurrentCandidate(savedCandidate);
        });
      }
    }
  }

  getAllCandidateErrors(): CandidateError[] {
    return [this.getSetupAddDistributorDetailsError(), this.getCaseDetailsError(), this.getStoreAuthError()];
  }

  public updatePageErrors(candidateError) {
    this.setupAddDistributorDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.caseDetailsError = JSON.parse(JSON.stringify(candidateError));
    this.storeAuthError = JSON.parse(JSON.stringify(candidateError));
  }
  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user to URL with candidate ID
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param url url to route to.
   * @param candidateID to include in query params of URL.
   */
  public saveAndCompleteTaskAndRouteToUrlWithCandidateId(action: string, taskDecision: TaskDecision, url: string, candidateID: number) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
        .subscribe(() => {
          this.router.navigate([url], {queryParams: {candidateId: candidateID}}).then();
        });
    });
  }

  scrollToTop() {
    document.getElementsByClassName('pm-editor-header')[0].scrollIntoView();
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.getOriginalCandidate().vendor)
      !== JSON.stringify(this.getCandidate().vendor);
  }


  /**
   * Creates the candidate and navigates to the supplier details.
   * @param candidate
   * @param url
   * @param callback
   */
  public createCandidateAndNavigate(candidate: Candidate, url: string, callback?: () => void) {
    this.candidateService.createNewCandidate(candidate).subscribe((newCandidate: Candidate) => {
      this.setOriginalAndCurrentCandidate(JSON.parse(JSON.stringify(newCandidate)));
      this.resetErrors();
      let workflow = this.DSD_INVITED_SUPPLIER_WORKFLOW;
      if (!this.isSupplierInitiated()) {
        workflow = this.PIA_NEW_PRODUCT_WORKFLOW;
      }
      this.createProcessInstanceWithCandidateId(newCandidate.candidateId, url, workflow, callback);
    });
  }


  /**
   * Create process instance with newly created candidate id and routes to the supplier details page.
   */
  private createProcessInstanceWithCandidateId(candidateId: number, url: string, workflow: string, callback?: () => void) {
    this.workflowService.createProcessInstanceWithCandidateId(candidateId, workflow)
      .subscribe(taskId => {
        if (taskId) {
          this.taskId = taskId;
          this.workflowService.getTaskByIdWithVariables(taskId).subscribe((task) => {
            this.task = task;
            this.router.navigate([url], {queryParams: {taskId: taskId}}).finally(callback);
          });
        } else {
          this.growlService.addError('Problem creating process instance.');
        }
      });
  }

  public setupSupplierInitiatedNewCandidate() {
    this.candidate = new Candidate();
    this.candidate.dsdSwitch = true;
    this.candidate.candidateType = Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR;
    this.candidate.candidateProducts = [];
    this.candidate.candidateProducts.push({id: UUID.UUID(), candidateProductType: CandidateProduct.ADDITIONAL_DISTRIBUTOR});
    this.originalCandidate = JSON.parse(JSON.stringify(this.candidate));
  }
  public setupPiaInitiatedNewCandidate() {
    this.candidate = new Candidate();
    this.candidate.dsdSwitch = true;
    this.candidate.candidateType = Candidate.ADDITIONAL_DISTRIBUTOR;
    this.candidate.candidateProducts = [];
    this.candidate.candidateProducts.push({id: UUID.UUID(), candidateProductType: CandidateProduct.ADDITIONAL_DISTRIBUTOR});
    this.originalCandidate = JSON.parse(JSON.stringify(this.candidate));
  }

  public isSupplierInitiated() {
    return this.candidate && this.candidate.candidateType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR;
  }

  /**
   * Sets product level information.
   */
  setInformationFromProductData() {
    this.candidate.brand = this.productData.brand;
    this.candidate.productType = this.productData.productTypeId === Product.SELLABLE_PRODUCT_TYPE ?
      Candidate.SELLABLE : Candidate.NON_SELLABLE;
    this.candidate.candidateProducts[this.currentCandidateProductIndex].description = this.productData.productDescription;
    this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
    this.setCountryOfOriginFromProduct();
    this.setCaseDescriptionFromProductData();
  }

  /**
   * Sets case description from product data.
   */
  setCaseDescriptionFromProductData() {
    if (this.productData.items && this.productData.items.length > 0) {
      if (!this.getCurrentCandidateProduct().caseDescription) {
        this.getCurrentCandidateProduct().caseDescription = this.productData.items[0].description;
      }
    } else if (this.productData.dsdItems && this.productData.dsdItems.length > 0) {
      if (!this.getCurrentCandidateProduct().caseDescription) {
        this.getCurrentCandidateProduct().caseDescription = this.productData.dsdItems[0].description;
      }
    }
  }

  /**
   * Sets the country of origin if it hasn't been set.
   */
  setCountryOfOriginFromProduct() {

    if (this.getCurrentCandidateProduct().countryOfOrigin) {
      return;
    }

    let countryOfOriginId = null;
    if (this.productData.items && this.productData.items.length > 0) {
      countryOfOriginId = this.getCountryOfOriginIdFromItems();
    } else if (this.productData.dsdItems && this.productData.dsdItems.length > 0) {
      countryOfOriginId = this.getCountryOfOriginIdFromDsdItems();
    }
    if (!countryOfOriginId) {
      return;
    }

    this.lookupService.findCountryOfOrigin(countryOfOriginId).subscribe((countryOfOrigin) => {
        this.getCurrentCandidateProduct().countryOfOrigin = countryOfOrigin;
      }
    );
  }

  /**
   * Returns the country of origin id for items.
   */
  getCountryOfOriginIdFromItems() {
    for (const item of this.productData.items) {
      for (const supplierItem of item.supplierItems) {
        if (supplierItem.countryOfOriginId) {
          return  supplierItem.countryOfOriginId;
        }
      }
    }
    return null;
  }

  /**
   * Returns the country of origin id for dsd items.
   */
  getCountryOfOriginIdFromDsdItems() {
    for (const dsdItem of this.productData.dsdItems) {
      for (const dsdSupplierItem of dsdItem.dsdSupplierItems) {
        if (dsdSupplierItem.countryOfOriginId) {
          return  dsdSupplierItem.countryOfOriginId;
        }
      }
    }
    return null;
  }

  /**
   * Whether or not to show location groups.
   * @param locationGroupStore
   */
  showLocationGroup(locationGroupStore: LocationGroupStores) {
    return locationGroupStore.stores.length > 0 || (locationGroupStore.isViewing !== null && locationGroupStore.isViewing !== undefined);
  }

  /**
   * Returns true if the activated store has been overridden or reverted, or it is not a same store error and
   * is not a currently activated store.
   *
   * @param apNumber
   * @param custLocationNumber
   * @param overrideActivatedStore
   * @param apNumberToStoreNumbersMap
   * @param activatedApNumberToStoreNumbersMap
   */
  public showDefaultStore(apNumber, custLocationNumber, overrideActivatedStore) {
    return !this.isSameStoreError(apNumber, custLocationNumber) &&
      !this.isActivatedStoreError(apNumber, custLocationNumber, overrideActivatedStore);
  }

  public isActivatedStoreError(apNumber, custLocationNumber, overrideActivatedStore) {
    // OverrideActivatedStore will be null/undefined if not set/undone.
    return this.isCurrentlyActivatedStore(apNumber, custLocationNumber) &&
      (overrideActivatedStore === null || overrideActivatedStore === undefined);
  }

  public hasStoreConflicts(apNumber, custLocationNumber, overrideActivatedStore) {
    if (this.isSameStoreError(apNumber, custLocationNumber)) {
      return true;
    }
    // override active store === false means
    return this.isCurrentlyActivatedStore(apNumber, custLocationNumber) &&
      (overrideActivatedStore === null || overrideActivatedStore === undefined);
  }

  public isSameStoreError(apNumber, storeNumber) {

    for (const key of this.apNumberToStoreNumbersMap.keys()) {
      if (key === apNumber) {
        continue;
      }
      if (this.apNumberToStoreNumbersMap.get(key).includes(storeNumber)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns whether or not the current store is the same as a store on the active product.
   * @param apNumber
   * @param storeNumber
   */
  public isCurrentlyActivatedStore(apNumber, storeNumber) {

    if (!storeNumber) {
      return false;
    }

    for (const key of this.activatedApNumberToStoreNumbersMap.keys()) {
      if (this.activatedApNumberToStoreNumbersMap.get(key).includes(storeNumber)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true if the candidate and group has an active product store override.
   * @param candidateId
   * @param groupId
   * @param invitedCandidates
   */
  public hasActiveCandidateStoreOverride(candidateId, groupId, invitedCandidates): boolean {
    const candidate: Candidate = invitedCandidates.find(tempCandidate => tempCandidate.candidateId === candidateId);

    if (!candidate) {
      return;
    }

    for (const locationGroupStore of candidate.candidateProducts[0].locationGroupStores) {

      if (groupId !== locationGroupStore.splrLocationGroupId) {
        continue;
      }

      if (!locationGroupStore.stores || locationGroupStore.stores.length === 0) {
        return false;
      }
      const store = locationGroupStore.stores.find(
        tempStore => tempStore.overrideActivatedStore || tempStore.overrideActivatedStore === false);

      if (store) {
        return true;
      }
    }

    return false;
  }

  /**
   * Returns activated store overrides for a group has and candidate id.
   * @param candidateId
   * @param groupId
   * @param invitedCandidates
   */
  public getActiveCandidateStoreOverride(candidateId, groupId, invitedCandidates): Store[] {
    const candidate: Candidate = invitedCandidates.find(tempCandidate => tempCandidate.candidateId === candidateId);

    return this.getActiveCandidateStoreOverrideByCandidate(candidate, groupId);
  }

  public getActiveCandidateStoreOverrideByCandidate(candidate: Candidate, groupId): Store[] {
    if (!candidate) {
      return;
    }

    for (const locationGroupStore of candidate.candidateProducts[0].locationGroupStores) {

      if (groupId !== locationGroupStore.splrLocationGroupId) {
        continue;
      }

      if (!locationGroupStore.stores || locationGroupStore.stores.length === 0) {
        return [];
      }

      return locationGroupStore.stores.filter(store => store.overrideActivatedStore || store.overrideActivatedStore === false);

    }
    return [];
  }

  /**
   * Returns activated store string.
   * @param stores
   * @param locationGroupStores
   */
  public getActivatedTotalCountString(stores: any[], locationGroupStores: LocationGroupStores[]) {
    const selectedStoreCount = stores ? stores.length : 0;
    const totalStoreCount = this.getStoreCount(locationGroupStores);

    if (!selectedStoreCount || !totalStoreCount) {
      return;
    } else {
      return 'Total stores: ' + selectedStoreCount + ' of ' + totalStoreCount + ' stores.';
    }
  }

  /**
   * Returns the total selected stores of all stores.
   * @param selectedLocationGroupStores
   * @param allLocationGroupStores
   */
  public getTotalStoreCountString(selectedLocationGroupStores: LocationGroupStores[], allLocationGroupStores: LocationGroupStores[]) {
    const selectedStoreCount = this.getStoreCount(selectedLocationGroupStores);
    const totalStoreCount = this.getStoreCount(allLocationGroupStores);

    if (!totalStoreCount) {
      return;
    } else {
      return 'Total stores: ' + (selectedStoreCount ? selectedStoreCount : 0) + ' of ' + totalStoreCount + ' stores.';
    }
  }

  /**
   * Returns total store in the list of LocationGroupStores.
   * @param locationGroupStores
   * @private
   */
  private getStoreCount(locationGroupStores: LocationGroupStores[]) {
    if (!locationGroupStores) {
      return 0;
    }

    let storeCount = 0;
    for (let x = 0; x < locationGroupStores.length; x++) {
      storeCount += locationGroupStores[x].stores.length;
    }
    return storeCount;
  }

  public getAuthGroupStoreCountLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  public getActivatedStoreCountLabel(stores: any[]): string {
    if (stores) {
      if (stores.length < 2) {
        return stores.length + ' store';
      } else {
        return stores.length + ' stores';
      }
    }
  }

  /**
   * Returns the total number of stores for an AP that have conflicts
   */
  public getAllStoreConflictsString(apNumber, locationGroupStores: LocationGroupStores[]): string {

    if (!locationGroupStores || locationGroupStores.length === 0) {
      return null;
    }
    let storeConflictsCount = 0;
    for (let x = 0; x < locationGroupStores.length; x++) {
      for (let y = 0; y < locationGroupStores[x].stores.length; y++) {
        if (this.hasStoreConflicts(apNumber, locationGroupStores[x].stores[y].custLocationNumber,
          locationGroupStores[x].stores[y].overrideActivatedStore)) {
          storeConflictsCount++;
        }
      }
    }

    if (storeConflictsCount === 0) {
      return null;
    } else if (storeConflictsCount === 1) {
      return storeConflictsCount + ' Store Conflict';
    } else {
      return storeConflictsCount + ' Store Conflicts';
    }
  }

  /**
   * Removes store conflicts for a  candidate. If there's specific stores to validate conflicts against it filters the candidate stores on this.
   * @param currentCandidate
   * @param removeActivationOverrides
   * @param storesToValidateAgainst
   */
  public removeAllStoresConflictsFromCandidate(currentCandidate: Candidate, removeActivationOverrides, storesToValidateAgainst?: number[]): boolean {
    let hasConflicts = false;
    if (!currentCandidate) {
      return hasConflicts;
    }

    const locationGroupStoresArray: LocationGroupStores[] = [];
    let currentLocationGroupStores: LocationGroupStores = null;
    for (let x = 0; x < currentCandidate.candidateProducts[0].locationGroupStores.length; x++) {

      currentLocationGroupStores = currentCandidate.candidateProducts[0].locationGroupStores[x];
      // if there's specific stores to validate , filter store group's stores by this, else use the full group to search for errors.
      const storesToValidate: Store[] = storesToValidateAgainst ? currentLocationGroupStores.stores.filter(store =>
        storesToValidateAgainst.includes(store.custLocationNumber)) : currentLocationGroupStores.stores;

      let storesNumbersWithConflicts: number[];

      // if include activation overrides, remove both invited vendor conflicts and active store conflicts.
      if (removeActivationOverrides) {
        storesNumbersWithConflicts = storesToValidate.filter(store =>
          this.hasStoreConflicts(currentCandidate.vendor.apNumber, store.custLocationNumber, null))
          .map(store => store.custLocationNumber);
      } else {
        storesNumbersWithConflicts = storesToValidate.filter(store =>
          this.isSameStoreError(currentCandidate.vendor.apNumber, store.custLocationNumber))
          .map(store => store.custLocationNumber);
      }

      // if there's no conflicts, add the entire group and continue.
      if (!storesNumbersWithConflicts || storesNumbersWithConflicts.length === 0) {
        locationGroupStoresArray.push(currentLocationGroupStores);
        continue;
      }

      hasConflicts = true;
      // if there are store conflicts remove them from the vendor store group map.
      storesNumbersWithConflicts.forEach(storeNumber => {
        this.removeStoreFromStoreNumberMap(currentCandidate.vendor.apNumber, storeNumber);
      });

      // if there are stores without errors, update the current store group and add to
      if (storesNumbersWithConflicts.length !== currentLocationGroupStores.stores.length) {
        currentLocationGroupStores.stores = currentLocationGroupStores.stores.filter(store =>
          !storesNumbersWithConflicts.includes(store.custLocationNumber));
        locationGroupStoresArray.push(currentLocationGroupStores);
      }
    }
    currentCandidate.candidateProducts[0].locationGroupStores = locationGroupStoresArray;
    return hasConflicts;
  }

  public selectedHasSameStoreError(candidateList?: Candidate[]) {
    const listToCheck = candidateList ?  candidateList : this.invitedCandidates;
    const selectedAps: number[] = this.getSelectedCandidateAps(listToCheck);
    const storeList: number[] = [];
    for (const key of this.apNumberToStoreNumbersMap.keys()) {

      if (!selectedAps.includes(key)) {
        continue;
      }
      for (let x = 0; x < this.apNumberToStoreNumbersMap.get(key).length; x++) {
        if (storeList.includes(this.apNumberToStoreNumbersMap.get(key)[x])) {
          return true;
        } else {
          storeList.push(this.apNumberToStoreNumbersMap.get(key)[x]);
        }
      }
    }
    return false;
  }

  public getSelectedCandidateAps(invitedCandidates: Candidate[]): number[] {
    if (!invitedCandidates || invitedCandidates.length === 0) {
      return [];
    }
    return invitedCandidates.filter(candidate => candidate.selected).map(candidate => candidate.vendor.apNumber);
  }

  public removeStoreFromStoreNumberMap(apNumber, storeNumber) {
    for (const key of this.apNumberToStoreNumbersMap.keys()) {
      if (key !== apNumber) {
        continue;
      }
      for (let x = 0; x < this.apNumberToStoreNumbersMap.get(key).length; x++) {
        if (this.apNumberToStoreNumbersMap.get(key)[x] === storeNumber) {
          this.apNumberToStoreNumbersMap.get(key).splice(x, 1);
          return;
        }
      }
    }
  }

  public getSelectedApNumberString(vendors: Vendor[]): string {
    if (vendors && vendors.length > 0) {
      let apNumberString = '';
      for (let x = 0; x < vendors.length; x++) {
        apNumberString += '' + vendors[x].apNumber;
        if (x !== vendors.length - 1) {
          apNumberString += ',';
        }
      }
      return apNumberString;
    }
    return '';
  }

  public getGroupNumberByApNumberAndStoreNumber(apNumber, storeNumber, vendorApToAuthGroupsMap) {

    const locGroups: LocationGroupStores[] = vendorApToAuthGroupsMap.get(apNumber);

    if (!locGroups) {
      return;
    }
    for (let x = 0; x < locGroups.length; x++) {
      for (let y = 0; y < locGroups[x].stores.length; y++) {
        if (storeNumber === locGroups[x].stores[y].custLocationNumber) {
          return locGroups[x].splrLocationGroupId;
        }
      }
    }
  }

  public removeNotSubmittedCandidateFromLists(candidateId, notSubmittedCandidates, allDsdCandidates) {
    for (let x = 0; x < notSubmittedCandidates.length; x++) {
      if (notSubmittedCandidates[x].candidateId === candidateId) {
        notSubmittedCandidates.splice(x, 1);
        break;
      }
    }
    for (let x = 0; x < allDsdCandidates.length; x++) {
      if (allDsdCandidates[x].candidateId === candidateId) {
        allDsdCandidates.splice(x, 1);
        break;
      }
    }
  }

  public getActivatedStoreName(storeNumber) {

    if (!storeNumber) {
      return '';
    }
    for (const key of this.activatedApNumberToStoreNumbersMap.keys()) {
      if (this.activatedApNumberToStoreNumbersMap.get(key).includes(storeNumber)) {
        return this.activatedApNumberToDisplayNameMap.get(key);
      }
    }
    return '';
  }

  /**
   * Returns the AP number associated with an activated store.
   * @param storeNumber
   */
  public getActivatedApNumberFromStoreNumber(storeNumber) {
    for (const entry of this.activatedApNumberToStoreNumbersMap.entries()) {
      if (entry[1].includes(storeNumber)) {
        return entry[0];
      }
    }
    return null;
  }

  public showErrorStoreOverlay(storeErrorPanel, event, apNumber, storeNumber, groupId) {

    if (this.currentErrorPanel) {
      this.currentErrorPanel.hide();
    }

    this.storeErrorApNumber = apNumber;
    this.storeErrorStoreNumber = storeNumber;
    this.storeErrorGroupId = groupId;
    this.overrideActivatedStore = this.isCurrentlyActivatedStore(apNumber, storeNumber);

    this.currentErrorPanel = storeErrorPanel;
    storeErrorPanel.show(event);
  }

  public hideErrorStoreOverlay(storeErrorPanel) {
    this.currentErrorPanel = null;
    storeErrorPanel.hide();
  }

  public getSupplierHeaderTextFromMap(apNumberToStoreNumbersMap: Map<any, any>) {
    if (apNumberToStoreNumbersMap && apNumberToStoreNumbersMap.size > 0) {
      if (apNumberToStoreNumbersMap.size === 1) {
        return '1 Supplier';
      } else {
        return '' + apNumberToStoreNumbersMap.size + ' Suppliers';
      }
    }
    return '';
  }


  public getSupplierHeaderText(candidates: Candidate[]) {
    if (candidates && candidates.length > 0) {
      if (candidates.length === 1) {
        return '1 Supplier';
      } else {
        return '' + candidates.length + ' Suppliers';
      }
    }
    return '';
  }

  public authorizeAllStoresForVendor(storeErrorPanel) {

    const candidateToAuthorize = this.invitedCandidates.find(candidate => candidate.vendor.apNumber ===
      this.storeErrorApNumber);
    if (!candidateToAuthorize) {
      this.growlService.addError('Error! Unable to find candidate with AP: ' + this.storeErrorApNumber + '!');
      return;
    }

    const storesToAuthorize = this.getStoresToAuthorize(candidateToAuthorize);

    let currentCandidate: Candidate = null;
    let hasConflicts;

    for (let x = 0; x < this.invitedCandidates.length; x++) {
      currentCandidate = this.invitedCandidates[x];
      hasConflicts = false;

      // if it's not the candidate to authorize all stores for, remove conflicts.
      if (currentCandidate.vendor.apNumber !== this.storeErrorApNumber) {
        // only remove store conflicts with current candidate, not activation overrides.
        hasConflicts = this.removeAllStoresConflictsFromCandidate(currentCandidate, false, storesToAuthorize);

        // if it's the candidate to authorize all stores for, override any activated store conflicts.
      } else {
        hasConflicts = this.authorizeActivatedStoreOverrides(currentCandidate);
      }
      // save the current candidate if there were any conflicts that were rectified.
      if (hasConflicts) {
        this.candidateService.saveCandidate(currentCandidate).pipe(tap((savedCandidate) => {
          this.setSavedCandidate(savedCandidate);
        })).subscribe();
      } else {
        this.invitedCandidates$.next(this.invitedCandidates);
      }
    }
    this.hideErrorStoreOverlay(storeErrorPanel);
  }

  private authorizeActivatedStoreOverrides(currentCandidate: Candidate): boolean {
    let hasConflicts = false;
    let storesToOverride: Store[] = null;
    for (let y = 0; y < currentCandidate.candidateProducts[0].locationGroupStores.length; y++) {
      // if there's no store group, or theres a error  group id and it isn't the same as the current group ID.
      if (!currentCandidate.candidateProducts[0].locationGroupStores[y] ||
        (this.storeErrorGroupId !== null && this.storeErrorGroupId !== undefined &&
          this.storeErrorGroupId !== currentCandidate.candidateProducts[0].locationGroupStores[y].splrLocationGroupId)) {
        continue;
      }
      storesToOverride = currentCandidate.candidateProducts[0].locationGroupStores[y].stores.filter(store =>
        this.isCurrentlyActivatedStore(currentCandidate.vendor.apNumber, store.custLocationNumber));
      if (!storesToOverride || storesToOverride.length === 0) {
        continue;
      }
      hasConflicts = true;
      storesToOverride.map(store => {
        store.overrideActivatedStore = true;
        store.overriddenApNumber = this.getActivatedApNumberFromStoreNumber(store.custLocationNumber);
        store.overriddenVendorDisplayName = this.activatedApNumberToDisplayNameMap.get(store.overriddenApNumber);
      });
    }
    return hasConflicts;
  }


  public setSavedCandidate(candidate: Candidate) {
    if (candidate.candidateId === this.candidate.candidateId) {
      this.setOriginalAndCurrentCandidate(candidate);
    }
    for (let x = 0; x < this.allDsdCandidates.length; x++) {
      if (candidate.candidateId === this.allDsdCandidates[x].candidateId) {
        this.allDsdCandidates[x] = candidate;
      }
    }
    for (let x = 0; x < this.invitedCandidates.length; x++) {
      if (candidate.candidateId === this.invitedCandidates[x].candidateId) {
        this.invitedCandidates[x] = candidate;
      }
    }
    this.invitedCandidates$.next(this.invitedCandidates);
    // this.addInvitedCandidateToApNumberToStoreMap(candidate);

    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe(() => {}, (error) => {
      if (error.error.candidateErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.candidateError.candidateProductErrors[this.candidate.candidateProducts[0].id];
        this.setCandidateIdToGroupStoreListMap();
      }
    });
  }

  private getStoresToAuthorize(candidateToAuthorize: Candidate): number[] {
    if (!candidateToAuthorize.candidateProducts[0].locationGroupStores ||
      candidateToAuthorize.candidateProducts[0].locationGroupStores.length === 0) {
      this.growlService.addError('Error! Unable to find store groups for candidate with AP: ' + this.storeErrorApNumber + '!');
      return;
    }

    // if there's store group id to filter on, fetch these stores from the vendor to authorize,
    // else fetch all the stores for the candidate to authorize.
    if (this.storeErrorGroupId !== null && this.storeErrorGroupId !== undefined) {
      const locationGroupStores = candidateToAuthorize.candidateProducts[0].locationGroupStores.find(locationGroup =>
        locationGroup.splrLocationGroupId === this.storeErrorGroupId);
      if (!locationGroupStores) {
        this.growlService.addError('Error! Unable to find store group id: ' + this.storeErrorApNumber + ' for candidate with AP: ' +
          this.storeErrorApNumber + '!');
        return [];
      }
      return locationGroupStores.stores.map(store => store.custLocationNumber);
    } else {
      return [].concat.apply([], candidateToAuthorize.candidateProducts[0].locationGroupStores.map(group =>
        group.stores.map(store => store.custLocationNumber)));
    }

  }

  public setCandidateIdToGroupStoreListMap() {
    if (!this.candidateProductError || !this.candidateProductError.candidateIdToGroupStoreListMap) {
      return;
    }
    this.candidateProductError.candidateIdToGroupStoreListMap =
      new Map(Object.entries(this.candidateProductError.candidateIdToGroupStoreListMap));
    for (const candidateId of this.candidateProductError.candidateIdToGroupStoreListMap.keys()) {
      this.candidateProductError.candidateIdToGroupStoreListMap.set(candidateId,
        new Map(Object.entries(this.candidateProductError.candidateIdToGroupStoreListMap.get(candidateId))));
    }
  }

  /**
   * Saves candidate.
   */
  public save() {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }


  public addInvitedCandidateToApNumberToStoreMap(candidate: Candidate) {
    const stores: number[] = [];
    for (let x = 0; x < candidate.candidateProducts[0].locationGroupStores.length; x++) {
      for (let y = 0; y < candidate.candidateProducts[0].locationGroupStores[x].stores.length; y++) {
        stores.push(candidate.candidateProducts[0].locationGroupStores[x].stores[y].custLocationNumber);
      }
    }
    this.apNumberToStoreNumbersMap.set(candidate.vendor.apNumber, stores);
  }

  public removeAllStoreConflictsFromVendor(storeErrorPanel) {
    const currentCandidate = this.invitedCandidates.find(candidate => candidate.vendor.apNumber ===
      this.storeErrorApNumber);
    let storeFilters: number[] = null;
    if (!currentCandidate) {
      this.growlService.addError('Error! Unable to find candidate with AP: ' + this.storeErrorApNumber + '!');
      return;
      // if the validation is at the group level, get these stores to validate against.
    } else if (this.storeErrorGroupId) {
      const locationGroup =
        currentCandidate.candidateProducts[0].locationGroupStores.find(group => group.splrLocationGroupId === this.storeErrorGroupId);

      if (!locationGroup) {
        this.growlService.addError('Error! Unable to find location group ID: ' + this.storeErrorGroupId + ' for candidate with AP: ' +
          this.storeErrorApNumber + '!');
        return;
      }
      storeFilters = locationGroup.stores.map(store => store.custLocationNumber);
    }

    this.removeAllStoresConflictsFromCandidate(currentCandidate, true, storeFilters);

    this.candidateService.saveCandidate(currentCandidate).pipe(tap((savedCandidate) => {
      this.setSavedCandidate(savedCandidate);
      this.hideErrorStoreOverlay(storeErrorPanel);
    })).subscribe();
  }

  public addToActivatedApNumberToGroupStoresMap(apNumber, store) {

    const groupNumber = this.getGroupNumberByApNumberAndStoreNumber(apNumber, store,
      this.vendorApToAuthGroupsMap);

    if (this.activatedApNumberToGroupStoresMap.get(apNumber)) {

      if (this.activatedApNumberToGroupStoresMap.get(apNumber).get(groupNumber)) {
        this.activatedApNumberToGroupStoresMap.get(apNumber).get(groupNumber).push(store);
      } else {
        this.activatedApNumberToGroupStoresMap.get(apNumber).set(groupNumber, [store]);
      }
    } else {
      const tempMap: Map<number, number[]> = new Map();
      tempMap.set(groupNumber, [store]);
      this.activatedApNumberToGroupStoresMap.set(apNumber, tempMap);
    }
  }


  public getActivatedApNumberToStoreNumbersMapKeys() {
    return Array.from(this.activatedApNumberToStoreNumbersMap.keys());
  }

  public doesActivatedGroupHaveStores(apNumber, groupId) {
    return this.activatedApNumberToGroupStoresMap.get(apNumber) && this.activatedApNumberToGroupStoresMap.get(apNumber).get(groupId) &&
      this.activatedApNumberToGroupStoresMap.get(apNumber).get(groupId).length > 0;
  }

  /**
   * Returns whether or not there's  current or activated store conflicts. If there was a a conflict, and there isn't one now, it flips
   * the flag to resolved to true.
   */
  public getHasSameStoreError() {

    if (this.isCurrentSameStoreError() || this.isActivatedSameStoreError()) {
      return true;
    }

    // if there was previously a same store error, but there's not one now, update resolved to true. Only say the error is resolved if
    // all vendors have stores.
    if (this.hasSameStoreError && !this.isVendorMissingStores()) {
      this.resolvedSameStoreError = true;
    }
    return false;
  }

  public isVendorMissingStores() {
    for (const key of this.apNumberToStoreNumbersMap.keys()) {
      if (this.apNumberToStoreNumbersMap.get(key).length === 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns whether or not there is a same store error with current candidates.
   */
  public isCurrentSameStoreError(): boolean {
    const storeList: number[] = [];
    for (const key of this.apNumberToStoreNumbersMap.keys()) {
      for (let x = 0; x < this.apNumberToStoreNumbersMap.get(key).length; x++) {
        if (storeList.includes(this.apNumberToStoreNumbersMap.get(key)[x])) {
          this.hasSameStoreError = true;
          this.resolvedSameStoreError = false;
          return true;
        } else {
          storeList.push(this.apNumberToStoreNumbersMap.get(key)[x]);
        }
      }
    }
    return false;
  }


  /**
   * Returns whether or not there is a same store error with activated candidates.
   */
  public isActivatedSameStoreError(): boolean {
    let activatedStores: number[] = [];

    for (const key of Array.from(this.activatedApNumberToStoreNumbersMap.keys())) {
      activatedStores = activatedStores.concat(this.activatedApNumberToStoreNumbersMap.get(key));
    }
    if (activatedStores.length === 0) {
      return false;
    }

    for (const entry of Array.from(this.apNumberToStoreNumbersMap.values())) {
      for (const store of entry) {
        if (activatedStores.includes(store) && !this.isOverrideStoreOrOverrideRevoked(store)) {
          this.hasSameStoreError = true;
          this.resolvedSameStoreError = false;
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Returns true if the store is an activation override.
   * @param storeNumber
   */
  public isOverrideStoreOrOverrideRevoked(storeNumber) {
    for (const invitedCandidate of this.invitedCandidates) {
      if (!invitedCandidate.candidateProducts[0].locationGroupStores ||
        invitedCandidate.candidateProducts[0].locationGroupStores.length === 0) {
        continue;
      }

      for (const locationGroupStore of invitedCandidate.candidateProducts[0].locationGroupStores) {
        // If overrideActivatedStore === true the override is enabled. If it's false, the override was removed and won't be activated.
        if (locationGroupStore.stores.find((store) => store.custLocationNumber === storeNumber &&
          store.overrideActivatedStore !== null && store.overrideActivatedStore !== undefined)) {
          return true;
        }
      }
    }
    return false;
  }


  public removeStoreFromUnauthorized(candidate: Candidate) {
    for (let x = 0; x < candidate.candidateProducts[0].locationGroupStores.length; x++) {
      for (let y = 0; y < candidate.candidateProducts[0].locationGroupStores[x].stores.length; y++) {

        if (candidate.candidateProducts[0].locationGroupStores[x].stores[y].custLocationNumber ===
          this.storeErrorStoreNumber) {
          // remove store
          candidate.candidateProducts[0].locationGroupStores[x].stores.splice(y, 1);
          // if no more stores, remove the store group
          if (candidate.candidateProducts[0].locationGroupStores[x].stores.length === 0) {
            candidate.candidateProducts[0].locationGroupStores.splice(x, 1);
          }
          return this.candidateService.saveCandidate(candidate).pipe(tap((savedCandidate) => {
              this.setSavedCandidate(savedCandidate);
              this.removeStoreFromStoreNumberMap(candidate.vendor.apNumber,
                this.storeErrorStoreNumber);
            })
          ).toPromise();
        }
      }
    }
  }


  /**
   * Removes selected store from the selected ap.
   * @param storeErrorPanel
   */
  public removeStore(storeErrorPanel) {
    for (let x = 0; x < this.invitedCandidates.length; x++) {
      if (this.invitedCandidates[x].vendor.apNumber === this.storeErrorApNumber) {
        this.removeStoreFromUnauthorized(this.invitedCandidates[x]).then(() => {
          this.hideErrorStoreOverlay(storeErrorPanel);
        });
      }
    }
    this.invitedCandidates$.next(this.invitedCandidates);
  }

  /**
   * Authorizes a store. If the store is being used by another AP trying to add to the DSD product, it removes it.
   * If it is tied to a current activated product, and we're overriding it, set the overrideActivatedStore flag to true.
   * @param storeErrorPanel
   */
  public async authorizeStore(storeErrorPanel) {
    for (let x = 0; x < this.invitedCandidates.length; x++) {
      if (this.invitedCandidates[x].vendor.apNumber !== this.storeErrorApNumber) {
        await this.removeStoreFromUnauthorized(this.invitedCandidates[x]);
      } else if (this.overrideActivatedStore) {
        await this.updateOverrideActivatedStore(this.invitedCandidates[x]);
      }
    }
    this.hideErrorStoreOverlay(storeErrorPanel);
  }


  /**
   * Updates the current store flag to override activated store for a candidate.
   * @param candidate
   */
  public updateOverrideActivatedStore(candidate: Candidate) {
    for (let x = 0; x < candidate.candidateProducts[0].locationGroupStores.length; x++) {
      for (let y = 0; y < candidate.candidateProducts[0].locationGroupStores[x].stores.length; y++) {

        if (candidate.candidateProducts[0].locationGroupStores[x].stores[y].custLocationNumber === this.storeErrorStoreNumber) {

          candidate.candidateProducts[0].locationGroupStores[x].stores[y].overrideActivatedStore = true;
          candidate.candidateProducts[0].locationGroupStores[x].stores[y].overriddenApNumber =
            this.getActivatedApNumberFromStoreNumber(this.storeErrorStoreNumber);
          candidate.candidateProducts[0].locationGroupStores[x].stores[y].overriddenVendorDisplayName =
            this.activatedApNumberToDisplayNameMap.get(candidate.candidateProducts[0].locationGroupStores[x].stores[y].overriddenApNumber);

          return this.candidateService.saveCandidate(candidate).pipe(tap((savedCandidate) => {
              this.setSavedCandidate(savedCandidate);
            })
          ).toPromise();
        }
      }
    }
  }

  public removeSelectedInvitedCandidates(candidateList?: Candidate[]): Candidate[] {
    const candidateIdsToRemove: any[] = this.getSelectedCandidateIds(candidateList);

    let removedCandidates = [];
    for (let x = 0; x < candidateIdsToRemove.length; x++) {
      for (let y = 0; y < this.invitedCandidates.length; y++) {
        if (candidateIdsToRemove[x] === this.invitedCandidates[y].candidateId) {
          removedCandidates = removedCandidates.concat(this.invitedCandidates.splice(y, 1));
          break;
        }
      }
    }
    return removedCandidates;
  }

  public getSelectedCandidateIds(candidateList?: Candidate[]) {
    const listToCheck = candidateList ? candidateList : this.invitedCandidates;
    const selectedCandidateIds = [];
    for (let x = 0; x < listToCheck.length; x++) {
      if (listToCheck[x].selected) {
        selectedCandidateIds.push(listToCheck[x].candidateId);
      }
    }
    return selectedCandidateIds;
  }

  public getSelectedCandidates(candidateList?: Candidate[]) {
    const listToCheck = candidateList ? candidateList : this.invitedCandidates;
    const selectedCandidates = [];
    for (let x = 0; x < listToCheck.length; x++) {
      if (listToCheck[x].selected) {
        selectedCandidates.push(listToCheck[x]);
      }
    }
    return selectedCandidates;
  }

  public isParentSelected(candidateList?: Candidate[]) {
    const listToCheck = candidateList ? candidateList : this.invitedCandidates;
    for (let x = 0; x < listToCheck.length; x++) {
      if (listToCheck[x].candidateId === this.candidate.candidateId) {
        return listToCheck[x].selected;
      }
    }
  }

  public getNotSelectedCandidates(candidateList?: Candidate[]) {
    const listToCheck = candidateList ? candidateList : this.invitedCandidates;
    const notSelectedCandidateIds = [];
    for (let x = 0; x < this.invitedCandidates.length; x++) {
      const invitedCandidate = this.invitedCandidates[x];
      const candidateToCheck = listToCheck.find(candidate => candidate.candidateId === invitedCandidate.candidateId);
      if (!candidateToCheck?.selected) {
        notSelectedCandidateIds.push(invitedCandidate);
      }
    }
    return notSelectedCandidateIds;
  }

  public hasSelectedSupplier(candidateList?: Candidate[]) {
    const listToCheck = candidateList ? candidateList : this.invitedCandidates;
    return listToCheck.some(candidate => candidate.selected);
  }

  public hasAllSelectedSuppliers(candidateList?: Candidate[]) {
    const listToCheck = candidateList ? candidateList : this.invitedCandidates;
    for (let x = 0; x < this.invitedCandidates.length; x++) {
      const invitedCandidate = this.invitedCandidates[x];
      const candidateToCheck = listToCheck.find(candidate => candidate.candidateId === invitedCandidate.candidateId);
      if (!candidateToCheck?.selected) {
        return false;
      }
    }
    return true;
  }

  public async createVendorToAuthGroupsMap() {
    this.invitedCandidates.push(this.candidate);

    if (this.candidate.vendor) {
      await this.findAndAddToVendorToAuthGroupsMap(this.candidate.vendor.apNumber);
    }

    if (this.candidate.candidateProducts[0].invitedSuppliers && this.candidate.candidateProducts[0].invitedSuppliers.length) {
      const getCandidateSubs = [];
      for (let x = 0; x < this.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
        getCandidateSubs.push(this.candidateService.getCandidate(this.candidate.candidateProducts[0].invitedSuppliers[x].candidateId).pipe(
          tap(async candidate => {
              if (!candidate.pssDepartment) {
                candidate.pssDepartment = this.candidate.commodity.pssDepartment;
              }
              this.invitedCandidates.push(candidate);
              await this.findAndAddToVendorToAuthGroupsMap(candidate.vendor.apNumber);
            }
          ),
          switchMap((candidate) => this.updateUpcDataForCandidate(candidate))));
      }
      await forkJoin(getCandidateSubs).toPromise();
      this.candidateService.validateCandidate(this.candidate,
        [CandidateValidatorType.ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe(() => {},
        (error) => {
          if (error.error.candidateErrors) {
            this.candidateError = error.error.candidateErrors;
            this.candidateProductError = this.candidateError.candidateProductErrors[this.candidate.candidateProducts[0].id];
            this.setCandidateIdToGroupStoreListMap();
          }
        });
        this.invitedCandidates$.next(this.invitedCandidates);
    } else {
      this.invitedCandidates$.next(this.invitedCandidates);
    }
  }

  public findAndAddToVendorToAuthGroupsMap(apNumber) {
    if (this.candidate.overrideDepartment) {
      if (!this.candidate.overrideSubDepartment) {
        return of({});
      }
      return this.lookupService.findAllAuthGroups(apNumber, this.candidate.overrideSubDepartment.departmentId,
        this.candidate.overrideSubDepartment.subDepartmentId).pipe(tap((locGrpStores: LocationGroupStores[]) => {
        this.vendorApToAuthGroupsMap.set(apNumber, locGrpStores);
      })).toPromise();
    } else {
      return this.lookupService.findAllAuthGroups(apNumber, this.candidate.commodity.departmentId,
        this.candidate.commodity.subDepartmentId).pipe(tap((locGrpStores: LocationGroupStores[]) => {
        this.vendorApToAuthGroupsMap.set(apNumber, locGrpStores);
      })).toPromise();
    }
  }

  /**
   * Candidate used to get map of location group stores.
   * @param candidate
   */
  public findAndAddToVendorToAuthGroupsMapByCandidate(candidate: Candidate) {
    if (candidate.overrideDepartment) {
      return this.lookupService.findAllAuthGroups(candidate.vendor.apNumber, candidate.overrideSubDepartment.departmentId,
        candidate.overrideSubDepartment.subDepartmentId).pipe(tap((locGrpStores: LocationGroupStores[]) => {
        this.vendorApToAuthGroupsMap.set(candidate.vendor.apNumber, locGrpStores);
      })).toPromise();
    } else {
      return this.lookupService.findAllAuthGroups(candidate.vendor.apNumber, candidate.commodity.departmentId,
        candidate.commodity.subDepartmentId).pipe(tap((locGrpStores: LocationGroupStores[]) => {
        this.vendorApToAuthGroupsMap.set(candidate.vendor.apNumber, locGrpStores);
      })).toPromise();
    }
  }

  /**
   * Finds all other invited candidates not associated with current task/candidate, and adds them to
   * their respective array grouping (activated/rejected/in progress).
   */
  public findOtherInvitedCandidates() {
    // contains current candidate of the task and accumulated "Candidates" to be processed with this candidate.
    let currentInvitedSuppliers: any[] = [this.candidate];
    currentInvitedSuppliers = currentInvitedSuppliers.concat(this.candidate.candidateProducts[0].invitedSuppliers);

    const candidatesToFind = [];
    for (let x = 0; x < this.allDsdCandidates.length; x++) {
      let found = false;
      for (let y = 0; y < currentInvitedSuppliers.length; y++) {
        if (this.allDsdCandidates[x].candidateId === currentInvitedSuppliers[y].candidateId) {
          found = true;
          break;
        }
      }
      if (!found) {
        candidatesToFind.push(this.allDsdCandidates[x].candidateId);
      }
    }
    if (candidatesToFind.length === 0) {
      return;
    }
    for (let x = 0; x < candidatesToFind.length; x++) {
      this.candidateService.getCandidate(candidatesToFind[x]).subscribe((candidate: Candidate) => {
        switch (candidate.status) {
          case Candidate.COMPLETED: {
            this.addCompletedCandidate(candidate);
            break;
          }
          case Candidate.DECLINED: {
            this.rejectedCandidates.push(candidate);
            break;
          }
          case Candidate.IN_PROGRESS: {
            // only show candidates invited by buyer/pia
            if (candidate.candidateType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
              break;
            }
            this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId).subscribe((task) => {
              if (task.name === 'Dsd Invited Supplier') {
                this.notSubmittedCandidates.push(candidate);
              } else {
                this.submittedCandidates.push(candidate);
              }
            });
            break;
          }
        }
      });
    }
  }

  /**
   * If the completed candidate has not activated, add to approved. Else Add info activate product info.
   * @param candidate
   */
  public addCompletedCandidate(candidate: Candidate) {
    if (!this.productData.dsdItems || this.productData.dsdItems.length === 0) {
      this.approvedCandidates.push(candidate);
    }
    // if we find the ap number in the stores authorizations, ignore this candidate as it will be added to live data.
    for (let x = 0; x < this.productData.dsdItems.length; x++) {
      if (this.productData.dsdItems[x].upc !== this.candidate.candidateProducts[0].upc) {
        continue;
      }
      for (let y = 0; y < this.productData.dsdItems[x].storeAuthorizations.length; y++) {
        if (candidate.vendor.apNumber === this.productData.dsdItems[x].storeAuthorizations[y].supplier.apNumber) {
          return;
        }
      }
    }
    this.approvedCandidates.push(candidate);
  }


  public showHasOtherCandidates() {
    return (this.notSubmittedCandidates.length > 0 || this.approvedCandidates.length > 0 || this.rejectedCandidates.length > 0 ||
      this.activatedApNumberToStoreNumbersMap.size > 0) && this.invitedCandidates && this.invitedCandidates.length;
  }


  public hasVendorsToInvite() {
    return this.vendorsToInvite && this.vendorsToInvite.length > 0;
  }


  public setCurrentSuppliersApString() {
    this.currentSuppliersString = '';
    const ids = [];
    for (let x = 0; x < this.allDsdCandidates.length; x++) {
      if (this.allDsdCandidates[x].status !== Candidate.DECLINED && this.allDsdCandidates[x].vendor &&
        this.allDsdCandidates[x].vendor.apNumber) {
        ids.push(this.allDsdCandidates[x].vendor.apNumber);
      }
    }
    this.currentSuppliersString = ids.join(',');
  }

  public setInitialSupplierConfiguration() {
    let url = '/lookup/vendordsd/exclude/';
    this.invitedSuppliersString = '';
    this.setCurrentSuppliersApString();

    if (this.candidate.candidateProducts[0].invitedSuppliers && this.candidate.candidateProducts[0].invitedSuppliers.length > 0) {
      for (let x = 0; x < this.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
        this.invitedSuppliersString += ',' + this.candidate.candidateProducts[0].invitedSuppliers[x].vendor.apNumber;
      }
    }
    url += this.currentSuppliersString + this.invitedSuppliersString;

    this.supplierConfiguration = {
      label: 'Suppliers to invite',
      isRequired: false,
      isHidden: () => false,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: 'vendorTypeAhead',
      displaySubRef: '',
      displayRef: 'displayName',
      placeholderText: 'Select or search for a supplier...',
      searchUrl: url,
      idRef: 'apNumber',
      showAddlInfo: true,
      allowMultiple: true
    };
  }


  onInvitedVendorSelection(event) {
    this.vendorsToInvite = event;
    if (event && event.length > 0) {
      const url = '/lookup/vendordsd/exclude/' + this.currentSuppliersString + ',' + this.getSelectedApNumberString(event);

      this.supplierConfiguration = {
        label: 'Suppliers to invite',
        isRequired: false,
        isHidden: () => false,
        isDisabled: () => false,
        isReadOnly: () => false,
        name: 'vendorTypeAhead',
        displaySubRef: '',
        displayRef: 'displayName',
        placeholderText: 'Select or search for a supplier...',
        searchUrl: url,
        idRef: 'apNumber',
        showAddlInfo: true,
        allowMultiple: true
      };
    }
  }



  public async inviteSuppliers(pmReview) {
    if (!this.hasVendorsToInvite()) {
      this.closeInviteSuppliersPanel(pmReview);
      return;
    }
    let inviteCandidate: Candidate;
    const description = 'Request stores – UPC: ' + this.candidate.candidateProducts[0].upc;

    for (let x = 0; x < this.vendorsToInvite.length; x++) {
      inviteCandidate = new Candidate();
      inviteCandidate.description = description;
      inviteCandidate.candidateType = Candidate.ADDITIONAL_DISTRIBUTOR;
      inviteCandidate.vendor = this.vendorsToInvite[x];
      inviteCandidate.buyer = this.candidate.buyer;
      inviteCandidate.brand = this.candidate.brand;
      inviteCandidate.commodity = this.candidate.commodity;
      inviteCandidate.dsdSwitch = true;
      inviteCandidate.candidateProducts = [];
      inviteCandidate.candidateProducts.push({id: UUID.UUID(), candidateProductType: CandidateProduct.ADDITIONAL_DISTRIBUTOR});
      inviteCandidate.candidateProducts[0].upc = this.candidate.candidateProducts[0].upc;

      await this.createInvitedSupplierTasks(inviteCandidate);
      this.emailService.sendInvitedSupplierEmail(inviteCandidate.vendor.apNumber, this.productData.productDescription,
        this.candidate.candidateProducts[0].upc);
    }

    this.closeInviteSuppliersPanel(pmReview);
  }


  public async createInvitedSupplierTasks(inviteCandidate: Candidate) {
    const candidate = await this.candidateService.createNewCandidate(inviteCandidate).toPromise();
    this.notSubmittedCandidates.push(candidate);
    this.allDsdCandidates.push(candidate);

    this.workflowService.createProcessInstanceWithCandidateId(candidate.candidateId, this.DSD_INVITED_SUPPLIER_WORKFLOW)
      .subscribe(taskId => {
        this.workflowService.getTaskByIdWithVariables(taskId).subscribe((task) => {
          this.workflowService.updateApNumber(candidate.vendor.apNumber, task.processInstanceId).subscribe();
        });
      });
  }


  public onClickAddDistributors(pmReview) {
    this.setInitialSupplierConfiguration();
    pmReview.openDrawer();
    this.showAddMoreSuppliers = true;
  }


  public closeInviteSuppliersPanel(pmReview) {
    this.vendorsToInvite = [];
    this.isViewingInvitedVendors = false;
    this.collapse(pmReview);
  }

  public collapse(pmReview) {
    this.showAddMoreSuppliers = false;
    pmReview.closeDrawer();
  }


  public getHebPennyProfit(candidate: Candidate) {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebPennyProfit(tempCandidate);
    }
  }

  public getHebMargin(candidate: Candidate) {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebMargin(tempCandidate);
    }
  }

  public getUnitCost(candidate: Candidate) {
    if (this.productData && this.upc) {
      return this.costService.toCurrencyForCost(this.costService.getUnitCost(candidate));
    }
  }

  async initializeActivatedDistributorData() {
    if (!this.productData || !this.productData.dsdItems || this.productData.dsdItems.length === 0) {
      return;
    }
    for (let x = 0; x < this.productData.dsdItems.length; x++) {
      if (this.productData.dsdItems[x].upc !== this.candidate.candidateProducts[0].upc) {
        continue;
      }
      for (let y = 0; y < this.productData.dsdItems[x].storeAuthorizations.length; y++) {
        const apNumber = this.productData.dsdItems[x].storeAuthorizations[y].supplier.apNumber;
        const storeNumber = this.productData.dsdItems[x].storeAuthorizations[y].storeNumber;
        // if the ap number already is in map, add new store. Else, add ap number and store to map, and get its the groups/stores.
        if (this.activatedApNumberToStoreNumbersMap.get(apNumber)) {
          this.activatedApNumberToStoreNumbersMap.get(apNumber).push(storeNumber);
          this.addToActivatedApNumberToGroupStoresMap(apNumber, storeNumber);
        } else {
          this.activatedApNumberToStoreNumbersMap.set(apNumber, [storeNumber]);
          const displayName = this.productData.dsdItems[x].storeAuthorizations[y].supplier.description + ' [' + apNumber + ']';
          this.activatedApNumberToDisplayNameMap.set(apNumber, displayName);
          await this.findAndAddToVendorToAuthGroupsMap(apNumber);
          this.addToActivatedApNumberToGroupStoresMap(apNumber, storeNumber);
        }
      }
    }
  }

  /**
   * Sets whether or not the selected supplier is already authorized.
   */
  isSupplierAlreadyAuthorized(): Observable<boolean> {
    if (!this.candidate?.vendor?.apNumber) {
      return of(false);
    }
    if (!!this.productData?.dsdItems?.length) {
      for (const dsdItem of this.productData.dsdItems) {
        if (dsdItem.upc === this.candidate.candidateProducts[0].upc && dsdItem.dsdSupplierItems?.length) {
          for (const dsdSuppliers of dsdItem.dsdSupplierItems) {
            if (dsdSuppliers.vendorLocationNumber === this.candidate.vendor.apNumber) {
              return of(true);
            }
          }
        }
      }
    }
    return this.candidateService.findDistributorCandidatesByUpc(this.candidate.candidateProducts[0].upc).pipe(
      map(candidates => {
        if (!candidates?.length) {
          return false;
        }
        return !!candidates.filter(existingCandidate => {
            return [Candidate.IN_PROGRESS, Candidate.COMPLETED].includes(existingCandidate.status) &&
              [Candidate.ADDITIONAL_DISTRIBUTOR, Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR].includes(existingCandidate.candidateType) &&
              this.candidate.candidateId !== existingCandidate.candidateId &&
              this.candidate.vendor.apNumber === existingCandidate.vendor?.apNumber;
          }
        ).length;
      })
    );
  }

  onSupplierChange(event, panel, target) {
    if (!this.candidate.vendor) {
      this.setAuthGroups(null);
      this.setSelectedAuthGroups(null);
      this.getCandidate().candidateProducts[0].locationGroupStores = [];
      return;
    } else if (this.getCandidate().candidateProducts[0]?.isAddComplimentaryPlu) {
      if (this.candidate.commodity) {
        this.findAllAuthGroups().subscribe((authGroups) => {
          this.setAuthGroups(authGroups);
          this.setSelectedAuthGroups(null);
          this.getCandidate().candidateProducts[0].locationGroupStores = [];
          this.validateHasNoStores();
        });
      }
      return;
    }

    this.isSupplierAlreadyAuthorized().subscribe(isSupplierAlreadyAuthorized => {
      if (isSupplierAlreadyAuthorized) {
        panel.show(target);
      } else if (this.candidate.commodity) {
        this.findAllAuthGroups().subscribe((authGroups) => {
          this.setAuthGroups(authGroups);
          this.setSelectedAuthGroups(null);
          this.getCandidate().candidateProducts[0].locationGroupStores = [];
          this.validateHasNoStores();
        });
      }
    });
  }


  /**
   * Checks if a department was overridden. And if it was overridden with a new value. Returns true or false.
   * @param response
   * @param invitedCandidate
   */
  hasDepartmentChanged(response: Candidate, invitedCandidate: Candidate): boolean {
    // Checks if department override is changed during edit modal.
    if (response.overrideDepartment === invitedCandidate.overrideDepartment) {
      // if the override hasn't changed (both are false), we're still using the commodity, so no department change.
      if (!response.overrideDepartment || !invitedCandidate.overrideSubDepartment) {
        return false;
        // if there's an override still, there's only a change if a dept or sub dept changed.
      } else {
        return invitedCandidate.overrideSubDepartment?.departmentId !== response.overrideSubDepartment?.departmentId ||
          invitedCandidate.overrideSubDepartment?.subDepartmentId !== response.overrideSubDepartment?.subDepartmentId;
      }
    } else {
      // If override department was changed and is false, compare previous override with the commodity.
      if (!response.overrideDepartment) {
        return invitedCandidate.overrideSubDepartment?.departmentId !== response.commodity?.departmentId ||
          invitedCandidate.overrideSubDepartment?.subDepartmentId !== response.commodity?.subDepartmentId;
      } else {
        // if the override department changed and is true, compare current response override with the commodity.
        return response.overrideSubDepartment?.departmentId !== invitedCandidate.commodity?.departmentId ||
          response.overrideSubDepartment?.subDepartmentId !== invitedCandidate.commodity?.subDepartmentId;
      }
    }
  }

  isOverrideDepartmentTheDefaultDepartment(response: Candidate, invitedCandidate: Candidate): boolean {
    return response.overrideDepartment &&
      response.overrideSubDepartment?.departmentId === invitedCandidate.commodity?.departmentId &&
      response.overrideSubDepartment?.subDepartmentId === invitedCandidate.commodity?.subDepartmentId;
  }

  /**
   * Get the list of pss departments and set map.
   * @param candidate
   */
  getPssDepartments(candidate: Candidate) {
    if (!candidate.overrideDepartment) {
      if (candidate.commodity && candidate.commodity.subDepartment == null) {
        // Make call to get sub department with pss departments when commodity does not have department info.
        this.lookupService.findSubDepartment(candidate.commodity.departmentId + candidate.commodity.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments.set(candidate.candidateId, subDepartment[0].pssDepartments);
        });
      } else {
        this.pssDepartments.set(candidate.candidateId, candidate.commodity.subDepartment.pssDepartments);
      }
    } else {
      if (candidate.overrideSubDepartment?.pssDepartments?.length > 0) {
        this.pssDepartments.set(candidate.candidateId, candidate.overrideSubDepartment?.pssDepartments);
      } else {
        this.lookupService.findSubDepartment(candidate.overrideSubDepartment.departmentId + candidate.overrideSubDepartment.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments.set(candidate.candidateId, subDepartment[0].pssDepartments);
        });
      }
    }
  }

  getUpc() {
    const searchedUpc = this.candidate?.candidateProducts[0]?.upc;
    if (!this.candidate?.candidateProducts[0]?.isAddComplimentaryPlu || !this.candidate?.candidateProducts[0]?.upc) {
      return searchedUpc;
    } else if (this.upcService.isPlu(searchedUpc)) {
      return this.upcService.pluToPreDigitTwo(searchedUpc);
    } else if (this.upcService.isPreDigitTwo(searchedUpc)) {
      return this.upcService.preDigitTwoToPlu(searchedUpc);
    } else {
      return searchedUpc;
    }
  }
}
