import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AuthService} from '../../auth/auth.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {ActivatedRoute, Router} from '@angular/router';
import {PharmProductService} from '../../service/pharm-product.service';
import {SupplierProductService} from '../../service/supplier-product.service';
import {AttributeDropdowndConfig, Task} from 'pm-models';
import {SupplierCasePackService} from '../../service/supplier-case-pack.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {PiaProductService} from '../../service/pia-product.service';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {UserRoleConstants} from '../../core/header/user-role-constants';

@Component({
  selector: 'app-task-viewer',
  templateUrl: './task-viewer.component.html',
  styleUrls: ['./task-viewer.component.scss']
})
export class TaskViewerComponent {

  tasks: Task[];
  displayCreateCandidateModal = false;
  reloadingPage = false;
  blurbTitleString = 'My Tasks';
  roleModelInitialized = false;
  role: {role: string, roleDisplayName: string};
  roleList: {role: string, roleDisplayName: string}[] = [];

  constructor(public route: ActivatedRoute, public titleService: Title, public authorization: AuthService,
              public router: Router, public permissionService: NgxPermissionsService, public supplierProductService: SupplierProductService,
              public supplierCasePackService: SupplierCasePackService, public bonusSizeService: BonusSizeService,
              public supplierMrtService: SupplierMrtService, public piaProductService: PiaProductService,
              public invitedDistributorService: InvitedDistributorService, public pharmProductService: PharmProductService) {

    this.titleService.setTitle('My Tasks');
    this.setBlurbTitle();
  }

  roleConfiguration: AttributeDropdowndConfig = {
    label: 'Currently viewing as',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    isHidden: () => false,
    collections: this.roleList,
    displayRef: 'roleDisplayName',
    showClear: false,
    labelInputGroupClass: 'multi-role-select',
    inputGroupClass: 'multi-role-select'
  };

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('display') === 'true') {
      this.updateDisplay(true);
    }
    if (this.authorization.hasMoreThanOneRole()) {
      this.setRoleListAndModel();
    }
  }

  setBlurbTitle() {
    if (this.authorization.isVendor()) {
      this.blurbTitleString = 'Supplier Tasks';
    } else if (this.authorization.isBuyer()) {
      this.blurbTitleString = 'Buyer Tasks';
    } else if (this.authorization.isSca()) {
      this.blurbTitleString = 'Warehouse Tasks';
    }
  }

  /**
   * Update the displayCreateCandidateModal with change emitted by the popup.
   * @param updates Updated displayCreateCandidateModal.
   */
  updateDisplay(updates) {
    this.displayCreateCandidateModal = updates;
  }

  /**
   * Sets displayCreateCandidateModal to true to show the create candidate modal popup.
   */
  createCandidate() {
    if (this.authorization.isPharm()) {
      this.pharmProductService.resetService();
      return this.router.navigate(['/pharmSetupNewProduct']);
    } else {
      this.supplierProductService.resetService();
      this.supplierCasePackService.resetService();
      this.bonusSizeService.resetService();
      this.supplierMrtService.resetService();
      this.piaProductService.resetService();
      this.invitedDistributorService.resetService();
      return this.router.navigate(['/setupCandidate']);
    }
  }

  roleChange(event) {
    if (!event) {
      return;
      // prevent initial setting of the role causing continuous refresh.
    } else if (!this.roleModelInitialized) {
      this.roleModelInitialized = true;
      return;
    }
    this.reloadingPage = true;
    // this.role = event;
    this.authorization.setCurrentRole(event.role).subscribe(() => location.reload());
  }

  setRoleListAndModel() {
    UserRoleConstants.ROLE_TO_DISPLAY_NAME_MAP.forEach((displayName: string, permissionKey: string) => {
      if (this.permissionService.getPermission(permissionKey)) {
        this.roleList.push({role: permissionKey, roleDisplayName: displayName});
      }
    });
    this.role = { role: this.authorization.getCurrentRole(),
      roleDisplayName: UserRoleConstants.ROLE_TO_DISPLAY_NAME_MAP.get(this.authorization.getCurrentRole())};
  }
}
