<pm-review #pmReview *ngIf="isViewingPage && supplierProductService?.candidate" (closeEvent)="onClose()">
  <pm-header>
    <app-new-warehouse-product-stepper *ngIf="supplierProductService.candidate && !supplierProductService.isDsdOnly() && !isClosedOrUnderReview"
                                       [candidateErrors]="supplierProductService.getAllWhsCandidateErrors()"
                                       [stepIndex]="whsStepperIndex"
                                       [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                       (indexClick)="onClickStepper($event)">
    </app-new-warehouse-product-stepper>
    <app-new-dsd-product-stepper *ngIf="supplierProductService.candidate && supplierProductService.isDsdOnly() && !isClosedOrUnderReview"
                                 [candidateErrors]="supplierProductService.getAllDsdCandidateErrors()"
                                 [stepIndex]="dsdStepperIndex"
                                 [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                 (indexClick)="onClickStepper($event)">
    </app-new-dsd-product-stepper>
  </pm-header>
  <pm-title>{{getPageTitle()}}</pm-title>
  <pm-sub-title>{{getTitleSubHeading()}}</pm-sub-title>
  <pm-app-reject-status-header [candidate]="supplierProductService?.candidate"></pm-app-reject-status-header>
  <div *ngIf="supplierProductService?.candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages.length > 0 ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div *ngIf="!candidateUtilService.isPlu(supplierProductService.candidate)" class="upc-header">
            UPC: {{supplierProductService.candidate.candidateProducts[0].upc}}-{{supplierProductService.candidate.candidateProducts[0].upcCheckDigit}}
          </div>
          <div *ngIf="candidateUtilService.isPlu(supplierProductService.candidate)" class="upc-header">
            <div *ngIf="candidateUtilService.isCheckerProduct(supplierProductService.candidate)">
              PLU: {{supplierProductService.candidate.candidateProducts[0].upc | upc}}-{{supplierProductService.candidate.candidateProducts[0].upc | upcCheckDigit}}<br>
            </div>
            <div *ngIf="candidateUtilService.isScaleProduct(supplierProductService.candidate)">
              UPC: {{upcService.pluToG14PreDigitTwo(supplierProductService.getCurrentCandidateProduct().upc)}}-{{upcService.pluToG14PreDigitTwo(this.supplierProductService.getCurrentCandidateProduct().upc) | upcCheckDigit}}
            </div>
          </div>
          <div class="attribute-description">
            <p>{{supplierProductService.candidate.candidateProducts[0].description}}</p>
            <p>{{getProductInfoString()}}</p>
          </div>
          <button pButton type="button"
                  [label]="'View candidate history'"
                  class="ui-button-link" (click)="historyPanelOpen()">
          </button>
        </div>
      </div>

      <!--   cost & retail  -->
      <pm-attribute-grid [title]="'Cost & retail'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterListCost')" (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(supplierProductService.candidate?.masterListCost) | emptyToValue : '&#8212;'}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(supplierProductService.candidate?.innerListCost) | emptyToValue : '&#8212;'}}<br>
                Penny profit: {{costService.getPennyProfit(supplierProductService?.candidate) | emptyToValue : '&#8212;'}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(supplierProductService.candidate)}">{{costService.getMarginString(supplierProductService.candidate) | emptyToValue : '&#8212;'}}</a>
                <img *ngIf="costService.isMarginNegativeOrZero(supplierProductService?.candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP">
              </pm-grid-cell-info>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{supplierProductService.candidate?.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'costLinked')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'costLink')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.costLink | emptyToValue : '&#8212;'}}
            </pm-grid-cell>

          </pm-grid-column>

          <pm-grid-column *ngIf="candidateUtilService.isSellable(supplierProductService.candidate)">
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'retailXFor') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'retailPrice')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.getRetailPricing(supplierProductService.candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'retailType')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate.retailType | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{costService.getHebPennyProfit(supplierProductService.candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              {{costService.getHebMargin(supplierProductService.candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>

          </pm-grid-column>

          <pm-grid-column *ngIf="candidateUtilService.isSellable(supplierProductService.candidate)">
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'suggestedRetailPrice')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.getSuggestedRetailPricing(supplierProductService.candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'prePrice')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'mapRetail')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.mapRetail ? costService.toCurrency(supplierProductService.candidate?.mapRetail) : '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column *ngIf="!candidateUtilService.isSellable(supplierProductService.candidate)">
            <pm-grid-cell [label]="'Retail pricing'">
              N/A
              <pm-grid-cell-info>
                Retail fields don't apply to non-sellable products
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>


        </pm-grid-group>
      </pm-attribute-grid>

      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'caseDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(supplierProductService?.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'caseUpc')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().caseUpc}}-{{supplierProductService.getCurrentCandidateProduct().caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'countryOfOrigin', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [errorMessage]="candidateProductError?.vendorProductCode"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterPack')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!supplierProductService.isDsdOnlyParms(supplierProductService.candidate)" [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.masterLength}}in x
              {{supplierProductService.candidate?.masterWidth}}in x
              {{supplierProductService.candidate?.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(supplierProductService.candidate?.masterLength, supplierProductService.candidate?.masterWidth, supplierProductService.candidate?.masterLength,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!supplierProductService.isDsdOnlyParms(supplierProductService.candidate)"
                          [label]="'Boxes on pallet'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'vendorTie') ||
                                                                                      candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'vendorTier')"
                          (historyClicked)="historyPanelOpen()">
              {{+supplierProductService.candidate?.vendorTie * +supplierProductService.candidate?.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{supplierProductService.candidate?.vendorTie}}
                Tier: {{supplierProductService.candidate?.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column *ngIf="!supplierProductService.isDsdOnlyParms(supplierProductService.candidate)">
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'itemWeightType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getItemWeightType(supplierProductService?.candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(supplierProductService?.candidate?.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'cubeAdjustedFactor')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column *ngIf="!supplierProductService.isDsdOnlyParms(supplierProductService.candidate)">
            <pm-grid-cell [label]="'Code date'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'codeDate')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'maxShelfLife')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(supplierProductService?.candidate, supplierProductService.candidate?.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell  [label]="'Inbound spec'"  [canEdit]="false"
                           [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'inboundSpecDays')"
                           [errorMessage]="candidateError?.inboundSpecDays"
                           (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(supplierProductService?.candidate, supplierProductService.candidate?.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!--  inner packs -->
      <pm-attribute-grid *ngIf="!supplierProductService.isDsdOnlyParms(supplierProductService.candidate)" [title]="'Inner packs'">
        <pm-grid-group *ngIf="supplierProductService.candidate?.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'innerPack')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.innerPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'innerLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'innerWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'innerWidth')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.innerLength}}in x
              {{supplierProductService.candidate?.innerWidth}}in x
              {{supplierProductService.candidate?.innerHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(supplierProductService.candidate?.innerLength, supplierProductService.candidate?.innerWidth, supplierProductService.candidate?.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'innerWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(supplierProductService.candidate?.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="!supplierProductService.candidate?.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterPack')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.masterPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.masterLength}}in x
              {{supplierProductService.candidate?.masterWidth}}in x
              {{supplierProductService.candidate?.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(supplierProductService.candidate?.masterLength, supplierProductService.candidate?.masterWidth, supplierProductService.candidate?.masterLength,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(supplierProductService.candidate?.masterWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="supplierProductService.candidate?.displayReadyUnit && candidateUtilService.isSellable(supplierProductService.candidate)" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'displayReadyUnit')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(this.supplierProductService?.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'displayReadyUnitRowsDeep') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'displayReadyUnitRowsFacing') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'displayReadyUnitRowsHigh')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.displayReadyUnitRowsDeep * supplierProductService.candidate?.displayReadyUnitRowsFacing * supplierProductService.candidate?.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Authorized stores -->
      <pm-attribute-grid *ngIf="supplierProductService.isDsdOnly()" [title]="'Authorized stores'">
        <pm-grid-group *ngFor="let locationGroupStore of this.supplierProductService.candidate.candidateProducts[0].locationGroupStores">
          <pm-grid-column>
            <pm-grid-cell [label]="'Distributor'">
              <div>{{getVendor(locationGroupStore.splrLocationNumber)?.displayName}}</div>
              <pm-grid-cell-info>
                <b>Total stores: {{locationGroupStore.stores.length}} of {{getNumberOfStoresForGroup(locationGroupStore.splrLocationGroupId)}} stores</b>
              </pm-grid-cell-info>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Authorization group'">
              <div>{{locationGroupStore.splrLocationGroupId}}</div>
              <pm-grid-cell-info>
                <div *ngIf="locationGroupStore.listCost">${{locationGroupStore.listCost| number : '1.2-2'}}</div>
                <div *ngIf="!locationGroupStore.listCost">${{supplierProductService.candidate?.masterListCost| number : '1.2-2'}}</div>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Stores'">
              <div class="mb-2">{{getAuthGroupLabel(locationGroupStore)}}</div>
              <div *ngFor="let store of locationGroupStore.stores">
                <div class="ui-grid-col-2 mr-2 mb-2" style="background: #f2f2f2; text-align: center;">{{store.custLocationNumber}}</div>
              </div>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-cell *ngIf="candidateProductError?.missingStoresError"
                      [errorMessage]="candidateProductError?.missingStoresError">
        </pm-grid-cell>
      </pm-attribute-grid>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'brand', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'subBrand')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCostOwner(supplierProductService.getCandidate())"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'contactName') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'contactEmail')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.contactName}}
              {{supplierProductService.candidate?.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForTopToTop(supplierProductService.getCandidate())"
                          (historyClicked)="historyPanelOpen()">
              <input type="text" pInputText value="{{supplierProductService.candidate?.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'vendor', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!supplierProductService.isDsdOnlyParms(supplierProductService.candidate)" [label]="'Warehouse supplier'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'lane', 'name')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'buyer', 'buyerName')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>

            <pm-grid-cell [label]="'Sellable'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productType')"
                          (historyClicked)="historyPanelOpen()">
              {{getSellableString()}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="candidateUtilService.isSellable(supplierProductService.candidate)" [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'foodStamp')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'taxable')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'flexibleSpendingAccount')"
                          [label]="'FSA/HSA eligible'"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'packageType', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'unitOfMeasure', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'retailSize')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.productLength}}in x
              {{supplierProductService.candidate?.productWidth}}in x
              {{supplierProductService.candidate?.productHeight}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductWeightString(supplierProductService.candidate?.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'totalVolume')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line & Packaging  -->
      <pm-attribute-grid *ngIf="!candidateUtilService.isSellable(supplierProductService.candidate)" [title]="'Product line & Packaging'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'packageType', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'unitOfMeasure', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.unitOfMeasure?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.productLength}}in x
              {{supplierProductService.candidate?.productWidth}}in x
              {{supplierProductService.candidate?.productHeight}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'productWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductWeightString(supplierProductService.candidate?.productWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'totalVolume')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(supplierProductService.getCandidate(),'retailSize')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.candidate?.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid *ngIf="candidateUtilService.isSellable(supplierProductService.candidate)" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(supplierProductService.candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false"
                            [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'upc')"
                            (historyClicked)="historyPanelOpen()">
                {{supplierProductService.getCurrentCandidateProduct().upc}}-{{supplierProductService.getCurrentCandidateProduct().upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(supplierProductService.candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="false"
                            [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'upc')"
                            (historyClicked)="historyPanelOpen()">
                <div *ngIf="candidateUtilService.isCheckerProduct(supplierProductService.candidate)">
                  {{supplierProductService.candidate.candidateProducts[0].upc | upc}}-{{supplierProductService.candidate.candidateProducts[0].upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(supplierProductService.candidate)">
                  {{upcService.pluToG14PreDigitTwo(supplierProductService.getCurrentCandidateProduct().upc)}}-{{upcService.pluToG14PreDigitTwo(this.supplierProductService.getCurrentCandidateProduct().upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(supplierProductService.getCurrentCandidateProduct().description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'customerFriendlyDescription1')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().customerFriendlyDescription1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'customerFriendlyDescription2')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().customerFriendlyDescription2}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'romanceCopy')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().romanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(supplierProductService.getCurrentCandidateProduct())}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(supplierProductService.getCurrentCandidateProduct())}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="supplierProductService.candidate?.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of supplierProductService.candidate?.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid *ngIf="!candidateUtilService.isSellable(supplierProductService.candidate)" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(supplierProductService.candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false"
                            [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'upc')"
                            (historyClicked)="historyPanelOpen()">
                {{supplierProductService.getCurrentCandidateProduct().upc}}-{{supplierProductService.getCurrentCandidateProduct().upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(supplierProductService.candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="false"
                            [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'upc')"
                            (historyClicked)="historyPanelOpen()">
                <div *ngIf="candidateUtilService.isCheckerProduct(supplierProductService.candidate)">
                  {{supplierProductService.candidate.candidateProducts[0].upc | upc}}-{{supplierProductService.candidate.candidateProducts[0].upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(supplierProductService.candidate)">
                  {{upcService.pluToG14PreDigitTwo(supplierProductService.getCurrentCandidateProduct().upc)}}-{{upcService.pluToG14PreDigitTwo(this.supplierProductService.getCurrentCandidateProduct().upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(supplierProductService.getCandidateProduct(), 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierProductService.getCurrentCandidateProduct().description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(supplierProductService.getCurrentCandidateProduct().description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(supplierProductService.getCurrentCandidateProduct())}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(supplierProductService.getCurrentCandidateProduct())}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="supplierProductService.candidate?.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of supplierProductService.candidate?.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <div *ngIf="candidateUtilService.isInProgressCandidate(supplierProductService.candidate)">
          <!--  Global Attributes    -->
          <div>
            <pm-attribute-grid [title]="'Extended attributes: Product'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid [title]="'Extended attributes: UPC'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="supplierProductService?.candidate?.warehouseSwitch && isLoadingMatData" [title]="'Extended attributes: Case'">
              <div style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
            </pm-attribute-grid>
            <pm-attribute-grid *ngIf="supplierProductService?.candidate?.warehouseSwitch && !isLoadingMatData && !!supplierProductService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>

          <!--   Mat Hierarchy   -->
          <div>
            <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(supplierProductService?.candidate)?.length" [title]="'Category attributes'">
              <div style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
            </pm-attribute-grid>
            <div *ngIf="!!supplierProductService.hierarchyNumberToAttributesMap?.size">
              <div *ngFor="let hierarchy of supplierProductService.candidate.candidateProducts[0].matHierarchyList">
                <pm-attribute-grid *ngIf="supplierProductService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                  <pm-grid-group>
                    <pm-grid-column>
                      <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>

                  </pm-grid-group>
                </pm-attribute-grid>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!candidateUtilService.isInProgressCandidate(supplierProductService.candidate)">
          <div>
            <pm-attribute-grid *ngIf="!!supplierProductService.productAttributes?.length" [title]="'Extended attributes: Product'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="!!supplierProductService.upcAttributes?.length" [title]="'Extended attributes: UPC'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="supplierProductService?.candidate?.warehouseSwitch && !!supplierProductService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierProductService.candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierProductService.candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>


          <!--   Mat Hierarchy   -->
          <div *ngIf="!!supplierProductService.candidate?.candidateProducts[0]?.matHierarchyList?.length">
            <div *ngFor="let hierarchy of supplierProductService.candidate.candidateProducts[0].matHierarchyList">
              <pm-attribute-grid *ngIf="!!hierarchy.attributes?.length" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierProductService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>
      </div>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="false">
              <div *ngIf="!!supplierProductService.candidate?.supplierComment">
                <b>{{supplierProductService.candidate?.contactName}}</b> <br>
                {{supplierProductService.candidate?.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!supplierProductService.candidate.buyerCommentUser">
                <b>{{supplierProductService.candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!supplierProductService.candidate.buyerComment">
                {{supplierProductService.candidate.buyerComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <!--  Audit History -->
  <pm-review-drawer-container >
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="historyService.sortedAudits">
            <div *ngIf="historyService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{historyService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" *ngIf="!isClosedOrUnderReview" (onClick)="onClickBack()" [disabled]="!canNavigate || !canClickSubmit"></p-button>
    <p-button class="ghost m-2" label="Back to home" *ngIf="isClosedOrUnderReview" (onClick)="onClickBackToHome()" [disabled]="!canEditAndResubmit || !canNavigate || !canClickSubmit"></p-button>
    <p-button class="ghost m-2" label="Print" (onClick)="onClickPrint()"></p-button>
    <p-button class="ghost m-2" label="Edit & Resubmit" *ngIf="isRejected()" (onClick)="resubmitCandidate()" [disabled]="!canEditAndResubmit || !canClickSubmit"></p-button>
    <p-button class="m-2" label="Submit" *ngIf="!isClosedOrUnderReview" (onClick)="onClickSubmit()" [disabled]="!canClickSubmit"></p-button>
  </pm-footer>
</pm-review>
