import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';
import {Candidate} from 'pm-models';
import {CandidateSearchFilter, CandidateSearchFilter_Column} from 'pm-models/lib/candidateSearchFilter';

import {GrowlService} from '../../growl/growl.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {TaskListService} from '../task-list-service/task-list.service';
import {TaskListTask} from '../task-list-service/taskListTask';
import {PreferencesService} from '../../service/preferences.service';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {UserPreferences} from 'pm-models/lib/userPreferences';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-task-viewer-table',
  templateUrl: './task-viewer-table.component.html',
  styleUrls: ['./task-viewer-table.component.scss']
})
export class TaskViewerTableComponent implements OnInit {

  mapSortOrderPrimeTableToEndPoint = new Map([[1, 'ASC'], [-1, 'DESC']]);
  mapColumnNamePrimeTableToEndPoint = new Map([
    ['candidateId', 'CANDIDATE_ID'],
    ['description', 'PRODUCT_DESCRIPTION'],
    ['displayName', 'TASK_TYPE'],
    ['buyerDisplayName', 'BUYER_NAME'],
    ['supplierDisplayName', 'SUPPLIER_NAME'],
    ['lastUpdatedBy', 'LAST_UPDATED_BY'],
    ['lastUpdatedTime', 'LAST_UPDATED_TIME'],
    ['landedOn', 'LANDED_ON'],
  ]);

  @Input()
  isBuyer: boolean = false;

  @Input()
  isSca: boolean = false;

  @Input()
  isPia: boolean = false;

  tasks: TaskListTask[] = [];
  totalNumRecords: number;

  currentPageNumber: number = 0;
  currentSortOrder: string = 'DESC';
  currentSortField: string = 'CANDIDATE_ID';
  currentBuyerFilter: CandidateSearchFilter = new CandidateSearchFilter(CandidateSearchFilter_Column.BUYER_ID, []);

  showSpinner: boolean = true;

  maxNumRecords: number = 999;

  userPreferences: UserPreferences;
  buyers: [] = [];
  buyerFilters: string[] = [];

  hasBulkActionPermission = false;

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              public taskListService: TaskListService,
              protected growlService: GrowlService,
              public candidateService: CandidateService,
              public workflowService: WorkflowService,
              public lookupService: LookupService,
              public preferencesService: PreferencesService,
              private invitedDistributorService: InvitedDistributorService,
              public permissionService: NgxPermissionsService,
              public authService: AuthService) {
  }

  async ngOnInit() {
    this.permissionService.hasPermission('ROLE_BULK_ACTION').then((hasPermission) => {
      if (hasPermission) {
        this.hasBulkActionPermission = this.isBuyer || this.isSca || this.isPia;
      }
    });

    // add isFollowed attribute to buyers
    const buyers = await this.lookupService.findBuyers(this.maxNumRecords).toPromise();
    for (let i = 0; i < buyers.length; i++) {
      buyers[i].isFollowed = false;
    }

    const preferences = await this.preferencesService.getPreferences().toPromise();
    this.userPreferences = preferences;
    if (preferences.followingDesks) {
      for (let i = 0; i < preferences.followingDesks.length; i++) {
        const buyer = this.findItem(buyers, preferences.followingDesks[i]);
        if (buyer) {
          buyer.isFollowed = true;
        }
      }
    }
    if (preferences.buyerFilters && preferences.buyerFilters.length > 0) {
      this.buyerFilters = preferences.buyerFilters;
      this.currentBuyerFilter.values = preferences.buyerFilters;
    }
    this.buyers = buyers;

    //
    // Check if we got routed from a page that wants us to display growl message
    //
    this.route.queryParamMap.subscribe(params => {
      if (params.has('growlMessage')) {
        this.growlService.addMessage(params['params']['growlMessage'], params['params']['growlToUse']);
        window.history.replaceState({}, 'Title', '#/tasks');
      }
    });

    this.getOpenTasks(true, this.currentPageNumber,
      this.currentSortOrder, this.currentSortField,
      [this.currentBuyerFilter]);
  }

  findItem(buyers: any[], id: string) {
    return buyers.find(item => id === item.buyerId);
  }

  getOpenTasks(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string, filter?: CandidateSearchFilter[]) {
    this.showSpinner = true;
    this.taskListService.getOpenTasks(getCount, pageNumber, sortOrder, sortField, filter).subscribe(result => {
      if (result.length > 0) {
        if (getCount) {
          this.totalNumRecords = result[0].count;
        }
        this.tasks = result;
      } else {
        this.totalNumRecords = 0;
        this.tasks = [];
      }
      this.showSpinner = false;
    });
  }

  onClickRow(task: TaskListTask) {
    return this.router.navigate([this.getUrlForTaskDetails(task)],
      {queryParams: this.taskListService.getQueryParamsForTaskDetails(task)});
  }

  getUrlForTaskDetails(task: TaskListTask): string {
    if (task.name === 'Key Vendor Data' || task.name === 'Revise Vendor Data') {
      if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
        return '/setupCandidateType';
      } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
        return '/setupAdditionalCasePack';
      } else if (task.taskType === Candidate.BONUS_SIZE) {
        return '/setupBonusSize';
      } else if (task.taskType === Candidate.MRT) {
        return '/setupMrt';
      } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
        return '/setupAssociateUpc';
      } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
        return '/setupReplacementUpc';
      } else {
        return '/';
      }
    } else if (task.name === 'Key Buyer Data' || task.name === 'Revise Buyer Data') {
      if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
        return '/buyerAdditionalCasePackReview';
      } else if (task.taskType === Candidate.BONUS_SIZE) {
        return '/buyerBonusSizeReview';
      } else if (task.taskType === Candidate.MRT) {
        return '/buyerMrtReview';
      } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
        return '/buyerReplacementUpcReview';
      } else {
        return '/buyerProductReview';
      }
    } else if (task.name === 'Assign Warehouse') {
      if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
        return '/scaAdditionalCasePack';
      } else if (task.taskType === Candidate.BONUS_SIZE) {
        return '/scaBonusSizeReview';
      } else if (task.taskType === Candidate.MRT) {
        return '/scaMrtReview';
      } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
        return '/scaReplacementUpcReview';
      } else {
        return '/scaProductReview';
      }
    } else if (task.name === 'PIA Final Review') {
      if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
        return '/piaAdditionalCasePack';
      } else if (task.taskType === Candidate.BONUS_SIZE) {
        return '/piaBonusSizeReview';
      } else if (task.taskType === Candidate.MRT) {
        return '/piaMrtReview';
      } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
        return '/piaReplacementUpcReview';
      } else {
        return '/procurementSupportProductReview';
      }
    } else if (task.name === 'Review Associate UPC') {
      if (task.taskType === Candidate.ASSOCIATE_UPC) {
        return '/associateUpcProcurementReview';
      }
    } else if (task.name === 'PIA New Product Flow') {
      if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
        return '/piaSetupNewProduct';
      } else if (task.taskType === Candidate.ASSOCIATE_UPC) {
        return '/piaAssociateUpcSetup';
      } else if (task.taskType === Candidate.BONUS_SIZE) {
        return '/piaSetupBonusSizeComponent';
      } else if (task.taskType === Candidate.REPLACEMENT_UPC) {
        return '/piaSetupReplacementUpc';
      } else if (task.taskType === Candidate.ADDITIONAL_CASE_PACK) {
        return '/piaSetupCasePackComponent';
      } else if (task.taskType === Candidate.MRT) {
        return '/piaSetupMrt';
      } else if (task.taskType === Candidate.NEW_PRODUCT_UPLOAD_PARENT && task.status === Candidate.UPLOADED) {
        return '/volumeUpload';
      } else if (task.taskType === Candidate.EDI_UPLOAD_PARENT && task.status === Candidate.UPLOADED) {
        return '/ediUpload';
      } else if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR) {
        this.invitedDistributorService.resetService();
        return '/piaAddDistributor';
      } else if (task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
        this.invitedDistributorService.resetService();
        return '/piaAddDistributor';
      }
    } else if (task.name === 'New Associate UPC') {
      if (task.taskType === Candidate.ASSOCIATE_UPC) {
        return '/setupAssociateUpc';
      }
    } else if (task.name === 'PIA OB Reg Flow Submit') {
      if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
        return '/piaSetupNewProduct';
      }
    } else if (task.name === 'Key OB Reg Data') {
      if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
        return '/obRegScalePluReview';
      }
    } else if (task.name === 'PIA OB Reg Flow Activate') {
      if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
        return '/piaScalePluReview';
      }
    } else if (task.name === 'Dsd Invited Supplier') {
      if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR) {
        this.invitedDistributorService.resetService();
        return '/invitedDistributorCaseDetails';
      } else if (task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
        this.invitedDistributorService.resetService();
        return '/supplierAddDistributor';
      }
    } else if (task.name === 'Buyer Review Dsd Invited Suppliers') {
      if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR ||
        task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
        return '/buyerInvitedDistributorsReview';
      }
    } else if (task.name === 'PHARM New Product Flow') {
      if (task.taskType === Candidate.NEW_PRODUCT || task.taskType === Candidate.PLU) {
        return '/pharmSetupNewProduct';
      }
    } else if (task.name === 'PIA Review Dsd Invited Suppliers') {
      if (task.taskType === Candidate.ADDITIONAL_DISTRIBUTOR ||
        task.taskType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
        return '/piaInvitedDistributorsReview';
      }
    } else {
      return '/';
    }
  }

  onLoadData(event: any) {
    const pageNumber = this.getPageNumber(event.first, this.taskListService.PAGE_SIZE);
    let sortField = this.mapColumnNamePrimeTableToEndPoint.get(event.sortField);
    if (!sortField) {
      sortField = 'CANDIDATE_ID';
    }
    const sortOrder = this.getSortOrder(sortField, this.mapSortOrderPrimeTableToEndPoint.get(event.sortOrder + 0));

    if (this.currentPageNumber === pageNumber && this.currentSortOrder === sortOrder && this.currentSortField === sortField) {
      return;
    } else {
      this.currentPageNumber = pageNumber;
      this.currentSortOrder = sortOrder;
      this.currentSortField = sortField;
    }

    this.getOpenTasks(false, pageNumber, sortOrder, sortField, [this.currentBuyerFilter]);
  }

  getPageNumber(first: number, pageSize: number) {
    return first / pageSize;
  }


  onApplyBuyerFilter(event: any) {
    if (event.map) {
      this.currentBuyerFilter.values = event.map(buyer => buyer.buyerId);
      if (this.currentBuyerFilter.values.sort().join(',') !== this.buyerFilters.sort().join(',')) {
        this.userPreferences.buyerFilters = this.currentBuyerFilter.values;
        this.userPreferences.hasSetupPreferences = true;
        this.preferencesService.updatePreferences(this.userPreferences).subscribe();
      }
    } else {
      this.currentBuyerFilter.values = [];
    }

    this.getOpenTasks(true, this.currentPageNumber,
      this.currentSortOrder, this.currentSortField,
      [this.currentBuyerFilter]);

  }

  onClickBulkEdit(toReview: Candidate[]) {
    const queryParams = {};
    queryParams['toReview'] = toReview;
    return this.router.navigate([this.getUrlForBulkEdit()],
      {queryParams: queryParams});
  }

  getUrlForBulkEdit() {
    if (this.isBuyer) {
      return '/buyerBulkEdit';
    } else if (this.isSca) {
      return '/scaBulkEdit';
    }
  }

  getSortOrder(sortField: string, sortOrder: string) {
    if (sortField === 'LAST_UPDATED_TIME' && sortOrder === 'ASC') {
      return 'DESC';
    } else if (sortField === 'LAST_UPDATED_TIME') {
      return 'ASC';
    }
    return sortOrder;
  }
}
