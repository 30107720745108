import {HierarchyDetails, MetaDataDescriptions, MetaDataDomain, MetaDataIdentifiers, MetaDataKey} from 'pm-models';

/**
 * Contains MAT Hierarchy related attributes and optional values.
 *
 * @author s573181
 * @since 1.58.0
 */
export class Attribute {

  key: MetaDataKey;
  identifiers: MetaDataIdentifiers;
  descriptions: MetaDataDescriptions;
  domain: MetaDataDomain;
  hierarchyDetails: HierarchyDetails;
  value?: any;
}
