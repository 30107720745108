import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {NoStoresMessageExtendedComponent} from './no-stores-message-extended.component';


@NgModule({
  declarations: [NoStoresMessageExtendedComponent],
  exports: [NoStoresMessageExtendedComponent, AttributeDisplayModule],
  imports: [
    CommonModule, AttributeDisplayModule
  ]
})
export class NoStoresMessageExtendedModule { }
