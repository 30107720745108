<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>
<ng-template #card>
  <pm-card title="OMI remarks" subtitle="Provide provide remarks for OMI.">
    <ng-container *ngIf="model">
      <pm-attribute-display-text-input [attribute]="remark1Configuration"
                                       [(model)]="model.remark1"
                                       [attributeError]="errorModel?.remark1">
      </pm-attribute-display-text-input>
      <pm-attribute-display-text-input [attribute]="remark2Configuration"
                                       [(model)]="model.remark2"
                                       [attributeError]="errorModel?.remark2">
      </pm-attribute-display-text-input>
    </ng-container>
  </pm-card>
</ng-template>
