<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>
<ng-template #card>
  <pm-card title="Merchandising info" subtitle="Provide merchandising and category information for this product.">
    <ng-container *ngIf="candidateModel">
      <pm-attribute-display-typeahead [attribute]="merchandiseTypeConfiguration"
                                      [(model)]="candidateModel.merchandiseType"
                                      [attributeError]="candidateErrorModel?.merchandiseType"
                                      (selection)="merchandiseTypeChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead [attribute]="commodityConfiguration"
                                      [(model)]="candidateModel.commodity"
                                      [attributeError]="candidateErrorModel?.commodity"
                                      (selection)="onCommodityChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead [attribute]="subCommodityConfiguration"
                                      [(model)]="candidateModel.subCommodity"
                                      [attributeError]="candidateErrorModel?.subCommodity"
                                      (selection)="onSubCommodityChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-typeahead *ngIf="candidateModel.overrideDepartment && canEditDepartment" [attribute]="departmentConfiguration"
                                      [attributeError]="candidateErrorModel?.overrideSubDepartment"
                                      [(model)]="candidateModel.overrideSubDepartment"
                                      (selection)="onSubCommodityDepartmentChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-text-input
        [attribute]="defaultDepartmentConfiguration"
        *ngIf="!candidateModel.overrideDepartment && canEditDepartment"
        [model]="getDepartment()">
      </pm-attribute-display-text-input>

      <div *ngIf="canEditDepartment" class="row pb-4">
        <a [routerLink]="" queryParamsHandling="preserve" class="override-button" (click)="toggleDepartmentOverride()">
          {{candidateModel.overrideDepartment ? 'Reset to Default' : 'Override'}}
        </a>
      </div>

      <div *ngIf="candidateModel.overrideDepartment && canEditDepartment" class="row pb-4">
        <div class="override-warning">
          <div class="borderLeft col-md-8 justify-content-md-left warning-box">
            <div class="row ml-2">
              <img src="../../../assets/images/info_24px.svg">
              <div class="col ml-2">Changing the department may remove existing store authorization requests.</div>
            </div>
          </div>
        </div>
      </div>

      <pm-attribute-display-typeahead [attribute]="pssDepartmentConfiguration" [(model)]="candidateModel.pssDepartment"
                                      [attributeError]="candidateErrorModel?.pssDepartment"
                                      (selection)="pssDepartmentChange($event)">
      </pm-attribute-display-typeahead>

      <div class="row">
        <div class="col-7 pr-0" style="padding-right: 0;">
          <pm-attribute-display-typeahead [attribute]="seasonConfiguration"
                                          [(model)]="candidateModel.season"
                                          (selection)="seasonChange($event)">
          </pm-attribute-display-typeahead>
        </div>
        <div class="col-3 pr-0 pl-0">
          <pm-attribute-display-text-input [attribute]="yearConfiguration"
                                           [(model)]="candidateModel.seasonYear">
          </pm-attribute-display-text-input>
        </div>
        <div *ngIf="candidateErrorModel?.season" class="attribute-group season-error">
          <div class="attribute-label"></div>
          <p class="attribute-error-message ml-3">{{candidateErrorModel?.season}}</p>
        </div>
        <div *ngIf="candidateErrorModel?.seasonYear" class="attribute-group season-error">
          <div class="attribute-label"></div>
          <p class="attribute-error-message ml-3">{{candidateErrorModel?.seasonYear}}</p>
        </div>
      </div>

      <pm-attribute-display-text-input [attribute]="likeItemCodeConfiguration"
                                       [(model)]="candidateModel.likeId"
                                       [attributeError]="candidateErrorModel?.likeId">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="estimatedStoreCountConfiguration"
                                       [(model)]="candidateModel.numberOfStores"
                                       [attributeError]="candidateErrorModel?.numberOfStores">
      </pm-attribute-display-text-input>
    </ng-container>

  </pm-card>
</ng-template>
