import {Component, OnInit} from '@angular/core';
import {AttributeTextInputConfig, AttributeTypeaheadConfig, NewUpcRequestError, TextInputType} from 'pm-models';
import {Router} from '@angular/router';
import {OwnBrandService} from '../../service/ownbrand.service';
import {GrowlService} from '../../growl/growl.service';
import {DatePipe} from '@angular/common';
import {NewUpcRequest, UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-request-heb-upc',
  templateUrl: './request-heb-upc.component.html',
  styleUrls: ['./request-heb-upc.component.scss']
})
export class RequestHebUpcComponent implements OnInit {

  constructor(private ownBrandService: OwnBrandService, private growlService: GrowlService, private router: Router,
              private datePipe: DatePipe, private authService: AuthService) {}

  upcRequest: NewUpcRequest;
  newUpcRequestError: NewUpcRequestError;
  isVendor: boolean = false;
  reservedUpcs: number[];
  isShowingReservationSuccessOverlay = false;

  // just for display, the back end will actually calculate the real expiration date
  expirationDateString: string;
  isSubmitOrCancelDisabled: boolean = false;

  descriptionConfiguration: AttributeTypeaheadConfig = {
    label: 'Name',
    description: 'Provide a name or brief description of the product for internal use.',
    isRequired: true,
    name: 'Description',
    idRef: 'descriptionId',
    displayRef: 'displayName',
    placeholderText: 'Enter a brief description...',
    showAddlInfo: true
  };

  buyerConfiguration: AttributeTypeaheadConfig;

  supplierConfiguration: AttributeTypeaheadConfig;

  numberOfUpcsConfiguration: AttributeTextInputConfig = {
    label: '# of UPCs',
    description: 'You can request more than one UPC for this reservation.',
    isRequired: true,
    textInputType: TextInputType.integer,
    placeholderText: '#',
    inputGroupClass: 'ui-narrow-input',
    name: 'numberOfUpcsId',
    maxLength: 4
  };

  upcRequestNotesConfiguration: AttributeTextInputConfig = {
    label: 'Notes',
    isRequired: false,
    textInputType: TextInputType.textarea,
    inputGroupClass: 'text-area-resize-disabled',
    name: 'upcRequestNotes',
    charLimit: 200,
    maxLength: 400
  };

  ngOnInit() {
    this.resetErrors();
    this.expirationDateString = this.getExpirationDate();
    this.upcRequest = this.getNewUpcRequest();
    this.isVendor = this.authService.isVendor();
    this.buyerConfiguration = this.getBuyerConfiguration();
    this.supplierConfiguration = this.getSupplierConfiguration();
  }

  private getNewUpcRequest(): NewUpcRequest {
    return {
      candidate: {
        description: null,
        vendor: null,
        buyer: null,
      },
      upcRequestNotes: null,
      count: 1,
      requestType: UpcRequestType.HEB_197870
    };
  }

  private getExpirationDate(): string {
    const expirationDate: Date = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 18);
    return this.datePipe.transform(expirationDate, 'M/dd/yy');
  }

  onBuyerChange(event) {
    this.upcRequest.candidate.buyer = event;
  }

  onSupplierChange(event) {
    this.upcRequest.candidate.vendor = event;
  }

  onUpcRequestNotesChange(event) {
    this.upcRequest.upcRequestNotes = event;
  }

  getIsSubmitDisabled() {
    return this.isSubmitOrCancelDisabled || (this.upcRequest.upcRequestNotes && this.upcRequest.upcRequestNotes.length > 200);
  }

  onClickRequestUpcs(event, panel, target) {
    this.isSubmitOrCancelDisabled = true;
    if (!this.validateReservationDetails()) {
      this.isSubmitOrCancelDisabled = false;
      return;
    }
    this.ownBrandService.reserveUpcs(this.upcRequest).subscribe((reservedUpcs) => {
      this.reservedUpcs = reservedUpcs;
      this.showReservationSuccessOverlay(event, panel, target);
    }, (error) => {
      if (error?.error?.message) {
        this.growlService.addError(error.error.message);
      } else {
        this.growlService.addError(error.message);
      }
      this.isSubmitOrCancelDisabled = false;
    });
  }

  showReservationSuccessOverlay(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
    this.isShowingReservationSuccessOverlay = true;
  }

  onClose() {
    this.resetErrors();
    this.router.navigate(['/tasks']).then();
  }

  resetErrors() {
    this.newUpcRequestError = new NewUpcRequestError();
  }

  private getSupplierConfiguration(): AttributeTypeaheadConfig {
    return {
      label: 'Supplier',
      description: 'The supplier of record that H-E-B pays.',
      isRequired: this.isVendor,
      name: 'vendorTypeAhead',
      idRef: 'vendorId',
      displayRef: 'displayName',
      placeholderText: 'Select or search for a Supplier...',
      searchUrl: '/lookup/vendor'
    };
  }

  private getBuyerConfiguration(): AttributeTypeaheadConfig {
    return {
      label: 'Buyer',
      description: 'Choose an H-E-B buyer for this UPC.',
      isRequired: this.isVendor,
      name: 'buyerTypeAhead',
      idRef: 'buyerId',
      displayRef: 'displayName',
      placeholderText: 'Type to search for a Buyer...',
      searchUrl: '/lookup/buyer'
    };
  }

  private validateReservationDetails(): boolean {
    this.resetErrors();
    let isValid = true;
    if (!this.upcRequest.candidate.description) {
      this.newUpcRequestError.description = 'Please enter a description.';
      isValid = false;
    }
    if (!this.upcRequest.count || +this.upcRequest.count < 1) {
      this.newUpcRequestError.count = 'Please request at least one UPC.';
      isValid = false;
    } else if (+this.upcRequest.count > 1200) {
      this.newUpcRequestError.count = 'Please request less than 1200 UPCs.';
      isValid = false;
    }
    if (this.upcRequest.upcRequestNotes && this.upcRequest.upcRequestNotes.length > 200) {
      this.newUpcRequestError.upcRequestNotes = 'Notes cannot exceed 200 characters.';
      isValid = false;
    }
    if (this.isVendor) {
      if (!this.upcRequest.candidate.vendor) {
        this.newUpcRequestError.vendor = 'Please enter a supplier.';
        isValid = false;
      }
      if (!this.upcRequest.candidate.buyer) {
        this.newUpcRequestError.buyer = 'Please enter a buyer.';
        isValid = false;
      }
    }

    return isValid;
  }
}
