import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CasePackBasicsCardComponent } from './case-pack-basics-card.component';
import { CardModule } from '../../shared/card/card.module';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';



@NgModule({
  declarations: [CasePackBasicsCardComponent],
  exports: [CasePackBasicsCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule,
    AttributeDisplayModule
  ]
})
export class CasePackBasicsCardModule { }
