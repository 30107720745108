import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {catchError, concatMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {OwnBrandService} from '../../service/ownbrand.service';
import {CandidateService} from '../../service/candidate.service';
import {AttributeTypes} from 'pm-components';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {LookupService} from '../../service/lookup.service';
import {Candidate} from 'pm-models';
import {UUID} from 'angular2-uuid';
import {UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {SupplierProductService} from '../../service/supplier-product.service';
import {AuthService} from '../../auth/auth.service';
import {PiaProductService} from '../../service/pia-product.service';
import {calculateCheckDigit} from '../../shared/upc.utils';

@Component({
  selector: 'app-reserved-upc-review',
  templateUrl: './reserved-upc-review.component.html',
  styleUrls: ['./reserved-upc-review.component.scss']
})
export class ReservedUpcReviewComponent implements OnInit {

  constructor(public route: ActivatedRoute, public router: Router, public ownBrandService: OwnBrandService,
              public candidateService: CandidateService, public editCandidateModalService: EditCandidateModalService,
              public lookupService: LookupService, public growlService: GrowlService, public authService: AuthService,
              public supplierProductService: SupplierProductService, public piaProductService: PiaProductService) { }

  public upcReservation: any;
  // Whether or not the user can edit the fields that can be editable. Only allowed if the upc is not in use by an active product.
  public canEdit = false;
  public isDeleteUpcDisabled = false;
  public isSetUpItemDisabled = false;

  ngOnInit() {
    this.route.queryParamMap.pipe(

      concatMap(params => this.validateUpcProvided(params)),
      concatMap(upc => this.ownBrandService.checkReservedUpc(upc)),
      catchError(() => this.handleError('Invalid upc provided.')),
      tap(upcReservation => this.upcReservation = upcReservation),
      concatMap(() => this.setCanEdit(this.upcReservation.upc))
    ).subscribe();
  }

  /**
   * User can edit if there's no live products or in progress candidates tied to the upc.
   * @param upc the reserved upc.
   */
  setCanEdit(upc): Observable<any> {
    let isLiveProduct = false;
    return this.lookupService.getProductByUpcAndApNumbers(this.upcReservation.upc, []).pipe(
      tap((productData) =>  isLiveProduct = !!productData ),
      concatMap(() => {
        // if there's not a live product, check for active candidates
        return !isLiveProduct ? this.candidateService.getCandidateByUpcOrCaseUpc(upc) : of([]);
      }),
      tap((candidateProducts) => {
        // if there's no live products and candidate products tied to the upc, user can edit, else false.
        this.canEdit = !isLiveProduct && (!candidateProducts || candidateProducts.length === 0);
      })
    );

  }

  onClose() {
    this.router.navigate(['/reservedUpcs']).then();
  }

  onClickDeleteAndReleaseUpc(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  deleteAndReleaseUpc(panel) {
    this.isDeleteUpcDisabled = true;
    const upcRequestType = ('' + this.upcReservation.upc).startsWith('41220') ? UpcRequestType.HEB_41220 : UpcRequestType.HEB_197870;
    this.ownBrandService.releaseUpc(this.upcReservation.upc, upcRequestType).pipe(
      tap(() => panel.hide()),
      concatMap(() => this.router.navigate(['/reservedUpcs']))
    ).subscribe();
  }

  /**
   * Instantiate a candidate (not created api wise yet), reset new product flow, and initialize with newly setup candidate.
   */
  onClickSetUpItem() {
    this.isSetUpItemDisabled = true;
    const candidate: Candidate = this.getReservationCandidate();


    if (this.authService.isVendor()) {
      // reset service and
      this.supplierProductService.resetService();
      this.supplierProductService.setOriginalAndCurrentCandidate(candidate);
      this.router.navigate(['/setupCandidateType']).then();

    } else if (this.authService.isPia()) {
      this.piaProductService.resetService();
      this.piaProductService.setOriginalAndCurrentCandidate(candidate);
      this.router.navigate(['/piaSetupNewProduct']).then();
    } else {
      this.growlService.addError('User not set up to create new products from a 41220 UPC.');
      this.isSetUpItemDisabled = false;
    }
  }

  /**
   * Validates upc is provided, else routes to task page.
   * @param params the url path params.
   */
  validateUpcProvided(params) {
    if (!params.has('upc')) {
      return this.handleError('Reserved Upc not provided.');
    }
    return of(params['params']['upc']);
  }

  /**
   * Handles errors, routes to task page.
   * @param message
   */
  handleError(message) {
    return this.router.navigate(['/tasks'], {
      queryParams: {
        growlMessage: message, growlToUse: GrowlService.SEVERITY_ERROR
      }
    });
  }
  /**
   * Returns date string offset from GMT.
   * @param dateNumber
   */
  getDateString(dateNumber) {
    if (!dateNumber) {
      return '';
    } else {
      const date = new Date(dateNumber);

      return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
        '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +
        '/' + date.getFullYear() + ' @ 11:59PM';

    }
  }


  /**
   * Shows the edit candidate modal.
   * @param attributeType
   */
  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }


  /**
   * Show edit candidate modal.
   * @param type
   * @param overrides
   */
  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.upcReservation, {} ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.upcReservation = response;
        this.updateReservation();
      }
    });
  }

  /**
   * Returns the attribute type.
   */
  get attributeType() {
    return AttributeTypes;
  }

  /**
   * Edits product description. Saves on response.
   */
  editProductDescription() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.ProductDescription, overrides: {}},
        {type: AttributeTypes.Receipt, overrides: {}}
      ], this.upcReservation).subscribe( response => {

      if (response) {
        this.upcReservation = response;
        this.updateReservation();
      }
    });
  }

  /**
   * Updates a upc reservation.
   */
  updateReservation() {

    this.ownBrandService.updateReservation(this.getReservationCandidate()).subscribe((reservation) => {
      this.upcReservation = reservation;
      this.growlService.addSuccess('UPC Reservation successfully updated.');
    }, (error) => {
      const errorMessage = 'UPC Reservation failed with error: ' + error.message ? error.message : error;
      this.growlService.addError(errorMessage);
    });
  }

  /**
   * Converts upc reservation record to a candidate.
   * @return the candidate.
   */
  getReservationCandidate() {
    const candidate = new Candidate();
    candidate.description = this.upcReservation.description;
    candidate.vendor = this.upcReservation.vendor;
    candidate.buyer = this.upcReservation.buyer;
    candidate.status = Candidate.IN_PROGRESS;
    candidate.productType = Candidate.SELLABLE;
    candidate.candidateType = Candidate.NEW_PRODUCT;
    candidate.candidateProducts = [];
    candidate.candidateProducts.push({id: UUID.UUID(), upc: this.upcReservation.upc,
      upcCheckDigit: calculateCheckDigit(this.upcReservation.upc),
      description: this.upcReservation.description});

    return candidate;
  }
}
