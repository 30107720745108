import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeConfig, AttributeTextInputConfig, Candidate, TextInputType} from 'pm-models';

@Component({
  selector: 'pm-request-new-attribute-confirm-overlay',
  templateUrl: './request-new-attribute-confirm.component.html',
  styleUrls: ['./request-new-attribute-confirm.component.scss']
})
export class RequestNewAttributeConfirmComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  classOverride: string = 'col-md-4';

  @Output()
  continueButtonClicked = new EventEmitter<string>();

  @Output()
  cancelClicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  continueButton() {
    this.continueButtonClicked.emit();
  }

  cancel() {
    this.cancelClicked.emit();
  }
}
