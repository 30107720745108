import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseAttributeDisplayComponent} from '../base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-date-picker',
  templateUrl: './attribute-display-date-picker.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayDatePickerComponent extends BaseAttributeDisplayComponent implements OnInit {

  _model: any;

  @Input()
  get model(): any {
    return this._model;
  }

  @Input()
  minimumDate: Date;

  set model(val: any) {
    if (val === undefined && this.attribute && this.attribute.defaultValue) {
      this._model = this.attribute.defaultValue;
      this.dateDisplay = new Date(this.attribute.defaultValue);
    } else if (this._model !== val) {
      this._model = val;
      if (this._model) {
        this.dateDisplay = new Date(val);
      } else {
        this.dateDisplay = undefined;
      }
    }
  }

  dateDisplay: Date;

  ngOnInit() {
    if (!this._model && this.attribute && this.attribute.defaultValue) {
      this.model(this.attribute.defaultValue);
    }
  }

  isReadOnly() {
    return (this.attribute.isReadOnly && this.attribute.isReadOnly() === true);
  }

  isDisabled() {
    return (this.attribute.isDisabled && this.attribute.isDisabled() === true);
  }

  dateChange(event) {
    this.model = event;
    this.modelChange.emit(event);
  }
}
