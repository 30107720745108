import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerComponent } from './drawer.component';
import { DrawerContainerComponent, DrawerContentComponent } from './drawer-container.component';

const COMPONENTS = [DrawerComponent, DrawerContainerComponent, DrawerContentComponent];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [CommonModule]
})
export class DrawerModule {}
