<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Basics"
    subtitle="Provide the basic details about this new case.">
    <ng-container *ngIf="model">
      <pm-attribute-display-radio [attribute]="weightConfiguration"
                                  [(model)]="model.itemWeightType"
                                  [attributeError]="errorModel?.itemWeightType"
                                  (change)="weightChange($event)">
      </pm-attribute-display-radio>

      <pm-attribute-display-toggle
        [attribute]="codeDateConfiguration"
        [(model)]="model.codeDate"
        (change)="codeDateChange($event)">
      </pm-attribute-display-toggle>

     <pm-attribute-display-text-input [attribute]="maxShelfLifeConfiguration"
        [attributeError]="errorModel?.maxShelfLife"
        [(model)]="model.maxShelfLife">
      </pm-attribute-display-text-input>

     <pm-attribute-display-text-input [attribute]="inboundSpecConfiguration"
        [attributeError]="errorModel?.inboundSpecDays"
        [(model)]="model.inboundSpecDays">
      </pm-attribute-display-text-input>
    </ng-container>

  </pm-card>
</ng-template>
