import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule } from './editor/editor.module';
import { ReviewModule } from './review/review.module';
import { BasicModule } from './basic/basic.module';
import { OverlayModule } from './overlay/overlay.module';
import { EditorBaseModule } from './editor-base/editor-base.module';

@NgModule({
  declarations: [],
  exports: [EditorModule, ReviewModule, OverlayModule, BasicModule, EditorBaseModule],
  imports: [CommonModule]
})
export class LayoutModule {}
