

<pm-overlay *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-title></pm-title>
  <div class="notification-card">
    <div class="m-3">
      <div class="row">
        <div class="col-3 text-center">
          <img src="/assets/images/envelope.svg" class="envelope">

        </div>
        <div class="col-9">
          <h3 class="pm-notification-title">You've unsubscribed.</h3>
          <p>
            You'll no longer receive work updates and notifications via email.
          </p>
          <p>
            If you change your mind later, you can update your notification preferences in your <a
              [routerLink]="'../supplierNotifications'">Account settings page</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</pm-overlay>
