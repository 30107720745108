<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-mrt-case-stepper *ngIf="supplierMrtService.hasNewInners(supplierMrtService.candidate) === false"
                          [candidateErrors]="supplierMrtService.getAllSupplierMrtErrors()"
                          [stepIndex]="noInnerFlowStepperIndex" [steps]="supplierMrtService.getNoInnerFlowSteps()"
                          (indexClick)="onClickStepper($event)">
    </app-mrt-case-stepper>
    <app-mrt-case-stepper *ngIf="supplierMrtService.hasNewInners(supplierMrtService.candidate)"
                          [candidateErrors]="supplierMrtService.getAllSupplierMrtErrors()"
                          [hasInnerCandidateError]="[supplierMrtService.hasInnerErrors()]"
                          [stepIndex]="innerFlowStepperIndex"
                          [steps]="supplierMrtService.getNewInnerFlowSteps()"
                          (indexClick)="onClickStepper($event)">
    </app-mrt-case-stepper>
  </pm-header>

  <div class="col-md-11" style="margin-top: 5rem; margin-bottom: 5rem; margin-left: -18%;">
    <div class="row">
      <div class="col-md-4 text-center">
        <img class="mrt-review-image" src="../../../../assets/images/you're-all-set.png">
      </div>
      <div class="col-md-8">
        <div class="row">
          <h3>
            You’re all set!
          </h3>
        </div>
        <div class="row">
          <p>
            You’ve set up your MRT and all of the new items. Hit submit and we’ll send everything below to your buyer for review.
          </p>
        </div>
        <div class="row">
          <p class="candidate-name-paragraph">
            {{supplierMrtService.candidate.description}}
          </p>
        </div>
        <div class="row">
          <div class="attribute-description">
            Case UPC: {{supplierMrtService.candidate.candidateProducts[0].caseUpc}}-{{supplierMrtService.candidate.candidateProducts[0].caseUpcCheckDigit}}
          </div>
        </div>
        <div class="row pt-3" *ngFor="let candidateInner of mrtCandidateInners">
          <div class="mrt-inner pt-3 pb-3">

            <div class="row ml-3">
              <div class="col-md-8">
                <div class="row">
                  UPC: {{supplierMrtService.getCurrentCandidateProducts(candidateInner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(candidateInner).upcCheckDigit}}
                  <div *ngIf="isNewItem(candidateInner)" class="new-item mr-1 ml-3">
                    <img src="/assets/images/mrt-new-item.svg">
                    <p>NEW ITEM</p>
                  </div>

                  <div *ngIf="isNewCase(candidateInner)" class="new-item">
                    <img src="/assets/images/mrt-new-item.svg">
                    <p>NEW CASE</p>
                  </div>
                </div>
                <div class="row">
                  <div class="attribute-description">
                    Sellable units in MRT: {{candidateInner.quantity}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-3" *ngFor="let existingInner of supplierMrtService.candidate.mrtInfo.existingInners">
          <div class="mrt-inner pt-3 pb-3">

            <div class="row ml-3">
              <div class="col-md-8">
                <div class="row">
                  UPC: {{existingInner.upc}}-{{existingInner.upcCheckDigit}}
                </div>
                <div class="row">
                  <div class="attribute-description">
                    Sellable units in MRT: {{existingInner.quantity}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Submit" (onClick)="onClickSubmit()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
