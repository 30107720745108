import {Component, OnInit} from '@angular/core';
import {Candidate, CandidateError} from 'pm-models';
import {GrowlService} from '../growl/growl.service';
import {switchMap, tap} from 'rxjs/operators';
import {InvitedDistributorService} from '../service/invited-distributor.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from '../service/lookup.service';
import {ProductService} from '../service/product.service';
import {CandidateService} from '../service/candidate.service';
import {CostService} from '../service/cost.service';
import {WorkflowService} from '../service/workflow.service';
import {CandidateUtilService} from '../service/candidate-util.service';

@Component({
  selector: 'app-pia-invited-distributors-review-closed',
  templateUrl: './pia-invited-distributors-review-closed.component.html',
  styleUrls: ['./pia-invited-distributors-review-closed.component.scss']
})
export class PiaInvitedDistributorsReviewClosedComponent implements OnInit {

  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';

  private candidateSubscription$: any;
  public taskId: any;
  public isViewingApprovedCandidates = false;
  public isViewingActivatedCandidates = false;

  constructor(public invitedDistributorService: InvitedDistributorService, public route: ActivatedRoute, public router: Router,
              private lookupService: LookupService, private productService: ProductService, public candidateService: CandidateService,
              private growlService: GrowlService, public costService: CostService, public workflowService: WorkflowService,
              private candidateUtilService: CandidateUtilService) { }

  ngOnInit() {
    this.invitedDistributorService.resetService();
    this.candidateSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR &&
            candidate.candidateType !== Candidate.ADDITIONAL_DISTRIBUTOR) {

            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
      if (params.has('taskId')) {
        this.taskId = parseInt(params.get('taskId'), 10);
      }
    });
  }



  private async setInitialValues(candidate: Candidate) {
    this.invitedDistributorService.isSelectingDistributors = true;
    this.invitedDistributorService.setOriginalAndCurrentCandidate(candidate);
    this.invitedDistributorService.productImageUrl = null;
    await this.initializeProductData().toPromise();
    await this.invitedDistributorService.initializeActivatedDistributorData();
    this.invitedDistributorService.createVendorToAuthGroupsMap();
    this.invitedDistributorService.candidateError = new CandidateError();
    this.invitedDistributorService.candidateProductError =
      this.invitedDistributorService.candidateError.candidateProductErrors[this.invitedDistributorService.candidate.candidateProducts[0].id];

    this.candidateService.findDistributorCandidatesByUpc(
      this.invitedDistributorService.candidate.candidateProducts[0].upc).subscribe((candidates) => {
      if (!candidates || candidates.length === 0) {
        this.growlService.addError('Unable to locate parent candidate.');
        return;
      }
      this.invitedDistributorService.allDsdCandidates = candidates;
      this.invitedDistributorService.findOtherInvitedCandidates();
    });
  }

  initializeProductData() {
    return this.lookupService.getProductByUpcAndApNumbers(this.invitedDistributorService.candidate.candidateProducts[0].upc, []).pipe(
      switchMap(
        (productData) => {
          this.invitedDistributorService.productData = productData;
          this.invitedDistributorService.productImageUrl = this.candidateUtilService.getProductImageUrl(this.invitedDistributorService.productData);
          return this.lookupService.getUpc(this.invitedDistributorService.candidate.candidateProducts[0].upc).pipe(tap((upc) => {
            this.invitedDistributorService.upc = upc;
          })).toPromise();
        }
      )
    );
  }
  onClose() {
    this.router.navigate(['/tasks']).then();
  }

  onClickPrint() {
    window.print();
  }
}
