<div class="category-selection p-fluid">
  <div class="row" [ngClass]="{'attribute-required': attribute?.isRequired, 'attribute-label': true}">
    <label>
      {{attribute?.label}}
    </label>
  </div>
  <p-autoComplete appendTo="body" #categorySelection class="p-autocomplete" inputId="{{attribute?.name}}" [ngModel]="displayModel" [suggestions]="options"
                  (completeMethod)="search($event)" [placeholder]="PLACEHOLDER_TEXT" field="{{DISPLAY_REF}}" (onFocus)="onFocus()"
                  [styleClass]="getStyleClass()" [inputStyleClass]="getInputStyleClass()" [disabled]="isDisabled"
                  [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" (ngModelChange)="onModelChange($event)"
                  (onSelect)="onSelect($event)" (onUnselect)="onClear($event)"
                  (onBlur)="onBlur()" [autoHighlight]="true" [dropdown]="true" tabindex="{{tabIndex}}"
                  [multiple]="true" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                  [ngClass]="getDisplayClass()" panelStyleClass="category-panel-style">

    <ng-template let-item pTemplate="selectedItem">
      <div class="ui-autocomplete-token-label">
        {{item[DISPLAY_REF]}}
      </div>
    </ng-template>

    <ng-template let-item pTemplate="item" let-index="index">

      <div class="ui-helper-clearfix">
        <div>
          {{item[DISPLAY_REF]}}
        </div>
        <div class="ui-autocomplete-list-item-description">
          {{item[HIERARCHY_DISPLAY_REF]}}
        </div>
        <div *ngIf="index === 0 && hasNoResultsMessage">
          <br>
          {{hasNoResultsMessage}}
        </div>
      </div>
    </ng-template>

  </p-autoComplete>
  <p *ngIf="attributeError" class="attribute-error-message">
    {{attributeError}}
  </p>
</div>
