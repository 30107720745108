import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  CandidateProduct,
  AttributeConfig,
  ProductImagesCardModel,
  ProductImagesCardErrors,
  ProductImagesCardPermissions, CandidateProductError
} from 'pm-models';
import { DisabledPermissionsMapper, ReadOnlyPermissionsMapper } from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-product-images-card',
  templateUrl: './product-images-card.component.html',
  styleUrls: ['./product-images-card.component.scss']
})
export class ProductImagesCardComponent implements OnInit {

  @Input()
  model: ProductImagesCardModel | CandidateProduct;
  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: ProductImagesCardPermissions;

  @Input()
  errorModel: ProductImagesCardErrors | CandidateProductError;

  globalContentStandardsUrl = '/candidates/training_guides/Global-Content-Standards-Suppliers.pdf';

  constructor() {}

  ngOnInit() {}

  imageUploadConfiguration: AttributeConfig = {
    label: '',
    description: '',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.imageLinks),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.imageLinks),
    isRequired: false
  };
  imageChange(e) {
    this.modelChange.emit(this.model);
  }
}
