<div class="pm-basic-body">
  <div class="container">
    <header class="row pt-5 justify-content-center" *ngIf="headerFacet">
      <div class="col  col-md-6">
        <h1 class="basic-title">
          <ng-content select="pm-title"></ng-content>
        </h1>
      </div>
    </header>

    <div class="row justify-content-center">
      <div class="col col-md-6">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

</div>
