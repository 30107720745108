import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeaheadComponent } from './typeahead.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TypeAheadTemplateComponent } from './typeahead.templates';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {ButtonModule} from 'primeng/button';

@NgModule({
  declarations: [TypeaheadComponent, TypeAheadTemplateComponent],
  exports: [TypeaheadComponent, AutoCompleteModule, FormsModule, TypeAheadTemplateComponent],
  entryComponents: [TypeAheadTemplateComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    AutoCompleteModule,
    FormsModule,
    ButtonModule
  ]
})
export class TypeaheadModule { }
