import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { HttpClientModule } from '@angular/common/http';
import { DirectiveModule } from '../directives/directive.module';
import {SharedModule} from 'pm-components';
import {AttachmentTileComponent} from './attachment-tile.component';

@NgModule({
  declarations: [AttachmentTileComponent],
  exports: [AttachmentTileComponent, ButtonModule, TooltipModule],
  imports: [CommonModule, ButtonModule, HttpClientModule, DirectiveModule, TooltipModule, SharedModule]
})
export class AttachmentTileModule {}
