import {BaseAttribute} from './base-attribute';
import {
  AttributeDisplayMuliSourceInputComponent,
  InputState
} from '../../shared/attribute-display/attribute-display-muli-source-input/attribute-display-muli-source-input.component';
import {AttributeConfig, Candidate, CandidateValidatorType} from 'pm-models';

export class CostLinkedItem implements BaseAttribute {

  private costLinkOptions: any[];

  getComponentType() {
    return AttributeDisplayMuliSourceInputComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      options: this.costLinkOptions,
      label: 'Cost linked item',
      description: 'Search by cost link number or item code to find the cost link.',
      isDisabled: () => false,
      isReadOnly: () => false,
      placeholderText: 'Search for cost link...',
      isRequired: true,
      name: 'costLinkId',
      defaultValue: 'LINK',
      ...overrides
    };
  }

  getCostLinkOptions(candidate: Candidate): any[] {
    if (!candidate) {
      return [];
    } else if (candidate.warehouseSwitch) {
      return [
        { value: 'LINK', label: 'Link #' },
        { value: 'ITEM', label: 'Item Code' },
        { value: 'UPC', label: 'UPC' }
      ];
    } else {
      return [
        { value: 'LINK', label: 'Link #' },
        { value: 'UPC', label: 'UPC' }
      ];
    }
  }


  setupComponent(componentInstance: AttributeDisplayMuliSourceInputComponent, candidate: Candidate, configOverrides: any = {}) {
    this.costLinkOptions = this.getCostLinkOptions(candidate);
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.costLink;
    componentInstance.modelChange.subscribe(x => {
      candidate.costLink = x.model;
      candidate.costLinkBy = x.category;
    });

    if (!candidate.costLinkBy) {
      candidate.costLinkBy = this.getAttributeConfig().defaultValue;
    }
    componentInstance.selectCategory = candidate.costLinkBy;
    componentInstance.selectCategoryChange.subscribe(x => {
      candidate.costLinkBy = x;
    });

    componentInstance.validate.subscribe(s => {
      if (configOverrides.validationService) {
        componentInstance.state = InputState.loading;
        candidate.costLinkFromServer = undefined;
        componentInstance.attributeError = undefined;
        configOverrides.validationService.validateCandidate(candidate, [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe(
          response => {
            componentInstance.state = InputState.valid;
            candidate.costLinkFromServer = response.costLinkFromServer;
          },
          error => {
            componentInstance.state = InputState.invalid;
            if (error.error.candidateErrors) {
              componentInstance.attributeError = error.error.candidateErrors.costLink;
            }
          }
        );
      }
    });
  }
}
