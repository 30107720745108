import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {CardModule} from '../../shared/card/card.module';
import {LabelInsightsImageCardComponent} from './label-insights-image-card.component';
import {
  StaticOverlayPanelModule
} from 'pm-components/lib/shared/overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {CheckboxModule} from 'primeng/checkbox';

@NgModule({
  declarations: [LabelInsightsImageCardComponent],
  imports: [CommonModule, AttributeDisplayModule, CardModule, StaticOverlayPanelModule, CheckboxModule],
  exports: [AttributeDisplayModule, CardModule, LabelInsightsImageCardComponent]
})
export class LabelInsightsImageCardModule {}
