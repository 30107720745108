<pm-review #pmReview *ngIf="isViewingPage" (closeEvent)="onClose()">

  <!-- Page Title -->
  <pm-title>{{getPageTitle()}}</pm-title>
  <pm-app-reject-status-header [candidate]="candidate"></pm-app-reject-status-header>
  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages.length > 0 ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div class="upc-header">
            UPC: {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
          </div>
          <div class="attribute-description">
            <p>{{candidateProduct.description}}</p>
            <p>{{getProductInfoString()}}</p>
          </div>
        </div>
      </div>

      <div *ngIf="isNonReplenishItem" class="row pb-3 ml-0" style="padding-top: 32px;">
        <div class="col-md-9 pending-candidates-box">
          <h6 class="ml-2 mt-3">
            Non-replenish item
          </h6>
          <p class="ml-2">
            The supplier set up this UPC as a non-replenishable item in an MRT. This UPC doesn’t have a case and H-E-B will only receive it in the MRT.
          </p>
          <div class="attribute-description">
            <p> MRT: {{mrtParentDescription}} | Case UPC: {{candidateProduct.caseUpc}}</p>
          </div>
        </div>
      </div>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Cost set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('prePrice')">
              {{candidate.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('costLinked')">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('costLink')">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('suggestedRetailPrice')">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!isNonReplenishItem" [label]="'Master list cost'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('masterListCost')">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(candidate)}">{{costService.getMargin(candidate)}}%</a>
                <img *ngIf="costService.isMarginNegativeOrZero(candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell *ngIf="isNonReplenishItem" [label]="'Master list cost'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('masterListCost')">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(candidate)}">{{costService.getMargin(candidate)}}%</a>
                <img *ngIf="costService.isMarginNegativeOrZero(candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('mapRetail')">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Cost set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('masterListCost')">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.unitCost)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Case summary-->
      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('caseDescription')">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="false">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('caseUpc')">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('countryOfOrigin', 'description')">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('vendorProductCode')">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Retail set by buyer-->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Retail set by buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false">
              {{candidate.retailType}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              {{costService.getHebMargin(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false">
              {{costService.getRetailPricing(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{costService.getHebPennyProfit(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('masterPack')">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('masterLength') ||
                                        hasSupplierValueChangedForCandidate('masterWidth') ||
                                        hasSupplierValueChangedForCandidate('masterHeight')">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('vendorTie') || hasSupplierValueChangedForCandidate('vendorTier')">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('itemWeightType')">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('masterWeight')">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('cubeAdjustedFactor')">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('codeDate')">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('maxShelfLife')">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('inboundSpecDays')">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Inner packs'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="false">
              {{candidate.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('innerPack')">
              {{candidate.innerPack }}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('innerLength') ||
                                        hasSupplierValueChangedForCandidate('innerWidth') ||
                                        hasSupplierValueChangedForCandidate('innerWidth')">
              {{candidateUtilService.getInnerDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('innerWeight')">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="isSellable && candidate.warehouseSwitch" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false">
              {{candidate.displayReadyUnit | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.displayReadyUnit">
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('displayReadyUnitOrientation')">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('displayReadyUnitRowsDeep') ||
                          hasSupplierValueChangedForCandidate('displayReadyUnitRowsFacing') ||
                          hasSupplierValueChangedForCandidate('displayReadyUnitRowsHigh')">
              {{candidate.displayReadyUnit ? candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh : 0}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit type'" [canEdit]="false">
              {{candidateUtilService.getDruType(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Display ready units, not showing for non sellable -->

      <!-- Product details from buyer -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('merchandiseType', 'description')">
              {{candidate.merchandiseType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('commodity', 'commodityName')">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('subCommodity', 'displayName')">
              {{candidate.subCommodity?.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>

          </pm-grid-column>

          <pm-grid-column>

            <pm-grid-cell [label]="'PSS department'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('pssDepartment', 'displayName')">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Like item code'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('likeId')">
              {{candidate.likeId}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season & Year'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('seasonYear') || hasBuyerValueChangedForCandidate('season', 'seasonDescription')">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>


            <pm-grid-cell [label]="'Estimated store count'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('numberOfStores')">
              {{candidate.numberOfStores}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Sold by weight'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('weightSwitch')">
              {{candidate.weightSwitch | yesNo}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('warehouseReactionDays')">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('guaranteeToStoreDays')">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Product details from buyer -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('merchandiseType', 'description')">
              {{candidate.merchandiseType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('warehouseReactionDays')">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'PSS department'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('pssDepartment', 'displayName')">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('commodity', 'commodityName')">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('guaranteeToStoreDays')">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('subCommodity', 'displayName')">
              {{candidate.subCommodity?.subCommodityName}}  [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Warehouses -->
      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Warehouses'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Max ship'" [canEdit]="false"
                          [hasChanged]="hasScaValueChangedForCandidate('maxShip')">
              {{candidate.maxShip}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <ng-template ngFor let-warehouse [ngForOf]="candidateProduct.warehouses" let-index="index">

          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Warehouse'" [canEdit]="false" class="warehouse-grid-cell">
                {{warehouse.name}}
                <pm-grid-cell-info>
                  ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                </pm-grid-cell-info>
                <button pButton *ngIf="candidateProduct.warehouses.length !== 1" type="button" label="Remove" class="ui-button-link" (click)="removeWarehouse(index)"></button>
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order unit'" [canEdit]="false">
                {{warehouse.orderUnit?.description}}
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order restriction'" [canEdit]="false">
                {{warehouse.orderRestriction?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <div *ngIf="index !== candidateProduct.warehouses.length - 1" class="grid-divider"></div>
        </ng-template>
      </pm-attribute-grid>
      <div *ngIf="showCasePackInfo()" class="row">
        <div class="col-6">
          <button pButton type="button" label="View additional warehouse options" class="ui-button-link" (click)="openWarehouseDrawer()"></button>
        </div>
      </div>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('brand', 'description')">
              {{candidate.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('subBrand')">
              {{candidateProduct.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('costOwner', 'costOwnerName')">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('contactName') ||
                          hasSupplierValueChangedForCandidate('contactEmail')">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('costOwner', 'topToTopName')">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('vendor', 'description')">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!isNonReplenishItem" [label]="'Warehouse supplier'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('lane', 'name')">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('buyer', 'buyerName')">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('productType')">
              {{getSellableString()}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>

            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('foodStamp')">
              {{candidate.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false"
                          [hasChanged]="hasBuyerValueChangedForCandidate('taxable')">
              {{candidate.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'"
                          [hasChanged]="hasSupplierValueChangedForCandidate('flexibleSpendingAccount')">
              {{candidate.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('packageType', 'description')">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('unitOfMeasure', 'description')">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('retailSize')">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>

            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('productLength') ||
                                        hasSupplierValueChangedForCandidate('productWidth') ||
                                        hasSupplierValueChangedForCandidate('productHeight')">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'"
                          [hasChanged]="hasSupplierValueChangedForCandidate('productWeight')">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('totalVolume')">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Qualifying conditions'" [canEdit]="false">
              {{candidateProduct?.qualifyingCondition?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Tax category'" [canEdit]="false">
              {{candidate.vertexTaxCategory}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line & Packaging -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product line & Packaging'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('packageType', 'description')">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('unitOfMeasure', 'description')">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Qualifying conditions'" [canEdit]="false">
              {{candidateProduct?.qualifyingCondition?.description}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('productLength') ||
                                        hasSupplierValueChangedForCandidate('productWidth') ||
                                        hasSupplierValueChangedForCandidate('productHeight')">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('productWeight')">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidate('totalVolume')">
              {{candidate.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'"
                          [hasChanged]="hasSupplierValueChangedForCandidate('retailSize')">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Import Info  -->
      <pm-attribute-grid *ngIf="!candidateProduct?.imported" [title]="'Imported product'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Imported product'" [canEdit]="false">
              {{candidateProduct?.imported | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Import Info  -->
      <pm-attribute-grid *ngIf="candidateProduct?.imported" [title]="'Imported product'">
        <pm-grid-group>
          <pm-grid-column>

            <pm-grid-cell [label]="'Imported product'" [canEdit]="false">
              {{candidateProduct?.imported | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pickup point'" [canEdit]="false">
              {{candidateProduct?.importInfo?.pickupPoint}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty %'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyPercent}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS1'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Carton marking'" [canEdit]="false">
              {{candidateProduct?.importInfo?.cartonMarking}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Instore date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.inStoreDate | date}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Container size'" [canEdit]="false">
              {{candidateProduct?.importInfo?.containerSize.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Agent %'" [canEdit]="false">
              {{candidateProduct?.importInfo?.agentPercent}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty confirmed'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyConfirmed | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS2'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts2}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product color'" [canEdit]="false">
              {{candidateProduct?.importInfo?.productColor}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'WHS flush date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.whsFlushDate | date}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Inco terms'" [canEdit]="false">
              {{candidateProduct?.importInfo?.incoTerms.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Proraction date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.prorationDate | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty info'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyInfo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS3'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts3}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Freight confirmed'" [canEdit]="false">
              {{candidateProduct?.importInfo?.freightConfirmedDate | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Import factory'" [canEdit]="false">
              {{candidateProduct?.importInfo?.factory.factoryName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Minimum order'" [canEdit]="false">
              {{candidateProduct?.importInfo?.minimumOrderQty}}, {{candidateProduct?.importInfo?.minimumOrderDesc}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!-- Unit Details -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('upc')">
              {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('description')">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('customerFriendlyDescription1')">
              {{candidateProduct.customerFriendlyDescription1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('customerFriendlyDescription2')">
              {{candidateProduct.customerFriendlyDescription2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('romanceCopy')">
              {{candidateProduct.romanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Unit Details -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('upc')">
              {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false"
                          [hasChanged]="hasSupplierValueChangedForCandidateProduct('description')">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <div *ngIf="candidateUtilService.isInProgressCandidate(candidate)">
          <!--   Global Attributes   -->
          <div>
            <pm-attribute-grid [title]="'Extended attributes: Product'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid [title]="'Extended attributes: UPC'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="candidate?.warehouseSwitch" [title]="'Extended attributes: Case'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>

          <!--   Mat Hierarchy attributes   -->
          <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(candidate)?.length" [title]="'Category attributes'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>
          <div *ngIf="!!supplierProductService.hierarchyNumberToAttributesMap?.size">
            <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
              <pm-attribute-grid *ngIf="supplierProductService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>

        </div>
        <div *ngIf="!candidateUtilService.isInProgressCandidate(candidate)">
          <!--  Global Attributes    -->
          <div>
            <pm-attribute-grid  *ngIf="!!supplierProductService.productAttributes?.length" [title]="'Extended attributes: Product'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid  *ngIf="!!supplierProductService.upcAttributes?.length" [title]="'Extended attributes: UPC'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="!!supplierProductService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>

          <!--   Mat Hierarchy   -->
          <div *ngIf="!!candidateProduct?.matHierarchyList?.length">
            <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
              <pm-attribute-grid *ngIf="!!hierarchy.attributes?.length" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>
      </div>

      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="false">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="false">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="false"  [hasReviewerComment]="hasReviewerComment()">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>

              <br>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.piaComment">
                <b>{{candidate.piaCommentUser}}</b> <br>
                {{candidate.piaComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back to home" (onClick)="onClickBackToHome()"></p-button>
    <p-button class="m-2" label="Print" (onClick)="onClickPrint()"></p-button>
  </pm-footer>
</pm-review>
