import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {PipesModule} from '../pipes/pipes.module';
import {SupplierDropDownFilterComponent} from './supplier-drop-down-filter.component';
import {DirectiveModule} from '../directives/directive.module';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
  declarations: [SupplierDropDownFilterComponent],
  exports: [SupplierDropDownFilterComponent],
  imports: [CommonModule, DirectiveModule, FormsModule, CheckboxModule, PipesModule, ButtonModule, ScrollingModule]
})
export class SupplierDropDownFilterModule {}
