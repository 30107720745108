import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AttributeConfig,
  AttributeDualInputConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  RetailLink, SubCommodity, Task, TaskDecision, TextInputType,
  WorkflowState
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {forkJoin, Observable} from 'rxjs';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {InputState, ReviewComponent} from 'pm-components';
import {LookupService} from '../../service/lookup.service';
import {GrowlService} from '../../growl/growl.service';
import {CostService} from '../../service/cost.service';
import {WorkflowService} from '../../service/workflow.service';
import {ProcessVariables} from 'pm-models/lib/processVariables';

@Component({
  selector: 'app-buyer-bulk-review',
  templateUrl: './buyer-bulk-review.component.html',
  styleUrls: ['./buyer-bulk-review.component.scss']
})
export class BuyerBulkReviewComponent implements OnInit {
  @ViewChild(ReviewComponent) pmReview;

  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public UPC = 'UPC';
  public ITEM_CODE = 'Item Code';
  public RETAIL_LINK_NUMBER = 'Link Number';
  public retailLinkState: InputState;

  originalCandidates: Candidate[];
  candidatesInDrawer: Candidate[];
  candidatesInTable: Candidate[];
  candidateErrors: Map<any, any> = new Map();
  workflowState: string;
  candidateSubscription$: any;
  isViewingPage: boolean;
  candidateProductIndex: number;

  public merchandiseTypes: any = [];
  public commodities: any;
  public subCommodities: any;
  public candidateError: CandidateError = new CandidateError();
  public pssDepartments: any;
  public candidateProductError: CandidateProductError;


  public isApproveDisabled = false;
  public merchandiseType: any = null;
  public commodity: any = null;
  public subCommodity: any = null;
  public pssDepartment: any = null;
  public inboundSpecDays: number = null;
  public warehouseReactionDays: number = null;
  public guaranteeToStoreDays: number = null;
  public retailType: any = null;
  public retailLink: RetailLink = null;
  public retailXFor: any = null;
  public retailPrice: any = null;
  public likeId: any = null;
  public numberOfStores: any = null;
  public season: any = null;
  public seasonYear: any = null;
  public weightSwitch: any = null;
  public rushFlag: any = null;
  public remark1: any = null;
  public remark2: any = null;
  public isDsd: boolean;
  public isWarehouse: boolean;

  public tempCommodity: Commodity;

  constructor(public route: ActivatedRoute, public candidateService: CandidateService, public router: Router,
              public lookupService: LookupService, public growlService: GrowlService, public costService: CostService,
              public candidateUtilService: CandidateUtilService, private workflowService: WorkflowService) {
  }

  ngOnInit() {
    this.isViewingPage = true;
    this.getCandidates().then();
  }

  pssDepartmentConfiguration: AttributeTypeaheadConfig = {
    label: 'PSS Department',
    description: 'Select the point of sale sub-department.',
    isRequired: true,
    isDisabled: () => !this.candidatesInDrawer[0].commodity || this.candidatesInDrawer[0].dsdSwitch === false,
    isReadOnly: () => false,
    name: 'pssDepartmentId',
    displayRef: 'displayName',
    placeholderText: 'Select a PSS Department...',
    idRef: 'pssDepartmentId',
    collections: this.pssDepartments
  };

  pssDepartmentChange(event) {
    this.pssDepartment = event;
    this.changeCandidate(event, 'pssDepartment');
  }

  async getCandidates() {
    const observables: Observable<Candidate>[] = [];
    this.candidateSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (!params.has('toReview')) {
        return;
      }
      for (let x = 0; x < params.getAll('toReview').length; x++) {
        const id: number = +params.getAll('toReview')[x];
        observables.push(this.candidateService.getCandidate(id));
      }
      forkJoin(observables).subscribe( candidates => {
        if (candidates && candidates.length > 0) {
          this.candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(candidates[0]);
        }
        this.isDsd = CandidateUtilService.areAllCandidatesDsdOnly(candidates);
        this.isWarehouse = CandidateUtilService.areAllCandidatesWarehouseOnly(candidates);

        this.originalCandidates = candidates;
        this.candidatesInDrawer = candidates.slice();
        this.candidatesInTable = candidates.slice();
        this.guaranteeToStoreDaysConfiguration.isHidden = () => !this.candidatesInTable[0].codeDate;
        this.reactionDaysConfiguration.isHidden = () => !this.candidatesInTable[0].codeDate;
        this.inboundSpecConfiguration.isHidden = () => !this.candidatesInTable[0].codeDate;

        if (!this.originalCandidates[0].codeDate || this.originalCandidates[0].dsdSwitch) {
          this.workflowState = WorkflowState.BUYER_NEW_PRODUCT_NO_CODE_DATE_REVIEW;
        } else {
          this.workflowState = WorkflowState.BUYER_NEW_PRODUCT_REVIEW;
        }
        this.defaultSelectCommodity();
        this.findMerchandiseTypesAndSetDefault();
        this.setCommoditiesAndSubCommodities(candidates[0].buyer.buyerId);
        this.merchandiseType = candidates[0]?.merchandiseType ? candidates[0]?.merchandiseType : '';
        this.pssDepartment = candidates[0]?.pssDepartment ? candidates[0]?.pssDepartment : '';
        if (!this.pssDepartmentConfiguration.collections || this.pssDepartmentConfiguration.collections.length === 0) {
          this.getPssDepartments();
        }
        this.pmReview.openDrawer();
      });
    });
  }

  onClose() {
    this.isViewingPage = false;
    // save only the candidate data in the tables, not the drawer data.
    this.candidateService.saveAllCandidates(this.candidatesInTable).subscribe(() => {
      this.router.navigate(['/tasks']).then();
    });
  }

  getToReviewSize() {
    return this.candidatesInDrawer ? this.candidatesInDrawer.length : '0';
  }

  changeCandidate(event, fieldName: string) {
    this.candidatesInDrawer.forEach(candidate => {
      candidate[fieldName] = event;
    });
  }

  merchandiseTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Merchandise type',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'description',
    placeholderText: 'Select a merchandise type...',
    collections: this.merchandiseTypes
  };

  merchandiseTypeChange(event) {
    this.merchandiseType = event;
    this.changeCandidate(event, 'merchandiseType');
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    this.lookupService.findAllMerchandiseTypes('ITMCD', true).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      this.merchandiseTypeConfiguration.collections = merchandiseTypes;
    });
  }

  commodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Commodity',
    description: 'Choose an appropriate category.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'commodityId',
    displayRef: 'commodityName',
    placeholderText: 'Select a commodity...',
    collections: this.commodities
  };

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);

        this.changeCandidate(commodity, 'commodity');
        // Initialize pssDepartment to the default pss department from commodity
        this.changeCandidate(commodity.pssDepartment, 'pssDepartment');

      } else {
        this.subCommodities = [];
        this.subCommodityConfiguration.collections = this.subCommodities;
      }
    });
  }

  changeCandidateProduct(event, fieldName: string) {
    this.candidatesInDrawer.forEach(candidate => {
      candidate.candidateProducts[0][fieldName] = event;
    });
  }

  commodityChange(event, panel, target) {
    if (this.isDsdOnlyParms() && event && (event.departmentId !== this.candidatesInTable[0]?.commodity?.departmentId ||
        event.subDepartmentId !== this.candidatesInTable[0]?.commodity?.subDepartmentId)) {
      this.tempCommodity = event;
      panel.show(event, target);

    } else {

      this.commodity = event;
      this.changeCandidate(event, 'commodity');
      this.changeCandidate(null, 'subCommodity');
      this.updateLocationGroupStores(event);
      this.getSubCommodities(event);

      // TODO: Cost link validation on API
      // if (costLink.commodity !== Number(toValidate.commodity.commodityId)) {
      //   return 'Cost Link not valid for this Commodity. Proceed without a Cost Link or send candidate back to Supplier to request' +
      //     ' a valid Cost Link for the selected Commodity.';
      // }
      if (this.commodity) {
        this.pssDepartment = this.commodity?.pssDepartment;
        this.changeCandidate(this.pssDepartment, 'pssDepartment');
      }
    }
  }

  isDsdOnlyParms(): boolean {
    return this.candidatesInTable && this.candidatesInTable[0].dsdSwitch && !this.candidatesInTable[0].warehouseSwitch;
  }

  updateLocationGroupStores(commodityEvent) {
    if (!commodityEvent) {
      this.changeCandidateProduct([], 'locationGroupStores');
    } else {
      this.candidatesInDrawer.forEach(candidate => {
        if (candidate?.commodity !== commodityEvent?.commodity) {
          candidate.candidateProducts[this.candidateProductIndex].locationGroupStores = [];
        }
      });
    }
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
    this.subCommodityConfiguration.collections = this.subCommodities;
  }

  getPssDepartments() {
    this.candidatesInDrawer.forEach(candidate => {
      if (candidate.overrideDepartment) {
        if (candidate.overrideSubDepartment?.pssDepartments === null || candidate.overrideSubDepartment?.pssDepartments === undefined) {
          this.lookupService.findSubDepartment(candidate.overrideSubDepartment?.departmentId + candidate.overrideSubDepartment?.subDepartmentId)
            .subscribe((subDepartment) => {
              this.pssDepartments = subDepartment[0].pssDepartments;
              this.pssDepartmentConfiguration.collections = this.pssDepartments;
            });
        } else {
          this.pssDepartments = candidate.overrideSubDepartment?.pssDepartments;
          this.pssDepartmentConfiguration.collections = this.pssDepartments;
        }
      } else {
        if (candidate.commodity && candidate.commodity.subDepartment == null) {
          // Make call to get sub department with pss departments when commodity does not have department info.
          this.lookupService.findSubDepartment(candidate.commodity.departmentId + candidate.commodity.subDepartmentId).subscribe((subDepartment) => {
            this.pssDepartments = subDepartment[0].pssDepartments;
            this.pssDepartmentConfiguration.collections = this.pssDepartments;
          });
        } else {
          this.pssDepartments = candidate.commodity.subDepartment.pssDepartments;
          this.pssDepartmentConfiguration.collections = this.pssDepartments;
        }
      }
    });
  }

  subCommodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-commodity',
    description: 'Choose an appropriate subcategory.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'subcommodityId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-commodity...',
    collections: this.subCommodities
  };

  subCommodityChange(event) {
    this.subCommodity = event;
    this.changeCandidate(event, 'subCommodity');
    this.changeCandidate(CandidateHelper.isFoodStamp(this.candidatesInDrawer[0]), 'foodStamp');
    this.changeCandidate(CandidateHelper.isTaxCode(this.candidatesInDrawer[0]), 'taxable');
  }

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'Verify the value provided by the supplier or enter a value if empty.',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer,
    isHidden: () => true
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  pricingTypeConfiguration: AttributeConfig = {
    label: 'Pricing type',
    description: '',
    isDisabled: () => !this.candidateUtilService.isSellable(this.candidatesInDrawer[0]),
    isReadOnly: () => !this.candidateUtilService.isSellable(this.candidatesInDrawer[0]),
    isRequired: true,
    inputGroupClass: 'attribute-radios-block',
    options: [
      { label: 'Key retail', value: this.KEY_RETAIL },
      { label: 'Retail link', value: this.RETAIL_LINK },
      { label: 'Price required', value: this.PRICE_REQUIRED }
    ],
  };

  pricingTypeChange(event) {
    this.retailType = event;
    this.retailLink = new RetailLink();
    this.retailLink.upcType = this.UPC;
    this.changeCandidate(event, 'retailType');

    if (this.candidateUtilService.isRetailLink(this.candidatesInDrawer[0])) {
      this.retailXFor = '';
      this.retailPrice = '';
      this.changeCandidate(this.retailLink, 'retailLink');
      this.changeCandidate('', 'retailXFor');
      this.changeCandidate('', 'retailPrice');
      this.retailLinkConfiguration.placeholderText = ''; // temp fix for bug that doesn't update retail component.
    } else {
      this.changeCandidate(null, 'retailLink');
      if (this.candidateUtilService.isKeyRetail(this.candidatesInDrawer[0])) {
        this.retailXFor = '';
        this.retailPrice = '';
        this.changeCandidate('', 'retailXFor');
        this.changeCandidate('', 'retailPrice');
      } else if (this.candidateUtilService.isPriceRequired(this.candidatesInDrawer[0])) {
        this.retailXFor = 1;
        this.retailPrice = 0.00;
        this.changeCandidate(1, 'retailXFor');
        this.changeCandidate(0.00, 'retailPrice');
      }
    }
  }

  onRetailLinkTypeChange(event) {
    if (this.retailLink) {
      this.retailLink.upcType = event;
      this.changeCandidate(this.retailLink, 'retailLink');
    }
  }

  validateRetailLink() {
    this.retailLinkState = InputState.loading;
    this.candidateError.retailLink = null;
    this.candidateService.validateCandidate(this.candidatesInDrawer[0], [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).subscribe((candidate) => {
      this.retailLinkState = InputState.valid;

      this.retailLink = candidate.retailLink;
      this.retailXFor = candidate.retailXFor;
      this.retailPrice = candidate.retailPrice;
      this.changeCandidate(candidate.retailLink, 'retailLink');
      this.changeCandidate(candidate.retailXFor, 'retailXFor');
      this.changeCandidate(candidate.retailPrice, 'retailPrice');

      this.candidateError.retailLink = null;
    }, (error) => {
      this.retailLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.candidateError.retailLink = error.error.candidateErrors.retailLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  onRetailLinkNumberChange(event) {
    this.retailLink.searchedId = event.model;
    this.retailLink.upcType = event.category;
    this.changeCandidate(this.retailLink, 'retailLink');
  }

  xForConfiguration: AttributeDualInputConfig = {
    collections: [],
    description: '',
    label: 'Retail pricing',
    name: '',
    options: [],
    isRequired: true,
    input1DecimalCount: 0,
    input1NumberCount: 3,
    input1Placeholder: '#',
    input2DecimalCount: 2,
    input2Placeholder: '$',
    input2NumberCount: 9,
    separatingText: 'for',
    isDisabled: () => this.isRetailPriceDisabled(),
    isReadOnly: () => this.isRetailPriceDisabled(),
  };

  retailLinkConfiguration: AttributeConfig = {
    options: [
      { value: this.RETAIL_LINK_NUMBER, label: 'Link #' },
      { value: this.UPC, label: 'UPC' },
      { value: this.ITEM_CODE, label: 'Item Code' }
    ],
    label: 'Retail link',
    description: 'Inherit retail price from another product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => !this.candidateUtilService.isRetailLink(this.candidatesInDrawer[0]),
    isRequired: true,
    defaultValue: this.UPC
  };

  isRetailPriceDisabled() {
    return this.candidateUtilService.isRetailLink(this.candidatesInDrawer[0]) || this.candidateUtilService.isPriceRequired(this.candidatesInDrawer[0]);
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidatesInDrawer[0].commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidatesInDrawer[0].commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  pennyProfitConfiguration: AttributeTextInputConfig = {
    label: 'H-E-B Penny profit',
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `(total)`,
    textInputType: TextInputType.text,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 2
  };

  marginPercentConfiguration: AttributeTextInputConfig = {
    label: 'H-E-B Margin %',
    isDisabled: () => false,
    isReadOnly: () => true,
    placeholderText: `(total)`,
    textInputType: TextInputType.text,
    inputGroupClass: 'ui-calculated-text-input',
    numberCount: 7,
    decimalCount: 4
  };

  likeItemCodeConfiguration: AttributeTextInputConfig = {
    label: 'Like item code',
    description: 'Enter an existing item code to use during forecasting.',
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    textInputType: TextInputType.integer
  };

  estimatedStoreCountConfiguration: AttributeTextInputConfig = {
    label: 'Estimated store count',
    description: 'Estimated number of stores that will carry this product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    name: '',
    textInputType: TextInputType.integer
  };

  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };

  seasonChange(event) {
    this.season = event;
    this.changeCandidate(event, 'season');
  }

  yearConfiguration: AttributeTextInputConfig = {
    label: 'Year',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };

  soldByWeightConfiguration: AttributeConfig = {
    label: 'Sold by weight',
    description: `Select if the item is sold by the pound.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    defaultValue: false
  };

  soldByWeightChange(event) {
    this.changeCandidate(event.checked, 'weightSwitch');
  }

  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  rushFlagConfiguration: AttributeConfig = {
    label: 'Rush review of this candidate',
    description: 'Send this Candidate to the top of other reviewer\'s queue.',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'rushFlagId',
    defaultValue: false
  };

  onClickApprove() {
    this.isApproveDisabled = true;

    // port drawer data on to candidate(s)
    this.setRequiredFields();
    this.setNotRequiredFields();

    this.candidateService.validateBulkCandidate(this.candidatesInDrawer, [CandidateValidatorType.BUYER_PRODUCT_REVIEW_VALIDATOR])
      .subscribe((validationResponse) => {
        this.candidateErrors = new Map();
        this.candidateError = new CandidateError();

        // add any candidate errors to the map.
        for (const key of Object.keys(validationResponse)) {
          if (validationResponse[key]?.candidateError) {
            this.candidateErrors.set(+key, validationResponse[key]?.candidateError);
          }
        }
        // if there's errors
        if (this.candidateErrors.size > 0) {
          this.candidateError = this.candidateErrors.values().next().value;
          this.isApproveDisabled = false;
        } else {
          let workflowStateToMove;
          if (this.isDsdOnlyParms()) {
            workflowStateToMove = Task.PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID;
          } else {
            workflowStateToMove = Task.WAREHOUSE_DETAILS_ACTIVITY_ID;
          }
          const processVariables: ProcessVariables[] = [];
          this.candidatesInTable.forEach(candidate => {
            processVariables.push({
              candidateId: candidate.candidateId, apNumber: candidate.vendor?.apNumber ? candidate.vendor.apNumber : null, moveToWorkflowState: workflowStateToMove
            });
          });
          this.candidateService.saveAllCandidates(this.candidatesInTable).subscribe(() => {
            this.workflowService.updateCandidateTasksStates(processVariables).subscribe(() => {
              this.router.navigate(['/tasks']).then();
            });
          });
        }

      }, (error) => {
        this.isApproveDisabled = false;
        if (error && error.message) {
          this.growlService.addError(error.message);
        } else {
          this.growlService.addError(error);
        }
      });
  }

  setRequiredFields() {
    this.candidatesInTable.forEach(candidate => {
      candidate.merchandiseType = this.merchandiseType;
      candidate.commodity = this.commodity;
      candidate.subCommodity = this.subCommodity;
      candidate.pssDepartment = this.pssDepartment;
      // field is required, so always set to true.
      // candidate['codeDate'] = true; // todo:
      candidate.inboundSpecDays = this.inboundSpecDays;
      candidate.warehouseReactionDays = this.warehouseReactionDays;
      candidate.guaranteeToStoreDays = this.guaranteeToStoreDays;
      candidate.retailType = this.retailType;
      candidate.retailLink = this.retailLink;

      candidate.retailXFor = this.retailXFor;
      candidate.retailPrice = this.retailPrice;
      candidate.retailXFor = this.retailXFor;
    });
  }

  setNotRequiredFields() {
    this.candidatesInTable.forEach(candidate => {
      candidate.likeId = this.likeId;
      candidate.numberOfStores = this.numberOfStores;
      candidate.season = this.season;
      candidate.seasonYear = this.seasonYear;
      candidate.inboundSpecDays = this.inboundSpecDays;
      candidate.weightSwitch = this.weightSwitch;
      candidate.guaranteeToStoreDays = this.guaranteeToStoreDays;
      candidate.rushFlag = this.rushFlag;
      candidate.candidateProducts[0].remark1 = this.remark1;
      candidate.candidateProducts[0].remark2 = this.remark2;
    });
  }

  defaultSelectCommodity() {
    let defaultCommodityId: string = null;
    let defaultCommodity: Commodity = null;
    let hasDifferentCommodity: boolean = false;
    this.candidatesInTable.forEach(candidate => {
      if (defaultCommodityId === null && candidate?.commodity?.commodityId) {
        defaultCommodityId = candidate?.commodity?.commodityId;
        defaultCommodity = candidate?.commodity;
      }
      if (defaultCommodityId !== candidate?.commodity?.commodityId) {
        hasDifferentCommodity = true;
        return; // breaking for loop
      }
    });
    if (!hasDifferentCommodity) {
      this.commodity = defaultCommodity;
      this.defaultSelectSubCommodity();
    }
  }

  defaultSelectSubCommodity() {
    let defaultSubCommodity: SubCommodity = null;
    let defaultSubCommodityId: string = null;
    let hasDifferentCommodity: boolean = false;
    this.candidatesInTable.forEach(candidate => {
      if (defaultSubCommodityId === null && candidate?.subCommodity?.subCommodityId) {
        defaultSubCommodity = candidate?.subCommodity;
        defaultSubCommodityId = candidate?.subCommodity?.subCommodityId;
      }
      if (defaultSubCommodityId !== candidate?.subCommodity?.subCommodityId) {
        hasDifferentCommodity = true;
        return;
      }
    });
    if (!hasDifferentCommodity) {
      this.subCommodity = defaultSubCommodity;
    }
  }

  showPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(target);
  }

  cancelStoreAuth(storeGroupsChangeOverlay) {
    this.tempCommodity = null;
    storeGroupsChangeOverlay.hide();
    this.commodity = JSON.parse(JSON.stringify(this.originalCandidates[0].commodity));
  }

  changeDsdCommodity() {
    this.commodity = this.tempCommodity;
    this.subCommodity = null;
    this.originalCandidates[0].candidateProducts[0].locationGroupStores = [];
    if (this.commodity) {
      this.pssDepartment = this.commodity.pssDepartment;
      this.changeCandidate(this.commodity, 'commodity');
      this.changeCandidate(this.commodity.pssDepartment, 'pssDepartment');
      // port drawer data on to candidate(s)
      this.setRequiredFields();
      this.setNotRequiredFields();
    }
    this.saveAndCompleteTaskAndRouteToTasksPage();
  }

  /**
   * Saves the current state of the candidates, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage() {
    const processVariables: ProcessVariables[] = [];
    this.candidatesInTable.forEach(candidate => {
      processVariables.push({
        candidateId: candidate.candidateId, apNumber: candidate.vendor?.apNumber ? candidate.vendor.apNumber : null, moveToWorkflowState: Task.VENDOR_DETAILS_ACTIVITY_ID
      });
    });
    this.candidateService.saveAllCandidates(this.candidatesInTable).subscribe(() => {
      this.workflowService.updateCandidateTasksStates(processVariables).subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: 'Successfully sent candidate back to supplier.' } }).then(() => {
          this.isApproveDisabled = false;
        });
      }, (error) => {
        this.growlService.addError(error);
      });
    }, (error) => {
      this.growlService.addError(error);
    });
  }
}
