import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {DistributionChannelComponent} from './distribution-channel.component';


@NgModule({
  declarations: [DistributionChannelComponent],
  exports: [DistributionChannelComponent, AttributeDisplayModule],
  imports: [
    CommonModule, AttributeDisplayModule
  ]
})
export class DistributionChannelModule { }
