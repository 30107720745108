import {Component, OnInit} from '@angular/core';
import {PreferencesService} from '../service/preferences.service';
import {Router} from '@angular/router';
import {LookupService} from '../service/lookup.service';
import {AuthService} from '../auth/auth.service';
import {EmailMessage} from 'pm-models';

@Component({
  selector: 'app-need-assistance',
  templateUrl: './need-assistance.component.html',
  styleUrls: ['./need-assistance.component.scss']
})
export class NeedAssistanceComponent implements OnInit {
  public isViewingPage = true;

  dsdNewItemSetupUrl = '/candidates/training_guides/PAM-Supplier-DSD-New-Item-Guide-v2.pdf';
  authorizeAnItemDSDUrl = '/candidates/training_guides/PAM-Supplier-Authorize-a-Supplier-(DSD-Only)-Guide.pdf';
  casePackSetupUrl = '/candidates/training_guides/PAM-Supplier-Case-Pack-Guide.pdf';
  bonusItemSetupUrl = '/candidates/training_guides/PAM-Supplier-Bonus-New-Item-Guide.pdf';
  replacementItemSetupUrl = '/candidates/training_guides/PAM-Supplier-Warehouse-Replacement-Item-Guide.pdf';
  associateItemSetupUrl = '/candidates/training_guides/PAM-Supplier-Associate-UPC-Guide.pdf';
  upcBarcodeFormatGuideUrl = '/candidates/training_guides/UPC-and-Barcode-Format-Guidelines.pdf';
  globalContentStandardsUrl = '/candidates/training_guides/Global-Content-Standards-Suppliers.pdf';
  OwnBrandUpcReservationVTAurl = '/candidates/training_guides/Own-Brand-UPC-Reservation-VTA.pdf';

  constructor(private lookupService: LookupService,
              private authService: AuthService,
              private preferencesService: PreferencesService,
              private router: Router) {
  }

  public feedbackText: string;
  public rating = 0;
  public wantContact: boolean;


  ngOnInit() {
    this.feedbackText = '';
    this.wantContact = false;
  }


  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']);
  }

  sendFeedback() {
    this.lookupService.sendEmail(this.getEmail()).subscribe();
    this.router.navigate(['/tasks']);
  }

  getEmailBody(): string {
    return this.feedbackText + '\n' +
      'Rating: ' + this.rating + '\n' +
      'The user has indicated they would like to be contacted: '
      + (this.wantContact === true ? 'Yes' : 'No');
  }

  getEmail(): EmailMessage {
    return new EmailMessage(
      'pamteam@heb.com',
      'PAMTEAM',
      this.authService.getUserEmail(),
      '',
      'PAM Feedback',
      this.getEmailBody(),
      '',
      '', );
  }
}
