import {NgZone, Pipe, PipeTransform} from '@angular/core';
import {Observable, Observer} from 'rxjs';

/**
 * This pipe transforms a date string into a 'time elapsed' string, that includes days, hours, and minutes, but
 * only if the given time's elapsed time until now contains these fields (only includes days if more than 1 day has
 * elapsed).
 *
 * How to use:
 * Example: <html>{{dateToFormat | timeAgo}} will show something like '4 hrs, 56 min'
 *
 * This was modified from https://github.com/AndrewPoyntz/time-ago-pipe/issues/6
 * author: Asger Juul Brunshøj
 *
 * This was an update to the original 'TimeAgoPipe' from the mentioned Git. There was an issue with angular processing
 * the time in this pipe before angular 'checked for updates', which presented an error:
 * 'Expression has changed after it was checked'. This update uses an observer instead of direct manipulation to the
 * passed in date.
 */
@Pipe({
  name: 'totalTime',
  pure: true
})
export class TotalTimePipe implements PipeTransform {

  constructor(private ngZone: NgZone) { }

  private static MILLISECONDS_IN_ONE_SECOND = 1000;
  private static MILLISECONDS_IN_ONE_MINUTE = 60000;
  private static SECONDS_TO_MINUTES = 60;
  private static SECONDS_TO_HOURS = 3600;
  private static MINUTES_IN_ONE_HOUR = 60;
  private static HOURS_TEXT = ' hours';
  private static MINUTES_TEXT = ' mins';
  private static COMMA_SEPARATOR_TEXT = ', ';
  private static EMPTY_STRING_TEXT = '';

  /**
   * Process the given timestamp (d.getTime()) into a user friendly version of elapsed time. If more than 1 day has
   * elapsed, include 'days'; if more than one hour has elapsed, include 'hours'; always show minutes.
   *
   * @param {number} timestamp Timestamp of given date.
   * @returns {string} User friendly elapsed time.
   */
  private static process(timestamp: number): string {
    let textToDisplay: string = TotalTimePipe.EMPTY_STRING_TEXT;

    // Time ago in seconds
    const timeAgoSeconds: number = timestamp / TotalTimePipe.MILLISECONDS_IN_ONE_SECOND;

    // Minutes in time ago (capped at 60)
    const minutes = Math.floor(timeAgoSeconds / TotalTimePipe.SECONDS_TO_MINUTES %  TotalTimePipe.MINUTES_IN_ONE_HOUR);

    // Hours in time ago
    const hours = Math.floor(timeAgoSeconds / TotalTimePipe.SECONDS_TO_HOURS);

    let previousValue = false;

    // add hours to return text if there are any
    if (hours > 0) {
      if (previousValue) {
        textToDisplay = textToDisplay.concat(TotalTimePipe.COMMA_SEPARATOR_TEXT);
      }
      textToDisplay = textToDisplay.concat(hours + TotalTimePipe.HOURS_TEXT);
      previousValue = true;
    }

    // add minutes to return text
    if (previousValue) {
      textToDisplay = textToDisplay.concat(TotalTimePipe.COMMA_SEPARATOR_TEXT);
      textToDisplay = textToDisplay.concat(minutes + TotalTimePipe.MINUTES_TEXT);
    } else {
      textToDisplay = textToDisplay.concat(minutes + TotalTimePipe.MINUTES_TEXT);
    }

    return textToDisplay;
  }

  public transform (total: number | Date): Observable<string> {
    if (typeof total === 'undefined') {
      return null;
    }
    let d: Date;
    if (total instanceof Date) {
      d = total;
    } else {
      d = new Date(total);
    }
    // time value in milliseconds
    const timestamp = d.getTime();

    let timeoutID;

    return new Observable((observer: Observer<string>) => {
      let latestText = '';

      // Repeatedly set new timeouts for new update checks.
      const registerUpdate = () => {
        const processOutput = TotalTimePipe.process(timestamp);
        if (processOutput !== latestText) {
          latestText = processOutput;
          this.ngZone.run(() => {
            observer.next(latestText);
          });
        }
        timeoutID = setTimeout(registerUpdate, TotalTimePipe.MILLISECONDS_IN_ONE_MINUTE);
      };

      this.ngZone.runOutsideAngular(registerUpdate);

      // Return teardown function
      return () => {
        if (timeoutID) {
          clearTimeout(timeoutID);
        }
      };
    });
  }

}

