import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {GrowlService} from '../../growl/growl.service';
import {
  AttachmentsCardPermissions,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  CaseDetailsCardPermissions,
  Commodity,
  CostCardPermissions,
  CostLinkCardPermissions,
  DisplayReadyUnitCardPermissions,
  ImportedProductCardPermissions,
  InnerPackDetailsCardPermissions,
  MasterPackDetailsCardPermissions,
  OmiRemarksCardPermissions
} from 'pm-models';
import {InputState, UPCInputState} from 'pm-components';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {PiaMrtInnerStepperComponent} from '../pia-mrt-inner-stepper/pia-mrt-inner-stepper.component';
import {ItemWeightTypeService} from '../../service/item-weight-type.service';

@Component({
  selector: 'app-pia-inner-case-details',
  templateUrl: './pia-inner-case-details.component.html',
  styleUrls: ['./pia-inner-case-details.component.scss']
})
export class PiaInnerCaseDetailsComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private lookupService: LookupService, private growlService: GrowlService,
              public candidateUtilService: CandidateUtilService, private itemWeightTypeService: ItemWeightTypeService) { }

  private taskSubscription$: any;
  public innerCandidateError: CandidateError;
  public innerCandidateProductError: CandidateProductError;
  public upcState: UPCInputState;
  private commodities: Commodity[] = undefined;
  public costLinkState: InputState;
  public canClickNext = true;
  public retailLinkState: InputState;
  public containerSizes: any;
  public incoTermsList: any;

  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions;

  readonly masterPackDetailsCardPermissions: MasterPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly innerPackDetailsCardPermissions: InnerPackDetailsCardPermissions = {
    isReadOnly: false,
  };

  readonly importProductCardPermissions: ImportedProductCardPermissions = {
    isReadOnly: false
  };
  readonly displayReadyUnitCardPermissions: DisplayReadyUnitCardPermissions = {
    isReadOnly: false
  };
  readonly costLinkCardPermissions: CostLinkCardPermissions = {
    isReadOnly: false
  };
  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false
  };
  readonly omiRemarksPermissions: OmiRemarksCardPermissions = {
    isReadOnly: false,
  };
  readonly attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };

  getAdditionalCasePackBasicsCardPermissions(): CaseDetailsCardPermissions {
    return {
      isReadOnly: false,
      itemWeightType: {
        isReadOnly: false,
        isDisabled: !this.isTiedToCatchOrVariableWeightBuyer()
      },
      distributionChannel: {
        isHidden: true
      },
      buyer: {
        isHidden: true
      },
      dealOffered: {
        isHidden: true
      }
    };
  }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {

        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId()  ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          // if the candidate is an mrt, and contains the inner set the inner candidate.
          if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).subscribe((innerCandidate) => {
              this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
              this.initializeData();
            });
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierMrtService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.innerCandidateError = this.supplierMrtService.getInnerCaseDetailsError(this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.innerCandidateError) {
      this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
        this.supplierMrtService.getInnerCandidateProduct(0).id];
    }
    if (this.supplierMrtService.getInnerCandidate().buyer && this.supplierMrtService.getInnerCandidate().buyer.buyerId) {
      this.lookupService.findAllCommoditiesByBuyerId(this.supplierMrtService.getInnerCandidate().buyer.buyerId).subscribe(data => {
        this.commodities = data;
      });
    }
    this.lookupService.findAllContainerSizes().subscribe(containerSizes => {
      this.containerSizes = containerSizes;
    });
    this.lookupService.findAllIncoTerms().subscribe(incoTermsList => {
      this.incoTermsList = incoTermsList;
    });
    this.setMrtInnerCandidates();
    this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
  }


  setMrtInnerCandidates() {
    if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners &&
      this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
        if (!this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate) {
          this.candidateService.getCandidate(this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidateId)
            .subscribe((candidate) => {
              this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate = candidate;
            });
        }
      }
    }
  }

  onClose() {
    this.supplierMrtService.onInnerClose();
  }


  validateUpc() {
    this.upcState = UPCInputState.loading;
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(),
      [CandidateValidatorType.CASE_UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.innerCandidateProductError.caseUpc = undefined;
      this.supplierMrtService.setInnerCaseDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
        JSON.parse(JSON.stringify(this.innerCandidateError)));
    }, (error) => {
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const candidateProductId = this.supplierMrtService.getInnerCandidateProduct(0).id;
        const candidateErrors: CandidateError = error.error.candidateErrors;
        const candidateProductError: CandidateProductError =
          candidateErrors.candidateProductErrors[candidateProductId];

        if (!this.innerCandidateError) {
          this.innerCandidateError = this.supplierMrtService.setInnerCaseDetailsError(
            this.supplierMrtService.getInnerCandidate().candidateId, candidateErrors);
          this.innerCandidateProductError = candidateProductError;
          if (this.innerCandidateProductError.caseUpc ||  this.innerCandidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.upcState = UPCInputState.valid;
          }
        } else if (this.innerCandidateError.candidateProductErrors.size === 0 ||
          !this.innerCandidateError.candidateProductErrors[candidateProductId]) {

          this.innerCandidateError.candidateProductErrors.set(candidateProductId,
            candidateProductError);
          this.innerCandidateError = this.supplierMrtService.setInnerCaseDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
            candidateErrors);
          this.innerCandidateProductError = candidateProductError;
          if (this.innerCandidateProductError.caseUpc ||  this.innerCandidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.upcState = UPCInputState.valid;
          }
        } else {
          this.innerCandidateError.itemWeightType = candidateErrors.itemWeightType;
          this.innerCandidateProductError.caseUpc = candidateProductError.caseUpc;
          this.innerCandidateProductError.caseUpcCheckDigit = candidateProductError.caseUpcCheckDigit;
          if (this.innerCandidateProductError.caseUpc ||  this.innerCandidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.upcState = UPCInputState.valid;
          }
        }
      } else {
        this.upcState = UPCInputState.invalid;
        this.growlService.addError(error.message);
      }
    });
  }

  innerPackQuantityChange() {
    if (this.innerCandidateProductError && this.innerCandidateProductError.caseUpc) {
      this.innerCandidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
  }


  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.supplierMrtService.getInnerCandidate().costLinkFromServer = undefined;
    if (this.innerCandidateError) {
      this.innerCandidateError.costLink = undefined;
    }
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(),
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((candidate) => {
      this.costLinkState = InputState.valid;
      this.supplierMrtService.getInnerCandidate().costLinkFromServer = candidate.costLinkFromServer;
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        if (this.innerCandidateError) {
          this.innerCandidateError.costLink = error.error.candidateErrors.costLink;
        } else {
          this.innerCandidateError = this.supplierMrtService.setInnerCaseDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
            error.error.candidateErrors);
        }
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  onClickBack() {
    this.jumpBack('/piaAddNewMrtCase');
  }

  onClickNext() {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(),
      [CandidateValidatorType.PIA_MRT_INNER_CASE_DETAILS_VALIDATOR]).toPromise().then(data => {
      this.innerCandidateError = this.supplierMrtService.setInnerCaseDetailsError(
        this.supplierMrtService.getInnerCandidate().candidateId,
        new CandidateError());
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaMrtInnerWarehouseDetails',
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
            candidateId: this.supplierMrtService.getInnerCandidate().candidateId}},
        this.hasVendorChanged());
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.innerCandidateError = this.supplierMrtService.setInnerCaseDetailsError(
          this.supplierMrtService.getInnerCandidate().candidateId, error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
      }
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/piaMrtInnerWarehouseDetails',
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
            candidateId: this.supplierMrtService.getInnerCandidate().candidateId}},
        this.hasVendorChanged(), true);
    });
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.supplierMrtService.getOriginalCandidate().vendor) !==
      JSON.stringify(this.supplierMrtService.getCandidate().vendor);
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaMrtInnerStepperComponent.SUPPLIER_HEB_SETUP: {
        this.jumpBack('/piaSupplierAndHebDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.PRODUCT_DETAILS: {
        this.jumpBack('/piaInnerProductDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.CASE_PACK: {
        this.onClickBack();
        break;
      }
      case PiaMrtInnerStepperComponent.WAREHOUSE: {
        this.jumpToPage('/piaMrtInnerWarehouseDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/piaMrtInnerExtendedAttributes');
        break;
      }
    }
  }

  jumpToPage(url: string) {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), [CandidateValidatorType.PIA_MRT_INNER_CASE_DETAILS_VALIDATOR]).subscribe(data => {
      this.innerCandidateError = this.supplierMrtService.setInnerCaseDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
        new CandidateError());
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(), candidateId:
            this.supplierMrtService.getInnerCandidate().candidateId}}, false);
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.innerCandidateError = this.supplierMrtService.setInnerCaseDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
          error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
          {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId}}, false, true);
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  jumpBack(urlToNavigate) {
    this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), urlToNavigate,
      {
        queryParams: {
          taskId: this.supplierMrtService.getTaskIdString(),
          candidateId: this.supplierMrtService.getInnerCandidate().candidateId
        }
      }, false, this.supplierMrtService.hasInnerChanged());
  }

  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.supplierMrtService.getInnerCandidate()?.commodity && this.supplierMrtService.getInnerCandidate().commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.supplierMrtService.getInnerCandidate().commodity?.departmentId);
    } else {
      return false;
    }
  }
}
