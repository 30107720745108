import {AttributeConfig, ScaleInformation} from 'pm-models';
import {BaseAttribute} from '../base-attribute';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayToggleComponent} from '../../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';

export class ForceTare implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Force tare',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: false,
      defaultValue: false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, scaleInformation: ScaleInformation, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (scaleInformation.forceTare === undefined) {
      scaleInformation.forceTare = this.defaultVal;
    }
    componentInstance.model = scaleInformation.forceTare;
    componentInstance.change.subscribe(x => {
      scaleInformation.forceTare = x.checked;
    });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, scaleInformation: ScaleInformation) {
    if (componentInstance.model !== scaleInformation.forceTare) {
      componentInstance.model = scaleInformation.forceTare;
    }
  }
}
