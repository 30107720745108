export enum AttributeTypes {
  BoxesOnPallet,
  Brand,
  Buyer,
  CaseUPC,
  CaseDescription,
  ProductDescription,
  Channel,
  CodeDate,
  Commodity,
  ContactEmail,
  ContactName,
  CostLinked,
  CostLinkedItem,
  CostOwner,
  CountryOfOrigin,
  Dimensions,
  DisplayReadyUnit,
  DisplayReadyUnitType,
  ECommCopy,
  EstimatedStoreCount,
  FoodStampEligible,
  FSAEligible,
  GuaranteeDays,
  InboundSpecDays,
  InnerPackSelected,
  InnerPackQuantity,
  InnerPackDimensions,
  InnerPackWeight,
  ItemWeightType,
  LikeItemCode,
  MapRetail,
  MasterListCost,
  MaxShelfLife,
  MaxShip,
  MerchandiseType,
  OrderFactor,
  Orientation,
  PackageTypes,
  PrePrice,
  ProductDimensions,
  ProductImages,
  ProductUPC,
  ProductWeight,
  Receipt,
  ReactionDays,
  RetailLink,
  RetailPricing,
  RetailSize,
  RetailType,
  RetailUnitCount,
  Season,
  SeasonYear,
  Sellable,
  ShelfTag1,
  ShelfTag2,
  SoldByWeight,
  SubBrand,
  SubCommodity,
  SuggestedRetail,
  Taxable,
  TotalRetail,
  TotalVolume,
  TopToTop,
  UnitOfMeasure,
  Vendor,
  VendorProductCode,
  Warehouse,
  WarehouseMaxShip,
  WarehouseOrderRestriction,
  WarehouseOrderUnit,
  Weight,
  QualifyingCondition,
  PssDepartment,
  VertexTaxCategory,
  SubDepartment,
  DepositUpc,
  IngredientStatement,
  NutritionStatementNumber,
  PrePackTare,
  ShelfLifeDays,
  EnglishLabelOne,
  EnglishLabelTwo,
  EnglishLabelThree,
  EnglishLabelFour,
  BilingualLabels,
  EnglishLabelOneBilingual,
  EnglishLabelTwoBilingual,
  SpanishLabelOne,
  SpanishLabelTwo,
  LabelFormatOne,
  LabelFormatTwo,
  ActionCode,
  ServiceCounterTare,
  MechanicallyTenderized,
  GraphicsCodeOne,
  GraphicsCodeTwo,
  GraphicsCodeThree,
  GradeNumber,
  NetWeight,
  ForceTare,
  AssociateUpc,
  LabelInsightsImages,
  Remark1,
  Remark2,
  Plu,
  ShowCalories,
  MatAttributeDatePicker,
  MatAttributeTextInput,
  MatAttributeToggle,
  MatAttributeBooleanRadioInput,
  MatAttributeTypeahead,
  CategorySelection
}
