import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NewUpcRequest, UpcRequestType} from 'pm-models/lib/newUpcRequest';
import {Observable} from 'rxjs';
import {Candidate, Vendor} from 'pm-models';
import {CandidateSearchParams} from 'pm-models/lib/candidateSearchParams';
import {calculateCheckDigit} from '../shared/upc.utils';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class OwnBrandService {

  private ownBrandServiceBaseUrl = '/ownBrand';

  constructor(private http: HttpClient) {}

  /**
   * Reserves a upc.
   * @deprecated since 1.96.0.
   *
   * @param newUpcRequest
   */
  public reserveUpc(newUpcRequest: NewUpcRequest) {
    return this.http.post<any>(this.ownBrandServiceBaseUrl + '/reserveUpc', newUpcRequest);
  }

  /**
   * Reserves 41220s.
   * @deprecated since 1.96.0
   *
   * @param newUpcRequest
   */
  public reserve41220Upcs(newUpcRequest: NewUpcRequest): Observable<number[]> {
    return this.http.post<any>(this.ownBrandServiceBaseUrl + '/reserveUpcs/41220', newUpcRequest);
  }

  public releaseUpc(upcId: number, upcRequestType: UpcRequestType): Observable<any> {
    const params = new HttpParams().append('upcRequestType', UpcRequestType[upcRequestType]);
    const returnthis = this.http.post<any>(this.ownBrandServiceBaseUrl + '/unreserveUpc', upcId, {params: params});
    return returnthis;
  }

  public updateReservation(candidate: Candidate): Observable<any> {
    return this.http.post<any>(this.ownBrandServiceBaseUrl + '/updatePreDigitFourUpc', candidate);
  }

  public activateFreightTaxUpc(type: string, candidate: any) {
    return this.http.post<any>(this.ownBrandServiceBaseUrl + '/activatePreDigitFourUpc?type=' + type.toUpperCase(), candidate);
  }

  public generate04NonSellableUPC() {
    return this.http.get<any>(this.ownBrandServiceBaseUrl + '/generateNonSellableUPC');
  }

  public checkReservedUpc(upc: string): Observable<any> {
    return this.http.get<any>(this.ownBrandServiceBaseUrl + '/checkReservedUpc/' + upc);
  }

  /**
   * Returns all 41220 UPCs.
   *
   * @deprecated since 1.96.0
   *
   * @param candidateSearchParams candidateSearchParams.
   * @return all 41220 UPCs, filtered by ap.
   */
  public findAll41220s(candidateSearchParams: CandidateSearchParams): Observable<any> {
    return this.http.post<any>(this.ownBrandServiceBaseUrl + '/findAll/41220', candidateSearchParams);
  }

  /**
   * Returns all vendors (whs/dsd/both) by page number and page size.
   */
  public findAllOwnBrandUpcReservationVendors(): Observable<Vendor[]> {
    return this.http.get<any>(this.ownBrandServiceBaseUrl + '/findAllVendors');
  }

  /**
   * Returns all Own Brand UPCs.
   **
   * @param candidateSearchParams candidateSearchParams.
   * @return all Own Brand UPCs.
   */
  public findAllOwnBrandUpcs(candidateSearchParams: CandidateSearchParams): Observable<any> {
    return this.http.post<any>(this.ownBrandServiceBaseUrl + '/findAll/ownBrandUpcs', candidateSearchParams);
  }

  /**
   * Reserves OwnBrand UPCs.
   *
   * @param newUpcRequest
   */
  public reserveUpcs(newUpcRequest: NewUpcRequest): Observable<number[]> {
    return this.http.post<any>(this.ownBrandServiceBaseUrl + '/reserveUpcs', newUpcRequest);
  }


  /**
   * Releases a current plu and fetches a new plu.
   * @param pluToRelease
   * @param pluTypeToRelease
   * @param newUpcRequest
   */
  releaseAndFetchNewPlu(pluToRelease, pluTypeToRelease: UpcRequestType, newUpcRequest: NewUpcRequest): Observable<number> {
    return this.releaseUpc(pluToRelease, pluTypeToRelease).pipe(
      switchMap(() => this.reserveUpcs(newUpcRequest)),
      map(plus => plus[0])
    );
  }
}
