import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {
  AttributeAreaConfig,
  AttributeTextInputConfig,
  AttributeVolumeConfig,
  Candidate,
  CandidateError,
  MasterPackDetailsCardErrors,
  MasterPackDetailsCardModel,
  MasterPackDetailsCardPermissions,
  TextInputType
} from 'pm-models';

import {
  DisabledPermissionsMapper,
  ReadOnlyPermissionsMapper,
  VisiblePermissionsMapper
} from '../attribute-permissions-mapper';
import {CostService} from '../../../../../../src/app/2.0.0/service/cost.service';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';

@Component({
  selector: 'pm-master-pack-details-card',
  templateUrl: './master-pack-details-card.component.html',
  styleUrls: ['./master-pack-details-card.component.scss']
})
export class MasterPackDetailsCardComponent implements OnInit {
  @Input()
  model: MasterPackDetailsCardModel | Candidate;

  @Output() modelChange = new EventEmitter<any>();
  @Output() masterPackChange = new EventEmitter<any>();

  @Input()
  permissions: MasterPackDetailsCardPermissions;

  @Input()
  errorModel: MasterPackDetailsCardErrors | CandidateError;

  constructor(public costService: CostService) {}

  ngOnInit() {}

  totalRetailUnitsConfiguration: AttributeTextInputConfig = {
    label: 'Total retail units',
    description: 'The total number of units in the master pack.',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.masterPack),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.masterPack),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.masterPack),
    textInputType: TextInputType.integer,
    placeholderText: '# Units',
    inputGroupClass: 'ui-narrow-input',
    name: 'totalRetailUnitsId',
    maxLength: 7
  };
  totalRetailChange(event) {
    if (!this.model.innerPackSelected) {
      this.model.innerPack = this.model.masterPack;
    }
    if (this.model && this.model.masterPack) {
      if (this.model.masterPack > CandidateUtilService.OMI_TOTAL_RETAIL_UNITS_MAX && this.model.innerPackSelected) {
        this.model.innerPackSelected = false;
        this.model.innerPack = this.model.masterPack;
        this.model.innerLength = this.model.masterLength;
        this.model.innerWidth = this.model.masterWidth;
        this.model.innerHeight = this.model.masterHeight;
        this.model.innerWeight = this.model.masterWeight;
      }
      this.masterPackChange.emit(this.model.masterPack);
    }
    this.costService.setInnerListCost(<Candidate>this.model);
    this.costService.setUnitCost(<Candidate>this.model);
    this.modelChange.emit(this.model);
  }

  dimensionsConfiguration: AttributeVolumeConfig = {
    label: 'Dimensions',
    description: `Enter the length, width, and height of the master pack in inches. We'll calculate the volume in cubic feet.`,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.masterLength),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.masterLength),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.masterLength),
    name: '',
    isRequired: true,
    numberCount: 7,
    decimalCount: 2
  };

  dimensionChange($event) {
    if (!this.model.innerPackSelected) {
      this.model.innerWidth = this.model.masterWidth;
      this.model.innerLength = this.model.masterLength;
      this.model.innerHeight = this.model.masterHeight;
    }
    this.modelChange.emit(this.model);
  }

  volumeCalc($event) {
    // console.log($event);
  }

  weightConfiguration: AttributeTextInputConfig = {
    label: 'Weight',
    description: 'The weight of one master pack case, in pounds (lbs).',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.masterWeight),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.masterWeight),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.masterWeight),
    isRequired: true,
    decimalCount: 2,
    numberCount: 6,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: 'lbs',
    name: 'weightId'
  };
  masterWeightChange(event) {
    if (!this.model.innerPackSelected) {
      this.model.innerWeight = this.model.masterWeight;
    }
    this.modelChange.emit(this.model);
  }

  orderFactorConfiguration: AttributeTextInputConfig = {
    label: 'Order factor',
    description: `A unit of measure derived from cube, pallets, and/or weight attributes,
    used to calculate how much product will fit in a truckload.`,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.cubeAdjustedFactor),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.cubeAdjustedFactor),
    isRequired: false,
    decimalCount: 4,
    numberCount: 9,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '#',
    name: 'orderFactorId'
  };
  orderFactorChange(event) {}

  boxesOnPalletConfig: AttributeAreaConfig = {
    description: 'On a pallet, tell us how many boxes are on one layer and how many layers there are.',
    label: 'Boxes on pallet',
    isRequired: true,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.vendorTie),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.vendorTie),
    isHidden: () => VisiblePermissionsMapper(this.permissions, this.permissions.vendorTie),
    areaPlaceholder: '(total)',
    lengthPlaceholder: 'Tie',
    widthPlaceholder: 'Tier',
    name: 'boxesPerLayerId'
  };
  onAreaChange(event) {}
  lengthHelpVisible = false;
  lengthHelperOffset = '0px';

  toggleLengthHelp(event, value) {
    if (event && event.srcElement && event.srcElement.offsetTop) {
      this.lengthHelperOffset = event.srcElement.offsetTop + 'px';
    }
    this.lengthHelpVisible = value;
  }

  widthHelpVisible = false;
  widthHelperOffset = '0px';

  toggleWidthHelp(event, value) {
    if (event && event.srcElement && event.srcElement.offsetTop) {
      this.widthHelperOffset = event.srcElement.offsetTop + 'px';
    }
    this.widthHelpVisible = value;
  }
}
