<pm-editor (closeEvent)="onClose()">

  <pm-header>
    <pm-associate-upc-stepper [candidateErrors]="associateUpcService.getAllCandidateErrors()" [stepIndex]="3"
                              (indexClick)="onClickStepper($event)">
    </pm-associate-upc-stepper>
  </pm-header>

  <pm-title>How will you send us this product?</pm-title>

  <div class="row" style="margin-top: 25px;">
  </div>
  <ng-container *ngIf="associateUpcService.candidate">

    <h3>Available cases</h3>
    <h6>Below are the cases for the primary UPC. Select a case that can include this new item.</h6>
    <hr/>

    <ng-template ngFor let-caseUpc [ngForOf]="caseUpcData">
      <div class="attribute-group" *ngIf="!caseUpc?.mrt">
        <div>
          <p-radioButton id="associateCaseSelection"
                         name="associateCaseSelection"
                         [(ngModel)]="selectedCasePack"
                         [value]="caseUpc.caseUpc"
                         (onClick)="selectedCaseChange($event, caseUpc)">
          </p-radioButton>
        </div>
        <div style="margin-bottom: 10px;">
          <div class="attribute-label">
            <label>
              Case Upc: {{caseUpc?.caseUpc}}
            </label>
          </div>
          <p class="attribute-description-label">
            {{caseUpc?.description}}
          </p>
          <p class="attribute-description">
            <!-- Channel will always be WHS for now-->
            Item Code: <mark class="mark-css">{{caseUpc?.itemCode}}</mark>
            Channel: <mark class="mark-css">WHS</mark>
            Master Pack: <mark class="mark-css">{{caseUpc?.supplierItems[0]?.pack}}</mark>
            Inner Pack: <mark class="mark-css">{{caseUpc.innerPackQuantity}}</mark>
            <!-- Status will always be Active for now-->
            Status: <mark class="mark-css">Active</mark>
          </p>
          <p class="attribute-description">
            <!-- All associates of primary plus the primary itself-->
            # UPCs in case: <mark class="mark-css">{{caseUpc?.containedUpc?.associatedUpcs?.length + 1}}</mark>
            <!-- master pack * inner pack-->
            VPC: <mark class="mark-css">{{caseUpc?.supplierItems[0]?.supplierItemId}}</mark>
          </p>
        </div>
      </div>
    </ng-template>
    <div class="row ml-2" *ngIf="candidateProductError?.caseUpc">
      <p class="error-message">{{candidateProductError?.caseUpc}}</p>
    </div>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button *ngxPermissionsExcept="['ROLE_CATEGORY_SELECTION-EDIT']" class="m-2" label="{{getActivateButtonTitle()}}" (onClick)="onClickActivate()" [disabled]="!canNavigate"></p-button>
    <p-button *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>

