import { BaseAttribute } from './base-attribute';
import { AttributeConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayRadioComponent } from '../../shared/attribute-display/attribute-display-radio/attribute-display-radio.component';

export class Orientation implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayRadioComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Orientation',
    description: 'How shoppers can access the products in this display ready unit.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    options: [
      { label: 'Shoppable from only one side', value: 'stock_facing_by_depth' },
      { label: 'Shoppable from at least 2 adjacent sides', value: 'stock_facing_by_depth_or_facing' }
    ],
    inputGroupClass: 'attribute-radios-block',
    defaultValue: 'stock_facing_by_depth',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayRadioComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.displayReadyUnitOrientation;
    componentInstance.modelChange.subscribe(x => {
      candidate.displayReadyUnitOrientation = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayRadioComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.displayReadyUnitOrientation) {
      componentInstance.model = candidate.displayReadyUnitOrientation;
    }
  }
}
