import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate } from 'pm-models';

export class SoldByWeight implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }
  getAttributeConfig(overrides?) {
    return {
      label: 'Sold by weight',
      description: `Select if the item is sold by the pound.`,
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: false,
      defaultValue: false,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (candidate.weightSwitch === undefined) {
      candidate.weightSwitch = this.defaultVal;
    }
    componentInstance.model = candidate.weightSwitch;
    componentInstance.change.subscribe(x => {
      candidate.weightSwitch = x.checked;
   });
  }

  updateModel(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.weightSwitch) {
      componentInstance.model = candidate.weightSwitch;
    }
  }
}
