import {Component, OnInit} from '@angular/core';
import {SupplierProductService} from '../../service/supplier-product.service';
import {
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  LocationGroupStores,
  Store,
  TextInputType
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {CostService} from '../../service/cost.service';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {NewWarehouseProductStepperComponent} from '../new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateErrorUtilService} from '../../service/candidate-error-util.service';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-set-up-stores',
  templateUrl: './set-up-stores.component.html',
  styleUrls: ['./set-up-stores.component.scss']
})
export class SetUpStoresComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public supplierProductService: SupplierProductService,
              public costService: CostService, private candidateService: CandidateService, private workflowService: WorkflowService,
              private candidateUtilService: CandidateUtilService, public candidateErrorUtilService: CandidateErrorUtilService,
              private permissionService: NgxPermissionsService) { }

  public isViewingPage = true;
  public currentCandidateProductIndex;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public canClickSubmit = true;
  public distributorName: any;
  private taskSubscription$: any;

  private validators: CandidateValidatorType[] =
    [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR, CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
      CandidateValidatorType.SUPPLIER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_CASE_PACK_VALIDATOR,
      CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR];


  listCostConfiguration: AttributeTextInputConfig = {
    label: 'List cost',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    textInputType: TextInputType.decimal,
    placeholderText: '$',
    numberCount: 4,
    decimalCount: 2
  };

  ngOnInit() {

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierProductService.getTaskId(),
        this.supplierProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT) {
           this.initializeData();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierProductService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierProductService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.candidateError = this.supplierProductService.getStoreAuthError();
    this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
    this.currentCandidateProductIndex = this.supplierProductService.getCurrentCandidateProductIndex();
    this.distributorName = this.supplierProductService.getCandidate().vendor.description;

    // if there's no auth selected auth groups, initialize the data.
    if (!this.supplierProductService.getSelectedAuthGroups()) {
      // if there's auth groups and no candidate data, set the selected to the auth groups data, else initialize the data
      // prefilled w/ candidate info.
      if (this.supplierProductService.getAuthGroups()) {
        if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());

          this.supplierProductService.openAuthGroups();
        } else {
          this.supplierProductService.setSelectedAuthGroupsFromCandidate();
        }

        // else Fetch auth groups and set SelectedAuthGroups
      } else {
        this.supplierProductService.findAllAuthGroups().subscribe((authGroups) => {

          this.supplierProductService.setAuthGroups(authGroups);

          // if there's no candidate data, set the selected to the auth groups data, else initialize the data
          // prefilled w/ candidate info.
          if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
            this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
            this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());

            this.supplierProductService.openAuthGroups();
          } else {
            this.supplierProductService.setSelectedAuthGroupsFromCandidate();
          }
        });
      }
    }
  }

  onClickNext() {
    const url = !!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT') ? '/extendedAttributes' : '/newProductReviewPageComponent';
    this.jumpTo(url);
  }

  jumpTo(url) {
    // validate the candidate for this page and send errors to components to display, if any
    this.canClickSubmit = false;
    this.applySelectedStoresToCandidate();
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(), this.validators).toPromise().then(data => {
      this.supplierProductService.setStoreAuthError(new CandidateError());
      this.supplierProductService.saveCandidateAndNavigate(url, false).subscribe();
    }, (error) => {
      document.getElementsByClassName('editor-title')[0].scrollIntoView();
      if (error.error.candidateErrors?.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.supplierProductService.getStoreAuthError();
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
      }
      this.supplierProductService.saveCandidateAndNavigate(url, false).subscribe();
    });
  }

  onClickBack() {
    this.applySelectedStoresToCandidate();
    this.supplierProductService.saveCandidateAndNavigate('/casePack', false).subscribe();
  }

  onClickBackToStoreSelection() {
    this.applySelectedStoresToCandidate();
    this.supplierProductService.saveCandidateAndNavigate('/storeAuthorization', false).subscribe();
  }

  onClose() {
    this.isViewingPage = false;
    this.applySelectedStoresToCandidate();
    this.supplierProductService.saveCandidateAndClose();
  }

  onClickAuthGroupCaret(authGroup) {
    authGroup.isViewing = !authGroup.isViewing;
  }

  getSelectedStoresCountString(authGroup) {
    if (!authGroup.stores || authGroup.stores.length === 0) {
      return '';
    }
    let count = 0;
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].checked) {
        count++;
      }
    }
    return count + ' of ' + authGroup.stores.length + ' stores selected.';
  }


  onStoreChecked(event, store, authGroup) {
    const checked = event.checked;

    if (!checked) {
      authGroup.checked = checked;
    } else {
      let areAllStoresChecked = true;
      for (let x = 0; x < authGroup.stores.length; x++) {
        if (!authGroup.stores[x].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        authGroup.checked = true;
      }
    }
  }

  onListCostChange(event, authGroup) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].listCost = event;
    }
  }


  listCostFocus(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }


  onClickApplyToAllGroups(listCost, listCostOverlay) {
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      this.supplierProductService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.supplierProductService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }
    listCostOverlay.hide();
  }

  onClickApplyToAllRemainingGroups(listCost, listCostOverlay) {
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      if (this.supplierProductService.getSelectedAuthGroups()[x].listCost) {
        continue;
      }
      this.supplierProductService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.supplierProductService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }
    listCostOverlay.hide();
  }

  getListCostOverlayStyle(index) {
    if (index === 0) {
      return {margin: '-1.375rem 0 0 16rem'};
    } else {
      return {margin: '-2rem 0 0 16rem'};
    }
  }

  getAllStoreAuthCountsString() {
    if (!this.supplierProductService.getSelectedAuthGroups()) {
      return '';
    }
    let totalCount = 0;
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          totalCount++;
        }
      }
    }
    return '' + totalCount + ' stores selected';
  }

  getStoreAuthCountsString(authGroup: LocationGroupStores) {
    if (!authGroup) {
      return '';
    }
    let storeCount = 0;
    for (let y = 0; y < authGroup.stores.length; y++) {
      if (authGroup.stores[y].checked) {
        storeCount++;
      }
    }
    return  'Group ' + authGroup.splrLocationGroupId + ' | ' +
      storeCount + ' of ' + authGroup.stores.length + ' selected';
  }

  displayShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].isHidden) {
        return true;
      }
    }
    return false;
  }

  onClickShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].isHidden = false;
    }
  }

  /**
   * Sets the selected stores onto the candidate model.
   */
  applySelectedStoresToCandidate() {
    const authGroups: LocationGroupStores[] = [];
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      let authGroup: LocationGroupStores = null;
      const stores: Store[] = [];

      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          stores.push(JSON.parse(JSON.stringify(this.supplierProductService.getSelectedAuthGroups()[x].stores[y])));
        }
      }
      if (stores.length > 0) {
        authGroup =  JSON.parse(JSON.stringify(this.supplierProductService.getSelectedAuthGroups()[x]));
        authGroup.stores = stores;
        authGroups.push(authGroup);
      }
    }
    this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores = authGroups;
  }

  hasListCostError(authGroup) {
    return false;
  }

  getErrorsByAuthGroup(authGroup) {

  }
  hasNoStoresSelected () {
    if (!this.supplierProductService.getSelectedAuthGroups() || this.supplierProductService.getSelectedAuthGroups().length === 0) {
      return true;
    }

    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          return false;
        }
      }
    }
    return true;
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case NewWarehouseProductStepperComponent.UPC_ITEM_CODE:
        this.jumpBack('/setupCandidateType');
        break;
      case NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP:
        this.jumpBack('/supplierDetails');
        break;
      case NewWarehouseProductStepperComponent.PRODUCT_DETAILS:
        this.jumpBack('/basicItemSetup');
        break;
      case NewWarehouseProductStepperComponent.CASE_PACK:
        this.jumpBack('/casePack');
        break;
      case NewWarehouseProductStepperComponent.EXTENDED_ATTRIBUTES:
        this.jumpTo('/extendedAttributes');
        break;
      case NewWarehouseProductStepperComponent.REVIEW_PAGE:
        this.jumpTo('/newProductReviewPageComponent');
        break;
    }
  }

  jumpBack(urlToNavigate) {
      this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).subscribe();
  }
}
