<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>
<ng-template #card>
  <pm-card title="Product category" subtitle="Select the category for this product.">
    <ng-container *ngIf="candidateProductModel">
      <div style="margin-left: 1.5rem; margin-right: 1.5rem;">
        <div *ngIf="isLoadingMatData" class="row" style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}">
          </pm-progress-spinner>
          <h5 class="ml-3">Fetching extended attribute data...</h5>
        </div>
        <div *ngIf="!isLoadingMatData" class="row">
          <pm-category-selection [attribute]="categorySelectionAttribute"
                                 [model]="candidateProductModel?.matHierarchyList"
                                 (selection)="onHierarchySelection($event)"
                                 [attributeError]="candidateProductErrorModel?.categorySelectionError"
                                 style="width: 100%">
          </pm-category-selection>
        </div>
      </div>
    </ng-container>
  </pm-card>
</ng-template>
