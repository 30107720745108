import { BaseAttribute } from '../base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayMuliSourceInputComponent } from '../../../shared/attribute-display/attribute-display-muli-source-input/attribute-display-muli-source-input.component';
import { Candidate, AttributeConfig, CandidateValidatorType } from 'pm-models';
// tslint:disable-next-line:max-line-length
import { InputState } from '../../../shared/attribute-display/attribute-display-muli-source-input/attribute-display-muli-source-input.component';
import {RETAIL_LINK, RETAIL_LINK_NUMBER} from '../../attribute-constants';
export class RetailLink implements BaseAttribute {
  public UPC = 'UPC';
  public ITEM_CODE = 'Item Code';
  public RETAIL_LINK = RETAIL_LINK;
  public RETAIL_LINK_NUMBER = RETAIL_LINK_NUMBER;

  getComponentType() {
    return AttributeDisplayMuliSourceInputComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      options: [
        { value: this.UPC, label: 'UPC' },
        { value: this.ITEM_CODE, label: 'Item Code' },
        { value: this.RETAIL_LINK_NUMBER, label: 'Link #' }
      ],
      label: 'Retail link',
      description: 'Inherit retail price from another product.',
      isDisabled: () => false,
      isReadOnly: () => false,
      // isHidden: () => !this.isRetailLink(),
      isRequired: true,
      //  defaultValue: this.UPC,
      placeholderText: '',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayMuliSourceInputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    this.updateModel(componentInstance, candidate);
    // componentInstance.model = 34343434;
    //    componentInstance.selectCategory = candidate.retailLink.upcType;
    componentInstance.modelChange.subscribe(x => {
      console.log('modelchange');
      candidate.retailLink.searchedId = x.model;
      candidate.retailLink.upcType = x.category;
    });

    componentInstance.selectCategoryChange.subscribe(x => {
      console.log('category change');
      candidate.retailLink.upcType = x;
    });

    componentInstance.validate.subscribe(s => {
      console.log('validating');
      if (configOverrides.validationService) {
        componentInstance.state = InputState.loading;
        componentInstance.attributeError = undefined;

        configOverrides.validationService.validateCandidate(candidate, [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).subscribe(
          response => {
            componentInstance.state = InputState.valid;

            candidate.retailLink = response.retailLink;
            candidate.retailXFor = response.retailXFor;
            candidate.retailPrice = response.retailPrice;

            componentInstance.attributeError = undefined;
          },
          error => {
            componentInstance.state = InputState.invalid;
            if (error.error.candidateErrors) {
              componentInstance.attributeError = error.error.candidateErrors.retailLink;
            }
          }
        );
      }
    });
  }
  updateModel(componentInstance: AttributeDisplayMuliSourceInputComponent, candidate: Candidate) {
    componentInstance.attribute.isHidden = () => candidate.retailType !== this.RETAIL_LINK;
    componentInstance.model = candidate.retailLink && candidate.retailLink.searchedId ? candidate.retailLink.searchedId : undefined;
    componentInstance.selectCategory = candidate?.retailLink?.upcType ? candidate?.retailLink?.upcType : this.UPC;
  }
}
