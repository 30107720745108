import {NgModule} from '@angular/core';
import {DirectiveModule} from '../directives/directive.module';
import {ButtonModule} from 'primeng/button';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ProgressSpinnerModule} from '../../shared/progress-spinner/progress-spinner.module';
import {LabelInsightsImageUploadComponent} from './label-insights-image-upload.component';


@NgModule({
  declarations: [LabelInsightsImageUploadComponent],
  exports: [LabelInsightsImageUploadComponent, ButtonModule, ProgressSpinnerModule],
  imports: [CommonModule, ButtonModule, HttpClientModule, ProgressSpinnerModule, DirectiveModule]
})
export class LabelInsightsImageUploadModule {}
