import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CardModule} from '../../shared/card/card.module';


import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {ImportFactoryCardComponent} from './import-factory-card.component';
import {ImportFactorySearchBarModule} from '../../shared/import-factory-search-bar/import-factory-search-bar.module';


@NgModule({
  declarations: [ImportFactoryCardComponent],
  exports: [ImportFactoryCardComponent, CardModule, AttributeDisplayModule, ImportFactorySearchBarModule],
  imports: [
    CommonModule,
    CardModule,
    AttributeDisplayModule,
    ImportFactorySearchBarModule,
  ]
})
export class ImportFactoryCardModule { }
