<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header></pm-header>

  <!-- Page Titles -->
  <pm-title>Authorize stores</pm-title>
  <pm-sub-title>Set up store authorization for {{distributorName}}.</pm-sub-title>
  <p></p><p></p><p></p>

  <div *ngIf="!showSearchPage">
    <h4>Set up stores</h4>
    <p class="mb-1" style="max-width: 80%;">
      Below are stores you can select, organized by authorization group. If you need to adjust the list cost for a group, you can do so in the Deals Cost Manager (DCM) app after we activate this product.    </p>
    <div class="backToStoreButton mb-4">
      <button pButton type="button" label="Back to store selection" class="ui-button-link" (click)="onClickBackToStoreSelection()"></button>
    </div>

    <div class="row ml-0">
      <ng-template ngFor let-authGroup let-i="index" [ngForOf]="supplierProductService.selectedAuthGroups">

        <div *ngIf="authGroup.stores && authGroup.stores.length > 0" class="col-md-10 authGroupContainer">

          <div class="row headerRow">
            <i *ngIf="authGroup.isViewing" class="pi pi-caret-up" (click)="onClickAuthGroupCaret(authGroup)"></i>
            <i *ngIf="!authGroup.isViewing" class="pi pi-caret-down" (click)="onClickAuthGroupCaret(authGroup)"></i>

            <div>
              <div class="headerText">Group {{authGroup.splrLocationGroupId}}</div>
              <div class="headerSubText">
                {{getSelectedStoresCountString(authGroup)}}
                <button *ngIf="displayShowAllStores(authGroup)" pButton type="button"
                        label="Show all stores in this group" class="ui-button-link pl-1"
                        (click)="onClickShowAllStores(authGroup)">
                </button>
              </div>
            </div>

            <div class="listCostColumn" *ngxPermissionsOnly="['ROLE_DSD_MULT_COST']">

              <div>
                <pm-attribute-display-text-input [attribute]="listCostConfiguration"
                                                 [showErrorMessage]="false"
                                                 [attributeError]="candidateProductError?.locationGroupStoresErrors[authGroup.splrLocationGroupId]?.listCost"
                                                 [(model)]="authGroup.listCost"
                                                 (modelChange)="onListCostChange($event, authGroup)"
                                                 (inputFocus)="listCostFocus($event, listCostOverlay, listCostDiv)"
                                                 (focus)="listCostFocus($event, listCostOverlay, listCostDiv)"
                                                 (focusin)="listCostFocus($event, listCostOverlay, listCostDiv)">
                </pm-attribute-display-text-input>
                <div #listCostDiv></div>
                <pm-static-overlay-panel #listCostOverlay appendTo="body" [style]="getListCostOverlayStyle(i)">

                  <div class="listCostOverlayWrapper">
                    <div class="listCostOverlay">
                      <div class="row m-0">
                        <img src="/assets/images/bulk-quick-action-bolt.svg">
                        <button pButton type="button" label="Apply to all groups"
                                class="ui-button-link"
                                (click)="onClickApplyToAllGroups($event, authGroup.listCost, listCostOverlay)"></button>
                      </div>
                      <button *ngIf="i !== 0" pButton type="button" label="Apply to all remaining groups"
                              class="ui-button-link remainGroupsButton"
                              (click)="onClickApplyToAllRemainingGroups($event, authGroup.listCost, listCostOverlay)">

                      </button>

                    </div>
                  </div>
                </pm-static-overlay-panel>
                <div *ngIf="candidateProductError?.locationGroupStoresErrors && candidateProductError?.locationGroupStoresErrors[authGroup.splrLocationGroupId]?.listCost">
                  <p class="error-message">
                    {{candidateProductError?.locationGroupStoresErrors[authGroup.splrLocationGroupId]?.listCost}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="authGroup.isViewing" class="authGroupBody">
            <div class="row ml-0 mr-0">
              <div class="checkboxColumn">
                <p-checkbox [(ngModel)]="authGroup.checked"
                            (onChange)="supplierProductService.onAuthGroupChecked($event, authGroup)"
                            binary="true">
                </p-checkbox>
              </div>
              <div class="col-md-2 columnHeader">
                Store #
              </div>
              <div class="col-md-7 columnHeader">
                Name
              </div>
              <div class="col-md-2 columnHeader" style="padding-left: 2.25rem;" *ngxPermissionsOnly="['ROLE_DSD_MULT_COST']">
                List cost
              </div>
            </div>
            <ng-template ngFor let-store let-x="index" [ngForOf]="authGroup.stores">

              <div class="row ml-0 mr-2" *ngIf="!store.isHidden" [ngClass]="{'error-row' : candidateErrorUtilService.has706StoreError(candidateProductError, authGroup.splrLocationGroupId) && store.custLocationNumber === 706}">
                <div class="checkboxColumn">
                  <p-checkbox [(ngModel)]="store.checked"
                              (onChange)="onStoreChecked($event, store, authGroup)"
                              binary="true">
                  </p-checkbox>
                </div>
                <div class="col-md-2 columnCell" [ngClass]="{'disabledCell': !store.checked}">
                  {{store.custLocationNumber}}
                </div>
                <div class="col-md-7 columnCell" [ngClass]="{'disabledCell': !store.checked}">
                  {{store.locationName}}
                </div>
                <div class="col-md-2 columnCell" style="padding-left: 2.25rem;" [ngClass]="{'disabledCell': !store.checked}">
                  {{costService.toCurrency(store.listCost)}}
                </div>
              </div>
              <p *ngIf="!store.isHidden && candidateErrorUtilService.has706StoreError(candidateProductError, authGroup.splrLocationGroupId) && store.custLocationNumber === 706" class="error-message">
                {{candidateErrorUtilService.get706StoreError(candidateProductError, authGroup.splrLocationGroupId)}}
              </p>
            </ng-template>

          </div>
        </div>
      </ng-template>
    </div>

    <div class="authStoresSummary">
      <p class="totalStoreSelected">{{getAllStoreAuthCountsString()}}</p>
      <ng-template ngFor let-authGroup let-i="index" [ngForOf]="supplierProductService.selectedAuthGroups">
        <p *ngIf="authGroup.stores && authGroup.stores.length > 0" class="authStoresSelected">
          {{getStoreAuthCountsString(authGroup)}}
        </p>
      </ng-template>
    </div>
  </div>

  <div *ngIf="showSearchPage">
    <div style="width: 95%;">
      <pm-no-stores-message-extended *ngIf="showNoStoresError"
                                     [candidate]="supplierProductService.candidate">
      </pm-no-stores-message-extended>
    </div>
    <div *ngIf="showNoStoresError === false">
      <h4>Find stores</h4>
      <p style="max-width: 70%;">Find stores by selecting one or more authorization groups, entering a list of store numbers, or searching for stores by region or county.</p>

      <ng-container *ngIf="supplierProductService.getCandidate()">
        <p-selectButton class="authGroupFilter" [options]="types" [(ngModel)]="selectedType" (onChange)="onFilterChange()"></p-selectButton>

        <div class="mt-4 mb-3" style="font-weight: bold;" *ngIf="selectedType === 'stores'">
          <p>Stores</p>
          <div class="row storeGroup">
            <div class="col-md-6">
              <pm-attribute-display-text-input [attributeError]="storesErrorMessage"
                                               (modelChange)="storesInputChangeEvent($event)"
                                               [attribute]="storesInputConfiguration"
                                               [(model)]="storesInput">
              </pm-attribute-display-text-input>
              <p-button class="ghost m-0"
                        label="Show all of my stores"
                        (onClick)="onClickShowAllStores2()">
              </p-button>
              <div style="display: inline-block;" pTooltip="{{validateStoresInput(storesInput) ? 'Enter at least one store.' : ''}}">
                <button pButton type="button"
                        class="ui-button-info ml-2"
                        label="Show these stores"
                        (click)="onClickShowTheseStores()"
                        [disabled]="validateStoresInput(storesInput)">
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 mb-3" style="font-weight: bold;" *ngIf="selectedType === 'groups'">
          <p>Authorization groups</p>
          <div *ngIf="supplierProductService.authGroups">
            <div class="row ml-0">
              <div class="col-md-4 authGroupHeader authGroupCheckboxGroup">
                <pm-attribute-display-checkbox class="pl-0"
                                               [attribute]="allGroupsConfiguration"
                                               [(model)]="selectAllAuthGroups"
                                               (change)="onClickAllAuthGroupCheckbox($event)">
                </pm-attribute-display-checkbox>
              </div>
            </div>
            <div class="row ml-0">
              <div class="col-md-4 authGroupBody authGroupCheckboxGroup">
                <ng-template ngFor let-authGroup let-i="index" [ngForOf]="supplierProductService.authGroups">

                  <pm-attribute-display-checkbox *ngIf="authGroup.stores && authGroup.stores.length > 0" class="pl-0"
                                                 [attribute]="authGroup.configuration"
                                                 [(model)]="authGroup.checked"
                                                 (change)="onAuthGroupButtonClick(i, $event)">
                  </pm-attribute-display-checkbox>
                </ng-template>
              </div>
            </div>
            <div class="row ml-0">
              <div class="col-md-4 mt-3 pr-0 text-right" pTooltip="{{!hasSelectedGroup() ? 'Select at least one group.' : ''}}">
                <button pButton type="button" class="ui-button-info" label="Show stores"
                        [disabled]="!hasSelectedGroup()"
                        (click)="onClickGroupShowStores()">
                </button>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </div>

  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Cancel" (onClick)="onClickCancel()"></p-button>
    <p-button class="m-2" label="Save" (onClick)="onClickSave()" [disabled]="!canClickSave || hasNoStoresSelected()"
              pTooltip="{{hasNoStoresSelected() ? 'Select at least one store.' : ''}}"></p-button>
  </pm-footer>
</pm-editor>
