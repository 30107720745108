import { BaseAttribute } from '../base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';
import { Warehouse } from 'pm-models';

export class WarehouseMaxShip implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Max ship',
      description: 'The maximum # of cases of this product that a store can receive.',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: false,
      textInputType: TextInputType.integer,
      inputGroupClass: 'ui-narrow-input',
      placeholderText: '# of cases',
      maxLength: 5,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Warehouse, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.maxShip;
    componentInstance.modelChange.subscribe(x => {
      candidate.maxShip = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Warehouse) {
    if (componentInstance.model !== candidate.maxShip) {
      componentInstance.model = candidate.maxShip;
    }
  }
}
