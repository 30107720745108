import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-radio',
  templateUrl: './attribute-display-radio.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-radio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayRadioComponent extends BaseAttributeDisplayComponent implements OnInit {
  _model;

  @Input()
  get model() {
    return this._model;
  }
  set model(val) {
    if ((val === undefined || val === null) && this.attribute && this.attribute.defaultValue !== undefined) {
      val = this.attribute.defaultValue;
    }
    this._model = val;
    this.modelChange.emit(this._model);
  }

  constructor() {
    super();
  }

  isOptionDisabled(option) {
    return (this.attribute.isReadOnly && this.attribute.isReadOnly()) ||
      (this.attribute.isDisabled && this.attribute.isDisabled()) ||
      (option.disabled && option.disabled());
  }

  ngOnInit() {
  }

  onChange(event) {
    this.change.emit(event);
  }
}
