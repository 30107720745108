import {Component, OnInit} from '@angular/core';
import {
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  LocationGroupStores,
  Store,
  TaskDecision,
  TextInputType
} from 'pm-models';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CostService} from '../../service/cost.service';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateErrorUtilService} from '../../service/candidate-error-util.service';

@Component({
  selector: 'app-set-up-invited-distributor-stores',
  templateUrl: './set-up-invited-distributor-stores.component.html',
  styleUrls: ['./set-up-invited-distributor-stores.component.scss']
})
export class SetUpInvitedDistributorStoresComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public invitedDistributorService: InvitedDistributorService,
              private costService: CostService, private candidateService: CandidateService, private growlService: GrowlService,
              private workflowService: WorkflowService, private candidateUtilService: CandidateUtilService,
              private candidateErrorUtilService: CandidateErrorUtilService) { }

  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public canClickSubmit = true;
  public noStoresSelected = true;
  public distributorName: any;
  private taskSubscription$: any;
  public keepStoresSelected = false;


  listCostConfiguration: AttributeTextInputConfig = {
    label: 'List cost',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    textInputType: TextInputType.decimal,
    placeholderText: '$',
    numberCount: 4,
    decimalCount: 2
  };

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.invitedDistributorService.getTaskId(),
        this.invitedDistributorService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.invitedDistributorService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.ADDITIONAL_DISTRIBUTOR ||
            candidate.candidateType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR) {
            this.candidateError = this.invitedDistributorService.getStoreAuthError();
            this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
            this.initializeData();
            this.invitedDistributorService.initializeProductData().subscribe();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.invitedDistributorService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.invitedDistributorService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.distributorName = this.invitedDistributorService.getCandidate().vendor.description;

    // if there's no auth selected auth groups, initialize the data.
    if (!this.invitedDistributorService.getSelectedAuthGroups()) {
      // if there's auth groups and no candidate data, set the selected to the auth groups data, else initialize the data
      // prefilled w/ candidate info.
      if (this.invitedDistributorService.getAuthGroups()) {
        if (!this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.invitedDistributorService.setSelectedAuthGroups(this.invitedDistributorService.getAuthGroups());

          this.invitedDistributorService.openAuthGroups();
        } else {
          this.invitedDistributorService.setSelectedAuthGroupsFromCandidate();
        }

        // else Fetch auth groups and set SelectedAuthGroups
      } else {
        this.invitedDistributorService.findAllAuthGroups().subscribe((authGroups) => {

          this.invitedDistributorService.setAuthGroups(authGroups);

          // if there's no candidate data, set the selected to the auth groups data, else initialize the data
          // prefilled w/ candidate info.
          if (!this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores ||
            this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
            this.invitedDistributorService.setSelectedAuthGroups(this.invitedDistributorService.getAuthGroups());

            this.invitedDistributorService.openAuthGroups();
          } else {
            this.invitedDistributorService.setSelectedAuthGroupsFromCandidate();
          }
        });
      }
    }
  }

  onClose() {
    this.applySelectedStoresToCandidate();
    this.invitedDistributorService.saveCandidateAndNavigate('/tasks', false);
  }


  /**
   * Sets the selected stores onto the candidate model.
   */
  applySelectedStoresToCandidate() {
    const authGroups: LocationGroupStores[] = [];
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      let authGroup: LocationGroupStores = null;
      const stores: Store[] = [];

      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked) {
          stores.push(JSON.parse(JSON.stringify(this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y])));
        }
      }
      if (stores.length > 0) {
        authGroup =  JSON.parse(JSON.stringify(this.invitedDistributorService.getSelectedAuthGroups()[x]));
        authGroup.stores = stores;
        authGroups.push(authGroup);
      }
    }
    this.invitedDistributorService.getCandidate().candidateProducts[0].locationGroupStores = authGroups;
  }

  onClickSubmit() {
    // validate the candidate for this page and send errors to components to display, if any
    this.canClickSubmit = false;
    this.applySelectedStoresToCandidate();
    this.invitedDistributorService.saveCandidate();

    this.candidateService.validateCandidate(this.invitedDistributorService.getCandidate(), [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR,
      CandidateValidatorType.INVITED_DISTRIBUTOR_CASE_DETAILS_VALIDATOR]).toPromise().then(data => {

      this.candidateService.findDistributorCandidatesByUpc(this.invitedDistributorService.candidate.candidateProducts[0].upc, Candidate.IN_PROGRESS).subscribe(
        (candidates: Candidate[]) => {
          if (!candidates || candidates.length === 0) {
            this.growlService.addError('Unable to locate distributor candidates.');
            this.canClickSubmit = true;
            return;
          }
          this.createOrUpdateBuyerTask(candidates);
        });
    }, (error) => {
      this.invitedDistributorService.scrollToTop();
      if (error.error.candidateErrors.hasErrors) {
        this.invitedDistributorService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.invitedDistributorService.getStoreAuthError();
        this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canClickSubmit = true;
    });

  }

  /**
   * If there's buyer task associated with the parent that invited this candidate, update that task with this candidate id to
   * be processed together and set the task to be waiting, to be updated by reject/activate actions.
   * If there's not a buyer task, move this to the buyer.
   * @param candidates
   */
  createOrUpdateBuyerTask(candidates: Candidate[]) {
    const idString = candidates.map(candidate => candidate.candidateId).join(',');
    this.workflowService.findDsdInvitedSupplierTaskInBuyer(idString).subscribe((tasks) => {
      // if there's no invited candidate in the buyer phase, then we can just move this to the buyer.
      if (!tasks || tasks.length === 0) {
        this.invitedDistributorService.saveAndCompleteTaskAndRouteToUrlWithCandidateId(WorkflowService.ACTION_COMPLETE,
          TaskDecision.INVITED_DISTRIBUTOR_VENDOR_CONTINUE, '/tasks',
          this.invitedDistributorService.getCandidate().candidateId);
      } else {
        let activeTask = null;
        for (const inProgressCandidates of candidates) {
          // if the candidate is the same as the current, ignore. Or if the buyer is different (we want to find one with the same to merge
          // tasks, or create a new one.
          if (inProgressCandidates.candidateId === this.invitedDistributorService.getCandidate().candidateId ||
            (inProgressCandidates.buyer?.buyerId !== this.invitedDistributorService.candidate.buyer.buyerId)) {
            continue;
          }
          // check if candidate id is a current buyer task.
          const activeTasks = tasks.filter(task => task.candidateId === inProgressCandidates.candidateId);
          if (activeTasks && activeTasks.length > 0) {
            activeTask = activeTasks[0];
            break;
          }
        }
        // if there's an active task tied to the buyer, combine the candidates, and set current task to waiting
        // (so buyer doesn't get another task).
        if (activeTask) {
          this.candidateService.addInvitedCandidateToCandidate(this.invitedDistributorService.candidate, activeTask.candidateId)
            .subscribe(() => {
              this.workflowService.updateCandidateId(activeTask.candidateId, activeTask.processInstanceId).subscribe(() => {
                this.invitedDistributorService.saveAndCompleteTaskAndRouteToUrlWithCandidateId(WorkflowService.ACTION_COMPLETE,
                  TaskDecision.INVITED_DISTRIBUTOR_VENDOR_WAITING, '/tasks', this.invitedDistributorService.getCandidate().candidateId);
              });
            });
          // if there's not a current task for the upc, with the same buyer, create a new buyer task.
        } else {
          this.invitedDistributorService.saveAndCompleteTaskAndRouteToUrlWithCandidateId(WorkflowService.ACTION_COMPLETE,
            TaskDecision.INVITED_DISTRIBUTOR_VENDOR_CONTINUE, '/tasks',
            this.invitedDistributorService.getCandidate().candidateId);
        }
      }
    });
  }

  onClickBack() {
    this.applySelectedStoresToCandidate();
    this.invitedDistributorService.saveCandidateAndNavigate('/invitedDistributorCaseDetails', false);
  }

  onClickBackToStoreSelection() {
    this.applySelectedStoresToCandidate();
    this.invitedDistributorService.saveCandidateAndNavigate('/invitedDistributorStoreAuthorization', false);
  }

  onClickAuthGroupCaret(authGroup) {
    authGroup.isViewing = !authGroup.isViewing;
  }

  getSelectedStoresCountString(authGroup) {
    if (!authGroup.stores || authGroup.stores.length === 0) {
      return '';
    }
    let count = 0;
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].checked) {
        count++;
      }
    }
    return count + ' of ' + authGroup.stores.length + ' stores selected.';
  }


  onStoreChecked(event, store, authGroup) {
    const checked = event.checked;

    if (!checked) {
      authGroup.checked = checked;
    } else {
      let areAllStoresChecked = true;
      for (let x = 0; x < authGroup.stores.length; x++) {
        if (!authGroup.stores[x].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        authGroup.checked = true;
      }
    }
  }

  onListCostChange(event, authGroup) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].listCost = event;
    }
  }


  listCostFocus(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }


  onClickApplyToAllGroups(listCost, listCostOverlay) {
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      this.invitedDistributorService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }
    listCostOverlay.hide();
  }

  onClickApplyToAllRemainingGroups(listCost, listCostOverlay) {
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      if (this.invitedDistributorService.getSelectedAuthGroups()[x].listCost) {
        continue;
      }
      this.invitedDistributorService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }
    listCostOverlay.hide();
  }

  getListCostOverlayStyle(index) {
    if (index === 0) {
      return {margin: '-1.375rem 0 0 16rem'};
    } else {
      return {margin: '-2rem 0 0 16rem'};
    }
  }

  getAllStoreAuthCountsString() {
    if (!this.invitedDistributorService.getSelectedAuthGroups()) {
      return '';
    }
    let totalCount = 0;
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked) {
          totalCount++;
        }
      }
    }
    return '' + totalCount + ' stores selected';
  }

  getStoreAuthCountsString(authGroup: LocationGroupStores) {
    if (!authGroup) {
      return '';
    }
    let storeCount = 0;
    for (let y = 0; y < authGroup.stores.length; y++) {
      if (authGroup.stores[y].checked) {
        storeCount++;
      }
    }
    return  'Group ' + authGroup.splrLocationGroupId + ' | ' +
      storeCount + ' of ' + authGroup.stores.length + ' selected';
  }

  displayShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].isHidden) {
        return true;
      }
    }
    return false;
  }

  onClickShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].isHidden = false;
    }
  }


  hasNoStoresSelected () {
    if (!this.invitedDistributorService.getSelectedAuthGroups() || this.invitedDistributorService.getSelectedAuthGroups().length === 0) {
      return true;
    }

    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked) {
          return false;
        }
      }
    }
    return true;
  }

  hasStoreConflict(custLocationNumber) {
    if (!this.invitedDistributorService.productData || !this.invitedDistributorService.productData.dsdItems ||
      this.invitedDistributorService.productData.dsdItems.length === 0) {
      return false;
    }

    for (let x = 0; x < this.invitedDistributorService.productData.dsdItems.length; x++) {
      if (this.invitedDistributorService.productData.dsdItems[x].upc !==
        this.invitedDistributorService.candidate.candidateProducts[0].upc) {
        continue;
      }
      const storeConflict = this.invitedDistributorService.productData.dsdItems[x].storeAuthorizations.find(
        auth => auth.storeNumber === custLocationNumber);
      if (storeConflict) {
        return true;
      }
    }
    return false;
  }

  isStoreConflict() {
    if (!this.invitedDistributorService.selectedAuthGroups || this.invitedDistributorService.selectedAuthGroups.length === 0) {
      return false;
    }

    for (let x = 0; x < this.invitedDistributorService.selectedAuthGroups.length; x++) {
      if (!this.invitedDistributorService.selectedAuthGroups[x].isViewing) {
        continue;
      }
      for (let y = 0; y < this.invitedDistributorService.selectedAuthGroups[x].stores.length; y++) {
        if (this.invitedDistributorService.selectedAuthGroups[x].stores[y].checked &&
          this.hasStoreConflict(this.invitedDistributorService.selectedAuthGroups[x].stores[y].custLocationNumber)) {
          return true;
        }
      }
    }
  }

  getStoreNumbersWithConflict(): number[] {
    const storeConflicts = [];
    if (!this.invitedDistributorService.selectedAuthGroups || this.invitedDistributorService.selectedAuthGroups.length === 0) {
      return storeConflicts;
    }
    if (!this.invitedDistributorService.productData || !this.invitedDistributorService.productData.dsdItems ||
      this.invitedDistributorService.productData.dsdItems.length === 0) {
      return storeConflicts;
    }

    for (let x = 0; x < this.invitedDistributorService.selectedAuthGroups.length; x++) {
      if (!this.invitedDistributorService.selectedAuthGroups[x].isViewing) {
        continue;
      }
      for (let y = 0; y < this.invitedDistributorService.selectedAuthGroups[x].stores.length; y++) {
        if (this.invitedDistributorService.selectedAuthGroups[x].stores[y].checked &&
          this.hasStoreConflict(this.invitedDistributorService.selectedAuthGroups[x].stores[y].custLocationNumber)) {
          storeConflicts.push(this.invitedDistributorService.selectedAuthGroups[x].stores[y].custLocationNumber);
        }
      }
    }
    return storeConflicts;
  }

  onClickKeepStores() {
    this.keepStoresSelected = true;
  }

  onClickRemoveStores() {
    if (!this.invitedDistributorService.selectedAuthGroups || this.invitedDistributorService.selectedAuthGroups.length === 0) {
      return;
    }
    if (!this.invitedDistributorService.productData || !this.invitedDistributorService.productData.dsdItems ||
      this.invitedDistributorService.productData.dsdItems.length === 0) {
      return;
    }

    for (let x = 0; x < this.invitedDistributorService.selectedAuthGroups.length; x++) {
      if (!this.invitedDistributorService.selectedAuthGroups[x].isViewing) {
        continue;
      }
      for (let y = 0; y < this.invitedDistributorService.selectedAuthGroups[x].stores.length; y++) {
        if (this.invitedDistributorService.selectedAuthGroups[x].stores[y].checked &&
          this.hasStoreConflict(this.invitedDistributorService.selectedAuthGroups[x].stores[y].custLocationNumber)) {
          this.invitedDistributorService.selectedAuthGroups[x].stores[y].checked = false;
          this.onStoreChecked({checked: false}, this.invitedDistributorService.selectedAuthGroups[x].stores[y],
            this.invitedDistributorService.selectedAuthGroups[x]);
        }
      }
    }
  }
}
