import {Component, OnInit, ViewChild} from '@angular/core';
import {Candidate, CandidateError, CandidateProduct, CandidateValidatorType, Product, TaskDecision} from 'pm-models';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {GrowlService} from '../../growl/growl.service';
import {FileService} from '../../service/file.service';
import {ReviewComponent, UPCInputState} from 'pm-components';
import {AssociateUpcService} from '../../service/associate-upc.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {HistoryService} from '../../utils/history.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {AssociateUpcStepperComponent} from '../../shared/components/associate-upc-stepper/associate-upc-stepper.component';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {CandidateHistoryService} from '../../service/candidate-history.service';
import {Observable} from 'rxjs';
import {MatUtilService} from '../../service/mat-util.service';

@Component({
  selector: 'app-associate-review-page',
  templateUrl: './associate-review-page.component.html',
  styleUrls: ['./associate-review-page.component.scss']
})
export class AssociateReviewPageComponent implements OnInit {

  @ViewChild(ReviewComponent) reviewDrawer;

  constructor(private route: ActivatedRoute, private workflowService: WorkflowService, private lookupService: LookupService,
              private router: Router, public candidateService: CandidateService, public fileService: FileService,
              private growlService: GrowlService, public associateUpcService: AssociateUpcService,
              public candidateUtilService: CandidateUtilService, public historyService: HistoryService,
              public permissionService: NgxPermissionsService, public candidateHistoryService: CandidateHistoryService,
              public matUtilService: MatUtilService) {
  }

  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  public selectedProductData: any;
  private searchedCandidateProductIndex = 0;
  private candidateSubscription$: any;
  private productData = [];
  public productImageUrl: string = null;
  public canNavigate = true;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public isViewingPage = true;
  public upcState: UPCInputState;
  private selectedCasePack: number;
  private product: Product;
  private commodity: any;
  private buyer: any;
  private commodityId;
  public resubmittedCandidate: Candidate;
  public currentHistoryResults: any = undefined;
  public showHistoryPanel: boolean = false;
  public isClosedOrUnderReview: boolean = true;
  canEditAndResubmit = true;
  showMatAttributes = false;
  isLoadingMatData = true;

  ngOnInit(): void {
    this.candidateSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId') && params.has('taskId')) {
        this.workflowService.getAllTasksForCandidates([+params.get('candidateId')]).subscribe((tasks) => {

          if (tasks && tasks.length > 0) {
            this.associateUpcService.setTaskId(tasks[0].id);
          } else {
            this.associateUpcService.resetService();
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Unable to find a task associated with candidate id: ' +
                  params.get('candidateId'), growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          }

          const candidateId: number = parseInt(params.get('candidateId'), 10);
          this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
            if (candidate.candidateType !== Candidate.ASSOCIATE_UPC) {
              this.router.navigate(['/tasks'], {
                queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
              }).then();
            } else {
              this.associateUpcService.setOriginalAndCurrentCandidate(candidate);
              this.candidateHistoryService.setLastSupplierChangedCandidate(this.associateUpcService.getCandidate());
              this.setInitialValues(this.associateUpcService.getCandidate());
            }

          });
        });
      } else if (params.has('taskId')) {
        const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.associateUpcService.getTaskId(), this.associateUpcService.getTask());
        if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
          this.associateUpcService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.ASSOCIATE_UPC) {
              this.isClosedOrUnderReview = false;
              this.setInitialValues(candidate);
              this.candidateHistoryService.setLastSupplierChangedCandidate(candidate);
            }
          });
        } else {
          this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.associateUpcService.getCandidate()).subscribe(
            (isValid) => {
              if (isValid) {
                this.isClosedOrUnderReview = false;
                this.setInitialValues(this.associateUpcService.getCandidate());
                this.candidateHistoryService.setLastSupplierChangedCandidate(this.associateUpcService.getCandidate());
              } else {
                this.associateUpcService.resetService();
                this.router.navigate(['/tasks']);
              }
            });
        }
      } else {
        this.associateUpcService.resetService();
        this.router.navigate(['/tasks'], {
          queryParams: {growlMessage: 'Invalid URL Parameters!', growlToUse: GrowlService.SEVERITY_ERROR}
        });
      }
    });
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']);
  }

  private setInitialValues(primaryCandidate: Candidate) {
    this.setOriginalAndCurrentSearchedCandidate(primaryCandidate);
    this.productImageUrl = null;
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
    if (primaryCandidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      this.lookupService.getUpc(this.associateUpcService.getCandidate().candidateProducts[
        this.searchedCandidateProductIndex].upc).subscribe(
        (upc) => {
          this.product = upc.product;
          this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.product);
          this.setUpcData(upc);
        });
    } else if (primaryCandidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_ITEM) {
      this.lookupService.getItem(
        primaryCandidate.candidateProducts[this.searchedCandidateProductIndex].itemCode)
        .subscribe((productData) => {
          // using the the upc from the item information to get all the needed fields
          this.lookupService.getUpc(productData.containedUpc.upc.scanCodeId).subscribe(
            (upc) => {
              this.product = upc.product;
              this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.product);
              this.setUpcData(upc);
            });
        });
    }
    this.currentHistoryResults = this.historyService.getCandidateHistory(this.associateUpcService.getCandidate().candidateId, true);
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      return;
    }
    this.associateUpcService.resetMatHierarchyFields();
    this.showMatAttributes = true;
    if (this.candidateUtilService.isInProgressCandidate(this.associateUpcService.candidate)) {
      this.matUtilService.updateCandidateProductsMatHierarchyFromProduct(this.associateUpcService.candidate.candidateProducts, this.product).pipe(
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.associateUpcService.getCandidate(),
          this.associateUpcService.globalAttributes, this.associateUpcService.hierarchyAttributes)),
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.associateUpcService.hierarchyAttributes,
            this.associateUpcService.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.associateUpcService.globalAttributes,
            [], [], this.associateUpcService.upcAttributes);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })).subscribe();
    } else {
      this.isLoadingMatData = false;
    }
  }

  private setProductData(): Observable<any> {
    if (this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
        tap((productData) => {
          if (productData) {
            this.productData = productData;
            this.associateUpcService.getCandidate().productId = productData.productId;
            this.setCaseUpcData(productData);
          } else {
            this.growlService.addError('Unable to fetch product data!');
          }
        })
      );
    } else if (this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap((productData) => {
          if (productData) {
            this.productData = productData;
            this.associateUpcService.getCandidate().productId = productData.productId;
            this.setCaseUpcData(productData);
            this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].upc = productData.primaryScanCodeId;
          } else {
            this.growlService.addError('Unable to fetch product data!');
          }
        })
      );
    }
  }

  /**
   * Sets the original and current associateCandidate objects. The original represents the original state of the associateCandidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentSearchedCandidate(candidate: Candidate) {
    this.associateUpcService.setOriginalAndCurrentCandidate(candidate);
    if (!this.associateUpcService.getCandidate().candidateProducts[0].upc) {
      this.lookupService.getUpc(this.associateUpcService.getCandidate().candidateProducts[0].itemCode).subscribe(upc =>
        this.associateUpcService.getCandidate().candidateProducts[0].upc = upc.searchedUpc);
    }
  }

  onClickBackToHome() {
    this.router.navigate(['/tasks']).then();
  }

  completeTaskAndRouteToHome() {
    this.canNavigate = false;
    const validators = [CandidateValidatorType.ASSOCIATE_CASE_PACK_DETAILS_VALIDATOR,
      CandidateValidatorType.ASSOCIATE_BASIC_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_COMMENT_VALIDATOR];
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      validators.push(CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR);
    }
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), validators).subscribe(data => {
      this.associateUpcService.saveAndCompleteTaskAndRouteToUrl(WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_VENDOR_DATA_DECISION_NO, '/tasks', 'Successfully completed task.');
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.associateUpcService.updatePageErrors(error.error.candidateErrors);
      }
      this.canNavigate = true;
    });
  }

  print() {
    window.print();
  }

  /**
   * Sets the data on a retail info object to be displayed on a table.
   * @param upc
   */
  setUpcData(upc) {
    this.associateUpcService.getCandidate().masterHeight = upc.height;
    this.associateUpcService.getCandidate().masterWidth = upc.width;
    this.associateUpcService.getCandidate().masterLength = upc.length;

    this.associateUpcService.getCandidate().retailSize = upc.size;
    this.associateUpcService.getCandidate().unitOfMeasure = upc.unitOfMeasure;
    this.associateUpcService.getCandidate().candidateProducts[0].subBrand = upc.subBrand;
    this.associateUpcService.getCandidate().totalVolume = upc.sellingSizeOne;
  }

  setCaseUpcData(productData: any) {
    this.productData = productData.items;
    this.setCasePack();
  }

  setCasePack() {
    for (let x = 0; x < this.productData.length; x++) {
      if (this.productData[x].caseUpc === this.associateUpcService.getCandidate().candidateProducts[1].caseUpc) {
        this.selectedCasePack = this.associateUpcService.getCandidate().candidateProducts[1].caseUpc;
        this.commodityId = this.productData[x].commodity;
        this.selectedProductData = this.productData[x];
        this.findCommodity();
      }
    }
  }

  getSelectedCasePack() {
    return this.selectedCasePack;
  }

  /**
   * To find the commodity.
   */
  findCommodity() {
    this.lookupService.findCommodity(this.commodityId).subscribe( data => {
      this.commodity = data;

      this.findBuyer();
    });
  }

  /**
   * To find buyer info attached to the commodity.
   */
  findBuyer() {
    this.lookupService.findBuyer(this.commodity[0].buyer.buyerId).subscribe( buyerData => {
      this.buyer = buyerData;
    });
  }

  getSumbitButtonTitle() {
    return 'Submit';
  }

  getPageTitle() {
    return 'Review This Associate.';
  }

  getTitleSubHeading(): String {
    if (!this.associateUpcService.candidate) {
      return '';
    }
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.associateUpcService.getCandidate().status);
    if (this.associateUpcService.candidate.candidateProducts[0].itemCode) {
      infoString += ' | Item code: ' + this.associateUpcService.candidate.candidateProducts[0].itemCode;
    }

    return infoString;
  }

  isRejected() {
    return (CandidateUtilService.getUIStatusFromCandidateStatus(this.associateUpcService.getCandidate().status) === 'Rejected');
  }

  resubmitCandidate() {
    this.canEditAndResubmit = false;
    this.resubmittedCandidate = JSON.parse(JSON.stringify(this.associateUpcService.getCandidate()));
    this.resubmittedCandidate.candidateId = null;
    this.resubmittedCandidate.status = null;
    this.resubmittedCandidate.vendorComment = null;
    this.resubmittedCandidate.buyerComment = null;
    this.resubmittedCandidate.scaComment = null;

    // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
    this.associateUpcService.createCandidateAndNavigate(this.resubmittedCandidate, '/setupAssociateUpc', true);
    this.workflowService.deleteHistoricInstance(this.associateUpcService.getTaskId()).subscribe();
  }

  historyPanelOpen() {
    this.permissionService.hasPermission('ROLE_VIEW_CANDIDATE_HISTORY').then((hasPermission) => {
      if (hasPermission) {
        this.historyService.getCandidateHistory(this.associateUpcService.getCandidate().candidateId, true);
        this.currentHistoryResults = this.historyService.sortedAudits;
        this.showHistoryPanel = true;
        this.reviewDrawer.openDrawer();
      }
    });
  }

  historyPanelClose() {
    this.showHistoryPanel = false;
    this.reviewDrawer.closeDrawer();
  }

  onClickBack() {
    const url = this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT') ? '/associateExtendedAttributes' : '/associateCaseSelection';
    this.jumpBack(url);
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case AssociateUpcStepperComponent.ORIGINAL_UPC_ITEM_CODE: {
        this.jumpBack('/setupAssociateUpc');
        break;
      }
      case AssociateUpcStepperComponent.ITEM_DETAILS: {
        this.jumpBack('/associateBasicItemSetup');
        break;
      }
      case AssociateUpcStepperComponent.CASE_SELECTION: {
        this.jumpBack('/associateCaseSelection');
        break;
      }
      case AssociateUpcStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpBack('/associateExtendedAttributes');
        break;
      }
    }
  }

  jumpBack(urlToNavigate) {
    this.canNavigate = false;
    this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
      finalize(() => this.canNavigate = true)
    ).subscribe();
  }
}
