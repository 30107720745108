import { BaseAttribute } from './base-attribute';
import {AttributeUPCConfig, CandidateValidatorType, CandidateError, CandidateProduct} from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayUpcComponent, InputState } from '../../shared/attribute-display/attribute-display-upc/attribute-display-upc.component';

export class DepositUpc implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayUpcComponent;
  }

  getAttributeConfig(overrides?): AttributeUPCConfig {
    return {
      label: 'Deposit UPC',
      description: `Enter the product's deposit UPC.`,
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      placeholderText: `Enter Deposit UPC`,
      checkDigitPlaceholderText: `Check #`,
      productIndex: 0,
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayUpcComponent, candidate: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.depositScanCodeId;
    componentInstance.modelChange.subscribe(x => {
      candidate.depositScanCodeId = x;
    });
    componentInstance.checkDigitModel = candidate.depositScanCodeCheckDigit;
    componentInstance.checkDigitModelChange.subscribe(x => {
      candidate.depositScanCodeCheckDigit = x;
    });

    componentInstance.validateUpc.subscribe(s => {
      if (configOverrides.validationService) {
        componentInstance.state = InputState.loading;
        const candidateProductId = candidate.id;

        configOverrides.validationService.validateCandidate({ candidateProducts: [candidate] },
          [CandidateValidatorType.DEPOSIT_UPC_VALIDATOR]).subscribe(
          () => {
            componentInstance.state = InputState.valid;
            componentInstance.attributeError = undefined;
          },
          error => {
            componentInstance.state =  InputState.invalid;
            // if there's an error, and it's an instance of candidate error model, update the candidate product's
            // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
            // add the whole candidate product error model.
            if (error.error.candidateErrors) {
              const returnedCandidateError: CandidateError = error.error.candidateErrors;
              const returnedCandidateProductError = returnedCandidateError.candidateProductErrors[candidateProductId];
              componentInstance.attributeError = returnedCandidateProductError.depositScanCodeId;
            }
          }
        );
      }
    });
  }

  updateModel(componentInstance: AttributeDisplayUpcComponent, candidate: CandidateProduct) {
    if (componentInstance.model !== candidate.depositScanCodeId) {
      componentInstance.model = candidate.depositScanCodeId;
    }

    if (componentInstance.checkDigitModel !== candidate.depositScanCodeCheckDigit) {
      componentInstance.checkDigitModel = candidate.depositScanCodeCheckDigit;
    }
  }
}
