import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UpcInputComponent } from './upc-input/upc-input.component';
import { BaseUpcInputComponent } from './base-upc-input/base-upc-input.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {SharedModule} from '../../../../../../src/app/2.0.0/shared/shared.module';
import { UpcPluInputComponent } from 'pm-components/lib/shared/upc-inputs/upc-plu-input/upc-plu-input.component';
import { StaticOverlayPanelModule } from 'pm-components/lib/shared/overlay-panels/static-overlay-panel/static-overlay-panel.module';

@NgModule({
  declarations: [UpcInputComponent, UpcPluInputComponent,  BaseUpcInputComponent],
  exports: [UpcInputComponent, DropdownModule, FormsModule, InputTextModule, UpcPluInputComponent],
  imports: [CommonModule, FormsModule, InputTextModule, DropdownModule, AttributeDisplayModule, SharedModule, StaticOverlayPanelModule],
  providers: [{provide: CandidateService}]
})
export class UpcInputsModule {}
