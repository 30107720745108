import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeleteButtonsModule} from '../../shared/delete-button/delete-button.module';
import {TaskViewerTableComponent} from './task-viewer-table.component';
import {CardModule} from '../../shared/card/card.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {TableModule} from 'primeng/table';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ProgressSpinnerModule} from '../../shared/progress-spinner/progress-spinner.module';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {SelectionFilterModule} from '../../shared/selection-filter/selection-filter.module';
import {CheckboxModule} from 'primeng/checkbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {UploadCandidateTableModule} from '../../shared/upload-candidate-table/upload-candidate-table.module';
import {StaticOverlayPanelModule} from '../../shared/overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {RejectCandidateModule} from '../../shared/reject-candidate/reject-candidate-module';

@NgModule({
  declarations: [TaskViewerTableComponent],
  exports: [TaskViewerTableComponent, CardModule, AttributeDisplayModule, ProgressSpinnerModule],
  imports: [
    CommonModule, CardModule,
    NgxPermissionsModule, DeleteButtonsModule,
    AttributeDisplayModule, TableModule, ConfirmDialogModule, ProgressSpinnerModule, PipesModule, SelectionFilterModule, CheckboxModule,
    TabMenuModule, TabViewModule, UploadCandidateTableModule, StaticOverlayPanelModule, RejectCandidateModule
  ]
})
export class TaskViewerTableModule { }
