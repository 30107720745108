import {CandidateSearchFilter} from 'pm-models/lib/candidateSearchFilter';
import {CandidateSearchSort} from 'pm-models/lib/candidateSearchSort';

export class CandidateSearchParams {
  candidateIds?: number[];
  pageNumber: number;
  pageSize: number;
  needsCount: boolean;
  sort?: CandidateSearchSort;
  filter: CandidateSearchFilter[];

  constructor(candidateIds: number[], pageNumber: number, pageSize: number, needsCount: boolean,
              sort: CandidateSearchSort, filter?: CandidateSearchFilter[]) {
    this.candidateIds = candidateIds;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.needsCount = needsCount;
    this.sort = sort;
    if (filter) {
      this.filter = filter;
    }
  }
}
