import {
  Component,
  OnInit,
  AfterContentInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  ContentChildren,
  ChangeDetectorRef,
  ElementRef,
  QueryList
} from '@angular/core';
import { PMToolbarSectionDirective } from './toolbar-section.directive';
import { untilDestroyed } from '../../common/takeUntilDestroyed';
import { coerceBooleanProperty } from '../../common/coerceBooleanProperty';

@Component({
  selector: 'pm-toolbar',
  template: '<ng-content></ng-content>',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'mtr-toolbar',
    '[class.mtr-toolbar--dense]': 'dense',
    '[class.mtr-toolbar--transparent]': 'transparent'
  }
})
export class ToolbarComponent implements AfterContentInit, OnDestroy {
  /** Sets the toolbar to dense mode */
  @Input()
  get dense(): boolean {
    return this._dense;
  }
  set dense(value: boolean) {
    this._dense = coerceBooleanProperty(value);
  }
  private _dense: boolean;

  /** Sets the toolbar to dense mode */
  @Input()
  get transparent(): boolean {
    return this._transparent;
  }
  set transparent(value: boolean) {
    this._transparent = coerceBooleanProperty(value);
  }
  private _transparent: boolean;

  @ContentChildren(PMToolbarSectionDirective, { descendants: true }) sections: QueryList<PMToolbarSectionDirective>;

  constructor(elementRef: ElementRef, public changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.autoAlignSections();

    this.sections.changes.pipe(untilDestroyed(this)).subscribe(sections => this.autoAlignSections());
  }

  protected autoAlignSections() {
    let hasStart = false;
    let hasEnd = false;
    this.sections.forEach(section => {
      if (section.align === 'start') {
        hasStart = true;
      } else if (section.align === 'end') {
        hasEnd = true;
      }
    });
    if (!hasStart) {
      this.sections.some(section => {
        if (section.align !== 'start' && section.align !== 'end') {
          section.align = 'start';
          return true;
        }
        return false;
      });
    }
    if (!hasEnd) {
      this.sections
        .map(item => item)
        .reverse()
        .some(section => {
          if (section.align !== 'start' && section.align !== 'end') {
            section.align = 'end';
            return true;
          }
          return false;
        });
    }
  }

  /** Required for untilDestroyed() */
  ngOnDestroy() {}
}
