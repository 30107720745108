import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AttributeTypeaheadConfig} from 'pm-models';
import {UserPreferences} from 'pm-models/lib/userPreferences';
import {PreferencesService} from '../service/preferences.service';

@Component({
  selector: 'app-set-up-notifications',
  templateUrl: './set-up-notifications.component.html',
  styleUrls: ['./set-up-notifications.component.scss']
})
export class SetUpNotificationsComponent implements OnInit {

  constructor(private preferencesService: PreferencesService, private router: Router) {}

  private userPreferences: UserPreferences;

  public isViewingPage = true;

  public buyerModel: any;

  public emailToggleModel: boolean = false;

  // If any changes were made to the component
  public changes: boolean = false;

  buyerConfiguration: AttributeTypeaheadConfig = {
    label: 'Buyer',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    description: 'Choose the H-E-B buyer(s) you work with. ' +
      'You\'ll receive notifications when they receive new work.',
    name: 'Buyer',
    idRef: 'buyerId',
    displayRef: 'displayName',
    displaySubRef: '',
    searchUrl: '/lookup/buyer',
    placeholderText: 'Type to search for a Buyer...',
    showAddlInfo: true,
    allowMultiple: true
  };

  ngOnInit() {
    this.preferencesService.getPreferences().subscribe(prefs => {
      this.userPreferences = prefs;

      if (prefs.followingDesks) {
        this.buyerModel = prefs.followingDesks;
      }
    });

  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']);
  }

  buyerChange(event) {
    this.changes = true;
    this.buyerModel = event;
 }

  save() {
    this.changes = false;

    if (this.buyerModel !== undefined) {
      // impicitly set this to true since they are following a BDM
      this.userPreferences.sendEmails = true;

      this.userPreferences.followingDesks = this.buyerModel.map(x => x.buyerId);
    } else {
      this.userPreferences.followingDesks = undefined;
    }
    this.userPreferences.hasSetupPreferences = true;

    this.preferencesService.updatePreferences(this.userPreferences).subscribe(prefs => {
      this.userPreferences = prefs;
      this.onClose();
    });
  }
}
