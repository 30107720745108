import { Injectable } from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {Candidate} from 'pm-models';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {PublisherService} from './publisher.service';
import {GrowlService} from '../growl/growl.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BatchUploadService {

  private candidateBatchServiceBaseUrl = '/candidates/batch';

  private findSuccessfullyUploadedCandidatesByParentIdUrl =
    this.candidateBatchServiceBaseUrl + '/findSuccessfullyUploadedCandidatesByParentId';

  constructor(private http: HttpClient, private publisherService: PublisherService,
              private growlService: GrowlService, private router: Router, private auth: AuthService) {
  }

  /**
   * Finds successfully uploaded candidates by the parent candidate id.
   *
   * @param candidateId the parent candidate id.
   * @returns {Observable<Candidate[]>} The successfully uploaded candidate.
   */
  public findSuccessfullyUploadedCandidatesByParentId(candidateId: number): Observable<Candidate[]> {
    return this.http.get<any>(this.findSuccessfullyUploadedCandidatesByParentIdUrl + '?parentCandidateId=' + candidateId)
      .pipe(
        tap(() =>
          this.logSuccess('Successfully found the successfully upload candidates for the parent candidate id: ' + candidateId)
        )
      );
  }

  /**
   * Log a BatchUploadService success message
   */
  private logSuccess(message: string) {
    this.publisherService.add('BatchUploadService: ' + message);
  }

  /**
   * Activates a candidate.
   * @param candidate to activate.
   */
  public activateCandidate(candidate: Candidate): Observable<any> {
    return this.http.post<any>(this.candidateBatchServiceBaseUrl + '/activate/', candidate).pipe(
      tap(() => this.logSuccess('Successfully activated candidate: ')),
      catchError(this.handleAndDisplayError<any>('activating candidate'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleAndDisplayError<T> (operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401 || error.status === 403) {
          this.auth.isNotAuthenticatedLogout();
          this.router.navigate(['/login']).then();
        } else if (error.status === 400 || error.status === 500) {
          let message = `Error in ${operation}: ${error.error.message}`;

          if (error.error.candidateErrors && error.error.candidateErrors.errors) {
            message += ' ' + error.error.validationErrors.join(' ');
          }
          return observableThrowError(message);
        }
      }

      let errorMessage: string;
      if (error.error && error.error.message) {
        errorMessage = `Error in ${operation}: ${error.error.message}`;
      } else {
        errorMessage = `Error in ${operation}: ${error.message}`;
      }
      this.logError(errorMessage);
      this.growlService.addError(errorMessage);
      return observableThrowError(error);
    };
  }


  /** Log a CandidateService message */
  private logError(message: string) {
    this.publisherService.add('CandidateService: ' + message);
  }

}
