<div class="product-table-container">
  <div class="row ml-0 mr-0">
    <p-table selectionMode="single"
             [rowHover]="true"
             [columns]="allWarehouseColumns"
             [value]="candidates"
             [rows]="pageSize"
             [totalRecords]="totalNumRecords"
             [scrollable]="true"
             styleClass="p-datatable-gridlines"
             [ngStyle]="{'width': '100%'}">

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns"
               [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-productHeaderColumns>
        <tr class="product-table-column-group">
          <th class="left-sticky-header" [attr.colspan]="1">#</th>
          <th class="left-sticky-header" [attr.colspan]="1">UPC</th>
          <th colspan="1">All WHS</th>
          <th *ngFor="let item of [].constructor(maxWarehouseColumnsCount);  let i = index" [attr.colspan]="getWarehouseColumnGroupLength()">
            Warehouse ({{i + 1}})
          </th>
        </tr>
        <tr class="product-table-column-headers">
          <th *ngFor="let col of productHeaderColumns"
              [ngClass]="col.sticky ? 'left-sticky-header' : ''"
              [ngStyle]="{'width': col.width}">
            <div>
              {{col.header}}
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-candidate let-index="rowIndex">
        <tr class="product-table-body" [pSelectableRow]="candidate">
          <!--  candidate index -->
          <td class="sticky-column col">
            <div>{{index + 1}}</div>
          </td>
          <!--  UPC  -->
          <td class="sticky-column" (click)="editProductUPC(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])}}">
            </i>
            {{candidate?.candidateProducts[candidateProductIndex].upc}}
          </td>
          <td (click)="showEditCandidateModal(index, attributeType.MaxShip)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['maxShip'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['maxShip'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['maxShip'])}}">
            </i>
            {{candidate.maxShip | emptyToValue: '-'}}
          </td>
          <ng-template ngFor let-warehouse [ngForOf]="[].constructor(maxWarehouseColumnsCount)" let-warehouseIndex="index">

            <td *ngIf="isUpload && warehouseIndex === 0" (click)="showWarehousesPanel($event, warehousesOverlay, warehousesModalDiv, index, candidate)"
                [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['missingWarehouseSupplier'])}">
              <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['missingWarehouseSupplier'])" class="pi pi-exclamation-triangle errorTriangle"
                 pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['missingWarehouseSupplier'])}}">
              </i>
              {{getWarehouseSupplier(candidate, warehouseIndex)?.lane?.name | emptyToValue: '-'}}
            </td>

            <td *ngIf="isUpload && warehouseIndex !== 0"  (click)="showWarehousesPanel($event, warehousesOverlay, warehousesModalDiv, index, candidate)">
              {{getWarehouseSupplier(candidate, warehouseIndex)?.lane?.name | emptyToValue: '-'}}
            </td>

            <td (click)="showWarehousesPanel($event, warehousesOverlay, warehousesModalDiv, index, candidate)"
                [ngClass]="{'bulkErrorCell': candidateErrorUtils.getWarehouseSupplierItemError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['bicep'], getWarehouseSupplier(candidate, warehouseIndex)?.id, getWarehouse(candidate, warehouseIndex)?.id)}">
              <i *ngIf="candidateErrorUtils.getWarehouseSupplierItemError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['bicep'], getWarehouseSupplier(candidate, warehouseIndex)?.id, getWarehouse(candidate, warehouseIndex)?.id)" class="pi pi-exclamation-triangle errorTriangle"
                 pTooltip="{{candidateErrorUtils.getWarehouseSupplierItemError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['bicep'], getWarehouseSupplier(candidate, warehouseIndex)?.id, getWarehouse(candidate, warehouseIndex)?.id)}}">
              </i>
              {{getWarehouse(candidate, warehouseIndex)?.name | emptyToValue: '-'}}
              <div class="cell-info" *ngIf="getWarehouse(candidate, warehouseIndex)">
                ID: {{getWarehouse(candidate, warehouseIndex)?.omiId}}  |  Facility #: {{getWarehouse(candidate, warehouseIndex)?.warehouseId}}
              </div>
            </td>
            <td (click)="showWarehousesPanel($event, warehousesOverlay, warehousesModalDiv, index, candidate)">
              {{getWarehouse(candidate, warehouseIndex)?.orderUnit?.displayName | emptyToValue: '-'}}
            </td>
            <td (click)="showWarehousesPanel($event, warehousesOverlay, warehousesModalDiv, index, candidate)">
              {{getWarehouse(candidate, warehouseIndex)?.orderRestriction?.displayName | emptyToValue: '-'}}
            </td>
          </ng-template>

        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div>
  <div #warehousesModalDiv></div>
  <pm-static-overlay-panel #warehousesOverlay appendTo="body"
                           [classOverride]="'pm-overlaypanel-fixed'"
                           [showTransitionOptions]="'0ms ease-out'"
                           [style]="{width: '100%', height: '100%', position: 'fixed', 'overflow-y': 'auto'}"
                           [dismissable]="false">
    <ng-container *ngIf="candidateWarehouse" >
      <div class="row align-items-center warehouse-overlay-wrapper">
        <div class="col-md-12 warehouse-overlay">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="header-row">
                <h2>Warehouses</h2>
                <h5 class="sub-title">Configure one or more of the warehouses below for <b>{{candidateWarehouse.description}} – {{candidateWarehouse.candidateProducts[0].upc}}</b></h5>
                <hr>
              </div>
              <div>
                <ng-template ngFor let-lane [ngForOf]="warehouseData" let-index="rowIndex">

                  <div *ngIf="lane.warehouses && lane.warehouses.length > 0" class="col-md-12 laneContainer">
                    <div class="row headerRow">

                      <i *ngIf="lane.isViewing" class="pi pi-caret-up" (click)="onClickLaneCaret(lane)"></i>
                      <i *ngIf="!lane.isViewing" class="pi pi-caret-down" (click)="onClickLaneCaret(lane)"></i>

                      <div>
                        <div class="headerText">{{lane.lane.name}}</div>
                      </div>
                    </div>

                    <div  *ngIf="isLaneViewing(lane)" class="warehouseScroll">
                      <ng-template ngFor let-warehouse [ngForOf]="lane.warehouses" let-index="index">
                        <hr>
                        <div class="attribute-group">
                          <div>
                            <p-checkbox id="whsCheckbox"
                                        [(ngModel)]="warehouse.checked"
                                        (onChange)="selectedWarehouseChange($event, warehouse)"
                                        binary="true">
                            </p-checkbox>
                          </div>
                          <div class="wsh-details-row">
                            <div class="whs-label">
                              <label>
                                {{warehouse.name}}
                              </label>
                            </div>
                            <p class="attribute-description">
                              ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                            </p>
                            <p *ngIf="warehouse.checked && candidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
                               class="error-message bicep-error">
                              {{candidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
                            </p>
                          </div>
                        </div>
                        <div *ngIf="warehouse.checked">
                          <div class="attributes-stacked">
                            <div class="row wsh-details-row ml-0">
                              <div class="col-md-6">
                                <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                                                [(model)]="warehouse.orderRestriction"
                                                                (selection)="orderRestrictionChange($event, warehouse)"
                                                                [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
                                </pm-attribute-display-typeahead>
                              </div>
                              <div class="col-md-6">
                                <pm-attribute-display-radio [attribute]="orderUnitConfiguration"
                                                            [(model)]="warehouse.orderUnitId"
                                                            (change)="orderUnitChange($event, warehouse)"
                                                            [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
                                </pm-attribute-display-radio>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="row">
                <div class="col-md-12 button-row">
                  <button pButton class="cancel mr-3" label="Cancel" (click)="closeWarehousesPanel($event, warehousesOverlay)"></button>
                  <button pButton class="m-2" label="Save" (click)="updateWarehouses($event, warehousesOverlay)"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-static-overlay-panel>
</div>

