<pm-review #pmReview *ngIf="isViewingPage && supplierCasePackService.candidate" (closeEvent)="onClose()">
  <pm-header>
    <div *ngIf="!isClosedOrUnderReview">
      <pm-additional-case-pack-stepper *ngxPermissionsExcept="['ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT']"
                                       [candidateErrors]="supplierCasePackService.getAllSupplierCandidateErrors()" [stepIndex]="3"
                                       (indexClick)="onClickStepper($event)">
      </pm-additional-case-pack-stepper>
      <pm-additional-case-pack-stepper *ngxPermissionsOnly="['ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT']"
                                       [candidateErrors]="supplierCasePackService.getAllSupplierCandidateErrors()" [stepIndex]="4"
                                       (indexClick)="onClickStepper($event)">
      </pm-additional-case-pack-stepper>
    </div>
  </pm-header>
  <pm-title>{{getPageTitle()}}</pm-title>
  <pm-sub-title>{{getTitleSubHeading()}}</pm-sub-title>
  <pm-app-reject-status-header [candidate]="supplierCasePackService.candidate"></pm-app-reject-status-header>

  <div *ngIf="supplierCasePackService.candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImageUrl ? productImageUrl : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div class="upc-header">
            UPC: {{productData?.primaryScanCodeId}}-{{scanCodeCheckDigit}}
          </div>
          <div class="attribute-description">
            <p class="mb-0">{{productData?.productDescription}}</p>
            <p class="mb-0">{{productInfoString}}</p>
            <div class="row" style="padding-left: 15px;">
              <button pButton type="button"
                      [label]="'View candidate history'"
                      class="ui-button-link" (click)="historyPanelOpen()">
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--   Cost set by supplier   -->
      <pm-attribute-grid [title]="'Cost set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'prePrice')"
                          (historyClicked)="historyPanelOpen()">
              {{productData?.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'costLinked')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'costLink')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'masterListCost')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(supplierCasePackService?.candidate?.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.getCaseCostAsCurrency(this.supplierCasePackService.candidate)}}<br>
                Penny profit: {{getHebPennyProfit()}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':isHebMarginNegative()}">{{getHebMargin()}}
                <img *ngIf="isHebMarginNegative()" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br></a>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{supplierCasePackService?.candidate?.dealOffered | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Retail set by buyer-->
      <pm-attribute-grid [title]="'Retail set by buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false">
              {{getPricingType()}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              {{getHebMargin()}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false">
              {{upc?.xfor}} for {{this.costService.toCurrency(upc?.retailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{getHebPennyProfit()}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!--   Case summary   -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(this.supplierCasePackService.getCurrentCandidateProduct(), 'caseDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.candidateProducts[this.currentCandidateProductIndex]?.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="false">
              {{this.candidateUtilService.getChannel(supplierCasePackService.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(this.supplierCasePackService.getCurrentCandidateProduct(),'caseUpc')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.candidateProducts[this.currentCandidateProductIndex]?.caseUpc}}-{{supplierCasePackService?.candidate?.candidateProducts[this.currentCandidateProductIndex]?.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(this.supplierCasePackService.getCurrentCandidateProduct(),'countryOfOrigin', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.candidateProducts[this.currentCandidateProductIndex]?.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(this.supplierCasePackService.getCurrentCandidateProduct(),'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.candidateProducts[this.currentCandidateProductIndex]?.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'masterPack')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"

                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getMasterDimensions(supplierCasePackService?.candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(supplierCasePackService?.candidate?.masterLength, supplierCasePackService?.candidate?.masterWidth, supplierCasePackService?.candidate?.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'vendorTie') ||
                                                                                      candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'vendorTier')"
                          (historyClicked)="historyPanelOpen()">
              {{+supplierCasePackService?.candidate?.vendorTie * +supplierCasePackService?.candidate?.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{supplierCasePackService?.candidate?.vendorTie}}
                Tier: {{supplierCasePackService?.candidate?.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'itemWeightType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getItemWeightType(supplierCasePackService.candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(supplierCasePackService.candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'cubeAdjustedFactor')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'maxShelfLife')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(supplierCasePackService.candidate, supplierCasePackService?.candidate?.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="false" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'inboundSpecDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(supplierCasePackService.candidate, supplierCasePackService?.candidate?.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid [title]="'Inner packs'">
        <pm-grid-group style="margin-bottom: 0;">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'innerPackSelected')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="supplierCasePackService?.candidate?.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'innerPack')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.innerPack }}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"

                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'innerLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'innerWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'innerWidth')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getInnerDimensions(supplierCasePackService.candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(supplierCasePackService?.candidate?.innerLength, supplierCasePackService?.candidate?.innerWidth, supplierCasePackService?.candidate?.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'innerWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(supplierCasePackService.candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Display ready units -->
      <pm-attribute-grid [title]="'Display ready units'">
        <pm-grid-group style="margin-bottom: 0;">
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'displayReadyUnit')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.displayReadyUnit | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="supplierCasePackService?.candidate?.displayReadyUnit">
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(this.supplierCasePackService.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false"

                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'displayReadyUnitRowsDeep') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'displayReadyUnitRowsFacing') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'displayReadyUnitRowsHigh')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.displayReadyUnit ? supplierCasePackService?.candidate?.displayReadyUnitRowsDeep * supplierCasePackService?.candidate?.displayReadyUnitRowsFacing * supplierCasePackService?.candidate?.displayReadyUnitRowsHigh : 0}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit type'" [canEdit]="false"

                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDruType(supplierCasePackService?.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Product details from buyer -->
      <pm-attribute-grid [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <!-- Column 1 -->
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false">
              {{productData?.productTypeId}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{supplierCasePackService?.candidate?.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{supplierCasePackService?.candidate?.subCommodity?.subCommodityName}}
            </pm-grid-cell>
          </pm-grid-column>
          <!-- Column 2 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'Like item code'" [canEdit]="false">
              {{item?.itemCode}}
              {{dsdItem?.upc}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season & Year'" [canEdit]="false">
              {{supplierCasePackService?.candidate?.season?.seasonDescription}} {{supplierCasePackService?.candidate?.seasonYear}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Estimated store count'" [canEdit]="false">
              {{supplierCasePackService?.candidate?.numberOfStores}}
            </pm-grid-cell>
          </pm-grid-column>
          <!-- Column 3 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'Sold by weight'" [canEdit]="false">
              {{upc?.weightSw}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(supplierCasePackService.candidate, supplierCasePackService?.candidate?.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(supplierCasePackService.candidate, supplierCasePackService?.candidate?.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Warehouses -->
      <pm-attribute-grid [title]="'Warehouses'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Max ship'" [canEdit]="false">
              {{supplierCasePackService?.candidate?.maxShip}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <ng-template ngFor let-warehouse [ngForOf]="supplierCasePackService?.candidate?.candidateProducts[0].warehouses" let-index="index">

          <pm-grid-group style="margin-bottom: 0;">
            <pm-grid-column>
              <pm-grid-cell [label]="'Warehouse'" [canEdit]="false" class="warehouse-grid-cell">
                {{warehouse.name}}
                <pm-grid-cell-info>
                  ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                </pm-grid-cell-info>
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order unit'" [canEdit]="false">
                {{warehouse.orderUnit?.description}}
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order restriction'" [canEdit]="false">
                {{warehouse.orderRestriction?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <div *ngIf="index !== supplierCasePackService?.candidate?.candidateProducts[0].warehouses.length - 1" class="grid-divider"></div>
        </ng-template>
      </pm-attribute-grid>

      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="false">
              {{supplierCasePackService?.candidate?.candidateProducts[0]?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="false">
              {{supplierCasePackService?.candidate?.candidateProducts[0]?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false">
              {{productData?.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false"

                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'subBrand')"
                          (historyClicked)="historyPanelOpen()">
              {{upc?.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCostOwner(this.supplierCasePackService.getCandidate())"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.costOwner?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false"

                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'contactName') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'contactEmail')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.contactName}}
              {{supplierCasePackService?.candidate?.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForTopToTop(this.supplierCasePackService.getCandidate())"
                          (historyClicked)="historyPanelOpen()">
              <input type="text" pInputText value="{{supplierCasePackService?.candidate?.costOwner?.topToTopDisplayName}}"  readonly="readonly"/>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Supplier'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'vendor', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'lane', 'name')"
                          (historyClicked)="historyPanelOpen()">
              {{supplierCasePackService?.candidate?.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'">
              {{supplierCasePackService?.candidate?.buyer?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'productType')"
                          (historyClicked)="historyPanelOpen()">
              {{productData?.productTypeId === 'GOODS'| yesNo}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'foodStamp')"
                          (historyClicked)="historyPanelOpen()">
              {{productData?.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'taxable')"
                          (historyClicked)="historyPanelOpen()">
              {{productData?.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'flexibleSpendingAccount')"
                          [label]="'FSA/HSA eligible'"
                          (historyClicked)="historyPanelOpen()">
              {{productData?.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'packageType')"
                          (historyClicked)="historyPanelOpen()">
              {{productData?.packageDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'unitOfMeasure')"
                          (historyClicked)="historyPanelOpen()">
              {{upc?.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'retailSize')"
                          (historyClicked)="historyPanelOpen()">
              {{upc?.size  }}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"

                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'productLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'productWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(),'productHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{upc?.length}}in x
              {{upc?.width}}in x
              {{upc?.height}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'productWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductWeightString(upc?.weight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Total volume'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(this.supplierCasePackService.getCandidate(), 'totalVolume')"
                          (historyClicked)="historyPanelOpen()">
              {{item?.totalVolume}}
              {{dsdItem?.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid [title]="'Unit details'">
        <pm-grid-group style="margin-bottom: 0;">
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
              {{upc?.scanCodeId}}-{{upc?.scanCodeId | upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'">
              {{productData?.productDescription}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(productData?.productDescription) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false" >
              {{productData?.customerFriendlyDescriptionOne}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false">
              {{productData?.customerFriendlyDescriptionTwo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false">
              {{productData?.signRomanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="supplierCasePackService?.candidate?.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of supplierCasePackService?.candidate?.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <div *ngIf="showMatAttributes">
        <div *ngIf="candidateUtilService.isInProgressCandidate(supplierCasePackService.candidate)">
          <!--   Global Attributes   -->
          <div>

            <pm-attribute-grid *ngIf="isLoadingMatData || !!supplierCasePackService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierCasePackService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierCasePackService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierCasePackService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>
          <!--   Mat Hierarchy attributes   -->
          <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(supplierCasePackService.candidate)?.length" [title]="'Category attributes'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>
          <div *ngIf="!!supplierCasePackService.hierarchyNumberToAttributesMap?.size">
            <div *ngFor="let hierarchy of supplierCasePackService.candidate.candidateProducts[1]?.matHierarchyList">
              <pm-attribute-grid *ngIf="supplierCasePackService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of supplierCasePackService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of supplierCasePackService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of supplierCasePackService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>
        <div *ngIf="!candidateUtilService.isInProgressCandidate(supplierCasePackService.candidate)">

          <!--  Global Attributes    -->
          <div>

            <pm-attribute-grid *ngIf="!!supplierCasePackService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierCasePackService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierCasePackService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of supplierCasePackService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(supplierCasePackService.candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(supplierCasePackService.candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>

          <!--   Mat Hierarchy   -->
          <div *ngIf="!!supplierCasePackService.candidate?.candidateProducts[1]?.matHierarchyList?.length">
            <div *ngFor="let hierarchy of supplierCasePackService.candidate.candidateProducts[1].matHierarchyList">
              <pm-attribute-grid *ngIf="!!hierarchy?.attributes?.length" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(supplierCasePackService.candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>

      </div>


      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="false">
              <div *ngIf="!!supplierCasePackService.candidate?.supplierComment">
                <b>{{supplierCasePackService.candidate?.contactEmail}}</b> <br>
                {{supplierCasePackService.candidate?.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!supplierCasePackService.candidate.buyerCommentUser">
                <b>{{supplierCasePackService.candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!supplierCasePackService.candidate.buyerComment">
                {{supplierCasePackService.candidate.buyerComment}}
              </div>

              <br>

              <div *ngIf="!!supplierCasePackService.candidate.scaComment">
                <b>{{supplierCasePackService.candidate.scaName}}</b> <br>
                {{supplierCasePackService.candidate.scaComment}}
              </div>
              <br>

              <div *ngIf="!!supplierCasePackService.candidate.piaComment">
                <b>{{supplierCasePackService.candidate.piaCommentUser}}</b> <br>
                {{supplierCasePackService.candidate.piaComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <!--  Audit History -->
  <pm-review-drawer-container >
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="historyService.sortedAudits">
            <div *ngIf="historyService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{historyService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button *ngIf="isClosedOrUnderReview" id="backToHome" class="ghost m-2" label="Back to home" (onClick)="onClickBackToHome()" [disabled]="!canEditAndResubmit"></p-button>
    <p-button class="ghost m-2" label="Back" *ngIf="!isRejected() && !isClosedOrUnderReview" (onClick)="onClickBack() " [disabled]="!canNavigate"></p-button>
    <p-button class="ghost m-2" label="Submit" *ngIf="!isRejected() && !isClosedOrUnderReview" (onClick)="onClickSubmit(false)" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Print" (onClick)="onClickPrint()" [disabled]="!canNavigate"></p-button>
    <p-button class="ghost" label="Edit & Resubmit" *ngIf="isRejected()" (onClick)="resubmitCandidate()" [disabled]="!canEditAndResubmit || !canNavigate"></p-button>
  </pm-footer>
</pm-review>
