export class File {
  clipId: string;
  uuid: string;
  data: any;
  fileRoot: string;
  fullFile: any;
  name: string;
  type: string;
  size: number;
  resolutionX: number;
  resolutionY: number;
  category: string;
  status: string;
  salesChannels: string[];
}
