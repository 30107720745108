<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4 helper-col">
    <div *ngIf="lengthHelpVisible" class="helper-image" [style.top]="lengthHelperOffset">
      <pm-card>
        <div class="row">
          <div class="col-6">
            <img src="assets/images/pam-tie.png" class="img-fluid" />
          </div>
          <div class="col-6">
            <h3>Tie</h3>
            <p>
              How many boxes are on one layer?
            </p>
            <p>
              This example has 5 boxes per layer.
            </p>
          </div>
        </div>
      </pm-card>
    </div>
    <div *ngIf="widthHelpVisible" class="helper-image" [style.top]="widthHelperOffset">
      <pm-card>
        <div class="row">
          <div class="col-6">
            <img src="assets/images/pam-tier.png" class="img-fluid" />
          </div>
          <div class="col-6">
            <h3>Tier</h3>
            <p>
              How many layers high is this pallet?
            </p>
            <p>
              This example has 3 layers.
            </p>
          </div>
        </div>
      </pm-card>
    </div>
  </div>
</div>

<ng-template #card>
  <pm-card title="Master pack details"
           subtitle="Tell us about the pallet coming to the warehouse.">
    <ng-container *ngIf="model">
      <pm-attribute-display-text-input [attribute]="totalRetailUnitsConfiguration" [(model)]="model.masterPack"
        [attributeError]="errorModel?.masterPack" (modelChange)="totalRetailChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-volume [attribute]="dimensionsConfiguration" [(lengthModel)]="model.masterLength"
        [(widthModel)]="model.masterWidth" [(heightModel)]="model.masterHeight" (volumeModelChange)="volumeCalc($event)"
        (lengthModelChange)="dimensionChange($event)" (widthModelChange)="dimensionChange($event)"
        (heightModelChange)="dimensionChange($event)" [attributeWidthError]="errorModel?.masterWidth"
        [attributeHeightError]="errorModel?.masterHeight" [attributeLengthError]="errorModel?.masterLength"
        [divisor]="1728">
      </pm-attribute-display-volume>

      <pm-attribute-display-text-input [attribute]="weightConfiguration" [(model)]="model.masterWeight"
        [attributeError]="errorModel?.masterWeight" (modelChange)="masterWeightChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="orderFactorConfiguration" [(model)]="model.cubeAdjustedFactor"
        [attributeError]="errorModel?.cubeAdjustedFactor" (change)="orderFactorChange($event)">
      </pm-attribute-display-text-input>


      <pm-attribute-display-area [attribute]="boxesOnPalletConfig" [(lengthModel)]="model.vendorTie"
        [(widthModel)]="model.vendorTier" [(areaModel)]="areaModel" (change)="onAreaChange($event)"
        (lengthFocus)="toggleLengthHelp($event, true)" (lengthBlur)="toggleLengthHelp($event, false)"
        (lengthModelChange)="dimensionChange($event)" (widthFocus)="toggleWidthHelp($event, true)"
        (widthBlur)="toggleWidthHelp($event, false)" (widthModelChange)="dimensionChange($event)"
        [attributeLengthError]="errorModel?.vendorTie" [attributeWidthError]="errorModel?.vendorTier">
      </pm-attribute-display-area>

    </ng-container>

  </pm-card>
</ng-template>
