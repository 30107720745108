export class NewUpcRequestError {

  description?: string;

  buyer?: string;

  vendor?: string;

  upcRequestNotes?: string;

  count?: string;
}
