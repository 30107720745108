import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Candidate, CandidateError} from 'pm-models';
import {SellableErrors, SellableModel, SellablePermissions} from 'pm-models/lib/card-models/sellable-model';

@Component({
  selector: 'pm-sellable',
  templateUrl: './sellable.component.html',
  styleUrls: ['./sellable.component.scss']
})
export class SellableComponent implements OnInit, OnChanges {
  @Input()
  model: SellableModel | Candidate;

  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: SellablePermissions;

  @Input()
  errorModel: SellableErrors | CandidateError;

  SELLABLE = Candidate.SELLABLE;
  NON_SELLABLE = Candidate.NON_SELLABLE;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(): void {
  }

  onChange(event) {
    this.modelChange.emit(this.model);
  }

  isComponentDisabledOrReadOnly(): boolean {
    return !this.permissions || this.permissions.isReadOnly || this.permissions.isDisabled;
  }

  isSellableDisabledOrReadOnly(): boolean {
    return this.isComponentDisabledOrReadOnly() ||
      (this.permissions.sellableSwitch &&
        (this.permissions.sellableSwitch.isDisabled ||
          this.permissions.sellableSwitch.isReadOnly));
  }

  isNonSellableDisabledOrReadOnly(): boolean {
    return this.isComponentDisabledOrReadOnly() ||
      (this.permissions.nonSellableSwitch &&
        (this.permissions.nonSellableSwitch.isDisabled ||
          this.permissions.nonSellableSwitch.isReadOnly));
  }
}
