import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupplierDetailsCardComponent} from './supplier-details-card.component';

import {CardModule} from '../../shared/card/card.module';

import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {StaticOverlayPanelModule} from '../../shared/overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {RequestNewAttributeConfirmModule} from '../../shared/request-new-attribute-confirm/request-new-attribute-confirm-module';
import {RequestNewAttributeFormModule} from '../../shared/request-new-attribute-form/request-new-attribute-form-module';
import {AuthService} from '../../../../../../src/app/2.0.0/auth/auth.service';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {NgxPermissionsModule, NgxPermissionsService} from 'ngx-permissions';
import {NgxPermissionsServiceMock} from './ngx-permissions-service-mock.service';
import {WorkflowService} from '../../../../../../src/app/2.0.0/service/workflow.service';
import {WorkflowServiceMock} from '../regulatory-card/workflow-service-mock.service';


@NgModule({
  declarations: [SupplierDetailsCardComponent],
  exports: [SupplierDetailsCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule,
    AttributeDisplayModule, RequestNewAttributeConfirmModule, StaticOverlayPanelModule, RequestNewAttributeFormModule, NgxPermissionsModule

  ],
  providers: [{ provide: AuthService, useValue: undefined },
    { provide: LookupService, useValue: undefined }, { provide: CandidateService, useValue: undefined },
    {provide: NgxPermissionsService, useClass: NgxPermissionsServiceMock},
    { provide: WorkflowService, useClass: WorkflowServiceMock }]
})
export class SupplierDetailsCardModule { }
