import {Component, OnInit} from '@angular/core';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  LocationGroupStores,
  TextInputType
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {SelectItem} from 'primeng/api';
import {CandidateUtilService} from '../../service/candidate-util.service';

@Component({
  selector: 'app-invited-distributor-store-authorization',
  templateUrl: './invited-distributor-store-authorization.component.html',
  styleUrls: ['./invited-distributor-store-authorization.component.scss']
})
export class InvitedDistributorStoreAuthorizationComponent implements OnInit {

  public currentCandidateProductIndex;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  private taskSubscription$: any;
  public distributorName: any;
  public showNoStoresError: boolean;
  public canClickSubmit = true;
  public storesInput: any = '';
  public storesErrorMessage: string;
  public selectAllAuthGroups: boolean;
  public STORES = 'stores';
  public GROUPS = 'groups';

  types: SelectItem[];
  selectedType: string = 'stores';
  storesInputConfiguration: AttributeTextInputConfig = {
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'storesListId',
    textInputType: TextInputType.textarea,
    inputGroupClass: 'find-stores-by-selec',
    placeholderText: '',
    description: 'Separate store numbers by a comma or enter each store on a separate line.',
  };

  allGroupsConfiguration: AttributeConfig = {
    label: 'All groups',
    description: 'Show stores in all groups',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'allGroupsId',
    defaultValue: false
  };

  constructor(public invitedDistributorService: InvitedDistributorService, private route: ActivatedRoute, private router: Router,
              private candidateUtilService: CandidateUtilService) {
    this.types = [
      { label: 'Stores', value: this.STORES },
      { label: 'Groups', value: this.GROUPS }
    ];
  }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.invitedDistributorService.getTaskId(),
        this.invitedDistributorService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.invitedDistributorService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          this.candidateError = this.invitedDistributorService.getStoreAuthError();
          this.candidateProductError = this.invitedDistributorService.getCurrentCandidateProductError(this.candidateError);
          this.initializeData();
          this.invitedDistributorService.initializeProductData().subscribe();
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.invitedDistributorService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.invitedDistributorService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.invitedDistributorService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.invitedDistributorService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  private getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  initializeData() {
    this.distributorName = this.invitedDistributorService.getCandidate().vendor.description;
    this.setAuthGroups();
    if (this.invitedDistributorService.getStoresInput()) {
      this.storesInput = this.invitedDistributorService.getStoresInput();
    } else {
      this.onFilterChange();
    }
  }

  onClose() {
    this.invitedDistributorService.saveCandidateAndNavigate('/tasks', false);
  }

  setAuthGroups() {
    if (!this.invitedDistributorService.getAuthGroups()) {

      this.invitedDistributorService.findAllAuthGroups().subscribe((authGroups) => {
        this.invitedDistributorService.setAuthGroups(authGroups);
        this.showNoStoresError = this.invitedDistributorService.validateHasNoStores();
        for (let x = 0; x < this.invitedDistributorService.getAuthGroups().length; x++) {
          this.invitedDistributorService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
      });
    } else {
      this.showNoStoresError = this.invitedDistributorService.validateHasNoStores();
      for (let x = 0; x < this.invitedDistributorService.getAuthGroups().length; x++) {
        this.invitedDistributorService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
      }
    }
  }

  onClickBack() {
    this.invitedDistributorService.saveCandidateAndNavigate('/invitedDistributorCaseDetails', false);
  }

  storesInputChangeEvent(storesInput: any) {
    this.storesErrorMessage = this.validateStoresInput(storesInput);
  }

  isShowTheseStoresDisabled() {
    return this.selectedType === this.STORES &&
      (this.storesErrorMessage || !this.storesInput);
  }

  /**
   * Stores input should be comma or newline separated group of store numbers.
   * @param storesInput
   */
  validateStoresInput(storesInput: any) {
    const stores = storesInput.trim().replace(/,/g, '\n');
    const storesArray = stores.split('\n');
    const regexp: RegExp = /^\d+$/; // only numbers

    let result;

    for (let i = 0; i < storesArray.length; i++) {
      // make sure all stores are a number
      if (!regexp.test(storesArray[i])) {
        result = 'Please enter store numbers, separated by a comma or each store on a new line.';
        break;
      }
    }
    if (!result) {
      this.invitedDistributorService.setStoresSearched(storesArray);
      this.invitedDistributorService.setStoresInput(storesInput);
    }
    return result;
  }

  onClickAllAuthGroupCheckbox(event) {
    for (let x = 0; x < this.invitedDistributorService.getAuthGroups().length; x++) {
      this.invitedDistributorService.getAuthGroups()[x].checked = event;
    }
    // trigger update
    this.invitedDistributorService.setAuthGroups(JSON.parse(JSON.stringify(this.invitedDistributorService.getAuthGroups())));
  }

  onClickShowAllStores() {
    this.invitedDistributorService.setSelectedAuthGroups(this.invitedDistributorService.getAuthGroups());
    this.invitedDistributorService.openAuthGroups();
    this.invitedDistributorService.setNotFoundStores(null);
    this.router.navigate(['/setUpInvitedDistributorStores'], {queryParams: {taskId: this.invitedDistributorService.getTaskId()}});

  }

  onClickGroupShowStores() {

    const selectedAuthGroups: LocationGroupStores[] = [];

    for (let x = 0; x < this.invitedDistributorService.getAuthGroups().length; x++) {
      if (this.invitedDistributorService.getAuthGroups()[x].checked) {
        selectedAuthGroups.push(JSON.parse(JSON.stringify(this.invitedDistributorService.getAuthGroups()[x])));
      }
    }
    this.invitedDistributorService.setSelectedAuthGroups(selectedAuthGroups);
    this.invitedDistributorService.openAuthGroups();
    this.router.navigate(['/setUpInvitedDistributorStores'], {queryParams: {taskId: this.invitedDistributorService.getTaskId()}});

  }

  onClickShowTheseStores() {
    if (!this.isShowTheseStoresDisabled()) {
      this.invitedDistributorService.setNotFoundStores(null);
      this.invitedDistributorService.setSelectedAuthGroups(this.invitedDistributorService.getAuthGroups());
      for (let x = 0; x < this.invitedDistributorService.getStoresSearched().length; x++) {
        this.findAndCheckStore(this.invitedDistributorService.getStoresSearched()[x]);
      }
      this.setAuthGroupCheckboxesAndHideNonSelectedStores();
      this.router.navigate(['/setUpInvitedDistributorStores'], {queryParams: {taskId: this.invitedDistributorService.getTaskId()}});
    }
  }

  hideNonSelectedStores() {
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      if (!this.invitedDistributorService.getSelectedAuthGroups()[x].isViewing) {
        continue;
      }
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (!this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked) {
          this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].isHidden = true;
        }
      }
    }
  }

  findAndCheckStore(storeNumber) {
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (+storeNumber === this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].custLocationNumber) {
          this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked = true;
          this.invitedDistributorService.getSelectedAuthGroups()[x].isViewing = true;
          return;
        }
      }
    }
    // if store not found-
    this.invitedDistributorService.getNotFoundStores().push(storeNumber);
  }

  /**
   * Sets auth groups to checked if all stores are checked.
   */
  setAuthGroupCheckboxesAndHideNonSelectedStores() {
    let areAllStoresChecked = true;
    for (let x = 0; x < this.invitedDistributorService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.invitedDistributorService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (!this.invitedDistributorService.getSelectedAuthGroups()[x].stores[y].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        this.invitedDistributorService.getSelectedAuthGroups()[x].checked = true;
      }

    }
    this.hideNonSelectedStores();
  }

  onAuthGroupButtonClick(index, event) {

    if (!event) {
      this.selectAllAuthGroups = false;
    } else {
      let areAllGroupsChecked = true;
      for (let x = 0; x < this.invitedDistributorService.getAuthGroups().length; x++) {
        if (!this.invitedDistributorService.getAuthGroups()[x].checked) {
          areAllGroupsChecked = false;
        }
      }
      if (areAllGroupsChecked) {
        this.selectAllAuthGroups = true;
      }

    }
  }

  /**
   * On filter changes, set all the checkboxes/viewports to false and remove searched stores.
   */
  onFilterChange() {
    this.invitedDistributorService.setStoresSearched(null);
    this.invitedDistributorService.setStoresInput(null);
    this.invitedDistributorService.setNotFoundStores(null);
    this.storesInput = '';
    this.selectAllAuthGroups = false;
    if (!this.invitedDistributorService.getAuthGroups()) {
      return;
    }
    for (let x = 0; this.invitedDistributorService.getAuthGroups().length; x++) {
      this.invitedDistributorService.getAuthGroups()[x].isViewing = false;
      this.invitedDistributorService.getAuthGroups()[x].checked = false;
      for (let y = 0; y < this.invitedDistributorService.getAuthGroups()[x].stores.length; y++) {
        this.invitedDistributorService.getAuthGroups()[x].stores[y].checked = false;
      }
    }
  }

  hasSelectedGroup() {
    if (!this.invitedDistributorService.getAuthGroups() || this.invitedDistributorService.getAuthGroups().length === 0) {
      return true;
    }
    for (let x = 0; x < this.invitedDistributorService.getAuthGroups().length; x++) {
      if (this.invitedDistributorService.getAuthGroups()[x].checked) {
        return true;
      }
    }
    return false;
  }
}
