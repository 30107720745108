import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RejectCandidateComponent} from './reject-candidate.component';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';


@NgModule({
  declarations: [RejectCandidateComponent],
  exports: [RejectCandidateComponent, AttributeDisplayModule],
  imports: [
    CommonModule, AttributeDisplayModule
  ]
})
export class RejectCandidateModule { }
