import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CostLinkCardComponent } from './cost-link-card.component';

import { CardModule } from '../../shared/card/card.module';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';

@NgModule({
  declarations: [CostLinkCardComponent],
  exports: [CostLinkCardComponent, CardModule, AttributeDisplayModule],
  imports: [CommonModule, CardModule, AttributeDisplayModule]
})
export class CostLinkCardModule {}
