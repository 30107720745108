import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {calculateCheckDigit, getTypeAFormat} from '../../shared/upc.utils';
import {GrowlService} from '../../growl/growl.service';
import {NewUpcRequest} from 'pm-models/lib/newUpcRequest';

@Component({
  selector: 'app-request-heb-upc-success',
  templateUrl: './request-heb-upc-success.component.html',
  styleUrls: ['./request-heb-upc-success.component.scss']
})
export class RequestHebUpcSuccessComponent implements OnInit {

  @Input()
  public upcs: number[];
  @Input()
  public upcRequest: NewUpcRequest;

  multiReserve: boolean = false;
  formattedUpc: string;

  constructor(private router: Router, private growlService: GrowlService) { }

  ngOnInit() {
    if (this.upcs && this.upcs.length > 1) {
      this.multiReserve = true;
    } else if (this.upcs && this.upcs.length === 1) {
      this.formattedUpc = getTypeAFormat(this.upcs[0].toString());
    } else {
      this.growlService.addError('Error! No reserved UPCs returned!');
    }
  }

  onClickReturnHome() {
    this.router.navigate(['/tasks']).then();
  }

  onClickDownloadUpcs() {
    let csvString = 'data:text/csv;charset=utf-8,' + 'UPCs including the check digit,Buyer,Supplier,Notes\n';
    const buyerString = this.upcRequest?.candidate?.buyer ? this.upcRequest?.candidate?.buyer.displayName : 'n/a';
    const supplierString = this.upcRequest?.candidate?.vendor ? this.upcRequest?.candidate?.vendor.displayName : 'n/a';
    const reservationNotes = this.upcRequest?.upcRequestNotes ? this.upcRequest?.upcRequestNotes : 'n/a';

    for (let x = 0;  x < this.upcs.length; x++) {
      csvString += this.upcs[x] + '' + calculateCheckDigit(this.upcs[x]) + ',' + buyerString + ',' + supplierString + ',' + reservationNotes + '\n';
    }

    const encodedUri = encodeURI(csvString);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', this.getFileName());
    link.setAttribute('target', '_blank');
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  private getFileName(): string {
    const date = new Date();
    const yy = date.getFullYear().toString().substring(2);
    const mm = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const dd = date.getDate().toString().padStart(2, '0');
    const dateString = mm + dd + yy;
    return  'OWN_BRAND_UPCs_' + dateString + '.csv';
  }
}
