import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeTypeaheadConfig, CandidateProduct } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class CountryOfOrigin implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'Country of origin',
      description: '',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: '',
      displayRef: 'description',
      placeholderText: 'Select or search a country...',
      searchUrl: '/lookup/countryOfOrigin',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTypeaheadComponent, candidate: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.countryOfOrigin;
    componentInstance.selection.subscribe(x => {
      candidate.countryOfOrigin = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidate: CandidateProduct) {
    if (componentInstance.model !== candidate.countryOfOrigin) {
      componentInstance.model = candidate.countryOfOrigin;
    }
  }
}
