//
// This only includes what is needed for the Task Tables
//
import {Task} from 'pm-models';

export class TaskListTask {

  //
  // These fields come from the workflow Task service (TaskImpl)
  //
  id: string;
  candidateId: number;
  name: string;
  processInstanceId: string;

  //
  // These fields come from the candidate search service
  //
  candidateDescription: string;
  elapsedTime: Date;
  lastUpdatedBy: string;
  lastUpdatedTime: Date;
  buyerId: string;
  buyerDisplayName: string;
  supplierId: string;
  supplierDisplayName: string;
  taskType: string;
  upc?: string;
  itemCode?: string;
  status?: string;
  vendorComment?: string;
  candidateProductDescription?: string;
  childCandidates?: TaskListChildCandidate[];
  warehouseSupplierId: string;
  warehouseSupplierDisplayName: string;
  associateUpcs: number[];
  channel: string;
  productTypeId: string;
  codeDate: boolean;
  departmentId: string;
  subDepartmentId: string;

  //
  // The rest of these are calculated fields
  //
  displayName: string;
  count?: number;
  canDelete?: boolean = false;
  rushFlag?: boolean = false;

  checked?: boolean = false;
  createTime: Date;

  public constructor(id: string, candidateId: number, name?: string, processInstanceId?: string, rushFlag?: boolean, createTime?: Date) {
    this.id = id;
    this.candidateId = candidateId;
    this.processInstanceId = processInstanceId;
    if (rushFlag) {
      this.rushFlag = rushFlag;
    }

    if (name) {
      this.name = name;
      this.canDelete =
        (name === Task.PIA_NEW_PRODUCT_FLOW) ||
        (name === Task.KEY_VENDOR_DATA) ||
        (name === Task.ASSOCIATE_VENDOR_TASK_NAME) ||
        (name === Task.PHARM_NEW_PRODUCT_FLOW) ||
        (name === Task.DSD_INVITED_SUPPLIER_VENDOR_TASK_NAME) ||
        (name === Task.PIA_OB_REG_FLOW_SUBMIT);
    }
    this.createTime = createTime;
  }
}

export class TaskListChildCandidate {
  candidateId: number;
  lastUpdatedBy: string;
  lastUpdatedTime: Date;
  supplierDisplayName: string;
}
