import {Component, OnInit} from '@angular/core';
import {Candidate, CandidateError, CandidateProductError, CandidateValidatorType, Task, TaskDecision} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {ProcessVariables} from 'pm-models/lib/processVariables';
import {ProcessInstanceConstants} from '../../utils/constants/process-instance-constants';
import {tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-supplier-mrt-review',
  templateUrl: './supplier-mrt-review.component.html',
  styleUrls: ['./supplier-mrt-review.component.scss']
})
export class SupplierMrtReviewComponent implements OnInit {

  public isViewingPage = true;
  public mrtCandidateInners: any[] = [];
  public canNavigate = true;
  public innerFlowStepperIndex;
  public noInnerFlowStepperIndex;

  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private growlService: GrowlService, private lookupService: LookupService,
              private workflowService: WorkflowService, private candidateUtilService: CandidateUtilService, public permissionService: NgxPermissionsService) { }


  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierMrtService.resetService();
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          if (candidate.candidateType === Candidate.MRT) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.resetErrors();
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']).then();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierMrtService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  initializeData() {
    if (this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.innerFlowStepperIndex = 5;
      this.noInnerFlowStepperIndex = 4;
    } else {
      this.innerFlowStepperIndex = 4;
      this.noInnerFlowStepperIndex = 3;
    }
    this.setInnerCandidates();
  }

  setInnerCandidates() {
    this.supplierMrtService.setInnerCandidates(true).pipe(
      tap(() => {
        if (!!this.supplierMrtService.getCandidate().mrtInfo?.candidateInners?.length) {
          const nonRep = [];
          const rep = [];
          this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
            if (candidateInner.replenishable) {
              const returnedCandidate = this.addNewItemInnersAndValidate(candidateInner);
              if (returnedCandidate) {
                rep.push(returnedCandidate);
              }
            } else {
              const returnedCandidate = this.addNewItemInnersAndValidate(candidateInner);
              if (returnedCandidate) {
                nonRep.push(returnedCandidate);
              }
            }
          });
          if (nonRep.length > 0) {
            this.candidateService.validateBulkCandidate(nonRep,
              [CandidateValidatorType.MRT_INNER_PRODUCT_DETAILS_VALIDATOR,
                CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR]).subscribe((result) => {
              for (const candidate of Object.keys(result)) {
                if (result[candidate]?.candidateError) {
                  this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
                    if (result[candidate]?.candidate.candidateId === candidateInner.candidateId) {
                      candidateInner.valid = false;
                    }
                  });
                } else {
                  this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
                    if (result[candidate]?.candidate.candidateId === candidateInner.candidateId) {
                      candidateInner.valid = true;
                    }
                  });
                }
              }
            });
          }
          if (rep.length > 0) {
            this.candidateService.validateBulkCandidate(rep,
              [CandidateValidatorType.MRT_INNER_PRODUCT_DETAILS_VALIDATOR,
                CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR, CandidateValidatorType.ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR]).subscribe((result) => {
              for (const candidate of Object.keys(result)) {
                if (result[candidate]?.candidateError) {
                  this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
                    if (result[candidate]?.candidate.candidateId === candidateInner.candidateId) {
                      candidateInner.valid = false;
                    }
                  });
                } else {
                  this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
                    if (result[candidate]?.candidate.candidateId === candidateInner.candidateId) {
                      candidateInner.valid = true;
                    }
                  });
                }
              }
            });
          }
        }
      })
    ).subscribe();
  }

  addNewItemInnersAndValidate(candidateInner: CandidateInner) {
    if (candidateInner.candidate.candidateType === Candidate.MRT_INNER && !candidateInner.draft) {
      this.mrtCandidateInners.unshift(candidateInner);
      if (candidateInner.replenishable === null || candidateInner.replenishable === undefined) {
        candidateInner.valid = false;
        return;
      }
      return candidateInner.candidate;
    } else {
      this.mrtCandidateInners.push(candidateInner);
      return candidateInner.candidate;
    }
  }

  onClose() {
    this.isViewingPage = false;
    this.router.navigate(['/tasks']).then();
  }

  onClickBack() {
    const url = !!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT') ? '/mrtExtendedAttributes' : '/mrtCaseDetails';
    this.jumpBack(url);
  }

  onClickSubmit() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.supplierMrtService.getCandidate(), [CandidateValidatorType.SUPPLIER_SETUP_MRT_ITEMS_VALIDATOR,
      CandidateValidatorType.MRT_CASE_DETAILS_VALIDATOR, CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR]).subscribe(() => {
      if (this.areInnersValid()) {
        this.completeTaskAndCreateSubTasks();
      } else {
        this.canNavigate = true;
        this.growlService.addError('Invalid Candidate Inners. Please fix the errors and resubmit.');
      }
    }, (error) => {
      this.canNavigate = true;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc or check digit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error?.candidateErrors) {
        this.supplierMrtService.updateMrtErrors(error.error.candidateErrors);
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  completeTaskAndCreateSubTasks() {
    const processVariables: ProcessVariables[] = [];
    if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners &&
      this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length > 0) {

      let candidateInner: CandidateInner;
      for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
        candidateInner = this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x];
        if (candidateInner.candidate.candidateType === Candidate.MRT_INNER && candidateInner.replenishable && !candidateInner.draft) {
          // add process variable to create NON REPLENISHABLE task in buyer queue.
          processVariables.push({
            candidateId: candidateInner.candidate.candidateId, processDefinition: ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_WORKFLOW,
            apNumber: candidateInner.candidate.vendor?.apNumber, moveToWorkflowState: Task.BUYER_DETAILS_ACTIVITY_ID
          });
        } else if (candidateInner.candidate.candidateType === Candidate.MRT_INNER && !candidateInner.replenishable
          && !candidateInner.draft) {
          // add process variable to create NON REPLENISHABLE task in buyer queue.
          processVariables.push({
            candidateId: candidateInner.candidate.candidateId, processDefinition: ProcessInstanceConstants.NEW_PRODUCT_DSD_WORKFLOW,
            apNumber: candidateInner.candidate.vendor?.apNumber, moveToWorkflowState: Task.BUYER_DETAILS_ACTIVITY_ID
          });
        }
      }
    }

    // if there's inner tasks to create, create them then submit the MRT task to the buyer.
    if (processVariables.length > 0) {
      this.workflowService.createMultipleTasks(processVariables).subscribe(() => {
        this.supplierMrtService.saveAndCompleteTaskAndRouteToUrlWithCandidateId(WorkflowService.ACTION_COMPLETE,
          TaskDecision.KEY_VENDOR_DATA_DECISION_NO, '/mrtCaseDetailsReview', this.supplierMrtService.getCandidate().candidateId);
      });
      // else just submit the MRT task to the buyer.
    } else {
      this.supplierMrtService.saveAndCompleteTaskAndRouteToUrlWithCandidateId(WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_VENDOR_DATA_DECISION_NO, '/mrtCaseDetailsReview', this.supplierMrtService.getCandidate().candidateId);
    }
  }

  isNewItem(inner: CandidateInner) {
    return inner.candidate.candidateType === Candidate.MRT_INNER;
  }

  isNewCase(inner: CandidateInner) {
    return inner.candidate.candidateType === Candidate.MRT_INNER && inner.replenishable;
  }

  areInnersValid() {
    if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners) {
      for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
        if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate.candidateType === Candidate.MRT_INNER &&
          !this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].draft &&
          !this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].valid) {
          return false;
        }
      }
    }
    return true;
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }

    switch (stepperItem.text) {
      case SupplierMrtService.UPC_ITEM_CODES: {
        this.jumpBack('/setupMrt');
        break;
      }
      case SupplierMrtService.NEW_ITEM_SETUP: {
        this.jumpBack('/setUpNewMrtItems');
        break;
      }
      case SupplierMrtService.MRT_DETAILS: {
        this.jumpBack('/mrtCaseDetails');
        break;
      }
      case SupplierMrtService.EXTENDED_ATTRIBUTES: {
        this.jumpBack('/mrtExtendedAttributes');
        break;
      }
    }
  }

  jumpBack(urlToNavigate) {
    this.canNavigate = false;
    this.supplierMrtService.saveCandidateAndNavigate(urlToNavigate, {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
  }
}
