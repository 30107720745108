import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FilterSearchPipe} from './filterSearch/filter-search';
import {FilterByNameSearchPipe} from './filterSearch/filter-search-name';
import {UpcPipe} from './upc/upc.pipe';
import {DateStandardPipe} from './dateStandard/date-standard-pipe';
import {TotalTimePipe} from './totalTime/total-time-pipe';
import {YesNoPipe} from './yesNo/yes-no-pipe';
import {EmptyToValuePipe} from './emptyToValue/empty-to-value';

@NgModule({
  declarations: [ UpcPipe, DateStandardPipe, TotalTimePipe, YesNoPipe, FilterSearchPipe, FilterByNameSearchPipe, EmptyToValuePipe],
  exports: [ UpcPipe, DateStandardPipe, TotalTimePipe, YesNoPipe, FilterSearchPipe, FilterByNameSearchPipe, EmptyToValuePipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
