import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuComponent } from './menu.component';
import { MenuItemComponent } from './menu-item.component';


import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [MenuComponent, MenuItemComponent],
  exports: [MenuComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class MenuModule { }
