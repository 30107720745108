<div class="pm-overlay-body">
  <div class="container">

    <div class="row">
      <div class="pm-overlay-header px-3 py-4 col" >
        <pm-close (click)="closeEvent.emit()"></pm-close>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col col-lg-8">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

</div>
