import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfigService {
  private appConfig;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return this.http.get('/assets/data/appConfig.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  getConfig() {
    return this.appConfig;
  }

  isSsoEnabled(): Boolean {
    if (this.appConfig.ssoEnabled === 'false') {
      return false;
    } else if (this.appConfig.ssoEnabled === 'true') {
      return true;
    }
  }
}
