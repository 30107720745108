<p-confirmDialog #cd>
  <p-footer>
    <button type="button" pButton id="confirm" icon="fa-check" label="OK" style="background-color: green;" (click)="cd.accept()"></button>
    <button type="button" pButton id="reject" icon="fa-close" label="Cancel" style="background-color: red;" (click)="cd.reject()"></button>
  </p-footer>
</p-confirmDialog>

<div *ngIf="authorization.hasMoreThanOneRole()" class="multi-role-select">
  <ng-container *ngIf="authorization.hasMoreThanOneRole()">
    <pm-attribute-display-dropdown [attribute]="roleConfiguration" [(model)]="role" (modelChange)="roleChange($event)">
    </pm-attribute-display-dropdown>
  </ng-container>
</div>

<div *ngIf="!reloadingPage">
  <div class="row mx-3 my-2 clearfix d-flex align-items-center">
    <h1 class="p-2 mb-0" style="text-align: left;">{{blurbTitleString}}</h1>

    <ng-container *ngIf="authorization.isVendor() || authorization.isPia() || authorization.isPharm()">
      <button pButton type="button" id="createCandidateButton" class="ui-button-info p-2" (click)="createCandidate()" label="Create New Candidate"></button>
    </ng-container>
  </div>

  <div class="ml-auto" style="margin-bottom: 2em;">
    <div class="float-left">
      <div class="col-md-12">

        <!-- Buyer, SCA, OBREG roles; table with no tabs -->
        <app-task-viewer-table *ngIf="!authorization.isPia() && !authorization.isVendor() && !authorization.isPharm()"
                               [isBuyer]="authorization.isBuyer()" [isSca]="authorization.isSca()"></app-task-viewer-table>

        <!-- PIA role -->
        <app-pia-task-viewer-table *ngIf="authorization.isPia()" [isPia]="authorization.isPia()"></app-pia-task-viewer-table>

        <!-- Supplier role -->
        <app-vendor-task-viewer-table *ngIf="authorization.isVendor()"></app-vendor-task-viewer-table>

        <!-- Pharm role -->
        <app-pharm-task-viewer-table *ngIf="authorization.isPharm()"></app-pharm-task-viewer-table>

      </div>
    </div>
  </div>
</div>
