<div class="row justify-content-md-center reserved-upcs">
  <div class="col-md-8">
    <div class="row headers">
      <div class="col">
        <div class="row">
          <div class="col">
            <h1 class="mb-2">Reserved UPCs</h1>
          </div>
          <div class="col text-right">
            <p-button class="m-2" label="Reserve a UPC" (onClick)="onClickReserveUpc()"></p-button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>These UPCs have been reserved for Own Brand products. Own Brand UPCs begin with 197870 & 41220.</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showFilters" class="row justify-content-left">
      <div *ngIf="buyers.length > 0" class="col-md-5">
        <pm-buyer-drop-down-filter [hidden]="showSpinner" [buyers]="buyers" [buyerFilters]="buyerFilters"
                                   (dropDownChange)="onApplyBuyerFilter($event)">
        </pm-buyer-drop-down-filter>
      </div>
      <div *ngIf="suppliers.length > 0" class="col-md-5">
        <pm-supplier-drop-down-filter [hidden]="showSpinner" [suppliers]="suppliers" [supplierFilters]="supplierFilters"
                                      (dropDownChange)="onApplySupplierFilter($event)">
        </pm-supplier-drop-down-filter>
      </div>
    </div>
    <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

    <p-table class="reserved-upcs-table" selectionMode="single"
             [hidden]="showSpinner"
             [sortOrder]="-1"
             [rowHover]="true"
             [columns]="reservedUpcsHeaderColumns"
             [value]="upcReservations"
             [paginator]="true"
             [totalRecords]="recordCount"
             [rows]="PAGE_SIZE"
             [lazy]="true"
             (onLazyLoad)="onLoadReservations($event)"
             (onRowSelect)="onClickRow($event)">
      <ng-template pTemplate="header" let-upcReservationColumns>

        <tr>
          <th *ngFor="let col of upcReservationColumns" [pSortableColumn]="col.field"
              [pSortableColumnDisabled]="!col.sortable" [ngStyle]="{'width' : col.width}">
            {{col.header}}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-upcReservation let-index="rowIndex">
        <tr style="height: 16px !important;" [pSelectableRow]="upcReservation">

          <td>{{upcReservation?.upc}}</td>
          <td>{{upcReservation?.description}}</td>
          <td>{{upcReservation?.vendor?.displayName}}</td>
          <td>{{upcReservation?.buyer?.displayName}}</td>
          <td>{{getDateString(upcReservation?.expirationDate)}}</td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</div>
