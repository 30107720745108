import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {AuthService} from '../auth/auth.service';
import {GrowlService} from '../growl/growl.service';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  DsdItem,
  Item,
  Product,
  Task,
  TextInputType,
  Upc,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {WorkflowService} from '../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../service/candidate.service';
import {LookupService} from '../service/lookup.service';
import {CostService} from '../service/cost.service';
import {ProductService} from '../service/product.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {FileService} from '../service/file.service';
import {AttributeTypes, ReviewComponent} from 'pm-components';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {calculateCheckDigit} from '../shared/upc.utils';
import {MatUtilService} from '../service/mat-util.service';
import {SupplierCasePackService} from '../service/supplier-case-pack.service';
import {Observable} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-sca-additional-case-pack',
  templateUrl: './sca-additional-case-pack.component.html',
  styleUrls: ['./sca-additional-case-pack.component.scss']
})
export class ScaAdditionalCasePackComponent implements OnInit {
  @ViewChild(ReviewComponent) pmReview;

  public warehouseMissingNotesEntered: boolean = false;

  public WAREHOUSE_TASK_NAME = 'Assign Warehouse';
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public UPC = 'UPC';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public searchedCandidateProductIndex = 0;
  showMatAttributes = false;
  isLoadingMatData = true;
  private openCommentDrawer: boolean;
  public tempCommentHolder: string;

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute, public router: Router,
              public candidateService: CandidateService, public lookupService: LookupService, public growlService: GrowlService,
              public costService: CostService, public productService: ProductService, public fileService: FileService,
              public editCandidateModalService: EditCandidateModalService, public candidateUtilService: CandidateUtilService,
              public authService: AuthService, public permissionService: NgxPermissionsService, public matUtilService: MatUtilService,
              public supplierCasePackService: SupplierCasePackService) { }

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  private taskSubscription$: any;
  private task: Task;
  public isViewingPage = true;
  public productData: Product;
  public upc: Upc;
  public item: Item;
  public dsdItem: DsdItem;
  public productInfoString: string;
  public displayingExistingCasesDrawer = false;
  public displayingScaInfo = false;
  public departmentId = undefined;
  public commodities: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public productImages = [];
  public pennyProfit: any;
  public margin;
  public warehouseData = [];
  public warehouses: any = undefined;
  public orderRestrictions: any[];
  public isApproveDisabled = false;
  public scanCodeCheckDigit: number;
  public decimalCount = 1;

  public isPageEditable: boolean = false;

  missingWarehousesCommentsConfiguration: AttributeTextInputConfig = {
    label: 'Notes for Procurement Support',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-half-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: '',
    name: 'missingWarehouseCommentId',
    maxLength: 4000,
    isRequired: true
  };

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  scaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'scaCommentId',
    maxLength: 4000
  };

  private setIsPageEditable() {
    this.isPageEditable = this.authService.isSca();
  }

  ngOnInit() {

    this.supplierCasePackService.resetService();
    this.setIsPageEditable();

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.WAREHOUSE_TASK_NAME) {
              this.router.navigate(
                ['/tasks'], { queryParams: { growlMessage: 'Candidate is not in ' + this.WAREHOUSE_TASK_NAME +
                      ' status.', growlToUse: GrowlService.SEVERITY_ERROR }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                if (candidate.candidateType !== Candidate.ADDITIONAL_CASE_PACK) {
                  this.router.navigate(['/tasks'], {
                    queryParams: {growlMessage: 'Invalid task type.', growlToUse: GrowlService.SEVERITY_ERROR}
                  }).then();
                }
                this.costService.updateCostLink(candidate).subscribe((updatedCandidate) => {
                  this.setInitialValues(updatedCandidate);
                });
              });

          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );    this.getWarehouses(candidate);
    this.getOrderRestrictions();
    this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe();
    if (this.candidate.missingWarehousesComment && this.candidate.missingWarehousesComment.length > 0) {
      this.warehouseMissingNotesEntered = true;
    }
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    if (this.candidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({},
              this.candidateProduct.warehouses[y], JSON.parse(JSON.stringify(this.candidateProduct.warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }
  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[1];

    this.candidate.scaName = this.authService.getUser();
    if (this.candidate.scaComment) {
      this.tempCommentHolder = this.candidate.scaComment;
    }
  }


  setProductData(): Observable<any> {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.getCheckDigit();
          this.setInformationFromProductData(productData);
        })
      );

    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
        })
      );
    }
  }

  private setInformationFromProductData(productData: Product) {
    this.candidate.productId = productData.productId;
    this.item = this.productService.getPrimaryItem(this.candidate.candidateProducts[0], productData);
    if (!this.item) {
      this.dsdItem = this.productService.getPrimaryDsdItem(this.candidate.candidateProducts[0], productData);
    }
    this.productService.getUpcBySearchedValue(this.candidate.candidateProducts[0], productData).subscribe(upc => {
      this.upc = upc;
      this.productService.setCandidateRetailFromUpc(this.candidate, upc);
      this.setProductRetailInfo();
    });
    this.productService.setBuyerAndHierarchyInformation(this.candidate, productData);
    this.productImages.push(this.productService.getProductImageUrl(productData));
    this.departmentId = this.productService.getDepartment(productData);
  }

  setProductRetailInfo() {
    const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
    tempCandidate.retailPrice = this.upc.retailPrice;
    tempCandidate.retailXFor = this.upc.xfor;
    if (!this.productData.priceRequired) {
      tempCandidate.retailType = 'KEY_RETAIL';
    }
    this.pennyProfit = this.costService.getHebPennyProfit(tempCandidate);
    this.margin = this.costService.getHebMargin(tempCandidate);
    this.productInfoString = '' + this.upc.size +
      ' | Inner case cost: '  +  this.costService.getCaseCostAsCurrency(this.candidate) +
      ' | Unit cost: '  +  this.costService.toCurrencyForCost(tempCandidate.unitCost) +
      ' | Retail: '  +  this.costService.toCurrency(this.upc.retailPrice);
  }

  showExistingCasePacks() {
    this.displayingExistingCasesDrawer = true;
    this.displayingScaInfo = false;
    this.pmReview.openDrawer();
  }
  collapse() {
    this.displayingExistingCasesDrawer = false;
    this.displayingScaInfo = false;
    this.pmReview.closeDrawer();
  }

  onClickNext() {
    this.displayingExistingCasesDrawer = false;
    this.displayingScaInfo = true;
    this.pmReview.openDrawer();
  }
  onClose() {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']).then();
    });
  }

  onClickCasePack(casePack) {
    casePack.isViewing = !casePack.isViewing;
  }
  /**
   * Saves candidate.
   */
  save() {
    this.candidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }


  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }
  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  onClickApprove() {
    this.isApproveDisabled = true;
    this.candidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.SCA_PRODUCT_REVIEW_VALIDATOR]).subscribe(() => {
      this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE, 'Successfully completed assign warehouse task.');
    }, (error) => {
      this.isApproveDisabled = false;
      if (error.error.candidateErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        if (this.candidateProductError.errors.length > 0) {
          let errorString = '';
          for (let x = 0; x < this.candidateError.candidateProductErrors[this.candidateProduct.id].errors.length; x++) {
            errorString += this.candidateError.candidateProductErrors[this.candidateProduct.id].errors[x];
          }
          this.growlService.addError(errorString);
        }
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.workflowService.completeTaskWithAction(this.task, action)
        .subscribe(() => {
          this.router.navigate(['/tasks'], {queryParams: {growlMessage: growlMessage}}).then();
        }, (error) => {
          this.growlService.addError(error);
          this.isApproveDisabled = false;
        });
    });
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  getPricingType() {
    return this.productService.getPricingType(this.productData, this.upc);
  }

  editWarehouseSupplier() {
    if (this.candidate.vendor && (!this.candidate.vendor.lanes || this.candidate.vendor.lanes.length === 0)
      && this.candidate.vendor.apNumber) {
      this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe(() => {
        this.showEditCandidateModal(AttributeTypes.Warehouse, {collections: this.candidate.vendor.lanes});
      });
    } else {
      this.showEditCandidateModal(AttributeTypes.Warehouse,
        {collections: this.candidate.vendor ? this.candidate.vendor.lanes : []});
    }
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.resetWarehouseData();
      }
    });
  }

  private resetWarehouseData() {
    if (this.candidate.vendor.lanes && this.candidate.lane.id !== null && this.candidate.lane.id !== undefined) {
      this.lookupService.findWarehousesByVendorApNumberAndLaneId(this.candidate.vendor.apNumber,
        this.candidate.lane.id).subscribe(warehouses => {
        this.warehouses = warehouses;
        this.getWarehouses(this.candidate);
        if (this.candidate.vendor.apNumber !== undefined) {
          this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe();
        }
      });
    } else {
      this.warehouses = [];
      this.warehouseData = [];
      this.candidateProduct.warehouses = [];
    }
    this.setWarehouseData();
  }

  getCheckDigit() {
    if (this.productData && this.productData.primaryScanCodeId) {
      this.scanCodeCheckDigit = calculateCheckDigit(this.productData.primaryScanCodeId);
    } else {
      this.scanCodeCheckDigit = null;
    }
  }

  updatedMissingWarehousesComment() {
    this.warehouseMissingNotesEntered = true;
  }

  warehouseMissingCheckboxChange(event) {
    if (!event) {
      this.warehouseMissingNotesEntered = false;
      this.candidate.missingWarehousesComment = null;
      this.candidate.missingWarehousesCommentUser = null;
      this.candidate.missingWarehousesCommentTime = null;
      this.candidateError.missingWarehousesComment = undefined;
    } else {
      this.candidate.missingWarehousesCommentUser = this.authService.getUser();
      this.candidate.missingWarehousesCommentTime = new Date().getTime();
    }
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }
    this.showMatAttributes = true;
    this.matUtilService.updateMatHierarchyFromProduct(this.candidateProduct, this.productData).pipe(
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
        this.supplierCasePackService.globalAttributes, this.supplierCasePackService.hierarchyAttributes)),
      tap(() => {
        this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierCasePackService.hierarchyAttributes,
          this.supplierCasePackService.hierarchyNumberToAttributesMap);
        this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierCasePackService.globalAttributes,
          [], this.supplierCasePackService.warehouseItemAttributes, []);
      }),
      finalize(() => {
        this.isLoadingMatData = false;
      })).subscribe();

  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.scaComment;  }


  onEditComment() {
    this.openCommentDrawer = true;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.scaComment = this.tempCommentHolder;
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }
}
