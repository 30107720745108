<p-confirmDialog width="425" key="cd1" #cd1>
  <p-footer>
    <button type="button" pButton id="confirm1" label="OK" style="background-color: green;" (click)="cd1.accept()"></button>
    <button type="button" pButton id="reject1" label="Cancel" style="background-color: red;" (click)="cd1.reject()"></button>
  </p-footer>
</p-confirmDialog>

<p-tabView #taskTable [activeIndex]="tabIndex" (onChange)="changeTab($event)">
  <p-tabPanel header="{{OPEN_TAB.title}}" tooltip="{{OPEN_TAB.tooltip}}">
    <div class="object-manager-page">

      <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

      <pm-task-viewer-table [hidden]="showSpinner" [tasks]="tasks"
                            [lazyLoadOn]="true"
                            [totalNumRecords]="totalNumRecords"
                            [pageSize]="taskListService.PAGE_SIZE"
                            (taskClicked)="onClickRow($event)"
                            (loadData)="onLoadData($event)"
                            (deleteClicked)="rejectCandidate($event)"
      >
      </pm-task-viewer-table>
    </div>

  </p-tabPanel>

  <p-tabPanel header="{{UNDER_REVIEW_TAB.title}}" tooltip="{{UNDER_REVIEW_TAB.tooltip}}">
    <div class="object-manager-page">

      <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

      <pm-task-viewer-table-in-progress [hidden]="showSpinner" [tasks]="inProgressTasks"
                            [lazyLoadOn]="true"
                            [totalNumRecords]="inProgressTotalNumRecords"
                            [pageSize]="taskListService.PAGE_SIZE"
                            (taskClicked)="onClickRowNotOpen($event)"
                            (loadData)="onLoadDataInProgress($event)"
      >
      </pm-task-viewer-table-in-progress>
    </div>

  </p-tabPanel>

  <p-tabPanel header="{{REJECTED_TAB.title}}" tooltip="{{REJECTED_TAB.tooltip}}">
    <div class="object-manager-page">

      <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

      <pm-task-viewer-table-rejected [hidden]="showSpinner" [tasks]="rejectedTasks"
                                   [lazyLoadOn]="true"
                                   [totalNumRecords]="rejectedTotalNumRecords"
                                   [pageSize]="taskListService.PAGE_SIZE"
                                   (taskClicked)="onClickRowNotOpen($event)"
                                   (loadData)="onLoadDataRejected($event)"
      >
      </pm-task-viewer-table-rejected>
    </div>
  </p-tabPanel>

  <p-tabPanel header="{{ACTIVATED_TAB.title}}" tooltip="{{ACTIVATED_TAB.tooltip}}">
    <div class="object-manager-page">

      <pm-progress-spinner [showSpinner]="showSpinner" [style]="{ 'width': '30px', 'height': '30px' }"></pm-progress-spinner>

      <pm-task-viewer-table-closed [hidden]="showSpinner" [tasks]="approvedTasks"
                                   [lazyLoadOn]="true"
                                   [totalNumRecords]="approvedTotalNumRecords"
                                   [pageSize]="taskListService.PAGE_SIZE"
                                   (taskClicked)="onClickRowNotOpen($event)"
                                   (loadData)="onLoadDataApproved($event)"
      >
      </pm-task-viewer-table-closed>
    </div>
  </p-tabPanel>

</p-tabView>


<div #retireCpsDiv></div>
<pm-static-overlay-panel #retireCpsOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
  <ng-container>
    <div class="row align-items-center retire-cps-overlay-wrapper">
      <div class="col retire-cps-overlay">
        <div class="header-row">
          <h2>CPS is retiring!</h2>
        </div>
        <p class="ml-2">
          Exciting news! PAM (Product Attribute Management) is replacing CPS,
          and will be the primary system to complete product setup tasks.
        </p>
        <div class="row button-row">
          <p-button class="ghost mr-3" label="Got it" (click)="onClickGotIt()"></p-button>
          <button pButton label="Learn more" (click)="onClickLearnMore()"></button>
        </div>
      </div>
    </div>
  </ng-container>
</pm-static-overlay-panel>
