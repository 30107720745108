<div class="request-body">
  <pm-editor>
  <div class="row">
  <div class="col-md-5 inline-block">
    <img src="/assets/images/new-role-icon.png" class="request-icon" alt="blank">
  </div>
  <div class="col-md-7 role-request-right">
    <div class="row h1">Request Access</div>
    <div class="row description-input">
      <div class="col description-column">
        <div class="h7">Let us know what department you work for, your title, or how you need to use PAM and someone from our
          team will set up your account correctly.</div>
        <pm-attribute-display-text-input [attribute]="requestDescriptionConfiguration" [(model)]="requestDescription">
        </pm-attribute-display-text-input>
      </div>
    </div>
    <div class="row">
      <button class="back-button" (click)="onClose()">Back</button>
      <div class="col-md-8"></div>
      <p-button label="Submit" (onClick)="onNext(requestDescription)" [disabled]="isSubmitButtonsDisabled"></p-button>
    </div>
  </div>
  </div>
</pm-editor>
</div>
