<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">
  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label">
      <label for="">{{attribute.label}}</label>
    </div>
    <div class="attribute-control">
      <p-inputSwitch [ngModel]="model" (onChange)="handleChange($event)"
        [disabled]="isDisabled()" [id]="attribute?.name"></p-inputSwitch>
      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="attributeError" class="attribute-error-message">
        {{attributeError}}
      </p>
      <div *ngIf="showWarningMessage" class="borderLeft col-md-8 justify-content-md-left warning-box">
        <div class="row ml-2">
          <img src="../../../assets/images/info_24px.svg">
          <div class="col ml-2">{{warningMessageText}}</div>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
