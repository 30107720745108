import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AttributeTextInputConfig, EmailMessage, TextInputType} from 'pm-models';
import {AuthService} from '../../auth/auth.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';

@Component({
  selector: 'app-new-user-role-request',
  templateUrl: './new-user-role-request.component.html',
  styleUrls: ['./new-user-role-request.component.scss']
})
export class NewUserRoleRequestComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService,
              private candidateService: CandidateService, private lookupService: LookupService) { }

  private userEmail: string;
  requestDescription: string;
  private pamTeamEmail = 'pamteam@heb.com';
  public isSubmitButtonsDisabled = false;

  requestDescriptionConfiguration: AttributeTextInputConfig = {
    placeholderText: 'Tell us about yourself...',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    textInputType: TextInputType.textarea,
    name: 'requestDescriptionId',
    maxLength: 10000
  };

  ngOnInit() {
    this.requestDescription = null;
    this.candidateService.getCurrentPartnerEmail().subscribe(
      data => {
      this.userEmail = data;
    },
      error => {
        console.log(error);
        this.userEmail = 'unknown';
      });

  }

  onNext(description?: string) {
    this.isSubmitButtonsDisabled = true;
    if (description) {
      this.requestNewRole(description);
    }
  }

  public requestNewRole(description: string) {
    const body = '<div>A new user with email ' + this.userEmail + ' has logged in to PAM and requested access. ' +
      'The following request description was provided:</div>' + description + '</div>';
    const emailMessage = new EmailMessage(this.pamTeamEmail, 'PAM Team', 'do-not-reply@heb.com',
      'PAM Reporting', 'PAM – Access request', body, '', '');
    this.lookupService.sendEmail(emailMessage).subscribe();

  }

  onClose() {
    this.authService.isNotAuthenticatedLogout();
    this.router.navigate(['/login']);
  }
}
