import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  DsdItem,
  Item,
  Product,
  Task,
  TaskDecision,
  TextInputType,
  Upc
} from 'pm-models';
import {GrowlService} from '../growl/growl.service';
import {WorkflowService} from '../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../service/candidate.service';
import {LookupService} from '../service/lookup.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {CostService} from '../service/cost.service';
import {AttributeTypes, ReviewComponent} from 'pm-components';
import {ProductService} from '../service/product.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {FileService} from '../service/file.service';
import {AuthService} from '../auth/auth.service';
import {calculateCheckDigit} from '../shared/upc.utils';
import {ItemWeightTypeService} from '../service/item-weight-type.service';
import {SupplierCasePackService} from '../service/supplier-case-pack.service';
import {MatUtilService} from '../service/mat-util.service';
import {Observable} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';

@Component({
  selector: 'app-buyer-additional-case-pack-review',
  templateUrl: './buyer-additional-case-pack-review.component.html',
  styleUrls: ['./buyer-additional-case-pack-review.component.scss'],
  providers: [ EditCandidateModalService ]
})
export class BuyerAdditionalCasePackReviewComponent implements OnInit {
  @ViewChild(ReviewComponent) pmReview;

  public BUYER_TASK_NAME = 'Key Buyer Data';
  public BUYER_REVISE_TASK_NAME = 'Revise Buyer Data';
  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public UPC = 'UPC';
  public isApproveDisabled = false;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public productData: Product;
  public upc: Upc;
  public item: Item;
  public dsdItem: DsdItem;
  public displayingExistingCasesDrawer = false;
  public displayingBuyerInfo = false;
  public departmentId = undefined;
  private currentCandidateProductIndex = 1;
  private searchedCandidateProductIndex = 0;
  private scanCodeCheckDigit: number;
  private openCommentDrawer: boolean;
  public tempCommentHolder: string;

  constructor(private workflowService: WorkflowService, private route: ActivatedRoute,
              private router: Router, private candidateService: CandidateService, private lookupService: LookupService,
              private growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, private productService: ProductService, public candidateUtils: CandidateUtilService,
              private fileService: FileService, public candidateUtilService: CandidateUtilService,
              public permissionService: NgxPermissionsService, private authService: AuthService, private itemWeightTypeService: ItemWeightTypeService,
              public supplierCasePackService: SupplierCasePackService, public matUtilService: MatUtilService) {
  }
  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  private taskSubscription$: any;
  private task: Task;
  public isViewingPage = true;
  public commodities: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public productImages = [];
  private isRejectDisabled = false;
  private decimalCount = 1;
  public costOwners = [];

  showMatAttributes = false;
  isLoadingMatData = true;

  public isPageEditable: boolean = false;
  public requestNewMatAttributeOverrideWrapper = new RequestNewMatAttributeOverrideWrapper();

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'Verify the value provided by the supplier or enter a value if empty .',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };

  yearConfiguration: AttributeTextInputConfig = {
    label: 'Year',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };

  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  rushFlagConfiguration: AttributeConfig = {
    label: 'Rush review of this candidate',
    description: 'Send this Candidate to the top of other reviewer\'s queue.',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'rushFlagId',
    defaultValue: false
  };

  buyerComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'buyerCommentId',
    maxLength: 4000
  };

  private setIsPageEditable() {
    this.isPageEditable = this.authService.isBuyer();
  }

  ngOnInit() {

    this.setIsPageEditable();
    this.supplierCasePackService.resetService();

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.BUYER_TASK_NAME && this.task.name !== this.BUYER_REVISE_TASK_NAME) {
              this.router.navigate(
                ['/tasks'], { queryParams: { growlMessage: 'Candidate is not in ' + this.BUYER_TASK_NAME +
                      ' or ' + this.BUYER_REVISE_TASK_NAME + ' status.', growlToUse: GrowlService.SEVERITY_ERROR }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                if (candidate.candidateType !== Candidate.ADDITIONAL_CASE_PACK) {
                  this.router.navigate(['/tasks'], {
                    queryParams: {growlMessage: 'Invalid task type.', growlToUse: GrowlService.SEVERITY_ERROR}
                  }).then();
                }
                this.costService.updateCostLink(candidate).subscribe((updatedCandidate) => {
                  this.setInitialValues(updatedCandidate);
                });
              });

          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  onClose() {
    if (JSON.stringify(this.candidate) !== JSON.stringify(this.originalCandidate) && this.isPageEditable) {
      this.candidate.candidateProducts[1] = this.candidateProduct;
      this.candidateService.saveCandidate(this.candidate).subscribe(() => {
        this.isViewingPage = false;
        this.router.navigate(['/tasks']);
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']);
    }
  }

  private setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.guaranteeToStoreDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.reactionDaysConfiguration.isHidden = () => !this.candidate.codeDate;
    this.inboundSpecConfiguration.isHidden = () => !this.candidate.codeDate;
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }

    if (this.isPageEditable) {
      this.showMatAttributes = true;
      this.matUtilService.updateMatHierarchyFromProduct(this.candidateProduct, this.productData).pipe(
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
          this.supplierCasePackService.globalAttributes, this.supplierCasePackService.hierarchyAttributes)),
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierCasePackService.hierarchyAttributes,
            this.supplierCasePackService.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierCasePackService.globalAttributes,
            [], this.supplierCasePackService.warehouseItemAttributes, []);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })).subscribe();

    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.candidateProduct?.globalAttributes, [],
        this.supplierCasePackService.warehouseItemAttributes, []);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[1];
  }

  setProductData(): Observable<any> {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
          this.getCheckDigit();
          this.candidateUtilService.getCostOwners(this.productData.brand.brandId).subscribe(costOwners => {
            this.costOwners = costOwners;
          });
        })
      );
    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.setInformationFromProductData(productData);
          this.getCheckDigit();
          this.candidateUtilService.getCostOwners(this.productData.brand.brandId).subscribe(costOwners => {
            this.costOwners = costOwners;
          });
        })
      );
    }
  }

  private setInformationFromProductData(productData: Product) {
    this.candidate.productId = productData.productId;
    this.item = this.productService.getPrimaryItem(this.candidate.candidateProducts[0], productData);
    if (!this.item) {
      this.dsdItem = this.productService.getPrimaryDsdItem(this.candidate.candidateProducts[0], productData);
    }
    this.productService.getUpcBySearchedValue(this.candidate.candidateProducts[0], productData).subscribe(upc => {
      this.upc = upc;
      this.productService.setCandidateRetailFromUpc(this.candidate, upc);
    });
    this.productService.setBuyerAndHierarchyInformation(this.candidate, productData);
    this.productImages.push(this.productService.getProductImageUrl(productData));
    this.departmentId = this.productService.getDepartment(productData);
  }

  getProductInfoString() {

    if (!this.candidate || !this.upc) {
      return '';
    }
    return  '' + this.upc.size +
      ' | Inner case cost: '  +  this.costService.getCaseCostAsCurrency(this.candidate) +
      ' | Unit cost: '  +  this.costService.toCurrencyForCost(this.candidate.unitCost) +
      ' | Retail: '  +  this.costService.toCurrency(this.upc.retailPrice);
  }

  showExistingCasePacks() {
    this.displayingExistingCasesDrawer = true;
    this.displayingBuyerInfo = false;
    this.pmReview.openDrawer();
  }

  collapse() {
    this.displayingExistingCasesDrawer = false;
    this.displayingBuyerInfo = false;
    this.pmReview.closeDrawer();
  }
  get attributeType() {
    return AttributeTypes;
  }
  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  showEditCandidateProductModal(type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
      }
    });
  }
  onClickCasePack(casePack) {
    casePack.isViewing = !casePack.isViewing;
  }

  showRejectPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  reject(event, panel, target) {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;
    this.saveAndCompleteTaskAndRouteToTasksPage(
      WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_REJECT_DECISION, 'Successfully rejected candidate.');
    panel.hide();
  }
  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
    }, (error) => {
      this.growlService.addError(error);
      this.isRejectDisabled = false;
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  onClickNext() {
    this.displayingBuyerInfo = true;
    this.displayingExistingCasesDrawer = false;
    this.pmReview.openDrawer();
  }

  seasonChange(event) {
    this.candidate.season = event;
  }

  onClickApprove() {
    this.isApproveDisabled = true;
    this.candidate.candidateProducts[this.currentCandidateProductIndex] = this.candidateProduct;
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.BUYER_ADDITIONAL_CASE_PACK_VALIDATOR])
      .subscribe((candidate) => {
        this.saveAndCompleteBuyerTask();
      }, (error) => {
        if (error.error.candidateErrors) {
          this.candidateError = error.error.candidateErrors;

          this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message);
        }
        this.isApproveDisabled = false;
      });
  }

  /**
   * Saves candidate.
   */
  save() {
    this.candidate.candidateProducts[1] = this.candidateProduct;
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editCandidateProduct(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }
  /**
   * Calls workflow service to end the buyer task and then navigates back to the tasks page.
   */
  private saveAndCompleteBuyerTask() {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.KEY_BUYER_DATA_APPROVE_DECISION, 'Successfully completed task.');
    });
  }

  getCheckDigit() {
    if (this.productData.primaryScanCodeId) {
      this.scanCodeCheckDigit = calculateCheckDigit(this.productData.primaryScanCodeId);
    } else {
      this.scanCodeCheckDigit = null;
    }
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, JSON.parse(JSON.stringify(this.candidate)), overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        if (type === AttributeTypes.CaseUPC) {
            const currentCandidateProduct = CandidateUtilService.getCurrentCandidateProduct(response);
            this.candidateProduct.caseUpc = currentCandidateProduct.caseUpc;
            this.candidateProduct.caseUpcCheckDigit = currentCandidateProduct.caseUpcCheckDigit;
        }
      }
    });
  }

  editCostLink() {
    this.editCandidateModalService
      .openModal(AttributeTypes.CostLinkedItem, this.candidate, {
        validationService: this.candidateService
      })
      .subscribe(response => {
        if (response) {
          this.candidate = response;
        }
      });
  }

  editCaseUPC() {
    this.showEditCandidateModal(AttributeTypes.CaseUPC, {
      validationService: this.candidateService
    });
  }

  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.candidate.commodity && this.candidate.commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.candidate.commodity.departmentId);
    } else {
      return false;
    }
  }

  addAttachments(event) {
    this.candidate.attachments = event;
    this.save();
  }

  editBrandModal() {
    this.editCandidateModalService.openModal(AttributeTypes.Brand, this.candidate).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[0];
      }
    });
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.buyerComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.displayingBuyerInfo = false;
    this.displayingExistingCasesDrawer = false;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.displayingBuyerInfo = false;
    this.displayingExistingCasesDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.buyerComment = this.tempCommentHolder;
    this.candidate.buyerCommentUser = this.authService.getUser();
    this.openCommentDrawer = false;
    this.displayingBuyerInfo = false;
    this.displayingExistingCasesDrawer = false;
    this.pmReview.closeDrawer();
  }
}
