import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductBasicsCardComponent } from './product-basics-card.component';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import { CardModule } from '../../shared/card/card.module';



@NgModule({
  declarations: [ProductBasicsCardComponent],
  exports: [ProductBasicsCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule, AttributeDisplayModule
  ]
})
export class ProductBasicsCardModule { }
