import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, Renderer2} from '@angular/core';

import {DomHandler} from 'primeng/dom';
import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'pm-static-overlay-panel',
  template: `
    <div
      [ngClass]="classOverride"
      [ngStyle]="style"
      [class]="styleClass"
      (click)="onContainerClick()"
      [@animation]="{
        value: 'visible',
        params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
      }"
      (@animation.start)="onAnimationStart($event)"
      *ngIf="visible"
    >
      <div class="pm-overlaypanel-content" [ngClass]="contentClassOverride">
        <ng-content></ng-content>
      </div>
      <a
        tabindex="0"
        *ngIf="showCloseIcon"
        class="ui-overlaypanel-close ui-state-default"
        (click)="onCloseClick($event)"
        (keydown.enter)="hide()"
        [attr.aria-label]="ariaCloseLabel"
      >
        <span class="ui-overlaypanel-close-icon pi pi-times"></span>
      </a>
    </div>
  `,
  styleUrls: ['./static-overlay-panel.component.scss'],
  animations: [
    trigger('animation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1
        })
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}'))
    ])
  ]
})
export class StaticOverlayPanelComponent implements OnDestroy {
  @Input() dismissable: boolean = true;

  @Input() showCloseIcon: boolean;

  @Input() classOverride: string = 'pm-overlaypanel';

  @Input() contentClassOverride: string = 'pm-overlaypanel-content';

  @Input() style: any;

  @Input() styleClass: string;

  @Input() appendTo: any;

  @Input() autoZIndex: boolean = true;

  @Input() ariaCloseLabel: string;

  @Input() baseZIndex: number = 0;

  @Input() showTransitionOptions: string = '225ms ease-out';

  @Input() hideTransitionOptions: string = '195ms ease-in';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onShow: EventEmitter<any> = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  container: HTMLDivElement;

  visible: boolean = false;

  isContainerClicked: boolean = true;

  documentClickListener: any;

  target: any;

  willHide: boolean;

  constructor(public el: ElementRef, public renderer: Renderer2, private cd: ChangeDetectorRef, private zone: NgZone) {}

  onContainerClick() {
    this.isContainerClicked = true;
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener && this.dismissable) {
      this.zone.runOutsideAngular(() => {
        const documentEvent = DomHandler.isIOS() ? 'touchstart' : 'click';
        this.documentClickListener = this.renderer.listen('document', documentEvent, event => {
          if (
            !this.container.contains(event.target) &&
            this.target !== event.target &&
            !this.target.contains(event.target) &&
            !this.isContainerClicked
          ) {
            this.zone.run(() => {
              this.hide();
            });
          }

          this.isContainerClicked = false;
          this.cd.markForCheck();
        });
      });
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  toggle(event, target?) {
    if (this.visible) {
      this.visible = false;

      if (this.hasTargetChanged(event, target)) {
        this.target = target || event.currentTarget || event.target;

        setTimeout(() => {
          this.visible = true;
        }, 200);
      }
    } else {
      this.show(event, target);
    }
  }

  show(event, target?) {
    this.target = target || event.currentTarget || event.target;
    this.isContainerClicked = true;
    this.visible = true;
  }

  hasTargetChanged(event, target) {
    return this.target != null && this.target !== (target || event.currentTarget || event.target);
  }

  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        document.body.appendChild(this.container);
      } else {
        DomHandler.appendChild(this.container, this.appendTo);
      }
    }
  }

  restoreAppend() {
    if (this.container && this.appendTo) {
      this.el.nativeElement.appendChild(this.container);
    }
  }

  onAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.onShow.emit(null);
        this.appendContainer();
        if (this.autoZIndex) {
          this.container.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
        }
        if (this.classOverride === 'pm-overlaypanel-fixed') {
          DomHandler.addClass(this.container, 'pm-overlaypanel-fixed-margins');
        } else {
          DomHandler.absolutePosition(this.container, this.target);
          if (DomHandler.getOffset(this.container).top < DomHandler.getOffset(this.target).top) {
            DomHandler.addClass(this.container, 'pm-overlaypanel-flipped');
          }
          if (
            Math.floor(DomHandler.getOffset(this.container).left) < Math.floor(DomHandler.getOffset(this.target).left) &&
            DomHandler.getOffset(this.container).left > 0
          ) {
            DomHandler.addClass(this.container, 'pm-overlaypanel-shifted');
          }
        }
        this.bindDocumentClickListener();
        break;

      case 'void':
        this.onContainerDestroy();
        this.onHide.emit({});
        break;
    }
  }

  hide() {
    this.visible = false;
  }

  onCloseClick(event) {
    this.hide();
    event.preventDefault();
  }

  onContainerDestroy() {
    this.unbindDocumentClickListener();
  }

  ngOnDestroy() {
    this.target = null;
    if (this.container) {
      this.restoreAppend();
      this.onContainerDestroy();
    }
  }
}
