<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">
  <div class="attribute-group factory-search-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label" [ngClass]="attribute.labelInputGroupClass" *ngIf="attribute.label">
      <label for="">{{attribute.label}}</label>
    </div>
    <div class="attribute-control">
      <div class="ui-inputgroup">
        <p-dropdown class="factory-dropdown"
                    [ngClass]="attributeError ? 'error-class': ''"
                    [options]="searchOptions"
                    [(ngModel)]="searchType"
                    [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
                    [disabled]="attribute.isDisabled && attribute.isDisabled()"
                    (onChange)="onSearchOptionChange($event)">
        </p-dropdown>
        <pm-typeahead *ngIf="searchTypeValue === FACTORY_ID"
                      class="factory-typeahead"
                      [id]="factoryIdConfiguration.name"
                      [displayRef]="factoryIdConfiguration?.displayRef"
                      [displaySubRef]="factoryIdConfiguration?.displaySubRef"
                      [showAddlInfo]="factoryIdConfiguration?.showAddlInfo"
                      [placeholder]="factoryIdConfiguration?.placeholderText"
                      [maxSearchSize]="5"
                      [model]="factoryModel"
                      [idRef]="factoryIdConfiguration?.idRef"
                      [url]="factoryIdConfiguration?.searchUrl"
                      [isDropDown]="true"
                      (selection)="handleFactoryChange($event)"
                      ngDefaultControl
                      [ngStyle]="getTypeaheadStyle()"
                      [doInitCallback]="factoryModel"
                      [hasError]="attributeError"
                      (initCallBack)="factoryInitCallback($event)"
                      [allowMultiple]="false"
                      >
        </pm-typeahead>
        <pm-typeahead *ngIf="searchTypeValue === FACTORY_NAME"
                      class="factory-typeahead"
                      [id]="factoryNameConfiguration.name"
                      [displayRef]="factoryNameConfiguration?.displayRef"
                      [displaySubRef]="factoryNameConfiguration?.displaySubRef"
                      [showAddlInfo]="factoryNameConfiguration?.showAddlInfo"
                      [placeholder]="factoryNameConfiguration?.placeholderText"
                      [maxSearchSize]="5"
                      [model]="factoryModel"
                      [idRef]="factoryNameConfiguration?.idRef"
                      [url]="factoryNameConfiguration?.searchUrl"
                      [isDropDown]="true"
                      (selection)="handleFactoryChange($event)"
                      ngDefaultControl
                      [ngStyle]="getTypeaheadStyle()"
                      [doInitCallback]="factoryModel"
                      [hasError]="attributeError"
                      (initCallBack)="factoryInitCallback($event)">
        </pm-typeahead>
        <pm-typeahead *ngIf="searchTypeValue === FACTORY_COUNTRY"
                      class="factory-typeahead"
                      [id]="factoryCountryConfiguration.name"
                      [displayRef]="factoryCountryConfiguration?.displayRef"
                      [displaySubRef]="factoryCountryConfiguration?.displaySubRef"
                      [showAddlInfo]="factoryCountryConfiguration?.showAddlInfo"
                      [placeholder]="factoryCountryConfiguration?.placeholderText"
                      [maxSearchSize]="5"
                      [model]="factoryModel"
                      [idRef]="factoryCountryConfiguration?.idRef"
                      [url]="factoryCountryConfiguration?.searchUrl"
                      [isDropDown]="true"
                      (selection)="handleFactoryChange($event)"
                      ngDefaultControl
                      [ngStyle]="getTypeaheadStyle()"
                      [doInitCallback]="factoryModel"
                      [hasError]="attributeError"
                      (initCallBack)="factoryInitCallback($event)">
        </pm-typeahead>
        <pm-typeahead *ngIf="searchTypeValue === FACTORY_CITY"
                      class="factory-typeahead"
                      [id]="factoryCityConfiguration.name"
                      [displayRef]="factoryCityConfiguration?.displayRef"
                      [displaySubRef]="factoryCityConfiguration?.displaySubRef"
                      [showAddlInfo]="factoryCityConfiguration?.showAddlInfo"
                      [placeholder]="factoryCityConfiguration?.placeholderText"
                      [maxSearchSize]="5"
                      [model]="factoryModel"
                      [idRef]="factoryCityConfiguration?.idRef"
                      [url]="factoryCityConfiguration?.searchUrl"
                      [isDropDown]="true"
                      (selection)="handleFactoryChange($event)"
                      ngDefaultControl
                      [ngStyle]="getTypeaheadStyle()"
                      [doInitCallback]="factoryModel"
                      [hasError]="attributeError"
                      (initCallBack)="factoryInitCallback($event)">
        </pm-typeahead>
      </div>
      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="attributeError" class="attribute-error-message">
        {{attributeError}}
      </p>
    </div>
  </div>
</ng-container>
