import {Component, OnInit} from '@angular/core';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {CostService} from '../../service/cost.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {MatUtilService} from '../../service/mat-util.service';
import {SupplierCasePackService} from '../../service/supplier-case-pack.service';
import {Candidate, CandidateError, CandidateProduct, CandidateProductError, CandidateValidatorType, TaskDecision} from 'pm-models';
import {PiaAdditionalCasePackStepperComponent} from '../pia-additional-case-pack-stepper/pia-additional-case-pack-stepper.component';
import {finalize, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-pia-additional-case-pack-extended-attributes',
  templateUrl: './pia-additional-case-pack-extended-attributes.component.html',
  styleUrls: ['./pia-additional-case-pack-extended-attributes.component.scss']
})
export class PiaAdditionalCasePackExtendedAttributesComponent implements OnInit {


  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public supplierCasePackService: SupplierCasePackService, public costService: CostService,
              private candidateUtilService: CandidateUtilService, private matUtils: MatUtilService) { }

  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;
  candidateProductError: CandidateProductError;

  private validators: CandidateValidatorType[] = [CandidateValidatorType.PIA_CASE_PACK_VALIDATOR,
    CandidateValidatorType.PIA_ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR, CandidateValidatorType.PIA_ADDITIONAL_CASE_PACK_VALIDATOR,
    CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR];

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierCasePackService.getTaskId(),
        this.supplierCasePackService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierCasePackService.resetService();
        this.supplierCasePackService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.ADDITIONAL_CASE_PACK) {
              this.supplierCasePackService.setOriginalAndCurrentCandidate(candidate);
              return this.updateHierarchyAndAttributes();
            } else {
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.supplierCasePackService.getExtendedAttributesError();
            this.candidateProductError = this.supplierCasePackService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierCasePackService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return this.updateHierarchyAndAttributes();
            } else {
              this.supplierCasePackService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.supplierCasePackService.getExtendedAttributesError();
            this.candidateProductError = this.supplierCasePackService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      }
    });
  }

  updateHierarchyAndAttributes(): Observable<any> {
    if (this.supplierCasePackService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.supplierCasePackService.candidate.candidateProducts[0].upc, []).pipe(
        switchMap((productData) => this.matUtils.updateMatHierarchyFromProduct(this.supplierCasePackService.candidate.candidateProducts[1], productData)),
        switchMap(() => this.matUtils.updateMatAttributesAndValues(this.supplierCasePackService.getCandidate(),
          this.supplierCasePackService.globalAttributes, this.supplierCasePackService.hierarchyAttributes)),
      );
    } else if (this.supplierCasePackService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.supplierCasePackService.candidate.candidateProducts[0].itemCode, []).pipe(
        switchMap((productData) => this.matUtils.updateMatHierarchyFromProduct(this.supplierCasePackService.candidate.candidateProducts[1], productData)),
        switchMap(() => this.matUtils.updateMatAttributesAndValues(this.supplierCasePackService.getCandidate(),
          this.supplierCasePackService.globalAttributes, this.supplierCasePackService.hierarchyAttributes)),
      );
    }
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case PiaAdditionalCasePackStepperComponent.UPC_ITEM_CODE: {
        this.jumpBack('/piaSetupCasePackComponent');
        break;
      }
      case PiaAdditionalCasePackStepperComponent.CASE_PACK: {
        this.jumpBack('/piaCasePackDetailsComponent');
        break;
      }
      case PiaAdditionalCasePackStepperComponent.WAREHOUSE_SELECTION: {
        this.jumpBack('/piaCasePackWarehouseComponent');
        break;
      }
      case PiaAdditionalCasePackStepperComponent.EXTENDED_ATTRIBUTES: {
        break;
      }
    }
  }

  onClickBack() {
    this.jumpBack('/piaCasePackWarehouseComponent');
  }

  jumpBack(url) {
    this.supplierCasePackService.saveCandidateAndNavigate(url, false);
  }

  onClickActivate() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.supplierCasePackService.candidate, this.validators).subscribe(data => {
      this.candidateService.activateCandidate(this.supplierCasePackService.candidate).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE,
          TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
          'Successfully activated candidate.'
        );
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
      });
    }, (error) => {
      this.supplierCasePackService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.supplierCasePackService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.supplierCasePackService.getExtendedAttributesError();
        this.candidateProductError = this.supplierCasePackService.getCurrentCandidateProductError(this.candidateError);
      }
      this.canNavigate = true;
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.supplierCasePackService.candidate.vendor.apNumber, this.supplierCasePackService.getTask(), action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( data => {
          this.canNavigate = true;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
      });
  }

  onClose() {
    this.supplierCasePackService.saveCandidateAndNavigate('/tasks', false);
  }
}
