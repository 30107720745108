// tslint:disable: max-line-length

import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MessagesModule} from 'primeng/messages';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BuyerDropDownFilterModule} from '../../projects/pm-components/src/lib/shared/buyer-drop-down-filter/buyer-drop-down-filter.module';
import {SubmitButtonSpinnerModule} from '../../projects/pm-components/src/lib/shared/submit-button-spinner/submit-button-spinner.module';
import {BdaOrScaGuard} from './2.0.0/auth/bda-or-sca.guard';
import {EditCandidateModalService} from './2.0.0/service/edit-candidate-modal.service';
import {PreferencesService} from './2.0.0/service/preferences.service';
import {AppComponent} from './app.component';
import {WorkflowService} from './2.0.0/service/workflow.service';
import {PublisherService} from './2.0.0/service/publisher.service';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CandidateService} from './2.0.0/service/candidate.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LookupService} from './2.0.0/service/lookup.service';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {DropdownModule} from 'primeng/dropdown';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {GrowlComponent} from './2.0.0/growl/growl.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {GrowlService} from './2.0.0/growl/growl.service';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {MenubarModule} from 'primeng/menubar';
import {AuthService} from './2.0.0/auth/auth.service';
import {TokenInterceptor} from './2.0.0/auth/token.interceptor';
import {LoginComponent} from './2.0.0/login/login.component';
import {AuthGuard} from './2.0.0/auth/auth.guard';
import {CustomErrorHandler} from './2.0.0/auth/custom-error-handler';

import {CurrencyPipe, DatePipe} from '@angular/common';

import {NgxPermissionsModule} from 'ngx-permissions';
import {FilteringService} from './2.0.0/service/filtering.service';
import {AppConfigService} from './2.0.0/service/app-config.service';
import {SupplierDetailsComponent} from './2.0.0/supplier-product-flow/supplier-details/supplier-details.component';
import {
  AttributeModalModule,
  ComponentCardsModule,
  DrawerModule,
  LayoutModule,
  SharedModule as PMComponentsSharedModule
} from 'pm-components';
import {SetupCandidateComponent} from './2.0.0/setup-candidate/setup-candidate.component';
import {SetupCandidateTypeComponent} from './2.0.0/supplier-product-flow/setup-candidate-type/setup-candidate-type.component';
import {TaskViewerComponent} from './2.0.0/task-list/task-viewer/task-viewer.component';
import {PiaTaskViewerTableComponent} from './2.0.0/task-list/pia-task-viewer-table/pia-task-viewer-table.component';
import {VendorTaskViewerTableComponent} from './2.0.0/task-list/vendor-task-viewer-table/vendor-task-viewer-table.component';
import {DateTimeFilterComponent} from './2.0.0/date-time-filter/date-time-filter.component';
import {BasicItemSetupComponent} from './2.0.0/supplier-product-flow/basic-item-setup/basic-item-setup.component';
import {ProductPackagingCardModule} from '../../projects/pm-components/src/lib/cards/product-packaging-card/product-packaging-card.module';
import {ProductImagesCardModule} from '../../projects/pm-components/src/lib/cards/product-images-card/product-images-card.module';
import {CasePackComponent} from './2.0.0/supplier-product-flow/case-pack/case-pack.component';
import {BuyerNotificationsComponent} from './2.0.0/buyer-notifications/buyer-notifications.component';

import {CoreModule} from './2.0.0/core/core.module';
import {SupplierNotificationsComponent} from './2.0.0/supplier-notifications/supplier-notifications.component';
import {SetUpNotificationsComponent} from './2.0.0/set-up-notifications/set-up-notifications.component';
import {BuyerProductReviewComponent} from './2.0.0/buyer-product-review/buyer-product-review.component';
import {ScaProductReviewComponent} from './2.0.0/sca-product-review/sca-product-review.component';
import {TaskViewerTableComponent} from './2.0.0/task-list/task-viewer-table/task-viewer-table.component';
import {TaskViewerTableModule} from '../../projects/pm-components/src/lib/task-viewer-tables/task-viewer-table/task-viewer-table.module';
import {
  TaskViewerTableClosedModule
} from '../../projects/pm-components/src/lib/task-viewer-tables/task-viewer-table-closed/task-viewer-table-closed.module';
import {
  TaskViewerTableInProgressModule
} from '../../projects/pm-components/src/lib/task-viewer-tables/task-viewer-table-in-progress/task-viewer-table-in-progress.module';
import {AttributeGridModule} from '../../projects/pm-components/src/lib/attribute-grid/attribute-grid.module';
import {
  ProcurementSupportProductReviewComponent
} from './2.0.0/procurement-support-product-review/procurement-support-product-review.component';
import {UnsubscribeNotificationsComponent} from './2.0.0/unsubscribe-notifications/unsubscribe-notifications.component';
import {
  SetupAdditionalCasePackComponent
} from './2.0.0/supplier-case-pack-flow/setup-additional-case-pack/setup-additional-case-pack.component';
import {
  AdditionalCasePackDetailsComponent
} from './2.0.0/supplier-case-pack-flow/additional-case-pack-details/additional-case-pack-details.component';
import {SetupBonusSizeComponent} from './2.0.0/supplier-bonus-size-flow/setup-bonus-size/setup-bonus-size.component';
import {BonusDetailsComponent} from './2.0.0/supplier-bonus-size-flow/bonus-details/bonus-details.component';
import {BonusCaseDetailsComponent} from './2.0.0/supplier-bonus-size-flow/bonus-case-details/bonus-case-details.component';
import {AssociateUpcStepperModule} from './2.0.0/shared/components/associate-upc-stepper/associate-upc-stepper.module';

import {
  BuyerAdditionalCasePackReviewComponent
} from './2.0.0/buyer-additional-case-pack-review/buyer-additional-case-pack-review.component';
import {SupplierBonusStepperComponent} from './2.0.0/supplier-bonus-size-flow/supplier-bonus-stepper/supplier-bonus-stepper.component';
import {ScaAdditionalCasePackComponent} from './2.0.0/sca-additional-case-pack/sca-additional-case-pack.component';
import {PiaAdditionalCasePackComponent} from './2.0.0/pia-additional-case-pack/pia-additional-case-pack.component';

import {
  AssociateBasicItemSetupComponent
} from './2.0.0/supplier-associate-upc-flow/associate-basic-item-setup/associate-basic-item-setup.component';
import {SetupAssociateUpcComponent} from './2.0.0/supplier-associate-upc-flow/setup-associate-upc/setup-associate-upc.component';

import {
  AssociateUpcProcurementReviewComponent
} from './2.0.0/pia-associate-upc-flow/associate-upc-procurement-review/associate-upc-procurement-review.component';
import {BuyerBonusSizeReviewComponent} from './2.0.0/buyer-bonus-size-flow/buyer-bonus-size-review/buyer-bonus-size-review.component';

import {
  AssociateCaseSelectionComponent
} from './2.0.0/supplier-associate-upc-flow/associate-case-selection/associate-case-selection.component';
import {SCABonusSizeReviewComponent} from './2.0.0/sca-bonus-size-review/sca-bonus-size-review.component';
import {SetupMrtComponent} from './2.0.0/supplier-mrt-flow/setup-mrt/setup-mrt.component';
import {PiaBonusSizeReviewComponent} from './2.0.0/pia-bonus-size-review/pia-bonus-size-review.component';
import {DeleteButtonsModule} from '../../projects/pm-components/src/lib/shared/delete-button/delete-button.module';
import {MrtCaseDetailsComponent} from './2.0.0/supplier-mrt-flow/mrt-case-details/mrt-case-details.component';
import {MrtCaseStepperComponent} from './2.0.0/supplier-mrt-flow/mrt-case-details-stepper/mrt-case-stepper.component';
import {SetUpNewMrtItemsComponent} from './2.0.0/supplier-mrt-flow/set-up-new-mrt-items/set-up-new-mrt-items.component';
import {
  InnerSupplierAndHebDetailsComponent
} from './2.0.0/supplier-mrt-flow/inner-supplier-and-heb-details/inner-supplier-and-heb-details.component';
import {EditButtonModule} from '../../projects/pm-components/src/lib/shared/edit-button/edit-button.module';
import {RequestHebUpcComponent} from './2.0.0/upc-reservation/request-heb-upc/request-heb-upc.component';
import {InnerProductDetailsComponent} from './2.0.0/supplier-mrt-flow/inner-product-details/inner-product-details.component';
import {AddNewMrtCaseComponent} from './2.0.0/supplier-mrt-flow/add-new-mrt-case/add-new-mrt-case.component';
import {InnerCaseDetailsComponent} from './2.0.0/supplier-mrt-flow/inner-case-details/inner-case-details.component';
import {MrtInnerStepperComponent} from './2.0.0/supplier-mrt-flow/mrt-inner-stepper/mrt-inner-stepper.component';
import {SupplierMrtReviewComponent} from './2.0.0/supplier-mrt-flow/supplier-mrt-review/supplier-mrt-review.component';
import {BuyerMrtReviewComponent} from './2.0.0/buyer-mrt-review/buyer-mrt-review.component';

import {
  StaticOverlayPanelModule
} from '../../projects/pm-components/src/lib/shared/overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {ScaMrtReviewComponent} from './2.0.0/sca-mrt-review/sca-mrt-review.component';
import {PiaMrtReviewComponent} from './2.0.0/pia-mrt-review/pia-mrt-review.component';
import {RequestHebUpcSuccessComponent} from './2.0.0/upc-reservation/request-heb-upc-success/request-heb-upc-success.component';
import {OwnBrandService} from './2.0.0/service/ownbrand.service';

import {
  ProcurementSupportProductReviewClosedComponent
} from './2.0.0/procurement-support-product-review-closed/procurement-support-product-review-closed.component';
import {PiaSetupNewProductComponent} from './2.0.0/pia-new-product-flow/pia-setup-new-product/pia-setup-new-product.component';
import {DistributionChannelModule} from '../../projects/pm-components/src/lib/cards/distribution-channel/distribution-channel.module';

import {
  PiaNewProductSupplierDetailsComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-supplier-details/pia-new-product-supplier-details.component';

import {
  PiaNewProductFlowStepperComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-flow-stepper/pia-new-product-flow-stepper.component';
import {PiaAdditionalCasePackClosedComponent} from './2.0.0/pia-additional-case-pack-closed/pia-additional-case-pack-closed.component';

import {
  AssociateUpcProcurementReviewClosedComponent
} from './2.0.0/pia-associate-upc-flow/associate-upc-procurement-review-closed/associate-upc-procurement-review-closed.component';
import {PiaBonusSizeReviewClosedComponent} from './2.0.0/pia-bonus-size-review-closed/pia-bonus-size-review-closed.component';
import {PiaMrtReviewClosedComponent} from './2.0.0/pia-mrt-review-closed/pia-mrt-review-closed.component';
import {MrtCaseDetailsReviewComponent} from './2.0.0/supplier-mrt-flow/mrt-case-details-review/mrt-case-details-review.component';
import {SupplierBonusReviewComponent} from './2.0.0/supplier-bonus-size-flow/supplier-bonus-review/supplier-bonus-review.component';

import {PiaNewProductDetailsComponent} from './2.0.0/pia-new-product-flow/pia-new-product-details/pia-new-product-details.component';


import {
  PiaNewProductCaseDetailsComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-case-details/pia-new-product-case-details.component';
import {PricingCardModule} from '../../projects/pm-components/src/lib/cards/pricing-card/pricing-card.module';
import {OmiRemarksCardModule} from '../../projects/pm-components/src/lib/cards/omi-remarks-card/omi-remarks-card.module';

import {
  MerchandisingInfoCardModule
} from '../../projects/pm-components/src/lib/cards/merchandising-info-card/merchandising-info-card.module';

import {
  PiaNewProductWarehousesComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-warehouses/pia-new-product-warehouses.component';
import {MaxShipCardModule} from '../../projects/pm-components/src/lib/cards/max-ship-card/max-ship-card.module';
import {NewProductReviewPageComponent} from './2.0.0/supplier-product-flow/new-product-review-page/new-product-review-page.component';

import {AssociateReviewPageComponent} from './2.0.0/supplier-associate-upc-flow/associate-review-page/associate-review-page.component';

import {
  AdditionalCasePackReviewComponent
} from './2.0.0/supplier-case-pack-flow/additional-case-pack-review/additional-case-pack-review.component';

import {
  NewWarehouseProductStepperComponent
} from './2.0.0/supplier-product-flow/new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {NewDsdProductStepperComponent} from './2.0.0/supplier-product-flow/new-dsd-product-stepper/new-dsd-product-stepper.component';
import {StoreAuthorizationComponent} from './2.0.0/supplier-product-flow/store-authorization/store-authorization.component';
import {PiaNewFreightTaxUpcComponent} from './2.0.0/pia-new-freight-tax-upc/pia-new-freight-tax-upc.component';
import {SupplierMrtInnerReviewComponent} from './2.0.0/supplier-mrt-flow/supplier-mrt-inner-review/supplier-mrt-inner-review.component';
import {PiaMrtInnerReviewClosedComponent} from './2.0.0/pia-mrt-inner-review-closed/pia-mrt-inner-review-closed.component';
import {ProductSearchComponent} from './2.0.0/product-search/product-search.component';
import {NeedAssistanceComponent} from './2.0.0/need-assistance/need-assistance.component';
import {SellableModule} from '../../projects/pm-components/src/lib/shared/sellable/sellable.module';
import {RejectCandidateModule} from '../../projects/pm-components/src/lib/shared/reject-candidate/reject-candidate-module';
import {
  TaskViewerTableRejectedModule
} from '../../projects/pm-components/src/lib/task-viewer-tables/task-viewer-table-rejected/task-viewer-table-rejected.module';
import {RejectStatusHeaderModule} from '../../projects/pm-components/src/lib/shared/reject-status-header/reject-status-header.module';
import {ScaNotificationsComponent} from './2.0.0/sca-notifications/sca-notifications.component';
import {ImportFactoryCardModule} from '../../projects/pm-components/src/lib/cards/import-factory-card/import-factory-card.module';
import {
  ImportFactorySearchBarModule
} from '../../projects/pm-components/src/lib/shared/import-factory-search-bar/import-factory-search-bar.module';
import {ImportedProductCardModule} from '../../projects/pm-components/src/lib/cards/imported-product-card/imported-product-card.module';
import {SetUpStoresComponent} from './2.0.0/supplier-product-flow/set-up-stores/set-up-stores.component';
import {
  PiaAssociateBasicItemSetupComponent
} from './2.0.0/pia-new-associate-upc-flow/pia-associate-basic-item-setup/pia-associate-basic-item-setup.component';
import {
  PiaAssociateCaseSelectionComponentComponent
} from './2.0.0/pia-new-associate-upc-flow/pia-associate-case-selection-component/pia-associate-case-selection-component.component';
import {
  PiaAssociateUpcSetupComponentComponent
} from './2.0.0/pia-new-associate-upc-flow/pia-associate-upc-setup-component/pia-associate-upc-setup-component.component';
import {NoStoresMessageModule} from '../../projects/pm-components/src/lib/shared/no-stores-message/no-stores-message.module';
import {
  NoStoresMessageExtendedModule
} from '../../projects/pm-components/src/lib/shared/no-stores-message-extended/no-stores-message-extended.module';
import {PiaBonusDetailsComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-details/pia-bonus-details.component';
import {PiaBonusCaseDetailsComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-case-details/pia-bonus-case-details.component';
import {PiaSetupBonusSizeComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-setup-bonus-size/pia-setup-bonus-size.component';
import {PiaBonusStepperComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-stepper/pia-bonus-stepper.component';
import {PiaBonusWarehouseComponent} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-warehouse/pia-bonus-warehouse.component';
import {SetUpStoresOverlayComponent} from './2.0.0/set-up-stores-overlay/set-up-stores-overlay.component';
import {ScaleInformationCardModule} from '../../projects/pm-components/src/lib/cards/scale-information-card/scale-information-card.module';
import {PiaSetupCasePackComponent} from './2.0.0/pia-new-case-pack-flow/pia-setup-case-pack/pia-setup-case-pack.component';
import {
  PiaAdditionalCasePackDetailsComponent
} from './2.0.0/pia-new-case-pack-flow/pia-additional-case-pack-details/pia-additional-case-pack-details.component';
import {
  PiaAdditionalCasePackWarehouseComponent
} from './2.0.0/pia-new-case-pack-flow/pia-additional-case-pack-warehouse/pia-additional-case-pack-warehouse.component';
import {
  PiaAdditionalCasePackStepperComponent
} from './2.0.0/pia-new-case-pack-flow/pia-additional-case-pack-stepper/pia-additional-case-pack-stepper.component';
import {ObRegScalePluReviewComponent} from './2.0.0/scale-plu-flow/ob-reg-scale-plu-review/ob-reg-scale-plu-review.component';
import {
  PiaNewDsdProductFlowStepperComponent
} from './2.0.0/pia-new-product-flow/pia-new-dsd-product-flow-stepper/pia-new-dsd-product-flow-stepper.component';
import {PiaStoreAuthorizationComponent} from './2.0.0/pia-new-product-flow/pia-store-authorization/pia-store-authorization.component';
import {PiaSetUpStoresComponent} from './2.0.0/pia-new-product-flow/pia-set-up-stores/pia-set-up-stores.component';
import {HistoryService} from './2.0.0/utils/history.service';
import {PiaScalePluReviewComponent} from './2.0.0/scale-plu-flow/pia-scale-plu-review/pia-scale-plu-review.component';

import {NewUserRoleRequestComponent} from './2.0.0/new-user-flow/new-user-role-request/new-user-role-request.component';
import {
  AttachmentUploadReviewPageModule
} from '../../projects/pm-components/src/lib/shared/attachment-upload-review-page/attachment-upload-review-page.module';
import {
  InvitedDistributorCaseDetailsComponent
} from './2.0.0/supplier-invited-distributor-flow/invited-distributor-case-details/invited-distributor-case-details.component';
import {
  SetUpInvitedDistributorStoresComponent
} from './2.0.0/supplier-invited-distributor-flow/set-up-invited-distributor-stores/set-up-invited-distributor-stores.component';
import {
  InvitedDistributorStoreAuthorizationComponent
} from './2.0.0/supplier-invited-distributor-flow/invited-distributor-store-authorization/invited-distributor-store-authorization.component';
import {
  InvitedDistributorStepperComponent
} from './2.0.0/supplier-invited-distributor-flow/invited-distributor-stepper/invited-distributor-stepper.component';
import {
  BuyerInvitedDistributorsReviewComponent
} from './2.0.0/buyer-invited-distributors-review/buyer-invited-distributors-review.component';
import {PharmNewProductSetupComponent} from './2.0.0/pharm-new-product-flow/pharm-new-product-setup/pharm-new-product-setup.component';
import {
  PharmTaskViewerTableComponent
} from './2.0.0/task-list/pharm-task-viewer-table/pharm-task-viewer-table/pharm-task-viewer-table.component';
import {
  PharmNewProductFlowStepperComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-flow-stepper/pharm-new-product-flow-stepper.component';
import {PiaInvitedDistributorsReviewComponent} from './2.0.0/pia-invited-distributors-review/pia-invited-distributors-review.component';
import {
  PharmNewProductSupplierDetailsComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-supplier-details/pharm-new-product-supplier-details.component';
import {
  PharmNewProductDetailsComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-details/pharm-new-product-details.component';
import {
  PharmNewProductCaseDetailsComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-case-details/pharm-new-product-case-details.component';
import {
  PharmNewProductWarehousesComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-product-warehouses/pharm-new-product-warehouses.component';
import {
  PharmNewDsdProductFlowStepperComponent
} from './2.0.0/pharm-new-product-flow/pharm-new-dsd-product-flow-stepper/pharm-new-dsd-product-flow-stepper.component';
import {PiaSetupMrtComponent} from './2.0.0/pia-new-mrt-flow/pia-setup-mrt/pia-setup-mrt.component';
import {PiaMrtStepperComponent} from './2.0.0/pia-new-mrt-flow/pia-mrt-stepper/pia-mrt-stepper.component';
import {PiaMrtWarehouseComponent} from './2.0.0/pia-new-mrt-flow/pia-mrt-warehouse/pia-mrt-warehouse.component';
import {PiaMrtCaseDetailsComponent} from './2.0.0/pia-new-mrt-flow/pia-mrt-case-details/pia-mrt-case-details.component';
import {PiaSetupNewMrtItemsComponent} from './2.0.0/pia-new-mrt-flow/pia-setup-new-mrt-items/pia-setup-new-mrt-items.component';
import {
  PiaInnerSupplierAndHebDetailsComponent
} from './2.0.0/pia-new-mrt-flow/pia-inner-supplier-and-heb-details/pia-inner-supplier-and-heb-details.component';
import {PiaInnerProductDetailsComponent} from './2.0.0/pia-new-mrt-flow/pia-inner-product-details/pia-inner-product-details.component';
import {PiaMrtInnerStepperComponent} from './2.0.0/pia-new-mrt-flow/pia-mrt-inner-stepper/pia-mrt-inner-stepper.component';
import {PiaAddNewMrtCaseComponent} from './2.0.0/pia-new-mrt-flow/pia-add-new-mrt-case/pia-add-new-mrt-case.component';
import {PiaInnerCaseDetailsComponent} from './2.0.0/pia-new-mrt-flow/pia-inner-case-details/pia-inner-case-details.component';
import {PiaMrtInnerWarehouseComponent} from './2.0.0/pia-new-mrt-flow/pia-mrt-inner-warehouse/pia-mrt-inner-warehouse.component';
import {VolumeUploadComponent} from './2.0.0/volume-upload/volume-upload/volume-upload.component';
import {
  SupplierAddDistributorComponent
} from './2.0.0/supplier-invited-distributor-flow/supplier-add-distributor/supplier-add-distributor.component';
import {PharmNewProductClosedComponent} from './2.0.0/pharm-new-product-flow/pharm-new-product-closed/pharm-new-product-closed.component';
import {UploadCandidateModule} from '../../projects/pm-components/src/lib/shared/upload-candidate/upload-candidate.module';
import {UploadProductTableModule} from '../../projects/pm-components/src/lib/shared/upload-product-table/upload-product-table.module';
import {UploadCandidateTableModule} from '../../projects/pm-components/src/lib/shared/upload-candidate-table/upload-candidate-table.module';
import {UploadCaseTableModule} from '../../projects/pm-components/src/lib/shared/upload-case-table/upload-case-table.module';
import {
  UploadSupplierAndHebTableModule
} from '../../projects/pm-components/src/lib/shared/upload-supplier-and-heb-table/upload-supplier-and-heb-table.module';
import {UploadWarehouseTableModule} from '../../projects/pm-components/src/lib/shared/upload-warehouse-table/upload-warehouse-table.module';
import {BuyerBulkReviewComponent} from './2.0.0/task-list/buyer-bulk-review/buyer-bulk-review.component';
import {ScaBulkReviewComponent} from './2.0.0/task-list/sca-bulk-review/sca-bulk-review.component';
import {UploadProductsComponent} from './2.0.0/volume-upload/upload-products/upload-products.component';
import {ErrorPanelModule} from '../../projects/pm-components/src/lib/shared/error-panel/error-panel.module';
import {
  InvitedDistributorReviewPageComponent
} from './2.0.0/supplier-invited-distributor-flow/invited-distributor-review-page/invited-distributor-review-page.component';
import {
  PiaInvitedDistributorsReviewClosedComponent
} from './2.0.0/pia-invited-distributors-review-closed/pia-invited-distributors-review-closed.component';
import {CategorySelectionModule} from '../../projects/pm-components/src/lib/shared/category-selection/category-selection.module';
import {
  UploadSettingsConfigurationModule
} from '../../projects/pm-components/src/lib/shared/upload-settings-configuration/upload-settings-configuration.module';
import {VolumeUploadSettingsComponent} from './2.0.0/volume-upload-settings/volume-upload-settings.component';
import {PiaAddDistributorComponent} from './2.0.0/pia-new-invited-distributor-flow/pia-add-distributor/pia-add-distributor.component';
import {
  PiaNewInvitedDistributorCaseDetailsComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-case-details/pia-new-invited-distributor-case-details.component';
import {
  PiaNewInvitedDistributorStoreAuthorizationComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-store-authorization/pia-new-invited-distributor-store-authorization.component';
import {
  PiaNewInvitedDistributorStepperComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-stepper/pia-new-invited-distributor-stepper.component';
import {
  PiaNewInvitedDistributorReviewComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-review/pia-new-invited-distributor-review.component';
import {
  PiaNewInvitedDistributorSetupStoresComponent
} from './2.0.0/pia-new-invited-distributor-flow/pia-new-invited-distributor-setup-stores/pia-new-invited-distributor-setup-stores.component';
import {PiaBulkReviewComponent} from './2.0.0/task-list/pia-bulk-review/pia-bulk-review.component';
import {ReservedUpcsComponent} from './2.0.0/upc-reservation/reserved-upcs/reserved-upcs.component';
import {
  SupplierDropDownFilterModule
} from '../../projects/pm-components/src/lib/shared/supplier-drop-down-filter/supplier-drop-down-filter.module';
import {ReservedUpcReviewComponent} from './2.0.0/upc-reservation/reserved-upc-review/reserved-upc-review.component';
import {CandidateProductListModule} from '../../projects/pm-components/src/lib/shared/candidate-product-list/candidate-product-list.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {InputMaskModule} from 'primeng/inputmask';
import {SpinnerModule} from 'primeng/spinner';
import {PaginatorModule} from 'primeng/paginator';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {TooltipModule} from 'primeng/tooltip';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TabViewModule} from 'primeng/tabview';
import {FileUploadModule} from 'primeng/fileupload';
import {CheckboxModule} from 'primeng/checkbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {BuyerDetailsCardModule} from '../../projects/pm-components/src/lib/cards/buyer-details-card/buyer-details-card.module';
import {SharedModule} from './2.0.0/shared/shared.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {LabelInsightsImageCardModule} from 'pm-components/lib/cards/label-insights-image-card/label-insights-image-card.module';
import {SupplierModule} from './2.0.0/supplier/supplier.module';
import {UploadDsdTableModule} from 'pm-components/lib/shared/upload-dsd-table/upload-dsd-table.module';
import {ExtendedAttributesComponent} from './2.0.0/supplier-product-flow/extended-attributes/extended-attributes.component';
import {RetailLinkCardComponent} from 'pm-components/lib/cards/retail-link-card/retail-link-card.component';
import {MatHierarchyCardModule} from 'pm-components/lib/cards/mat-hierarchy-card/mat-hierarchy-card.module';
import {InnerExtendedAttributesComponent} from './2.0.0/supplier-mrt-flow/inner-extended-attributes/inner-extended-attributes.component';
import {
  PiaNewProductExtendedAttributesComponent
} from './2.0.0/pia-new-product-flow/pia-new-product-extended-attributes/pia-new-product-extended-attributes.component';
import {
  PiaInnerExtendedAttributesComponent
} from './2.0.0/pia-new-mrt-flow/pia-inner-extended-attributes/pia-inner-extended-attributes.component';
import {
  BonusExtendedAttributesComponent
} from './2.0.0/supplier-bonus-size-flow/bonus-extended-attributes/bonus-extended-attributes.component';
import {
  PiaBonusExtendedAttributesComponent
} from './2.0.0/pia-new-bonus-upc-flow/pia-bonus-extended-attributes/pia-bonus-extended-attributes.component';
import {
  AdditionalCasePackExtendedAttributesComponent
} from './2.0.0/supplier-case-pack-flow/additional-case-pack-extended-attributes/additional-case-pack-extended-attributes.component';
import {
  PiaAdditionalCasePackExtendedAttributesComponent
} from './2.0.0/pia-new-case-pack-flow/pia-additional-case-pack-extended-attributes/pia-additional-case-pack-extended-attributes.component';
import {
  RequestNewAttributeConfirmModule
} from 'pm-components/lib/shared/request-new-attribute-confirm/request-new-attribute-confirm-module';
import {
  RequestNewMatAttributeFormModule
} from 'pm-components/lib/shared/request-new-mat-attribute-form/request-new-mat-attribute-form.module';
import { MrtExtendedAttributesComponent } from './2.0.0/supplier-mrt-flow/mrt-extended-attributes/mrt-extended-attributes.component';
import { PiaMrtExtendedAttributesComponent } from './2.0.0/pia-new-mrt-flow/pia-mrt-extended-attributes/pia-mrt-extended-attributes.component';
import { AssociateExtendedAttributesComponent } from './2.0.0/supplier-associate-upc-flow/associate-extended-attributes/associate-extended-attributes.component';
import { PiaAssociateExtendedAttributesComponent } from './2.0.0/pia-new-associate-upc-flow/pia-associate-extended-attributes/pia-associate-extended-attributes.component';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    TaskViewerComponent,
    GrowlComponent,
    TaskViewerTableComponent,
    VendorTaskViewerTableComponent,
    PiaTaskViewerTableComponent,
    LoginComponent,
    DateTimeFilterComponent,
    SupplierDetailsComponent,
    SetupCandidateComponent,
    SetupCandidateTypeComponent,
    BasicItemSetupComponent,
    CasePackComponent,
    BuyerNotificationsComponent,
    SupplierNotificationsComponent,
    SetUpNotificationsComponent,
    SupplierNotificationsComponent,
    BuyerProductReviewComponent,
    ScaProductReviewComponent,
    ProcurementSupportProductReviewComponent,
    UnsubscribeNotificationsComponent,
    SetupAdditionalCasePackComponent,
    AdditionalCasePackDetailsComponent,
    SetupBonusSizeComponent,
    BonusDetailsComponent,
    BonusCaseDetailsComponent,
    BuyerAdditionalCasePackReviewComponent,
    SupplierBonusStepperComponent,
    ScaAdditionalCasePackComponent,
    PiaAdditionalCasePackComponent,
    SetupAssociateUpcComponent,
    AssociateBasicItemSetupComponent,
    AssociateUpcProcurementReviewComponent,
    BuyerBonusSizeReviewComponent,
    AssociateCaseSelectionComponent,
    SCABonusSizeReviewComponent,
    PiaBonusSizeReviewComponent,
    SCABonusSizeReviewComponent,
    SetupMrtComponent,
    MrtCaseDetailsComponent,
    MrtCaseStepperComponent,
    SetUpNewMrtItemsComponent,
    InnerSupplierAndHebDetailsComponent,
    RequestHebUpcComponent,
    InnerProductDetailsComponent,
    AddNewMrtCaseComponent,
    InnerCaseDetailsComponent,
    MrtInnerStepperComponent,
    SupplierMrtReviewComponent,
    BuyerMrtReviewComponent,
    ScaMrtReviewComponent,
    PiaMrtReviewComponent,
    RequestHebUpcSuccessComponent,
    ProcurementSupportProductReviewClosedComponent,
    PiaAdditionalCasePackClosedComponent,
    AssociateUpcProcurementReviewClosedComponent,
    PiaBonusSizeReviewClosedComponent,
    PiaMrtReviewClosedComponent,
    PiaSetupNewProductComponent,
    PiaNewProductSupplierDetailsComponent,
    PiaNewProductFlowStepperComponent,
    MrtCaseDetailsReviewComponent,
    ProcurementSupportProductReviewClosedComponent,
    SupplierBonusReviewComponent,
    PiaNewProductDetailsComponent,
    PiaNewProductCaseDetailsComponent,
    PiaNewProductWarehousesComponent,
    SupplierBonusReviewComponent,
    AssociateReviewPageComponent,
    NewProductReviewPageComponent,
    AdditionalCasePackReviewComponent,
    NewWarehouseProductStepperComponent,
    NewDsdProductStepperComponent,
    StoreAuthorizationComponent,
    PiaNewFreightTaxUpcComponent,
    SupplierMrtInnerReviewComponent,
    PiaMrtInnerReviewClosedComponent,
    ProductSearchComponent,
    NeedAssistanceComponent,
    ScaNotificationsComponent,
    SetUpStoresComponent,
    ScaNotificationsComponent,
    PiaAssociateBasicItemSetupComponent,
    PiaAssociateCaseSelectionComponentComponent,
    PiaAssociateUpcSetupComponentComponent,
    PiaBonusDetailsComponent,
    PiaBonusCaseDetailsComponent,
    PiaSetupBonusSizeComponent,
    PiaBonusStepperComponent,
    PiaBonusWarehouseComponent,
    SetUpStoresOverlayComponent,
    PiaSetupCasePackComponent,
    PiaAdditionalCasePackDetailsComponent,
    PiaAdditionalCasePackWarehouseComponent,
    PiaAdditionalCasePackStepperComponent,
    ObRegScalePluReviewComponent,
    PiaNewDsdProductFlowStepperComponent,
    PiaStoreAuthorizationComponent,
    PiaSetUpStoresComponent,
    ObRegScalePluReviewComponent,
    NewUserRoleRequestComponent,
    PiaScalePluReviewComponent,
    InvitedDistributorCaseDetailsComponent,
    SetUpInvitedDistributorStoresComponent,
    InvitedDistributorStoreAuthorizationComponent,
    InvitedDistributorStepperComponent,
    BuyerInvitedDistributorsReviewComponent,
    PharmNewProductSetupComponent,
    PharmTaskViewerTableComponent,
    PharmNewProductFlowStepperComponent,
    PiaInvitedDistributorsReviewComponent,
    PharmNewProductSupplierDetailsComponent,
    PharmNewProductDetailsComponent,
    PharmNewProductCaseDetailsComponent,
    PharmNewProductWarehousesComponent,
    PharmNewDsdProductFlowStepperComponent,
    PiaSetupMrtComponent,
    PiaMrtStepperComponent,
    PiaMrtWarehouseComponent,
    PiaMrtCaseDetailsComponent,
    PiaSetupNewMrtItemsComponent,
    PiaInnerSupplierAndHebDetailsComponent,
    PiaInnerProductDetailsComponent,
    PiaMrtInnerStepperComponent,
    PiaAddNewMrtCaseComponent,
    PiaInnerCaseDetailsComponent,
    PiaMrtInnerWarehouseComponent,
    VolumeUploadComponent,
    SupplierAddDistributorComponent,
    PharmNewProductClosedComponent,
    BuyerBulkReviewComponent,
    ScaBulkReviewComponent,
    UploadProductsComponent,
    InvitedDistributorReviewPageComponent,
    PiaInvitedDistributorsReviewClosedComponent,
    VolumeUploadSettingsComponent,
    PiaInvitedDistributorsReviewClosedComponent,
    PiaAddDistributorComponent,
    PiaNewInvitedDistributorCaseDetailsComponent,
    PiaNewInvitedDistributorStepperComponent,
    PiaNewInvitedDistributorStoreAuthorizationComponent,
    PiaNewInvitedDistributorReviewComponent,
    PiaNewInvitedDistributorSetupStoresComponent,
    PiaBulkReviewComponent,
    ReservedUpcsComponent,
    ReservedUpcReviewComponent,
    ExtendedAttributesComponent,
    RetailLinkCardComponent,
    InnerExtendedAttributesComponent,
    PiaNewProductExtendedAttributesComponent,
    PiaInnerExtendedAttributesComponent,
    BonusExtendedAttributesComponent,
    PiaBonusExtendedAttributesComponent,
    AdditionalCasePackExtendedAttributesComponent,
    PiaAdditionalCasePackExtendedAttributesComponent,
    MrtExtendedAttributesComponent,
    PiaMrtExtendedAttributesComponent,
    AssociateExtendedAttributesComponent,
    PiaAssociateExtendedAttributesComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    TableModule,
    ToastModule,
    DynamicDialogModule,
    ButtonModule,
    InputMaskModule,
    SpinnerModule,
    SubmitButtonSpinnerModule,
    ProgressSpinnerModule,
    MessagesModule,
    BrowserAnimationsModule,
    PaginatorModule,
    DialogModule,
    TooltipModule,
    MenubarModule,
    BrowserAnimationsModule,
    AttributeModalModule,
    ConfirmDialogModule,
    DropdownModule,
    AutoCompleteModule,
    RadioButtonModule,
    TabViewModule,
    NgxPermissionsModule.forRoot(),
    FileUploadModule,
    LayoutModule,
    ComponentCardsModule,
    PMComponentsSharedModule,
    ProductPackagingCardModule,
    ProductImagesCardModule,
    CoreModule,
    DrawerModule,
    CheckboxModule,
    TaskViewerTableModule,
    TaskViewerTableClosedModule,
    TaskViewerTableRejectedModule,
    TaskViewerTableInProgressModule,
    AttributeGridModule,
    SupplierModule,
    AssociateUpcStepperModule,
    // StepperModule,
    DeleteButtonsModule,
    EditButtonModule,
    StaticOverlayPanelModule,
    DistributionChannelModule,
    MerchandisingInfoCardModule,
    PricingCardModule,
    OmiRemarksCardModule,
    MaxShipCardModule,
    SelectButtonModule,
    SellableModule,
    RejectCandidateModule,
    RejectStatusHeaderModule,
    ImportFactoryCardModule,
    ImportFactorySearchBarModule,
    ImportedProductCardModule,
    NoStoresMessageModule,
    NoStoresMessageExtendedModule,
    ScaleInformationCardModule,
    AttachmentUploadReviewPageModule,
    BuyerDropDownFilterModule,
    OverlayPanelModule,
    UploadCandidateModule,
    UploadProductTableModule,
    UploadCandidateTableModule,
    UploadCaseTableModule,
    UploadSupplierAndHebTableModule,
    UploadWarehouseTableModule,
    UploadDsdTableModule,
    ErrorPanelModule,
    CategorySelectionModule,
    UploadSettingsConfigurationModule,
    MatHierarchyCardModule,
    SupplierDropDownFilterModule,
    CandidateProductListModule,
    BuyerDetailsCardModule,
    ScrollingModule,
    LabelInsightsImageCardModule,
    RequestNewAttributeConfirmModule,
    RequestNewMatAttributeFormModule
  ],
  providers:
    [
      EditCandidateModalService, WorkflowService, PublisherService, CandidateService,
      LookupService, MessageService, GrowlService,
      ConfirmationService,
      AuthGuard, BdaOrScaGuard, AuthService, CurrencyPipe, DatePipe,
      FilteringService,
      PreferencesService, OwnBrandService, OwnBrandService, HistoryService,
      AppConfigService,
      {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
      {provide: ErrorHandler, useClass: CustomErrorHandler},
      {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFn,
        multi: true,
        deps: [AppConfigService]
      }
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
