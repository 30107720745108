import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType, Task,
  TextInputType,
  WAREHOUSE_MAXSHIP_MAX,
  WorkflowState
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {forkJoin, Observable} from 'rxjs';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {LookupService} from '../../service/lookup.service';
import {ReviewComponent} from 'pm-components';
import {AuthService} from '../../auth/auth.service';
import {WorkflowService} from '../../service/workflow.service';
import {GrowlService} from '../../growl/growl.service';
import {ProcessVariables} from 'pm-models/lib/processVariables';

@Component({
  selector: 'app-sca-bulk-review',
  templateUrl: './sca-bulk-review.component.html',
  styleUrls: ['./sca-bulk-review.component.scss']
})
export class ScaBulkReviewComponent implements OnInit {
  @ViewChild(ReviewComponent) pmReview;

  public CASE_ID = 'C';
  public EACH_ID = 'E';


  originalCandidates: Candidate[];
  candidatesInDrawer: Candidate[];
  candidatesInTable: Candidate[];
  workflowState: string = WorkflowState.SCA_NEW_PRODUCT_REVIEW;
  candidateSubscription$: any;
  isViewingPage: boolean;
  candidateProductIndex: number;

  public candidateError: CandidateError;
  public candidateErrors: Map<any, any> = new Map();
  public candidateProductError: CandidateProductError;
  public warehouses: any = undefined;
  public warehouseData = [];
  public orderRestrictions: any[];
  public warehouseMissingNotesEntered: boolean = false;

  public currentMaxShip: number;
  public isMissingWarehouses: boolean;
  public currentMissingWarehousesComment: string;
  public isApproveDisabled = false;

  constructor(public route: ActivatedRoute, public candidateService: CandidateService, public router: Router,
              public lookupService: LookupService, public authService: AuthService, private workflowService: WorkflowService,
              public growlService: GrowlService) {
  }

  ngOnInit() {
    this.isViewingPage = true;
    this.getCandidates().then();
  }

  async getCandidates() {
    const observables: Observable<Candidate>[] = [];
    this.candidateSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (!params.has('toReview')) {
        return;
      }
      for (let x = 0; x < params.getAll('toReview').length; x++) {
        const id: number  = +params.getAll('toReview')[x];
        observables.push(this.candidateService.getCandidate(id));
      }
      forkJoin(observables).subscribe( candidates => {
        if (candidates && candidates.length > 0) {
          this.candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(candidates[0]);
        }
        this.originalCandidates = candidates;
        this.candidatesInDrawer = candidates.slice();
        this.candidatesInTable = candidates.slice();
        if (!this.originalCandidates[0].codeDate) {
          this.workflowState = WorkflowState.SCA_NEW_PRODUCT_NO_CODE_DATE_REVIEW;
        }
        this.getOrderRestrictions();
        // this.setReferenceCandidateData();
        this.getWarehouses(this.candidatesInDrawer[0]);
        this.pmReview.openDrawer();
        }
      );
    });
  }

  onClose() {
    this.isViewingPage = false;
    // save only the candidate data in the tables, not the drawer data.
    this.candidateService.saveAllCandidates(this.candidatesInTable).subscribe(() => {
      this.router.navigate(['/tasks']).then();
    });
  }

  getToReviewSize() {
    return this.candidatesInTable ? this.candidatesInTable.length : '0';
  }

  changeCandidate(event, fieldName: string) {
    this.candidatesInDrawer.forEach(candidate => {
      candidate[fieldName] = event;
    });
  }

  changeCandidateProduct(event, fieldName: string) {
    this.candidatesInDrawer.forEach(candidate => {
      candidate.candidateProducts[0][fieldName] = event;
    });
  }

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    const candidateProduct = this.candidatesInDrawer[0].candidateProducts[this.candidateProductIndex];
    if (this.candidatesInDrawer[0].candidateProducts[this.candidateProductIndex].warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({},
              candidateProduct.warehouses[y], JSON.parse(JSON.stringify(candidateProduct.warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  warehouseMissingCheckboxChange(event, isMissingWarehouses: boolean, missingWarehouses: string) {
    const checked = event.checked;
    this.changeCandidate(isMissingWarehouses, missingWarehouses);
    if (!checked) {
      this.warehouseMissingNotesEntered = false;
      this.changeCandidate(null, 'missingWarehousesComment');
      this.changeCandidate(null, 'missingWarehousesCommentUser');
      this.changeCandidate(null, 'missingWarehousesCommentTime');
      this.candidateError.missingWarehousesComment = undefined;
    } else {
      this.changeCandidate('', 'missingWarehousesComment');
      this.changeCandidate(this.authService.getUser(), 'missingWarehousesCommentUser');
      this.changeCandidate(new Date().getTime(), 'missingWarehousesCommentTime');
    }
  }

  missingWarehousesCommentsConfiguration: AttributeTextInputConfig = {
    label: 'Notes for Procurement Support',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-half-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: '',
    name: 'missingWarehouseCommentId',
    maxLength: 4000,
    isRequired: true
  };

  updatedMissingWarehousesComment() {
    this.warehouseMissingNotesEntered = true;
  }

  applyChangesToCandidates() {
    this.changeCandidateProduct(this.getSelectedWarehouses(), 'warehouses');
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  onClickApprove() {
    this.isApproveDisabled = true;

    this.applyFields();
    this.candidateService.validateBulkCandidate(this.candidatesInDrawer, [CandidateValidatorType.SCA_PRODUCT_REVIEW_VALIDATOR])
      .subscribe((validationResponse) => {
        this.candidateErrors = new Map();
        this.candidateError = new CandidateError();

        // add any candidate errors to the map.
        for (const key of Object.keys(validationResponse)) {
          if (validationResponse[key]?.candidateError) {
            this.candidateErrors.set(+key, validationResponse[key]?.candidateError);
          }
        }
        // if there's errors
        if (this.candidateErrors.size > 0) {
          this.candidateError = this.candidateErrors.values().next().value;
          this.candidateProductError = this.candidateError.candidateProductErrors[this.candidatesInDrawer[0].candidateProducts[0].id];
          this.isApproveDisabled = false;
        } else {
          const processVariables: ProcessVariables[] = [];
          this.candidatesInTable.forEach(candidate => {
            processVariables.push({
              candidateId: candidate.candidateId, apNumber: candidate.vendor?.apNumber ? candidate.vendor.apNumber : null,
              moveToWorkflowState: Task.PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID
            });
          });

          this.candidateService.saveAllCandidates(this.candidatesInTable).subscribe(() => {
            this.workflowService.updateCandidateTasksStates(processVariables).subscribe(() => {
              this.router.navigate(['/tasks']).then();
            });
          });
        }

      }, (error) => {
        this.isApproveDisabled = false;
        if (error && error.message) {
          this.growlService.addError(error.message);
        } else {
          this.growlService.addError(error);
        }
      });
  }

  applyFields() {
    this.candidatesInTable.forEach(candidate => {
      candidate.maxShip = this.currentMaxShip;
      candidate.candidateProducts[this.candidateProductIndex].warehouses = this.warehouseData.filter(warehouse => warehouse.checked);
    });
  }
}
