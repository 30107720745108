import { BaseAttribute } from './base-attribute';
// tslint:disable-next-line:max-line-length
import { AttributeDisplayToggleComponent } from '../../shared/attribute-display/attribute-display-toggle/attribute-display-toggle.component';
import { Candidate, AttributeConfig } from 'pm-models';

export class PrePrice implements BaseAttribute {

  private defaultVal = false;
  getComponentType() {
    return AttributeDisplayToggleComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Pre-price',
      description: 'Let us know if the product packaging lists the suggested retail price.',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      defaultValue: false,
      name: 'prePriceId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayToggleComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    if (candidate.prePrice === undefined) {
      candidate.prePrice = this.defaultVal;
    }
    componentInstance.model = candidate.prePrice;
    componentInstance.change.subscribe(x => {
      candidate.prePrice = x.checked;
    });
  }
}
