import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version: string = environment.VERSION;
  public candidateVersion: string;
  public lookupVersion: string;
  public workflowVersion: string;
  public authVersion: string;

  constructor(private candidateService: CandidateService, private lookupService: LookupService,
              private workflowService: WorkflowService, private authService: AuthService) { }

  ngOnInit() {
    this.candidateService.getCandidateServiceVersion().subscribe((version) => {
      this.candidateVersion = version.number;
    });
    this.lookupService.getLookupServiceVersion().subscribe((version) => {
      this.lookupVersion = version.number;
    });
    this.workflowService.getWorkflowServiceVersion().subscribe((version) => {
      this.workflowVersion = version.number;
    });
    this.authService.getAuthServiceVersion().subscribe((version) => {
      this.authVersion = version.number;
    });
  }


  /**
   * Returns the tooltip for upload image requirements.
   * @returns Candidate Products Tooltip
   */
  getBackEndVersionToolTip() {
    return 'Services\n\n' +
      '\u2022 Auth: ' + this.authVersion + '\n' +
      '\u2022 Candidate: ' + this.candidateVersion + '\n' +
      '\u2022 Lookup: ' + this.lookupVersion + '\n' +
      '\u2022 Workflow: ' + this.workflowVersion + '\n';
  }
}
