<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-new-warehouse-product-stepper *ngIf="supplierProductService.candidate && !supplierProductService.isDsdOnly()"
                                       [candidateErrors]="supplierProductService.getAllWhsCandidateErrors()"
                                       [stepIndex]="4"
                                       [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                       (indexClick)="onClickStepper($event)">
    </app-new-warehouse-product-stepper>
    <app-new-dsd-product-stepper *ngIf="supplierProductService.candidate && supplierProductService.isDsdOnly()"
                                 [candidateErrors]="supplierProductService.getAllDsdCandidateErrors()"
                                 [stepIndex]="4"
                                 [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                 (indexClick)="onClickStepper($event)">
    </app-new-dsd-product-stepper>
  </pm-header>
  <!-- Page Titles -->
  <pm-title>Case Pack</pm-title>
  <!-- Page Cards -->
  <!--
    We're using  an ng-container to not add additional
    DOM elements and only show the cards when the candidate is loaded
  -->
  <ng-container *ngIf="supplierProductService.candidate">
    <pm-master-pack-details-card *ngIf="!supplierProductService.isDsdOnly()"
                                 [(model)]="supplierProductService.candidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="candidateError"
                                 (masterPackChange)="onMasterPackChange($event)">
    </pm-master-pack-details-card>


    <pm-inner-pack-details-card *ngIf="!supplierProductService.isDsdOnly()"
                                [(model)]="supplierProductService.candidate"
                                [permissions]="innerPackDetailsCardPermissions"
                                [errorModel]="candidateError"
                                (modelChange)="onInnerPackChange()"
                                (innerPackQuantityChange)="innerPackQuantityChange()">
    </pm-inner-pack-details-card>

    <pm-case-details-card [(candidateModel)]="supplierProductService.candidate"
                          [(candidateProductModel)]="supplierProductService.candidate.candidateProducts[0]"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="candidateError"
                          [candidateProductErrorModel]="candidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUpc()"
                          (masterPackChange)="onMasterPackChange($event)">
    </pm-case-details-card>

    <pm-display-ready-unit-card *ngIf="isSellable && !supplierProductService.isDsdOnly()" [(model)]="supplierProductService.candidate"
                                [permissions]="displayReadyUnitCardPermissions"
                                [errorModel]="candidateError">
    </pm-display-ready-unit-card>

    <pm-cost-link-card *ngIf="isSellable" [model]="supplierProductService.candidate"
                       (validateCostLink)="validateCostLink()"
                       [costLinkState]="costLinkState"
                       [errorModel]="candidateError"
                       [permissions]="costLinkCardPermissions">
    </pm-cost-link-card>

    <pm-cost-card [model]="supplierProductService.candidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="candidateError">
    </pm-cost-card>

    <pm-attachments-card [(model)]="supplierProductService.candidate"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="candidateError">
    </pm-attachments-card>
  </ng-container>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button *ngIf="!supplierProductService.isDsdOnly()" class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
    <p-button *ngIf="supplierProductService.isDsdOnly()" class="m-2" label="Next" (onClick)="onClickNextDsd()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
