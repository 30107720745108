import {
  Component,
  Directive,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  QueryList,
  ContentChildren,
  ElementRef,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { PMToolbarTitleDirective } from '../shared/toolbar/toolbar-title.directive';
import { PMToolbarSectionDirective } from '../shared/toolbar/toolbar-section.directive';
import { ToolbarComponent } from '../shared/toolbar/toolbar.component';

/** A default title to place in a toolbar or toolbar section. */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'pm-header-title',
  host: {
    class: 'mtr-toolbar__title'
  }
})
export class MtrHeaderTitleDirective extends PMToolbarTitleDirective {}

/** Header sections allow for separation of content and actions within a single toolbar */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'pm-header-section',
  host: {
    class: 'mtr-header__section mtr-toolbar__section',
    '[class.mtr-toolbar__section--align-start]': 'align === "start"',
    '[class.mtr-toolbar__section--align-end]': 'align === "end"'
  }
})
export class MtrHeaderSectionDirective extends PMToolbarSectionDirective {}

@Component({
  selector: 'pm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  exportAs: 'pmHeader',
  host: {
    class: 'mtr-header mtr-toolbar',
    '[class.mtr-toolbar--dense]': 'dense',
    '[class.mtr-toolbar--transparent]': 'transparent',
    '[class.mtr-header--sticky]': 'sticky'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends ToolbarComponent {
  /** Sets the app headers position to sticky  */
  @Input() sticky: boolean = false;


  /** Template ref for the header logo */
  @Input() logoRef: TemplateRef<any>;

  /**
   * Router link that will be navigated to when the logo is clicked.
   * Passing a falsey value will prevent the logo from rendering as a link.
   */
  @Input() logoRouterLink: string[] | string = '/';


  @ContentChildren(PMToolbarSectionDirective, { descendants: true }) sections: QueryList<PMToolbarSectionDirective>;

  constructor(elementRef: ElementRef, public changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
  }


}
