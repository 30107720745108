<pm-editor #pmEditor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-pia-mrt-stepper [innerFlow]="supplierMrtService.hasNewInners(supplierMrtService.candidate) === true"
                         [candidateErrors]="supplierMrtService.getAllPiaMrtErrors()"
                         [hasInnerCandidateError]="[supplierMrtService.hasInnerErrors()]"
                         [stepIndex]="2"
                         (indexClick)="onClickStepper($event)">
    </app-pia-mrt-stepper>
  </pm-header>
  <div class="col-md-11 pb-5" style="margin-top: 5rem; margin-left: -18%;">
    <div class="row">
      <div class="col-md-4 text-center">
        <img class="new-mrt-items-image" src="../../../../assets/images/mrt-new-items.png">
      </div>
      <div class="col-md-8">
        <div class="row">
          <h3>
            Let’s set up those new items.
          </h3>
          <p>
            This MRT contains items that don’t exist in our system yet.
            Before creating your MRT, we need to set up the items below.
          </p>
        </div>
        <div class="row pt-3" *ngFor="let mrtInner of mrtNewItemInners">
          <div class="mrt-inner pt-3 pb-3">
            <div class="row ml-3">
              <div class="col-md-8">
                <div class="row">
                  UPC: {{supplierMrtService.getCurrentCandidateProducts(mrtInner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(mrtInner).upcCheckDigit}}
                  <div *ngIf="mrtInner.valid" class="new-item mr-1 ml-2">
                    <img src="/assets/images/mrt-new-item.svg">
                    <p>NEW ITEM</p>
                  </div>

                  <div *ngIf="supplierMrtService.isNewItem(mrtInner)" class="new-item">
                    <img src="/assets/images/mrt-new-item.svg">
                    <p>NEW CASE</p>
                  </div>
                </div>
                <div class="row">
                  <div class="attribute-description">
                    Sellable units in MRT: {{mrtInner.quantity}}
                  </div>
                </div>
                <div *ngIf="mrtInner.valid === false" class="row incomplete-inner-status">
                  <i class="pi pi-exclamation-triangle"></i>
                  <p>Incomplete</p>
                </div>
              </div>
              <div class="col-md-4 button-column">
                <div class="row mr-3">
                  <div>
                    <pm-delete-button (deleteButtonClicked)="deleteInner(mrtInner)" [style]="{margin: '-0.5% 0 0 -25%'}"
                                      [disabled]="supplierMrtService.isDeleteDisabled()" [tooltip]="supplierMrtService.getDisabledTooltip()">
                    </pm-delete-button>
                  </div>
                  <p-button class="m-2" *ngIf="!mrtInner.vendorEdited" label="Set up" (onClick)="setUpMrtInner(mrtInner)"></p-button>
                  <pm-edit-button *ngIf="mrtInner.vendorEdited" (editButtonClicked)="setUpMrtInner(mrtInner)"></pm-edit-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pm-footer style="margin-right: -15%;">
    <p-button class="ghost m-2" label="Back to UPCs/Item codes" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button *ngIf="!supplierMrtService.hasInnersToSetup()" class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
