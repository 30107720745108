import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDescriptionsCardComponent } from './product-descriptions-card.component';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import { CardModule } from '../../shared/card/card.module';

@NgModule({
  declarations: [ProductDescriptionsCardComponent],
  exports: [ProductDescriptionsCardComponent, CardModule, AttributeDisplayModule],
  imports: [CommonModule, CardModule, AttributeDisplayModule]
})
export class ProductDescriptionsCardModule {}
