<div class="ui-upcinput" *ngIf="hidePlu">
  <div class="ui-inputgroup" [ngClass]="{'ui-upc-invalid' : hasError}">
    <p-dropdown [options]="upcOptions" [(ngModel)]="upcType" *ngIf="upcOptions"
                [readonly]="readOnly"
                [disabled]="disabled" placeholder="" optionLabel="">
    </p-dropdown>
    <input type="text" pInputText class="upc-input" placeholder="{{placeholderText}}" appOnlyNumber
           (blur)="upcBlur($event)" (keydown)="upcKeyDown($event)" [readonly]="readOnly" [disabled]="disabled"
           [ngClass]="{'ui-inputtext-invalid' : hasError}" [ngModel]="modelDisplay" (ngModelChange)="upcChange($event)"
           [ngModelOptions]="{updateOn:'blur'}" maxlength="13">
    <span class="ui-inputgroup-addon" *ngIf="!readOnly && !disabled">
      <i class="pi pi-spin pi-spinner" *ngIf="state===1"></i>
      <i class="material-icons ui-success" *ngIf="state === 2">check_circle_outline</i>
      <i class="material-icons ui-invalid rotate-45" *ngIf="state === 3">add_circle_outline</i>
    </span>
  </div>
  <input *ngIf="!hideCheckDigit" type="text" pInputText [(ngModel)]="checkDigitModel" placeholder="{{checkDigitPlaceholderText}}"
         appOnlyNumber
         (keydown)="checkDigitKeyDown($event)" class="upc-check-digit-input" (blur)="upcBlur($event)" [readonly]="readOnlyCheckDigit"
         [disabled]="readOnlyCheckDigit" [ngClass]="{'ui-inputtext-invalid' : hasError}" maxlength="1">


</div>
<div class="ui-pluinput" *ngIf="!hidePlu">
  <div class="ui-inputgroup" [ngClass]="{'ui-upc-invalid' : hasError}">
    <p-dropdown [options]="upcOptions" [(ngModel)]="upcType" *ngIf="upcOptions"
                [readonly]="readOnly" (onChange)="resetPlu()"
                [disabled]="disabled" placeholder="" optionLabel="">
    </p-dropdown>
    <div class="row pluGroup ml-0">
      <p-dropdown placeholder="{{pluTypePlaceholderText}}" [options]="pluTypes"
                  [(ngModel)]="pluTypeModel" [readonly]="readOnly" (onChange)="onTypeSelection($event)"
                  [disabled]="disabled" optionLabel="">
      </p-dropdown>
      <pm-attribute-display-typeahead [attribute]="pluRangeConfiguration"
                  [(model)]="pluRangeModel" (selection)="onRangeSelection($event)">
      </pm-attribute-display-typeahead>
    </div>
  </div>
  <div *ngIf="upcModel" class="pluDisplay col" [ngClass]="{'ui-upc-invalid' : hasError}">
    <div class="row" *ngIf="showPlu()">PLU: {{upcModel | upc}}-{{upcModel | upcCheckDigit}}</div>
    <div class="row" *ngIf="showFullPlu()">UPC: {{fullPlu | upc}}-{{fullPlu | upcCheckDigit}}</div>
  </div>
</div>
