import { Injectable } from '@angular/core';

@Injectable()
export class NumericProcessorsService {
  constructor() {}

  numericValidation(event, currentValue, numberCount, decimalCount) {
    let regex = new RegExp('(^\\d{0,' + numberCount + '}$)|(^\\d{0,' + numberCount + '}\\.\\d{0,' + decimalCount + '}$)');

    if (decimalCount === 0) {
      regex = new RegExp('(^\\d{0,' + numberCount + '}$)');
    }

    let newString;

    if (currentValue === undefined || currentValue === null || currentValue === '') {
      newString = event.key;
    } else {
      newString =
        ('' + currentValue).slice(0, event.target.selectionStart) +
        event.key +
        ('' + currentValue).slice(event.target.selectionEnd);
    }

    return regex.test(newString);
  }
}
