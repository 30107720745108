<div class="product-table-container">
  <div class="row ml-0 mr-0">
    <p-table selectionMode="single"
             [rowHover]="true"
             [columns]="caseHeaderColumns"
             [value]="candidates"
             [rows]="pageSize"
             [totalRecords]="totalNumRecords"
             [scrollable]="true"
             styleClass="p-datatable-gridlines"
             [ngStyle]="{'width': '100%'}">

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns"
               [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-productHeaderColumns>
        <tr class="product-table-column-group">
          <th class="left-sticky-header" [attr.colspan]="1">#</th>
          <th class="left-sticky-header" [attr.colspan]="1">UPC</th>
          <th *ngIf="COST_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="COST_COLUMN_GROUP_LENGTH">Cost</th>
          <th *ngIf="MASTER_PACK_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="MASTER_PACK_COLUMN_GROUP_LENGTH">Master pack</th>
          <th *ngIf="INNER_PACK_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="INNER_PACK_COLUMN_GROUP_LENGTH">Inner pack</th>
          <th *ngIf="CASE_DETAILS_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="CASE_DETAILS_COLUMN_GROUP_LENGTH">Case details</th>
          <th *ngIf="DISPLAY_READY_UNIT_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="DISPLAY_READY_UNIT_COLUMN_GROUP_LENGTH">Display ready unit</th>
          <th *ngIf="OMI_REMARKS_COLUMN_GROUP_LENGTH > 0" [attr.colspan]="OMI_REMARKS_COLUMN_GROUP_LENGTH">Remarks</th>
        </tr>
        <tr class="product-table-column-headers">
          <th *ngFor="let col of productHeaderColumns"
              [ngClass]="col.sticky ? 'left-sticky-header' : ''"
              [ngStyle]="{'width': col.width}"
              (click)="onClickHeader(col)">
            <div>
              {{col.header}}
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-candidate let-index="rowIndex">
        <tr class="product-table-body" [pSelectableRow]="candidate">

          <!--  candidate index -->
          <td class="sticky-column col">
            <div>{{index + 1}}</div>
          </td>
          <!--  UPC  -->
          <td class="sticky-column" (click)="editProductUPC(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])}}">
            </i>
            {{candidate?.candidateProducts[candidateProductIndex]?.upc}}
          </td>
          <!--  Cost linked  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.COST_LINKED)" (click)="showEditCandidateModal(index, attributeType.CostLinked)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['costLinked'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['costLinked'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['costLinked'])}}">
            </i>
            {{candidate.costLinked | yesNo}}
          </td>
          <!--  Cost linked item  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.COST_LINKED) && candidate.costLinked" (click)="editCostLink(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['costLink'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['costLink'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['costLink'])}}">
            </i>
            {{candidate.costLinked ? candidate.costLink : '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.COST_LINKED) && !candidate.costLinked"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['costLink'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['costLink'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['costLink'])}}">
            </i>
            {{candidate.costLinked ? candidate.costLink : '-'}}
          </td>
          <!--  Master list cost  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.MASTER_LIST_COST)" (click)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterListCost'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterListCost'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['masterListCost'])}}">
            </i>
            {{costService.toCurrencyForCost(candidate.masterListCost)}}
          </td>
          <!--  Inner list cost  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INNER_LIST_COST)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerListCost'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerListCost'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['innerListCost'])}}">
            </i>
            {{costService.toCurrencyForCost(candidate.innerListCost)}}
          </td>
          <!-- Deal offered  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.DEAL_OFFERED)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['dealOffered'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['dealOffered'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['dealOffered'])}}">
            </i>
            {{candidate.dealOffered | yesNo}}
          </td>
          <!--  Unit cost  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.UNIT_COST)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['unitCost'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['unitCost'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['unitCost'])}}">
            </i>
            {{costService.toCurrencyForCost(candidate.unitCost)}}
          </td>
          <!--  HEB margin %  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.HEB_MARGIN)">
            {{costService.getHebMargin(candidate)}}
          </td>
          <!--  HEB penny profit  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.HEB_PENNY_PROFIT)">
            {{costService.getHebPennyProfit(candidate)}}
          </td>
          <!--  Sold by weight  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.SOLD_BY_WEIGHT)" (click)="showEditCandidateModal(index, attributeType.SoldByWeight)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['weightSwitch'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['weightSwitch'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['weightSwitch'])}}">
            </i>
            {{candidate.weightSwitch | yesNo}}
          </td>
          <!-- Total retail units (master pack)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.MASTER_PACK_TOTAL_RETAIL_UNITS)" (click)="costService.editCostRelatedFields(attributeType.TotalRetail, candidate)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterPack'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterPack'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['masterPack'])}}">
            </i>
            {{candidate.masterPack}}
          </td>
          <!-- Weight (master pack)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.MASTER_PACK_WEIGHT)" (click)="showEditCandidateModal(index, attributeType.Weight)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterWeight'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterWeight'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['masterWeight'])}}">
            </i>
            {{candidateUtils.getWeightString(candidate.masterWeight)}}
          </td>
          <!--  Dimensions (master pack)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.MASTER_PACK_DIMENSIONS)" (click)="showEditCandidateModal(index, attributeType.Dimensions)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterLength', 'masterWidth', 'masterHeight'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterLength', 'masterWidth', 'masterHeight'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['masterLength', 'masterWidth', 'masterHeight'])}}">
            </i>
            {{candidateUtils.getMasterDimensions(candidate)}}
          </td>
          <!-- Order factor  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.ORDER_FACTOR)" (click)="showEditCandidateModal(index, attributeType.OrderFactor)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['cubeAdjustedFactor'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['cubeAdjustedFactor'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['cubeAdjustedFactor'])}}">
            </i>
            {{candidate.cubeAdjustedFactor | emptyToValue: '-'}}
          </td>
          <!--  Boxes on pallet (high & tier) -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.BOXES_ON_PALLET)" (click)="showEditCandidateModal(index, attributeType.BoxesOnPallet)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['vendorTie', 'vendorTier'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['vendorTie', 'vendorTier'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['vendorTie', 'vendorTier'])}}">
            </i>
            {{+candidate.vendorTie * +candidate.vendorTier}}
            <div class="cell-info">
              Tie: {{candidate.vendorTie}} Tier: {{candidate.vendorTier}}
            </div>
          </td>
          <!--  Inner packs (toggle)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INNER_PACKS)" (click)="costService.editCostRelatedFields(attributeType.InnerPackSelected, candidate)">
            {{candidate.innerPackSelected | yesNo}}
          </td>
          <!--  Inner pack quantity  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INNER_PACKS) && candidate.innerPackSelected" (click)="costService.editCostRelatedFields(attributeType.InnerPackQuantity, candidate)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerPack'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerPack'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['innerPack'])}}">
            </i>
            {{candidate.innerPack}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INNER_PACKS) && !candidate.innerPackSelected"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerPack'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerPack'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['innerPack'])}}">
            </i>
            {{candidate.innerPack}}
          </td>
          <!--  Dimensions (inner pack)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INNER_PACK_DIMENSIONS) && candidate.innerPackSelected" (click)="showEditCandidateModal(index, attributeType.InnerPackDimensions)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerLength', 'innerWidth', 'innerHeight'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerLength', 'innerWidth', 'innerHeight'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['innerLength', 'innerWidth', 'innerHeight'])}}">
            </i>
            {{candidateUtils.getInnerDimensions(candidate)}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INNER_PACK_DIMENSIONS) && !candidate.innerPackSelected"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerLength', 'innerWidth', 'innerHeight'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerLength', 'innerWidth', 'innerHeight'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['innerLength', 'innerWidth', 'innerHeight'])}}">
            </i>
            {{candidateUtils.getInnerDimensions(candidate)}}
          </td>
          <!--  Weight (inner pack)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INNER_PACK_WEIGHT) && candidate.innerPackSelected" (click)="showEditCandidateModal(index, attributeType.InnerPackWeight)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerWeight'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerWeight'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['innerWeight'])}}">
            </i>
            {{candidateUtils.getWeightString(candidate.innerWeight)}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INNER_PACK_WEIGHT) && !candidate.innerPackSelected"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerWeight'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['innerWeight'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['innerWeight'])}}">
            </i>
            {{candidateUtils.getWeightString(candidate.innerWeight)}}
          </td>
          <!--  Case UPC  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.CASE_UPC)" (click)="editCaseUPC(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseUpc'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseUpc'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseUpc'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].caseUpc}}
          </td>
          <!--  Case description  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.CASE_DESCRIPTION)" (click)="showEditCandidateProductModal(index, attributeType.CaseDescription)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseDescription'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseDescription'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseDescription'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].caseDescription}}
          </td>
          <!--  VPC  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.VENDOR_PRODUCT_CODE)" (click)="showEditCandidateProductModal(index, attributeType.VendorProductCode)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['vendorProductCode'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['vendorProductCode'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['vendorProductCode'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].vendorProductCode}}
          </td>
          <!--  Country of origin  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.COUNTRY_OF_ORIGIN)" (click)="showEditCandidateProductModal(index, attributeType.CountryOfOrigin)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['countryOfOrigin'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['countryOfOrigin'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['countryOfOrigin'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].countryOfOrigin?.description}}
          </td>
          <!--  Weight type (fixed, catch weight, variable weight)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.WEIGHT_TYPE)" (click)="editWeightType(index, attributeType.ItemWeightType)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['itemWeightType'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['itemWeightType'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['itemWeightType'])}}">
            </i>
            {{candidateUtils.getItemWeightType(candidate)}}
          </td>
          <!--  Code date -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.CODE_DATE)" (click)="showEditCandidateModal(index, attributeType.CodeDate)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['codeDate'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['codeDate'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['codeDate'])}}">
            </i>
            {{candidate.codeDate | yesNo}}
          </td>
          <!--  Max shelf life  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.MAX_SHELF_LIFE) && candidate.codeDate" (click)="showEditCandidateModal(index, attributeType.MaxShelfLife)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['maxShelfLife'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['maxShelfLife'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['maxShelfLife'])}}">
            </i>
            {{candidate.codeDate ? candidateUtils.getDaysString(candidate, candidate.maxShelfLife) : '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.MAX_SHELF_LIFE) && !candidate.codeDate"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['maxShelfLife'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['maxShelfLife'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['maxShelfLife'])}}">
            </i>
            {{candidate.codeDate ? candidateUtils.getDaysString(candidate, candidate.maxShelfLife) : '-'}}
          </td>
          <!-- Inbound spec  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INBOUND_SPEC_DAYS) && candidate.codeDate" (click)="showEditCandidateModal(index, attributeType.InboundSpecDays)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['inboundSpecDays'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['inboundSpecDays'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['inboundSpecDays'])}}">
            </i>
            {{candidate.codeDate ? candidateUtils.getDaysString(candidate, candidate.inboundSpecDays) : '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.INBOUND_SPEC_DAYS) && !candidate.codeDate"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['inboundSpecDays'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['inboundSpecDays'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['inboundSpecDays'])}}">
            </i>
            {{candidate.codeDate ? candidateUtils.getDaysString(candidate, candidate.inboundSpecDays) : '-'}}
          </td>
          <!-- Reaction days  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.REACTION_DAYS) && candidate.codeDate" (click)="showEditCandidateModal(index, attributeType.ReactionDays)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['warehouseReactionDays'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['warehouseReactionDays'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['warehouseReactionDays'])}}">
            </i>
            {{candidate.codeDate ? candidateUtils.getDaysString(candidate, candidate.warehouseReactionDays) : '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.REACTION_DAYS) && !candidate.codeDate"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['warehouseReactionDays'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['warehouseReactionDays'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['warehouseReactionDays'])}}">
            </i>
            {{candidate.codeDate ? candidateUtils.getDaysString(candidate, candidate.warehouseReactionDays) : '-'}}
          </td>
          <!--  Guarantee to store days  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.GUARANTEE_TO_STORE_DAYS) && candidate.codeDate" (click)="showEditCandidateModal(index, attributeType.GuaranteeDays)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['guaranteeToStoreDays'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['guaranteeToStoreDays'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['guaranteeToStoreDays'])}}">
            </i>
            {{candidate.codeDate ? candidateUtils.getDaysString(candidate, candidate.guaranteeToStoreDays) : '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.GUARANTEE_TO_STORE_DAYS) && !candidate.codeDate"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['guaranteeToStoreDays'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['guaranteeToStoreDays'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['guaranteeToStoreDays'])}}">
            </i>
            {{candidate.codeDate ? candidateUtils.getDaysString(candidate, candidate.guaranteeToStoreDays) : '-'}}
          </td>
          <!--  DRU (toggle)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.DISPLAY_READY_UNIT)" (click)="editIsDru(index)">
            {{candidate.displayReadyUnit | yesNo}}
          </td>
          <!--  # Retail units (DRU)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.DRU_RETAIL_UNITS) && candidate.displayReadyUnit" (click)="showEditCandidateModal(index, attributeType.RetailUnitCount)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnit', 'displayReadyUnitRowsDeep', 'displayReadyUnitRowsFacing', 'displayReadyUnitRowsHigh'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnit', 'displayReadyUnitRowsDeep', 'displayReadyUnitRowsFacing', 'displayReadyUnitRowsHigh'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnit', 'displayReadyUnitRowsDeep', 'displayReadyUnitRowsFacing', 'displayReadyUnitRowsHigh'])}}">
            </i>
            {{candidate.displayReadyUnit ? +candidate.displayReadyUnitRowsDeep * +candidate.displayReadyUnitRowsFacing * +candidate.displayReadyUnitRowsHigh : '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.DRU_RETAIL_UNITS) && !candidate.displayReadyUnit"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitRowsDeep', 'displayReadyUnitRowsFacing', 'displayReadyUnitRowsHigh'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitRowsDeep', 'displayReadyUnitRowsFacing', 'displayReadyUnitRowsHigh'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitRowsDeep', 'displayReadyUnitRowsFacing', 'displayReadyUnitRowsHigh'])}}">
            </i>
            {{candidate.displayReadyUnit ? candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh : '-'}}
          </td>
          <!--  Orientation (DRU)  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.DRU_ORIENTATION) && candidate.displayReadyUnit" (click)="showEditCandidateModal(index, attributeType.Orientation)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitOrientation'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitOrientation'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitOrientation'])}}">
            </i>
            {{candidate.displayReadyUnit ? candidateUtils.getDRUOrientation(candidate) : '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.DRU_ORIENTATION) && !candidate.displayReadyUnit"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitOrientation'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitOrientation'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitOrientation'])}}">
            </i>
            {{candidate.displayReadyUnit ? candidateUtils.getDRUOrientation(candidate) : '-'}}
          </td>
          <!--  DRU type  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.DRU_TYPE) && candidate.displayReadyUnit" (click)="showEditCandidateModal(index, attributeType.DisplayReadyUnitType)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitType'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitType'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitType'])}}">
            </i>
            {{candidate.displayReadyUnit ? candidateUtils.getDruType(candidate) : '-'}}
          </td>
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.DRU_TYPE) && !candidate.displayReadyUnit"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitType'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitType'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['displayReadyUnitType'])}}">
            </i>
            {{candidate.displayReadyUnit ? candidateUtils.getDruType(candidate) : '-'}}
          </td>
          <!-- Remark 1 for OMI  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.OMI_REMARK_1)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['remark1'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['remark1'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['remark1'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].remark1 | emptyToValue: '-'}}
          </td>
          <!-- Remark 2 for OMI  -->
          <td *ngIf="hasColumn(uploadCandidateCaseConstants.OMI_REMARK_2)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['remark2'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['remark2'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['remark2'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].remark2 | emptyToValue: '-'}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
