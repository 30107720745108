import { Input, Output, EventEmitter, Injectable, Directive } from '@angular/core';
import { AttributeConfig, AttributeTypeaheadConfig, AttributeDropdowndConfig } from 'pm-models';

@Directive()
@Injectable()
export class BaseAttributeDisplayComponent {

  @Input()
  public attribute?: AttributeConfig | AttributeTypeaheadConfig | AttributeDropdowndConfig;

  @Input() model?;
  @Output() modelChange?: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public attributeError?: any;

  @Input()
  public showErrorMessage = true;

  @Output()
  public change?: EventEmitter<any> = new EventEmitter();

}
