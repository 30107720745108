<div class="col col-md-body">
  <div class="row title">Enter the Product's UPC or another unique identification code.</div>
  <div class="row ui-pluinput">
    <div class="ui-inputgroup">
      <p-dropdown [options]="upcOptions" [(ngModel)]="model.candidateProductType" *ngIf="upcOptions"
                  [readonly]="true"
                  [disabled]="true" placeholder="" optionLabel="">
      </p-dropdown>
        <p-dropdown placeholder="{{pluTypePlaceholderText}}" [options]="pluTypes"
                    [(ngModel)]="+model.pluType" optionLabel="">
        </p-dropdown>
        <pm-attribute-display-typeahead class="rangeDropdown" [attribute]="pluRangeConfiguration"
                                        [(model)]="model.pluRange" (selection)="onRangeSelection($event)">
        </pm-attribute-display-typeahead>
    </div>
  </div>
  <div class="row title">Keep existing PLU?</div>
  <div class="row">
    <div *ngIf="canKeep" class="col-md-4 distribution-channel">
      <div>
        <div class="row m-0">
          <p-radioButton [id]="'keepButton'"
                         class="attribute-radio"
                         [(ngModel)]="model.keepPlu"
                         [value]="true">
          </p-radioButton>
          <div>
            <label>Keep</label>
          </div>
        </div>
        <p class="attribute-description ml-4">
          Keep PLU #{{model.upc}}
        </p>
      </div>
    </div>
    <div class="col-md-4 distribution-channel">
      <div>
        <div class="row m-0">
          <p-radioButton [id]="'removeButton'"
                         class="attribute-radio"
                         [(ngModel)]="model.keepPlu"
                         [value]="false">
          </p-radioButton>
          <div>
            <label>Remove</label>
          </div>
        </div>
        <p class="attribute-description ml-4">
          Generate a new PLU
        </p>
      </div>
    </div>
  </div>
</div>
