<pm-editor (closeEvent)="onClose()">
  <pm-header>
    <app-pia-mrt-inner-stepper *ngIf="supplierMrtService.innerCandidate"
                               [candidateErrors]="supplierMrtService.getAllPiaCandidateInnerErrorsByCandidateId()"
                               [isReplenishable]="supplierMrtService.isInnerCandidateReplenishable()" [stepIndex]="1"
                               [isSellable]="candidateUtilService.isSellable(supplierMrtService.innerCandidate)"
                               (indexClick)="onClickStepper($event)">
    </app-pia-mrt-inner-stepper>
  </pm-header>
  <pm-title>Provide supplier and buyer details.</pm-title>
  <pm-sub-title *ngIf="supplierMrtService.innerCandidate">UPC: {{supplierMrtService.getInnerCandidateProduct(0).upc}}-{{supplierMrtService.getInnerCandidateProduct(0).upcCheckDigit}}</pm-sub-title>

  <ng-container *ngIf="supplierMrtService.innerCandidate">

    <pm-mat-hierarchy-card *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION']" [permissions]="matHierarchyCardPermissions"
                           [isLoadingMatData]="isLoadingMatData"
                           [candidateProductModel]="supplierMrtService.innerCandidate.candidateProducts[0]"
                           [candidateProductErrorModel]="innerCandidateProductError"
                           (hierarchyChange)="onHierarchyChange()">
    </pm-mat-hierarchy-card>

    <pm-heb-details-card [(model)]="supplierMrtService.innerCandidate"
                         [(candidateProductModel)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                         (buyerChange)="onBuyerChange($event)"
                         [permissions]="hebDetailsCardPermissions"
                         [errorModel]="innerCandidateError"
                         [candidateProductErrorModel]="innerCandidateProductError">

    </pm-heb-details-card>
    <pm-supplier-details-card [(candidateModel)]="supplierMrtService.innerCandidate"
                              [(candidateProductModel)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                              [permissions]="supplierDetailsCardPermissions"
                              [candidateErrorModel]="innerCandidateError"
                              [candidateProductErrorModel]="innerCandidateProductError"
                              (supplierChange)="supplierChange($event)">

    </pm-supplier-details-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="isLoadingMatData"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="isLoadingMatData"></p-button>
  </pm-footer>
</pm-editor>
