<pm-editor #pmEditor (closeEvent)="onClose()">
  <pm-header>
    <app-pia-mrt-inner-stepper #stepper *ngIf="currentCandidateInner"
                               [candidateErrors]="supplierMrtService.getAllPiaCandidateInnerErrorsByCandidateId()"
                               [isReplenishable]="isReplenishable"
                               [isSellable]="candidateUtilService.isSellable(supplierMrtService.innerCandidate)"
                               [stepIndex]="3"
                               (indexClick)="onClickStepper($event)">
    </app-pia-mrt-inner-stepper>
  </pm-header>

  <div *ngIf="supplierMrtService.innerCandidate" class="col-md-12" style="margin-top: 5rem; margin-left: -18%;">
    <div class="row">
      <div class="col-md-4 text-center">
        <img class="new-mrt-items-image" src="../../../../assets/images/item-setup-case-pack.png">
      </div>
      <div class="col-md-8">
        <div class="row">
          <h3>
            Do you need to add a case?
          </h3>

        </div>
        <div class="row mb-1">
          <p class="upc-paragraph">
            UPC: {{supplierMrtService.getCandidatesCurrentCandidateProducts(supplierMrtService.innerCandidate)?.upc}}-{{supplierMrtService.getCandidatesCurrentCandidateProducts(supplierMrtService.innerCandidate)?.upcCheckDigit}}
          </p>
        </div>
        <div class="row">
          <p>
            Let us know if you’ll send this item to us in its own case or only in the MRT.
          </p>
        </div>
        <div class="row attribute-group" *ngFor="let selection of radiosChoices">
          <p-radioButton class="ui-radiobutton-large"
                         [(ngModel)]="selectedValue"
                         [value]="selection.value"
                         (onClick)="onClickSelection()">
          </p-radioButton>
          <div class="pl-2 pt-1">
            <div class="attribute-label">
              <label class="pb-1">
                {{selection.label}}
              </label>
            </div>
            <p class="attribute-description">
              {{selection.subText}}
            </p>
          </div>
        </div>

        <div *ngIf="showWarehouseSupplier()">
          <div class="channel-block">
            <pm-distribution-channel [permissions]="channelPermissions"
                                     [(model)]="supplierMrtService.innerCandidate"
                                     [errorModel]="candidateError"
                                     (modelChange)="channelChange()">
            </pm-distribution-channel>
          </div>
          <div class="attributes-stacked warehouse-typeahead-block">
            <pm-attribute-display-typeahead [attribute]="warehouseSupplierConfiguration"
                                            [(model)]="supplierMrtService.getInnerCandidate().lane"
                                            (selection)="warehouseSupplierChange($event)"
                                            [attributeError]="candidateError?.lane">
            </pm-attribute-display-typeahead>
          </div>
        </div>
        <div *ngIf="showUnitCost()" class="pl-3">
          <label class="pl-1 unit-cost-label">How much does one unit cost H-E-B?</label>
          <pm-attribute-display-text-input [attribute]="unitCostConfiguration"
                                           [(model)]="currentCandidateInner.unitCost"
                                           (modelChange)="unitCostChange($event)"
                                           [attributeError]="candidateError?.unitCost">
          </pm-attribute-display-text-input>
        </div>
        <div class="row mb-3" *ngIf="getMissingCaseError()">
          <p class="error-message">{{getMissingCaseError()}}</p>
        </div>
      </div>
    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!selectedValue"></p-button>
  </pm-footer>
</pm-editor>
