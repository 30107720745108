import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseDetailsCardComponent } from './case-details-card.component';
import { CardModule } from '../../shared/card/card.module';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [CaseDetailsCardComponent],
  exports: [CaseDetailsCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule,
    AttributeDisplayModule, RouterModule
  ]
})
export class CaseDetailsCardModule { }
