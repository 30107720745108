import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidateErrorModel} from 'pm-models';

@Component({
  selector: 'pm-error-panel',
  templateUrl: './error-panel.component.html',
  styleUrls: ['./error-panel.component.scss']
})
export class ErrorPanelComponent implements OnInit {

  public errorHeaderColumns = [
    {field: 'unitUpc', header: 'Unit UPC', sortable: false, width: '30%'},
    {field: 'fieldNames', header: 'Fields(s) with errors', sortable: false, width: '70%'}
  ];

  @Input()
  errorCandidates: CandidateErrorModel[];

  @Input() pageSize: number = 10;

  @Input() totalNumRecords: number;

  @Output() downloadClicked: EventEmitter<any> = new EventEmitter<any>();

  isDownCaret: boolean = true;

  constructor() { }

  ngOnInit() {
    if (this.errorCandidates) {
      this.totalNumRecords = this.getTotalRecordCount();
    }
  }

  onClickDownCaret() {
    this.isDownCaret = false;
  }

  onClickUpCaret() {
    this.isDownCaret = true;
  }

  isPaginatorNeeded() {
    return  this.totalNumRecords > this.pageSize;
  }

  /**
   * Returns total record count. IF there's an all candidates error record, return the length - 1, else the length.
   */
  getTotalRecordCount() {
    for (let x = 0; x < this.errorCandidates.length; x++) {
      if (this.errorCandidates[x].isAllCandidatesError) {
        return this.errorCandidates.length - 1;
      }
    }
    return this.errorCandidates.length;
  }

  onClickDownloadErrors() {
    this.downloadClicked.emit();
  }
}
