import {BaseAttribute} from './base-attribute';
import { AttributeConfig, Candidate } from 'pm-models';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayDropdownComponent} from '../../shared/attribute-display/attribute-display-dropdown/attribute-display-dropdown.component';


export class DisplayReadyUnitType implements BaseAttribute {

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: 'Display ready unit type',
      description: 'The type of display ready unit.',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      collections: [
        {label: 'Retail Ready packaging', value: 'retail_ready_packaging'},
        {label: 'Display Ready Pallets', value: 'display_ready_pallets'}
      ],
      defaultValue: 'retail_ready_packaging',
      ...overrides
    };
  }

  getComponentType(): any {
    return AttributeDisplayDropdownComponent;
  }

  setupComponent(componentInstance: AttributeDisplayDropdownComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.displayReadyUnitType;
    componentInstance.modelChange.subscribe(x => {
      candidate.displayReadyUnitType = x;
    });

    if (candidate.displayReadyUnit === false) {
      candidate.displayReadyUnitType = null;
    }
  }

  updateModel(componentInstance: AttributeDisplayDropdownComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.displayReadyUnitType) {
      componentInstance.model = candidate.displayReadyUnitType;
    }

    if (candidate.displayReadyUnit === false) {
      candidate.displayReadyUnitType = null;
    }
  }
}
