<pm-review #pmReview class="pia-invited-review" (closeEvent)="onClose()">

  <pm-title>Review store authorization requests</pm-title>
  <pm-app-reject-status-header [candidate]="invitedDistributorService?.candidate"></pm-app-reject-status-header>

  <div *ngIf="invitedDistributorService?.candidate" class="row ml-0">
    <div class="col-md-3 pl-4">
      <div  class="product-info">
        <div class="row image-row">
          <img class="product-image" src="{{invitedDistributorService.getProductImage()}}">
        </div>
        <div class="row header-row">
          UPC: {{invitedDistributorService.getUpc()}}
        </div>
        <div class="row description-row">
          {{invitedDistributorService.productData?.productDescription}}
        </div>
        <div class="row body-row">
          Brand: {{invitedDistributorService.productData?.brand?.description}}
        </div>
        <div class="row body-row">
          Size: {{invitedDistributorService.upc?.size}}
        </div>
        <div class="row body-row">
          Retail: {{invitedDistributorService.upc?.xfor}} for ${{invitedDistributorService.upc?.retailPrice}}
        </div>
        <div class="row body-row">
          Status: Activated
        </div>
      </div>
    </div>

    <div class="col-md-9" [ngClass]="invitedDistributorService.isSelectingDistributors ? 'grid-with-checkbox' : ''">
      <div *ngIf="invitedDistributorService.invitedCandidates && invitedDistributorService.invitedCandidates.length > 0" class="row pb-3">
        <div class="col col-md-11">
          <pm-attribute-grid [title]="'New requests'">

            <ng-template ngFor let-invitedCandidate [ngForOf]="invitedDistributorService.invitedCandidates" let-parentIndex="index">

              <div class="grid-divider" [ngStyle]="invitedDistributorService.isSelectingDistributors ? {'margin-left' : '25px'} : {}"></div>
              <div *ngIf="parentIndex !== 0" class="m-3"></div>
              <div *ngIf="parentIndex !== 0" class="grid-divider" [ngStyle]="invitedDistributorService.isSelectingDistributors ? {'margin-left' : '25px'} : {}"></div>
              <div class="row">
                <div class="col">
                  <pm-grid-group>
                    <pm-grid-column>
                      <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
                        <b>{{invitedCandidate.vendor?.displayName}}</b>
                        <pm-grid-cell-info>
                          Cost owner: {{invitedCandidate.costOwner?.costOwnerName}}&nbsp; |&nbsp;  Top to top: {{invitedCandidate.costOwner?.topToTopName}}
                        </pm-grid-cell-info>
                      </pm-grid-cell>
                      <pm-grid-cell [label]="''">
                        {{invitedDistributorService.getTotalStoreCountString(invitedCandidate.candidateProducts[0]?.locationGroupStores, invitedCandidate.vendor?.apNumber)}}
                      </pm-grid-cell>
                    </pm-grid-column>
                  </pm-grid-group>
                  <pm-grid-group>
                    <pm-grid-column>
                      <pm-grid-cell *ngxPermissionsOnly="['ROLE_COST_LINK']" [label]="'Cost linked'" [canEdit]="false">
                        {{invitedCandidate.costLinked | yesNo}}
                      </pm-grid-cell>
                      <pm-grid-cell *ngxPermissionsOnly="['ROLE_COST_LINK']" [label]="'Cost linked item'" [canEdit]="false">
                        {{invitedCandidate.costLink}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Master list cost'">
                        {{costService.toCurrencyForCost(invitedCandidate.masterListCost)}}
                        <pm-grid-cell-info>
                          Unit Cost: {{invitedDistributorService.getUnitCost(invitedCandidate)}} <br>
                          Penny profit: {{invitedDistributorService.getHebPennyProfit(invitedCandidate)}} <br>
                          Margin: {{invitedDistributorService.getHebMargin(invitedCandidate)}}
                        </pm-grid-cell-info>
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Deal offered'">
                        {{invitedCandidate.dealOffered | yesNo}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Total retail units'">
                        {{invitedCandidate.masterPack}}
                      </pm-grid-cell>
                    </pm-grid-column>
                    <pm-grid-column>
                      <pm-grid-cell [label]="'Case description'">
                        {{invitedCandidate.candidateProducts[0]?.caseDescription}}
                      </pm-grid-cell>
                      <pm-grid-cell *ngIf="!invitedCandidate.overrideDepartment" [label]="'Department'" [canEdit]="false">
                        {{invitedCandidate.commodity?.subDepartmentDescription}}
                      </pm-grid-cell>
                      <pm-grid-cell *ngIf="invitedCandidate.overrideDepartment" [label]="'Department'" [canEdit]="false">
                        {{invitedCandidate.overrideSubDepartment?.subDepartmentDescription}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'PSS department'" [canEdit]="false">
                        {{invitedCandidate.pssDepartment?.displayName}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'VPC'">
                        {{invitedCandidate.candidateProducts[0]?.vendorProductCode}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Country of origin'">
                        {{invitedCandidate.candidateProducts[0]?.countryOfOrigin.description}}
                      </pm-grid-cell>
                    </pm-grid-column>
                  </pm-grid-group>
                  <div class="grid-divider"></div>
                  <ng-template ngFor let-locationGroupStore [ngForOf]="invitedCandidate.candidateProducts[0].locationGroupStores" let-index="index">

                    <pm-grid-group *ngIf="invitedDistributorService.showLocationGroup(locationGroupStore)">
                      <pm-grid-column>
                        <pm-grid-cell [label]="'Authorization group'">
                          <div>{{locationGroupStore.splrLocationGroupId}}</div>
                        </pm-grid-cell>
                        <pm-grid-cell [label]="'Stores'">
                          <div class="row ml-0 mb-2">{{invitedDistributorService.getAuthGroupStoreCountLabel(locationGroupStore)}}</div>
                          <div class="m-0 row">
                            <div #storeBlockId *ngFor="let store of locationGroupStore.stores" class="ui-grid-col-2 mr-2 mb-2">
                              <div [ngClass]="{'reverted-override-store-cell' : store.overrideActivatedStore === false}" *ngIf="invitedDistributorService.showDefaultStore(invitedCandidate.vendor.apNumber, store.custLocationNumber, store.overrideActivatedStore)"
                                   class="store-block">
                                {{store.custLocationNumber}}
                                <span *ngIf="store.overrideActivatedStore || store.overrideActivatedStore === false">*</span>
                              </div>
                              <div *ngIf="!invitedDistributorService.showDefaultStore(invitedCandidate.vendor.apNumber, store.custLocationNumber, store.overrideActivatedStore)"
                                   class="store-error-block">
                                {{store.custLocationNumber}}
                              </div>
                            </div>
                          </div>

                          <div *ngIf="invitedDistributorService.hasActiveCandidateStoreOverride(invitedCandidate.candidateId, locationGroupStore.splrLocationGroupId, invitedDistributorService.invitedCandidates)" class="active-store-error">
                            <div class="row active-store-conflicts-header">
                              <div class="col-md-10">
                                <span>*</span> Authorized Supplier changed
                              </div>
                              <div *ngIf="locationGroupStore.isViewing" class="col caret-column">
                                <i class="pi pi-caret-up col-md-2" (click)="locationGroupStore.isViewing = !locationGroupStore.isViewing"></i>
                              </div>
                              <div *ngIf="!locationGroupStore.isViewing" class="col caret-column">
                                <i class="pi pi-caret-down col-md-2" (click)="locationGroupStore.isViewing = !locationGroupStore.isViewing"></i>
                              </div>
                            </div>
                            <div *ngIf="locationGroupStore.isViewing" class="active-store-conflicts-body">
                              <div class="mb-1" style="padding-left: 0.025rem;">
                                A reviewer changed the authorized Supplier for the stores below:
                              </div>
                              <div class="row ml-3 mt-1" *ngFor="let store of invitedDistributorService.getActiveCandidateStoreOverride(invitedCandidate.candidateId, locationGroupStore.splrLocationGroupId, invitedDistributorService.invitedCandidates)">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="override-col">
                                      <div class="row">
                                        <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}" class="override-bullet">
                                          &#8226;
                                        </div>
                                        <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}">
                                          #{{store.custLocationNumber}} -
                                        </div>
                                        <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}" class="overridden-vendor-col">
                                          {{store.overriddenVendorDisplayName}}
                                        </div>
                                        <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}">
                                          &nbsp;&#8594;&nbsp;
                                        </div>
                                        <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}" class="current-vendor-override-col">
                                          {{invitedCandidate.vendor.displayName}}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </pm-grid-cell>
                      </pm-grid-column>
                    </pm-grid-group>
                  </ng-template>
                </div>
              </div>
              <div *ngIf="parentIndex === invitedDistributorService.invitedCandidates.length - 1" class="grid-divider" [ngStyle]="invitedDistributorService.isSelectingDistributors ? {'margin-left' : '25px'} : {}"></div>

            </ng-template>
          </pm-attribute-grid>
        </div>
      </div>

      <div *ngIf="invitedDistributorService.showHasOtherCandidates()" class="pl-0 mt-3 mb-3 col-md-12">
        <h3 class="other-suppliers-header">Other Suppliers</h3>

        <!--   approved   -->
        <div *ngIf="invitedDistributorService.approvedCandidates.length > 0" class="other-invited-container">
          <div class="row headerRow">
            <div class="col-md-10 pl-0 invitedHeader">
              Approved<br>
              <div class="invitedHeaderSubText">
                {{invitedDistributorService.getSupplierHeaderText(invitedDistributorService.approvedCandidates)}}
              </div>
            </div>
            <i *ngIf="isViewingApprovedCandidates" class="pi pi-caret-up col-md-2 text-right" (click)="isViewingApprovedCandidates = !isViewingApprovedCandidates"></i>
            <i *ngIf="!isViewingApprovedCandidates" class="pi pi-caret-down col-md-2 text-right" (click)="isViewingApprovedCandidates = !isViewingApprovedCandidates"></i>
            <div *ngIf="isViewingApprovedCandidates" class="invitedBody">
              <ng-template ngFor let-invitedSupplier let-i="index" [ngForOf]="invitedDistributorService.approvedCandidates">
                <div class="mt-3">
                  {{invitedSupplier.vendor?.displayName}}<br>
                  <div class="ml-0 row">
                    <div class="invitedBodySubText">
                      Approved
                    </div>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>

        <div *ngIf="invitedDistributorService.activatedApNumberToStoreNumbersMap.size > 0" class="other-invited-container">
          <div class="row headerRow">
            <div class="col-md-10 pl-0 invitedHeader">
              Activated<br>
              <div class="invitedHeaderSubText">
                {{invitedDistributorService.getSupplierHeaderTextFromMap(invitedDistributorService.activatedApNumberToStoreNumbersMap)}}
              </div>
            </div>
            <i *ngIf="isViewingActivatedCandidates" class="pi pi-caret-up col-md-2 text-right" (click)="isViewingActivatedCandidates = !isViewingActivatedCandidates"></i>
            <i *ngIf="!isViewingActivatedCandidates" class="pi pi-caret-down col-md-2 text-right" (click)="isViewingActivatedCandidates = !isViewingActivatedCandidates"></i>

            <div *ngIf="isViewingActivatedCandidates" class="invitedBody">

              <ng-template ngFor let-activatedSupplierAp let-i="index" [ngForOf]="invitedDistributorService.getActivatedApNumberToStoreNumbersMapKeys()">

                <pm-attribute-grid>
                  <div class="grid-divider"></div>
                  <pm-grid-group>
                    <pm-grid-column>
                      <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
                        <b>{{invitedDistributorService.activatedApNumberToDisplayNameMap.get(activatedSupplierAp)}}</b>
                      </pm-grid-cell>
                      <pm-grid-cell [label]="''">
                        {{invitedDistributorService.getActivatedTotalCountString(invitedDistributorService.activatedApNumberToStoreNumbersMap.get(activatedSupplierAp), invitedDistributorService.vendorApToAuthGroupsMap.get(activatedSupplierAp))}}
                      </pm-grid-cell>
                    </pm-grid-column>
                  </pm-grid-group>
                  <ng-template ngFor let-locationGroupStore [ngForOf]="invitedDistributorService.vendorApToAuthGroupsMap.get(activatedSupplierAp)" let-index="index">

                    <div *ngIf="invitedDistributorService.doesActivatedGroupHaveStores(activatedSupplierAp, locationGroupStore.splrLocationGroupId)" class="grid-divider"></div>
                    <pm-grid-group *ngIf="invitedDistributorService.doesActivatedGroupHaveStores(activatedSupplierAp, locationGroupStore.splrLocationGroupId)">
                      <pm-grid-column>
                        <pm-grid-cell [label]="'Authorization group'">
                          <div>{{locationGroupStore.splrLocationGroupId}}</div>
                        </pm-grid-cell>
                        <pm-grid-cell [label]="'Stores'">
                          <div class="row ml-0 mb-2">{{invitedDistributorService.getActivatedStoreCountLabel(invitedDistributorService.activatedApNumberToGroupStoresMap.get(activatedSupplierAp).get(locationGroupStore.splrLocationGroupId))}}</div>
                          <div class="m-0 row">
                            <div *ngFor="let store of invitedDistributorService.activatedApNumberToGroupStoresMap.get(activatedSupplierAp).get(locationGroupStore.splrLocationGroupId)" class="ui-grid-col-2 mr-2 mb-2">
                              <div class="store-block">
                                {{store}}
                              </div>
                            </div>
                          </div>
                        </pm-grid-cell>
                      </pm-grid-column>
                    </pm-grid-group>
                  </ng-template>
                  <div class="grid-divider"></div>
                </pm-attribute-grid>
              </ng-template>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <pm-footer>
    <div>
      <div>
        <p-button class="ghost m-2" [ngStyle]="{'float': 'right'}" label="Back to home" (onClick)="onClose()"></p-button>
        <p-button class=" m-2" [ngStyle]="{'float': 'right'}" label="Print" (onClick)="onClickPrint()"></p-button>
      </div>
    </div>
  </pm-footer>
</pm-review>


