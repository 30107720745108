import { Component } from '@angular/core';

@Component({
  selector: 'pm-title',
  template: '<ng-content></ng-content>'
})
export class TitleComponent { }

@Component({
  selector: 'pm-sub-title',
  template: '<ng-content></ng-content>'
})
export class SubTitleComponent { }


@Component({
  selector: 'pm-footer',
  template: '<ng-content></ng-content>'
})
export class FooterComponent { }

@Component({
  selector: 'pm-sidebar',
  template: '<ng-content></ng-content>'
})
export class SidebarComponent { }

@Component({
  selector: 'pm-header',
  template: '<ng-content></ng-content>'
})
export class HeaderComponent { }



// tslint:disable: max-line-length
@Component({
  selector: 'pm-close',
  host: { class: 'pm-close' },
  template: `
  <svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
    xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1 0 0 1 -16 -24 )">
      <path
         d="M 14.4171428571429 12  L 24 2.41714285714286  L 21.5828571428571 0  L 12 9.58285714285714  L 2.41714285714286 0  L 0 2.41714285714286  L 9.58285714285714 12  L 0 21.5828571428571  L 2.41714285714286 24  L 12 14.4171428571429  L 21.5828571428571 24  L 24 21.5828571428571  L 14.4171428571429 12  Z "
       fill-rule="nonzero" fill="#b8b8b8" stroke="none" transform="matrix(1 0 0 1 16 24 )" />
  </g>
</svg>`
})
export class CloseComponent { }
// tslint:enable: max-line-length
