import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pm-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit {
  // Width of the circle stroke
  @Input() strokeWidth: string;
  // Any styles the component should have
  @Input() style: any;
  // Color for the background of the circle
  @Input() fill: string;
  // Show/hide component
  @Input() showSpinner: boolean;

  private static DEFAULT_STROKE_WIDTH = '8';
  private static DEFAULT_STYLE = { width: '10px', height: '10px' };
  private static DEFAULT_FILL = 'none';
  private static DEFAULT_SHOW_SPINNER = null;

  constructor() {}

  /**
   * A callback method that is invoked immediately after the
   * default change detector has checked the directive's
   * data-bound properties for the first time,
   * and before any of the view or content children have been checked.
   * It is invoked only once when the directive is instantiated.
   */
  ngOnInit() {
    this.setDefaultsIfNotProvided();
  }

  /**
   * Sets variables to defaults if not provided.
   */
  setDefaultsIfNotProvided() {
    this.strokeWidth = this.strokeWidth ? this.strokeWidth : ProgressSpinnerComponent.DEFAULT_STROKE_WIDTH;
    this.style = this.style ? this.style : ProgressSpinnerComponent.DEFAULT_STYLE;
    this.fill = this.fill ? this.fill : ProgressSpinnerComponent.DEFAULT_FILL;
    this.showSpinner = this.showSpinner ? this.showSpinner : ProgressSpinnerComponent.DEFAULT_SHOW_SPINNER;
  }
}
