import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeGridComponent } from './attribute-grid.component';
import { GridCellComponent, GridCellInfoComponent } from './grid-cell/grid-cell.component';
import { AttributeGridColumnComponent } from './attribute-grid.column.component';
import { AttributeGridGroupComponent } from './attribute-grid.group.component';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from '../shared/shared.module';
import {CommentGridCellComponent, CommentGridCellInfoComponent} from './comment-grid-cell/comment-grid-cell.component';
import {TooltipModule} from 'primeng/tooltip';
import {AttributeDisplayModule} from '../shared/attribute-display/attribute-display.module';

const COMPONENTS = [
  AttributeGridComponent,
  GridCellComponent,
  GridCellInfoComponent,
  AttributeGridColumnComponent,
  AttributeGridGroupComponent,
  CommentGridCellComponent,
  CommentGridCellInfoComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, AttributeDisplayModule],
  imports: [CommonModule, ButtonModule, SharedModule, TooltipModule, AttributeDisplayModule]
})
export class AttributeGridModule {}
