<pm-review *ngIf="isViewingPage" (closeEvent)="onClose()">
  <h2>Review {{getToReviewSize()}} Candidates</h2>
  <h6>Review these candidates’ data and fill out the form. The data you enter will apply to each candidate below.</h6>
  <pm-upload-candidate-table *ngIf="toReview" [candidates]="toReview" [isDsd]="isDsd" [isWarehouse]="isWarehouse"
                             [workflowState]="workflowState" [candidateProductIndex]="candidateProductIndex"
                             [candidateIdToCandidateErrorMap]="candidateErrors">
  </pm-upload-candidate-table>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClose()"></p-button>
    <p-button class="ghost m-2" label="Reject" (onClick)="showPanel($event, rejectOverlay, rejectCandidateDiv)" [disabled]="isRejectDisabled"></p-button>
    <p-button class="m-2" label="Activate" (onClick)="onClickActivate()" [disabled]="!toReview || toReview.length === 0 || isActivateButtonDisabled"></p-button>
  </pm-footer>
</pm-review>

<div>
  <div #rejectCandidateDiv></div>
  <pm-static-overlay-panel #rejectOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
    <pm-reject-candidate-overlay (cancelClicked)="rejectOverlay.hide()" (rejectButtonClicked)="onBulkRejectClicked($event, rejectOverlay)"></pm-reject-candidate-overlay>
  </pm-static-overlay-panel>
</div>
