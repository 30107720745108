import { BaseAttribute } from './base-attribute';
import { AttributeTypeaheadConfig, CandidateProduct, Candidate } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTypeaheadComponent } from '../../shared/attribute-display/attribute-display-typeahead/attribute-display-typeahead.component';

export class SubBrand implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTypeaheadComponent;
  }

  getAttributeConfig(overrides?): AttributeTypeaheadConfig {
    return {
      label: 'Sub-brand',
      description: '',
      isRequired: false,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: '',
      idRef: 'subBrandId',
      displayRef: 'displayName',
      placeholderText: 'Select a sub-brand...',
      searchUrl: '/lookup/subBrand',
      ...overrides
    };
  }

  setupComponent(
    componentInstance: AttributeDisplayTypeaheadComponent,
    candidateProduct: CandidateProduct,
    configOverrides: any = {}
  ) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidateProduct.subBrand;
    componentInstance.selection.subscribe(x => {
      candidateProduct.subBrand = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayTypeaheadComponent, candidateProduct: CandidateProduct) {
    if (componentInstance.model !== candidateProduct.subBrand) {
      componentInstance.model = candidateProduct.subBrand;
    }
  }
}
