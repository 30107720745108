import {Component, Input, OnInit} from '@angular/core';
import {Candidate} from 'pm-models';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';

@Component({
  selector: 'pm-app-reject-status-header',
  templateUrl: './reject-status-header.component.html',
  styleUrls: ['./reject-status-header.component.scss']
})
export class RejectStatusHeaderComponent implements OnInit {

  constructor() { }

  @Input() candidate;

  ngOnInit() {
  }

  getUIStatusFromCandidateStatus(status: String) {
    return CandidateUtilService.getUIStatusFromCandidateStatus(status);
  }

  isRejected() {
    return (this.getUIStatusFromCandidateStatus(this.candidate.status) === 'Rejected');
  }

  getRejectReason() {
    if (!this.candidate) {
      return '';
    } else if (!(this.candidate.vendorComment === Candidate.CORRECTIONS_NEEDED || this.candidate.vendorComment === Candidate.NOT_WANTED)) {
      const other = 'Other-';
      return other.concat(this.candidate.vendorComment);
    } else {
      return this.candidate.vendorComment;
    }
  }
}
