<pm-review #pmReview class="buyer-invited-review" (closeEvent)="onClose()">

  <pm-title>Review store authorization requests</pm-title>
  <pm-app-reject-status-header [candidate]="candidate"></pm-app-reject-status-header>

  <div *ngIf="candidate" class="row ml-0">
    <div class="col-md-3 pl-4">
      <div  class="product-info">
        <div class="row image-row">
          <img class="product-image" src="{{productImageUrl ? productImageUrl : DEFAULT_NO_PRODUCT_IMAGE}}">
        </div>
        <div class="row header-row">
          UPC: {{candidate.candidateProducts[0].upc}}
        </div>
        <div class="row description-row">
          {{productData?.productDescription}}
        </div>
        <div class="row body-row">
          Brand: {{productData?.brand.description}}
        </div>
        <div class="row body-row">
          Size: {{upc?.size}}
        </div>
        <div class="row body-row">
          Retail: {{upc?.xfor}} for ${{upc?.retailPrice}}
        </div>
        <div class="row body-row">
          Status: Activated
        </div>
      </div>
    </div>

    <div class="col-md-9" [ngClass]="isSelectingDistributors ? 'grid-with-checkbox' : ''">
      <div *ngIf="invitedCandidates && invitedCandidates.length > 0" class="row pb-3">
        <div class="col col-md-11">
          <pm-attribute-grid [title]="'New requests'">

            <ng-template ngFor let-invitedCandidate [ngForOf]="invitedCandidates" let-parentIndex="index">

              <div class="grid-divider" [ngStyle]="isSelectingDistributors ? {'margin-left' : '25px'} : {}"></div>
              <div *ngIf="parentIndex !== 0" class="m-3"></div>
              <div *ngIf="parentIndex !== 0" class="grid-divider" [ngStyle]="isSelectingDistributors ? {'margin-left' : '25px'} : {}"></div>
              <div class="row">
                <div class="col">
                  <pm-grid-group>
                    <pm-grid-column>
                      <pm-grid-cell [label]="'Supplier'" [canEdit]="false" >
                        <b>{{invitedCandidate?.vendor?.displayName}}</b>
                        <pm-grid-cell-info>
                          Cost owner: {{invitedCandidate?.costOwner?.costOwnerName}}&nbsp; |&nbsp;  Top to top: {{invitedCandidate?.costOwner?.topToTopName}}
                        </pm-grid-cell-info>
                      </pm-grid-cell>
                      <pm-grid-cell [label]="''">
                        {{getTotalStoresString(invitedCandidate?.candidateProducts[0].locationGroupStores, invitedCandidate?.vendor?.apNumber)}}
                      </pm-grid-cell>
                    </pm-grid-column>
                  </pm-grid-group>
                  <pm-grid-group>
                    <pm-grid-column>
                      <pm-grid-cell *ngxPermissionsOnly="['ROLE_COST_LINK']" [label]="'Cost linked'" [canEdit]="false">
                        {{invitedCandidate?.costLinked | yesNo}}
                      </pm-grid-cell>
                      <pm-grid-cell *ngxPermissionsOnly="['ROLE_COST_LINK']" [label]="'Cost linked item'" [canEdit]="false">
                        {{invitedCandidate?.costLink}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Master list cost'">
                        {{costService.toCurrencyForCost(invitedCandidate.masterListCost)}}
                        <pm-grid-cell-info>
                          Penny profit: {{getHebPennyProfit(invitedCandidate)}} <br>
                          Margin: {{getHebMargin(invitedCandidate)}}
                        </pm-grid-cell-info>
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Deal offered'">
                        {{invitedCandidate.dealOffered | yesNo}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Total retail units'">
                        {{invitedCandidate.masterPack}}
                      </pm-grid-cell>
                    </pm-grid-column>
                    <pm-grid-column>
                      <pm-grid-cell [label]="'Case description'">
                        {{invitedCandidate.candidateProducts[0].caseDescription}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'VPC'">
                        {{invitedCandidate.candidateProducts[0].vendorProductCode}}
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Country of origin'">
                        {{invitedCandidate.candidateProducts[0].countryOfOrigin.description}}
                      </pm-grid-cell>
                    </pm-grid-column>
                  </pm-grid-group>
                  <div class="grid-divider"></div>
                  <ng-template ngFor let-locationGroupStore [ngForOf]="invitedCandidate.candidateProducts[0].locationGroupStores" let-index="index">

                    <pm-grid-group *ngIf="invitedDistributorService.showLocationGroup(locationGroupStore)">
                      <pm-grid-column>
                        <pm-grid-cell [label]="'Authorization group'">
                          <div>{{locationGroupStore.splrLocationGroupId}}</div>
                        </pm-grid-cell>
                        <pm-grid-cell [label]="'Stores'">
                          <div class="row ml-0 mb-2">{{getAuthGroupLabel(locationGroupStore)}}</div>
                          <div class="m-0 row">
                            <div #storeBlockId *ngFor="let store of locationGroupStore.stores" class="ui-grid-col-2 mr-2 mb-2">
                              <div class="store-block">
                                {{store.custLocationNumber}}
                              </div>
                            </div>
                          </div>

                        </pm-grid-cell>
                      </pm-grid-column>
                    </pm-grid-group>
                  </ng-template>

                </div>
              </div>
              <div *ngIf="parentIndex === invitedCandidates.length - 1" class="grid-divider" [ngStyle]="isSelectingDistributors ? {'margin-left' : '25px'} : {}"></div>

            </ng-template>
          </pm-attribute-grid>
        </div>
      </div>

    </div>
  </div>

  <pm-footer>
    <div>
      <div>
        <p-button class="ghost m-2" label="Back to home" (onClick)="onClose()" [disabled]="!canEditAndResubmit"></p-button>
        <p-button class="ghost m-2" label="Print" (onClick)="onClickPrint()"></p-button>
        <p-button class="ghost m-2" label="Edit & Resubmit" *ngIf="isRejected()" (onClick)="resubmitCandidate()" [disabled]="!canEditAndResubmit"></p-button>
      </div>
    </div>
  </pm-footer>
</pm-review>

