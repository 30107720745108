import {Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'pm-attribute-grid',
  templateUrl: './attribute-grid.component.html',
  styleUrls: ['./attribute-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeGridComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  canEdit: boolean = false;

  @Output() editClicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onEditClicked($event) {
    this.editClicked.emit($event);
  }
}
