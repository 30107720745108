import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import {EMPTY, Observable, throwError} from 'rxjs';
import { Router } from '@angular/router';
import {catchError, delay} from 'rxjs/internal/operators';
import {GrowlService} from '../growl/growl.service';

/**
 * Injector that will add the OAUTH token to HTTP requests if one is present.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService, public router: Router, private growlService: GrowlService) {
    // Intentionally empty.
  }

  static addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Called when HTTP requests are made. It will add the OAUTH token to the request if the user is authenticated.
   *
   * @param {<any>} request The HTTP request to add the token to.
   * @param {} next The next handler in the chain.
   * @returns {<<any>>} The result of going through the handler chain.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // If the access token expired then request a new one
    if (this.auth.isAccessTokenExpired() && !this.auth.isRefreshingToken) {
      this.auth.isRefreshingToken = true;
      this.auth.refreshToken().subscribe(token => {
        this.auth.setAuthToken(token);
        this.auth.setUser(JSON.stringify(token.display_name));
        this.auth.setUserEmail(JSON.stringify(token.user_email));
        this.auth.setExpireTokenTime(token);
        this.auth.isRefreshingToken = false;
      });
    }

    // If the session expired then force a login
    if (this.auth.isSessionExpired()) {
      this.auth.localLogout();
      this.router.navigateByUrl('/login');
    }

    // every time something happens extend the session timeout
    this.auth.updateActionTime();

    if (this.auth.isRefreshingToken) {
      // delay to make sure we get new token first
      delay(500);
    }

    // If the user is authenticated, add the OAUTH token to the HTTP request.
    // Make sure we don't do this for the refresh token HTTP request
    if (this.auth.isAuthenticated() && !request.url.includes('token')) {
      request = TokenInterceptor.addToken(request, this.auth.currentToken);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const data = {
          exception: error && (error.error.message || error.error.errorMessage) ? (error.error.message || error.error.errorMessage) : '',
          status: error.status
        };
        if (data.status === 404) {
          this.router.navigateByUrl('/tasks').then(() => {
            this.growlService.addError(data.exception);
          });
          return EMPTY;
        } else  if (error.status === 401 || error.status === 403) {
          this.auth.localLogout();
          this.router.navigate(['/login']).then(() => {
            this.growlService.addError('Invalid credentials. Please login.');
          });
        } else {
            return throwError(error);
        }
      }));
  }
}
