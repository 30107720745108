// tslint:disable-next-line:max-line-length
import {AttributeDisplayTaxCategoryComponent} from '../../shared/attribute-display/attribute-display-tax-category/attribute-display-tax-category.component';
import { BaseAttribute } from './base-attribute';
import { Candidate, AttributeConfig } from 'pm-models';

export class VertexTaxCategory implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTaxCategoryComponent;
  }

  getAttributeConfig(overrides?): AttributeConfig {
    return {
      label: '',
      description: '',
      isRequired: false,
      isDisabled: () => false,
      isReadOnly: () => false,
      defaultValue: false,
      name: 'taxCategoryList',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTaxCategoryComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.vertexTaxCategoryModel  ;
    componentInstance.change.subscribe(x => {
      candidate.vertexTaxCategoryModel = x;
    });
  }
}
