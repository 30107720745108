<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4" >
    <!-- -->
  </div>
</div>
<ng-template #card>
  <pm-card title="Case Details"
           subtitle="Provide the basic details about this new case.">
    <ng-container *ngIf="candidateModel && candidateModel">

      <pm-attribute-display-text-input [attribute]="totalRetailUnitsConfiguration"
                                       [(model)]="candidateModel.masterPack"
                                       [attributeError]="candidateErrorModel?.masterPack"
                                       (modelChange)="totalRetailUnitsChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-typeahead [attribute]="buyerConfiguration" [attributeError]="candidateErrorModel?.buyer"
                                      [(model)]="candidateModel.buyer" (selection)="onBuyerChange($event)" (initCallback)="buyerInitCallback($event)">
      </pm-attribute-display-typeahead>
      <pm-attribute-display-radio [attribute]="channelConfiguration"
                                  [model]="channelModel"
                                  (change)="channelChange($event)">
      </pm-attribute-display-radio>

      <pm-attribute-display-upc [attribute]="caseUPCConfiguration" [(model)]="candidateProductModel.caseUpc"
                                [attributeError]="candidateProductErrorModel?.caseUpc"
                                [(checkDigitModel)]="candidateProductModel.caseUpcCheckDigit"
                                (modelChange)="validateUpcEvent($event)"
                                (checkDigitModelChange)="validateUpcEvent($event)"
                                [state]="upcState">
      </pm-attribute-display-upc>

      <pm-attribute-display-text-input [attribute]="caseDescriptionConfiguration"
                                       [(model)]="candidateProductModel.caseDescription"
                                       [attributeError]="candidateProductErrorModel?.caseDescription"
                                       (change)="descriptionChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="vendorProductCodeConfiguration" [(model)]="candidateProductModel.vendorProductCode"
                                       [attributeError]="candidateProductErrorModel?.vendorProductCode" (change)="vendorProductCodeChange($event)">
      </pm-attribute-display-text-input>

      <pm-attribute-display-typeahead [attribute]="countryOfOriginConfiguration" [(model)]="candidateProductModel.countryOfOrigin"
                                      [attributeError]="candidateProductErrorModel?.countryOfOrigin" (selection)="countryOfOriginChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-radio [attribute]="weightConfiguration"
                                  [(model)]="candidateModel.itemWeightType"
                                  [attributeError]="candidateErrorModel?.itemWeightType"
                                  (change)="weightChange($event)">
      </pm-attribute-display-radio>

      <pm-attribute-display-toggle
        [attribute]="codeDateConfiguration"
        [(model)]="candidateModel.codeDate"
        (change)="codeDateChange($event)">
      </pm-attribute-display-toggle>

      <pm-attribute-display-text-input [attribute]="maxShelfLifeConfiguration"
                                       [attributeError]="candidateErrorModel?.maxShelfLife"
                                       [(model)]="candidateModel.maxShelfLife">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="inboundSpecConfiguration"
                                       [attributeError]="candidateErrorModel?.inboundSpecDays"
                                       [(model)]="candidateModel.inboundSpecDays">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="reactionDaysConfiguration"
                                       [attributeError]="candidateErrorModel?.warehouseReactionDays"
                                       [(model)]="candidateModel.warehouseReactionDays">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="guaranteeToStoreDaysConfiguration"
                                       [attributeError]="candidateErrorModel?.guaranteeToStoreDays"
                                       [(model)]="candidateModel.guaranteeToStoreDays">
      </pm-attribute-display-text-input>

      <pm-attribute-display-checkbox [attribute]="dealOfferedConfiguration" [(model)]="candidateModel.dealOffered"
                                     (change)="dealOfferedChange($event)">
      </pm-attribute-display-checkbox>

      <pm-attribute-display-typeahead *ngIf="candidateModel.overrideDepartment && canEditDepartment" [attribute]="departmentConfiguration"
                                      [attributeError]="candidateErrorModel?.overrideSubDepartment"
                                      [(model)]="candidateModel.overrideSubDepartment"
                                      (selection)="onSubCommodityDepartmentChange($event)">
      </pm-attribute-display-typeahead>

      <pm-attribute-display-text-input
        [attribute]="defaultDepartmentConfiguration"
        *ngIf="!candidateModel.overrideDepartment && canEditDepartment"
        [model]="getDepartment()">
      </pm-attribute-display-text-input>

      <div class="row pb-4">
        <a *ngIf="canEditDepartment" [routerLink]="" queryParamsHandling="preserve" class="override-button" (click)="toggleDepartmentOverride()">
          {{candidateModel.overrideDepartment ? 'Reset to Default' : 'Override'}}
        </a>
      </div>

      <div class="row pb-4">
        <div *ngIf="candidateModel.overrideDepartment && canEditDepartment" class="override-warning">
          <div class="borderLeft col-md-8 justify-content-md-left warning-box">
            <div class="row ml-2">
              <img src="../../../assets/images/info_24px.svg">
              <div class="col ml-2">Changing the department may remove existing store authorization requests.</div>
            </div>
          </div>
        </div>
      </div>

      <pm-attribute-display-typeahead *ngIf="canEditPssDepartment" [attribute]="pssDepartmentConfiguration" [(model)]="candidateModel.pssDepartment"
                                      [attributeError]="candidateErrorModel?.pssDepartment"
                                      (selection)="pssDepartmentChange($event)">
      </pm-attribute-display-typeahead>

    </ng-container>

  </pm-card>
</ng-template>
