<ng-container>
  <div class="row align-items-center reject-candidate-overlay-wrapper">
    <div class="col-md-4 reject-candidate-overlay">
      <div class="header-row">
        <h2>Reject candidate?</h2>
        <p>{{rejectMessage}}</p>
      </div>
      <div class="attributes-stacked">
        <pm-attribute-display-radio [attribute]="reasonConfiguration"
                                    [(model)]="reasonType"
                                    (change)="reasonTypeChange($event)">
        </pm-attribute-display-radio>
        <div *ngIf="reasonType === OTHER" class="other-reason-row">
          <input id="otherReasonMessage" pInputText
                 placeholder="Provide a custom reason..."
                 type="text"
                 accept-charset="UTF-8"
                 [(ngModel)]="otherReasonMessage"/>
        </div>
      </div>
      <div class="row button-row">
        <button pButton class="cancel mr-3" label="Cancel" (click)="cancel()"></button>
        <button pButton class="ui-button-danger" label="Reject" (click)="rejectCandidate()" [disabled]="isRejectDisabled()"></button>
      </div>
    </div>
  </div>
</ng-container>
