import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import { Component, OnInit } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { File, FileHandle } from 'pm-models';

import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';

@Component({
  selector: 'pm-attachment-upload',
  templateUrl: './attachment-upload.component.html',
  styleUrls: ['./attachment-upload.component.scss']
})
export class AttachmentUploadComponent implements OnInit {
  @Input() attachments: File[];
  // @Input() tabStartIndex: number;

  @Output() updateCallback: EventEmitter<any> = new EventEmitter<any>();

  uploadingFiles: File[] = [];
  failedUploads: string[] = [];
  isInProgress: boolean;
  fileErrorMap: Map<string, string[]> = new Map<string, string[]>();
  error: boolean;
  errorMessage: string;

  //  @ViewChild('fileUpload', null) fileUpload: FileUpload;
  //  @ViewChild('downloadFileLink', null) private downloadFileLink: ElementRef;
  constructor(private candidateService: CandidateService, private growlService: GrowlService) {}

  ngOnInit() {}

  /**
   * Notify parent of a change to the data.
   */
  updateParentAndSave() {
    this.updateCallback.emit(this.attachments);
    this.disableAndResetDisplay();
  }

  /**
   * Disables and resets all the values for this display.
   */
  disableAndResetDisplay() {
    this.uploadingFiles = [];
    this.isInProgress = false;
    this.fileErrorMap.clear();
  }

  onFilesAdded(files) {
    const filesToSend = [];
    const filesToDiscard = [];
    this.clearOldErrors();
    for (const file of files) {
      if (this.checkForErrors({ file: file })) {
        filesToDiscard.push(file);
        this.error = true;
      } else {
        filesToSend.push({ file: file });
      }
    }
    if (filesToSend.length >= 1) {
      this.uploadAttachments(filesToSend);
    }
  }

  filesDropped(files: FileHandle[]): void {
    const filesToSend = [];
    const filesToDiscard = [];
    this.clearOldErrors();
    for (const file of files) {
      if (this.checkForErrors(file)) {
        filesToDiscard.push(file);
        this.error = true;
      } else {
        filesToSend.push(file);
      }
    }
    if (filesToSend.length >= 1) {
      this.uploadAttachments(filesToSend);
    }
  }

  uploadAttachments(files) {
    this.clearError();
    this.isInProgress = true;
    const fileReader$: Observable<File>[] = [];
    console.log(files);
    for (const file of files) {
      this.getDataFromFile(fileReader$, file.file);
    }
    let failedUpload;
    forkJoin(fileReader$).subscribe(attachments => {
      const candCall$: Observable<String>[] = [];
      for (const attachment of attachments) {
        failedUpload = attachment.name;
        candCall$.push(this.candidateService.uploadFile(attachment, attachment.type, true));
      }
      forkJoin(candCall$).subscribe(
        uuids => {
          uuids.forEach(uuid => {
            if (this.attachments === null || this.attachments === undefined) {
              this.attachments = [];
            }
            let x;
            for (x = 0; x < this.uploadingFiles.length; x++) {
              if (this.uploadingFiles[x].uuid === uuid) {
                failedUpload = this.uploadingFiles[x].name;
                this.attachments.push(this.uploadingFiles[x]);
                break;
              }
            }
          });
          this.updateParentAndSave();
        },
        () => {
          this.isInProgress = false;
          this.fileErrorMap.get('serverError').push(failedUpload);
          this.error = true;
          this.growlService.clearMessages();
        },
        () => {
          this.isInProgress = false;
        }
      );
    });
  }

  clearOldErrors() {
    this.growlService.clearMessages(); // clear out any growl message(s)
    this.error = false;
    this.fileErrorMap.clear();
    this.fileErrorMap.set('fileSizeError', []);
    this.fileErrorMap.set('serverError', []);
  }

  private checkForErrors(file) {
    this.clearOldErrors();
    let hasError = false;
    if (file.file.size > 8388608) {
      this.fileErrorMap.get('fileSizeError').push(file.file.name);
      hasError = true;
    }
    return hasError;
  }
  // filesDropped(files: FileHandle[]): void {
  //   const filesToSend = [];
  //   const filesToDiscard = [];
  //   this.clearOldErrors();
  //   for (const file of files) {
  //     if (this.checkForErrors(file, filesToSend.length)) {
  //       filesToDiscard.push(file);
  //       this.error = true;
  //     } else {
  //       filesToSend.push(file);
  //     }
  //   }
  //   if (filesToSend.length >= 1) {
  //     this.uploadImage(filesToSend);
  //   }
  // }

  private getDataFromFile(fileReader$: Observable<File>[], file) {
    const fileReader = new FileReader();
    const fileReaderObserver$ = new Observable<File>(o => {
      fileReader.onloadend = e => {
        const attachment = new File();
        attachment.data = file;
        attachment.uuid = UUID.UUID();
        attachment.name = file.name;
        attachment.type = file.type;
        attachment.size = file.size;
        this.uploadingFiles.push(attachment);
        o.next(attachment);
        o.complete();
      };
    });
    fileReader$.push(fileReaderObserver$);
    fileReader.readAsDataURL(file);
  }

  removeFile(file) {
    const index = this.attachments.indexOf(file);
    if (index !== -1) {
      this.attachments.splice(index, 1);
    }
    this.updateParentAndSave();
  }

  downloadFile(file) {
    this.candidateService.retrieveAttachment(file.uuid, true).subscribe(arrayData => {
      const blob = new Blob([arrayData], { type: file.type });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = file.name;
      anchor.href = url;
      anchor.click();
    });
  }

  fileErrors() {
    let errorsToReturn = '';

    if (this.fileErrorMap.get('fileSizeError').length === 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
            this.fileErrorMap
              .get('fileSizeError')
              .toString()
              .bold() +
            ' because it is too big. Files must be smaller than 8 megabytes.'
        )
        .concat('</br>');
    } else if (this.fileErrorMap.get('fileSizeError').length > 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
            this.fileErrorMap
              .get('fileSizeError')
              .toString()
              .bold() +
            ' because they are too large. Files must be smaller than 8 megabytes.'
        )
        .concat('</br>');
    }

    if (this.fileErrorMap.get('serverError').length === 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
            this.fileErrorMap.get('serverError').toString() +
            ' because of an internal server error. Please try again.'
        )
        .concat('</br>');
    } else if (this.fileErrorMap.get('serverError').length > 1) {
      errorsToReturn = errorsToReturn
        .concat('<br>')
        .concat(
          'We couldn’t upload ' +
            this.fileErrorMap
              .get('serverError')
              .toString()
              .bold() +
            ' because of an internal server error. Please try again.'
        )
        .concat('</br>');
    }

    return errorsToReturn;
  }

  clearError() {
    this.errorMessage = '';
    this.error = false;
  }
}
