<div class="object-manager-body">
  <div class="row justify-content-center">
    <div class="col-md-11">

      <h2 class="manager-sub-title">The task list below displays recently submitted candidates. These items require your attention:</h2>

      <div class="row" *ngIf="bulkUpdate && allTasksCheckbox">
        <div class="col-md-1">
          <p-button (onClick)="onClickBulkReview()"
                    [disabled]="hasBulkActionConflict"
                    class="m-2" label="Review">
          </p-button>
        </div>
        <div class="format-bulk-action-text">
          <img src="/assets/images/lightning-bolt-2color.svg">
        </div>
        <div class="col-md-9 format-bulk-action-text"><span [innerHTML]="getBulkReviewErrorText()"></span></div>
      </div>

      <p-table class="task-viewer-table" selectionMode="single" [sortField]="'candidateId'" [sortOrder]="-1"
               [rowHover]="true"
               [columns]="taskHeaderColumns"
               [value]="tasks"
               [paginator]="showPagination()"
               [rows]="pageSize"
               [totalRecords]="totalNumRecords"
               [lazy]="lazyLoadOn"
               (onLazyLoad)="loadCandidatesLazy($event)"
               *ngIf="isEditDisabled">

        <ng-template pTemplate="header" let-taskHeaderColumns>
          <tr>
            <th *ngIf="bulkUpdate" style="width: 3%;">
              <p-checkbox [(ngModel)]="allTasksCheckbox"
                          (onChange)="selectedAllChange()"
                          [checkboxIcon]="getCheckAllIcon()"
                          [ngStyle]="{'padding-left': '2px'}"
                          binary="true">
              </p-checkbox>
            </th>

            <th *ngIf="rushFlag" style="width: 3%;"></th>

            <th *ngFor="let col of taskHeaderColumns" [pSortableColumn]="col.field"
                [pSortableColumnDisabled]="!col.sortable" [ngStyle]="{'width' : col.width}">
              {{col.header}}
              <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-task let-index="rowIndex">
          <tr style="height: 16px !important;" [pSelectableRow]="task">
            <td *ngIf="bulkUpdate">
              <p-checkbox [(ngModel)]="task.checked"
                          (onChange)="onCheckTask(index, $event)"
                          [disabled]="hasBulkActionConflict && !task.checked"
                          binary="true">
              </p-checkbox>
            </td>

            <!--  Rush Flag (Candidate priority)  -->
            <td *ngIf="rushFlag" (click)="onClickRow(task)">
              <a pTooltip="Rush review requested.">
                <img *ngIf="getRushFlag(task?.rushFlag)"
                     src="/assets/images/rush-icon.svg" alt="blank">
              </a>
            </td>

            <!--  ID (Candidate id)  -->
            <td (click)="onClickRow(task)">{{task?.candidateId}}</td>

            <!--  UPC  -->
            <td (click)="onClickRow(task)">{{task?.upc}}</td>

            <!--   Description  -->
            <td (click)="onClickRow(task)">{{getDescription(task)}}</td>

            <!--   Task   -->
            <td (click)="onClickRow(task)">{{task?.displayName}}</td>

            <!--   Buyer [BDM]   -->
            <td (click)="onClickRow(task)">{{task?.buyerDisplayName}}</td>

            <!--   Supplier [AP]   -->
            <td (click)="onClickRow(task)">{{(task?.childCandidates && task?.childCandidates.length) ? "Multiple" : task?.supplierDisplayName}}</td>

            <!--   Updated By   -->
            <td (click)="onClickRow(task)">{{(task?.childCandidates && task?.childCandidates.length) ? "Multiple" : ((task?.lastUpdatedBy.length > 10) ? (task?.lastUpdatedBy | slice:0:10) : (task?.lastUpdatedBy))}}
              <i *ngIf="(!task?.childCandidates || !task?.childCandidates.length) && task?.lastUpdatedBy.length > 10" tooltipPosition="top"
                 tooltipStyleClass="imageUpload-tooltip" pTooltip="{{task?.lastUpdatedBy}}">...</i>
            </td>

            <!--   Last Updated   -->
            <td (click)="onClickRow(task)">
              <ng-container *ngIf="task?.childCandidates && task?.childCandidates.length">Multiple</ng-container>
              <ng-container *ngIf="!(task?.childCandidates && task?.childCandidates.length)">{{task?.lastUpdatedTime | dateStandard : {showTime: true} }}</ng-container>
            </td>

            <!--   Date Landed   -->
            <td (click)="onClickRow(task)" *ngIf="isPia">{{task?.createTime | dateStandard : {showTime: true} }}</td>

            <!--   Optional Trashcan   -->
            <td>
              <pm-delete-button *ngIf="task?.canDelete" (deleteButtonClicked)="onClickDelete(task?.candidateId)"></pm-delete-button>
            </td>

          </tr>

        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="columns.length">
              No records found
            </td>
          </tr>
        </ng-template>

      </p-table>
    </div>
  </div>
</div>
