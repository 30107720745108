import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicComponent } from './basic.component';

import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [BasicComponent],
  imports: [SharedModule, CommonModule],
  exports: [BasicComponent, SharedModule]
})
export class BasicModule {}
