import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierProductService} from '../../service/supplier-product.service';
import {GrowlService} from '../../growl/growl.service';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {MatUtilService} from '../../service/mat-util.service';
import {CandidateService} from '../../service/candidate.service';
import {Candidate, CandidateError, CandidateProduct, CandidateValidatorType} from 'pm-models';
import {AssociateUpcService} from '../../service/associate-upc.service';
import {finalize, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {LookupService} from '../../service/lookup.service';
import {AssociateUpcStepperComponent} from '../../shared/components/associate-upc-stepper/associate-upc-stepper.component';

@Component({
  selector: 'app-associate-extended-attributes',
  templateUrl: './associate-extended-attributes.component.html',
  styleUrls: ['./associate-extended-attributes.component.scss']
})
export class AssociateExtendedAttributesComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, public supplierProductService: SupplierProductService,
              private growlService: GrowlService, private workflowService: WorkflowService, private candidateUtilService: CandidateUtilService,
              private matUtils: MatUtilService, private candidateService: CandidateService, public associateUpcService: AssociateUpcService,
              public lookupService: LookupService, private matUtilService: MatUtilService) { }


  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.associateUpcService.getTaskId(),
        this.associateUpcService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.associateUpcService.resetService();
        this.associateUpcService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.ASSOCIATE_UPC) {
              this.associateUpcService.setOriginalAndCurrentCandidate(candidate);
              return this.updateHierarchyAndAttributes();
            } else {
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
            this.initializeData();
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.associateUpcService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return this.updateHierarchyAndAttributes();
            } else {
              this.associateUpcService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
            this.initializeData();
          })
        ).subscribe();
      }
    });
  }

  initializeData() {
    this.associateUpcService.initializeExtendedAttributesIsViewing();
    this.candidateError = this.associateUpcService.getAssociateExtendedAttributesError();
    this.isLoadingData = false;
  }

  onClickBack() {
    this.jumpBack('/associateCaseSelection');
  }

  onClickNext() {
    this.canNavigate = false;
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), [CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR]).subscribe(() => {
      this.candidateError = this.associateUpcService.setAssociateExtendedAttributesError(new CandidateError());
      this.associateUpcService.saveCandidateAndNavigateTo('/associateSupplierReview', false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      this.associateUpcService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.associateUpcService.setAssociateExtendedAttributesError(this.candidateError);
        this.associateUpcService.saveCandidateAndNavigateTo('/associateSupplierReview', false).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      } else {
        this.canNavigate = true;
      }
    });
  }

  onClose() {
    this.associateUpcService.saveCandidateAndClose();
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case AssociateUpcStepperComponent.ORIGINAL_UPC_ITEM_CODE: {
        this.jumpBack('/setupAssociateUpc');
        break;
      }
      case AssociateUpcStepperComponent.ITEM_DETAILS: {
        this.jumpBack('/associateBasicItemSetup');
        break;
      }
      case AssociateUpcStepperComponent.CASE_SELECTION: {
        this.onClickBack();
        break;
      }
      case AssociateUpcStepperComponent.REVIEW: {
        this.onClickNext();
        break;
      }
    }
  }

  updateHierarchyAndAttributes(): Observable<any> {
    if (this.associateUpcService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.associateUpcService.candidate.candidateProducts[0].upc, []).pipe(
        switchMap((productData) => this.matUtilService.updateCandidateProductsMatHierarchyFromProduct(this.associateUpcService.candidate.candidateProducts, productData)),
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.associateUpcService.getCandidate(),
          this.associateUpcService.globalAttributes, this.associateUpcService.hierarchyAttributes)),
      );
    } else if (this.associateUpcService.candidate.candidateProducts[0].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.associateUpcService.candidate.candidateProducts[0].itemCode, []).pipe(
        switchMap((productData) => this.matUtilService.updateCandidateProductsMatHierarchyFromProduct(this.associateUpcService.candidate.candidateProducts, productData)),
        switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.associateUpcService.getCandidate(),
          this.associateUpcService.globalAttributes, this.associateUpcService.hierarchyAttributes)),
      );
    }
  }

  jumpBack(urlToNavigate) {
    this.canNavigate = false;
    this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
      finalize(() => this.canNavigate = true)
    ).subscribe();
  }

  hasExtendedAttributesErrors(candidateProductId): boolean {
   if (!this.candidateError?.candidateProductErrors[candidateProductId]?.matAttributeErrors) {
     return false;
   }
   return !!Object.keys(this.candidateError.candidateProductErrors[candidateProductId].matAttributeErrors).length;
  }
}
