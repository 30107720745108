import {Component, OnInit} from '@angular/core';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateError, CandidateProductError, CandidateValidatorType} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierProductService} from '../../service/supplier-product.service';
import {GrowlService} from '../../growl/growl.service';
import {WorkflowService} from '../../service/workflow.service';
import {catchError, finalize, switchMap, tap} from 'rxjs/operators';
import {MatUtilService} from '../../service/mat-util.service';
import {NewWarehouseProductStepperComponent} from '../new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {NewDsdProductStepperComponent} from '../new-dsd-product-stepper/new-dsd-product-stepper.component';
import {CandidateService} from '../../service/candidate.service';

@Component({
  selector: 'app-extended-attributes',
  templateUrl: './extended-attributes.component.html',
  styleUrls: ['./extended-attributes.component.scss']
})
export class ExtendedAttributesComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public supplierProductService: SupplierProductService,
              private growlService: GrowlService, private workflowService: WorkflowService, private candidateUtilService: CandidateUtilService,
              private matUtils: MatUtilService, private candidateService: CandidateService) { }


  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;
  candidateProductError: CandidateProductError;

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierProductService.getTaskId(),
        this.supplierProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierProductService.resetService();
        this.supplierProductService.setCandidateByUrlParameters(params).pipe(
          switchMap(() => this.matUtils.updateMatAttributesAndValues(this.supplierProductService.candidate,
            this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes)),
          finalize(() => {
              this.isLoadingData = false;
              this.candidateError = this.supplierProductService.getExtendedAttributesError();
              this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
            }
          )
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierProductService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              this.supplierProductService.resetMatHierarchyFields();
              return this.matUtils.updateMatAttributesAndValues(this.supplierProductService.candidate,
                this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes);
            } else {
              this.supplierProductService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          finalize(() => {
              this.isLoadingData = false;
              this.candidateError = this.supplierProductService.getExtendedAttributesError();
              this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
            }
          )
        ).subscribe();
      }
    });
  }

  onClickStepper(stepperItem) {
    let urlToNavigate;
    switch (stepperItem.text) {
      case NewWarehouseProductStepperComponent.UPC_ITEM_CODE:
        urlToNavigate = '/setupCandidateType';
        break;
      case NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP:
        urlToNavigate = '/supplierDetails';
        break;
      case NewWarehouseProductStepperComponent.PRODUCT_DETAILS:
        urlToNavigate = '/basicItemSetup';
        break;
      case NewWarehouseProductStepperComponent.CASE_PACK:
        urlToNavigate = '/casePack';
        break;
      case NewDsdProductStepperComponent.STORE_AUTHORIZATION:
        this.onClickBack();
        return;
      case NewWarehouseProductStepperComponent.REVIEW_PAGE:
        urlToNavigate = '/newProductReviewPageComponent';
        break;
    }
    this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).subscribe();
  }

  onClickBack() {
    this.canNavigate = false;
    let urlToNavigate;
    if (!this.supplierProductService.getCandidate().dsdSwitch) {
      urlToNavigate = '/casePack';
    } else if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
      this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
      urlToNavigate = '/storeAuthorization';
    } else {
      urlToNavigate = '/setUpStores';
    }
    this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).pipe(
      finalize(() => this.canNavigate = true)
    ).subscribe();
  }

  onClickNext() {
    this.canNavigate = false;

    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(), [CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR])
      .pipe(
        tap(() => {
          this.candidateError = this.supplierProductService.setExtendedAttributesError(new CandidateError());
          this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
        }),
        switchMap(() => this.supplierProductService.saveCandidateAndNavigate('/newProductReviewPageComponent', false)),
        catchError(error => {
          if (error.error?.candidateErrors?.hasErrors) {
            this.candidateError = this.supplierProductService.setExtendedAttributesError(error.error.candidateErrors);
            this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
          } else {
            this.growlService.addError(error.message);
          }
          return this.supplierProductService.saveCandidateAndNavigate('/newProductReviewPageComponent', false);
        }),
        finalize(() => this.canNavigate = true)
      )
      .subscribe();
  }
}
