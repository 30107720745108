<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">

  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label" [ngClass]="attribute.labelInputGroupClass" *ngIf="attribute.label">
      <label for="">{{attribute.label}}</label>
    </div>

    <div class="attribute-control">

      <ng-container [ngSwitch]="attribute.textInputType">
        <ng-container *ngSwitchCase="inputTextType.decimal">
          <pm-decimal-input [inputClass]="getErrorClass()"
                            [(model)]="model" [numOfDecimals]="attribute.decimalCount" [numOfIntegers]="attribute.numberCount"
                            [placeholderText]="attribute.placeholderText" (inputBlur)="onBlur($event)" (inputFocus)="onFocus($event)"
                            [inputReadOnly]="attribute.isReadOnly && attribute.isReadOnly()"
                            [inputDisabled]="attribute.isDisabled && attribute.isDisabled()" [inputId]="attribute?.name"
                            [inputTabIndex]="attribute.tabIndex">
          </pm-decimal-input>
        </ng-container>
        <ng-container *ngSwitchCase="inputTextType.textarea">
          <textarea rows="6" pInputTextarea [(ngModel)]="model" [class]="attribute.inputGroupClass" (blur)="onBlur($event)" (focus)="onFocus($event)" (focusout)="onFocusOut($event)"
                    placeholder="{{attribute.placeholderText}}" [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
                    [maxlength]="attribute.maxLength" [id]="attribute?.name"
                    [disabled]="attribute.isDisabled && attribute.isDisabled()"
                    [ngClass]="{'ui-inputtext-invalid' : attributeError}"
                    tabindex="{{attribute.tabIndex}}"></textarea>
        </ng-container>
        <ng-container *ngSwitchCase="inputTextType.integer">
          <input type="text" pInputText [(ngModel)]="model" [class]="attribute.inputGroupClass" appOnlyNumber
                 (blur)="onBlur($event)" (focus)="onFocus($event)" (focusout)="onFocusOut($event)"
                 placeholder="{{attribute.placeholderText}}" [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
                 [maxlength]="attribute.maxLength" [id]="attribute?.name"
                 [disabled]="attribute.isDisabled && attribute.isDisabled()"
                 [ngClass]="{'ui-inputtext-invalid' : attributeError}"
                 tabindex="{{attribute.tabIndex}}">
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input type="text" pInputText [(ngModel)]="model" [class]="attribute.inputGroupClass"
                 (blur)="onBlur($event)" (focus)="onFocus($event)" (focusout)="onFocusOut($event)"
                 placeholder="{{attribute.placeholderText}}" [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
                 [maxlength]="attribute.maxLength" [id]="attribute?.name"
                 [disabled]="attribute.isDisabled && attribute.isDisabled()"
                 [ngClass]="{'ui-inputtext-invalid' : attributeError}"
                 tabindex="{{attribute.tabIndex}}">
        </ng-container>
      </ng-container>
      <div class="d-flex flex-wrap" *ngIf="attribute.charLimit">
        <div>
          <p *ngIf="attribute.description" class="attribute-description" [innerHTML]="attribute.description">
          </p>
        </div>
        <div class="ml-auto">
          <p class="{{getCharLimitStatus()}}">{{getModelLength()}}/{{attribute.charLimit}}
          </p>
        </div>
      </div>
      <p *ngIf="attribute.description && !attribute.charLimit" class="attribute-description" [innerHTML]="attribute.description">
      </p>
      <p *ngIf="attributeError && showErrorMessage" class="attribute-error-message">
        {{attributeError}}
      </p>

    </div>
  </div>
</ng-container>
