<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4 helper-col">
    <!-- Helper Column -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Pricing" subtitle="Details about pricing, margin, and profit.">
    <ng-container *ngIf="model">
      <pm-attribute-display-radio [attribute]="pricingTypeConfiguration"
                                  [(model)]="model.retailType"
                                  [attributeError]="errorModel?.retailType"
                                  (change)="pricingTypeChange($event)">
      </pm-attribute-display-radio>

      <pm-attribute-display-muli-source-input [attribute]="retailLinkConfiguration"
                                              [attributeError]="errorModel?.retailLink"
                                              [model]="model?.retailLink?.searchedId"
                                              (modelChange)="onRetailLinkNumberChange($event)"
                                              [selectCategory]="model?.retailLink?.upcType"
                                              (selectCategoryChange)="onRetailLinkTypeChange($event)"
                                              [state]="retailLinkState"
                                              (validate)="validateRetailLinkEvent($event)">
      </pm-attribute-display-muli-source-input>

      <pm-attribute-display-dual-numeric-input [attribute]="getXForConfiguration()"
                                               [(firstInputModel)]="model.retailXFor"
                                               [(secondInputModel)]="model.retailPrice"
                                               [firstInputModelError]="errorModel?.retailXFor"
                                               [secondInputModelError]="errorModel?.retailPrice">
      </pm-attribute-display-dual-numeric-input>

      <pm-attribute-display-toggle [attribute]="soldByWeightConfiguration"
                                   [(model)]="model.weightSwitch"
                                   (change)="soldByWeightChange($event)">
      </pm-attribute-display-toggle>

      <pm-attribute-display-text-input [attribute]="pennyProfitConfiguration"
                                       [model]="getHebPennyProfit()">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="marginPercentConfiguration"
                                       [model]="costService.getHebMargin(model)">
      </pm-attribute-display-text-input>


    </ng-container>
  </pm-card>
</ng-template>
