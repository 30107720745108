import {Component, OnInit} from '@angular/core';
import {GrowlService} from '../../growl/growl.service';
import {
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  LocationGroupStores,
  Product,
  Upc
} from 'pm-models';
import {InvitedDistributorService} from '../../service/invited-distributor.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {ProductService} from '../../service/product.service';
import {CostService} from '../../service/cost.service';
import {AuthService} from '../../auth/auth.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {WorkflowService} from '../../service/workflow.service';

@Component({
  selector: 'app-invited-distributor-review-page',
  templateUrl: './invited-distributor-review-page.component.html',
  styleUrls: ['./invited-distributor-review-page.component.scss']
})
export class InvitedDistributorReviewPageComponent implements OnInit {

  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';

  private candidateSubscription$: any;
  public taskId: any;
  public productData: Product;
  public upc: Upc;
  public productImageUrl: string = null;
  public candidate: Candidate;
  public originalCandidate: any = {};
  public invitedCandidates: Candidate[] = [];
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public allDsdCandidates: Candidate[] = [];
  public isSelectingDistributors: boolean = true;
  canEditAndResubmit = true;


  // contains all invited candidates ap numbers, and their selected stores.
  apNumberToStoreNumbersMap: Map<number, number[]> = new Map();
  // contains all associated ap numbers and all their groups/stores possibilities.
  public vendorApToAuthGroupsMap: Map<number, LocationGroupStores[]> = new Map<number, LocationGroupStores[]>();

  constructor(public invitedDistributorService: InvitedDistributorService, public route: ActivatedRoute, public router: Router,
              private lookupService: LookupService, private productService: ProductService, public candidateService: CandidateService,
              private growlService: GrowlService, public costService: CostService, private authService: AuthService,
              private candidateUtilService: CandidateUtilService, private workflowService: WorkflowService) { }

  ngOnInit() {
    this.candidateSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId')) {
        const candidateId: number = parseInt(params.get('candidateId'), 10);

        this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
          if (candidate.candidateType !== Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR &&
            candidate.candidateType !== Candidate.ADDITIONAL_DISTRIBUTOR) {

            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          } else {
            this.setInitialValues(candidate);
          }
        });
      } else {
        this.router.navigate(['/tasks']).then();
      }
      if (params.has('taskId')) {
        this.taskId = parseInt(params.get('taskId'), 10);
      }
    });
  }



  private async setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.productImageUrl = null;
    await this.initializeProductData().toPromise();
    this.createVendorToAuthGroupsMap();
    this.candidateError = new CandidateError();
    this.candidateProductError = this.candidateError.candidateProductErrors[this.candidate.candidateProducts[0].id];

    this.candidateService.findDistributorCandidatesByUpc(this.candidate.candidateProducts[0].upc).subscribe((candidates) => {
      if (!candidates || candidates.length === 0) {
        this.growlService.addError('Unable to locate parent candidate.');
        return;
      }
      this.allDsdCandidates = candidates;
    });
  }

  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
  }

  initializeProductData() {
    return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[0].upc, []).pipe(
      switchMap(
        (productData) => {
          this.productData = productData;
          this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
          return this.lookupService.getUpc(this.candidate.candidateProducts[0].upc).pipe(tap((upc) => {
            this.upc = upc;
          })).toPromise();
        }
      )
    );
  }

  async createVendorToAuthGroupsMap() {
    this.invitedCandidates.push(this.candidate);

    await this.findAndAddToVendorToAuthGroupsMap(this.candidate.vendor.apNumber);
    this.addInvitedCandidateToApNumberToStoreMap(this.candidate);

    // if the user is only a vendor, don't show linked tasks (or if there are no linked tasks).
    if ((this.authService.isVendor() && !this.authService.hasMoreThanOneRole()) ||
      !this.candidate.candidateProducts[0].invitedSuppliers || this.candidate.candidateProducts[0].invitedSuppliers.length === 0) {
      return;
    }
    for (let x = 0; x < this.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
      this.candidateService.getCandidate(this.candidate.candidateProducts[0].invitedSuppliers[x].candidateId)
        .subscribe(async (candidate: Candidate) => {
          this.invitedCandidates.push(candidate);
          this.addInvitedCandidateToApNumberToStoreMap(candidate);
          await this.findAndAddToVendorToAuthGroupsMap(candidate.vendor.apNumber);
        });
      if (x === this.candidate.candidateProducts[0].invitedSuppliers.length - 1) {
        this.candidateService.validateCandidate(this.candidate,
          [CandidateValidatorType.ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe(() => {}, (error) => {
          if (error.error.candidateErrors) {
            this.candidateError = error.error.candidateErrors;
            this.candidateProductError = this.candidateError.candidateProductErrors[this.candidate.candidateProducts[0].id];
            this.setCandidateIdToGroupStoreListMap();
            this.removeActiveStores();
          }
        });
      }
    }
  }

  findAndAddToVendorToAuthGroupsMap(apNumber) {
    return this.lookupService.findAllAuthGroups(apNumber, this.candidate.commodity.departmentId,
      this.candidate.commodity.subDepartmentId).pipe(tap((locGrpStores: LocationGroupStores[]) => {
      this.vendorApToAuthGroupsMap.set(apNumber, locGrpStores);
    })).toPromise();
  }

  addInvitedCandidateToApNumberToStoreMap(candidate: Candidate) {
    const stores: number[] = [];
    for (let x = 0; x < candidate.candidateProducts[0].locationGroupStores.length; x++) {
      for (let y = 0; y < candidate.candidateProducts[0].locationGroupStores[x].stores.length; y++) {
        stores.push(candidate.candidateProducts[0].locationGroupStores[x].stores[y].custLocationNumber);
      }
    }
    this.apNumberToStoreNumbersMap.set(candidate.vendor.apNumber, stores);
  }

  setCandidateIdToGroupStoreListMap() {
    if (!this.candidateProductError || !this.candidateProductError.candidateIdToGroupStoreListMap) {
      return;
    }
    this.candidateProductError.candidateIdToGroupStoreListMap =
      new Map(Object.entries(this.candidateProductError.candidateIdToGroupStoreListMap));
    for (const candidateId of this.candidateProductError.candidateIdToGroupStoreListMap.keys()) {
      this.candidateProductError.candidateIdToGroupStoreListMap.set(candidateId,
        new Map(Object.entries(this.candidateProductError.candidateIdToGroupStoreListMap.get(candidateId))));
    }
  }

  removeActiveStores() {
    if (!this.candidateProductError || !this.candidateProductError.candidateIdToGroupStoreListMap ||
      this.candidateProductError.candidateIdToGroupStoreListMap.size === 0) {
      return;
    }
    for (const candidateId of this.candidateProductError.candidateIdToGroupStoreListMap.keys()) {
      for (const groupId of this.candidateProductError.candidateIdToGroupStoreListMap.get(candidateId).keys()) {
        this.findAndRemoveActiveStoresForCandidateAndGroup(candidateId, groupId);
      }
    }
  }

  findAndRemoveActiveStoresForCandidateAndGroup(candidateId, groupId) {
    for (let x = 0; x < this.invitedCandidates.length; x++) {
      if (+candidateId !== this.invitedCandidates[x].candidateId) {
        continue;
      }
      for (let y = 0; y < this.invitedCandidates[x].candidateProducts[0].locationGroupStores.length; y++) {
        if (+groupId !== this.invitedCandidates[x].candidateProducts[0].locationGroupStores[y].splrLocationGroupId) {
          continue;
        }
        this.removeActiveStoresForCandidateAndGroup(this.invitedCandidates[x],
          this.invitedCandidates[x].candidateProducts[0].locationGroupStores[y],
          this.candidateProductError.candidateIdToGroupStoreListMap.get(candidateId).get(groupId));
      }
    }
  }

  removeActiveStoresForCandidateAndGroup(candidate, locationGroupStores: LocationGroupStores, storeNumbers: number[]) {

    for (let x = 0; x < storeNumbers.length; x++) {
      for (let y = 0; y < locationGroupStores.stores.length; y++) {
        if (storeNumbers[x] === locationGroupStores.stores[y].custLocationNumber) {
          locationGroupStores.isViewing = true;
          locationGroupStores.stores.splice(y, 1);
          break;
        }
      }
      if (x === storeNumbers.length - 1) {
        this.candidateService.saveCandidate(candidate, true).subscribe((savedCandate) => {
          if (this.candidate.candidateId === savedCandate.candidateId) {
            this.setOriginalAndCurrentCandidate(savedCandate);
          }
        });
      }
    }
  }

  onClose() {
    this.router.navigate(['/tasks']).then();
  }

  getTotalStoresString(locationGroupStores: LocationGroupStores[], apNumber: number) {
    const selectedStoreCount = this.getTotalStoresSelected(locationGroupStores);
    const totalStoreCount = this.getTotalStoresForApNumber(apNumber);

    if (!selectedStoreCount || !totalStoreCount) {
      return;
    } else {
      return 'Total stores: ' + selectedStoreCount + ' of ' + totalStoreCount + ' stores.';
    }
  }

  getTotalStoresForApNumber(apNumber: number) {
    const locationGroupStores: LocationGroupStores[] = this.vendorApToAuthGroupsMap.get(apNumber);

    if (!locationGroupStores) {
      return null;
    }

    let totalStores = 0;
    for (let x = 0; x < locationGroupStores.length; x++) {
      totalStores += locationGroupStores[x].stores.length;
    }
    return totalStores;
  }

  getTotalStoresSelected(locationGroupStores: LocationGroupStores[]) {
    let selectedStoreCount = 0;
    for (let x = 0; x < locationGroupStores.length; x++) {
      selectedStoreCount += locationGroupStores[x].stores.length;
    }
    return selectedStoreCount;
  }

  getHebPennyProfit(candidate: Candidate) {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebPennyProfit(tempCandidate);
    }
  }

  getHebMargin(candidate: Candidate) {
    if (this.productData && this.upc) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(candidate));
      tempCandidate.unitCost = this.costService.getUnitCost(candidate);
      tempCandidate.retailPrice = this.upc.retailPrice;
      tempCandidate.retailXFor = this.upc.xfor;
      if (!this.productData.priceRequired) {
        tempCandidate.retailType = 'KEY_RETAIL';
      }
      return this.costService.getHebMargin(tempCandidate);
    }
  }

  getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  onClickPrint() {
    window.print();
  }

  isRejected(): boolean {
    return this.candidate.status === Candidate.DECLINED;
  }

  resubmitCandidate() {
    this.canEditAndResubmit = false;
    const resubmittedCandidate = JSON.parse(JSON.stringify(this.candidate));
    resubmittedCandidate.candidateId = null;
    resubmittedCandidate.status = null;
    resubmittedCandidate.vendorComment = null;
    resubmittedCandidate.buyerComment = null;
    resubmittedCandidate.scaComment = null;
    // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
    this.invitedDistributorService.createCandidateAndNavigate(resubmittedCandidate, '/supplierAddDistributor', () => {
      this.workflowService.deleteHistoricInstance(this.taskId).subscribe();
    });

  }
}
