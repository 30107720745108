import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import {
  CandidateProduct,
  ProductDescriptionsCardModel,
  ProductDescriptionsCardPermissions,
  AttributeTextInputConfig,
  TextInputType,
  ProductDescriptionsCardErrors, CandidateError
} from 'pm-models';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper, VisiblePermissionsMapper} from '../attribute-permissions-mapper';

@Component({
  selector: 'pm-product-descriptions-card',
  templateUrl: './product-descriptions-card.component.html',
  styleUrls: ['./product-descriptions-card.component.scss']
})
export class ProductDescriptionsCardComponent implements OnInit {
  @Input()
  model: ProductDescriptionsCardModel | CandidateProduct;

  @Input()
  permissions: ProductDescriptionsCardPermissions;

  @Input()
  errorModel: ProductDescriptionsCardErrors | CandidateError;

  @Output() modelChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    this.updateReceiptModel();

    this.checkDescriptionLength();
  }

  PRODUCT_DESCRIPTION_MAX_SIZE: number = 30;

  descriptionHelpOffset = '130px';
  descriptionHelpVisible: boolean = false;

  st1HelpVisible = false;
  st1HelperOffset = '0px';

  receiptModel: string;

  descriptionConfiguration1: AttributeTextInputConfig = {
    label: 'Product Description',
    description: `Enter a description (30 char. max). The first 25 characters will appear on the customer's printed receipt.`,
    placeholderText: 'Description',
    charLimit: 30,
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.description),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.description),
    isRequired: true,
    name: 'productDescriptionId'
  };

  descriptionConfiguration2: AttributeTextInputConfig = {
    label: 'Receipt',
    isDisabled: () => true,
    isReadOnly: () => true,
    isRequired: false,
    name: 'receiptId'
  };

  descriptionChange($event) {
    this.modelChange.emit(this.model);
  }

  toggleDescriptionHelp(event) {
    if (event && event.srcElement && event.srcElement.offsetTop) {
      this.descriptionHelpOffset = event.srcElement.offsetTop + 'px';
    }

    this.descriptionHelpVisible = !this.descriptionHelpVisible;
  }

  st1Configuration: AttributeTextInputConfig = {
    label: 'Shelf tag line 1',
    description: `Enter what will appear on the shelf tag in the store.`,
    placeholderText: 'Description',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.customerFriendlyDescription1),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.customerFriendlyDescription1),
    isHidden: () => VisiblePermissionsMapper(this.permissions,  this.permissions.customerFriendlyDescription1),
    isRequired: true,
    charLimit: 30,
    name: 'shelfTagLine1Id'
  };

  st1Change($event) {
    this.modelChange.emit(this.model);
  }

  toggleSt1Help(event, value) {
    this.descriptionHelpVisible = false;

    if (event && event.srcElement && event.srcElement.offsetTop) {
      this.st1HelperOffset = event.srcElement.offsetTop + 'px';
    }
    this.st1HelpVisible = value;
  }

  st2Configuration: AttributeTextInputConfig = {
    label: 'Shelf tag line 2',
    description: `You can optionally add additional details that will appear as a second line on the shelf tag.`,
    placeholderText: 'Description',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.customerFriendlyDescription2),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.customerFriendlyDescription2),
    isHidden: () => VisiblePermissionsMapper(this.permissions,  this.permissions.customerFriendlyDescription2),
    isRequired: false,
    charLimit: 30,
    name: 'shelfTagLine2Id'
  };

  st2Change($event) {
    this.modelChange.emit(this.model);
  }

  ecommCopyConfiguration: AttributeTextInputConfig = {
    label: 'eComm copy',
    description: `Provide a description that explains what this product is and why it's worth buying.
    This will appear under the "Product description" section on our eCommerce platforms. <br/><br />

    Only enter plain text. Our apps will handle styling this content.
    `,
    placeholderText: '',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.romanceCopy),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.romanceCopy),
    isHidden: () => VisiblePermissionsMapper(this.permissions,  this.permissions.romanceCopy),
    isRequired: true,
    textInputType: TextInputType.textarea,
    name: 'romanceCopyId',
    charLimit: 4000,
    maxLength: 10000
  };

  ecommChange($event) {
    this.modelChange.emit(this.model);
  }

  updateReceiptModel() {
    if (this.model && this.model.description) {
      this.receiptModel = this.model.description.substring(0, 25).toLocaleUpperCase();
    } else {
      this.receiptModel = '';
    }
  }

  private checkDescriptionLength() {
    if (this.model && this.model.description && this.model.description.length > this.PRODUCT_DESCRIPTION_MAX_SIZE) {
        this.errorModel.description = 'Maximum length is ' + this.PRODUCT_DESCRIPTION_MAX_SIZE + ' characters.';
    } else {
      this.clearDescriptionLengthError();
    }
  }

  private clearDescriptionLengthError() {
    if (this.errorModel && this.errorModel.description && this.errorModel.description.startsWith('Maximum length is')) {
      this.errorModel.description = undefined;
    }
  }
}
