import {Injectable} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {Candidate, CandidateValidatorType, Upc} from 'pm-models';
import {AttributeTypes} from 'pm-components';
import {EditCandidateModalService} from './edit-candidate-modal.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CandidateService} from './candidate.service';
import {RETAIL_LINK} from 'pm-components/lib/attributes/attribute-constants';

@Injectable({
  providedIn: 'root'
})
export class CostService {

  public static KEY_RETAIL = 'Key Retail';
  public static KEY_RETAIL_KEY_CONSTANT = 'KEY_RETAIL';
  public static PRICE_REQUIRED = 'Price Required';
  digitsInfo: string = '1.2-2';
  costPrecision: string = '1.4-4';
  public MARGIN_TOOLTIP = 'Margin is less than recommended.';

  constructor(private currencyPipe: CurrencyPipe, public editCandidateModalService: EditCandidateModalService,
              private candidateService: CandidateService) { }

  public toCurrencyForCost(val) {
    if (val !== null && val !== undefined && val !== '') {
      return this.currencyPipe.transform(
        val, 'USD', undefined, this.costPrecision);
    }
  }

  public toCurrency(val) {
    if (val !== null && val !== undefined && val !== '') {
      return this.currencyPipe.transform(
        val, 'USD', undefined, this.digitsInfo);
    }
  }

  public getPennyProfit(candidate: Candidate) {
    if (candidate.suggestedRetailPrice && candidate.suggestedXFor &&
      candidate.unitCost && candidate.retailType !== CostService.PRICE_REQUIRED) {
      return this.toCurrency(
        (+candidate.suggestedRetailPrice / +candidate.suggestedXFor) - +candidate.unitCost);
    } else {
      return null;
    }
  }

  public getMargin(candidate: Candidate) {
    if (candidate.suggestedRetailPrice && candidate.suggestedXFor &&
      candidate.unitCost && candidate.retailType !== CostService.PRICE_REQUIRED) {
      return ((((+candidate.suggestedRetailPrice / +candidate.suggestedXFor) - +candidate.unitCost) /
        (+candidate.suggestedRetailPrice / +candidate.suggestedXFor)) * 100).toFixed(4);
    } else {
      return;
    }
  }

  public getMarginString(candidate: Candidate) {
    const margin = this.getMargin(candidate);
    if (margin) {
      return margin + '%';
    } else {
      return null;
    }
  }

  /**
   * determines whether or not a given candidate would have negative profit margins.
   * @param candidate
   */
  isMarginNegativeOrZero(candidate: Candidate) {
    // tslint:disable-next-line:radix
    const margin: number = parseInt(this.getMargin(candidate));
    if (margin !== null && margin !== undefined) {
      return margin <= 0;
    }
    return false;
  }

  /**
   * Calculates the H-E-B penny profit.
   */
  public getHebPennyProfit(candidate: Candidate) {
    if (candidate.retailPrice && candidate.retailXFor && candidate.unitCost &&
      candidate.retailType && candidate.retailType !== CostService.PRICE_REQUIRED) {
      return this.toCurrency(
        (+candidate.retailPrice / +candidate.retailXFor) - +candidate.unitCost);
    } else {
      return;
    }
  }

  /**
   * Calculates the H-E-B penny profit from a candidate and live UPC.
   */
  public getHebPennyProfitFromCandidateAndUpc(candidate: Candidate, upc: Upc) {

    if (!candidate || !upc) {
      return;
    }

    const tempCandidate: Candidate = JSON.parse(JSON.stringify(candidate));

    tempCandidate.unitCost = this.getUnitCost(candidate);
    tempCandidate.retailPrice = upc.retailPrice;
    tempCandidate.retailXFor = upc.xfor;
    tempCandidate.retailType = CostService.KEY_RETAIL_KEY_CONSTANT;

    return this.getHebPennyProfit(tempCandidate);
  }

  /**
   * Calculates the H-E-B margin percent from a candidate and live UPC.
   */
  public getHebMarginFromCandidateAndUpc(candidate: Candidate, upc: Upc) {

    if (!candidate || !upc) {
      return;
    }

    const tempCandidate: Candidate = JSON.parse(JSON.stringify(candidate));

    tempCandidate.unitCost = this.getUnitCost(candidate);
    tempCandidate.retailPrice = upc.retailPrice;
    tempCandidate.retailXFor = upc.xfor;
    tempCandidate.retailType = CostService.KEY_RETAIL_KEY_CONSTANT;

    return this.getHebMargin(tempCandidate);
  }

  isHebMarginNegativeOrZeroByCandidateAndUpc(candidate: Candidate, upc: Upc) {
    const tempCandidate: Candidate = JSON.parse(JSON.stringify(candidate));

    tempCandidate.unitCost = this.getUnitCost(candidate);
    tempCandidate.retailPrice = upc?.retailPrice;
    tempCandidate.retailXFor = upc?.xfor;
    tempCandidate.retailType = CostService.KEY_RETAIL_KEY_CONSTANT;
    if (tempCandidate.retailPrice && tempCandidate.retailXFor && tempCandidate.unitCost
      && tempCandidate.retailType && tempCandidate.retailType !== CostService.PRICE_REQUIRED ) {
      const margin = (((+tempCandidate.retailPrice / +tempCandidate.retailXFor) - +tempCandidate.unitCost)
        / (+tempCandidate.retailPrice / +tempCandidate.retailXFor) * 100);
      return margin <= 0;
    } else {
      return false;
    }
  }

  /**
   * Calculates the H-E-B margin percent.
   */
  public getHebMargin(candidate: Candidate) {
    if (candidate.retailPrice && candidate.retailXFor && candidate.unitCost
      && candidate.retailType && candidate.retailType !== CostService.PRICE_REQUIRED ) {
      const margin = (((+candidate.retailPrice / +candidate.retailXFor) - +candidate.unitCost)
        / (+candidate.retailPrice / +candidate.retailXFor) * 100);
      return '' + margin.toFixed(4) + '%';
    } else {
      return;
    }
  }

  /**
   * Calculates the H-E-B margin percent.
   */
  public isHebMarginNegativeOrZero(candidate: Candidate) {
    if (candidate.retailPrice && candidate.retailXFor && candidate.unitCost
      && candidate.retailType && candidate.retailType !== CostService.PRICE_REQUIRED ) {
      const margin = (((+candidate.retailPrice / +candidate.retailXFor) - +candidate.unitCost)
        / (+candidate.retailPrice / +candidate.retailXFor) * 100);
      return margin <= 0;
    } else {
      return;
    }
  }

  getCaseCostAsCurrency(candidate: Candidate) {
    return this.toCurrencyForCost(this.getCaseCost(candidate));
  }

  getCaseCost(candidate: Candidate) {
    if (this.hasInnerPack(candidate)) {
      if (!candidate.masterPack || !+candidate.masterPack) {
        return null;
      }
      return +candidate.masterListCost / (+candidate.masterPack / +candidate.innerPack);
    } else {
      return +candidate.masterListCost;
    }
  }

  /**
   * Sets inner list cost.
   * @param candidate
   */
  setUnitCost(candidate: Candidate): any {
    candidate.unitCost = this.getUnitCost(candidate);
  }

  getUnitCost(candidate: Candidate) {
    if (this.hasInnerPack(candidate)) {
      return this.getCaseCost(candidate) / +candidate.innerPack;
    } else {
      if (!candidate.masterPack || !+candidate.masterPack) {
        return null;
      }
      return this.getCaseCost(candidate) / +candidate.masterPack;
    }
  }

  hasInnerPack(candidate: Candidate) {
    return candidate.innerPack !== null && candidate.innerPack !== undefined && +candidate.innerPack !== 0;
  }

  /**
   * Sets inner list cost.
   * @param candidate
   */
  setInnerListCost(candidate: Candidate): any {
    if (!this.hasValue(candidate.masterListCost) ||
      !this.hasValue(candidate.masterPack)) {
      return;
    }
    candidate.innerListCost = this.getInnerListCost(candidate);
  }

  /**
   * Returns inner list cost if all variables are present.
   * @param candidate
   */
  getInnerListCost(candidate: Candidate) {
    if (!this.hasValue(candidate.masterListCost) ||
      !this.hasValue(candidate.masterPack)) {
      return null;
    }
    if (this.hasValue(candidate.innerPack)) {
      return candidate.masterListCost / (candidate.masterPack / candidate.innerPack);
    } else {
      return candidate.masterListCost;
    }
  }


  hasValue(value) {
    return value !== undefined && value !== null && value !== 0 && value !== '';
  }

  /**
   * Sets appropriate values for fields affecting cost (master pack, inner pack, master list cost).
   * @param attributeType the specific attribute being edited
   * @param candidate
   */
  editCostRelatedFields(attributeType: AttributeTypes, candidate: Candidate) {
    this.editCandidateModalService.openModal(attributeType, candidate).subscribe(response => {
      if ( response ) {
        this.updateCandidateCostsFromUpdatedCandidate(candidate, response);
      }
    });
  }

  /**
   * Updates a candidates costs from updated candidate response.
   * @param candidate candidate to update.
   * @param updatedCandidate candidate with updated cost related fields.
   * @private
   */
  public updateCandidateCostsFromUpdatedCandidate(candidate, updatedCandidate) {

    candidate.masterListCost = updatedCandidate.masterListCost;
    candidate.masterPack = updatedCandidate.masterPack;
    candidate.innerPack = updatedCandidate.innerPack;
    candidate.innerPackSelected = updatedCandidate.innerPackSelected;

    // If inner pack matches master pack then prefill in inner pack size fields
    const innerPackQty = candidate.innerPack + '';
    const masterPackQty = candidate.masterPack + '';

    if (!candidate.innerPackSelected) {
      candidate.innerPack = candidate.masterPack;
      candidate.innerLength = candidate.masterLength;
      candidate.innerWidth = candidate.masterWidth;
      candidate.innerHeight = candidate.masterHeight;
      candidate.innerWeight = candidate.masterWeight;
      this.setInnerListCost(candidate);
      candidate.unitCost = this.getUnitCost(candidate);
    } else if (this.areMasterPackValuesSet(candidate)) {
      if (innerPackQty === masterPackQty) {
        candidate.innerLength = candidate.masterLength;
        candidate.innerWidth = candidate.masterWidth;
        candidate.innerHeight = candidate.masterHeight;
        candidate.innerWeight = candidate.masterWeight;
      }
      this.setInnerListCost(candidate);
      candidate.unitCost = this.getUnitCost(candidate);
    }
  }


  areMasterPackValuesSet(candidate: Candidate): boolean {
    return this.isNumberSetAndNotZero(candidate.masterLength) &&
      this.isNumberSetAndNotZero(candidate.masterWidth) &&
      this.isNumberSetAndNotZero(candidate.masterHeight) &&
      this.isNumberSetAndNotZero(candidate.masterWeight);
  }


  isNumberSetAndNotZero (value: any) {
    return value !== undefined && value !== null && value !== 0 && value !== '' && value !== '0';
  }

  /**
   * If the candidate has a valid cost link, it updates the data.
   * @param candidate the candidate to update.
   */
  updateCostLink(candidate: Candidate): Observable<any> {
    if (!candidate.costLinked) {
      return of(candidate);
    }
    return this.candidateService.validateCandidate(candidate, [CandidateValidatorType.COST_LINK_VALIDATOR]).pipe(
      map((validatedCandidate: Candidate) => {
          return this.updateCostLinkRelatedValuesFromValidatedCandidate(candidate, validatedCandidate);
        }
      ),
      catchError(() => of(candidate)));
  }

  updateRetailLink(candidate) {
    if (!candidate.retailLink || candidate.retailType !== RETAIL_LINK) {
      return of(candidate);
    }
    return this.candidateService.validateCandidate(candidate, [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).pipe(
      map((validatedCandidate: Candidate) => {
          return this.updateRetailLinkRelatedValuesFromValidatedCandidate(candidate, validatedCandidate);
        }
      ),
      catchError(() => of(candidate)));

  }

  /**
   * Updates cost link related values on a candidate from a validated candidate.
   * @param candidate the candidate to update.
   * @param validatedCandidate the validated candidate.
   */
  public updateCostLinkRelatedValuesFromValidatedCandidate(candidate: Candidate, validatedCandidate: Candidate): Candidate {
    // If there was a cost link that hasn't changed, and there's an inner list cost that's
    // equal to the link list cost just return the candidate.
    if (candidate.costLinkFromServer &&
      JSON.stringify(candidate.costLinkFromServer) === JSON.stringify(validatedCandidate.costLinkFromServer) &&
      candidate.innerListCost && validatedCandidate.costLinkFromServer.listCost === candidate.innerListCost) {
      return candidate;
    }
    candidate.costLinkFromServer = validatedCandidate.costLinkFromServer;
    const currentInnerListCost = this.getInnerListCost(candidate);
    // if there's a current inner list cost and it equals the link from server, just return.
    if (currentInnerListCost && currentInnerListCost === validatedCandidate.costLinkFromServer.listCost) {
      return candidate;
    }
    // calculate new master list cost based on inner cost from the cost link from the server.
    const newMasterListerCost = this.calculateMasterListCostFromInnerListCostMasterPackAndInnerPack(
      validatedCandidate.costLinkFromServer.listCost, candidate.masterPack, candidate.innerPack);

    // if a new master list cost can be calculated set this value, and update all the cost related fields.
    if (newMasterListerCost) {
      const tempCandidate: Candidate = JSON.parse(JSON.stringify(candidate));
      tempCandidate.masterListCost = newMasterListerCost;
      this.updateCandidateCostsFromUpdatedCandidate(candidate, tempCandidate);
    }

    return candidate;
  }

  /**
   * Updates cost link related values on a candidate from a validated candidate.
   * @param candidate the candidate to update.
   * @param validatedCandidate the validated candidate.
   */
  public updateRetailLinkRelatedValuesFromValidatedCandidate(candidate: Candidate, validatedCandidate: Candidate): Candidate {
      candidate.retailXFor = validatedCandidate.retailXFor;
      candidate.retailPrice = validatedCandidate.retailPrice;
      candidate.retailLink = validatedCandidate.retailLink;
      return candidate;
  }


  /**
   * Calculated the master list cost from inner list cost, master pack and inner pack, if provided.
   * @param innerListCost innerListCost.
   * @param masterPack masterPack qty.
   * @param innerPack innerPack qty.
   */
  private calculateMasterListCostFromInnerListCostMasterPackAndInnerPack(innerListCost: number, masterPack: number, innerPack: number) {
    if (!innerListCost || !masterPack) {
      return null;
    }
    if (!innerPack) {
      return innerListCost;
    } else {
      return innerListCost * (masterPack / innerPack);
    }
  }

  getRetailPricing(candidate: Candidate) {
    if (candidate.retailXFor !== null && candidate.retailXFor !== undefined && candidate.retailXFor !== '' &&
      candidate.retailPrice !== null && candidate.retailPrice !== undefined && candidate.retailPrice !== '') {
      return '' + candidate.retailXFor + ' For ' +  this.toCurrency(candidate.retailPrice);
    } else if (candidate.retailXFor !== null && candidate.retailXFor !== undefined && candidate.retailXFor !== '') {
      return '0 For ' +  this.toCurrency(candidate.retailPrice);
    } else if (candidate.retailPrice !== null && candidate.retailPrice !== undefined && candidate.retailPrice !== '') {
      return '' + candidate.retailXFor + ' For $0.00';
    } else {
      return null;
    }
  }
  getSuggestedRetailPricing(candidate: Candidate) {
    if (candidate.suggestedXFor !== null && candidate.suggestedXFor !== undefined && candidate.suggestedXFor !== '' &&
      candidate.suggestedRetailPrice !== null && candidate.suggestedRetailPrice !== undefined && candidate.suggestedRetailPrice !== '') {
      return '' + candidate.suggestedXFor + ' For ' +  this.toCurrency(candidate.suggestedRetailPrice);
    } else if (candidate.suggestedXFor !== null && candidate.suggestedXFor !== undefined && candidate.suggestedXFor !== '') {
      return '0 For ' +  this.toCurrency(candidate.suggestedRetailPrice);
    } else if (candidate.suggestedRetailPrice !== null && candidate.suggestedRetailPrice !== undefined && candidate.suggestedRetailPrice !== '') {
      return '' + candidate.suggestedXFor + ' For $0.00';
    } else {
      return null;
    }
  }
}
