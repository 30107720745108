import {NgModule} from '@angular/core';
import {CardModule} from 'pm-components/lib/shared/card/card.module';
import {AttributeDisplayModule} from 'pm-components/lib/shared/attribute-display/attribute-display.module';
import {CommonModule} from '@angular/common';
import {
  RequestNewAttributeConfirmModule
} from 'pm-components/lib/shared/request-new-attribute-confirm/request-new-attribute-confirm-module';
import {StaticOverlayPanelModule} from 'pm-components/lib/shared/overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {GlobalAttributesCardComponent} from 'pm-components/lib/cards/global-attributes-card/global-attributes-card.component';
import {
  RequestNewMatAttributeFormModule
} from 'pm-components/lib/shared/request-new-mat-attribute-form/request-new-mat-attribute-form.module';

@NgModule({
  declarations: [GlobalAttributesCardComponent],
  exports: [GlobalAttributesCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule, AttributeDisplayModule, StaticOverlayPanelModule, RequestNewAttributeConfirmModule,
    RequestNewMatAttributeFormModule
  ]
})
export class GlobalAttributesCardModule { }
