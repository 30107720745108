import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';
import {CostService} from '../../../../../../src/app/2.0.0/service/cost.service';
import {AttributeTypes} from 'pm-components';
import {Candidate, CandidateError, CandidateHelper, CandidateProduct, ErrorCandidate} from 'pm-models';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {UploadCandidateService} from '../../../../../../src/app/2.0.0/service/upload-candidate.service';
import {UploadCandidateProductConstants} from '../../../../../../src/app/2.0.0/utils/constants/upload-candidate-product-constants';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';
import {CandidateErrorUtilService} from '../../../../../../src/app/2.0.0/service/candidate-error-util.service';
import {EditCandidateUtilService} from '../../../../../../src/app/2.0.0/service/edit-candidate-util.service';

@Component({
  selector:  'pm-upload-product-table',
  templateUrl:  './upload-product-table.component.html',
  styleUrls:  ['./upload-product-table.component.scss']
})
export class UploadProductTableComponent implements OnInit {

  @Input() candidates: Candidate[];
  @Input() errorCandidates: ErrorCandidate[] = [];
  @Input() candidateType: string;
  @Input() workflowState: string;
  @Input() pageSize: number = 50;
  @Input() totalNumRecords: number;
  @Input() candidateProductIndex;
  @Input() uuidToImageMap: Map<string, any>;
  @Input() candidateIdToCandidateErrorMap: Map<number, CandidateError>;

  @Output() loadData = new EventEmitter<any>();
  @Output() candidatesChange = new EventEmitter<any>();

  productHeaderColumns: any[];

  public DESCRIPTION_COLUMN_GROUP_LENGTH;
  public MERCHANDISING_COLUMN_GROUP_LENGTH;
  public REGULATORY_COLUMN_GROUP_LENGTH;
  public RETAIL_PRICING_COLUMN_GROUP_LENGTH;
  public PACKAGING_COLUMN_GROUP_LENGTH;

  constructor(public candidateUtils: CandidateUtilService, public costService: CostService,
              private editCandidateModalService: EditCandidateModalService, private candidateService: CandidateService,
              private lookupService: LookupService, public growlService: GrowlService,
              private uploadCandidateService: UploadCandidateService, public candidateErrorUtils: CandidateErrorUtilService,
              private editCandidateUtilService: EditCandidateUtilService) { }

  ngOnInit() {
    if (this.candidateType) {
      this.productHeaderColumns = this.uploadCandidateService.getProductColumnsFromCandidateType(this.candidateType);
    } else if (this.workflowState) {
      this.productHeaderColumns = this.uploadCandidateService.getProductColumnsFromWorkflowState(this.workflowState);
    }

    // initialize columns group lengths.
    this.DESCRIPTION_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.productHeaderColumns,
      UploadCandidateProductConstants.DESCRIPTIONS_COLUMN_GROUP);

    this.MERCHANDISING_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.productHeaderColumns,
      UploadCandidateProductConstants.MERCHANDISING_COLUMN_GROUP);

    this.REGULATORY_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.productHeaderColumns,
      UploadCandidateProductConstants.REGULATORY_COLUMN_GROUP);

    this.RETAIL_PRICING_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.productHeaderColumns,
      UploadCandidateProductConstants.RETAIL_PRICING_COLUMN_GROUP);

    this.PACKAGING_COLUMN_GROUP_LENGTH =  this.uploadCandidateService.getColumnGroupCount(this.productHeaderColumns,
      UploadCandidateProductConstants.PACKAGING_COLUMN_GROUP);
  }


  getSeasonYearString(product) {
    if (product.season && product.season.seasonDescription) {
      if (product.seasonYear) {
        return product.season.seasonDescription + ', ' + product.seasonYear;
      }
      return product.season.seasonDescription;
    } else if (product.seasonYear) {
      return product.seasonYear;
    } else {
      return null;
    }
  }

  showEditCandidateModal(index, type: AttributeTypes,  overrides?: any) {
    if (!this.isSca()) {
      const tempCandidate = JSON.parse(JSON.stringify(this.candidates[index]));
      this.editCandidateModalService.openModal(type, tempCandidate, overrides).subscribe(response => {
        if (response) {
          // Dispatch Update
          this.candidates[index] = response;
        }
      });
    }
  }

  editProductUPC(index) {
    this.showEditCandidateModal(index, AttributeTypes.ProductUPC, {
      validationService: this.candidateService
    });
  }

  editCandidateProduct(index, attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(index, attributeType);
  }

  showEditCandidateProductModal(index, type: AttributeTypes,  overrides?: any) {
    if (!this.isSca()) {
      this.editCandidateModalService.openModal(type,
        this.candidates[index].candidateProducts[this.candidateProductIndex], overrides).subscribe(response => {
        if (response) {
          // Dispatch Update
          this.candidates[index].candidateProducts[this.candidateProductIndex] = response;
          if (type === AttributeTypes.ProductImages) {
            this.getImages(index);
          }
        }
      });
    }
  }

  showEditAllCandidateProductModal(type, fieldName) {
    if (!this.isSca()) {
      this.editCandidateModalService.openModal(type, new CandidateProduct(), {'isApplyToAll': true}).subscribe(response => {
        if (response) {
          // Dispatch Update

          this.candidates.forEach((candidate) => {
            candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidate)][fieldName] =
              response[fieldName];
          });
        }
      });
    }
  }

  editProductDescription(index, isEditAll?: boolean) {
    if (!this.isSca()) {
      this.editCandidateModalService.openMultiEditModal(
        [
          {type: AttributeTypes.ProductDescription},
          {type: AttributeTypes.Receipt}
        ], isEditAll ? new CandidateProduct() : this.candidates[index].candidateProducts[this.candidateProductIndex],
        {'isApplyToAll': isEditAll}).subscribe(response => {

        if (response) {
          if (isEditAll) {
            this.candidates.forEach((candidate) => {
              candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidate)].description =
                response.description;
            });
          } else {
            this.candidates[index].candidateProducts[this.candidateProductIndex] = response;
          }
        }
      });
    }
  }

  get attributeType() {
    return AttributeTypes;
  }

  get uploadCandidateProductConstants() {
    return UploadCandidateProductConstants;
  }

  hasColumn(column): boolean {
    return this.productHeaderColumns.includes(column);
  }

  async editCommodity(index) {
    if (!this.isSca()) {
      // todo: no buyer- growl? Determine w/ validation on edits.
      if (!this.candidates[index].buyer || !this.candidates[index].buyer.buyerId) {
        return;
      }

      const commodities = await this.lookupService.findAllCommoditiesByBuyerId(this.candidates[index].buyer.buyerId).toPromise();

      this.editCandidateModalService.openModal(AttributeTypes.Commodity,
        this.candidates[index], {collections: commodities}).subscribe(response => {
        if (response) {
          let updateTaxCategory = false;
          if (response.commodity !== this.candidates[index].commodity) {
            updateTaxCategory = true;
          }
          this.candidates[index] = response;
          // subCommodities =  this.candidates[index].commodity.subCommodityList;
          if (updateTaxCategory) {
            this.setVertexTaxCategoryModel(index);
          }
          if (this.candidates[index].commodity) {
            this.candidates[index].pssDepartment = this.candidates[index].commodity.pssDepartment;
          }
        }
      });
    }
  }


  async editPssDepartment(index) {
    if (!this.isSca()) {
      // todo: no buyer- growl? Determine w/ validation on edits.
      if (!this.candidates[index].buyer || !this.candidates[index].buyer.buyerId) {
        return;
      }

      const subDepartments = await this.lookupService.findSubDepartment(this.candidates[index].commodity.departmentId +
        this.candidates[index].commodity.subDepartmentId).toPromise();

      this.editCandidateModalService.openModal(AttributeTypes.PssDepartment,
        this.candidates[index], {collections: subDepartments[0].pssDepartments}).subscribe(response => {
        if (response) {
          this.candidates[index] = response;
        }
      });
    }
  }

  async editSubCommodity(index) {
    if (!this.isSca()) {
      let subCommodities = [];
      // todo: no buyer or Commodity- growl? Determine w/ validation on edits.
      if (!this.candidates[index].buyer || !this.candidates[index].buyer.buyerId) {
        return;
      } else if (this.candidates[index].commodity && !this.candidates[index].commodity.subCommodityList) {

        const commodities = await this.lookupService.findAllCommoditiesByBuyerId(this.candidates[index].buyer.buyerId).toPromise();
        for (let i = 0; i < commodities.length; i++) {
          if (this.candidates[index].commodity.commodityId.toString() === commodities[i].commodityId) {
            this.candidates[index].commodity.subCommodityList = commodities[i].subCommodityList;
            subCommodities = commodities[i].subCommodityList;
          }
        }
      } else if (this.candidates[index].commodity && this.candidates[index].commodity.subCommodityList) {
        subCommodities = this.candidates[index].commodity.subCommodityList;
      }


      this.editCandidateModalService.openModal(AttributeTypes.SubCommodity, this.candidates[index],
        {collections: subCommodities}).subscribe(response => {
        if (response) {

          let updateTaxCategory = false;
          if (response.subCommodity !== this.candidates[index].subCommodity) {
            updateTaxCategory = true;
          }
          // Dispatch Update
          this.candidates[index] = response;
          if (updateTaxCategory) {
            this.setVertexTaxCategoryModel(index);
          }
          this.updateRegulatoryFields(index);
        }
      });
    }
  }

  setVertexTaxCategoryModel(index) {
    if (!this.isSca()) {
      const vertexId = CandidateHelper.getVertexTaxCategoryNumber(this.candidates[index]);
      if (!vertexId) {
        this.candidates[index].vertexTaxCategory = null;
        this.candidates[index].vertexTaxCategoryModel = null;
        // trigger change detection for vertex tax category.
        this.candidates[index] = JSON.parse(JSON.stringify(this.candidates[index]));
      } else {
        this.lookupService.findVertexTaxCategoryById(vertexId).subscribe((vertexCategory) => {
          this.candidates[index].vertexTaxCategoryModel = vertexCategory;
          this.candidates[index].vertexTaxCategory = null;
        });
      }
    }
  }

  private updateRegulatoryFields(index) {
    if (!this.isSca()) {
      this.candidates[index].foodStamp = CandidateHelper.isFoodStamp(this.candidates[index]);
      this.candidates[index].taxable = CandidateHelper.isTaxCode(this.candidates[index]);
    }
  }

  async editMerchandiseType(index) {
    if (!this.isSca()) {
      const isSellable = this.candidates[index].productType === 'SELLABLE';
      const itemType = CandidateUtilService.getItemType(this.candidates[index]);

      const merchandiseTypes = await this.lookupService.findAllMerchandiseTypes(itemType, isSellable).toPromise();

      this.showEditCandidateModal(index, AttributeTypes.MerchandiseType, {collections: merchandiseTypes});
    }
  }

  editSeasonYear(index, isEditAll?: boolean) {
    if (!this.isSca()) {
      this.editCandidateModalService.openMultiEditModal(
        [
          {type: AttributeTypes.Season},
          {type: AttributeTypes.SeasonYear},
        ], isEditAll ? new Candidate() : this.candidates[index],
        {'isApplyToAll': isEditAll}).subscribe(response => {

        if (response) {
          if (isEditAll) {
            this.candidates.forEach((candidate) => {
              candidate.season = response.season;
              candidate.seasonYear = response.seasonYear;
            });
          } else {
            this.candidates[index] = response;
          }
        }
      });
    }
  }

  async editVertexTaxCategory(index) {
    if (!this.isSca()) {
      if (!this.candidates[index].subCommodity) {
        // todo: no subcom- growl? Determine w/ validation on edits.
        return;
      }
      const defaultTaxCategories = await this.lookupService.findDefaultTaxCategories(this.candidates[index].subCommodity.subCommodityId,
        this.candidates[index].taxable.toString()).toPromise();

      this.editCandidateModalService.openModal(AttributeTypes.VertexTaxCategory, this.candidates[index],
        {collections: defaultTaxCategories},
        {contentStyle: {'max-height': '550px', overflow: 'none'}}
      ).subscribe(response => {
        if (response) {
          // Dispatch Update
          this.candidates[index] = response;
        }
      });
    }
  }


  editPricingType(index, isEditAll?: boolean) {
    if (!this.isSca()) {
      this.editCandidateModalService.openMultiEditModal(
        [
          {type: AttributeTypes.RetailType},
          {type: AttributeTypes.RetailLink, overrides: {validationService: this.candidateService}},
          {type: AttributeTypes.RetailPricing}
        ], isEditAll ? new Candidate() : this.candidates[index],
        {'isApplyToAll': isEditAll}).subscribe(response => {

        if (response) {
          if (isEditAll) {
            this.candidates.forEach((candidate) => {
              candidate.retailType = response.retailType;
              candidate.retailXFor = response.retailXFor;
              candidate.retailPrice = response.retailPrice;
            });
          } else {
            this.candidates[index] = response;
          }
        }
      });
    }
  }

  async editPackageType(index, isEditAll?: boolean) {
    if (!this.isSca()) {
      const packageTypes = await this.lookupService.findAllPackageTypes().toPromise();
      if (isEditAll) {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, AttributeTypes.PackageTypes, ['packageType'], this.isSca(), {collections: packageTypes});
      } else {
        this.showEditCandidateModal(index, AttributeTypes.PackageTypes, {collections: packageTypes});
      }
    }
  }

  async editUnitOfMeasure(index, isEditAll?: boolean) {
    if (!this.isSca()) {
      const unitOfMeasure = await this.lookupService.findAllUnitsOfMeasures().toPromise();

      if (isEditAll) {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, AttributeTypes.UnitOfMeasure, ['unitOfMeasure'], this.isSca(), {collections: unitOfMeasure});
      } else {
        this.showEditCandidateModal(index, AttributeTypes.UnitOfMeasure, {collections: unitOfMeasure});
      }
    }
  }


  getImages(index) {
    if (this.candidates[index].candidateProducts[this.candidateProductIndex].imageLinks &&
      this.candidates[index].candidateProducts[this.candidateProductIndex].imageLinks.length > 0) {
      for (let x = 0; x < this.candidates[index].candidateProducts[this.candidateProductIndex].imageLinks.length; x++) {
        this.candidateService.retrieveCandidateImage(
          this.candidates[index].candidateProducts[this.candidateProductIndex].imageLinks[x].uuid).subscribe(
          result => {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {

              this.uuidToImageMap.set(this.candidates[index].candidateProducts[this.candidateProductIndex].imageLinks[x].uuid,
                fileReader.result.toString().replace('application/json',
                  this.candidates[index].candidateProducts[this.candidateProductIndex].imageLinks[x].type));

              // this.candidates[index].candidateProducts[this.candidateProductIndex].images.push(
              //   fileReader.result.toString().replace('application/json',
              //     this.candidates[index].candidateProducts[this.candidateProductIndex].imageLinks[x].type));
            };
            fileReader.readAsDataURL(result);
          },
          error => {
            this.growlService.addError(error);
          }
        );
      }
    }
  }

  isBuyer() {
    return this.candidateType === 'BUYER_IN_PROGRESS';
  }

  isSca() {
    return this.candidateType === 'SCA_IN_PROGRESS';
  }

  editAllBuyerCommodityAndSubCommodity() {
    this.editCandidateModalService.openMultiEditModal([
      {type: AttributeTypes.Buyer},
      {type: AttributeTypes.Commodity}
    ], new Candidate(), {'isApplyToAll': true}).subscribe();
  }


  onClickHeader(columnHeader) {
    switch (columnHeader) {
      case UploadCandidateProductConstants.UNIT_UPC: {
        break;
      }
      case UploadCandidateProductConstants.PRODUCT_DESCRIPTION:
      case UploadCandidateProductConstants.RECEIPT: {
        this.editProductDescription(null, true);
        break;
      }
      case UploadCandidateProductConstants.SHELF_TAG_1: {
        this.showEditAllCandidateProductModal(this.attributeType.ShelfTag1, 'customerFriendlyDescription1');
        break;
      }
      case UploadCandidateProductConstants.SHELF_TAG_2: {
        this.showEditAllCandidateProductModal(this.attributeType.ShelfTag2, 'customerFriendlyDescription2');
        break;
      }
      case UploadCandidateProductConstants.ECOM_COPY: {
        this.showEditAllCandidateProductModal(this.attributeType.ECommCopy, 'romanceCopy');
        break;
      }
      case UploadCandidateProductConstants.IMAGES: {
        // todo:
        break;
      }
      case UploadCandidateProductConstants.COMMODITY: {
        // todo:
        // this.editAllBuyerCommodityAndSubCommodity();
        break;
      }
      case UploadCandidateProductConstants.SUB_COMMODITY: {
        // todo:
        // this.editAllBuyerCommodityAndSubCommodity();
        break;
      }
      case UploadCandidateProductConstants.MERCHANDISE_TYPE: {
        // todo:
        break;
      }
      case UploadCandidateProductConstants.PSS_DEPARTMENT: {
        // todo:
        break;
      }
      case UploadCandidateProductConstants.SEASON: {
        this.editSeasonYear(null, true);
        break;
      }
      case UploadCandidateProductConstants.LIKE_ITEM_CODE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.LikeItemCode, ['likeId'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.ESTIMATED_STORE_COUNT: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.EstimatedStoreCount, ['numberOfStores'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.TAXABLE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.Taxable, ['taxable'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.TAX_CATEGORY: {
        break;
      }
      case UploadCandidateProductConstants.FOOD_STAMP_ELIGIBLE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.FoodStampEligible, ['foodStamp'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.FSA_ELIGIBLE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.FSAEligible, ['flexibleSpendingAccount'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.PRICING: {
        this.editPricingType(null, true);
        break;
      }
      case UploadCandidateProductConstants.SOLD_BY_WEIGHT: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.SoldByWeight, ['weightSwitch'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.SUGGESTED_RETAIL: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.SuggestedRetail, ['suggestedXFor', 'suggestedRetailPrice'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.MAP_RETAIL: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.MapRetail, ['mapRetail'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.PACKAGE_TYPE: {
        this.editPackageType(null, true);
        break;
      }
      case UploadCandidateProductConstants.DIMENSIONS: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.ProductDimensions,
          ['productHeight', 'productLength', 'productWidth'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.WEIGHT: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.ProductWeight, ['productWeight'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.UNIT_OF_MEASURE: {
        this.editUnitOfMeasure(null, true);
        break;
      }
      case UploadCandidateProductConstants.RETAIL_SIZE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.RetailSize, ['retailSize'], this.isSca());
        break;
      }
      case UploadCandidateProductConstants.TOTAL_VOLUME: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.TotalVolume, ['totalVolume'], this.isSca());
        break;
      }

      // non editable fields.
      case UploadCandidateProductConstants.PENNY_PROFIT:
      case UploadCandidateProductConstants.MARGIN: {
        console.log(columnHeader.header + ' is not editable.');
        break;
      }

      default: {
        this.growlService.addError('Edit all column not implemented: ' + columnHeader.header);
      }
    }
  }

  /**
   * Returns whether or not the supplied candidate has images.
   * @param candidate the candidate.
   */
  hasImages(candidate: Candidate): boolean {
    return candidate && candidate.candidateProducts[this.candidateProductIndex].imageLinks &&
      candidate.candidateProducts[this.candidateProductIndex].imageLinks.length > 0;
  }

  /**
   * Returns the image from the map.
   * @param uuid the uuid.
   */
  getImage(uuid) {
    return this.uuidToImageMap && this.uuidToImageMap.get(uuid);
  }
}
