<pm-editor *ngIf="pharmProductService.candidate && isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-pharm-new-product-flow-stepper *ngIf="pharmProductService.candidate && !pharmProductService.isDsdOnly()"
                                      [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                      [stepIndex]="1"
                                      [isSellable]="candidateUtilService.isSellable(pharmProductService.candidate)">
    </app-pharm-new-product-flow-stepper>
    <app-pharm-new-dsd-product-flow-stepper *ngIf="pharmProductService.candidate && pharmProductService.isDsdOnly()"
                                          [candidateErrors]="pharmProductService.getAllCandidateErrors()"
                                          [stepIndex]="1"
                                          [isSellable]="candidateUtilService.isSellable(pharmProductService.candidate)">
    </app-pharm-new-dsd-product-flow-stepper>
  </pm-header>

  <pm-title>What product are you setting up?</pm-title>

  <div *ngIf="pharmProductService.candidate" class="col-md-10 mt-3">
    <div class="row">
      <label for="descriptionId">Provide a nickname for this so it’s easy to find in PAM. We’ll only use this internally.</label>
    </div>
    <div class="row">
      <input id="descriptionId" pInputText placeholder="Enter a name or short description..." type="text" maxlength="100" minlength="1" [(ngModel)]="pharmProductService.candidate.description" style="width: 80%;">
    </div>
    <div class="row mt-0" *ngIf="candidateError.description">
      <p *ngIf="candidateError.description" class="attribute-error-message">{{candidateError.description}}</p>
    </div>
  </div>

  <div *ngIf="pharmProductService.candidate" class="mt-3">
    <pm-distribution-channel [permissions]="channelPermissions"
                             [(model)]="pharmProductService.candidate"
                             [errorModel]="candidateError"
                             (modelChange)="channelChange()">
    </pm-distribution-channel>
  </div>
  <div *ngIf="pharmProductService.candidate" class="col-md-10 mt-3" style="margin-bottom: 100px;">
    <div class="row">
      <label>Enter the product's UPC or another unique product identification code.</label>
    </div>
    <div class="row">
      <pm-upc-input
        [(upcModel)]="pharmProductService.candidate.candidateProducts[0].upc"
        [(checkDigitModel)]="pharmProductService.candidate.candidateProducts[0].upcCheckDigit"
        [(upcType)]="upcType"
        [readOnly]="pharmProductService.candidate.candidateProducts[0].generatedUpc === true"
        [disabled]="pharmProductService.candidate.candidateProducts[0].generatedUpc === true"
        [readOnlyCheckDigit]="pharmProductService.candidate.candidateProducts[0].generatedUpc === true"
        [hasError]="hasUpcError()"
        placeholderText="Enter UPC"
        checkDigitPlaceholderText="Check #"
        [upcOptions]="upcOptions"
        [state]="upcState"
        (validateUPC)="validateUPC()"
        (upcModelChange)="upcChange()"
        (checkDigitModelChange)="upcChange()"
        (pluRangeModelChange)="onPluRangeModelChange($event)"
        (pluTypeModelChange)="onPluRTypeModelChange($event)"
        [(pluRangeModel)]="this.pharmProductService.getCurrentCandidateProduct().pluRange "
        [(pluTypeModel)]="this.pharmProductService.getCurrentCandidateProduct().pluType"
        (upcTypeChange)="onUpcTypeChange($event)"
        [hideCheckDigit]="isPLU()"
        [hidePlu]="!isPLU()"
        [hideUpcField]="isPLU()"
        [pluRanges]="pluRanges"
        [pluTypes]="pluTypes"
        pluRangePlaceholderText="Select a PLU Range..."
        pluTypePlaceholderText="Select a PLU Type..."
        style="width: 80%;">
      </pm-upc-input>
    </div>
      <p *ngIf="!isPLU()" style="font-size: 12px; color: #7B7B7B;">Don't have a UPC? Generate a <span
        (click)="generate04UPC()" style="color: #0066CC; cursor: pointer;">standard 04UPC</span>.</p>
    <div class="row mb-3" *ngIf="hasUpcError()">
      <p class="error-message">{{candidateProductError.upc}}</p>
    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
