import {CandidateSearchSort} from 'pm-models/lib/candidateSearchSort';

export class CandidateSearchResultsParams {
  searchType: string;
  searchString: string;
  pageNumber: number;
  pageSize: number;
  needsCount: boolean;
  sort: CandidateSearchSort;

  constructor(searchType: string, searchString: string,
              pageNumber: number, pageSize: number,
              needsCount: boolean, sort: CandidateSearchSort) {
    this.searchType = searchType;
    this.searchString = searchString;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.needsCount = needsCount;
    this.sort = sort;
  }
}
