import {Component, Input, OnInit} from '@angular/core';
import {Candidate, CandidateError, CandidateErrorModel, ImageFile, Task, Vendor} from 'pm-models';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../../service/workflow.service';
import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError as observableThrowError} from 'rxjs';

import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {BatchUploadService} from '../../service/batch-upload.service';
import {CandidateErrorUtilService} from '../../service/candidate-error-util.service';

@Component({
  selector: 'app-volume-upload',
  templateUrl: './volume-upload.component.html',
  styleUrls: ['./volume-upload.component.scss']
})
export class VolumeUploadComponent implements OnInit {

  candidateType = Candidate.NEW_PRODUCT_UPLOAD;

  private static readonly VALID_TASKS_TYPES: string[] = [Candidate.NEW_PRODUCT_UPLOAD_PARENT, Candidate.EDI_UPLOAD_PARENT];

  constructor(private router: Router, private workflowService: WorkflowService, private route: ActivatedRoute,
              private candidateService: CandidateService, private batchUploadService: BatchUploadService,
              private candidateErrorUtils: CandidateErrorUtilService) {}

  private taskSubscription$: any;

  public task: Task;
  uploadedCandidateErrorModels: CandidateErrorModel[];
  candidateIdToCandidateErrorMap: Map<number, CandidateError> = new Map<number, CandidateError>();
  parentCandidate: Candidate;
  candidates: Candidate[];
  candidateProductIndex: number;
  uuidToImageMap: Map<string, any>;
  isActivateButtonDisabled = false;
  vendor: Vendor;
  isViewingSupplierSettingsPanel: boolean;
  hasProductDetailErrors: boolean = false;
  hasCaseDetailErrors: boolean = false;
  hasSupplierDetailErrors: boolean = false;
  hasWarehouseDetailErrors: boolean = false;
  hasDsdErrors: boolean = false;

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (params.keys.length > 0 && params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId']).subscribe((task) => {
          this.task = task;
          if (task.name !== Task.PIA_NEW_PRODUCT_FLOW) {
            this.router.navigate(['/tasks'], {
              queryParams: {
                growlMessage: 'Task isn\'t in the PIA Only workflow.',
                growlToUse: GrowlService.SEVERITY_ERROR
              }
            });
          }
          this.initializeData(task.candidateId);

        });
      } else {
        this.router.navigate(['/tasks'], {
          queryParams: {
            growlMessage: 'Task id not supplied.',
            growlToUse: GrowlService.SEVERITY_ERROR
          }
        });
      }
    });
  }

  initializeData(candidateId) {
    this.getCandidateByCandidateId(candidateId).subscribe((parentCandidate) => {
      this.parentCandidate = parentCandidate;
      this.batchUploadService.findSuccessfullyUploadedCandidatesByParentId(candidateId).subscribe((candidates) => {
        if (!candidates || candidates.length === 0) {
          this.candidates = [];
        } else {
          const tempCandidates = [];
          const imageIdsToTypeMap: Map<string, string> = new Map<string, string>();
          this.candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(candidates[0]);
          for (let x = 0; x < candidates.length; x++) {
            if (candidates[x].status === Candidate.IN_PROGRESS) {
              tempCandidates.push(candidates[x]);
              if (candidates[x].candidateProducts[this.candidateProductIndex].imageLinks &&
                candidates[x].candidateProducts[this.candidateProductIndex].imageLinks.length > 0) {
                candidates[x].candidateProducts[this.candidateProductIndex].imageLinks.forEach((image) => {
                  imageIdsToTypeMap.set(image.uuid, image.type);
                });
              }
            }
          }
          this.candidates = tempCandidates;
          this.vendor = this.candidates[0].vendor;
          if (imageIdsToTypeMap.size > 0) {
            this.candidateService.retrieveCandidateImages(Array.from(imageIdsToTypeMap.keys()))
              .subscribe(async (imageFiles: ImageFile[]) => {

                const tempUuidToImageMap: Map<string, any> = new Map<string, any>();

                if (imageFiles && imageFiles.length !== 0) {

                  for (let x = 0; x < imageFiles.length; x++) {
                    const img = 'data:' + imageIdsToTypeMap.get(imageFiles[x].id.trim()) + ';base64,' + imageFiles[x].fileData;
                    tempUuidToImageMap.set(imageFiles[x].id.trim(), img);
                  }
                }
                this.uuidToImageMap = tempUuidToImageMap;
              });
          } else {
            this.uuidToImageMap = new Map<string, any>();
          }
        }
      });

      this.candidateService.findUploadedCandidatesErrorsByParentId(candidateId).subscribe((errors) => {
        this.setUploadedCandidateErrorModels(errors);
      });
      this.candidateService.findValidationErrorsByParentId(candidateId).subscribe((errors) => {
        this.setCandidateIdToCandidateErrorMap(errors);
        this.setCandidateTabErrors();
      });
    });

  }

  /**
   * Returns a candidate by the candidate id.
   * @param candidateId
   */
  private getCandidateByCandidateId(candidateId): Observable<Candidate> {
    return this.candidateService.getCandidate(candidateId).pipe(
      tap(
        (candidate) => {
          if (!candidate.candidateType || !VolumeUploadComponent.VALID_TASKS_TYPES.includes(candidate.candidateType)) {
            const message = 'Invalid Candidate Task Flow : "' + candidate.candidateType + '" expected "' + Candidate.NEW_PRODUCT +
              '" or "' + Candidate.PLU + '"';
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: message, growlToUse: GrowlService.SEVERITY_ERROR}
            });
            return observableThrowError(message);
          } else if (candidate.status !== Candidate.UPLOADED) {
            const message = 'Candidate isn\'t in a viewable state!';
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: message, growlToUse: GrowlService.SEVERITY_ERROR}
            });
            return observableThrowError(message);
          }
          return candidate;
        }
      ),
      catchError(
        (error) => {
          // if there was an error retrieving task, route back to tasks page with the error
          this.router.navigate(['/tasks'], {
            queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
          });
          return observableThrowError(error);

        })
    );
  }

  setCandidateIdToCandidateErrorMap(errors: CandidateErrorModel[]) {
    if (!errors || errors.length === 0) {
      return;
    }

    for (const error of errors) {
      if (error && error.candidateId && error.candidateError) {
        this.candidateIdToCandidateErrorMap.set(error.candidateId, error.candidateError);
      }
    }
  }

  setCandidateTabErrors() {
    const candidateErrors: CandidateError[] = Array.from(this.candidateIdToCandidateErrorMap.values());
    this.hasProductDetailErrors = this.candidateErrorUtils.hasProductDetailErrors(candidateErrors);
    this.hasCaseDetailErrors = this.candidateErrorUtils.hasCaseDetailErrors(candidateErrors);
    this.hasSupplierDetailErrors = this.candidateErrorUtils.hasSupplierDetailErrors(candidateErrors);
    this.hasWarehouseDetailErrors = this.candidateErrorUtils.hasWarehouseDetailErrors(candidateErrors);
    this.hasDsdErrors = this.candidateErrorUtils.hasDsdErrors(candidateErrors);
  }

  setUploadedCandidateErrorModels(errors: CandidateErrorModel[]) {
    if (!errors || errors.length === 0) {
      this.uploadedCandidateErrorModels = [];
      return;
    } else if (errors.length === 1) {
      this.uploadedCandidateErrorModels = errors;
      return;
    }

    let hasAllCandidatesError = false;
    const allCandidatesErrorModel = new CandidateErrorModel();
    allCandidatesErrorModel.isAllCandidatesError = true;

    // Check if the first elements map values are in every other map, if so, remove them and put in "ALL" map.
    Object.keys(errors[0].displayNameToErrorMap).forEach((key: string) => {
      let isAllCandidatesError = true;
      for (let x = 0; x < errors.length; x++) {
        if (!errors[x].displayNameToErrorMap[key] || errors[x].displayNameToErrorMap[key] !==
          errors[0].displayNameToErrorMap[key]) {
          isAllCandidatesError = false;
        }
      }
      if (isAllCandidatesError) {
        hasAllCandidatesError = true;
        allCandidatesErrorModel.displayNameToErrorMap.set(key, errors[0].displayNameToErrorMap[key]);
        // let keyD
        for (let x = 0; x < errors.length; x++) {
          delete errors[x].displayNameToErrorMap[key];
        }
      }
    });

    if (hasAllCandidatesError) {
      errors.push(allCandidatesErrorModel);
    }
    this.uploadedCandidateErrorModels = errors;
  }

  onClickActivate() {
    this.isActivateButtonDisabled = true;
    this.candidateService.saveAllCandidates(this.candidates).subscribe(() => {
      this.batchUploadService.activateCandidate(this.parentCandidate).subscribe(() =>
        this.router.navigate(['/tasks']).then());
    });

    this.router.navigate(['/tasks']).then();
  }

  onClose() {
    this.candidateService.saveAllCandidates(this.candidates).subscribe(() => {
      this.router.navigate(['/tasks']).then();
    });
  }

  onClickDownloadErrors() {
    this.candidateService.exportVolumeUploadErrors(this.parentCandidate.candidateId);
  }

  showSupplierSettingsPanel(event, panel, target) {
    this.isViewingSupplierSettingsPanel = true;
    event.stopPropagation();
    panel.show(event, target);
  }

  hideSupplierSettingsPanel(supplierSettingsOverlay) {
    this.isViewingSupplierSettingsPanel = false;
    supplierSettingsOverlay.hide();
  }

  /**
   * Returns string label to show on upload success.
   */
  getUploadSuccessfulString(): string {
    if (!this.parentCandidate) {
      return '';
    }
    if (this.parentCandidate.candidateType === Candidate.EDI_UPLOAD_PARENT) {
      return 'These products were successfully uploaded via EDI. You can click a value to edit it or click a column ' +
        'header to edit all the values in that column.';
    } else {
      return 'These products were successfully uploaded. You can click a value to edit it or click a column header to ' +
        'edit all the values in that column.';
    }
  }
}
