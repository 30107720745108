import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingCardComponent } from './pricing-card.component';

import { CardModule } from '../../shared/card/card.module';
import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';



@NgModule({
  declarations: [PricingCardComponent],
  exports: [PricingCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule, AttributeDisplayModule
  ]
})
export class PricingCardModule { }
