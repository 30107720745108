<ng-container *ngIf="model">
  <div class="mb-1">
    <label>Is this item sellable?</label>
  </div>
  <div class="col-md-10">
    <div class="row">
      <div class="col-md-4 pl-0 ">
        <div [ngClass]="isSellableDisabledOrReadOnly() ? 'sellable sellable-disabled': 'sellable'">
          <div class="row m-0">
            <p-radioButton [id]="'sellableButton'"
                           class="attribute-radio"
                           [(ngModel)]="model.productType"
                           value="{{SELLABLE}}"
                           (ngModelChange)="onChange($event)">
            </p-radioButton>
            <div>
              <label>Sellable</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div [ngClass]="isNonSellableDisabledOrReadOnly() ? 'sellable sellable-disabled': 'sellable'">
          <div class="row m-0">
            <p-radioButton [id]="'nonSellableButton'"
                           class="attribute-radio"
                           [(ngModel)]="model.productType"
                           value="{{NON_SELLABLE}}"
                           (ngModelChange)="onChange($event)">
            </p-radioButton>
            <div>
              <label>Non-sellable</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row">
      <p *ngIf="errorModel && errorModel?.productType" class="attribute-error-message">
        {{errorModel?.productType}}
      </p>
    </div>
  </div>
</ng-container>
