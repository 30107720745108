import {Component, OnInit, ViewChild} from '@angular/core';
import {
  Attribute,
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  Product,
  Task,
  TaskDecision,
  TextInputType,
  Upc,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {AuthService} from '../auth/auth.service';
import {GrowlService} from '../growl/growl.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkflowService} from '../service/workflow.service';
import {CandidateService} from '../service/candidate.service';
import {LookupService} from '../service/lookup.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {CostService} from '../service/cost.service';
import {FileService} from '../service/file.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {ExistingInner} from 'pm-models/lib/existingInner';
import {AttributeTypes, ReviewComponent, UPCInputState} from 'pm-components';
import {ProductService} from '../service/product.service';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {UserRoleConstants} from '../core/header/user-role-constants';
import {SupplierMrtService} from '../service/supplier-mrt.service';
import {ItemWeightTypeService} from '../service/item-weight-type.service';
import {catchError, finalize, switchMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, throwError} from 'rxjs';
import {UUID} from 'angular2-uuid';
import {CandidateHistoryService} from '../service/candidate-history.service';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';
import {NgxPermissionsService} from 'ngx-permissions';
import {MatUtilService} from '../service/mat-util.service';

@Component({
  selector: 'app-pia-mrt-review',
  templateUrl: './pia-mrt-review.component.html',
  styleUrls: ['./pia-mrt-review.component.scss']
})
export class PiaMrtReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  constructor(public route: ActivatedRoute, public workflowService: WorkflowService,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public fileService: FileService, public productService: ProductService,
              public candidateUtilService: CandidateUtilService, public authService: AuthService,
              public supplierMrtService: SupplierMrtService, private itemWeightTypeService: ItemWeightTypeService,
              public candidateHistoryService: CandidateHistoryService, public permissionService: NgxPermissionsService,
              public matUtilService: MatUtilService) {
  }

  public PROCUREMENT_SUPPORT_TASK_NAME = 'PIA Final Review';
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public PRICE_REQUIRED = 'Price Required';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';

  private taskSubscription$: any;
  private task: Task;
  public isActivateDisabled = false;
  public isRejectDisabled = false;
  public isSaveMrtButtonDisabled = false;
  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  public commodities: any;
  public subCommodities: any;
  public warehouses = [];
  public merchandiseTypes: any;
  public isViewingPage = true;
  public candidateError: CandidateError;
  public warehouseCandidateError: CandidateError;
  public warehouseCandidateProductError: CandidateProductError;
  public candidateProductError: CandidateProductError;
  public orderRestrictions: any[];
  public warehouseData = [];
  public productImages = [];
  public packageTypes: any;
  public unitsOfMeasures: any;
  public mrtExistingInners: ExistingInner[] = [];
  public mrtCandidateInners: CandidateInner[] = [];
  public isViewingProductDetails = false;
  public isViewingCaseDetails = false;
  public isViewingCandidateDetails = false;
  public currentExistingInner: ExistingInner = undefined;
  public currentProduct: Product = undefined;
  public currentUpc: Upc = undefined;
  public currentInnerCandidate: Candidate = undefined;
  public currentInnerCandidateProduct: CandidateProduct = undefined;
  public decimalCount = 1;
  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  public showHistoryPanel: boolean = false;
  public currentHistoryResults: any = undefined;
  public candidateAudits: any = undefined;
  public candidateProductAudits: any = undefined;
  public candidateVendorLanes = [];
  public costOwners: any = [];


  public mrtModalExistingInners: ExistingInner[] = [];
  public mrtModalCandidateInners: CandidateInner[] = [];
  public mrtModalCandidateError: CandidateError;
  public searchedCandidateProduct: CandidateProduct = { id: UUID.UUID() };
  public searchedCandidateProductError: CandidateProductError = new CandidateProductError();

  public upcType = 'UPC';
  public upcState: UPCInputState = UPCInputState.none;
  public upcOptions = [{ label: 'UPC', value: 'UPC' }];

  public isWarehouseMissingResolved = false;
  // undefined if not yet calculated, false if is missing data,
  // true if all data present
  public isNotMissingCandidateInfo: Boolean;
  public isLoadingInners = true;

  public showMatAttributes = false;
  public isLoadingMatData = true;
  public globalAttributes: Attribute[] = [];
  public warehouseItemAttributes: Attribute[] = [];
  public openCommentDrawer: boolean = false;
  public tempCommentHolder: string;

  public requestNewMatAttributeOverrideWrapper = new RequestNewMatAttributeOverrideWrapper();

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => true,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        {label: 'Case', value: this.CASE_ID},
        {label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  quantityConfiguration: AttributeTextInputConfig = {
    label: '# Sellable units',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'quantityId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# Units'
  };

  reasonType: string = Candidate.CORRECTIONS_NEEDED;
  public OTHER = Candidate.OTHER;

  reasonConfiguration: AttributeConfig = {
    label: 'Reason',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    inputGroupClass: 'attribute-radios-block',
    options: [
      { label: Candidate.CORRECTIONS_NEEDED, value: Candidate.CORRECTIONS_NEEDED },
      { label: Candidate.NOT_WANTED, value: Candidate.NOT_WANTED },
      { label: Candidate.OTHER, value: Candidate.OTHER }
    ],
  };

  piaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'piaCommentId',
    maxLength: 4000
  };

  otherReasonMessage: string;

  expandCollapseMissingWarehouseButtonText: string = 'Collapse';
  isExpandedWarehouse: boolean = true;

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.PROCUREMENT_SUPPORT_TASK_NAME) {
              this.router.navigate(['/tasks'], {
                queryParams: {
                  growlMessage: 'Candidate is not in ' + this.PROCUREMENT_SUPPORT_TASK_NAME + ' status.',
                  growlToUse: GrowlService.SEVERITY_ERROR
                }
              }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                this.candidateHistoryService.setInitialValues(candidate);
                this.setInitialValues(candidate);
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
  }


  private setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError = this.candidateError.candidateProductErrors[this.candidateProduct.id];

    this.initializeMrtInners().subscribe();

    this.getOrderRestrictions();
    this.getWarehouses(candidate);
    this.setWarehouseSuppliers();

    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }
    if (candidate.buyer && candidate.buyer.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }
    if (candidate.vendor && candidate.vendor.apNumber !== undefined) {
      this.setVendor(candidate.vendor.apNumber);
    }
    this.setupMatAttributes();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_SHOW_CASE_MAT_ATTRIBUTES-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }

    this.showMatAttributes = true;
    this.matUtilService.updateMatAttributesAndValues(this.candidate, this.globalAttributes, []).pipe(
      tap(() => {
        this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.globalAttributes,
          [], this.warehouseItemAttributes, []);
      }),
      finalize(() => {
        this.isLoadingMatData = false;
      })
    ).subscribe();
  }


  initializeMrtInners(): Observable<any> {
    this.mrtExistingInners = [];
    this.mrtCandidateInners = [];
    this.isNotMissingCandidateInfo = null;
    this.isLoadingInners = true;
    return forkJoin([this.supplierMrtService.setCandidateInnerCandidates(this.candidate),
      this.supplierMrtService.setCandidateExistingInnerProducts(this.candidate)]).pipe(
      tap(() => {
          this.handleExistingInners();
          this.handleActivatedCandidateInners();
          this.handleNonActivatedCandidateInners();
          this.isLoadingInners = false;
        }
      ),
      switchMap(() => this.supplierMrtService.getCandidateCostOwners(this.candidate)),
      tap(costOwners => this.costOwners = costOwners)
    );
  }

  private handleActivatedCandidateInners() {
    const activatedCandidateInners: CandidateInner[] = this.candidate.mrtInfo?.candidateInners
      ?.filter(candidateInner => candidateInner.candidate && candidateInner.candidate.status === Candidate.ACTIVATED);

    if (!!activatedCandidateInners?.length) {
      const upcs: number[] = activatedCandidateInners.map(activatedCandidateInner =>
        activatedCandidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate)].upc);
      this.lookupService.getProductsByUpcs(upcs).pipe(
        tap((products: Product[]) => {
          activatedCandidateInners.forEach(activatedCandidateInner => {
            const index = CandidateUtilService.getCurrentCandidateProductIndex(activatedCandidateInner.candidate);
            const upc = activatedCandidateInner.candidate.candidateProducts[index].upc;
            const existingInner = new ExistingInner();
            existingInner.product = this.productService.getProductByUpc(upc, products);
            existingInner.unitCost = activatedCandidateInner.unitCost;
            existingInner.quantity = activatedCandidateInner.quantity;
            existingInner.upc = upc;
            existingInner.upcCheckDigit = activatedCandidateInner.candidate.candidateProducts[index].upcCheckDigit;
            this.mrtExistingInners.push(existingInner as ExistingInner);
          });
        })
      ).subscribe();
    }
  }

  private handleNonActivatedCandidateInners() {
    let hasRetail = true;
    if (!!this.candidate.mrtInfo?.candidateInners?.length) {
      this.candidate.mrtInfo.candidateInners.forEach(candidateInner => {
        if (candidateInner.candidate && candidateInner.candidate.status !== Candidate.ACTIVATED) {
          if (!candidateInner.candidate.retailType) {
            candidateInner.candidate.retailType = CostService.KEY_RETAIL;
          }
          // if the candidate is non replenishable, the unit cost is never calculated by supplier
          // due to lack of fields. So use the value from the mrt.
          if (!candidateInner.replenishable) {
            candidateInner.candidate.unitCost = candidateInner.unitCost;
            this.mrtCandidateInners.push(candidateInner as CandidateInner);
          } else {
            this.mrtCandidateInners.unshift(candidateInner as CandidateInner);
          }
          // if the candidate doesn't have retail, set isNotMissingCandidateInfo to false.
          if (candidateInner.candidate.retailType === CostService.PRICE_REQUIRED || !candidateInner.candidate.retailPrice || !candidateInner.candidate.retailXFor) {
            hasRetail = false;
          }
        }
      });
    }
    this.isNotMissingCandidateInfo = hasRetail;
  }

  private handleExistingInners() {
    if (!!this.candidate.mrtInfo?.existingInners?.length) {
      this.candidate.mrtInfo.existingInners.forEach(existingInner => {
        this.mrtExistingInners.push(existingInner);
      });
    }
  }


  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe(data => {
      this.commodities = data;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.originalCandidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.originalCandidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      if (!this.originalCandidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });

  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }


  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }


  getReviewClass(): string {
    let classString = '';
    if (this.isViewingProductDetails || this.isViewingCandidateDetails) {
      classString += ' review-grid-container';
    }
    if (!this.isShowingNormalFooter()) {
      classString += 'pending-footer';
    }
    return classString;
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidate.candidateProducts[0] = this.candidateProduct;
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.router.navigate(['/tasks']);
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']);
    }
  }


  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }


  getMasterPack() {
    let masterPack = 0;
    if (this.candidate.mrtInfo.existingInners && this.candidate.mrtInfo.existingInners.length > 0) {
      for (let x = 0; x < this.candidate.mrtInfo.existingInners.length; x++) {
        masterPack += this.candidate.mrtInfo.existingInners[x].quantity;
      }
    }
    if (this.candidate.mrtInfo.candidateInners && this.candidate.mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.candidate.mrtInfo.candidateInners.length; x++) {
        masterPack += this.candidate.mrtInfo.candidateInners[x].quantity;
      }
    }
    return masterPack;
  }


  collapse() {
    this.warehouseData = [];
    this.pmReview.closeDrawer();
  }


  /**
   * Saves candidate.
   */
  update() {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.candidateProducts[0].warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.candidateService.validateCandidate(tempCandidate, [CandidateValidatorType.WAREHOUSE_VALIDATOR,
      CandidateValidatorType.BICEP_VALIDATOR])
      .subscribe(() => {
        this.candidateProduct.warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
        this.candidateService.saveCandidate(this.candidate, true).subscribe(savedCandidate => {
          this.setOriginalAndCurrentCandidate(savedCandidate);
          this.collapse();
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.warehouseCandidateError = error.error.candidateErrors;
          this.warehouseCandidateProductError = this.warehouseCandidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message); // TODO: new way to handle server side errors?
        }
      });
  }


  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }


  /**
   * Saves candidate.
   */
  save() {
    this.candidateProduct.warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.setWarehouseData();
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, don't add to warehouse list.
    if (this.candidateProduct.warehouses && this.candidateProduct.warehouses.length > 0) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }


  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }


  openWarehouseDrawer() {
    this.isViewingCaseDetails = true;
    this.isViewingProductDetails = false;
    this.isViewingCandidateDetails = false;
    this.getWarehouses(this.candidate);
    this.pmReview.openDrawer();
  }

  viewProductDetails(existingInner: ExistingInner) {

    this.lookupService.getUpc(existingInner.upc).subscribe((upc) => {
      this.currentUpc = upc;
    });

    this.currentExistingInner = existingInner;
    this.currentProduct = existingInner.product;
    this.currentInnerCandidate = null;
    this.currentInnerCandidateProduct = null;
    this.isViewingCaseDetails = false;
    this.isViewingProductDetails = true;
    this.isViewingCandidateDetails = false;
    this.pmReview.openDrawer();
  }

  viewCandidateDetails(candidateInner: CandidateInner) {

    this.isViewingCaseDetails = false;
    this.isViewingProductDetails = false;
    this.isViewingCandidateDetails = true;
    this.currentExistingInner = undefined;
    this.currentProduct = undefined;
    this.currentInnerCandidate = candidateInner.candidate;
    this.currentInnerCandidateProduct = candidateInner.candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidateInner.candidate)];
    this.pmReview.openDrawer();
  }


  showRejectPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  reject(event, panel, target) {
    this.isActivateDisabled = true;
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;
    this.workflowService.addProcessVariableViewed(this.task.processInstanceId, false).subscribe(() => {
      this.saveAndCompleteTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE,
        TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION,
        'Successfully rejected candidate');
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
      this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], {queryParams: {growlMessage: growlMessage}}).then(() => {
          this.isActivateDisabled = false;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
        this.isActivateDisabled = false;
      });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   */
  onClickActivate() {
    this.isActivateDisabled = true;
    this.isRejectDisabled = true;
    this.candidate.candidateProducts[0] = this.candidateProduct;

    this.workflowService.updateApNumber(
      this.candidate.vendor ? this.candidate.vendor.apNumber : null,
      this.task.processInstanceId).subscribe(() => {

      this.candidateService.validateCandidate(this.candidate,
        [CandidateValidatorType.PIA_MRT_REVIEW_VALIDATOR])
        .subscribe(() => {
          this.candidateService.activateCandidate(this.candidate).subscribe(() => {
            this.completeTaskAndRouteToTasksPage(
              WorkflowService.ACTION_COMPLETE,
              TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
              'Successfully activated candidate.'
            );
          }, (error) => {
            this.growlService.addError(error);
            this.isActivateDisabled = false;
            this.isRejectDisabled = false;
          });
        }, (error) => {
          if (error.error.candidateErrors) {
            this.candidateError = error.error.candidateErrors;
            this.candidateProductError = this.candidateError.candidateProductErrors[this.candidateProduct.id];
          } else {
            this.growlService.addError(error.message); // TODO: new way to handle server side errors?
          }
          this.isActivateDisabled = false;
          this.isRejectDisabled = false;
        });
    }, (error) => {
      this.growlService.addError(error);
      this.isActivateDisabled = false;
      this.isRejectDisabled = false;
    });
  }

  /**
   * Sets available selectable cost owners
   * @param vendorId the vendorId id.
   */
  private setVendor(vendorId) {
    this.lookupService.findVendor(vendorId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.vendor.apNumber === data[index].apNumber) {
          this.candidateVendorLanes = data[index].lanes;
          break;
        }
      }
    });
  }

  removeWarehouse(index) {
    const warehouse = this.candidateProduct.warehouses.splice(index, 1).pop();
    if (this.warehouseData && this.warehouses) {
      this.addRemovedWarehouseToDrawer(+warehouse.warehouseId);
    }
  }

  addRemovedWarehouseToDrawer(warehouseId) {
    for (let x = 0; x < this.warehouses.length; x++) {
      if (this.warehouses[x].warehouseId === warehouseId) {
        this.warehouseData.push(this.warehouses[x]);
        this.warehouseData[this.warehouseData.length - 1].orderUnitConfig = this.getOrderUnitConfiguration(warehouseId);
        break;
      }
    }
  }

  editWarehouseSupplier() {
    this.editCandidateModalService.openModal(AttributeTypes.Warehouse, this.candidate,
      {collections: this.candidateVendorLanes}).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidate = response;
        this.resetWarehouseData();
      }
    });
  }

  private resetWarehouseData() {
    if (this.candidate.lane.id !== null && this.candidate.lane.id !== undefined) {
      this.lookupService.findWarehousesByVendorApNumberAndLaneId(this.candidate.vendor.apNumber,
        this.candidate.lane.id).subscribe(warehouses => {
        this.warehouses = warehouses;
        this.setWarehouseData();
      });
    } else {
      this.warehouses = [];
      this.warehouseData = [];
      this.candidateProduct.warehouses = [];
      this.setWarehouseData();
    }
  }

  /**
   * Sets available selectable cost owners
   * @param vendorId the vendorId id.
   */
  private setWarehouseSuppliers() {
    this.lookupService.findSingleVendor(this.candidate.vendor.apNumber).subscribe(data => {
      this.candidateVendorLanes = data.lanes;
    });
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, JSON.parse(JSON.stringify(this.candidate)), overrides).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidate = response;
        if (type === AttributeTypes.CaseUPC) {
          const currentCandidateProduct = CandidateUtilService.getCurrentCandidateProduct(response);
          this.candidateProduct.caseUpc = currentCandidateProduct.caseUpc;
          this.candidateProduct.caseUpcCheckDigit = currentCandidateProduct.caseUpcCheckDigit;
        }
      }
    });
  }

  editWarehouse(warehouse, type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, warehouse, overrides).subscribe(response => {
      if (response) {
        // Dispatch Update
        const warehouseIndex = this.candidateProduct.warehouses.findIndex(x => x.warehouseId === warehouse.warehouseId);
        this.candidateProduct.warehouses[warehouseIndex] = response;
      }
    });
  }

  editOrderRestrictions(warehouse) {
    this.editWarehouse(warehouse, AttributeTypes.WarehouseOrderRestriction, {collections: this.orderRestrictions});
  }

  get attributeType() {
    return AttributeTypes;
  }

  getMasterDimensions() {
    const masterLength = this.currentInnerCandidate.masterLength ? this.currentInnerCandidate.masterLength : '0';
    const masterWidth = this.currentInnerCandidate.masterWidth ? this.currentInnerCandidate.masterWidth : '0';
    const masterHeight = this.currentInnerCandidate.masterHeight ? this.currentInnerCandidate.masterHeight : '0';
    return masterLength + 'in x ' + masterWidth + 'in x ' + masterHeight + 'in';
  }

  getProductDimensions() {
    const length = this.currentInnerCandidate.productLength ? this.currentInnerCandidate.productLength : '0';
    const width = this.currentInnerCandidate.productWidth ? this.currentInnerCandidate.productWidth : '0';
    const height = this.currentInnerCandidate.productHeight ? this.currentInnerCandidate.productHeight : '0';
    return length + 'in x ' + width + 'in x ' + height + 'in';
  }

  showEditCandidateProductModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidateProduct = response;
      }
    });
  }

  editCaseUPC() {
    this.showEditCandidateModal(AttributeTypes.CaseUPC, {
      validationService: this.candidateService
    });
  }


  editBuyer() {
    this.editCandidateModalService.openModal(AttributeTypes.Buyer, this.candidate).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidate = response;
        this.candidate.commodity = null;
        this.candidate.subCommodity = null;
        if (response.buyer && response.buyer.buyerId) {
          this.setCommoditiesAndSubCommodities(response.buyer.buyerId);
        } else {
          this.commodities = [];
          this.subCommodities = [];
        }
      }
    });
  }

  editSeasonYear() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.Season},
        {type: AttributeTypes.SeasonYear},
      ], this.candidate).subscribe(response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  /**
   * Returns whether a buyer is tied to departments that are variable or catch weight departments (2, 6, 9).
   */
  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.candidate.commodity && this.candidate.commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.candidate.commodity.departmentId);
    } else {
      return false;
    }
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  getActivateButtonTitle() {
    if (this.isActivateDisabled) {
      return 'Activating';
    } else {
      return 'Activate';
    }
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }


  getCostDisplay() {
    // if we haven't deduced whether there's missing information, return empty string
    if (this.isNotMissingCandidateInfo === null || this.isNotMissingCandidateInfo === undefined) {
      return '';
      // if we're missing information, return message.
    } else if (this.isNotMissingCandidateInfo === false) {
      return 'We can’t calculate margin and penny profit because we’re missing retail information for one or more of the UPCs above.';
    }
  }

  getPennyProfit() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.candidate.masterListCost);

    if (!pennyProfit) {
      return '';
    }
    return this.costService.toCurrency(pennyProfit);
  }

  getMarginPercent() {
    const masterSuggestedRetail = this.candidateUtilService.getMRTMasterSuggestedRetail(this.mrtExistingInners, this.mrtCandidateInners);
    if (!masterSuggestedRetail) {
      return '';
    }
    const pennyProfit = this.candidateUtilService.getMRTPennyProfit(masterSuggestedRetail,
      this.candidate.masterListCost);

    if (!pennyProfit) {
      return '';
    }
    const marginPercent = this.candidateUtilService.getMRTMarginPercent(pennyProfit, masterSuggestedRetail);
    return marginPercent.toPrecision(6);
  }

  isMarginNegativeOrZero() {
    const margin = +this.getMarginPercent();
    return margin <= 0;
  }

  toLowerCase(status: String) {
    return status.toLowerCase();
  }

  toggleResolved() {
    this.isWarehouseMissingResolved = !this.isWarehouseMissingResolved;

    if (this.isWarehouseMissingResolved) {
      this.candidate.missingWarehousesResolveUser = this.authService.getUser();
    } else {
      this.candidate.missingWarehousesResolveUser = null;
    }
  }

  toggleExpandCollapseMissingWarehouse() {
    if (this.expandCollapseMissingWarehouseButtonText === 'Collapse') {
      this.expandCollapseMissingWarehouseButtonText = 'Expand';
      this.isExpandedWarehouse = false;
    } else {
      this.expandCollapseMissingWarehouseButtonText = 'Collapse';
      this.isExpandedWarehouse = true;
    }
  }

  isActivateButtonDisabled() {
    return this.candidate.missingWarehouses && !this.isWarehouseMissingResolved;
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  hasPendingInner(): boolean {
    let result = false;
    for (let x = 0; x < this.mrtCandidateInners.length; x++) {
      if (this.mrtCandidateInners[x].candidate.status === 'IN_PROGRESS') {
        result = true;
        break;
      }
    }
    return result;
  }

  hasPendingReplenishableInner(): boolean {
    for (let x = 0; x < this.mrtCandidateInners.length; x++) {
      if (this.mrtCandidateInners[x].replenishable && this.mrtCandidateInners[x].candidate.status === 'IN_PROGRESS') {
        return true;
      }
    }
    return false;
  }

  isShowingPendingFooter(): boolean {
    if (!this.candidate) {
      return false;
    }
    return this.hasPendingInner();
  }

  isShowingNormalFooter(): boolean {
    if (!this.candidate) {
      return false;
    }
    return !this.hasPendingInner();
  }

  onClickReturnToTaskPage() {
    this.onClose();
  }

  reasonTypeChange($event: any) {

  }

  /**
   * Returns true if there's no reason type, or the reason type is OTHER and the message is null, undefined or empty (not all spaces).
   */
  isRejectButtonDisabled(): boolean {
    return this.isRejectDisabled || !this.reasonType || (this.reasonType === Candidate.OTHER &&
      (!this.otherReasonMessage || !this.otherReasonMessage.trim()));
  }

  rejectMrtAndNewItems(panel) {
    panel.hide();
    this.isRejectDisabled = true;
    this.candidate.vendorComment = this.getRejectMessage();
    this.candidate.status = Candidate.DECLINED;
    for (let x = 0; x < this.mrtCandidateInners.length; x++) {

      // if the non replenish is approved, save declined status and reject reason.
      if (this.mrtCandidateInners[x].candidate.status === Candidate.APPROVED && !this.mrtCandidateInners[x].replenishable) {
        this.mrtCandidateInners[x].candidate.status = Candidate.DECLINED;
        this.mrtCandidateInners[x].candidate.vendorComment = this.candidate.vendorComment;
        this.candidateService.saveCandidate(this.mrtCandidateInners[x].candidate).subscribe(savedCandidate => {
          this.workflowService.updateNonReplenishableCompletedEndStateToRejected(
            this.mrtCandidateInners[x].candidate.candidateId).subscribe(() => {
            // if it's the last iteration, save and reject MRT.
            if (x === this.mrtCandidateInners.length - 1) {
              this.saveAndCompleteTaskAndRouteToTasksPage(
                WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
            }
          });
        });
        continue;
        // if the replenishable is completed, then it will be shortly activated and we cannot reject it at this point.
      } else if (this.mrtCandidateInners[x].candidate.status === Candidate.COMPLETED && this.mrtCandidateInners[x].replenishable) {
        if (x === this.mrtCandidateInners.length - 1) {
          this.saveAndCompleteTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
        }
        continue;
      }

      this.mrtCandidateInners[x].candidate.status = Candidate.DECLINED;
      this.mrtCandidateInners[x].candidate.vendorComment = this.getRejectMessage();
      this.candidateService.saveCandidate(this.mrtCandidateInners[x].candidate).subscribe(savedCandidate => {
        this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.mrtCandidateInners[x].candidateId)
          .subscribe(task => {
            // non replenishables can only be in buyer/pia stage
            if (task.name === 'Key Buyer Data') {
              this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).subscribe(() => {
                  // reject MRT last.
                  if (x === this.mrtCandidateInners.length - 1) {
                    this.saveAndCompleteTaskAndRouteToTasksPage(
                      WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
                  }
                }
              );
            } else if (task.name === 'PIA Final Review') {
              this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe(() => {
                  // reject MRT last.
                  if (x === this.mrtCandidateInners.length - 1) {
                    this.saveAndCompleteTaskAndRouteToTasksPage(
                      WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
                  }
                }
              );
              // replenishables only
            } else if (task.name === 'Assign Warehouse') {
              // if it's an sca task, move to next, then reject from PIA.
              this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE)
                .subscribe(() => {
                  this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.mrtCandidateInners[x].candidateId)
                    .subscribe(piaTask => {
                      this.workflowService.completeTaskWithAction(piaTask, WorkflowService.ACTION_COMPLETE,
                        TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe(() => {
                          // reject MRT last.
                          if (x === this.mrtCandidateInners.length - 1) {
                            this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE,
                              TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
                          }
                        }
                      );
                    });
                });
            }
          });
      });
    }
  }

  getRejectMessage(): string {
    if (this.reasonType === Candidate.OTHER) {
      return this.otherReasonMessage;
    } else {
      return this.reasonType;
    }
  }

  rejectMrt() {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = this.getRejectMessage();
    this.candidate.status = Candidate.DECLINED;
    this.rejectNonReplenishablesAndMrt();
  }

  rejectMrtAndCloseModal(event, panel, target) {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;
    this.rejectNonReplenishablesAndMrt();
    panel.hide();
  }

  async rejectNonReplenishablesAndMrt() {
    if (!this.candidate.mrtInfo.candidateInners || this.candidate.mrtInfo.candidateInners.length === 0) {
      this.saveAndCompleteTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
      return;
    }

    for (let x = 0; x < this.candidate.mrtInfo.candidateInners.length; x++) {
      if (!this.candidate.mrtInfo.candidateInners[x].replenishable) {
        if (this.candidate.mrtInfo.candidateInners[x].candidate.status === Candidate.APPROVED) {
          this.candidate.mrtInfo.candidateInners[x].candidate.status = Candidate.DECLINED;
          this.candidate.mrtInfo.candidateInners[x].candidate.vendorComment = this.candidate.vendorComment;
          await this.candidateService.saveCandidate(this.candidate.mrtInfo.candidateInners[x].candidate).toPromise();
          await this.workflowService.updateNonReplenishableCompletedEndStateToRejected(
            this.candidate.mrtInfo.candidateInners[x].candidateId).toPromise();
        } else {
          this.candidate.mrtInfo.candidateInners[x].candidate.status = Candidate.DECLINED;
          this.candidate.mrtInfo.candidateInners[x].candidate.vendorComment = this.candidate.vendorComment;
          await this.candidateService.saveCandidate(this.candidate.mrtInfo.candidateInners[x].candidate).toPromise();
          const task = await this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(
            this.candidate.mrtInfo.candidateInners[x].candidateId).toPromise();
          // non replenishables can only be in buyer/pia stage
          if (task.name === 'Key Buyer Data') {
            await this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
              TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).toPromise();
          } else if (task.name === 'PIA Final Review') {
            await this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
              TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).toPromise();
          }
        }
      }
      // reject MRT last.
      if (x === this.candidate.mrtInfo.candidateInners.length - 1) {
        this.saveAndCompleteTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
      }
    }
  }



  onClickEditMrtItems(event, panel, target) {
    this.searchedCandidateProduct = { id: UUID.UUID()};
    this.searchedCandidateProductError = new CandidateProductError();
    this.mrtModalCandidateError = undefined;
    this.mrtModalExistingInners = [];
    this.mrtModalCandidateInners = [];
    this.upcState = UPCInputState.none;
    if (this.mrtExistingInners && this.mrtExistingInners.length > 0) {
      this.mrtExistingInners.forEach(val => this.mrtModalExistingInners.push(Object.assign({}, val)));
    }
    if (this.mrtCandidateInners && this.mrtCandidateInners.length > 0) {
      this.mrtCandidateInners.forEach(val => this.mrtModalCandidateInners.push(Object.assign({}, val)));
    }
    event.stopPropagation();
    panel.show(event, target);
  }



  cancelEditMrtItems(op) {
    op.hide();
    this.mrtModalCandidateError = undefined;
    this.mrtModalCandidateInners = [];
    this.mrtModalExistingInners = [];
  }

  /**
   * Saves candidate.
   */
  saveMrtAndCloseModal(overlayPanel) {
    this.isSaveMrtButtonDisabled = true;
    const toValidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    toValidate.mrtInfo.candidateInners = this.mrtModalCandidateInners;
    toValidate.mrtInfo.existingInners = this.mrtModalExistingInners;
    const updateMrtInnerRequest =
      this.supplierMrtService.getUpdateMrtInnerRequest(this.candidate, this.mrtModalExistingInners, this.mrtModalCandidateInners);


    this.candidateService.validateCandidate(toValidate, [CandidateValidatorType.SUPPLIER_SETUP_MRT_ITEMS_VALIDATOR]).pipe(
      switchMap((validatedCandidate) =>  this.candidateService.updateMrtInners(updateMrtInnerRequest)),
      tap((updatedMrtCandidate: Candidate) => {
        this.candidate = updatedMrtCandidate;
        this.isSaveMrtButtonDisabled = false;
        this.mrtModalCandidateError = undefined;
        overlayPanel.hide();
      }),
      switchMap(() => this.initializeMrtInners()),
      catchError((error) => {
        this.isSaveMrtButtonDisabled = false;
        if (error.error?.candidateErrors) {
          this.mrtModalCandidateError = error.error.candidateErrors;
        } else {
          this.growlService.addError(error);
        }
        return throwError(error);
      })
    ).subscribe();
  }


  hasError(): boolean {
    return !!this.searchedCandidateProductError?.upc || !!this.searchedCandidateProductError?.upcCheckDigit;
  }



  setUpcAndValidate() {
    if (!this.searchedCandidateProduct.upc || this.searchedCandidateProduct.upcCheckDigit === null || this.searchedCandidateProduct.upcCheckDigit === undefined) {
      this.upcState = UPCInputState.none;
      this.searchedCandidateProductError.upc = null;
      this.searchedCandidateProductError.upcCheckDigit = null;
    } else {
      this.validateUPC();
    }
  }


  validateUPC() {
    this.upcState = UPCInputState.loading;
    this.searchedCandidateProduct.availableUpc = null;
    this.searchedCandidateProduct.existingUpc = null;

    if (this.mrtModalExistingInners.some(inner => inner.upc === this.searchedCandidateProduct.upc)) {
      this.upcState = UPCInputState.invalid;
      this.searchedCandidateProductError.upc = 'Provided UPC is already a part of this MRT.';
      return;
    }

    const validationCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    validationCandidate.candidateProducts = [this.searchedCandidateProduct];
    this.candidateService.validateCandidate(validationCandidate,
      [CandidateValidatorType.MRT_UPC_VALIDATOR]).subscribe((candidate: Candidate) => {

      if (candidate.candidateProducts[0].existingUpc) {
        this.upcState = UPCInputState.valid;
        this.searchedCandidateProduct = candidate.candidateProducts[0];
        this.searchedCandidateProductError.upc = null;
        this.searchedCandidateProductError.upcCheckDigit = null;
      } else {
        this.upcState = UPCInputState.invalid;
        this.searchedCandidateProductError.upc = 'UPC not activated. You can only add activated UPCs to this MRT.';
      }

    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        this.searchedCandidateProductError = returnedCandidateError.candidateProductErrors[this.searchedCandidateProduct.id];
      } else {
        this.growlService.addError(error.message);
      }
    });
  }


  addItem() {
    if (!this.upcState || this.upcState !== UPCInputState.valid) {
      return;
    }

    const candidateProductToUse = JSON.parse(JSON.stringify(this.searchedCandidateProduct));
    this.resetCandidateProductAndUpcSearchState();

    if (candidateProductToUse.existingUpc) {
      this.addExistingInner(candidateProductToUse);
      this.selectUpcInput();
    }
  }


  resetCandidateProductAndUpcSearchState() {
    this.searchedCandidateProduct = { id: UUID.UUID()};
    this.upcState = UPCInputState.none;
  }

  selectUpcInput() {
    window.getSelection().removeAllRanges();
    const selector: HTMLInputElement = document.getElementsByClassName('upc-input')[0] as HTMLInputElement;
    selector.select();
  }

  deleteExistingInner(index) {
    this.mrtModalExistingInners.splice(index, 1);
  }
  deleteCandidateInner(index) {
    this.mrtModalCandidateInners.splice(index, 1);
  }

  addExistingInner(candidateProduct: CandidateProduct) {
    const existingInner = new ExistingInner();
    existingInner.upc = candidateProduct.upc;
    existingInner.upcCheckDigit = candidateProduct.upcCheckDigit;

    this.lookupService.getProductByUpcAndApNumbers(candidateProduct.upc, []).subscribe((productData) => {
      existingInner.product = productData;
      this.mrtModalExistingInners.push(existingInner);
    });
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.piaComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.showHistoryPanel = false;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.piaComment = this.tempCommentHolder;
    this.candidate.piaCommentUser = this.authService.getUser();
    this.openCommentDrawer = false;
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  addAttachments(event) {
    this.candidate.attachments = event;
    this.save();
  }
}
