<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4">

  </div>
</div>

<ng-template #card>
  <pm-card title="Retail pricing" subtitle="Tell us about the retail pricing for this product.">
    <ng-container *ngIf="model">
      <pm-attribute-display-dual-numeric-input
        [attribute]="getSuggestedRetailConfiguration()"
        [(firstInputModel)]="model.suggestedXFor"
        [(secondInputModel)]="model.suggestedRetailPrice"
        [firstInputModelError]="errorModel?.suggestedXFor"
        [secondInputModelError]="errorModel?.suggestedRetailPrice">
      </pm-attribute-display-dual-numeric-input>
      <pm-attribute-display-checkbox [attribute]="prePriceConfiguration" [(model)]="model.prePrice"
        (change)="prePriceChange($event)">
      </pm-attribute-display-checkbox>

      <pm-attribute-display-text-input [attribute]="mapPricingConfiguration" [(model)]="model.mapRetail"
      [attributeError]="errorModel?.mapRetail" (change)="mapPricingChange($event)">
    </pm-attribute-display-text-input>

    </ng-container>

  </pm-card>
</ng-template>
