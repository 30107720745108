import {Component, OnInit} from '@angular/core';
import {
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  HEBDetailsCardPermissions,
  SupplierDetailsCardPermissions
} from 'pm-models';
import {PiaProductService} from '../../service/pia-product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {CandidateService} from '../../service/candidate.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {OwnBrandService} from '../../service/ownbrand.service';
import {PiaNewProductFlowStepperComponent} from '../pia-new-product-flow-stepper/pia-new-product-flow-stepper.component';
import {PiaNewDsdProductFlowStepperComponent} from '../pia-new-dsd-product-flow-stepper/pia-new-dsd-product-flow-stepper.component';

@Component({
  selector: 'app-pia-new-product-supplier-details',
  templateUrl: './pia-new-product-supplier-details.component.html',
  styleUrls: ['./pia-new-product-supplier-details.component.scss']
})
export class PiaNewProductSupplierDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private piaProductService: PiaProductService,
              private authService: AuthService, private candidateService: CandidateService,
              private candidateUtilService: CandidateUtilService, public ownBrandService: OwnBrandService) { }

  hebDetailsCardPermissions: HEBDetailsCardPermissions = {
    isReadOnly: false,
    productType: {
      isHidden: true
    },
    merchandiseType: {
      isHidden: true
    }
  };

  readonly supplierDetailsCardPermissions: SupplierDetailsCardPermissions = {
    isReadOnly: false
  };

  public isViewingPage = true;
  private taskSubscription$: any;

  public candidate: Candidate;
  public currentCandidateProduct: CandidateProduct;

  public originalCandidate: Candidate;

  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public canClickNext = true;

  ngOnInit() {

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.piaProductService.getTaskId(), this.piaProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.piaProductService.resetService();
        this.piaProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.candidate = this.piaProductService.getCandidate();
            this.originalCandidate = JSON.parse(JSON.stringify(candidate));
            this.candidateError = this.piaProductService.getSupplierDetailsError();
            this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
            this.currentCandidateProduct = this.piaProductService.getCurrentCandidateProduct();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.piaProductService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.candidate = this.piaProductService.getCandidate();
              this.originalCandidate = this.piaProductService.getOriginalCandidate();
              this.currentCandidateProduct = this.piaProductService.getCurrentCandidateProduct();
              this.candidateError = this.piaProductService.getSupplierDetailsError();
              this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
              this.candidate.contactName = this.authService.getUser();
            } else {
              this.piaProductService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  onClose() {
    this.isViewingPage = false;
    this.piaProductService.saveCandidateAndClose();
  }

  onMerchandiseTypeChange(event) {
  }

  onBuyerChange(event) {
    this.piaProductService.updateBuyerCallBack(event);
  }

  onSupplierChange(event) {
    if (event.apNumber !== this.originalCandidate.vendor.apNumber) {
      this.currentCandidateProduct.warehouses = [];
      this.candidate.candidateProducts[0].locationGroupStores = [];
      this.piaProductService.setAuthGroups(null);
      this.piaProductService.setSelectedAuthGroups(null);
      this.piaProductService.setNotFoundStores(null);
    }
  }

  onClickBack() {
    this.piaProductService.saveCandidateAndNavigate('/piaSetupNewProduct', this.hasVendorChanged());
  }


  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.originalCandidate.vendor) !== JSON.stringify(this.candidate.vendor);
  }


  onClickNext() {
    this.canClickNext = false;
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR]).toPromise().then(data => {
      this.candidateError = this.piaProductService.setSupplierDetailsError(new CandidateError());
      if (this.piaProductService.getCurrentCandidateProduct().generatedUpc) {
        this.ownBrandService.updateReservation(this.candidate).subscribe();
      }
      this.piaProductService.saveCandidateAndNavigate('/piaNewProductDetails', this.hasVendorChanged());
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = this.piaProductService.setSupplierDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.piaProductService.getCurrentCandidateProductError(this.candidateError);
      }
      if (this.piaProductService.getCurrentCandidateProduct().generatedUpc) {
        this.ownBrandService.updateReservation(this.candidate).subscribe();
      }
      this.piaProductService.saveCandidateAndNavigate('/piaNewProductDetails', this.hasVendorChanged());
    });
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaNewProductFlowStepperComponent.UPC_ITEM_CODE: {
        this.onClickBack();
        break;
      }
      case PiaNewProductFlowStepperComponent.SUPPLIER_HEB_SETUP: {
        this.jumpToPage('/piaNewProductSupplierDetails', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.PRODUCT_DETAILS: {
        this.onClickNext();
        break;
      }
      case PiaNewProductFlowStepperComponent.CASE_PACK: {
        this.jumpToPage('/piaNewProductCaseDetails', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewDsdProductFlowStepperComponent.STORE_AUTHORIZATION: {
        if (!this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.piaProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.jumpToPage('/piaStoreAuthorization', PiaProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/piaSetUpStores', PiaProductService.DSD_VALIDATORS);
        }
        break;
      }
      case PiaNewProductFlowStepperComponent.WAREHOUSE: {
        this.jumpToPage('/piaNewProductWarehouses', PiaProductService.BASE_VALIDATORS);
        break;
      }
      case PiaNewProductFlowStepperComponent.EXTENDED_ATTRIBUTES: {
        if (this.piaProductService.isDsdOnly()) {
          this.jumpToPage('/piaNewProductExtendedAttributes', PiaProductService.DSD_VALIDATORS);
        } else {
          this.jumpToPage('/piaNewProductExtendedAttributes', PiaProductService.BASE_VALIDATORS);
        }
        break;
      }
    }
  }

  jumpToPage(urlToNavigate, validator) {
    // validate the candidate and display errors if any
    this.candidateService.validateCandidate(this.piaProductService.getCandidate(),
      [validator]).toPromise().then(data => {
      this.candidateError = this.piaProductService.setSetupCandidateError(new CandidateError());
      // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
      this.piaProductService.setCandidate(this.piaProductService.getCandidate());
      this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);

    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError =
          this.candidateError.candidateProductErrors[this.piaProductService.getCurrentCandidateProduct().id];
        this.piaProductService.setSetupCandidateError(this.candidateError);
        if (!this.piaProductService.isDsdOnly() || !PiaProductService.DSD_NON_NAVIGABLE_ON_ERROR_PAGES.includes(urlToNavigate)) {
          this.piaProductService.saveCandidateAndNavigate(urlToNavigate, false);
        }
      }
    });
  }
}
