import {NgModule} from '@angular/core';
import {CardModule} from '../../shared/card/card.module';
import {AttributeDisplayModule} from '../../shared/attribute-display/attribute-display.module';
import {CommonModule} from '@angular/common';
import {MatCategoriesCardComponent} from './mat-categories-card.component';
import {
  RequestNewAttributeConfirmModule
} from 'pm-components/lib/shared/request-new-attribute-confirm/request-new-attribute-confirm-module';
import {StaticOverlayPanelModule} from 'pm-components/lib/shared/overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {
  RequestNewMatAttributeFormModule
} from 'pm-components/lib/shared/request-new-mat-attribute-form/request-new-mat-attribute-form.module';

@NgModule({
  declarations: [MatCategoriesCardComponent],
  exports: [MatCategoriesCardComponent, CardModule, AttributeDisplayModule],
  imports: [
    CommonModule, CardModule,
    AttributeDisplayModule, RequestNewAttributeConfirmModule, StaticOverlayPanelModule, RequestNewMatAttributeFormModule
  ]
})
export class MatCategoriesCardModule { }
