import { Component, OnInit } from '@angular/core';
import {Candidate, CandidateError, CandidateValidatorType, Task, TaskDecision, WorkflowState} from 'pm-models';
import {forkJoin, Observable, Subject} from 'rxjs';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {WorkflowService} from '../../service/workflow.service';
import {ProcessVariables} from 'pm-models/lib/processVariables';

@Component({
  selector: 'app-pia-bulk-review',
  templateUrl: './pia-bulk-review.component.html',
  styleUrls: ['./pia-bulk-review.component.scss']
})
export class PiaBulkReviewComponent implements OnInit {


  toReview: Candidate[];
  workflowState: string = WorkflowState.PIA_NEW_PRODUCT_REVIEW;
  candidateSubscription$: any;
  isViewingPage: boolean;
  candidateProductIndex: number;
  isActivateButtonDisabled = false;
  candidateErrors: Map<any, any> = new Map();
  public candidateError: CandidateError = new CandidateError();
  public isRejectDisabled = false;
  public isDsd: boolean;
  public isWarehouse: boolean;

  constructor(public route: ActivatedRoute, public candidateService: CandidateService, public router: Router,
              protected growlService: GrowlService, public workflowService: WorkflowService) { }

  ngOnInit() {
    this.isViewingPage = true;
    this.getCandidates().then();
  }

  async getCandidates() {
    const observables: Observable<Candidate>[] = [];
    this.candidateSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (!params.has('toReview')) {
        return;
      }
      for (let x = 0; x < params.getAll('toReview').length; x++) {
        const id: number  = +params.getAll('toReview')[x];
        observables.push(this.candidateService.getCandidate(id));
      }
      forkJoin(observables).subscribe( candidates => {
          if (candidates && candidates.length > 0) {
            this.candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(candidates[0]);
          }
          this.isDsd = CandidateUtilService.areAllCandidatesDsdOnly(candidates);
          this.isWarehouse = CandidateUtilService.areAllCandidatesWarehouseOnly(candidates);
          this.toReview = candidates;
        }
      );
    });
  }

  onBulkRejectClicked(event, panel) {

    for (let x = 0; x < this.toReview.length; x++) {
      const isDone = new Subject<string>();

      this.candidateService.getCandidate(this.toReview[x].candidateId).subscribe((candidate: Candidate) => {
        candidate.vendorComment = event;
        candidate.status = Candidate.DECLINED;
        this.candidateService.saveCandidate(candidate, true).subscribe(() => {
          this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId)
            .subscribe(task => {
              if (task.name === 'PIA Final Review' || task.name === 'PIA New Product Flow') {
                this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                  TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe(() => {
                  if (x === this.toReview.length - 1) {
                    panel.hide();
                    this.router.navigate(['/tasks']).then();
                  }
                  isDone.next('reject done');
                }, (error) => {
                  this.growlService.addError(error);
                });
              }
            }, (error) => {
              this.growlService.addError(error);
            });
        }, (error) => {
          this.growlService.addError(error);
        });
      }, (error) => {
        this.growlService.addError(error);
      });
    }
  }

  onClickActivate() {
    this.isActivateButtonDisabled = true;
    this.isRejectDisabled = true;
    this.bulkActivate();
  }

  bulkActivate() {
    this.candidateService.validateBulkCandidate(this.toReview, [CandidateValidatorType.PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR])
      .subscribe((validationResponse) => {
        this.candidateErrors = new Map();
        this.candidateError = new CandidateError();

        // add any candidate errors to the map.
        for (const key of Object.keys(validationResponse)) {
          if (validationResponse[key]?.candidateError) {
            this.candidateErrors.set(+key, validationResponse[key]?.candidateError);
          }
        }
        // if there's errors
        if (this.candidateErrors.size > 0) {
          this.candidateError = this.candidateErrors.values().next().value;
          this.isActivateButtonDisabled = false;
        } else {
          const processVariables: ProcessVariables[] = [];
          this.toReview.forEach(candidate => {
            processVariables.push({
              candidateId: candidate.candidateId, apNumber: candidate.vendor?.apNumber ? candidate.vendor.apNumber : null, moveToWorkflowState: WorkflowService.PIA_APPROVED_STATE
            });
          });
          // need for loop here to activate one by one and then update workflow after complete
          let activationDone = false;
          for (let x = 0; x < this.toReview.length; x++) {
            this.candidateService.activateCandidate(this.toReview[x]).subscribe(() => {
            }, (error) => {
              this.growlService.addError(error);
              this.isActivateButtonDisabled = false;
              this.isRejectDisabled = false;
            });
            activationDone = true;
          }
          if (activationDone) {
            this.workflowService.updateCandidateTasksStates(processVariables).subscribe(() => {
              this.router.navigate(['/tasks']).then();
            });
          }
        }
      }, (error) => {
        this.isActivateButtonDisabled = false;
        if (error && error.message) {
          this.growlService.addError(error.message);
        } else {
          this.growlService.addError(error);
        }
      });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string, task: Task) {
    this.workflowService.completeTaskWithAction(task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then(() => {
          this.isActivateButtonDisabled = false;
          this.isRejectDisabled = false;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
        this.isActivateButtonDisabled = false;
      });
  }

  onClose() {
    this.isViewingPage = false;
    this.isRejectDisabled = true;
    this.candidateService.saveAllCandidates(this.toReview).subscribe(() => {
      this.router.navigate(['/tasks']).then();
    });
  }

  showPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  getToReviewSize() {
    return this.toReview ? this.toReview.length : '0';
  }

  getIsDsd(): boolean {
    for (const candidate of this.toReview) {
      if (candidate.warehouseSwitch || !candidate.dsdSwitch) {
        return false;
      }
    }
    return true;
  }

  getIsWarehouse(): boolean {
    for (const candidate of this.toReview) {
      if (!candidate.warehouseSwitch || candidate.dsdSwitch) {
        return false;
      }
    }
    return true;
  }
}
