import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {Candidate, CandidateError, CandidateProductError, CandidateValidatorType} from 'pm-models';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-pia-setup-new-mrt-items',
  templateUrl: './pia-setup-new-mrt-items.component.html',
  styleUrls: ['./pia-setup-new-mrt-items.component.scss']
})
export class PiaSetupNewMrtItemsComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private candidateUtilService: CandidateUtilService) { }

  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public isViewingPage = true;
  public mrtNewItemInners: CandidateInner[] = [];
  public canNavigate = true;

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierMrtService.resetService();
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          if (candidate.candidateType === Candidate.MRT) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.resetErrors();
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierMrtService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  initializeData() {
    this.setInnerCandidates();
    this.candidateError = this.supplierMrtService.getMrtCaseDetailsError();
    this.candidateProductError = this.getCandidateProductError();
  }

  getCandidateProductError() {
    if (this.candidateError && this.candidateError.candidateProductErrors && this.candidateError.candidateProductErrors.size > 0) {
      return this.candidateError.candidateProductErrors[this.supplierMrtService.getCandidateProduct(0).id];
    }
    return new CandidateProductError();
  }

  setInnerCandidates() {
    this.supplierMrtService.setInnerCandidates(true).pipe(
      tap(() => {
        if (!!this.supplierMrtService.getCandidate().mrtInfo?.candidateInners?.length) {
          const nonRep = [];
          const rep = [];
          this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
            if (candidateInner.replenishable) {
              const returnedCandidate = this.addNewItemInnersAndValidate(candidateInner);
              if (returnedCandidate) {
                rep.push(returnedCandidate);
              }
            } else {
              const returnedCandidate = this.addNewItemInnersAndValidate(candidateInner);
              if (returnedCandidate) {
                nonRep.push(returnedCandidate);
              }
            }
          });
          if (nonRep.length > 0) {
            this.candidateService.validateBulkCandidate(nonRep, SupplierMrtService.FULL_PIA_NON_REPLENISHABLE_VALIDATORS).subscribe((result) => {
              for (const candidate of Object.keys(result)) {
                if (result[candidate]?.candidateError) {
                  this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
                    if (result[candidate]?.candidateError.candidateId === candidateInner.candidateId) {
                      candidateInner.valid = false;
                    }
                  });
                } else {
                  this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
                    if (result[candidate]?.candidate?.candidateId === candidateInner.candidateId) {
                      candidateInner.valid = true;
                    }
                  });
                }
              }
            });
          }
          if (rep.length > 0) {
            this.candidateService.validateBulkCandidate(rep, SupplierMrtService.FULL_PIA_REPLENISHABLE_VALIDATORS).subscribe((result) => {
              for (const candidate of Object.keys(result)) {
                if (result[candidate]?.candidateError) {
                  this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
                    if (result[candidate]?.candidateError.candidateId === candidateInner.candidateId) {
                      candidateInner.valid = false;
                    }
                  });
                } else {
                  this.supplierMrtService.getCandidate().mrtInfo?.candidateInners.forEach(candidateInner => {
                    if (result[candidate]?.candidate?.candidateId === candidateInner.candidateId) {
                      candidateInner.valid = true;
                    }
                  });
                }
              }
            });
          }
        }
      })
    ).subscribe();
  }

  addNewItemInnersAndValidate(candidateInner: CandidateInner) {
    // Not adding NR mrt draft candidate to setup list
    if (candidateInner.candidate.candidateType === Candidate.MRT_INNER && !candidateInner.draft) {
      this.mrtNewItemInners.push(candidateInner);
      if (candidateInner.vendorEdited && !candidateInner.valid) {
        if (candidateInner.replenishable === null || candidateInner.replenishable === undefined) {
          candidateInner.valid = false;
          return;
        }
        return candidateInner.candidate;
      }
    }
  }

  onClose() {
    this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
    this.supplierMrtService.saveCandidateAndNavigate('/tasks', null);
  }

  setUpMrtInner(mrtInner: CandidateInner) {
    this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
    this.supplierMrtService.saveCandidateAndNavigate('/piaSupplierAndHebDetails',
      {queryParams: {taskId: this.supplierMrtService.getTaskIdString(), candidateId: mrtInner.candidateId}});
  }

  deleteInner(inner: CandidateInner) {

    for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
      if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate.candidateProducts[0].upc ===
        inner.candidate.candidateProducts[0].upc) {
        this.supplierMrtService.getCandidate().mrtInfo.candidateInners.splice(x, 1);
        break;
      }
    }
    for (let x = 0; x < this.mrtNewItemInners.length; x++) {
      if (this.mrtNewItemInners[x].candidate.candidateProducts[0].upc === inner.candidate.candidateProducts[0].upc) {
        this.mrtNewItemInners.splice(x, 1);
      }
    }
    inner.candidate.status = 'DELETED';
    this.candidateService.saveCandidate(inner.candidate, true).subscribe();
    if (this.mrtNewItemInners.length === 0) {
      this.supplierMrtService.setCandidate(this.supplierMrtService.getCandidate());
      this.supplierMrtService.saveCandidateAndNavigate('/piaMrtCaseDetails', null);
    } else {
      this.supplierMrtService.saveMrtCandidate();
    }
    this.supplierMrtService.deleteInnerErrors(inner.candidateId);
  }

  onClickBack() {
    this.canNavigate = false;
    this.supplierMrtService.saveCandidateAndNavigate('/piaSetupMrt', null);
  }
  onClickNext() {
    if (this.supplierMrtService.hasInnersToSetup()) {
      return;
    }
    this.canNavigate = false;
    this.supplierMrtService.saveCandidateAndNavigate('/piaMrtCaseDetails', null);
  }


  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case SupplierMrtService.UPC_ITEM_CODES: {
        this.onClickBack();
        break;
      }
      case SupplierMrtService.MRT_DETAILS: {
        this.onClickNext();
        break;
      }
      case SupplierMrtService.WAREHOUSE_SELECTION: {
        this.jumpToPage('/piaMrtWarehouseSelection');
        break;
      }
      case SupplierMrtService.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/piaMrtExtendedAttributes');
        break;
      }
    }
  }

  jumpToPage(urlToNavigate) {
    if (this.supplierMrtService.hasInnersToSetup()) {
      return;
    }
    this.canNavigate = false;
    this.supplierMrtService.saveCandidateAndNavigate(urlToNavigate, {queryParams: {taskId: this.supplierMrtService.getTaskIdString()}});
  }

}
