<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pia-additional-case-pack-stepper [candidateErrors]="supplierCasePackService.getAllPiaCandidateErrors()" [stepIndex]="1"
                                          (indexClick)="onClickStepper($event)">
    </app-pia-additional-case-pack-stepper>
  </pm-header>

  <pm-title></pm-title>
  <div class="col-md-10" style="margin-top: 25px; margin-left: 5px;">
    <div class="row">
      <label>Provide a nickname for this so it’s easy to find in PAM. We’ll only use this internally.</label>
    </div>
    <div class="row">
      <input id="descriptionId" pInputText
             [ngClass]="{'ui-inputtext-invalid' : candidateError.description}"
             placeholder="Enter a name or short description..."
             type="text"
             maxlength="100"
             minlength="1"
             accept-charset="UTF-8"
             [(ngModel)]="supplierCasePackService.candidate.description"
             style="width: 80%;">
    </div>
    <div class="row mt-0" *ngIf="candidateError?.description">
      <p class="error-message">{{candidateError?.description}}</p>
    </div>
  </div>
  <div class="col-md-9 attributes-stacked mt-4" style="padding-left: 0; padding-right: 60px;">
    <pm-attribute-display-typeahead [attribute]="warehouseSupplierConfiguration" [(model)]="supplierCasePackService.candidate.lane"
                                    [attributeError]="candidateError?.lane" (selection)="warehouseSupplierChange($event)"  >
    </pm-attribute-display-typeahead>
  </div>
  <div class="col-md-10" style="margin-bottom: 100px; margin-left: 5px;">
    <div class="row">
      <label>What product is in this case pack?</label>
    </div>
    <div class="row">
      <pm-upc-input
        [(upcModel)]="searchedCode"
        [(checkDigitModel)]="supplierCasePackService.candidate.candidateProducts[0].upcCheckDigit"
        [(upcType)]="upcType"
        [readOnly]="false"
        [disabled]="false"
        [hasError]="hasError()"
        placeholderText="Search for item..."
        checkDigitPlaceholderText="Check #"
        [upcOptions]="upcOptions"
        [state]="upcState"
        (upcModelChange)="setUpcItemCodeAndValidate()"
        (upcTypeChange)="onUpcTypeChange()"
        [hideCheckDigit]="isItemCode()"
        [autoCheckDigit]="true"
        [readOnlyCheckDigit]="true"
        style="width: 80%;">
      </pm-upc-input>
    </div>
    <div class="row" *ngIf="hasError()">
      <p class="error-message">{{getError()}}</p>
    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button id="nextButton" class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
