import { Injectable } from '@angular/core';
import {CandidateSearch} from 'pm-models/lib/candidateSearch';
import {CandidateSearchFilter} from 'pm-models/lib/candidateSearchFilter';
import {WorkFlowTask} from 'pm-models/lib/workFlowTask';
import {Observable, Subject} from 'rxjs';
import {CandidateUtilService} from '../../service/candidate-util.service';

import {CandidateService} from '../../service/candidate.service';
import {WorkflowService} from '../../service/workflow.service';

import {TaskListChildCandidate, TaskListTask} from './taskListTask';
import {Candidate, HistoricProcessInstance, ProcessInstance, Task} from 'pm-models';
import {AuthService} from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TaskListService {

  public PAGE_SIZE: number = 20;
  private static readonly PIA_DRAFT_NAME = 'Procurement Support';
  private static readonly SUPPLIER_DRAFT_NAME = 'Supplier';
  private static readonly PHARMACY_DRAFT_NAME = 'Pharmacy';
  private static readonly OB_REG_NAME = 'Own Brand Regulatory';

  mapWorkflowStateToDisplay = new Map([
    ['Key Buyer Data', 'Buyer review'],
    ['Revise Buyer Data', 'Buyer review'],
    ['Assign Warehouse', 'Warehouse set up'],
    ['PIA Final Review', 'PS Review'],
    ['Review Associate UPC', 'PS Review'],
    [Task.PIA_NEW_PRODUCT_FLOW, TaskListService.PIA_DRAFT_NAME],
    [Task.PIA_OB_REG_FLOW_SUBMIT, TaskListService.PIA_DRAFT_NAME],
    [Task.KEY_VENDOR_DATA, TaskListService.SUPPLIER_DRAFT_NAME],
    [Task.ASSOCIATE_VENDOR_TASK_NAME, TaskListService.SUPPLIER_DRAFT_NAME],
    [Task.DSD_INVITED_SUPPLIER_VENDOR_TASK_NAME, TaskListService.SUPPLIER_DRAFT_NAME],
    [Task.PHARM_NEW_PRODUCT_FLOW, TaskListService.PHARMACY_DRAFT_NAME],
    [Task.KEY_OB_REG_DATA, TaskListService.OB_REG_NAME],
    [Task.PIA_OB_REG_FLOW_ACTIVATE, TaskListService.PIA_DRAFT_NAME]
  ]);

  private readonly CANDIDATE_UPLOAD_IN_PROGRESS = 'Upload in progress';
  private readonly CANDIDATE_ACTIVATION_IN_PROGRESS = 'Activation in progress';

  constructor(public candidateService: CandidateService, public workflowService: WorkflowService,
              public candidateUtilService: CandidateUtilService, private authService: AuthService) {
  }

  getOpenTasks(getCount: boolean, pageNumber: number,
               sortOrder: string, sortField: string,
               filter?: CandidateSearchFilter[]): Observable<TaskListTask[]> {

    const taskListTasks$ = new Subject<TaskListTask[]>();

    let taskListTasks: TaskListTask[];

    let currentSort;

    if (sortField === 'LANDED_ON') {
      currentSort = sortOrder;
    }

    this.workflowService.getTasks(this.authService.getCurrentRole(), currentSort).subscribe((tasks) => {
      taskListTasks = this.getTaskListTasksFromTasks(tasks);

      const taskListTasksMap = this.createMapOfTaskListTasksBasedOnCandidateId(taskListTasks);
      const candidateIds: number[] = this.getCandidateIdsFromTaskListTasks(taskListTasks);
      this.candidateService.getCandidateSearchsFor(
        candidateIds, getCount, this.PAGE_SIZE, pageNumber,
        sortOrder, sortField,
        filter).subscribe((candidateSearchResult) => {
        taskListTasks = this.updateTaskListTasksFromCandidateSearchs(
          taskListTasksMap, candidateSearchResult.data, candidateSearchResult.recordCount);
        taskListTasks$.next(taskListTasks);
      });
    });

    return taskListTasks$;
  }

  getRejectedProcesses(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {
    const taskListTasks$ = new Subject<TaskListTask[]>();

    let taskListTasks: TaskListTask[];

    this.workflowService.getDeclinedHistoricProcesses().subscribe((processes) => {
      taskListTasks = this.getTaskListTasksFromHistoricProcesses(processes);

      const taskListTasksMap = this.createMapOfTaskListTasksBasedOnCandidateId(taskListTasks);
      const candidateIds: number[] = this.getCandidateIdsFromTaskListTasks(taskListTasks);

      this.candidateService.getCandidateSearchsFor(
        candidateIds, getCount, this.PAGE_SIZE, pageNumber, sortOrder, sortField).subscribe((candidateSearchResult) => {
        taskListTasks = this.updateTaskListTasksFromCandidateSearchs(
          taskListTasksMap, candidateSearchResult.data, candidateSearchResult.recordCount, true);
        taskListTasks$.next(taskListTasks);
      });
    });

    return taskListTasks$;
  }

  getCompletedProcesses(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {
    const taskListTasks$ = new Subject<TaskListTask[]>();

    let taskListTasks: TaskListTask[];

    this.workflowService.getApprovedHistoricProcesses().subscribe((processes) => {
      taskListTasks = this.getTaskListTasksFromHistoricProcesses(processes);

      const taskListTasksMap = this.createMapOfTaskListTasksBasedOnCandidateId(taskListTasks);
      const candidateIds: number[] = this.getCandidateIdsFromTaskListTasks(taskListTasks);

      this.candidateService.getCandidateSearchsFor(
        candidateIds, getCount, this.PAGE_SIZE, pageNumber, sortOrder, sortField).subscribe((candidateSearchResult) => {
        taskListTasks = this.updateTaskListTasksFromCandidateSearchs(
          taskListTasksMap, candidateSearchResult.data, candidateSearchResult.recordCount, true);
        taskListTasks$.next(taskListTasks);
      });
    });

    return taskListTasks$;
  }


  getPiaCompletedProcesses(includeCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {

    return this.candidateService.findAllCompletedCandidates(includeCount, this.PAGE_SIZE, pageNumber, sortOrder, sortField);
  }


  getPiaRejectedProcesses(includeCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {

    return this.candidateService.findAllRejectedCandidates(includeCount, this.PAGE_SIZE, pageNumber, sortOrder, sortField);
  }

  getInProgressTasks(getCount: boolean, pageNumber: number, sortOrder: string, sortField: string) {
    const taskListTasks$ = new Subject<TaskListTask[]>();

    let taskListTasks: TaskListTask[];

    this.workflowService.getUnderReviewProcessesForVendor().subscribe((processes) => {
      taskListTasks = this.getTaskListTasksFromProcesses(processes);

      const taskListTasksMap = this.createMapOfTaskListTasksBasedOnCandidateId(taskListTasks);
      const candidateIds: number[] = this.getCandidateIdsFromTaskListTasks(taskListTasks);

      this.candidateService.getCandidateSearchsFor(
        candidateIds, getCount, this.PAGE_SIZE, pageNumber, sortOrder, sortField).subscribe((candidateSearchResult) => {
        taskListTasks = this.updateTaskListTasksFromCandidateSearchs(
          taskListTasksMap, candidateSearchResult.data, candidateSearchResult.recordCount, true);
        taskListTasks$.next(taskListTasks);
      });
    });

    return taskListTasks$;
  }

  createMapOfTaskListTasksBasedOnCandidateId(taskListTasks: TaskListTask[]) {
    return taskListTasks.reduce(function(map, obj) {
      map[obj.candidateId] = obj;
      return map;
    }, {});
  }

  updateTaskListTasksFromCandidateSearchs(taskListTasksMap,
                                          sortedCandidates: CandidateSearch[],
                                          recordCount?: number,
                                          closed: boolean = false): TaskListTask[] {
    const result: TaskListTask[] = [];

    for (let index = 0; index < sortedCandidates.length; index++) {
      const candidateId = sortedCandidates[index].candidateId;

      if (taskListTasksMap[candidateId]) {
        const taskListTask: TaskListTask =
          new TaskListTask(taskListTasksMap[candidateId].id, candidateId, taskListTasksMap[candidateId].name, taskListTasksMap[candidateId].processInstanceId);

        taskListTask.candidateDescription = this.getCandidateDescription(sortedCandidates[index]);
        taskListTask.elapsedTime = sortedCandidates[index].elapsedTime;
        taskListTask.lastUpdatedBy = sortedCandidates[index].lastUpdatedBy;
        taskListTask.lastUpdatedTime = sortedCandidates[index].lastUpdatedTime;
        taskListTask.buyerId = sortedCandidates[index].buyerId;
        taskListTask.buyerDisplayName = sortedCandidates[index].buyerDisplayName;
        taskListTask.supplierId = sortedCandidates[index].supplierId;
        taskListTask.supplierDisplayName = sortedCandidates[index].supplierDisplayName;
        taskListTask.taskType = sortedCandidates[index].taskType;
        taskListTask.upc = sortedCandidates[index].upc;
        taskListTask.itemCode = sortedCandidates[index].itemCode;
        taskListTask.status = sortedCandidates[index].status;
        taskListTask.vendorComment = sortedCandidates[index].vendorComment;
        taskListTask.rushFlag = sortedCandidates[index].rushFlag;
        taskListTask.createTime = taskListTasksMap[candidateId].createTime;
        taskListTask.candidateProductDescription = sortedCandidates[index].candidateProductDescription;
        taskListTask.warehouseSupplierId = sortedCandidates[index].warehouseSupplierId;
        taskListTask.warehouseSupplierDisplayName = sortedCandidates[index].warehouseSupplierDisplayName;
        taskListTask.associateUpcs = sortedCandidates[index].associateUpcs;
        taskListTask.channel = sortedCandidates[index].channel;
        taskListTask.productTypeId = sortedCandidates[index].productTypeId;
        taskListTask.codeDate = sortedCandidates[index].codeDate;
        taskListTask.departmentId = sortedCandidates[index].departmentId;
        taskListTask.subDepartmentId = sortedCandidates[index].subDepartmentId;
        if (sortedCandidates[index].childCandidates && sortedCandidates[index].childCandidates.length) {
          taskListTask.childCandidates = [];
          sortedCandidates[index].childCandidates.forEach(childCandidate => {
            const taskListChildCandidate = new TaskListChildCandidate();
            taskListChildCandidate.candidateId = childCandidate.candidateId;
            taskListChildCandidate.lastUpdatedBy = childCandidate.lastUpdatedBy;
            taskListChildCandidate.lastUpdatedTime = childCandidate.lastUpdatedTime;
            taskListChildCandidate.supplierDisplayName = childCandidate.supplierDisplayName;
            taskListTask.childCandidates.push(taskListChildCandidate);
          });
        }
        if (closed) {
          taskListTask.displayName = this.getDisplayNameClosed(taskListTask.status);
        } else {
          taskListTask.displayName = this.getDisplayName(taskListTask.taskType);
        }

        if (recordCount) {
          taskListTask.count = recordCount;
        }

        result.push(taskListTask);
      }
    }

    return result;
  }

  getTaskListTasksFromTasks(tasks: Task[]): TaskListTask[] {
    const taskListTasks: TaskListTask[] = [];

    for (let index = 0; index < tasks.length; index++) {
      taskListTasks.push(new TaskListTask(tasks[index].id, tasks[index].candidateId, tasks[index].name,
        tasks[index].processInstanceId, tasks[index].rushFlag, tasks[index].createTime));
    }

    return taskListTasks;
  }

  getTaskListTasksFromHistoricProcesses(processes: HistoricProcessInstance[]) {
    const taskListTasks: TaskListTask[] = [];

    for (let index = 0; index < processes.length; index++) {
      taskListTasks.push(new TaskListTask(processes[index].id, processes[index].candidateId));
    }

    return taskListTasks;
  }

  getTaskListTasksFromProcesses(processes: ProcessInstance[]) {
    const taskListTasks: TaskListTask[] = [];

    for (let index = 0; index < processes.length; index++) {
      taskListTasks.push(new TaskListTask(processes[index].id, processes[index].candidateId));
    }

    return taskListTasks;
  }

  getCandidateIdsFromTaskListTasks(taskListTasks: TaskListTask[]): number[] {
    const candidateIds: number[] = [];

    for (let index = 0; index < taskListTasks.length; index++) {
      candidateIds.push(taskListTasks[index].candidateId);
    }

    return candidateIds;
  }

  getQueryParamsForTaskDetails(task: TaskListTask): any {
    const queryParams = {};
    queryParams['taskId'] = task.id;
    return queryParams;
  }

  getQueryParamsForCandidateDetails(candidateId: number): any {
    const queryParams = {};
    queryParams['candidateId'] = candidateId;
    return queryParams;
  }

  getQueryParamsForCandidateAndTaskDetails(candidateId: number, task: TaskListTask): any {
    const queryParams = {};
    queryParams['taskId'] = task.id;
    queryParams['candidateId'] = candidateId;
    return queryParams;
  }

  getDisplayName(candidateType: string) {

    switch (candidateType) {
      case Candidate.ASSOCIATE_UPC: {
        return 'Associate UPC';
      }
      case Candidate.NEW_PRODUCT:
      case Candidate.PLU: {
        return 'New Product';
      }
      case Candidate.BONUS_SIZE: {
        return 'Bonus UPC';
      }
      case Candidate.MRT: {
        return 'MRT';
      }
      case Candidate.MRT_INNER: {
        return 'New MRT Product';
      }
      case Candidate.ADDITIONAL_CASE_PACK: {
        return 'Case Pack';
      }
      case Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR:
      case Candidate.ADDITIONAL_DISTRIBUTOR: {
        return 'DSD stores';
      }
      case Candidate.REPLACEMENT_UPC: {
        return 'Replacement UPC';
      }
      case Candidate.NEW_PRODUCT_UPLOAD_PARENT: {
        return 'Volume upload';
      }
      case Candidate.EDI_UPLOAD_PARENT: {
        return 'EDI upload';
      }
      default: {
        return 'Unknown';
      }
    }
  }

  getDisplayNameClosed(status: string) {
    return CandidateUtilService.getUIStatusFromCandidateStatus(status);
  }

  getCandidateSearchsResultsFor(searchType: string, searchString: string): Observable<TaskListTask[]> {
    const taskListTasks$ = new Subject<TaskListTask[]>();

    // TODO pagination is not implemented, passing dummy values
    this.candidateService.getCandidateSearchsResultsFor(searchType, searchString,
      false, 2, 1, 'ASC', 'CANDIDATE_ID').subscribe(candidateSearchs => {
      let taskListTasks: TaskListTask[];
      const candidateIds: number[] = this.getCandidateIdsFromCandidateSearchs(candidateSearchs);

      this.workflowService.getAllTasksForCandidates(candidateIds).subscribe((tasks) => {
        taskListTasks = this.getTaskListTasksFromWorkflowTasks(tasks);
        const taskListTasksMap = this.createMapOfTaskListTasksBasedOnCandidateId(taskListTasks);

        taskListTasks = this.updateTaskListTasksFromCandidateSearchs(
          taskListTasksMap, candidateSearchs);
        taskListTasks$.next(taskListTasks);
      });
    });

    return taskListTasks$;
  }

  getCandidateIdsFromCandidateSearchs(candidateSearchs: CandidateSearch[]) {
    const candidateIds: number[] = [];

    for (let index = 0; index < candidateSearchs.length; index++) {
      candidateIds.push(candidateSearchs[index].candidateId);
    }

    return candidateIds;
  }

  getTaskListTasksFromWorkflowTasks(tasks: WorkFlowTask[]) {
    const taskListTasks: TaskListTask[] = [];

    for (let index = 0; index < tasks.length; index++) {
      taskListTasks.push(new TaskListTask(tasks[index].id, tasks[index].candidateId, tasks[index].name, tasks[index].processInstanceId));
    }

    return taskListTasks;
  }

  getDisplayNameWorkflowStatus(status: string) {
    let result = this.mapWorkflowStateToDisplay.get(status);

    if (!result) {
      result = '';
    }

    return result;
  }

  /**
   * Returns the candidate description.
   * @param candidateSearch the candidateSearch.
   */
  getCandidateDescription(candidateSearch: CandidateSearch) {
    if (candidateSearch.taskType === Candidate.NEW_PRODUCT_UPLOAD_PARENT ||
      candidateSearch.taskType === Candidate.EDI_UPLOAD_PARENT) {
      if (candidateSearch.status === Candidate.IN_PROGRESS) {
        return this.CANDIDATE_UPLOAD_IN_PROGRESS;
      } else if (candidateSearch.status === Candidate.ACTIVATING_BATCH) {
        return this.CANDIDATE_ACTIVATION_IN_PROGRESS;
      }
    }
    return candidateSearch.candidateDescription;
  }
}

