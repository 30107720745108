export class AuthCookie {
  access_token: string;
  account_type: string;
  ap_numbers: Array<number>;
  display_name: string;
  expires_in: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  user_email: string;

  constructor(access_token: string, refresh_token: string, expires_in: string, account_type: string) {
    this.access_token = access_token;
    this.refresh_token = refresh_token;
    this.expires_in = expires_in;
    this.account_type = account_type;
    this.token_type = 'bearer';
  }

  public setTokenWithCheckToken(checkToken: any) {
    this.ap_numbers = checkToken.ap_numbers;
    this.display_name = checkToken.display_name;
    this.scope = checkToken.scope;
    this.user_email = checkToken.user_email;
  }
}
