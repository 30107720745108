// tslint:disable: max-line-length
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeTypes} from 'pm-components';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';
import {CostService} from '../../../../../../src/app/2.0.0/service/cost.service';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {Candidate, CandidateError, CandidateProduct} from 'pm-models';
import {UploadCandidateService} from '../../../../../../src/app/2.0.0/service/upload-candidate.service';
import {UploadCandidateSupplierAndHebConstants} from '../../../../../../src/app/2.0.0/utils/constants/upload-candidate-supplier-and-heb-constants';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';
import {CandidateErrorUtilService} from '../../../../../../src/app/2.0.0/service/candidate-error-util.service';
import {EditCandidateUtilService} from '../../../../../../src/app/2.0.0/service/edit-candidate-util.service';

@Component({
  selector: 'pm-upload-supplier-and-heb-table',
  templateUrl: './upload-supplier-and-heb-table.component.html',
  styleUrls: ['./upload-supplier-and-heb-table.component.scss']
})
export class UploadSupplierAndHebTableComponent implements OnInit {

  @Input() candidates: Candidate[];
  @Input() candidateType: string;
  @Input() workflowState: string;
  @Input() pageSize: number = 50;
  @Input() totalNumRecords: number;
  @Input() candidateProductIndex;
  @Input() isSca: boolean = false;
  @Input() isWarehouse = false;
  @Input() candidateIdToCandidateErrorMap: Map<number, CandidateError>;

  @Output() loadData = new EventEmitter<any>();
  @Output() candidatesChange = new EventEmitter<any>();

  supplierAndHebHeaderColumns: any[];

  public HEB_DETAILS_COLUMN_GROUP_LENGTH;
  public SUPPLIER_DETAILS_COLUMN_GROUP_LENGTH;

  constructor(public candidateUtils: CandidateUtilService, private costService: CostService,
              private editCandidateModalService: EditCandidateModalService, private candidateService: CandidateService,
              private lookupService: LookupService, public growlService: GrowlService,
              private uploadCandidateService: UploadCandidateService, public candidateErrorUtils: CandidateErrorUtilService,
              private editCandidateUtilService: EditCandidateUtilService) {
  }


  ngOnInit() {
    if (this.candidateType) {
      this.supplierAndHebHeaderColumns = this.uploadCandidateService.getSupplierAndHebColumnsFromCandidateType(this.candidateType, this.isWarehouse);
    } else if (this.workflowState) {
      this.supplierAndHebHeaderColumns = this.uploadCandidateService.getSupplierAndHebColumnsFromWorkflowState(this.workflowState);
    }
    // initialize columns group lengths.
    this.HEB_DETAILS_COLUMN_GROUP_LENGTH = this.uploadCandidateService.getColumnGroupCount(this.supplierAndHebHeaderColumns,
      UploadCandidateSupplierAndHebConstants.HEB_DETAILS_COLUMN_GROUP);

    this.SUPPLIER_DETAILS_COLUMN_GROUP_LENGTH = this.uploadCandidateService.getColumnGroupCount(this.supplierAndHebHeaderColumns,
      UploadCandidateSupplierAndHebConstants.SUPPLIER_DETAILS_COLUMN_GROUP);
  }


  editProductUPC(index) {
    this.showEditCandidateModal(index, AttributeTypes.ProductUPC, {
      validationService: this.candidateService
    });
  }


  showEditCandidateModal(index, type: AttributeTypes, overrides?: any) {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidates[index]));
    this.editCandidateModalService.openModal(type, tempCandidate, overrides).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidates[index] = response;
      }
    });
  }

  get attributeType() {
    return AttributeTypes;
  }


  editBuyer(index) {
    if (!this.isWarehouse) {
      return;
    }
    this.editCandidateModalService.openModal(AttributeTypes.Buyer, this.candidates[index]).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidates[index] = response;
        this.candidates[index].commodity = null;
        this.candidates[index].subCommodity = null;
      }
    });
  }


  editBrandModal(index) {
    if (!this.isSca) {
      this.editCandidateModalService.openModal(AttributeTypes.Brand, this.candidates[index]).subscribe(response => {
        if (response) {
          const candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(this.candidates[index]);
          // Dispatch Update
          this.candidates[index] = response;
          this.candidates[index].costOwner = null;
          this.candidates[index].candidateProducts[candidateProductIndex] = this.candidates[index].candidateProducts[candidateProductIndex];
        }
      });
    }
  }


  showEditCandidateProductModal(index, type: AttributeTypes, overrides?: any) {
    if (!this.isSca) {
      const candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(this.candidates[index]);
      this.editCandidateModalService.openModal(type, this.candidates[index].candidateProducts[candidateProductIndex], overrides)
        .subscribe(response => {
          if (response) {
            // Dispatch Update
            this.candidates[index].candidateProducts[candidateProductIndex] = response;
          }
        });
    }
  }

  async editCostOwner(index) {
    if (!this.isSca) {
      let costOwners = [];
      if (this.candidates[index].brand && this.candidates[index].brand.costOwners) {
        costOwners = this.candidates[index].brand.costOwners;
      } else if (this.candidates[index].brand) {
        const brands = await this.lookupService.findBrandsById(this.candidates[index].brand.brandId).toPromise();

        for (let x = 0; index < brands.length; x++) {
          if (this.candidates[index].brand.brandId === brands[x].brandId) {
            this.candidates[index].brand.costOwners = brands[x].costOwners;
            costOwners = brands[x].costOwners;
            this.candidates[index].brand.subBrands = brands[x].subBrands;
            break;
          }
        }
      }

      this.editCandidateModalService.openMultiEditModal(
        [
          {type: AttributeTypes.CostOwner, overrides: {collections: costOwners}},
          {type: AttributeTypes.TopToTop, overrides: {collections: costOwners}}
        ], this.candidates[index]).subscribe(response => {

        if (response) {
          this.candidates[index] = response;
        }
      });
    }
  }

  editSupplier(index) {
    if (!this.isSca && this.isWarehouse) {
      const candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(this.candidates[index]);
      const tempCandidate = JSON.parse(JSON.stringify(this.candidates[index]));
      this.editCandidateModalService.openModal(AttributeTypes.Vendor, tempCandidate).subscribe(response => {
        if (response) {
          // Dispatch Update
          this.candidates[index] = response;
          this.candidates[index].candidateProducts[candidateProductIndex].warehouses = [];
          this.candidates[index].candidateProducts[candidateProductIndex].warehouseSupplierItems = [];
        }
      });
    }
  }

  async editWarehouseSupplier(index) {
    if (!this.isWarehouse) {
      return;
    }

    const candidateProductIndex = CandidateUtilService.getCurrentCandidateProductIndex(this.candidates[index]);
    const tempCandidate = JSON.parse(JSON.stringify(this.candidates[index]));
    let lanes = [];

    if (this.candidates[index].vendor && this.candidates[index].vendor.lanes) {
      lanes = this.candidates[index].vendor.lanes;
    } else if (this.candidates[index].vendor) {
      const vendor = await this.lookupService.findSingleVendor(this.candidates[index].vendor.apNumber).toPromise();
      lanes = vendor.lanes;
    }
    this.editCandidateModalService.openModal(AttributeTypes.Warehouse, tempCandidate,
      {collections: lanes}).subscribe(response => {
      if (response) {
        // Dispatch Update
        this.candidates[index] = response;
        this.candidates[index].candidateProducts[candidateProductIndex].warehouses = [];
      }
    });
  }

  get uploadCandidateSupplierAndHebConstants() {
    return UploadCandidateSupplierAndHebConstants;
  }

  hasColumn(column): boolean {
    return this.supplierAndHebHeaderColumns.includes(column);
  }

  onClickHeader(columnHeader) {
    switch (columnHeader) {
      case UploadCandidateSupplierAndHebConstants.SELLABLE: {
        this.editCandidateUtilService.showBulkEditAllCandidateModal(this.candidates, this.attributeType.Sellable, ['productType'], this.isSca);
        break;
      }
      case UploadCandidateSupplierAndHebConstants.BUYER: {
        // todo:
        break;
      }
      case UploadCandidateSupplierAndHebConstants.BRAND: {
        // todo:
        break;
      }
      case UploadCandidateSupplierAndHebConstants.SUB_BRAND: {
        // todo:
        break;
      }
      case UploadCandidateSupplierAndHebConstants.COST_OWNER: {
        // todo:
        break;
      }
      case UploadCandidateSupplierAndHebConstants.TOP_TO_TOP: {
        // todo:
        break;
      }
      case UploadCandidateSupplierAndHebConstants.SUPPLIER: {
        // todo:
        break;
      }
      case UploadCandidateSupplierAndHebConstants.WAREHOUSE_SUPPLIER: {
        // todo:
        break;
      }
      case UploadCandidateSupplierAndHebConstants.CHANNEL:
      case UploadCandidateSupplierAndHebConstants.UNIT_UPC:
      case UploadCandidateSupplierAndHebConstants.CONTACT: {
        console.log('Invalid edit all selection: ' + columnHeader.header);
        break;
      }
      default: {
        this.growlService.addError('Edit all column not implemented: ' + columnHeader.header);
      }
    }
  }
}
