import {Component, OnInit, ViewChild} from '@angular/core';
import {
  Attribute,
  AttributeConfig,
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  LocationGroupStores, NutritionPanel,
  Task,
  TaskDecision,
  TextInputType
} from 'pm-models';
import {WorkflowService} from '../../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {GrowlService} from '../../growl/growl.service';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {CostService} from '../../service/cost.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {SupplierProductService} from '../../service/supplier-product.service';
import {FileService} from '../../service/file.service';
import {AttributeTypes, ReviewComponent, UpcService} from 'pm-components';
import {AuthService} from '../../auth/auth.service';
import {MatUtilService} from '../../service/mat-util.service';
import {ProcessInstanceConstants} from '../../utils/constants/process-instance-constants';
import {LabelInsightService} from '../../service/label-insight.service';
import {finalize, tap} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-ob-reg-scale-plu-review',
  templateUrl: './ob-reg-scale-plu-review.component.html',
  styleUrls: ['./ob-reg-scale-plu-review.component.scss']
})
export class ObRegScalePluReviewComponent implements OnInit {

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public candidateUtilService: CandidateUtilService,
              public supplierProductService: SupplierProductService, public fileService: FileService,
              public authService: AuthService, public upcService: UpcService, public matUtilService: MatUtilService,
              public labelInsightService: LabelInsightService, public permissionService: NgxPermissionsService) { }

  @ViewChild(ReviewComponent) pmReview;

  public OB_REG_TASK_NAME = 'Key OB Reg Data';
  public isViewingPage = true;
  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  private taskSubscription$: any;
  private task: Task;
  public productImages = [];
  public labelInsightImages = [];
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public suppliers = new Map();
  public openCommentDrawer = false;
  public tempCommentHolder: string;
  public isRejectDisabled = false;
  public rejectMessage = 'Let us know why you\'re rejecting this candidate.';
  public mechanicallyTenderized = 'false';
  public forceTare = 'false';
  public isApproveDisabled = false;
  public openOwnBrandDetailDrawer = false;
  public isNutritionDrawerApproveDisabled = false;
  public nutritionResults: any[] = undefined;
  public ingredientsList: any[] = undefined;
  public allergensList: string[] = undefined;
  public servingSize: string;
  public servingsPerContainer: string;
  public scaleNutritionPanel: NutritionPanel;
  public openNutritionDrawer = false;
  public hierarchyNumberToAttributesMap: Map<number, Attribute[]> = new Map();
  showMatAttributes = false;
  isLoadingMatGlobalData = true;
  isLoadingMatHierarchyData = true;
  hierarchyAttributes: Attribute[] = [];
  globalAttributes: Attribute[] = [];
  productAttributes: Attribute[] = [];
  warehouseItemAttributes: Attribute[] = [];
  upcAttributes: Attribute[] = [];

  obRegComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'obRegCommentId',
    maxLength: 4000
  };

  ingredientStatementConfiguration: AttributeTextInputConfig = {
    label: 'Ingredient statement #',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '#',
    isRequired: true,
    name: 'ingredientStatementId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 5
  };

  nutritionStatemenToggleConfiguration: AttributeTextInputConfig = {
    label: 'Nutrition Statement',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    defaultValue: false,
    name: 'nutritionStatementToggleId',
  };

  nutritionStatementConfiguration: AttributeTextInputConfig = {
    label: 'Nutrition statement #',
    isDisabled: () => false,
    isHidden: () => this.candidateProduct.scaleInformation.nutritionStatementNumber === null ||
      this.candidateProduct.scaleInformation.nutritionStatementNumber === undefined,
    isReadOnly: () => true,
    isRequired: false,
    name: 'nutritionStatementId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 7
  };

  gradeConfiguration: AttributeTextInputConfig = {
    label: 'Grade #',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '#',
    isRequired: false,
    name: 'gradeId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 3
  };

  englishLabelOneConfiguration: AttributeTextInputConfig = {
    label: 'English label 1',
    description: `Enter a description for the first line of the scale printout.`,
    placeholderText: 'Line 1 description...',
    isHidden: () => false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'englishLabelDescriptionOne',
    charLimit: 32
  };

  englishLabelTwoConfiguration: AttributeTextInputConfig = {
    label: 'English label 2',
    description: `Enter a description for the second line of the scale printout.`,
    placeholderText: 'Line 2 description...',
    isHidden: () => false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'englishLabelDescriptionTwo',
    charLimit: 32
  };

  englishLabelThreeConfiguration: AttributeTextInputConfig = {
    label: 'English label 3',
    description: `Enter a description for the third line of the scale printout.`,
    placeholderText: 'Line 3 description...',
    isHidden: () => false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'englishLabelDescriptionThree',
    charLimit: 32
  };

  englishLabelFourConfiguration: AttributeTextInputConfig = {
    label: 'English label 4',
    description: `Enter a description for the fourth line of the scale printout.`,
    placeholderText: 'Line 4 description...',
    isHidden: () => false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'englishLabelDescriptionFour',
    charLimit: 32
  };


  labelFormatOneConfiguration: AttributeTextInputConfig = {
    label: 'Label format 1',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '#',
    isRequired: false,
    name: 'labelFormatOneId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 5
  };

  labelFormatTwoConfiguration: AttributeTextInputConfig = {
    label: 'Label format 2',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '#',
    isRequired: false,
    name: 'labelFormatTwoId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 5
  };

  isBilingualConfiguration: AttributeConfig = {
    label: 'Bilingual label?',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    defaultValue: false,
    name: 'isBilingualId'
  };

  englishLabelOneBilingualConfiguration: AttributeTextInputConfig = {
    label: 'English label 1 (bilingual)',
    description: `Enter a description for the first English line of the scale printout.`,
    placeholderText: 'Line 1 English description...',
    isHidden: () => false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'englishLabelDescriptionOneBilingual',
    charLimit: 32
  };

  englishLabelTwoBilingualConfiguration: AttributeTextInputConfig = {
    label: 'English label 2 (bilingual)',
    description: `Enter a description for the second English line of the scale printout.`,
    placeholderText: 'Line 2 English description...',
    isHidden: () => false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'englishLabelDescriptionTwoBilingual',
    charLimit: 32
  };

  spanishLabelOneConfiguration: AttributeTextInputConfig = {
    label: 'Spanish label 1',
    description: `Enter a description for the first Spanish line of the scale printout.`,
    placeholderText: 'Line 1 Spanish description...',
    isHidden: () => false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'spanishLabelDescriptionOne',
    charLimit: 32
  };

  spanishLabelTwoConfiguration: AttributeTextInputConfig = {
    label: 'Spanish label 2 ',
    description: `Enter a description for the second Spanish line of the scale printout.`,
    placeholderText: 'Line 2 Spanish description...',
    isHidden: () => false,
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'englishLabelDescriptionTwoBilingual',
    charLimit: 32
  };

  prePackTareConfiguration: AttributeTextInputConfig = {
    label: 'Pre-pack tare',
    isDisabled: () => false,
    isReadOnly: () => this.candidateProduct.scaleInformation.forceTare,
    isHidden: () => false,
    isRequired: false,
    name: 'prePackTare',
    decimalCount: 3,
    numberCount: 1,
    textInputType: TextInputType.decimal,
    placeholderText: '#',
    inputGroupClass: 'ui-medium-input',
  };

  serviceCounterTareConfiguration: AttributeTextInputConfig = {
    label: 'Service counter tare',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => false,
    isRequired: false,
    name: 'serviceCounterTareId',
    decimalCount: 3,
    numberCount: 1,
    textInputType: TextInputType.decimal,
    placeholderText: '#',
    inputGroupClass: 'ui-medium-input',
  };


  forceTareConfiguration: AttributeConfig = {
    label: 'Force tare',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    inputGroupClass: 'attribute-radios-block',
    name: 'forceTare',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ],
  };


  shelfLifeDaysConfiguration: AttributeTextInputConfig = {
    label: 'Shelf life days',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => false,
    isRequired: false,
    name: 'shelfLifeDays',
    maxLength: 3,
    textInputType: TextInputType.integer,
    placeholderText: 'Days',
    inputGroupClass: 'ui-medium-input',
  };

  mechanicallyTenderizedConfiguration: AttributeConfig = {
    label: 'Mechanically tenderize',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    inputGroupClass: 'attribute-radios-block',
    name: 'mechanicallyTenderized',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ],
  };

  actionCodeConfiguration: AttributeTextInputConfig = {
    label: 'Action code',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '#',
    isRequired: false,
    name: 'actionCodeId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 5
  };

  graphicsCode1Configuration: AttributeTextInputConfig = {
    label: 'Graphics code 1',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '#',
    isRequired: false,
    name: 'actionCodeId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 5
  };

  graphicsCode2Configuration: AttributeTextInputConfig = {
    label: 'Graphics code 2',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '#',
    isRequired: false,
    name: 'actionCodeId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 5
  };

  graphicsCode3Configuration: AttributeTextInputConfig = {
    label: 'Graphics code 3',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '#',
    isRequired: false,
    name: 'actionCodeId',
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-medium-input',
    maxLength: 5
  };

  netWeightConfiguration: AttributeTextInputConfig = {
    label: 'Net weight',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => false,
    isRequired: false,
    name: 'shelfLifeDays',
    decimalCount: 4,
    numberCount: 5,
    textInputType: TextInputType.decimal,
    placeholderText: 'lbs',
    inputGroupClass: 'ui-medium-input',
  };
  ngOnInit() {

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.OB_REG_TASK_NAME) {
              this.router.navigate(
                ['/tasks'],
                {
                  queryParams:
                    {
                      growlMessage: 'Candidate is not in ' + this.OB_REG_TASK_NAME + ' status.',
                      growlToUse: GrowlService.SEVERITY_ERROR
                    }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                this.costService.updateRetailLink(candidate).subscribe( (updatedCandidate) => {
                  this.setInitialValues(updatedCandidate);
                });
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  private setInitialValues(candidate: Candidate) {
    // Field migration for graphicsCode to graphicsCodeOne
    if (!!candidate.candidateProducts && !!candidate.candidateProducts.length) {
      candidate.candidateProducts.forEach(cp => {
        if (!!cp.scaleInformation && (cp.scaleInformation.graphicsCode !== undefined && cp.scaleInformation.graphicsCode !== null)) {
          cp.scaleInformation.graphicsCodeOne = cp.scaleInformation.graphicsCode;
          cp.scaleInformation.graphicsCode = null;
        }
      });
    }
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    if (this.candidateProduct.scaleInformation !== undefined) {
      this.mechanicallyTenderized = this.candidateProduct.scaleInformation?.mechanicallyTenderized ? 'true' : 'false';
      this.forceTare = this.candidateProduct.scaleInformation?.forceTare ? 'true' : 'false';
    }

    this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].labelInsightsImageLinks);

    if (this.candidate.dsdSwitch) {
      this.setAuthGroups();
    }

    if (this.candidate.obRegComment) {
      this.tempCommentHolder = this.candidate.obRegComment;
    }

    if (this.candidateUtilService.isScaleProduct(this.candidate)) {
      this.lookupService.findNutrition(this.candidateProduct.upc).subscribe((scaleNutritionPanel) => {
        this.scaleNutritionPanel = scaleNutritionPanel;
      });
    } else {
      this.buildNutrition();
    }
    this.setupMatAttributes();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatHierarchyData = false;
      this.isLoadingMatGlobalData = false;
      return;
    }
    this.showMatAttributes = true;
    this.matUtilService.setMatAttributeLists(this.candidate, this.globalAttributes, this.hierarchyAttributes).pipe(
      tap(() => {
        this.matUtilService.setHierarchyNumberToAttributesMap(this.hierarchyAttributes, this.hierarchyNumberToAttributesMap);
        this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.globalAttributes, this.productAttributes, this.warehouseItemAttributes, this.upcAttributes);
      }),
      finalize(() => {
        this.isLoadingMatHierarchyData = false;
        this.isLoadingMatGlobalData = false;
      })
    ).subscribe();
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.router.navigate(['/tasks']).then();
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']).then();
    }
  }

  getProductInfoString() {
    let infoString = '';

    infoString += this.candidate.retailSize + ' | ' + 'Inner case cost: ' +
      this.costService.toCurrencyForCost(this.candidate.innerListCost) + ' |  Unit cost: ' +
      this.costService.toCurrencyForCost(this.candidate.unitCost);

    if (this.originalCandidate.productType === 'SELLABLE') {
      infoString +=
        ' | Suggested Retail: '  +
        this.candidate.suggestedXFor + ' for ' + this.costService.toCurrency(this.candidate.suggestedRetailPrice);
    }

    return infoString;
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }

  showCasePackInfo() {
    return this.candidate.warehouseSwitch;
  }

  isDsdOnlyParms(candidate: Candidate): boolean {
    return candidate && candidate.dsdSwitch && !candidate.warehouseSwitch;
  }

  getVendor(vendorId: number) {
    return this.suppliers.get(vendorId);
  }


  loadVendors() {
    for (let x = 0; x < this.candidate.candidateProducts[0].locationGroupStores.length; x++) {
      this.lookupService.findDsdVendor(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber).subscribe(vendor => {
        this.suppliers.set(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber, vendor[0]);
      });
    }
  }

  setAuthGroups() {
    if (!this.supplierProductService.getAuthGroups()) {
      this.supplierProductService.findAllAuthGroupsByParms(this.candidate.vendor.apNumber, this.candidate.commodity.departmentId,
        this.candidate.commodity.subDepartmentId).subscribe((authGroups) => {
        this.supplierProductService.setAuthGroups(authGroups);
        this.loadVendors();
      });
    } else {
      this.loadVendors();
    }
  }

  getNumberOfStoresForGroup(groupId) {
    if (this.supplierProductService.authGroups) {
      for (let x = 0; x < this.supplierProductService.authGroups.length; x++) {
        if (this.supplierProductService.authGroups[x].splrLocationGroupId === groupId) {
          return this.supplierProductService.authGroups[x].stores.length;
        }
      }
    }
  }


  getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  getSellableString(): string {
    return this.candidate.productType === 'SELLABLE' ? 'Yes' : 'No';
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.buyerComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.pmReview.openDrawer();
  }


  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.obRegComment = this.tempCommentHolder;
    this.candidate.obRegName = this.authService.getUser();
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  onClickNext() {
    if (this.candidate.showCalories) {
      this.onOpenNutritionDrawer();
    } else {
      this.openOwnBrandDetailDrawer = true;
      this.pmReview.openDrawer();
    }
  }


  showPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  reject(event, panel, target) {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;

    this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE,
      TaskDecision.OB_REG_SCALE_PLU_REJECT_DECISION, 'Successfully rejected candidate.');
    panel.hide();
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.updateApNumber(this.candidate.vendor.apNumber, this.task.processInstanceId).subscribe(() => {
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
      });
    }, (error) => {
      this.growlService.addError(error);
      this.isRejectDisabled = false;
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Saves candidate.
   */
  save() {
    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  collapse() {
    this.openOwnBrandDetailDrawer = false;
    this.pmReview.closeDrawer();
  }

  mechanicallyTenderizedChange(event) {
    this.mechanicallyTenderized = event;
    this.candidateProduct.scaleInformation.mechanicallyTenderized = event === 'true';
  }

  forceTareChange(event) {
    this.forceTare = event;
    this.candidateProduct.scaleInformation.forceTare = event === 'true';
    if (this.candidateProduct.scaleInformation.forceTare) {
      this.candidateProduct.scaleInformation.prePackTare = 0.00;
    } else {
      this.candidateProduct.scaleInformation.prePackTare = null;
    }
  }

  onClickApprove() {
    this.isApproveDisabled = true;

    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    this.candidateService.validateCandidate(this.candidate, [CandidateValidatorType.SCALE_INFORMATION_VALIDATOR])
      .subscribe(() => {
        this.saveAndCompleteObRegTask();
      }, (error) => {
        if (error.error.candidateErrors) {
          this.candidateError = error.error.candidateErrors;

          this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message); // TODO: new way to handle server side errors?
        }
        this.isApproveDisabled = false;
      });

  }

  onClickNutritionDrawerApprove() {
    this.isNutritionDrawerApproveDisabled = true;

    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    // validation not needed because candidate was not changed
    this.saveAndCompleteObRegTask();

  }

  onClickScaleNutritionDrawerApprove() {
    this.openNutritionDrawer = false;
    this.openOwnBrandDetailDrawer = true;
  }

  onClickBackToNutrition() {
    this.openNutritionDrawer = true;
    this.openOwnBrandDetailDrawer = false;
  }

  /**
   * Calls workflow service to end the buyer task and then navigates back to the tasks page.
   */
  private saveAndCompleteObRegTask() {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.OB_REG_SCALE_PLU_APPROVE_DECISION, 'Successfully completed task.');
    });
  }


  isBilingualChange(event) {
    this.candidateProduct.scaleInformation.bilingual = event.checked;
    this.candidateProduct.scaleInformation.englishLabelOneBilingual = null;
    this.candidateProduct.scaleInformation.englishLabelTwoBilingual = null;
    this.candidateProduct.scaleInformation.spanishLabelOne = null;
    this.candidateProduct.scaleInformation.spanishLabelTwo = null;
  }

  nutritionStatementChange(e) {
    if (e.checked) {
      this.candidateProduct.scaleInformation.nutritionStatementNumber = this.candidateProduct.upc;
    } else {
      this.candidateProduct.scaleInformation.nutritionStatementNumber = null;
    }
  }


  /**
   * Returns the number of days as a string. If empty, it returns 0 Days.
   * @param days
   */
  getDaysString(days) {
    if (days) {
      return '' + days + ' Days';
    }
    return '0 Days';
  }

  onOpenNutritionDrawer() {
    this.openNutritionDrawer = true;
    this.pmReview.openDrawer();
  }

  collapseNutritionDrawer() {
    this.openNutritionDrawer = false;
    this.pmReview.closeDrawer();
  }

  public nutritionHeaderColumns = [
    {field: 'fieldName', header: 'Amount per Serving', sortable: false, width: '3%'},
    {field: 'fieldGrams', header: '            ', sortable: false, width: '20%'},
    {field: 'fieldValue', header: 'Daily Value*', sortable: false, width: '20%'}
  ];

  buildNutrition() {
    this.buildNutritionPanelHeader();
    this.buildIngredientsList();
    this.buildAllergensList();
    this.buildNutritionResults();
  }

  buildNutritionResults() {
    if (this.candidate.candidateNutrition !== undefined && this.candidate.candidateNutrition !== null
      && this.candidate.candidateNutrition.productNutrients !== undefined) {
      this.nutritionResults = [];
      for (let index = 0; index < this.candidate.candidateNutrition.productNutrients.length; index++) {
        if (this.candidate.candidateNutrition.productNutrients[index].nutrient === undefined) {
          continue;
        }
        const field = this.candidate.candidateNutrition.productNutrients[index].nutrient.description;
        const gramPerServing = this.candidate.candidateNutrition.productNutrients[index].nutrientQuantity;
        let dailyValue = this.candidate.candidateNutrition.productNutrients[index].dailyValuePercent;
        if (dailyValue === undefined) {
          dailyValue = ' ';
        }
        this.nutritionResults.push({'field' : field, 'gramPerServing' : gramPerServing, 'dailyValue' : dailyValue});
      }
    }
  }
  buildNutritionPanelHeader() {
    if (this.candidate.candidateNutrition !== undefined && this.candidate.candidateNutrition !== null
      && this.candidate.candidateNutrition.nutritionPanelHeader !== undefined) {
      this.servingSize = this.candidate.candidateNutrition.nutritionPanelHeader.servingSize;
      this.servingsPerContainer = this.candidate.candidateNutrition.nutritionPanelHeader.servingsPerContainer;
    }
  }

  buildIngredientsList() {
    if (this.candidate.candidateNutrition !== undefined && this.candidate.candidateNutrition !== null
      && this.candidate.candidateNutrition.ingredients !== undefined) {
      this.ingredientsList = [];
      for (let index = 0; index < this.candidate.candidateNutrition.ingredients.length; index++) {
        this.ingredientsList.push(this.candidate.candidateNutrition.ingredients[index].textValue);
      }
    }
  }

  buildAllergensList() {
    if (this.candidate.candidateNutrition !== undefined && this.candidate.candidateNutrition !== null
      && this.candidate.candidateNutrition.productAllergens !== undefined) {
      this.allergensList = [];
      for (let index = 0; index < this.candidate.candidateNutrition.productAllergens.length; index++) {
        this.ingredientsList.push(this.candidate.candidateNutrition.productAllergens[index].display);
      }
    }
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        if (response.showCalories !== this.candidate.showCalories) {
          this.candidate = response;
          // check if show calories is changed to false
          if (!response.showCalories) {
            // if scale set to false and save
            if (this.candidateUtilService.isScaleProduct(this.candidate)) {
              this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
                this.candidate = savedCandidate;
                this.openOwnBrandDetailDrawer = true;
              });
            } else {
              // not scale use endpoint
              const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;

              this.workflowService.deleteProcessInstanceAndCreateNewFlow(this.task.processInstanceId, this.candidate.candidateId,
                ProcessInstanceConstants.PIA_NEW_PRODUCT_WORKFLOW,
                apNumber).subscribe((task) => {
                this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
                  this.candidate = savedCandidate;
                  this.router.navigate(['/tasks'],
                    { queryParams: { growlMessage: 'Successfully removed the candidate', growlToUse: GrowlService.SEVERITY_SUCCESS }}).then();
                });
              });
            }
          } else {
            // set show calories to true and save, fetch gdsn nutrition
            this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
              // set nutrition drawer to open.
              this.candidate = savedCandidate;
              if (this.openOwnBrandDetailDrawer) {
                this.openOwnBrandDetailDrawer = false;
              }
              this.openNutritionDrawer = true;
            });
          }
        }
      }
    });
  }

  get attributeType() {
    return AttributeTypes;
  }

  removeNutrition(panel) {
    // if scale set to false and save
    if (this.candidateUtilService.isScaleProduct(this.candidate)) {
      this.candidate.showCalories = false;
      this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
        this.candidate = savedCandidate;
        this.openOwnBrandDetailDrawer = true;
        panel.hide();
      });
    } else {
      // not scale use endpoint
      const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
      this.candidate.showCalories = false;

      this.workflowService.deleteProcessInstanceAndCreateNewFlow(this.task.processInstanceId, this.candidate.candidateId,
        ProcessInstanceConstants.PIA_NEW_PRODUCT_WORKFLOW,
        apNumber).subscribe((task) => {
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          this.candidate = savedCandidate;
          panel.hide();
          this.router.navigate(['/tasks'],
            { queryParams: { growlMessage: 'Successfully removed the candidate', growlToUse: GrowlService.SEVERITY_SUCCESS }}).then();
        });
      });
    }
  }
}
