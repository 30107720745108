import {Store} from 'pm-models/lib/store';

export class LocationGroupStores {

  splrLocationGroupId: number;

  stores: Store[];

  listCost?: number;

  configuration?: any;

  checked?: boolean;

  isViewing?: Boolean;

  splrLocationNumber: number;
}
