<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-title>Create a UPC for billing</pm-title>

  <div class="col-md-12 mainBody" style="margin-top: 25px;">
    <div class="row">
      <label class="ml-4">What type of UPC do you need?</label>
    </div>

    <div class="col-md-10 mb-4">
      <div class="row">
        <div class="col-md-4 ml-2 pl-0 black-border">
          <div class="mt-1">
            <div class="row m-0">
              <p-radioButton [id]="'freightUpcButton'"
                             class="mt-0 ml-1 attribute-radio"
                             [(ngModel)]="upcType"
                             value="freight"
                             (onClick)="onClickFreight()">
              </p-radioButton>
              <div style="font-weight: bold;">
                <label class="ml-1">Freight UPC</label>
              </div>
            </div>
            <p class="attribute-description ml-4">
              For freight charges
            </p>
          </div>
        </div>
        <div class="col-md-4 ml-2 pl-0 black-border">
          <div class="mt-1">
            <div class="row m-0">
              <p-radioButton [id]="'taxUpcButton'"
                             class="mt-0 ml-1 attribute-radio"
                             [(ngModel)]="upcType"
                             value="tax"
                             (onClick)="onClickTax()">
              </p-radioButton>
              <div style="font-weight: bold;">
                <label class="ml-1">Tax UPC</label>
              </div>
            </div>
            <p class="attribute-description ml-4">
              For sales tax
            </p>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>

    <div *ngIf="upcType">

      <div class="col-md-10 mb-4">
        <div class="row">
          <label class="ml-2">{{upcLabel}}</label>
        </div>
        <div class="row">
          <pm-upc-input class="ml-2"
            [(upcModel)]="candidate.candidateProducts[0].upc"
            [(checkDigitModel)]="candidate.candidateProducts[0].upcCheckDigit"
            [readOnly]="false"
            [disabled]="true"
            [hasError]="candidateError.description"
            [readOnlyCheckDigit]="true">
          </pm-upc-input>
        </div>
        <div *ngIf="candidateError.description" class="row attribute-error-message">
          <label class="ml-2">{{candidateError.description}}</label>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-6 attributes-stacked">

          <pm-attribute-display-typeahead [attribute]="supplierConfiguration" [(model)]="candidate.vendor"
                                          [attributeError]="candidateError.vendor" (selection)="onSupplierChange($event)">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-text-input [attribute]="vendorProductCodeConfiguration" [(model)]="candidate.candidateProducts[0].vendorProductCode"
                                           [attributeError]="candidateError?.candidateProductErrors[candidate.candidateProducts[0].id]?.vendorProductCode">
          </pm-attribute-display-text-input>

          <pm-attribute-display-typeahead [attribute]="costOwnerConfiguration" [(model)]="candidate.costOwner"
                                          [attributeError]="candidateError?.costOwner" (selection)="costOwnerChange($event)">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-text-input [attribute]="topToTopConfiguration" [model]="topToTopModel">
          </pm-attribute-display-text-input>

          <div *ngIf="candidate.vendor">
            <pm-attribute-display-typeahead [attribute]="buyerConfiguration" [(model)]="candidate.buyer"
                                            [attributeError]="candidateError.buyer" (selection)="onBuyerChange($event)">
            </pm-attribute-display-typeahead>
          </div>

          <div *ngIf="candidate.buyer">
            <pm-attribute-display-typeahead [attribute]="commodityConfiguration"
                                            [(model)]="candidate.commodity"
                                            [attributeError]="candidateError?.commodity"
                                            (selection)="commodityChange($event)">
            </pm-attribute-display-typeahead>

            <div style="width: 95%;">
              <pm-no-stores-message-extended *ngIf="showNoStoresError"
                                             [candidate]="candidate">
              </pm-no-stores-message-extended>
            </div>

            <pm-attribute-display-typeahead [attribute]="subCommodityConfiguration"
                                            [(model)]="candidate.subCommodity"
                                            [attributeError]="candidateError?.subCommodity"
                                            (selection)="subCommodityChange($event)">
            </pm-attribute-display-typeahead>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4 ml-2 mb-0">
          <label>Set the list cost.<span style="color: #e32526">*</span></label>
          <input [class]="getMasterListCostClass()" type="number" placeholder="$" pInputText [(ngModel)]="candidate.masterListCost"/>
        </div>
      </div>
      <div *ngIf="candidateError.masterListCost" class="row">
        <div class="col-4">
          <label class="ml-2 attribute-error-message">{{candidateError.masterListCost}}</label>
        </div>
      </div>

      <div class="row mb-4"></div>

    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Cancel" (onClick)="onClose()"></p-button>
    <p-button class="m-2" label="Activate" [disabled]="!areAllRequiredFieldsFilledOut()" (onClick)="onClickActivate()"></p-button>
  </pm-footer>
</pm-editor>
