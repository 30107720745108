import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class MasterListCost implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Master list cost',
      description: `How much H-E-B will pay for the entire master case pack.
      We'll calculate values for the fields below based on this value.`,
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      placeholderText: `List cost`,
      textInputType: TextInputType.decimal,
      inputGroupClass: 'ui-narrow-input',
      numberCount: 7,
      decimalCount: 4,
      name: 'masterListCostId',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidate.masterListCost;
    componentInstance.modelChange.subscribe(x => {
      candidate.masterListCost = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.masterListCost) {
      componentInstance.model = candidate.masterListCost;
    }
  }
}
