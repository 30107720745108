export class NutritionDetail {
  id: number;
  sequence: number;
  description: string;
  value: string;
  percentDailyValue: string;
  externalId: string;
  nutrientValue: number;
  nutrientUnitOfMeasure: string;
  nutrientPercentDailyValue: number;
  panelSection: any;
  lessThan: Boolean;
}
