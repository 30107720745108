import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeConfig, Candidate, CandidateError, CostLinkCardModel, CostLinkCardPermissions} from 'pm-models';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper} from '../attribute-permissions-mapper';
import {InputState} from '../../shared/attribute-display/attribute-display-muli-source-input/attribute-display-muli-source-input.component';

@Component({
  selector: 'pm-cost-link-card',
  templateUrl: './cost-link-card.component.html',
  styleUrls: ['./cost-link-card.component.scss']
})
export class CostLinkCardComponent implements OnInit {
  @Input()
  model: CostLinkCardModel | Candidate;

  @Output() modelChange = new EventEmitter<any>();

  @Output() validateCostLink = new EventEmitter<any>();

  @Input()
  permissions: CostLinkCardPermissions;

  @Input()
  errorModel: CandidateError;

  @Input()
  costLinkState: InputState;

  private costLinkOptions: any[];
  public costLinkedConfiguration: AttributeConfig;

  constructor() {}

  ngOnInit() {
    this.costLinkOptions = this.getCostLinkOptions();
    this.costLinkedConfiguration = this.getCostLinkedConfiguration();
  }

  getCostLinkOptions(): any[] {
    if (!this.model) {
      return [];
    } else if (this.model.warehouseSwitch) {
      return [
        { value: 'LINK', label: 'Link #' },
        { value: 'ITEM', label: 'Item Code' },
        { value: 'UPC', label: 'UPC' }
      ];
    } else {
      return [
        { value: 'LINK', label: 'Link #' },
        { value: 'UPC', label: 'UPC' }
      ];
    }
  }


  isCostLinkedConfiguration: AttributeConfig = {
    label: 'Cost linked',
    description:
      'Setting up a cost link lets you quickly manage cost changes for multiple linked items or cases in our Cost tool.',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.costLinked),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.costLinked),
    isRequired: false,
    defaultValue: false,
    name: 'costLinkId'
  };

  isCostLinkedChange(e) {
    this.model.costLinked = e.checked;
    if (this.model.costLinked === false) {
      this.model.costLink = null;
      this.model.costLinkBy = null;
      this.model.costLinkFromServer = null;
    } else {
      this.model.costLinkBy = 'LINK';
    }
    this.modelChange.emit(this.model);
  }

  getCostLinkedConfiguration(): AttributeConfig {
    return {
      options: this.costLinkOptions,
      label: 'Cost linked item',
      description: 'Search by cost link number or item code to find the cost link.',
      isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.costLinkBy),
      isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.costLinkBy),
      isHidden: () => !this.model.costLinked,
      placeholderText: 'Search for cost link...',
      isRequired: true,
      defaultValue: false,
      name: 'costLinkId'
    };
  }

  onMultiSelectChange(e) {
    if (this.model.costLinked) {
      this.model.costLinkBy = e;
    } else {
      this.model.costLinkBy = null;
    }
  }

  onCostLinkChange(e) {
    this.model.costLink = e.model;
    this.model.costLinkBy = e.category;
  }

  validateCostLinkEvent(e) {
    this.validateCostLink.emit(e);
  }
}
