<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4">
    <div *ngIf="lengthHelpVisible" class="helper-image" [style.top]="lengthHelperOffset">
      <pm-card>
        <div class="row">
          <div class="col-6">
            <img src="assets/images/pam-dru-wide.png" class="img-fluid" />
          </div>
          <div class="col-6">
            <h3>Wide</h3>
            <p>How many retail units are facing-forward, toward the aisle?</p>
            <p>This example is 2 retail units wide.</p>
          </div>
        </div>
      </pm-card>
    </div>
    <div *ngIf="widthHelpVisible" class="helper-image" [style.top]="widthHelperOffset">
      <pm-card>
        <div class="row">
          <div class="col-6">
            <img src="assets/images/pam-dru-deep.png" class="img-fluid" />
          </div>
          <div class="col-6">
            <h3>Deep</h3>
            <p>How many retail units are in one row?</p>
            <p>This example is 3 retail units deep.</p>
          </div>
        </div>
      </pm-card>
    </div>
    <div *ngIf="heightHelpVisible" class="helper-image" [style.top]="heightHelperOffset">
      <pm-card>

        <div class="row">
          <div class="col-6">
            <img src="assets/images/pam-dru-heigh.png" class="img-fluid" />
          </div>
          <div class="col-6">
            <h3>High</h3>
            <p>
              Are retail units stacked in columns? How many units per column?
            </p>
            <p>
              This example is 2 retail units high.
            </p>
          </div>
        </div>
      </pm-card>
    </div>
    <!-- <div *ngIf="orientationHelpVisible" class="helper-image"> -->
    <div *ngIf="showOrientationHelp" class="">
      <pm-card>
        <h3>Orientation</h3>
        <div class="row">
          <div class="col-6">
            <img src="assets/images/dru-facing-only-covered-highlight.png" class="img-fluid" />
          </div>
          <div class="col-6">
            <h4>1 side</h4>
            <p>
              Shoppable from only one side.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <img src="assets/images/dru-facing-or-side-highlight.png" class="img-fluid" />
          </div>
          <div class="col-6">
            <h4>2+ sides</h4>
            <p>
              Shoppable from 2 or more adjacent sides.
            </p>
          </div>
        </div>

      </pm-card>
    </div>
  </div>
</div>



<ng-template #card>
  <pm-card title="Display ready unit" subtitle="Let us know if this case contains display ready units.">
    <ng-container *ngIf="model">



      <pm-attribute-display-toggle [attribute]="isDisplayReadyUnitConfiguration" [(model)]="model.displayReadyUnit"
        (change)="isDisplayReadyUnitChange($event)" [attributeError]="errorModel?.displayReadyUnit">
      </pm-attribute-display-toggle>

      <pm-attribute-display-volume [attribute]="retailUnitCountConfiguration"
        [(lengthModel)]="model.displayReadyUnitRowsFacing" [(widthModel)]="model.displayReadyUnitRowsDeep"
        [(heightModel)]="model.displayReadyUnitRowsHigh" (volumeModelChange)="volumeCalc($event)"
        (lengthFocus)="toggleLengthHelp($event, true)" (lengthBlur)="toggleLengthHelp($event, false)"
        (widthFocus)="toggleWidthHelp($event, true)" (widthBlur)="toggleWidthHelp($event, false)"
        (heightFocus)="toggleHeightHelp($event, true)" (heightBlur)="toggleHeightHelp($event, false)"
        [attributeWidthError]="errorModel?.displayReadyUnitRowsDeep"
        [attributeHeightError]="errorModel?.displayReadyUnitRowsHigh"
        [attributeLengthError]="errorModel?.displayReadyUnitRowsFacing"
                                   [divisor]="1">
      </pm-attribute-display-volume>

      <pm-attribute-display-radio [attribute]="orientationConfiguration" [(model)]="model.displayReadyUnitOrientation" (change)="orientationChange($event)" [attributeError]="errorModel?.displayReadyUnitOrientation">
        <div class="orientation-toggle" *ngIf="!(displayReadyUnitOrientation?.isReadOnly && displayReadyUnitOrientation?.isReadOnly())">
          <button class="btn btn-link" type="button" (click)="toggleOrientationHelp()">{{showOrientationHelp ? 'Hide examples' : 'Show examples'}}</button>
        </div>
      </pm-attribute-display-radio>

      <pm-attribute-display-dropdown [attribute]="druTypeConfiguration" [(model)]="model.displayReadyUnitType"
                                     (change)="druTypeChange($event)" [attributeError]="errorModel?.displayReadyUnitType">
      </pm-attribute-display-dropdown>
    </ng-container>

  </pm-card>
</ng-template>
