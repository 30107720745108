import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  MerchandisingInfoCardPermissions,
  PharmacyCardPermissions,
  ProductDescriptionsCardPermissions,
  ProductImagesCardPermissions,
  ProductPackagingCardPermissions,
  ProductPricingCardPermissions,
  RegulatoryCardPermissions,
  ScaleInformationCardPermissions
} from 'pm-models';
import {AuthService} from '../../auth/auth.service';
import {GrowlService} from '../../growl/growl.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {PharmProductService} from '../../service/pharm-product.service';
import {WorkflowService} from '../../service/workflow.service';
import {LabelProductImagesCardPermissions} from 'pm-models/lib/card-models/label-product-images-model';

@Component({
  selector: 'app-pharm-new-product-details',
  templateUrl: './pharm-new-product-details.component.html',
  styleUrls: ['./pharm-new-product-details.component.scss']
})
export class PharmNewProductDetailsComponent implements OnInit {

  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public KEY_RETAIL = 'Key Retail';

  public isViewingPage = true;
  private taskSubscription$: any;
  public candidate: Candidate;
  public currentCandidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;

  public unitsOfMeasures: any;
  public packageTypes: any;
  public merchandiseTypes: any;

  public drugNameAbbreviations: any;
  public drugScheduleTypeCodes: any;
  public pseTypes: any;

  readonly productDescriptionsCardPermissions: ProductDescriptionsCardPermissions = {
    isReadOnly: false,
    customerFriendlyDescription1: {
      isHidden: true
    },
    customerFriendlyDescription2: {
      isHidden: true
    },
    romanceCopy: {
      isHidden: true
    }
  };

  readonly productPricingCardPermissions: ProductPricingCardPermissions = {
    isReadOnly: false,
    prePrice: {
      isHidden: true
    }
  };

  readonly productPackagingCardPermissions: ProductPackagingCardPermissions = {
    isReadOnly: false,
    unitOfMeasure: {
      isHidden: true
    },
    totalVolume: {
      isHidden: true
    }
  };

  readonly productImagesCardPermissions: ProductImagesCardPermissions = {
    isReadOnly: false
  };

  readonly labelInsightsImagesCardPermissions: LabelProductImagesCardPermissions = {
    isReadOnly: false
  };

  readonly merchandisingInfoCardPermissions: MerchandisingInfoCardPermissions = {
    isReadOnly: false,
    pssDepartment: {
      isDisabled: false
    },
    commodity: {
      isHidden: true
    },
    subCommodity: {
      isHidden: true
    }
  };

  readonly regulatoryCardPermissions: RegulatoryCardPermissions = {
    isReadOnly: false,
    showCalories: {
      isHidden: true
    }
  };

  readonly scaleInformationCardPermissions: ScaleInformationCardPermissions = {
    isReadOnly: false
  };

  readonly pharmacyCardPermissions: PharmacyCardPermissions = {
    isReadOnly: false
  };

  constructor(public route: ActivatedRoute, public router: Router, public pharmProductService: PharmProductService,
              public authService: AuthService, public candidateService: CandidateService,
              public candidateUtilService: CandidateUtilService, public lookupService: LookupService,
              public growlService: GrowlService, public workflowService: WorkflowService) { }

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      let taskId;
      if (this.pharmProductService.getTaskId()) {
        taskId = this.pharmProductService.getTaskId();
      } else if (this.pharmProductService.getTask() && this.pharmProductService.getTask().id) {
        taskId = this.pharmProductService.getTask().id;
      }
      if (params.keys.length > 0 && (!(taskId && params.has('taskId') && params['params']['taskId'] === taskId))) {
        this.pharmProductService.resetService();
        this.pharmProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.PLU) {
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']).then();
          }
        });
      } else if (this.pharmProductService.getOriginalCandidate() && this.pharmProductService.getCandidate()) {
        this.initializeData();
        // this.candidate.contactName = this.authService.getUser(); // tODO:s
      }  else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  initializeData() {
    this.candidate = this.pharmProductService.getCandidate();

    // defaults for Pharmacy
    this.candidate.unitOfMeasure = {
      'unitOfMeasureId': 'D ',
      'description': 'EACH'
    };
    this.candidate.totalVolume = '1.0';
    this.candidate.suggestedXFor = 1;

    this.originalCandidate = this.pharmProductService.getOriginalCandidate();
    this.currentCandidateProduct = this.pharmProductService.getCurrentCandidateProduct();
    this.candidateError = this.pharmProductService.getProductDetailsError();
    this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }

    this.lookupService.findAllDrugNameAbbreviations().subscribe(drugNameAbbreviations => {
      this.drugNameAbbreviations = drugNameAbbreviations;
    });

    this.lookupService.findAllDrugScheduleTypeCodes().subscribe(drugScheduleTypeCodes => {
      this.drugScheduleTypeCodes = drugScheduleTypeCodes;
    });

    this.lookupService.findAllPseTypes().subscribe(pseTypes => {
      this.pseTypes = pseTypes;
    });
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.originalCandidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.originalCandidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      // Default to basic for sellable (if there's no merchandise type).
      if (!this.originalCandidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
    if (!isSellable) {
      this.setNonSellableRetailValues();
    }
  }
  onClose() {
    this.isViewingPage = false;
    this.pharmProductService.setCandidate(this.candidate);
    this.pharmProductService.saveCandidateAndClose();
  }

  onClickNext() {
    const validators = [CandidateValidatorType.PHARM_PRODUCT_DETAILS_VALIDATOR];

    // CFD1 & romance copy always matches description for Pharmacy products
    this.candidate.candidateProducts[0].customerFriendlyDescription1 = this.candidate.candidateProducts[0].description;
    this.candidate.candidateProducts[0].romanceCopy = this.candidate.candidateProducts[0].description;

    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.candidate, validators).toPromise().then(() => {
      this.candidateError = this.pharmProductService.setProductDetailsError(new CandidateError());
      this.candidateProductError = new CandidateProductError();
      this.pharmProductService.setCandidate(this.candidate);
      this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductCaseDetails', false);
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.candidateError = this.pharmProductService.setProductDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.pharmProductService.getCurrentCandidateProductError(this.candidateError);
        this.pharmProductService.setCandidate(this.candidate);
        this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductCaseDetails', false);
      }
    });
  }

  onClickBack() {
    this.pharmProductService.setCandidate(this.candidate);
    this.pharmProductService.saveCandidateAndNavigate('/pharmNewProductSupplierDetails', false);
  }

  /**
   * Sets non sellable default values.
   */
  private setNonSellableRetailValues() {
    this.originalCandidate.retailXFor = 1;
    this.originalCandidate.retailPrice = '0.00';
    this.originalCandidate.retailType = this.KEY_RETAIL;
  }

  public onPharmacyModelChange(event) {
  }

  /**
   * Sets the vertex tax category model.
   */
  setVertexTaxCategoryModel() {
    this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe((candidate) => {
      this.candidate = candidate;
    });
  }
}
