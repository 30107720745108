import {Component, OnInit} from '@angular/core';
import {
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  CandidateError,
  CandidateValidatorType,
  DistributionChannelPermissions,
  TextInputType
} from 'pm-models';
import {CandidateInnerError} from 'pm-models/lib/candidateInnerError';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {CandidateInner} from 'pm-models/lib/candidateInner';
import {LookupService} from '../../service/lookup.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {PiaMrtInnerStepperComponent} from '../pia-mrt-inner-stepper/pia-mrt-inner-stepper.component';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-pia-add-new-mrt-case',
  templateUrl: './pia-add-new-mrt-case.component.html',
  styleUrls: ['./pia-add-new-mrt-case.component.scss']
})
export class PiaAddNewMrtCaseComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private growlService: GrowlService, private lookupService: LookupService,
              public candidateUtilService: CandidateUtilService, private permissionService: NgxPermissionsService) {
  }

  public currentCandidateInner: CandidateInner;
  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateInnerError: CandidateInnerError;
  public isViewingPage = true;
  public selectedValue = '';
  private availableWarehouseSuppliers: any;
  public isReplenishable: boolean;

  radiosChoices = [
    {
      value: 'replenishable',
      label: 'Add a new case',
      subText: 'I may also send this item to H-E-B in its own case'
    },
    {
      value: 'nonReplenishable',
      label: 'Use only the MRT case',
      subText: 'I only plan to send this item to H-E-B in the MRT case.'
    }
  ];

  unitCostConfiguration: AttributeTextInputConfig = {
    label: '',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    placeholderText: `Enter unit cost`,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    numberCount: 7,
    decimalCount: 2,
    name: 'unitCostId'
  };

  warehouseSupplierConfiguration: AttributeTypeaheadConfig;

  channelPermissions: DistributionChannelPermissions = {
    isReadOnly: false,
    dsdSwitch: {
      isDisabled: true
    }
  };

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {

        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId() ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          // if the candidate is an mrt, and contains the inner set the inner candidate.
          if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).subscribe((innerCandidate) => {
              this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
              this.initializeData();
            });
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierMrtService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  /**
   * Sets available selectable cost owners
   */
  private setWarehouseSupplier() {
    if (!this.supplierMrtService.getInnerCandidate()?.vendor?.apNumber) {
      this.warehouseSupplierConfiguration = {
        label: 'Select the warehouse supplier. This is the pick up location.',
        description: '',
        isRequired: false,
        isDisabled: () => true,
        isReadOnly: () => false,
        isHidden: () => false,
        collections: this.availableWarehouseSuppliers,
        displayRef: 'name',
        name: 'warehouseId',
        placeholderText: 'Select a warehouse...',
      };
    } else {
      this.lookupService.findSingleVendor(this.supplierMrtService.getInnerCandidate().vendor.apNumber).subscribe(data => {
        this.availableWarehouseSuppliers = data.lanes;
        this.warehouseSupplierConfiguration = {
          label: 'Select the warehouse supplier. This is the pick up location.',
          description: '',
          isRequired: false,
          isDisabled: () => false,
          isReadOnly: () => false,
          isHidden: () => false,
          collections: this.availableWarehouseSuppliers,
          displayRef: 'name',
          name: 'warehouseId',
          placeholderText: 'Select a warehouse...',
        };
      });
    }
  }


  async initializeData() {
    this.setCandidateInner();
    this.candidateError = this.supplierMrtService.getInnerCasePackError(this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.candidateError) {
      this.candidateInnerError =
        this.candidateError.mrtInfoError.candidateInnerErrors[this.supplierMrtService.getInnerCandidate().candidateId];
    }
    await this.setMrtInnerCandidates();
    this.setWarehouseSupplier();
  }


  setCandidateInner() {
    for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
      if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidateId ===
        this.supplierMrtService.getInnerCandidate().candidateId) {
        this.currentCandidateInner = this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x];
        if (this.currentCandidateInner.replenishable) {
          this.isReplenishable = true;
          this.selectedValue = 'replenishable';
        } else if (this.currentCandidateInner.replenishable === false) {
          this.isReplenishable = false;
          this.selectedValue = 'nonReplenishable';
        }
        return;
      }
    }
  }


  async setMrtInnerCandidates() {
    if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners &&
      this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length > 0) {
      for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
        if (!this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate) {
          this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidate = await this.candidateService.getCandidate(
            this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidateId).toPromise();
        }
      }
    }
  }

  onClickNext() {
    if (this.currentCandidateInner.replenishable) {
      this.jumpToPage('/piaInnerCaseDetails');
    } else if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.jumpToPage('/piaMrtInnerExtendedAttributes');
    } else {
      this.jumpToPage('/piaSetupNewMrtItems');
    }
  }


  onClose() {
    this.supplierMrtService.onInnerClose();
  }

  onClickSelection() {
    if (this.selectedValue === 'replenishable') {
      this.currentCandidateInner.replenishable = true;
      this.isReplenishable = true;
      this.currentCandidateInner.unitCost = null;
      this.supplierMrtService.getInnerCandidate().lane = null;
      this.supplierMrtService.getInnerCandidate().candidateProducts[0].caseUpc = null;
      this.supplierMrtService.getInnerCandidate().candidateProducts[0].caseUpcCheckDigit = null;
    } else {
      this.isReplenishable = false;
      this.currentCandidateInner.replenishable = false;
      this.supplierMrtService.setNonReplenishableValues();
    }
  }

  showUnitCost() {
    return this.currentCandidateInner && this.currentCandidateInner.replenishable === false;
  }

  showWarehouseSupplier() {
    return this.currentCandidateInner && this.currentCandidateInner.replenishable === true;
  }


  onClickBack() {
    this.jumpBack('/piaInnerProductDetails');
  }

  warehouseSupplierChange(event) {
    if (event === undefined) {
      this.supplierMrtService.getInnerCandidate().lane = null;
      this.supplierMrtService.getInnerCandidate().vendor = null;
      return;
    }
    this.supplierMrtService.getInnerCandidate().lane = event;
    this.supplierMrtService.getInnerCandidate().vendor = this.supplierMrtService.getInnerCandidate().lane.vendor;
    this.supplierMrtService.getInnerCandidate().candidateProducts[0].warehouses = null;
  }


  channelChange() {
    this.supplierMrtService.setCandidate(this.candidateUtilService.handleChannelChange(this.supplierMrtService.getInnerCandidate()));
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case PiaMrtInnerStepperComponent.SUPPLIER_HEB_SETUP: {
        this.jumpBack('/piaSupplierAndHebDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.PRODUCT_DETAILS: {
        this.onClickBack();
        break;
      }
      case PiaMrtInnerStepperComponent.CASE_DETAILS: {
        this.onClickNext();
        break;
      }
      case PiaMrtInnerStepperComponent.WAREHOUSE: {
        this.jumpToPage('/piaMrtInnerWarehouseDetails');
        break;
      }
      case PiaMrtInnerStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/piaMrtInnerExtendedAttributes');
      }
    }
  }

  jumpToPage(urlToNavigate) {
    let validators: CandidateValidatorType[];
    if (this.currentCandidateInner.replenishable) {
      validators = [CandidateValidatorType.LANE_VALIDATOR, CandidateValidatorType.DISTRIBUTION_CHANNEL_VALIDATOR];
    } else {
      validators = [CandidateValidatorType.UNIT_COST_VALIDATOR];
    }

    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), validators).subscribe(() => {
      this.candidateError = this.supplierMrtService.setInnerCasePackError(this.supplierMrtService.getInnerCandidate().candidateId, new CandidateError());
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), urlToNavigate,
        {
          queryParams: {
            taskId: this.supplierMrtService.getTaskIdString(),
            candidateId: this.supplierMrtService.getInnerCandidate().candidateId
          }
        }, false, true);
    }, (error) => {
      if (error.error?.candidateErrors) {
        this.candidateError = this.supplierMrtService.setInnerCasePackError(this.supplierMrtService.getInnerCandidate().candidateId,
          error.error.candidateErrors);
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), urlToNavigate,
          {
            queryParams: {
              taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId
            }
          }, false, true);
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  jumpBack(urlToNavigate) {
    this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), urlToNavigate,
      {
        queryParams: {
          taskId: this.supplierMrtService.getTaskIdString(),
          candidateId: this.supplierMrtService.getInnerCandidate().candidateId
        }
      }, false, this.supplierMrtService.hasInnerChanged());
  }

  unitCostChange(event) {
    this.supplierMrtService.getInnerCandidate().unitCost = event;
    // the bdm and pia see the unit cost as master list cost but activation needs unit cost
    this.supplierMrtService.getInnerCandidate().masterListCost = event;
  }

  getMissingCaseError() {
    if (this.candidateInnerError?.replenishable) {
      return this.candidateInnerError?.replenishable;
    } else if (this.candidateError?.unitCost &&
      (this.currentCandidateInner.replenishable === null || this.currentCandidateInner.replenishable === undefined)) {
      return 'Please select whether to add a case or not.';
    } else {
      return null;
    }
  }
}
