<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pia-new-product-flow-stepper *ngIf="candidate && !piaProductService.isDsdOnly()"
                                      [candidateErrors]="piaProductService.getAllWhsCandidateErrors()"
                                      [stepIndex]="3"
                                      [isSellable]="candidateUtilService.isSellable(candidate)"
                                      (indexClick)="onClickStepper($event)">
    </app-pia-new-product-flow-stepper>
    <app-pia-new-dsd-product-flow-stepper *ngIf="candidate && piaProductService.isDsdOnly()"
                                          [candidateErrors]="piaProductService.getAllDsdCandidateErrors()"
                                          [stepIndex]="3"
                                          [isSellable]="candidateUtilService.isSellable(candidate)"
                                          (indexClick)="onClickStepper($event)">
    </app-pia-new-dsd-product-flow-stepper>
  </pm-header>

  <!-- Page Titles -->
  <pm-title>Tell us about this product.</pm-title>
  <pm-sub-title *ngIf="candidate">
    <div *ngIf="!candidateUtilService.isPlu(candidate)" class="upc-header">
      UPC: {{candidate.candidateProducts[0].upc}}-{{candidate.candidateProducts[0].upcCheckDigit}}
    </div>
    <div *ngIf="candidateUtilService.isPlu(candidate)" class="upc-header">
      <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
        PLU: {{candidate.candidateProducts[0].upc | upc}}-{{candidate.candidateProducts[0].upc | upcCheckDigit}}<br>
      </div>
      <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
        UPC: {{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc)}}-{{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc) | upcCheckDigit}}
      </div>
    </div>
  </pm-sub-title>
  <!-- Page Cards -->
  <!--
    We're using  an ng-container to not add additional
    DOM elements and only show the cards when the candidate is loaded
  -->
  <ng-container *ngIf="candidate">

    <pm-product-descriptions-card *ngIf="candidateUtilService.isSellable(candidate)"
                                  [(model)]="candidate.candidateProducts[0]"
                                  [permissions]="productDescriptionsCardPermissions"
                                  [errorModel]="candidateProductError">
    </pm-product-descriptions-card>

    <pm-product-images-card [(model)]="candidate.candidateProducts[0]"
                            [permissions]="productImagesCardPermissions"
                            [errorModel]="candidateProductError">
    </pm-product-images-card>

    <pm-merchandising-info-card [(candidateModel)]="candidate"
                                [(candidateProductModel)]="candidate.candidateProducts[0]"
                                [permissions]="merchandisingInfoCardPermissions"
                                [merchandiseTypes]="merchandiseTypes"
                                [commodities]="commodities"
                                (commodityChange)="onCommodityChange($event)"
                                (subCommodityChange)="onSubCommodityChange($event)"
                                (taxableChange)="setVertexTaxCategoryModel()"
                                [candidateErrorModel]="candidateError"
                                [candidateProductErrorModel]="candidateProductError"
                                [canEditDepartment]="candidate.dsdSwitch"
                                (overrideSubDepartmentChange)="onOverrideSubDepartmentChange()">
    </pm-merchandising-info-card>

    <pm-regulatory-card [(model)]="candidate"
                        (taxableChange)="setVertexTaxCategoryModel()"
                        [permissions]="regulatoryCardPermissions"
                        [errorModel]="candidateError">
    </pm-regulatory-card>

    <pm-product-pricing-card *ngIf="candidateUtilService.isSellable(candidate)"
                             [(model)]="candidate"
                             [permissions]="productPricingCardPermissions"
                             [errorModel]="candidateError">
    </pm-product-pricing-card>

    <pm-product-packaging-card [(model)]="candidate"
                               [(productModel)]="candidate.candidateProducts[0]"
                               [permissions]="productPackagingCardPermissions"
                               [packageTypes]="packageTypes"
                               [unitOfMeasure]="unitsOfMeasures"
                               [errorModel]="candidateError"
                               [productErrorModel]="candidateProductError"
                               (validateDepositUPC)="validateDepositUpc()"
                               [state]="upcState">
    </pm-product-packaging-card>

    <pm-scale-information-card *ngIf="piaProductService.isScaleProduct(piaProductService.candidate)"
                               [(model)]="candidate.candidateProducts[0].scaleInformation"
                               [permissions]="scaleInformationCardPermissions"
                               [errorModel]="candidateProductError.scaleInformation">
    </pm-scale-information-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-editor>
