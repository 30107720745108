<div class="grid-cell">
  <div class="grid-cell-label">
    <label>
      {{label}}
    </label>
  </div>
  <div class="grid-cell-content">
    <div class="grid-cell-info">
      <ng-content></ng-content>
      <div class="grid-cell-addl-info" *ngIf="gridCellInfo">
        <ng-content select="pm-grid-cell-info"></ng-content>
      </div>
      <span class="grid-cell-error" *ngIf="errorMessage">{{errorMessage}}</span>
      <span class="grid-cell-error" *ngFor="let message of errorMessages">
        {{message}}
      </span>
    </div>
    <button pButton *ngIf="hasChanged"
            style="color: #FCD131; background: unset; border: unset;"
            pTooltip="A reviewer updated this data. Click to view all edit history."
            (click)="onHistoryClicked($event)">
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <circle cx="5" cy="5" r="5" fill="#FCD131"/>
      </svg>
    </button>
    <button *ngIf="canEdit" pButton type="button" label="Edit" class="ui-button-link grid-cell-edit"
      (click)="onEditClicked($event)"></button>
    <button *ngIf="editNoHover" pButton type="button" label="Edit" class="ui-button-link grid-cell-edit-no-hover"
            (click)="onEditClicked($event)"></button>
  </div>
</div>
