/**
 * The attribute descriptions and help information.
 *
 * @author s573181
 * @since 1.58.0
 */
export class MetaDataDescriptions {

  businessFriendlyDescription: string;
  fieldDescription: string;
  helpText: string;

}
