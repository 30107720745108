<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">
  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label">
      <label>{{attribute.label}}</label>
    </div>
    <div class="attribute-control">
      <div class="date-picker">
        <ng-template [ngIf]="!isReadOnly()">
          <p-calendar [id]="attribute.name"
                      placeholder="{{attribute.placeholderText}}"
                      [minDate]="minimumDate"
                      [showIcon]="true"
                      inputId="icon"
                      [disabled]="isDisabled()"
                      (ngModelChange)="dateChange($event)"
                      [(ngModel)]="dateDisplay"
                      [ngClass]="{'ui-inputtext-invalid' : attributeError}">

          </p-calendar>
        </ng-template>
        <ng-template [ngIf]="isReadOnly()">
          <input type="text" [id]="attribute.name" pInputText
                 placeholder="{{attribute.placeholderText}}"
                 [disabled]="isDisabled()"
                 [ngModel]="dateDisplay | dateStandard"
                 [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
                 [ngClass]="{'ui-inputtext-invalid' : attributeError}"/>

        </ng-template>
      </div>
      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="attributeError && showErrorMessage" class="attribute-error-message">
        {{attributeError}}
      </p>
    </div>
  </div>
</ng-container>
