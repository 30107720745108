import {Injectable} from '@angular/core';
import {Candidate, CandidateProduct} from 'pm-models';
import {CostService} from './cost.service';
import {EditCandidateModalService} from './edit-candidate-modal.service';
import {AttributeTypes} from 'pm-components';
import {CandidateUtilService} from './candidate-util.service';

@Injectable({
  providedIn: 'root'
})
export class EditCandidateUtilService {

  private readonly COST_RELATED_FIELDS: AttributeTypes[] =
    [AttributeTypes.MasterListCost, AttributeTypes.TotalRetail, AttributeTypes.InnerPackSelected, AttributeTypes.InnerPackQuantity];

  private readonly INNER_PACK_RELATED_FIELDS: AttributeTypes[] = [AttributeTypes.InnerPackWeight, AttributeTypes.InnerPackDimensions];

  private readonly CODE_DATE_RELATED_FIELDS: AttributeTypes[] =
    [AttributeTypes.MaxShelfLife, AttributeTypes.InboundSpecDays, AttributeTypes.ReactionDays, AttributeTypes.GuaranteeDays];

  constructor(public costService: CostService, private editCandidateModalService: EditCandidateModalService) { }


  public showBulkEditAllCandidateModal(candidates: Candidate[], type, fieldNames: string[], isSca, overrides?: any) {
    if (isSca) {
      return;
    }
    if (!fieldNames || !fieldNames.length || !candidates || !candidates.length) {
      return;
    }

    if (overrides) {
      overrides['isApplyToAll'] = true;
    } else {
      overrides = {'isApplyToAll': true};
    }

    const modalCandidate = new Candidate();
    modalCandidate.innerPackSelected = false;

    if (this.CODE_DATE_RELATED_FIELDS.includes(type)) {
      modalCandidate.codeDate = true;
    }

    this.editCandidateModalService.openModal(type, modalCandidate, overrides).subscribe(response => {
      if (!response) {
        return;
      }

      if (this.COST_RELATED_FIELDS.includes(type)) {
        let tempCandidate;
        candidates.forEach((candidate) => {
          tempCandidate = JSON.parse(JSON.stringify(candidate));
          fieldNames.forEach((fieldName) => {
            tempCandidate[fieldName] = response[fieldName];
          });
          this.costService.updateCandidateCostsFromUpdatedCandidate(candidate, tempCandidate);
        });

      } else if (this.INNER_PACK_RELATED_FIELDS.includes(type)) {
        candidates.forEach((candidate) => {
          // only make updated to candidates with inner packs == true.
          if (candidate.innerPackSelected) {
            fieldNames.forEach((fieldName) => {
              candidate[fieldName] = response[fieldName];
            });
          }
        });

      } else {
        candidates.forEach((candidate) => {
          fieldNames.forEach((fieldName) => {
            candidate[fieldName] = response[fieldName];
          });
        });
      }
    });
  }


  public showBulkEditAllCandidateProductModal(candidates: Candidate[], type, fieldName, isSca) {
    if (isSca) {
      return;
    }
    this.editCandidateModalService.openModal(type, new CandidateProduct(), {'isApplyToAll': true}).subscribe(response => {
      if (response) {
        // Dispatch Update

        candidates.forEach((candidate) => {
          candidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(candidate)][fieldName] = response[fieldName];
        });
      }
    });
  }
}
