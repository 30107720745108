

<pm-overlay *ngIf="isViewingPage" (closeEvent)="onClose()" >
  <div class="notification-card">
    <pm-card title="" subtitle="" >
      <div class="m-3">
      <div class="row">
        <div class="col-3">

          <img src="/assets/images/envelope.svg" class="envelope">
        </div>
     <div class="col-9">
       <h3 class="pm-notification-title">Set up notifications</h3>
       <p>
          To receive work updates via email, you need to choose buyer(s) to follow. You can change who you follow in your account settings at any time. <a routerLink="/tasks">If you're a buyer, skip this.</a>
       </p>
       <div class="attributes-stacked">
      <ng-container *ngIf="userPreferences">
        <pm-attribute-display-typeahead [attribute]="buyerConfiguration" [(model)]="buyerModel"
                                        (selection)="buyerChange($event)">
        </pm-attribute-display-typeahead>
      </ng-container></div>

      <div class="pb-3 text-right">&nbsp;
        <button pButton type="button" label="Save" class="ui-button" [disabled]="!changes" (click)="save()"></button>
      </div>
     </div>
</div>
      </div>
    </pm-card>
</div>
</pm-overlay>
