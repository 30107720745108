import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionFilterComponent } from './selection-filter.component';

import { FormsModule } from '@angular/forms';

import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [SelectionFilterComponent],
  exports: [MultiSelectModule, SelectionFilterComponent, ButtonModule, FormsModule],
  imports: [
    CommonModule, MultiSelectModule, ButtonModule, FormsModule
  ]
})
export class SelectionFilterModule { }
