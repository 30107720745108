import { Pipe, PipeTransform } from '@angular/core';
import { UpcService } from '../../services/upc-service/upc.service';

@Pipe({
  name: 'upc'
})
export class UpcPipe implements PipeTransform {

  constructor(private upcService: UpcService) {
  }

  transform(value: number): string {
    return this.upcService.getG14Upc(value);
  }

}
