import {Component, OnInit, ViewChild} from '@angular/core';
import {WorkflowService} from '../../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {GrowlService} from '../../growl/growl.service';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {CostService} from '../../service/cost.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {SupplierProductService} from '../../service/supplier-product.service';
import {FileService} from '../../service/file.service';
import {AuthService} from '../../auth/auth.service';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  LocationGroupStores,
  NutritionPanel,
  Task,
  TaskDecision,
  TextInputType,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {AttributeTypes, ReviewComponent, UpcService} from 'pm-components';
import {NgxPermissionsService} from 'ngx-permissions';
import {QualifyingCondition} from 'pm-models/lib/qualifyingCondition';
import {HistoryService} from '../../utils/history.service';
import {VertexTaxCategoryDefaults} from 'pm-models/lib/vertexTaxCategoriesDefault';
import {UUID} from 'angular2-uuid';
import {NewUpcRequest} from 'pm-models/lib/newUpcRequest';
import {OwnBrandService} from '../../service/ownbrand.service';
import {ProcessInstanceConstants} from '../../utils/constants/process-instance-constants';
import {MatUtilService} from '../../service/mat-util.service';
import {ItemWeightTypeService} from '../../service/item-weight-type.service';
import {LabelInsightService} from '../../service/label-insight.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {ProcessVariables} from 'pm-models/lib/processVariables';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';
import { CandidateErrorUtilService } from '../../service/candidate-error-util.service';


@Component({
  selector: 'app-pia-scale-plu-review',
  templateUrl: './pia-scale-plu-review.component.html',
  styleUrls: ['./pia-scale-plu-review.component.scss']
})
export class PiaScalePluReviewComponent implements OnInit {

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public candidateUtilService: CandidateUtilService,
              public supplierProductService: SupplierProductService, public fileService: FileService,
              public authService: AuthService, public permissionService: NgxPermissionsService,
              public historyService: HistoryService, public upcService: UpcService, public ownBrandService: OwnBrandService,
              public matUtilService: MatUtilService, private itemWeightTypeService: ItemWeightTypeService,
              public labelInsightService: LabelInsightService, public candidateErrorUtils: CandidateErrorUtilService) { }

  @ViewChild(ReviewComponent) pmReview;

  public PIA_SCALE_PLU_REVIEW_TASK_NAME = 'PIA OB Reg Flow Activate';

  public isViewingPage = true;
  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: any = {};
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  private taskSubscription$: any;
  private task: Task;
  public productImages = [];
  public labelInsightImages = [];
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';

  public suppliers = new Map();
  public openCommentDrawer = false;
  public tempCommentHolder: string;
  public isActivateDisabled = false;
  public isRejectDisabled = false;
  public rejectMessage = 'Let us know why you\'re rejecting this candidate.';
  public mechanicallyTenderized = 'false';
  public forceTare = 'false';
  public isApproveDisabled = false;
  public commodities: any;
  public subCommodities: any;
  public warehouses = [];
  public qualifyingConditions: QualifyingCondition[] = [];
  public merchandiseTypes: any;
  public warehouseCandidateError: CandidateError;
  public warehouseCandidateProductError: CandidateProductError;
  public orderRestrictions: any[];
  public warehouseData = [];
  public packageTypes: any;
  public unitsOfMeasures: any;
  public piaName: string;
  public defaultTaxCategories: VertexTaxCategoryDefaults;
  public showWarehousePanel: boolean = false;

  public currentHistoryResults: any = undefined;
  public showHistoryPanel: boolean = false;
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public canClickSave: boolean = true;
  public showStoreOverlay: boolean = false;
  public supplierOverlayCandidateError: CandidateError = new CandidateError();
  public supplierOverlayCandidateProductError: CandidateProductError;
  public openNutritionDrawer = false;
  public scaleNutritionPanel: NutritionPanel;
  showMatAttributes = false;
  isLoadingMatGlobalData = true;
  isLoadingMatHierarchyData = true;
  public pssDepartments: any;
  public requestNewMatAttributeOverrideWrapper = new RequestNewMatAttributeOverrideWrapper();

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => true,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  piaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'obRegCommentId',
    maxLength: 4000
  };


  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.supplierProductService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.supplierProductService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  ngOnInit() {

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.PIA_SCALE_PLU_REVIEW_TASK_NAME) {
              this.router.navigate(
                ['/tasks'],
                {
                  queryParams:
                    {
                      growlMessage: 'Candidate is not in ' + this.PIA_SCALE_PLU_REVIEW_TASK_NAME + ' status.',
                      growlToUse: GrowlService.SEVERITY_ERROR
                    }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                this.costService.updateRetailLink(candidate).subscribe( (updatedCandidate) => {
                  this.setInitialValues(updatedCandidate);
                });
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }


  private setInitialValues(candidate: Candidate) {
    // Field migration for graphicsCode to graphicsCodeOne
    if (!!candidate.candidateProducts && !!candidate.candidateProducts.length) {
      candidate.candidateProducts.forEach(cp => {
        if (!!cp.scaleInformation && (cp.scaleInformation.graphicsCode !== undefined && cp.scaleInformation.graphicsCode !== null)) {
          cp.scaleInformation.graphicsCodeOne = cp.scaleInformation.graphicsCode;
          cp.scaleInformation.graphicsCode = null;
        }
      });
    }
    this.supplierProductService.resetService();
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];

    if (!candidate.showCalories) {
      this.mechanicallyTenderized = this.candidateProduct.scaleInformation.mechanicallyTenderized ? 'true' : 'false';
      this.forceTare = this.candidateProduct.scaleInformation.forceTare ? 'true' : 'false';
    }

    this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].labelInsightsImageLinks);
    this.getOrderRestrictions();

    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }

    if (candidate.brand?.brandId !== undefined) {
      this.setCostOwners(candidate.brand.brandId);
    }

    if (candidate.buyer?.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }

    this.getDefaultTaxCategories(candidate);

    if (this.candidate.vendor?.apNumber !== undefined) {
      this.setVendor(this.candidate.vendor.apNumber);
    }
    this.piaName = this.authService.getUser();

    if (this.candidate.dsdSwitch) {
      this.setAuthGroups();
    }
    if (this.candidate.obRegComment) {
      this.tempCommentHolder = this.candidate.piaComment;
    }

    this.currentHistoryResults = this.historyService.getCandidateHistory(candidate.candidateId, true);

    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllQualifyingConditions().subscribe(qualifyingConditions => {
      this.qualifyingConditions = qualifyingConditions;
    });
    if (this.candidateUtilService.isScaleProduct(this.candidate)) {
      this.lookupService.findNutrition(this.candidateProduct.upc).subscribe((scaleNutritionPanel) => {
        this.scaleNutritionPanel = scaleNutritionPanel;
      });
    } else {
      this.buildNutrition();
    }
    this.getPssDepartments(this.candidate);
    this.setupMatAttributes();
  }

  setupMatAttributes() {
    this.isLoadingMatHierarchyData = true;
    this.isLoadingMatGlobalData = true;
    this.supplierProductService.hierarchyNumberToAttributesMap = new Map();

    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatHierarchyData = false;
      this.isLoadingMatGlobalData = false;
      return;
    }
    this.showMatAttributes = true;

    this.matUtilService.updateMatHierarchy(this.candidateProduct).pipe(
      tap((hasHierarchyChanges) => {
        this.supplierProductService.hasHierarchyUpdateChanges = hasHierarchyChanges;
      }),
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
        this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes)),
      tap(() => {
        this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierProductService.hierarchyAttributes,
          this.supplierProductService.hierarchyNumberToAttributesMap);
        this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierProductService.globalAttributes,
          this.supplierProductService.productAttributes, this.supplierProductService.warehouseItemAttributes, this.supplierProductService.upcAttributes);
      }),
      finalize(() => {
        this.isLoadingMatHierarchyData = false;
        this.isLoadingMatGlobalData = false;
      })
    ).subscribe();
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.supplierProductService.resetService();
        this.isViewingPage = false;
        this.router.navigate(['/tasks']).then();
      });
    } else {
      this.supplierProductService.resetService();
      this.isViewingPage = false;
      this.router.navigate(['/tasks']).then();
    }
  }

  getProductInfoString() {
    let infoString = '';

    infoString += this.candidate.retailSize + ' | ' + 'Inner case cost: ' +
      this.costService.toCurrencyForCost(this.candidate.innerListCost) + ' |  Unit cost: ' +
      this.costService.toCurrencyForCost(this.candidate.unitCost);

    if (this.originalCandidate.productType === 'SELLABLE') {
      infoString +=
        ' | Suggested Retail: '  +
        this.candidate.suggestedXFor + ' for ' + this.costService.toCurrency(this.candidate.suggestedRetailPrice);
    }

    return infoString;
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }


  showCasePackInfo() {
    return this.candidate.warehouseSwitch;
  }

  isDsdOnlyParms(candidate: Candidate): boolean {
    return candidate && candidate.dsdSwitch && !candidate.warehouseSwitch;
  }

  getVendor(vendorId: number) {
    return this.suppliers.get(vendorId);
  }


  loadVendors() {
    for (let x = 0; x < this.candidate.candidateProducts[0].locationGroupStores.length; x++) {
      this.lookupService.findDsdVendor(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber).subscribe(vendor => {
        this.suppliers.set(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber, vendor[0]);
      });
    }
  }

  setAuthGroups() {
    if (!this.supplierProductService.getAuthGroups()) {
      this.supplierProductService.findAllAuthGroupsByParms(this.candidate.vendor.apNumber, this.candidate.commodity.departmentId,
        this.candidate.commodity.subDepartmentId).subscribe((authGroups) => {
        this.supplierProductService.setAuthGroups(authGroups);
        this.loadVendors();
        for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
          this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
      });
    } else {
      this.loadVendors();
      for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
        this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
      }
    }
  }

  getNumberOfStoresForGroup(groupId) {
    if (this.supplierProductService.authGroups) {
      for (let x = 0; x < this.supplierProductService.authGroups.length; x++) {
        if (this.supplierProductService.authGroups[x].splrLocationGroupId === groupId) {
          return this.supplierProductService.authGroups[x].stores.length;
        }
      }
    }
  }


  getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  getSellableString(): string {
    return this.candidate.productType === 'SELLABLE' ? 'Yes' : 'No';
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.buyerComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.showWarehousePanel = false;
    this.showHistoryPanel = false;
    this.openNutritionDrawer = false;
    this.pmReview.openDrawer();
  }


  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.piaComment = this.tempCommentHolder;
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  onClickNext() {
    this.pmReview.openDrawer();
  }


  showPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  reject(event, panel, target) {
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;

    this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE,
      TaskDecision.PIA_REVIEW_SCALE_PLU_REJECT, 'Successfully rejected candidate.');
    panel.hide();
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.updateApNumber(this.candidate.vendor.apNumber, this.task.processInstanceId).subscribe(() => {
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
      });
    }, (error) => {
      this.growlService.addError(error);
      this.isRejectDisabled = false;
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Saves candidate.
   */
  save() {
    // Re-apply CP to base candidate;
    this.candidate.candidateProducts = [this.candidateProduct];
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  collapse() {
    this.pmReview.closeDrawer();
  }

  /**
   * Calls workflow service to end the buyer task and then navigates back to the tasks page.
   */
  private saveAndCompleteTask() {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.completeTaskAndRouteToTasksPage(
        WorkflowService.ACTION_COMPLETE, TaskDecision.OB_REG_SCALE_PLU_APPROVE_DECISION, 'Successfully completed task.');
    });
  }


  isBilingualChange(event) {
    if (!this.candidate.showCalories) {
      this.candidateProduct.scaleInformation.bilingual = event.checked;
      this.candidateProduct.scaleInformation.englishLabelOneBilingual = null;
      this.candidateProduct.scaleInformation.englishLabelTwoBilingual = null;
      this.candidateProduct.scaleInformation.spanishLabelOne = null;
      this.candidateProduct.scaleInformation.spanishLabelTwo = null;
    }
  }

  /**
   * Returns the number of days as a string. If empty, it returns 0 Days.
   * @param days
   */
  getDaysString(days) {
    if (days) {
      return '' + days + ' Days';
    }
    return '0 Days';
  }


  getActivateButtonTitle() {
    const buttonNotClicked = 'Activate';
    const activatingButton = 'Activating';
    if (this.isActivateDisabled) {
      return activatingButton;
    } else {
      return buttonNotClicked;
    }
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   */
  onClickActivate() {
    this.isActivateDisabled = true;
    this.isRejectDisabled = true;
    this.candidate.candidateProducts[0] = this.candidateProduct;

    this.workflowService.updateApNumber(
      this.candidate.vendor ? this.candidate.vendor.apNumber : null,
      this.task.processInstanceId).subscribe(() => {

      const validators = [CandidateValidatorType.PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR];
      if (this.candidateUtilService.isScaleProduct(this.candidate)) {
        validators.push(CandidateValidatorType.SCALE_INFORMATION_VALIDATOR);
      }

      this.candidateService.validateCandidate(this.candidate, validators)
        .subscribe(() => {
          this.workflowService.addProcessVariableViewed(this.task.processInstanceId, false).subscribe(() => {
            this.candidateService.activateCandidate(this.candidate).subscribe(() => {
              this.completeTaskAndRouteToTasksPage(
                WorkflowService.ACTION_COMPLETE,
                TaskDecision.PIA_REVIEW_SCALE_PLU_APPROVE_DECISION,
                'Successfully activated candidate.'
              );
            }, (error) => {
              this.growlService.addError(error);
              this.isActivateDisabled = false;
              this.isRejectDisabled = false;
            });
          });
        }, (error) => {
          if (error.error.candidateErrors) {
            this.candidateError = error.error.candidateErrors;
            this.candidateProductError = this.candidateError.candidateProductErrors[this.candidateProduct.id];
          } else {
            this.growlService.addError(error.message);
          }
          this.isActivateDisabled = false;
          this.isRejectDisabled = false;
        });
    }, (error) => {
      this.growlService.addError(error);
      this.isActivateDisabled = false;
      this.isRejectDisabled = false;
    });
  }


  isActivateButtonDisabled() {
    if (this.isActivateDisabled) {
      return true;
    } else {
      return !this.candidate;
    }
  }


  editMerchandiseType() {
    this.showEditCandidateModal(AttributeTypes.MerchandiseType, {collections: this.merchandiseTypes});
  }
  editQualifyingConditions() {
    this.showEditCandidateProductModal(AttributeTypes.QualifyingCondition, {collections: this.qualifyingConditions});
  }
  editWarehouseSupplier() {
    this.showEditCandidateModal(AttributeTypes.Warehouse, {collections: this.candidate.vendor.lanes});
  }

  editSubCommodity() {
    this.editCandidateModalService.openModal(AttributeTypes.SubCommodity, this.candidate,
      {collections: this.subCommodities}).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.updateRegulatoryFields();
      }
    });
  }

  private updateRegulatoryFields() {
    this.candidate.foodStamp = CandidateHelper.isFoodStamp(this.candidate);
    this.candidate.taxable = CandidateHelper.isTaxCode(this.candidate);
  }

  editPricingType() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.RetailType},
        {type: AttributeTypes.RetailLink, overrides: {validationService: this.candidateService}},
        {type: AttributeTypes.RetailPricing}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editCommodity() {
    this.editCandidateModalService.openModal(AttributeTypes.Commodity,
      this.candidate, {collections: this.commodities} ).subscribe(response => {
      if ( response ) {
        this.candidate = response;
        this.subCommodities = this.candidate.commodity.subCommodityList;
        this.getPssDepartments(this.candidate);
        if (this.candidate.commodity) {
          this.candidate.pssDepartment = this.candidate.commodity.pssDepartment;
        }
      }
    });
  }

  editPackageType() {
    this.showEditCandidateModal(AttributeTypes.PackageTypes, {collections: this.packageTypes});
  }

  editUnitOfMeasure() {
    this.showEditCandidateModal(AttributeTypes.UnitOfMeasure, {collections: this.unitsOfMeasures});
  }

  editCandidateProduct(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.candidate.brand.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.candidate.brand.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }
  editContact() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.ContactName},
        {type: AttributeTypes.ContactEmail}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }
  editSeasonYear() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.Season},
        {type: AttributeTypes.SeasonYear},
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }
  editProductUPC() {
    this.showEditCandidateProductUpcModal(AttributeTypes.ProductUPC, {
      validationService: this.candidateService
    });
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  editCostLink() {
    this.editCandidateModalService
      .openModal(AttributeTypes.CostLinkedItem, this.candidate, {
        validationService: this.candidateService
      })
      .subscribe(response => {
        if (response) {
          this.candidate = response;
        }
      });
  }
  get attributeType() {
    return AttributeTypes;
  }
  editOrderRestrictions(warehouse) {
    this.editWarehouse(warehouse, AttributeTypes.WarehouseOrderRestriction, { collections : this.orderRestrictions});
  }

  editWarehouse(warehouse, type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, warehouse, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        const warehouseIndex = this.candidateProduct.warehouses.findIndex(x => x.warehouseId === warehouse.warehouseId);
        this.candidateProduct.warehouses[warehouseIndex] = response;
      }
    });
  }


  showEditCandidateProductModal(type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
        if (type === AttributeTypes.ProductImages) {
          this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
        }
      }
    });
  }

  showEditCandidateProductUpcModal(type: AttributeTypes,  overrides?: any) {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    this.editCandidateModalService.openModal(type, tempCandidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[0];
      }
    });
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, JSON.parse(JSON.stringify(this.candidate)), overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        if (this.candidate.retailSize !== undefined && this.candidate.retailSize !== null && this.candidate.retailSize.length === 0) {
          this.candidate.retailSize = null;
        }
        if (type === AttributeTypes.Channel) {
          this.findMerchandiseTypesAndSetDefault();
          this.setupMatAttributes();
          this.candidateUtilService.handleChannelChange(this.candidate);
        } else if (type === AttributeTypes.Sellable) {
          this.findMerchandiseTypesAndSetDefault();
        } else if (type === AttributeTypes.CaseUPC) {
          const currentCandidateProduct = CandidateUtilService.getCurrentCandidateProduct(response);
          this.candidateProduct.caseUpc = currentCandidateProduct.caseUpc;
          this.candidateProduct.caseUpcCheckDigit = currentCandidateProduct.caseUpcCheckDigit;
        }
      }
    });
  }

  editBuyer() {
    this.editCandidateModalService.openModal(AttributeTypes.Buyer, this.candidate).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidate.commodity = null;
        this.candidate.subCommodity = null;
        if (response.buyer && response.buyer.buyerId) {
          this.setCommoditiesAndSubCommodities(response.buyer.buyerId);
        } else {
          this.commodities = [];
          this.subCommodities = [];
        }
      }
    });
  }


  /**
   * Returns whether a buyer is tied to departments that are variable or catch weight departments (2, 6, 9).
   */
  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.candidate.commodity && this.candidate.commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.candidate.commodity.departmentId);
    } else {
      return false;
    }
  }


  historyPanelOpen() {
    this.historyService.getCandidateHistory(this.candidate.candidateId, true);
    this.currentHistoryResults = this.historyService.sortedAudits;
    this.showHistoryPanel = true;
    this.openCommentDrawer = false;
    this.showWarehousePanel = false;
    this.openNutritionDrawer = false;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  /**
   * Code date is editable by the pia if dsdSwitch set to false ==> warehouse
   */
  codeDateEditable() {
    return !this.candidate.dsdSwitch;
  }


  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }


  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.candidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.candidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      if (!this.candidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });

  }


  /**
   * Sets available selectable cost owners
   * @param brandId the brandId id.
   */
  private setCostOwners(brandId) {
    this.lookupService.findBrandsById(brandId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.brand.brandId === data[index].brandId) {
          this.candidate.brand.costOwners = data[index].costOwners;
          break;
        }
      }
    });
  }
  /**
   * Sets available selectable cost owners
   * @param vendorId the vendorId id.
   */
  private setVendor(vendorId) {
    this.lookupService.findVendor(vendorId).subscribe(data => {
      if (data) {
        for (let index = 0; index < data.length; index++) {
          if (this.candidate.vendor.apNumber === data[index].apNumber) {
            this.candidate.vendor.lanes = data[index].lanes;
            break;
          }
        }
      }
    });
  }



  private getDefaultTaxCategories(candidate: Candidate) {
    if (!candidate.subCommodity) {
      return;
    }

    this.lookupService.findDefaultTaxCategories(candidate.subCommodity.subCommodityId, candidate.taxable.toString())
      .subscribe(defaultTaxCategories => {
          this.defaultTaxCategories = defaultTaxCategories;
        }
      );
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }


  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
  }


  /**
   * Pss Department is editable by the pia if dsdSwitch set to true ==> DSD
   */
  pssDepartmentEditable() {
    return this.candidate.dsdSwitch === true;
  }


  editPssDepartment() {
    this.editCandidateModalService.openModal(
      AttributeTypes.PssDepartment, this.candidate, {collections: this.pssDepartments}).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
      }
    });
  }

  getPssDepartments(candidate: Candidate) {
    if (!candidate.overrideDepartment) {
      if (candidate.commodity && candidate.commodity.subDepartment == null) {
        // Make call to get sub department with pss departments when commodity does not have department info.
        this.lookupService.findSubDepartment(candidate.commodity.departmentId + candidate.commodity.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
        });
      } else {
        this.pssDepartments = candidate.commodity.subDepartment.pssDepartments;
      }
    } else {
      this.pssDepartments = candidate.overrideSubDepartment?.pssDepartments;
    }
  }

  openWarehouseDrawer() {
    this.showWarehousePanel = true;
    this.openCommentDrawer = false;
    this.showHistoryPanel = false;
    this.openNutritionDrawer = false;
    this.getWarehouses(this.candidate);
    this.pmReview.openDrawer();
  }


  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }



  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, don't add to warehouse list.
    if (this.candidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  removeWarehouse(index) {
    const warehouse = this.candidateProduct.warehouses.splice(index, 1).pop();
    if (this.warehouseData && this.warehouses) {
      this.addRemovedWarehouseToDrawer(+warehouse.warehouseId);
    }
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }


  addRemovedWarehouseToDrawer(warehouseId) {
    for (let x = 0; x < this.warehouses.length; x++) {
      if (this.warehouses[x].warehouseId === warehouseId) {
        this.warehouseData.push(this.warehouses[x]);
        this.warehouseData[this.warehouseData.length - 1].orderUnitConfig = this.getOrderUnitConfiguration(warehouseId);
        break;
      }
    }
  }


  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }


  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }


  /**
   * Saves candidate.
   */
  update() {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.candidateProducts[0].warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.candidateService.validateCandidate(tempCandidate, [CandidateValidatorType.WAREHOUSE_VALIDATOR,
      CandidateValidatorType.BICEP_VALIDATOR])
      .subscribe(() => {
        this.candidateProduct.warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
        this.candidateService.saveCandidate(this.candidate, true).subscribe(savedCandidate => {
          this.setOriginalAndCurrentCandidate(savedCandidate);
          this.collapse();
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.warehouseCandidateError = error.error.candidateErrors;
          this.warehouseCandidateProductError  = this.warehouseCandidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message);
        }
      });
  }



  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }


  saveClicked(candidate: Candidate, panel) {
    this.canClickSave = false;

    this.candidateService.validateCandidate(candidate, [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR]).toPromise().then(() => {
      // Re-apply CP to base candidate;
      this.candidate.candidateProducts = [candidate.candidateProducts[0]];
      this.candidate = candidate;
      this.supplierOverlayCandidateError = new CandidateError();
      this.supplierOverlayCandidateProductError = new CandidateProductError();
      this.candidateService.saveCandidate(candidate).subscribe(savedCandidate => {
        this.setOriginalAndCurrentCandidate(savedCandidate);
        this.loadVendors();
        this.showStoreOverlay = false;
        panel.hide();
        this.canClickSave = true;
      });
    }, (error) => {
      this.canClickSave = true;
      this.supplierProductService.scrollToTop();
      if (error.error.candidateErrors.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.supplierProductService.getStoreAuthError();
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
      }
    });
  }

  hideStoreSearchPanel(target) {
    this.supplierOverlayCandidateError = new CandidateError();
    this.supplierOverlayCandidateProductError = new CandidateProductError();
    this.showStoreOverlay = false;
    target.hide();
  }

  editBrandModal() {
    this.editCandidateModalService.openModal(AttributeTypes.Brand, this.candidate).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[0];
      }
    });
  }

  editVertexTaxCategory() {
    this.editCandidateModalService.openModal(AttributeTypes.VertexTaxCategory, this.candidate,
      {collections: this.defaultTaxCategories},
      {contentStyle: { 'max-height': '550px', overflow: 'none' }}
    ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
      }
    });
  }



  editProductDescription() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.ProductDescription},
        {type: AttributeTypes.Receipt}
      ], this.candidateProduct).subscribe( response => {

      if (response) {
        this.candidateProduct = response;
      }
    });
  }

  onOpenNutritionDrawer() {
    this.openNutritionDrawer = true;
    this.pmReview.openDrawer();
  }

  collapseNutritionDrawer() {
    this.openNutritionDrawer = false;
    this.pmReview.closeDrawer();
  }

  public nutritionHeaderColumns = [
    {field: 'fieldName', header: 'Amount per Serving', sortable: false, width: '3%'},
    {field: 'fieldGrams', header: '            ', sortable: false, width: '20%'},
    {field: 'fieldValue', header: 'Daily Value*', sortable: false, width: '20%'}
  ];

  public nutritionResults: any[] = undefined;
  public ingredientsList: any[] = undefined;
  public allergensList: string[] = undefined;
  public servingSize: string;
  public servingsPerContainer: string;

  buildNutrition() {
    this.buildNutritionPanelHeader();
    this.buildIngredientsList();
    this.buildAllergensList();
    this.buildNutritionResults();
  }

  buildNutritionResults() {
    if (this.candidate.candidateNutrition !== undefined && this.candidate.candidateNutrition !== null
      && this.candidate.candidateNutrition.productNutrients !== undefined) {
      this.nutritionResults = [];
      for (let index = 0; index < this.candidate.candidateNutrition.productNutrients.length; index++) {
        if (this.candidate.candidateNutrition.productNutrients[index].nutrient === undefined) {
          continue;
        }
        const field = this.candidate.candidateNutrition.productNutrients[index].nutrient.description;
        const gramPerServing = this.candidate.candidateNutrition.productNutrients[index].nutrientQuantity;
        let dailyValue = this.candidate.candidateNutrition.productNutrients[index].dailyValuePercent;
        if (dailyValue === undefined) {
          dailyValue = ' ';
        }
        this.nutritionResults.push({'field' : field, 'gramPerServing' : gramPerServing, 'dailyValue' : dailyValue});
      }
    }
  }
  buildNutritionPanelHeader() {
    if (this.candidate.candidateNutrition !== undefined && this.candidate.candidateNutrition !== null
      && this.candidate.candidateNutrition.nutritionPanelHeader !== undefined) {
      this.servingSize = this.candidate.candidateNutrition.nutritionPanelHeader.servingSize;
      this.servingsPerContainer = this.candidate.candidateNutrition.nutritionPanelHeader.servingsPerContainer;
    }
  }

  buildIngredientsList() {
    if (this.candidate.candidateNutrition !== undefined && this.candidate.candidateNutrition !== null
      && this.candidate.candidateNutrition.ingredients !== undefined) {
      this.ingredientsList = [];
      for (let index = 0; index < this.candidate.candidateNutrition.ingredients.length; index++) {
        this.ingredientsList.push(this.candidate.candidateNutrition.ingredients[index].textValue);
      }
    }
  }

  buildAllergensList() {
    if (this.candidate.candidateNutrition !== undefined && this.candidate.candidateNutrition !== null
      && this.candidate.candidateNutrition.productAllergens !== undefined) {
      this.allergensList = [];
      for (let index = 0; index < this.candidate.candidateNutrition.productAllergens.length; index++) {
        this.ingredientsList.push(this.candidate.candidateNutrition.productAllergens[index].display);
      }
    }
  }

  editScaleAttributeModal(attributeType) {
    this.editCandidateModalService.openModal(attributeType, this.candidateProduct.scaleInformation).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct.scaleInformation = response;
      }
    });
  }

  editPrePackTareModal() {
    if (!this.candidateProduct.scaleInformation.forceTare) {
      this.editCandidateModalService.openModal(AttributeTypes.PrePackTare, this.candidateProduct.scaleInformation).subscribe(response => {
        if ( response ) {
          // Dispatch Update
          this.candidateProduct.scaleInformation = response;
        }
      });
    }
  }

  editForceTareModal() {
    this.editCandidateModalService.openModal(AttributeTypes.ForceTare, this.candidateProduct.scaleInformation).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct.scaleInformation.forceTare = response.forceTare;
        if (this.candidateProduct.scaleInformation.forceTare) {
          this.candidateProduct.scaleInformation.prePackTare = 0.00;
        }
      }
    });
  }

  getProductWeight() {
    const productWeight = this.candidate.productWeight ? this.candidate.productWeight : '0';
    return productWeight + 'oz';
  }

  hasStores() {
    return this.candidate.candidateProducts[0].locationGroupStores &&
      (this.candidate.candidateProducts[0].locationGroupStores.length > 0);
  }

  showStoreSearchPanel(event, panel, target) {
    event.stopPropagation();
    this.supplierProductService.setCandidate(JSON.parse(JSON.stringify(this.candidate)));
    this.supplierProductService.setAuthGroups(null);
    this.supplierProductService.setSelectedAuthGroups(null);
    panel.show(event, target);
    this.showStoreOverlay = true;
  }

  editPlu() {
    this.editCandidateModalService.openModal(AttributeTypes.Plu, JSON.parse(JSON.stringify(this.candidateProduct)), {saveDisabled: false}).subscribe(response => {
      if (!response) {
        return;
      }
      const wasScale = this.candidateUtilService.isScaleProduct(this.candidate);
      const previousPlu = this.candidateProduct.upc;
      const previousPluType = this.candidateProduct.pluType;

      this.candidate.candidateProducts[0] = response;
      const scaleChange = wasScale !== this.candidateUtilService.isScaleProduct(this.candidate);

      if (scaleChange) {
        this.candidate.candidateProducts[0].scaleInformation = { id: UUID.UUID() };
      }
      this.candidateProduct = this.candidate.candidateProducts[0];

      if (!response.keepPlu) {
        this.updatePluAndSave(previousPlu, previousPluType);
      }
      if (scaleChange) {
        this.updateProcessInstanceForPluTypeChange(response.keepPlu);
      }
    });
  }

  private updatePluAndSave(previousPlu, previousPluType) {
    const upcRequest = new NewUpcRequest();
    upcRequest.requestType = this.candidateProduct.pluType;
    upcRequest.candidate = this.candidate;
    upcRequest.rangeId = this.candidateProduct.pluRange.pluRangeId;
    this.ownBrandService.releaseAndFetchNewPlu(previousPlu, previousPluType, upcRequest).subscribe((plu) => {
      this.candidateProduct.upc = plu;
      this.candidate.candidateProducts[0].upc = plu;
      this.save();
    });
  }

  updateProcessInstanceForPluTypeChange(saveCandidate: boolean) {
    let processDefinition;

    if (this.candidateUtilService.isScaleProduct(this.candidate)) {
      processDefinition = this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_SCALE_WORKFLOW :
        ProcessInstanceConstants.NEW_PRODUCT_DSD_SCALE_WORKFLOW;
    } else {
      processDefinition = this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_WORKFLOW :
        ProcessInstanceConstants.NEW_PRODUCT_DSD_WORKFLOW;
    }

    const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
    this.workflowService.deleteProcessInstanceAndCreateNewFlow(this.task.processInstanceId, this.candidate.candidateId, processDefinition,
      apNumber, Task.PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID).subscribe((task) => {
      this.task = task;
      // update url with new task id.
      window.history.replaceState({}, 'Title', '#/procurementSupportProductReview?taskId=' + this.task.id);
      // if the plu stayed the same (we didn't save yet), and the workflow changed, save the change to the plu type so there's no mismatch
      // between the plu type and flow (scale vs non scale).
      if (saveCandidate) {
        this.save();
      }
    });
  }

  editCategory() {
    this.editCandidateModalService.openModal(AttributeTypes.CategorySelection,  this.candidateProduct, {},
      {contentStyle: { 'min-width': '700px', overflow: 'none' }}).subscribe((response) => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
        this.isLoadingMatHierarchyData = true;
        this.supplierProductService.hasHierarchyUpdateChanges = false;
        this.supplierProductService.hierarchyAttributes = [];
        this.supplierProductService.hierarchyNumberToAttributesMap = new Map();
        this.matUtilService.setMatHierarchyList(this.candidate, this.supplierProductService.hierarchyAttributes).pipe(
          tap(() => {
            this.matUtilService.setHierarchyNumberToAttributesMap(this.supplierProductService.hierarchyAttributes,
              this.supplierProductService.hierarchyNumberToAttributesMap);
            this.matUtilService.updateMatHierarchyErrors(this.candidateProductError,
              this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes);
          }),
          finalize(() => { this.isLoadingMatHierarchyData = false; } )
        ).subscribe();
      }
    });
  }

  editShowCalories() {
    this.editCandidateModalService
      .openModal(AttributeTypes.ShowCalories, this.candidate,
        {description: `If checked, we'll pull nutritional information for the Own Brand Regulatory team to review.`,
          showWarningMessage: false})
      .subscribe(response => {
        if (!response || response.showCalories === this.candidate.showCalories) {
          return;
        }
        this.candidate = response;
        // if switched to chow calories, send back to first PS review
        if (this.candidateUtilService.isScaleProduct(this.candidate) && response.showCalories) {
          // already been approved by OB reg, so route to first state of PS
          this.processStateChange(true);
        } else if (!this.candidateUtilService.isScaleProduct(this.candidate) && !response.showCalories) {
          // OB reg not required, so route to first state of PS
          const processDefinitionToUse =
            this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_WORKFLOW : ProcessInstanceConstants.NEW_PRODUCT_DSD_WORKFLOW;
          this.processFlowChange(true, processDefinitionToUse);
        } else {
          this.save();
        }
      });
  }

  processFlowChange(saveCandidate: boolean, processDefinition) {
    const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
    this.workflowService.deleteProcessInstanceAndCreateNewFlow(this.task.processInstanceId, this.candidate.candidateId, processDefinition,
      apNumber, Task.PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID).subscribe((task) => {
      this.task = task;
      // update url with new task id.
      window.history.replaceState({}, 'Title', '#/procurementSupportProductReview?taskId=' + this.task.id);
      location.reload();
      // if the plu stayed the same (we didn't save yet), and the workflow changed, save the change to the plu type so there's no mismatch
      // between the plu type and flow (scale vs non scale).
      if (saveCandidate) {
        this.save();
      }
    });
  }

  processStateChange(saveCandidate: boolean) {
    const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
    const processVariables: ProcessVariables[] = [];
    processVariables.push({
      candidateId: this.candidate.candidateId, apNumber: apNumber, moveToWorkflowState: Task.PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID
    });
    this.workflowService.updateCandidateTasksStates(processVariables).subscribe((task) => {
      this.task = task;
      // update url with new task id.
      window.history.replaceState({}, 'Title', '#/procurementSupportProductReview?taskId=' + this.task[0].id);
      location.reload();
      // if the plu stayed the same (we didn't save yet), and the workflow changed, save the change to the plu type so there's no mismatch
      // between the plu type and flow (scale vs non scale).
      if (saveCandidate) {
        this.save();
      }
    });
  }
}
