<pm-editor (closeEvent)="onClose()">

  <pm-header>
    <app-pia-new-invited-distributor-stepper *ngIf="invitedDistributorService.getCandidate()"
                                             [candidateErrors]="invitedDistributorService.getAllCandidateErrors()"
                                             [supplierInitiated]="invitedDistributorService.isSupplierInitiated()"
                                             [stepIndex]="1">
    </app-pia-new-invited-distributor-stepper>
  </pm-header>

  <pm-title>What product are you setting up?</pm-title>

  <div *ngIf="invitedDistributorService.candidate" class="col-md-10 mt-3">

    <div class="row">
      <label>Provide a nickname for this so it’s easy to find in PAM. We’ll only use this internally.</label>
    </div>
    <div class="row">
      <input id="descriptionId" pInputText
             [ngClass]="{'ui-inputtext-invalid' : candidateError.description}"
             placeholder="Enter a name or short description..."
             type="text"
             maxlength="100"
             minlength="1"
             accept-charset="UTF-8"
             [(ngModel)]="invitedDistributorService.candidate.description"
             style="width: 80%;">
    </div>
    <div class="row mt-0" *ngIf="candidateError.description">
      <p class="attribute-error-message">{{ candidateError.description }}</p>
    </div>
  </div>

  <div *ngIf="invitedDistributorService.getCandidate()" class="col-md-10 mt-4 mb-4">
    <div class="row">
      <label>Enter the product's UPC or another unique product identification code.</label>
    </div>
    <div class="row">
      <pm-upc-input
        [(upcModel)]="searchedCode"
        [(checkDigitModel)]="invitedDistributorService.candidate.candidateProducts[0].upcCheckDigit"
        [(upcType)]="upcType"
        [readOnly]="false"
        [disabled]="false"
        [hasError]="hasUpcError()"
        placeholderText="Search for item..."
        checkDigitPlaceholderText="Check #"
        [upcOptions]="upcOptions"
        [state]="upcState"
        (upcModelChange)="setUpcItemCodeAndValidate()"
        [hideCheckDigit]="isItemCode()"
        [autoCheckDigit]="true"
        [readOnlyCheckDigit]="true"
        style="width: 80%;">
        >
      </pm-upc-input>
    </div>
    <div class="row" *ngIf="hasUpcError()">
      <p class="error-message">{{candidateProductError.upc}}</p>
    </div>
  </div>
  <div *ngxPermissionsOnly="['ROLE_COMPLIMENTARY_PLU']" style="margin-bottom: 100px">
    <pm-attribute-display-checkbox *ngIf="invitedDistributorService.getCandidate()"  [isIndented]="false" [attribute]="isAddComplimentaryPluConfiguration"
                                   [(model)]="invitedDistributorService.candidate.candidateProducts[0].isAddComplimentaryPlu"
                                   (change)="handleIsAddComplimentaryPluChange($event)"
                                   [attributeError]="candidateProductError?.addComplimentaryPlu">
    </pm-attribute-display-checkbox>
  </div>
  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="isNextOrBackDisabled"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="isNextOrBackDisabled"></p-button>
  </pm-footer>
</pm-editor>
