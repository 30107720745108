import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupService} from '../../service/lookup.service';
import {AssociateUpcService} from '../../service/associate-upc.service';
import {GrowlService} from '../../growl/growl.service';
import {CandidateService} from '../../service/candidate.service';
import {
  AttachmentsCardPermissions,
  AttributeConfig,
  AttributeUPCConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Product,
  ProductPackagingCardModel,
  ProductPackagingCardPermissions
} from 'pm-models';
import {UPCInputState} from 'pm-components';
import {UUID} from 'angular2-uuid';
import {CandidateUtilService} from '../../service/candidate-util.service';
import { finalize } from 'rxjs/operators';
import {AssociateUpcStepperComponent} from '../../shared/components/associate-upc-stepper/associate-upc-stepper.component';

@Component({
  selector: 'app-pia-associate-basic-item-setup',
  templateUrl: './pia-associate-basic-item-setup.component.html',
  styleUrls: ['./pia-associate-basic-item-setup.component.scss']
})
export class PiaAssociateBasicItemSetupComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private lookupService: LookupService,
              private candidateService: CandidateService, public associateUpcService: AssociateUpcService,
              private growlService: GrowlService, private candidateUtilService: CandidateUtilService) { }

  public isViewingPage = true;
  private taskSubscription$: any;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public upcState: UPCInputState;
  public productImageUrl: string = null;
  private currentCandidateProductIndex = 1;
  private searchedCandidateProductIndex = 0;
  public productData: Product;
  public primaryUpc: ProductPackagingCardModel;
  public packageTypes: any;
  public subBrand: string;
  public isValidating: boolean = false;
  canNavigate = true;

  readonly productPackagingCardPermissions: ProductPackagingCardPermissions = {
    isReadOnly: true,
    packageType: {
      isHidden: true
    },
    productWeight: {
      isHidden: true
    }
  };

  readonly attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };

  unitUPCConfiguration: AttributeUPCConfig = {
    label: 'Unit UPC',
    description: `Enter the UPC and check digit that appears on this unit's packaging.`,
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: 'Associate Unit UPC',
    checkDigitPlaceholderText: 'Check #',
    name: 'associateUpcId'
  };

  subBrandConfiguration: AttributeConfig = {
    label: 'Sub brand',
    isRequired: false,
    inputGroupClass: 'typeahead-width',
    isReadOnly: () => true
  };

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.associateUpcService.getTaskId(),
        this.associateUpcService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.associateUpcService.resetService();
        this.associateUpcService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.ASSOCIATE_UPC) {
            this.associateUpcService.setOriginalAndCurrentCandidate(candidate);
            this.candidateError = this.associateUpcService.getAssociateUpcBasicDetailError();
            this.productImageUrl = null;
            this.setProductData();
            this.candidateProductError = this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id] ?
              this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id] : new CandidateProductError();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.associateUpcService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            this.associateUpcService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      }
    });
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    if (this.associateUpcService.getCandidate().candidateProducts.length > 1) {
      return this.associateUpcService.getCandidate().candidateProducts[this.currentCandidateProductIndex];
    } else {
      const candidateProduct = new CandidateProduct();
      candidateProduct.id = UUID.UUID();
      candidateProduct.candidateProductType = CandidateProduct.ASSOCIATE_UPC;
      return candidateProduct;
    }
  }

  setProductData() {
    this.currentCandidateProductIndex = this.associateUpcService.getCandidateProductsLength() - 1;
    if (this.currentCandidateProductIndex < 1) {
      this.addAssociateUpc();
    }
    if (this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_UPC) {
      this.lookupService.getUpc(
        this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].upc).subscribe(
        (primaryUpcData) => {
          this.productData = primaryUpcData.product;
          this.associateUpcService.getCandidate().productId = primaryUpcData.product.productId;
          this.associateUpcService.getCandidate().candidateProducts[this.currentCandidateProductIndex].description =
            primaryUpcData.product.productDescription;
          this.primaryUpc = primaryUpcData;
          this.setPrimaryUpc(primaryUpcData);
          this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
        });
    } else if (this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_ITEM) {
      this.lookupService.getItem(
        this.associateUpcService.getCandidate().candidateProducts[this.searchedCandidateProductIndex].itemCode)
        .subscribe((productData) => {
          // using the the upc from the item information to get all the needed fields
          this.lookupService.getUpc(productData.containedUpc.upc.scanCodeId).subscribe((primaryUpcData) => {
            this.productData = primaryUpcData.product;
            this.associateUpcService.candidate.candidateProducts[this.searchedCandidateProductIndex].upc =
              this.productData.primaryScanCodeId;
            this.associateUpcService.getCandidate().productId = primaryUpcData.product.productId;
            this.associateUpcService.getCandidate().candidateProducts[this.currentCandidateProductIndex].description =
              primaryUpcData.product.productDescription;
            this.primaryUpc = primaryUpcData;
            this.setPrimaryUpc(primaryUpcData);
            this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
          });
        });
    }
  }

  setPrimaryUpc(primaryUpcData: any) {
    this.subBrand = primaryUpcData.subBrand.description;
    this.primaryUpc.productLength = primaryUpcData.length;
    this.primaryUpc.productWidth = primaryUpcData.width;
    this.primaryUpc.productHeight = primaryUpcData.height;
    this.primaryUpc.retailSize = primaryUpcData.size;
    this.primaryUpc.totalVolume = primaryUpcData.sellingSizeOne;
    this.associateUpcService.getCandidate().buyer = { buyerId: primaryUpcData.item.hierarchy.commodity.bdm.bdmId,
      buyerName: primaryUpcData.item.hierarchy.commodity.bdm.fullName,
      userId: primaryUpcData.item.hierarchy.commodity.bdm.onePassId};

  }

  initializeData() {
    this.candidateError = this.associateUpcService.getAssociateUpcBasicDetailError();
    this.candidateProductError = this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id] ?
      this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id] : new CandidateProductError();
    this.productImageUrl = null;
    this.setProductData();
  }

  onClickNext() {
    this.jumpToPage('/piaAssociateCaseSelection');
  }

  onClickBack() {
    this.canNavigate = false;
    this.associateUpcService.saveCandidateAndNavigateTo('/piaAssociateUpcSetup', false).pipe(
      finalize(() => this.canNavigate = true)
    ).subscribe();
  }

  onClose() {
    this.isViewingPage = false;
    this.associateUpcService.saveCandidateAndClose();
  }

  validateUPC() {
    this.isValidating = true;
    const candidateProductId = this.getCurrentCandidateProduct().id;
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(),
      [CandidateValidatorType.UPC_VALIDATOR]).subscribe(() => {
      this.candidateProductError.upc = undefined;
      this.candidateProductError.upcCheckDigit = undefined;
    }, (error) => {
      this.isValidating = false;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        this.isValidating = false;
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.upc = returnedCandidateProductError.upc;
        this.candidateProductError.upcCheckDigit = returnedCandidateProductError.upcCheckDigit;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  addAssociateUpc() {
    const tempCandidateProduct = new CandidateProduct();
    tempCandidateProduct.id = UUID.UUID();
    tempCandidateProduct.candidateProductType = CandidateProduct.ASSOCIATE_UPC;
    this.associateUpcService.addCandidateProduct(JSON.parse(JSON.stringify(tempCandidateProduct)));
    this.associateUpcService.saveCandidate();
    this.currentCandidateProductIndex = this.associateUpcService.getCandidate().candidateProducts.length - 1;
  }

  deleteExistingAssociate(product: CandidateProduct) {
    if (this.currentCandidateProductIndex > 1) {
      this.associateUpcService.deleteCandidateProduct(product);
      this.associateUpcService.saveCandidate();
      this.currentCandidateProductIndex = this.associateUpcService.getCandidate().candidateProducts.length - 1;
    }
  }

  validateSingleUpc(product: CandidateProduct) {
    this.isValidating = true;
    let candidateProducts = JSON.parse(JSON.stringify(this.associateUpcService.getCandidate().candidateProducts));
    candidateProducts = candidateProducts.slice(0, 1);
    candidateProducts.push(product);
    const candidate = JSON.parse(JSON.stringify(this.associateUpcService.getCandidate()));
    candidate.candidateProducts = candidateProducts;
    const validator = product.isAddComplimentaryPlu ?
      CandidateValidatorType.ADD_COMPLIMENTARY_PLU_VALIDATOR : CandidateValidatorType.UPC_VALIDATOR;
    this.candidateService.validateCandidate(candidate,
      [validator]).subscribe(() => {
      this.isValidating = false;
      const candidateProduct =  this.candidateError.candidateProductErrors[product.id];
      if (candidateProduct) {
        this.candidateError.candidateProductErrors[product.id].upc = undefined;
        this.candidateError.candidateProductErrors[product.id].upcCheckDigit = undefined;
      }
    }, (error) => {
      this.isValidating = false;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors.candidateProductErrors;
        if (returnedCandidateError[product.id]) {
          this.candidateError.candidateProductErrors[product.id] = returnedCandidateError[product.id];
        }
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }



  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case AssociateUpcStepperComponent.ORIGINAL_UPC_ITEM_CODE: {
        this.onClickBack();
        break;
      }
      case AssociateUpcStepperComponent.CASE_SELECTION: {
        this.onClickNext();
        break;
      }
      case AssociateUpcStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/piaAssociateExtendedAttributes');
        break;
      }
    }
  }


  jumpToPage(urlToNavigate) {
    this.canNavigate = false;
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), [CandidateValidatorType.PIA_ASSOCIATE_BASIC_DETAILS_VALIDATOR,
      CandidateValidatorType.SUPPLIER_COMMENT_VALIDATOR]).subscribe(() => {

      this.candidateError = this.associateUpcService.setAssociateUpcBasicDetailError(new CandidateError());
      this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.associateUpcService.setAssociateUpcBasicDetailError(error.error.candidateErrors);
        this.candidateProductError = this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id];
      }
      this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    });
  }
}
