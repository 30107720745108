import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ItemWeightTypeService {

  // Candidate Weight Type Departments
  private static ITEM_WEIGHT_TYPE_DEPARTMENTS = ['02', '06', '09', '23'];

  /**
   * Returns a list of departments that are eligible for weight type switching.
   */
  public getItemWeightTypeDepartments(): any {
    return ItemWeightTypeService.ITEM_WEIGHT_TYPE_DEPARTMENTS;
  }
}
