<pm-editor #pmEditor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-pia-additional-case-pack-stepper [candidateErrors]="supplierCasePackService.getAllPiaCandidateErrors()" [stepIndex]="2"
                                          (indexClick)="onClickStepper($event)">
    </app-pia-additional-case-pack-stepper>
  </pm-header>

  <pm-title>Tell us about this new case pack.</pm-title>

  <div *ngIf="supplierCasePackService.candidate" class="col-md-8">
    <div class="row ml-0">
      <img class="product-image" src="{{productImageUrl ? productImageUrl : DEFAULT_NO_PRODUCT_IMAGE}}">
      <div class="row ml-0">
        <div class="col">
          <div class="row ml-0">
            <div class="upc-header">
              UPC: {{productData?.primaryScanCodeId}}-{{scanCodeCheckDigit}}
            </div>
          </div>
          <div class="attribute-description">
            <p>{{productData?.productDescription}}</p>
            <p>{{productInfoString}}</p>
            <div class="row ml-0">
              <button *ngIf="!displayingDrawer" pButton type="button" label="Show existing cases" (click)="showExistingCasePacks()" class="ui-button-link"></button>
              <button *ngIf="displayingDrawer" pButton type="button" label="Hide existing cases" (click)="collapse()" class="ui-button-link"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="supplierCasePackService.candidate">


    <pm-master-pack-details-card [(model)]="supplierCasePackService.candidate"
                                 [permissions]="masterPackDetailsCardPermissions"
                                 [errorModel]="candidateError"
                                 (masterPackChange)="onMasterPackChange($event)">
    </pm-master-pack-details-card>


    <pm-inner-pack-details-card [(model)]="supplierCasePackService.candidate"
                                [permissions]="innerPackDetailsCardPermissions"
                                [errorModel]="candidateError" (modelChange)="onInnerPackChange()"
                                (innerPackQuantityChange)="innerPackQuantityChange()">
    </pm-inner-pack-details-card>

    <pm-case-details-card *ngIf="additionalCasePackBasicsCardPermissions" [(candidateModel)]="supplierCasePackService.candidate"
                          [(candidateProductModel)]="supplierCasePackService.candidate.candidateProducts[currentCandidateProductIndex]"
                          [permissions]="additionalCasePackBasicsCardPermissions"
                          [candidateErrorModel]="candidateError"
                          [candidateProductErrorModel]="candidateProductError"
                          [upcState]="upcState"
                          (validateUPC)="validateUpc()">
    </pm-case-details-card>

    <pm-imported-product-card [(model)]="supplierCasePackService.candidate.candidateProducts[currentCandidateProductIndex]"
                              [containerSizes]="containerSizes"
                              [incoTermsList]="incoTermsList"
                              [permissions]="importProductCardPermissions"
                              [errorModel]="candidateProductError">
    </pm-imported-product-card>

    <pm-display-ready-unit-card [(model)]="supplierCasePackService.candidate"
                                [permissions]="displayReadyUnitCardPermissions"
                                [errorModel]="candidateError">
    </pm-display-ready-unit-card>

    <pm-cost-link-card [model]="supplierCasePackService.candidate"
                       (validateCostLink)="validateCostLink()"
                       [costLinkState]="costLinkState"
                       [errorModel]="candidateError"
                       [permissions]="costLinkCardPermissions">
    </pm-cost-link-card>

    <pm-cost-card [model]="supplierCasePackService.candidate"
                  [permissions]="costCardPermissions"
                  [errorModel]="candidateError">
    </pm-cost-card>

    <pm-supplier-details-card *ngIf="supplierDetailsCardPermissions" [(candidateModel)]="supplierCasePackService.candidate"
                              [candidateProductModel]="supplierCasePackService.candidate.candidateProducts[currentCandidateProductIndex]"
                              [permissions]="supplierDetailsCardPermissions"
                              [inputCostOwners]="costOwners"
                              [candidateErrorModel]="candidateError">
    </pm-supplier-details-card>

    <pm-merchandising-info-card [(candidateModel)]="supplierCasePackService.candidate"
                                [(candidateProductModel)]="supplierCasePackService.candidate.candidateProducts[currentCandidateProductIndex]"
                                [permissions]="merchandisingInfoCardPermissions"
                                [merchandiseTypes]="merchandiseTypes"
                                [commodities]="commodities"
                                (taxableChange)="setDefaultVertexTaxCategory(supplierCasePackService.candidate)"
                                [candidateErrorModel]="candidateError"
                                [candidateProductErrorModel]="candidateProductError">
    </pm-merchandising-info-card>

    <pm-omi-remarks-card [(model)]="supplierCasePackService.candidate.candidateProducts[currentCandidateProductIndex]"
                         [permissions]="omiRemarksPermissions"
                         [errorModel]="candidateProductError">
    </pm-omi-remarks-card>

    <pm-attachments-card [(model)]="supplierCasePackService.candidate"
                         [permissions]="attachmentsCardPermissions"
                         [errorModel]="candidateError">
    </pm-attachments-card>
  </ng-container>

  <pm-editor-drawer-container>
    <ng-container>
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>Existing cases</h2>
        <ng-template *ngIf="productData?.items" ngFor let-casePack [ngForOf]="productData?.items">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!casePack.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(casePack)"></i>
              <i *ngIf="casePack.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(casePack)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{casePack.caseUpc}}-{{casePack.caseUpcCheckDigit}}
                </label>
                <i *ngIf="casePack.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                WHS | {{casePack.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="casePack.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{casePack.supplierItems[0]?.pack}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
            <!--  Inner Pack -->
            <pm-attribute-grid [title]="'Inner Packs'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Inner pack quantity'" [canEdit]="false">
                    {{casePack.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>


        <ng-template *ngIf="productData?.dsdItems" ngFor let-dsdItem [ngForOf]="productData?.dsdItems">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!dsdItem.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(dsdItem)"></i>
              <i *ngIf="dsdItem.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(dsdItem)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{dsdItem.upc}}-{{dsdItem.upc | upcCheckDigit}}
                </label>
                <i *ngIf="dsdItem.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                DSD | {{dsdItem.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="dsdItem.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{dsdItem?.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>

      </div>
    </ng-container>
  </pm-editor-drawer-container>


  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button id="submitButton" class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
