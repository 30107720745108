<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">

  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label">
      <label for="">{{attribute.label}}</label>
    </div>

    <div class="attribute-control">
      <div class="attribute-volume">

        <input type="text" pInputText [(ngModel)]="lengthModel"
               appOnlyNumber [integerDigits]="attribute.numberCount" [decimalPlaces]="attribute.decimalCount"
          (blur)="onLengthBlur($event)" (focus)="onLengthFocus($event)"
          [placeholder]="attribute.lengthPlaceholder || 'Length (in)'"
          [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
          [disabled]="attribute.isDisabled && attribute.isDisabled()"
          [ngClass]="{'ui-inputtext-invalid' : attributeLengthError, 'length-input': true}">

        <input type="text" pInputText [(ngModel)]="widthModel"
               appOnlyNumber [integerDigits]="attribute.numberCount" [decimalPlaces]="attribute.decimalCount"
          (blur)="onWidthBlur($event)" (focus)="onWidthFocus($event)"
          [placeholder]="attribute.widthPlaceholder || 'Width (in)'"
          [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
          [disabled]="attribute.isDisabled && attribute.isDisabled()"
          [ngClass]="{'ui-inputtext-invalid' : attributeWidthError, 'width-input': true}">

        <input type="text" pInputText [(ngModel)]="heightModel"
               appOnlyNumber [integerDigits]="attribute.numberCount" [decimalPlaces]="attribute.decimalCount"
          (blur)="onHeightBlur($event)" (focus)="onHeightFocus($event)"
          [placeholder]="attribute.heightPlaceholder || 'Height (in)'"
          [readonly]="attribute.isReadOnly && attribute.isReadOnly()"
          [disabled]="attribute.isDisabled && attribute.isDisabled()"
          [ngClass]="{'ui-inputtext-invalid' : attributeHeightError, 'height-input': true}">


        <input type="number" step="0.01" pInputText [(ngModel)]="volumeModel"
          [placeholder]="attribute.volumePlaceholder || 'ft&#179;'" [readonly]="true"
          [ngClass]="{'ui-inputtext-invalid' : attributeVolumeError, 'volume-input': true}" tabindex="-1">

      </div>

      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="attributeLengthError" class="attribute-error-message">
        {{attributeLengthError}}
      </p>
      <p *ngIf="attributeWidthError" class="attribute-error-message">
        {{attributeWidthError}}
      </p>
      <p *ngIf="attributeHeightError" class="attribute-error-message">
        {{attributeHeightError}}
      </p>
      <p *ngIf="attributeVolumeError" class="attribute-error-message">
        {{attributeVolumeError}}
      </p>
    </div>
  </div>
</ng-container>
