import { AttributeConfig } from './attributeConfig';
export interface AttributeUPCConfig extends AttributeConfig {

  UPCType?: UPC_Type;

  checkDigitPlaceholderText?: string;
  UPCCheckUrl?: string;
  productIndex?: number;

}

export enum UPC_Type {
  UnitUPC,
  CaseUPC
}
