import { Component, OnInit, ContentChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pm-comment-grid-cell-info',
  template: '<ng-content></ng-content>'
})
export class CommentGridCellInfoComponent {}

@Component({
  selector: 'pm-comment-grid-cell',
  templateUrl: './comment-grid-cell.component.html',
  styleUrls: ['./comment-grid-cell.component.scss']
})
export class CommentGridCellComponent implements OnInit {
  constructor() {}

  @Input()
  label;

  @Input()
  errorMessage;

  @Input()
  errorMessages;

  @Input()
  canEdit: boolean;

  @Input()
  hasReviewerComment: boolean;

  @Output() editClicked: EventEmitter<any> = new EventEmitter();

  @ContentChild(CommentGridCellInfoComponent, { static: true }) commentGridCellInfo;

  ngOnInit() {}

  onEditClicked($event) {
    this.editClicked.emit($event);
  }
}
