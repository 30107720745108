import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteButtonComponent } from './delete-button.component';
import { ButtonModule } from 'primeng/button';
import { StaticOverlayPanelModule } from '../overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {TooltipModule} from 'primeng/tooltip';

@NgModule({
  declarations: [DeleteButtonComponent],
  exports: [DeleteButtonComponent],
  imports: [CommonModule, ButtonModule, StaticOverlayPanelModule, TooltipModule]
})
export class DeleteButtonsModule {}
