import { BaseAttribute } from './base-attribute';
import { AttributeTextInputConfig, Candidate, TextInputType } from 'pm-models';

// tslint:disable-next-line:max-line-length
import { AttributeDisplayTextinputComponent } from '../../shared/attribute-display/attribute-display-textinput/attribute-display-textinput.component';

export class RetailSize implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayTextinputComponent;
  }

  getAttributeConfig(overrides?): AttributeTextInputConfig {
    return {
      label: 'Retail size',
      description: 'This is the retail unit size. Example: six 12oz cans would read 6/12oz',
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      textInputType: TextInputType.text,
      inputGroupClass: 'ui-narrow-input',
      placeholderText: '',
      ...overrides
    };
  }

  setupComponent(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidate.retailSize;
    componentInstance.modelChange.subscribe(x => {
      candidate.retailSize = x;
    });
  }
  updateModel(componentInstance: AttributeDisplayTextinputComponent, candidate: Candidate) {
    if (componentInstance.model !== candidate.retailSize) {
      componentInstance.model = candidate.retailSize;
    }
  }
}
