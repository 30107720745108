import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {SupplierProductService} from '../service/supplier-product.service';

import {
  AttributeConfig,
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  LocationGroupStores,
  Store,
  TextInputType
} from 'pm-models';

import {ActivatedRoute, Router} from '@angular/router';
import {CostService} from '../service/cost.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {CandidateErrorUtilService} from '../service/candidate-error-util.service';

@Component({
  selector: 'app-set-up-stores-overlay',
  templateUrl: './set-up-stores-overlay.component.html',
  styleUrls: ['./set-up-stores-overlay.component.scss']
})
export class SetUpStoresOverlayComponent implements OnInit {

  constructor(public route: ActivatedRoute, public router: Router, public supplierProductService: SupplierProductService,
              public costService: CostService, private candidateErrorUtilService: CandidateErrorUtilService) {
    this.types = [
      { label: 'Stores', value: this.STORES },
      { label: 'Groups', value: this.GROUPS }
    ];
  }

  @Output()
  saveButtonClicked = new EventEmitter<Candidate>();

  @Output()
  cancelButtonClicked = new EventEmitter<void>();

  public isViewingPage = true;
  public currentCandidateProductIndex;

  @Input()
  public candidateError: CandidateError;

  @Input()
  public candidateProductError: CandidateProductError;

  @Input()
  public canClickSave = true;

  @Input()
  public candidate: Candidate;

  public noStoresSelected = true;
  public distributorName: any;
  private taskSubscription$: any;
  public showSearchPage: boolean = false;

  listCostConfiguration: AttributeTextInputConfig = {
    label: 'List cost',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    textInputType: TextInputType.decimal,
    placeholderText: '$',
    numberCount: 4,
    decimalCount: 2
  };

  public showNoStoresError: boolean;
  public storesInput: any = '';
  public storesErrorMessage: string;
  public selectAllAuthGroups: boolean;
  public STORES = 'stores';
  public GROUPS = 'groups';

  types: SelectItem[];
  selectedType: string = 'stores';


  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      let taskId;
      if (this.supplierProductService.getTaskId()) {
        taskId = this.supplierProductService.getTaskId();
      } else if (this.supplierProductService.getTask() && this.supplierProductService.getTask().id) {
        taskId = this.supplierProductService.getTask().id;
      }
      if (this.candidate) {
        this.supplierProductService.setOriginalAndCurrentCandidate(this.candidate);
        this.initializeData();
      } else if (!taskId && params.keys.length > 0 && params.has('taskId')) {
        this.supplierProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT || candidate.candidateType === Candidate.ADDITIONAL_DISTRIBUTOR
            || candidate.candidateType === Candidate.SUPPLIER_ADDITIONAL_DISTRIBUTOR ||
            candidate.candidateType === Candidate.PLU) {
            this.candidateError = this.supplierProductService.getStoreAuthError();
            this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
            this.currentCandidateProductIndex = this.supplierProductService.getCurrentCandidateProductIndex();
            this.initializeData();
          }
        });
      } else if (this.supplierProductService.getOriginalCandidate() && this.supplierProductService.getCandidate()) {
        this.initializeData();
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  /**
   * On filter changes, set all the checkboxes/viewports to false and remove searched stores.
   */
  onFilterChange() {
    this.supplierProductService.setStoresSearched(null);
    this.supplierProductService.setStoresInput(null);
    this.storesInput = '';
    this.selectAllAuthGroups = false;

    const authGroups: LocationGroupStores[] = this.supplierProductService.getAuthGroups();
    if (authGroups) {
      for (let x = 0; x < authGroups.length; x++) {
        authGroups[x].isViewing = false;
        authGroups[x].checked = false;
        for (let y = 0; y < authGroups[x].stores.length; y++) {
          authGroups[x].stores[y].checked = false;
        }
      }
    }
  }

  storesInputChangeEvent(storesInput: any) {
    this.storesErrorMessage = this.validateStoresInput(storesInput);
  }

  isShowTheseStoresDisabled() {
    return this.selectedType === this.STORES &&
      (this.storesErrorMessage || !this.storesInput);
  }

  /**
   * Stores input should be comma or newline separated group of store numbers.
   * @param storesInput
   */
  validateStoresInput(storesInput: any) {
    const stores = storesInput.trim().replace(/,/g, '\n');
    const storesArray = stores.split('\n');
    const regexp: RegExp = /^\d+$/; // only numbers

    let result;

    for (let i = 0; i < storesArray.length; i++) {
      // make sure all stores are a number
      if (!regexp.test(storesArray[i])) {
        result = 'Please enter store numbers, separated by a comma or each store on a new line.';
        break;
      }
    }
    if (!result) {
      this.supplierProductService.setStoresSearched(storesArray);
      this.supplierProductService.setStoresInput(storesInput);
    }
    return result;
  }

  public initializeData() {
    this.distributorName = this.supplierProductService.getCandidate().vendor.description;
    if (this.supplierProductService.getStoresInput()) {
      this.storesInput = this.supplierProductService.getStoresInput();
    } else {
      this.onFilterChange();
    }

    // if there's no auth selected auth groups, initialize the data.
    if (!this.supplierProductService.getSelectedAuthGroups()) {
      this.showSearchPage = true;
      // if there's auth groups and no candidate data, set the selected to the auth groups data, else initialize the data
      // prefilled w/ candidate info.
      if (this.supplierProductService.getAuthGroups()) {
        this.showNoStoresError = this.supplierProductService.validateHasNoStores();
        for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
          this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
        if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());

          this.supplierProductService.openAuthGroups();
        } else {
          this.supplierProductService.setSelectedAuthGroupsFromCandidate();
          this.showSearchPage = false;
        }

        // else Fetch auth groups and set SelectedAuthGroups
      } else if (!this.supplierProductService.candidate.overrideDepartment) {
        this.supplierProductService.findAllAuthGroups().subscribe((authGroups) => {

          this.supplierProductService.setAuthGroups(authGroups);
          this.showNoStoresError = this.supplierProductService.validateHasNoStores();
          for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
            this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
          }

          // if there's no candidate data, set the selected to the auth groups data, else initialize the data
          // prefilled w/ candidate info.
          if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
            this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
            this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());

            this.supplierProductService.openAuthGroups();
          } else {
            this.supplierProductService.setSelectedAuthGroupsFromCandidate();
            this.showSearchPage = false;
          }
        });
      } else {
        this.supplierProductService.findAllAuthGroupsByParms(this.supplierProductService.candidate.vendor.apNumber,
          this.supplierProductService.candidate.overrideSubDepartment.departmentId,
          this.supplierProductService.candidate.overrideSubDepartment.subDepartmentId).subscribe(
          (authGroups) => {

            this.supplierProductService.setAuthGroups(authGroups);
            this.showNoStoresError = this.supplierProductService.validateHasNoStores();
            if (this.supplierProductService.getAuthGroups()) {
              for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
                this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
              }
            }

            // if there's no candidate data, set the selected to the auth groups data, else initialize the data
            // prefilled w/ candidate info.
            if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
              this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
              this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());

              if (this.supplierProductService.getAuthGroups()) {
                this.supplierProductService.openAuthGroups();
              }
            } else {
              this.supplierProductService.setSelectedAuthGroupsFromCandidate();
              this.showSearchPage = false;
            }
          });
      }
    }
  }

  onClickSave() {
    this.showSearchPage = false;
    this.applySelectedStoresToCandidate();
    this.supplierProductService.setSelectedAuthGroupsFromCandidate();
    this.saveButtonClicked.emit(this.supplierProductService.getCandidate());
  }

  onClickCancel() {
    this.showSearchPage = false;
    this.supplierProductService.setSelectedAuthGroupsFromCandidate();
    this.cancelButtonClicked.emit();
  }

  onClose() {
    this.showSearchPage = false;
    this.supplierProductService.setSelectedAuthGroupsFromCandidate();
    this.cancelButtonClicked.emit();
  }

  onClickAuthGroupCaret(authGroup) {
    authGroup.isViewing = !authGroup.isViewing;
  }

  getSelectedStoresCountString(authGroup) {
    if (!authGroup.stores || authGroup.stores.length === 0) {
      return '';
    }
    let count = 0;
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].checked) {
        count++;
      }
    }
    return count + ' of ' + authGroup.stores.length + ' stores selected.';
  }

  onStoreChecked(event, store, authGroup) {
    const checked = event.checked;

    if (!checked) {
      authGroup.checked = checked;
    } else {
      let areAllStoresChecked = true;
      for (let x = 0; x < authGroup.stores.length; x++) {
        if (!authGroup.stores[x].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        authGroup.checked = true;
      }
    }
  }

  onListCostChange(event, authGroup) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].listCost = event;
    }
  }


  listCostFocus(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }


  onClickApplyToAllGroups(event, listCost, listCostOverlay) {
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      this.supplierProductService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.supplierProductService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }

    event.stopPropagation();
    listCostOverlay.hide();
  }

  onClickApplyToAllRemainingGroups(event, listCost, listCostOverlay) {
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      if (this.supplierProductService.getSelectedAuthGroups()[x].listCost) {
        continue;
      }
      this.supplierProductService.getSelectedAuthGroups()[x].listCost = listCost;
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        this.supplierProductService.getSelectedAuthGroups()[x].stores[y].listCost = listCost;
      }
    }

    event.stopPropagation();
    listCostOverlay.hide();
  }

  getListCostOverlayStyle(index) {
    if (index === 0) {
      return {margin: '-1.375rem 0 0 16rem'};
    } else {
      return {margin: '-2rem 0 0 16rem'};
    }
  }

  getAllStoreAuthCountsString() {
    if (!this.supplierProductService.getSelectedAuthGroups()) {
      return '';
    }
    let totalCount = 0;
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          totalCount++;
        }
      }
    }
    return '' + totalCount + ' stores selected';
  }

  getStoreAuthCountsString(authGroup: LocationGroupStores) {
    if (!authGroup) {
      return '';
    }
    let storeCount = 0;
    for (let y = 0; y < authGroup.stores.length; y++) {
      if (authGroup.stores[y].checked) {
        storeCount++;
      }
    }
    return  'Group ' + authGroup.splrLocationGroupId + ' | ' +
      storeCount + ' of ' + authGroup.stores.length + ' selected';
  }

  displayShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      if (authGroup.stores[x].isHidden) {
        return true;
      }
    }
    return false;
  }

  onClickShowAllStores(authGroup: LocationGroupStores) {
    for (let x = 0; x < authGroup.stores.length; x++) {
      authGroup.stores[x].isHidden = false;
    }
  }

  onClickShowAllStores2() {
    this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());
    if (this.supplierProductService.getSelectedAuthGroups()) {
      this.supplierProductService.openAuthGroups();
    }
    // todo: buttons disabled ?
    this.showSearchPage = false;

  }

  onAuthGroupButtonClick(index, event) {

    if (!event) {
      this.selectAllAuthGroups = false;
    } else {
      let areAllGroupsChecked = true;
      for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
        if (!this.supplierProductService.getAuthGroups()[x].checked) {
          areAllGroupsChecked = false;
        }
      }
      if (areAllGroupsChecked) {
        this.selectAllAuthGroups = true;
      }

    }
  }

  hasSelectedGroup() {
    if (!this.supplierProductService.getAuthGroups() || this.supplierProductService.getAuthGroups().length === 0) {
      return true;
    }
    for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
      if (this.supplierProductService.getAuthGroups()[x].checked) {
        return true;
      }
    }
    return false;
  }

  onClickAllAuthGroupCheckbox(event) {
    for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
      this.supplierProductService.getAuthGroups()[x].checked = event;
    }
    // trigger update
    this.supplierProductService.setAuthGroups(JSON.parse(JSON.stringify(this.supplierProductService.getAuthGroups())));
  }

  onClickShowTheseStores() {
    // todo: buttons disabled ?
    if (!this.isShowTheseStoresDisabled()) {
      this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());
      for (let x = 0; x < this.supplierProductService.getStoresSearched().length; x++) {
        this.findAndCheckStore(this.supplierProductService.getStoresSearched()[x]);
      }
      this.setAuthGroupCheckboxesAndHideNonSelectedStores();
      this.showSearchPage = false;
    }
  }

  findAndCheckStore(storeNumber) {
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (+storeNumber === this.supplierProductService.getSelectedAuthGroups()[x].stores[y].custLocationNumber) {
          this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked = true;
          this.supplierProductService.getSelectedAuthGroups()[x].isViewing = true;
          return;
        }
      }
    }
    // TODO: Store not found?
  }

  /**
   * Sets auth groups to checked if all stores are checked.
   */
  setAuthGroupCheckboxesAndHideNonSelectedStores() {
    let areAllStoresChecked = true;
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (!this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        this.supplierProductService.getSelectedAuthGroups()[x].checked = true;
      }

    }
    this.hideNonSelectedStores();
  }

  hideNonSelectedStores() {
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      if (!this.supplierProductService.getSelectedAuthGroups()[x].isViewing) {
        continue;
      }
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (!this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          this.supplierProductService.getSelectedAuthGroups()[x].stores[y].isHidden = true;
        }
      }
    }
  }

  /**
   * Sets the selected stores onto the candidate model.
   */
  applySelectedStoresToCandidate() {
    const authGroups: LocationGroupStores[] = [];
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      let authGroup: LocationGroupStores = null;
      const stores: Store[] = [];

      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          stores.push(JSON.parse(JSON.stringify(this.supplierProductService.getSelectedAuthGroups()[x].stores[y])));
        }
      }
      if (stores.length > 0) {
        authGroup =  JSON.parse(JSON.stringify(this.supplierProductService.getSelectedAuthGroups()[x]));
        authGroup.stores = stores;
        authGroups.push(authGroup);
      }
    }
    this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores = authGroups;
  }

  hasListCostError(authGroup) {
    return false;
  }

  getErrorsByAuthGroup(authGroup) {
  }

  hasNoStoresSelected () {
    if (!this.supplierProductService.getSelectedAuthGroups() || this.supplierProductService.getSelectedAuthGroups().length === 0) {
      return true;
    }

    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          return false;
        }
      }
    }
    return true;
  }

  onClickBackToStoreSelection() {
    this.selectedType = 'stores';
    this.showSearchPage = true;
  }

  storesInputConfiguration: AttributeTextInputConfig = {
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'storesListId',
    textInputType: TextInputType.textarea,
    inputGroupClass: 'find-stores-by-selec',
    placeholderText: '',
    description: 'Separate store numbers by a comma or enter each store on a separate line.',
  };

  allGroupsConfiguration: AttributeConfig = {
    label: 'All groups',
    description: 'Show stores in all groups',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'allGroupsId',
    defaultValue: false
  };

  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.supplierProductService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.supplierProductService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  private getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  onClickGroupShowStores() {
    // todo: buttons disabled if none selected?

    const selectedAuthGroups: LocationGroupStores[] = [];

    for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
      if (this.supplierProductService.getAuthGroups()[x].checked) {
        selectedAuthGroups.push(JSON.parse(JSON.stringify(this.supplierProductService.getAuthGroups()[x])));
      }
    }
    this.supplierProductService.setSelectedAuthGroups(selectedAuthGroups);
    this.supplierProductService.openAuthGroups();
    this.showSearchPage = false;
  }
}
