import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective implements OnInit {
  keyCodes = [46, 8, 9, 27, 13];
  // if decimal places is specified this .x is allowed
  @Input() decimalPlaces: number;
  @Input() integerDigits: number;

  private regex: RegExp;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    const integerDigs = this.integerDigits && this.integerDigits > 0 ? `{0,${this.integerDigits}}` : '+'
    this.regex = new RegExp('^\\d' + integerDigs + (this.decimalPlaces && this.decimalPlaces > 0 ? '\\.?\\d{0,' + this.decimalPlaces + '}' : '') + '$', 'g');
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    if (this.keyCodes.indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }

    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const next = event.clipboardData ? event.clipboardData.getData('text').trim() :
      window['clipboardData'] ? window['clipboardData'].getData('Text').trim() : '';
    event.preventDefault();
    if (next && String(next).match(this.regex)) {
      document.execCommand('insertText', false, next);
    }
  }

  handleKey(e: KeyboardEvent) {

  }

  handleKeyCode(e: KeyboardEvent) {
    if (this.keyCodes.indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
}
