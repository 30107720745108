<pm-review *ngIf="isViewingPage" (closeEvent)="onClose()">
  <h2>Review {{getToReviewSize()}} Candidates</h2>
  <h6>Review these candidates’ data and fill out the form. The data you enter will apply to each candidate below.</h6>
  <pm-upload-candidate-table [candidates]="candidatesInTable" [isSca]="true"
                             [workflowState]="workflowState" [candidateProductIndex]="candidateProductIndex"
                             [candidateIdToCandidateErrorMap]="candidateErrors">
  </pm-upload-candidate-table>

  <pm-review-drawer-container class="bulkAction-sca-drawer">
    <ng-container *ngIf="candidatesInTable">
      <div class="container p-3">

        <h2>Warehouse Details</h2>
        <p>Configure one or more of the warehouses below for this product.</p>

        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="maxShipConfiguration"
                                           [(model)]="currentMaxShip"
                                           [attributeError]="candidateError?.maxShip"
                                           (inputFocusOut)="changeCandidate(currentMaxShip, 'maxShip')">
          </pm-attribute-display-text-input>
        </div>

        <hr/>
        <ng-template ngFor let-warehouse [ngForOf]="warehouseData">

          <div class="attribute-group">
            <div>
              <p-checkbox id="whsCheckbox"
                          [(ngModel)]="warehouse.checked"
                          (onChange)="selectedWarehouseChange($event, warehouse)"
                          binary="true">
              </p-checkbox>
            </div>

            <div>
              <div class="attribute-label">
                <label>
                  {{warehouse.name}}
                </label>
              </div>
              <p class="attribute-description">
                ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
              </p>
              <p *ngIf="warehouse.checked && candidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
                 class="error-message bicep-error">
                {{candidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
              </p>
            </div>

          </div>


          <div *ngIf="warehouse.checked">
            <div class="attributes-stacked">
              <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                          [(model)]="warehouse.orderUnitId"
                                          (change)="orderUnitChange($event, warehouse)"
                                          [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
              </pm-attribute-display-radio>
              <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                              [(model)]="warehouse.orderRestriction"
                                              (selection)="orderRestrictionChange($event, warehouse)"
                                              [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
              </pm-attribute-display-typeahead>
            </div>

          </div>
          <hr/>

        </ng-template>

        <div class="attribute-group">
          <div>
            <p-checkbox id="whsMissingCheckbox"
                        (onChange)="warehouseMissingCheckboxChange($event, isMissingWarehouses, 'missingWarehouses')"
                        [(ngModel)]="isMissingWarehouses"
                        binary="true">
            </p-checkbox>
          </div>

          <div>
            <div class="attribute-label">
              <label style="font-size: 18px !important;">
                Warehouse missing?
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="isMissingWarehouses">
          <p style="font-size: 14px;">Not seeing the warehouse you need? This could happen if the bicep or AP number is wrong, or if a warehouse wasn't tied to a particular bicep.</p>
          <p style="font-size: 14px;">Right now, only Procurement Support can fix this issue. Let them know which warehouse you want to select and if they need to change the bicep or AP number referenced by this candidate.</p>
          <div class="attributes-stacked">
            <pm-attribute-display-text-input [attribute]="missingWarehousesCommentsConfiguration"
                                             [attributeError]="candidateError?.missingWarehousesComment"
                                             [(model)]="currentMissingWarehousesComment"
                                             (keypress) ="updatedMissingWarehousesComment()"
                                             (inputFocusOut)="changeCandidate(currentMissingWarehousesComment, 'missingWarehousesComment')">
            </pm-attribute-display-text-input>
          </div>
        </div>
        <hr/>
        <div class="text-right">
          <p-button (onClick)="onClickApprove()" [disabled]="isApproveDisabled" class="m-2"
                    label="Approve all"></p-button>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>
</pm-review>
