import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductPricingCardComponent } from './product-pricing-card.component';

import { CardModule } from '../../shared/card/card.module';

import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';

@NgModule({
  declarations: [ProductPricingCardComponent],
  imports: [CommonModule, CardModule, AttributeDisplayModule],
  exports: [CardModule, ProductPricingCardComponent, AttributeDisplayModule]
})
export class ProductPricingCardModule {}
