<pm-review *ngIf="isViewingPage" (closeEvent)="onClose()">
  <h2 class="mt-5">Review {{getToReviewSize()}} Candidates</h2>
  <h6>Review these candidates’ data and fill out the form. The data you enter will apply to each candidate below.</h6>

  <div *ngIf="candidatesInTable && candidatesInTable.length > 0" class="row ml-0">
    <pm-upload-candidate-table [candidates]="candidatesInTable" [isBuyer]="true" [isDsd]="isDsd" [isWarehouse]="isWarehouse"
                               [workflowState]="workflowState" [candidateProductIndex]="candidateProductIndex"
                               [candidateIdToCandidateErrorMap]="candidateErrors">
    </pm-upload-candidate-table>
  </div>

  <pm-review-drawer-container class="bulkAction-bmd-drawer">
    <ng-container *ngIf="candidatesInTable">
      <div class="container p-3">
        <div class="attributes-stacked">


          <h3>Required Fields</h3>
          <pm-attribute-display-typeahead [attribute]="merchandiseTypeConfiguration"
                                          [attributeError]="candidateError?.merchandiseType"
                                          (selection)="merchandiseTypeChange($event)"
                                          [(model)]="merchandiseType">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-typeahead [attribute]="commodityConfiguration"
                                          [attributeError]="candidateError?.commodity"
                                          (selection)="commodityChange($event, storeGroupsChangeOverlay, storeGroupsChangeDiv)"
                                          [(model)]="commodity">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-typeahead [attribute]="subCommodityConfiguration"
                                          [attributeError]="candidateError?.subCommodity"
                                          (selection)="subCommodityChange($event)"
                                          [(model)]="subCommodity">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-typeahead [attribute]="pssDepartmentConfiguration"
                                          [attributeError]="candidateError?.pssDepartment"
                                          (selection)="pssDepartmentChange($event)"
                                          [(model)]="pssDepartment">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-text-input [attribute]="inboundSpecConfiguration"
                                           [attributeError]="candidateError?.inboundSpecDays"
                                           (inputFocusOut)="changeCandidate(inboundSpecDays, 'inboundSpecDays')"
                                           [(model)]="inboundSpecDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input
            (inputFocusOut)="changeCandidate(warehouseReactionDays, 'warehouseReactionDays')"
            [(model)]="warehouseReactionDays"
            [attributeError]="candidateError?.warehouseReactionDays" [attribute]="reactionDaysConfiguration">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input
            (inputFocusOut)="changeCandidate(guaranteeToStoreDays, 'guaranteeToStoreDays')"
            [(model)]="guaranteeToStoreDays"
            [attributeError]="candidateError?.guaranteeToStoreDays" [attribute]="guaranteeToStoreDaysConfiguration">
          </pm-attribute-display-text-input>

          <pm-attribute-display-radio *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0])" [attribute]="pricingTypeConfiguration"
                                      (change)="pricingTypeChange($event)" [(model)]="retailType"
                                      [attributeError]="candidateError?.retailType">
          </pm-attribute-display-radio>

          <pm-attribute-display-muli-source-input *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0])"
                                                  [attribute]="retailLinkConfiguration"
                                                  [attributeError]="candidateError?.retailLink"
                                                  [model]="retailLink?.searchedId"
                                                  (modelChange)="onRetailLinkNumberChange($event)"
                                                  [selectCategory]="retailLink?.upcType"
                                                  (selectCategoryChange)="onRetailLinkTypeChange($event)"
                                                  [state]="retailLinkState"
                                                  (validate)="validateRetailLink()">
          </pm-attribute-display-muli-source-input>

          <pm-attribute-display-dual-numeric-input *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0])" [attribute]="xForConfiguration"
                                                   (firstInputModelChange)="changeCandidate(retailXFor, 'retailXFor')"
                                                   (secondInputModelChange)="changeCandidate(retailPrice, 'retailPrice')"
                                                   [(firstInputModel)]="retailXFor"
                                                   [(secondInputModel)]="retailPrice"
                                                   [firstInputModelError]="candidateError?.retailXFor"
                                                   [secondInputModelError]="candidateError?.retailPrice">
          </pm-attribute-display-dual-numeric-input>

          <div *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0])" class="row" style="margin-left: 0; margin-bottom: 25px;">
            <pm-attribute-display-text-input [attribute]="pennyProfitConfiguration"
                                             [model]="costService.getHebPennyProfit(candidatesInDrawer[0])">
            </pm-attribute-display-text-input>

            <pm-attribute-display-text-input [attribute]="marginPercentConfiguration"
                                             [model]="costService.getHebMargin(candidatesInDrawer[0])">
            </pm-attribute-display-text-input>
          </div>


          <h3>Additional fields</h3>
          <pm-attribute-display-text-input *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0])" (inputFocusOut)="changeCandidate(likeId, 'likeId')" [(model)]="likeId"
                                           [attributeError]="candidateError?.likeId"
                                           [attribute]="likeItemCodeConfiguration">
          </pm-attribute-display-text-input>
          <pm-attribute-display-text-input *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0])" (inputFocusOut)="changeCandidate(numberOfStores, 'numberOfStores')"
                                           [(model)]="numberOfStores"
                                           [attributeError]="candidateError?.numberOfStores"
                                           [attribute]="estimatedStoreCountConfiguration">
          </pm-attribute-display-text-input>
          <div *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0])" class="row season-year-row">
            <div class="col-9" style="padding-right: 0;">
              <pm-attribute-display-typeahead [attribute]="seasonConfiguration"
                                              [attributeError]="candidateError?.season"
                                              (selection)="seasonChange($event)" [(model)]="season"
                                              [showErrorMessage]="false">
              </pm-attribute-display-typeahead>
            </div>
            <div class="col-3" style="padding-left: 0;">
              <pm-attribute-display-text-input (inputFocusOut)="changeCandidate(seasonYear, 'seasonYear')"
                                               [(model)]="seasonYear"
                                               [attributeError]="candidateError?.seasonYear" [showErrorMessage]="false"
                                               [attribute]="yearConfiguration">
              </pm-attribute-display-text-input>
            </div>
          </div>
          <p *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0]) && candidateError?.season" class="error-message pl-2">
            {{candidateError.season}}
          </p>
          <p *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0]) && candidateError?.seasonYear" class="error-message pl-2">
            {{candidateError.seasonYear}}
          </p>
          <pm-attribute-display-toggle *ngIf="candidateUtilService.isSellable(candidatesInDrawer[0])"
                                       [attribute]="soldByWeightConfiguration"
                                       (change)="soldByWeightChange($event)"
                                       [(model)]="weightSwitch">
          </pm-attribute-display-toggle>

          <pm-attribute-display-text-input *ngIf="candidatesInDrawer[0].warehouseSwitch" [attribute]="remark1Configuration" [(model)]="remark1"
                                           [attributeError]="candidateProductError?.remark1"
                                           (inputFocusOut)="changeCandidateProduct(remark1, 'remark1')">
          </pm-attribute-display-text-input>
          <pm-attribute-display-text-input *ngIf="candidatesInDrawer[0].warehouseSwitch" [attribute]="remark2Configuration" [(model)]="remark2"
                                           [attributeError]="candidateProductError?.remark2"
                                           (inputFocusOut)="changeCandidateProduct(remark2, 'remark2')">
          </pm-attribute-display-text-input>

          <pm-attribute-display-checkbox [isIndented]="false" [attribute]="rushFlagConfiguration"
                                         (change)="changeCandidate($event, 'rushFlag')"
                                         [(model)]="rushFlag">
          </pm-attribute-display-checkbox>

          <div class="text-right">
            <p-button (onClick)="onClickApprove()" [disabled]="isApproveDisabled" class="m-2"
                      label="Approve all"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

</pm-review>
<div>
  <div #storeGroupsChangeDiv></div>
  <pm-static-overlay-panel #storeGroupsChangeOverlay appendTo="body"
                           [style]="{width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1013}"
                           [contentClassOverride]="'pm-overlaypanel-content-bulk-review-margins'">
    <ng-container>
      <div class="row align-items-center bulk-store-groups-change-overlay-wrapper">
        <div class="col-md-4" style="background: white; align-items: center; border-color: #e0e0e0; border-radius: 3px;
                                border-width: 1px; border-style: solid; padding: 2.5rem; min-width: 40rem;">
          <div class="header-row">
            <h2>Updates required from Supplier</h2>
            <p>
              If you change the commodity to {{tempCommodity?.commodityName}}, we'll need to ask the
              Supplier to update their store authorization requests. You won't be able to finish reviewing
              this item until the Supplier does this.
            </p>
          </div>
          <div class="row button-row">
            <p-button type="button" class="ghost mr-2" label="Cancel" (onClick)="cancelStoreAuth(storeGroupsChangeOverlay)"></p-button>
            <p-button type="button" label="Change commodity & notify Supplier" class="ml-2" (onClick)="changeDsdCommodity()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-static-overlay-panel>
</div>
