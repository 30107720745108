/**
 * Contains metadata information regarding the attribute values.
 *
 * @author s573181
 * @since 1.58.0
 */
import {Code} from 'pm-models';

export class MetaDataDomain {
  public static readonly DECIMAL_TYPE_ID = 'DEC';
  public static readonly DATE_TYPE_ID = 'DT';
  public static readonly INTEGER_TYPE_ID = 'I';
  public static readonly IMAGE_TYPE_ID = 'IMG';
  public static readonly STRING_TYPE_ID = 'S';
  public static readonly TIMESTAMP_TYPE_ID = 'TS';
  public static readonly BOOLEAN_TYPE_ID = 'BOOL';


  type: Code;
  minSizeNumber: number;
  maxLengthNumber: number;
  attributePrecisionNumber: number;
  multipleValues: boolean;
  standardCodeTable: boolean;
  customerFacing: boolean;
  required: boolean;
  codeTableName: string;
  resource: string;
  values: Code[];

  valueSelectable: boolean;
}
