import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Candidate, Task} from 'pm-models';
import {TaskListTask} from '../../../../../../src/app/2.0.0/task-list/task-list-service/taskListTask';

interface TaskHeaderType {
  field: string;
  header: string;
  sortable: boolean;
  width: string;
}

@Component({
  selector: 'pm-task-viewer-table',
  templateUrl: './task-viewer-table.component.html',
  styleUrls: ['./task-viewer-table.component.scss']
})
export class TaskViewerTableComponent implements OnInit {

  @Input() tasks: TaskListTask[];

  @Input() totalNumRecords: number;

  @Input() lazyLoadOn: boolean = false;

  @Output() taskClicked = new EventEmitter<TaskListTask>();

  @Output() loadData = new EventEmitter<any>();

  @Input() pageSize: number = 50;

  @Input() rushFlag: boolean = false;

  @Input() bulkUpdate: boolean = false;

  @Output() deleteClicked = new EventEmitter<number>();

  @Input() isPia: boolean = false;

  @Input() isSca: boolean = false;

  @Input() isBuyer: boolean = false;

  @Output() bulkReview = new EventEmitter<any[]>();

  taskHeaderColumns: TaskHeaderType[];

  allTasksCheckbox: boolean = false;

  isEditDisabled: boolean = true;

  toReview: number[];

  hasBulkActionConflict: boolean;
  onlyNewProductSelected: boolean = false;
  inValidWarehouseSupplier: boolean = false;
  inValidSupplier: boolean;
  inValidBuyer: boolean;
  inValidChannel: boolean;
  inValidProductType: boolean;
  inValidCodeDate: boolean;
  inValidDepartmentId: boolean;
  inValidSubDepartmentId: boolean;
  channel: string;
  DSD_CHANNEL_TYPE: string = 'DSD';
  onlyVendorCreatedNewProductSelected: boolean = false;

  constructor() {
  }

  ngOnInit() {
    if (!this.isPia) {
      this.createTaskColumns();
    } else {
      this.createPiaTaskColumns();
    }
  }

  createTaskColumns() {
    const canHaveMultiple = this.isBuyer;
    if (this.rushFlag && this.bulkUpdate) {
      this.taskHeaderColumns = [
        {field: 'candidateId', header: 'ID', sortable: true, width: '5%'},
        {field: 'upc', header: 'UPC', sortable: false, width: '8%'},
        {field: 'description', header: 'Candidate', sortable: false, width: '13%'},
        {field: 'displayName', header: 'Task', sortable: true, width: '8%'},
        {field: 'buyerDisplayName', header: 'Buyer [BDM]', sortable: true, width: '15%'},
        {field: 'supplierDisplayName', header: 'Supplier [AP]', sortable: !canHaveMultiple, width: '22%'},
        {field: 'lastUpdatedBy', header: 'Updated By', sortable: !canHaveMultiple, width: '9%'},
        {field: 'lastUpdatedTime', header: 'Last Updated', sortable: !canHaveMultiple, width: '14%'}
      ];
    } else if (this.rushFlag || this.bulkUpdate) {
      this.taskHeaderColumns = [
        {field: 'candidateId', header: 'ID', sortable: true, width: '5%'},
        {field: 'upc', header: 'UPC', sortable: false, width: '9%'},
        {field: 'description', header: 'Candidate', sortable: false, width: '14%'},
        {field: 'displayName', header: 'Task', sortable: true, width: '9%'},
        {field: 'buyerDisplayName', header: 'Buyer [BDM]', sortable: true, width: '15%'},
        {field: 'supplierDisplayName', header: 'Supplier [AP]', sortable: !canHaveMultiple, width: '22%'},
        {field: 'lastUpdatedBy', header: 'Updated By', sortable: !canHaveMultiple, width: '9%'},
        {field: 'lastUpdatedTime', header: 'Last Updated', sortable: !canHaveMultiple, width: '14%'}
      ];
    } else {
      this.taskHeaderColumns = [
        {field: 'candidateId', header: 'ID', sortable: true, width: '6%'},
        {field: 'upc', header: 'UPC', sortable: false, width: '9%'},
        {field: 'description', header: 'Candidate', sortable: false, width: '14%'},
        {field: 'displayName', header: 'Task', sortable: true, width: '9%'},
        {field: 'buyerDisplayName', header: 'Buyer [BDM]', sortable: true, width: '15%'},
        {field: 'supplierDisplayName', header: 'Supplier [AP]', sortable: !canHaveMultiple, width: '22%'},
        {field: 'lastUpdatedBy', header: 'Updated By', sortable: !canHaveMultiple, width: '10%'},
        {field: 'lastUpdatedTime', header: 'Last Updated', sortable: !canHaveMultiple, width: '15%'}
      ];
    }
  }

  getRushFlag(event): boolean {
    if (!event) {
      return false;
    } return event;
  }

  showPagination(): boolean {
    return true;
  }

  onClickRow(row: any) {
    this.taskClicked.emit(row);
  }

  loadCandidatesLazy(event: any) {
    this.loadData.emit(event);
  }

  onClickDelete(candidateId: number) {
    this.deleteClicked.emit(candidateId);
  }

  /**
   * On click of check all button, if only some or all of the rows are currently selected, unselect all,
   * or if none are currently selected- select all.
   */
  selectedAllChange() {
    let areAnyChecked = false;
    for (let x = 0; x < this.tasks.length; x++) {
      if (this.tasks[x].checked) {
        areAnyChecked = true;
        break;
      }
    }
    // if only some or all are currently selected, unselect all.
    if (areAnyChecked) {
      this.setAllTasksSelectedValue(false);
      // if none are currently selected- select all.
    } else {
      this.setAllTasksSelectedValue(true);
    }
    this.areAllNewProduct();
    this.areAllVendorCreatedNewProduct();
    this.findBulkActionConflict();
  }

  /**
   * If any buttons aren't checked, return dash icon, else return the check icon.
   */
  getCheckAllIcon() {
    for (let x = 0; x < this.tasks.length; x++) {
      if (!this.tasks[x].checked) {
        return 'pi pi-minus';
      }
    }
    return 'pi pi-check';
  }

  /**
   * If the box is checked or any are selected, set allTasksCheckbox to true, else set to false.
   * @param index the index of the checkbox.
   * @param event check event from click of task checkbox.
   */
  onCheckTask(index, event) {
    const checked = event.checked;
    this.resetBulkUpdateConflicts();
    this.areAllNewProduct();
    this.areAllVendorCreatedNewProduct();
    this.findBulkActionConflict();
    if (checked) {
      this.allTasksCheckbox = true;
      return;
    } else {
      for (let x = 0; x < this.tasks.length; x++) {
        if (this.tasks[x].checked) {
          this.allTasksCheckbox = true;
          return;
        }
      }
    }
    this.allTasksCheckbox = false;
  }

  /**
   * Sets all tasks selected value to the boolean supplied.
   * @param value the value to set the selected.
   */
  setAllTasksSelectedValue(value: boolean) {
    for (let x = 0; x < this.tasks.length; x++) {
      this.tasks[x].checked = value;
    }
  }

  getBulkReviewErrorText() {
      if (!this.onlyNewProductSelected) {
        return 'PAM only supports bulk review for <b>New Product</b> candidates.';
      } else if (!this.onlyVendorCreatedNewProductSelected) {
        return 'Right now PAM only supports bulk review for <b>New Product</b> candidates created by Suppliers.';
      }  else if (this.inValidChannel) {
        return 'Select candidates of the same <b>channel</b> to review in bulk.';
      } else if (this.hasBulkActionConflict) {
        return 'Select candidates with the same ' + this.getErrorFields() + ' to review in bulk';
      } else {
        return 'Select multiple <b> New product </b> candidates to review in bulk.';
    }
  }

  getErrorFields() {
    let errorTextString = '';
    const errorTextArray: string[] = [];

    if (this.inValidChannel) {
      errorTextArray.push('channel');
    }
    if (this.inValidSupplier) {
      errorTextArray.push('Supplier');
    }
    if (this.inValidBuyer) {
      errorTextArray.push('Buyer');
    }
    if (this.inValidProductType) {
      errorTextArray.push('selling type');
    }
    if (this.inValidWarehouseSupplier) {
      errorTextArray.push('BICEP');
    }
    if (this.inValidCodeDate) {
      errorTextArray.push('code date value');
    }
    if (this.channel === this.DSD_CHANNEL_TYPE && this.inValidDepartmentId || this.inValidSubDepartmentId) {
      errorTextArray.push('department');
    }

    if (errorTextArray.length === 1) {
      return '<b>' + errorTextArray[0] + '</b>';
    }
    for (let x = 0; x < errorTextArray.length; x++) {
      if (x === errorTextArray.length - 1) {
        if (errorTextArray.length === 2) {
          errorTextString += '</b>' + ' and ' + '<b>' + errorTextArray[x] + '</b>';
        } else {
          errorTextString += ',</b>' + ' and ' + '<b>' + errorTextArray[x] + '</b>';
        }
      } else if (x === 0) {
        errorTextString += '<b>' + errorTextArray[x];
      } else {
        errorTextString += ', ' + errorTextArray[x];
      }
    }
    return errorTextString;
  }

  areAllNewProduct() {
    this.onlyNewProductSelected = true;
    for (let x = 0; x < this.tasks.length; x++) {
      if (this.tasks[x].checked && this.tasks[x].taskType !== Candidate.NEW_PRODUCT) {
        this.onlyNewProductSelected = false;
        this.hasBulkActionConflict = true;
        return false;
      }
    }
    this.onlyNewProductSelected = true;
    this.hasBulkActionConflict = false;
    return true;
  }

  areAllVendorCreatedNewProduct() {
    this.onlyVendorCreatedNewProductSelected = true;
    for (let x = 0; x < this.tasks.length; x++) {
      if (this.tasks[x].checked &&   this.tasks[x].name === 'PIA New Product Flow') {
        this.onlyVendorCreatedNewProductSelected = false;
        this.hasBulkActionConflict = true;
        return false;
      }
    }
    this.onlyVendorCreatedNewProductSelected = true;
    this.hasBulkActionConflict = false;
    return true;
  }

  findBulkActionConflict() {
    this.inValidWarehouseSupplier = false;
    let defaultsSet = false;
    let ap, buyer, warehouseSupplier, productTypeId, codeDate, departmentId, subDepartmentId;
    for (let x = 0; x < this.tasks.length; x++) {
      if (this.tasks[x].checked) {
        // sets defaults for first checked task
        if (!defaultsSet) {
          ap = this.tasks[x].supplierId;
          buyer = this.tasks[x].buyerId;
          warehouseSupplier = this.tasks[x].warehouseSupplierId;
          this.channel = this.tasks[x].channel;
          productTypeId = this.tasks[x].productTypeId;
          codeDate = this.tasks[x].codeDate;
          departmentId = this.tasks[x].departmentId;
          subDepartmentId = this.tasks[x].subDepartmentId;
          defaultsSet = true;
        }
        if (defaultsSet) {
          if (ap !== this.tasks[x].supplierId) {
            this.inValidSupplier = true;
            this.hasBulkActionConflict = true;
          }
          if (buyer !== this.tasks[x].buyerId) {
            this.inValidBuyer = true;
            this.hasBulkActionConflict = true;
          }
          if (warehouseSupplier !== this.tasks[x].warehouseSupplierId) {
            this.inValidWarehouseSupplier = true;
            this.hasBulkActionConflict = true;
          }
          if (this.channel !== this.tasks[x].channel) {
            this.inValidChannel = true;
            this.hasBulkActionConflict = true;
          }
          if (productTypeId !== this.tasks[x].productTypeId) {
            this.inValidProductType = true;
            this.hasBulkActionConflict = true;
          }
          if (codeDate !== this.tasks[x].codeDate) {
            this.inValidCodeDate = true;
            this.hasBulkActionConflict = true;
          }
          if (departmentId !== this.tasks[x].departmentId) {
            this.inValidDepartmentId = true;
            this.hasBulkActionConflict = true;
          }
          if (subDepartmentId !== this.tasks[x].subDepartmentId) {
            this.inValidSubDepartmentId = true;
            this.hasBulkActionConflict = true;
          }
          if (this.hasBulkActionConflict) {
            return false;
          }
        }
      }
    }
    this.hasBulkActionConflict = false;
    return true;
  }

  resetBulkUpdateConflicts() {
    this.onlyNewProductSelected = false;
    this.inValidWarehouseSupplier = false;
    this.inValidSupplier = false;
    this.inValidBuyer = false;
    this.inValidChannel = false;
    this.inValidProductType = false;
    this.inValidCodeDate = false;
    this.inValidDepartmentId = false;
    this.inValidSubDepartmentId = false;
  }

  createPiaTaskColumns() {
    if (this.rushFlag && this.bulkUpdate) {
      this.taskHeaderColumns = [
        {field: 'candidateId', header: 'ID', sortable: true, width: '5%'},
        {field: 'upc', header: 'UPC', sortable: false, width: '9%'},
        {field: 'description', header: 'Candidate', sortable: false, width: '13%'},
        {field: 'displayName', header: 'Task', sortable: true, width: '10%'},
        {field: 'buyerDisplayName', header: 'Buyer [BDM]', sortable: true, width: '15%'},
        {field: 'supplierDisplayName', header: 'Supplier [AP]', sortable: false, width: '15%'},
        {field: 'lastUpdatedBy', header: 'Updated By', sortable: false, width: '9%'},
        {field: 'lastUpdatedTime', header: 'Last Updated', sortable: false, width: '9%'},
        {field: 'landedOn', header: 'Landed On', sortable: true, width: '9%'}
      ];
    } else if (this.rushFlag || this.bulkUpdate) {
      this.taskHeaderColumns = [
        {field: 'candidateId', header: 'ID', sortable: true, width: '5%'},
        {field: 'upc', header: 'UPC', sortable: false, width: '10%'},
        {field: 'description', header: 'Candidate', sortable: false, width: '14%'},
        {field: 'displayName', header: 'Task', sortable: true, width: '10%'},
        {field: 'buyerDisplayName', header: 'Buyer [BDM]', sortable: true, width: '15%'},
        {field: 'supplierDisplayName', header: 'Supplier [AP]', sortable: false, width: '15%'},
        {field: 'lastUpdatedBy', header: 'Updated By', sortable: false, width: '10%'},
        {field: 'lastUpdatedTime', header: 'Last Updated', sortable: false, width: '9%'},
        {field: 'landedOn', header: 'Landed On', sortable: true, width: '9%'}
      ];
    } else {
      this.taskHeaderColumns = [
        {field: 'candidateId', header: 'ID', sortable: true, width: '6%'},
        {field: 'upc', header: 'UPC', sortable: false, width: '10%'},
        {field: 'description', header: 'Candidate', sortable: false, width: '14%'},
        {field: 'displayName', header: 'Task', sortable: true, width: '10%'},
        {field: 'buyerDisplayName', header: 'Buyer [BDM]', sortable: true, width: '15%'},
        {field: 'supplierDisplayName', header: 'Supplier [AP]', sortable: false, width: '15%'},
        {field: 'lastUpdatedBy', header: 'Updated By', sortable: false, width: '10%'},
        {field: 'lastUpdatedTime', header: 'Last Updated', sortable: false, width: '10%'},
        {field: 'landedOn', header: 'Landed On', sortable: true, width: '10%'}
      ];
    }
  }

  async onClickBulkReview() {
    this.toReview = [];
    for (let x = 0; x < this.tasks.length; x++) {
      if (this.tasks[x].checked) {
        this.toReview.push(this.tasks[x].candidateId);
      }
    }
    this.bulkReview.emit(this.toReview);
  }

  getDescription(row: any) {
    if (row.candidateProductDescription === undefined || row.candidateProductDescription === null) {
      return row.candidateDescription;
    }
    return row.candidateProductDescription;
  }

  showPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }
}
