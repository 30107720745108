<ng-container *ngIf="attribute && !(attribute.isHidden && attribute.isHidden())">

  <div class="attribute-group" [ngClass]="{'attribute-required': attribute.isRequired}">
    <div class="attribute-label">
      <label for="">{{attribute.label}}</label>
    </div>

    <div class="attribute-control">
      <div class="attribute-dual-input">

        <input *ngIf="!attribute.onlyShowSecondInput" type="text" pInputText [(ngModel)]="firstInputModel"
               appOnlyNumber [integerDigits]="attribute.input1NumberCount" [decimalPlaces]="attribute.input1DecimalCount"
               (blur)="onFirstInputModelBlur($event)" (focus)="onFirstInputModelFocus($event)"
               [placeholder]="attribute.input1Placeholder || ''" id=firstInputId
               [readonly]="attribute.isReadOnly && attribute.isReadOnly()" [maxlength]="attribute.input1MaxLength"
               [disabled]="attribute.isDisabled && attribute.isDisabled()"
               [ngClass]="{'ui-inputtext-invalid' : firstInputModelError, 'firstInput-input': true}">

        <div class="spacer" *ngIf="attribute.onlyShowSecondInput">{{firstInputModel}}</div>
        <div class="spacer" *ngIf="attribute.separatingText">{{attribute.separatingText}}</div>

        <input type="text" pInputText
               [ngModel]="attribute.input2DecimalCount ? (secondInputModel | number: getFormatString()) : secondInputModel"
               (ngModelChange)="secondInputChange($event)"
               appOnlyNumber [integerDigits]="attribute.input2NumberCount" [decimalPlaces]="attribute.input2DecimalCount"
               (blur)="onSecondInputModelBlur($event)" (focus)="onSecondInputModelFocus($event)"
               [placeholder]="attribute.input2Placeholder || ''" id=secondInputId
               [readonly]="attribute.isReadOnly && attribute.isReadOnly()" [maxlength]="attribute.input2MaxLength"
               [disabled]="attribute.isDisabled && attribute.isDisabled()"
               [ngClass]="{'ui-inputtext-invalid' : secondInputModelError, 'secondInput-input': true}"
               [ngModelOptions]="{updateOn:'blur'}">

      </div>

      <p *ngIf="attribute.description" class="attribute-description">
        {{attribute.description}}
      </p>
      <p *ngIf="firstInputModelError" class="attribute-error-message">
        {{firstInputModelError}}
      </p>
      <p *ngIf="secondInputModelError" class="attribute-error-message">
        {{secondInputModelError}}
      </p>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
