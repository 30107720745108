import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterPackDetailsCardComponent } from './master-pack-details-card.component';


import { AttributeDisplayModule } from '../../shared/attribute-display/attribute-display.module';
import { CardModule } from '../../shared/card/card.module';

@NgModule({
  declarations: [MasterPackDetailsCardComponent],
  exports: [MasterPackDetailsCardComponent, AttributeDisplayModule, CardModule],
  imports: [
    CommonModule, AttributeDisplayModule, CardModule
  ]
})
export class MasterPackDetailsCardModule { }
