import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-upload-products',
  templateUrl: './upload-products.component.html',
  styleUrls: ['./upload-products.component.scss']
})
export class UploadProductsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onClose() {
    this.router.navigate(['/tasks']);
  }
}
