<pm-review #pmReview *ngIf="isViewingPage" [ngClass]="getReviewClass()" (closeEvent)="onClose()">
  <!-- Page Titles -->
  <pm-title>Review MRT</pm-title>
  <pm-sub-title>{{candidate?.description}}</pm-sub-title>

  <div *ngIf="candidate" class="row justify-content-md-center pb-3">
    <div class="col col-md-10 pb-5">

      <pm-attribute-grid class="mrt-inner-info-grid-container" [title]="'MRT items & costs set by supplier'">
        <div *ngIf="isLoadingInners" style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingInners" [strokeWidth]="'2'" class="loading-indicator"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching product data...</h5>
        </div>
        <div *ngIf="!isLoadingInners">
          <ng-template ngFor let-inner [ngForOf]="mrtCandidateInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="false">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).upc}}-{{supplierMrtService.getCurrentCandidateProducts(inner).upcCheckDigit}}<br>
                  <button pButton type="button" label="View details" class="ui-button-link inner-details-button" (click)="viewCandidateDetails(inner)"></button>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status === 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="pending">Pending</div>
                  <pm-grid-cell-info>
                    This item is new and requires a separate review
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell *ngIf="inner?.candidate?.status !== 'IN_PROGRESS'" class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  <div class="text-capitalize">{{toLowerCase(inner?.candidate?.status)}}</div>
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{supplierMrtService.getCurrentCandidateProducts(inner).description}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(supplierMrtService.getCurrentCandidateProducts(inner).description) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="false">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  -
                </pm-grid-cell>

              </pm-grid-column>
              <hr>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>

          <ng-template ngFor let-inner [ngForOf]="mrtExistingInners" let-index="index">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Unit UPC'" [canEdit]="false">
                  {{inner?.upc}}-{{inner.upcCheckDigit}}<br>
                  <button pButton type="button" label="View details" class="ui-button-link inner-details-button" (click)="viewProductDetails(inner)"></button>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'Status'" [canEdit]="false">
                  Active
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell class="mrt-inner-item-code-row-cell" [label]="'Product description'" [canEdit]="false">
                  {{inner?.product?.productDescription}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(inner?.product?.productDescription) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell class="mrt-inner-cost-row-cell" [label]="'# Sellable units'" [canEdit]="false">
                  {{inner?.quantity}}
                </pm-grid-cell>
              </pm-grid-column>
              <pm-grid-column>
                <pm-grid-cell *ngIf="!productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  {{productService.getItemCode(inner?.product)}}
                </pm-grid-cell>
                <pm-grid-cell *ngIf="productService.isMoreThanOneItemCode(inner.product)" class="mrt-inner-item-code-row-cell" [label]="'Item code'" [canEdit]="false">
                  Multiple
                  <pm-grid-cell-info>
                    This item has multiple case configurations
                  </pm-grid-cell-info>
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
            <div class="grid-divider"></div>
          </ng-template>
        </div>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate?.masterListCost)}}
              <pm-grid-cell-info style="white-space: pre-line;">
                <div *ngIf="!isNotMissingCandidateInfo" >
                  {{getCostDisplay()}}
                </div>
                <div *ngIf="isNotMissingCandidateInfo">
                  Penny Profit: {{getPennyProfit()}}<br>
                  <a>Margin: <a [ngClass]="{'grid-cell-error':isMarginNegativeOrZero()}">{{getMarginPercent()}}%</a>
                    <img *ngIf="isMarginNegativeOrZero()" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
                  </a>
                </div>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Case summary -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'">
              {{candidate?.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [editNoHover]="isPageEditable" (editClicked)="editWarehouseSupplier()" >
              {{candidate.lane?.name}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Case details from buyer -->
      <pm-attribute-grid [title]="'Case details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inbound spec'">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season'">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Reaction days'">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Guarantee to store days'">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{candidate.subCommodity?.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master Pack'" [canEdit]="false">
              1
            </pm-grid-cell>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
              {{getMasterPack()}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false" [errorMessage]="candidateError?.costOwner">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false" [errorMessage]="candidateError?.buyer">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false" [errorMessage]="candidateError?.topToTop">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="false">
              {{candidate.lane?.name}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false" [errorMessage]="candidateError?.vendor">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <!--   Global Attributes   -->
        <pm-attribute-grid *ngIf="isLoadingMatData || !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
          <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>
      </div>

      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="false">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="false">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="true"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.scaComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate?.supplierComment">
                <b>{{candidate?.contactEmail}}</b> <br>
                {{candidate?.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>

              <br>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
            </pm-comment-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <pm-attachment-upload-review-page [attachments]="candidate.attachments" (updateCallback)="addAttachments($event)"></pm-attachment-upload-review-page>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>
  <pm-review-drawer-container>
    <ng-container *ngIf="isViewingCaseDetails">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link" (click)="save()">
            </button>
          </div>
        </div>

        <h2>Warehouse Details</h2>
        <p>Configure one or more of the warehouses below for this product.</p>

        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="maxShipConfiguration"
                                           [(model)]="candidate.maxShip"
                                           [attributeError]="candidateError?.maxShip">
          </pm-attribute-display-text-input>
        </div>

        <hr/>
        <ng-template ngFor let-warehouse [ngForOf]="warehouseData">

          <div class="attribute-group">
            <div>
              <p-checkbox id="whsCheckbox"
                          [(ngModel)]="warehouse.checked"
                          (onChange)="selectedWarehouseChange($event, warehouse)"
                          binary="true">
              </p-checkbox>
            </div>

            <div>
              <div class="attribute-label">
                <label>
                  {{warehouse.name}}
                </label>
              </div>
              <p class="attribute-description">
                ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
              </p>
              <p *ngIf="warehouse.checked && candidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
                 class="error-message bicep-error">
                {{candidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
              </p>
              <p *ngIf="warehouse.checked && candidateProductError?.warehouseErrors[warehouse.warehouseId]?.ecomWarehouseError"
                 class="error-message bicep-error">
                {{candidateProductError.warehouseErrors[warehouse.warehouseId].ecomWarehouseError}}
              </p>
            </div>

          </div>


          <div *ngIf="warehouse.checked">
            <div class="attributes-stacked">
              <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                          [(model)]="warehouse.orderUnitId"
                                          (change)="orderUnitChange($event, warehouse)"
                                          [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
              </pm-attribute-display-radio>
              <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                              [(model)]="warehouse.orderRestriction"
                                              (selection)="orderRestrictionChange($event, warehouse)"
                                              [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
              </pm-attribute-display-typeahead>
            </div>

          </div>
          <hr/>

        </ng-template>

        <div class="attribute-group">
          <div>
            <p-checkbox id="whsMissingCheckbox"
                        [(ngModel)]="candidate.missingWarehouses"
                        (onChange)="warehouseMissingCheckboxChange($event)"
                        binary="true">
            </p-checkbox>
          </div>

          <div>
            <div class="attribute-label">
              <label style="font-size: 18px !important;">
                Warehouse missing?
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="candidate.missingWarehouses">
          <p style="font-size: 14px;">Not seeing the warehouse you need? This could happen if the bicep or AP number is wrong, or if a warehouse wasn't tied to a particular bicep.</p>
          <p style="font-size: 14px;">Right now, only Procurement Support can fix this issue. Let them know which warehouse you want to select and if they need to change the bicep or AP number referenced by this candidate.</p>
          <div class="attributes-stacked">
            <pm-attribute-display-text-input [attribute]="missingWarehousesCommentsConfiguration"
                                             [attributeError]="candidateError?.missingWarehousesComment"
                                             [(model)]="candidate.missingWarehousesComment"
                                             (keypress) ="updatedMissingWarehousesComment()">
            </pm-attribute-display-text-input>
          </div>
        </div>

        <hr/>


        <div class="text-right">
          <p-button class="m-2" label="Approve" (onClick)="onClickApprove()"
                    [disabled]="this.isApproveDisabled ||
                                (!this.isSelectedWarehouse() && !warehouseMissingNotesEntered)"
          ></p-button>
        </div>

      </div>
    </ng-container>
    <ng-container *ngIf="isViewingProductDetails">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>UPC Details</h2>
        <div>
          <pm-attribute-grid [title]="'Overview'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'UPC'">
                  {{currentUpc?.scanCodeId}}-{{currentUpc?.scanCodeId | upcCheckDigit}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Brand'">
                  {{currentExistingInner?.product?.brand ? currentExistingInner?.product?.brand.description + ' [' + currentExistingInner?.product?.brand.brandId + ']': ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-brand'">
                  {{currentUpc?.subBrand ? currentUpc?.subBrand.description + ' [' + currentUpc?.subBrand.subBrandId + ']': ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Buyer'">
                  {{currentUpc?.item?.hierarchy?.commodity?.bdm ? currentUpc?.item?.hierarchy?.commodity?.bdm.fullName +
                  ' [' + currentUpc?.item?.hierarchy?.commodity?.bdm.bdmId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Product description'">
                  {{currentProduct?.productDescription}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(currentProduct?.productDescription) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell [label]="'Commodity'">
                  {{currentUpc?.item?.hierarchy?.commodity ? currentUpc?.item?.hierarchy?.commodity.description +
                  ' [' + currentUpc?.item?.hierarchy?.commodity.commodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-commodity'">
                  {{currentUpc?.item?.hierarchy ? currentUpc?.item?.hierarchy?.description +
                  ' [' + currentUpc?.item?.hierarchy?.subCommodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Merchandise type'">
                  {{currentProduct?.productTypeId}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentProduct?.productTypeId === 'GOODS'| yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Unit details'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentUpc?.unitOfMeasure?.description}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{currentUpc?.length}}in x
                  {{currentUpc?.width}}in x
                  {{currentUpc?.height}}in
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit size'">
                  {{currentUpc?.size}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 1'">
                  {{currentProduct?.customerFriendlyDescriptionOne}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 2'">
                  {{currentProduct?.customerFriendlyDescriptionTwo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentProduct?.productTypeId === 'GOODS' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Packaging & regulatory info'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Food stamp eligible'">
                  {{currentProduct?.foodStamp | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Taxable'">
                  {{currentProduct?.taxable | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'FSA/HSA eligible'">
                  {{currentProduct?.flexibleSpendingAccount | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Package type'">
                  {{currentProduct?.packageDescription}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{currentUpc?.length}}in x
                  {{currentUpc?.width}}in x
                  {{currentUpc?.height}}in
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentUpc?.unitOfMeasure?.description}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Retail size'">
                  {{currentUpc?.size}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Weight'">
                  {{candidateUtilService.getProductWeightString(currentUpc.weight)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit Size'">
                  {{currentUpc?.item?.totalVolume}}
                </pm-grid-cell>

              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isViewingCandidateDetails">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>UPC Details</h2>
        <div>
          <pm-attribute-grid [title]="'Overview'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'UPC'">
                  {{currentInnerCandidateProduct?.upc}}-{{currentInnerCandidateProduct?.upcCheckDigit}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Brand'">
                  {{currentInnerCandidate?.brand?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-brand'">
                  {{currentInnerCandidateProduct?.subBrand?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Buyer'">
                  {{currentInnerCandidate?.buyer?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Product description'">
                  {{currentInnerCandidateProduct?.description}}
                  <pm-grid-cell-info>
                    {{candidateUtilService.getReceipt(currentInnerCandidateProduct?.description) }}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell [label]="'Commodity'">
                  {{currentInnerCandidate?.commodity ? currentInnerCandidate?.commodity?.commodityName + ' [' + currentInnerCandidate?.commodity?.commodityId + ']' : ''}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sub-commodity'">
                  {{currentInnerCandidate?.subCommodity ? currentInnerCandidate?.subCommodity?.subCommodityName + ' [' + currentInnerCandidate?.subCommodity?.subCommodityId + ']' : ''}}

                </pm-grid-cell>
                <pm-grid-cell [label]="'Merchandise type'">
                  {{currentInnerCandidate?.merchandiseType ? currentInnerCandidate?.merchandiseType?.description + ' [' + currentInnerCandidate?.merchandiseType?.merchandiseTypeCode + ']' : ''}}

                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentInnerCandidate.productType === 'SELLABLE' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Unit details'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentInnerCandidate?.unitOfMeasure.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{candidateUtilService.getMasterDimensions(currentInnerCandidate)}}
                  <pm-grid-cell-info>
                    Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
                  </pm-grid-cell-info>
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit size'">
                  {{currentInnerCandidate.retailSize}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 1'">
                  {{currentInnerCandidateProduct?.customerFriendlyDescription1}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Shelf tag line 2'">
                  {{currentInnerCandidateProduct?.customerFriendlyDescription2}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Sellable'">
                  {{currentInnerCandidate.productType === 'SELLABLE' | yesNo}}
                </pm-grid-cell>
              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>
          <pm-attribute-grid [title]="'Packaging & regulatory info'">
            <pm-grid-group>
              <pm-grid-column>
                <pm-grid-cell [label]="'Food stamp eligible'">
                  {{currentInnerCandidate?.foodStamp | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Taxable'">
                  {{currentInnerCandidate?.taxable | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'FSA/HSA eligible'">
                  {{currentInnerCandidate?.flexibleSpendingAccount | yesNo}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Package type'">
                  {{currentInnerCandidate?.packageType?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Dimensions'">
                  {{candidateUtilService.getProductDimensionsString(currentInnerCandidate)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit of measure'">
                  {{currentInnerCandidate?.unitOfMeasure?.displayName}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Retail size'">
                  {{currentInnerCandidate?.retailSize}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Weight'">
                  {{candidateUtilService.getProductWeightString(currentInnerCandidate?.productWeight)}}
                </pm-grid-cell>
                <pm-grid-cell [label]="'Unit Size'">
                  {{currentInnerCandidate?.totalVolume}}
                </pm-grid-cell>

              </pm-grid-column>
            </pm-grid-group>
          </pm-attribute-grid>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="scaComments"
                                           [(model)]="tempCommentHolder"
                                           [attributeError]="candidateError?.scaComment">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.scaComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.scaComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>
  <pm-footer>
    <p-button *ngIf="isPageEditable" class="m-2" label="Next" (click)="onClickNext()"></p-button>
  </pm-footer>
</pm-review>
