<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-4">
    <!-- Helper images -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Import factory" subtitle="Choose the import factory for this product.">

    <ng-container *ngIf="candidateProductModel">

      <pm-import-factory-search-bar [attribute]="importFactoryConfiguration"
                                    [(factoryModel)]="candidateProductModel.factory"
                                    [attributeError]="candidateProductErrorModel?.factory">
      </pm-import-factory-search-bar>
    </ng-container>
  </pm-card>
</ng-template>
