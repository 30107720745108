<div class="product-table-container">
  <div class="row ml-0 mr-0">
    <p-table selectionMode="single"
             [rowHover]="true"
             [columns]="allDsdColumns"
             [value]="candidates"
             [rows]="pageSize"
             [totalRecords]="totalNumRecords"
             [scrollable]="true"
             styleClass="p-datatable-gridlines"
             [ngStyle]="{'width': '100%'}">

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns"
               [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-productHeaderColumns>
        <tr class="product-table-column-group">
          <th class="left-sticky-header" colspan="1">#</th>
          <th class="left-sticky-header" colspan="1">UPC</th>
          <th colspan="2">Supplier & Buyer</th>
          <th colspan="8">Case & cost</th>
          <th colspan="1">Stores</th>

          <th *ngFor="let item of [].constructor(maxStoreGroupCount);  let i = index" [attr.colspan]="DSD_LOCATION_GROUP_COLUMNS_LENGTH">
            Store auth group
          </th>
        </tr>
        <tr class="product-table-column-headers">
          <th *ngFor="let col of productHeaderColumns"
              [ngClass]="col.sticky ? 'left-sticky-header' : ''"
              [ngStyle]="{'width': col.width}"
              (click)="onClickHeader(col)">
            <div>
              {{col.header}}
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-candidate let-index="rowIndex">
        <tr class="product-table-body" [pSelectableRow]="candidate">
          <!--  candidate index -->
          <td class="sticky-column">
            <div>{{index + 1}}</div>
          </td>
          <!--  UPC  -->
          <td class="sticky-column" (click)="editProductUPC(index)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['upc'])}}">
            </i>
            {{candidate?.candidateProducts[candidateProductIndex].upc}}
          </td>
          <!-- Buyer -->
          <td [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['buyer'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['buyer'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['buyer'])}}">
            </i>
            {{candidate.buyer?.displayName}}
          </td>
          <td [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['vendor'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['vendor'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['vendor'])}}">
            </i>
            {{candidate.vendor?.displayName}}
          </td>
          <td (click)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterListCost'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterListCost'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['masterListCost'])}}">
            </i>
            {{costService.toCurrencyForCost(candidate.masterListCost)}}
          </td>
          <!--  Unit cost  -->
          <td>
            {{costService.toCurrencyForCost(candidate.unitCost)}}
          </td>
          <!--  HEB margin %  -->
          <td>
            {{costService.getHebMargin(candidate)}}
          </td>
          <!--  HEB penny profit  -->
          <td>
            {{costService.getHebPennyProfit(candidate)}}
          </td>
          <!-- Total retail units (master pack)  -->
          <td (click)="costService.editCostRelatedFields(attributeType.TotalRetail, candidate)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterPack', 'innerPack'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateError(candidate, candidateIdToCandidateErrorMap, ['masterPack', 'innerPack'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateErrorText(candidate, candidateIdToCandidateErrorMap, ['masterPack', 'innerPack'])}}">
            </i>
            {{candidate.masterPack}}
          </td>
          <!--  VPC  -->
          <td (click)="showEditCandidateProductModal(index, attributeType.VendorProductCode)"
               [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['vendorProductCode'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['vendorProductCode'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['vendorProductCode'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].vendorProductCode}}
          </td>
          <!--  Case description  -->
          <td (click)="showEditCandidateProductModal(index, attributeType.CaseDescription)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseDescription'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseDescription'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['caseDescription'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].caseDescription}}
          </td>
          <!--  Country of origin  -->
          <td (click)="showEditCandidateProductModal(index, attributeType.CountryOfOrigin)"
              [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['countryOfOrigin'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['countryOfOrigin'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['countryOfOrigin'])}}">
            </i>
            {{candidate.candidateProducts[candidateProductIndex].countryOfOrigin?.description}}
          </td>
          <!--  Total # of Stores -->
          <td [ngClass]="{'bulkErrorCell': candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['missingStoresError'])}">
            <i *ngIf="candidateErrorUtils.hasCandidateProductError(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['missingStoresError'])" class="pi pi-exclamation-triangle errorTriangle"
               pTooltip="{{candidateErrorUtils.getCandidateProductErrorText(candidate, candidateProductIndex, candidateIdToCandidateErrorMap, ['missingStoresError'])}}">
            </i>
            {{getStoreCountString(candidate)}}
          </td>
          <ng-template ngFor [ngForOf]="[].constructor(maxStoreGroupCount)" let-locationGroupIndex="index">

            <td>
              {{candidate.candidateProducts[candidateProductIndex]?.locationGroupStores[locationGroupIndex]?.splrLocationGroupId | emptyToValue: '-'}}
            </td>
            <td>
              {{candidate.candidateProducts[candidateProductIndex]?.locationGroupStores[locationGroupIndex]?.stores?.length | emptyToValue: '-'}}
            </td>
            <td>
              <div class="m-0 row" *ngIf="candidate.candidateProducts[candidateProductIndex]?.locationGroupStores[locationGroupIndex]?.stores?.length > 0">
                <ng-template class="col-sm-auto" ngFor let-store [ngForOf]="candidate.candidateProducts[candidateProductIndex]?.locationGroupStores[locationGroupIndex]?.stores" let-storeIndex="index">
                  <div *ngIf="storeIndex < 10 || candidate.candidateProducts[candidateProductIndex].locationGroupStores[locationGroupIndex].showAllStores" class="store-block">
                    {{store.custLocationNumber}}
                  </div>
                </ng-template>

              </div>
              <button *ngIf="candidate.candidateProducts[candidateProductIndex]?.locationGroupStores[locationGroupIndex]?.stores?.length > 10" type="button" class="btn btn-link btn-sm pt-1 p-0" (click)="candidate.candidateProducts[candidateProductIndex].locationGroupStores[locationGroupIndex].showAllStores = !candidate.candidateProducts[candidateProductIndex].locationGroupStores[locationGroupIndex].showAllStores">
                {{!candidate.candidateProducts[candidateProductIndex].locationGroupStores[locationGroupIndex].showAllStores ? '+ Show all ' + candidate.candidateProducts[candidateProductIndex]?.locationGroupStores[locationGroupIndex]?.stores.length + ' stores' : 'Hide full list'}}
              </button>
              <div *ngIf="!candidate.candidateProducts[candidateProductIndex]?.locationGroupStores[locationGroupIndex]?.stores?.length === 0">
                -
              </div>
            </td>


          </ng-template>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

