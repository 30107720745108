import {BaseAttribute} from 'pm-components/lib/attributes/attribute-type/base-attribute';
import {CandidateProduct, MatHierarchy} from 'pm-models';
import {CategorySelectionComponent} from 'pm-components/lib/shared/category-selection/category-selection.component';

export class CategorySelection implements BaseAttribute {

  originalMatHierarchyList: MatHierarchy[];

  getComponentType(): any {
    return CategorySelectionComponent;
  }

  getAttributeConfig(overrides): any {
  }

  setupComponent(componentInstance: any, candidateProduct: CandidateProduct, configOverrides: any = {}) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);
    componentInstance.model = candidateProduct.matHierarchyList;
    this.originalMatHierarchyList = candidateProduct.matHierarchyList;
    componentInstance.selection.subscribe((selection: MatHierarchy[]) => {
      candidateProduct.matHierarchyList = selection;
    });
  }
}
