import {NgModule} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'pm-components/lib/shared/card/card.module';
import {AttributeDisplayModule} from 'pm-components/lib/shared/attribute-display/attribute-display.module';
import {CommonModule} from '@angular/common';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {StaticOverlayPanelModule} from 'pm-components/lib/shared/overlay-panels/static-overlay-panel/static-overlay-panel.module';
import {MaxShipCardModule} from 'pm-components/lib/cards/max-ship-card/max-ship-card.module';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {EditCandidateModalServiceMock} from 'pm-components/lib/cards/regulatory-card/edit-candidate-modal-service-mock.service';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {LookupServiceMock} from 'pm-components/lib/cards/regulatory-card/lookup-service-mock.service';
import {WorkflowService} from '../../../../../../src/app/2.0.0/service/workflow.service';
import {WorkflowServiceMock} from 'pm-components/lib/cards/regulatory-card/workflow-service-mock.service';
import {UploadDsdTableComponent} from 'pm-components/lib/shared/upload-dsd-table/upload-dsd-table.component';

@NgModule({
  declarations: [UploadDsdTableComponent],
  exports: [UploadDsdTableComponent, ButtonModule, CardModule, AttributeDisplayModule],
  imports: [CommonModule, CardModule, AttributeDisplayModule, TableModule, CheckboxModule, StaticOverlayPanelModule],
  providers: [
    { provide: EditCandidateModalService, useClass: EditCandidateModalServiceMock },
    { provide: LookupService, useClass: LookupServiceMock },
    { provide: WorkflowService, useClass: WorkflowServiceMock }]
})
export class UploadDsdTableModule {}
