import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {UploadSettingsConfigurationComponent} from './upload-settings-configuration.component';
import {AttributeDisplayModule} from '../attribute-display/attribute-display.module';
import {CheckboxModule} from 'primeng/checkbox';


@NgModule({
  declarations: [UploadSettingsConfigurationComponent],
  exports: [UploadSettingsConfigurationComponent, FormsModule, ButtonModule],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    AttributeDisplayModule,
    CheckboxModule
  ]
})
export class UploadSettingsConfigurationModule { }
