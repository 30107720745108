<div class="row" *ngIf="!permissions?.isReadOnly else card">
  <div class="col-12 col-md-8">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
  <div class="col-6 col-md-4 helper-col">
    <!-- Helper Column -->
  </div>
</div>

<ng-template #card>
  <pm-card title="Cost" subtitle="{{getSubTitle()}}">
    <ng-container *ngIf="model">
      <pm-attribute-display-text-input [attribute]="masterListCostConfiguration" [(model)]="model.masterListCost"
                                       [attributeError]="errorModel?.masterListCost" (modelChange)="masterListCostChange($event)">
      </pm-attribute-display-text-input>

      <div class="attribute-group">
        <div class="attribute-label">
        </div>

        <div class="attribute-control">
          <input style="width:20px; height:20px; vertical-align: middle;" type="checkbox" id="dealCheckbox" [(ngModel)]="model.dealOffered">
          <label class="ml-2" for="dealCheckbox">Deal offered</label>

          <p class="attribute-description">
            Enter the list cost per master case pack and let us know if you plan to offer a deal.
            If we accept this candidate, you can set up the deal in our Cost & Deals app.
          </p>
        </div>
      </div>

      <pm-attribute-display-text-input [attribute]="masterPackConfiguration"
                                       [(model)]="model.masterPack"
                                       [attributeError]="errorModel?.masterPack">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="unitCostConfiguration" [model]="model.unitCost"
                                       [attributeError]="errorModel?.unitCost">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input [attribute]="innerCostConfiguration" [model]="model.innerListCost"
                                       [attributeError]="errorModel?.innerListCost">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input *ngIf="isSellable" [attribute]="pennyProfitConfiguration" [model]="calculatePennyProfit()"
                                       [attributeError]="errorModel?.pennyProfit">
      </pm-attribute-display-text-input>

      <pm-attribute-display-text-input *ngIf="isSellable" [attribute]="marginPercentConfiguration" [model]="getMargin"
                                       [attributeError]="errorModel?.marginPercent">
      </pm-attribute-display-text-input>
    </ng-container>
  </pm-card>
</ng-template>
