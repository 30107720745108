import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InputState, UPCInputState} from 'pm-components';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  LocationGroupStores,
  TextInputType
} from 'pm-models';
import {SelectItem} from 'primeng/api';
import {SupplierProductService} from '../../service/supplier-product.service';
import {NewWarehouseProductStepperComponent} from '../new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {CandidateService} from '../../service/candidate.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-store-authorization',
  templateUrl: './store-authorization.component.html',
  styleUrls: ['./store-authorization.component.scss']
})
export class StoreAuthorizationComponent implements OnInit {

  public isViewingPage = true;
  public currentCandidateProductIndex;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public costLinkState: InputState;
  public upcState: UPCInputState;
  private taskSubscription$: any;

  canNavigate = true;
  public storesInput: any = '';

  storesInputConfiguration: AttributeTextInputConfig = {
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    name: 'storesListId',
    textInputType: TextInputType.textarea,
    inputGroupClass: 'find-stores-by-selec',
    placeholderText: '',
    description: 'Separate store numbers by a comma or enter each store on a separate line.',
  };

  allGroupsConfiguration: AttributeConfig = {
    label: 'All groups',
    description: 'Show stores in all groups',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'allGroupsId',
    defaultValue: false
  };

  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.supplierProductService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.supplierProductService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  private getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }


  public distributorName: any;
  public storesErrorMessage: string;
  public selectAllAuthGroups: boolean;
  public showNoStoresError: boolean;
  public STORES = 'stores';
  public GROUPS = 'groups';

  types: SelectItem[];
  selectedType: string = 'stores';

  constructor( private route: ActivatedRoute, private router: Router, public supplierProductService: SupplierProductService,
               private workflowService: WorkflowService, private candidateUtilService: CandidateUtilService,
               private candidateService: CandidateService) {
    this.types = [
      { label: 'Stores', value: this.STORES },
      { label: 'Groups', value: this.GROUPS }
    ];
  }

  ngOnInit() {

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierProductService.getTaskId(),
        this.supplierProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT) {
           this.initializeData();
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierProductService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierProductService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.candidateError = this.supplierProductService.getStoreAuthError();
    this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
    this.currentCandidateProductIndex = this.supplierProductService.getCurrentCandidateProductIndex();
    this.distributorName = this.supplierProductService.getCandidate().vendor.description;
    this.setAuthGroups();
    if (this.supplierProductService.getStoresInput()) {
      this.storesInput = this.supplierProductService.getStoresInput();
    } else {
      this.onFilterChange();
    }
  }

  setAuthGroups() {
    if (!this.supplierProductService.getAuthGroups()) {

      this.supplierProductService.findAllAuthGroups().subscribe((authGroups) => {
        this.supplierProductService.setAuthGroups(authGroups);
        this.showNoStoresError = this.supplierProductService.validateHasNoStores();
        for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
          this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
      });
    } else {
      this.showNoStoresError = this.supplierProductService.validateHasNoStores();
      for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
        this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
      }
    }
  }

  onClickBack() {
    this.supplierProductService.saveCandidateAndNavigate('/casePack', false).pipe(
      finalize(() => this.canNavigate = true)
    ).subscribe();
  }

  onClose() {
    this.isViewingPage = false;
    this.supplierProductService.saveCandidateAndClose();
  }

  storesInputChangeEvent(storesInput: any) {
    this.storesErrorMessage = this.validateStoresInput(storesInput);
  }

  isShowTheseStoresDisabled() {
    return this.selectedType === this.STORES &&
      (this.storesErrorMessage || !this.storesInput);
  }

  /**
   * Stores input should be comma or newline separated group of store numbers.
   * @param storesInput
   */
  validateStoresInput(storesInput: any) {
    const stores = storesInput.trim().replace(/,/g, '\n');
    const storesArray = stores.split('\n');
    const regexp: RegExp = /^\d+$/; // only numbers

    let result;

    for (let i = 0; i < storesArray.length; i++) {
      // make sure all stores are a number
      if (!regexp.test(storesArray[i])) {
        result = 'Please enter store numbers, separated by a comma or each store on a new line.';
        break;
      }
    }
    if (!result) {
      this.supplierProductService.setStoresSearched(storesArray);
      this.supplierProductService.setStoresInput(storesInput);
    }
    return result;
  }

  onClickAllAuthGroupCheckbox(event) {
    for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
      this.supplierProductService.getAuthGroups()[x].checked = event;
    }
    // trigger update
    this.supplierProductService.setAuthGroups(JSON.parse(JSON.stringify(this.supplierProductService.getAuthGroups())));
  }

  onClickShowAllStores() {
    this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());
    this.supplierProductService.openAuthGroups();
    this.supplierProductService.setNotFoundStores(null);
    // todo: buttons disabled ?
    this.router.navigate(['/setUpStores'], {queryParams: {taskId: this.supplierProductService.getTaskId()}});

  }

  onClickGroupShowStores() {
    // todo: buttons disabled if none selected?

    const selectedAuthGroups: LocationGroupStores[] = [];

    for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
      if (this.supplierProductService.getAuthGroups()[x].checked) {
        selectedAuthGroups.push(JSON.parse(JSON.stringify(this.supplierProductService.getAuthGroups()[x])));
      }
    }
    this.supplierProductService.setSelectedAuthGroups(selectedAuthGroups);
    this.supplierProductService.openAuthGroups();
    this.router.navigate(['/setUpStores'], {queryParams: {taskId: this.supplierProductService.getTaskId()}});

  }

  onClickShowTheseStores() {
    // todo: buttons disabled ?
    if (!this.isShowTheseStoresDisabled()) {
      this.supplierProductService.setNotFoundStores(null);
      this.supplierProductService.setSelectedAuthGroups(this.supplierProductService.getAuthGroups());
      for (let x = 0; x < this.supplierProductService.getStoresSearched().length; x++) {
        this.findAndCheckStore(this.supplierProductService.getStoresSearched()[x]);
      }
      this.setAuthGroupCheckboxesAndHideNonSelectedStores();
      this.router.navigate(['/setUpStores'], {queryParams: {taskId: this.supplierProductService.getTaskId()}});
    }
  }

  hideNonSelectedStores() {
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      if (!this.supplierProductService.getSelectedAuthGroups()[x].isViewing) {
        continue;
      }
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (!this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          this.supplierProductService.getSelectedAuthGroups()[x].stores[y].isHidden = true;
        }
      }
    }
  }

  findAndCheckStore(storeNumber) {
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (+storeNumber === this.supplierProductService.getSelectedAuthGroups()[x].stores[y].custLocationNumber) {
          this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked = true;
          this.supplierProductService.getSelectedAuthGroups()[x].isViewing = true;
          return;
        }
      }
    }
    // if store not found-
    this.supplierProductService.getNotFoundStores().push(storeNumber);
  }

  /**
   * Sets auth groups to checked if all stores are checked.
   */
  setAuthGroupCheckboxesAndHideNonSelectedStores() {
    let areAllStoresChecked = true;
    for (let x = 0; x < this.supplierProductService.getSelectedAuthGroups().length; x++) {
      for (let y = 0; y < this.supplierProductService.getSelectedAuthGroups()[x].stores.length; y++) {
        if (!this.supplierProductService.getSelectedAuthGroups()[x].stores[y].checked) {
          areAllStoresChecked = false;
        }
      }
      if (areAllStoresChecked) {
        this.supplierProductService.getSelectedAuthGroups()[x].checked = true;
      }

    }
    this.hideNonSelectedStores();
  }

  onAuthGroupButtonClick(index, event) {

    if (!event) {
      this.selectAllAuthGroups = false;
    } else {
      let areAllGroupsChecked = true;
      for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
        if (!this.supplierProductService.getAuthGroups()[x].checked) {
          areAllGroupsChecked = false;
        }
      }
      if (areAllGroupsChecked) {
        this.selectAllAuthGroups = true;
      }

    }
  }

  /**
   * On filter changes, set all the checkboxes/viewports to false and remove searched stores.
   */
  onFilterChange() {
    this.supplierProductService.setStoresSearched(null);
    this.supplierProductService.setStoresInput(null);
    this.supplierProductService.setNotFoundStores(null);
    this.storesInput = '';
    this.selectAllAuthGroups = false;
    for (let x = 0; x < this.supplierProductService.getAuthGroups()?.length; x++) {
      this.supplierProductService.getAuthGroups()[x].isViewing = false;
      this.supplierProductService.getAuthGroups()[x].checked = false;
      for (let y = 0; y < this.supplierProductService.getAuthGroups()[x].stores.length; y++) {
        this.supplierProductService.getAuthGroups()[x].stores[y].checked = false;
      }
    }
  }

  hasSelectedGroup() {
    if (!this.supplierProductService.getAuthGroups() || this.supplierProductService.getAuthGroups().length === 0) {
      return true;
    }
    for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
      if (this.supplierProductService.getAuthGroups()[x].checked) {
        return true;
      }
    }
    return false;
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case NewWarehouseProductStepperComponent.UPC_ITEM_CODE:
        this.jumpTo('/setupCandidateType');
        break;
      case NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP:
        this.jumpTo('/supplierDetails');
        break;
      case NewWarehouseProductStepperComponent.PRODUCT_DETAILS:
        this.jumpTo('/basicItemSetup');
        break;
      case NewWarehouseProductStepperComponent.CASE_PACK:
        this.jumpTo('/casePack');
        break;
      case NewWarehouseProductStepperComponent.EXTENDED_ATTRIBUTES:
        this.jumpTo('/extendedAttributes', CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR);
        break;
      case NewWarehouseProductStepperComponent.REVIEW_PAGE:
        this.jumpTo('/newProductReviewPageComponent', CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR);
        break;
    }
  }

  jumpTo(urlToNavigate, validator?) {
    this.canNavigate = false;
    if (validator) {
      this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
        [validator]).toPromise().then(data => {
        this.candidateError = this.supplierProductService.setCasePackError(new CandidateError());
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
        this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      }, (error) => {
        if (error.error.candidateErrors?.hasErrors) {
          this.candidateError = this.supplierProductService.setStoreAuthError(error.error.candidateErrors);
          this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
          this.supplierProductService.setCandidateTypeError(this.candidateError);
        }
        this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      });
    } else {
      this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }
  }
}
