import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AttributeConfig, BaseCardPermissions, CandidateProduct, CandidateProductError} from 'pm-models';

@Component({
  selector: 'pm-mat-hierarchy-card',
  templateUrl: './mat-hierarchy-card.component.html',
  styleUrls: ['./mat-hierarchy-card.component.scss']
})
export class MatHierarchyCardComponent implements OnInit {

  @Input()
  permissions: BaseCardPermissions;

  @Input()
  candidateProductModel: CandidateProduct;

  @Input()
  candidateProductErrorModel: CandidateProductError;

  @Input()
  isLoadingMatData: boolean;

  @Output()
  hierarchyChange = new EventEmitter<any>();

  categorySelectionAttribute: AttributeConfig = {
    label: 'Product categories',
    isRequired: false
  };

  constructor() { }

  ngOnInit(): void {
  }


  onHierarchySelection(event) {
    this.candidateProductModel.matHierarchyList = event;
    this.hierarchyChange.emit();
  }
}
