<pm-review #pmReview class="pia-invited-review" (closeEvent)="onClose()">
  <pm-header>
    <app-pia-new-invited-distributor-stepper [candidateErrors]="invitedDistributorService.getAllCandidateErrors()"
                                             [stepIndex]="4">
    </app-pia-new-invited-distributor-stepper>
  </pm-header>
  <pm-title>Review store authorization requests</pm-title>

  <div *ngIf="invitedDistributorService.candidate" class="row ml-0">
    <div class="col-md-3 pl-4">
      <div  class="product-info">
        <div class="row image-row">
          <img class="product-image" src="{{invitedDistributorService.getProductImage()}}">
        </div>
        <div class="row header-row">
          UPC: {{invitedDistributorService.getUpc()}}
        </div>
        <div class="row description-row">
          {{invitedDistributorService.productData?.productDescription}}
        </div>
        <div class="row body-row">
          Brand: {{invitedDistributorService.productData?.brand.description}}
        </div>
        <div class="row body-row">
          Size: {{invitedDistributorService.upc?.size}}
        </div>
        <div class="row body-row">
          Retail: {{invitedDistributorService.upc?.xfor}} for ${{invitedDistributorService.upc?.retailPrice}}
        </div>
        <div class="row body-row">
          Status: Activated
        </div>
      </div>
    </div>

    <div class="col-md-9" [ngClass]="invitedDistributorService.isSelectingDistributors ? 'grid-with-checkbox' : ''">
      <div class="row">
        <div class="col col-md-11">
          <pm-attribute-grid [title]="'New requests'">
            <div *ngIf="invitedDistributorService.getHasSameStoreError()">
              <div class="col p-0">
                <div class="row same-store-error-container">
                  <img class="ban-icon" src="/assets/images/ban-icon.svg">
                  <div class="header-row">
                    Store conflicts
                  </div>
                  <div class="row message-body-row">
                    <p>
                      Multiple distributors have selected the same store(s). Stores can only receive this item from one distributor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="candidateErrorUtils.hasCandidateProductErrorMessages(invitedDistributorService.candidateError)">
              <div class="col p-0">
                <div class="row same-store-error-container">
                  <div class="col">
                    <div class="row ml-1">
                      <img class="ban-icon" src="/assets/images/ban-icon.svg">
                      <div class="header-row">
                        Missing Store Errors
                      </div>
                    </div>
                    <ui class="row error-list-row">
                      <li *ngFor="let error of candidateErrorUtils.getCandidateProductErrorMessages(invitedDistributorService.candidateError)">
                        {{error}}
                      </li>
                    </ui>
                  </div>
                </div>

              </div>
            </div>
            <div *ngIf="invitedDistributorService.resolvedSameStoreError">
              <div class="col p-0">
                <div class="same-store-error-container">
                  <div class="m-0 row">
                    <img class="ban-icon" src="/assets/images/party-popper-emoji.svg">
                    <div class="header-row">
                      Store conflicts resolved!
                    </div>
                  </div>
                  <div class="row message-body-row">
                    <p>
                      You've fixed all of the store conflicts. Awesome work!
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div class="grid-divider" [ngStyle]="invitedDistributorService.isSelectingDistributors ? {'margin-left' : '25px'} : {}"></div>
            <div class="row">
              <div *ngIf="invitedDistributorService.isSelectingDistributors">
                <pm-attribute-display-checkbox class="pl-0"
                                               [attribute]="selectedConfiguration"
                                               [(model)]="invitedDistributorService.candidate.selected">
                </pm-attribute-display-checkbox>
              </div>
              <div class="col">
                <pm-grid-group>
                  <pm-grid-column>
                    <pm-grid-cell [label]="'Supplier'" [canEdit]="true" (editClicked)="showStorePanel($event, authGroupsOverlay, authGroupsDiv, invitedDistributorService.candidate)" [errorMessage]="invitedDistributorService.candidateError?.vendor">
                      <b>{{invitedDistributorService.candidate.vendor.displayName}}</b>
                      <pm-grid-cell-info>
                        Cost owner: {{invitedDistributorService.candidate.costOwner.costOwnerName}}&nbsp; |&nbsp;  Top to top: {{invitedDistributorService.candidate.costOwner.topToTopName}}
                      </pm-grid-cell-info>
                    </pm-grid-cell>
                    <pm-grid-cell [label]="''">
                      <div class="row">
                        <div class="col">{{invitedDistributorService.getTotalStoreCountString(invitedDistributorService.candidate.candidateProducts[0].locationGroupStores,
                          invitedDistributorService.vendorApToAuthGroupsMap.get(invitedDistributorService.candidate.vendor.apNumber))}}
                        </div>
                        <div *ngIf="invitedDistributorService.getAllStoreConflictsString(invitedDistributorService.candidate.vendor.apNumber, invitedDistributorService.candidate.candidateProducts[0].locationGroupStores)" class="col-sm-auto">
                          <img src="/assets/images/bulk-quick-action-bolt.svg">
                          <button type="button" class="btn btn-link btn-sm pl-1 p-0" (click)="invitedDistributorService.showErrorStoreOverlay(resolveAllConflictsPanel, $event, invitedDistributorService.candidate.vendor.apNumber, null, null)">Resolve all conflicts</button>
                        </div>
                      </div>
                      <div *ngIf="invitedDistributorService.getAllStoreConflictsString(invitedDistributorService.candidate.vendor.apNumber, invitedDistributorService.candidate.candidateProducts[0].locationGroupStores)" class="total-store-conflicts">
                        <img class="ban-icon" src="/assets/images/ban-icon.svg">&nbsp;
                        <b>
                          {{invitedDistributorService.getAllStoreConflictsString(invitedDistributorService.candidate.vendor.apNumber, invitedDistributorService.candidate.candidateProducts[0].locationGroupStores)}}
                        </b>
                      </div>
                    </pm-grid-cell>
                  </pm-grid-column>
                </pm-grid-group>
                <pm-grid-group>
                  <pm-grid-column>
                    <pm-grid-cell *ngxPermissionsOnly="['ROLE_COST_LINK']" [label]="'Cost linked'" [canEdit]="true" (editClicked)="editCostLink()">
                      {{invitedDistributorService.candidate.costLinked | yesNo}}
                    </pm-grid-cell>
                    <pm-grid-cell *ngxPermissionsOnly="['ROLE_COST_LINK']" [label]="'Cost linked item'" [canEdit]="true" (editClicked)="editCostLinkedItem()">
                      {{invitedDistributorService.candidate.costLink}}
                    </pm-grid-cell>
                    <pm-grid-cell [label]="'Master list cost'">
                      {{costService.toCurrencyForCost(invitedDistributorService.candidate.masterListCost)}}
                      <pm-grid-cell-info>
                        Unit Cost: {{invitedDistributorService.getUnitCost(invitedDistributorService.candidate)}} <br>
                        Penny profit: {{invitedDistributorService.getHebPennyProfit(invitedDistributorService.candidate)}} <br>
                        Margin: {{invitedDistributorService.getHebMargin(invitedDistributorService.candidate)}}
                      </pm-grid-cell-info>
                    </pm-grid-cell>
                    <pm-grid-cell [label]="'Deal offered'">
                      {{invitedDistributorService.candidate.dealOffered | yesNo}}
                    </pm-grid-cell>
                    <pm-grid-cell [label]="'Total retail units'">
                      {{invitedDistributorService.candidate.masterPack}}
                    </pm-grid-cell>
                  </pm-grid-column>
                  <pm-grid-column>
                    <pm-grid-cell [label]="'Case description'">
                      {{invitedDistributorService.candidate.candidateProducts[0].caseDescription}}
                    </pm-grid-cell>
                    <pm-grid-cell *ngIf="!invitedDistributorService.candidate.overrideDepartment" [label]="'Department'" [canEdit]="false">
                      {{invitedDistributorService.candidate?.commodity?.subDepartmentDescription}}
                    </pm-grid-cell>
                    <pm-grid-cell *ngIf="invitedDistributorService.candidate.overrideDepartment" [label]="'Department'" [canEdit]="false">
                      {{invitedDistributorService.candidate?.overrideSubDepartment?.subDepartmentDescription}}
                    </pm-grid-cell>
                    <pm-grid-cell *ngIf="invitedDistributorService.candidate.pssDepartment" [label]="'PSS Department'" [canEdit]="false">
                      {{invitedDistributorService.candidate?.pssDepartment?.displayName}}
                    </pm-grid-cell>
                    <pm-grid-cell [label]="'VPC'">
                      {{invitedDistributorService.candidate.candidateProducts[0].vendorProductCode}}
                    </pm-grid-cell>
                    <pm-grid-cell [label]="'Country of origin'">
                      {{invitedDistributorService.candidate.candidateProducts[0].countryOfOrigin.description}}
                    </pm-grid-cell>
                  </pm-grid-column>
                </pm-grid-group>
                <div class="grid-divider"></div>

                <ng-template ngFor let-locationGroupStore [ngForOf]="invitedDistributorService.candidate.candidateProducts[0].locationGroupStores" let-index="index">

                  <pm-grid-group *ngIf="invitedDistributorService.showLocationGroup(locationGroupStore)">
                    <pm-grid-column>
                      <pm-grid-cell [label]="'Authorization group'">
                        <div class="row">
                          <div class="col">
                            <div>{{locationGroupStore.splrLocationGroupId}}</div>
                          </div>
                          <div *ngIf="invitedDistributorService.getAllStoreConflictsString(invitedDistributorService.candidate.vendor.apNumber, [locationGroupStore])" class="col-sm-auto">
                            <img src="/assets/images/bulk-quick-action-bolt.svg">
                            <button type="button" class="btn btn-link btn-sm pl-1 p-0" (click)="invitedDistributorService.showErrorStoreOverlay(resolveAllConflictsPanel, $event, invitedDistributorService.candidate.vendor.apNumber, null, locationGroupStore.splrLocationGroupId)">Resolve conflicts</button>
                          </div>
                        </div>
                      </pm-grid-cell>
                      <pm-grid-cell [label]="'Stores'">
                        <div class="row ml-0 mb-2">{{invitedDistributorService.getAuthGroupStoreCountLabel(locationGroupStore)}}</div>
                        <div class="m-0 row">

                          <div #storeBlockId *ngFor="let store of locationGroupStore.stores" class="ui-grid-col-2 mr-2 mb-2">
                            <div [ngClass]="{'reverted-override-store-cell' : store.overrideActivatedStore === false}" *ngIf="invitedDistributorService.showDefaultStore(invitedDistributorService.candidate.vendor.apNumber, store.custLocationNumber, store.overrideActivatedStore)"
                                 class="store-block">
                              {{store.custLocationNumber}}
                              <span *ngIf="store.overrideActivatedStore || store.overrideActivatedStore === false">*</span>
                            </div>
                            <div *ngIf="!invitedDistributorService.showDefaultStore(invitedDistributorService.candidate.vendor.apNumber, store.custLocationNumber, store.overrideActivatedStore)"
                                 class="store-error-block" (click)="invitedDistributorService.showErrorStoreOverlay(storeErrorPanel, $event, invitedDistributorService.candidate.vendor.apNumber, store.custLocationNumber, null)">
                              {{store.custLocationNumber}}
                            </div>

                            <p-overlayPanel #storeErrorPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" class="store-error-panel" appendTo="body" [style]="{width: '650px', 'background-color': 'black'}" (mouseleave)="invitedDistributorService.hideErrorStoreOverlay(storeErrorPanel)">
                              <div class="store-error-panel-body">
                                <div *ngIf="invitedDistributorService.overrideActivatedStore" class="row">
                                  <div class="col-md-7">
                                    <div>
                                      <div class="store-error-header-row">
                                        Change authorization to this Supplier?
                                      </div>
                                      <div class="store-error-header-body">
                                        We'll let {{invitedDistributorService.getActivatedStoreName(invitedDistributorService.storeErrorStoreNumber)}} know that they are no longer authorized to deliver to this store.
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="row m-0 store-error-button-row">
                                      <button pButton type="button" label="Remove" class="cancel-dark mr-2 text-right" (click)="invitedDistributorService.removeStore(storeErrorPanel)"></button>
                                      <p-button class="ml-2" label="Authorize" (onClick)="invitedDistributorService.authorizeStore(storeErrorPanel)"></p-button>
                                    </div>
                                  </div>
                                </div>

                                <div *ngIf="!invitedDistributorService.overrideActivatedStore" class="row">
                                  <div class="col-md-7">
                                    <div>
                                      <div class="store-error-header-row">
                                        Authorize <b>only</b> this distributor for this store?
                                      </div>
                                      <div class="store-error-header-body">
                                        We'll let the other distributors know that they are not authorized to deliver to this store.
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="row m-0 store-error-button-row">
                                      <button pButton type="button" label="Cancel" class="cancel-dark mr-2 text-right" (click)="invitedDistributorService.hideErrorStoreOverlay(storeErrorPanel)"></button>
                                      <p-button class="ml-2" label="Authorize" (onClick)="invitedDistributorService.authorizeStore(storeErrorPanel)"></p-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </p-overlayPanel>
                          </div>
                        </div>

                        <div *ngIf="invitedDistributorService.hasActiveCandidateStoreOverride(invitedDistributorService.candidate.candidateId, locationGroupStore.splrLocationGroupId, invitedDistributorService.invitedCandidates)" class="active-store-error">
                          <div class="row active-store-conflicts-header">
                            <div class="col-md-10">
                              <span>*</span> Authorized Supplier changed
                            </div>
                            <div *ngIf="locationGroupStore.isViewing" class="col caret-column">
                              <i class="pi pi-caret-up col-md-2" (click)="locationGroupStore.isViewing = !locationGroupStore.isViewing"></i>
                            </div>
                            <div *ngIf="!locationGroupStore.isViewing" class="col caret-column">
                              <i class="pi pi-caret-down col-md-2" (click)="locationGroupStore.isViewing = !locationGroupStore.isViewing"></i>
                            </div>
                          </div>
                          <div *ngIf="locationGroupStore.isViewing" class="active-store-conflicts-body">
                            <div class="mb-1" style="padding-left: 0.025rem;">
                              A reviewer changed the authorized Supplier for the stores below:
                            </div>
                            <div class="row ml-3 mt-1" *ngFor="let store of this.invitedDistributorService.getActiveCandidateStoreOverrideByCandidate(invitedDistributorService.candidate, locationGroupStore.splrLocationGroupId)">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="override-col">
                                    <div class="row">
                                      <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}" class="override-bullet">
                                        &#8226;
                                      </div>
                                      <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}">
                                        #{{store.custLocationNumber}} -
                                      </div>
                                      <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}" class="overridden-vendor-col">
                                        {{store.overriddenVendorDisplayName}}
                                      </div>
                                      <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}">
                                        &nbsp;&#8594;&nbsp;
                                      </div>
                                      <div [ngClass]="{'grayed-out-col' : !store.overrideActivatedStore}" class="current-vendor-override-col">
                                        {{invitedDistributorService.candidate.vendor.displayName}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="override-col pl-1">
                                    <div>
                                      <img *ngIf="store.overrideActivatedStore" src="/assets/images/undo-icon.svg" class="undo-button"
                                           (click)="store.overrideActivatedStore = !store.overrideActivatedStore">

                                      <img *ngIf="!store.overrideActivatedStore" src="/assets/images/redo-icon.svg" class="undo-button"
                                           (click)="store.overrideActivatedStore = !store.overrideActivatedStore">

                                      <a class="undo-button-link" [routerLink]="" queryParamsHandling="preserve"
                                         (click)="store.overrideActivatedStore = !store.overrideActivatedStore">{{store.overrideActivatedStore ? 'Undo' : 'Redo'}}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </pm-grid-cell>

                    </pm-grid-column>
                  </pm-grid-group>
                </ng-template>

                <p-overlayPanel #resolveAllConflictsPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" class="store-error-panel" appendTo="body" [style]="{width: '650px', 'background-color': 'black'}" (mouseleave)="invitedDistributorService.hideErrorStoreOverlay(resolveAllConflictsPanel)">
                  <div class="store-error-panel-body">
                    <div class="store-error-header-row">
                      Change authorization to this Supplier?
                      <div class="store-error-header-body">
                        You can keep the current Supplier(s) and remove all conflicting requests or switch stores to this Supplier. We can let the current Supplier(s) know that they are no longer authorized to deliver to these stores.
                      </div>
                    </div>
                    <div class="row m-0 store-error-button-row">
                      <button pButton type="button" label="Keep current Suppliers" class="cancel-dark mr-2 text-right" (click)="invitedDistributorService.removeAllStoreConflictsFromVendor(resolveAllConflictsPanel)"></button>
                      <p-button class="ml-2" label="Authorize this Supplier" (onClick)="invitedDistributorService.authorizeAllStoresForVendor(resolveAllConflictsPanel)"></p-button>
                    </div>
                  </div>
                </p-overlayPanel>
              </div>
            </div>
            <div class="grid-divider" [ngStyle]="invitedDistributorService.isSelectingDistributors ? {'margin-left' : '25px'} : {}"></div>

          </pm-attribute-grid>
        </div>
      </div>

      <div *ngIf="invitedDistributorService.showHasOtherCandidates()" class="pl-0 mt-3 mb-3 col-md-12">
        <h3 class="other-suppliers-header">Other Suppliers</h3>

        <!--   not yet submitted by supplier   -->
        <div *ngIf="invitedDistributorService.notSubmittedCandidates.length > 0" class="other-invited-container">
          <div class="row headerRow">
            <div class="col-md-10 pl-0 invitedHeader">
              Invited but not yet submitted<br>
              <div class="invitedHeaderSubText">
                {{invitedDistributorService.getSupplierHeaderText(invitedDistributorService.notSubmittedCandidates)}}
              </div>
            </div>
            <i *ngIf="isViewingNotSubmittedCandidates" class="pi pi-caret-up col-md-2 text-right" (click)="isViewingNotSubmittedCandidates = !isViewingNotSubmittedCandidates"></i>
            <i *ngIf="!isViewingNotSubmittedCandidates" class="pi pi-caret-down col-md-2 text-right" (click)="isViewingNotSubmittedCandidates = !isViewingNotSubmittedCandidates"></i>
            <div *ngIf="isViewingNotSubmittedCandidates" class="invitedBody">
              <ng-template ngFor let-invitedSupplier let-i="index" [ngForOf]="invitedDistributorService.notSubmittedCandidates">
                <div class="mt-3">
                  {{invitedSupplier.vendor.displayName}}<br>
                  <div class="ml-0 row">
                    <div class="invitedBodySubText">
                      Invited
                    </div>
                    <div class="invitedBodyDivider">
                      &nbsp; | &nbsp;
                    </div>
                    <div>
                      <button pButton type="button" label="Remove" class="ui-button-link" (click)="removeInvitedSupplier(invitedSupplier)"></button>
                    </div>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>

        <!--   submitted for review   -->
        <div *ngIf="invitedDistributorService.submittedCandidates.length > 0" class="other-invited-container">
          <div class="row headerRow">
            <div class="col-md-10 pl-0 invitedHeader">
              Submitted for review<br>
              <div class="invitedHeaderSubText">
                {{invitedDistributorService.getSupplierHeaderText(invitedDistributorService.submittedCandidates)}}
              </div>
            </div>
            <i *ngIf="isViewingSubmittedCandidates" class="pi pi-caret-up col-md-2 text-right" (click)="isViewingSubmittedCandidates = !isViewingSubmittedCandidates"></i>
            <i *ngIf="!isViewingSubmittedCandidates" class="pi pi-caret-down col-md-2 text-right" (click)="isViewingSubmittedCandidates = !isViewingSubmittedCandidates"></i>
            <div *ngIf="isViewingSubmittedCandidates" class="invitedBody">
              <ng-template ngFor let-invitedSupplier let-i="index" [ngForOf]="invitedDistributorService.submittedCandidates">
                <div class="mt-3">
                  {{invitedSupplier.vendor.displayName}}<br>
                  <div class="ml-0 row">
                    <div class="invitedBodySubText">
                      Invited
                    </div>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>

        <!--   approved   -->
        <div *ngIf="invitedDistributorService.approvedCandidates.length > 0" class="other-invited-container">
          <div class="row headerRow">
            <div class="col-md-10 pl-0 invitedHeader">
              Approved<br>
              <div class="invitedHeaderSubText">
                {{invitedDistributorService.getSupplierHeaderText(invitedDistributorService.approvedCandidates)}}
              </div>
            </div>
            <i *ngIf="isViewingApprovedCandidates" class="pi pi-caret-up col-md-2 text-right" (click)="isViewingApprovedCandidates = !isViewingApprovedCandidates"></i>
            <i *ngIf="!isViewingApprovedCandidates" class="pi pi-caret-down col-md-2 text-right" (click)="isViewingApprovedCandidates = !isViewingApprovedCandidates"></i>
            <div *ngIf="isViewingApprovedCandidates" class="invitedBody">
              <ng-template ngFor let-invitedSupplier let-i="index" [ngForOf]="invitedDistributorService.approvedCandidates">
                <div class="mt-3">
                  {{invitedSupplier.vendor.displayName}}<br>
                  <div class="ml-0 row">
                    <div class="invitedBodySubText">
                      Approved
                    </div>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>

        <div *ngIf="invitedDistributorService.activatedApNumberToStoreNumbersMap.size > 0" class="other-invited-container">
          <div class="row headerRow">
            <div class="col-md-10 pl-0 invitedHeader">
              Activated<br>
              <div class="invitedHeaderSubText">
                {{invitedDistributorService.getSupplierHeaderTextFromMap(invitedDistributorService.activatedApNumberToStoreNumbersMap)}}
              </div>
            </div>
            <i *ngIf="isViewingActivatedCandidates" class="pi pi-caret-up col-md-2 text-right" (click)="isViewingActivatedCandidates = !isViewingActivatedCandidates"></i>
            <i *ngIf="!isViewingActivatedCandidates" class="pi pi-caret-down col-md-2 text-right" (click)="isViewingActivatedCandidates = !isViewingActivatedCandidates"></i>

            <div *ngIf="isViewingActivatedCandidates" class="invitedBody">

              <ng-template ngFor let-activatedSupplierAp let-i="index" [ngForOf]="invitedDistributorService.getActivatedApNumberToStoreNumbersMapKeys()">

                <pm-attribute-grid>
                  <div class="grid-divider"></div>
                  <pm-grid-group>
                    <pm-grid-column>
                      <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
                        <b>{{invitedDistributorService.activatedApNumberToDisplayNameMap.get(activatedSupplierAp)}}</b>
                      </pm-grid-cell>
                      <pm-grid-cell [label]="''">
                        {{invitedDistributorService.getActivatedTotalCountString(invitedDistributorService.activatedApNumberToStoreNumbersMap.get(activatedSupplierAp), invitedDistributorService.vendorApToAuthGroupsMap.get(activatedSupplierAp))}}
                      </pm-grid-cell>
                    </pm-grid-column>
                  </pm-grid-group>
                  <ng-template ngFor let-locationGroupStore [ngForOf]="invitedDistributorService.vendorApToAuthGroupsMap.get(activatedSupplierAp)" let-index="index">

                    <div *ngIf="invitedDistributorService.doesActivatedGroupHaveStores(activatedSupplierAp, locationGroupStore.splrLocationGroupId)" class="grid-divider"></div>
                    <pm-grid-group *ngIf="invitedDistributorService.doesActivatedGroupHaveStores(activatedSupplierAp, locationGroupStore.splrLocationGroupId)">
                      <pm-grid-column>
                        <pm-grid-cell [label]="'Authorization group'">
                          <div>{{locationGroupStore.splrLocationGroupId}}</div>
                        </pm-grid-cell>
                        <pm-grid-cell [label]="'Stores'">
                          <div class="row ml-0 mb-2">{{invitedDistributorService.getActivatedStoreCountLabel(invitedDistributorService.activatedApNumberToGroupStoresMap.get(activatedSupplierAp).get(locationGroupStore.splrLocationGroupId))}}</div>
                          <div class="m-0 row">
                            <div *ngFor="let store of invitedDistributorService.activatedApNumberToGroupStoresMap.get(activatedSupplierAp).get(locationGroupStore.splrLocationGroupId)" class="ui-grid-col-2 mr-2 mb-2">
                              <div class="store-block">
                                {{store}}
                              </div>
                            </div>
                          </div>
                        </pm-grid-cell>
                      </pm-grid-column>
                    </pm-grid-group>
                  </ng-template>
                  <div class="grid-divider"></div>
                </pm-attribute-grid>
              </ng-template>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="invitedDistributorService.invitedCandidates && invitedDistributorService.invitedCandidates.length > 0" class="addDistributors">
        <button pButton label="Add more distributors" class="ui-button-info mt-2" (click)="invitedDistributorService.onClickAddDistributors(pmReview)"></button>
      </div>
    </div>
  </div>

  <pm-review-drawer-container>

    <ng-container *ngIf="invitedDistributorService.showAddMoreSuppliers">
      <div class="container p-3 addMoreDistributorsDrawer">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="invitedDistributorService.closeInviteSuppliersPanel(pmReview)"></button>
          </div>
        </div>
        <div class="attributes-stacked">
          <h2>Invite Suppliers</h2>
          <p>Choose your Suppliers and we’ll invite them to set up store authorization for this product after it’s activated.</p>

          <pm-attribute-display-typeahead [attribute]="invitedDistributorService.supplierConfiguration"
                                          [(model)]="invitedDistributorService.vendorsToInvite"
                                          (selection)="invitedDistributorService.onInvitedVendorSelection($event)">
          </pm-attribute-display-typeahead>

          <div class="text-right">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="invitedDistributorService.closeInviteSuppliersPanel(pmReview)"></p-button>
            <p-button type="button" label="Invite" class="m-2" (click)="invitedDistributorService.inviteSuppliers(pmReview)" [disabled]="!invitedDistributorService.hasVendorsToInvite()"></p-button>
          </div>
        </div>


      </div>
    </ng-container>
  </pm-review-drawer-container>
  <pm-footer>
    <div *ngIf="invitedDistributorService.isSelectingDistributors">
      <div>
        <p-button class="ghost m-2 cancel-button" [ngStyle]="{'float': 'left'}" label="Cancel"
                  (onClick)="invitedDistributorService.isSelectingDistributors = !invitedDistributorService.isSelectingDistributors"
                  [disabled]="isRejectDisabled || isApproveDisabled">

        </p-button>
      </div>
      <div>
        <p-button class="ghost m-2" label="Reject selected"
                  (onClick)="rejectSelected()"
                  [disabled]="isRejectDisabled || isApproveDisabled || !invitedDistributorService.hasSelectedSupplier()">
        </p-button>
        <p-button class="m-2" label="Approve selected" (onClick)="approveSelected()" [disabled]="isRejectDisabled || isApproveDisabled || !invitedDistributorService.hasSelectedSupplier() || invitedDistributorService.selectedHasSameStoreError()"></p-button>
      </div>
    </div>
    <div *ngIf="!invitedDistributorService.isSelectingDistributors">
      <p-button class="ghost m-2" label="Reject all" (onClick)="rejectAll()" [disabled]="isApproveDisabled || isRejectDisabled"></p-button>
      <p-button class="ghost m-2" label="Select..." (onClick)="invitedDistributorService.isSelectingDistributors = !invitedDistributorService.isSelectingDistributors" [disabled]="isRejectDisabled || isApproveDisabled"></p-button>
      <p-button class="m-2" label="Approve all" (onClick)="approveAll()" [disabled]="isApproveDisabled || isRejectDisabled || invitedDistributorService.getHasSameStoreError()"></p-button>
    </div>
  </pm-footer>
</pm-review>

<div #authGroupsDiv></div>
<pm-static-overlay-panel [hideTransitionOptions]="'0ms ease-in'" [showTransitionOptions]="'0ms ease-out'" #authGroupsOverlay appendTo="body" [style]="{width: '100%', height: '100%'}">
  <app-set-up-stores-overlay #setupStoreOverlay *ngIf="isShowingStorePanel" [canClickSave]="canClickSave" [candidateError]="invitedDistributorService.candidateError"
                             [candidateProductError]="invitedDistributorService.candidateProductError" (cancelButtonClicked)="authGroupsOverlay.hide()"
                             (saveButtonClicked)="saveClicked($event, authGroupsOverlay)">
  </app-set-up-stores-overlay>
</pm-static-overlay-panel>
