import {Injectable} from '@angular/core';
import {Candidate, CandidateProduct, DsdItem, Item, Product, Upc} from 'pm-models';
import {LookupService} from './lookup.service';
import {Observable} from 'rxjs';
import {CostService} from './cost.service';
import {ExistingInner} from 'pm-models/lib/existingInner';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public IMAGE_BASE_URL = 'https://images.heb.com/is/image/HEBGrocery/';

  constructor(private lookupService: LookupService, private costService: CostService) { }


  getUpcBySearchedValue(candidateProduct: CandidateProduct, productData: Product): Observable<Upc> {

    let upcToFind;
    if (candidateProduct.candidateProductType === CandidateProduct.SEARCHED_UPC) {
      if (!candidateProduct.upc) {
        return null;
      }
      upcToFind = candidateProduct.upc;
    } else if (candidateProduct.candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      if (!candidateProduct.itemCode) {
        return null;
      }
      for (let x = 0; x < productData.items.length; x++) {
        if (productData.items[x].itemCode === candidateProduct.itemCode) {
          upcToFind = this.findItemsPrimaryUpc(productData.items[x]);
        }
      }
    } else {
      return null;
    }
    return this.lookupService.getUpc(upcToFind);
  }

  findItemsPrimaryUpc(item: Item) {
    // if MRT, get first upc
    if (item.innerUpcs) {
      return item.innerUpcs[0].upc.scanCodeId;
    } else if (item.containedUpc && item.containedUpc.upc) {
      return item.containedUpc.upc.scanCodeId;
    } else {
      return null;
    }
  }

  getProductImageUrl(productData: Product) {
    return this.IMAGE_BASE_URL + productData.productId.toString().padStart(9, '0');
  }

  /**
   * Sets the candidate retail from the provided upc.
   * @param candidate
   * @param upc
   */
  setCandidateRetailFromUpc(candidate: Candidate, upc: Upc) {
    candidate.suggestedRetailPrice = upc.retailPrice;
    candidate.suggestedXFor = 1;
  }

  setCandidateRetailFromProduct(candidate: Candidate, productData: Product) {
    for (let x = 0; x < productData.items.length; x++) {
      if (productData.items[x].innerUpcs && productData.items[x].innerUpcs.length > 0) {
        candidate.suggestedRetailPrice = productData.items[x].innerUpcs[0].upc.retailPrice;
        candidate.suggestedXFor = 1;
        return;
      } else if (productData.items[x].containedUpc) {
        candidate.suggestedRetailPrice = productData.items[x].containedUpc.upc.retailPrice;
        candidate.suggestedXFor = 1;
        return;
      }
    }
  }

  /**
   * Searches for the department in the item or dsd hierarchy.
   * @param productData the product data to search.
   */
  getDepartment(productData: Product) {
    const department = this.getDepartmentFromItems(productData);
    return department ? department : this.getDepartmentFromDsdItems(productData);
  }

  /**
   * Searches for the department in the item hierarchy.
   * @param productData the product data to search.
   */
  getDepartmentFromItems(productData: Product) {
    if (productData.items && productData.items.length > 0) {
      for (let x = 0; x < productData.items.length; x++) {
        if (productData.items[x].hierarchy &&
          !productData.items[x].mrt &&
          productData.items[x].hierarchy.commodity &&
          productData.items[x].hierarchy.commodity.itemClass &&
          productData.items[x].hierarchy.commodity.itemClass.subDepartment &&
          productData.items[x].hierarchy.commodity.itemClass.subDepartment.department) {
          return +productData.items[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId;
        }
      }
    } else {
      return null;
    }
  }

  /**
   * Searches for the department in the dsd hierarchy.
   * @param productData the product data to search.
   */
  getDepartmentFromDsdItems(productData: Product) {
    if (productData.dsdItems && productData.dsdItems.length > 0) {
      for (let x = 0; x < productData.dsdItems.length; x++) {
        if (productData.dsdItems[x].hierarchy &&
          !productData.dsdItems[x].mrt &&
          productData.dsdItems[x].hierarchy.commodity &&
          productData.dsdItems[x].hierarchy.commodity.itemClass &&
          productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment &&
          productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department) {
          return +productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId;
        }
      }
    } else {
      return null;
    }
  }

  getPrimaryItem(candidateProduct: CandidateProduct, productData: Product) {

    if (!productData.items || productData.items.length === 0) {
      return null;
    }

    if (candidateProduct.candidateProductType === CandidateProduct.SEARCHED_UPC) {
      for (let x = 0; x < productData.items.length; x++) {
        if (productData.items[x].containedUpc &&
          productData.items[x].containedUpc.upc.scanCodeId === candidateProduct.upc) {
          return productData.items[x];
        } else if (productData.items[x].innerUpcs) {
          for (let y = 0; y < productData.items[x].innerUpcs.length; y++) {
            if (productData.items[x].innerUpcs[y].upc.scanCodeId === candidateProduct.upc) {
              return productData.items[x];
            }
          }
        } else {
          if (!productData.items[x].containedUpc || !productData.items[x].containedUpc.associatedUpcs ||
            productData.items[x].containedUpc.associatedUpcs.length === 0) {
            continue;
          }
          for (let y = 0; y < productData.items[x].containedUpc.associatedUpcs.length; y++) {
            if (candidateProduct.upc === productData.items[x].containedUpc.associatedUpcs[y]) {
              return productData.items[x];
            }
          }
        }
      }
    } else if (candidateProduct.candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      for (let x = 0; x < productData.items.length; x++) {
        if (productData.items[x].itemCode === candidateProduct.itemCode) {
          return productData.items[x];
        }
      }
    }
    return null;
  }

  /**
   * Returns the primary dsd item for the upc provided.
   *
   * @param candidateProduct
   * @param productData
   */
  getPrimaryDsdItem(candidateProduct: CandidateProduct, productData: Product): DsdItem {
    if (candidateProduct.candidateProductType !== CandidateProduct.SEARCHED_UPC ||
      !productData.dsdItems || productData.dsdItems.length === 0) {
      return null;
    }

    for (let x = 0; x < productData.dsdItems.length; x++) {
      if (productData.dsdItems[x].upc === candidateProduct.upc) {
        return productData.dsdItems[x];
      }
    }
    return null;
  }

  getPricingType(productData: Product, upc: Upc) {
    if (productData && upc) {
      if (productData.priceRequired) {
        return 'Price Required';
      } else if (upc.retailLink) {
        return 'Retail Link';
      } else {
        return 'Key Retail';
      }
    }
    return '';
  }

  getMarginFromExistingInner(existingInner: ExistingInner) {

    if (existingInner.product) {
      const candidate = new Candidate();
      candidate.unitCost = existingInner.unitCost;
      candidate.retailType = CostService.KEY_RETAIL;
      this.setCandidateRetailFromProduct(candidate, existingInner.product);
      return this.costService.getMargin(candidate) + '%';
    }
  }
  getPenneyProfitFromExistingInner(existingInner: ExistingInner) {
    if (existingInner.product) {
      const candidate = new Candidate();
      candidate.unitCost = existingInner.unitCost;
      candidate.retailType = CostService.KEY_RETAIL;
      this.setCandidateRetailFromProduct(candidate, existingInner.product);
      return this.costService.getPennyProfit(candidate);
    }
  }

  setBuyerAndHierarchyInformation(candidate: Candidate, productData: Product) {
    if (productData.items && productData.items.length > 0) {

      for (let x = 0; x < productData.items.length; x++) {
        if (productData.items[x].hierarchy &&
          !productData.items[x].mrt &&
          productData.items[x].hierarchy.commodity &&
          productData.items[x].hierarchy.commodity.itemClass &&
          productData.items[x].hierarchy.commodity.itemClass.subDepartment &&
          productData.items[x].hierarchy.commodity.itemClass.subDepartment.department) {
          candidate.commodity = { commodityId: productData.items[x].hierarchy.commodity.commodityId,
            commodityName: productData.items[x].hierarchy.commodity.description,
            departmentId: productData.items[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId};
          candidate.subCommodity = {subCommodityId: productData.items[x].hierarchy.subCommodityId,
            subCommodityName: productData.items[x].hierarchy.description};
          candidate.buyer = { buyerId: productData.items[x].hierarchy.commodity.bdm.bdmId,
            buyerName: productData.items[x].hierarchy.commodity.bdm.fullName,
            userId: productData.items[x].hierarchy.commodity.bdm.onePassId};
          break;
        }
      }
    } else {
      for (let x = 0; x < productData.dsdItems.length; x++) {
        if (productData.dsdItems[x].hierarchy &&
          !productData.dsdItems[x].mrt &&
          productData.dsdItems[x].hierarchy.commodity &&
          productData.dsdItems[x].hierarchy.commodity.itemClass &&
          productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment &&
          productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department) {
          candidate.commodity = { commodityId: productData.dsdItems[x].hierarchy.commodity.commodityId,
            commodityName: productData.dsdItems[x].hierarchy.commodity.description,
            departmentId: productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId};
          candidate.subCommodity = {subCommodityId: '' + productData.dsdItems[x].hierarchy.subCommodityId,
            subCommodityName: productData.dsdItems[x].hierarchy.description};
          candidate.buyer = { buyerId: productData.dsdItems[x].hierarchy.commodity.bdm.bdmId,
            buyerName: productData.dsdItems[x].hierarchy.commodity.bdm.fullName,
            userId: productData.dsdItems[x].hierarchy.commodity.bdm.onePassId};
          break;
        }
      }
    }
  }

  isMoreThanOneItemCode(product: Product)   {
    return product
      && ((product.items && product.items.length > 1)
        || (product.dsdItems && product.dsdItems.length > 1));
  }

  getItemCode(product: Product) {
    if (product) {
      if (product.items && product.items.length > 0) {
        return product.items[0].itemCode;
      } else if (product.dsdItems && product.dsdItems.length > 0) {
        return product.dsdItems[0].upc;
      }
    }
  }

  getProductByUpc(upc: number, products: Product[]): Product {
    if (!products?.length) {
      return null;
    }
    for (const product of products) {
      const productItemsContainsUpc = !!product.items?.find(item => this.itemContainsUpc(item, upc));
      const productDsdItemsContainsUpc = !!product.dsdItems?.find(dsdItem => this.dsdItemContainsUpc(dsdItem, upc));
      if (productItemsContainsUpc || productDsdItemsContainsUpc) {
        return product;
      }
    }
    return null;
  }

  itemContainsUpc(item: Item, upc: number): boolean {
    if (!item || item.mrt || upc === null || upc === undefined) {
      return false;
    }

    if (item.containedUpc && item.containedUpc.upc.scanCodeId === upc) {
      return true;
    } else if (!!item.innerUpcs?.length) {
      for (let y = 0; y < item.innerUpcs.length; y++) {
        if (item.innerUpcs[y].upc.scanCodeId === upc) {
          return true;
        }
      }
    } else {
      return !!item.containedUpc.associatedUpcs?.find(associateUpc => associateUpc === upc);
    }
  }

  dsdItemContainsUpc(dsdItem: DsdItem, upc: number): boolean {
    if (!dsdItem || upc === null || upc === undefined) {
      return false;
    }
    return dsdItem?.upc === upc;
  }
}
