import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-attachment-upload',
  templateUrl: './attribute-display-attachment-upload.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-attachment-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayAttachmentUploadComponent extends BaseAttributeDisplayComponent implements OnInit {
  constructor() {
    super();
  }

  updateCallback(event) {
    this.model = event;
    this.modelChange.emit(this.model);
  }

  ngOnInit() {}
}
