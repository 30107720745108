import {Input, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

/**
 * This pipe transforms a date string into the date standard desired from users. It can either just show the date,
 * or show the date with time (if the additional argument 'showTime' is used).
 *
 * How to use:
 * Example: <html>{{dateToFormat | dateStandard}} will show something like '07/09/2018'
 * Example: <html>{{dateToFormat | dateStandard : {showTime: false} }} will show something like '07/09/2018'
 * Example: <html>{{dateToFormat | dateStandard : {showTime: true} }} will show something like '07/09/2018, 8:32:47 AM'
 *
 * This was modified from https://loiane.com/2017/08/angular-tips-formatting-dates-with-a-custom-date-pipe-dd-mm-yyyy/
 * author: Loiane Groner
 */
@Pipe({
  name: 'dateStandard'
})
export class DateStandardPipe extends DatePipe implements PipeTransform {

  private static DATE_STANDARD_FORMAT = 'MM/dd/yy';
  private static DATE_STANDARD_WITH_TIME_FORMAT = 'MM/dd/yy, h:mm a';

  transform(value: any, args?: any): any {
    if (this.isShowTime(args)) {
      return super.transform(value, DateStandardPipe.DATE_STANDARD_WITH_TIME_FORMAT);
    }
    return super.transform(value, DateStandardPipe.DATE_STANDARD_FORMAT);
  }

  /**
   * Checks for property 'showTime' within the arguments. If 'showTime' property is present, return the value associated
   * with it. Else return false.
   *
   * @param args Arguments sent to this pipe.
   * @returns {boolean}
   */
  private isShowTime(args?: any) {
    if (!args) {
      return false;
    } else if (args.hasOwnProperty('showTime')) {
      return args.showTime;
    } else {
      return false;
    }
  }
}
