<ng-container>
  <div class="border">

    <div class="row error-panel-row">

      <div class="col-md-1 error-image">
        <img src="/assets/images/error-banner.svg" class="error-svg">
      </div>
      <div class="col-md-10">
        <h6>Products with errors ({{totalNumRecords}})</h6>
        <p>The products below have data errors that need addressing. Click “Download products,” correct all errors, and re-upload that spreadsheet. The download will also include the errors listed below.</p>
        <button class="download-btn btn-link download-button" (click)="onClickDownloadErrors()">
          <img src="/assets/images/download-blue.svg" class="download-svg">
          Download products
        </button>
      </div>

      <div class="col-md-1" class="caret">
        <button *ngIf="isDownCaret" type="image" id="errorPanelDownCaret" class="button1" (click)="onClickDownCaret()">
          <img src="/assets/images/expand-more.svg" class="caret-svg">
        </button>

        <button *ngIf="!isDownCaret" type="image" id="errorPanelUpCaret" class="button1" (click)="onClickUpCaret()">
          <img src="/assets/images/expand-less.svg" class="caret-svg">
        </button>
      </div>
    </div>

    <div *ngIf="!isDownCaret" class="border-top error-panel error-table">
      <p-table [columns]="errorHeaderColumns"
               [resizableColumns]="true" [autoLayout]="true"
               [paginator]="isPaginatorNeeded()"
               [rows]="pageSize"
               [totalRecords]="totalNumRecords"
               [value]="errorCandidates">

        <ng-template pTemplate="header" let-errorHeaderColumns>
          <tr>
            <th *ngFor="let col of errorHeaderColumns" [pSortableColumn]="col.field"
                [pSortableColumnDisabled]="!col.sortable" [ngStyle]="{'width' : col.width}">
              {{col.header}}
              <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-errorCandidate >
          <tr class="table-tr">

            <td *ngIf="errorCandidate?.upc">{{errorCandidate?.upc}}</td>
            <td *ngIf="!errorCandidate?.upc && !errorCandidate.isAllCandidatesError">Row {{errorCandidate?.rowNumber}}</td>
            <td *ngIf="errorCandidate?.isAllCandidatesError">All</td>
            <td>
              <div *ngFor="let item of errorCandidate?.displayNameToErrorMap | keyvalue">
                <b class="error-text">{{item.key}}</b> - {{item.value}}
              </div>
              <div *ngIf="!(errorCandidate?.displayNameToErrorMap | keyvalue)?.length">
                -
              </div>
            </td>

          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-container>
