/**
 * Represents names of a work flow task, and a means to sort them (as defined by the business).
 */
export enum TaskName {
  REVISE_CANDIDATE = 'Revise Candidate',
  ENTER_CANDIDATE_DETAILS = 'Enter Candidate Details',
  ENTER_WAREHOUSE_DETAILS = 'Enter Warehouse Details',
  CONDUCT_FINAL_REVIEW = 'Conduct Final Review',
  NEW_ASSOCIATE_UPC = 'New Associate UPC',
}

export namespace TaskName {
  function getSort(status: TaskName): number {
    switch (status) {
      case TaskName.REVISE_CANDIDATE:
        return 0;
      case TaskName.CONDUCT_FINAL_REVIEW:
        return 1;
      case TaskName.ENTER_CANDIDATE_DETAILS:
        return 2;
      case TaskName.ENTER_WAREHOUSE_DETAILS:
        return 3;
      case TaskName.NEW_ASSOCIATE_UPC:
        return 4;
      default:
        return 5;
    }
  }
  export function compare(first: TaskName, second: TaskName): number {
    const difference = getSort(first) - getSort(second);
    return difference > 0 ? 1 : difference < 0 ? -1 : 0;
  }
}
