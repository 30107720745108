import {Component, OnInit} from '@angular/core';
import {UPCInputState} from 'pm-components';
import {
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {AssociateUpcService} from '../../service/associate-upc.service';
// tslint:disable-next-line:max-line-length
import {AssociateUpcStepperComponent} from '../../shared/components/associate-upc-stepper/associate-upc-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-setup-associate-upc',
  templateUrl: './setup-associate-upc.component.html',
  styleUrls: ['./setup-associate-upc.component.scss']
})
export class SetupAssociateUpcComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public associateUpcService: AssociateUpcService,
              private candidateService: CandidateService, private growlService: GrowlService, private lookupService: LookupService,
              private candidateUtilService: CandidateUtilService) { }
  public upcType;
  public upcState: UPCInputState;
  public upcOptions = [{ label: 'UPC', value: 'UPC' }, { label: 'Item Code', value: 'ITMCD' }];
  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public isViewingPage = true;
  private currentCandidateProductIndex = 0;
  public searchedCode: number;
  public canNavigate = true;

  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.associateUpcService.getTaskId(),
        this.associateUpcService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.associateUpcService.resetService();
        this.associateUpcService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          if (candidate.candidateType === Candidate.ASSOCIATE_UPC) {
            this.associateUpcService.setOriginalAndCurrentCandidate(candidate);
            this.candidateError = this.associateUpcService.getSetupAssociateUpcError();
            this.candidateProductError = new CandidateProductError();
            this.setInitialUpcType();
            this.setSearchedCode();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else if (this.associateUpcService.getOriginalCandidate() && this.associateUpcService.getCandidate() && !taskId
        && !this.associateUpcService.getCandidate().candidateId) {
        this.initializeData();
      }  else {
        // if there's a task id and current candidates, get the task by candidate id. If the tasks IDs don't match, reset everything.
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.associateUpcService.getCandidate()).subscribe((isValid) => {
          if (isValid) {
            this.initializeData();
          } else {
            window.history.replaceState({}, 'Title', '#/setupAssociateUpc');
            this.associateUpcService.resetService();
            this.associateUpcService.setupNewCandidate();
            this.initializeData();
          }
        });
      }
    });
  }

  warehouseSupplierConfiguration: AttributeTypeaheadConfig = {
    label: 'Warehouse supplier',
    description: 'Where the product is shipped from (origin).',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    idRef: 'id',
    searchUrl: '/lookup/lane',
    displayRef: 'name',
    name: 'warehouseId',
    placeholderText: 'Select a warehouse...',
    showAddlInfo: true
  };

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    return this.associateUpcService.getCandidate().candidateProducts[this.currentCandidateProductIndex];
  }

  hasError() {
    return this.candidateProductError && (this.candidateProductError.upc || this.candidateProductError.itemCode);
  }

  getError() {
    if (!this.hasError()) {
      return;
    }
    if (this.isItemCode()) {
      return this.candidateProductError.itemCode;
    } else if (this.isUPC()) {
      return this.candidateProductError.upc;
    }
  }

  validateUPC() {
    const validators = [];
    if (this.isItemCode()) {
      validators.push(CandidateValidatorType.EXISTING_ITEM_CODE_VALIDATOR);
    } else if (this.isUPC()) {
      validators.push(CandidateValidatorType.EXISTING_UPC_VALIDATOR);
    }
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.getCurrentCandidateProduct().id;
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), validators).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.upc = undefined;
      this.candidateProductError.upcCheckDigit = undefined;
      this.candidateProductError.itemCode = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.candidateProductError.upc = returnedCandidateProductError.upc;
        this.candidateProductError.upcCheckDigit = returnedCandidateProductError.upcCheckDigit;
        this.candidateProductError.itemCode = returnedCandidateProductError.itemCode;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  onClickNext() {
    this.jumpToPage('/associateBasicItemSetup');
  }

  onClickBack() {
    this.router.navigate(['/setupCandidate']);
  }

  onClose() {
    this.isViewingPage = false;
    if (this.associateUpcService.getTaskId()) {
      this.associateUpcService.setCandidate(this.associateUpcService.getCandidate());
      this.associateUpcService.saveCandidateAndNavigate('/tasks', this.hasVendorChanged());
    } else {
      if (this.associateUpcService.getCandidate().description) {
        this.associateUpcService.createCandidateAndNavigate(this.associateUpcService.getCandidate(), '/tasks', this.hasVendorChanged());
      }
    }
    this.router.navigate(['/tasks']);
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    return JSON.stringify(this.associateUpcService.getOriginalCandidate().vendor)
      !== JSON.stringify(this.associateUpcService.getCandidate().vendor);
  }

  isItemCode() {
    return this.upcType === 'ITMCD';
  }

  isUPC() {
    return this.upcType === 'UPC';
  }

  //  UpcModelChange occurs on blur to handle leading 0s for UPCs. Since validation needs to also occur on blur,
  // a race condition arises with the value not always being set before it tries to validate. To circumvent this,
  // Call validation after upc/item code is set.
  setUpcItemCodeAndValidate() {
    if (this.isItemCode()) {
      this.getCurrentCandidateProduct().itemCode = +this.searchedCode;
      this.validateUPC();
    } else {
      this.getCurrentCandidateProduct().upc = +this.searchedCode;
      if (this.getCurrentCandidateProduct().upc &&
        (this.getCurrentCandidateProduct().upcCheckDigit || this.getCurrentCandidateProduct().upcCheckDigit === 0)) {
        this.validateUPC();
      } else {
        this.candidateProductError.upc = null;
        this.candidateProductError.upcCheckDigit = null;
        this.upcState = UPCInputState.none;
      }
    }

  }

  onUpcTypeChange() {
    if (this.isUPC()) {
      this.getCurrentCandidateProduct().itemCode = null;
      this.searchedCode = this.getCurrentCandidateProduct().upc;
      this.getCurrentCandidateProduct().candidateProductType = CandidateProduct.SEARCHED_UPC;
    } else if (this.isItemCode()) {
      this.getCurrentCandidateProduct().upc = null;
      this.getCurrentCandidateProduct().upcCheckDigit = null;
      this.searchedCode = this.getCurrentCandidateProduct().itemCode;
      this.getCurrentCandidateProduct().candidateProductType = CandidateProduct.SEARCHED_ITEM;
    }
  }

  setSearchedCode () {
    this.searchedCode = this.isItemCode() ? this.getCurrentCandidateProduct().itemCode :
      this.isUPC() ? this.getCurrentCandidateProduct().upc : null;
  }

  initializeData() {
    this.candidateError = this.associateUpcService.getSetupAssociateUpcError();
    this.candidateProductError = new CandidateProductError();
    this.setInitialUpcType();
    this.setSearchedCode();
  }

  setInitialUpcType() {
    if (this.getCurrentCandidateProduct().itemCode !== null && this.getCurrentCandidateProduct().itemCode !== undefined) {
      this.upcType = 'ITMCD';
    } else {
      this.upcType = 'UPC';
    }
  }

  hasUpcError() {
    return this.candidateProductError && (this.candidateProductError.upc || this.candidateProductError.itemCode);
  }

  warehouseSupplierChange($event) {
    if ($event === undefined) {
      this.associateUpcService.getCandidate().lane = null;
      this.associateUpcService.getCandidate().vendor = null;
    } else {
      this.associateUpcService.getCandidate().lane = $event;
      this.associateUpcService.getCandidate().vendor = $event.vendor;
    }
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {

      case AssociateUpcStepperComponent.ITEM_DETAILS: {
        this.onClickNext();
        break;
      }
      case AssociateUpcStepperComponent.CASE_SELECTION: {
        this.jumpToPage('/associateCaseSelection');
        break;
      }
      case AssociateUpcStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpToPage('/associateExtendedAttributes');
        break;
      }
      case AssociateUpcStepperComponent.REVIEW: {
        this.jumpToPage('/associateSupplierReview');
        break;
      }
    }
  }

  jumpToPage(urlToNavigate) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.associateUpcService.getCandidate(), [CandidateValidatorType.EXISTING_UPC_ITEM_SEARCH_PAGE_VALIDATOR]).subscribe(() => {
      this.candidateError = this.associateUpcService.setSetupAssociateUpcError(new CandidateError());
      if (this.associateUpcService.getTaskId()) {
        this.associateUpcService.saveCandidateAndNavigateTo(urlToNavigate, false).pipe(
          finalize(() => this.canNavigate = true)
        ).subscribe();
      } else {
        if (this.associateUpcService.getCandidate().description) {
          this.associateUpcService.createCandidateAndNavigate(this.associateUpcService.getCandidate(),
            urlToNavigate, this.hasVendorChanged());
        } else {
          this.canNavigate = true;
        }
      }
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.associateUpcService.setSetupAssociateUpcError(error.error.candidateErrors);
        this.candidateProductError = this.candidateError.candidateProductErrors[this.getCurrentCandidateProduct().id];
      }
      this.canNavigate = true;
    });
  }
}
