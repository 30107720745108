import { Component, OnInit } from '@angular/core';
import {WorkflowService} from '../../service/workflow.service';
import {CandidateService} from '../../service/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlService} from '../../growl/growl.service';
import {LookupService} from '../../service/lookup.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {CostService} from '../../service/cost.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {MatUtilService} from '../../service/mat-util.service';
import {Candidate, CandidateError, CandidateProductError, CandidateValidatorType, TaskDecision} from 'pm-models';
import {finalize, switchMap} from 'rxjs/operators';
import {PiaBonusStepperComponent} from '../pia-bonus-stepper/pia-bonus-stepper.component';

@Component({
  selector: 'app-pia-bonus-extended-attributes',
  templateUrl: './pia-bonus-extended-attributes.component.html',
  styleUrls: ['./pia-bonus-extended-attributes.component.scss']
})
export class PiaBonusExtendedAttributesComponent implements OnInit {

  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public bonusSizeService: BonusSizeService, public costService: CostService,
              private candidateUtilService: CandidateUtilService, private matUtils: MatUtilService) { }

  isLoadingData = true;
  canNavigate = true;
  candidateError: CandidateError;
  candidateProductError: CandidateProductError;


  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId,
        this.bonusSizeService.getTask());

      // if there's params, and it doesn't have a task id equal to the services current task id
      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.BONUS_SIZE || candidate.candidateType === Candidate.REPLACEMENT_UPC) {
              this.bonusSizeService.setOriginalAndCurrentCandidate(candidate);
              return this.bonusSizeService.setProductData();
            } else {
              return this.router.navigate(['/tasks']);
            }
          }),
          switchMap(() => this.matUtils.updateMatAttributesAndValues(this.bonusSizeService.getCandidate(),
            this.bonusSizeService.globalAttributes, this.bonusSizeService.hierarchyAttributes)),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.bonusSizeService.extendedAttributesError;
            this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return this.bonusSizeService.setProductData();
            } else {
              this.bonusSizeService.resetService();
              return this.router.navigate(['/tasks']);
            }
          }),
          switchMap(() => this.matUtils.updateMatAttributesAndValues(this.bonusSizeService.getCandidate(),
            this.bonusSizeService.globalAttributes, this.bonusSizeService.hierarchyAttributes)),
          finalize(() => {
            this.isLoadingData = false;
            this.candidateError = this.bonusSizeService.extendedAttributesError;
            this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
          })
        ).subscribe();
      }
    });
  }

  onClose() {
    this.bonusSizeService.saveCandidateAndNavigate('/tasks', false);
  }


  onClickBack() {
    this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaWarehouseUrl(), false);
  }

  onClickActivate() {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.PIA_BONUS_SIZE_DETAILS_VALIDATOR, CandidateValidatorType.PIA_BONUS_CASE_PACK_DETAILS_VALIDATOR,
        CandidateValidatorType.PIA_BONUS_SIZE_VALIDATOR, CandidateValidatorType.WAREHOUSE_VALIDATOR, CandidateValidatorType.BICEP_VALIDATOR,
        CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR]).subscribe(() => {
      this.candidateService.activateCandidate(this.bonusSizeService.currentCandidate).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE,
          TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
          'Successfully activated candidate.'
        );
      }, (error) => {
        this.growlService.addError(error);
        this.canNavigate = true;
      });
    }, (error) => {

      this.bonusSizeService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.bonusSizeService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError = this.bonusSizeService.getCurrentCandidateProductError(this.candidateError);
      } else {
        this.growlService.addError(error.message);
      }
      this.canNavigate = true;
    });
  }


  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithActionForPiaOnlyFlow(
      this.bonusSizeService.currentCandidate.vendor.apNumber, this.bonusSizeService.getTask(), action, taskDecision).subscribe(() => {
      this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then( data => {
        this.canNavigate = true;
      });
    }, (error) => {
      this.growlService.addError(error);
      this.canNavigate = true;
    });
  }



  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case PiaBonusStepperComponent.UPC_ITEM_CODE:
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaSetupUpcUrl(), false);
        break;
      case PiaBonusStepperComponent.BONUS_DETAILS:
      case PiaBonusStepperComponent.REPLACEMENT_DETAILS:
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaUpcDetailsUrl(), false);
        break;
      case PiaBonusStepperComponent.CASE_DETAILS:
        this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getPiaCaseDetailsUrl(), false);
        break;
      case PiaBonusStepperComponent.WAREHOUSE_SELECTION:
        this.onClickBack();
        break;
    }
  }
}
