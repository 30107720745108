<div class="object-manager-body">
  <div class="row justify-content-center">
    <div class="col-md-10">

      <h2 class="manager-sub-title">Previously completed candidates:</h2>

      <p-table selectionMode="single" [sortField]="'candidateId'" [sortOrder]="-1"
               [rowHover]="true"
               [columns]="taskHeaderColumns"
               [value]="tasks"
               [paginator]="showPagination()"
               [rows]="pageSize"
               [totalRecords]="totalNumRecords"
               [lazy]="lazyLoadOn"
               (onLazyLoad)="loadCandidatesLazy($event)"
               (onRowSelect)="onClickRow($event)">

        <ng-template pTemplate="header" let-taskHeaderColumns>
          <tr>
            <th *ngFor="let col of taskHeaderColumns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
              {{col.header}}
              <p-sortIcon *ngIf="col.sortable" [field]="col.field" [id]="col.header.toString()"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-task>
          <tr style="height: 16px !important;" [pSelectableRow]="task">

            <!--  ID (Candidate id)  -->
            <td>{{task?.candidateId}}</td>

            <!--   Description  -->
            <td>{{task?.candidateDescription}}</td>

            <!--   Status   -->
            <td>{{task?.displayName}}</td>

            <!--   UPC   -->
            <td>{{task?.upc}}</td>

            <!--   Supplier [AP]   -->
            <td>{{task?.supplierDisplayName}}</td>

            <td>
              <div class="overflow-ellipsis-css">
                {{getRejectedReason(task)}}
              </div>
            </td>

            <!--   Updated By   -->
            <td>{{task?.lastUpdatedBy}}</td>

            <!--   Last Updated   -->
            <td>{{task?.lastUpdatedTime | dateStandard : {showTime: true} }}</td>

          </tr>

        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="columns.length">
              No records found
            </td>
          </tr>
        </ng-template>

      </p-table>

    </div>
  </div>
</div>
