import {NgModule} from '@angular/core';
import {CardModule} from 'pm-components/lib/shared/card/card.module';
import {AttributeDisplayModule} from 'pm-components/lib/shared/attribute-display/attribute-display.module';
import {CommonModule} from '@angular/common';
import {MatHierarchyCardComponent} from 'pm-components/lib/cards/mat-hierarchy-card/mat-hierarchy-card.component';
import {CategorySelectionModule} from 'pm-components/lib/shared/category-selection/category-selection.module';

@NgModule({
  declarations: [MatHierarchyCardComponent],
  exports: [MatHierarchyCardComponent, CardModule, AttributeDisplayModule],
  imports: [CommonModule, CardModule, AttributeDisplayModule, CategorySelectionModule]
})
export class MatHierarchyCardModule { }
