import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';
import {CostService} from '../../../../../../src/app/2.0.0/service/cost.service';
import {EditCandidateModalService} from '../../../../../../src/app/2.0.0/service/edit-candidate-modal.service';
import {AttributeTypes} from 'pm-components';
import {
  AttributeConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  Lane,
  MaxShipCardPermissions,
  WarehouseSupplierItem
} from 'pm-models';
import {LookupService} from '../../../../../../src/app/2.0.0/service/lookup.service';
import {CandidateService} from '../../../../../../src/app/2.0.0/service/candidate.service';
import {GrowlService} from '../../../../../../src/app/2.0.0/growl/growl.service';
import {CandidateErrorUtilService} from '../../../../../../src/app/2.0.0/service/candidate-error-util.service';

@Component({
  selector: 'pm-upload-warehouse-table',
  templateUrl: './upload-warehouse-table.component.html',
  styleUrls: ['./upload-warehouse-table.component.scss']
})
export class UploadWarehouseTableComponent implements OnInit {

  @Input() candidates: Candidate[];
  @Input() pageSize: number = 50;
  @Input() totalNumRecords: number;
  @Input() candidateProductIndex;
  @Input() isBuyer: boolean = false;
  @Input() isSca: boolean = false;
  @Input() candidateIdToCandidateErrorMap: Map<number, CandidateError>;

  @Output() loadData = new EventEmitter<any>();
  @Output() candidatesChange = new EventEmitter<any>();


  maxWarehouseColumnsCount = 0;
  isUpload: boolean;
  candidateWarehouse: Candidate = undefined;
  public currentCandidateProduct: CandidateProduct;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  private currentCandidateIndex;
  public warehouseData: WarehouseSupplierItem[] = [];
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public orderRestrictions: any[];

  public allWarehouseColumns;

  singleWarehouseColumns = [
    {header: 'Warehouse', width: '200px'},
    {header: 'Order unit', width: '150px'},
    {header: 'Order restriction', width: '175px'}
  ];

  singleWarehouseColumnsForUpload = [
    {header: 'Warehouse Supplier', width: '400px'},
    {header: 'Warehouse', width: '200px'},
    {header: 'Order unit', width: '150px'},
    {header: 'Order restriction', width: '175px'}
  ];

  readonly maxShipCardPermissions: MaxShipCardPermissions = {
    isReadOnly: false
  };

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  orderUnitConfiguration: AttributeConfig = {
    label: 'Order unit',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    inputGroupClass: 'attribute-radios-row',
    options: [
      { label: 'Case', value: this.CASE_ID },
      { label: 'Each', value: this.EACH_ID},
    ]
  };

  constructor(private candidateUtils: CandidateUtilService, private costService: CostService,
              private editCandidateModalService: EditCandidateModalService, private candidateService: CandidateService,
              public growlService: GrowlService, private lookupService: LookupService, public candidateErrorUtils: CandidateErrorUtilService) { }



  ngOnInit() {
    this.initializeWarehouseColumns();
  }

  /**
   * Finds and sets the max number of warehouses that a candidate has in the candidate lists.
   * Adds all the singleWarehouseColumns for each of the max number of warehouses.
   */
  initializeWarehouseColumns() {
    this.allWarehouseColumns = this.getBaseWarehouseColumns();

    if (!this.candidates || this.candidates.length === 0) {
      return;
    }
    if (this.candidates[0].candidateType === Candidate.NEW_PRODUCT_UPLOAD) {
      this.isUpload = true;
      this.initializeWarehouseColumnsForUpload();
    } else {
      this.isUpload = false;
      this.initializeWarehouseColumnsForNonUpload();
    }
    this.getOrderRestrictions();
  }

  /**
   * Initializes warehouse columns for upload candidate. This structure accounts for multiple warehouse suppliers, each with warehouses.
   */
  initializeWarehouseColumnsForUpload() {
    if (this.candidateProductIndex === undefined || this.candidateProductIndex === null) {
      this.candidateProductIndex = 0;
    }

    this.maxWarehouseColumnsCount = this.getMaxCountForUpload();
    // Add all of the warehouse columns for each of the max counts.
    for (let x = 0; x < this.maxWarehouseColumnsCount; x++) {
      for (let y = 0; y < this.singleWarehouseColumnsForUpload.length; y++) {
        this.allWarehouseColumns.push(this.singleWarehouseColumnsForUpload[y]);
      }
    }
  }

  getBaseWarehouseColumns(): any[] {
    return [
      {header: ' ', width: '50px', sticky: true},
      {header: 'Unit UPC', width: '150px', sticky: true},
      {header: 'Max ship', width: '150px'}
    ];
  }

  getMaxCountForNonUpload(): number {
    let currentMaxCount = 0;
    for (let x = 0; x < this.candidates.length; x++) {
      if (this.getMaxCountForNonUploadCandidate(this.candidates[x]) > currentMaxCount) {
        currentMaxCount = this.candidates[x].candidateProducts[this.candidateProductIndex].warehouses.length;
      }
    }
    return currentMaxCount ? currentMaxCount : 1;  // always return at least 1 column for editing purposes.
  }

  getMaxCountForUploadCandidate(candidate: Candidate): number {
    let currentCandidateMaxCount = 0;
    if (candidate?.candidateProducts[this.candidateProductIndex]?.warehouseSupplierItems) {
      for (let y = 0; y < candidate.candidateProducts[this.candidateProductIndex].warehouseSupplierItems.length; y++) {
        if (candidate.candidateProducts[this.candidateProductIndex].warehouseSupplierItems[y].warehouses) {
          currentCandidateMaxCount += candidate.candidateProducts[this.candidateProductIndex].warehouseSupplierItems[y].warehouses.length;
        }
      }
    }
    return currentCandidateMaxCount;
  }

  getMaxCountForNonUploadCandidate(candidate: Candidate): number {
    return candidate?.candidateProducts[this.candidateProductIndex]?.warehouses?.length ?
      candidate?.candidateProducts[this.candidateProductIndex]?.warehouses?.length : 0;
  }

  getMaxCountForUpload(): number {
    let currentMaxCount = 0;
    // Find the max number of warehouses that a candidate has in candidate list
    for (let x = 0; x < this.candidates.length; x++) {
      const currentCandidateMaxCount = this.getMaxCountForUploadCandidate(this.candidates[x]);
      if (currentCandidateMaxCount > currentMaxCount) {
        currentMaxCount = currentCandidateMaxCount;
      }
    }
    return currentMaxCount ? currentMaxCount : 1;  // always return at least 1 column for editing purposes.
  }


  /**
   * Initializes the warehouse columns for non upload candidate.
   */
  initializeWarehouseColumnsForNonUpload() {
    if (this.candidateProductIndex === undefined || this.candidateProductIndex === null) {
      this.candidateProductIndex = 0;
    }
    // Find the max number of warehouses that a candidate has in candidate list
    this.maxWarehouseColumnsCount = this.getMaxCountForNonUpload();

    // Add all of the warehouse columns for each of the max counts.
    for (let x = 0; x < this.maxWarehouseColumnsCount; x++) {
      for (let y = 0; y < this.singleWarehouseColumns.length; y++) {
        this.allWarehouseColumns.push(this.singleWarehouseColumns[y]);
      }
    }
  }

  editProductUPC(index) {
    if (!this.isBuyer && !this.isSca) {
      this.showEditCandidateModal(index, AttributeTypes.ProductUPC, {
        validationService: this.candidateService
      });
    }
  }

  showEditCandidateModal(index, type: AttributeTypes,  overrides?: any) {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidates[index]));
    this.editCandidateModalService.openModal(type, tempCandidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidates[index] = response;
      }
    });
  }

  /**
   * Returns the warehouse supplier based off of the index of the warehouse number. If the index is higher than the count of warehouses,
   * return null (ui will handle display of '-' to denote there's no more warehouses suppliers to display for the current candidate).
   * @param candidate
   * @param index
   */
  getWarehouseSupplier(candidate: Candidate, index): WarehouseSupplierItem {
    if (!candidate.candidateProducts[this.candidateProductIndex].warehouseSupplierItems) {
      return;
    }
    let count = 0;
    for (let x = 0; x < candidate.candidateProducts[this.candidateProductIndex].warehouseSupplierItems.length; x++) {
      const warehouseSupplierItem = candidate.candidateProducts[this.candidateProductIndex].warehouseSupplierItems[x];
      if (!warehouseSupplierItem.warehouses || warehouseSupplierItem.warehouses.length === 0) {
        continue;
      }
      for (let y = 0; y < warehouseSupplierItem.warehouses.length; y++) {
        if (index === count) {
          return warehouseSupplierItem;
        } else {
          count++;
        }
      }
    }
    return null;
  }

  /**
   * Returns the warehouse based off of the index of the warehouse number. If the index is higher than the count of warehouses,
   * return null (ui will handle display of '-' to denote there's no more warehouses to display for the current candidate).
   * @param candidate
   * @param index
   */
  getWarehouse(candidate: Candidate, index) {
    if (this.isUpload) {
      let count = 0;
      for (let x = 0; x < candidate.candidateProducts[this.candidateProductIndex].warehouseSupplierItems.length; x++) {
        const warehouseSupplierItem = candidate.candidateProducts[this.candidateProductIndex].warehouseSupplierItems[x];
        if (!warehouseSupplierItem.warehouses || warehouseSupplierItem.warehouses.length === 0) {
          continue;
        }
        for (let y = 0; y < warehouseSupplierItem.warehouses.length; y++) {
          if (index === count) {
            return warehouseSupplierItem.warehouses[y];
          } else {
            count++;
          }
        }
      }
    } else {
      if (candidate.candidateProducts[this.candidateProductIndex].warehouses &&
        candidate.candidateProducts[this.candidateProductIndex].warehouses.length >= index + 1) {
        return candidate.candidateProducts[this.candidateProductIndex].warehouses[index];
      }
    }
    return null;
  }

  get attributeType() {
    return AttributeTypes;
  }

  /**
   * Returns the column group length.
   */
  getWarehouseColumnGroupLength() {
    if (this.isUpload) {
      return this.singleWarehouseColumnsForUpload.length;
    } else {
      return this.singleWarehouseColumns.length;
    }
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  showWarehousesPanel(event, panel, target, index, candidate) {
    event.stopPropagation();

    this.warehouseData = [];
    this.candidateWarehouse = candidate;
    this.currentCandidateIndex = index;
    // we are only dealing with New Product currently
    this.currentCandidateProduct = this.candidateWarehouse.candidateProducts[this.candidateProductIndex];
    this.getWarehouses(this.candidateWarehouse);

    panel.show(event, target);
  }

  closeWarehousesPanel(event, panel) {
    this.warehouseData = [];
    event.stopPropagation();
    panel.hide();
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findVendor(candidate.vendor.apNumber).subscribe(data => {
      this.setWarehouseDataFromLanes(data[0].lanes).then();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  async setWarehouseDataFromLanes(allLanes: Lane[]) {
    if (this.candidateWarehouse.candidateType === Candidate.NEW_PRODUCT_UPLOAD) {
      this.setWarehouseDataForUpload(allLanes);
    } else {
      this.setWarehouseDataForNonUpload(allLanes);
    }
    this.setOrderUnitConfiguration();
  }

  setWarehouseDataForUpload(allLanes: Lane[]) {
    let currentlySelectedWarehouse: any;
    let warehouseCopy: any;
    for (let x = 0; x < allLanes.length; x++) {
      let hasSetLaneData = false;
      for (let y = 0; y < this.currentCandidateProduct.warehouseSupplierItems.length; y++) {
        // only check for the same whses on the same biceps.
        if (allLanes[x].id !== this.currentCandidateProduct.warehouseSupplierItems[y].id) {
          continue;
        }
        const warehouses = [];
        for (const warehouse of allLanes[x].warehouses) {
          currentlySelectedWarehouse =
            this.currentCandidateProduct.warehouseSupplierItems[y].warehouses.find(whse => whse.warehouseId === warehouse.warehouseId);
          if (currentlySelectedWarehouse) {
            warehouseCopy = JSON.parse(JSON.stringify(currentlySelectedWarehouse));
            warehouseCopy.checked = true;
            warehouses.push(warehouseCopy);
          } else {
            warehouses.push(JSON.parse(JSON.stringify(warehouse)));
          }
        }
        this.warehouseData.push({id: allLanes[x].id, lane: allLanes[x], laneAuditDisplayName: allLanes[x].name, warehouses: warehouses});
        hasSetLaneData = true;
      }
      // if the bicep isn't in the candidate, add it to the list of bicep options.
      if (!hasSetLaneData) {
        this.warehouseData.push({id: allLanes[x].id, lane: allLanes[x], laneAuditDisplayName: allLanes[x].name, warehouses: allLanes[x].warehouses});
      }
    }
  }

  setWarehouseDataForNonUpload(allLanes: Lane[]) {
    if (allLanes && this.candidateWarehouse?.lane) {
      for (let x = 0; x < allLanes.length; x++) {
        if (allLanes[x].id === this.candidateWarehouse?.lane.id) {
          const warehouses = [];
          for (const warehouse of allLanes[x].warehouses) {
            const currentlySelectedWarehouse =
              this.currentCandidateProduct.warehouses.find(whse => whse.warehouseId === warehouse.warehouseId);
            if (currentlySelectedWarehouse) {
              const warehouseCopy = JSON.parse(JSON.stringify(currentlySelectedWarehouse));
              warehouseCopy.checked = true;
              warehouses.push(warehouseCopy);
            } else {
              warehouses.push(JSON.parse(JSON.stringify(warehouse)));
            }
          }
          this.warehouseData.push({id: allLanes[x].id, lane: allLanes[x], laneAuditDisplayName: allLanes[x].name, warehouses: warehouses});
          break;
        }
      }
    }
  }

  setOrderUnitConfiguration() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      for (let y = 0; y < this.warehouseData[x].warehouses.length; y++) {
        if (!this.warehouseData[x].warehouses[y].orderRestriction) {
          this.warehouseData[x].warehouses[y].orderRestriction = this.getDefaultOrderRestriction();
          this.warehouseData[x].warehouses[y].orderUnitId = this.CASE_ID;
        }
        if (!this.warehouseData[x].warehouses[y].orderUnitId) {
          this.warehouseData[x].warehouses[y].orderUnitId = this.warehouseData[x].warehouses[y].orderUnit.id;
        }
      }
    }
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE', displayName: 'CASE[C]'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH', displayName: 'EACH[E]'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  getSelectedWarehouseSuppliers() {
    const tempWarehouseSupplierList = [];
    let currentWarehouseSupplier: any;

    for (let x = 0; x < this.warehouseData.length; x++) {
      currentWarehouseSupplier = tempWarehouseSupplierList.find(warehouseSupplier => warehouseSupplier.id === this.warehouseData[x].id);
      if (!currentWarehouseSupplier) {
        currentWarehouseSupplier = new WarehouseSupplierItem();
        currentWarehouseSupplier.id = this.warehouseData[x].id;
        currentWarehouseSupplier.laneAuditDisplayName = this.warehouseData[x].laneAuditDisplayName;
        currentWarehouseSupplier.lane = this.warehouseData[x].lane;
        currentWarehouseSupplier.warehouses = [];

        // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
        currentWarehouseSupplier = Object.assign({}, currentWarehouseSupplier, JSON.parse(JSON.stringify(currentWarehouseSupplier)));
      }

      for (let y = 0; y < this.warehouseData[x].warehouses.length; y++) {
        if (this.warehouseData[x].warehouses[y].checked) {
          currentWarehouseSupplier.warehouses.push(this.warehouseData[x].warehouses[y]);
        }
      }

      if (currentWarehouseSupplier.warehouses?.length) {
        tempWarehouseSupplierList.push(currentWarehouseSupplier);
      }
    }
    return tempWarehouseSupplierList;
  }

  updateWarehouses(event, panel) {
    if (this.candidates[0].candidateType === Candidate.NEW_PRODUCT_UPLOAD) {
      this.candidateWarehouse.candidateProducts[this.candidateProductIndex].warehouseSupplierItems = this.getSelectedWarehouseSuppliers();
      this.candidates[this.currentCandidateIndex].candidateProducts[this.candidateProductIndex].warehouseSupplierItems =
        this.candidateWarehouse.candidateProducts[this.candidateProductIndex].warehouseSupplierItems;
    } else {
      const warehouseSupplierData = this.getSelectedWarehouseSuppliers();
      if (warehouseSupplierData && warehouseSupplierData.length) {
        this.candidateWarehouse.candidateProducts[this.candidateProductIndex].warehouses = warehouseSupplierData[0].warehouses;
        this.candidates[this.currentCandidateIndex].candidateProducts[this.candidateProductIndex].warehouses = warehouseSupplierData[0].warehouses;
      }
    }
    this.updateWarehouseColumns(this.candidates[this.currentCandidateIndex]);

    this.closeWarehousesPanel(event, panel);
  }

  updateWarehouseColumns(candidate: Candidate) {
    let currentMaxCount;
    let warehouseColumns;
    if (this.candidates[0].candidateType === Candidate.NEW_PRODUCT_UPLOAD) {
      currentMaxCount = this.getMaxCountForUploadCandidate(candidate);
      warehouseColumns = this.singleWarehouseColumnsForUpload;
    } else {
      currentMaxCount = this.getMaxCountForNonUploadCandidate(candidate);
      warehouseColumns = this.singleWarehouseColumns;
    }

    if (currentMaxCount > this.maxWarehouseColumnsCount) {
      const newWarehousesCount = currentMaxCount - this.maxWarehouseColumnsCount;
      this.maxWarehouseColumnsCount = currentMaxCount;

      for (let x = 0; x < newWarehousesCount; x++) {
        for (let y = 0; y < warehouseColumns.length; y++) {
          this.allWarehouseColumns.push(warehouseColumns[y]);
        }
      }
    } else if (currentMaxCount < this.maxWarehouseColumnsCount) {
      this.initializeWarehouseColumns();
    }
  }

  onClickLaneCaret(lane) {
    lane.isViewing = !lane.isViewing;
  }

  isLaneViewing(lane) {
    if (lane.isViewing === undefined) {
      lane.isViewing = true;
    }
    return lane.isViewing;
  }
}
