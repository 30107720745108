import {WorkFlowEntity} from './workFlowEntity';

export class HistoricProcessInstance extends WorkFlowEntity {
  // variables related to Workflow Process Instance
  endActivityId: string;
  endTime: number;
  processDefinitionId: string;
  processInstanceId: string;
  startTime: number;
}
