<pm-review (closeEvent)="onClose()">

  <div class="row" style="margin-top: 25px;">
    <div class="col-md-11">
      <div class="row">
        <h1 class="page-title">Review upload</h1>
      </div>
      <div *ngIf="uploadedCandidateErrorModels && uploadedCandidateErrorModels.length > 0" class="row">
        <pm-error-panel [errorCandidates]="uploadedCandidateErrorModels"
                        [pageSize]="10" (downloadClicked)="onClickDownloadErrors()">
        </pm-error-panel>
      </div>
    </div>
  </div>
  <div *ngIf="candidates && candidates.length > 0" class="row" style="margin-top: 25px;">
    <div class="col-md-11">
      <div class="row pl-0">
        <div class="col-md-6 pl-0">
          <h3>Uploaded products ({{candidates.length}})</h3>
        </div>
        <div class="col-md-6 mr-0 supplier-settings-button" (click)="showSupplierSettingsPanel($event, supplierSettingsOverlay, supplierSettingsDiv)">
          <label class="supplier-settings-label">Supplier settings</label>
          <img class="supplier-settings-image" src="../../../../assets/images/blue-gear-icon.svg">
        </div>
      </div>
      <div class="row">
        <label>{{getUploadSuccessfulString()}}</label>
      </div>
    </div>
  </div>

  <div *ngIf="candidates && candidates.length > 0" class="row ml-0">
    <div>
      <div class="row mr-0">
        <pm-upload-candidate-table [candidateType]="candidateType"
                                   [isDsd]="parentCandidate.dsdSwitch"
                                   [isWarehouse]="parentCandidate.warehouseSwitch"
                                   [candidates]="candidates"
                                   [candidateProductIndex]="candidateProductIndex"
                                   [uuidToImageMap]="uuidToImageMap"
                                   [candidateIdToCandidateErrorMap]="candidateIdToCandidateErrorMap"
                                   [hasProductDetailErrors]="hasProductDetailErrors"
                                   [hasCaseDetailErrors]="hasCaseDetailErrors"
                                   [hasSupplierDetailErrors]="hasSupplierDetailErrors"
                                   [hasWarehouseDetailErrors]="hasWarehouseDetailErrors"
                                   [hasDsdErrors]="hasDsdErrors">
        </pm-upload-candidate-table>
      </div>
    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClose()"></p-button>
    <p-button class="m-2" label="Activate" (onClick)="onClickActivate()" [disabled]="!candidates || candidates.length === 0 || isActivateButtonDisabled"></p-button>
  </pm-footer>
</pm-review>

<div>
  <div #supplierSettingsDiv></div>
  <pm-static-overlay-panel [hideTransitionOptions]="'0ms ease-in'" [showTransitionOptions]="'0ms ease-out'"
                           #supplierSettingsOverlay appendTo="body" [style]="{width: '100%', height: '100%'}">
    <pm-upload-settings-configuration *ngIf="isViewingSupplierSettingsPanel" [vendor]="vendor"
                                      (cancelClicked)="hideSupplierSettingsPanel(supplierSettingsOverlay)"
                                      (saveButtonClicked)="hideSupplierSettingsPanel(supplierSettingsOverlay)">
    </pm-upload-settings-configuration>
  </pm-static-overlay-panel>
</div>
