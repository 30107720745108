import {Component, OnInit} from '@angular/core';
import {
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  HEBDetailsCardPermissions,
  SupplierDetailsCardPermissions
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {SupplierProductService} from '../../service/supplier-product.service';
import {AuthService} from '../../auth/auth.service';
import {LookupService} from '../../service/lookup.service';
import {NewWarehouseProductStepperComponent} from '../new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {NewDsdProductStepperComponent} from '../new-dsd-product-stepper/new-dsd-product-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss']
})
export class SupplierDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router,
              public supplierProductService: SupplierProductService, private candidateService: CandidateService,
              private authService: AuthService, private lookupService: LookupService,
              private candidateUtilService: CandidateUtilService) { }

  hebDetailsCardPermissions: HEBDetailsCardPermissions = {
    isReadOnly: false,
    productType: {
      isHidden: true
    }
  };

  readonly supplierDetailsCardPermissions: SupplierDetailsCardPermissions = {
    isReadOnly: false
  };

  public isViewingPage = true;
  canNavigate = true;

  // any errors for this page will be here
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;

  public commodities: any;
  public subCommodities: any;
  public merchandiseTypes: any;
  private taskSubscription$: any;

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierProductService.getTaskId(),
        this.supplierProductService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.NEW_PRODUCT) {
            this.candidateError = this.supplierProductService.getSupplierDetailsError();
            this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
            this.supplierProductService.getCandidate().contactName = this.authService.getUser();
            if (this.supplierProductService.getCandidate().buyer) {
              this.setCommoditiesAndSubCommodities(this.supplierProductService.getCandidate().buyer);
            }

            if (this.supplierProductService.getAuthGroups()) {
              this.supplierProductService.validateHasNoStores();
            } else if (this.supplierProductService.candidate.vendor &&
              this.supplierProductService.candidate.commodity) {
              this.supplierProductService.findAllAuthGroups().subscribe((authGroups) => {
                this.supplierProductService.setAuthGroups(authGroups);
                this.supplierProductService.setSelectedAuthGroups(null);
                this.supplierProductService.validateHasNoStores();
              });
            }
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierProductService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierProductService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  getCommodityConfiguration(): AttributeTypeaheadConfig {
    return {
      label: 'Commodity',
      description: 'Choose an appropriate category.',
      isRequired: this.supplierProductService.getCandidate() && this.supplierProductService.getCandidate().dsdSwitch,
      isDisabled: () => !(this.commodities && this.commodities.length > 0),
      isReadOnly: () => false,
      name: 'commodityId',
      displayRef: 'commodityName',
      placeholderText: 'Select a commodity...',
      collections: this.commodities
    };
  }
  getSubCommodityConfiguration(): AttributeTypeaheadConfig {
    return {
      label: 'Sub-commodity',
      description: 'Choose an appropriate subcategory.',
      isRequired: this.supplierProductService.getCandidate() && this.supplierProductService.getCandidate().dsdSwitch,
      isDisabled: () => !(this.subCommodities && this.subCommodities.length > 0),
      isReadOnly: () => false,
      name: 'subcommodityId',
      displayRef: 'displayName',
      placeholderText: 'Select a sub-commodity...',
      collections: this.subCommodities
    };
  }

  initializeData() {
    this.candidateError = this.supplierProductService.getSupplierDetailsError();
    this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
    this.supplierProductService.getCandidate().contactName = this.authService.getUser();
    if (this.supplierProductService.getCandidate().buyer) {
      this.setCommoditiesAndSubCommodities(this.supplierProductService.getCandidate().buyer);
    }

    if (!this.supplierProductService.getAuthGroups()) {
      if (this.supplierProductService.candidate.vendor && this.supplierProductService.candidate.commodity) {
        this.supplierProductService.findAllAuthGroups().subscribe((authGroups) => {
          this.supplierProductService.setAuthGroups(authGroups);
          this.supplierProductService.setSelectedAuthGroups(null);
          this.supplierProductService.validateHasNoStores();
        });
      }
    } else {
      this.supplierProductService.validateHasNoStores();
    }
    if (this.supplierProductService.getOriginalCandidate().productType) {
      this.findMerchandiseTypesAndSetDefault();
    }
  }

  onClickNext() {
    this.canNavigate = false;
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      [CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR]).toPromise().then(data => {
      if (this.supplierProductService.validateHasNoStores()) {
        const error = new CandidateError();
        error.noStoresError = true;
        this.candidateError = this.supplierProductService.setSupplierDetailsError(error);
      } else {
        this.candidateError = this.supplierProductService.setSupplierDetailsError(new CandidateError());
      }
      this.supplierProductService.saveCandidateAndNavigate('/basicItemSetup', this.hasVendorChanged()).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        const errors = error.error.candidateErrors;
        if (this.supplierProductService.validateHasNoStores()) {
          errors.noStoresError = true;
        }
        this.candidateError = this.supplierProductService.setSupplierDetailsError(errors);
      }
      this.supplierProductService.saveCandidateAndNavigate('/basicItemSetup', this.hasVendorChanged()).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    });
  }

  onClickBack() {
    this.jumpTo('/setupCandidateType', [CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR]);
  }

  onClose() {
    this.isViewingPage = false;
    this.supplierProductService.saveCandidateAndClose();
  }

  onBuyerChange(event) {
    this.supplierProductService.updateBuyerCallBack(event);
    this.supplierProductService.getCandidate().commodity = null;
    this.supplierProductService.getCandidate().subCommodity = null;
    this.supplierProductService.getCandidate().pssDepartment = null;
    if (event && event.buyerId) {
      this.setCommoditiesAndSubCommodities(event);
    }
    this.supplierProductService.setAuthGroups(null);
    this.supplierProductService.setSelectedAuthGroups(null);
    this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
  }

  onSupplierChange(event) {
    if (!this.supplierProductService.isDsdOnly()) {
      return;
    }
    if (this.supplierProductService.candidate.vendor && this.supplierProductService.candidate.commodity) {
      this.supplierProductService.findAllAuthGroups().subscribe((authGroups) => {
        this.supplierProductService.setAuthGroups(authGroups);
        this.supplierProductService.setSelectedAuthGroups(null);
        this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
        this.supplierProductService.validateHasNoStores();
      });
    } else {
      this.supplierProductService.setAuthGroups(null);
      this.supplierProductService.setSelectedAuthGroups(null);
      this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
    }
  }

  onMerchandiseTypeChange(event) {
  }

  /**
   * Determines if the new candidate has a different vendor from the original
   * @returns {boolean}
   */
  hasVendorChanged() {
    if (this.supplierProductService.getCandidate() && this.supplierProductService.getOriginalCandidate()) {
      return JSON.stringify(this.supplierProductService.getOriginalCandidate().vendor) !==
        JSON.stringify(this.supplierProductService.getCandidate().vendor);
    }
    return false;
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyer the buyer.
   */
  private setCommoditiesAndSubCommodities(buyer) {
    if (!buyer || !buyer.buyerId) {
      this.commodities = [];
      this.subCommodities = [];
    }
    this.lookupService.findAllCommoditiesByBuyerId(buyer.buyerId).subscribe( data => {
      this.commodities = data;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
      }
    });
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.supplierProductService.getCandidate().commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.supplierProductService.getCandidate().commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
  }

  onCommodityChange(event) {
    this.supplierProductService.getCandidate().commodity = event;
    this.supplierProductService.getCandidate().subCommodity = null;

    if (this.supplierProductService.candidate.vendor && this.supplierProductService.candidate.commodity) {
      this.supplierProductService.findAllAuthGroups().subscribe((authGroups) => {
        this.supplierProductService.setAuthGroups(authGroups);
        this.supplierProductService.setSelectedAuthGroups(null);
        this.supplierProductService.validateHasNoStores();
        this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
      });
    } else {
      this.supplierProductService.setAuthGroups(null);
      this.supplierProductService.setSelectedAuthGroups(null);
      this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores = [];
    }
    this.getSubCommodities(event);
  }

  onSubCommodityChange(event) {
    this.supplierProductService.getCandidate().subCommodity = event;

    if (event) {
      this.supplierProductService.getCandidate().foodStamp = CandidateHelper.isFoodStamp(this.supplierProductService.getCandidate());
      this.supplierProductService.getCandidate().taxable = CandidateHelper.isTaxCode(this.supplierProductService.getCandidate());
    }
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case NewWarehouseProductStepperComponent.UPC_ITEM_CODE:
        this.jumpTo('/setupCandidateType', SupplierProductService.BASE_VALIDATORS);
        break;
      case NewWarehouseProductStepperComponent.PRODUCT_DETAILS:
        this.onClickNext();
        break;
      case NewWarehouseProductStepperComponent.CASE_PACK:
        this.jumpTo('/casePack', SupplierProductService.BASE_VALIDATORS);
        break;
      case NewDsdProductStepperComponent.STORE_AUTHORIZATION:
        if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          this.jumpTo('/storeAuthorization', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpTo('/setUpStores', SupplierProductService.DSD_VALIDATORS);
        }
        break;
      case NewWarehouseProductStepperComponent.EXTENDED_ATTRIBUTES:
        if (this.supplierProductService.isDsdOnly()) {
          this.jumpTo('/extendedAttributes', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpTo('/extendedAttributes', SupplierProductService.BASE_VALIDATORS);
        }
        break;
      case NewWarehouseProductStepperComponent.REVIEW_PAGE:
        if (this.supplierProductService.isDsdOnly()) {
          this.jumpTo('/newProductReviewPageComponent', SupplierProductService.DSD_VALIDATORS);
        } else {
          this.jumpTo('/newProductReviewPageComponent', SupplierProductService.BASE_VALIDATORS);
        }
        break;
    }
  }

  jumpTo(urlToNavigate, validator) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      validator).toPromise().then(data => {
      this.candidateError = this.supplierProductService.setSupplierDetailsError(new CandidateError());
      this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
      this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, this.hasVendorChanged()).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }, (error) => {
      if (error.error.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierProductService.setSupplierDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
        if (!this.supplierProductService.isDsdOnly() || !SupplierProductService.DSD_NON_NAVIGABLE_ON_ERROR_PAGES.includes(urlToNavigate)) {
          this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).pipe(
            finalize(() => this.canNavigate = true)
          ).subscribe();
        } else {
          this.canNavigate = true;
        }
      } else {
        this.canNavigate = true;
      }
    });
  }

  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.supplierProductService.getOriginalCandidate().productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.supplierProductService.getOriginalCandidate());
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
    });
  }
}
