import {Candidate} from 'pm-models';

export class CandidateInner {

  candidateId?: number;

  candidate?: Candidate;

  replenishable?: Boolean;

  unitCost?: number;

  quantity?: number;

  vendorEdited?: Boolean;

  valid?: Boolean;

  draft?: Boolean;

  isPiaInitiated?: Boolean;

}
