import {Component, OnInit, ViewChild} from '@angular/core';
import {CandidateService} from '../service/candidate.service';
import {AttributeConfig, AttributeTextInputConfig, Candidate, CandidateError, CandidateValidatorType, Task, TaskDecision, TextInputType} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';
import {LookupService} from '../service/lookup.service';
import {ProductService} from '../service/product.service';
import {GrowlService} from '../growl/growl.service';
import {WorkflowService} from '../service/workflow.service';
import {CostService} from '../service/cost.service';
import {SupplierProductService} from '../service/supplier-product.service';
import {SetUpStoresOverlayComponent} from '../set-up-stores-overlay/set-up-stores-overlay.component';
import {AuthService} from '../auth/auth.service';
import {CandidateErrorUtilService} from '../service/candidate-error-util.service';
import {forkJoin} from 'rxjs';
import {InvitedDistributorService} from '../service/invited-distributor.service';
import {AttributeTypes, InputState, ReviewComponent} from 'pm-components';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {CandidateUtilService} from '../service/candidate-util.service';

@Component({
  selector: 'app-buyer-invited-distributors-review',
  templateUrl: './buyer-invited-distributors-review.component.html',
  styleUrls: ['./buyer-invited-distributors-review.component.scss']
})
export class BuyerInvitedDistributorsReviewComponent implements OnInit {

  @ViewChild(SetUpStoresOverlayComponent) setupStoreOverlay: SetUpStoresOverlayComponent;

  @ViewChild(ReviewComponent) pmReview;

  public BUYER_TASK_NAME = 'Buyer Review Dsd Invited Suppliers';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  private DSD_INVITED_SUPPLIER_WORKFLOW = 'dsdInvitedSupplierProcess';

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute, public router: Router,
              public candidateService: CandidateService, private lookupService: LookupService,
              private productService: ProductService, public costService: CostService, private growlService: GrowlService,
              public supplierProductService: SupplierProductService, public editCandidateModalService: EditCandidateModalService,
              private authService: AuthService, public candidateErrorUtils: CandidateErrorUtilService,
              public invitedDistributorService: InvitedDistributorService, public candidateUtilService: CandidateUtilService) { }

  private taskSubscription$: any;
  private task: Task;
  public canClickSave: boolean = true;
  public isRejectDisabled = false;
  public isApproveDisabled = false;
  public isShowingStorePanel = false;
  public isViewingNotSubmittedCandidates = false;
  public isViewingSubmittedCandidates = false;
  public isViewingApprovedCandidates = false;
  public isViewingActivatedCandidates = false;
  public candidateErrorsMap: Map<number, any> = new Map<number, any>();
  public isPageEditable: boolean = false;
  public tempCommentHolder: string;
  public tempRushFlagHolder: boolean;
  public showSelectWarning: boolean;
  public costLinkState: InputState;

  selectedConfiguration: AttributeConfig = {
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'allGroupsId',
    defaultValue: false
  };

  rushFlagConfiguration: AttributeConfig = {
    checkboxLabel: 'Rush',
    label: 'Rush Review',
    description: 'Send this request to the top of other reviewer\'s queue.',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    labelInputGroupClass: 'bold-label',
    name: 'rushFlagId',
    defaultValue: false
  };

  buyerComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    labelInputGroupClass: 'bold-label',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'buyerCommentId',
    charLimit: 4000,
    maxLength: 4000,
  };

  ngOnInit() {
    this.isPageEditable = this.authService.isBuyer();
    this.invitedDistributorService.resetService();

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.invitedDistributorService.resetService();
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.BUYER_TASK_NAME) {
              this.router.navigate(
                ['/tasks'],
                {
                  queryParams:
                    {
                      growlMessage: 'Candidate is not in ' + this.BUYER_TASK_NAME + ' status.',
                      growlToUse: GrowlService.SEVERITY_ERROR
                    }
                }).then();
            }
            if (params.has('candidateId')) {
              this.invitedDistributorService.candidateIdsToShow = [parseInt(params.get('candidateId'), 10)];
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                if (this.invitedDistributorService.candidateIdsToShow) {
                  if (!this.invitedDistributorService.candidateIdsToShow.some(value => candidate.candidateId === value ||
                    candidate.candidateProducts[0]?.invitedSuppliers?.some(invitedSupplier => invitedSupplier.candidateId === value))) {
                    this.router.navigate(
                      ['/tasks'],
                      {
                        queryParams:
                          {
                            growlMessage: 'Candidate is not part of the parent task.',
                            growlToUse: GrowlService.SEVERITY_ERROR
                          }
                      }).then();
                  }
                }
                this.setInitialValues(candidate);
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }
  private async setInitialValues(candidate: Candidate) {
    this.invitedDistributorService.setOriginalAndCurrentCandidate(candidate);
    await this.invitedDistributorService.initializeProductData().toPromise();
    await this.invitedDistributorService.initializeActivatedDistributorData();
    await this.invitedDistributorService.createVendorToAuthGroupsMap();
    this.invitedDistributorService.candidateError = new CandidateError();
    this.invitedDistributorService.candidateProductError =
      this.invitedDistributorService.candidateError.candidateProductErrors[this.invitedDistributorService.candidate.candidateProducts[0].id];

    this.invitedDistributorService.invitedCandidates?.forEach(singleCandidate => {
      this.invitedDistributorService.getPssDepartments(singleCandidate);
      if (singleCandidate.costLinked) {
        this.validateCostLink(singleCandidate);
      }
    });

    this.candidateService.findDistributorCandidatesByUpc(this.invitedDistributorService.candidate.candidateProducts[0].upc).subscribe(
      (candidates) => {
        if (!candidates || candidates.length === 0) {
          this.growlService.addError('Unable to locate parent candidate.');
          return;
        }
        this.invitedDistributorService.allDsdCandidates = candidates;
        this.invitedDistributorService.findOtherInvitedCandidates();
      });
  }

  onClose() {
    if (this.isPageEditable) {
      this.candidateService.saveCandidate(this.invitedDistributorService.candidate, true).subscribe(() => {
        this.router.navigate(['/tasks']).then();
      });
    } else {
      this.router.navigate(['/tasks']).then();
    }
  }

  showStorePanel(event, panel, target, candidate: Candidate) {
    event.stopPropagation();
    panel.show(event, target);
    this.isShowingStorePanel = true;
    this.supplierProductService.resetService();
    this.supplierProductService.setTaskId(this.task.id);
    this.supplierProductService.setOriginalAndCurrentCandidate(candidate);
    this.setupStoreOverlay.initializeData();
  }


  removeInvitedSupplier(candidate: Candidate) {
    this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId).subscribe((task) => {
      if (task.name === 'Dsd Invited Supplier') {
        this.removeNotSubmittedCandidate(candidate, task);
      } else {
        this.growlService.addError('Cannot delete task: ' + task.name + '. Please refresh and try again.');
      }
    });
  }

  /**
   * Saves the current state of a candidate with reject reason, and completes the task.
   */
  private removeNotSubmittedCandidate(candidate: Candidate, task) {
    candidate.vendorComment = 'Declined by Buyer.';
    candidate.status = Candidate.DECLINED;
    this.candidateService.saveCandidate(candidate, true).subscribe(() => {
      this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE, TaskDecision.INVITED_DISTRIBUTOR_VENDOR_REJECT)
        .subscribe(() => {
          this.invitedDistributorService.removeNotSubmittedCandidateFromLists(candidate.candidateId,
            this.invitedDistributorService.notSubmittedCandidates, this.invitedDistributorService.allDsdCandidates);
        }, (error) => {
          this.growlService.addError(error);
        });
    }, (error) => {
      this.growlService.addError(error);
    });
  }

  async rejectAll(event, panel, target) {
    if (this.invitedDistributorService.candidateIdsToShow) {
      this.invitedDistributorService.candidatesToShow.forEach(candidateToShow => candidateToShow.selected = true);
      this.rejectSelected(event, panel, target);
      return;
    }
    this.isApproveDisabled = true;
    this.isRejectDisabled = true;
    if (this.tempCommentHolder) {
      this.invitedDistributorService.invitedCandidates = this.applyBuyerComments(this.invitedDistributorService.invitedCandidates);
    }
    let candidate;
    for (let x = 0; x < this.invitedDistributorService.invitedCandidates.length; x++) {
      candidate = this.invitedDistributorService.invitedCandidates[x];
      candidate.vendorComment = event;
      candidate.status = Candidate.DECLINED;
      // Make sure tasks are not combined anymore
      candidate.candidateProducts[0].invitedSuppliers = [];

      if (this.invitedDistributorService.candidate.candidateId === candidate.candidateId) {
        await this.candidateService.saveCandidate(candidate).toPromise();
      } else {
        await this.saveRejectedChildCandidateAndRejectWorkflow(candidate);
      }

      if (x === this.invitedDistributorService.invitedCandidates.length - 1) {

        this.workflowService.completeTaskWithAction(this.task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.INVITED_DISTRIBUTOR_BUYER_REJECT_DECISION).subscribe(() => {
          panel.hide();
          this.router.navigate(['/tasks'], { queryParams: { growlMessage: 'Successfully rejected candidate.' }
          }).then(() => {
            this.isApproveDisabled = false;
            this.isRejectDisabled = false;
            this.invitedDistributorService.isSelectingDistributors = false;
            this.resetTempRushFlagAndBuyerComments();
          });
        }, (error) => {
          this.growlService.addError(error);
          this.isRejectDisabled = false;
          this.isApproveDisabled = false;
          this.invitedDistributorService.isSelectingDistributors = false;

        });
      }
    }
  }

  async rejectSelected(event, panel, target) {

    const selectedCandidateIds = this.invitedDistributorService.getSelectedCandidateIds(this.invitedDistributorService.candidatesToShow);

    if (this.invitedDistributorService.hasAllSelectedSuppliers(this.invitedDistributorService.candidatesToShow)) {
      this.rejectAll(event, panel, target);
      return;
    }

    this.isRejectDisabled = true;
    this.isApproveDisabled = true;
    let removingParentCandidate = false;

    // remove from invited candidates list (displayed invites)
    for (let x = 0; x < selectedCandidateIds.length; x++) {
      let candidate: Candidate;
      for (let y = 0; y < this.invitedDistributorService.invitedCandidates.length; y++) {
        if (selectedCandidateIds[x] === this.invitedDistributorService.invitedCandidates[y].candidateId) {
          candidate = this.invitedDistributorService.invitedCandidates[y];
          candidate.vendorComment = event;
          candidate.status = Candidate.DECLINED;
          if (this.tempCommentHolder) {
            candidate.buyerComment = this.tempCommentHolder;
            candidate.buyerCommentUser = this.authService.getUser();
          }
          if (selectedCandidateIds[x] !== this.invitedDistributorService.candidate.candidateId) {
            await this.saveRejectedChildCandidateAndRejectWorkflow(candidate);
          } else {
            removingParentCandidate = true;
            const parentCandidate = await this.candidateService.saveCandidate(candidate).toPromise();
            this.invitedDistributorService.setOriginalAndCurrentCandidate(parentCandidate);
          }
          this.invitedDistributorService.apNumberToStoreNumbersMap.delete(candidate.vendor.apNumber);
          this.invitedDistributorService.invitedCandidates.splice(y, 1);
          break;
        }
      }
    }
    // remove from all candidates list.
    for (let x = 0; x < selectedCandidateIds.length; x++) {
      for (let y = 0; y < this.invitedDistributorService.allDsdCandidates.length; y++) {
        if (selectedCandidateIds[x] === this.invitedDistributorService.allDsdCandidates[y].candidateId) {
          this.invitedDistributorService.allDsdCandidates.splice(y, 1);
          break;
        }
      }
    }

    // remove from invitees.
    for (let x = 0; x < selectedCandidateIds.length; x++) {
      if (selectedCandidateIds[x] === this.invitedDistributorService.candidate.candidateId) {
        continue;
      }
      for (let y = 0; y < this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.length; y++) {
        if (selectedCandidateIds[x] === this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[y].candidateId) {
          this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.splice(y, 1);
          break;
        }
      }
    }
    panel.hide();

    // if we're removing the parent candidate, reassign workflow and remaining invitees to a new candidate.
    if (removingParentCandidate) {
      const invitedSupplier = this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.pop();

      let newParentCandidate;
      for (let x = 0; x < this.invitedDistributorService.invitedCandidates.length; x++) {
        if (invitedSupplier.candidateId === this.invitedDistributorService.invitedCandidates[x].candidateId) {
          newParentCandidate = this.invitedDistributorService.invitedCandidates[x];
          break;
        }
      }

      newParentCandidate.candidateProducts[0].invitedSuppliers =
        JSON.parse(JSON.stringify(this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers));

      // Make sure tasks are not combined anymore
      this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers = [];
      forkJoin([
        this.candidateService.saveCandidate(this.invitedDistributorService.candidate, true),
        // find old task associated with the candidate to be the new parent, set it to the old parent and reject.
        // the current task will get the new parent's candidate id.
        this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(newParentCandidate.candidateId).pipe(
          switchMap(task =>  {
            return this.workflowService.updateCandidateId(this.invitedDistributorService.candidate.candidateId, task.processInstanceId).pipe(
              tap(async () => {
                await this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
                  TaskDecision.DISTRIBUTOR_WAITING_REJECT).toPromise();
                // update current task with new candidate id
                this.workflowService.updateCandidateId(newParentCandidate.candidateId, this.task.processInstanceId).subscribe(() => {
                  this.candidateService.saveCandidate(newParentCandidate, true).subscribe((savedCandidate) => {
                    if (this.invitedDistributorService.candidateIdsToShow) {
                      this.resetTempRushFlagAndBuyerComments();
                      this.router.navigate(['/tasks'], { queryParams: { growlMessage: 'Successfully rejected task'}});
                    } else {
                      this.isRejectDisabled = false;
                      this.isApproveDisabled = false;
                      this.resetTempRushFlagAndBuyerComments();
                      this.invitedDistributorService.isSelectingDistributors = false;
                      this.invitedDistributorService.setOriginalAndCurrentCandidate(savedCandidate);
                    }
                  });
                });
              })
            );
          })
        )
      ]).subscribe(value => {
        // success
      }, error => {
        this.growlService.addError(error);
      });
    } else {
      this.candidateService.saveCandidate(this.invitedDistributorService.candidate, true).subscribe((savedCandidate) => {
        if (this.invitedDistributorService.candidateIdsToShow) {
          this.resetTempRushFlagAndBuyerComments();
          this.router.navigate(['/tasks'], { queryParams: { growlMessage: 'Successfully rejected task'}});
        } else {
          this.isRejectDisabled = false;
          this.isApproveDisabled = false;
          this.resetTempRushFlagAndBuyerComments();
          this.invitedDistributorService.isSelectingDistributors = false;
          this.invitedDistributorService.setOriginalAndCurrentCandidate(savedCandidate);
        }
      });
    }
  }

  async saveRejectedChildCandidateAndRejectWorkflow(candidate: Candidate) {
    this.candidateService.saveCandidate(candidate).subscribe(() => {
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId).subscribe((task) => {
        return this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.DISTRIBUTOR_WAITING_REJECT).toPromise();
      });
    });
  }

  hideStorePanel(authGroupsOverlay) {
    this.isShowingStorePanel = false;
    authGroupsOverlay.hide();
  }

  saveClicked(candidate: Candidate, panel) {
    this.canClickSave = false;
    this.invitedDistributorService.vendorApToAuthGroupsMap.delete(candidate.vendor.apNumber);
    this.invitedDistributorService.findAndAddToVendorToAuthGroupsMapByCandidate(candidate);
    this.candidateService.validateCandidate(candidate,
      [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR]).toPromise().then(() => {
      this.invitedDistributorService.candidateError = new CandidateError();
      this.invitedDistributorService.candidateProductError  =
        this.invitedDistributorService.candidateError.candidateProductErrors[this.invitedDistributorService.candidate.candidateProducts[0].id];
      this.candidateService.saveCandidate(candidate).subscribe(savedCandidate => {

        this.invitedDistributorService.setSavedCandidate(savedCandidate);
        panel.hide();
        this.canClickSave = true;
      });
    }, (error) => {
      this.canClickSave = true;
      this.supplierProductService.scrollToTop();
      if (error.error.candidateErrors.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.invitedDistributorService.candidateError = this.supplierProductService.getStoreAuthError();
        this.invitedDistributorService.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.invitedDistributorService.candidateError);
      }
    });
  }


  approveSelected() {
    this.isApproveDisabled = true;
    this.isRejectDisabled = true;
    if (this.invitedDistributorService.hasAllSelectedSuppliers(this.invitedDistributorService.candidatesToShow)) {
      this.approveAll();
      return;
    }

    if (this.invitedDistributorService.isParentSelected(this.invitedDistributorService.candidatesToShow)) {
      let candidatesToValidate = [];
      candidatesToValidate = this.invitedDistributorService.getSelectedCandidates(this.invitedDistributorService.candidatesToShow);
      this.candidateService.validateBulkCandidate(candidatesToValidate,
        [CandidateValidatorType.ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe((validationResponse) => {
        // add any candidate errors to the map.
        for (const key of Object.keys(validationResponse)) {
          if (validationResponse[key]?.candidateError) {
            this.candidateErrorsMap.set(+key, validationResponse[key]?.candidateError);
          } else {
            this.candidateErrorsMap.delete(+key);
          }
        }
        if (this.candidateErrorsMap.size > 0) {
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
        } else {
          if (this.tempRushFlagHolder) {
            candidatesToValidate = this.applyRushFlag(candidatesToValidate);
          }
          if (this.tempCommentHolder) {
            candidatesToValidate = this.applyBuyerComments(candidatesToValidate);
          }
          this.candidateService.saveAllCandidates(candidatesToValidate).subscribe(() => {
            this.resetTempRushFlagAndBuyerComments();
            this.submitCurrentParentCreateNewParentCandidateAndMoveToBuyer();
          });
        }
      });
    } else {

      let childCandidatesToValidate = [];
      childCandidatesToValidate = this.invitedDistributorService.getSelectedCandidates(this.invitedDistributorService.candidatesToShow);
      this.candidateService.validateBulkCandidate(childCandidatesToValidate,
        [CandidateValidatorType.ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe((validationResponse) => {
        // add any candidate errors to the map.
        for (const key of Object.keys(validationResponse)) {
          if (validationResponse[key]?.candidateError) {
            this.candidateErrorsMap.set(+key, validationResponse[key]?.candidateError);
          } else {
            this.candidateErrorsMap.delete(+key);
          }
        }
        if (this.candidateErrorsMap.size > 0) {
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
        } else {
          if (this.tempRushFlagHolder) {
            childCandidatesToValidate = this.applyRushFlag(childCandidatesToValidate);
          }
          if (this.tempCommentHolder) {
            childCandidatesToValidate = this.applyBuyerComments(childCandidatesToValidate);
          }
          this.candidateService.saveAllCandidates(childCandidatesToValidate).subscribe(() => {
            this.resetTempRushFlagAndBuyerComments();
            this.createNewParentCandidateAndMoveWaitingTaskToPia();
          });
        }
      });
    }
  }

  createNewParentCandidateAndMoveWaitingTaskToPia() {
    const selectedIds = this.invitedDistributorService.getSelectedCandidateIds(this.invitedDistributorService.candidatesToShow);
    let newParent: Candidate;
    let invitedSuppliers = [];
    for (let x = 0; x < selectedIds.length; x++) {
      for (let y = 0; y < this.invitedDistributorService.invitedCandidates.length; y++) {
        if (selectedIds[x] === this.invitedDistributorService.invitedCandidates[y].candidateId) {
          newParent = this.invitedDistributorService.invitedCandidates.slice(y)[0];
          break;
        }
      }
      if (newParent) {
        break;
      }
    }
    for (let x = 0; x < selectedIds.length; x++) {
      for (let y = 0; y < this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.length; y++) {
        // simply remove the parent from invited suppliers, and the rest of selected add to the new list for the candidate.
        if (this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[y].candidateId === newParent.candidateId) {
          this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.splice(y, 1);
        } else if (selectedIds[x] === this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[y].candidateId) {
          invitedSuppliers = invitedSuppliers.concat(this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.splice(y, 1));
          break;
        }
      }
    }
    newParent.candidateProducts[0].invitedSuppliers = invitedSuppliers;

    // update current display by removing from current, and putting selected into the pia submitted.
    this.invitedDistributorService.submittedCandidates =
      this.invitedDistributorService.submittedCandidates.concat(this.invitedDistributorService.removeSelectedInvitedCandidates(this.invitedDistributorService.candidatesToShow));

    // save current parent candidate.
    this.candidateService.saveCandidate(this.invitedDistributorService.candidate).subscribe((savedCandidate) => {
      this.invitedDistributorService.setOriginalAndCurrentCandidate(savedCandidate);
    });

    // save new parent to be submitted
    this.candidateService.saveCandidate(newParent).subscribe(() => {
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(newParent.candidateId).subscribe((task) => {
        this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.DISTRIBUTOR_WAITING_CONTINUE_TO_PIA)
          .subscribe(() => {
            this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.invitedDistributorService.candidate.candidateId)
              .subscribe((newCurrentTask) => {
                this.workflowService.updateApNumber(this.invitedDistributorService.candidate.vendor.apNumber,
                  newCurrentTask.processInstanceId).subscribe();
                this.isApproveDisabled = false;
                this.isRejectDisabled = false;
                this.invitedDistributorService.isSelectingDistributors = false;
                if (this.invitedDistributorService.candidateIdsToShow) {
                  this.router.navigate(['/tasks'], { queryParams: { growlMessage: 'Successfully completed task'}}).then();
                }
              });
          });
      });
    });

  }

  /**
   * if the parent candidate is selected, remove non selected candidates from the parent candidate and complete current task.
   * Pick the first non selected as the new parent, assigning remaining as the childs, and move parents task to buyer.
   */
  async submitCurrentParentCreateNewParentCandidateAndMoveToBuyer() {
    const notSelectedCandidates: Candidate[] = this.invitedDistributorService.getNotSelectedCandidates(this.invitedDistributorService.candidatesToShow);
    let newParent: Candidate;
    for (let x = 0; x < notSelectedCandidates.length; x++) {
      if (notSelectedCandidates[x].candidateId !== this.invitedDistributorService.candidate.candidateId) {
        newParent = notSelectedCandidates.slice(x, 1)[0];
        break;
      }
    }

    let notSelectedInvitedSuppliers = [];

    //  find not selected invited suppliers to be associated w/ new task.
    for (let x = 0; x < notSelectedCandidates.length; x++) {
      for (let y = 0; y < this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.length; y++) {
        if (notSelectedCandidates[x].candidateId === this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers[y].candidateId) {
          notSelectedInvitedSuppliers = notSelectedInvitedSuppliers.concat(
            this.invitedDistributorService.candidate.candidateProducts[0].invitedSuppliers.splice(y, 1));
          break;
        }
      }
    }
    // remove parent from invited suppliers list.
    for (let x = 0; x < notSelectedInvitedSuppliers.length; x++) {
      if (notSelectedInvitedSuppliers[x].candidateId === newParent.candidateId) {
        notSelectedInvitedSuppliers.splice(x, 1);
        break;
      }
    }
    newParent.candidateProducts[0].invitedSuppliers = notSelectedInvitedSuppliers;

    // update current display by removing from current, and putting selected into the pia submitted.
    this.invitedDistributorService.submittedCandidates =
      this.invitedDistributorService.submittedCandidates.concat(this.invitedDistributorService.removeSelectedInvitedCandidates(this.invitedDistributorService.candidatesToShow));

    // save and complete current candidate
    await this.candidateService.saveCandidate(this.invitedDistributorService.candidate).toPromise();
    await this.workflowService.completeTaskWithAction(this.task, WorkflowService.ACTION_COMPLETE,
      TaskDecision.INVITED_DISTRIBUTOR_BUYER_APPROVE_DECISION).toPromise();


    this.candidateService.saveCandidate(newParent).subscribe((newCandidate) => {
      this.invitedDistributorService.setOriginalAndCurrentCandidate(newCandidate);
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.invitedDistributorService.candidate.candidateId).subscribe((task) => {
        this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.DISTRIBUTOR_WAITING_CONTINUE_TO_BUYER)
          .subscribe(() => {
            this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(this.invitedDistributorService.candidate.candidateId)
              .subscribe((newCurrentTask) => {
                this.task = newCurrentTask;
                this.workflowService.updateApNumber(this.invitedDistributorService.candidate.vendor.apNumber, this.task.processInstanceId).subscribe();
                this.isApproveDisabled = false;
                this.isRejectDisabled = false;
                this.invitedDistributorService.isSelectingDistributors = false;
                if (this.invitedDistributorService.candidateIdsToShow) {
                  this.router.navigate(['/tasks'], { queryParams: { growlMessage: 'Successfully completed task'}}).then();
                } else {
                  // update url
                  window.history.replaceState({}, 'Title', '#/buyerInvitedDistributorsReview?taskId=' + this.task.id);
                }
              });
          });
      });
    });
  }

  approveAll() {
    this.isApproveDisabled = true;
    this.isRejectDisabled = true;

    if (this.invitedDistributorService.candidateIdsToShow) {
      this.invitedDistributorService.candidatesToShow.forEach(candidate => candidate.selected = true);
      this.approveSelected();
      return;
    }
    let candidatesToValidate = this.invitedDistributorService.candidatesToShow;
    this.candidateService.validateBulkCandidate(candidatesToValidate,
      [CandidateValidatorType.ADDITIONAL_DISTRIBUTOR_REVIEW_VALIDATOR]).subscribe((validationResponse) => {
      // add any candidate errors to the map.
      for (const key of Object.keys(validationResponse)) {
        if (validationResponse[key]?.candidateError) {
          this.candidateErrorsMap.set(+key, validationResponse[key]?.candidateError);
        } else {
          this.candidateErrorsMap.delete(+key);
        }
      }
      if (this.candidateErrorsMap.size > 0) {
        this.isApproveDisabled = false;
        this.isRejectDisabled = false;
      } else {
        if (this.tempRushFlagHolder) {
          candidatesToValidate = this.applyRushFlag(candidatesToValidate);
        }
        if (this.tempCommentHolder) {
          candidatesToValidate = this.applyBuyerComments(candidatesToValidate);
        }
        this.candidateService.saveAllCandidates(candidatesToValidate).subscribe(() => {
          this.resetTempRushFlagAndBuyerComments();
          this.completeTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE, TaskDecision.INVITED_DISTRIBUTOR_BUYER_APPROVE_DECISION, 'Successfully completed task.');
        });
      }
    });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then(() => {
          this.isApproveDisabled = false;
          this.isRejectDisabled = false;
          this.invitedDistributorService.isSelectingDistributors = false;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
        this.isApproveDisabled = false;
        this.invitedDistributorService.isSelectingDistributors = false;
      });
  }

  /**
   * Checks if a department was overridden and resets data if it was.
   * @param invitedCandidate
   */
  editDepartment(invitedCandidate: Candidate) {
    this.editCandidateModalService.openModal(AttributeTypes.SubDepartment, invitedCandidate, {}).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        if (this.invitedDistributorService.hasDepartmentChanged(response, invitedCandidate)) {
          if (this.invitedDistributorService.isOverrideDepartmentTheDefaultDepartment(response, invitedCandidate)) {
            response.overrideDepartment = false;
            response.overrideSubDepartment = null;
          }
          this.invitedDistributorService.getPssDepartments(response);
          this.resetModalAndFetchNewStores(response);
        } else if (this.invitedDistributorService.isOverrideDepartmentTheDefaultDepartment(response, invitedCandidate)) {
          invitedCandidate.overrideDepartment = false;
          invitedCandidate.overrideSubDepartment = null;
          this.invitedDistributorService.getPssDepartments(invitedCandidate);
        }
      }
    });
  }

  /**
   * Updates the ap to stores group map and clears out stores for when a department is changed.
   * @param response
   */
  resetModalAndFetchNewStores(response: Candidate) {
    response.candidateProducts[0].locationGroupStores = [];
    this.supplierProductService.setAuthGroups(null);
    this.supplierProductService.setSelectedAuthGroups(null);
    this.supplierProductService.setNotFoundStores(null);
    this.candidateService.saveCandidate(response).subscribe(savedCandidate => {
      this.invitedDistributorService.setSavedCandidate(savedCandidate);
      this.invitedDistributorService.vendorApToAuthGroupsMap.delete(savedCandidate.vendor.apNumber);
      this.invitedDistributorService.findAndAddToVendorToAuthGroupsMapByCandidate(savedCandidate);
    });
  }


  editPssDepartment(invitedCandidate: Candidate) {
    this.editCandidateModalService.openModal(AttributeTypes.PssDepartment, invitedCandidate,
      {collections: this.invitedDistributorService.pssDepartments.get(invitedCandidate.candidateId)}).subscribe(response => {
      if ( response ) {
        this.invitedDistributorService.candidateError.pssDepartment = null;
        if (this.candidateErrorsMap.get(invitedCandidate.candidateId)?.pssDepartment) {
          this.candidateErrorsMap.get(invitedCandidate.candidateId).pssDepartment = null;
        }
        // Dispatch Update
        this.candidateService.saveCandidate(response).subscribe(savedCandidate => {
          this.invitedDistributorService.setSavedCandidate(savedCandidate);
        });
      }
    });
  }

  applyRushFlag(candidates: Candidate[]) {
    candidates.forEach(candidate => {
      candidate.rushFlag = true;
    });
    return candidates;
  }

  applyBuyerComments(candidates: Candidate[]) {
    candidates.forEach(candidate => {
      candidate.buyerComment = this.tempCommentHolder;
      candidate.buyerCommentUser = this.authService.getUser();
    });
    return candidates;
  }

  resetTempRushFlagAndBuyerComments() {
    this.tempRushFlagHolder = false;
    this.tempCommentHolder = '';
    this.showSelectWarning = false;
  }

  onSelect() {
    this.invitedDistributorService.isSelectingDistributors = !this.invitedDistributorService.isSelectingDistributors;
    this.showSelectWarning = true;
  }

  onCancel() {
    this.invitedDistributorService.isSelectingDistributors = !this.invitedDistributorService.isSelectingDistributors;
    this.showSelectWarning = false;
  }

  editCostLink(editedCandidate: Candidate) {
    this.editCandidateModalService
      .openModal(AttributeTypes.CostLinked, editedCandidate, {
        validationService: this.candidateService
      })
      .subscribe(response => {
        if (response) {
          this.validateCostLink(response);
        }
      });
  }

  editCostLinkedItem(editedCandidate: Candidate) {
    this.editCandidateModalService
      .openModal(AttributeTypes.CostLinkedItem, editedCandidate, {
        validationService: this.candidateService
      })
      .subscribe(response => {
        if (response) {
          this.validateCostLink(response);
        }
      });
  }

  validateCostLink(editedCandidate: Candidate) {
    this.costLinkState = InputState.loading;
    this.candidateService.validateCandidate(editedCandidate,
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((validatedCandidate) => {
      this.costLinkState = InputState.valid;
      editedCandidate.costLinkFromServer = validatedCandidate.costLinkFromServer;
      if (editedCandidate.costLinkFromServer !== editedCandidate.masterListCost) {
        editedCandidate.masterListCost = editedCandidate.costLinkFromServer?.listCost;
      }
      if (this.candidateErrorsMap.get(editedCandidate.candidateId) != null) {
        this.candidateErrorsMap.get(editedCandidate.candidateId).costLink = undefined;
        this.candidateErrorsMap.get(editedCandidate.candidateId).hasErrors = null;
      }
      this.candidateService.saveCandidate(editedCandidate).subscribe(savedCandidate => {
        this.invitedDistributorService.setSavedCandidate(savedCandidate);
      });
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.candidateErrorsMap.set(error.error.candidateErrors.candidateId, error.error.candidateErrors);
      }
      this.candidateService.saveCandidate(editedCandidate).subscribe(savedCandidate => {
        this.invitedDistributorService.setSavedCandidate(savedCandidate);
      });
    });
  }

  showPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(target);
  }

  get attributeType() {
    return AttributeTypes;
  }

  editMasterListCost(type: AttributeTypes, invitedCandidate: Candidate, overrides?: any) {
    this.editCandidateModalService.openModal(type, invitedCandidate, overrides).subscribe(response => {
      if ( response ) {
        this.invitedDistributorService.candidateError.masterListCost = null;
        this.costService.updateCandidateCostsFromUpdatedCandidate(invitedCandidate, response);
        if (this.candidateErrorsMap.get(invitedCandidate.candidateId)?.masterListCost) {
          this.candidateErrorsMap.get(invitedCandidate.candidateId).masterListCost = null;
        }
        // Dispatch Update
        this.candidateService.saveCandidate(invitedCandidate).subscribe(savedCandidate => {
          this.invitedDistributorService.setSavedCandidate(savedCandidate);
        });
      }
    });
  }

  editVPC(type: AttributeTypes, invitedCandidate: Candidate, overrides?: any) {
    this.editCandidateModalService
      .openModal(type, invitedCandidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(invitedCandidate)], overrides ).subscribe(response => {
      if ( response ) {
        this.invitedDistributorService.getCurrentCandidateProductError(this.invitedDistributorService.candidateError).vendorProductCode = null;
        if (this.candidateErrorsMap
          .get(invitedCandidate.candidateId)?.candidateProductErrors[invitedCandidate.candidateProducts[0].id].vendorProductCode) {
          this.candidateErrorsMap
            .get(invitedCandidate.candidateId).candidateProductErrors[invitedCandidate.candidateProducts[0].id].vendorProductCode = null;
        }
        // Dispatch Update
        invitedCandidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(invitedCandidate)] = response;
        this.candidateService.saveCandidate(invitedCandidate).subscribe(savedCandidate => {
          this.invitedDistributorService.setSavedCandidate(savedCandidate);
        });
      }
    });
  }

  editCountryOfOrigin(type: AttributeTypes, invitedCandidate: Candidate, overrides?: any) {
    this.editCandidateModalService
      .openModal(type, invitedCandidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(invitedCandidate)], overrides ).subscribe(response => {
      if ( response ) {
        this.invitedDistributorService.getCurrentCandidateProductError(this.invitedDistributorService.candidateError).countryOfOrigin = null;
        if (this.candidateErrorsMap
          .get(invitedCandidate.candidateId)?.candidateProductErrors[invitedCandidate.candidateProducts[0].id].countryOfOrigin) {
          this.candidateErrorsMap
            .get(invitedCandidate.candidateId).candidateProductErrors[invitedCandidate.candidateProducts[0].id].countryOfOrigin = null;
        }
        // Dispatch Update
        invitedCandidate.candidateProducts[CandidateUtilService.getCurrentCandidateProductIndex(invitedCandidate)] = response;
        this.candidateService.saveCandidate(invitedCandidate).subscribe(savedCandidate => {
          this.invitedDistributorService.setSavedCandidate(savedCandidate);
        });
      }
    });
  }
}
