import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UPCInputState} from 'pm-components';
import {
  Candidate,
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  ProductImagesCardPermissions,
  ProductPackagingCardPermissions
} from 'pm-models';
import {GrowlService} from '../../growl/growl.service';
import {BonusSizeService} from '../../service/bonus-size.service';
import {CandidateService} from '../../service/candidate.service';
import {CostService} from '../../service/cost.service';
import {LookupService} from '../../service/lookup.service';
import {WorkflowService} from '../../service/workflow.service';
import {SupplierBonusStepperComponent} from '../supplier-bonus-stepper/supplier-bonus-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {LabelProductImagesCardPermissions} from 'pm-models/lib/card-models/label-product-images-model';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-bonus-details',
  templateUrl: './bonus-details.component.html',
  styleUrls: ['./bonus-details.component.scss']
})
export class BonusDetailsComponent implements OnInit {

  public isViewingPage = true;

  readonly productPackagingCardPermissions: ProductPackagingCardPermissions = {
    isReadOnly: false
  };

  private taskSubscription$: any;

  public unitsOfMeasures: any;
  public packageTypes: any;
  canNavigate = true;

  upcState: UPCInputState;

  readonly productImagesCardPermissions: ProductImagesCardPermissions = {
    isReadOnly: false
  };

  readonly labelInsightsImagesCardPermissions: LabelProductImagesCardPermissions = {
    isReadOnly: false
  };

  constructor(public workflowService: WorkflowService, public candidateService: CandidateService, public route: ActivatedRoute,
              public router: Router, public growlService: GrowlService, public lookupService: LookupService,
              public bonusSizeService: BonusSizeService, public costService: CostService,
              private candidateUtilService: CandidateUtilService) {
  }

  ngOnInit() {
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.bonusSizeService.taskId,
        this.bonusSizeService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.bonusSizeService.resetService();
        this.bonusSizeService.setCandidateByUrlParameters(params).pipe(
          switchMap((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.BONUS_SIZE || candidate.candidateType === Candidate.REPLACEMENT_UPC) {
              this.bonusSizeService.currentCandidate = candidate;
              this.bonusSizeService.originalCandidate = JSON.parse(JSON.stringify(candidate));
              this.bonusSizeService.candidateError = this.bonusSizeService.setupBonusSizeError;
              this.bonusSizeService.candidateProductError = new CandidateProductError();
              return this.bonusSizeService.setProductData();
            } else {
              return this.router.navigate(['/tasks']);
            }
          })
        ).subscribe();
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.bonusSizeService.getCandidate()).pipe(
          switchMap((isValid) => {
            if (isValid) {
              return this.bonusSizeService.setProductData();
            } else {
              this.bonusSizeService.resetService();
              return this.router.navigate(['/tasks']);
            }
          })
        ).subscribe();
      }
    });
  }

  onClickNext() {
    this.jumpToPage('/caseDetails', [CandidateValidatorType.BONUS_SIZE_DETAILS_VALIDATOR]);
  }

  onClickBack() {
    this.bonusSizeService.saveCandidateAndNavigate(this.bonusSizeService.getSupplierSetupUrl(), false);
  }

  onClose() {
    this.isViewingPage = false;
    this.bonusSizeService.saveCandidateAndNavigate('/tasks', false);
  }

  subBrandChange(selectedValue: any) {
    this.bonusSizeService.currentCandidate.candidateProducts[this.bonusSizeService.currentCandidateProductIndex].subBrand = selectedValue;
  }

  validateUPC() {
    this.upcState = UPCInputState.loading;
    const candidateProductId = this.bonusSizeService.getCurrentCandidateProduct().id;

    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate,
      [CandidateValidatorType.UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.bonusSizeService.candidateProductError.upc = undefined;
    }, (error) => {
      this.upcState = UPCInputState.invalid;
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {
        const returnedCandidateError: CandidateError = error.error.candidateErrors;
        const returnedCandidateProductError: CandidateProductError =
          returnedCandidateError.candidateProductErrors[candidateProductId];
        this.bonusSizeService.candidateProductError.upc = returnedCandidateProductError.upc;
        this.bonusSizeService.candidateProductError.upcCheckDigit = returnedCandidateProductError.upcCheckDigit;
      } else {
        this.growlService.addError(error.message); // TODO: new way to handle server side errors?
      }
    });
  }

  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    let urlToNavigate;
    switch (stepperItem.text) {
      case SupplierBonusStepperComponent.UPC_ITEM_CODE:
        this.onClickBack();
        break;
      case SupplierBonusStepperComponent.CASE_DETAILS:
        this.onClickNext();
        break;
      case SupplierBonusStepperComponent.EXTENDED_ATTRIBUTES:
        urlToNavigate = this.bonusSizeService.isReplacementUpc ? 'replacementUpcExtendedAttributes' : 'bonusUpcExtendedAttributes';
        const validators = [CandidateValidatorType.BONUS_SIZE_DETAILS_VALIDATOR, CandidateValidatorType.BONUS_CASE_PACK_DETAILS_VALIDATOR,
          CandidateValidatorType.SUPPLIER_COMMENT_VALIDATOR];
        this.jumpToPage(urlToNavigate, validators);
        break;
    }
  }

  jumpToPage(urlToNavigate, validators: CandidateValidatorType[]) {
    this.canNavigate = false;
    this.candidateService.validateCandidate(this.bonusSizeService.currentCandidate, validators).subscribe(() => {
      this.bonusSizeService.candidateError = this.bonusSizeService.bonusDetailsError = new CandidateError();
      this.bonusSizeService.candidateProductError = new CandidateProductError();
      this.bonusSizeService.saveCandidateAndNavigate(urlToNavigate, false);
    }, (error) => {
      this.bonusSizeService.scrollToTop();

      // set the errors on the page
      if (error.error.candidateErrors.hasErrors) {
        this.bonusSizeService.candidateError = error.error.candidateErrors;
        this.bonusSizeService.candidateProductError =
          this.bonusSizeService.candidateError.candidateProductErrors[this.bonusSizeService.getCurrentCandidateProduct().id];
        this.bonusSizeService.bonusDetailsError = this.bonusSizeService.candidateError;
      }
      this.canNavigate = true;
    });
  }

  hasCandidateProduct() {
    return this.bonusSizeService.currentCandidate != null &&
      this.bonusSizeService.currentCandidate.candidateProducts[this.bonusSizeService.currentCandidateProductIndex] !== null;
  }
}
