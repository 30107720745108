import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Vendor} from 'pm-models';

@Component({
  selector: 'pm-supplier-drop-down-filter',
  templateUrl: './supplier-drop-down-filter.component.html',
  styleUrls: ['./supplier-drop-down-filter.component.scss']
})
export class SupplierDropDownFilterComponent implements OnInit {

  @Input()
  suppliers: Vendor[];

  @Input()
  supplierFilters: string[];

  @Output()
  dropDownChange: EventEmitter<any> = new EventEmitter<any>();

  followedSuppliers: any[] = [];
  allSuppliers: any[];
  checkedSupplierAps: number[] = [];

  isSearchFormOpen: boolean = false;
  searchText: string = '';

  constructor() { }

  ngOnInit() {
    if (!this.suppliers || this.suppliers.length === 0) {
      return;
    }
    if (!this.supplierFilters || this.supplierFilters.length === 0) {
      this.allSuppliers = this.suppliers;
    } else {
      const notFollowedSuppliers = [];
      this.suppliers.forEach((supplier) => {
        if (this.supplierFilters.includes('' + supplier.apNumber)) {
          supplier.checked = true;
          this.checkedSupplierAps.push(supplier.apNumber);
          this.followedSuppliers.push(supplier);
        } else {
          supplier.checked = false;
          notFollowedSuppliers.push(supplier);
        }
      });
      this.allSuppliers = this.followedSuppliers.concat(notFollowedSuppliers);
    }
  }

  clickSupplierButton() {
    if (this.isSearchFormOpen) {
      // same as clicking on apply button
      this.onClickApplyButton();
    } else {
      // toggle hide/show search form
      this.isSearchFormOpen = !this.isSearchFormOpen;
    }
  }

  onClickClearButton() {
    this.checkedSupplierAps = [];
    this.allSuppliers.map(supplier => supplier.checked = false);
  }

  onClickApplyButton() {
    // hide search form
    this.isSearchFormOpen = !this.isSearchFormOpen;
    this.searchText = '';
    this.dropDownChange.emit(this.checkedSupplierAps);
  }

  supplierChecked(event, apNumber) {
    if (event.checked) {
      this.checkedSupplierAps.push(apNumber);
    } else {
      this.checkedSupplierAps.splice(this.checkedSupplierAps.findIndex(checekdAp => checekdAp === apNumber), 1);
    }
  }
}
