<pm-editor (closeEvent)="onClose()">
  <pm-header>
    <app-pia-mrt-stepper [innerFlow]="supplierMrtService.hasNewInners(supplierMrtService.candidate) === true"
                         [candidateErrors]="supplierMrtService.getAllPiaMrtErrors()"
                         [hasInnerCandidateError]="[supplierMrtService.hasInnerErrors()]"
                         [stepIndex]="supplierMrtService.hasNewInners(supplierMrtService.candidate) ? 5 : 4"
                         (indexClick)="onClickStepper($event)">
    </app-pia-mrt-stepper>
  </pm-header>

  <pm-title>
    Extended attributes
  </pm-title>

  <div *ngIf="isLoadingData" class="col-md-8 spinner-row">
    <pm-progress-spinner [showSpinner]="isLoadingData" [strokeWidth]="'2'"
                         [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}">
    </pm-progress-spinner>
    <h5 class="ml-3">Fetching attributes...</h5>
  </div>
  <ng-container *ngIf="!isLoadingData">

    <pm-global-attributes-card [candidateModel]="supplierMrtService.candidate"
                               [candidateProductModel]="supplierMrtService.candidate.candidateProducts[0]"
                               [globalAttributes]="supplierMrtService.globalAttributes"
                               [candidateProductErrorModel]="candidateProductError">
    </pm-global-attributes-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Activate" (onClick)="onClickActivate()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
